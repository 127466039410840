import { FaturamentoService } from './../../../../services/faturamento.service';


import { ModalService } from 'src/app/services/shared/modal-service';
import { DividaPagamentosService } from '../../../../services/divida-pagamentos.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';

@Component({
  selector: 'app-modal-detalhamento-nf',
  templateUrl: './modal-detalhamento-nf.component.html',
  styleUrls: ['./modal-detalhamento-nf.component.css']
})
export class ModalDetalhamentoNfComponent implements OnInit {

  modalRef: NgbModalRef;
  load:boolean = false;

  faturamentos:any = [];

  credorId:any;
  date:Date;
  title:String = "Notas Fiscais"

  constructor(
    private faturamentoService: FaturamentoService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.credorId = this.modalRef.componentInstance.credorId;
    this.date = this.modalRef.componentInstance.date;

    this.getFaturamentosByCredorAndDate();
  }


  close() {
    this.modalRef.close();
  }

  dismiss() {
      this.modalRef.dismiss();
  }


  getFaturamentosByCredorAndDate()
  {
    this.load = true;
      this.faturamentoService.getAllbyCredorAndDate(this.credorId,this.date).subscribe(x =>
        {
            this.faturamentos = x.data;
            this.load = false;
        });
  }




}

