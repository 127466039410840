<div class="row">
  <div class="col-12 mb-2">
    <h6 id="formulario_{{ index }}">Formulário ({{ index }})</h6>
  </div>

  <div class="col-12 mb-2">
    <label class="form-label"
      >Formulário<span class="required-field">*</span></label
    >
    <ng-select
      [items]="formularios"
      [(ngModel)]="formulario.formularioDinamicoId"
      [ngModelOptions]="{ standalone: true }"
      bindLabel="nomeFormulario"
      bindValue="id"
    ></ng-select>
  </div>
  <div class="col-12 mb-2">
    <label class="form-label"
      >Prioridade<span class="required-field">*</span></label
    >
    <ng-select
      [items]="tipoPrioridade"
      [(ngModel)]="formulario.tipoPrioridade"
      [ngModelOptions]="{ standalone: true }"
      bindLabel="nome"
      bindValue="id"
      [loading]="tipoPrioridade == undefined || tipoPrioridade.length == 0"
    ></ng-select>
  </div>
  <div class="col-4 mb-2">
    <label class="form-label"
      >Data limite<span class="required-field ml-1">*</span></label
    >
    <div class="input-group">
      <input
        (click)="dataLimite.toggle()"
        class="form-control"
        placeholder="dd/mm/yyyy"
        name="dataLimiteComponent"
        ngbDatepicker
        [(ngModel)]="formulario.dataLimiteComponent"
        placement="bottom-right"
        #dataLimite="ngbDatepicker"
        (ngModelChange)="definirDataRevisao()"
      />
      <div class="input-group-append">
        <button
          class="input-group-text"
          (click)="dataLimite.toggle()"
          type="button"
        >
          <span class="ion ion-md-calendar" style="cursor: pointer"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-2 mb-2">
    <label class="form-label">Alerta</label>
    <input
      type="number"
      min="0"
      class="form-control"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="formulario.alerta"
    />
  </div>
  <div class="col-6 mb-2">
    <label class="form-label">&nbsp;</label>
    <ng-select
      [items]="tipoAlerta"
      [(ngModel)]="formulario.tipoAlerta"
      [ngModelOptions]="{ standalone: true }"
      bindLabel="nome"
      bindValue="id"
      [loading]="tipoAlerta == undefined || tipoAlerta.length == 0"
    ></ng-select>
  </div>
  <div class="col-4 mb-2">
    <label class="form-label"
      >Data Revisão<span class="required-field ml-1">*</span></label
    >
    <div class="input-group">
      <input
        (click)="dataRevisao.toggle()"
        class="form-control"
        placeholder="dd/mm/yyyy"
        name="dataRevisaoComponent"
        ngbDatepicker
        [(ngModel)]="formulario.dataRevisaoComponent"
        placement="bottom-right"
        [disabled]="calcularData"
        #dataRevisao="ngbDatepicker"
      />
      <div class="input-group-append">
        <button
          class="input-group-text"
          (click)="dataRevisao.toggle()"
          type="button"
        >
          <span
            class="ion ion-md-calendar"
            style="cursor: pointer"
            *ngIf="!calcularData"
          ></span>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            *ngIf="calcularData"
            aria-hidden="true"
          ></span>
          <span class="sr-only" *ngIf="calcularData">Loading...</span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 mb-2">
    <label class="form-label"
      >Equipe
      <i
        class="fas fa-info-circle"
        ngbTooltip="Por favor, selecione uma equipe para que os membros padrão sejam carregados automaticamente nos campos abaixo"
      ></i
    ></label>
    <equipe-dropdown
      [(equipeId)]="equipeId"
      (change)="onChangeEquipe($event)"
    ></equipe-dropdown>
  </div>
  <div class="col-12 mb-2">
    <label class="form-label"
      >Executor <span class="required-field">*</span></label
    >
    <pessoa-dropdown
      #executorDropdown
      [(pessoaId)]="formulario.responsavelId"
    ></pessoa-dropdown>
  </div>
  <div class="col-12 mb-2">
    <label class="form-label"
      >Revisor <span class="required-field">*</span></label
    >
    <pessoa-dropdown
      #revisorDropdown
      [(pessoaId)]="formulario.revisorId"
    ></pessoa-dropdown>
  </div>
  <div class="col-12 mb-2">
    <label class="form-label"
      >Assistente <span class="required-field">*</span></label
    >
    <pessoa-dropdown
      #assistenteDropdown
      [(pessoaId)]="formulario.assistenteId"
    ></pessoa-dropdown>
  </div>
  <div class="col-12 mb-2">
    <label class="form-label">Observações</label>
    <textarea
      class="form-control"
      placeholder="Digite a descrição"
      rows="2"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="formulario.observacao"
    ></textarea>
  </div>
</div>
