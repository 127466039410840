import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { HonorarioService } from 'src/app/services/honorario.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-honorarios-por-percentual',
  templateUrl: './honorarios-por-percentual.component.html',
  styleUrls: ['./honorarios-por-percentual.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
]
})
export class HonorariosPorPercentualComponent implements OnInit {

  public honorarioPorPercentual: any;
  public honorariosDetalhe:any=[];
  public tipo:string;
  public referenciaId:string;
  public clienteSelected:any; 

  constructor(
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private honorarioService: HonorarioService       
  ) { }

  ngOnInit(): void {
    this.tipo = this.route.snapshot.params['tipo'];
    this.referenciaId = this.route.snapshot.params['referenciaId'];   

    this.honorarioPorPercentual = 
    {
        alertarEncerramento:true,
        tipoReferencia:this.tipo,
        referenciaId:this.referenciaId
    };

    this.honorarioService.buscarDetalhesHonorario(this.tipo, this.referenciaId)
                        .subscribe(res=>{
                            this.honorariosDetalhe = res.data;
                            
                            this.clienteSelected = this.honorariosDetalhe.nomeCliente[0];
                        });
  }

  onSubmit(){
    this.spinner.show();
    
    this.toastrService.clear();

    if(typeof this.honorarioPorPercentual.valorPercentual == 'undefined'){
        this.toastrService.error('Valor é obrigatório!', 'Atenção', { timeOut: 10000 });
        this.spinner.hide();
        return;
    }

    if(typeof this.honorarioPorPercentual.descricao == 'undefined'){
        this.toastrService.error('Descrição é obrigatório!', 'Atenção', { timeOut: 10000 });
        this.spinner.hide();
        return;
    }

    this.honorarioService.addPorPercentual(this.honorarioPorPercentual)
        .subscribe(res=>{
            this.spinner.hide();
            this.location.back();
            this.toastrService.success("Honorário cadastrado.", "Sucesso");
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar o Honorário!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
  }

  cancel() {
    this.location.back();
  }

}
