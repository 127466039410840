
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { InstanciasService } from 'src/app/services/instancias.service';

@Component({
    selector: 'app-modal-cadastro-instancias',
    templateUrl: './modal-cadastro-instancias.component.html',
    providers: [InstanciasService]
})
export class ModalCadastroInstanciasComponent implements OnInit {
    modalRef: NgbModalRef;
    public instancia: any;
    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private appService: AppService,
        private instanciasService: InstanciasService,
    ) {

    }
    popularNovaComarca() {
        this.instancia = {
            nome: '',
        };
    }
    ngOnInit(): void {
        this.popularNovaComarca();
    }
    cadastrar() {
        this.add(this.instancia);
    }
    add(instancia) {
        this.spinner.show();
        this.toastrService.clear();
        this.instanciasService.add(instancia)
            .subscribe(res => {
                this.toastrService.success("Instância cadastrada.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a instância!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }
    close() {
        this.modalRef.close();
    }
    dismiss() {
        this.modalRef.dismiss();
    }
}