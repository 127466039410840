import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DivisaoDeOrgaoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'DivisaoDeOrgao/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    add(data): Observable<any> {
      return this.http.post(environment.api + this.endpoint, data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
    
    getAll(): Observable<any> {
      return this.http.get(environment.api + this.endpoint)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getAllAsync() {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

    update(data): Observable<any> {
      return this.http.put(environment.api + this.endpoint , data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
    
    delete(id): Observable<any> {
      return this.http.delete(environment.api + this.endpoint + id)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
