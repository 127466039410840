import { Component, Input, OnInit } from '@angular/core';
import { AtendimentoService } from 'src/app/services/atendimento.service';

@Component({
    selector: 'atendimento-resumo',
    templateUrl: './atendimento-resumo.component.html',
    styleUrls: ['./atendimento-resumo.component.css']
})
export class AtendimentoResumoComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() raiz: string;
    @Input() hasFilter = true;

    public loadQuantidade: boolean = true;
    public quantidade: number = 0;
    public hideButtons: boolean = true;
    public recolhido: boolean = false;

    constructor(
        public atendimentoService: AtendimentoService,
    ) { }

    ngOnInit(): void {
        this.loadQuantidade = true;
        this.atendimentoService.obterQuantidadeAtendimentosPorRegistro(this.tipo, this.referenciaId)
            .subscribe(res => {
                if (res.success) {
                    this.loadQuantidade = false;

                    this.quantidade = res.data;

                    console.log(res.data);
                }
            })
    }

    recolherDados() {
        this.recolhido = !this.recolhido;
    }

}
