import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable()
export class ArquivoService extends ServiceBase {
    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'arquivo/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }
    
    downloadArquivo(id) {
      let url = environment.api + this.endpoint + id;

      return this.http.request(new HttpRequest(
          'GET',
          url,
          null,
          {
              reportProgress: true,
              responseType: 'blob'
          }));
  }
}
