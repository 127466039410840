<div class="d-flex justify-content-center" *ngIf="load">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="card" *ngIf="notasDeDebitos != undefined && notasDeDebitos.length">
    <div class="card-header mb-2">
       <h3>Notas de débito geradas</h3>
    </div>
    <div class="card-body">
        <div class="table-responsive">                        
            <table class="table table-striped table-bordered card-table">
                <thead>
                    <tr>                        
                        <th class="text-nowrap text-center align-middle">
                            Código
                        </th>
                        <th class="text-nowrap text-center align-middle">
                            Cliente
                        </th>
                        <th class="text-nowrap text-center align-middle">
                            Valor
                        </th>
                        <th class="text-nowrap text-center align-middle">
                            Data
                        </th> 
                        <th>

                        </th>                       
                    </tr>
                </thead>
                <tbody>
                    <tr class="tabela" *ngFor="let nota of notasDeDebitos">                        
                        <td>
                            {{nota.idExterno}}
                        </td>
                        <td>
                            {{nota.nomeCliente}}
                        </td>
                        <td>
                            {{nota.valorDebito | currency: 'R$'}}
                        </td>
                        <td>
                            {{nota.dataCriacao | date:'dd/MM/yyyy'}}
                        </td>    
                        <td class="text-center">
                            <i class="btn btn-xs icon-btn btn-primary">
                                <span class="fas fa-download" (click)="exportPdf(nota)" ></span>
                            </i>   
                        </td>                 
                    </tr>
                </tbody>
            </table>            
        </div>
    </div>
</div>