import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/utils/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { HonorarioService } from 'src/app/services/honorario.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { HonorariosService } from '../honorarios.service';

@Component({
    selector: 'honorarios-listar',
    templateUrl: './honorarios-listar.component.html',
    styleUrls: ['./honorarios-listar.component.scss']
})
export class HonorariosListarComponent implements OnInit {

    public tipo: string;
    public tipoHonorario: string;
    public referenciaId: string;
    public atendimentoId: string;
    public raiz:string;
    public processoJudicialId: string;
    public honorarios: any;

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    constructor(
        private honorarioService: HonorarioService,
        private router: Router,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,        
        public arquivoService: ArquivoService,
        public toastrService: ToastrService,        
        public utilsService: UtilsService,
        private location: Location,
        private honorariosService: HonorariosService
        ) { }

    ngOnInit(): void {
        this.tipo = this.route.snapshot.params['tipo'];
        this.tipoHonorario = this.route.snapshot.params['tipoHonorario'];
        this.referenciaId = this.route.snapshot.params['referenciaId'];
        this.atendimentoId = this.route.snapshot.params['atendimentoId'];
        this.processoJudicialId = this.route.snapshot.params['processoJudicialId'];

        if (this.router.url.indexOf('/dashboard/') > -1) 
        {
            this.raiz = '/dashboard'
        }else{
            this.raiz = '/operacionais'
        }

        if (this.atendimentoId == undefined) this.atendimentoId = null;

        this.buscarHonorarios();
    }

    cadastrarNotaDeDespesa(){
        let honorariosParaFaturamento = this.honorarios.filter(c=>c.faturar && !c.faturamento && c.faturamentoId == null);
        debugger;
        if(honorariosParaFaturamento == undefined){
            this.swalWithBootstrapButtons.fire({
                text: 'Selecione uma despesa para gerar a nota de débito!',
                confirmButtonColor: '#FFD950 !important;',
                showCloseButton: true,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok'
            });

            return;
        }

        let honorariosParaFaturarGuid = [];

        honorariosParaFaturamento.forEach(element => {
            honorariosParaFaturarGuid.push(element.id);
        });

        this.spinner.show();

        this.honorarioService.cadastrarFaturamento(honorariosParaFaturarGuid)
            .subscribe(res=>{
                this.buscarHonorarios();                
            }, error=>{
                this.spinner.hide();
                this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
            });
    }

    buscarHonorarios() {
        this.honorarioService.buscarTodos(this.tipoHonorario, this.tipo, this.referenciaId)
            .subscribe(res=>{
                this.honorarios = res.data;

                this.honorarios.forEach(honorario => {
                    honorario.valorHonorario = honorario.valorHonorario.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
                    honorario.faturar = false;
                });
                this.spinner.hide();
            })
    }

    handleDownloadArquivo(arquivo: any) {
        this.spinner.show();
        try {
            this.arquivoService.downloadArquivo(arquivo.arquivoId).subscribe(
                data => {
                    if (data.type == 3) {
                        this.spinner.hide();
                    }
                    this.utilsService.renderDownload(data, arquivo.nomeArquivo as string);
                }
            );
        } catch {
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }

    voltar(){
        this.location.back();
    }

    obterNome(){
        return this.honorariosService.obterNome(this.tipoHonorario);
    }
}
