import { Component, OnInit } from "@angular/core";
import { CATEGORIAS } from "./categorias";
import { BehaviorSubject } from "rxjs";
import { FormBuilder } from "@angular/forms";
import { CurrencyPipe } from "@angular/common";
import { ConfigFormFilter } from "src/app/views/shared/advanced-search-filter/filter";

enum FORM_FILTER {
  V_MINIMO = "valorMinimo",
  V_MAXIMO = "valorMaximo",
  RESPONSAVEL = "responsavel",
  CENTRO_CUSTO = "centroCusto",
  DT_INICIAL = "dtInicial",
  DT_FINAL = "dtFinal",
  DT_TYPE = "dtType",
  SHOW = "show",
}

@Component({
  selector: "app-lancamentos",
  templateUrl: "./lancamentos.component.html",
  styleUrls: ["./lancamentos.component.scss"],
})
export class LancamentosComponent implements OnInit {
  public expandTest: boolean = false;

  readonly FORM_FILTER = FORM_FILTER;

  public filterTypes = [
    {
      id: 1,
      label: "Todos",
      selected: true,
    },
    {
      id: 2,
      label: "A pagar / A receber",
      selected: false,
    },
    {
      id: 3,
      label: "Pagos e recebidos",
      selected: false,
    },
  ];

  /** accounts filter*/
  public standardAccounts = new BehaviorSubject([
    { name: "Conta padrão", objectId: "CP" },
    { name: "Todas", objectId: "T" },
  ]);

  /** categories filter */
  public categoriasFiltered = new BehaviorSubject(CATEGORIAS);
  public categoriasOriginal = CATEGORIAS;
  public searchCategory: string;

  /** form filter */
  public formFilter = this.fb.group({
    [FORM_FILTER.V_MINIMO]: "",
    [FORM_FILTER.V_MAXIMO]: "",
    [FORM_FILTER.RESPONSAVEL]: "",
    [FORM_FILTER.CENTRO_CUSTO]: "",
    [FORM_FILTER.DT_TYPE]: "",
    [FORM_FILTER.DT_INICIAL]: "",
    [FORM_FILTER.DT_FINAL]: "",
    [FORM_FILTER.SHOW]: "",
  });

  public configFormFilter: ConfigFormFilter[] = [
    {
        label: 'Valor Mínimo',
        placeholder: 'R$ 0,00',
        controlName: FORM_FILTER.V_MINIMO,
        col: '2',
        isCurrency: true,
    },
    {
        label: 'Valor Máximo',
        placeholder: 'R$ 0,00',
        controlName: FORM_FILTER.V_MAXIMO,
        col: '2',
        isCurrency: true,
    },
    {
        label: 'Responsável',
        placeholder: 'Digite o nome do responsável',
        controlName: FORM_FILTER.RESPONSAVEL,
        col: '4',
        isText: true,
    },
    {
        label: 'Centro de custo',
        placeholder: 'Digite o centro de custo',
        controlName: FORM_FILTER.CENTRO_CUSTO,
        col: '4',
        isText: true,
    }
  ]

  constructor(private fb: FormBuilder, private currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {}

  selectFilterTypes(id: number) {
    this.filterTypes = this.filterTypes.map((item) =>
      item.id === id
        ? { ...item, selected: true }
        : { ...item, selected: false }
    );
  }

  getBgCategorie(color: string) {
    return `background: ${color}`;
  }

  onSearchCategory() {
    this.categoriasFiltered.next(
      this.categoriasOriginal.filter((c) =>
        c.name.toLowerCase().includes(this.searchCategory.toLowerCase())
      )
    );
  }
}
