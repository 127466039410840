<ng-select 
    [items]="acoes" 
    [loading]="loadAcoes"
    [multiple]="selecionaMultiplos"
    [(ngModel)]="acaoId" 
    [ngModelOptions]="{standalone: true}"
    bindLabel="nome" 
    bindValue="id" 
    placeholder="Digite a ação" 
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    clearAllText="Limpar seleção"
    (keyup)="filtrar($event)"
    (click)="changeAcao($event)"
    >
</ng-select>
