import { ProcessosService } from './../../../../services/processos.service';
import { NotificacaoService } from './../../../../services/notificacao.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-modal-notificacoes',
  templateUrl: './modal-notificacoes.component.html',
  styleUrls: ['./modal-notificacoes.component.css']
})
export class ModalNotificacoesComponent implements OnInit {

  modalRef: NgbModalRef;
  processoId:any;
  idExterno:any = "";
  cobradorId:any;


  public loadingSoft:Boolean = false;
  public loadingMed:Boolean = false;
  public loadingHard:Boolean = false;

  constructor(
    public notificacaoService: NotificacaoService,
    public toastrService: ToastrService,
    public processoService:ProcessosService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.processoId = this.modalRef.componentInstance.processoId;

    this.processoService.getById(this.processoId).subscribe(x => {
      this.idExterno = x.data.idExterno.toString();
      this.cobradorId = x.data.cobradorId;
    })

  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }


  enviarNotificacaoHard() {

    this.loadingHard = true;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Terceira Notificação: Exigencia de pagamento',
      text:'Tem certeza que deseja enviar uma Exigencia de Pagamento ao devedor ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){
      this.notificacaoService.EnviarNotificacaoHard(this.processoId).subscribe( res =>{
        //toast
        this.toastrService.success("Terceira Notificação: Exigencia de Pagamento Gerada!", "Success")
        this.loadingHard = false;
      },error=>{
        this.loadingHard = false;
        this.toastrService.error("Terceira Notificação: Exigencia de Pagamento Gerada!", "Error")
      });
    }else{
      this.loadingHard = false;
    }

  });

  }

  enviarNotificacaoHardAviso() {


    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Atenção',
      text:'Somente enviar esta notificação quando houver certeza que os débitos estão em aberto,deseja prosseguir ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){
      this.enviarNotificacaoHard();
    }else{

    }

  });


  }


  enviarNotificacaoMed() {

    this.loadingMed = true;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Segunda Notificação: Cobrança de pagamento',
      text:'Tem certeza que deseja enviar uma Cobrança de Pagamento ao devedor ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){
      this.notificacaoService.EnviarNotificacaoMed(this.processoId).subscribe( res =>{
        console.log(res);
        this.toastrService.success("Segunda Notificação: Cobrança de pagamento Gerada!", "Success")
        this.loadingMed = false;
      },error=>{
        this.loadingMed = false;
        this.toastrService.error("Segunda Notificação: Cobrança de pagamento Gerada!", "Error")
      });
    }else{
      this.loadingMed = false;
    }

  });
  }



  enviarNotificacaoSoft() {

    this.loadingSoft = true;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Primeira Notificação: Aviso de Débito',
      text:'Tem certeza que deseja enviar um Aviso de Débito ao devedor ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){

      if(this.cobradorId == null || this.cobradorId == undefined)
      {
        this.loadingSoft = false;
        this.toastrService.error("Esse processo ainda nao tem um cobrador definido", "Error")

      }else{
        this.notificacaoService.EnviarNotificacaoSoft(this.processoId,this.cobradorId).subscribe( res =>{
          //toast
          this.toastrService.success("Primeira Notificação: Aviso de Débito Gerada!", "Success")
          this.loadingSoft = false;
        },error=>{
          this.loadingSoft = false;
          this.toastrService.error("Notificação: Exigencia de Pagamento Gerada!", "Error")
        });
      }


    }else{
      this.loadingSoft = false;
    }

  });


  }



  downloadTemplateSoft() {
    this.notificacaoService.downloadSoft(this.processoId).subscribe(
        data => {
            this.utilsService.renderDownload(data, "template primeira notificação.docx" as string);
        }
    );
  }

  downloadTemplateMed() {
    this.notificacaoService.downloadMed(this.processoId).subscribe(
        data => {
            this.utilsService.renderDownload(data, "template segunda notificação.docx" as string);
        }
    );
  }

  downloadTemplateHard() {
    this.notificacaoService.downloadHard(this.processoId).subscribe(
        data => {
            this.utilsService.renderDownload(data, "template terceira notificação.docx" as string);
        }
    );
  }

}
