import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { DatePipe } from "@angular/common";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { CalendarioService } from "src/app/services/calendario.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ProcessosJudiciaisHistoricoManualService } from 'src/app/services/processos-judiciais-historico-manual.service';
import { ProcessosJudiciaisOcorrenciaAudienciaService } from 'src/app/services/processos-judiciais-ocorrencia-audiencia.service';
import { ProcessosJudiciaisOcorrenciaEventoService } from 'src/app/services/processos-judiciais-ocorrencia-evento.service';
import { ProcessosJudiciaisOcorrenciaPrazoService } from 'src/app/services/processos-judiciais-ocorrencia-prazo.service';
import { ProcessosJudiciaisOcorrenciaTarefaService } from 'src/app/services/processos-judiciais-ocorrencia-tarefa.service';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import Swal from 'sweetalert2';
import { ModalConcluirAtividadeComponent } from '../../area-trabalho/modal-concluir-atividade/modal-concluir-atividade.component';


export enum RescheduleType {
    TODAY = "today",
    TOMORROW = "tomorrow",
    SOMEDAY = "someDay",
    NEXT_MONDAY = "nextMonday",
  }

@Component({
    selector: 'app-modal-detalhe',
    templateUrl: './modal-detalhe.component.html',
    styleUrls: ['./modal-detalhe.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ModalDetalheComponent implements OnInit, AfterViewInit {

    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

    private modalRef: NgbModalRef;
    public id: Guid;
    public tipoOcorrencia: number;
    public tipoStatusAtividade: number;
    public descricao: string;
    public tipoObjeto: string;
    public responsavelNome: string;
    public dataInicioPrazo: string;
    public estilo: string;
    public corAlertaData: string;
    public numeroProcesso: string;
    public item: any;
    public processo: any;
    public exibirAcoes: boolean = true;
    public buscarTarefaDoUsuario: any;
    public abaSelecionada: number = 0;

    constructor(
        private processosJudiciaisOcorrenciaEventoService: ProcessosJudiciaisOcorrenciaEventoService,
        private processosJudiciaisOcorrenciaPrazoService: ProcessosJudiciaisOcorrenciaPrazoService,
        private processosJudiciaisOcorrenciaAudienciaService: ProcessosJudiciaisOcorrenciaAudienciaService,
        private processosJudiciaisHistoricoManualService: ProcessosJudiciaisHistoricoManualService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
        private ngbModal: NgbModal,
        private spinner: NgxSpinnerService,
        private processosJudiciaisService: ProcessosJudiciaisService,
        private processosJudiciaisOcorrenciaTarefaService: ProcessosJudiciaisOcorrenciaTarefaService,
        private calendarioService: CalendarioService, 
        public datepipe: DatePipe, 
        private ocorrenciaService: ProcessosJudiciaisOcorrenciasService,
    ) { }

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    public readonly RESCHEDULE_TYPE = RescheduleType;
    public rescheduleDay: Date = null;
    public today = new Date();
    public dataRevisaoComponent;
    public dataLimiteComponent;

    ngOnInit(): void {
        this.buscarProcesso();
    }

    get tomorrow() {
        const tomorrow = new Date();
        tomorrow.setDate(this.today.getDate() + 1);
        return tomorrow;
      }
    
      get todayFormatted() {
        const today = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
        return today;
    }
    
      get nextMonday() {
        const dayOfWeek = this.today.getDay();
        const daysUntilNextMonday = dayOfWeek === 0 ? 1 : 8 - dayOfWeek;
        const nextMonday = new Date(this.today);
        nextMonday.setDate(this.today.getDate() + daysUntilNextMonday);
        return nextMonday;
      }

    ngAfterViewInit(): void {
        this.tabGroup.selectedIndex = this.abaSelecionada;
    }

    buscarProcesso() {
        this.processosJudiciaisService.getById(this.item.processoJudicialId)
            .subscribe(res => {
                this.processo = res.data;
            })
    }
    
    saveDate(type: string, event) {
        this.spinner.show();

        switch (type) {
          case RescheduleType.TODAY:
            this.rescheduleDay = this.today;
            break;
          case RescheduleType.TOMORROW:
            this.rescheduleDay = this.tomorrow;
            break;
        case RescheduleType.SOMEDAY:
            const dateSchedule = (event as MatDatepickerInputEvent<Date>).value;
            this.rescheduleDay = dateSchedule;
            break;
          case RescheduleType.NEXT_MONDAY:
            this.rescheduleDay = this.nextMonday;
            break;
        }
        this.calendarioService
          .calcularDiaUtilNegativo(-3, this.rescheduleDay)
          .subscribe((res) =>{
            this.formatDateComponent(new Date(res.data), this.rescheduleDay)
            const atividade = {
                id: this.item.id,
                dataRevisao: this.dataRevisaoComponent,
                dataLimite: this.dataLimiteComponent,
                tipoOcorrencia: this.item.tipoOcorrencia
            }
            this.ocorrenciaService.atualizarDataAtividade(atividade).subscribe(res => {
                this.item.dataLimite = this.dataLimiteComponent;
                if(this.item.tipoOcorrencia == 4){
                    this.item.dataRevisao = this.dataRevisaoComponent;
                }
                this.buscarTarefaDoUsuario.emit(atividade);
                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.swalWithBootstrapButtons.fire(error.errors[0], 'Atenção', "error");
                }
                else {
                    this.swalWithBootstrapButtons.fire('Não foi possível alterar data!', 'Atenção', "error");
                }
                this.spinner.hide();
            });
      }, error => {
        this.swalWithBootstrapButtons.fire(error.errors[0], 'Atenção', "error");
        this.spinner.hide();
      }
      );
    
      }
    
      formatDateComponent(reviewDate: Date, deadline: Date) {
        this.dataRevisaoComponent = new Date(reviewDate.getFullYear(), reviewDate.getMonth() , reviewDate.getDate())
        this.dataLimiteComponent = new Date(deadline.getFullYear(), deadline.getMonth() , deadline.getDate())
    
      }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    sairModal(){
        this.dismiss();
    }

    onIniciarAtividade(item) {
        if (item.tipoOcorrencia != 1 && item.tipoOcorrencia != 4 && item.tipoOcorrencia != 5) {
            this.swalWithBootstrapButtons.fire(
                'Não foi possível iniciar!',
                '',
                'error'
            );

            return;
        }

        

        let request: Observable<any>;

        switch (item.tipoOcorrencia) {
            case 1:
                request = this.processosJudiciaisOcorrenciaTarefaService.iniciarExecucaoAtividade(item.id);
                break;
            case 4:
                request = this.processosJudiciaisOcorrenciaPrazoService.iniciarExecucaoAtividade(item.id);
                break;
            case 5:
                request = this.processosJudiciaisOcorrenciaAudienciaService.iniciarExecucaoAtividade(item.id);
                break;
        }

        request.subscribe(res => {
            this.item = res.data;
            item = res.data;
            debugger;
            this.buscarTarefaDoUsuario.emit(res.data);
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
            this.swalWithBootstrapButtons.fire(
                'Não foi possível iniciar!',
                '',
                'error'
            );
        });
    }

    defineClass(classe: string) {
        return 'badge ' + classe;
    }

    onEnviarParaRevisao(atividade: any) {
        let request: Observable<any>;

        this.swalWithBootstrapButtons.fire({
            text: 'Deseja enviar para revisão essa atividade?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value != undefined && result.value == true) {
                this.spinner.show();
                switch (atividade.tipoOcorrencia) {
                    case 1:
                        request = this.processosJudiciaisOcorrenciaTarefaService.enviarParaRevisao(atividade.id);
                        break;
                    case 3:
                        request = this.processosJudiciaisOcorrenciaEventoService.enviarParaRevisao(atividade.id);
                        break;
                    case 4:
                        request = this.processosJudiciaisOcorrenciaPrazoService.enviarParaRevisao(atividade.id);
                        break;
                    case 5:
                        request = this.processosJudiciaisOcorrenciaAudienciaService.enviarParaRevisao(atividade.id);
                        break;
                }

                request.subscribe(res => {
                    this.item = res.data;
                    this.buscarTarefaDoUsuario.emit(this.item);
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    this.swalWithBootstrapButtons.fire(
                        'Não foi possível enviar!',
                        '',
                        'error'
                    );
                })
            }
        });
    }

    onLiberarProtocolarAtividade(atividade: any) {
        let request: Observable<any>;

        this.spinner.show();
        switch (atividade.tipoOcorrencia) {
            case 1:
                request = this.processosJudiciaisOcorrenciaTarefaService.liberarProtocolar(atividade.id);
                break;
            case 4:
                request = this.processosJudiciaisOcorrenciaPrazoService.liberarProtocolar(atividade.id);
                break;
        }

        request.subscribe(res => {
            this.item = res.data;
            this.buscarTarefaDoUsuario.emit(this.item);
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
            this.swalWithBootstrapButtons.fire(
                'Não foi possível enviar!',
                '',
                'error'
            );
        });

        // if (item.tipoOcorrencia == 1) {
        //     this.processosJudiciaisOcorrenciaTarefaService.liberarProtocolar(item.id)
        //         .subscribe(res => {
        //             this.close();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else {
        //     this.processosJudiciaisOcorrenciaPrazoService.liberarProtocolar(item.id)
        //         .subscribe(res => {
        //             this.close();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // }
    }

    onDevolverAtividade(atividade: any) {

        let request: Observable<any>;

        this.spinner.show();
        switch (atividade.tipoOcorrencia) {
            case 1:
                request = this.processosJudiciaisOcorrenciaTarefaService.devolverParaAjuste(atividade.id);
                break;
            case 4:
                request = this.processosJudiciaisOcorrenciaPrazoService.devolverParaAjuste(atividade.id);
                break;
            case 5:
                request = this.processosJudiciaisOcorrenciaAudienciaService.devolverParaAjuste(atividade.id);
                break;
        }

        request.subscribe(res => {
            this.item = res.data;
            this.buscarTarefaDoUsuario.emit(this.item);
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
            this.swalWithBootstrapButtons.fire(
                'Não foi possível devolver!',
                '',
                'error'
            );
        });
        // this.spinner.show();

        // if (item.tipoOcorrencia == 1) {
        //     this.processosJudiciaisOcorrenciaTarefaService.devolverParaAjuste(item.id)
        //         .subscribe(res => {
        //             //this.close();
        //             this.item.tipoStatusAtividade = 4;
        //             this.item.tipoStatusAtividadeNome = "Devolvido para ajuste";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível devolver!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else if (item.tipoOcorrencia == 4) {
        //     this.processosJudiciaisOcorrenciaPrazoService.devolverParaAjuste(item.id)
        //         .subscribe(res => {
        //             //this.close();
        //             this.item.tipoStatusAtividade = 4;
        //             this.item.tipoStatusAtividadeNome = "Devolvido para ajuste";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível devolver!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else if (item.tipoOcorrencia == 5) {
        //     this.processosJudiciaisOcorrenciaAudienciaService.devolverParaAjuste(item.id)
        //         .subscribe(res => {
        //             //this.close();
        //             this.item.tipoStatusAtividade = 4;
        //             this.item.tipoStatusAtividadeNome = "Devolvido para ajuste";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível devolver!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else {
        //     this.swalWithBootstrapButtons.fire(
        //         'Não foi possível devolver!',
        //         '',
        //         'error'
        //     );
        // }
    }

    onRevisadoAtividade(atividade) {

        let request: Observable<any>;

        this.spinner.show();
        switch (atividade.tipoOcorrencia) {
            case 1:
                request = this.processosJudiciaisOcorrenciaTarefaService.revisadoELiberado(atividade.id);
                break;
            case 4:
                request = this.processosJudiciaisOcorrenciaPrazoService.revisadoELiberado(atividade.id);
                break;
            case 5:
                request = this.processosJudiciaisOcorrenciaAudienciaService.revisadoELiberado(atividade.id);
                break;
        }

        request.subscribe(res => {
            this.item = res.data;
            this.buscarTarefaDoUsuario.emit(this.item);
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
            this.swalWithBootstrapButtons.fire(
                'Não foi possível mudar o status para revisado e liberado!',
                '',
                'error'
            );
        });
        // this.spinner.show();

        // if (item.tipoOcorrencia == 1) {
        //     this.processosJudiciaisOcorrenciaTarefaService.revisadoELiberado(item.id)
        //         .subscribe(res => {
        //             //this.close();
        //             this.item.tipoStatusAtividade = 5;
        //             this.item.tipoStatusAtividadeNome = "Revisado e liberado";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else if (item.tipoOcorrencia == 4) {
        //     this.processosJudiciaisOcorrenciaPrazoService.revisadoELiberado(item.id)
        //         .subscribe(res => {
        //             this.item.tipoStatusAtividade = 5;
        //             this.item.tipoStatusAtividadeNome = "Revisado e liberado";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else if (item.tipoOcorrencia == 5) {
        //     this.processosJudiciaisOcorrenciaAudienciaService.revisadoELiberado(item.id)
        //         .subscribe(res => {
        //             this.item.tipoStatusAtividade = 5;
        //             this.item.tipoStatusAtividadeNome = "Revisado e liberado";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else {
        //     this.swalWithBootstrapButtons.fire(
        //         'Não foi possível mudar o status!',
        //         '',
        //         'error'
        //     );
        // }
    }

    onEncerrarRevisao(atividade: any) {
        let request: Observable<any>;
        debugger;
        this.spinner.show();
        switch (atividade.tipoOcorrencia) {
            case 1:
                request = this.processosJudiciaisOcorrenciaTarefaService.encerrarAtividade(atividade.id);
                break;
            case 4:
                request = this.processosJudiciaisOcorrenciaPrazoService.encerrarAtividade(atividade.id);
                break;
            case 5:
                request = this.processosJudiciaisOcorrenciaAudienciaService.encerrarAtividade(atividade.id);
                break;
        }

        request.subscribe(res => {
            this.item = res.data;
            this.buscarTarefaDoUsuario.emit(this.item);
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
            this.swalWithBootstrapButtons.fire(
                'Não foi possível mudar o status para encerrado!',
                '',
                'error'
            );
        });
        // this.spinner.show();

        // if (item.tipoOcorrencia == 1) {
        //     this.processosJudiciaisOcorrenciaTarefaService.encerrarAtividade(item.id)
        //         .subscribe(res => {
        //             this.item.tipoStatusAtividade = 8;
        //             this.item.tipoStatusAtividadeNome = "Encerrado";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else if (item.tipoOcorrencia == 4) {
        //     this.processosJudiciaisOcorrenciaPrazoService.encerrarAtividade(item.id)
        //         .subscribe(res => {
        //             this.item.tipoStatusAtividade = 8;
        //             this.item.tipoStatusAtividadeNome = "Encerrado";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else if (item.tipoOcorrencia == 5) {
        //     this.processosJudiciaisOcorrenciaAudienciaService.encerrarAtividade(item.id)
        //         .subscribe(res => {
        //             this.item.tipoStatusAtividade = 8;
        //             this.item.tipoStatusAtividadeNome = "Encerrado";
        //             this.buscarTarefaDoUsuario.emit(this.item);
        //             this.spinner.hide();
        //         }, error => {
        //             this.spinner.hide();
        //             this.swalWithBootstrapButtons.fire(
        //                 'Não foi possível mudar o status!',
        //                 '',
        //                 'error'
        //             );
        //         });
        // } else {
        //     this.swalWithBootstrapButtons.fire(
        //         'Não foi possível mudar o status!',
        //         '',
        //         'error'
        //     );
        // }
    }

    concluirAtividade(item: any) {
        const modalRef = this.ngbModal.open(ModalConcluirAtividadeComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'processo-judiciais-detalhes';
        modalRef.componentInstance.concluirItem(item);


        modalRef.result.then(result => {
            //this.close();
            this.item.tipoStatusAtividade = 7;
            this.item.tipoStatusAtividadeNome = "Concluído";
            this.buscarTarefaDoUsuario.emit(this.item);
            this.spinner.hide();
        }).catch(error => { });
    }

    onExcluirAtiviade(atividade: any) {
        let request: Observable<any>;

        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir essa atividade?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value != undefined && result.value == true) {
                this.spinner.show();
                switch (atividade.tipoOcorrencia) {
                    case 1:
                        request = this.processosJudiciaisOcorrenciaTarefaService.delete(atividade.id);
                        break;
                    case 2:
                        request = this.processosJudiciaisHistoricoManualService.delete(atividade.id);
                        break;
                    case 3:
                        request = this.processosJudiciaisOcorrenciaEventoService.delete(atividade.id);
                        break;
                    case 4:
                        request = this.processosJudiciaisOcorrenciaPrazoService.delete(atividade.id);
                        break;
                    case 5:
                        request = this.processosJudiciaisOcorrenciaAudienciaService.delete(atividade.id);
                        break;
                }

                request.subscribe(res => {
                    this.close();
                    this.swalWithBootstrapButtons.fire(
                        'Removido com sucesso!',
                        '',
                        'success'
                    );
                }, error => {
                    this.spinner.hide();
                    this.swalWithBootstrapButtons.fire(
                        'Não foi possível remover!',
                        '',
                        'error'
                    );
                })
            }
        });
    }
}