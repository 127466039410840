import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { ProcessosJudiciaisHistoricoManualService } from "src/app/services/processos-judiciais-historico-manual.service";
import { ProcessosJudiciaisOcorrenciaAudienciaService } from "src/app/services/processos-judiciais-ocorrencia-audiencia.service";
import { ProcessosJudiciaisOcorrenciaEventoService } from "src/app/services/processos-judiciais-ocorrencia-evento.service";
import { ProcessosJudiciaisOcorrenciaPrazoService } from "src/app/services/processos-judiciais-ocorrencia-prazo.service";
import { ProcessosJudiciaisOcorrenciaTarefaService } from "src/app/services/processos-judiciais-ocorrencia-tarefa.service";
import { ProcessosJudiciaisOcorrenciasService } from "src/app/services/processos-judiciais-ocorrencias.service";
import { ProcessosJudiciaisRecursoDesdobramentoService } from "src/app/services/processos-judiciais-recurso-desdobramento.service";
import { ProcessosJudiciaisService } from "src/app/services/processos-judiciais.service";
import Swal from "sweetalert2";
import { ModalDetalheComponent } from "../../dashboard/agenda/modal-detalhe/modal-detalhe.component";
import { ModalConcluirAtividadeComponent } from "../../dashboard/area-trabalho/modal-concluir-atividade/modal-concluir-atividade.component";
import { ModalOcorrenciaComponent } from "../../operacionais/processos-judiciais/processos-judiciais-detalhes/modal-ocorrencia/modal-ocorrencia.component";

@Component({
    selector: "app-opcoes-processo",
    templateUrl: "./opcoes-processo.component.html",
    styleUrls: ["./opcoes-processo.component.scss"],
})
export class OpcoesProcessoComponent
    implements OnInit, ICellRendererAngularComp {
    @Input() item;
    @Input() items = [];
    @Input() isGrid = false;
    @Input() viewOnly = false;
    @Input() resultConfirmDialog$;
    @Input() bulkAction = false;
    @Input() showReschedule = false;    

    @Output() buscarTarefaDoUsuario = new EventEmitter();
    @Output() changeRefresh = new EventEmitter();

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-padrao btn-lg mr-3",
            cancelButton: "btn btn-default btn-lg",
        },
        buttonsStyling: false,
    });

    buscar;

    constructor(
        private processosJudiciaisOcorrenciaTarefaService: ProcessosJudiciaisOcorrenciaTarefaService,
        private processosJudiciaisOcorrenciaEventoService: ProcessosJudiciaisOcorrenciaEventoService,
        private processosJudiciaisOcorrenciaPrazoService: ProcessosJudiciaisOcorrenciaPrazoService,
        private processosJudiciaisOcorrenciaAudienciaService: ProcessosJudiciaisOcorrenciaAudienciaService,
        private processosJudiciaisHistoricoManualService: ProcessosJudiciaisHistoricoManualService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
        private ngbModal: NgbModal,
        private spinner: NgxSpinnerService,
        private processosJudiciaisService: ProcessosJudiciaisService
    ) { }

    ngOnInit(): void {
        console.log(this.item);
        if (this.items.length == 0 && this.item != undefined) {
            this.items.push(this.item);
        }
    }

    disabled() {
        return this.items.every(
            (item) => item.tipoStatusAtividadeNome === "Encerrado" || !item.isEdit
        );
    }

    agInit(params): void {
        this.item = params.item;
        this.items = [params.item];
        this.resultConfirmDialog$ = params.resultConfirmDialog$;
        this.isGrid = params.isGrid;
        this.viewOnly = params.viewOnly;
        this.buscar = params.buscarTarefaDoUsuario;
    }

    refresh(params) {
        return false;
    }

    openModalDetalhesAtividade() {
        if (this.disabled()) return;

        const data = this.items[0];
        this.processosJudiciaisService
            .getById(data.processoJudicialId)
            .subscribe((processo) => {
                const modalRef = this.ngbModal.open(ModalDetalheComponent, {
                    size: "lg",
                    backdrop: "static",
                    keyboard: false,
                });

                modalRef.componentInstance.modalRef = modalRef;
                modalRef.componentInstance.id = data.id;
                modalRef.componentInstance.tipoOcorrencia = data.tipoOcorrencia;
                modalRef.componentInstance.descricao = data.title;
                modalRef.componentInstance.tipoObjeto = data.tipoObjeto;
                modalRef.componentInstance.responsavelNome = data.responsavelNome;
                modalRef.componentInstance.dataInicioPrazo = data.dataInicioPrazo;
                modalRef.componentInstance.estilo = data.estilo;
                modalRef.componentInstance.corAlertaData = data.corAlertaData;
                modalRef.componentInstance.numeroProcesso = processo.data.numero;

                modalRef.result
                    .then((result) => {
                        this.resultConfirmDialog$.next(result);
                        if (this.isGrid) return this.buscar();

                        this.buscarTarefaDoUsuario.emit();
                    })
                    .catch((error) => { });
            });
    }

    onAbrirAtividade() {

        //if (this.disabled()) return;    

        const atividade = this.item;

        this.spinner.show();
        this.processosJudiciaisOcorrenciasService
            .getById(atividade.processoJudicialOcorrenciaId)
            .subscribe((rs) => {
                let ocorrencia = rs.data;
                this.processosJudiciaisOcorrenciasService
                    .obterOcorrenciaComAtividade(
                        ocorrencia.id,
                        atividade.tipoOcorrencia,
                        atividade.id
                    )
                    .subscribe((oc) => {
                        if (oc.success) {
                            this.processosJudiciaisRecursoDesdobramentoService
                                .obterPorIdComDetalhes(
                                    oc.data.processoJudicialRecursoDesdobramentoId
                                )
                                .subscribe((res) => {
                                    if (res.success) {
                                        const modalRef = this.ngbModal.open(
                                            ModalOcorrenciaComponent,
                                            {
                                                size: "lg",
                                                backdrop: "static",
                                                keyboard: false,
                                            }
                                        );

                                        let recursoDesdobramento = res.data;

                                        modalRef.componentInstance.modalRef = modalRef;
                                        modalRef.componentInstance.page =
                                            "processo-judiciais-detalhes";
                                        modalRef.componentInstance.recursoDesdobramento =
                                            recursoDesdobramento;
                                        modalRef.componentInstance.editarAtividade(
                                            ocorrencia.id,
                                            oc.data.detalhes[0]
                                        );

                                        modalRef.result
                                            .then((result) => {
                                                this.resultConfirmDialog$.next(result);
                                                if (this.isGrid) {
                                                    this.buscar();
                                                } else {
                                                    this.buscarTarefaDoUsuario.emit();
                                                }
                                                this.changeRefresh.emit(true);
                                            })
                                            .catch((error) => { });
                                    }
                                });
                        }
                    });
            });
    }

    onEnviarParaRevisao() {
        if (this.disabled()) return;

        let request: Observable<any>;

        this.swalWithBootstrapButtons
            .fire({
                text: "Deseja enviar para revisão essa atividade?",
                confirmButtonColor: "#FFD950 !important;",
                showCloseButton: true,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Cancelar",
            })
            .then((result) => {                
                if (result.value != undefined && result.value == true) {
                    this.spinner.show();

                    this.items.forEach((atividade) => {
                        debugger;
                        switch (atividade.tipoOcorrencia) {
                            case 1:
                                request =
                                    this.processosJudiciaisOcorrenciaTarefaService.enviarParaRevisao(
                                        atividade.id
                                    );
                                break;
                            case 3:
                                request =
                                    this.processosJudiciaisOcorrenciaEventoService.enviarParaRevisao(
                                        atividade.id
                                    );
                                break;
                            case 4:
                                request =
                                    this.processosJudiciaisOcorrenciaPrazoService.enviarParaRevisao(
                                        atividade.id
                                    );
                                break;
                            case 5:
                                request =
                                    this.processosJudiciaisOcorrenciaAudienciaService.enviarParaRevisao(
                                        atividade.id
                                    );
                                break;
                        }

                        request.subscribe(
                            (res) => {
                                if (this.isGrid) {
                                    this.buscar();
                                } else {
                                    atividade.tipoStatusAtividade = 3;
                                    atividade.tipoStatusAtividadeNome = "Em revisão";
                                    this.buscarTarefaDoUsuario.emit(atividade);
                                }

                                this.spinner.hide();

                                this.swalWithBootstrapButtons.fire(
                                    "Enviado com sucesso!",
                                    "",
                                    "success"
                                );
                            },
                            (error) => {
                                this.spinner.hide();
                                this.swalWithBootstrapButtons.fire(
                                    "Não foi possível enviar!",
                                    "",
                                    "error"
                                );
                            }
                        );
                    });
                }
            });
    }

    atualizarTarefaEvent(event){
        console.log(event)
        this.buscarTarefaDoUsuario.emit(event);
    }

    onExcluirAtiviade() {
        if (this.disabled()) return;
        let request: Observable<any>;

        this.swalWithBootstrapButtons
            .fire({
                text: "Deseja excluir essa atividade?",
                confirmButtonColor: "#FFD950 !important;",
                showCloseButton: true,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Cancelar",
            })
            .then((result) => {
                if (result.value != undefined && result.value == true) {
                    this.spinner.show();
                    this.items.forEach((atividade) => {
                        switch (atividade.tipoOcorrencia) {
                            case 1:
                                request = this.processosJudiciaisOcorrenciaTarefaService.delete(
                                    atividade.id
                                );
                                break;
                            case 2:
                                request = this.processosJudiciaisHistoricoManualService.delete(
                                    atividade.id
                                );
                                break;
                            case 3:
                                request = this.processosJudiciaisOcorrenciaEventoService.delete(
                                    atividade.id
                                );
                                break;
                            case 4:
                                request = this.processosJudiciaisOcorrenciaPrazoService.delete(
                                    atividade.id
                                );
                                break;
                            case 5:
                                request =
                                    this.processosJudiciaisOcorrenciaAudienciaService.delete(
                                        atividade.id
                                    );
                                break;
                        }

                        request.subscribe(
                            (res) => {
                                if (this.isGrid) {
                                    this.buscar();
                                } else {
                                    this.buscarTarefaDoUsuario.emit();
                                }

                                this.swalWithBootstrapButtons.fire(
                                    "Removido com sucesso!",
                                    "",
                                    "success"
                                );
                            },
                            (error) => {
                                this.spinner.hide();
                                this.swalWithBootstrapButtons.fire(
                                    "Não foi possível remover!",
                                    "",
                                    "error"
                                );
                            }
                        );
                    });
                }
            });
    }

    onIniciarAtividade() {
        if (this.disabled()) return;

        this.items.forEach((item) => {
            this.spinner.show();
            if (item.tipoOcorrencia == 1) {
                this.processosJudiciaisOcorrenciaTarefaService
                    .iniciarExecucaoAtividade(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 2;
                            item.tipoStatusAtividadeNome = "Em execução";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível iniciar!",
                                "",
                                "error"
                            );
                        }
                    );
            } else if (item.tipoOcorrencia == 4) {
                this.processosJudiciaisOcorrenciaPrazoService
                    .iniciarExecucaoAtividade(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 2;
                            item.tipoStatusAtividadeNome = "Em execução";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível iniciar!",
                                "",
                                "error"
                            );
                        }
                    );
            } else if (item.tipoOcorrencia == 5) {
                this.processosJudiciaisOcorrenciaAudienciaService
                    .iniciarExecucaoAtividade(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 2;
                            item.tipoStatusAtividadeNome = "Em execução";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível iniciar!",
                                "",
                                "error"
                            );
                        }
                    );
            } else {
                this.swalWithBootstrapButtons.fire(
                    "Não foi possível iniciar!",
                    "",
                    "error"
                );
            }
        });
    }

    concluirAtividade() {
        if (this.disabled()) return;

        this.items.forEach((item) => {
            const modalRef = this.ngbModal.open(ModalConcluirAtividadeComponent, {
                size: "lg",
                backdrop: "static",
                keyboard: false,
            });

            modalRef.componentInstance.modalRef = modalRef;
            modalRef.componentInstance.page = "processo-judiciais-detalhes";
            modalRef.componentInstance.concluirItem(item);

            modalRef.result
                .then((result) => {
                    this.resultConfirmDialog$.next(result);
                    if (this.isGrid) {
                        this.buscar();
                    } else {
                        item.tipoStatusAtividade = 7;
                        item.tipoStatusAtividadeNome = "Concluído";
                        this.buscarTarefaDoUsuario.emit(item);
                        this.spinner.hide();
                    }
                    this.changeRefresh.emit(true);
                })
                .catch((error) => { });
        });
    }

    onDevolverAtividade() {
        if (this.disabled()) return;

        this.spinner.show();
        this.items.forEach((item) => {
            if (item.tipoOcorrencia == 1) {
                this.processosJudiciaisOcorrenciaTarefaService
                    .devolverParaAjuste(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 4;
                            item.tipoStatusAtividadeNome = "Devolvido para ajuste";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível devolver!",
                                "",
                                "error"
                            );
                        }
                    );
            } else if (item.tipoOcorrencia == 4) {
                this.processosJudiciaisOcorrenciaPrazoService
                    .devolverParaAjuste(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 4;
                            item.tipoStatusAtividadeNome = "Devolvido para ajuste";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível devolver!",
                                "",
                                "error"
                            );
                        }
                    );
            }else if (item.tipoOcorrencia == 5) {
                this.processosJudiciaisOcorrenciaAudienciaService
                    .devolverParaAjuste(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 4;
                            item.tipoStatusAtividadeNome = "Devolvido para ajuste";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível devolver!",
                                "",
                                "error"
                            );
                        }
                    );
            }else{
                this.swalWithBootstrapButtons.fire(
                    "Não foi possível devolver!",
                    "",
                    "error"
                );
            }
        });
    }

    onRevisadoAtividade() {
        if (this.disabled()) return;

        this.spinner.show();

        this.items.forEach((item) => {
            if (item.tipoOcorrencia == 1) {
                this.processosJudiciaisOcorrenciaTarefaService
                    .revisadoELiberado(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 5;
                            item.tipoStatusAtividadeNome = "Revisado e liberado";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            } else if (item.tipoOcorrencia == 1) {
                this.processosJudiciaisOcorrenciaPrazoService
                    .revisadoELiberado(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 5;
                            item.tipoStatusAtividadeNome = "Revisado e liberado";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            }else if (item.tipoOcorrencia == 5) {
                this.processosJudiciaisOcorrenciaAudienciaService
                    .revisadoELiberado(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 5;
                            item.tipoStatusAtividadeNome = "Revisado e liberado";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            }else{
                this.swalWithBootstrapButtons.fire(
                    "Não foi possível mudar o status!",
                    "",
                    "error"
                );
            }
        });
    }

    onEncerrarRevisao() {
        if (this.disabled()) return;

        this.spinner.show();
        this.items.forEach((item) => {
            if (item.tipoOcorrencia == 1) {
                this.processosJudiciaisOcorrenciaTarefaService
                    .encerrarAtividade(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 8;
                            item.tipoStatusAtividadeNome = "Encerrado";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            } else if (item.tipoOcorrencia == 4) {
                this.processosJudiciaisOcorrenciaPrazoService
                    .encerrarAtividade(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 8;
                            item.tipoStatusAtividadeNome = "Encerrado";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            }else if (item.tipoOcorrencia == 5) {
                this.processosJudiciaisOcorrenciaAudienciaService
                    .encerrarAtividade(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            item.tipoStatusAtividade = 8;
                            item.tipoStatusAtividadeNome = "Encerrado";
                            this.buscarTarefaDoUsuario.emit(item);
                            this.spinner.hide();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            }else{
                this.swalWithBootstrapButtons.fire(
                    "Não foi possível mudar o status!",
                    "",
                    "error"
                );
            }
        });
    }
    onLiberarProtocolarAtividade() {
        if (this.disabled()) return;

        this.spinner.show();

        this.items.forEach((item) => {
            if (item.tipoOcorrencia == 1) {
                this.processosJudiciaisOcorrenciaTarefaService
                    .liberarProtocolar(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            this.buscarTarefaDoUsuario.emit();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            } else {
                this.processosJudiciaisOcorrenciaPrazoService
                    .liberarProtocolar(item.id)
                    .subscribe(
                        (res) => {
                            if (this.isGrid) return this.buscar();

                            this.buscarTarefaDoUsuario.emit();
                        },
                        (error) => {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                "Não foi possível mudar o status!",
                                "",
                                "error"
                            );
                        }
                    );
            }
        });
    }

    showIniciarAtividade() {
        debugger;
        return this.items.every(
            (item) =>                
                item.tipoOcorrencia !== 2 &&
                item.tipoOcorrencia !== 5 &&
                item.tipoStatusAtividade === 1
        );
    }

    showEnviarParaAnalise() {
        return this.items.every(
            (item) =>
                item.tipoOcorrencia != 2 &&
                item.tipoOcorrencia != 5 &&
                (item.tipoStatusAtividade == 2 || item.tipoStatusAtividade == 4)
        );
    }

    showLiberarProtocolarAtividade() {
        return this.items.every(
            (item) =>
                item.tipoOcorrencia !== 2 &&
                item.tipoOcorrencia !== 5 &&
                item.tipoStatusAtividade === 5
        );
    }

    showConcluirAtividade() {
        return this.items.every(
            (item) =>
                item.tipoOcorrencia != 2 &&                
                (item.tipoStatusAtividade == 5 || item.tipoStatusAtividade == 6)
        );
    }

    showDevolverAtividade() {
        return this.items.every(
            (item) =>
                item.tipoOcorrencia != 2 &&
                item.tipoOcorrencia != 5 &&
                item.tipoStatusAtividade == 3
        );
    }

    showRevisadoAtividade() {
        return this.items.every(
            (item) =>
                item.tipoOcorrencia != 2 &&
                item.tipoOcorrencia != 5 &&
                item.tipoStatusAtividade == 3
        );
    }

    showEncerrarRevisao() {
        return this.items.every(
            (item) =>
                item.tipoOcorrencia != 2 &&
                item.tipoOcorrencia != 5 &&
                item.tipoStatusAtividade == 7
        );
    }
}
