
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { VarasService } from 'src/app/services/varas.service';


@Component({
  selector: 'app-modal-cadastro-varas',
  templateUrl: './modal-cadastro-varas.component.html',
  providers: [VarasService]
})
export class ModalCadastroVarasComponent implements OnInit {


  modalRef: NgbModalRef;
  public vara: any;

  constructor(
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private appService: AppService,
    private varasService: VarasService,
  )
  {

  }

  popularNovaComarca() {
    this.vara = {
        nome: '',
    };
}



  ngOnInit(): void {
    this.popularNovaComarca();
  }

  cadastrar() {
    //if (this.validarCadastroPessoa(this.pessoa)) {
        this.add(this.vara);
    //}
  }

  add(vara) {
    this.spinner.show();
    this.toastrService.clear();

    // var pessoaModelCadastro = this.montarModelPessoaCadastro(pessoa);

    this.varasService.add(vara)
        .subscribe(res => {
            this.toastrService.success("Vara cadastrada.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar a vara!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
  }


  close() {
    this.modalRef.close();
  }

  dismiss() {
      this.modalRef.dismiss();
  }


}

