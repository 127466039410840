<div class="mt-4" style="min-height: 139vh">
  <app-advanced-search-filter
    #filter
    [context]="this"
    [config]="configFormFilter"
    [formFilter]="formFilter"
    serachPlaceholder="Encontre um lançamento"
  >
    <ng-container drops>
      <div ngbDropdown>
        <button
          class="btn bg-light font-weight-bold btn-tag"
          type="button"
          id="menu-etiquetas"
          title="Etiquetas"
          tooltip-placement="bottom"
          tooltip-append-to-body="true"
          ngbDropdownToggle
        >
          <i class="fas fa-tag"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="menu-etiquetas">
          <div class="pt-3 pr-3 pl-3 pb-2">
            <label class="form-label">Categorias</label>
            <div class="input-group mb-3">
              <input
                placeholder="Encontrar categoria"
                class="form-control"
                [(ngModel)]="searchCategory"
                (input)="onSearchCategory()"
              />
            </div>
            <div style="overflow: auto; max-height: 350px">
              <a
                ngbDropdownItem
                class="cursor-pointer d-flex align-items-center"
                *ngFor="let item of categoriasFiltered | async"
                (click)="
                  filter.selectSomeFilter(
                    item.objectId,
                    'CATEGORIA',
                    categoriasOriginal
                  )
                "
              >
                <span
                  class="categorie mr-1"
                  [style]="getBgCategorie(item.color)"
                ></span>
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div ngbDropdown>
        <button
          class="btn bg-light dropdown-toggle font-weight-bold btn-contas"
          type="button"
          id="menu-contas"
          ngbDropdownToggle
        >
          1 CONTA(S)
        </button>
        <div ngbDropdownMenu aria-labelledby="menu-contas">
          <a
            ngbDropdownItem
            class="cursor-pointer"
            *ngFor="let c of standardAccounts | async"
            (click)="
              filter.selectSomeFilter(
                c.objectId,
                'CONTA',
                standardAccounts.value
              )
            "
          >
            {{ c.name }}
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container otherThings>
      <div class="col-2 col-sm-2 col-md-2 d-flex">
        <button class="btn today-arrow mr-2 text-white font-weight-bold">
          HOJE
        </button>

        <button class="btn today-arrow text-white font-weight-bold"><</button>

        <button class="btn today-arrow text-white font-weight-bold">></button>
      </div>
    </ng-container>
  </app-advanced-search-filter>

  <!-- FILTERS TYPES (todos, a pagar, pagos e recebidos...) -->
  <div
    class="d-flex bg-light filter-type pl-4 mt-2"
  >
    <a
      class="mr-3 p-3 cursor-pointer"
      [ngClass]="{ selected: item.selected }"
      (click)="selectFilterTypes(item.id)"
      *ngFor="let item of filterTypes"
    >
      {{ item.label }}
    </a>
  </div>
  <!--  -->

  <div class="d-flex justify-content-between mt-3">
    <span> Mostrando os lançamentos de <b>março de 2023</b> </span>

    <span
      class="text-muted cursor-pointer"
      (click)="expandTest = !expandTest"
      >{{ expandTest ? "RECOLHER TODOS" : "EXPANDIR TODOS" }}</span
    >
  </div>

  <!-- TABLE -->
  <table class="table mt-2">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Data</th>
        <th scope="col">Tipo</th>
        <th scope="col">Recebido de/Pago para</th>
        <th scope="col">Descrição</th>
        <th scope="col">Categoria</th>
        <th scope="col">Boleto</th>
        <th scope="col">Valor</th>
        <th scope="col">Pago</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th colspan="8" class="bg-light text-align-right">
          Saldo anterior de <b>R$ 448.683,00</b>
        </th>
        <td class="bg-light"></td>
      </tr>
      <tr>
        <td>
          <span
            class="material-symbols-outlined cursor-pointer arrow"
            style="margin-top: -6px"
            (click)="expandTest = !expandTest"
          >
            {{ expandTest ? "expand_less" : "expand_more" }}
          </span>
        </td>
        <td>21/03/2023</td>
        <td>
          <i
            class="fas fa-arrow-up text-success"
            style="transform: rotate(145deg)"
          ></i>
        </td>
        <td>
          Isis
          <div *ngIf="expandTest">
            <br />
            Processo/Caso
            <br />
            District Paradigm Officer
          </div>
        </td>
        <td>Descrição Teste</td>
        <td>Categoria Teste</td>
        <td></td>
        <td class="text-align-right">R$ 15.000,00</td>
        <td class="text-center"><input type="checkbox" /></td>
      </tr>
      <tr>
        <th colspan="8" class="bg-light text-align-right">
          Saldo do dia 21/03/2023 <b>R$ 563.683,00 </b>
        </th>
        <td class="bg-light"></td>
      </tr>
      <tr>
        <th colspan="8" class="bg-light text-align-right">
          Total de entradas no período <b>R$ 115.000,00 </b>
        </th>
        <td class="bg-light"></td>
      </tr>
      <tr>
        <th colspan="8" class="bg-light text-align-right">
          Total de saídas no período <b>R$ 0,00 </b>
        </th>
        <td class="bg-light"></td>
      </tr>
    </tbody>
  </table>
  <!--  -->
</div>
