import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-honorarios-sucubencia',
    templateUrl: './modal-honorarios-sucubencia.component.html',
    styleUrls: ['./modal-honorarios-sucubencia.component.scss']
})
export class ModalHonorariosSucubenciaComponent implements OnInit {

    public honorarioSucubenciaForm: FormGroup;
    modalRef: NgbModalRef;

    constructor() { }

    ngOnInit(): void {
    }

    dismiss(){
        this.modalRef.dismiss();
      }
    
      close() {
        this.modalRef.close();
      }
}
