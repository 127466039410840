import { Component, OnInit, } from '@angular/core';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ForosService } from 'src/app/services/foros.service';
import { InstanciasService } from 'src/app/services/instancias.service';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { VarasService } from 'src/app/services/varas.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { QualificacaoService } from 'src/app/services/qualificacao.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import Swal from 'sweetalert2';
import { TipoProcessoJudicialRecursoDesdobramentoService } from 'src/app/services/tipo-processo-judicial-recurso-desdobramento.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { DivisaoDeOrgaoService } from 'src/app/services/divisao-de-orgao.service';
import { AreaService } from 'src/app/services/area.service';
import { OrgaosService } from 'src/app/services/orgaos.service';
import { ComarcaService } from 'src/app/services/comarca.service';
import { UfService } from 'src/app/services/uf.service';
import { SituacaoCasoProcessoJudicialService } from 'src/app/services/situacao-caso-processo-judicial.service';
import { TipoProcessoEletronicoService } from 'src/app/services/tipo-processo-eletronico.service';
import { AcaoService } from 'src/app/services/acao.service';

@Component({
    selector: 'app-processos-judiciais-recursos-desdobramentos',
    templateUrl: './processos-judiciais-recursos-desdobramentos.component.html',
    styleUrls: ['./processos-judiciais-recursos-desdobramentos.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class ProcessosJudiciaisRecursosDesdobramentosComponent implements OnInit {

    public id: string;
    public processoId: string;
    public isEdit:boolean = false;
    public processo: any;
    public mascaraInteiro: Array<string | RegExp>;
    public mascaraData: Array<string | RegExp>;
    public mascaraDataAutoCorrected: Array<string | RegExp>;
    public mascaraNumero: Array<string | RegExp>;

    public recusoDesdobramento: any = {ativo:true, partes: [{}, {}] };
    public instancias: any = [];
    public dividoesOrgao: any = [];
    public varas: any = [];
    public foros: any = [];
    public tiposProcessosJudiciaisRecursosDesdobramentos: any = [];
    public pessoas: any = [];
    public qualificacoes: any = [];
    

    public loadInstancias: boolean = false;
    public loadDivisoesOrgaos: boolean = false;
    public loadProcesso: boolean = false;
    public loadVaras: boolean = false;
    public loadForos: boolean = false;
    public loadAcoes: boolean = false;
    public loadtipoRecursoDesdobramento: boolean = false;
    public loadPessoas: boolean = false;
    public loadQualificacoes: boolean = false;
    
    public loadRecursosDesdobramentos: boolean = false;
    public recursosDesdobramentos: [{}];
    public orgaos: any = [];
    public comarcas: any = [];
    public ufs: any = [];
    public areas: any = [];
    public fases: any = [];
    public situacoesProcesso: any = [];
    public recurso:boolean = true;
    public tiposProcessosEletronicos: any = [];
    public acoes: any = [];

    public ehProcessoEletronico: any =[
        {
            id: 1, nome: 'Físico',
        },
        {
            id: 2, nome: 'Eletrônico',
        }
    ];
    
    public ehProcessoEletronicoSelecionado: number = 1;

    constructor(
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private router: Router,
        private processosJudiciaisService: ProcessosJudiciaisService,
        private instanciasService: InstanciasService,
        private divisaoOrgaoService: DivisaoDeOrgaoService,
        private varasService: VarasService,
        private forosService: ForosService,
        private activatedRoute: ActivatedRoute,
        private tipoProcessoJudicialRecursoDesdobramentoService: TipoProcessoJudicialRecursoDesdobramentoService,
        private qualificacaoService: QualificacaoService,
        private pessoaService: PessoaService,
        private location: Location,        
        public areaService: AreaService,
        public orgaoServie: OrgaosService,
        public comarcaService: ComarcaService,
        public ufService: UfService,
        private situacaoCasoProcessoJudicialService: SituacaoCasoProcessoJudicialService,
        public tipoProcessoEletronicoService: TipoProcessoEletronicoService,
        public acaoService: AcaoService) {
        this.mascaraInteiro = createNumberMask({
            prefix: '',
            thousandsSeparatorSymbol: '',
        });
        this.mascaraData = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        this.mascaraDataAutoCorrected = createAutoCorrectedDatePipe('dd/mm/yyyy', { minYear: 1900, maxYear: 2100 });
        this.mascaraNumero = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

    }

    ngOnInit(): void {

        this.buscarInstancias();
        this.buscarDivisoesOrgaos();
        this.buscarOrgao();
        this.buscarVaras();
        this.buscarForos();
        this.buscarTipoOcorrenciaJudicialService();        
        this.buscarQualificacoes();
        this.buscarTipoFaseSituacaoProcesso();
        this.buscarComarcas();
        this.buscarUfs();
        this.buscarAreas();
        this.buscarTipoProcessoEletronico();
        this.buscarAcoes();

        this.isEdit = this.activatedRoute.snapshot.params['editar'] !== undefined;
        
        if(this.isEdit) {

            this.id = this.activatedRoute.snapshot.params['id'];
            this.buscarProcessoJudicialRecursoDesdobramento();            
        }
        else {

            this.processoId = this.activatedRoute.snapshot.params['processoId'];
            this.buscarProcesso();

            this.activatedRoute.data.subscribe(res =>{
                this.recurso = res.recurso;
                  
                if(!this.recurso){
                    this.buscarRecursosDesdobramentos();
                }
            });
        }                
    }

    buscarAcoes() {
        this.loadAcoes = true;
        this.acaoService.getAllAsync().then(x => {
            this.loadAcoes = false;
            this.acoes = x.data;
        });
    }
    
    buscarTipoProcessoEletronico(){
        this.tipoProcessoEletronicoService.getAll()
            .subscribe(res=>{
                this.tiposProcessosEletronicos = res.data;
            })
    }

    buscarAreas(){
        this.areaService.getAll()
            .subscribe(res=>{
                this.areas = res.data;
            })
    }

    buscarUfs(){
        this.ufService.getAll()
            .subscribe(res=>{
                this.ufs = res.data;
            })
    }

    buscarComarcas(){
        this.comarcaService.getAll()
            .subscribe(res=>{                
                this.comarcas = res.data;                
            });
    }
    
    buscarTipoFaseSituacaoProcesso(){

        this.situacaoCasoProcessoJudicialService.getAllFase().subscribe(res=>{
            this.fases = res.data;
        });
    }

    changeFase(faseSelecionada?: Number): void {

        if(!faseSelecionada && (!this.processo || !this.processo.faseProcesso))
            return;
        
        const faseProcesso = faseSelecionada ?? this.processo?.faseProcesso;

        this.situacaoCasoProcessoJudicialService.obterTipoProcessoPorFase(faseProcesso).subscribe(res=> {

            this.recusoDesdobramento.situacaoId = null;
            this.situacoesProcesso = res.data;
        });
    }

    buscarOrgao(){
        this.orgaoServie.getAll()
            .subscribe(res=>{                
                this.orgaos = res.data;                
            });
    }    

    buscarRecursosDesdobramentos() {
        this.processosJudiciaisRecursoDesdobramentoService.obterPorProcessoComRecurso(this.processoId)
            .subscribe(res =>{                
                this.recursosDesdobramentos = res.data;
                this.loadRecursosDesdobramentos = false;
            });
    }

    buscarProcessoJudicialRecursoDesdobramento() {
        this.processosJudiciaisRecursoDesdobramentoService.getById(this.id)
            .subscribe(res=>{
                if(res.success){
                    this.recusoDesdobramento = res.data;

                    this.ehProcessoEletronicoSelecionado = !this.recusoDesdobramento.processoEletronico ? 1 : 2;

                    this.processoId = this.recusoDesdobramento.processoJudicialId;
                    
                    if(this.recusoDesdobramento.distribuidoEm != null){
                        var distribuidoEm = new Date(this.recusoDesdobramento.distribuidoEm);

                        var data = {
                            year: distribuidoEm.getFullYear(),
                            month: distribuidoEm.getMonth() +1,
                            day: distribuidoEm.getDate()
                        }

                        this.recusoDesdobramento.distribuidoEm = data;
                        
                    }

                    if(this.recusoDesdobramento.partes == null || this.recusoDesdobramento.partes.length == 0){
                        this.recusoDesdobramento.partes = [{}, {}];
                    }else if(this.recusoDesdobramento.partes.length == 1){
                        this.recusoDesdobramento.partes.push({});
                    }
                    
                    this.buscarProcesso(false);
                }
            })
    }

    buscarQualificacoes() {
        this.loadQualificacoes = true;
        this.qualificacaoService.getAllAsync().then(x => {
            this.loadQualificacoes = false;
            this.qualificacoes = x.data;
        });
    }

    buscarTipoOcorrenciaJudicialService() {
        this.loadtipoRecursoDesdobramento = false;
        this.tipoProcessoJudicialRecursoDesdobramentoService.getAllAsync().then(x => {
            this.loadtipoRecursoDesdobramento = false;
            this.tiposProcessosJudiciaisRecursosDesdobramentos = x.data;
        });
    }

    buscarForos() {
        this.loadForos = true;
        this.forosService.getAllAsync().then(x => {
            this.loadForos = false;
            this.foros = x.data;
        });
    }

    buscarVaras() {
        this.loadVaras = true;
        this.varasService.getAllAsync().then(x => {
            this.loadVaras = false;
            this.varas = x.data;
        });
    }

    buscarInstancias() {
        this.loadInstancias = true;
        this.instanciasService.getAllAsync().then(x => {
            this.loadInstancias = false;
            this.instancias = x.data;
        });
    }

    buscarDivisoesOrgaos() {
        this.loadDivisoesOrgaos = true;
        this.divisaoOrgaoService.getAllAsync().then(x => {
            this.loadDivisoesOrgaos = false;
            this.dividoesOrgao = x.data;
        });
    }

    buscarProcesso(preencheCamposSugeridos=true) {
        this.processosJudiciaisService.getByIdAsync(this.processoId)
            .then(processo => {

                this.processo = processo.data;
                this.recusoDesdobramento.processoJudicialId = this.processoId;
                this.loadProcesso = true;
                
                if(preencheCamposSugeridos)
                    this.preencheCamposSugeridos();
            });
    }
    preencheCamposSugeridos() {

        if(this.processo.instanciaId != undefined){
            this.recusoDesdobramento.instanciaId = this.processo.instanciaId;
        }

        if(this.processo.divisaoDeOrgaoId != undefined){
            this.recusoDesdobramento.divisaoDeOrgaoId = this.processo.divisaoDeOrgaoId;
        }

        if(this.processo.orgaoId != undefined){
            this.recusoDesdobramento.orgaoId = this.processo.orgaoId;
        }

        if(this.processo.foroId != undefined){
            this.recusoDesdobramento.foroId = this.processo.foroId;
        }

        if(this.processo.faseProcesso != undefined){

            this.recusoDesdobramento.faseProcesso = this.processo.faseProcesso;
            this.changeFase(this.recusoDesdobramento.faseProcesso);
            this.recusoDesdobramento.situacaoId = this.processo.situacaoId;
        }

        if(this.processo.acaoId != undefined){
            this.recusoDesdobramento.acaoId = this.processo.acaoId;
        }

        if(this.processo.comarcaId != undefined){
            this.recusoDesdobramento.comarcaId = this.processo.comarcaId;
        }

        if(this.processo.ufId != undefined){
            this.recusoDesdobramento.ufId = this.processo.ufId;
        }

        if(this.processo.areaId != undefined){
            this.recusoDesdobramento.areaId = this.processo.areaId;
        }

        if(this.processo.clientes && this.processo.clientes.length > 0){
            
            let clientePrincipal = this.processo.clientes.filter(x=>x.principal); 

            if(clientePrincipal && clientePrincipal.length > 0){
                this.recusoDesdobramento.partes[0].pessoaId = clientePrincipal[0].pessoaId;
                this.recusoDesdobramento.partes[0].qualificacaoId = clientePrincipal[0].qualificacaoId;
            }
        }
    }

    defineClass(classe: string) {
        return 'badge ' + classe;
    }

    onSubmit(){        
        
        // console.log(this.recusoDesdobramento.partes[0].pessoaId);
        // return;

        if(this.recusoDesdobramento.distribuidoEm !== undefined && this.recusoDesdobramento.distribuidoEm !== null){
            let date = this.recusoDesdobramento.distribuidoEm;

            this.recusoDesdobramento.distribuidoEm = new Date(date.year,date.month-1,date.day);
        }

        if(this.recusoDesdobramento.instanciaId === undefined){
            this.swalAlert("Não foi informada a instância!");
            return;
        }

        // if(this.recusoDesdobramento.tipoProcessoJudicialRecursoDesdobramentoId === undefined){
        //     this.swalAlert("Não foi informado o tipo!");
        //     return;
        // }
        
        if(this.recusoDesdobramento.partes[0].pessoaId === undefined || this.recusoDesdobramento.partes[1].pessoaId === undefined){
            this.swalAlert("Não foi informado as partes!");
            return;
        }

        if(this.recusoDesdobramento.partes[0].qualificacaoId === undefined || this.recusoDesdobramento.partes[1].qualificacaoId === undefined){
            this.swalAlert("Não foi informado a qualificação das partes!");
            return;
        }

        this.spinner.show();
        this.toastrService.clear();

        if(!this.isEdit){
            this.processosJudiciaisRecursoDesdobramentoService.add(this.recusoDesdobramento)
            .subscribe(res => {
                this.router.navigate([`operacionais/processos-judiciais/detalhes/${this.processoId}`]);
                this.toastrService.success("Registro cadastrado.", "Sucesso");
                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a Ocorrência!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
        }else{
            this.processosJudiciaisRecursoDesdobramentoService.update(this.recusoDesdobramento)
            .subscribe(res => {
                this.router.navigate([`operacionais/processos-judiciais/detalhes/${this.processoId}`]);
                this.toastrService.success("Registro atualizado.", "Sucesso");
                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
        }
    }

    swalAlert(mensagem:any){
        let swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire(
            mensagem,
            '',
            'error'
        );
    }

    handleCancel() {
        this.location.back();
    }

    changeProcessoEletronico($event){
        
        if(this.ehProcessoEletronicoSelecionado == 1){
            this.recusoDesdobramento.tipoProcessoEletronicoId = null;
            this.recusoDesdobramento.processoEletronico = false;
        }else{
            this.recusoDesdobramento.processoEletronico = true;
        }
    }
}
