import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-modal-filtro-processos-orfaos',
    templateUrl: './modal-filtro-processos-orfaos.component.html',
    styleUrls: ['../../../../../vendor/styles/pages/modal-filtro-processos-orfaos.scss',
        '../../../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    ],
})

export class ModalFiltroProcessosOrfaosComponent implements OnInit {
    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    public processosOrfaosModal = [];
    public processosOrfaosModalFiltrados = [];
    public todosOsProcessosOrfaosModal = false;

    public filtroSemContato = ['Selecione...', 'mais que 5 dias', 'mais que 10 dias', 'mais que 15 dias', 'mais que 30 dias'];
    public filtroSemAtuacao = ['Selecione...', 'mais que 5 dias', 'mais que 10 dias', 'mais que 15 dias', 'mais que 30 dias'];

    public rangeValorFiltro = [0,0];
    public rangeConfig = {
        connect: true,
        step: 10,
        range: {
            min: 0,
            max: 0
        },
        direction: 'ltr',
        format: {
            to: function (value) {
                return 'R$ ' + value.toFixed(0);
            },
                // 'from' the formatted value.
                // Receives a string, should return a number.
            from: function (value) {
                return Number(value.replace('R$ ', ''));
            }
          }
    };

    public settings = {
        selectMode: 'multi',
        actions: {
            columnTitle: "",
            position: "right",
            add: false,
            edit: false,
            delete: false,
        },
        noDataMessage: "Não possui dado",
        pager: {
            display: false
        },
        columns: {},
        attr: {
            class: 'table table-bordered table-responsive table-modal-tabs-scroll scroll-bar-padrao'
        }
    };

    public columns = { idExterno: { title: 'Código' }, valor:
    {
        title:'Valor',
            valuePrepareFunction: (value) => {
                if (!value) return '';

                var formatoValor = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' };
                return value.toLocaleString('pt-BR', formatoValor);
              },
    }
    , anoDivida: { title: 'Ano da dívida' }, credor: { title: 'Credor' }, devedor: { title: 'Devedor' }, regiao: { title: 'Região' } };

    modalRef: NgbModalRef;
    isIE10 = false;
    loadingIndicator = true;

    ngOnInit(): void {
        this.modalRef.componentInstance.processosOrfaos.forEach(element => {
            var novoProcesso = {
                idExterno: element.idExterno,
                //nome: 'teste'element.Nome,
                valor: element.valor,
                anoDivida:element.anoDivida,
                credor:element.credorNome,
                devedor:element.devedorNome,
                regiao: element.regiao
            };

            this.processosOrfaosModal.push(novoProcesso);
            this.processosOrfaosModalFiltrados.push(novoProcesso);
        });

        this.getValorMaximoProcesso();

         this.settings.columns = this.columns;
    }

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService
    ) {

        this.isIE10 = this.appService.isIE10;
    }

    public onUserRowSelect(event) {
        if (event.data) {
            this.processosOrfaosModalFiltrados.forEach(element => {
                if (element.idExterno == event.data.idExterno && element.selecionado) {
                    element.selecionado = element.selecionado == true ? false : true;
                    return true;
                }
                else if (element.idExterno == event.data.idExterno){
                    element.selecionado = true;
                    return true;
                }
            });
        }
        else {
            this.processosOrfaosModalFiltrados.forEach(element => {
                var selecionado = event.source.filteredAndSorted.find(s => s.idExterno == element.idExterno);
                if (selecionado) {
                    if (element.selecionado) {
                        element.selecionado = element.selecionado == true ? false : true;
                        return true;
                    }
                    else {
                        element.selecionado = true;
                        return true;
                    }
                }
            });
        }
    }

    public filtrar() {
        var lista = this.processosOrfaosModalFiltrados.filter(c => c.selecionado == true);
        this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'processosOrfaosFiltrados', lista: lista });
    }

    public onSelecionaTodosProcessosOrfaosChange(event) {
        if (this.todosOsProcessosOrfaosModal) {
            this.processosOrfaosModalFiltrados.forEach(function (item) { item.selecionado = true; });
        }
        else {
            this.processosOrfaosModalFiltrados.forEach(function (item) { item.selecionado = false });
        }
    }

    rangeChanged(event) {
        this.processosOrfaosModalFiltrados = this.processosOrfaosModal.filter(function isBigEnough(element, index, array) {
            return (element.valor >= event[0] && element.valor <= event[1]);
         } )
    }


    public close() {
        this.modalRef.close();
    }

    public dismiss() {
        this.modalRef.dismiss();
    }

    private getValorMaximoProcesso() {
        this.rangeConfig.range.max = Math.max.apply(Math, this.processosOrfaosModal.map(function (o) { return o.valor; }));
        this.rangeValorFiltro = [0, this.rangeConfig.range.max];
    }
}
