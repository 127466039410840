import { OcorrenciaResponse } from 'src/app/models/operacionais/ocorrencia/ocorrencia-response';
import { Ocorrencia } from 'src/app/models/operacionais/ocorrencia/ocorrencia';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Tipo } from 'src/app/enums/documento/tipo.enum';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { SelectList } from 'src/app/models/select-list';
import { AtividadeService } from 'src/app/services/atividade.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { SituacaoService } from 'src/app/services/situacao.service';
import { TagService } from 'src/app/services/tag.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { AtividadeTarefaRequest } from 'src/app/models/operacionais/atividade/atividade-tarefa-request';
import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { DividaPagamentosService } from 'src/app/services/divida-pagamentos.service';
import { DividaPagamentoResponse } from 'src/app/models/operacionais/divida/divida-pagamento-response';
import { NgbDateCustomParserFormatter } from '../../../../utils/dateformat';
import { Moment } from 'moment';
import * as moment from "moment";
const now = new Date();


@Component({
  selector: 'app-modal-adicionar-divida-pagamentos',
  templateUrl: './modal-adicionar-divida-pagamentos.component.html',
  styleUrls: [
    '../../../../../vendor/styles/pages/dropzone.scss',
    './modal-adicionar-divida-pagamentos.component.css'
    ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
]
})
export class ModalAdicionarDividaPagamentosComponent implements OnInit {

    enableButtons:Boolean = true;

    modalRef: NgbModalRef;
    isIE10 = false;
    public isRTL: boolean;
    maxDate: NgbDateStruct = {
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate()
    };

    public dataPagamentoPlanejado: NgbDateStruct = {
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate()
    };
    public tipoDocumentos: SelectList[] = [];

    public novosAnexos: any[] = [];;
    public files: File[] = [];
    public isHovering: boolean;
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    public hasAnotherDropZoneOver: boolean;
    public response: string;
    public tiposDocumentos: any;

    public processo: ProcessoOperacao;
    public dividas: any[] = [];

    public displayMonths = 1;
    public navigation = 'select';
    public disabled = false;

    valorComprovante: number = 0;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public documentoService: DocumentoService,
        public ocorrenciaService: OcorrenciaService,
        public dividaPagamentoSevice: DividaPagamentosService,
        public tagService: TagService,
        public utilsService: UtilsService,
        private spinner: NgxSpinnerService
    ) {

        this.isRTL = appService.isRTL;

        this.uploader = new FileUploader({
            url: ocorrenciaService.postFileUrl,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise<void>((resolve, reject) => {
                    resolve();
                });
            }
        });

        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);

        this.isIE10 = this.appService.isIE10;
    }

    public pagamento: any = {
        observacao: '',
        dataPagamento: '',
        idProcesso: '',
        valorComprovante: 0,
        ocorrencia:{
            ocorrenciaId: '',
            descricao: '',
            processoId: ''
        },
        dividas: []
    };

    valorTotalPagamento: number = 0;
    valorPendente: number = 0;

    ngOnInit(): void {

        this.processo = this.modalRef.componentInstance.processo;
        this.valorComprovante = this.modalRef.componentInstance.valorComprovante;
        this.pagamento.valorComprovante = this.valorComprovante;
        this.pagamento.dataPagamento = new Date(this.pagamento.dataPagamento);

        this.dataPagamentoPlanejado.day = this.pagamento.dataPagamento.getDate();
        this.dataPagamentoPlanejado.month = this.pagamento.dataPagamento.getMonth() + 1;
        this.dataPagamentoPlanejado.year = this.pagamento.dataPagamento.getFullYear();

        this.pagamento.dividas = [];
        this.valorPendente = 0;
        this.valorTotalPagamento = 0;
        this.dividas = this.modalRef.componentInstance.dividas;

        this.dividas.forEach(divida => {
            //divida.valorPendente = divida.valorTotal - divida.valorPagamento;
            divida.dividaId = divida.id;
            divida.id = "";
            /*
            if(divida.retido == 1)
            {
                divida.juros2 = divida.juros;
                divida.juros = 0;
                divida.valorTotal -= divida.juros2;
                divida.valorPendente -= divida.juros2;
            }
            */

            // if(divida.isento)
            // {
            //   divida.valorPendente = Math.floor(divida.valorPendente * 100) / 100;
            //   var encargos = (divida.juros + divida.multa + divida.valorCustasProtesto + divida.valorAtualizacaoCustas);
            //   encargos = Math.floor(encargos * 100) / 100;
            //   divida.valorPendente = divida.valorPendente - encargos;
            // }

            divida.valorComprovante = this.pagamento.valorComprovante;
            this.valorTotalPagamento += divida.valorPagamento;
            this.valorPendente += divida.valorPendente;

        });

        this.pagamento.dividas = this.dividas;

        console.log(this.pagamento);

        this.novosAnexos = [];


        this.documentoService.getAllByTipo(Tipo.Ocorrencia).subscribe(res => {
            this.tipoDocumentos = res.data.map(e => { return new SelectList(e.id.toString(), e.nome) });
        });

    }

    salvar() {
        this.toastrService.clear();

        if(!this.dataPagamentoPlanejado)
        {
            this.toastrService.error("Por Favor, informe a data de pagamento!", 'Atenção', { timeOut: 10000 });
        }
        else if(!this.pagamento.ocorrencia.descricao)
        {
            this.toastrService.error("Por Favor, informe a observação!", 'Atenção', { timeOut: 10000 });
        }
        // else if(this.uploader.queue.length == 0)
        // {
        //     this.toastrService.error("Por Favor, selecione ao menos um arquivo!", 'Atenção', { timeOut: 10000 });
        // }
        else{
            this.add();
        }
    }

    add() {

        this.enableButtons = false;

        this.spinner.show();

        this.preSave();

        this.dividaPagamentoSevice.add(this.pagamento)
        .subscribe(res => {
            this.postSave(res);
        }, error => {
            this.spinner.hide();

            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível criar o pagamento!', 'Atenção', { timeOut: 10000 });
            }
            this.enableButtons = true;
        });

    }


    postSave(res: DividaPagamentoResponse)
     {
        this.pagamento.ocorrencia.ocorrenciaId = res.data.ocorrenciaId;

        if (this.uploader != null && this.uploader.queue.length > 0) {
            this.enviarArquivos().subscribe(result => {
              this.enableButtons = true;
            }, error => {
                this.enableButtons = true;
                this.spinner.hide();
                this.toastrService.error(error, 'Atenção');
            },() => { //complete
                this.spinner.hide();
                this.enableButtons = true;
                this.toastrService.success("Ocorrência adicionada.", "Sucesso");

                setTimeout(() => {
                    this.close(this.processo);
                }, 300);
            });
        } else {

            this.enableButtons = true;

            this.spinner.hide();

            this.toastrService.success("Ocorrência adicionada.", "Sucesso");

            setTimeout(() => {
                this.close(this.processo);
            }, 300);
        }
    }

    preSave() {
        this.toastrService.clear();


        //this.dataPagamentoPlanejado.year +"-"+ this.dataPagamentoPlanejado.month+"-"+ this.dataPagamentoPlanejado.day;

        var datePayment = new Date(this.dataPagamentoPlanejado.year,this.dataPagamentoPlanejado.month-1,this.dataPagamentoPlanejado.day);

        this.pagamento.dataPagamento = datePayment;

        this.pagamento.dividas.forEach(divida => {
            divida.dataPagamento = datePayment;
            if(divida.retido == 1){
                divida.valorAtualizacaoCustas = divida.valorAtualizacaoCustas2;
                divida.juros = divida.juros2;
            }

        });

        this.pagamento.idProcesso = this.processo.id;
        this.pagamento.ocorrencia.processoId = this.processo.id;
    }

    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalContatoProcesso(processo);
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
     }

     onDrop(fileList) {
        for (var i = 0; i < fileList.length; i++) {
            const event = fileList[i];
            const fileInQueue = this.uploader.queue.find(q => q.file.name === event.name);
            const newFile = new CustomFileLike(fileInQueue.file);

            newFile.documentType = this.tipoDocumentos[0].key.toString();
            newFile.file = event;

            fileInQueue.file = newFile;
        }
    }

    onTipoDocumentChange(index, customFile) {
        customFile.documentType = this.tipoDocumentos[index].key;
    }

    excluirNovoAnexo(novoAnexo) {
        this.novosAnexos = this.novosAnexos.filter(c => c.name != novoAnexo.name);
    }

    enviarArquivos() : Observable<boolean> {
        const self = this;
        const envioArquivos = new Observable<boolean>((observer) => {
            let canceled = false;

            async function engine() {
                const arquivosUpload = [];

                self.uploader.queue.forEach(item => {
                    arquivosUpload.push(self.montarFormDataArquivo(item.file));
                });

                await self.utilsService.asyncForEach(arquivosUpload, async (arquivo, idx) => {
                    await self.ocorrenciaService.uploadArquivo(arquivo).then(result => {
                        if (result.success) {
                            self.uploader.clearQueue();

                            observer.next(true);
                        } else {
                            if (result.data && result.data.errors && result.data.errors.length > 0) {
                                result.data.errors.forEach(element => {
                                    observer.error(element);
                                });
                            } else {
                                observer.error('Houve algum problema ao salvar os documentos');
                            }

                            return;
                        }

                        if (arquivosUpload.length == idx + 1) {
                            observer.complete();
                        }
                    }).catch(error => {
                        if (error && error.errors) {
                            error.errors.forEach(element => {
                                observer.error(element);
                            });
                        } else {
                            observer.error('Houve algum problema ao salvar os documentos');
                        }

                        return;
                    });
                });
            }

            engine();
            return () => canceled = true;
        });

        return envioArquivos;
    }

    montarFormDataArquivo(item: FileLikeObject) {
        const file = item as CustomFileLike;
        const formData: FormData = new FormData();

        formData.append('ocorrenciaId', this.pagamento.ocorrencia.ocorrenciaId.toString());
        formData.append('pasta', 'ocorrencias');
        formData.append('tipoDocumentoId', file.documentType);
        formData.append('nome', file.name);
        formData.append('contentType', file.type);
        formData.append('file', file.file as File);

        return formData;
    }

    isTarefaFinalizada(tarefa: AtividadeTarefaRequest) {
        return tarefa.status == TipoStatus.AtividadeTarefaFinalizada;
    }

    changeTarefaStatus(tarefa: AtividadeTarefaRequest) {
        tarefa.status = tarefa.status == TipoStatus.AtividadeTarefaAberta ? TipoStatus.AtividadeTarefaFinalizada : TipoStatus.AtividadeTarefaAberta;
    }

    calcularTotalValorTotal(){

        var valor = 0;
        this.dividas.forEach(divida =>{
            //valor += divida.valorTotal;
            valor += divida.valorPagamento;
        });

        return valor;
    }

    calcularTotalValorPago(){
        var valor = 0;
        this.dividas.forEach(divida =>{
            valor += divida.valorPago;
        });

        return valor;
    }

    calcularTotalValorQuitacao(){

        var valor = 0;
        this.dividas.forEach(divida =>{
            valor += divida.valorQuitacao;
        });

        return valor;
    }

    calcularTotalValorPendente(){

        var valor = 0;
        this.dividas.forEach(divida =>{
            valor += divida.valorPendente;
        });

        return valor;
    }
}
