import { TipoStatus } from "src/app/enums/tipo-status.enum";

export class AtividadeTarefaRequest {
    nome: String;
    status: TipoStatus;
    ordem: Number;

    constructor(nome: String, ordem: Number) {
        this.nome = nome;
        this.ordem = ordem;

        this.status = TipoStatus.AtividadeTarefaAberta;
    }
}