<div id="modal">
    <!-- Modal template -->
    <div class="modal-header">
        <h5 class="modal-title">
            Emails para notificar
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>

    <div class="modal-body pt-0">
        <div class="card-body scroll-bar-padrao p-0">
            <div style="height: 300px !important;" class="table-responsive scroll-bar-padrao">
                <table class="table product-discounts-edit">
                    <thead>
                        <tr>
                            <th style="width:10%"></th>
                            <th style="width:90%">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center" *ngIf="!contatosCredorEmails || contatosCredorEmails.length == 0">
                            <td colspan="7">
                                <label class="form-label">Não há dados disponíveis</label>
                            </td>
                        </tr>
                        <tr *ngFor="let email of contatosCredorEmails; let i = index">
                            <td class="text-center pl-0 pr-0">
                                <input type="checkbox" [(ngModel)]="email.selecionado">
                            </td>

                            <td>{{email.email}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <br>
        <span *ngIf="description"><strong>Motivo:</strong></span>
        <textarea class="form-control" rows="4" [(ngModel)]="descricao" *ngIf="description"></textarea>

      </div>

    <div class="modal-footer p-1">
      <button *ngIf="contatosCredorEmails && contatosCredorEmails.length > 0" type="button" (click)='mudarButton()' [ngClass]="btnSucess == true ? 'btn btn-success' : 'btn btn-danger'">
        <i *ngIf="btnSucess" class="fas fa-check"></i>
        <i *ngIf="!btnSucess" class="fas fa-ban"></i> {{buttonName}}
      </button>
        <button (click)='confirmarEmailNotificacao()' *ngIf="contatosCredorEmails && contatosCredorEmails.length > 0" type="button"
            class="btn btn-success"><i class="fas fa-check"></i> Confirmar</button>
        <button (click)='close()' type="button" class="btn btn-default">Fechar</button>
    </div>

</div>
