import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Modulo } from 'src/app/enums/modulo.enum';
import { ParametroChaveType } from 'src/app/enums/parametro/parametro-chave-type.enum';
import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { Acordo } from 'src/app/models/operacionais/acordo/acordo';
import { AcordoListaResponse } from 'src/app/models/operacionais/acordo/acordo-lista-response';
import { AcordoResponse } from 'src/app/models/operacionais/acordo/acordo-response';
import { Ocorrencia } from 'src/app/models/operacionais/ocorrencia/ocorrencia';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { Parametro } from 'src/app/models/shared/parametro';
import { AcordoService } from 'src/app/services/acordo.service';
import { ParametroService } from 'src/app/services/parametro.service';
import { ModalService } from 'src/app/services/shared/modal-service';

@Component({
  selector: 'app-modal-aprovar-acordo',
  templateUrl: './modal-aprovar-acordo.component.html',
})
export class ModalAprovarAcordoComponent implements OnInit {

  public loadingAcordo:Boolean = false;
  modalRef: NgbModalRef;
  public acordo:Acordo;

  public processo: ProcessoOperacao;
  public ocorrencia: Ocorrencia;

  isAprovarAcordo: Boolean;
  isReprovarAcordo: Boolean;

  buttonsDescumprimentoAcordo:Boolean;

  public options: { readonly?: Boolean, previousTab?: String };

  parametroL: Parametro[];

  constructor(
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private appService: AppService,
    private acordoService:AcordoService,
    private authService: AuthService,
    private parametroService: ParametroService,
    public modalService: ModalService,
  ) {

  }

  ngOnInit(): void {

    this.loadingAcordo = true;

    this.processo = this.modalRef.componentInstance.processo;
    this.ocorrencia = this.modalRef.componentInstance.ocorrencia;

    this.acordoService.getById(this.ocorrencia.id).subscribe((res: AcordoResponse) => {
      this.loadingAcordo = false;
      this.acordo = res.data;
      this.acordo.valorAtualizacaoCustas = 0;
      this.acordo.dividas.forEach(x =>{
          this.acordo.valorAtualizacaoCustas += x.valorAtualizacaoCustas;
      })

      console.log(this.acordo);
      this.ocorrencia = this.acordo.ocorrencia;

      this.acordo.dividas = this.acordo.dividas.sort((a: ProcessoDivida, b: ProcessoDivida) => {
        return +new Date(a.dataVencimento) - +new Date(b.dataVencimento);
      });

      this.checkButtonsAcordo();
      this.checkButtonsDescumprimento();
      this.checkComentario();
    });

    this.parametroService.getAll().subscribe(res => {
        this.parametroL = res.data;
    });
  }


  close() {
    this.modalRef.close();
    var proc = new ProcessoOperacao();
    proc.id = this.processo.id;
    this.modalService.abrirModalContatoProcesso(proc, { previousTab: "tabOcorrencias" });
  }

  dismiss() {
    this.modalRef.dismiss();
  }

  aprovarAcordo(aprovarOuReprovar:Boolean)
  {

    this.spinner.show();
    this.toastrService.clear();

      var res = this.acordoService.aprovarAcordo(this.ocorrencia.id,aprovarOuReprovar).subscribe(x =>
      {
        this.spinner.hide();
        this.toastrService.success(aprovarOuReprovar ? "Acordo Aprovado !":"Acordo Negado !","Sucesso")
        this.close();
      },error=>{
        this.toastrService.error("Erro ao atualizar acordo!","Erro")
        this.spinner.hide();

      })
  }

  aprovarDescumprimentoAcordo(aprovarOuReprovar:Boolean)
  {

    this.spinner.show();
    this.toastrService.clear();

      var res = this.acordoService.aprovarDescumprimentoAcordo(this.ocorrencia.id,aprovarOuReprovar).subscribe(x =>
      {
        this.spinner.hide();
        this.toastrService.success(aprovarOuReprovar ? "Descumprimento Aprovado !":"Descumprimento Negado !","Sucesso")
        this.dismiss();
      },error=>{
        this.toastrService.error("Erro ao atualizar acordo!","Erro")
        this.spinner.hide();

      })
  }

  checkButtonsAcordo()
  {
    //o id no final é analise de aprovacao do acordo
    this.isAprovarAcordo = (this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao || this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacaoCredor)
        &&
        (this.checkMaster() || this.authService.isPermissionAvailable(Modulo.OPERACIONAIS_PROCESSOS_CREDOR))
        &&
        this.acordo.ocorrencia.situacaoId == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_ANALISE].toString())[0].valor);

    this.isReprovarAcordo = this.isAprovarAcordo;

    if (this.checkMaster() && this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacaoCredor) {
        //this.isAprovarAcordo = false; // se o acordo tiver sido enviado ao Credor para aprovar, o master não pode aprovar
    }
  }

  checkButtonsDescumprimento()
  {
    //o id no final é analise de aprovacao de descumprimento
    this.buttonsDescumprimentoAcordo = (this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao) && this.checkMaster() && this.acordo.ocorrencia.situacaoId == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_DESCUMPRIMENTO_ANALISE].toString())[0].valor);
  }

  checkMaster()
  {
    return this.authService.isMaster();
  }


  checkComentario()
  {
    //id de acordo em analise
    var idAnaliseAcordo = Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_ANALISE].toString())[0].valor);

    //id de descumprimento em analise
    var idAnaliseDescumprimento = Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_DESCUMPRIMENTO_ANALISE].toString())[0].valor);

    if(this.acordo.ocorrencia.situacaoId == idAnaliseAcordo ||  this.acordo.ocorrencia.situacaoId == idAnaliseDescumprimento)
    {
      this.options.readonly = false;
    }else{
      this.options.readonly = true;
    }

  }
}
