import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ComarcaService } from 'src/app/services/comarca.service';

@Component({
    selector: 'app-modal-atualiza-comarca',
    templateUrl: './modal-atualiza-comarca.component.html',
    providers: [ComarcaService]
})
export class ModalAtualizaComarcaComponent implements OnInit {

    modalRef: NgbModalRef;
    public comarca: any;
    public telefones: any[];

    //public telefones: any[] = [];
    public tiposTelefones = [];
    public settingsTelefones: any;

    constructor(
        private comarcaService: ComarcaService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        this.comarca = this.modalRef.componentInstance.comarca;
    }


    atualizar() {
        this.update(this.comarca);
    }

    update(comarca) {

        this.spinner.show();
        this.toastrService.clear();

        this.comarcaService.update(comarca)
            .subscribe(res => {

                this.toastrService.success("Comarca atualizada.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar a comarca!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }


    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
