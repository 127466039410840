import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ProcessosJudiciaisApensosService } from 'src/app/services/processos-judiciais-apensos.service';
import { TipoApensoService } from 'src/app/services/tipo-apenso.service';

@Component({
    selector: 'app-modal-apensos',
    templateUrl: './modal-apensos.component.html',
    styleUrls: ['./modal-apensos.component.css']
})
export class ModalApensosComponent implements OnInit {

    private modalRef: NgbModalRef;   
    public processos:any =[{}];
    public loadProcessos: boolean = true;
    public segundoProcesso: string;
    public processoJudicialId: string;
    public tipoApensos: any = [];
    public tipoApenso: any;

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });
    
    constructor(
        private processosJudiciaiServices: ProcessosJudiciaisService,
        private processosJudiciaisApensosService: ProcessosJudiciaisApensosService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        public tipoApensoService: TipoApensoService,
    ) { }

    ngOnInit(): void {
        this.buscarProcessos();
        this.buscarTipoApensos();
    }

    buscarTipoApensos() {
        this.tipoApensoService.getAll().subscribe(res=>{
            this.tipoApensos = res.data;
        })
    }

    buscarProcessos() {
        this.processosJudiciaiServices.obterProcessosApenso(this.processoJudicialId)
            .then(res=>{
                if(res.success){
                    this.processos = res.data;
                    this.loadProcessos = false;
                }
            });
    }

    salvar(){        

        if(this.segundoProcesso == undefined || this.segundoProcesso == null){
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o processo!',
                '',
                'error'
            );

            return;
        }

        debugger;

        if(this.tipoApenso == undefined || this.tipoApenso == null){
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o tipo!',
                '',
                'error'
            );

            return;
        }

        this.spinner.show();
        this.toastrService.clear();

        let apenso:any = {
            "primeiroProcessoJudicial": this.processoJudicialId,
            "segundoProcessoJudicial": this.segundoProcesso,
            "tipoApensoId": this.tipoApenso
        }

        this.processosJudiciaisApensosService.add(apenso)
            .subscribe(res=>{
                if(res.success){
                    this.spinner.hide();
                    this.close();                    
                }else{
                    this.spinner.hide();
                    this.swalWithBootstrapButtons.fire(
                        'Não foi possível incluir!',
                        '',
                        'error'
                    );
                }
            }, error => {

                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar a ocorrência!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
