<ul ngbNav #subNavHonorarios="ngbNav" class="nav-tabs pt-2">
    <li [ngbNavItem]="1">
        <a ngbNavLink>Sucumbência</a>
        <ng-template ngbNavContent>
            <honorarios-resumo
                [tipo]="tipo"
                [referenciaId]="referenciaId"
                [raiz]="raiz" 
                [tipoHonorario]="1"
                [(refresh)]="refresh"> <!--1 Sucumbência-->
            </honorarios-resumo>            
            <app-honorarios-listar-detalhes
                [tipo]="tipo"
                [referenciaId]="referenciaId"
                [raiz]="raiz" 
                [tipoHonorario]="1"
                [(refresh)]="refresh">                
            </app-honorarios-listar-detalhes>
        </ng-template>
    </li> 
    <li [ngbNavItem]="2">
        <a ngbNavLink>Convencionados</a>
        <ng-template ngbNavContent>
            <div class="row">
                <div class="col-12 d-flex justify-content-end align-items-center font-weight-bold pt-3">
                    <div class="btn-adicionar" (click)="abrirModalConvenionados()">
                        <i class="fas fa-plus"></i>
                    </div>                    
                </div>
            </div>
            <ul ngbNav #subNavConvenionados="ngbNav" class="nav-tabs pt-2">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Inicial Fee</a>
                    <ng-template ngbNavContent>
                        <honorarios-resumo
                            [tipo]="tipo"
                            [referenciaId]="referenciaId"
                            [raiz]="raiz" 
                            [tipoHonorario]="2"
                            [(refresh)]="refresh"> <!--2 Inicial Fee-->
                        </honorarios-resumo>
                        <app-honorarios-listar-detalhes
                            [tipo]="tipo"
                            [referenciaId]="referenciaId"
                            [raiz]="raiz" 
                            [tipoHonorario]="2"
                            [(refresh)]="refresh">
                        </app-honorarios-listar-detalhes>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Sucess Fee</a>
                    <ng-template ngbNavContent>
                        <honorarios-resumo
                            [tipo]="tipo"
                            [referenciaId]="referenciaId"
                            [raiz]="raiz" 
                            [tipoHonorario]="3"
                            [(refresh)]="refresh"> <!--3 Sucess Fee-->
                        </honorarios-resumo>
                        <app-honorarios-listar-detalhes
                            [tipo]="tipo"
                            [referenciaId]="referenciaId"
                            [raiz]="raiz" 
                            [tipoHonorario]="3"
                            [(refresh)]="refresh">
                        </app-honorarios-listar-detalhes>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Timesheet</a>
                    <ng-template ngbNavContent>
                        <honorarios-resumo
                            [tipo]="tipo"
                            [referenciaId]="referenciaId"
                            [raiz]="raiz" 
                            [tipoHonorario]="4"
                            [(refresh)]="refresh"> <!--4 Timesheet-->
                        </honorarios-resumo>
                        <app-honorarios-listar-detalhes
                            [tipo]="tipo"
                            [referenciaId]="referenciaId"
                            [raiz]="raiz" 
                            [tipoHonorario]="4"
                            [(refresh)]="refresh">
                        </app-honorarios-listar-detalhes>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="subNavConvenionados" class="border-card"></div>
        </ng-template>
    </li>    
</ul>
<div [ngbNavOutlet]="subNavHonorarios" class="border-card"></div>