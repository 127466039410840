import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComarcaService } from 'src/app/services/comarca.service';

@Component({
    selector: 'comarca-dropdown',
    templateUrl: './comarca-dropdown.component.html'
})
export class ComarcaDropdownComponent implements OnInit {

    @Input() comarcaId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() comarcaIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public comarcas: any = [];
    public loadComarcas: boolean = false;
    public comarca: any;

    constructor(
        private _comarcaService: ComarcaService
    ) { }

    ngOnInit(): void {

        if (this.comarcaId != undefined && this.comarcaId != null && this.comarcaId != '') {
            this.buscarPorId();
        } else {
            this.buscarComarca('a');
        }
    }

    changeComarca($event) {

        this.comarcaIdChange.emit(this.comarcaId);
        this.change.emit(this.comarcas.filter(x => x.id == this.comarcaId)[0]);
    }

    filtrar(term: any) {

        if (term.target.value != '')
            this.buscarComarca(term.target.value);
    }

    buscarComarca(buscar) {

        this.loadComarcas = true;

        this._comarcaService.obterComarcaFiltroNomeAsync(buscar).then(x => {

            this.loadComarcas = false;
            this.comarcas = x.data;

            if (this.comarca != undefined) {
                this.comarcas.push(this.comarca);
            }
        });
    }

    buscarPorId() {

        this.loadComarcas = true;
        this._comarcaService.getByIdAsync(this.comarcaId)
            .then(res => {
                this.loadComarcas = false;
                let comarcas = [];
                comarcas.push(res.data);
                this.comarca = res.data;
                this.comarcas = comarcas;
            })
    }

    limparComarca() {

        this.comarcaIdChange.emit(null);
    }
}
