import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Location, DatePipe, CurrencyPipe, DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { ProcessosJudiciaisHistoricoManualService } from 'src/app/services/processos-judiciais-historico-manual.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { TipoTarefaService } from 'src/app/services/tipo-tarefa.service';
import { TipoPrioridadeService } from 'src/app/services/tipo-prioridade.service';
import { ProcessosJudiciaisOcorrenciaTarefaService } from 'src/app/services/processos-judiciais-ocorrencia-tarefa.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { ModalOcorrenciaComponent } from './modal-ocorrencia/modal-ocorrencia.component';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { Subject } from 'rxjs';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ModeloService } from 'src/app/services/modelo.service';
import { ProcessosJudiciaisOcorrenciaEventoService } from 'src/app/services/processos-judiciais-ocorrencia-evento.service';
import { ProcessosJudiciaisOcorrenciaPrazoService } from 'src/app/services/processos-judiciais-ocorrencia-prazo.service';
import { ModalApensosComponent } from './modal-apensos/modal-apensos.component';
import { ProcessosJudiciaisApensosService } from 'src/app/services/processos-judiciais-apensos.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridComponent } from 'src/app/views/shared/ag-grid/ag-grid.component';
import { ModalAddDocValoresComponent } from './modal-add-doc-valores/modal-add-doc-valores.component';
import { ModalAdicionarTagComponent } from './modal-adicionar-tag/modal-adicionar-tag.component';
import { ProcessosJudiciaisValorService } from 'src/app/services/processos-judiciais-valor.service';
import { ModalDetalheComponent } from 'src/app/views/dashboard/agenda/modal-detalhe/modal-detalhe.component';
import { ProcessoOperacaoResponse } from 'src/app/models/operacionais/operacao/processo-operacao-response';
import { ProcessosService } from 'src/app/services/processos.service';
import { TipoAtividade } from 'src/app/enums/atividade/tipo-atividade.enum';
import { DivisaoDeOrgaoService } from 'src/app/services/divisao-de-orgao.service';
import { OrgaosService } from 'src/app/services/orgaos.service';
import { ModalAddEditPedidoComponent } from './modal-add-edit-pedido/modal-add-edit-pedido.component';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-processos-judiciais-detalhes',
    templateUrl: './processos-judiciais-detalhes.component.html',
    styleUrls: [
        './processos-judiciais-detalhes.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class ProcessosJudiciaisDetalhesComponent extends AgGridComponent implements OnInit {

    public taxasAcordo: any = {};

    public hideButtonsDadosProcesso: boolean = true;
    public hideButtonsProximasAitividades: boolean = true;
    public hideButtonsDadosOcorrencias: boolean = true;
    public hideButtonsRecursosDesdobramentos: boolean = true;
    public loadDadosOcorrencia: boolean = true;
    public loadhistoricosProcessoOcorrencias: boolean = true;
    public loadRecursosDesdobramentos: boolean = true;
    public loadApensos: boolean = true;
    public loadProcesso: boolean = true;
    public navProcessoSelecionada = 1;
    public exibirDetalhesRecursoDesdobramento = false;
    public recursoDesdobramentoDetalhe: any = [];
    public activenavRecursosDesdobramentos = 1;

    public id: string;
    public tab: string;
    public processo: any;
    public ultimasOcorrencias: any;

    public historicoManual: any = {
        isEdit: false
    };

    public tarefaManual: any = {
        isEdit: false
    }

    public loadPessoas: boolean = false;
    public pessoas: any = [];
    public pessoaId: any;

    public loadTipoTarefas: boolean = false;
    public loadTipoPrioridade: boolean = false;
    public tipoTarefas: any = [];
    public tipoPrioridade: any = [];

    public historicosProcessoOcorrencias: any = [{}]
    public recursosDesdobramentos: any = [{}]
    public recursoDesdobramentoArquivos: any = [{}]
    public apensos: any = [{}]

    public loadAtividadesDoProcesso: boolean = false;
    public atividadesDoProcessoObjeto: any = [{}];
    public atividadesDoProcesso: any = [];
    public atividadesDoProcessoTop: any = [];
    public quantidadeAtividadesNaoConcluida = 0;
    public quantidadeAtividadesConcluida = 0;
    public quantidadeAtividadesEmAtraso = 0;
    public quantidadeAtividadesAbertas = 0;
    public atividadeSelecionada: string = 'atividade-aberta';

    public resultConfirmDialog$ = new Subject<ResultadoModal>();

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    public raiz: string = '';

    public uploader: FileUploader;

    private file: File;

    private files: Array<File> = [];
    public hasBaseDropZoneOver: boolean;
    public showProcessLinked:Boolean = true;

    @ViewChild('dadosDiv', { read: ElementRef, static: false }) dadosDiv: ElementRef;
    @ViewChild('casosDiv', { read: ElementRef, static: false }) casosDiv: ElementRef;

    columnDefs: ColDef[] = [
        {
            headerCheckboxSelection: true,
            headerName: 'N° Documento',
            field: 'numero',
            width: 150,
            checkboxSelection: true,
            cellStyle: () => {
                return { cursor: 'pointer' }
            },
        },
        {
            headerName: 'Vencimento',
            field: 'dtVencimento',
            type: "dateColumn",
            filter: 'agDateColumnFilter',
            filterParams: this.filterDateParams,
            width: 108,
            cellStyle: () => {
                return { cursor: 'pointer' }
            },
        },
        {
            headerName: 'Remessa',
            field: 'dtRemessa',
            type: "dateColumn",
            filter: 'agDateColumnFilter',
            filterParams: this.filterDateParams,
            width: 108,
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            valueFormatter: function (params) {

                if (params.value == null) {
                    return ' - ';
                }

                return params.value;
            }
        },
        {
            headerName: 'Parcela',
            field: 'parcela',
            width: 100,
            cellStyle: () => {
                return { cursor: 'pointer' }
            },
        },
        {
            headerName: 'Valor Original',
            field: 'valor',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 120
        },
        {
            headerName: 'Juros',
            field: 'juros',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 100
        },
        {
            headerName: 'Valor Protesto',
            field: 'protesto',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 120
        },
        {
            headerName: 'Juros do Protesto',
            field: 'jurosProtesto',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 138
        },        
        {
            headerName: 'Total',
            field: 'total',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {

                if (params.value && params.value > 0)
                    return this.currencyPipe.transform(Number(params.value), 'BRL');

                const total = params.data.valor + params.data.juros + params.data.protesto + params.data.jurosProtesto;
                return this.currencyPipe.transform(Number(total), 'BRL')
            },
            width: 100
        },
        {
            headerName: 'Índice',
            field: 'indiceNome',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            width: 138
        },
        {
            headerName: 'Valor Atualizado Índice',
            field: 'valorAtualizadoCalculado',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 140
        },
        {
            headerName: 'Juros Moratorios Índice',
            field: 'valorJurosMoratoriosCalculado',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 140
        },
        {
            headerName: 'Juros Compensatorios Índice',
            field: 'valorJurosCompensatoriosCalculado',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 140
        },
        {
            headerName: 'Multa Índice',
            field: 'valorMultaCalculado',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 140
        },
        {
            headerName: 'Honorário Índice',
            field: 'valorHonorarioCalculado',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 140
        },
        {
            headerName: 'Total Índice',
            field: 'valorTotalCalculado',
            cellStyle: (params) => {
                return { fontWeight: params.node.rowPinned ? 'bold' : 'normal', cursor: 'pointer' }
            },
            cellRenderer: params => {
                return this.currencyPipe.transform(Number(params.value), 'BRL')
            },
            width: 140
        },
    ];    

    pinnedBottom = {
        dtRemessa: 'Totais',
        valor: 0,
        juros: 0,
        protesto: 0,
        jurosProtesto: 0,
        total: 0,
        indiceNome: "",
        valorAtualizadoCalculado: 0,
        valorJurosMoratoriosCalculado: 0,
        valorJurosCompensatoriosCalculado:0,
        valorMultaCalculado:0,
        valorHonorarioCalculado:0,
        valorTotalCalculado:0
    };

    @ViewChild('agGridComponent') agGridComponent: AgGridComponent;

    gridOptions: GridOptions = {
        headerHeight: 20,
        rowHeight: 20,
        rowStyle: {
            fontSize: '10px'
        }
    }

    defaultColDef: ColDef = {
        resizable: true,
        sortable: true,
        filter: false,
    };

    divOrgaoDesc;
    orgaoDesc;

    constructor(
        private modalService: ModalService,
        private ngbModal: NgbModal,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute,
        private processosJudiciaisService: ProcessosJudiciaisService,
        private processoAmigavelService: ProcessosService,
        private datePipe: DatePipe,
        private location: Location,
        private currencyPipe: CurrencyPipe,
        private pessoaService: PessoaService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
        private processosJudiciaisHistoricoManualService: ProcessosJudiciaisHistoricoManualService,
        private processosJudiciaisOcorrenciaTarefaService: ProcessosJudiciaisOcorrenciaTarefaService,
        private processosJudiciaisOcorrenciaEventoService: ProcessosJudiciaisOcorrenciaEventoService,
        private processosJudiciaisOcorrenciaPrazoService: ProcessosJudiciaisOcorrenciaPrazoService,
        private tipoTarefaService: TipoTarefaService,
        private tipoPrioridadeService: TipoPrioridadeService,
        private processosJudiciaisApensosService: ProcessosJudiciaisApensosService,
        private router: Router,
        public arquivoService: ArquivoService,
        public utilsService: UtilsService,
        private processosJudiciaisValorService: ProcessosJudiciaisValorService,
        private divisaoDeOrgaoService: DivisaoDeOrgaoService,
        private orgaosService: OrgaosService,
        @Inject(DOCUMENT) document: Document
    ) {
        super();
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            }
        });        
    }

    getDivisaoDeOrgaoById() {
        if (this.processo.divisaoDeOrgaoId) {
            this.divisaoDeOrgaoService.getById(this.processo.divisaoDeOrgaoId).subscribe(res => this.divOrgaoDesc = res.nome);
        }
        if (this.orgaoDesc) {
            this.orgaosService.getAll().subscribe(res => this.orgaoDesc = res?.find(o => o.id === this.processo.orgaoId)?.nome);
        }
    }

    onCellClicked(event) {        
        this.openModalAddDoc(this.recursoDesdobramentoDetalhe, event.data);
    }

    goToAddDocs(recursoDesdobramentoId) {

        const navigationExtras: NavigationExtras = {
            queryParams: { 'recursoDesdobramentoId': recursoDesdobramentoId }
        };

        this.router.navigate(['dashboard/documentos/documentos-cadastro'], navigationExtras);
    }

    goToDocs() {
        this.router.navigate(['dashboard/documentos/', this.processo.numero]);
    }

    openModalComments(params) {
        const { atividade } = params;

        this.spinner.show();

        this.processosJudiciaisService.obterPorRecursoDesdobramentoId(atividade.processoJudicialRecursoDesdobramentoId).subscribe(processo => {
            this.processosJudiciaisService.obterAtividade(atividade.id, atividade.tipoOcorrencia).subscribe(res => {
                const modalRef = this.ngbModal.open(ModalDetalheComponent, {
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false
                });

                modalRef.componentInstance.modalRef = modalRef;
                modalRef.componentInstance.id = atividade.id;
                modalRef.componentInstance.tipoOcorrencia = atividade.tipoOcorrencia;
                modalRef.componentInstance.descricao = atividade.title;
                modalRef.componentInstance.tipoObjeto = atividade.tipoObjeto;
                modalRef.componentInstance.tipoStatusAtividade = res.data.tipoStatusAtividade;
                modalRef.componentInstance.responsavelNome = atividade.responsavelNome;
                modalRef.componentInstance.dataInicioPrazo = atividade.dataInicioPrazo;
                modalRef.componentInstance.estilo = atividade.estilo;
                modalRef.componentInstance.corAlertaData = atividade.corAlertaData;
                modalRef.componentInstance.numeroProcesso = processo.data.numero;
                modalRef.componentInstance.processo = processo.data;
                modalRef.componentInstance.item = res.data;
                modalRef.componentInstance.exibirAcoes = false;

                this.spinner.hide();
                modalRef.result.then(result => {

                }).catch(error => { });
            });
        });
    }

    deleteDoc(recursoDesdobramento) {
        const rows = this.agGridComponent.gridApi.getSelectedRows();

        if (rows.length > 0) {
            this.swalWithBootstrapButtons.fire({
                text: 'Deseja excluir os valores selecionados?',
                confirmButtonColor: '#FFD950 !important;',
                showCloseButton: true,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    this.spinner.show();

                    rows.forEach(c => {
                        this.processosJudiciaisValorService.delete(c.id)
                            .subscribe(res => {
                                this.processosJudiciaisValorService.obterPorRecursoDesdobramentoId(recursoDesdobramento.id)
                                    .subscribe(res => {
                                        recursoDesdobramento.valores = res.data;
                                        this.setPinnedBottom(res.data);
                                    });
                            }, error => {
                                this.toastrService.error('Falha ao deletar!', 'Atenção', { timeOut: 10000 });
                            })
                    });

                    this.spinner.hide();
                }
            });
        }
    }

    atualizarValores(recursoDesdobramentoDetalhe){
        this.spinner.show();

        this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(recursoDesdobramentoDetalhe.id)
            .subscribe(res=>{
                
                recursoDesdobramentoDetalhe.valores = res.data.valores;
                this.setPinnedBottom(res.data.valores);
                this.spinner.hide();
            }, err=>{
                this.toastrService.error('Não foi possível sincronizar as Dívidas!', 'Atenção', { timeOut: 10000 });
            })
    }

    calculadora() {
        var data = this.agGridComponent.gridApi.getSelectedRows();

        if (!data.length) {
            this.toastrService.error('Selecione pelo menos 1 valor!', 'Atenção', { timeOut: 10000 });
            return;
        }

        this.spinner.show();

        this.processosJudiciaisRecursoDesdobramentoService.calcularValores(data)
            .subscribe(res => {
                this.router.navigate([this.raiz + '/atualizacao-valores/' + res.data]);
                this.spinner.hide();

            }, error => {
                this.toastrService.error('Falha ao calcular valores!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
            })
    }

    openModalAddDoc(recursoDesdobramento, documento = null) {
        const modalRef = this.ngbModal.open(ModalAddDocValoresComponent, { size: 'md' });
        modalRef.componentInstance.documento = documento;
        modalRef.componentInstance.recursoDesdobramento = recursoDesdobramento;

        modalRef.result.then(result => {            
            this.resultConfirmDialog$.next(result);       
            this.atualizarValores(recursoDesdobramento);                
        }).catch(error => { });
    }

    openModalPedido(recursoDesdobramentoId: Guid) {
        const modalRef = this.ngbModal.open(ModalAddEditPedidoComponent, { size: 'md' });
        modalRef.componentInstance.recursoDesdobramentoId = recursoDesdobramentoId;
    }

    setPinnedBottom(valores) {
        if (valores != undefined) {
            ['valor', 'juros', 'protesto', 'jurosProtesto', 'total', 'valorAtualizadoCalculado', 
                'valorJurosMoratoriosCalculado', 'valorJurosCompensatoriosCalculado', 'valorMultaCalculado',
                'valorHonorarioCalculado','valorTotalCalculado'].forEach((field) => {
                this.pinnedBottom[field] = valores.reduce((a, b) => Number(b[field]) + a, 0);
            });
            this.agGridComponent?.gridApi?.setPinnedBottomRowData([this.pinnedBottom]);
        }
    }

    ngOnInit(): void {

        this.spinner.show();

        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
        } else {
            this.raiz = '/operacionais'
        }

        this.loadProcesso = true;
        this.processo = {};

        this.id = this.activatedRoute.snapshot.params['id'];

        this.tab = this.activatedRoute.snapshot.params['tab'];

        this.obterProcesso();
        this.atualizar();

        if (this.tab != undefined) {
            this.navProcessoSelecionada = parseInt(this.tab);
        }
    }

    atualizar() {
        this.buscarUltimasOcorrencias();

        this.buscarRecursosDesdobramentos();

        this.buscarTipoTarefas();

        this.buscarTipoPrioridade();

        this.buscarAtividadesDoProcesso();

        this.buscarApensos();
    }

    buscarApensos() {
        this.loadApensos = true;
        this.processosJudiciaisApensosService.obterApessosPorProcessoJudicialId(this.id)
            .subscribe(res => {
                if (res.success) {
                    this.apensos = res.data;
                    this.loadApensos = false;
                }
            })
    }

    buscarUltimasOcorrencias() {
        this.processosJudiciaisOcorrenciasService.obterUltimasOcorrencias(this.id, 5)
            .subscribe(res => {
                if (res.success) {
                    this.ultimasOcorrencias = res.data;
                    this.loadDadosOcorrencia = false;
                }
            });
    }

    buscarProcessoJudicialOcorrencias() {
        this.recursosDesdobramentos.forEach(recurso => {
            this.processosJudiciaisOcorrenciasService.obterOcorrencias(recurso.id)
                .subscribe(res => {
                    if (res.success) {
                        recurso.historicosProcessoOcorrencias = res.data;
                        recurso.loadHistoricoOcorrencia = false;
                    }
                })
        });
    }

    buscarRecursosDesdobramentos() {
        this.loadRecursosDesdobramentos = true;
        this.processosJudiciaisRecursoDesdobramentoService.obterPorProcesso(this.id)
            .subscribe(res => {
                if (res.success) {
                    this.recursosDesdobramentos = res.data;
                    this.recursosDesdobramentos.forEach(recurso => {
                        recurso.loadHistoricoOcorrencia = true;
                        recurso.selecionado = false;

                        if (recurso.processoJudicialRecursoDesdobramentos != undefined && recurso.processoJudicialRecursoDesdobramentos.length > 0) {
                            recurso.processoJudicialRecursoDesdobramentos.forEach(des => {
                                des.selecionado = false;
                            });
                        }
                    });

                    this.loadRecursosDesdobramentos = false;

                    this.buscarProcessoJudicialOcorrencias();

                    if (this.recursosDesdobramentos.length > 0) {
                        let item = this.recursosDesdobramentos[0];
                        this.detalheRecursoDesdobramento(item);
                    }
                }
            })
    }

    buscarTipoTarefas() {
        this.loadTipoTarefas = true;
        this.tipoTarefaService.getAllAsync().then(x => {
            this.loadTipoTarefas = false;
            this.tipoTarefas = x.data;
        });
    }

    buscarAtividadesDoProcesso() {
        this.loadAtividadesDoProcesso = true;
        this.processosJudiciaisService.getTarefasDoProcesso(this.id).subscribe(x => {

            this.atividadesDoProcessoObjeto = x.data;

            this.gerarAtividades();
        });
    }

    gerarAtividades() {

        this.atividadesDoProcesso = [];

        this.atividadesDoProcessoObjeto.forEach(c => {

            switch (c.tipoOcorrencia) {
                case TipoAtividade.Tarefa:
                    c.dataOcorrencia = c.dataTarefa;
                    c.tituloDescricao = c.descricao;
                    break;
                case TipoAtividade.Prazo:
                    c.dataOcorrencia = c.dataLimite;
                    c.tituloDescricao = c.classificacaoSubtipoAtividadeNome ?? c.titulo;
                    c.quantidadeTarefasEventosVinculados = c.quantidadeTarefasEventosVinculados;
                    break;
                case TipoAtividade.Evento, TipoAtividade.Audiencia:
                    c.dataOcorrencia = c.dataHoraInicial;
                    c.tituloDescricao = c.titulo;
                    break;
                default:
                    c.dataOcorrencia = c.dataLimite;
                    c.tituloDescricao = c.titulo;
            }

            c.editarDetalhes = false;

            if (this.atividadesDoProcessoTop.length <= 10) {
                if (c.exibirNoResumo !== undefined) {
                    if (c.exibirNoResumo)
                        this.atividadesDoProcessoTop.push(c);
                } else {
                    this.atividadesDoProcessoTop.push(c);
                }
            }

            this.atividadesDoProcesso.push(c);
        });

        this.atividadesDoProcesso = this.atividadesDoProcesso.sort(
            (objA, objB) => Number(new Date(objB.dataOcorrencia)) - Number(new Date(objA.dataOcorrencia)),
        );

        this.atividadesDoProcessoTop = this.atividadesDoProcessoTop.sort(
            (objA, objB) => Number(new Date(objB.dataOcorrencia)) - Number(new Date(objA.dataOcorrencia)),
        );

        this.quantidadeAtividadesNaoConcluida = this.atividadesDoProcesso.filter(x => !x.concluido && !x.emAtraso).length;
        this.quantidadeAtividadesConcluida = this.atividadesDoProcesso.filter(x => x.concluido).length;
        this.quantidadeAtividadesEmAtraso = this.atividadesDoProcesso.filter(x => !x.concluido && x.emAtraso).length;

        this.quantidadeAtividadesAbertas = this.quantidadeAtividadesNaoConcluida + this.quantidadeAtividadesEmAtraso;

        if (this.atividadeSelecionada == 'atividade-aberta') {
            this.atividadesDoProcesso = this.atividadesDoProcesso.filter(c => !c.concluido && !c.emAtraso);
        } else if (this.atividadeSelecionada == 'atividade-concluida') {
            this.atividadesDoProcesso = this.atividadesDoProcesso.filter(c => c.concluido)
        } else {
            this.atividadesDoProcesso = this.atividadesDoProcesso.filter(c => !c.concluido && c.emAtraso);
        }

        this.loadAtividadesDoProcesso = false;
    }

    atividadesEmAberto() {
        this.atividadeSelecionada = 'atividade-aberta';
        this.gerarAtividades();
    }

    atividadesConcluidas() {
        this.atividadeSelecionada = 'atividade-concluida';
        this.gerarAtividades();
    }

    atividadesEmAtraso() {
        this.atividadeSelecionada = 'atividade-em-atraso';
        this.gerarAtividades();
    }

    buscarTipoPrioridade() {
        this.loadTipoPrioridade = true;
        this.tipoPrioridadeService.getAllAsync().then(x => {
            this.loadTipoPrioridade = false;
            this.tipoPrioridade = x.data;
        });
    }

    defineClass(classe: string) {
        return 'badge ' + classe;
    }

    cancel() {
        this.location.back();
    }

    handleAdicionarOcorrencia(recursoDesdobramento: any) {

        const modalRef = this.ngbModal.open(ModalOcorrenciaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'processo-judiciais-detalhes';
        modalRef.componentInstance.recursoDesdobramento = recursoDesdobramento;


        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
            this.buscarProcessoJudicialOcorrencias();
            this.buscarUltimasOcorrencias();
            this.buscarAtividadesDoProcesso();
            this.buscarRecursosDesdobramentos();
        }).catch(error => { });
    }

    abrirAba(aba: number) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.navProcessoSelecionada = aba;
    }

    editarRecursoDesdobramento(recursoDesdobramento: any, index: number) {
        if (index == 0) {
            this.router.navigate([this.raiz + '/processos-judiciais/editar/' + this.id]);
        } else {
            this.router.navigate([this.raiz + '/processos-judiciais-recursos/editar/' + recursoDesdobramento.id]);
        }
    }

    ativarDesativarRecursoDesdobramento(recursoDesdobramento: any) {
        this.spinner.show();
        this.processosJudiciaisRecursoDesdobramentoService.ativarDesativar(recursoDesdobramento.id)
            .subscribe(res => {
                this.buscarRecursosDesdobramentos();
                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.swalWithBootstrapButtons.fire(
                        error.errors[0],
                        '',
                        'error'
                    );
                }
                else {
                    this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }


    changeRefresh(refresh: boolean) {

        if (refresh == true) {
            this.buscarProcessoJudicialOcorrencias();
            this.buscarUltimasOcorrencias();
            this.buscarAtividadesDoProcesso();
        }
    }

    detalhesExibirOcultar(atividade: any) {
        atividade.editarDetalhes = !atividade.editarDetalhes;
    }

    onChangeQuantidade(atividade: any, ocultar: boolean, quantidadeComentarios: number) {

        atividade.editarDetalhes = !ocultar;
        atividade.quantidadeComentarios = quantidadeComentarios;
    }

    onChangeConcluir(atividade: any, concluir: boolean) {
        if (atividade.tipoOcorrencia == 1) {
            this.onChangeConcluirTarefa(atividade, concluir);
            return;
        }

        if (atividade.tipoOcorrencia == 3) {
            this.onChangeConcluirEvento(atividade, concluir);
            return;
        }

        if (atividade.tipoOcorrencia == 4) {
            if (concluir) {
                this.swalWithBootstrapButtons.fire({
                    title: 'Justifique o motívo da conclusão do prazo',
                    input: 'text',
                    inputPlaceholder: 'Justificativa',
                    confirmButtonColor: '#FFD950 !important;',
                    showCloseButton: true,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancelar'
                }).then(
                    result => {
                        if (result.value != undefined) {
                            this.onChangeConcluirPrazo(atividade, concluir, result.value);
                        } else {
                            atividade.concluido = false;
                        }
                    }, err => {
                        console.log('err' + err);
                    }
                )
            } else {
                this.onChangeConcluirPrazo(atividade, concluir, '');
            }
            return;
        }
    }

    onChangeConcluirTarefa(atividade: any, concluirTarefa: boolean) {

        this.spinner.show();

        var data = {
            tarefaId: atividade.id,
            concluido: concluirTarefa
        }

        this.processosJudiciaisOcorrenciaTarefaService.concluirOuRemoverConcluirDaTarefa(data).subscribe(res => {

            this.buscarAtividadesDoProcesso();

            this.spinner.hide();

        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível concluir a tarefa!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    onChangeConcluirPrazo(atividade: any, concluirPrazo: boolean, justificativa: string) {
        this.spinner.show();

        var data = {
            prazoId: atividade.id,
            concluido: concluirPrazo,
            justificativa: justificativa != undefined && justificativa != null ? justificativa : null
        }

        this.processosJudiciaisOcorrenciaPrazoService.concluirOuRemoverConcluirDoPrazo(data).subscribe(res => {

            this.buscarAtividadesDoProcesso();

            this.spinner.hide();

        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível concluir o prazo!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }


    onChangeConcluirEvento(atividade: any, concluirEvento: boolean) {

        this.spinner.show();

        var data = {
            eventoId: atividade.id,
            concluido: concluirEvento
        }

        this.processosJudiciaisOcorrenciaEventoService.concluirOuRemoverConcluirDaTarefa(data).subscribe(res => {

            this.buscarAtividadesDoProcesso();

            this.spinner.hide();

        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível concluir o evento!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    excluirOcorrencia(ocorrencia: any) {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir essa ocorrência junto com seus desdobramentos?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.processosJudiciaisOcorrenciasService.delete(ocorrencia.id)
                    .subscribe(res => {

                        this.buscarProcessoJudicialOcorrencias();

                        this.spinner.hide();

                        this.swalWithBootstrapButtons.fire(
                            'Removido com sucesso!',
                            '',
                            'success'
                        );

                    }, error => {

                        this.spinner.hide();

                        this.swalWithBootstrapButtons.fire(
                            'Não foi possível remover!',
                            '',
                            'error'
                        );
                    });
            }
        });
    }

    handleAdicionarApensos() {
        const modalRef = this.ngbModal.open(ModalApensosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'processo-judiciais-detalhes';
        modalRef.componentInstance.processoJudicialId = this.id;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
            this.atualizar();
        }).catch(error => {

        });
    }

    handleRemoverApenso(processoJudicialId: string) {
        this.spinner.show();

        this.processosJudiciaisApensosService.delete(processoJudicialId)
            .subscribe(res => {
                if (res.success) {
                    this.spinner.hide();
                    this.buscarApensos();
                } else {
                    this.spinner.hide();
                    this.toastrService.error('Não foi possível remover vínculo!', 'Atenção', { timeOut: 10000 });
                }
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível remover vínculo!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    handleIncluirAtividadeAoPrazo(atividade: any) {
        this.spinner.show();
        this.processosJudiciaisOcorrenciasService.getById(atividade.processoJudicialOcorrenciaId)
            .subscribe(res => {
                let ocorrencia = res.data;
                if (res.success) {
                    this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(res.data.processoJudicialRecursoDesdobramentoId)
                        .subscribe(res => {
                            if (res.success) {
                                this.spinner.hide();
                                const modalRef = this.ngbModal.open(ModalOcorrenciaComponent, {
                                    size: 'lg',
                                    backdrop: 'static',
                                    keyboard: false
                                });

                                let recursoDesdobramento = res.data;

                                modalRef.componentInstance.modalRef = modalRef;
                                modalRef.componentInstance.page = 'processo-judiciais-detalhes';
                                modalRef.componentInstance.recursoDesdobramento = recursoDesdobramento;
                                modalRef.componentInstance.carregarEditarAtividadesPrazos(atividade, ocorrencia);

                                modalRef.result.then(result => {
                                    this.resultConfirmDialog$.next(result);
                                    this.buscarProcessoJudicialOcorrencias();
                                    this.buscarUltimasOcorrencias();
                                    this.buscarAtividadesDoProcesso();
                                }).catch(error => { });
                            }
                        })
                }
            })
    }

    limparSelecionado(processoJudicialRecursoDesdobramentos: any) {
        if (processoJudicialRecursoDesdobramentos != undefined && processoJudicialRecursoDesdobramentos.length > 0) {
            processoJudicialRecursoDesdobramentos.forEach(des => {
                des.selecionado = false;
                this.limparSelecionado(des.processoJudicialRecursoDesdobramentos);
            });
        }
    }

    detalheRecursoDesdobramento(recursoDesdobramento: any) {

        this.exibirDetalhesRecursoDesdobramento = false;

        this.recursosDesdobramentos.forEach(rec => {

            this.limparSelecionado(rec.processoJudicialRecursoDesdobramentos);

            rec.selecionado = false;
        });

        recursoDesdobramento.selecionado = true;

        this.activenavRecursosDesdobramentos = 1;

        this.recursoDesdobramentoDetalhe = recursoDesdobramento;

        console.log(recursoDesdobramento);

        this.handleAnexosRecurososDesdobramentos(recursoDesdobramento, 1);

        //this.exibirDetalhesRecursoDesdobramento = true;
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public onFileDrop($event) {
        console.log($event);
    }

    onDropRecursosDesdobramentosUpload(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            this.files.push(files[i] as File);
        }

        const fileInQueue: any = this.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleAnexosRecurososDesdobramentos(recursoDesdobramento: any, aba: any) {
        this.activenavRecursosDesdobramentos = aba;
        this.processosJudiciaisRecursoDesdobramentoService.obterArquivos(recursoDesdobramento.id)
            .subscribe(res => {
                this.recursoDesdobramentoArquivos = res.data;
                this.exibirDetalhesRecursoDesdobramento = true;
            });
        this.setPinnedBottom(recursoDesdobramento.valores);
    }

    handleAdicionarAnexos(recursoDesdobramentoDetalhe: any) {

        let formData: FormData = new FormData();
        this.files.forEach(file => {
            formData.append('files', file);
        });

        this.spinner.show();
        this.processosJudiciaisRecursoDesdobramentoService.uploadAnexo(recursoDesdobramentoDetalhe.id, formData)
            .subscribe(res => {
                this.recursoDesdobramentoArquivos = res.data;

                this.spinner.hide();

                this.uploader.clearQueue();

                this.files = [];
            })
    }

    handleDownloadArquivo(arquivo: any) {

        this.spinner.show();
        try {
            this.arquivoService.downloadArquivo(arquivo.arquivoId).subscribe(
                data => {
                    if (data.type == 3) {
                        this.spinner.hide();
                    }
                    this.utilsService.renderDownload(data, arquivo.nomeArquivo as string);
                }
            );
        } catch {
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }

    removerTag(tag: any) {
        this.spinner.show();

        this.processosJudiciaisService.removerTag(tag.id)
            .subscribe(res => {
                this.processo.tags = this.processo.tags.filter(c => c.id != tag.id);
                this.spinner.hide();
            });
    }

    obterSituacaoEBase() {

        let descricao: string = "<ul>";

        if(this.recursosDesdobramentos.length == 0){
            return "<li> Não se aplica </li>";
        }

        if (this.processo.encerrado) {
            return `<li>${this.processo.dataEncerrado != null ? "Encerrado em " + this.datePipe.transform(this.processo.dataEncerrado, 'dd/MM/yyyy') : "Encerrado"}</li>`;
        }

        this.recursosDesdobramentos.forEach((recurso, index) => {

            descricao += "<li>"
            if (recurso.acaoNome != null && recurso.acaoNome.length > 0) {
                descricao += `${recurso.acaoNome} | `;
            }

            if (recurso.situacao != null) {
                descricao += `<div class='${this.defineClass(recurso.situacaoCor)}'>`;
                descricao += recurso.situacao + "</div> |";
            }

            descricao += `Base: ${recurso.ativo ? 'Ativo' : 'Encerrado'}`;

            descricao += ` <i class='fa fa-rss'
                          [ngClass]="${recurso.monitorarPublicacao ? 'monitorar-publicacao-ativo' : 'monitorar-publicacao-inativo'}"
                          aria-hidden="true"></i>`;

            descricao += "</li>"
        });

        descricao += "</ul>"
        return descricao;
    }

    showModalPessoa(pessoaSelecionadaId: any) {
        this.spinner.show();

        this.pessoaService.getById(pessoaSelecionadaId)
            .subscribe(res => {
                this.spinner.hide();
                this.modalService.abrirModalCadastraPessoa(res.data, true, true);
            })
    }

    reativarProcesso() {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente reativar o processo?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.processosJudiciaisService.reativarProcesso(this.processo)
                    .subscribe(res => {
                        this.spinner.hide();
                        if (this.raiz == '/dashboard') {
                            this.router.navigate(['/dashboard/carteira-de-processos']);
                        } else {
                            this.router.navigate(['/operacionais/processos-judiciais']);
                        }
                    }, error => {
                        this.spinner.hide();
                        this.swalWithBootstrapButtons.fire(
                            'Erro ao reativar processo!',
                            '',
                            'error'
                        );
                    })
            }
        });
    }

    monitorarPublicacao(recursoDesdobramento) {
        const textoPergunta = recursoDesdobramento.monitorarPublicacao ?
            `Deseja realmente parar o monitoramento da publicação do recurso/desdobramento nº ${recursoDesdobramento.numero}?` :
            `Deseja reativar o monitoramento da publicação do recurso/desdobramento nº ${recursoDesdobramento.numero}?`;

        this.swalWithBootstrapButtons.fire({
            text: textoPergunta,
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                const data = {
                    id: recursoDesdobramento.id,
                    monitorarPublicacao: !recursoDesdobramento.monitorarPublicacao
                };

                this.processosJudiciaisRecursoDesdobramentoService.ativarDesativarMonitoramento(data)
                    .subscribe(res => {
                        recursoDesdobramento.monitorarPublicacao = res.data.monitorarPublicacao;
                        this.spinner.hide();
                    }, error => {
                        if (error && error.errors) {
                            this.swalWithBootstrapButtons.fire(
                                error.errors[0],
                                '',
                                'error'
                            );
                        }
                        else {
                            this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                        }
                        this.spinner.hide();
                    })
            }
        });
    }


    encerrarTodosRecursosDesdobramentos() {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente encerrar todos so recursos/desdobramentos do processo?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.processosJudiciaisRecursoDesdobramentoService.ativarDesativarPorProcesso(this.processo.id)
                    .subscribe(res => {
                        this.spinner.hide();

                    }, error => {
                        if (error && error.errors) {
                            this.swalWithBootstrapButtons.fire(
                                error.errors[0],
                                '',
                                'error'
                            );
                        }
                        else {
                            this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                        }
                        this.spinner.hide();
                    })
            }
        });
    }

    encerrarProcesso() {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente encerrar o processo?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.processosJudiciaisService.podeEncerrar(this.processo.id)
                    .subscribe(res => {
                        if (!res.data) {
                            this.spinner.hide();
                            this.swalWithBootstrapButtons.fire(
                                'Processo ainda possui atividades não concluídas!',
                                '',
                                'error'
                            );
                        } else {
                            this.processosJudiciaisService.encerrarProcesso(this.processo)
                                .subscribe(res => {
                                    this.spinner.hide();
                                    if (this.raiz == '/dashboard') {
                                        this.router.navigate(['/dashboard/carteira-de-processos']);
                                    } else {
                                        this.router.navigate(['/operacionais/processos-judiciais']);
                                    }
                                }, error => {
                                    this.spinner.hide();
                                    this.swalWithBootstrapButtons.fire(
                                        'Erro ao encerrar processo!',
                                        '',
                                        'error'
                                    );
                                })
                        }
                    })
            }
        });

    }

    handleAdicionarTag() {

        const modalRef = this.ngbModal.open(ModalAdicionarTagComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.processoJudicialId = this.id;
        modalRef.componentInstance.tagsDoProcesso = this.processo.tags;

        modalRef.result.then(result => {

            this.resultConfirmDialog$.next(result);

            this.obterProcesso();
            this.atualizar();

        }).catch(error => {

        });
    }

    onNavChange(event) {
        if (event.nextId === 1) {
            this.changeHeightResumo();
        }
    }

    changeHeightResumo() {
        setTimeout(() => {
            const hCasosDiv = document.getElementById('dadosDiv').offsetHeight;
            const hDadosDiv = document.getElementById('casosDiv').offsetHeight;

            const resumoDiv = document.getElementById('resumoDiv');

            resumoDiv.style.height = `${hCasosDiv + hDadosDiv}px`
        }, 200)
    }

    obterProcesso() {

        this.processosJudiciaisService.getById(this.id).subscribe(processo => {
            this.processo = processo.data;
            let objetoCliente = this.processo.clientes.find(x => x.principal);
            this.pessoaId = objetoCliente ? objetoCliente.pessoaId ?  objetoCliente.pessoaId : null : null;
            // this.processo.acaoNome = 'Restituição de mercadoria';
            // this.processo.cnpj = '49.189.108/0001-63';
            // this.processo.distribuidoEm = '2021-05-01T00:00:00';
            // this.processo.divisaoDeOrgaoId = '';
            // this.processo.orgaoId = '3c693880-bfdf-4136-901f-266e8c95a427';
            // this.processo.foroNome = 'Central';
            // this.processo.comarcaNome = 'Americana';
            // this.processo.ufNome = 'Acre - AC';
            // this.processo.tipoProcessoEletronicoNome = 'ESAJ';
            // this.processo.linkTribunal = 'http//www.teste.com';

            this.loadProcesso = false;
            //console.log(this.processo);
            this.getDivisaoDeOrgaoById();
            this.spinner.hide();
            this.changeHeightResumo();
            this.lerTaxasAcordo();
        });
    }

    lerTaxasAcordo() {
        this.processosJudiciaisService.obterTaxasAcordo(this.id).subscribe(res => {
            this.taxasAcordo = res.data;
        });
    }

    converterEmProcesso() {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente converter o caso em processo?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                this.processosJudiciaisService.transformarEmProcesso(this.processo.id).subscribe(res => {
                    this.processo = res.data;
                    this.spinner.hide();
                }, error => {
                    this.toastrService.error('Falha ao converter!', 'Atenção', { timeOut: 10000 });
                    this.spinner.hide();
                });
            }
        });
    }

    codigoExternoDevedor: any;

    // setCodigoExternoDevedor() {
    //     this.spinner.show();
    //     this.processo.codigoExternoDevedor = this.codigoExternoDevedor;

    //     this.processosJudiciaisService.setCodigoExternoDevedor(this.processo).subscribe(res => {
    //         this.spinner.hide();
    //     }, error => {
    //         this.processo.codigoExternoDevedor = null;
    //         this.toastrService.error('Falha ao informar código!', 'Atenção', { timeOut: 10000 });
    //         this.spinner.hide();
    //     });
    // }

    abrirModalAmigavel() {
        this.spinner.show();

        this.processoAmigavelService.getById(this.processo.processoAmigavelId).subscribe((res: ProcessoOperacaoResponse) => {
            this.modalService.abrirModalContatoProcesso(res.data);
            this.spinner.hide();
        });
    }

    migrarProcessoParaAmigavel() {
        this.spinner.show();

        this.processosJudiciaisService.migrarProcessoParaAmigavel(this.processo.id).subscribe(res => {
            this.obterProcesso();
            this.spinner.hide();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível enivar para o amigável!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    refreshTagProcesso() {
        this.processosJudiciaisService.getById(this.id).subscribe(processo => {
            this.processo = processo.data;
        });
    }
}
