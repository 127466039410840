<div class="d-flex justify-content-center" *ngIf="loadTarefa">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="row linha-detalhes-tarefa" *ngIf="!loadTarefa">    
    <div class="col-12">
        <b>Detalhes da Tarefa</b>
    </div>
    <div class="col-12">
        <i class="fas fa-calendar-check"></i> Data: {{ tarefa.dataTarefa ==null || tarefa.dataTarefa == undefined ? "A Definir..." : tarefa.dataTarefa | date: 'dd/MM/yyyy'}}
    </div>
    <div class="col-12">
        <i class="fas fa-user"></i> Responsável: {{tarefa.responsavelNome}}
    </div>
    <div class="col-12 d-flex align-items-center">
        <prioridades-icone prioridade="{{ tarefa.prioridade }}" class="d-flex"></prioridades-icone> Prioridade: {{tarefa.prioridadeDescricao}}
    </div>        
    <div class="col-12">
        <comentarios-detalhes [comentarios]="comentarios"></comentarios-detalhes>
        <div class="card-body" *ngIf="loadAtach">
            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                (fileOver)="fileOverBase($event)" [uploader]="uploader" class="dropzone"
                (click)="fileInput.click()">
                <span class=" span-icon fas fa-cloud-download-alt"></span>
                <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
                    carregar</span>
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none"
                    multiple (onFileSelected)="onDrop($event)" />
            </div>

            <table class="table" *ngIf="uploader.queue.length > 0">
                <thead>
                    <tr>
                        <th width="45%">Nome</th>                        
                        <th>Tamanho</th>                        
                        <th>Status</th>
                        <th>Acões</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>{{ item?.file?.name }}</strong></td>                        
                        <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 |
                            number:'.2' }} MB
                        </td>                        
                        <td class="text-center">
                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            <span *ngIf="item.isCancel"><i
                                    class="glyphicon glyphicon-ban-circle"></i></span>
                            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                        </td>
                        <td nowrap>                            
                            <button type="button" class="btn btn-danger" (click)="handleRemoveFile(item)">
                                <span class="glyphicon glyphicon-trash"></span> Remover
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">            
            <div class="col-10">
                <label class="form-label">Adicionar comentário</label>
                <input type="text" class="form-control" placeholder="Comentário" [(ngModel)]="comentarioDescricao" maxlength="1000"><i class="fas fa-paperclip atachment" (click)="loadAtach = !loadAtach"></i>
            </div>
            <div class="col-2">
                <label class="form-label">&nbsp;</label>
                <div class="btn btn-primary form-control" (click)="handleInserirComentarioForm()">
                    Comentar
                </div>
            </div>
        </div>        
        <hr />
        <div class="d-flex justify-content-end">
            <button type="button" (click)="handleOcultar()" class="btn btn-light mr-3">Ocultar detalhes</button>            
        </div>
    </div>    
</div>