export enum TipoStatusAtividade {
    NaoIniciada = 1,
    EmExecucao = 2,
    EmRevisao = 3,
    DevolvidoParaAjuste = 4,
    RevisadoELiberado = 5,
    LiberadoParaProtocolar = 6,
    Concluido = 7,
    Encerrado = 8
}

export const TipoStatusAtividadeLabel = new Map<TipoStatusAtividade, string>([

    [TipoStatusAtividade.NaoIniciada, "Não iniciada"],
    [TipoStatusAtividade.EmExecucao, "Em execução"],
    [TipoStatusAtividade.EmRevisao, "Em revisão"],
    [TipoStatusAtividade.DevolvidoParaAjuste, "Devolvido para ajuste"],
    [TipoStatusAtividade.RevisadoELiberado, "Revisado e liberado"],
    [TipoStatusAtividade.LiberadoParaProtocolar, "Liberado para protocolar"],
    [TipoStatusAtividade.Concluido, "Concluído"],
    [TipoStatusAtividade.Encerrado, "Encerrado"],
]);
