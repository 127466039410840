import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SelectList } from '../models/select-list';
import { Tipo, TipoLabel } from '../enums/documento/tipo.enum';

@Injectable({
    providedIn: 'root'
})
export class DocumentoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'documento/';
    
    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllByTipo(tipo): Observable<any> {

        return this.http.get(environment.api + this.endpoint + `tipo/${tipo}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getTipo() {
        var tiposDocumento: SelectList[] = [];
        
        tiposDocumento.push(new SelectList(Tipo.Pessoa.toString(), TipoLabel.get(Tipo.Pessoa)));
        tiposDocumento.push(new SelectList(Tipo.Ocorrencia.toString(), TipoLabel.get(Tipo.Ocorrencia)));
        tiposDocumento.push(new SelectList(Tipo.Processo.toString(), TipoLabel.get(Tipo.Processo)));
        tiposDocumento.push(new SelectList(Tipo.Atividade.toString(), TipoLabel.get(Tipo.Atividade)));
        tiposDocumento.push(new SelectList(Tipo.Juridico.toString(), TipoLabel.get(Tipo.Juridico)));
        
        return tiposDocumento;
    }
}

