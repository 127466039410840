import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AcaoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'acao/';

    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllAsync() {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

    getById(acaoId): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'getById/' + acaoId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(acao): Observable<any> {
        return this.http.post(environment.api + this.endpoint, acao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(acao): Observable<any> {
        return this.http.put(environment.api + this.endpoint, acao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(acaoId): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + acaoId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByIdAsync(id) {
        return this.http
            .get(environment.api + this.endpoint + `getById/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

    obterAcaoFiltroNomeAsync(busca) {
        return this.http
            .get(environment.api + this.endpoint + "obter-acao-filtro/" + busca)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }
}
