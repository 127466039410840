import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { UsuarioService } from '../services/usuario.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    modulos: any[] = [];
    user_ambiente: string;

    constructor(private authService: AuthService,
        private appService: AppService,
        private usuarioService: UsuarioService,
        private router: Router)
    {
        this.user_ambiente = this.authService.getAmbiente();
    }

    async canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot) {

        if(this.modulos.length == 0)
        {
            await this.usuarioService.getPermissoes().then(res =>{
                this.modulos = res["data"];
            });
            return this.verifyModules(this.modulos,state);
        }
        else{

            return this.verifyModules(this.modulos,state);
        }

        var teste = "";
    }

    verifyModules(modulos,state)
    {
      var existeModulo = false;
      modulos.forEach(x => {

          if(x.url == state.url){
            if(this.verificaModuloAmbiente(x)) {
              existeModulo = true;
            }
          }
          else{
              x.filhos.forEach(filho => {
                  if(filho.url == state.url){
                      if(this.verificaModuloAmbiente(filho)) {
                        existeModulo = true;
                      }
                  }
              });
          }
      });

      if(existeModulo){
          return true;
      }
      else{
          this.router.navigate(['home']);
          return false;
      }
    }

    verificaModuloAmbiente(modulo) {
        return modulo.moduloAmbiente.filter(ma => {
            return ma.ambiente == this.user_ambiente;
        }).length > 0;
    }

}
