import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/services/shared/modal-service';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { ModalOcorrenciaComponent } from '../../processos-judiciais/processos-judiciais-detalhes/modal-ocorrencia/modal-ocorrencia.component';
import { Subject } from 'rxjs';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import Swal from 'sweetalert2';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { ModalDetalheComponent } from 'src/app/views/dashboard/agenda/modal-detalhe/modal-detalhe.component';

@Component({
    selector: 'ocorrencias-detalhes',
    templateUrl: './ocorrencias-detalhes.component.html',
    styleUrls: ['./ocorrencias-detalhes.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})

export class OcorrenciasDetalhesComponent implements OnInit, OnChanges {

    @Input() recursoDesdobramentoId: any;
    
    @Output() changeRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

    public recursoDesdobramento: any = { loadHistoricoOcorrencia: true };
    public resultConfirmDialog$ = new Subject<ResultadoModal>();

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    arrayToggleTipoOcorrencia = [];

    load = true;

    constructor(
        private modalService: ModalService,
        private ngbModal: NgbModal,
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private processosJudiciaisService: ProcessosJudiciaisService,
        private spinner: NgxSpinnerService,
    ) { }

    ngOnInit(): void {
        
        this.buscarOcorrencias();        
    }

    countLines(i) {
        const el = document.getElementById('tipo-descricao ' + i);
        
        if(el != undefined && el != null){
            const divHeight = el.offsetHeight
            const lineHeight = parseInt(el.style.lineHeight);
            return divHeight / lineHeight;
        }

        return 0;
    }

    toogleTipoOcorrencia(i) {
        this.countLines(i);
        this.recursoDesdobramento.historicosProcessoOcorrencias[i].showTipoOcorrencia = !this.recursoDesdobramento.historicosProcessoOcorrencias[i].showTipoOcorrencia;
    }

    buscarOcorrencias() {
        
        this.processosJudiciaisOcorrenciasService.obterOcorrencias(this.recursoDesdobramentoId)
            .subscribe(res => {
                if (res.success) {
                    this.recursoDesdobramento.historicosProcessoOcorrencias = res.data;
                    setTimeout(() => {
                        this.recursoDesdobramento.historicosProcessoOcorrencias =res.data.map((item, i) => {return {...item, showTipoOcorrencia: true, qtdLines: this.countLines(i)}});
                        setTimeout(() => {
                            this.recursoDesdobramento.historicosProcessoOcorrencias = this.recursoDesdobramento.historicosProcessoOcorrencias.map((item) => {return {...item, showTipoOcorrencia: false}});
                            setTimeout(() => {this.load = false;}, 500)
                        }, 500)    
                    }, 500)                
                    this.recursoDesdobramento.loadHistoricoOcorrencia = false;
                }
            })
    }

    detalhesExibirOcultar(atividade: any) {
        console.log(atividade);
        atividade.editarDetalhes = !atividade.editarDetalhes;        
    }

    onChangeQuantidade(atividade: any, ocultar: boolean, quantidadeComentarios: number) {

        atividade.editarDetalhes = !ocultar;
        atividade.quantidadeComentarios = quantidadeComentarios;
    }

    ngOnChanges(){
        this.buscarOcorrencias();        
    }

    editarOcorrencia(ocorrencia: any) {

        this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(ocorrencia.processoJudicialRecursoDesdobramentoId)
            .subscribe(res => {
                if(res.success){
                    this.spinner.show();

                    const modalRef = this.ngbModal.open(ModalOcorrenciaComponent, {
                        size: 'lg',
                        backdrop: 'static',
                        keyboard: false
                    });

                    let recursoDesdobramento = res.data;

                    modalRef.componentInstance.modalRef = modalRef;
                    modalRef.componentInstance.page = 'processo-judiciais-detalhes';
                    modalRef.componentInstance.recursoDesdobramento = recursoDesdobramento;
                    modalRef.componentInstance.editar(ocorrencia.id);
                    

                    modalRef.result.then(result => {
                        this.resultConfirmDialog$.next(result);
                        this.buscarOcorrencias();
                        this.changeRefresh.emit(true);
                    }).catch(error => { });
                }
            })

    }

    excluirOcorrencia(ocorrencia: any) {
        
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir essa ocorrência junto com seus desdobramentos?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.processosJudiciaisOcorrenciasService.delete(ocorrencia.id)
                    .subscribe(res => {

                        this.buscarOcorrencias();
                        this.changeRefresh.emit(true);

                        this.spinner.hide();

                        this.swalWithBootstrapButtons.fire(
                            'Removido com sucesso!',
                            '',
                            'success'
                        );

                    }, error => {

                        this.spinner.hide();

                        this.swalWithBootstrapButtons.fire(
                            'Não foi possível remover!',
                            '',
                            'error'
                        );
                    });
            }
        });
    }
    
    excluirAtividade(params){
        const {recursoDesdobramento, atividade} = params;
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir o registro?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.processosJudiciaisOcorrenciasService.getById(recursoDesdobramento.id)
                    .subscribe(res => {

                        if(res.success){
                        let ocorrencia = res.data;
                        
                        if(atividade.tipoOcorrencia == 1){
                            res.data.historicosManuais.forEach(historico => {
                                if(historico.id == atividade.id){
                                    historico.isDelete = true;
                                }
                            });
                        }

                        if(atividade.tipoOcorrencia == 2){
                            res.data.tarefas.forEach(tarefa => {
                                if(tarefa.id == atividade.id){
                                    tarefa.isDelete = true;
                                }
                            });
                        }

                        if(atividade.tipoOcorrencia == 3){
                            res.data.eventos.forEach(evento => {
                                if(evento.id == atividade.id){
                                    evento.isDelete = true;
                                }
                            });
                        }

                        if(atividade.tipoOcorrencia == 4){
                            res.data.prazos.forEach(prazo => {
                                if(prazo.id == atividade.id){
                                    prazo.isDelete = true;
                                }
                            });
                        }

                        if(atividade.tipoOcorrencia == 5){
                            res.data.audiencias.forEach(audiencia => {
                                if(audiencia.id == atividade.id){
                                    audiencia.isDelete = true;
                                }
                            });
                        }

                        if(atividade.tipoOcorrencia == 6){
                            res.data.formularios.forEach(formulario => {
                                if(formulario.id == atividade.id){
                                    formulario.isDelete = true;
                                }
                            });
                        }

                        this.processosJudiciaisOcorrenciasService.update(ocorrencia)
                            .subscribe(res=>{
                                if(res.success){
                                    this.swalWithBootstrapButtons.fire(
                                        'Removido com sucesso!',
                                        '',
                                        'success'
                                    );

                                    this.buscarOcorrencias();
                                    this.changeRefresh.emit(true);

                                    this.spinner.hide();   
                                }else{
                                    this.spinner.hide();
        
                                    this.swalWithBootstrapButtons.fire(
                                        'Não foi possível remover!',
                                        '',
                                        'error'
                                    );
                                }
                            },error=>{
                                this.spinner.hide();

                                this.swalWithBootstrapButtons.fire(
                                    'Não foi possível remover!',
                                    '',
                                    'error'
                                );
                            })
                        }else{
                            this.spinner.hide();

                            this.swalWithBootstrapButtons.fire(
                                'Não foi possível remover!',
                                '',
                                'error'
                            );
                        }                                                                    

                    }, error => {

                        this.spinner.hide();

                        this.swalWithBootstrapButtons.fire(
                            'Não foi possível remover!',
                            '',
                            'error'
                        );
                    });
            }
        });        
    }

    editarAtividade(params){
        const {recursoDesdobramento, atividade} = params;
        this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(recursoDesdobramento.processoJudicialRecursoDesdobramentoId)
            .subscribe(res => {
                if(res.success){
                    this.spinner.show();

                    const modalRef = this.ngbModal.open(ModalOcorrenciaComponent, {
                        size: 'lg',
                        backdrop: 'static',
                        keyboard: false
                    });

                    let rec = res.data;

                    modalRef.componentInstance.modalRef = modalRef;
                    modalRef.componentInstance.page = 'processo-judiciais-detalhes';
                    modalRef.componentInstance.recursoDesdobramento = rec;
                    modalRef.componentInstance.editarAtividade(recursoDesdobramento.id, atividade); 

                    modalRef.result.then(result => {
                        this.resultConfirmDialog$.next(result);
                        this.buscarOcorrencias();
                        this.changeRefresh.emit(true);
                    }).catch(error => { });
                }
            })
    }

    expandirAtividade(atividade:any){        
        return [2,3,4].includes(atividade.tipoOcorrencia);
    }

    openModalComments(params) {  
        
        const {recursoDesdobramento, atividade} = params;           
        this.spinner.show();

        this.processosJudiciaisService.obterPorRecursoDesdobramentoId(recursoDesdobramento.processoJudicialRecursoDesdobramentoId).subscribe(processo => {
            this.processosJudiciaisService.obterAtividade(atividade.id, atividade.tipoOcorrencia).subscribe(res => {
                const modalRef = this.ngbModal.open(ModalDetalheComponent, {
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false
                });

                modalRef.componentInstance.modalRef = modalRef;
                modalRef.componentInstance.id = atividade.id;
                modalRef.componentInstance.tipoOcorrencia = atividade.tipoOcorrencia;
                modalRef.componentInstance.descricao = atividade.title;
                modalRef.componentInstance.tipoObjeto = atividade.tipoObjeto;
                modalRef.componentInstance.tipoStatusAtividade = res.data.tipoStatusAtividade;
                modalRef.componentInstance.responsavelNome = atividade.responsavelNome;
                modalRef.componentInstance.dataInicioPrazo = atividade.dataInicioPrazo;
                modalRef.componentInstance.estilo = atividade.estilo;
                modalRef.componentInstance.corAlertaData = atividade.corAlertaData;
                modalRef.componentInstance.numeroProcesso = processo.data.numero;
                modalRef.componentInstance.processo = processo.data;
                modalRef.componentInstance.item = res.data;
                modalRef.componentInstance.exibirAcoes = false;

                this.spinner.hide();
                modalRef.result.then(result => {
                    this.buscarOcorrencias();
                            this.changeRefresh.emit(true);
                }).catch(error => { });
            });
        });
    }
}
