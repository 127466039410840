<div class="d-flex justify-content-center">
    <div>
        <h4 class="font-weight-bold text-center pt-3 mb-3">
            Selecione o ambiente:
        </h4>
        <button type="button" class="btn btn-lg btn-primary" (click)="selecionaAmbiente(1)">
            Amigável
        </button>
        <button type="button" class="btn btn-lg btn-primary" (click)="selecionaAmbiente(2)">
            Jurídico
        </button>
    </div>
</div>