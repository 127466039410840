<div class="card-atendimento" (mouseover)="hideButtons = false" (mouseleave)="hideButtons = true">
    <div class="row">
        <div class="col-11">
            <h5 class="card-title">
                <i class="fas fa-users"></i> Atendimentos
            </h5>
        </div>
        <div class="col-1 d-flex justify-content-between align-items-center font-weight-bold pt-3">
            <div class="btn-adicionar" routerLink="{{raiz}}/atendimento/criar-atendimento/{{tipo}}/{{referenciaId}}">
                <i class="fas fa-plus"></i>
            </div>           
            <div class="btn-detalhes" routerLink="{{raiz}}/atendimento/atendimentos-lista/{{tipo}}/{{referenciaId}}">
                <i class="fas fa-folder-open"></i>
            </div>
        </div>        
    </div>
    <div class="d-flex justify-content-center" *ngIf="loadQuantidade">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- <div class="row" *ngIf="!loadQuantidade && !recolhido">
        <div class="col-12" *ngIf="quantidade == 0">Sem registros.</div>
        <div class="col-12" *ngIf="quantidade == 1"><a routerLink="{{raiz}}/atendimento/atendimentos-lista/{{tipo}}/{{referenciaId}}">1 Atendimento encontrado.</a></div>
        <div class="col-12" *ngIf="quantidade > 1"><a routerLink="{{raiz}}/atendimento/atendimentos-lista/{{tipo}}/{{referenciaId}}">{{quantidade}} Atendimentos encontrados.</a></div>
    </div> -->
    <app-atendimentos-lista [tipo]="tipo" [referenciaId]="referenciaId" [raiz]="raiz" [hasFilter]="hasFilter"></app-atendimentos-lista>
</div>