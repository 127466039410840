<div class="row" *ngFor="let recursoDesdobramento of recursosDesdobramentos; let index = i" id="recursoDesdobramento-{{index}}">
    <div *ngIf="nivel > 0" class="arvore-vertical arvore-horizontal" [ngClass]="'col-'+nivel"></div>
    <div class="recurso-linha cursor-pointer" 
                    [ngClass]="gerarCssColuna(recursoDesdobramento.selecionado)" 
                    (click)="detalheRecursoDesdobramento(recursoDesdobramento)">
        <i class="fa fa-rss"
            [ngClass]="recursoDesdobramento.monitorarPublicacao ? 'monitorar-publicacao-ativo' : 'monitorar-publicacao-inativo'"
            aria-hidden="true"></i>
        {{recursoDesdobramento.numero != undefined && recursoDesdobramento.numero != null? recursoDesdobramento.numero + " - " : ""}}
        {{recursoDesdobramento.acaoNome != null ? recursoDesdobramento.acaoNome + " /": ""}}
        {{ recursoDesdobramento.instanciaNome}}{{recursoDesdobramento.tipoRecursoDesdobramentoNome !=undefined? " - " + recursoDesdobramento.tipoRecursoDesdobramentoNome: ""}}
        <div class="ml-5 historico-situacao" *ngIf="recursoDesdobramento.situacao != undefined && recursoDesdobramento.situacao != null" [ngClass]="defineClass(recursoDesdobramento.situacaoCor)">
            {{ recursoDesdobramento.situacao }}
        </div>
    </div>
    <div class="col-1 recurso-linha" 
        [ngClass]="recursoDesdobramento.selecionado ? 'recurso-selecionado': ''" 
        (click)="detalheRecursoDesdobramento(recursoDesdobramento)">        
    </div>
    <div class="col-1" [ngClass]="recursoDesdobramento.selecionado? 'recurso-selecionado': ''">
        <div ngbDropdown>
            <button type="button" class="btn btn-primary mt-1" 
                id="dropDownMenuAcoesRecursosDesdobramentos" 
                (click)="detalheRecursoDesdobramento(recursoDesdobramento)" ngbDropdownToggle>
                <i class="fas fa-bolt"></i> Ações
            </button>
            <div ngbDropdownMenu aria-labelledby="dropDownMenuAcoesRecursosDesdobramentos">
                <a ngbDropdownItem routerLink="{{raiz}}/processos-judiciais-desdobramento/{{id}}">
                    <i class="fas fa-plus"></i> Incluir desdobramento</a>
                <a ngbDropdownItem class="cursor-pointer" (click)="handleAnexosRecurososDesdobramentos(recursoDesdobramento,5)">
                    <i class="fas fa-plus"></i> Incluir arquivo
                </a>
                <div class="dropdown-divider"></div>
                <a ngbDropdownItem class="cursor-pointer" (click)="handleAdicionarOcorrencia(recursoDesdobramento)">
                    <i class="fas fa-plus"></i> Adicionar ocorrência
                </a>
                <a ngbDropdownItem class="cursor-pointer" (click)="detalheRecursoDesdobramento(recursoDesdobramento)">
                    <i class="fas fa-align-left"></i> Exibir ocorrência
                </a>
                <a ngbDropdownItem class="cursor-pointer" (click)="monitorarPublicacao(recursoDesdobramento)">
                    <i class="fa fa-rss"
                        [ngClass]="recursoDesdobramento.monitorarPublicacao ? 'monitorar-publicacao-ativo' : 'monitorar-publicacao-inativo'"
                        aria-hidden="true"></i> Monitorar Publicação
                </a>
                <div class="dropdown-divider"></div>
                <a ngbDropdownItem routerLink="{{raiz}}/processos-judiciais-recursos/editar/{{recursoDesdobramento.id}}">
                    <i class="far fa-edit"></i> Editar
                </a>
                <a ngbDropdownItem class="cursor-pointer" (click)="ativarDesativarRecursoDesdobramento(recursoDesdobramento)" *ngIf="recursoDesdobramento.ativo">
                    <i class="fas fa-lock"></i> Encerrar
                </a>
                <a ngbDropdownItem class="cursor-pointer" (click)="ativarDesativarRecursoDesdobramento(recursoDesdobramento)" 
                    *ngIf="!recursoDesdobramento.ativo">
                    <i class="fas fa-lock-open"></i> Ativar
                </a>
            </div>
        </div>
    </div>
    <div class="col-12 arvore-pai" *ngIf="recursoDesdobramento.processoJudicialRecursoDesdobramentos !=null && recursoDesdobramento.processoJudicialRecursoDesdobramentos.length > 0">
        <app-recursos-e-desdobramentos
            [id]="id"
            [raiz]="raiz"
            [recursosDesdobramentos]="recursoDesdobramento.processoJudicialRecursoDesdobramentos"
            [nivel]="nivel + 1"
            (detalheRecursoDesdobramentoEvent)="detalheRecursoDesdobramento($event)"
            (handleAdicionarOcorrenciaEvent)="handleAdicionarOcorrencia($event)"
            (monitorarPublicacaoEvent)="monitorarPublicacao($event)"
            (ativarDesativarRecursoDesdobramentoEvent)="ativarDesativarRecursoDesdobramento($event)"
            >
        </app-recursos-e-desdobramentos>
    </div>
</div>