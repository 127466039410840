import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { HonorarioService } from 'src/app/services/honorario.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploader } from 'ng2-file-upload';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { HonorariosService } from '../honorarios.service';

@Component({
    selector: 'app-honorarios-por-valor',
    templateUrl: './honorarios-por-valor.component.html',
    styleUrls: [
        './honorarios-por-valor.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class HonorariosPorValorComponent implements OnInit {

    constructor(
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private location: Location,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        private honorarioService: HonorarioService,
        private router: Router,
        private honorariosService: HonorariosService
    ) { }

    public isAdd: boolean = false;
    public honorariosObject: any;
    public honorariosDetalhe: any = [];
    public tipo: string;
    public tipoHonorario: string;
    public referenciaId: string;
    public atendimentoId: string;
    public processoJudicialId: string;
    public quantidadeLinhasAdd: number = 1;
    public clienteSelected: any;
    public raiz: string;
    public id: string;

    ngOnInit(): void {
        this.spinner.show();

        this.id = this.route.snapshot.params['id'];

        if (this.id == undefined) {
            this.isAdd = true;
            this.tipo = this.route.snapshot.params['tipo'];
            this.tipoHonorario = this.route.snapshot.params['tipoHonorario'];
            this.referenciaId = this.route.snapshot.params['referenciaId'];
            this.atendimentoId = this.route.snapshot.params['atendimentoId'];
            this.processoJudicialId = this.route.snapshot.params['processoJudicialId'];

            this.honorariosObject = [{
                isAdd: true,
                quantidadeMesesRepetir: 1,
                repetirMensalmente: false,
                tipoReferencia: this.tipo,
                referenciaId: this.referenciaId,
                atendimentoId: this.atendimentoId,
                files: [],
                uploader: new FileUploader({
                    disableMultipart: true,
                })
            }];

            this.honorarioService.buscarDetalhesHonorario(this.tipo, this.referenciaId)
                .subscribe(res => {
                    this.honorariosDetalhe = res.data;

                    this.clienteSelected = this.honorariosDetalhe.nomeCliente[0];

                    this.spinner.hide();
                })
        } else {
            this.honorarioService.obterPorId(this.id).subscribe(res => {
                console.log(res.data);

                this.tipo = res.data.tipoReferencia;
                this.tipoHonorario = res.data.tipoHonorario.toString();
                this.referenciaId = res.data.referenciaId;
                this.atendimentoId = null;
                this.processoJudicialId = res.data.processoJudicialId;

                let dataVencimento = new Date(res.data.vencimento);

                let vencimento = {
                    year: dataVencimento.getFullYear(),
                    month: dataVencimento.getMonth() + 1,
                    day: dataVencimento.getDate()
                }

                this.honorariosObject = [{
                    isAdd: false,
                    id: res.data.id,
                    quantidadeMesesRepetir: 1,
                    repetirMensalmente: false,
                    tipoReferencia: this.tipo,
                    referenciaId: this.referenciaId,
                    atendimentoId: this.atendimentoId,
                    vencimento: vencimento,
                    valorHonorario: res.data.valorHonorario,
                    descricao: res.data.descricao,
                    files: [],
                    uploader: new FileUploader({
                        disableMultipart: true,
                    })
                }];

                this.honorarioService.buscarDetalhesHonorario(this.tipo, this.referenciaId)
                    .subscribe(res => {
                        this.honorariosDetalhe = res.data;

                        this.clienteSelected = this.honorariosDetalhe.nomeCliente[0];

                        this.spinner.hide();
                    })

                this.spinner.hide();

            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível carregar!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            })
        }

        if (this.atendimentoId == undefined) this.atendimentoId = null;

        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
        } else {
            this.raiz = '/operacionais'
        }
    }

    onDrop(honorario: any, files: FileList) {
        for (let i = 0; i < files.length; i++) {
            honorario.files.push(files[i] as File);
        }

        const fileInQueue: any = honorario.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    onSubmit() {
        this.spinner.show();

        this.toastrService.clear();        

        this.honorariosObject.forEach(c => {

            if (typeof c.vencimento == 'undefined') {
                this.toastrService.error('Data é obrigatória!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                return;
            }

            if (typeof c.valorHonorario == 'undefined') {
                this.toastrService.error('Valor é obrigatório!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                return;
            }

            if (typeof c.descricao == 'undefined') {
                this.toastrService.error('Descrição é obrigatório!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                return;
            }

            c.vencimento = new Date(c.vencimento.year, c.vencimento.month - 1, c.vencimento.day);
        });

        if (this.tipo != "4") {
            this.honorarioService.addPorValor(this.honorariosObject)
                .subscribe(res => {
                    this.spinner.hide();
                    this.location.back();
                    this.toastrService.success("Honorário cadastrado.", "Sucesso");
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar o Honorário!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        } else {
            //Tipo 4 = Custas recurso e desdobramento
            //api tratada só para esse tipo
            let i = 0;
            this.honorariosObject.forEach(async honorario => {

                let formData: FormData = new FormData();

                if(!this.isAdd){
                    formData.append('id', honorario.id);    
                }

                formData.append('vencimento', honorario.vencimento.getDate() + "/" + (honorario.vencimento.getMonth() + 1) + "/" + honorario.vencimento.getFullYear() + " 12:00:00");
                formData.append('valorHonorario', honorario.valorHonorario.toString().replace(".", ","));
                formData.append('descricao', honorario.descricao);
                formData.append('repetirMensalmente', honorario.repetirMensalmente);
                formData.append('quantidadeMesesRepetir', honorario.quantidadeMesesRepetir);
                formData.append('referenciaId', this.referenciaId);
                formData.append('tipoReferencia', this.tipo);
                formData.append('tipoHonorario', this.tipoHonorario);

                honorario.files.forEach(file => {
                    formData.append('files', file);
                });                

                if (this.isAdd){
                    await this.honorarioService.addPorValorComArquivo(formData)
                    .toPromise()
                    .catch(error => {
                        if (error && error.errors) {
                            this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                        }
                        else {
                            this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                        }
                        this.spinner.hide();
                        this.cancel();
                    });
                }else{
                    await this.honorarioService.atualizarPorValorComArquivo(formData)
                        .toPromise()
                        .catch(error => {
                            if (error && error.errors) {
                                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                            }
                            else {
                                this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                            }
                            this.spinner.hide(); 
                            i=10000;                            
                        });
                }
                i++;

                if (i == this.honorariosObject.length) {
                    this.spinner.hide();
                    this.cancel();
                }
            });
        }
    }

    adicionarHonorario() {
        for (let i = 0; i < this.quantidadeLinhasAdd; i++) {
            this.honorariosObject.push({
                quantidadeMesesRepetir: 1,
                repetirMensalmente: false,
                tipoReferencia: this.tipo,
                referenciaId: this.referenciaId,
                files: [],
                uploader: new FileUploader({
                    disableMultipart: true,
                })
            });
        }
    }

    removerHonorario(honorarioIndex) {
        this.honorariosObject.forEach((element, index) => {
            if (index == honorarioIndex) {
                this.honorariosObject.splice(index, 1);
            }
        });
    }

    cancel() {
        this.location.back();
    }

    obterNome() {
        return this.honorariosService.obterNome(this.tipoHonorario);
    }

}
