import { Component, Input, OnInit } from '@angular/core';
import { NotaDeDebitoService } from 'src/app/services/notadedebito.service';
import { ExportNotaDebitosService } from '../export-nota-debito';

@Component({
    selector: 'app-lista-nota-de-debito',
    templateUrl: './lista-nota-de-debito.component.html',
    styleUrls: ['./lista-nota-de-debito.component.scss']
})
export class ListaNotaDeDebitoComponent implements OnInit {

    public notasDeDebitos: any;

    @Input() tipo: string;
    @Input() referenciaId: string;

    public load:boolean = true;

    constructor(
        private notaDeDebitoService: NotaDeDebitoService,
        private eportNotaDebitosService: ExportNotaDebitosService,
    ) { }

    ngOnInit(): void {
      if(this.referenciaId)
        this.buscarNotaDeDebitos();
      else
        this.buscarNotaDeDebitosTipos();
    }

    buscarNotaDeDebitos() {
        this.load = true;
        this.notaDeDebitoService.obterNotas(this.tipo, this.referenciaId)
            .subscribe(res=>{
                this.notasDeDebitos = res.data;
                this.load = false;
            })
    }
    buscarNotaDeDebitosTipos() {
      this.load = true;
      this.notaDeDebitoService.obterNotasTipos(this.tipo)
          .subscribe(res=>{
              this.notasDeDebitos = res.data;
              this.load = false;
          })
  }

    exportPdf(nota: any){
        this.notaDeDebitoService.getById(nota.id)
            .subscribe(res=>{
                this.eportNotaDebitosService.exportPdf(res.data);
            })
    }
}
