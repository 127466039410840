import { AcordoListaResponse } from './../models/operacionais/acordo/acordo-lista-response';
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { Acordo } from '../models/operacionais/acordo/acordo';
import { AcordoResponse } from '../models/operacionais/acordo/acordo-response';
import { AcordoListaCredorResponse } from '../models/operacionais/acordo/acordo-lista-credor-response';

@Injectable({
    providedIn: 'root'
})
export class AcordoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'acordo/';

    getAllByProcesso(guid: Guid): Observable<AcordoListaResponse> {
      return this.http.get(environment.api + this.endpoint + 'processo/' + guid)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getAllByCredor(): Observable<AcordoListaCredorResponse> {
        return this.http.get(environment.api + this.endpoint + 'credor')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data): Observable<Acordo> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(guid: Guid): Observable<AcordoResponse> {
      return this.http.get(environment.api + this.endpoint + guid)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    aprovarAcordo(id: Guid , aprovar:Boolean):Observable<AcordoResponse> {
      return this.http.put(environment.api + this.endpoint + `aprovar/${id}?aprovarOuReprovar=${aprovar}`,null)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }




    gerarDescumprimentoAcordo(id: Guid):Observable<any> {
      return this.http.post(environment.api + this.endpoint + `gerarDescumprimentoAcordo/${id}`,null)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    aprovarDescumprimentoAcordo(id: Guid , aprovar:Boolean):Observable<any> {
      return this.http.put(environment.api + this.endpoint + `descumprimentoAcordo/aprovar/${id}?aprovarOuReprovar=${aprovar}`,null)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }





    isEnviarAprovacaoCredor(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + "is-enviar-aprovacao-credor", data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }


}
