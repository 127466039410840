import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ServiceBase } from './service.base';
import { ProcessoDividaResponse } from '../models/operacionais/operacao/processo-divida-response';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ModalAdicionarDividaPagamentosComponent } from '../views/operacionais/operacao-de-processos/modal-adicionar-divida-pagamentos/modal-adicionar-divida-pagamentos.component';

@Injectable({
    providedIn: 'root'
})
export class DividaPagamentosService extends ServiceBase{

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'dividapagamento/';

    add(dividaPagamento: any) : Observable<ProcessoDividaResponse> {
        return this.http.post(environment.api + this.endpoint + 'add/', dividaPagamento)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }


    addTaxaAdministrativa(dividaPagamento: any) : Observable<ProcessoDividaResponse> {
        return this.http.post(environment.api + this.endpoint + 'add-taxa-administrativa/', dividaPagamento)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }
    addDesconto(dividaPagamento: any) : Observable<ProcessoDividaResponse> {
        return this.http.post(environment.api + this.endpoint + 'add-desconto/', dividaPagamento)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    getAllByOcorrencia(ocorrenciaId: Guid) : Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'divida-ocorrencia/' + ocorrenciaId)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    delete(idOcorrencia: Guid, idProcesso: Guid): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + 'delete/' + idOcorrencia +'/'+ idProcesso)
           .pipe(
              map(this.extractData),
              catchError(this.serviceError)
           );
     }

     update(dividas: any): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'update', dividas)
           .pipe(
              map(this.extractData),
              catchError(this.serviceError)
           );
     }

     deleteDesconto(idOcorrencia: Guid, idProcesso: Guid): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + 'delete-desconto/' + idOcorrencia +'/'+ idProcesso)
           .pipe(
              map(this.extractData),
              catchError(this.serviceError)
           );
     }

     updateDesconto(dividas: any): Observable<any> {

        return this.http.put(environment.api + this.endpoint + 'update-desconto', dividas)
           .pipe(
              map(this.extractData),
              catchError(this.serviceError)
           );
     }

     getPagamentosNaoFaturados(credoresSelecionadosId,dataInicial,dataFinal,tipoPagamento)  {

      dataInicial = dataInicial == undefined ? new Date('1970-01-01Z00:00:00:000').toDateString():new Date(dataInicial.year,dataInicial.month-1,dataInicial.day).toDateString();
      dataFinal = dataFinal == undefined ? new Date().toDateString():new Date(dataFinal.year,dataFinal.month-1,dataFinal.day+1).toDateString();
      return this.http.post(environment.api + this.endpoint + `pagamentosByIdCredorNaoFaturados?tipoPagamento=${tipoPagamento}&dataFinal=${dataFinal}&dataInicial=${dataInicial}`,credoresSelecionadosId)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      ).toPromise();
    }

    getPagamentosFaturados(faturamentoId)  {
      return this.http.get(environment.api + this.endpoint + `pagamentosByIdFaturamento?faturamentoId=${faturamentoId}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      ).toPromise();
    }



    getAllRetidoByCredorAndDate(credorId,date:Date) : Observable<any> {

      var dateStr = date.toDateString()

      return this.http.get(environment.api + this.endpoint + `pagamentosRetidosByCredor?credorId=${credorId}&date=${dateStr}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    getAllFaturadoByCredorAndDate(credorId,date:Date) : Observable<any> {

      var dateStr = date.toDateString()

      return this.http.get(environment.api + this.endpoint + `pagamentosFaturadosByCredor?credorId=${credorId}&date=${dateStr}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    getAllTaxaAdministrativaByCredorAndDate(credorId,date:Date) : Observable<any> {

      var dateStr = date.toDateString()

      return this.http.get(environment.api + this.endpoint + `pagamentosTaxaAdministrativaByCredor?credorId=${credorId}&date=${dateStr}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }


    deleteTAxaAdministrativa(id): Observable<any> {
      return this.http.delete(environment.api + this.endpoint + `taxaAdministrativa/delete/${id}`)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
}
