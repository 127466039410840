import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Guid } from "guid-typescript";
import { SelectList } from "src/app/models/select-list";
import { DadosCargasService } from "src/app/services/dados-cargas.service";
import { SituacaoCasoProcessoJudicialService } from "src/app/services/situacao-caso-processo-judicial.service";
import { TagService } from "src/app/services/tag.service";
import { SaveStateFilters } from "../services/save-state-filters";

@Component({
  selector: "app-filtro-processos-casos",
  templateUrl: "./filtro-processos-casos.component.html",
  styleUrls: ["./filtro-processos-casos.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FiltroProcessosCasosComponent implements OnInit {
  @Output() buscar = new EventEmitter();

  public tagDropdownSettings = {
    singleSelection: false,
    primaryKey: "tagId",
    text: "",
    noDataLabel: "",
    enableSearchFilter: true,
    filterSelectAllText: "Selecionar todas filtradas",
    filterUnSelectAllText: "Desmarcar todas filtradas",
    disabled: false,
    selectAllText: "Selecionar todas",
    unSelectAllText: "Desmarcar todas",
    searchPlaceholderText: "Procurar",
    classes: "multiselect-tags",
    badgeShowLimit: 4,
  };

  //Filtros
  public filtroOn: boolean = false;
  numeroPasta: string = '';
  clientes: Guid[] = [];
  responsaveis: Guid[] = [];
  outrosEnvolvidos: Guid[] = [];
  ufs: Guid[] = [];
  tipoFiltroData: string = '1';
  dataInicial: Date = null;
  dataFinal: Date = null;
  situacoes: Guid[] = [];
  tagsSelecionadas: any = [];
  tipoFiltroValor: number = 1;
  valorInicial: number;
  valorFinal: number;
  tipo: string = '';
  base: string = '';
  instancias: Guid[] = [];
  //varas: Guid[] = [];
  acoes: Guid[] = [];
  comarcas: Guid[] = [];
  stringMultiplosNumerosProcessos = '';
  filtroMultiplosNumerosProcessos: string[] = [];
  stringMultiplosIdsAdvNet = '';
  filtroMultiplosIdsAdvNet: number[] = [];

  //Contens do filtro
  contemCliente: boolean = true;
  contemResponsavel: boolean = true;
  contemOutrosEnvolvidos: boolean = true;
  contemUf: boolean = true;
  contemSituacao: boolean = true;
  contemInstancia: boolean = true;
  contemAcao: boolean = true;
  contemComarca: boolean = true;
  //contemVara: boolean = true;
  public loadTags: boolean = false;
  public tags: any = [];

  constructor(
    private saveStateFilter: SaveStateFilters,
    private _tagService: TagService
  ) {}

  ngOnInit(): void {
    this.setFiltros();
    this.buscarTags();
  }

  get requisicao() {
    return {
      numeroPasta: this.numeroPasta,
      multiplosIdsAdvNet: this.filtroMultiplosIdsAdvNet,
      multiplosNumerosProcessos: this.filtroMultiplosNumerosProcessos,
      contemCliente: this.contemCliente,
      clientes: this.clientes,
      contemResponsavel: this.contemResponsavel,
      responsaveis: this.responsaveis,
      contemOutrosEnvolvidos: this.contemOutrosEnvolvidos,
      outrosEnvolvidos: this.outrosEnvolvidos,
      contemUf: this.contemUf,
      ufs: this.ufs,
      tipoFiltroData: Number(this.tipoFiltroData),
      dataInicial: this.formatarDataParaRequisicao(this.dataInicial),
      dataFinal: this.formatarDataParaRequisicao(this.dataFinal),
      contemSituacao: this.contemSituacao,
      situacoes: this.situacoes,
      contemInstancia: this.contemInstancia,
      instancias: this.instancias,
      //contemVara: this.contemVara,
      //varas: this.varas,
      contemAcao: this.contemAcao,
      acoes: this.acoes,
      contemComarca: this.contemComarca,
      comarcas: this.comarcas,
      tags: this.tagsSelecionadas.map((tag) => tag.tagId),
      tipo: this.tipo,
      base: this.base,
      tipoFiltroValor: Number(this.tipoFiltroValor),
      valorInicial: this.valorInicial != null ? Number(this.valorInicial) : 0,
      valorFinal: this.valorFinal != null ? Number(this.valorFinal) : 0,
    };
  }

  get filtros() {
    return {
      numeroPasta: this.numeroPasta,
      multiplosNumerosProcessos: this.filtroMultiplosNumerosProcessos,
      clientes: this.clientes,
      multiplosIdsAdvNet: this.filtroMultiplosIdsAdvNet,
      responsaveis: this.responsaveis,
      outrosEnvolvidos: this.outrosEnvolvidos,
      ufs: this.ufs,
      tipoFiltroData: this.tipoFiltroData,
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
      situacoes: this.situacoes,
      tagsSelecionadas: this.tagsSelecionadas,
      tipoFiltroValor: this.tipoFiltroValor,
      valorInicial: this.valorInicial,
      valorFinal: this.valorFinal,
      tipo: this.tipo,
      base: this.base,
      instancias: this.instancias,
      //varas: this.varas,
      acoes: this.acoes,
      comarcas: this.comarcas,
    };
  }

  buscarTags() {
    this.loadTags = true;

    this._tagService.getAllAsync().then((x) => {
      this.loadTags = false;

      x.data.forEach((element, index) => {
        this.tags.push({
          tagId: element.id,
          tagNome: element.nome,
          tagCor: element.cor,
        });
      });
    });
  }

  setFiltros() {
    const value = this.saveStateFilter.getFilterCarteiraProcesso;

    if (!value) return;

    this.filtroOn = true;
    this.numeroPasta = value.numeroPasta;
    this.stringMultiplosNumerosProcessos = value.numeroProcesso;
    this.clientes = value.clientes;
    this.stringMultiplosIdsAdvNet = value.idAdvNet;
    this.responsaveis = value.responsaveis;
    this.outrosEnvolvidos = value.outrosEnvolvidos;
    this.ufs = value.ufs;
    this.tipoFiltroData = value.tipoFiltroData;
    this.dataInicial = value.dataInicial;
    this.dataFinal = value.dataFinal;
    this.situacoes = value.situacoes;
    this.tagsSelecionadas = value.tagsSelecionadas;
    this.tipoFiltroValor = value.tipoFiltroValor;
    this.valorInicial = value.valorInicial;
    this.valorFinal = value.valorFinal;
    this.tipo = value.tipo;
    this.base = value.base;
    this.instancias = value.instancias;
    //this.varas = value.varas;
    this.acoes = value.acoes;
    this.comarcas = value.comarcas;
  }

  filtrar() {
    this.saveStateFilter.setFilterCarteiraProcesso(this.filtros);
    this.buscar.emit(this.requisicao);
  }

  limparFiltros() {
    this.numeroPasta = "";
    this.stringMultiplosNumerosProcessos = '';
    this.filtroMultiplosNumerosProcessos = [];
    this.clientes = [];
    this.responsaveis = [];
    this.outrosEnvolvidos = [];
    this.ufs = [];
    this.tipoFiltroData = "1";
    this.dataInicial = null;
    this.dataFinal = null;
    this.situacoes = [];
    this.tagsSelecionadas = [];
    this.tipoFiltroValor = 1;
    this.valorInicial = 0;
    this.stringMultiplosIdsAdvNet = '';
    this.filtroMultiplosIdsAdvNet = [];
    this.valorFinal = 0;
    this.tipo = "";
    this.base = "";
    this.instancias = [];
    //this.varas = [];
    this.acoes = [];
    this.comarcas = [];

    this.saveStateFilter.setFilterCarteiraProcesso(this.filtros);
    this.buscar.emit(this.requisicao);
  }

  formatarDataParaRequisicao(data) {
    if (!data) return null;

    return new Date(data.year, data.month - 1, data.day);
  }

  convertStringToArrayNumerosProcessos() {
    if (!this.stringMultiplosNumerosProcessos) {
      this.filtroMultiplosNumerosProcessos = [];
      return;
    }

    this.filtroMultiplosNumerosProcessos = this.stringMultiplosNumerosProcessos.trim().split(" ").map(String);
  }
  convertStringToArrayIdsProcessosCasos() {
    if (!this.stringMultiplosIdsAdvNet) {
        this.filtroMultiplosIdsAdvNet = [];
        return;
      }
  
      this.filtroMultiplosIdsAdvNet = this.stringMultiplosIdsAdvNet.trim().split(" ").map(Number);
  }
}
