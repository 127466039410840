import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { agColumnTypes } from 'src/app/configs/ag-exports';
import { AG_GRID_LOCALE_PT } from './locale.ag-grid.pt';

@Component({
    selector: 'app-ag-grid',
    templateUrl: './ag-grid.component.html',
    styleUrls: ['./ag-grid.component.scss']
})
export class AgGridComponent implements OnInit {

    @Input() columnDefs = [];
    @Input() rowData = [];
    @Input() keyLocalStorageFilter = '';
    @Input() classGrid;
    @Input() rowSelection;
    @Input() showToolbar = false;
    @Input() pagination = false;
    @Input() paginationPageSize = 50;
    @Input() pinnedBottom;
    @Input() gridOptions;

    @Output() cellClicked = new EventEmitter();
    @Output() selectionChanged = new EventEmitter();

    localeText = AG_GRID_LOCALE_PT;
    columnTypes = agColumnTypes;

    savedFilterModel: any;
    gridApi: GridApi;

    filterParams = {
        buttons: ['clear', 'apply'],
        closeOnApply: true
    }

    filterDateParams = {
        ...this.filterParams,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            if (cellValue == null) return -1;
            cellValue = cellValue.split('T')[0];
            const dateParts = cellValue.split('-');
            const cellDate = new Date(
                Number(dateParts[0]),
                Number(dateParts[1]) - 1,
                Number(dateParts[2])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) return 0;

            if (cellDate < filterLocalDateAtMidnight) return -1;

            if (cellDate > filterLocalDateAtMidnight) return 1;

            return 0;
        },
    };

    defaultColDef: ColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };

    path = `-${window.location.pathname.split('/')[1]}-${window.location.pathname.split('/')[2]}`;

    gridColumnApi: ColumnApi;

    constructor() { }

    ngOnInit(): void {
    }

    onFilterChanged() {
        localStorage.setItem(this.keyLocalStorageFilter, JSON.stringify(this.gridApi.getFilterModel()));
    }

    onColumnMoved() {
        localStorage.setItem(`grid-column-state${this.path}`, JSON.stringify(this.gridColumnApi.getColumnState()));
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (this.pinnedBottom) {
            this.gridApi.setPinnedBottomRowData([this.pinnedBottom])
        }
        this.onSetFilterModel();
        this.onApplyColumnState();
    }


    onSetFilterModel() {
        if (this.keyLocalStorageFilter) {
            this.gridApi.setFilterModel(JSON.parse(localStorage.getItem(this.keyLocalStorageFilter)) || null)
        }
    }

    onApplyColumnState() {
        const state = JSON.parse(localStorage.getItem(`grid-column-state${this.path}`));
        this.gridColumnApi.applyColumnState({ state, applyOrder: true })
    }

    onCellClicked(event) {
        this.cellClicked.emit(event);
    }

    onClearFilters() {
        this.gridApi.setFilterModel(null)
    }

    onSelectionChanged() {
        this.selectionChanged.emit(this.gridApi.getSelectedRows());
    }
}
