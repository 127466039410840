<div  *ngIf="!load"class="card mb-4">
  <div class="card-body">

      <div class="text-muted small">
        <span>Avisar quando o cliente anexar documento no <strong>Processo</strong></span>
      </div>
  </div>

  <div class="px-2">
    <div class="w-100">
     <ol class="list-group list-group-numbered mb-3">
      <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let documentos of documento">
        <div class="ms-2 me-auto">
          <div class="user"><strong>Cliente: </strong>{{documentos.credor}}</div>
          <div class="user"><strong>Documento: </strong>{{documentos.arquivo}}</div>
          <div class="user"><strong>Data do cadastro: </strong>{{documentos.dataHora | date: 'fullDate'}}</div>
          <div><strong>Hora do cadastro: </strong>{{documentos.dataHora | date: 'hh:mm a'}}</div>
        </div>
      </li>
    </ol>
    </div>
  </div>

</div>

<div *ngIf="load" class="card mb-4" style="display: flex;flex-direction: column;justify-content: center;align-items: center">

  <div class="card-body">

  </div>

  <div class="card-body">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="card-body">
  </div>

</div>
