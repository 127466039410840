import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { debug } from 'console';

@Component({
    selector: 'app-tabela-parcelas',
    templateUrl: './tabela-parcelas.component.html',
    styleUrls: ['./tabela-parcelas.component.scss']
})
export class TabelaParcelasComponent implements OnInit {

    @Input() acordosParcelas: string;
    @Input() sortBy: string;
    @Input() sortDesc: boolean;

    @Output() abrirModalAcordoChange = new EventEmitter<any>();
    @Output() sortChange = new EventEmitter<any>();
    @Output() sortByChange = new EventEmitter<string>();
    @Output() sortDescChange = new EventEmitter<boolean>();

    public raiz: string = '';

    constructor(
        private router: Router,
    ) { }

    ngOnInit(): void {
        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
          } else {
            this.raiz = '/operacionais'
          }
          
    }

    public sortObject: any={
        sortBy: <string> 'razao',
        sortDesc: <boolean> false
    }    

    formatarParcela(acordo) {
        const parcela = acordo.numeroParcela < 9 ? '0' + acordo.numeroParcela : acordo.numeroParcela;
        const quantidadeParcelas = acordo.quantidadeParcelas < 9 ? '0' + acordo.quantidadeParcelas : acordo.quantidadeParcelas;

        return `${parcela}/${quantidadeParcelas}`;
    }

    abrirModalAcordo(acordo) {
        this.abrirModalAcordoChange.emit(acordo);
    }

    setSort(key) {
        debugger;
        if (this.sortBy !== key) {
            this.sortBy = key;            
            this.sortDesc = false;
        } else {
            this.sortDesc = !this.sortDesc;
        }               
        
        this.sortObject.sortBy=this.sortBy;
        this.sortByChange.emit(this.sortBy);
        
        this.sortObject.sortDesc = this.sortDesc; 
        this.sortDescChange.emit(this.sortDesc);
        
        this.sortChange.emit(this.sortObject);
    }    
}
