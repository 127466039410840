export const CATEGORIAS = [
  {
    name: "Adiantamento de despesas",
    blocked: false,
    color: "#999a00",
    sons: [],
    status: "ACTIVE",
    objectId: 5989805370507264,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "ADVANCE_EXPENSE",
    valid: true,
  },
  {
    name: "Aluguel",
    blocked: false,
    color: "#ff0000",
    sons: [],
    status: "ACTIVE",
    objectId: 5900990874255360,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Anuidade OAB",
    blocked: false,
    color: "#ff6562",
    sons: [],
    status: "ACTIVE",
    objectId: 5937258291134464,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "COFINS",
    blocked: false,
    color: "#000000",
    father: 6624796387442688,
    sons: [],
    status: "ACTIVE",
    objectId: 5359149451968512,
    level: 1,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "CSLL",
    blocked: false,
    color: "#000000",
    sons: [],
    status: "ACTIVE",
    objectId: 5178050478211072,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Capitalização sócios",
    blocked: false,
    color: "#ce62ce",
    sons: [],
    status: "ACTIVE",
    objectId: 5741000431632384,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Condomínio",
    blocked: false,
    color: "#ff6562",
    sons: [],
    status: "ACTIVE",
    objectId: 6566238534893568,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Contador",
    blocked: false,
    color: "#9b0000",
    sons: [],
    status: "ACTIVE",
    objectId: 5441205271920640,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Despesa do cliente",
    blocked: false,
    color: "#999a00",
    sons: [],
    status: "ACTIVE",
    objectId: 5374308337713152,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "EXPENSE",
    valid: true,
  },
  {
    name: "GPS - INSS",
    blocked: false,
    color: "#c0c0c0",
    sons: [],
    status: "ACTIVE",
    objectId: 6567105178763264,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Honorários",
    blocked: false,
    color: "#5dffff",
    sons: [],
    status: "ACTIVE",
    objectId: 5384707124625408,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "HONORARY",
    valid: true,
  },
  {
    name: "IPTU",
    blocked: false,
    color: "#666666",
    sons: [],
    status: "ACTIVE",
    objectId: 6725612825870336,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "IRRF",
    blocked: false,
    color: "#000000",
    father: 6624796387442688,
    sons: [],
    status: "ACTIVE",
    objectId: 6500208244555776,
    level: 1,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Impostos",
    blocked: false,
    color: "#000000",
    sons: [],
    status: "ACTIVE",
    objectId: 6624796387442688,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Limpeza",
    blocked: false,
    color: "#6563cf",
    sons: [],
    status: "ACTIVE",
    objectId: 5945718437183488,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Marketing",
    blocked: false,
    color: "#ff6600",
    sons: [],
    status: "ACTIVE",
    objectId: 4737517830144000,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "PIS",
    blocked: false,
    color: "#000000",
    father: 6624796387442688,
    sons: [],
    status: "ACTIVE",
    objectId: 4799706741997568,
    level: 1,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "PróLabore",
    blocked: false,
    color: "#cd9a24",
    sons: [],
    status: "ACTIVE",
    objectId: 4556051301138432,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Rendimentos financeiros",
    blocked: false,
    color: "#15ffff",
    sons: [],
    status: "ACTIVE",
    objectId: 5338040920834048,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Salários",
    blocked: false,
    color: "#ff0000",
    sons: [],
    status: "ACTIVE",
    objectId: 4629463096754176,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Suprimentos escritório",
    blocked: false,
    color: "#ff9960",
    sons: [],
    status: "ACTIVE",
    objectId: 5597060306960384,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Tarifas Bancárias",
    blocked: false,
    color: "#6563cf",
    sons: [],
    status: "ACTIVE",
    objectId: 5755363003596800,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
  {
    name: "Taxa assistencial",
    blocked: false,
    color: "#2e60ff",
    sons: [],
    status: "ACTIVE",
    objectId: 4584102806290432,
    level: 0,
    expanded: true,
    systemCategory: true,
    defaultCategoryFor: "",
    valid: true,
  },
];
