import { PessoaMapeamentoChaves } from 'src/app/models/pessoa/pessoa-mapeamento-chaves';
import { Component, Input, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { AppService } from 'src/app/app.service';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { Pessoa } from 'src/app/models/pessoa/pessoa';
import { PessoaService } from 'src/app/services/pessoa.service';
import { ProcessosService } from 'src/app/services/processos.service';
import { DividaService } from 'src/app/services/divida.service';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { Modulo } from 'src/app/enums/modulo.enum';

@Component({
  selector: 'app-capa-processo',
  templateUrl: './capa-processo.component.html',
  styleUrls: ['./capa-processo.component.scss']
})
export class CapaProcessoComponent implements OnInit {

  public loadingProcesso:Boolean = true;
  pmc:PessoaMapeamentoChaves;
  @Input() processoId: Guid;
  processo: ProcessoOperacao;
  credor: Pessoa;
  devedor: Pessoa;
  cobrador:Pessoa;



  enderecoDevedor:String = "";
  bairroDevedor:String ="";
  cidadeDevedor:String="";
  ufDevedor:String="";
  cepDevedor:String="";

  taxaJuros = 0;
  taxaAdministrativa = 0;

  totalJuros = 0;
  totalAtualizacaoCusta = 0;
  totalMulta = 0;
  totalProtesto = 0;
  totalCredor = 0;
  totalTaxaAdm = 0;


  contatosCredor:any = [];
  bancosCredor:any = [];

  dividaL: ProcessoDivida[] = [];
  dividasOriginais: ProcessoDivida[] = [];

  isMaster:Boolean = false;
  isCredor:Boolean = false;
  isCobrador:Boolean = false;

  avalistas:any = [];
  avalistasSelecionados:any = [];
  public dropdownSettings = {
    singleSelection: false,
    text: "Selecione",
    enableSearchFilter: true,
    disabled: false,
    enableCheckAll:false,
    enableUnCheckAll:false,

  };

  constructor(
    private appService: AppService,
    public pessoaService: PessoaService,
    public processoService: ProcessosService,
    public dividaService: DividaService,
    public toastrService: ToastrService,
    public authService: AuthService
  ) {
    this.isMaster = authService.isMaster();
    this.isCredor = this.authService.isPermissionAvailable(Modulo.OPERACIONAIS_PROCESSOS_CREDOR);
  }

  ngOnInit(): void {

    if(this.processoId != undefined)
    {
      this.pessoaService.getPessoaMapeamentoChaves().subscribe(x => {
        this.loadingProcesso = true;
        this.pmc = x.data;

        this.processoService.getById(this.processoId).subscribe(res => {
          this.processo = res.data;

          this.taxaJuros = this.processo.percentualJuros;
          this.taxaAdministrativa = this.processo.percentualTaxaAdministrativa;

          this.pessoaService.getById(this.processo.credorId).subscribe(cre => {
            this.credor = cre.data;
            this.getContatosCredor(this.credor);
            this.getBancosCredor(this.credor);
          },erro=>{
            var teste;
          });

          this.pessoaService.getById(this.processo.devedorId).subscribe(dev => {
            this.devedor = dev.data;
            this.getEnderecoDevedor(this.devedor);
          },erro=>{
            var teste;
          });

          this.pessoaService.getById(this.processo.cobradorId).subscribe(cob => {
            this.cobrador = cob.data;
          },erro=>{
            var teste;
          });

          this.dividaService.getAllByProcesso(this.processoId).subscribe(x => {
            this.dividaL = x.data;
            this.getDividasOriginais();
          },erro=>{
            var teste;
          });

          this.getAvalistas(this.processoId);
          this.getPessoasByTipoSimplificado("Avalista");

          this.loadingProcesso = false;

        });

      });
    }


  }


  getEnderecoDevedor(devedor:Pessoa)
  {
      var dev = new Pessoa();

      var pdc = dev.GetPessoaDados(devedor,this.pmc.endereco.enderecoPd);

      if(dev != null)
      {
        this.enderecoDevedor = dev.GetPessoaDadosComplementar(pdc[0],this.pmc.endereco.logradouro).valor;
        this.bairroDevedor = ''
        this.cidadeDevedor = dev.GetPessoaDadosComplementar(pdc[0],this.pmc.endereco.cidade).valor;
        this.ufDevedor = dev.GetPessoaDadosComplementar(pdc[0],this.pmc.endereco.uf).valor;;
        this.cepDevedor = dev.GetPessoaDadosComplementar(pdc[0],this.pmc.endereco.cep).valor;;
      }

  }

  getContatosCredor(credor:Pessoa)
  {
      var cre = new Pessoa();
      var pdc = cre.GetPessoaDados(credor,this.pmc.telefone.telefonePd);

      if(pdc != null)
      {
          pdc.forEach(contato => {

            var objContato = {
                numero:cre.GetPessoaDadosComplementar(contato,this.pmc.telefone.numero).valor,
                contato:cre.GetPessoaDadosComplementar(contato,this.pmc.telefone.contato).valor,
                email:cre.GetPessoaDadosComplementar(contato,this.pmc.telefone.email).valor
            };
            this.contatosCredor.push(objContato);
          });
      }
  }


  getBancosCredor(credor:Pessoa)
  {
      var cre = new Pessoa();
      var pdc = cre.GetPessoaDados(credor,this.pmc.banco.bancoPd);

      if(pdc != null)
      {
          pdc.forEach(banco => {

            var objContato = {
                nome:cre.GetPessoaDadosComplementar(banco,this.pmc.banco.nomeBanco).valor,
                agencia:cre.GetPessoaDadosComplementar(banco,this.pmc.banco.agencia).valor,
                contaCorrente:cre.GetPessoaDadosComplementar(banco,this.pmc.banco.contaCorrente).valor
            };
            this.bancosCredor.push(objContato);
          });
      }
  }


  getDividasOriginais()
  {
      this.dividasOriginais = this.dividaL.filter(function filterDividaAcordo(element, index, array) {
        return (element.acordoId == null && element.dividaPaiId == null && element.tipoParcela != 'taxa administrativa');
      });

      this.dividasOriginais.forEach(x => {
        this.totalJuros += x.juros;
        this.totalMulta += x.multa;
        this.totalProtesto += x.valorCustasProtesto;
        this.totalAtualizacaoCusta += x.valorAtualizacaoCustas;
        this.totalCredor += x.valorOriginal + x.juros + x.multa + x.valorCustasProtesto + x.valorAtualizacaoCustas;
        this.totalTaxaAdm += this.getTaxaAdministrativaDivida(x);

      });
  }



  getTaxaAdministrativaDivida(divida:ProcessoDivida)
  {
      if(new Date() > new Date(divida.dataVencimento))
      {
          var taxa = (divida.valorAberto + divida.juros+ divida.multa + divida.valorCustasProtesto + divida.valorAtualizacaoCustas) * (this.taxaAdministrativa /100);
          return taxa;
        }
      return 0;
  }


  setCodigoExternoDevedor()
  {
    this.processoService.updateCodigoExternoDevedor(this.processo.codigoExternoDevedor,this.processo.id).subscribe(x => {
      this.toastrService.success("Codigo Externo Devedor atualizado com sucesso!", 'Sucesso', { timeOut: 10000 });
    },error=>{
      if (error && error.errors) {
        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
      }
      else {
          this.toastrService.error('Não foi possível atualizar a ocorrência!', 'Atenção', { timeOut: 10000 });
      }
    })
  }

  removeAvalista(id)
  {
      this.processoService.deleteAvalista(id).subscribe(x =>
      {
        this.toastrService.success("Avalista Removido", "Sucesso")
      });
  }

  getAvalistas(processoId){
    this.processoService.getAvalistas(processoId).subscribe(x =>
    {
          this.avalistasSelecionados = [];
          x.data.forEach(element => {
              this.avalistasSelecionados.push({
                cpfCnpj:element.pessoa.cpfCnpj,
                nome:element.pessoa.nome,
                id:element.pessoa.id,
                bu:null,
                processoAvalistaId:element.id
              });
          });
    });
  }

  addAvalista(data)
  {
    this.processoService.addAvalista(data).subscribe(x => {
      data.processoAvalistaId = x.data.id;
      this.avalistasSelecionados.forEach(element => {
        if(element.id == x.data.pessoa.id){
          element.processoAvalistaId = data.processoAvalistaId;
        }
      });
      this.toastrService.success("Avalista Salvo", "Sucesso")
    });
  }

  getPessoasByTipoSimplificado(tipo)
  {
      this.pessoaService.getByPessoaTipoListagemSimplificadaAsync(tipo).then(x =>
      {
          x.data.forEach(element => {
            var result = this.avalistasSelecionados.find(x => x.id == element.id);
            this.avalistas.push({
              cpfCnpj:element.cpfCnpj,
              nome:element.nome,
              id:element.id,
              bu:null,
              processoAvalistaId: result != null ? result.processoAvalistaId : null
            });
          });
      })
  }

  onItemSelectAvalista(event)
  {
      var teste = "";

      var data = {
        processoId:this.processoId,
        pessoaId:event.id
      }
      this.addAvalista(data);
  }


  onItemDeSelectAvalista(event)
  {
      var teste = "";
      if(event.processoAvalistaId != undefined){
        this.removeAvalista(event.processoAvalistaId)
      }else{
        this.toastrService.success("Avalista Removido", "Sucesso")
      }
  }


}
