import { element } from 'protractor';
import { TipoCredorComissoesEntidade, TipoCredorComissoesEntidadeLabel } from './../../../../enums/pessoa-enums/credor-comissoes/tipo-credor-comissoes-entidade.enum';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PessoaService } from 'src/app/services/pessoa.service';
import { settings } from 'cluster';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { Observable, Subject } from 'rxjs';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { DefaultEditor, LocalDataSource } from 'ng2-smart-table';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { DocumentoService } from 'src/app/services/documento.service';
import { Guid } from 'guid-typescript';
import { Tipo } from 'src/app/enums/documento/tipo.enum';
import { CustomInputEditorNg2Component } from 'src/app/views/shared/custom-input-editor-ng2/custom-input-editor-ng2/custom-input-editor-ng2.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Pessoa, PessoaDados, PessoaDadosComplementares } from 'src/app/models/pessoa/pessoa';
import { PessoaMapeamentoChaves } from 'src/app/models/pessoa/pessoa-mapeamento-chaves';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Modulo } from 'src/app/enums/modulo.enum';
import Swal from 'sweetalert2';
import { CustomInputEditorNg2MoneyComponent } from 'src/app/views/shared/custom-input-editor-ng2-money/custom-input-editor-ng2-money.component';
import { CustomInputEditorNg2PercentageComponent } from 'src/app/views/shared/custom-input-editor-ng2-percentage/custom-input-editor-ng2-percentage.component';
import { SalarioService } from 'src/app/services/salario.service';






@Component({
    selector: 'app-modal-cadastro-pessoa',
    templateUrl: './modal-cadastro-pessoa.component.html',
    styleUrls: [
        '../../../../../vendor/styles/pages/dropzone.scss',
        'modal-cadastro-pessoa.component.css',
        '../../../../../vendor/libs/ng2-smart-table/ng2-smart-table.scss'
    ],
    providers: [PessoaService, DadosCargasService]
})
export class ModalCadastroPessoaComponent implements OnInit {

    resultConfirmDialog$ = new Subject<ResultadoModal>();

    modalRef: NgbModalRef;
    isIE10 = false;
    cadastrandoCampo = false;

    public maskTime = [/\d/, /\d/, ':', /\d/, /\d/]

    // public pessoa: any;
    public arrayVazio: any[] = [];
    public bancos: any[] = [];
    public telefones: any[] = [];
    public camposAdicionais: any[] = [];
    public enderecos: any[] = [];
    public superiores: any[] = [];
    //public comissoes: any[] = [];
    public enderecosEletronicos: any[] = [];
    public tiposComissoes: any[] = [];

    public settingsBancos: any;
    public settingsSuperiores: any;
    public settingsTelefones: any;
    public settingsTelefonesDevedor: any;
    public settingsEnderecos: any;
    public settingsCamposAdicionais: any;
    public settingsEnderecoEletronico: any;

    public settingsComissoesPrivadoValores: any;
    public settingsComissoesPublicoValores: any;
    public settingsComissoesPrivadoDias: any;
    public settingsComissoesPublicoDias: any;
    public settingsHistoricoComissoes: any;

    public settingsHonorariosJDValores: any;
    honorariosJDPrivadoValoresInitialFee = [];
    honorariosJDPublicoValoresInitialFee = [];

    honorariosJDPrivadoValoresSuccessFee = [];
    honorariosJDPublicoValoresSuccessFee = [];

    public settingsComissoesCobradorValores: any;

    public settingsSuperioresAdvogado: any;

    public settingsArquivos;

    public tiposPessoas = [];
    public listaBancos = [];
    public listaEstados = [];
    public tiposTelefones = [];
    public tipoEstadoCivil = [];
    public tipoRegimeDeBens = [];
    public tiposCobrador = [];
    public tiposEntidade = [];
    public situacoesCliente = [];
    public tiposCliente = [];
    public calcularHonorarioCliente = [];

    tipoPessoa = null;

    title: any;

    public files: File[] = [];
    public isHovering: boolean;
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    public hasAnotherDropZoneOver: boolean;
    public response: string;

    file: File;
    arquivos: any[] = [];
    source: LocalDataSource;
    tipoDocumentos = [];


    credorComissoesComVigenciaAtiva = [];
    tipoComissao = '0';
    comissoesPrivadoValores = [];
    comissoesPublicoValores = [];
    comissoesPrivadoDias = [];
    comissoesPublicoDias = [];

    credorComissoesHistorico = [];
    credorComissoesHistoricoDias = [];
    credorComissoesHistoricoValor = [];

    active = 1;


    permitirParcelamento = true;
    permitirParcelamentoJuridico = true;
    politica = true;
    politicaJuridico = true;
    checarAcordo = true;
    checarAcordoJuridico = true;
    calcularHonorarios = true;

    readonly: Boolean;

    pessoa2: Pessoa;
    pessoaMap: PessoaMapeamentoChaves;


    //leitura e gravacao dados tabelas ng
    bancosPessoa: PessoaDados[] = [];
    bancosPessoaNG: any[] = [];

    telefonesPessoa: PessoaDados[] = [];
    telefonesPessoaNG: any[] = [];

    telefonesDevedorPessoa: PessoaDados[] = [];
    telefonesDevedorPessoaNG: any[] = [];

    camposAdicionaisPessoa: PessoaDados[] = [];
    camposAdicionaisPessoaNG: any[] = [];

    enderecosPessoa: PessoaDados[] = [];
    enderecosPessoaNG: any[] = [];

    enderecosEletronicosPessoa: PessoaDados[] = [];
    enderecosEletronicosPessoaNG: any[] = [];

    superioresPessoa: PessoaDados[] = [];
    superioresPessoaNG: any[] = [];

    comissoesCobradorPessoa: PessoaDados[] = [];
    comissoesCobradorPessoaNG: any[] = [];
    //leitura e gravacao dados tabelas ng


    grupos: any[] = [];

    isCredor: Boolean;
    isMaster: Boolean;
    public loading: Boolean = false;
    dadosUnico: any[];

    isUpdateContato: Boolean = false;

    public isDisableSave: boolean = false;

    public valorSalarioCorrente: number=0;

    async ngOnInit(): Promise<void> {
        this.obterValorSalario();

        if (this.modalRef.componentInstance.updateData) {
            this.loading = true;
            await this.checkPessoaIfExists(this.modalRef.componentInstance.pessoa.id);
        }

        if (this.modalRef.componentInstance.updateDataContato != undefined
            && this.modalRef.componentInstance.updateDataContato) {
            this.isUpdateContato = true;
            this.active = 9;
        }

        this.getTiposDocumento();

        this.isMaster = this.authService.isMaster();
        this.isCredor = this.authService.isPermissionAvailable(Modulo.OPERACIONAIS_PROCESSOS_CREDOR);


        this.pessoaService.getAllGroups().subscribe(x => {
            x.data.forEach(element => {
                this.grupos.push(element.grupo)
            });
            const newArr = this.grupos.map(x => x.toLocaleLowerCase());
            this.dadosUnico = newArr.filter((el, i, arr) => arr.indexOf(el.toLocaleLowerCase()) == i);

        })

        this.pessoaService.getPessoaMapeamentoChaves().subscribe(x => {
            this.pessoaMap = x.data;
            if (!this.modalRef.componentInstance.updateData) {

                this.pessoa2 = new Pessoa();
                this.startLists();
                this.loading = false;


            } else {
                this.loading = true;
                this.pessoaService.getById(this.modalRef.componentInstance.pessoa.id).subscribe(x => {

                    this.pessoa2 = x.data;
                    this.startLists();

                    this.onChangeTipoPessoa(this.pessoa2.tipo);
                    this.getCredorComissoes(this.pessoa2.id);
                    this.getArquivosPessoa();

                    this.checks(this.pessoa2);

                    this.loading = false;

                    this.calcularSalario();
                    this.calcularSalarioPublico();
                })
            }

        });


        this.tiposPessoas = this.dadosCargasService.getipoPessoas();//this.modalRef.componentInstance.camposComplementares.tiposPessoas;
        this.camposAdicionais = this.dadosCargasService.mockCamposAdicionais();
        this.listaBancos = this.dadosCargasService.mockBancos(); //this.modalRef.componentInstance.camposComplementares.bancos;
        this.tiposTelefones = this.dadosCargasService.mockTelefones();//this.modalRef.componentInstance.camposComplementares.tiposTelefones;
        this.listaEstados = this.dadosCargasService.mockEstados();//this.modalRef.componentInstance.camposComplementares.estados;
        this.tiposComissoes = //this.modalRef.componentInstance.camposComplementares.tipoComissoes;
            this.tipoEstadoCivil = this.pessoaService.getTipoEstadoCivil();
        this.tipoRegimeDeBens = this.pessoaService.getTipoRegimeBens();
        this.tiposCobrador = this.pessoaService.getTipoCobrador();
        this.tiposEntidade = this.pessoaService.getTipoEntidade();
        this.situacoesCliente = this.pessoaService.getSituacaoCredor();
        this.tiposCliente = this.pessoaService.getTipoCredor();
        this.calcularHonorarioCliente = this.pessoaService.getCalcularHonorarioCredor();


        this.title = this.modalRef.componentInstance.updateData ?
            "Atualizar Pessoa" : "Cadastrar Nova Pessoa";



        if (!this.authService.isMaster() && this.pessoa2 && this.pessoa2.tipo != "Devedor") {
            this.readonly = true;
        }


        this.popularSettingsTabelas();

        if (this.isUpdateContato) {
            this.active = 9;
        }
    }

    constructor(
        private appService: AppService,
        private pessoaService: PessoaService,
        private dadosCargasService: DadosCargasService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public utilsService: UtilsService,
        private documenoService: DocumentoService,
        private ngbModal: NgbModal,
        private authService: AuthService,
        private salarioMinimoService: SalarioService
    ) {

        this.uploader = new FileUploader({
            url: pessoaService.postFileUrl,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => {
                    resolve();
                });
            }
        });

        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);

        this.isIE10 = this.appService.isIE10;

    }

    obterValorSalario() {
        this.salarioMinimoService.obterUltimo().subscribe(res=>{
            this.valorSalarioCorrente = res.data.valor;
        })
    }

    checks(pessoa) {
        if (pessoa.tipo == 'Credor') {
            this.permitirParcelamento = this.checkPermitirParcelamento();
            this.permitirParcelamentoJuridico = this.checkPermitirParcelamentoJuridico();
            this.politica = this.checkPolitica();
            this.politicaJuridico = this.checkPoliticaJuridico();
            this.checarAcordo = this.checkChecarParametrosAoGerarAcordo();
            this.checarAcordoJuridico = this.checkChecarParametrosAoGerarAcordoJuridico();
            this.calcularHonorarios = this.checkTaxaAdministrativa();

        }
    }

    popularSettingsTabelas() {

        this.settingsArquivos = {
            actions: {
                add: false,
                edit: false,
                delete: true,
                columnTitle: 'Ações',
                position: 'right',
                custom: [
                    {
                        name: 'Download file',
                        title: '<i class="btn btn-xs icon-btn btn-primary"><span class="fas fa-download"></span></i>'
                    },
                ]
            },
            noDataMessage: 'Não possui dado',

            delete: {
                confirmDelete: true,
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
            },
            pager: {
                display: false
            },
            columns: {
                nome: {
                    title: 'Nome do Arquivo',
                    filter: false,
                    width: '65%'
                },
                tipoDocumento: {
                    title: 'Tipo do Documento',
                    filter: false,
                    width: '35%',
                    valuePrepareFunction: (cell, row) => this.getDescricaoTipoArquivo(cell, row)
                }
            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsBancos = {
            actions: {
                columnTitle: "Ações",
                position: "right",
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };


        this.settingsEnderecos = {
            actions: {
                columnTitle: "Ações",
                position: "right"
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsTelefones = {
            actions: {
                columnTitle: "Ações",
                position: "right"
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        // telefone Devedor
        this.settingsTelefonesDevedor = {
            actions: {
                columnTitle: "Ações",
                position: "right"
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsCamposAdicionais = {
            actions: {
                columnTitle: "Ações",
                position: "right"
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsEnderecoEletronico = {
            actions: {
                columnTitle: "Ações",
                position: "right"
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        //
        this.settingsComissoesPrivadoValores = {
            actions: {
                columnTitle: "Ações",
                position: "right",
                hideSubHeader: false
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },

            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsHonorariosJDValores = {
            actions: {
                columnTitle: "Ações",
                position: "right",
                hideSubHeader: false
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
                confirmCreate: true
            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
                confirmSave: true
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },

            attr: {
                class: 'table table-striped table-bordered'
            }
        };


        this.settingsComissoesPrivadoDias = {
            actions: {
                columnTitle: "Ações",
                position: "right",
                hideSubHeader: true
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };
        this.settingsComissoesPublicoDias = {
            actions: {
                columnTitle: "Ações",
                position: "right",
                hideSubHeader: true
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };
        //

        this.settingsComissoesCobradorValores = {
            actions: {
                columnTitle: "Ações",
                position: "right",
                hideSubHeader: true
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsHistoricoComissoes = {
            hideSubHeader: true,
            actions: false,
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '',
                createButtonContent: '',
                cancelButtonContent: '',

            },
            edit: {
                editButtonContent: '',
                saveButtonContent: '',
                cancelButtonContent: '',

            },
            delete: {
                deleteButtonContent: ''
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsSuperioresAdvogado = {
            actions: {
                columnTitle: "Ações",
                position: "right",
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',

            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },
            columns: {

            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };


        //Aba de bancos
        var filtroEEdicaoAbaBancos = { type: 'list', config: { selectText: 'Selecione...', list: this.listaBancos } };
        var bancoColuna = { title: 'Banco', editor: filtroEEdicaoAbaBancos, filter: /*filtroEEdicaoAbaBancos*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsBancos.columns = { nomeBanco: bancoColuna, agencia: { title: 'Agência', filter: false }, contaCorrente: { title: 'Conta corrente', filter: false }, contato: { title: 'Titular', filter: false } };

        //Aba de Endereços

        var filtroEEdicaoAbaEndereco = { type: 'list', config: { selectText: 'Selecione...', list: this.listaEstados } };
        var enderecoColuna = { title: 'UF', editor: filtroEEdicaoAbaEndereco, filter: /*filtroEEdicaoAbaEndereco*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsEnderecos.columns = { uf: enderecoColuna, cidade: { title: 'Cidade', filter: false }, cep: { title: 'CEP', filter: false }, logradouro: { title: 'Logradouro', filter: false } };

        //Aba de telefones

        var filtroEEdicaoAbaTelefone = { type: 'list', config: { selectText: 'Selecione...', list: this.tiposTelefones } };
        var telefoneColuna = { title: 'Tipo Telefone', editor: filtroEEdicaoAbaTelefone, filter: /*filtroEEdicaoAbaTelefone*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsTelefones.columns = { tipoTelefone: telefoneColuna, numero: { title: 'Número', filter: false }, ramal: { title: 'Ramal', filter: false }, contato: { title: 'Contato', filter: false }, email: { title: 'Email', filter: false }, departamento: { title: 'Departamento', filter: false } };

        //Aba de telefones Devedor

        var filtroEEdicaoAbaTelefone = { type: 'list', config: { selectText: 'Selecione...', list: this.tiposTelefones } };
        var telefoneColuna = { title: 'Tipo Telefone', editor: filtroEEdicaoAbaTelefone, filter: /*filtroEEdicaoAbaTelefone*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsTelefonesDevedor.columns = { tipoTelefone: telefoneColuna, numero: { title: 'Número', filter: false }, ramal: { title: 'Ramal', filter: false }, contato: { title: 'Contato', filter: false } };

        //Aba de campos adicionais

        var filtroEEdicaoCamposAdicionais = { type: 'list', config: { selectText: 'Selecione...', list: this.camposAdicionais } };
        var campoAdicionalColuna = { title: 'Tipo', editor: filtroEEdicaoCamposAdicionais, filter: /*filtroEEdicaoAbaBancos*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsCamposAdicionais.columns = {
            campo: campoAdicionalColuna,
            conteudo: { title: 'Conteudo', filter: false }
        };

        //Aba de endereco eletronico
        this.settingsEnderecoEletronico.columns = {
            email: { title: 'E-mail', filter: false },// linkedin: { title: 'LinkedIn', filter: false }, twitter: { title: 'Twitter', filter: false },
            // facebook: { title: 'Facebook', filter: false }, instagram: { title: 'Instagram', filter: false }, telegram: { title: 'Telegram', filter: false }
        };


        //Aba de comissoes com range de valores here
        var filtroTiposComissoes = { type: 'list', config: { selectText: 'Selecione...', list: [{ value: "Identificacao", title: "Identificação" }, { value: "Pagamento", title: "Pagamento", selected: true }] } };
        var tiposComissoesColuna = { width: '20%', editor: filtroTiposComissoes, filter: /*filtroTiposComissoes*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsComissoesPrivadoValores.columns = {
            tipoComissao: tiposComissoesColuna,
            inicial: {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = this.formatterValue(value);
                    return newValue;
                },
                title: 'Valor Inicial', width: '23%', filter: false
            },
            final: {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = this.formatterValue(value);
                    return newValue;
                },
                title: 'Valor Final', width: '23%', filter: false
            },
            percentual:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2PercentageComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = value + "%";
                    return newValue;
                },
                title: 'Porcentagem', width: '20%', filter: false
            }
        };


        this.settingsHonorariosJDValores.columns = {
            inicial: {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = this.formatterValue(value);
                    return newValue;
                },
                title: 'Valor Inicial', width: '23%', filter: false
            },
            final: {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = this.formatterValue(value);
                    return newValue;
                },
                title: 'Valor Final', width: '23%', filter: false
            },
            percentual:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2PercentageComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = value + "%";
                    return newValue;
                },
                title: 'Porcentagem', width: '20%', filter: false
            },
            valor:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = "R$ " + value;
                    return newValue;
                },
                title: 'Valor', width: '20%', filter: false
            }
        };


        //Aba de comissoes sem range de valores
        var filtroTiposComissoesv2 = { type: 'list', config: { selectText: 'Selecione...', list: [{ value: "Pagamento", title: "Pagamento" }, { value: "Identificacao", title: "Identificação" }] } };
        var tiposComissoesColunav2 = { width: '40%', editor: filtroTiposComissoesv2, filter: /*filtroTiposComissoesv2*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsComissoesPublicoValores.columns = {
            tipoComissao: tiposComissoesColunav2,
            percentual:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2PercentageComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = value + "%";
                    return newValue;
                },
                title: 'Porcentagem', width: '40%', filter: false
            }
        };



        //Aba de comissoes com range de dias
        var filtroTiposComissoestipoData = { type: 'list', config: { selectText: 'Selecione...', list: [{ value: "Dias do Recebimento", title: "Dias do Recebimento" }, { value: "Dias de vencido", title: "Dias de vencido" }] }, };
        var tiposComissoesColunatipoData = { title: "Tipo Data", width: '30%', editor: filtroTiposComissoestipoData, filter: /*filtroTiposComissoestipoData*/false, filterFunction(value, filter) { return filter === value; } };


        this.settingsComissoesPrivadoDias.columns = {
            tipoComissao: tiposComissoesColunatipoData,
            inicial:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2Component,
                },
                title: 'Quantidade de dias inicial', width: '20%', filter: false
            },
            final:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2Component,
                },
                title: 'Quantidade de dias final', width: '20%', filter: false
            }
            , percentual:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2PercentageComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = value + "%";
                    return newValue;
                },
                title: 'Porcentagem', width: '20%', filter: false
            }
        };


        //Aba de comissoes sem range de dias
        var filtroTiposComissoesv2tipoData = { type: 'list', config: { title: "Tipo de comissão", selectText: 'Selecione...', list: [{ value: "Dias do Recebimento", title: "Dias do Recebimento" }, { value: "Dias de vencido", title: "Dias de vencido" }] }, };
        var tiposComissoesColunav2tipoData = { title: "Tipo Data", width: '30%', editor: filtroTiposComissoesv2tipoData, filter: /*filtroTiposComissoesv2tipoData*/false, filterFunction(value, filter) { return filter === value; } };


        this.settingsComissoesPublicoDias.columns = {
            tipoComissao: tiposComissoesColunav2tipoData, inicial:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2Component,
                },
                title: 'Quantidade de dias inicial', width: '20%', filter: false
            },
            final:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2Component,
                },
                title: 'Quantidade de dias final', width: '20%', filter: false
            }
            , percentual:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2PercentageComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = value + "%";
                    return newValue;
                },
                title: 'Porcentagem', width: '20%', filter: false
            }
        };


        //Aba de comissoes do cobrador
        var filtroTiposComissoesCobrador = { type: 'list', config: { selectText: 'Selecione...', list: [{ value: "Cobrador 1", title: "Cobrador 1 - Taxa + Nota" }, { value: "Cobrador 2", title: "Cobrador 2 - Taxa Ou Nota", selected: true }] } };
        var tiposComissoesCobradorColuna = { width: '50%', editor: filtroTiposComissoesCobrador, filter: /*filtroTiposComissoesCobrador*/false, filterFunction(value, filter) { return filter === value; } };
        this.settingsComissoesCobradorValores.columns = {
            tipoCobrador: tiposComissoesCobradorColuna,
            percentual:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2PercentageComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = value + "%";
                    return newValue;
                },
                title: 'Porcentagem', width: '20%', filter: false
            }
        };

        //Aba de superiores do advogado
        //var filtroTiposComissoesCobrador = { type: 'list', config: { selectText: 'Selecione...', list: [{ value: "Cobrador 1", title: "Cobrador 1 - Taxa + Nota" },{ value: "Cobrador 2", title: "Cobrador 2 - Taxa Ou Nota" , selected: true}] } };
        //var tiposComissoesCobradorColuna = { width: '20%' , editor: filtroTiposComissoesCobrador, filter: filtroTiposComissoesCobrador, filterFunction(value, filter) { return filter === value; }  };
        this.settingsSuperioresAdvogado.columns = {
            cpfCnpj: { title: 'Cpf ou Cnpj', width: '25%', filter: false }, nome: { title: 'Nome', width: '25%', filter: false },
            email: { title: 'Email', width: '25%', filter: false }
        };

        //aba historico de comissoes
        this.settingsHistoricoComissoes.columns = {
            cpfCnpj: { title: 'Cpf ou Cnpj', width: '25%', filter: false }, nome: { title: 'Nome', width: '25%', filter: false },
            email: { title: 'Email', width: '25%', filter: false }
        };


    }

    cadastrar() {

        this.addLists();
        this.montarComissoesParaUpdate();
        var cpfCnpj = this.utilsService.mascaraCpfCnpjCadastro(this.pessoa2.cpfCnpj);
        this.pessoa2.cpfCnpj = cpfCnpj;


        if (this.validarCadastroPessoa(this.pessoa2)) {

            if (this.modalRef.componentInstance.updateData) {
                this.update(this.pessoa2)
            } else {
                this.add(this.pessoa2);
            }


        }
    }

    onDeleteConfirm(event) {

        if (window.confirm('Você quer mesmo deletar esse registro ?')) {
            const index = event.source.data.indexOf(event.data);
            event.source.data.splice(index, 1);
            event.confirm.resolve();
        } else {
            event.confirm.reject();
        }
    }

    onCreateEditHonorarioJD(event){
        if(event){
            if(+event.newData['valor'] == 0 && +event.newData['percentual']==0){
                this.toastrService.error('Informe o percentual ou o valor!', 'Atenção', { timeOut: 10000 });
                event.confirm.reject();
                return;
            }

            if(+event.newData['valor'] > 0){
                event.newData['percentual']=0;
            }

            event.confirm.resolve(event.newData);
        }
    }

    onDeleteConfirmComissao(event) {

        if (window.confirm('Você quer mesmo deletar essa comissão ?')) {
            const index = event.source.data.indexOf(event.data);
            event.source.data.splice(index, 1);
            event.confirm.resolve();
            if (this.pessoa2.id != null && this.pessoa2.id != undefined) {
                this.deleteCredorComissoes(this.pessoa2.id, event.data.id)
            }
        } else {
            event.confirm.reject();
        }
    }

    onDeleteConfirmHonorariosJD(event) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            text: 'Deseja realmente excluir?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const index = event.source.data.indexOf(event.data);
                event.source.data.splice(index, 1);
                event.confirm.resolve();
                if (this.pessoa2.id != null && this.pessoa2.id != undefined) {
                    this.deleteCredorComissoes(this.pessoa2.id, event.data.id)
                    //aqui vai remover
                }
            } else {
                event.confirm.reject();
            }
        });
    }


    getArquivosPessoa() {
        this.pessoaService.getAllArquivos(this.pessoa2.id)
            .subscribe(result => {

                this.source = new LocalDataSource(result.data);


            }, error => {
                if (error && error.errors) {
                    error.errors.forEach(element => {
                        this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                    });
                } else {
                    this.toastrService.error('Não foi possível obter os arquivos da pessoa!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    validarCadastroPessoa(pessoa) {

        if (this.uploader.queue.length > 0) {
            var filesValidation = [];
            this.uploader.queue.forEach(x => {
                const file = x.file as CustomFileLike;
                filesValidation.push(file);
            });
            var check = filesValidation.find(x => x.documentType == Guid.EMPTY);
            if (check != null || check != undefined) {
                this.toastrService.error('Selecione um tipo de documento valido.', 'Erro');
                return false;
            }
        }


        if (pessoa.tipo == null || pessoa.tipo == undefined || pessoa.tipo == '') {
            this.toastrService.error('Seleciona o tipo de pessoa.', 'Erro');
            return false;
        }
        if (pessoa.nome == null || pessoa.nome == undefined || pessoa.nome == '' || pessoa.nome.length <= 2) {
            this.toastrService.error('Nome da pessoa inválido.', 'Erro');
            return false;
        }

        if (pessoa.tipo != 'Devedor') {
            if (pessoa.email == null || pessoa.email == undefined || pessoa.email == '') {
                this.toastrService.error('E-mail da pessoa inválido', 'Erro');
                return false;
            }
        }
        if (pessoa.cpfCnpj == null || pessoa.cpfCnpj == undefined || pessoa.cpfCnpj == '') {
            this.toastrService.error('CPF ou CNPJ inválido.', 'Erro');
            return false;
        }

        return true;
    }

    add(pessoa) {
        this.spinner.show();
        this.toastrService.clear();

        this.pessoaService.add(this.pessoa2)
            .subscribe(async res => {

                this.pessoa2.id = res.data.id;

                if (!this.updateCredorComissoes(pessoa.id, this.credorComissoesComVigenciaAtiva)) {
                    this.toastrService.error('Comissoes não foram salvas corretamente', 'Erro');
                };

                await this.enviarArquivos().subscribe(x => {

                }, erro => { });

                this.spinner.hide();
                this.toastrService.success("Pessoa cadastrada.", "Successo");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });

            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a pessoa!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    update(pessoa) {


        this.spinner.show();
        this.toastrService.clear();

        this.pessoaService.update(this.pessoa2)
            .subscribe(async res => {

                if (!this.updateCredorComissoes(pessoa.id, this.credorComissoesComVigenciaAtiva)) {
                    this.toastrService.error('Comissoes não foram salvas corretamente', 'Erro');
                };

                this.toastrService.success('Pessoa atualizada.', 'Successo');

                await this.enviarArquivos().subscribe(x => {

                }, erro => { });

                this.spinner.hide();
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
            }, error => {
                if (error && error.errors) {
                    error.errors.forEach(element => {
                        this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                    });
                } else {
                    this.toastrService.error('Não foi possível atualizar a pessoa!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }


    // métodos basicos
    cadastrarCampo() {
        this.cadastrandoCampo = true;
    }

    fecharCadastrarCampo() {
        this.cadastrandoCampo = false;
    }

    isCPF(): boolean {
        return this.pessoa2.cpfCnpj == null ? true : this.pessoa2.cpfCnpj.length <= 14 ? true : false;
    }

    getCpfCnpjMask() {
        var cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
        var cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

        return this.isCPF() ? cpfMask : cnpjMask;
    }

    switchTipoComissao(event) {
        if (this.tipoComissao == "0") {
            this.tipoComissao = "1";
        }
        else {
            this.tipoComissao = "0";
        }
        //this.pessoa.cliente.tipoComissao =
    }


    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    montarComissoesParaUpdate() {
        var credorComissoes = []
        if (this.pessoa2.tipo == "Credor") {

            this.comissoesPrivadoValores.forEach(function (item) {
                item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada);
                item.ambiente = "1";
            });

            this.comissoesPublicoValores.forEach(function (item) {
                item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica);
                item.ambiente = "1";
            });

            this.comissoesPrivadoDias.forEach(function (item) {
                item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada);
                item.ambiente = "1";
            });

            this.comissoesPublicoDias.forEach(function (item) {
                item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica);
                item.ambiente = "1";
            });

            credorComissoes.push.apply(credorComissoes, this.comissoesPrivadoValores);
            credorComissoes.push.apply(credorComissoes, this.comissoesPublicoValores);
            credorComissoes.push.apply(credorComissoes, this.comissoesPrivadoDias);
            credorComissoes.push.apply(credorComissoes, this.comissoesPublicoDias);
        }

        this.honorariosJDPrivadoValoresInitialFee.forEach(function (item) {
            item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada);
            item.ambiente = "2";
            item.tipoComissao = "Initial Fee";
        });

        this.honorariosJDPublicoValoresInitialFee.forEach(function (item) {
            item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica);
            item.ambiente = "2";
            item.tipoComissao = "Initial Fee";
        });

        this.honorariosJDPrivadoValoresSuccessFee.forEach(function (item) {
            item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada);
            item.ambiente = "2";
            item.tipoComissao = "Success Fee";
        });

        this.honorariosJDPublicoValoresSuccessFee.forEach(function (item) {
            item.entidade = TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica);
            item.ambiente = "2";
            item.tipoComissao = "Success Fee";
        });

        credorComissoes.push.apply(credorComissoes, this.honorariosJDPrivadoValoresInitialFee);
            credorComissoes.push.apply(credorComissoes, this.honorariosJDPublicoValoresInitialFee);
            credorComissoes.push.apply(credorComissoes, this.honorariosJDPrivadoValoresSuccessFee);
            credorComissoes.push.apply(credorComissoes, this.honorariosJDPublicoValoresSuccessFee);

            credorComissoes.forEach(function (value) {
                value.inicial = isNaN(Number(value.inicial)) ? 0 : Number(value.inicial);
                value.final = isNaN(Number(value.final)) ? 0 : Number(value.final);
                value.percentual = isNaN(Number(value.percentual)) ? 0 : Number(value.percentual);
            });

        this.credorComissoesComVigenciaAtiva = credorComissoes;

    }



    getDescricaoTipoArquivo(cell, row) {
        return this.tipoDocumentos.find(t => t.valor == cell.id).nome;
    }

    getTiposDocumento() {
        this.documenoService.getAllByTipo(Tipo.Pessoa)
            .subscribe(result => {
                this.tipoDocumentos.push({ descricao: '-- Selecione --', nome: 'selecione', valor: Guid.EMPTY })
                result.data.forEach(tipoDocumento => {
                    this.tipoDocumentos.push({ descricao: tipoDocumento.descricao, nome: tipoDocumento.nome, valor: tipoDocumento.id });
                });
            }, error => {
                if (error && error.errors) {
                    error.errors.forEach(element => {
                        this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                    });
                } else {
                    this.toastrService.error('Não foi possível obter os tipos de documento!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(event) {
        this.file = event[0];
        const fileInQueue = this.uploader.queue.find(q => q.file.name === this.file.name);
        const newFile = new CustomFileLike(fileInQueue.file);
        newFile.documentType = this.tipoDocumentos[0].valor;
        fileInQueue.file = newFile;
    }

    onTipoDocumentChange(index, customFile) {
        customFile.documentType = this.tipoDocumentos[index].valor;
    }

    enviarArquivos(): Observable<boolean> {
        const envioArquivos = new Observable<boolean>((observer) => {
            const arquivosUpload = [];
            this.uploader.queue.forEach(item => {
                debugger;
                arquivosUpload.push(this.montarFormDataArquivo(item.file));
            });

            arquivosUpload.forEach(arquivo => {
                this.pessoaService.uploadArquivo(arquivo).subscribe(result => {
                    if (result.success) {
                        this.arquivos.push(result.data);
                        this.uploader.clearQueue();
                        this.source.append(result.data);
                        this.toastrService.success(`Arquivo ${result.data.nome} salvo.`, 'Successo');
                        observer.next(true);
                        return;
                    } else {
                        result.data.errors.forEach(element => {
                            this.toastrService.error(element);
                        });
                        observer.error('Houve um erro no envio de arquivos');
                        return;
                    }
                }, error => {
                    if (error && error.errors) {
                        error.errors.forEach(element => {
                            this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                        });
                    } else {
                        this.toastrService.error('Não foi possível enviar o arquivo!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                    observer.error('Houve um erro no envio de arquivos');
                    return;
                });
            });
            observer.next(false);
        });

        return envioArquivos;
    }

    montarFormDataArquivo(item: FileLikeObject) {
        const file = item as CustomFileLike;
        const formData: FormData = new FormData();
        formData.append('pessoaId', this.pessoa2.id);
        formData.append('pasta', 'pessoas');
        formData.append('tipoDocumentoId', file.documentType);
        formData.append('nome', file.name);
        formData.append('contentType', file.type);
        formData.append('file', this.file as File);
        return formData;
    }

    onExcluirArquivo($event) {
        if (window.confirm('Confirma a exclusão do arquivo?')) {
            const pessoaId = this.pessoa2.id;
            const arquivoId = $event.data.id;

            this.pessoaService.deleteArquivo(pessoaId, arquivoId).subscribe(result => {
                if (result.success) {
                    this.toastrService.success(`Arquivo excluído.`, 'Successo');
                    $event.confirm.resolve();
                }
            }, error => {
                if (error && error.errors) {
                    error.errors.forEach(element => {
                        this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                    });
                } else {
                    this.toastrService.error('Não foi possível excluir o arquivo!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
        } else {
            $event.confirm.reject();
        }
    }

    updateCredorComissoes(pessoaId, comissoes) {
        return this.pessoaService.updateCredorComissoes(pessoaId, comissoes).subscribe(x => {
            return true;
        }, error => {
            return false;
        })
    }

    getCredorComissoes(pessoaId) {
        this.pessoaService.getCredorComissoes(pessoaId).subscribe(x => {

            this.credorComissoesComVigenciaAtiva = x.data.filter(function vigenciaAtiva(element, index, array) {
                return (element.dataVigenciaFinal == "0001-01-01T00:00:00");
            });

            console.log(x);

            this.comissoesPrivadoValores = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return ((element.tipoComissao == "Identificacao" || element.tipoComissao == "Pagamento") && element.ambiente == "Amigavel" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada));
            });

            this.comissoesPublicoValores = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return ((element.tipoComissao == "Identificacao" || element.tipoComissao == "Pagamento") && element.ambiente == "Amigavel" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica));
            });

            this.comissoesPrivadoDias = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return ((element.tipoComissao == "Dias do Recebimento" || element.tipoComissao == "Dias de vencido") && element.ambiente == "Amigavel" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada));
            });

            this.comissoesPublicoDias = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return ((element.tipoComissao == "Dias do Recebimento" || element.tipoComissao == "Dias de vencido") && element.ambiente == "Amigavel" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica));
            });

            this.honorariosJDPrivadoValoresInitialFee = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return (element.tipoComissao == "Initial Fee" && element.ambiente == "Juridico" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada));
            });

            this.honorariosJDPublicoValoresInitialFee = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return (element.tipoComissao == "Initial Fee" && element.ambiente == "Juridico" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica));
            });

            this.honorariosJDPrivadoValoresSuccessFee = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return (element.tipoComissao == "Success Fee" && element.ambiente == "Juridico" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Privada));
            });

            this.honorariosJDPublicoValoresSuccessFee = this.credorComissoesComVigenciaAtiva.filter(function getComissoes(element, index, array) {
                return (element.tipoComissao == "Success Fee" && element.ambiente == "Juridico" && element.entidade == TipoCredorComissoesEntidadeLabel.get(TipoCredorComissoesEntidade.Publica));
            });

            this.credorComissoesHistorico = x.data;

            this.credorComissoesHistoricoDias = x.data.filter(function dias(element, index, array) {
                return (element.tipoComissao.includes("Dias"));
            });
            this.credorComissoesHistoricoValor = x.data.filter(function dias(element, index, array) {
                return (!element.tipoComissao.includes("Dias"));
            });
            //credorComissoesHistoricoValor = [];
        }, error => {

        })
    }

    deleteCredorComissoes(pessoaId, id) {
        return this.pessoaService.deleteCredorComissoes(pessoaId, id).subscribe(x => {
            return true;
        }, error => {
            return false;
        })
    }


    onChangeTipoPessoa(event) {
        if (this.isUpdateContato) {
            this.active = 9;
            return;
        }

        switch (event) {
            case "Advogado":
                this.active = 1;
                break;
            case "Credor":
                this.active = 2;
                if (!this.modalRef.componentInstance.updateData) {
                    this.politicaDeAcordoDefault();
                    this.politicaDeAcordoDefaultJuridico();
                }
                break;

            case "Devedor":
                this.active = 3;
                break;
            case "Cobrador":
                this.active = 4;
                break;

            case "Avalista":
                this.active = 5;
                break;
            case "Administrador":
                this.active = 6;
                break;
            case "Colaborador":
                this.active = 7;
                break;
            default:
                this.active = 0;
                break
        }

        this.checks(this.pessoa2);
    }


    politicaDeAcordoDefault() {
        var jurosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.jurosMinimo, this.pessoa2);
        var jurosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.jurosMaximo, this.pessoa2);
        jurosMinimo.valor = '0';
        jurosMaximo.valor = '0';

        var parcelamentoMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);
        var parcelamentoMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);
        parcelamentoMinimo.valor = '1';
        parcelamentoMaximo.valor = '1';
        this.permitirParcelamento = false;

        var politica = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.politica, this.pessoa2);
        politica.valor = 'CDD';


        var checarParamentros = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.checarParametrosAoGerarAcordo, this.pessoa2);
        checarParamentros.valor = 'Nao';
        this.checarAcordo = false;

    }
    politicaDeAcordoDefaultJuridico() {
      var percentualIndiceCorrecao = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
        this.pessoaMap.credorInfoCad.percentualIndiceCorrecao, this.pessoa2);
      var percentualJuros = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.percentualJuros, this.pessoa2);
      var percentualMulta = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.percentualMulta, this.pessoa2);
      var limiteDescontoSobValorAtualizado = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
            this.pessoaMap.credorInfoCad.LimiteDescontoSobValorAtualizado, this.pessoa2);
      limiteDescontoSobValorAtualizado.valor = '0';
      percentualIndiceCorrecao.valor = '0';
      percentualJuros.valor = '0';
      percentualMulta.valor = '0';

      var parcelamentoMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);
      var parcelamentoMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);
      parcelamentoMinimo.valor = '1';
      parcelamentoMaximo.valor = '1';
      this.permitirParcelamentoJuridico = false;

      var politica = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.politica, this.pessoa2);
      politica.valor = 'CDD';


      var checarParamentros = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.checarParametrosAoGerarAcordo, this.pessoa2);
      checarParamentros.valor = 'Nao';
      this.checarAcordoJuridico = false;

  }

    handleChangeParcelamento(evt, tipo) {

        var pessoaDadosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);

        var pessoaDadosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);

        var target = evt.target;

        if (tipo == 'Sim') {
            this.permitirParcelamento = true;

        }
        if (tipo == 'Não') {
            this.permitirParcelamento = false;
            pessoaDadosMinimo.valor = '1';
            pessoaDadosMaximo.valor = '1';
            // this.pessoa.cliente.parcelamentoMinimo.valor = '1';
            // this.pessoa.cliente.parcelamentoMaximo.valor = '1';
        }

    }
    handleChangeParcelamentoJuridico(evt, tipo) {

      var pessoaDadosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
          this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);

      var pessoaDadosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
          this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);

      var target = evt.target;

      if (tipo == 'Sim') {
          this.permitirParcelamentoJuridico = true;

      }
      if (tipo == 'Não') {
          this.permitirParcelamentoJuridico = false;
          pessoaDadosMinimo.valor = '1';
          pessoaDadosMaximo.valor = '1';
          // this.pessoa.cliente.parcelamentoMinimo.valor = '1';
          // this.pessoa.cliente.parcelamentoMaximo.valor = '1';
      }

    }

    handleChangePolitica(evt, tipo) {

        var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.politica, this.pessoa2);

        var target = evt.target;


        if (tipo == 'CDD') {
            pessoaDados.valor = 'CDD';
            // this.pessoa.cliente.politica.valor = 'CDD';
        }
        if (tipo == 'Credor') {
            pessoaDados.valor = 'Credor'
            //this.pessoa.cliente.politica.valor = 'Credor';

        }

    }

    handleChangeChecarParametrosAoGerarAcordo(evt, tipo) {

        var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.checarParametrosAoGerarAcordo, this.pessoa2);


        var target = evt.target;

        if (tipo == 'Sim') {
            pessoaDados.valor = 'Sim';

            //permitir parcelamento = nao
            var pessoaDadosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
                this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);
            var pessoaDadosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
                this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);
            pessoaDadosMinimo.valor = '1';
            pessoaDadosMaximo.valor = '1';
            //

            //politica = credor
            var pessoaDadosPolitica = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
                this.pessoaMap.credorInfoCad.politica, this.pessoa2);
            pessoaDadosPolitica.valor = 'Credor';
            //

            //juros maximo e juros minimo = 0;
            var jurosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
                this.pessoaMap.credorInfoCad.jurosMinimo, this.pessoa2);
            var jurosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
                this.pessoaMap.credorInfoCad.jurosMaximo, this.pessoa2);
            jurosMinimo.valor = '0';
            jurosMaximo.valor = '0';
            //
            this.politica = this.checkPolitica();
            this.permitirParcelamento = this.checkPermitirParcelamento();
            this.checarAcordo = true;


        }
        if (tipo == 'Não') {
            pessoaDados.valor = 'Nao';
            this.checarAcordo = false;

        }

    }

    handleChangeChecarParametrosAoGerarAcordoJuridico(evt, tipo) {

      var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.checarParametrosAoGerarAcordo, this.pessoa2);


      var target = evt.target;

      if (tipo == 'Sim') {
          pessoaDados.valor = 'Sim';

          //permitir parcelamento = nao
          var pessoaDadosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
              this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);
          var pessoaDadosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
              this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);
          pessoaDadosMinimo.valor = '1';
          pessoaDadosMaximo.valor = '1';
          //

          //politica = credor
          var pessoaDadosPolitica = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
              this.pessoaMap.credorInfoCad.politica, this.pessoa2);
          pessoaDadosPolitica.valor = 'Credor';
          //

          var percentualIndiceCorrecao = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
              this.pessoaMap.credorInfoCad.percentualIndiceCorrecao, this.pessoa2);
          var percentualJuros = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
              this.pessoaMap.credorInfoCad.percentualJuros, this.pessoa2);
          var percentualMulta = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
              this.pessoaMap.credorInfoCad.percentualMulta, this.pessoa2);
          var limiteDescontoSobValorAtualizado = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
              this.pessoaMap.credorInfoCad.LimiteDescontoSobValorAtualizado, this.pessoa2);
          limiteDescontoSobValorAtualizado.valor = '0';
          percentualIndiceCorrecao.valor = '0';
          percentualJuros.valor = '0';
          percentualMulta.valor = '0';
          //
          this.politicaJuridico = this.checkPoliticaJuridico();
          this.permitirParcelamentoJuridico = this.checkPermitirParcelamentoJuridico();
          this.checarAcordoJuridico = true;


      }
      if (tipo == 'Não') {
          pessoaDados.valor = 'Nao';
          this.checarAcordoJuridico = false;

      }

    }

    checkPermitirParcelamento() {

        var pessoaDadosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);

        if (pessoaDadosMinimo.valor == '') {
            pessoaDadosMinimo.valor = '1';
        }

        var pessoaDadosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);

        if (pessoaDadosMaximo.valor == '') {
            pessoaDadosMaximo.valor = '1';
        }


        if (pessoaDadosMinimo.valor === '1' && pessoaDadosMaximo.valor === '1') {
            return false;
        }
        return true;
    }
    checkPermitirParcelamentoJuridico() {

      var pessoaDadosMinimo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.parcelamentoMinimo, this.pessoa2);

      if (pessoaDadosMinimo.valor == '') {
          pessoaDadosMinimo.valor = '1';
      }

      var pessoaDadosMaximo = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.parcelamentoMaximo, this.pessoa2);

      if (pessoaDadosMaximo.valor == '') {
          pessoaDadosMaximo.valor = '1';
      }


      if (pessoaDadosMinimo.valor === '1' && pessoaDadosMaximo.valor === '1') {
          return false;
      }
      return true;
    }

    checkPolitica() {

        var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.politica, this.pessoa2);

        if (pessoaDados.valor == '') {
            pessoaDados.valor = 'CDD';
        }

        if (pessoaDados.valor === 'CDD') {
            return true;
        }
        return false;


    }
    checkPoliticaJuridico() {

      var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.politica, this.pessoa2);

      if (pessoaDados.valor == '') {
          pessoaDados.valor = 'CDD';
      }

      if (pessoaDados.valor === 'CDD') {
          return true;
      }
      return false;


  }

    checkChecarParametrosAoGerarAcordo() {

        var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.checarParametrosAoGerarAcordo, this.pessoa2);

        if (pessoaDados.valor == '') {
            pessoaDados.valor = 'Nao';
        }

        if (pessoaDados.valor === 'Sim') {
            return true;
        }
        return false;


    }
    checkChecarParametrosAoGerarAcordoJuridico() {

      var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ,
          this.pessoaMap.credorInfoCad.checarParametrosAoGerarAcordo, this.pessoa2);

      if (pessoaDados.valor == '') {
          pessoaDados.valor = 'Nao';
      }

      if (pessoaDados.valor === 'Sim') {
          return true;
      }
      return false;


  }






    handleChangeCalcularHonorarios(evt, tipo) {

        var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.taxaAdministrativa, this.pessoa2);

        var target = evt.target;
        if (tipo == 'Sim') {
            this.calcularHonorarios = true;


        }
        if (tipo == 'Não') {
            this.calcularHonorarios = false;
            pessoaDados.valor = '0';
            //this.pessoa.cliente.taxaAdministrativa.valor = '0';
        }

    }

    checkTaxaAdministrativa() {

        var pessoaDados = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,
            this.pessoaMap.credorInfoCad.taxaAdministrativa, this.pessoa2);

        if (pessoaDados.valor != '0' && pessoaDados.valor != '') {
            return true;
        }
        return false;

        // if (this.pessoa.cliente.taxaAdministrativa.valor != '0') {
        //     return true;
        // } else {
        //     false;
        // }
    }


    getValue(chavePessoaDados: String, chavePessoaComplementar, pessoa: Pessoa) {

        var pessoaDados = this.pessoa2.pessoaDados.find(x => x.nome == chavePessoaDados);

        if (pessoaDados != null) {
            var pessoaDadosComplementares = pessoaDados.pessoaDadosComplementares.find(x => x.nome == chavePessoaComplementar);

            if (pessoaDadosComplementares != null) {
                return pessoaDadosComplementares;
            } else {
                var newPessoaDadosComp = new PessoaDadosComplementares();
                newPessoaDadosComp.pessoaDadosId = pessoaDados.id;
                newPessoaDadosComp.nome = chavePessoaComplementar;

                pessoaDados.pessoaDadosComplementares.push(newPessoaDadosComp);

                return newPessoaDadosComp;
            }


        }
        else {
            debugger;
            var newPessoaDados = new PessoaDados();
            newPessoaDados.nome = chavePessoaDados;

            var newPessoaDadosComp = new PessoaDadosComplementares();
            newPessoaDadosComp.pessoaDadosId = newPessoaDados.id;
            newPessoaDadosComp.nome = chavePessoaComplementar;

            newPessoaDados.pessoaDadosComplementares.push(newPessoaDadosComp);

            this.pessoa2.pessoaDados.push(newPessoaDados);

            return newPessoaDadosComp;

        }



    }


    getListValue(chavePessoaDados: String, pessoa: Pessoa) {
        var pessoaDados = this.pessoa2.pessoaDados.filter(function getListValues(element, index, array) {
            return element.nome == chavePessoaDados;
        });

        if (pessoaDados != null) {
            return pessoaDados;
        }

    }

    getDadoCompNg2(chaveComplementar: String, pdc: PessoaDados) {
        if (pdc.pessoaDadosComplementares != undefined) {
            var pessoaDadosComplementares = pdc.pessoaDadosComplementares.find(x => x.nome == chaveComplementar);

            if (pessoaDadosComplementares != null) {
                return pessoaDadosComplementares;
            }
        }


        var pessoaDadosComplementar = new PessoaDadosComplementares();
        pessoaDadosComplementar.nome = chaveComplementar;
        pessoaDadosComplementar.valor = "";
        pessoaDadosComplementar.pessoaDadosId = pdc.id != null ? pdc.id : undefined;

        return pessoaDadosComplementar;

    }


    startTableNgBanco(pessoaDados: PessoaDados[], dadosTableNG: any[]) {
        pessoaDados.forEach(x => {
            var nomeBanco = this.getDadoCompNg2(this.pessoaMap.banco.nomeBanco, x).valor;
            var nomeBancoId = this.getDadoCompNg2(this.pessoaMap.banco.nomeBanco, x).id;
            var agencia = this.getDadoCompNg2(this.pessoaMap.banco.agencia, x).valor;
            var agenciaId = this.getDadoCompNg2(this.pessoaMap.banco.agencia, x).id;
            var contato = this.getDadoCompNg2(this.pessoaMap.banco.contato, x).valor;
            var contatoId = this.getDadoCompNg2(this.pessoaMap.banco.contato, x).id;
            var contaCorrente = this.getDadoCompNg2(this.pessoaMap.banco.contaCorrente, x).valor;
            var contaCorrenteId = this.getDadoCompNg2(this.pessoaMap.banco.contaCorrente, x).id;
            var pessoaDadosId = x.id;
            dadosTableNG.push({
                nomeBanco: nomeBanco,
                nomeBancoId: nomeBancoId,
                agencia: agencia,
                agenciaId: agenciaId,
                contato: contato,
                contatoId: contatoId,
                contaCorrente: contaCorrente,
                contaCorrrenteId: contaCorrenteId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshBancoPessoa() {

        this.bancosPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.banco.bancoPd);

        this.bancosPessoaNG.forEach(x => {

            var pdcNomeBanco = new PessoaDadosComplementares();
            pdcNomeBanco.valor = x.nomeBanco,
                pdcNomeBanco.nome = this.pessoaMap.banco.nomeBanco;
            pdcNomeBanco.id = x.nomeBancoId,
                pdcNomeBanco.pessoaDadosId = x.pessoaDadosId;

            var pdcAgencia = new PessoaDadosComplementares();
            pdcAgencia.valor = x.agencia,
                pdcAgencia.nome = this.pessoaMap.banco.agencia;
            pdcAgencia.id = x.agenciaId,
                pdcAgencia.pessoaDadosId = x.pessoaDadosId;

            var pdcContato = new PessoaDadosComplementares();
            pdcContato.valor = x.contato,
                pdcContato.nome = this.pessoaMap.banco.contato;
            pdcContato.id = x.contatoId,
                pdcContato.pessoaDadosId = x.pessoaDadosId;

            var pdcContaCorrente = new PessoaDadosComplementares();
            pdcContaCorrente.valor = x.contaCorrente,
                pdcContaCorrente.nome = this.pessoaMap.banco.contaCorrente;
            pdcContaCorrente.id = x.contaCorrenteId,
                pdcContaCorrente.pessoaDadosId = x.pessoaDadosId;

            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.banco.bancoPd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcNomeBanco);
            pd.pessoaDadosComplementares.push(pdcAgencia);
            pd.pessoaDadosComplementares.push(pdcContato);
            pd.pessoaDadosComplementares.push(pdcContaCorrente);

            this.bancosPessoa.push(pd);
        });
    }


    startTableNgTelefone(pessoaDados: PessoaDados[], dadosTableNG: any[]) {

        pessoaDados.forEach(x => {
            var tipoTelefone = this.getDadoCompNg2(this.pessoaMap.telefone.tipo, x).valor;
            var tipoTelefoneId = this.getDadoCompNg2(this.pessoaMap.telefone.tipo, x).id;
            var numero = this.getDadoCompNg2(this.pessoaMap.telefone.numero, x).valor;
            var numeroId = this.getDadoCompNg2(this.pessoaMap.telefone.numero, x).id;
            var ramal = this.getDadoCompNg2(this.pessoaMap.telefone.ramal, x).valor;
            var ramalId = this.getDadoCompNg2(this.pessoaMap.telefone.ramal, x).id;
            var contato = this.getDadoCompNg2(this.pessoaMap.telefone.contato, x).valor;
            var contatoId = this.getDadoCompNg2(this.pessoaMap.telefone.contato, x).id;
            var email = this.getDadoCompNg2(this.pessoaMap.telefone.email, x).valor;
            var emailId = this.getDadoCompNg2(this.pessoaMap.telefone.email, x).id;
            var departamento = this.getDadoCompNg2(this.pessoaMap.telefone.departamento, x).valor;
            var departamentoId = this.getDadoCompNg2(this.pessoaMap.telefone.departamento, x).id;
            var pessoaDadosId = x.id;
            dadosTableNG.push({
                tipoTelefone: tipoTelefone,
                tipoTelefoneId: tipoTelefoneId,
                numero: numero,
                numeroId: numeroId,
                ramal: ramal,
                ramalId: ramalId,
                contato: contato,
                contatoId: contatoId,
                email: email,
                emailId: emailId,
                departamento: departamento,
                departamentoId: departamentoId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshTelefonePessoa() {

        this.telefonesPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.telefone.telefonePd);

        this.telefonesPessoaNG.forEach(x => {

            var pdcTipoTelefone = new PessoaDadosComplementares();
            pdcTipoTelefone.valor = x.tipoTelefone,
                pdcTipoTelefone.nome = this.pessoaMap.telefone.tipo;
            pdcTipoTelefone.id = x.tipoTelefoneId,
                pdcTipoTelefone.pessoaDadosId = x.pessoaDadosId;

            var pdcNumero = new PessoaDadosComplementares();
            pdcNumero.valor = x.numero,
                pdcNumero.nome = this.pessoaMap.telefone.numero;
            pdcNumero.id = x.numeroId,
                pdcNumero.pessoaDadosId = x.pessoaDadosId;

            var pdcRamal = new PessoaDadosComplementares();
            pdcRamal.valor = x.ramal,
                pdcRamal.nome = this.pessoaMap.telefone.ramal;
            pdcRamal.id = x.ramalId,
                pdcRamal.pessoaDadosId = x.pessoaDadosId;

            var pdcContato = new PessoaDadosComplementares();
            pdcContato.valor = x.contato,
                pdcContato.nome = this.pessoaMap.telefone.contato;
            pdcContato.id = x.contatoId,
                pdcContato.pessoaDadosId = x.pessoaDadosId;

            var pdcEmail = new PessoaDadosComplementares();
            pdcEmail.valor = x.email,
                pdcEmail.nome = this.pessoaMap.telefone.email;
            pdcEmail.id = x.emailId,
                pdcEmail.pessoaDadosId = x.pessoaDadosId;

            var pdcDepartamento = new PessoaDadosComplementares();
            pdcDepartamento.valor = x.departamento,
                pdcDepartamento.nome = this.pessoaMap.telefone.departamento;
            pdcDepartamento.id = x.departamentoId,
                pdcDepartamento.pessoaDadosId = x.pessoaDadosId;



            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.telefone.telefonePd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcTipoTelefone);
            pd.pessoaDadosComplementares.push(pdcNumero);
            pd.pessoaDadosComplementares.push(pdcRamal);
            pd.pessoaDadosComplementares.push(pdcContato);
            pd.pessoaDadosComplementares.push(pdcEmail);
            pd.pessoaDadosComplementares.push(pdcDepartamento);

            this.telefonesPessoa.push(pd);
        });
    }



    startTableNgTelefoneDevedor(pessoaDados: PessoaDados[], dadosTableNG: any[]) {

        pessoaDados.forEach(x => {
            var tipoTelefone = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.tipo, x).valor;
            var tipoTelefoneId = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.tipo, x).id;
            var numero = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.numero, x).valor;
            var numeroId = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.numero, x).id;
            var ramal = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.ramal, x).valor;
            var ramalId = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.ramal, x).id;
            var contato = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.contato, x).valor;
            var contatoId = this.getDadoCompNg2(this.pessoaMap.telefoneDevedor.contato, x).id;
            var pessoaDadosId = x.id;
            dadosTableNG.push({
                tipoTelefone: tipoTelefone,
                tipoTelefoneId: tipoTelefoneId,
                numero: numero,
                numeroId: numeroId,
                ramal: ramal,
                ramalId: ramalId,
                contato: contato,
                contatoId: contatoId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshTelefoneDevedorPessoa() {

        this.telefonesDevedorPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.telefoneDevedor.telefoneDevedorPd);

        this.telefonesDevedorPessoaNG.forEach(x => {

            var pdcTipoTelefone = new PessoaDadosComplementares();
            pdcTipoTelefone.valor = x.tipoTelefone,
                pdcTipoTelefone.nome = this.pessoaMap.telefoneDevedor.tipo;
            pdcTipoTelefone.id = x.tipoTelefoneId,
                pdcTipoTelefone.pessoaDadosId = x.pessoaDadosId;

            var pdcNumero = new PessoaDadosComplementares();
            pdcNumero.valor = x.numero,
                pdcNumero.nome = this.pessoaMap.telefoneDevedor.numero;
            pdcNumero.id = x.numeroId,
                pdcNumero.pessoaDadosId = x.pessoaDadosId;

            var pdcRamal = new PessoaDadosComplementares();
            pdcRamal.valor = x.ramal,
                pdcRamal.nome = this.pessoaMap.telefoneDevedor.ramal;
            pdcRamal.id = x.ramalId,
                pdcRamal.pessoaDadosId = x.pessoaDadosId;

            var pdcContato = new PessoaDadosComplementares();
            pdcContato.valor = x.contato,
                pdcContato.nome = this.pessoaMap.telefoneDevedor.contato;
            pdcContato.id = x.contatoId,
                pdcContato.pessoaDadosId = x.pessoaDadosId;



            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.telefoneDevedor.telefoneDevedorPd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcTipoTelefone);
            pd.pessoaDadosComplementares.push(pdcNumero);
            pd.pessoaDadosComplementares.push(pdcRamal);
            pd.pessoaDadosComplementares.push(pdcContato);


            this.telefonesDevedorPessoa.push(pd);
        });
    }


    startTableNgEnderecos(pessoaDados: PessoaDados[], dadosTableNG: any[]) {
        pessoaDados.forEach(x => {
            var uf = this.getDadoCompNg2(this.pessoaMap.endereco.uf, x).valor;
            var ufId = this.getDadoCompNg2(this.pessoaMap.endereco.uf, x).id;
            var cidade = this.getDadoCompNg2(this.pessoaMap.endereco.cidade, x).valor;
            var cidadeId = this.getDadoCompNg2(this.pessoaMap.endereco.cidade, x).id;
            var cep = this.getDadoCompNg2(this.pessoaMap.endereco.cep, x).valor;
            var cepId = this.getDadoCompNg2(this.pessoaMap.endereco.cep, x).id;
            var logradouro = this.getDadoCompNg2(this.pessoaMap.endereco.logradouro, x).valor;
            var logradouroId = this.getDadoCompNg2(this.pessoaMap.endereco.logradouro, x).id;
            var pessoaDadosId = x.id;
            dadosTableNG.push({
                uf: uf,
                ufId: ufId,
                cidade: cidade,
                cidadeId: cidadeId,
                cep: cep,
                cepId: cepId,
                logradouro: logradouro,
                logradouroId: logradouroId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshEnderecosPessoa() {

        this.enderecosPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.endereco.enderecoPd);

        this.enderecosPessoaNG.forEach(x => {

            var pdcUf = new PessoaDadosComplementares();
            pdcUf.valor = x.uf,
                pdcUf.nome = this.pessoaMap.endereco.uf;
            pdcUf.id = x.ufId,
                pdcUf.pessoaDadosId = x.pessoaDadosId;

            var pdcCidade = new PessoaDadosComplementares();
            pdcCidade.valor = x.cidade,
                pdcCidade.nome = this.pessoaMap.endereco.cidade;
            pdcCidade.id = x.cidadeId,
                pdcCidade.pessoaDadosId = x.pessoaDadosId;

            var pdcCep = new PessoaDadosComplementares();
            pdcCep.valor = x.cep,
                pdcCep.nome = this.pessoaMap.endereco.cep;
            pdcCep.id = x.cepId,
                pdcCep.pessoaDadosId = x.pessoaDadosId;

            var pdcLogradouro = new PessoaDadosComplementares();
            pdcLogradouro.valor = x.logradouro,
                pdcLogradouro.nome = this.pessoaMap.endereco.logradouro;
            pdcLogradouro.id = x.logradouroId,
                pdcLogradouro.pessoaDadosId = x.pessoaDadosId;



            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.endereco.enderecoPd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcUf);
            pd.pessoaDadosComplementares.push(pdcCidade);
            pd.pessoaDadosComplementares.push(pdcCep);
            pd.pessoaDadosComplementares.push(pdcLogradouro);


            this.enderecosPessoa.push(pd);
        });
    }


    startTableNgEnderecosEletronicos(pessoaDados: PessoaDados[], dadosTableNG: any[]) {

        pessoaDados.forEach(x => {
            var email = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.email, x).valor;
            var emailId = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.email, x).id;
            var linkedin = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.linkedin, x).valor;
            var linkedinId = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.linkedin, x).id;
            var twitter = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.twitter, x).valor;
            var twitterId = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.twitter, x).id;
            var facebook = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.facebook, x).valor;
            var facebookId = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.facebook, x).id;
            var instagram = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.instagram, x).valor;
            var instagramId = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.instagram, x).id;
            var telegram = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.telegram, x).valor;
            var telegramId = this.getDadoCompNg2(this.pessoaMap.enderecoEletronico.telegram, x).id;
            var pessoaDadosId = x.id;
            dadosTableNG.push({
                email: email,
                emailId: emailId,
                linkedin: linkedin,
                linkedinId: linkedinId,
                twitter: twitter,
                twitterId: twitterId,
                facebook: facebook,
                facebookId: facebookId,
                instagram: instagram,
                instagramId: instagramId,
                telegram: telegram,
                telegramId: telegramId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshEnderecosEletronicosPessoa() {



        this.enderecosEletronicosPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.enderecoEletronico.enderecoEletronicoPd);

        this.enderecosEletronicosPessoaNG.forEach(x => {
            var pdcEmail = new PessoaDadosComplementares();
            pdcEmail.valor = x.email,
                pdcEmail.nome = this.pessoaMap.enderecoEletronico.email;
            pdcEmail.id = x.emailId,
                pdcEmail.pessoaDadosId = x.pessoaDadosId;

            var pdcLinkedin = new PessoaDadosComplementares();
            pdcLinkedin.valor = (x.linkedin == undefined ? '' : x.linkedin),
                pdcLinkedin.nome = this.pessoaMap.enderecoEletronico.linkedin;
            pdcLinkedin.id = x.linkedinId,
                pdcLinkedin.pessoaDadosId = x.pessoaDadosId;

            var pdcTwitter = new PessoaDadosComplementares();
            pdcTwitter.valor = (x.twitter == undefined ? '' : x.twitter),
                pdcTwitter.nome = this.pessoaMap.enderecoEletronico.twitter;
            pdcTwitter.id = x.twitterId,
                pdcTwitter.pessoaDadosId = x.pessoaDadosId;

            var pdcFacebook = new PessoaDadosComplementares();
            pdcFacebook.valor = (x.facebook == undefined ? '' : x.facebook),
                pdcFacebook.nome = this.pessoaMap.enderecoEletronico.facebook;
            pdcFacebook.id = x.facebookId,
                pdcFacebook.pessoaDadosId = x.pessoaDadosId;

            var pdcInstagram = new PessoaDadosComplementares();
            pdcInstagram.valor = (x.instagram == undefined ? '' : x.instagram),
                pdcInstagram.nome = this.pessoaMap.enderecoEletronico.instagram;
            pdcInstagram.id = x.instagramId,
                pdcInstagram.pessoaDadosId = x.pessoaDadosId;

            var pdcTelegram = new PessoaDadosComplementares();
            pdcTelegram.valor = (x.telegram == undefined ? '' : x.telegram),
                pdcTelegram.nome = this.pessoaMap.enderecoEletronico.telegram;
            pdcTelegram.id = x.telegramId,
                pdcTelegram.pessoaDadosId = x.pessoaDadosId;



            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.enderecoEletronico.enderecoEletronicoPd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcEmail);
            pd.pessoaDadosComplementares.push(pdcLinkedin);
            pd.pessoaDadosComplementares.push(pdcTwitter);
            pd.pessoaDadosComplementares.push(pdcFacebook);
            pd.pessoaDadosComplementares.push(pdcInstagram);
            pd.pessoaDadosComplementares.push(pdcTelegram);

            this.enderecosEletronicosPessoa.push(pd);
        });
    }



    startTableNgSuperiores(pessoaDados: PessoaDados[], dadosTableNG: any[]) {



        pessoaDados.forEach(x => {
            var cpfCnpj = this.getDadoCompNg2(this.pessoaMap.advogadoSuperior.cpfCnpj, x).valor;
            var cpfCnpjId = this.getDadoCompNg2(this.pessoaMap.advogadoSuperior.cpfCnpj, x).id;
            var nome = this.getDadoCompNg2(this.pessoaMap.advogadoSuperior.nome, x).valor;
            var nomeId = this.getDadoCompNg2(this.pessoaMap.advogadoSuperior.nome, x).id;
            var email = this.getDadoCompNg2(this.pessoaMap.advogadoSuperior.email, x).valor;
            var emailId = this.getDadoCompNg2(this.pessoaMap.advogadoSuperior.email, x).id;

            var pessoaDadosId = x.id;
            dadosTableNG.push({
                cpfCnpj: cpfCnpj,
                cpfCnpjId: cpfCnpjId,
                nome: nome,
                nomeId: nomeId,
                email: email,
                emailId: emailId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshSuperioresPessoa() {


        this.superioresPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.advogadoSuperior.advogadoSuperiorPd);

        this.superioresPessoaNG.forEach(x => {

            var pdcCpfCnpj = new PessoaDadosComplementares();
            pdcCpfCnpj.valor = x.cpfCnpj,
                pdcCpfCnpj.nome = this.pessoaMap.advogadoSuperior.cpfCnpj;
            pdcCpfCnpj.id = x.cpfCnpjId,
                pdcCpfCnpj.pessoaDadosId = x.pessoaDadosId;


            var pdcNome = new PessoaDadosComplementares();
            pdcNome.valor = x.nome,
                pdcNome.nome = this.pessoaMap.advogadoSuperior.nome;
            pdcNome.id = x.nomeId,
                pdcNome.pessoaDadosId = x.pessoaDadosId;

            var pdcEmail = new PessoaDadosComplementares();
            pdcEmail.valor = x.email,
                pdcEmail.nome = this.pessoaMap.advogadoSuperior.email;
            pdcEmail.id = x.emailId,
                pdcEmail.pessoaDadosId = x.pessoaDadosId;

            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.advogadoSuperior.advogadoSuperiorPd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcEmail);
            pd.pessoaDadosComplementares.push(pdcNome);
            pd.pessoaDadosComplementares.push(pdcCpfCnpj);


            this.superioresPessoa.push(pd);
        });
    }


    startTableNgComissoesCobrador(pessoaDados: PessoaDados[], dadosTableNG: any[]) {

        pessoaDados.forEach(x => {
            var percentual = this.getDadoCompNg2(this.pessoaMap.cobradorComissao.percentual, x).valor;
            var percentualId = this.getDadoCompNg2(this.pessoaMap.cobradorComissao.percentual, x).id;
            var tipoCobrador = this.getDadoCompNg2(this.pessoaMap.cobradorComissao.tipo, x).valor;
            var tipoCobradorId = this.getDadoCompNg2(this.pessoaMap.cobradorComissao.tipo, x).id;

            var pessoaDadosId = x.id;
            dadosTableNG.push({
                percentual: percentual,
                percentualId: percentualId,
                tipoCobrador: tipoCobrador,
                tipoCobradorId: tipoCobradorId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshComissoesCobradorPessoa() {

        this.comissoesCobradorPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.cobradorComissao.cobradorComissaoPd);

        this.comissoesCobradorPessoaNG.forEach(x => {

            var pdcPercentual = new PessoaDadosComplementares();
            pdcPercentual.valor = x.percentual,
                pdcPercentual.nome = this.pessoaMap.cobradorComissao.percentual;
            pdcPercentual.id = x.percentualId,
                pdcPercentual.pessoaDadosId = x.pessoaDadosId;

            var pdcTipoCobrador = new PessoaDadosComplementares();
            pdcTipoCobrador.valor = x.tipoCobrador,
                pdcTipoCobrador.nome = this.pessoaMap.cobradorComissao.tipo;
            pdcTipoCobrador.id = x.tipoCobradorId,
                pdcTipoCobrador.pessoaDadosId = x.pessoaDadosId;

            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.cobradorComissao.cobradorComissaoPd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcPercentual);
            pd.pessoaDadosComplementares.push(pdcTipoCobrador);

            this.comissoesCobradorPessoa.push(pd);
        });
    }


    startTableNgAdicionais(pessoaDados: PessoaDados[], dadosTableNG: any[]) {

        pessoaDados.forEach(x => {
            var campo = this.getDadoCompNg2(this.pessoaMap.adicional.campo, x).valor;
            var campoId = this.getDadoCompNg2(this.pessoaMap.adicional.campo, x).id;

            var conteudo = this.getDadoCompNg2(this.pessoaMap.adicional.conteudo, x).valor;
            var conteudoId = this.getDadoCompNg2(this.pessoaMap.adicional.conteudo, x).id;

            var pessoaDadosId = x.id;
            dadosTableNG.push({
                campo: campo,
                campoId: campoId,
                conteudo: conteudo,
                conteudoId: conteudoId,
                pessoaDadosId: pessoaDadosId,
            });
        });
    }

    refreshAdicionaisPessoa() {

        this.camposAdicionaisPessoa = [];
        this.pessoa2.pessoaDados = this.pessoa2.pessoaDados.filter(obj => obj.nome != this.pessoaMap.adicional.adicionalPd);

        this.camposAdicionaisPessoaNG.forEach(x => {

            var pdcCampo = new PessoaDadosComplementares();
            pdcCampo.valor = x.campo,
                pdcCampo.nome = this.pessoaMap.adicional.campo;
            pdcCampo.id = x.campoId,
                pdcCampo.pessoaDadosId = x.pessoaDadosId;

            var pdcConteudo = new PessoaDadosComplementares();
            pdcConteudo.valor = x.conteudo,
                pdcConteudo.nome = this.pessoaMap.adicional.conteudo;
            pdcConteudo.id = x.conteudoId,
                pdcConteudo.pessoaDadosId = x.pessoaDadosId;


            var pd = new PessoaDados();
            pd.nome = this.pessoaMap.adicional.adicionalPd,
                pd.valor = "";
            pd.pessoaId = this.pessoa2.id,
                pd.id = x.pessoaDadosId;

            pd.pessoaDadosComplementares.push(pdcCampo);
            pd.pessoaDadosComplementares.push(pdcConteudo);

            this.camposAdicionaisPessoa.push(pd);
        });
    }


    startLists() {
        this.bancosPessoa = this.getListValue(this.pessoaMap.banco.bancoPd, this.pessoa2);
        this.telefonesPessoa = this.getListValue(this.pessoaMap.telefone.telefonePd, this.pessoa2);
        this.telefonesDevedorPessoa = this.getListValue(this.pessoaMap.telefoneDevedor.telefoneDevedorPd, this.pessoa2);
        this.camposAdicionaisPessoa = this.getListValue(this.pessoaMap.adicional.adicionalPd, this.pessoa2);
        this.enderecosEletronicosPessoa = this.getListValue(this.pessoaMap.enderecoEletronico.enderecoEletronicoPd, this.pessoa2);
        this.superioresPessoa = this.getListValue(this.pessoaMap.advogadoSuperior.advogadoSuperiorPd, this.pessoa2);
        this.comissoesCobradorPessoa = this.getListValue(this.pessoaMap.cobradorComissao.cobradorComissaoPd, this.pessoa2);
        this.enderecosPessoa = this.getListValue(this.pessoaMap.endereco.enderecoPd, this.pessoa2);


        this.startTableNgBanco(this.bancosPessoa, this.bancosPessoaNG);
        this.startTableNgTelefone(this.telefonesPessoa, this.telefonesPessoaNG);
        this.startTableNgTelefoneDevedor(this.telefonesDevedorPessoa, this.telefonesDevedorPessoaNG);
        this.startTableNgEnderecos(this.enderecosPessoa, this.enderecosPessoaNG);
        this.startTableNgEnderecosEletronicos(this.enderecosEletronicosPessoa, this.enderecosEletronicosPessoaNG);

        this.startTableNgSuperiores(this.superioresPessoa, this.superioresPessoaNG);
        this.startTableNgComissoesCobrador(this.comissoesCobradorPessoa, this.comissoesCobradorPessoaNG);
        this.startTableNgAdicionais(this.camposAdicionaisPessoa, this.camposAdicionaisPessoaNG);
    }



    addLists() {
        this.refreshBancoPessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.bancosPessoa);

        this.refreshTelefonePessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.telefonesPessoa);

        this.refreshTelefoneDevedorPessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.telefonesDevedorPessoa);

        this.refreshEnderecosPessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.enderecosPessoa);

        this.refreshEnderecosEletronicosPessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.enderecosEletronicosPessoa);

        this.refreshSuperioresPessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.superioresPessoa);

        this.refreshComissoesCobradorPessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.comissoesCobradorPessoa);

        this.refreshAdicionaisPessoa();
        this.pessoa2.pessoaDados.push.apply(this.pessoa2.pessoaDados, this.camposAdicionaisPessoa);

    }





    //auto complete grupo
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => term.length < 1 ? []
                : this.dadosUnico.filter(v => v.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1))
        )

    onDownloadFile(event) {
        var fileId = event.data.id;

        this.pessoaService.downloadArquivo(fileId).subscribe(
            data => {
                this.utilsService.renderDownload(data, event.data.nome as string);
            }
        );
    }

    avisoPessoDeletada() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            text: 'Essa pessoa foi deletada!',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            confirmButtonText: 'Ok',
        }).then((result) => {
            this.close();
        });
    }

    async checkPessoaIfExists(pessoaId) {
        this.pessoaService.checkIfExists(pessoaId).then(x => {
            if (x["data"].length == 0) {
                this.avisoPessoDeletada();
            }
        });
    }

    formatterValue(value) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(value);
    }

    format(event) {
        event.target.value = this.formatterValue(event.target.value);
    }

    public valorSalario: string;

    calcularSalario(){
        const quantidade:number = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,this.pessoaMap.credorInfoCad.initialFeeSalariosPrivado,this.pessoa2).valor;

        this.valorSalario = this.formatterValue(quantidade * this.valorSalarioCorrente);
    }

    public valorSalarioPublico: string;

    calcularSalarioPublico(){
        const quantidade:number = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd,this.pessoaMap.credorInfoCad.initialFeeSalariosPublico,this.pessoa2).valor;

        this.valorSalarioPublico = this.formatterValue(quantidade * this.valorSalarioCorrente);
    }

}

class CustomFileLike extends FileLikeObject {
    public documentType: string;
}
