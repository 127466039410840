import { ProcessosService } from './../../../../services/processos.service';
import { NotificacaoService } from './../../../../services/notificacao.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';
import { UtilsService } from 'src/app/utils/utils.service';
import { ModeloService } from 'src/app/services/modelo.service';
import { Pessoa, PessoaDados } from 'src/app/models/pessoa/pessoa';
import { BancoChaves, PessoaMapeamentoChaves } from 'src/app/models/pessoa/pessoa-mapeamento-chaves';
import { PessoaService } from 'src/app/services/pessoa.service';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { BidiModule } from '@angular/cdk/bidi';
import { bindCallback } from 'rxjs';

@Component({
    selector: 'app-modal-repasse',
    templateUrl: './modal-repasse.component.html',
    styleUrls: ['./modal-repasse.component.css']
})
export class ModalRepasseComponent implements OnInit {

    modalRef: NgbModalRef;
    pmc:PessoaMapeamentoChaves;
    bancosCredor:any = [];
    processo: ProcessoOperacao;
    credor: Pessoa;
    processoId: any;
    pessoaDadosId: any;
    idExterno: any = "";
    cobradorId: any; 
    bancosIds:any = [] 

    public loadingModeloRepasse: Boolean = false;
    public loadingProcesso:Boolean = true;    

    constructor(
        public modeloService: ModeloService,
        public toastrService: ToastrService,
        public processoService: ProcessosService,
        public pessoaService: PessoaService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.processoId = this.modalRef.componentInstance.processoId;    
        this.pessoaDadosId = this.modalRef.componentInstance.pessoaDadosId;      
        
        this.pessoaService.getPessoaMapeamentoChaves().subscribe(x => {
            this.loadingProcesso = true;
            this.pmc = x.data;                       
        })

        this.processoService.getById(this.processoId).subscribe(x => {
            this.idExterno = x.data.idExterno.toString();
            this.cobradorId = x.data.cobradorId;
            this.processo = x.data;                        
                           
            this.pessoaService.getById(this.processo.credorId).subscribe(cre => {
              this.credor = cre.data;                           
              this.getBancosCredor(this.credor);                                                     
            },erro=>{
              var teste;
            });
                 
            this.loadingProcesso = false;
  
          });

    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }



    Modelo() {

        this.loadingModeloRepasse = true;

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });
    }
    

    downloadTemplateModeloRepasse(idBanco) {     

        this.modeloService.downloadTemplateModeloRepasse(this.processoId, idBanco).subscribe(
            data => {
                this.utilsService.renderDownload(data, "Modelo repasse.docx" as string);
            }
        );
    }

    getBancosCredor(credor:Pessoa){
  
      var cre = new Pessoa();
      var pdc = cre.GetPessoaDados(credor,this.pmc.banco.bancoPd);          

      if(pdc != null)
      {
          pdc.forEach(banco => {
                           
            var objContato = {                                                        
                nome:cre.GetPessoaDadosComplementar(banco,this.pmc.banco.nomeBanco).valor,
                agencia:cre.GetPessoaDadosComplementar(banco,this.pmc.banco.agencia).valor,
                contaCorrente:cre.GetPessoaDadosComplementar(banco,this.pmc.banco.contaCorrente).valor,
                titular:cre.GetPessoaDadosComplementar(banco,this.pmc.banco.contato).valor,
                id:banco.id

            };  
                 
            if(objContato.titular == "")
            {
                objContato.titular = "Conta sem titular cadastrado";
            }
            this.bancosCredor.push(objContato); 
           
            
          });
      }
  }

 
}




