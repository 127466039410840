import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Guid } from "guid-typescript";

@Injectable()
export class ProcessosJudiciaisOcorrenciasService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'processoJudicialOcorrencia/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    buscarProcessosJudiciais(filtro): Observable<any> {
        return this.http.get(environment.api + this.endpoint , filtro)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data): Observable<any> {
      return this.http.post(environment.api + this.endpoint, data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
    
    getAll(): Observable<any> {
      return this.http.get(environment.api + this.endpoint)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    update(data): Observable<any> {
      return this.http.put(environment.api + this.endpoint , data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
    
    delete(id): Observable<any> {
      return this.http.delete(environment.api + this.endpoint + id)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterUltimasOcorrencias(processoId, quantidade): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-ultimas-ocorrencias/${processoId}/${quantidade}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterOcorrencias(processoJudicialRecursoDesdobramentoId): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-ocorrencias-com-detalhes/${processoJudicialRecursoDesdobramentoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterOcorrenciaComAtividade(processoJudicialOcorrenciaId, tipoOcorrencia, atividadeId): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-ocorrencia-com-atividade/${processoJudicialOcorrenciaId}/${tipoOcorrencia}/${atividadeId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    ObterContatosClientePrincipalPorOcorrenciaId(processoJudicialRecursoDesdobramentoId: Guid): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-contatos-cliente-principal-por-recurso-desdobramento/${processoJudicialRecursoDesdobramentoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    obterCodigoDesdobramentoIdPorAtividateId(processoId, atividadeId): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-recurso-desdobramento-por-atividade/${processoId}/${atividadeId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    atualizarDataAtividade(atividade): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'atualizar-data-ocorrencia-atividade', atividade)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    adicionarEmMassa(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'adicionar-em-massa', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
      }
    
}
