<ng-select 
    [items]="ufs" 
    [loading]="loadUfs"
    [multiple]="selecionaMultiplos"
    [(ngModel)]="ufId" 
    [ngModelOptions]="{standalone: true}"
    bindLabel="displayName"
    bindValue="id"
    placeholder="Digite a UF" 
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    clearAllText="Limpar seleção"
    (keyup)="filtrar($event)"
    (click)="changeUf($event)"
    (clear)="limparUf()"
    >
</ng-select>
