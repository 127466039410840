<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">Operacionais / Publicações /</span> Importar</span>    
</h4>
<div class="container">
    <div class="row card" *ngIf="loadAtach">
        <button type="button" (click)="sincronizarPublicacoes()" class="btn btn-primary">Sincronizar publicações via integração</button>
        <div class="text-center mt-3">
            <h3> OU </h3>
        </div>
        <div class="col-12">
            <div class="card-body" >
                <div *ngIf="uploader.queue.length == 0" ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                    (fileOver)="fileOverBase($event)" [uploader]="uploader" class="dropzone"
                    (click)="fileInput.click()">
                    <span class=" span-icon fas fa-cloud-download-alt"></span>
                    <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
                        carregar</span>
                    <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none"
                        (onFileSelected)="onDrop($event)" />
                </div>

                <table class="table" *ngIf="uploader.queue.length > 0">
                    <thead>
                        <tr>
                            <th width="45%">Nome</th>                        
                            <th>Tamanho</th>                        
                            <th>Status</th>
                            <th>Acões</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploader.queue">
                            <td><strong>{{ item?.file?.name }}</strong></td>                        
                            <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 |
                                number:'.2' }} MB
                            </td>                        
                            <td class="text-center">
                                <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                                <span *ngIf="item.isCancel"><i
                                        class="glyphicon glyphicon-ban-circle"></i></span>
                                <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                            </td>
                            <td nowrap>                            
                                <button type="button" class="btn btn-danger" (click)="handleRemoveFile(item)">
                                    <span class="glyphicon glyphicon-trash"></span> Remover
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-end" *ngIf="loadAtach">
                <button type="button" (click)="cancel()" class="btn btn-light mr-3">Cancelar</button>
                <button type="button" (click)="onSubmit()" class="btn btn-primary" [attr.disabled]="uploader.queue.length == 0 ? true : null">Importar</button>
            </div>
        </div>
        <br />
    </div>
    <div class="row" *ngIf="!loadAtach">
        <div class="col-12 botoes-cabecalho-importacao">
            <div class="d-flex justify-content-end">
                <button type="button" (click)="cancel()" class="btn btn-info"><i class="fas fa-arrow-circle-left"></i> Voltar</button>
                &nbsp;
                <button type="button" (click)="limpar()" class="btn btn-primary mr-3"><i class="fas fa-sync-alt"></i> Importar Novamente</button>
            </div>
        </div>        
        <div class="col-12 card">            
            <!-- Table -->
            <hr class="border-light m-0">
            <div class="table-responsive">
                <table class="table table-striped table-bordered card-table">
                    <thead>
                        <tr>
                            <th class="text-nowrap text-center cursor-pointer" (click)="setSort('numeroProcesso')">
                                Processo<i class="ion text-muted ml-2" *ngIf="sortBy=='numeroProcesso'"
                                    [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                            </th>
                            <th class="text-nowrap text-center cursor-pointer" (click)="setSort('nomePesquisado')">
                                Nome Pesquisado<i class="ion text-muted ml-2" *ngIf="sortBy=='nomePesquisado'"
                                    [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                            </th>
                            <th class="text-nowrap text-center cursor-pointer" (click)="setSort('datadivulgacao')">
                                Data Divulgação<i class="ion text-muted ml-2" *ngIf="sortBy=='datadivulgacao'"
                                    [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                            </th>
                            <th class="text-nowrap text-center cursor-pointer" (click)="setSort('datapublicação')">
                                Data Publicação<i class="ion text-muted ml-2" *ngIf="sortBy=='datapublicação'"
                                    [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                            </th>
                            <th class="text-nowrap text-center cursor-pointer" (click)="setSort('descricaoImportacao')">
                                Retorno<i class="ion text-muted ml-2" *ngIf="sortBy=='descricaoImportacao'"
                                    [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tabela" *ngFor="let publicacao of publicacoes">
                            <td class="align-middle link">
                                {{publicacao.numeroProcesso}}                                
                            </td>
                            <td class="align-middle link">
                                {{publicacao.nomePesquisado}}
                            </td>
                            <td class="align-middle link">
                                {{publicacao.dataDivulgacao | date:'dd/MM/yyyy'}}
                            </td>
                            <td class="align-middle link">
                                {{ publicacao.dataPublicacao | date:'dd/MM/yyyy'}}
                            </td>
                            <td class="text-center text-nowrap align-middle link">
                                <div class="mt-2 mr-1" [ngClass]="defineClass(publicacao.cor)">{{publicacao.descricaoImportacao}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- / Table -->
        
            <!-- Pagination -->
            <div class="card-body py-0">
        
                <div class="row">
                    <div class="col-sm text-sm-left text-center pt-3">
                        <span class="text-muted" *ngIf="totalItems">Páginas {{ currentPage }} de {{ totalPages }}</span>
                    </div>
                    <div class="col-sm pt-3">
                        <ngb-pagination [maxSize]="15" [collectionSize]="totalItems" [pageSize]="perPage" [(page)]="currentPage"
                            (pageChange)="filter()" size="sm" class="d-flex justify-content-center justify-content-sm-end m-0">
                        </ngb-pagination>
                    </div>
                </div>
        
            </div>
            <!-- / Pagination -->
        </div>
    </div>
</div>