import { CurrencyPipe, DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { debug } from "console";
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class ExportNotaDebitosService {
    constructor(
        private spinner: NgxSpinnerService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
    ) { }

    handleDespesas(despesas) {
        const list = [];
        despesas.forEach((item) => {
          list.push([
            { text: this.datePipe.transform(new Date(item.data), "dd/MM/yyyy")},
            { text: this.currencyPipe.transform(item.valor, "BRL")},
            { text: item.centroCustoNome },
            { text: item.descricao },
          ]);
        });     
        return list;           
      }

    public exportPdf(nota: any) {
        console.log(nota);        

        let docDefinition: TDocumentDefinitions = {
            pageSize: "A4",
            pageMargins: 20,
            defaultStyle: {
                fontSize: 8,
            },
            content: [
                {
                    margin: [0, 0, 20, 0],
                    columns: [
                        [
                            {
                                text: "Nota de débito",
                                fontSize: 13,
                                bold: true,
                            },
                        ],
                        [                            
                            {
                                text: `Cliente: ${nota.nomeCliente}`,
                                style: "infoHeader",
                            },
                            {
                                text: `${nota.cpfCnpj}`,
                                style: "infoHeader",
                            },                            
                        ],
                    ],
                },
                {
                    columns: [
                        {
                            text: "",
                            margin: [0, 0, 0, 15],
                        },
                    ],
                },
                {
                    layout: "lightHorizontalLines",
                    table: {
                        widths: [80, 450],
                        body: [
                            ["", ""],
                            [
                                { text: "Número Processo:", bold: true },
                                { text: nota.numeroProcesso },                                                                
                            ],
                            [
                                { text: "Número Pasta:", bold: true },
                                { text: nota.pastaProcesso },
                            ]
                        ],
                    },
                },
                {
                    columns: [
                        {
                            text: "",
                            margin: [0, 0, 0, 15],
                        },
                    ],
                },
                {
                    layout: "lightHorizontalLines",
                    table: {
                      widths: [80, 50, 100, 280],
                      body: [
                        [
                          { text: "Data", bold: true },                          
                          { text: "Valor", bold: true },
                          { text: "Centro de custo", bold: true },
                          { text: "Descrição", bold: true },
                        ],
                        ...this.handleDespesas(nota.despesas)
                      ],
                    },
                  },
                {
                    columns: [
                        {
                            text: "",
                            margin: [0, 0, 0, 15],
                        },
                    ],
                },
            ],
            styles: {
              infoHeader: {
                alignment: "right",
                italics: true,
                bold: true,
              },
            },
        };

        pdfMake.createPdf(docDefinition).download('nota-de-debito.pdf');                
    }
}