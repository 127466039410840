<div class="modal-header">
    <h4>Honorários</h4>
</div>
<div class="modal-body">
    <form [formGroup]="honorarioForm">
        <div class="row">
            <div class="col-12">
                Forma de cálculo dos honorários:
            </div>
        </div>
        <div class="row mt-2">
            <div class="form-check col-12">
                <input id="type-form-n" type="radio" value="1" formControlName="tipoCalculoHonorario">
                <label for="type-form-n">Abaixo vou digitar um percentual para ser aplicado no final do cálculo </label>
            </div>
            <div class="form-check col-12">
                <input id="type-form-s" type="radio" value="2" formControlName="tipoCalculoHonorario">
                <label for="type-form-s"> Abaixo vou digitar um valor em R$ que será somado no fim do cálculo </label>
            </div>
        </div>
        <div class="rol">
            <div class="col-4">
                <div class="form-group">
                    <label class="font-weight-bold">Valor:</label>
                    <input type="number" class="form-control" formControlName="valor">
                </div>
            </div>
        </div>
        <hr />
        <div class="d-flex justify-content-end">
            <div class="col-md-2">
                <button type="button" class="btn background-color-white" (click)="dismiss()">Cancelar</button>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-padrao btn-block" (click)="close()">OK</button>
            </div>
        </div>
    </form>
</div>