<div class="card">
    <div class="row row-reverse">
                            
        <div class="col-2 d-flex justify-content-between align-items-center font-weight-bold pt-3 to-left">
           
            <a  class="btn btn-primary" target="_blank" 
            routerLink="dashboard/despesas/cadastrar/4/{{desdobramentoId}}/{{numeroProcesso}}/true">
                <i class="fas fa-plus">Inserir</i>           
                     
            </a>
           
        </div>   
        <div class="col-2 d-flex justify-content-between align-items-center font-weight-bold pt-3 to-left">
            <button class="btn btn-primary" (click)="refreshData()">
                <i class="fas fa-sync">Atualizar</i>
            </button>
        </div>
    </div>    
    <div>
        <div class="table-responsive" *ngIf="despesas.length > 0">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('NomeDespesa')">
                            Nome<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='NomeDespesa'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Valor')">
                            Valor<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Valor'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('CentroCusto')" >
                            Tipo Despesa<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='CentroCusto'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Pasta')">
                            Pasta<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Pasta'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('NumeroProcesso')">
                            Processo<i class="ion text-muted ml-2"
                                *ngIf="colunaOrdenacao=='NumeroProcesso'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('NumeroRecurso')">
                            Recurso/Desdobramento<i class="ion text-muted ml-2"
                                *ngIf="colunaOrdenacao=='NumeroRecurso'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('DataInsercao')">
                            Data Ins.<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='DataInsercao'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-center text-nowrap align-middle">
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tabela" *ngFor="let despesa of despesas">
                        <td>
                            {{despesa.nomeDespesa}}
                        </td>
                        <td>
                            {{despesa.valor | currency:'BRL'}}
                        </td>
                        <td >
                            {{despesa.centroCusto}}
                        </td>
                        <td>
                            {{despesa.pasta}}
                        </td>
                        <td>
                            {{despesa.numeroProcesso}}
                            <a  class="fas fa-external-link-square-alt color-info p-0 ml-2" (click)="saiuTela.emit()" aria-hidden="true" href="/dashboard/processos-judiciais/detalhes/{{despesa.processoJudicialId}}" target="_blank"></a>
                        </td>
                        <td>
                            {{despesa.numeroRecurso}} 
                        </td>
                        
                        <td>
                            {{despesa.dataInsercao | date: 'dd/MM/yyyy - HH:mm'}}
                        </td>
                        <td class="text-center text-nowrap align-middle link">
                            <button type="button" class="btn btn-xs icon-btn btn-primary"
                                (click)="handleDownloadArquivo(despesa.despesaId)">
                                <span class="fas fa-download"></span>
                            </button>
                            <button type="button" class="btn btn-xs btn-primary btn-sm"
                                (click)="editarDespesa(despesa)">
                                Editar
                            </button>
                            
                            <button type="button" class="btn btn-xs btn-danger btn-sm" 
                                (click)="deletarDespesa(despesa)">
                                <i class="fas fa-trash"></i>
                            </button>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-body py-0">
                <div class="row">
                    <div class="col-sm text-sm-left text-center pt-3">
                        <span class="text-muted" *ngIf="totalItens">Páginas {{ paginaAtual }} de {{ totalPaginas
                            }}</span>
                    </div>
                    <div class="col-sm text-sm-left text-center pt-3">
                        <label>Itens por página:</label>
                        <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                            (change)="alterarPagina(1)">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                            <option [ngValue]="500">500</option>
                        </select>
                    </div>
                    <div class="col-sm pt-6">
                        <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                            [(page)]="paginaAtual" [boundaryLinks]="true" (pageChange)="alterarPagina(paginaAtual)"
                            size="sm" class="d-flex justify-content-center justify-content-sm-end m-0">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-5" *ngIf="despesas.length == 0">
            <h3>Não possui dados.</h3>
        </div>
    </div>
</div>