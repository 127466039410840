<div>
    <!-- Modal template -->
    <div class="modal-header">
        <h5 class="modal-title">
            Concluir atividade
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
  
        <div class="form-row" *ngIf="item.tipoOcorrencia == 1 || item.tipoOcorrencia == 4">  
            <div class="form-group col-12">
                <input type="checkbox" id="publicacao" name="publicacao" [checked]="gerarPublicacao" (change)="gerarPublicacao = !gerarPublicacao" />
                <!-- <input type="checkbox" class="form-control"  [(ngModel)]="gerarPublicacao" (change)="gerarPublicacao = !gerarPublicacao" > -->
                <label for="publicacao" class="form-label">Deseja gerar uma publicação após a conclusão da atividade?</label>
            </div>
        </div>
      <div class="form-row">  
        <div class="form-group col-12">
            <label class="form-label">Descrição</label>
            <textarea [(ngModel)]="descricao" class="form-control" rows="3" maxlength="500"></textarea>
        </div>
      </div>        
      <div class="form-row">
        <div class="card-body">
            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                [uploader]="uploader" class="dropzone" (click)="fileInput.click()">
                <span class=" span-icon fas fa-cloud-download-alt"></span>
                <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
                    carregar</span>
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" multiple
                    (onFileSelected)="onDrop($event)" />
            </div>

            <table class="table" *ngIf="uploader.queue.length > 0">
                <thead>
                    <tr>
                        <th width="45%">Nome</th>
                        <th>Tamanho</th>
                        <th>Status</th>
                        <th>Acões</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>{{ item?.file?.name }}</strong></td>
                        <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 |
                            number:'.2' }} MB
                        </td>
                        <td class="text-center">
                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                        </td>
                        <td nowrap>
                            <button type="button" class="btn btn-danger" (click)="handleRemoveFile(item)">
                                <span class="glyphicon glyphicon-trash"></span> Remover
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="salvar()"><i class="fas fa-save"></i> Salvar</button>
    </div>
  </div>
  