import { Component, OnInit } from '@angular/core';
import { guid } from '@fullcalendar/angular';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { TipoAcordo, TipoAcordoLabel } from 'src/app/enums/Acordo/tipo-acordo.enum';
import { AcordoJuridico } from 'src/app/models/operacionais/acordo/acordoJuridico';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { Pessoa, PessoaDados, PessoaDadosComplementares } from 'src/app/models/pessoa/pessoa';
import { PessoaMapeamentoChaves } from 'src/app/models/pessoa/pessoa-mapeamento-chaves';
import { SelectList } from 'src/app/models/select-list';
import { Parametro } from 'src/app/models/shared/parametro';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { AtualizacaoValoresService } from 'src/app/services/atualizacao-valores.service';
import { IndiceService } from 'src/app/services/indice.service';
import { ParametroService } from 'src/app/services/parametro.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ProcessosJudiciaisValorService } from 'src/app/services/processos-judiciais-valor.service';
import { SelectListService } from 'src/app/services/shared/select-list.service';
import { TipoAcordoJuridicoService } from 'src/app/services/tipo-acordo-juridico.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-modal-cadastrar-acordo-judicial',
    templateUrl: './modal-cadastrar-acordo-judicial.component.html',
    styleUrls: ['./modal-cadastrar-acordo-judicial.component.scss']
})
export class ModalCadastrarAcordoJudicialComponent implements OnInit {

    private modalRef: NgbModalRef;

    private processoJudicialId: Guid;

    private tipoAcordo: number;

    private recursoDesdobramentoId: Guid;
    private pessoaId: Guid;
    public pessoa: any;
    public maxParcelamento: number;
    public minParcelamento: number;

    private taxasAcordo: any = {};

    public salvarBtnDisable = true;

    public valorDivida: number = 0.0;

    public valorAtualizacaoValores: number = 0.0;

    public valorCausa: number = 0.0;
    public valorConfessado: number = 0.0;

    public acordos: AcordoJuridico[] = new Array<AcordoJuridico>(2);

    public isAdd: boolean = true;

    public loading: boolean = true;

    public referenciaId: Guid;

    pt_calendar: any = {};

    public indices: any;

    private acordoId: Guid;
    private honorarioId: Guid;

    public moeadas: {id: number, nome: string}[] = [
        {"id": 0, "nome": "R$ - Real"},
        {"id": 1, "nome": "$ - Dolar"},
        {"id": 2, "nome": "€ - Euro"}
    ]

    constructor(
        private processosJudiciaisValorService: ProcessosJudiciaisValorService,
        private atualizacaoValoresService: AtualizacaoValoresService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private pessoaService: PessoaService,
        private selectListService: SelectListService,
        private translateService: TranslateService,
        private primeNgConfig: PrimeNGConfig,
        private parametroService: ParametroService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public acordoService: AcordoJuridicoService,
        public indiceService: IndiceService,
        public tipoAcordoJuridicoService: TipoAcordoJuridicoService
    ) { }

    camposSimulacao = {
        tipoAcordo: '',
        moeda: 0,
        acordoVinculado: '',
        percentualJuros: 0,
        percentualTaxaAdministrativa: 0,
        percentualIndiceCorrecao: 0,
        jurosProtesto: 0,
        percentualMulta: 0,
        simples: true,
        composto: false,
        dataInicialParcelas: new Date(),
        dataCalculos: new Date(),
        numParcelas: 1,
        intervaloDias: 1,
        diaFixoOption: "Não",
        diaFixoValor: 1,
        acrescimoOuDesconto: "Desconto",
        tipo: '' as String,
        tipoNome: '',
        porcentagemOuValorOption: "%",
        porcentagemOuValor: 0,
        valorAvulso: null,
        indice: null,
        percentualHonorarioAdvocaticioDescumprimento: 0,
        valorConfessado: null
    }

    camposSimulacaoHonorario = {
        tipoAcordo: '2',//Honorario
        acordoVinculado: '3', //Valor Honorario
        moeda: 0,
        percentualJuros: 0,
        percentualTaxaAdministrativa: 0,
        percentualIndiceCorrecao: 0,
        jurosProtesto: 0,
        percentualMulta: 0,
        simples: true,
        composto: false,
        dataInicialParcelas: new Date(),
        dataCalculos: new Date(),
        numParcelas: 1,
        intervaloDias: 1,
        diaFixoOption: "Não",
        diaFixoValor: 1,
        acrescimoOuDesconto: "Desconto",
        tipo: '' as String,
        tipoNome: '',
        porcentagemOuValorOption: "%",
        porcentagemOuValor: 0,
        valorAvulso: null,
        indice: null
    }

    listTipoAcordo: any = [];
    pessoaMap: PessoaMapeamentoChaves;
    parametroL: Parametro[];

    ngOnInit(): void {

        this.buscarValores();

        this.carregarIndices();

        this.carregarTiposAcordoJuridico();

        this.translateService.setDefaultLang('pt-br');

        this.primeNgConfig.setTranslation({
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        });

        this.pt_calendar = {
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        };

        this.parametroService.getAll().subscribe(res => {
            this.parametroL = res.data;
        });

        if (!this.isAdd) {
            this.buscarDadosPorReferencia();
        } else {
            this.loading = false;
        }
    }

    buscarDadosPorReferencia() {
        this.loading = true;
        this.spinner.show();

        this.acordoService.obterAcordosPorReferencia(this.referenciaId).subscribe(res => {

            let acordo: AcordoJuridico = null;

            if (res.data.filter(r => r.tipoAcordoJudicial != 'Honorário').length) {
                acordo = res.data.filter(r => r.tipoAcordoJudicial != 'Honorário')[0] as AcordoJuridico;

                this.acordoId = acordo.id;
            }

            let honorario: AcordoJuridico = null;

            if (res.data.filter(r => r.tipoAcordoJudicial == 'Honorário').length) {
                honorario = res.data.filter(r => r.tipoAcordoJudicial == 'Honorário')[0] as AcordoJuridico;

                this.honorarioId = honorario.id;
            }

            if (acordo != null)
                this.alimentarAcordo(acordo);

            if (honorario != null)
                this.alimentarHonorario(honorario);

            this.loading = false;
            this.spinner.hide();
        })
    }
    alimentarHonorario(honorario: AcordoJuridico) {
        this.camposSimulacaoHonorario.valorAvulso = honorario.valorAberto;
        this.camposSimulacaoHonorario.dataInicialParcelas = new Date(honorario.inicio);
        this.camposSimulacaoHonorario.numParcelas = honorario.parcelas;
        this.camposSimulacaoHonorario.intervaloDias = honorario.dia;
        this.camposSimulacaoHonorario.diaFixoOption = honorario.diaFixo ? "Sim" : "Não";
        this.camposSimulacaoHonorario.moeda = honorario.moeda;
    }
    alimentarAcordo(acordo: AcordoJuridico) {
        this.camposSimulacao.tipoAcordo = this.obterTipoAcordoPorTexto(acordo.tipoAcordoJudicial);
        this.camposSimulacao.valorConfessado = acordo.valorConfesado;
        this.camposSimulacao.acordoVinculado = acordo.tipoAcordoVinculadoJudicial?.toString();
        this.camposSimulacao.numParcelas = acordo.parcelas;
        this.camposSimulacao.intervaloDias = acordo.dia;
        this.camposSimulacao.diaFixoOption = acordo.diaFixo ? "Sim" : "Não";
        this.camposSimulacao.dataInicialParcelas = new Date(acordo.inicio);
        this.camposSimulacao.tipo = acordo.tipo;
        this.camposSimulacao.indice = acordo.indiceId;        
        this.camposSimulacao.percentualHonorarioAdvocaticioDescumprimento = acordo.percentualHonorarioAdvocaticioDescumprimento;
        this.camposSimulacao.percentualJuros = acordo.percentualJuros;
        this.camposSimulacao.percentualMulta = acordo.percentualMulta;
        this.camposSimulacao.moeda = acordo.moeda;

        if (this.camposSimulacao.tipoAcordo == '1') {
            this.camposSimulacao.valorAvulso = acordo.valorTotal;
        }

    }

    obterTipoAcordoPorTexto(tipo: String) {
        switch (tipo) {
            case 'Acordo vinculado':
                return '0';
            case 'Acordo avulso':
                return '1'
            case 'Honorário':
                return '2';
        }
    }

    carregarTiposAcordoJuridico() {
        this.tipoAcordoJuridicoService.getAll().subscribe(res => {
            this.listTipoAcordo = res.data;
        })
    }

    carregarIndices() {
        this.indiceService.getAll()
            .subscribe(res => {
                this.indices = res.data;
            });
    }

    dismiss() {
        this.modalRef.dismiss();
    }
    InserirValoresDefault(pessoa) {
        if (this.isAdd) {
            this.camposSimulacao.percentualJuros = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualJuros, pessoa).valor;
            this.camposSimulacao.percentualHonorarioAdvocaticioDescumprimento = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualJuros, pessoa).valor;
            //this.taxasAcordo.juros;
            const percentualMulta = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualMulta, pessoa).valor;
            
            this.camposSimulacao.percentualMulta = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualMulta, pessoa).valor
            this.camposSimulacaoHonorario.percentualJuros = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualJuros, pessoa).valor;
            //this.taxasAcordo.juros;
            this.camposSimulacaoHonorario.percentualMulta = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualMulta, pessoa).valor
            //this.taxasAcordo.multa;
            this.maxParcelamento = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.parcelamentoMaximo, pessoa).valor;
            this.minParcelamento = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.parcelamentoMinimo, pessoa).valor;
            this.camposSimulacao.percentualIndiceCorrecao = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualIndiceCorrecao, pessoa).valor;
            this.camposSimulacaoHonorario.percentualIndiceCorrecao = +this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPdJ, this.pessoaMap.credorInfoCad.percentualIndiceCorrecao, pessoa).valor;
            this.camposSimulacao.numParcelas = this.minParcelamento != 0 ? this.minParcelamento : this.camposSimulacao.numParcelas;
            this.camposSimulacaoHonorario.numParcelas = this.minParcelamento != 0 ? this.minParcelamento : this.camposSimulacaoHonorario.numParcelas;

            this.camposSimulacao.percentualTaxaAdministrativa = +this.taxasAcordo.taxaAdministrativa;
        }
    }

    salvar(aprovado) {
        this.acordos.forEach(c => c.aprovado = aprovado);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });


        var formatoValor = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

        var mensagemErro = "";

        this.parcelas.forEach(parcela => {
            if (parcela.valorTotal2) {
                if (parcela.valorTotal2 < 0) {
                    mensagemErro = "Os valores não podem ser menor que zero, por favor corrija os valores das parcelas!<br>";
                }
            }
        });


        if (mensagemErro != "") {
            this.toastrService.error('Erro ao gerar acordo!', 'Atenção', { timeOut: 10000 });
            return;
        }

        this.parcelasHonorario.forEach(parcela => {
            if (parcela.valorTotal2) {
                if (parcela.valorTotal2 < 0) {
                    mensagemErro = "Os valores não podem ser menor que zero, por favor corrija os valores das parcelas!<br>";
                }
            }
        });

        if (mensagemErro != "") {
            this.toastrService.error('Erro ao gerar acordo!', 'Atenção', { timeOut: 10000 });
            return;
        }

        let strAdvocaticios = "";

        if (this.tipoAcordo == 0 || this.tipoAcordo == 1) {
            strAdvocaticios = "<br /><h3>Honorários Advocatícios</h3>" +
                "<b>Total de Parcelas: </b>  " + this.parcelas.length +
                "<br/><b>Valor aberto: </b>" + Number(this.acordos[0].totalEmAberto).toLocaleString('pt-br', formatoValor);
        }

        let strHonorarios = "";

        if (this.tipoAcordo == 0 || this.tipoAcordo == 2) {
            strHonorarios = "<br /><br/><h3>Minuta de Honorário</h3>" +
                "<b>Total de Parcelas: </b>  " + this.parcelasHonorario.length
                + "<br/><b>Valor aberto: </b>" + Number(this.acordos[1].totalEmAberto).toLocaleString('pt-br', formatoValor);
        }

        swalWithBootstrapButtons.fire({
            title: 'Confimar Parcelas ?',
            html: strAdvocaticios + strHonorarios,
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            target: document.getElementById('modal')

        }).then((result) => {

            if (result.value === true) {
                this.salvarAcordo();
            }
        });
    }

    salvarAcordo() {
        this.spinner.show();
        this.atualizarValoresAcordo();

        //Vai vincular os 2 objetos com uma guid unica entre os 2
        //Se deletar um tem que deletar o outro
        const referenciaId: any = Guid.create().toString();

        let acordoTemp = [];

        if (this.tipoAcordo == 0 || this.tipoAcordo == 1) {
            acordoTemp.push(this.acordos[0]);
        }

        if (this.tipoAcordo == 0 || this.tipoAcordo == 2) {
            acordoTemp.push(this.acordos[1]);
        }

        this.acordos = acordoTemp;

        this.acordos.forEach(a => {
            a.referenciaId = referenciaId
        });

        if (this.isAdd) {
            this.acordoService.addAcordos(this.acordos).subscribe(res => {
                this.spinner.hide();
                this.toastrService.success("Salvo com sucesso!", "Sucesso")
                this.modalRef.close();
            }, error => {
                this.spinner.hide();
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Erro ao salvar acordos!', 'Atenção', { timeOut: 10000 });
                }
            })
        } else {
            const acordo = this.acordos.filter(r => r.tipoAcordoJudicial != '2')[0] as AcordoJuridico;
            const honorario = this.acordos.filter(r => r.tipoAcordoJudicial == '2')[0] as AcordoJuridico;

            acordo.id = this.acordoId;
            honorario.id = this.honorarioId;

            this.acordoService.editAcordos(this.acordos).subscribe(res => {
                this.spinner.hide();
                this.toastrService.success("Salvo com sucesso!", "Sucesso")
                this.modalRef.close();
            }, error => {
                this.spinner.hide();
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Erro ao salvar acordos!', 'Atenção', { timeOut: 10000 });
                }
            })
        }
    }

    atualizarValoresAcordo() {

        this.acordos[0].dividas.forEach(divida => {

            var parcela = this.parcelas.filter(x => x.numDoc == divida.numeroDocumento);
            divida.juros = parcela[0].juros;
            divida.multa = parcela[0].multa;
            divida.valorCustasProtesto = parcela[0].custas;
            divida.valorAtualizacaoCustas = parcela[0].atualizacaoCusta;
            divida.valorAberto = parcela[0].valorAberto2;
            divida.valorSaldoPendente = parcela[0].valorTotal2;

            if (parcela[0].vencimento.toString().includes('/')) {
                const dateParts = parcela[0].vencimento.split('/');

                divida.dataVencimento = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0], 14, 0, 0);
            } else {
                divida.dataVencimento = new Date(parcela[0].vencimento.getFullYear(), parcela[0].vencimento.getMonth(), parcela[0].vencimento.getDate(), 14, 0, 0);
            }
        });

        this.acordos[1].dividas.forEach(divida => {

            var parcela = this.parcelasHonorario.filter(x => x.numDoc == divida.numeroDocumento);
            divida.juros = parcela[0].juros;
            divida.multa = parcela[0].multa;
            divida.valorCustasProtesto = parcela[0].custas;
            divida.valorAtualizacaoCustas = parcela[0].atualizacaoCusta;
            divida.valorAberto = parcela[0].valorAberto2;
            divida.valorSaldoPendente = parcela[0].valorTotal2;

            if (parcela[0].vencimento.toString().includes('/')) {
                const dateParts = parcela[0].vencimento.split('/');

                divida.dataVencimento = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0], 14, 0, 0);
            } else {
                divida.dataVencimento = new Date(parcela[0].vencimento.getFullYear(), parcela[0].vencimento.getMonth(), parcela[0].vencimento.getDate(), 14, 0, 0);
            }
        });
    }

    buscarValores() {
        const valorDividaPromese = this.processosJudiciaisValorService.obterValorAbertoPorRecursoDesdobramento(this.recursoDesdobramentoId).toPromise();
        const valorAtualizacaoValoresPromese = this.atualizacaoValoresService.calculosValoresAbertoPorRecursoDesdobramento(this.recursoDesdobramentoId).toPromise();
        const valorCausaPromese = this.processosJudiciaisRecursoDesdobramentoService.obterValorCausa(this.recursoDesdobramentoId).toPromise();
        const valorPessoaDados = this.pessoaService.getById(this.pessoaId).toPromise();
        const mapemaentoChaves = this.pessoaService.getPessoaMapeamentoChaves().toPromise();
        Promise.all([valorDividaPromese, valorAtualizacaoValoresPromese, valorCausaPromese, valorPessoaDados, mapemaentoChaves])
            .then(res => {
                this.valorDivida = res[0].data;
                this.valorAtualizacaoValores = res[1].data;
                this.valorCausa = res[2].data;
                this.pessoa = res[3].data;
                this.pessoaMap = res[4].data;
                this.InserirValoresDefault(this.pessoa);
            })
            .catch(err => console.log(err));
    }

    limparAcordoVinculado() {
        this.camposSimulacao.acordoVinculado = '';
    }

    disabledBotaoCalcular() {
        if (this.tipoAcordo == 0 || this.tipoAcordo == 1) {
            if (this.camposSimulacao.tipoAcordo == '' && this.camposSimulacao.acordoVinculado == '') return true;
            if (this.camposSimulacao.tipoAcordo == '0' && this.camposSimulacao.acordoVinculado == '') return true;
            if (this.camposSimulacao.tipoAcordo == '1' && this.camposSimulacao.valorAvulso == 0) return true;
            if (this.camposSimulacao.tipo == undefined || this.camposSimulacao.tipo == null) return true;
        }

        if (this.tipoAcordo == 0 || this.tipoAcordo == 2) {
            if (this.camposSimulacaoHonorario.tipoAcordo == '' && this.camposSimulacaoHonorario.acordoVinculado == '') return true;
            if (this.camposSimulacaoHonorario.tipoAcordo == '2' && this.camposSimulacaoHonorario.valorAvulso == null) return true;
            if (this.camposSimulacaoHonorario.tipoAcordo == '2' && this.camposSimulacaoHonorario.valorAvulso == 0) return true;
        }

        return false;
    }


    obterTipoParcela(tipoAcordo: string): String {
        switch (tipoAcordo) {
            case '0': return 'Acordo vinculado';
            case '1': return 'Acordo avulso';
            case '2': return 'Honorário';
        }

        return "";
    }

    arredondarValor(valor: any) {
        return Math.floor(valor * 100) / 100;
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    public valorTotalAcordoHonorario: number = 0.0;

    public valorTotalAcordo: number = 0.0;

    public parcelas = [];

    public parcelasHonorario = [];

    mockParcela() {
        this.valorTotalAcordo = 0.0;

        if (this.camposSimulacao.tipoAcordo == '0') {
            switch (this.camposSimulacao.acordoVinculado) {
                case '0':
                    this.valorTotalAcordo = this.valorDivida;
                    break;
                case '1':
                    this.valorTotalAcordo = this.valorAtualizacaoValores;
                    break;
                case '2':
                    this.valorTotalAcordo = this.valorCausa;
                    break;
                case '3':
                    this.valorTotalAcordo = this.valorConfessado;
                    break;
            }
        } else {
            this.valorTotalAcordo = this.camposSimulacao.valorAvulso;
        }

        const acordo = new AcordoJuridico();

        var dividas: ProcessoDivida[] = [];

        acordo.processoId = this.processoJudicialId;

        acordo.processoJudicialRecursoDesdobramentoId = this.recursoDesdobramentoId;

        acordo.juros = acordo.multa = acordo.custas = acordo.atualizacaoCusta = 0;

        acordo.valorConfesado = this.camposSimulacao.valorConfessado;

        acordo.totalEmAberto = acordo.valorTotal = this.valorTotalAcordo;        

        let dt = new Date(this.camposSimulacao.dataInicialParcelas.getFullYear(),
            this.camposSimulacao.dataInicialParcelas.getMonth(),
            this.camposSimulacao.dataInicialParcelas.getDate());

        let valorAbertoSobra = 0;
        let valorJurosSobra = 0;
        let valorMultaSobra = 0;
        let valorCustasSobra = 0;
        let valorAtualizacaoCustasSobra = 0;

        this.parcelas = [];

        let contadorMes = dt.getMonth();
        let numAno = 0;
        let numMes = 0;

        for (let index = 0; index < this.camposSimulacao.numParcelas; index++) {

            if (this.camposSimulacao.diaFixoOption == 'Sim') {
                dt.setMonth(contadorMes);
                numAno = dt.getFullYear();
                numMes = dt.getMonth();

                if (contadorMes == 1 && this.camposSimulacao.dataInicialParcelas.getDate() > 28) {
                    dt = new Date(numAno, numMes, 28);
                    //console.log("If Fevereiro: ", dt);
                }

                else if (this.camposSimulacao.dataInicialParcelas.getDate() == 31) {
                    var numDt = new Date(numAno, numMes + 1, 0).getDate();
                    dt = new Date(numAno, numMes, numDt);
                    //console.log("Else If dia 31: ", dt);
                }

                else {
                    dt = new Date(numAno, numMes, this.camposSimulacao.dataInicialParcelas.getDate());
                    //console.log("Else Outros Dias: ", dt);
                }
                contadorMes == 11 ? contadorMes = 0 : contadorMes++;
            }
            var calcJuros = this.arredondarValor((this.camposSimulacao.percentualJuros * this.valorTotalAcordo) / 100);
            var calcMulta = this.arredondarValor((this.camposSimulacao.percentualMulta * this.valorTotalAcordo) / 100);
            var calcAtualizacao = this.arredondarValor((this.camposSimulacao.percentualMulta * this.valorTotalAcordo) / 100);
            var dados = {
                tipoParcela: this.obterTipoParcela(this.camposSimulacao.tipoAcordo),
                tipoAcordo: this.camposSimulacao.tipoAcordo,
                numDoc: (index + 1) + "/" + this.camposSimulacao.numParcelas,
                parcela: index + 1,
                alterado: false,
                emissao: new Date().toLocaleDateString("pt-br"),//data
                vencimento: dt.toLocaleDateString("pt-br"),//data
                valorOriginal: 0,
                valorAberto: this.arredondarValor(this.valorTotalAcordo / this.camposSimulacao.numParcelas),
                valorAberto2: 0,
                juros: 0, //this.arredondarValor(calcJuros / this.camposSimulacao.numParcelas),
                multa: 0, // this.arredondarValor(calcMulta/ this.camposSimulacao.numParcelas),
                custas: 0, // this.arredondarValor(acordo.custas / this.camposSimulacao.numParcelas),
                atualizacaoCusta: 0,// this.arredondarValor(calcAtualizacao / this.camposSimulacao.numParcelas),
                valorTotal: 0,
                valorTotal2: 0,
                lancamento: "a vencer",//data
                tipo: this.camposSimulacao.tipo,
                tipoNome: this.obterNomeTipoAcordoJuridico(this.camposSimulacao.tipo),
                moeda: this.camposSimulacao.moeda
            };

            if ((index + 1) == this.camposSimulacao.numParcelas) {
                dados.valorAberto = this.valorTotalAcordo - valorAbertoSobra;
                dados.juros = 0;//acordo.juros;
                dados.multa = 0;//valorMultaSobra - acordo.multa;
                dados.custas = 0;//valorCustasSobra - acordo.custas;
                dados.atualizacaoCusta = 0;//valorAtualizacaoCustasSobra - acordo.atualizacaoCusta;
            }

            dados.valorAberto2 = dados.valorAberto;
            dados.valorTotal = dados.valorAberto + dados.juros + dados.multa + dados.custas + dados.atualizacaoCusta;
            dados.valorTotal2 = dados.valorTotal
            dados.moeda = this.camposSimulacao.moeda;

            valorAbertoSobra += dados.valorAberto;
            valorJurosSobra += dados.juros;
            valorMultaSobra += dados.multa;
            valorCustasSobra += dados.custas;
            valorAtualizacaoCustasSobra += dados.atualizacaoCusta;



            this.parcelas.push(dados);


            if (this.camposSimulacao.diaFixoOption == 'Sim') {
                dt.setMonth(dt.getMonth() + 1);

            } else {

                dt = this.addDays(dt, this.camposSimulacao.intervaloDias);
                console.log("Else Intervalo de dias: ", dt);
            }

            var processoDividaParaAcordo = new ProcessoDivida();
            processoDividaParaAcordo.dataEmissao = new Date();
            processoDividaParaAcordo.dataVencimento = new Date(dt.getFullYear(), dt.getMonth() - 1, dt.getDate(), 14, 0, 0);
            processoDividaParaAcordo.valorOriginal = 0,
                processoDividaParaAcordo.valorAberto = (acordo.totalEmAberto) / this.camposSimulacao.numParcelas
            processoDividaParaAcordo.parcela = (index + 1).toString();
            processoDividaParaAcordo.numeroDocumento = (index + 1) + "/" + this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.numeroTitulo = "";
            processoDividaParaAcordo.tipoParcela = this.obterTipoParcela(this.camposSimulacao.tipoAcordo);
            processoDividaParaAcordo.juros = 0;//acordo.juros / this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.multa = 0;//acordo.multa / this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.valorCustasProtesto = 0;//acordo.custas / this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.valorSaldoPendente = (acordo.totalEmAberto / this.camposSimulacao.numParcelas) + (acordo.juros / this.camposSimulacao.numParcelas) + (acordo.multa / this.camposSimulacao.numParcelas) + (acordo.custas / this.camposSimulacao.numParcelas) + (acordo.atualizacaoCusta / this.camposSimulacao.numParcelas);


            dividas.push(processoDividaParaAcordo);

            acordo.parcelas = this.camposSimulacao.numParcelas;
            acordo.dia = this.camposSimulacao.intervaloDias;
            acordo.diaFixo = this.camposSimulacao.diaFixoOption == "Sim" ? true : false;
            acordo.inicio = this.camposSimulacao.dataInicialParcelas;
            acordo.desconto = acordo.desconto;
            acordo.tipo = this.camposSimulacao.tipo;
            acordo.tipoNome = this.obterNomeTipoAcordoJuridico(this.camposSimulacao.tipo);
            acordo.tipoAcordoJudicial = this.camposSimulacao.tipoAcordo;
            acordo.tipoAcordoVinculadoJudicial = this.camposSimulacao.tipoAcordo == '0' ? this.camposSimulacao.acordoVinculado : '';
            acordo.dividas = dividas;
            acordo.processoId = this.processoJudicialId;
            acordo.percentualJuros = this.camposSimulacao.percentualJuros;
            acordo.juros = 0;//acordo.juros;
            acordo.percentualMulta = this.camposSimulacao.percentualMulta;
            acordo.multa = 0; //acordo.multa;
            acordo.percentualTaxaAdministrativa = this.camposSimulacao.percentualTaxaAdministrativa;
            acordo.percentualIndiceCorrecao = this.camposSimulacao.percentualIndiceCorrecao;
            acordo.percentualHonorarioAdvocaticioDescumprimento = this.camposSimulacao.percentualHonorarioAdvocaticioDescumprimento;
            acordo.taxaAdministrativa = 0; // acordo.taxaAdministrativa;
            acordo.protesto = 0; // acordo.custas;
            acordo.valorAberto = (acordo.totalEmAberto);
            acordo.valorOriginal = acordo.valorOriginal;
            acordo.valorTotal = (acordo.totalEmAberto) + (acordo.juros) + (acordo.multa) + (acordo.custas);
            acordo.indiceId = this.camposSimulacao.indice;
            acordo.moeda = this.camposSimulacao.moeda;
            this.acordos[0] = acordo;
        }
    }
    obterNomeTipoAcordoJuridico(tipo: String) {

        let tipoAcordo = this.listTipoAcordo.find(x => x.id == this.camposSimulacao.tipo);

        return tipoAcordo?.nome;
    }

    mockParcelaHonorario() {
        this.valorTotalAcordoHonorario = 0.0;

        this.valorTotalAcordoHonorario = this.camposSimulacaoHonorario.valorAvulso;

        const acordo = new AcordoJuridico();

        var dividas: ProcessoDivida[] = [];

        acordo.processoId = this.processoJudicialId;

        acordo.processoJudicialRecursoDesdobramentoId = this.recursoDesdobramentoId;

        acordo.juros = acordo.multa = acordo.custas = acordo.atualizacaoCusta = 0;

        acordo.totalEmAberto = acordo.valorTotal = this.valorTotalAcordoHonorario;

        let dt = new Date(this.camposSimulacaoHonorario.dataInicialParcelas.getFullYear(),
            this.camposSimulacaoHonorario.dataInicialParcelas.getMonth(),
            this.camposSimulacaoHonorario.dataInicialParcelas.getDate());

        let valorAbertoSobra = 0;
        let valorJurosSobra = 0;
        let valorMultaSobra = 0;
        let valorCustasSobra = 0;
        let valorAtualizacaoCustasSobra = 0;

        this.parcelasHonorario = [];

        let contadorMes = dt.getMonth();
        let numAno = 0;
        let numMes = 0;

        for (let index = 0; index < this.camposSimulacaoHonorario.numParcelas; index++) {

            if (this.camposSimulacaoHonorario.diaFixoOption == 'Sim') {
                dt.setMonth(contadorMes);
                numAno = dt.getFullYear();
                numMes = dt.getMonth();

                if (contadorMes == 1 && this.camposSimulacaoHonorario.dataInicialParcelas.getDate() > 28) {
                    dt = new Date(numAno, numMes, 28);
                    //console.log("If Fevereiro: ", dt);
                }

                else if (this.camposSimulacaoHonorario.dataInicialParcelas.getDate() == 31) {
                    var numDt = new Date(numAno, numMes + 1, 0).getDate();
                    dt = new Date(numAno, numMes, numDt);
                    //console.log("Else If dia 31: ", dt);
                }

                else {
                    dt = new Date(numAno, numMes, this.camposSimulacaoHonorario.dataInicialParcelas.getDate());
                    //console.log("Else Outros Dias: ", dt);
                }
                contadorMes == 11 ? contadorMes = 0 : contadorMes++;
            }
            var calcJuros = this.arredondarValor((this.camposSimulacaoHonorario.percentualJuros * this.valorTotalAcordoHonorario) / 100);
            var calcMulta = this.arredondarValor((this.camposSimulacaoHonorario.percentualMulta * this.valorTotalAcordoHonorario) / 100);
            var calcAtualizacao = this.arredondarValor((this.camposSimulacaoHonorario.percentualMulta * this.valorTotalAcordoHonorario) / 100);
            var dados = {
                tipoParcela: this.obterTipoParcela(this.camposSimulacaoHonorario.tipoAcordo),
                tipoAcordo: this.camposSimulacao.tipoAcordo,
                numDoc: (index + 1) + "/" + this.camposSimulacaoHonorario.numParcelas,
                parcela: index + 1,
                alterado: false,
                emissao: new Date().toLocaleDateString("pt-br"),//data
                vencimento: dt.toLocaleDateString("pt-br"),//data
                valorOriginal: 0,
                valorAberto: this.arredondarValor(this.valorTotalAcordoHonorario / this.camposSimulacaoHonorario.numParcelas),
                valorAberto2: 0,
                juros: 0,//this.arredondarValor(calcJuros / this.camposSimulacaoHonorario.numParcelas),
                multa: 0, //this.arredondarValor(calcMulta / this.camposSimulacaoHonorario.numParcelas),
                custas: 0, //this.arredondarValor(acordo.custas / this.camposSimulacaoHonorario.numParcelas),
                atualizacaoCusta: 0, // this.arredondarValor(calcAtualizacao / this.camposSimulacaoHonorario.numParcelas),
                valorTotal: 0,
                valorTotal2: 0,
                lancamento: "a vencer",//data                
                tipoNome: this.obterNomeTipoAcordoJuridico(this.camposSimulacao.tipo),
                moeda: this.camposSimulacaoHonorario.moeda
            };

            if ((index + 1) == this.camposSimulacaoHonorario.numParcelas) {
                dados.valorAberto = this.valorTotalAcordoHonorario - valorAbertoSobra;
                dados.juros = 0; // valorJurosSobra - acordo.juros;
                dados.multa = 0; //valorMultaSobra - acordo.multa;
                dados.custas = 0; //valorCustasSobra - acordo.custas;
                dados.atualizacaoCusta = 0; //valorAtualizacaoCustasSobra - acordo.atualizacaoCusta;
            }

            dados.valorAberto2 = dados.valorAberto;
            dados.valorTotal = dados.valorAberto + dados.juros + dados.multa + dados.custas + dados.atualizacaoCusta;
            dados.valorTotal2 = dados.valorTotal
            dados.moeda = this.camposSimulacaoHonorario.moeda

            valorAbertoSobra += dados.valorAberto;
            valorJurosSobra += dados.juros;
            valorMultaSobra += dados.multa;
            valorCustasSobra += dados.custas;
            valorAtualizacaoCustasSobra += dados.atualizacaoCusta;



            this.parcelasHonorario.push(dados);


            if (this.camposSimulacaoHonorario.diaFixoOption == 'Sim') {
                dt.setMonth(dt.getMonth() + 1);

            } else {

                dt = this.addDays(dt, this.camposSimulacaoHonorario.intervaloDias);
                console.log("Else Intervalo de dias: ", dt);
            }

            var processoDividaParaAcordo = new ProcessoDivida();
            processoDividaParaAcordo.dataEmissao = new Date();
            processoDividaParaAcordo.dataVencimento = new Date(dt.getFullYear(), dt.getMonth() - 1, dt.getDate(), 14, 0, 0);
            processoDividaParaAcordo.valorOriginal = 0,
                processoDividaParaAcordo.valorAberto = (acordo.totalEmAberto) / this.camposSimulacaoHonorario.numParcelas
            processoDividaParaAcordo.parcela = (index + 1).toString();
            processoDividaParaAcordo.numeroDocumento = (index + 1) + "/" + this.camposSimulacaoHonorario.numParcelas;
            processoDividaParaAcordo.numeroTitulo = "";
            processoDividaParaAcordo.tipoParcela = this.obterTipoParcela(this.camposSimulacaoHonorario.tipoAcordo);
            processoDividaParaAcordo.juros = 0; //acordo.juros / this.camposSimulacaoHonorario.numParcelas;
            processoDividaParaAcordo.multa = 0; //acordo.multa / this.camposSimulacaoHonorario.numParcelas;
            processoDividaParaAcordo.valorCustasProtesto = 0; //acordo.custas / this.camposSimulacaoHonorario.numParcelas;
            processoDividaParaAcordo.valorSaldoPendente = (acordo.totalEmAberto / this.camposSimulacaoHonorario.numParcelas) + (acordo.juros / this.camposSimulacaoHonorario.numParcelas) + (acordo.multa / this.camposSimulacaoHonorario.numParcelas) + (acordo.custas / this.camposSimulacaoHonorario.numParcelas) + (acordo.atualizacaoCusta / this.camposSimulacaoHonorario.numParcelas);


            dividas.push(processoDividaParaAcordo);

            acordo.parcelas = this.camposSimulacaoHonorario.numParcelas;
            acordo.dia = this.camposSimulacaoHonorario.intervaloDias;
            acordo.diaFixo = this.camposSimulacaoHonorario.diaFixoOption == "Sim" ? true : false;
            acordo.inicio = this.camposSimulacaoHonorario.dataInicialParcelas;
            acordo.desconto = acordo.desconto;
            acordo.tipoNome = this.obterNomeTipoAcordoJuridico(this.camposSimulacao.tipo);
            acordo.tipoAcordoJudicial = this.camposSimulacaoHonorario.tipoAcordo;
            acordo.tipoAcordoVinculadoJudicial = this.camposSimulacaoHonorario.tipoAcordo == '0' ? this.camposSimulacaoHonorario.acordoVinculado : '';
            acordo.dividas = dividas;
            acordo.processoId = this.processoJudicialId;
            acordo.percentualJuros = 0;
            acordo.juros = 0; //acordo.juros;
            acordo.percentualMulta = 0;
            acordo.multa = 0; //acordo.multa;
            acordo.percentualTaxaAdministrativa = this.camposSimulacaoHonorario.percentualTaxaAdministrativa;
            acordo.percentualIndiceCorrecao = this.camposSimulacao.percentualIndiceCorrecao;
            acordo.percentualHonorarioAdvocaticioDescumprimento = this.camposSimulacao.percentualHonorarioAdvocaticioDescumprimento;
            acordo.taxaAdministrativa = 0; //acordo.taxaAdministrativa;
            acordo.protesto = 0; //acordo.custas;
            acordo.valorAberto = (acordo.totalEmAberto);
            acordo.valorOriginal = acordo.valorOriginal;
            acordo.valorTotal = (acordo.totalEmAberto) + (acordo.juros) + (acordo.multa) + (acordo.custas);
            acordo.indiceId = this.camposSimulacao.indice;
            acordo.moeda = this.camposSimulacaoHonorario.moeda;
            this.acordos[1] = acordo;
        }
    }

    calcularParcelas() {
        this.mockParcela();
        this.mockParcelaHonorario();
        this.salvarBtnDisable = false;
    }

    calcularValorTotalAcordo(parcela: any) {
        if (parcela.valorTotal != parcela.valorTotal2) {
            parcela.valorAberto2 = parcela.valorTotal2 - (parcela.juros + parcela.multa + parcela.custas + parcela.atualizacaoCusta);
        }

        //parcela.alterado = true;

        this.valorTotalAcordo = 0;

        let totalParcelas = this.parcelas.length;
        let totalParcelasRestantes = totalParcelas;

        let valorSobraParcela = 0;

        let valorTotalCalculo = 0;

        this.parcelas.forEach(valor => {
            if (valor.alterado) {
                valorTotalCalculo += valor.valorAberto2;
                totalParcelasRestantes--;
            }
        });

        this.parcelas.forEach(valor => {

            if (!valor.alterado) {
                let valorCalculo = (this.acordos[0].totalEmAberto - valorTotalCalculo) / (totalParcelasRestantes);
                valor.valorAberto2 = Math.floor(valorCalculo * 100) / 100;


                if (valor.parcela == totalParcelas) {
                    valor.valorAberto2 = this.acordos[0].totalEmAberto - valorSobraParcela;
                }

                valor.valorTotal2 = valor.valorAberto2 + valor.juros + valor.multa + valor.custas + valor.atualizacaoCusta;
            }

            valorSobraParcela += valor.valorAberto2;

            this.valorTotalAcordo += valor.valorTotal2;
        });
    }

    calcularValorTotalHonorario(parcela: any) {
        if (parcela.valorTotal != parcela.valorTotal2) {
            parcela.valorAberto2 = parcela.valorTotal2 - (parcela.juros + parcela.multa + parcela.custas + parcela.atualizacaoCusta);
        }

        //parcela.alterado = true;

        this.valorTotalAcordoHonorario = 0;

        let totalParcelas = this.parcelasHonorario.length;
        let totalParcelasRestantes = totalParcelas;

        let valorSobraParcela = 0;

        let valorTotalCalculo = 0;

        this.parcelasHonorario.forEach(valor => {
            if (valor.alterado) {
                valorTotalCalculo += valor.valorAberto2;
                totalParcelasRestantes--;
            }
        });

        this.parcelasHonorario.forEach(valor => {

            if (!valor.alterado) {
                let valorCalculo = (this.acordos[1].totalEmAberto - valorTotalCalculo) / (totalParcelasRestantes);
                valor.valorAberto2 = Math.floor(valorCalculo * 100) / 100;


                if (valor.parcela == totalParcelas) {
                    valor.valorAberto2 = this.acordos[1].totalEmAberto - valorSobraParcela;
                }

                valor.valorTotal2 = valor.valorAberto2 + valor.juros + valor.multa + valor.custas + valor.atualizacaoCusta;
            }

            valorSobraParcela += valor.valorAberto2;

            this.valorTotalAcordoHonorario += valor.valorTotal2;
        });
    }

    limparParcelas() {
        this.parcelas = [];
        this.parcelasHonorario = [];
        this.salvarBtnDisable = true;
    }
    getValue(chavePessoaDados: String, chavePessoaComplementar, pessoa: Pessoa) {

        var pessoaDados = this.pessoa.pessoaDados.find(x => x.nome == chavePessoaDados);

        if (pessoaDados != null) {
            var pessoaDadosComplementares = pessoaDados.pessoaDadosComplementares.find(x => x.nome == chavePessoaComplementar);

            if (pessoaDadosComplementares != null) {
                return pessoaDadosComplementares;
            } else {
                var newPessoaDadosComp = new PessoaDadosComplementares();
                newPessoaDadosComp.pessoaDadosId = pessoaDados.id;
                newPessoaDadosComp.nome = chavePessoaComplementar;

                pessoaDados.pessoaDadosComplementares.push(newPessoaDadosComp);

                return newPessoaDadosComp;
            }


        }
        else {
            var newPessoaDados = new PessoaDados();
            newPessoaDados.nome = chavePessoaDados;

            var newPessoaDadosComp = new PessoaDadosComplementares();
            newPessoaDadosComp.pessoaDadosId = newPessoaDados.id;
            newPessoaDadosComp.nome = chavePessoaComplementar;

            newPessoaDados.pessoaDadosComplementares.push(newPessoaDadosComp);

            this.pessoa.pessoaDados.push(newPessoaDados);

            return newPessoaDadosComp;

        }



    }
}
