import { ConfissaoService } from './../../../../services/confissao.service';
import { ProcessosService } from '../../../../services/processos.service';
import { NotificacaoService } from '../../../../services/notificacao.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';
import { UtilsService } from 'src/app/utils/utils.service';
import { PddService } from 'src/app/services/pdd.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-modal-modelos-confissao',
  templateUrl: './modal-modelos-confissao.component.html',
  styleUrls: ['./modal-modelos-confissao.component.css']
})
export class ModalModelosConfissaoComponent implements OnInit {

  modalRef: NgbModalRef;
  processoId:any;
  idExterno:any = "";
  cobradorId:any;

  modelosDisponiveis: any[] = [];


  constructor(
    public pddService: PddService,
    public toastrService: ToastrService,
    public processoService:ProcessosService,
    private utilsService: UtilsService,
    public confissaoService : ConfissaoService
  ) { }

  ngOnInit(): void {
    this.processoId = this.modalRef.componentInstance.processoId;

    this.processoService.getById(this.processoId).subscribe(x => {
      this.idExterno = x.data.idExterno.toString();
      this.cobradorId = x.data.cobradorId;
    })

    this.getModelosConfissaoDisponiveis(this.processoId);

  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }



  downloadModeloConfissao(codImplementation) {
    this.confissaoService.downloadModeloConfissao(this.processoId,codImplementation).subscribe(
        data => {
            this.utilsService.renderDownload(data, "Modelo Confissao.docx" as string);
        }
    );

  }

  getModelosConfissaoDisponiveis(processoId)
  {
      this.confissaoService.getAllModelosDisponiveisByProcesso(processoId).subscribe(x => {
            this.modelosDisponiveis = x.data;
      });
  }


}

