export class MidiasSociaisDevedor {
    email: String;
    facebook: String;
    instagram: String;
    linkedin: String;
    telegram: String;
    twitter: String;

    selecionado:Boolean;
}
