import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "src/app/utils/dateformat";
import { PessoaDropdownComponent } from "../pessoa-dropdown/pessoa-dropdown.component";
import { ToastrService } from "ngx-toastr";
import { TipoPrioridadeService } from "src/app/services/tipo-prioridade.service";
import { CalendarioService } from "src/app/services/calendario.service";
import { ProcessosJudiciaisRecursoDesdobramentoService } from "src/app/services/processos-judiciais-recurso-desdobramento.service";
import { FormularioDinamicoService } from "src/app/services/formulario-dinamico.service";
import { TipoAlertaService } from "src/app/services/tipo-alerta.service";

@Component({
  selector: "formulario-cadastro",
  templateUrl: "./formulario-cadastro.component.html",
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class FormularioCadastroComponent implements OnInit {
  @Input() formulario: any;
  @Input() index: any;
  @Input() recursosDesdobramentoId: string;

  @ViewChild("executorDropdown") executorDropdown!: PessoaDropdownComponent;
  @ViewChild("revisorDropdown") revisorDropdown!: PessoaDropdownComponent;
  @ViewChild("assistenteDropdown") assistenteDropdown!: PessoaDropdownComponent;

  public tipoAlerta: any = [];
  public tipoPrioridade: any = [];
  public equipeId: number;
  public calcularData: boolean = false;
  public formularios: any = [];

  constructor(
    private _toastrService: ToastrService,
    private _tipoPrioridadeService: TipoPrioridadeService,
    private _caledarioService: CalendarioService,
    private _processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
    private _formularioDinamicoService: FormularioDinamicoService,
    private _tipoAlertaService: TipoAlertaService
  ) {}

  ngOnInit(): void {
    this.carregarFormularios();
    this.buscarTipoAlertas();
    this.buscarTipoPrioridade();
  }

  buscarTipoAlertas() {
    this._tipoAlertaService.getAllAsync().then(res => {
        console.log(res);
        this.tipoAlerta = res.data;
    });
  }

  buscarTipoPrioridade() {
    this._tipoPrioridadeService.getAllAsync().then((x) => {
      this.tipoPrioridade = x.data;
    });
  }

  definirDataRevisao() {
    if (this.formulario.dataLimiteComponent != undefined) {
      const dataLimite = new Date(
        this.formulario.dataLimiteComponent.year,
        this.formulario.dataLimiteComponent.month - 1,
        this.formulario.dataLimiteComponent.day
      );

      this.calcularData = true;

      this.formulario.dataRevisaoComponent = null;

      this._toastrService.clear();

      this._caledarioService.calcularDiaUtilNegativo(-3, dataLimite).subscribe(
        (res) => {
          this.formataDataRevisao(new Date(res.data));
        },
        (err) => {
          this.calcularData = false;
          this._toastrService.error(
            "Não foi possível calcular data!",
            "Atenção",
            { timeOut: 10000 }
          );
        }
      );
    }
  }

  formataDataRevisao(data: Date) {
    this.formulario.dataRevisaoComponent = {
      year: data.getFullYear(),
      month: data.getMonth() + 1,
      day: data.getDate(),
    };

    this.calcularData = false;
  }

  onChangeEquipe(equipe) {
    if (!equipe) return;

    this.executorDropdown.selecionarPessoa(equipe.executorPadraoId);
    this.revisorDropdown.selecionarPessoa(equipe.revisorPadraoId);
    this.assistenteDropdown.selecionarPessoa(equipe.assistentePadraoId);
  }

  obterResponsavelProcesso() {
    if (
      this.formulario.responsavelId == undefined &&
      this.recursosDesdobramentoId != null
    ) {
      this._processosJudiciaisRecursoDesdobramentoService
        .obterResponsavelProcesso(this.recursosDesdobramentoId)
        .subscribe((res) => {
          this.formulario.responsavelId = res.data;
        });
    }
  }

  carregarFormularios() {
    this._formularioDinamicoService.getAll().subscribe(
      (res) => {
        this.formularios = res.data;
      },
      (error) => {
        if (error && error.errors) {
          this._toastrService.error(error.errors[0], "Atenção");
        } else {
          this._toastrService.error(
            "Não foi possível carregar os formulários!",
            "Atenção",
            { timeOut: 10000 }
          );
        }
      }
    );
  }
}
