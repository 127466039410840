import { AcordoListaResponse } from '../models/operacionais/acordo/acordo-lista-response';
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AcordoJuridico } from '../models/operacionais/acordo/acordoJuridico';

@Injectable({
    providedIn: 'root'
})
export class AcordoJuridicoService extends ServiceBase {
    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'acordoJuridico/';

    isEnviarAprovacaoCredor(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + "is-enviar-aprovacao", data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data): Observable<AcordoJuridico> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addAcordos(data: AcordoJuridico[]) {
        return this.http.post(environment.api + this.endpoint + 'adicionar-acordos', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    editAcordos(data: AcordoJuridico[]) {
        return this.http.put(environment.api + this.endpoint + 'atualiar-acordos', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizarSimulacao(data: any) {
        return this.http.put(environment.api + this.endpoint + 'atualizar-simulacao', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    aprovarAcordo(id: any) {
        return this.http.put(environment.api + this.endpoint + 'aprovar-acordo/' + id, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterPorId(id) {
        return this.http
            .get(environment.api + this.endpoint + `${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosPorRecursoDescobramento(id) {
        return this.http
            .get(environment.api + this.endpoint + `recurso-desdobramento/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosPorReferencia(referenciaId) {
        return this.http
            .get(environment.api + this.endpoint + `obter-por-referencia/${referenciaId}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasPorProcesso(id) {
        return this.http
            .get(environment.api + this.endpoint + `obter-acordos-por-recurso-desdobramento/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterSituacaoAcordoDivida() {
        return this.http
            .get(environment.api + this.endpoint + `obter-situacao-acordo-divida`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterHistoricos(id) {
        return this.http
            .get(environment.api + this.endpoint + `obter-historicos/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    historicoAdd(data) {
        return this.http
            .post(environment.api + this.endpoint + `historico/`, data)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }
    atualizarSituacaoAcordoDivida(data) {
        return this.http
            .post(environment.api + this.endpoint + `atualizar-situacao-acordo-divida/`, data)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasEmAberto(filtro) {
        return this.http
            .post(environment.api + this.endpoint + 'obter-acordos-parcelas-em-aberto', filtro)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasGeral(filtro) {
        return this.http
            .post(environment.api + this.endpoint + 'obter-acordos-parcelas-geral', filtro)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasEmAbertoPorParcela(filtro) {
        return this.http
            .post(environment.api + this.endpoint + 'obter-acordos-parcelas-em-aberto-por-parcela', filtro)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasVencemHoje(filtro) {
        return this.http
            .post(environment.api + this.endpoint + 'obter-acordos-parcelas-vencem-hoje', filtro)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasVencemHojePorParcela(filtro) {
        return this.http
            .post(environment.api + this.endpoint + 'obter-acordos-parcelas-vencem-hoje-por-parcela', filtro)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasVencidas(filtro) {
        return this.http
            .post(environment.api + this.endpoint + 'obter-acordos-parcelas-vencidas', filtro)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterAcordosParcelasVencidasPorParcela(filtro) {
        return this.http
            .post(environment.api + this.endpoint + 'obter-acordos-parcelas-vencidas-por-parcela', filtro)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    definirPagamentoParcela(data: any) {
        return this.http.post(`${environment.api}${this.endpoint}definir-pagamento-parcela`, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    checarParcelaPossuiSuccessFeeParaAtender(id) {
        return this.http
            .get(environment.api + this.endpoint + `parcela-acordo-possui-success-fee-para-atender/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterChaveProcessoPorParcela(id) {
        return this.http
            .get(environment.api + this.endpoint + `obter-chave-processo-por-parcela/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    export(referenciaId) : Observable<Blob> {
        return this.http.get(environment.api + this.endpoint + 'export/'+ referenciaId, {responseType: "blob"})
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    excluirPagamento(parcelaId): Observable<any> {
        return this.http.delete(environment.api+ this.endpoint  + 'excluir-pagamento/' + parcelaId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
