import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EquipeService } from 'src/app/services/equipe.service';

@Component({
    selector: 'equipe-dropdown',
    templateUrl: './equipe-dropdown.component.html'
})
export class EquipeDropdownComponent implements OnInit {

    @Input() equipeId!: any | any;
    @Output() equipeIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public equipes: any = [];
    public loadEquipes: boolean = false;
    public equipe: any;

    constructor(
        private equipeService: EquipeService
        ) { }

    ngOnInit(): void {        
        if(this.equipeId){
            this.buscarPorId();            
        }else{
            this.buscarPessoa('e');
        }
    }

    changeEquipe($event){
        
        this.equipeIdChange.emit(this.equipeId);        
        
        this.change.emit(this.equipes.filter(x=>x.id == this.equipeId)[0]);
    }

    filtrar(term: any){
        if(term.target.value != '')
            this.buscarPessoa(term.target.value);
    }

    buscarPessoa(buscar) {        
        this.loadEquipes = true;
        this.equipeService.obterEquipeFiltroNomeAsync(buscar).then(x => {
            this.loadEquipes = false;
            this.equipes = x.data;
            
            if(this.equipe != undefined){
                this.equipes.push(this.equipe);
            }
        });
    }

    buscarPorId() {
        this.loadEquipes = true;
        this.equipeService.getByIdAsync(this.equipeId)
            .then(res=>{
                this.loadEquipes = false;
                let equipes = [];
                equipes.push(res.data);
                this.equipe = res.data;
                this.equipe = equipes;
            })
    }
}
