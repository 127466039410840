import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { FileLikeObject, FileUploader, FileUploadModule } from 'ng2-file-upload';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { DocumentoService } from 'src/app/services/documento.service';
import { TagService } from 'src/app/services/tag.service';
import { SituacaoService } from 'src/app/services/situacao.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { OcorrenciaRequest } from 'src/app/models/operacionais/ocorrencia/ocorrencia-request';
import { SelectListService } from 'src/app/services/shared/select-list.service';
import { SelectList } from 'src/app/models/select-list';
import { SelectListMultiSelect } from 'src/app/models/select-list-multi-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { OcorrenciaResponse } from 'src/app/models/operacionais/ocorrencia/ocorrencia-response';
import { UtilsService } from 'src/app/utils/utils.service';
import { TipoStatus, TipoStatusLabel } from 'src/app/enums/tipo-status.enum';
import { Ocorrencia } from 'src/app/models/operacionais/ocorrencia/ocorrencia';
import { OcorrenciaAtualizarStatus, OcorrenciaStatusRequest } from 'src/app/models/operacionais/ocorrencia/ocorrencia-status-request';
import { AuthService } from 'src/app/auth/auth.service';
import { ListaOcorrenciaHistoricoComponent } from '../lista-ocorrencia-historico/lista-ocorrencia-historico.component';


@Component({
    selector: 'app-modal-ocorrencia-detalhe',
    templateUrl: './modal-ocorrencia-detalhe.component.html',
    styleUrls: ['./modal-ocorrencia-detalhe.component.css']
})
export class ModalOcorrenciaDetalheComponent implements OnInit {

    @ViewChild(ListaOcorrenciaHistoricoComponent, { static: false }) comentarios: ListaOcorrenciaHistoricoComponent;

    public isRTL: boolean;

    modalRef: NgbModalRef;
    isIE10 = false;

    public origemList: SelectList[] = [];
    public tipoDocumentos: SelectList[] = [];
    public situacoesPaiList: SelectList[] = [];
    public situacoesList: SelectList[] = [];

    public options: { readonly?: Boolean, previousTab?: String };

    public dropdownSettings = {
        singleSelection: false,
        text: "Selecione tags",
        selectAllText: 'Selecione todas',
        unSelectAllText: 'Remova todas',
        enableSearchFilter: true,
        classes: "multi-select-myclass"
    };

    public processo: ProcessoOperacao;
    public ocorrencia: Ocorrencia = new Ocorrencia();

    public novosAnexos: any[] = [];;
    public files: File[] = [];
    public isHovering: boolean;
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    public hasAnotherDropZoneOver: boolean;
    public response: string;
    public tiposOcorrencias: any;

    public tags: SelectListMultiSelect[] = [];
    public tagsSelecionadas: SelectListMultiSelect[] = [];

    title: any;
    isMaster: Boolean;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public documentoService: DocumentoService,
        public tagService: TagService,
        public situacaoService: SituacaoService,
        public ocorrenciaService: OcorrenciaService,
        public selectListService: SelectListService,
        private spinner: NgxSpinnerService,
        public utilsService: UtilsService,
        authService: AuthService
    ) {
        this.isRTL = appService.isRTL;

        this.uploader = new FileUploader({
            url: ocorrenciaService.postFileUrl,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise<void>((resolve, reject) => {
                    resolve();
                });
            }
        });


        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);

        this.isIE10 = this.appService.isIE10;
        this.isMaster = authService.isMaster();
    }

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processo;

        this.ocorrenciaService.getById(this.modalRef.componentInstance.updateData.id).subscribe(res => {
            this.ocorrencia = res.data;
            this.title = `Detalhe da Ocorrência - ${TipoStatusLabel.get(this.ocorrencia.status)} - Processo #${this.processo.idExterno}`;
            this.tagsSelecionadas = this.ocorrencia.ocorrenciaTags.map(res => { return new SelectListMultiSelect(res.tagId, res.nome); });
        });

        /*
        this.modalService.resultConfirmDialog$.asObservable().subscribe(res => {
            if (res != undefined) {
                if (res.result === true && res.page === 'emailCredor') {
                    let ocorrencia = new OcorrenciaRequest();
                    ocorrencia.id = this.ocorrencia.id;
                    ocorrencia.emails = res.lista;
                    this.spinner.show();

                    this.ocorrenciaService.enviarEmail(ocorrencia)
                        .subscribe((res: OcorrenciaResponse) => {
                            if (res.success) {
                                this.toastrService.success("Email enviado com sucesso.", "Sucesso");
                            } else {
                                this.toastrService.error('Não foi possível enviar email para esta ocorrência!', 'Atenção', { timeOut: 10000 });
                            }

                            this.spinner.hide();
                        }, error => {
                            this.spinner.hide();

                            if (error && error.errors) {
                                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                            }
                            else {
                                this.toastrService.error('Não foi possível enviar email para esta ocorrência!', 'Atenção', { timeOut: 10000 });
                            }
                        });
                }
            }
        });
        */
    }


    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.options.previousTab = "tabOcorrencias";
        this.modalService.abrirModalContatoProcesso(processo, this.options);
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    aprovarOcorrencia() {
        this.updateStatus(TipoStatus.OcorrenciaFinalizada);
    }

    reprovarOcorrencia() {

        if(!this.comentarios.historicoIncluido)
        {
            this.toastrService.error("Por favor, inclua um comentário a ocorrência!", 'Atenção', { timeOut: 10000 });
        }
        else{
           this.updateStatus(TipoStatus.OcorrenciaRejeitada);
        }

    }

    isShowAprovar() {
        return this.isMaster  && (this.ocorrencia.status == TipoStatus.OcorrenciaCriada || this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao || this.ocorrencia.status == TipoStatus.OcorrenciaPendenteCobrador);
    }



    isShowReprovar() {
        return this.isMaster && (this.ocorrencia.status == TipoStatus.OcorrenciaCriada || this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao);
    }

    reenviarEmail() {
        var resultado = this.modalService.abrirModalEmailCredor(this.processo.credorId);
        resultado.asObservable().subscribe(res => {
            if (res != undefined) {
                if (res.result === true && res.page === 'emailCredor') {
                    let ocorrencia = new OcorrenciaRequest();
                    ocorrencia.id = this.ocorrencia.id;
                    ocorrencia.emails = res.lista;
                    this.spinner.show();

                    this.ocorrenciaService.enviarEmail(ocorrencia)
                        .subscribe((res: OcorrenciaResponse) => {
                            if (res.success) {
                                this.toastrService.success("Email enviado com sucesso.", "Sucesso");
                            } else {
                                this.toastrService.error('Não foi possível enviar email para esta ocorrência!', 'Atenção', { timeOut: 10000 });
                            }

                            this.spinner.hide();
                        }, error => {
                            this.spinner.hide();

                            if (error && error.errors) {
                                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                            }
                            else {
                                this.toastrService.error('Não foi possível enviar email para esta ocorrência!', 'Atenção', { timeOut: 10000 });
                            }
                        });
                }
            }
        });
    }

    private updateStatus(status: TipoStatus) {

        let ocorrenciaAtualizarStatus: OcorrenciaAtualizarStatus = {
            id: this.ocorrencia.id,
            processoId: this.processo.id
        }
        
        let ocorrenciaStatusRequest = new OcorrenciaStatusRequest(status, [ocorrenciaAtualizarStatus]);

        this.ocorrenciaService.updateStatus(ocorrenciaStatusRequest)
            .subscribe((res: OcorrenciaResponse) => {
                this.spinner.hide();

                this.toastrService.success("Ocorrência atualizada.", "Sucesso");

                setTimeout(() => {
                    this.close(this.processo);
                }, 300);
            }, error => {
                this.spinner.hide();

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
            });
    }
}
