import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-info-tipo-andamento',
  templateUrl: './modal-info-tipo-andamento.component.html',
  styleUrls: ['./modal-info-tipo-andamento.component.css']
})
export class ModalInfoTipoAndamentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
