import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from 'src/app/services/service.base';
import { Injectable } from '@angular/core';
import { AtividadeListaResponse } from '../models/operacionais/atividade/atividade-lista-response';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AtualizacaoValoresService extends ServiceBase {

    public endpoint = 'AtualizacaoValores/';

    constructor(private http: HttpClient) {
        super();
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'ListarTodos/')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(id: string): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'ObterPorId/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByProcessId(id: string): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'ObterPorProcessoId/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + `${this.endpoint}${data.id}`, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    aprovarSimulacaoValores(id): Observable<any> {
        return this.http.put(environment.api + `${this.endpoint}aprovar-simulacao-valores/${id}`, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(data): Observable<any> {
        return this.http.delete(environment.api + `${this.endpoint}/${data.id}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listarCalculosDeValoresPorRecursoDesdobramentoId(recursoDesdobramentoId: any) {
        return this.http.get<any>(environment.api + this.endpoint+ 'listar-calculos-valores-por-recurso-desdobramento-id/' + recursoDesdobramentoId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    calculosValoresAbertoPorRecursoDesdobramento(id: any) {
        return this.http.get<any>(environment.api + this.endpoint+ 'calculos-valores-por-recurso-desdobramento/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
