<div class="bg-secondary text-center text-white font-weight-bold p-3">
    {{textoQuantidadeNotificacoes}}
</div>
<div class="list-group list-group-flush">
    <div *ngFor="let item of notificacoes;let i=index">

        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center" (click)="abrirAtividade(item, item.id)">
            <div class="ui-icon ui-icon-sm ion ion-md-calendar bg-secondary border-0 text-white"></div>
            <div class="media-body line-height-condenced ml-3">
                <div class="text-body">{{item.titulo}}</div>
                <div class="text-light small mt-1">
                    {{item.mensagem}}
                </div>
                <div class="text-light small mt-1">{{item.dataCriacao}}</div>
            </div>
            
        </a>
    </div>
    <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">
        Ver todas as notificações
    </a>

</div>