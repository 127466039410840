import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProcessosJudiciaisOcorrenciaEventoService extends ServiceBase {
    
    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'ProcessoJudicialOcorrenciaEvento/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }
    
    add(data): Observable<any> {
      return this.http.post(environment.api + this.endpoint, data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    addComentario(data): Observable<any> {
        return this.http.post(`${environment.api}${this.endpoint}adicionar-comentario-evento/`, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
      }
    
    concluirOuRemoverConcluirDaTarefa(data: any) {
        return this.http.put(`${environment.api}${this.endpoint}definir-conclusao-evento`, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
      return this.http.get(environment.api + this.endpoint)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    obterDetalhesPorId(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'obter-detalhes-por-id/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getTarefasDoProcesso(processoId) {
        return this.http.get(`${environment.api}${this.endpoint}obter-eventos-do-processo/${processoId}`)
            .pipe(
                map(this.extractData), 
                catchError(this.serviceError)
            );
    }

    update(data): Observable<any> {
      return this.http.put(environment.api + this.endpoint , data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    enviarParaRevisao(id): Observable<any> {
        return this.http.put(environment.api + this.endpoint+ 'enviar-para-revisao/' + id , null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
      }
    
    delete(id): Observable<any> {
      return this.http.delete(environment.api + this.endpoint + id)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    alterarStatusImportanteComentario(comentarioId): Observable<any> {
        return this.http.put(`${environment.api}${this.endpoint}alterar-status-importante-comentario/${comentarioId}`, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    alterarDadosEmMassa(requisicao): Observable<any> {
        return this.http.put(`${environment.api}${this.endpoint}alterar-dados-em-massa/`, requisicao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
