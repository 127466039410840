import { CALCULATION_DATA_FORM_BINDING } from "./calculation.enum";

const itemsYesNo = [
    {
        label: 'Sim',
        value: true
    },
    {
        label: 'Não',
        value: false
    }
];

const itemsImpressao = [
    {
        label: 'Memória de cálculo completa ',
        value: 'M'
    },
    {
        label: 'Demonstrar a evolução mês a mês',
        value: 'DM'
    },
    {
        label: 'Diagramado em colunas',
        value: 'DC'
    }
]
export const dataTable = [
    {
        label: 'Nome',
        type: 'text',
        controlName: CALCULATION_DATA_FORM_BINDING.NOME,
        background: 'grey',
        class: 'w-100'
    },
    //
    {
        label: 'Descrição',
        type: 'textarea',
        controlName: CALCULATION_DATA_FORM_BINDING.DESC,
        background: 'white',
        class: 'w-100'
    },
    {
        label: 'Data de atualização',
        type: 'date',
        controlName: CALCULATION_DATA_FORM_BINDING.DATA_ATUALIZACAO,
        background: 'white',
        class: 'w-25'
    },
    //
    {
        label: 'Indice de correção',
        type: 'select',
        items: [],
        controlName: CALCULATION_DATA_FORM_BINDING.INDICE_ID,
        background: 'grey',
        class: 'w-25'
    },
    {
        label: 'Utilizar cálculo de atualização pró-rata:',
        type: 'radio',
        controlName: CALCULATION_DATA_FORM_BINDING.PRO_DATA,
        background: 'grey',
        class: 'w-25 p-0',
        items: itemsYesNo
    },
    //
    {
        label: 'Utilizar índices negativos:',
        type: 'radio',
        controlName: CALCULATION_DATA_FORM_BINDING.INDICE_NEGATIVOS,
        background: 'white',
        class: 'w-25 p-0',
        items: itemsYesNo
    },
    // {
    //     label: 'Como deseja a impressão do cálculo:',
    //     type: 'radio',
    //     controlName: 'impressaoCalculo',
    //     background: 'white',
    //     class: 'w-25 p-0',
    //     items: itemsImpressao
    // }
]