import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { PublicacaoService } from 'src/app/services/publicacao.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-importar',
    templateUrl: './importar.component.html',
    styleUrls: [
        './importar.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ]
})
export class ImportarComponent implements OnInit {

    public uploader: FileUploader;

    public loadAtach: boolean = true;

    private file: File;    

    public publicacoes:any;
    public publicacoesOriginal:any;

    sortBy = 'nomepesquisado';
    sortDesc = true;
    perPage = 8;
    currentPage = 1;
    totalItems = 0;

    get totalPages() {
        return Math.ceil(this.totalItems / this.perPage);
    }

    constructor(
        private location: Location,
        public publicacaoService:PublicacaoService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
    ) { 

        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            }
        });
    }

    ngOnInit(): void {
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {            
            this.file = (files[i] as File);
        }

        const fileInQueue = this.uploader.queue.find(q => q.file.name === files[0].name);
        
        if(fileInQueue != undefined){
            const newFile = new CustomFileLike(fileInQueue.file);
            fileInQueue.file = newFile;
        }
    }

    handleRemoveFile(item:FileItem){                
        this.uploader.queue = this.uploader.queue.filter(c=>c.file.name != item.file.name);
    }
    
    cancel(){
        this.location.back();
    }

    defineClass(classe: string) {
        return 'badge ' + classe;
    }

    paginate(data) {
        const perPage = parseInt(String(this.perPage), 10);
        const offset = (this.currentPage - 1) * perPage;

        return data.slice(offset, offset + perPage);
    }

    setSort(key) {
        if (this.sortBy !== key) {
            this.sortBy = key;
            this.sortDesc = false;
        } else {
            this.sortDesc = !this.sortDesc;
        }

        this.currentPage = 1;
        this.filter();
    }

    filter() {
        this.publicacoes = this.publicacoesOriginal;
        
        const data = this.publicacoes;
        this.totalItems = data.length;

        this.sort(data);
        this.publicacoes = this.paginate(data);
    }

    sort(data) {
        data.sort((a: any, b: any) => {
            a = typeof (a[this.sortBy]) === 'string' ? a[this.sortBy].toUpperCase() : a[this.sortBy];
            b = typeof (b[this.sortBy]) === 'string' ? b[this.sortBy].toUpperCase() : b[this.sortBy];

            if (a < b) { return this.sortDesc ? 1 : -1; }
            if (a > b) { return this.sortDesc ? -1 : 1; }
            return 0;
        });
    }

    limpar(){
        this.publicacoes = [];
        this.publicacoesOriginal = [];

        this.uploader.queue = [];

        this.loadAtach = true;
    }

    onSubmit(){
        this.toastrService.clear();
        this.spinner.show();

        let formData: FormData = new FormData();

        formData.append('file', this.file);

        this.publicacaoService.importacao(formData).subscribe(res=>{
            this.spinner.hide();
            
            this.toastrService.success("Importação realizada com sucesso!", "Sucesso");
            
            this.loadAtach = false;

            this.publicacoes = res.data;
            this.publicacoesOriginal = res.data;
            
            const data = this.publicacoes;
            this.totalItems = data.length;

            this.sort(data);
            this.publicacoes = this.paginate(data);

        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível fazer a importação!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    sincronizarPublicacoes(){
        this.toastrService.clear();
        this.spinner.show();

        this.publicacaoService.sincronizarPublicacoes().subscribe(res => {
            this.spinner.hide();
            this.toastrService.success("Sincronização realizada com sucesso!", "Sucesso");
            this.loadAtach = false;

            this.publicacoes = res.data;
            this.publicacoesOriginal = res.data;
            
            const data = this.publicacoes;
            this.totalItems = data.length;

            this.sort(data);
            this.publicacoes = this.paginate(data);


        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível fazer a importação!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        } )
    }
}
