<div class="row">
    <div class="col-12 margin-top top-100">
        <div class="row">
            <div class="col-12">
                <h1 class="title1">Área de trabalho</h1>
            </div>

            <div class="w-100">
                <app-header-filter
                [data]="dataHeaderFilter"
                [barChartData]="barChartData"
                [barChartLabels]="barChartLabels"
                [barChartOptions]="barChartOptions"
                [barChartPlugins]="barChartPlugins"
                [barChartLegend]="barChartLegend"
                [barChartType]="barChartType"
                barWidth="190px"
                barHeight="70px"
                [isAreaTrabalho]="true"
            >
            </app-header-filter>
            </div>

            <div class="mt-3 w-100">
                <app-header-filter
                    [data]="dataHeaderFilter2"
                    [hasChart]="false"
                    classe="justify-content-around"
                    [isAreaTrabalho]="true"
                >
                </app-header-filter>
            </div>
        </div>

        <div class="row mt-3">
            <div class="p-0 col-10 input-group box-busca">
                <button class="btn btn-outline-secondary btn-flag" type="button" (click)="exibirFiltro = !exibirFiltro" style="height: 2.86em">
                    <i class="fas fa-caret-down"></i>
                </button>
                <input type="text" class="form-control" placeholder="Digite o filtro" style="border-color: #8897AA"
                    (input)="onSearchChange($event.target.value)">
                <div ngbDropdown>
                    <button class="btn btn-outline-secondary dropdown-toggle btn-tipo-vinculo" type="button"
                        id="menu-filtro" ngbDropdownToggle>
                        Tipo de vínculo na atividade
                        <i class="fas fa-caret-down"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="menu-filtro">
                        <div class="dropdown-item cursor-pointer" (click)="filtrarPorTipoVinculo('executor', 'Responsavel')">
                            <i [ngClass]="gerenciarSelecaoFiltroTipoVinculo('executor')"></i>
                            Executor
                        </div>
                        <div class="dropdown-item cursor-pointer" (click)="filtrarPorTipoVinculo('revisor', 'Revisor')">
                            <i [ngClass]="gerenciarSelecaoFiltroTipoVinculo('revisor')"></i>
                            Revisor
                        </div>
                        <div class="dropdown-item cursor-pointer" (click)="filtrarPorTipoVinculo('assistente', 'Assistente')">
                            <i [ngClass]="gerenciarSelecaoFiltroTipoVinculo('assistente')"></i>
                            Assistente
                        </div>
                    </div>
                </div>
                <div ngbDropdown>
                    <button class="btn btn-outline-secondary dropdown-toggle btn-tipo" type="button"
                        id="menu-filtro-tipo" ngbDropdownToggle>
                        Tipo
                        <i class="fas fa-caret-down"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="menu-filtro-tipo">
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(1, 'Tarefa')">
                            <i [ngClass]="gerenciarSelecaoFiltroTipo(1)"></i>
                            Tarefa
                        </a>
                        <!-- <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(2)">
                            <i [ngClass]="gerenciarSelecaoFiltroTipo(2)"></i>
                            Histórico Manual
                        </a> -->
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(3, 'Evento')">
                            <i [ngClass]="gerenciarSelecaoFiltroTipo(3)"></i>
                            Evento
                        </a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(4, 'Prazo')">
                            <i [ngClass]="gerenciarSelecaoFiltroTipo(4)"></i>
                            Prazo
                        </a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(5, 'Audiência')">
                            <i [ngClass]="gerenciarSelecaoFiltroTipo(5)"></i>
                            Audiência
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-2 d-flex">
                <button class="ga-button ga-padding"
                    title="Visualizar Gridview"
                    tooltip-placement="bottom" tooltip-append-to-body="true" (click)="goToGridView()">
                    <i class="fa fa-table" aria-hidden="true"></i>
                </button>
                <button class="ga-button ga-padding" title="Ordenar..." tooltip-placement="bottom" (click)="ordenarDados()"
                    tooltip-append-to-body="true">
                    <i class="fa fa-sort-amount-down-alt" aria-hidden="true"></i>
                </button>
                <button class="ga-button ga-padding" title="Limpar Filtros" tooltip-placement="bottom"
                    tooltip-append-to-body="true" (click)="limparFiltros()">
                    <span class="material-symbols-outlined">filter_alt_off</span>
                </button>
            </div>
            <div class="card box-fload" *ngIf="exibirFiltro">
                <div class="card-body d-flex flex-column">
                    <div class="row mt-5">
                        <div class="col-6">
                            <label class="form-label">Data inicial</label>
                            <div class="input-group">
                                <input (click)="dataInicialFiltro.toggle()" class="form-control"
                                    placeholder="dd/mm/yyyy" name="dataInicialFiltroComponent" ngbDatepicker
                                    [(ngModel)]="dataInicialFiltroComponent" placement="bottom-right"
                                    #dataInicialFiltro="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="input-group-text" (click)="dataInicialFiltro.toggle()" type="button">
                                        <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Data final</label>
                            <div class="input-group">
                                <input (click)="dataFinalFiltro.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dataFinalFiltroComponent" ngbDatepicker [(ngModel)]="dataFinalFiltroComponent"
                                    placement="bottom-right" #dataFinalFiltro="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="input-group-text" (click)="dataFinalFiltro.toggle()" type="button">
                                        <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 mb-3">
                        <div class="row">
                            <div class="col-12 d-flex flex-wrap">
                                <div class="tag-filter" *ngFor="let option of options" (click)="changeDateFilterOption(option)" [ngClass]="{'selectedTagFilter': option.id === selectedTagFilter}">{{option.label}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-auto">
                        <button type="button" class="btn btn-primary float-right"
                            (click)="aplicarFiltros()">Aplicar</button>
                        <button type="button" class="btn btn-outline-primary float-right mr-2"
                            (click)="limparFiltros()">Limpar</button>
                        <button type="button" class="btn btn-outline-primary float-right mr-2"
                            (click)="exibirFiltro = false">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngFor="let filtro of filtersAppliedHeader; let index = i">
            <label *ngIf="filtro" class="ml-0 mt-3 label-filtro">Status: {{filtro.label}} <span class="filtro-remove" (click)="removeFilterApplied(filtro.id, filtro.typeFilter)"><i class="fas fa-window-close cursor-pointer"></i></span></label>
        </ng-container>

        <lista-atividades-mencionadas-usuario></lista-atividades-mencionadas-usuario>

        <hr />

        <div class="row" *ngIf="tarefasExibir == undefined || tarefasExibir.length == 0">
            Nenhum dado foi localizado!
        </div>

        <div class="row">
            <div class="col-6" *ngIf="tarefasExibir != undefined">
                <h5><strong>PENDENTE DA MINHA AÇÃO</strong></h5>
                <app-card-process *ngFor="let item of tarefasExibirMinhas;let i=index" [item]="item"
                    [resultConfirmDialog$]="resultConfirmDialog$"
                    (buscarTarefaDoUsuario)="recarregar(item)"
                    (changeRefresh)="refreshPage()">
                </app-card-process>
                <div class="col-12 pt-3" *ngIf="!tarefasExibirMinhas.length">
                    <h5>Não há dados à serem exibidos!</h5>
                </div>
                <!--<div class="col-12 carregar-mais" *ngIf="!!tarefasExibirMinhas.length" (click)="carregarMais()">
                    <i class="fas fa-arrow-circle-down"></i>Carregar mais
                </div>  -->
            </div>
            <div class="col-6" *ngIf="tarefasExibir != undefined">
                <h5><strong>DEPENDENTE DE OUTROS USUÁRIOS</strong></h5>
                <app-card-process *ngFor="let item of tarefasExibirOutros;let i=index" [item]="item"
                    [resultConfirmDialog$]="resultConfirmDialog$"
                    (buscarTarefaDoUsuario)="recarregar(item)"
                    (changeRefresh)="refreshPage()">
                </app-card-process>
                <div class="col-12 pt-3" *ngIf="!tarefasExibirOutros.length">
                    <h5>Não há dados à serem exibidos!</h5>
                </div>
                <!--<div class="col-12 carregar-mais" *ngIf="!!tarefasExibirOutros.length" (click)="carregarMais()">
                    <i class="fas fa-arrow-circle-down"></i>Carregar mais
                </div>  -->
            </div>

        </div>
    </div>
    <!-- <div class="col-12 col-sm-12 col-md-5 border-minha-atividade margin-top">
        <div class="col-12">
            <div class="row">
                <div class="col-7">
                    <div class="col-12">
                        <h3 color="gray" margin="md" class="minha-atividade">Minhas atividades</h3>
                    </div>
                    <div class="col-12 css-132o8hc">

                        <a type="button" class="e1cr19n20 css-1eh57tx-PlainButtonStyled enilzg62"
                            (click)="exibirGrafico = !exibirGrafico">
                            <span class="css-d26r8t-LinkSpan enilzg61" *ngIf="exibirGrafico">Ocultar</span>
                            <span class="css-d26r8t-LinkSpan enilzg61" *ngIf="!exibirGrafico">Exibir</span>
                            <i class="fa fa-angle-up css-1j7d5jt-Icon enilzg64" *ngIf="exibirGrafico"
                                content="Ocultar"></i>
                            <i class="fa fa-angle-down css-1j7d5jt-Icon enilzg64" *ngIf="!exibirGrafico"
                                content="Exibir"></i>
                        </a>
                        <div class="css-ut5fli e1ovr2wm1"></div>


                    </div>

                </div>
                <div class="col-5" *ngIf="isRevisorOrAssistenteOrExecutor()">
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="selectedOption" (change)="changeOption()">
                            <mat-option *ngFor="let option of options" [value]="option.id">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-12" *ngIf="!isRevisorOrAssistenteOrExecutor() && (exibirGrafico && !!tarefasOriginal.length)">
            <div class="row">
                <div class="col-4">
                    <div role="button" class="css-122l6va e1mhp44i0">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividadesConcluidas()">
                            {{quantidadeAtividadesConcluida}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividadesConcluidas()">Concluídas</label>
                    </div>
                </div>
                <div class="col-4">
                    <div role="button" class="css-122l6va e1mhp44i0">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividadesAtasadas()">
                            {{quantidadeAtividadesEmAtraso}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividadesAtasadas()">Atrasadas</label>
                    </div>
                </div>
                <div class="col-4">
                    <div role="button" class="css-122l6va e1mhp44i0">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividadesAConcluir()">
                            {{quantidadeAtividadesNaoConcluida}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividadesAConcluir()">A concluir</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12" *ngIf="isRevisorOrAssistenteOrExecutor() && (exibirGrafico && !!tarefasOriginal.length)">
            <div class="row justify-content-center">
                <div class="col-3 mb-4 hver">
                    <div role="button" class="css-122l6va e1mhp44i0 p-2">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividades()">
                            {{filtrarAtividades('emRevisao', true)}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividades()">Não Iniciadas</label>
                    </div>
                </div>

                <div class="col-3 hver">
                    <div role="button" class="css-122l6va e1mhp44i0 p-2">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividades()">
                            {{filtrarAtividades('emRevisao', true)}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividades()">Em Execução</label>
                    </div>
                </div>

                <div class="col-3 hver">
                    <div role="button" class="css-122l6va e1mhp44i0 p-2">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividades('emRevisao')">
                            {{filtrarAtividades('emRevisao', true)}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividades('emRevisao')">Em Revisão</label>
                    </div>
                </div>

                <div class="col-3 hver">
                    <div role="button" class="css-122l6va e1mhp44i0 p-2">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividades()">
                            {{filtrarAtividades('emRevisao', true)}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividades()">Devolvida para
                            Ajustes</label>
                    </div>
                </div>

                <div class="col-3 hver">
                    <div role="button" class="css-122l6va e1mhp44i0 p-2">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividades()">
                            {{filtrarAtividades('emRevisao', true)}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividades()">Revisado e Liberado</label>
                    </div>
                </div>

                <div class="col-3 hver">
                    <div role="button" class="css-122l6va e1mhp44i0 p-2">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividades('concluido')">
                            {{filtrarAtividades('concluido', true)}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividades('concluido')">Concluida
                            (Protoocolo)</label>
                    </div>
                </div>

                <div class="col-3 hver">
                    <div role="button" class="css-122l6va e1mhp44i0  p-2">
                        <p class="css-1h62zbb e1mhp44i1" (click)="filtrarAtividades()">
                            {{filtrarAtividades('emRevisao', true)}}</p>
                        <label class="css-13hfmhj e1mhp44i3" (click)="filtrarAtividades()">Encerrado</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="exibirGrafico">
            <div style="display: block;">
                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                    [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" height="200"
                    width="323">
                </canvas>
            </div>
        </div>

    </div> -->

</div>
