<div class="row">
    <div class="col-12">
        <h6>Tarefa ({{index}})</h6>
    </div>
    <div class="col-6 mb-2">
        <label class="form-label">Classificação atividade<span class="required-field">*</span></label>
        <ng-select [items]="classificacoesAtividades" [(ngModel)]="tarefa.classificacaoAtividadeId" [ngModelOptions]="{standalone: true}" (change)="classificacaoPrioridadeChange()"
            bindLabel="classificacao" bindValue="id" [loading]="classificacoesAtividades == undefined || classificacoesAtividades.length==0"></ng-select>
    </div>
    <div class="col-6 mb-2">
        <label class="form-label">Sub-tipo de classificação atividade<span class="required-field">*</span></label>
        <ng-select [items]="subtiposAtividade" [(ngModel)]="tarefa.classificacaoSubtipoAtividadeId" [ngModelOptions]="{standalone: true}"
            bindLabel="subtipoClassificacao" bindValue="id"></ng-select>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Descrição<span class="required-field">*</span></label>
        <textarea class="form-control" placeholder="Digite a descrição" rows="2"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="tarefa.descricao"></textarea>
    </div>
    <div class="col-6 mb-2" *ngIf="tarefa.dataInicioPrazo != undefined">
        <label class="form-label">Data da publicação</label>
        <p>{{tarefa.dataPublicacao | date: 'dd/MM/yyyy'}}</p>
    </div>
    <div class="col-6 mb-2" *ngIf="tarefa.dataInicioPrazo != undefined">
        <label class="form-label">Data início do prazo</label>
        <p>{{tarefa.dataInicioPrazo | date: 'dd/MM/yyyy'}}</p>
    </div>
    <div class="col-2 mb-2" *ngIf="publicacao != undefined">
        <label class="form-label">Prazo</label>
        <input type="number" min="0" (change)="atualizaDataTarefa()" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="tarefa.prazoQuantidade">
    </div>
    <div class="col-10 mb-2" *ngIf="publicacao != undefined">
        <label class="form-label">&nbsp;</label>
        <ng-select [items]="tipoPrazo" [(ngModel)]="tarefa.tipoPrazo" (change)="atualizaDataTarefa()" [ngModelOptions]="{standalone: true}"
            bindLabel="nome" bindValue="id" [loading]="loadTipoAlerta"></ng-select>
    </div>
    <div class="col-4">
        <label class="form-label">Data*</label>
        <div class="input-group">
            <input (click)="datatarefa.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataTarefaComponent" ngbDatepicker [(ngModel)]="tarefa.dataTarefaComponent"
                placement="bottom-right"
                #datatarefa="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="datatarefa.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-8">
        <label class="form-label">Prioridade <span class="required-field">*</span></label>
        <ng-select [items]="tipoPrioridade" [(ngModel)]="tarefa.prioridade"
            [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
            [loading]="loadTipoPrioridade"></ng-select>
    </div>   
    <!-- <div class="col-8">
        <label class="form-label">Lista de tarefas<span class="required-field ml-1">*</span></label>
        <ng-select [items]="tipoTarefas" [(ngModel)]="tarefa.tipoTarefaId"
            [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" [loading]="loadTipoTarefas">
        </ng-select>
    </div> -->
    <div class="col-12 mb-2">
        <label class="form-label">Equipe <i class="fas fa-info-circle" ngbTooltip="Por favor, selecione uma equipe para que os membros padrão sejam carregados automaticamente nos campos abaixo"></i></label>
        <equipe-dropdown [(equipeId)]="equipeId" (change)="onChangeEquipe($event)"></equipe-dropdown>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Executor <span class="required-field">*</span></label>        
        <pessoa-dropdown #executorDropdown [(pessoaId)]="tarefa.responsavelId"></pessoa-dropdown> 
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Revisor <span class="required-field">*</span></label>        
        <pessoa-dropdown #revisorDropdown [(pessoaId)]="tarefa.revisorId"></pessoa-dropdown> 
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Assistente <span class="required-field">*</span></label>        
        <pessoa-dropdown #assistenteDropdown [(pessoaId)]="tarefa.assistenteId"></pessoa-dropdown> 
    </div>     
</div>