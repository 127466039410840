import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashService } from 'src/app/services/dash.service';

@Component({
  selector: 'app-chart-clientes-nao-enviam-borderos',
  templateUrl: './chart-clientes-nao-enviam-borderos.component.html',
  styleUrls: ['./chart-clientes-nao-enviam-borderos.component.css']
})
export class ChartClientesNaoEnviamBorderosComponent implements OnInit {

  load = false;
  cliente: any [] = [];

  constructor(
    private dashService: DashService,
  ) { }


  ngOnInit(): void {
    this.getChartBorderosNaoEnviados();
  }


  getChartBorderosNaoEnviados() {
    this.load = true;
    this.dashService.getDashBorderosNaoEnviados().subscribe(x => {

      this.cliente = x.data;
      this.load = false;
    });
  }

}
