import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { CalendarioService } from "src/app/services/calendario.service";
import { DatePipe } from "@angular/common";
import { ProcessosJudiciaisOcorrenciasService } from "src/app/services/processos-judiciais-ocorrencias.service";
import { ToastrService } from "ngx-toastr";

export enum RescheduleType {
  TODAY = "today",
  TOMORROW = "tomorrow",
  SOMEDAY = "someDay",
  NEXT_MONDAY = "nextMonday",
}

@Component({
  selector: "app-opcoes-reagendamento",
  templateUrl: "./opcoes-reagendamento.component.html",
  styleUrls: ["./opcoes-reagendamento.component.scss"],
})
export class OpcoesReagendamentoComponent implements OnInit {
  public readonly RESCHEDULE_TYPE = RescheduleType;
  public rescheduleDay: Date = null;
  public today = new Date();
  public dataRevisaoComponent;
  public dataLimiteComponent;
  @Input('item') item: any;
  @Output() dateUpdate = new EventEmitter();

  constructor(private calendarioService: CalendarioService, public datepipe: DatePipe, private toastrService: ToastrService, private ocorrenciaService: ProcessosJudiciaisOcorrenciasService) {}
  ngOnInit(): void {
    console.log(this.item);
  }

  get tomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(this.today.getDate() + 1);
    return tomorrow;
  }

  get todayFormatted() {
    const today = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
    return today;
}

  get nextMonday() {
    const dayOfWeek = this.today.getDay();
    const daysUntilNextMonday = dayOfWeek === 0 ? 1 : 8 - dayOfWeek;
    const nextMonday = new Date(this.today);
    nextMonday.setDate(this.today.getDate() + daysUntilNextMonday);
    return nextMonday;
  }

  saveDate(type: string, event) {
    debugger;
    console.log(this.item);

    switch (type) {
      case RescheduleType.TODAY:
        this.rescheduleDay = this.today;
        break;
      case RescheduleType.TOMORROW:
        this.rescheduleDay = this.tomorrow;
        break;
    case RescheduleType.SOMEDAY:
        const dateSchedule = (event as MatDatepickerInputEvent<Date>).value;
        this.rescheduleDay = dateSchedule;
        break;
      case RescheduleType.NEXT_MONDAY:
        this.rescheduleDay = this.nextMonday;
        break;
    }
    this.calendarioService
      .calcularDiaUtilNegativo(-3, this.rescheduleDay)
      .subscribe((res) =>{
        this.formatDateComponent(new Date(res.data), this.rescheduleDay)
        const atividade = {
            id: this.item.id,
            dataRevisao: this.dataRevisaoComponent,
            dataLimite: this.dataLimiteComponent,
            tipoOcorrencia: this.item.tipoOcorrencia
        }
        this.ocorrenciaService.atualizarDataAtividade(atividade).subscribe(res => {
            this.dateUpdate.emit(atividade);
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível alterar data!', 'Atenção', { timeOut: 10000 });
            }
        });
  });

  }

  formatDateComponent(reviewDate: Date, deadline: Date) {
    this.dataRevisaoComponent = new Date(reviewDate.getFullYear(), reviewDate.getMonth() , reviewDate.getDate())
    this.dataLimiteComponent = new Date(deadline.getFullYear(), deadline.getMonth() , deadline.getDate())

  }
}
