<ng-container *ngFor="let comentario of comentarios">
    <div class="row panel-commentarios">
        <div class="col-12">
            <i class="fas fa-user"></i> {{comentario.pessoaComentarioNome}} | {{comentario.dataComentario | date: 'dd/MM/yyyy'}} às {{comentario.dataComentario | date: 'HH:mm'}}
            <br /><br />
            <p>
                <i class="fas fa-comment"></i> Comentário: {{comentario.comentario}}
            </p>
        </div>      
        <div class="col-12" *ngIf="comentario.arquivos != undefined && comentario.arquivos != null && comentario.arquivos.length > 0">
            <i class="fas fa-paperclip"></i> Anexos:
            <div class="anexo d-flex">
                <div class="btn btn-primary" *ngFor="let arquivo of comentario.arquivos" [ngbTooltip]="arquivo.nomeArquivo" (click)="handleDownloadArquivo(arquivo)">
                    Download <i class="btn btn-xs icon-btn btn-primary" ><span class="fas fa-download"></span></i>
                </div>
            </div>            
        </div>                  
    </div>
    <hr />          
</ng-container>