<div class="authentication-wrapper authentication-2 px-4">
    <div class="authentication-inner py-5">

        <!-- Form -->
        <form class="card">
            <div class="p-4 p-sm-5">

                <!-- Logo -->
                <div class="d-flex justify-content-center align-items-center pb-2 mb-4">
                    <div class="ui-w-60">
                        <div class="w-100 position-relative" style="padding-bottom: 54%">
                            <img src="assets/img/logo-cdd-sem-fundo.png" style="background-color:#fff">
                        </div>
                    </div>
                </div>
                <!-- / Logo -->

                <h5 class="text-center text-muted font-weight-normal mb-4">Redefina sua senha</h5>

                <hr class="mt-0 mb-4">

                <p>
                    Coloque seu o e-mail e será enviado uma nova senha no seu e-mail.
                </p>

                <div class="form-group">
                    <input [(ngModel)]="email" name="email" type="text" class="form-control"
                        placeholder="Digite o seu E-mail.">
                </div>

                <div class="form-group">
                    <button type="button" class="btn btn-block btn-padrao" (click)="recuperarSenha()" *ngIf="!requisicaoSendoFeita">enviar</button>
                    <button class="btn btn-block btn-padrao" type="button" disabled *ngIf="requisicaoSendoFeita">
                        <span class="spinner-border" role="status" aria-hidden="true"></span>
                        Enviando...
                    </button>
                    <button type="button" class="btn btn-block btn-default" (click)="voltar()">voltar</button>
                </div>

            </div>
        </form>
        <!-- / Form -->

    </div>
</div>