<!-- [ngbTooltip]="
  'Caso esteja selecionado, serão exibidos os processos/casos que CONTÉM os ' +
  partOfTooltip +
  ' selecionados. Caso contrário serão exibidos os processos/casos que NÃO CONTÉM os ' +
  partOfTooltip +
  ' selecionados'
" -->
<!-- [ngbTooltip]="model ? 'Não Contém' : 'Contém'" -->
<label class="form-label w-100 d-flex align-items-center {{ classLabel }}">
  <span>{{ label }}</span>

  <!-- <mat-chip-list aria-label="selection" class="ml-2">
    <mat-chip class="cursor-pointer" (click)="model = true" [selected]="model">
      <small>Contém</small>
    </mat-chip>

    <mat-chip
      class="cursor-pointer"
      (click)="model = false"
      [selected]="model === false"
    >
      <small>Não Contém</small>
    </mat-chip>
  </mat-chip-list> -->

  <mat-slide-toggle
    class="ml-2"
    [(ngModel)]="model"
    (change)="change.emit(model)"
  ><small>{{!model ? 'Não Contém' : 'Contém'}}</small></mat-slide-toggle>
</label>
