<h4
  class="d-flex justify-content-between align-items-center font-weight-bold pt-3"
  *ngIf="!loadProcesso"
>
  <span
    ><span class="text-muted font-weight-light">
      Operacionais / Processos Judiciais e Casos /</span
    >
    <span>Detalhes</span> {{ processo.caso ? "Caso" : "Processo" }}</span
  >
</h4>
<div class="container-fluid mb-5" *ngIf="!loadProcesso">
  <div class="row">
    <div class="col-8">
      <h1>{{ processo.titulo }}</h1>
      <div
        *ngFor="let tag of processo.tags"
        class="mr-2 mt-2 tag-topo"
        [ngClass]="defineClass(tag.tagCor)"
      >
        {{ tag.tagNome }} &nbsp;<i
          class="fas fa-times cursor-pointer"
          (click)="removerTag(tag)"
        ></i>
      </div>
    </div>
    <div class="col d-flex justify-content-end">
      <button
        type="button"
        (click)="cancel()"
        class="btn btn-primary mr-4 btn-atalhos-topo"
      >
        <span class="fas fa-arrow-left"></span>
      </button>

      <button
        type="button"
        class="btn btn-primary mr-2 btn-favorito btn-atalhos-topo"
      >
        <span class="fas fa-star"></span>
      </button>

      <button
        type="button"
        (click)="handleAdicionarTag()"
        class="btn btn-primary mr-2 btn-atalhos-topo"
      >
        <span class="fas fa-tag"></span>
      </button>

      <div ngbDropdown class="d-inline-block mr-2" placement="bottom-right">
        <button
          type="button"
          class="btn btn-primary btn-atalhos-topo"
          id="dropdownMenuOpcoesTopo"
          ngbDropdownToggle
        >
          <span class="fas fa-ellipsis-v"></span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownMenuOpcoesTopo">
          <a
            ngbDropdownItem
            class="cursor-pointer"
            (click)="handleAdicionarApensos()"
            >Vincular outro caso/processo a este</a
          >
          <a
            ngbDropdownItem
            class="cursor-pointer"
            (click)="handleAdicionarApensos()"
            >Vincular este {{ processo.caso ? "caso" : "processo" }} a outro</a
          >
          <a ngbDropdownItem class="cursor-pointer" *ngIf="!processo.caso"
            >Alterar instância atual</a
          >
          <a ngbDropdownItem class="cursor-pointer" *ngIf="!processo.caso"
            >Desvincular do tribunal</a
          >
          <a ngbDropdownItem class="cursor-pointer" [disabled]="true"
            >Imprimir relatório</a
          >
          <a
            ngbDropdownItem
            class="cursor-pointer"
            *ngIf="processo.caso"
            (click)="converterEmProcesso()"
            >Converter em processo</a
          >
          <div class="dropdown-divider"></div>
          <a [routerLink]="['../../editar/', processo.id]" ngbDropdownItem
            >Editar</a
          >
          <a
            (click)="migrarProcessoParaAmigavel()"
            ngbDropdownItem
            *ngIf="
              processo.processoAmigavelId != undefined &&
              processo.processoAmigavelId != null
            "
            >Migrar para amigável</a
          >
          <a
            (click)="encerrarProcesso()"
            ngbDropdownItem
            *ngIf="!processo.encerrado"
            >Encerrar</a
          >
          <a
            (click)="reativarProcesso()"
            ngbDropdownItem
            *ngIf="processo.encerrado"
            >Reativar</a
          >
          <a
            (click)="encerrarTodosRecursosDesdobramentos()"
            ngbDropdownItem
            >Encerrar Todos Recursos/Desdobramentos</a
          >
        </div>
      </div>

      <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button
          type="button"
          class="btn btn-primary btn-atalhos-topo"
          id="dropdownMenuAdicionarTopo"
          ngbDropdownToggle
        >
          <span class="fas fa-plus"></span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownMenuAdicionarTopo">
          <a
            ngbDropdownItem
            routerLink="{{ raiz }}/atendimento/criar-atendimento/1/{{ id }}"
            >Atendimento</a
          >
          <a
            ngbDropdownItem
            routerLink="{{ raiz }}/honorario-por-valor/1/{{ id }}"
            >Honorário</a
          >
          <a
            ngbDropdownItem
            routerLink="{{ raiz }}/honorario-por-percentual/1/{{ id }}"
            >Honorário com % no êxito</a
          >
          <a ngbDropdownItem>Despesa</a>
          <a ngbDropdownItem>Timesheet</a>
          <div class="dropdown-divider"></div>
          <a
            ngbDropdownItem
            routerLink="{{ raiz }}/documentos/documentos-cadastro/1/{{ id }}"
            >Documento</a
          >
          <a ngbDropdownItem *ngIf="!processo.caso"
            >Documento do Google Drive</a
          >
          <a ngbDropdownItem *ngIf="!processo.caso">Documento padrão</a>
          <a ngbDropdownItem *ngIf="!processo.caso"
            >Documento padrão do Google Drive</a
          >
          <a ngbDropdownItem *ngIf="!processo.caso">Link</a>
          <div class="dropdown-divider"></div>
          <a
            ngbDropdownItem
            *ngIf="!processo.caso"
            routerLink="{{ raiz }}/processos-judiciais-recursos/{{ id }}'"
            >Recurso</a
          >
          <a
            ngbDropdownItem
            *ngIf="!processo.caso"
            routerLink="{{ raiz }}/processos-judiciais-desdobramento/{{ id }}"
            >Desdobramento</a
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="row mt-2"
    *ngIf="
      processo.codigoInternoAdvNet != undefined &&
      processo.codigoInternoAdvNet != null
    "
  >
    <div class="col">
      ID do {{ processo.caso ? "Caso" : "Processo" }} |
      {{ processo.codigoInternoAdvNet }}
    </div>
  </div>
  <div class="row mt-2" *ngIf="processo.pasta != undefined">
    <div class="col">Pasta | {{ processo.pasta }}</div>
  </div>
  <div class="row mt-2">
    <div class="col">
      Cliente |
      <span *ngFor="let cliente of processo.clientes; let isLast = last"
        >{{ cliente.nome }}{{ isLast ? "" : ", " }}</span
      >
    </div>
  </div>
  <div
    class="row mt-2"
    *ngIf="
      processo.codigoExternoDevedor != undefined &&
      processo.codigoExternoDevedor != null
    "
  >
    <div class="col">Código Devedor | {{ processo.codigoExternoDevedor }}</div>
  </div>
  <div class="row mt-2">
    <div class="col">Situação e Base <span [innerHTML]="obterSituacaoEBase()"></span></div>
  </div>
  <div class="row mt-2">
    <div class="col">Responsável | {{ processo.responsavelNome }}</div>
  </div>

  <ul
    ngbNav
    #navProcesso="ngbNav"
    [(activeId)]="navProcessoSelecionada"
    class="nav-tabs mt-4"
    (navChange)="onNavChange($event)"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{ processo.caso ? "Caso" : "Processo" }}</a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-center" *ngIf="loadProcesso">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="row" *ngIf="!loadProcesso">
          <div class="col-8">
            <div class="card mt-2" id="dadosDiv">
              <div
                class="card-body"
                (mouseover)="hideButtonsDadosProcesso = false"
                (mouseleave)="hideButtonsDadosProcesso = true"
              >
                <div class="row">
                  <div class="col-11">
                    <h5 class="card-title d-flex align-items-center">
                        <i class="fa fa-id-card mr-2"></i>
                        Dados do {{ processo.caso ? "Caso" : "Processo Principal" }}
                    </h5>
                  </div>
                  <div class="col-1" *ngIf="!hideButtonsDadosProcesso">
                    <div
                      class="btn-diversos"
                      routerLink="{{ raiz }}/processos-judiciais/editar/{{
                        processo.id
                      }}"
                    >
                      <i class="far fa-edit"></i>
                    </div>
                  </div>
                </div>
                <ng-container>
                    <div class="row" *ngIf="processo.acaoNome != null">
                      <div class="col-3">Ação</div>
                      <div class="col">
                        <copiar-component
                          texto="{{ processo.acaoNome }}"
                        ></copiar-component>
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="processo.numero != null">
                      <div class="col-3">Número (CNJ)</div>
                      <div class="col">
                        <copiar-component
                          texto="{{ processo.numero }}"
                        ></copiar-component>
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="processo.outrosNumeros.length > 0">
                        <div class="col-12 col-lg-3">Outros Números</div>
                        <div class="col-12 col-lg-9">
                            <ul>
                                <li *ngFor="let outroNumero of processo.outrosNumeros">
                                    <copiar-component texto="{{ outroNumero.numero }}"></copiar-component>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ng-container *ngIf="processo.caso">
                      <div class="row mt-2">
                        <div class="col-3">Observação</div>
                        <div class="col">{{ processo.observacao }}</div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-3">Descrição</div>
                        <div class="col">{{ processo.descricao }}</div>
                      </div>
                    </ng-container>
                    <!-- <div class="row mt-2" *ngIf="processo.juizo != null">
                                        <div class="col-3">Juízo</div>
                                        <div class="col">
                                            <copiar-component texto="{{ processo.juizo }}"></copiar-component>
                                        </div>
                                    </div> -->
                    <div
                      class="row mt-2"
                      *ngIf="processo.valorCausa != null && !processo.caso"
                    >
                      <div class="col-3">Valor da causa</div>
                      <div class="col">
                        {{ processo.valorCausa | currency : "BRL" }}
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="processo.distribuidoEm != null">
                      <div class="col-3">Data de distribuição</div>
                      <div class="col">
                        {{ processo.distribuidoEm | date : "dd/MM/yyyy" }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-3">
                        {{ processo.caso ? "Tipo do Caso" : "Tipo do Processo" }}
                      </div>
                      <div class="col">
                        {{ !processo.processoEletronico ? "Físico" : "Eletrônico" }}
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="processo.processoEletronico">
                      <div class="col-3">Processo eletrônico</div>
                      <div class="col">
                        {{ processo.tipoProcessoEletronicoNome }}
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="processo.linkTribunal != null">
                      <div class="col-3">Link</div>
                      <div class="col">
                        <a href="{{ processo.linkTribunal }}" target="_blank">{{
                          processo.linkTribunal
                        }}</a>
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="!processo.caso">
                        <div class="col-3">Competência</div>
                        <div class="col">
                          {{ processo.competenciaNome ? processo.competenciaNome : "-" }}
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="!processo.caso">
                        <div class="col-3">Instância Principal</div>
                        <div class="col">
                          {{ processo.instanciaNome ? processo.instanciaNome : "-" }}
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="!processo.caso">
                        <div class="col-3">Comarca e UF</div>
                        <div class="col">
                          {{ processo.comarcaNome ? processo.comarcaNome : "-" }} | {{ processo.ufSigla ? processo.ufSigla : "-" }}
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="!processo.caso">
                        <div class="col-3">Seção Judiciária</div>
                        <div class="col">
                          {{ processo.divisaoDeOrgaoNome ? processo.divisaoDeOrgaoNome : "-" }} |
                          {{ processo.orgaoNome ? processo.orgaoNome : "-" }} |
                          {{ processo.foroNome ? processo.foroNome : "-" }}
                        </div>
                    </div>
                    <!-- <div class="row mt-2"
                                        *ngIf="processo.codigoInternoAdvNet != undefined && processo.codigoInternoAdvNet != null">
                                        <div class="col-3">ID do {{processo.caso ? 'Caso':'Processo'}}</div>
                                        <div class="col-5">
                                            {{processo.codigoInternoAdvNet}}
                                        </div>
                                    </div> -->
                    <!-- <div class="row mt-2" *ngIf="processo.codigoInternoAdvNet != undefined && processo.codigoInternoAdvNet != null">
                                        <div class="col-3">Código Interno Advnet</div>
                                        <div class="col-5">
                                            {{processo.codigoInternoAdvNet}}
                                        </div>
                                    </div> -->
                    <!-- <div class="row mt-2">
                      <div class="col-3">Código Externo Devedor</div>
                      <div
                        class="col-5"
                        *ngIf="processo.codigoExternoDevedor != null"
                      >
                        {{ processo.codigoExternoDevedor }}
                      </div>
                      <div
                        class="col-5 input-group"
                        *ngIf="processo.codigoExternoDevedor == null"
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Codigo Externo Devedor"
                          [(ngModel)]="codigoExternoDevedor"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-primary"
                            (click)="setCodigoExternoDevedor()"
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div> -->
                    <div
                      class="row mt-2"
                      *ngIf="
                        processo.processoAmigavelCodigo != undefined &&
                        processo.processoAmigavelCodigo != null
                      "
                    >
                      <div class="col-3">Processo Amigável:</div>
                      <div
                        class="col cursor-pointer"
                        (click)="abrirModalAmigavel()"
                      >
                        {{ processo.processoAmigavelCodigo }}
                        <i class="fas fa-external-link-square-alt color-info"></i>
                      </div>
                    </div>
                    <hr />
                    <div class="row mt-2" *ngFor="let cliente of processo.clientes">
                      <div class="col-3">{{ cliente.qualificacaoNome }}</div>
                      <div class="col">
                        <i
                          class="fas fa-eye cursor-pointer"
                          (click)="showModalPessoa(cliente.pessoaId)"
                        ></i>
                        <copiar-component
                          texto="{{ cliente.nome }}"
                        ></copiar-component>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      *ngFor="let envolvido of processo.envolvidos"
                    >
                      <div class="col-3">{{ envolvido.qualificacaoNome }}</div>
                      <div class="col">
                        <i
                          class="fas fa-eye cursor-pointer"
                          (click)="showModalPessoa(envolvido.pessoaId)"
                        ></i>
                        <copiar-component
                          texto="{{ envolvido.nome }}"
                        ></copiar-component>
                      </div>
                    </div>

                    <hr
                      *ngIf="
                        processo.revisorNome != null ||
                        processo.administrativoNome != null
                      "
                    />
                    <div class="row mt-2" *ngIf="processo.revisorNome != null">
                      <div class="col-3">Revisor</div>
                      <div class="col">{{ processo.revisorNome }}</div>
                    </div>
                    <div
                      class="row mt-2"
                      *ngIf="processo.administrativoNome != null"
                    >
                      <div class="col-3">Administrativo</div>
                      <div class="col">{{ processo.administrativoNome }}</div>
                    </div>
                    <hr
                      *ngIf="
                        !processo.caso &&
                        processo.possibilidadePerdaDescricao != undefined
                      "
                    />

                </ng-container>
                <!-- <hr /> -->
                <div class="mt-3">
                  <h5 class="card-title d-flex align-items-center">
                    <i class="fa fa-id-card mr-2"></i> Mostrar mais dados do {{ processo.caso ? "Caso" : "Processo" }}
                  </h5>
                  <div>
                    <!-- <div class="row mt-2" *ngIf="processo.acaoNome">
                        <div class="col-3">Ação</div>
                        <div class="col align-self-center">{{processo.acaoNome}}</div>
                    </div> -->
                    <!-- <div class="row mt-2" *ngIf="processo.distribuidoEm">
                        <div class="col-3">Distribuição</div>
                        <div class="col align-self-center">{{processo.distribuidoEm | date:'dd/MM/yyyy'}}</div>
                    </div> -->
                    <!-- <div class="row mt-2">
                        <div class="col-3">Valor da Causa</div>
                        <div class="col align-self-center">{{processo.valorCausa | currency: 'BRL'}}</div>
                    </div> -->
                    <div class="row mt-2" *ngIf="divOrgaoDesc">
                      <div class="col-3">Div. Órgão</div>
                      <div class="col align-self-center">
                        {{ divOrgaoDesc }}
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="orgaoDesc">
                      <div class="col-3">Órgão</div>
                      <div class="col align-self-center">{{ orgaoDesc }}</div>
                    </div>
                    <!-- <div class="row mt-2">
                        <div class="col-3">Tipo de Processo</div>
                        <div class="col align-self-center">{{processo.processoEletronico ? 'Eletrônico': 'Físico'}}</div>
                    </div> -->
                    <!-- <div class="row mt-2" *ngIf="processo.tipoProcessoEletronicoNome">
                        <div class="col-3">Processo Eletrônico</div>
                        <div class="col align-self-center">{{processo.tipoProcessoEletronicoNome}}</div>
                    </div>
                    <div class="row mt-2" *ngIf="processo.linkTribunal">
                        <div class="col-3">Link no Tribunal</div>
                        <div class="col align-self-center">{{processo.linkTribunal}}</div>
                    </div> -->
                    <div class="row mt-2">
                      <div class="col-3">Acesso</div>
                      <div class="col align-self-center">
                        {{ processo.tipoAcesso }}
                      </div>
                    </div>
                    <div
                      class="row mt-2"
                      *ngIf="
                        !processo.caso &&
                        processo.possibilidadePerdaDescricao != undefined
                      "
                    >
                      <div class="col-12">
                        <b>Possibilidade de perda de processo</b>
                      </div>
                      <br /><br />
                      <div class="col-12">
                        {{
                          processo.possibilidadePerdaDescricao == undefined ||
                          processo.possibilidadePerdaDescricao == null
                            ? "Não informado"
                            : processo.possibilidadePerdaDescricao
                        }}
                      </div>
                      <br /><br />
                      <div class="col-12">
                        Justificativa:
                        {{
                          processo.justificativaPossibilidadePerda ==
                            undefined ||
                          processo.justificativaPossibilidadePerda == null
                            ? "-"
                            : processo.justificativaPossibilidadePerda
                        }}
                      </div>
                    </div>
                    <hr
                      *ngIf="
                        !processo.caso &&
                        processo.possibilidadeNaoRecebimentoCreditoDescricao !=
                          undefined
                      "
                    />
                    <div
                      class="row mt-2"
                      *ngIf="
                        !processo.caso &&
                        processo.possibilidadeNaoRecebimentoCreditoDescricao !=
                          undefined
                      "
                    >
                      <div class="col-12">
                        <b>Possibilidade de não recebimento do crédito</b>
                      </div>
                      <br /><br />
                      <div class="col-12">
                        {{
                          processo.possibilidadeNaoRecebimentoCreditoDescricao ==
                            undefined ||
                          processo.possibilidadeNaoRecebimentoCreditoDescricao ==
                            null
                            ? "Não informado"
                            : processo.possibilidadeNaoRecebimentoCreditoDescricao
                        }}
                      </div>
                      <br /><br />
                      <div class="col-12">
                        Justificativa:
                        {{
                          processo.justificativaNaoRecebimentoCredito ==
                            undefined ||
                          processo.justificativaNaoRecebimentoCredito == null
                            ? "-"
                            : processo.justificativaNaoRecebimentoCredito
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <hr /> -->
              </div>
            </div>

            <div class="card mt-2" id="casosDiv">
              <div
                class="card-body"
                (mouseover)="hideButtonsApensos = false"
                (mouseleave)="hideButtonsApensos = true"
              >
                <div class="row">
                  <div class="col-10">
                    <h5
                        class="card-title d-flex cursor-pointer align-items-center"
                        (click)="showProcessLinked = !showProcessLinked"
                    >
                        <i class="fas fa-link mr-2"></i> Casos/Processos vinculados
                        <span class="material-symbols-outlined">{{
                            showProcessLinked ? "expand_less" : "expand_more"
                        }}</span>
                    </h5>
                  </div>
                  <div
                    [ngClass]="
                      !hideButtonsApensos
                        ? 'col-2 d-flex flex-row-reverse'
                        : 'col-2'
                    "
                    [hidden]="hideButtonsApensos"
                  >
                    <div
                      class="btn-adicionar"
                      (click)="handleAdicionarApensos()"
                    >
                      <i class="fas fa-plus"></i>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center" *ngIf="loadApensos">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <ng-container *ngIf="showProcessLinked">
                    <div clas="row">
                      <div
                        class="col-12"
                        *ngIf="
                          !loadApensos &&
                          (apensos == null ||
                            apensos == undefined ||
                            apensos.length == 0)
                        "
                      >
                        Este processo não possui vínculos e não está vinculado a
                        outro.
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="
                        !loadApensos &&
                        apensos != null &&
                        apensos != undefined &&
                        apensos.length > 0
                      "
                    >
                      <div class="col-12">
                        <a
                          href="{{ raiz }}/processos-judiciais/detalhes/{{
                            apensos[0].processoJudicialId
                          }}"
                          target="_blank"
                          >{{ apensos[0].titulo }}</a
                        >{{ apensos[0].display }}
                      </div>
                      <div class="col-12 arvore-pai">
                        <div
                          class="row"
                          *ngFor="let apenso of apensos; let i = index"
                        >
                          <div
                            class="col-1 arvore-processos-vinculados arvore-vertical arvore-horizontal"
                            *ngIf="i > 0"
                          ></div>
                          <div class="col-11" *ngIf="i > 0">
                            <a
                              href="{{ raiz }}/processos-judiciais/detalhes/{{
                                apenso.processoJudicialId
                              }}"
                              target="_blank"
                              >{{ apenso.titulo }}</a
                            >{{ apenso.display }}
                            <a
                              (click)="
                                handleRemoverApenso(apenso.processoJudicialId)
                              "
                              alt="Desvincular processo"
                              ><i class="fas fa-unlink"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>

                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="col"
            id="resumoDiv"
            style="overflow: scroll; overflow-x: hidden"
          >
            <div class="card mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">
                      <i class="fas fa-calendar-check"></i>
                      {{ processo.caso ? "Últimos históricos" : "Resumo" }}
                    </h5>
                  </div>
                  <div class="col-2 d-flex flex-row-reverse">
                    <div class="btn-diversos" (click)="abrirAba(4)">
                      <i class="fas fa-folder-open"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="row proxima-atividade"
                  (click)="abrirAba(4)"
                  [ngClass]="'proxima-atividade-' + atividade.estilo"
                  *ngFor="let atividade of atividadesDoProcessoTop"
                >
                  <div
                    class="col-5 mt-2"
                    [ngClass]="'texto-' + atividade.estilo"
                  >
                    {{ atividade.tipoObjeto }}
                  </div>
                  <div class="col-7">
                    <span
                      [ngClass]="
                        atividade.concluido ? 'concluido-risco' : 'texto-normal'
                      "
                      ><b>{{ atividade.tituloDescricao }}</b></span
                    >
                    <p
                      [ngClass]="
                        atividade.emAtraso ? 'proxima-atividade-atraso' : ''
                      "
                    >
                      {{ atividade.dataOcorrencia | dataextenso }}
                    </p>
                    <p
                      class="proxima-atividade-display-atividade"
                      *ngIf="atividade.instanciaNome != null"
                    >
                      {{ atividade.instanciaNome }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="card mt-2">
                            <div class="card-body">
                                <h5 class="card-title"><i class="fas fa-coins"></i> Despesas</h5>
                            </div>
                        </div>
                        <div class="card mt-2">
                            <div class="card-body">
                                <h5 class="card-title"><i class="fas fa-user-clock"></i> Timesheet</h5>
                            </div>
                        </div> -->
            <!-- <div class="card mt-2">
                            <div class="card-body" (mouseover)="hideButtonsDadosOcorrencias = false" (mouseleave)="hideButtonsDadosOcorrencias = true">
                                <div class="row">
                                    <div class="col-10">
                                        <h5 class="card-title"><i class="fas fa-history"></i> Últimas ocorrências</h5>
                                    </div>
                                    <div class="col-2 d-flex flex-row-reverse">
                                        <div class="btn-diversos" (click)="abrirAba(4)">
                                            <i class="fas fa-folder-open"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center" *ngIf="loadDadosOcorrencia">
                                    <div class="spinner-border text-primary" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div class="row ultimas-ocorrencias" *ngFor="let ocorrencia of ultimasOcorrencias">
                                    <div class="col-2" >
                                        {{ocorrencia.dataHistorico | date: 'dd/MM/yyyy'}}
                                    </div>
                                    <div class="col-10">
                                        <ocorrencias-icone tipo="{{ocorrencia.tipoOcorrencia}}"></ocorrencias-icone>
                                        <b>{{ocorrencia.tipoOcorrencia == 1 ? "Histórico ":""}}
                                        {{ocorrencia.tipoOcorrencia == 2 ? "Tarefa ":""}}
                                        {{ocorrencia.tipoOcorrencia == 3 ? "Evento ":""}}
                                        {{ocorrencia.tipoOcorrencia == 4 ? "Prazo ":""}}
                                        ({{ocorrencia.responsavelNome.split(' ')[0]}})</b> | {{ocorrencia.tituloDescricao}}
                                        <p class="instancia-display" *ngIf="ocorrencia.instanciaNome != null">{{ocorrencia.instanciaNome}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>     -->
          </div>
        </div>
        <div class="row" *ngIf="processo.caso">
            <div class="col-12">
                <ul ngbNav #navCaso="ngbNav" id="tabs" class="nav-tabs pt-2" >
                    <li [ngbNavItem]="1">
                        <a ngbNavLink
                          ><i class="fas fa-money-bill-alt"></i> Valores</a
                        >
                        <ng-template ngbNavContent>
                            <app-ag-grid
                            #agGridComponent
                            classGrid="h-grid"
                            [rowData]="recursoDesdobramentoDetalhe.valores"
                            [pagination]="false"
                            [columnDefs]="columnDefs"
                            [showToolbar]="true"
                            [rowSelection]="multiple"
                            keyLocalStorageFilter="filter-valores-recursos-desdobramentos"
                            (cellClicked)="onCellClicked($event)"
                            [gridOptions]="gridOptions"
                            [pinnedBottom]="pinnedBottom"
                          >
                            <i
                              toolbar
                              class="fa fa-plus cursor-pointer ml-3"
                              matTooltip="Incluir"
                              (click)="
                                openModalAddDoc(recursoDesdobramentoDetalhe)
                              "
                            ></i>
                            <i
                              toolbar
                              class="fa fa-trash cursor-pointer ml-3"
                              matTooltip="Deletar"
                              (click)="deleteDoc(recursoDesdobramentoDetalhe)"
                            ></i>
                          </app-ag-grid>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <a ngbNavLink><i class="fas fa-barcode"></i> Despesas</a>
                        <ng-template ngbNavContent>
                          <despesa-resumo
                            [tipo]="4"
                            [referenciaId]="recursoDesdobramentoDetalhe.id"
                            [processoJudicialId]="id"
                            [raiz]="raiz"
                          ></despesa-resumo>
                          <app-lista-despesa [tipo]="4" [referenciaId]="recursoDesdobramentoDetalhe.id" [resumo]="true"></app-lista-despesa>
                        </ng-template>
                      </li>
                </ul>
                <div [ngbNavOutlet]="navCaso" class="recurso-card mt-2" ></div>
            </div>
        </div>
        <div class="row" *ngIf="!loadProcesso && !processo.caso">
          <div class="col-12">
            <div class="card mt-2">
              <div class="card-body">
                <div class="row" id="recursosDesdobramentos">
                  <div class="col-11">
                    <h5 class="card-title pt-2">
                      <i class="fas fa-landmark"></i> Recursos e desdobramentos
                    </h5>
                  </div>
                  <div class="col-1">
                    <div
                      class="btn-adicionar"
                      (click)="
                        exibirMenusRecursosDesdobramentos =
                          !exibirMenusRecursosDesdobramentos
                      "
                    >
                      <i class="fas fa-plus"></i>
                      <div
                        class="menu-detalhes"
                        (mouseleave)="exibirMenusRecursosDesdobramentos = false"
                        [hidden]="!exibirMenusRecursosDesdobramentos"
                      >
                        <ul>
                          <li>
                            <a
                              routerLink="{{
                                raiz
                              }}/processos-judiciais-recursos/{{ id }}"
                              >Recurso</a
                            >
                          </li>
                          <li>
                            <a
                              routerLink="{{
                                raiz
                              }}/processos-judiciais-desdobramento/{{ id }}"
                              >Desdobramento</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    *ngIf="loadRecursosDesdobramentos"
                  >
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="row w-100" *ngIf="!loadRecursosDesdobramentos">
                    <div class="col-12">
                        <app-recursos-e-desdobramentos
                        [id]="id"
                        [raiz]="raiz"
                        [recursosDesdobramentos]="recursosDesdobramentos"
                        [nivel]="0"
                        (detalheRecursoDesdobramentoEvent)="detalheRecursoDesdobramento($event)"
                        (handleAdicionarOcorrenciaEvent)="handleAdicionarOcorrencia($event)"
                        (monitorarPublicacaoEvent)="monitorarPublicacao($event)"
                        (ativarDesativarRecursoDesdobramentoEvent)="ativarDesativarRecursoDesdobramento($event)"
                        ></app-recursos-e-desdobramentos>
                    </div>
                    <br />
                  </div>
                </div>
                <br />
                <div class="row" *ngIf="!exibirDetalhesRecursoDesdobramento">
                    <div
                    class="d-flex justify-content-center"
                  >
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="exibirDetalhesRecursoDesdobramento">
                  <div class="col-12">
                    <ul
                      ngbNav
                      #navRecursosDesdobramentos="ngbNav"
                      id="tabs"
                      [(activeId)]="activenavRecursosDesdobramentos"
                      class="nav-tabs pt-2"
                    >
                      <li [ngbNavItem]="1">
                        <a ngbNavLink
                          ><i class="fas fa-align-left"></i>Ocorrências</a
                        >
                        <ng-template ngbNavContent>
                          <ocorrencias-detalhes
                            recursoDesdobramentoId="{{
                              recursoDesdobramentoDetalhe.id
                            }}"
                            (changeRefresh)="changeRefresh($event)"
                          ></ocorrencias-detalhes>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <a ngbNavLink
                          ><i class="fas fa-money-bill-alt"></i> Valores</a
                        >
                        <ng-template ngbNavContent>
                          <ul
                            ngbNav
                            #subNavValores="ngbNav"
                            class="nav-tabs pt-2"
                          >
                            <li [ngbNavItem]="1">
                              <a ngbNavLink (click)="atualizarValores(recursoDesdobramentoDetalhe)">Dívidas</a>
                              <ng-template ngbNavContent>
                                <app-ag-grid
                                  #agGridComponent
                                  classGrid="h-grid"
                                  [rowData]="
                                    recursoDesdobramentoDetalhe.valores
                                  "
                                  [pagination]="false"
                                  [columnDefs]="columnDefs"
                                  [showToolbar]="true"
                                  [rowSelection]="'multiple'"
                                  keyLocalStorageFilter="filter-valores-recursos-desdobramentos"
                                  (cellClicked)="onCellClicked($event)"
                                  [gridOptions]="gridOptions"
                                  [pinnedBottom]="pinnedBottom"
                                >
                                  <i
                                    toolbar
                                    class="fa fa-plus cursor-pointer ml-3"
                                    matTooltip="Incluir"                                    
                                    (click)="
                                      openModalAddDoc(
                                        recursoDesdobramentoDetalhe
                                      )
                                    "
                                  ></i>
                                  <i
                                    toolbar
                                    class="fa fa-trash cursor-pointer ml-3"
                                    matTooltip="Deletar"
                                    (click)="
                                      deleteDoc(recursoDesdobramentoDetalhe)
                                    "
                                  ></i>

                                  <i
                                    toolbar
                                    class="ml-3 fa fa-calculator"
                                    matTooltip="Calculadora"
                                    (click)="calculadora()"
                                  >
                                  </i>
                                </app-ag-grid>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                              <a ngbNavLink>Atualização de Valores</a>
                              <ng-template ngbNavContent>
                                <h5
                                  class="d-flex justify-content-between align-items-center font-weight-bold pt-3"
                                >
                                  <div>
                                    <div>
                                      <span>{{
                                        "Atualização de Valores"
                                      }}</span>
                                    </div>
                                  </div>
                                </h5>
                                <hr/>
                                <app-lista-atualizacoes
                                  [recursoDesdobramentoId]="
                                    recursoDesdobramentoDetalhe.id
                                  "
                                ></app-lista-atualizacoes>
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="3">
                              <a ngbNavLink> Pedidos</a>
                              <ng-template ngbNavContent>
                                <h5
                                  class="d-flex justify-content-between align-items-center font-weight-bold pt-3"
                                >
                                  <div>
                                    <span>{{ "Pedidos" }}</span>
                                  </div>
                                  <i
                                    class="fas fa-plus mr-2 cursor-pointer"
                                    (click)="
                                    openModalPedido(recursoDesdobramentoDetalhe.id)
                                    "
                                  ></i>
                                </h5>
                                <hr/>
                                <app-lista-pedidos [recursoDesdobramentoId]="recursoDesdobramentoDetalhe.id"></app-lista-pedidos>
                              </ng-template>
                            </li>
                          </ul>

                          <div
                            [ngbNavOutlet]="subNavValores"
                            class="mt-2"
                          ></div>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="3">
                        <a ngbNavLink><i class="fas fa-barcode"></i> Despesas</a>
                        <ng-template ngbNavContent>
                          <despesa-resumo
                            [tipo]="4"
                            [referenciaId]="recursoDesdobramentoDetalhe.id"
                            [processoJudicialId]="id"
                            [raiz]="raiz"
                          ></despesa-resumo>
                          <app-lista-despesa [tipo]="4" [referenciaId]="recursoDesdobramentoDetalhe.id" [resumo]="true"></app-lista-despesa>
                          <!-- <app-lista-nota-de-debito [tipo]="4" [referenciaId]="recursoDesdobramentoDetalhe.id"></app-lista-nota-de-debito> -->
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="4">
                        <a ngbNavLink
                          ><i class="fas fa-handshake"></i> Acordo</a
                        >
                        <ng-template ngbNavContent>
                            <app-acordos-processo-judicial
                                [processoJudicialId]="id"
                                [taxasAcordo]="taxasAcordo"
                                [recursoDesdobramentoId]="recursoDesdobramentoDetalhe.id"
                                [pessoaId]="pessoaId"></app-acordos-processo-judicial>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="5">
                        <a ngbNavLink
                          ><i class="fas fa-file-invoice-dollar"></i>
                          Honorários</a
                        >
                        <ng-template ngbNavContent>
                            <!--movi a aba para um componente ideal fazer com os itens acima também para facilitar manutenção-->
                          <app-honorarios-processo
                            [tipo]="4"
                            [referenciaId]="recursoDesdobramentoDetalhe.id"
                            [raiz]="raiz"
                            (refreshTagProcesso)="refreshTagProcesso()"
                          >
                          </app-honorarios-processo>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="6">
                        <a ngbNavLink
                          ><i class="fas fa-paperclip"></i> Documentos</a
                        >
                        <ng-template ngbNavContent>
                          <h5
                            class="d-flex justify-content-between align-items-center font-weight-bold pt-3"
                          >
                            <div>
                              <i class="fas fa-paperclip"></i>
                              <span>{{ "Documentos" }}</span>
                            </div>
                            <div>
                              <i
                                class="fas fa-plus mr-2 cursor-pointer"
                                (click)="
                                  goToAddDocs(recursoDesdobramentoDetalhe.id)
                                "
                              ></i>
                              <i
                                class="fas fa-folder-open cursor-pointer"
                                (click)="goToDocs()"
                              ></i>
                            </div>
                          </h5>
                          <app-documents
                            [hasFilter]="false"
                            [itensPorPagina]="10"
                            [referenciaId]="recursoDesdobramentoDetalhe.id"
                          ></app-documents>
                          <!-- <div class="row">
                                                        <div class="col-12">
                                                            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                                            (fileOver)="fileOverBase($event)"
                                                            (onFileDrop)="onDropRecursosDesdobramentosUpload($event)"
                                                            [uploader]="uploader" class="dropzone" (click)="fileInput.click()">
                                                                <span
                                                                    class=" span-icon fas fa-cloud-download-alt"></span>
                                                                <span class="span-text">Arraste e solte seus arquivos
                                                                    aqui ou clique para
                                                                    carregar</span>
                                                                <input input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" multiple
                                                                (onFileSelected)="onDropRecursosDesdobramentosUpload($event)" />
                                                            </div>

                                                            <table class="table"
                                                                *ngIf="uploader.queue.length > 0">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="45%">Nome</th>
                                                                        <th>Tamanho</th>
                                                                        <th>Status</th>
                                                                        <th>Acões</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let item of uploader.queue">
                                                                        <td><strong>{{ item?.file?.name }}</strong></td>
                                                                        <td *ngIf="uploader.options.isHTML5"
                                                                            nowrap>{{ item?.file?.size/1024/1024 |
                                                                            number:'.2' }} MB
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <span *ngIf="item.isSuccess"><i
                                                                                    class="glyphicon glyphicon-ok"></i></span>
                                                                            <span *ngIf="item.isCancel"><i
                                                                                    class="glyphicon glyphicon-ban-circle"></i></span>
                                                                            <span *ngIf="item.isError"><i
                                                                                    class="glyphicon glyphicon-remove"></i></span>
                                                                        </td>
                                                                        <td nowrap>
                                                                            <button type="button" class="btn btn-danger"
                                                                                (click)="handleRemoveFile(item)">
                                                                                <span
                                                                                    class="glyphicon glyphicon-trash"></span>
                                                                                Remover
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-12"
                                                            *ngIf="uploader.queue != null && uploader.queue.length > 0">
                                                            <div class="d-flex justify-content-end">
                                                                <button type="button"
                                                                    (click)="handleAdicionarAnexos(recursoDesdobramentoDetalhe)"
                                                                    class="btn btn-primary mr-3">Salvar anexos</button>
                                                            </div>
                                                        </div>
                                                    </div> -->
                          <!-- <br />
                                                    <div class="row" *ngIf="recursoDesdobramentoArquivos != undefined">
                                                        <div class="col-12 mb-5">Anexos:</div>
                                                        <div class="col"
                                                            *ngFor="let arquivo of recursoDesdobramentoArquivos">
                                                            <div class="btn btn-primary"
                                                                (click)="handleDownloadArquivo(arquivo)">
                                                                {{arquivo.nomeArquivo}} <i
                                                                    class="btn btn-xs icon-btn btn-primary"><span
                                                                        class="fas fa-download"></span></i>
                                                            </div>
                                                        </div>
                                                    </div> -->
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="7">
                        <a ngbNavLink><i class="fas fa-comments"></i> Partes</a>
                        <ng-template ngbNavContent>
                          <div class="col-12">
                            <ul>
                              <li
                                *ngFor="
                                  let parte of recursoDesdobramentoDetalhe.partes
                                "
                              >
                                {{ parte.nome }} - {{ parte.qualificacaoNome }}
                              </li>
                            </ul>
                          </div>
                        </ng-template>
                      </li>
                    </ul>
                    <div
                      [ngbNavOutlet]="navRecursosDesdobramentos"
                      class="recurso-card mt-2"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <!-- <li [ngbNavItem]="2" (click)="exibirDetalhesRecursoDesdobramento = false">
            <a ngbNavLink>Recursos e Desdobramentos</a>
            <ng-template ngbNavContent>
                <div class="card mt-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-10">
                                <h5 class="card-title"><i class="fas fa-landmark"></i> Recursos e desdobramentos</h5>
                            </div>
                            <div [ngClass]="!hideButtonsRecursosDesdobramentos ? 'col-2 d-flex flex-row-reverse' : 'col-2'" [hidden]="hideButtonsRecursosDesdobramentos">
                                <div class="btn-adicionar" (click)="exibirMenusRecursosDesdobramentos = !exibirMenusRecursosDesdobramentos">
                                    <i class="fas fa-plus"></i>
                                    <div class="menu-detalhes" (mouseleave)="exibirMenusRecursosDesdobramentos = false" [hidden]="!exibirMenusRecursosDesdobramentos">
                                        <ul>
                                            <li><a routerLink="{{raiz}}/processos-judiciais-recursos/{{id}}">Recurso</a></li>
                                            <li><a routerLink="{{raiz}}/processos-judiciais-desdobramento/{{id}}">Desdobramento</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="loadRecursosDesdobramentos">
                            <div class="spinner-border text-primary" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="row" *ngIf="!loadRecursosDesdobramentos">
                            <div class="col-12">
                                <div class="row" *ngFor="let recursoDesdobramento of recursosDesdobramentos">
                                    <div class="col-9 recurso-linha cursor-pointer" [ngClass]="recursoDesdobramento.selecionado ? 'recurso-selecionado' : ''" (click)="detalheRecursoDesdobramento(recursoDesdobramento)">
                                        {{recursoDesdobramento.numero != undefined && recursoDesdobramento.numero != null ? recursoDesdobramento.numero+" - " : ""}}{{recursoDesdobramento.instanciaNome}}{{recursoDesdobramento.tipoRecursoDesdobramentoNome != undefined ? " - "+recursoDesdobramento.tipoRecursoDesdobramentoNome : ""}}
                                        <div *ngIf="recursoDesdobramento.situacao != undefined && recursoDesdobramento.situacao != null" [ngClass]="defineClass(recursoDesdobramento.situacaoCor)">
                                            {{recursoDesdobramento.situacao}}
                                        </div>
                                    </div>
                                    <div class="col-1 recurso-linha" [ngClass]="recursoDesdobramento.selecionado ? 'recurso-selecionado' : ''" (click)="detalheRecursoDesdobramento(recursoDesdobramento)">
                                        <span *ngIf="recursoDesdobramento.ativo" class="historico-ativo">Ativo</span>
                                        <span *ngIf="!recursoDesdobramento.ativo" class="historico-encerrado">Encerrado</span>
                                    </div>
                                    <div class="col-2" [ngClass]="recursoDesdobramento.selecionado ? 'recurso-selecionado' : ''">
                                        <div ngbDropdown>
                                            <button type="button" class="btn btn-primary mt-1" id="dropDownMenuAcoesRecursosDesdobramentos"
                                                ngbDropdownToggle>
                                                <i class="fas fa-bolt"></i> Ações
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropDownMenuAcoesRecursosDesdobramentos">
                                                <a ngbDropdownItem routerLink="{{raiz}}/processos-judiciais-recursos/editar/{{recursoDesdobramento.id}}"><i class="far fa-edit"></i> Editar</a>
                                                <a ngbDropdownItem>Timesheet</a>
                                                <div class="dropdown-divider"></div>
                                                <a ngbDropdownItem>Timesheet</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 arvore-pai" *ngIf="recursoDesdobramento.processoJudicialRecursoDesdobramentos != null && recursoDesdobramento.processoJudicialRecursoDesdobramentos.length > 0">
                                        <div class="row desdobramento" *ngFor="let desdobramento of recursoDesdobramento.processoJudicialRecursoDesdobramentos">
                                            <div class="col-1 arvore-vertical arvore-horizontal">
                                            </div>
                                            <div class="col-10">
                                                <a class="cursor-pointer" (click)="detalheRecursoDesdobramento(recursoDesdobramento)">{{desdobramento.numero != undefined && desdobramento.numero != null ? desdobramento.numero+" - " : ""}}{{desdobramento.instanciaNome}}{{desdobramento.tipoRecursoDesdobramentoNome != undefined ? " - "+desdobramento.tipoRecursoDesdobramentoNome : ""}}</a>
                                                <a routerLink="{{raiz}}/processos-judiciais-desdobramento/editar/{{desdobramento.id}}">
                                                    <i class="far fa-edit"></i>
                                                </a>
                                                <div class="situacao-display" *ngIf="desdobramento.situacao != undefined && desdobramento.situacao != null" [ngClass]="defineClass(desdobramento.situacaoCor)">
                                                    {{desdobramento.situacao}}
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <span *ngIf="desdobramento.ativo" class="historico-ativo">Ativo</span>
                                                <span *ngIf="!desdobramento.ativo" class="historico-encerrado">Encerrado</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <br />
                        <div class="row" *ngIf="exibirDetalhesRecursoDesdobramento">
                            <div class="col-12">
                                <ul ngbNav #navRecursosDesdobramentos="ngbNav" [(activeId)]="active" class="nav-tabs">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink><i class="fas fa-align-left"></i>Ocorrências</a>
                                        <ng-template ngbNavContent>
                                            <ocorrencias-detalhes recursoDesdobramentoId="{{recursoDesdobramentoDetalhe.id}}" (changeRefresh)="changeRefresh($event)"></ocorrencias-detalhes>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink><i class="fas fa-money-bill-alt"></i> Objetos</a>
                                        <ng-template ngbNavContent>

                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink><i class="fas fa-handshake"></i> Acordo</a>
                                        <ng-template ngbNavContent>

                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="4">
                                        <a ngbNavLink><i class="fas fa-barcode"></i> Custas</a>
                                        <ng-template ngbNavContent>

                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="5">
                                        <a ngbNavLink><i class="fas fa-paperclip"></i> Arquivos</a>
                                        <ng-template ngbNavContent>

                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="6">
                                        <a ngbNavLink><i class="fas fa-comments"></i> Partes</a>
                                        <ng-template ngbNavContent>
                                            <div class="col-12">
                                                <ul>
                                                    <li *ngFor="let parte of recursoDesdobramentoDetalhe.partes">{{parte.nome}} - {{parte.qualificacaoNome}}</li>
                                                </ul>
                                            </div>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="navRecursosDesdobramentos" class="recurso-card mt-2"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-template>
        </li>              -->
    <li [ngbNavItem]="3">
      <a ngbNavLink
        >Atividades
        <span
          class="atividades-concluidas"
          *ngIf="quantidadeAtividadesAbertas > 0"
          >{{ quantidadeAtividadesAbertas }}</span
        ></a
      >
      <ng-template ngbNavContent>
        <div
          class="d-flex justify-content-center"
          *ngIf="loadAtividadesDoProcesso"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="row" *ngIf="!loadAtividadesDoProcesso">
          <span
            class="atividade-link"
            (click)="atividadesEmAberto()"
            [ngClass]="
              atividadeSelecionada == 'atividade-aberta'
                ? 'atividade-selecionada'
                : ''
            "
            >{{ quantidadeAtividadesNaoConcluida }} ABERTA{{
              quantidadeAtividadesNaoConcluida > 1 ? "S" : ""
            }}</span
          >&nbsp;|&nbsp;
          <span
            class="atividade-link"
            (click)="atividadesEmAtraso()"
            [ngClass]="
              atividadeSelecionada == 'atividade-em-atraso'
                ? 'atividade-selecionada'
                : ''
            "
            >{{ quantidadeAtividadesEmAtraso }} ATRASADA{{
              quantidadeAtividadesEmAtraso > 1 ? "S" : ""
            }}</span
          >&nbsp;|&nbsp;
          <span
            class="atividade-link"
            (click)="atividadesConcluidas()"
            [ngClass]="
              atividadeSelecionada == 'atividade-concluida'
                ? 'atividade-selecionada'
                : ''
            "
            >{{ quantidadeAtividadesConcluida }} CONCLUÍDA{{
              quantidadeAtividadesConcluida > 1 ? "S" : ""
            }}</span
          >
        </div>
        <br />
        <div
          class="row"
          style="margin-left: 2em"
          *ngIf="
            !loadAtividadesDoProcesso &&
            atividadesDoProcesso != undefined &&
            atividadesDoProcesso.length > 0
          "
        >
          <div class="col-12" *ngFor="let atividade of atividadesDoProcesso">
            <app-card-ocorrencia-atvd-detalhe
              dataField="dataOcorrencia"
              [isAbaAtividade]="true"
              [atividade]="atividade"
              [recursoDesdobramento]="recursoDesdobramento"
              (openModalComments)="openModalComments($event)"
            ></app-card-ocorrencia-atvd-detalhe>

            <!-- <div class="row" [ngClass]="atividade.concluido ? 'concluido' : atividade.estilo">
                            <div class="col-3 d-flex align-items-center" [ngClass]="'texto-'+atividade.estilo">
                                <i class="fas fa-plus-square btn-diversos" *ngIf="!atividade.editarDetalhes"
                                    (click)="detalhesExibirOcultar(atividade)"></i>
                                <i class="fas fa-minus btn-diversos" *ngIf="atividade.editarDetalhes"
                                    (click)="detalhesExibirOcultar(atividade)"></i>
                                {{atividade.tipoObjeto}}
                            </div>
                            <div class="col-2">
                                <input class="form-check-input" type="checkbox" [checked]="atividade.concluido"
                                    (change)="onChangeConcluir(atividade, !atividade.concluido)" />
                                &nbsp;
                                <span
                                    [ngClass]="atividade.concluido ? 'concluido-risco' : 'texto-normal'">{{atividade.tituloDescricao}}</span>
                                <p class="instancia-display-atividade" *ngIf="atividade.instanciaNome != null">
                                    {{atividade.instanciaNome}}</p>
                            </div>
                            <div class="col-3 d-flex align-items-center">
                                <i class="fas fa-calendar-minus" *ngIf="atividade.dataOcorrencia == null"></i>
                                <i class="fas fa-calendar-check" *ngIf="atividade.dataOcorrencia != null"></i>
                                &nbsp;{{atividade.dataOcorrencia | dataextenso}}&nbsp;
                                <prioridades-icone prioridade="{{ atividade.prioridade }}"
                                    prioridadeDescricao="{{ atividade.prioridadeDescricao }}" class="d-flex">
                                </prioridades-icone>
                            </div>
                            <div class="col-2" *ngIf="atividade.tipoOcorrencia == 1">
                                <i class="fas fa-comment"></i> <span
                                    *ngIf="atividade.quantidadeComentarios > 0">{{atividade.quantidadeComentarios}}
                                    comentário{{atividade.quantidadeComentarios > 1 ? "s" : ""}}</span>
                            </div>
                            <div class="col-2" *ngIf="atividade.tipoOcorrencia == 3">
                                <i class="fas fa-clock"></i> {{atividade.periodo}}
                            </div>
                            <div class="col-2 atividade-link" *ngIf="atividade.tipoOcorrencia == 4"
                                (click)="handleIncluirAtividadeAoPrazo(atividade)">
                                <i class="fas fa-bars"></i> ({{atividade.quantidadeTarefasEventosVinculados}})
                            </div>
                            <div class="col-2">
                                <i class="fas fa-user"></i> <span
                                    [ngClass]="atividade.concluido ? 'concluido-risco' : 'texto-normal'">{{atividade.responsavelNome.length
                                    > 17?atividade.responsavelNome.substring(0,14)+'...' :
                                    atividade.responsavelNome}}</span>
                            </div>
                            <div class="col-12" *ngIf="atividade.editarDetalhes && atividade.tipoOcorrencia == 1">
                                <tarefas-detalhes tarefaId="{{atividade.id}}" tarefaDetalhes="{{atividade}}"
                                    (changeQuantidade)="onChangeQuantidade(atividade, false, $event)"
                                    (changeQuantidadeOcultando)="onChangeQuantidade(atividade, true, $event)"
                                    (changeConcluirTarefa)="onChangeConcluirTarefa(atividade, $event)">
                                </tarefas-detalhes>
                            </div>
                            <div class="col-12" *ngIf="atividade.editarDetalhes && atividade.tipoOcorrencia == 3">
                                <eventos-detalhes eventoId="{{atividade.id}}" eventoDetalhes="{{atividade}}"
                                    (changeConcluirEvento)="onChangeConcluirEvento(atividade, $event)"
                                    (changeQuantidadeOcultando)="onChangeQuantidade(atividade, true, $event)">
                                </eventos-detalhes>
                            </div>
                            <div class="col-12" *ngIf="atividade.editarDetalhes && atividade.tipoOcorrencia == 4">
                                <prazo-detalhes prazoId="{{atividade.id}}" prazoDetalhes="{{atividade}}"
                                    (changeConcluirPrazo)="onChangeConcluirPrazo(atividade, $event)"
                                    (changeQuantidadeOcultando)="onChangeQuantidade(atividade, true, $event)">
                                </prazo-detalhes>
                            </div>
                            <div class="col-12" *ngIf="atividade.editarDetalhes && atividade.tipoOcorrencia == 5">
                                <audiencia-detalhes audienciaId="{{atividade.id}}" audienciaDetalhes="{{atividade}}">
                                </audiencia-detalhes>
                            </div>
                        </div> -->
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>Ocorrências</a>
      <ng-template ngbNavContent>
        <div
          class="d-flex justify-content-center"
          *ngIf="loadRecursosDesdobramentos"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div *ngIf="!loadRecursosDesdobramentos && processo.id != undefined">
          <div
            class="row"
            *ngFor="
              let recursoDesdobramento of recursosDesdobramentos;
              let i = index
            "
          >
            <div class="col-12">
              <div class="row box box-historico">
                <div class="col-9">
                  <h5>
                    <i class="fas fa-landmark"></i>
                    {{
                      processo.caso
                        ? "Instância Administrativa PréProcessual"
                        : recursoDesdobramento.instanciaNome
                    }}
                  </h5>
                </div>
                <div class="col-2">
                  <span
                    *ngIf="recursoDesdobramento.ativo"
                    class="historico-ativo"
                    >Ativo</span
                  >
                  <span
                    *ngIf="!recursoDesdobramento.ativo"
                    class="historico-encerrado"
                    >Encerrado</span
                  >
                </div>
                <div class="col-1">
                  <div
                    class="btn-adicionar"
                    (click)="
                      recursoDesdobramento.menuEdivarVisible =
                        !recursoDesdobramento.menuEdivarVisible
                    "
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </div>
                  <div
                    class="menu-detalhes"
                    (mouseleave)="
                      recursoDesdobramento.menuEdivarVisible = false
                    "
                    [hidden]="!recursoDesdobramento.menuEdivarVisible"
                  >
                    <ul>
                      <li>
                        <a
                          (click)="
                            handleAdicionarOcorrencia(recursoDesdobramento)
                          "
                          >Adicionar Ocorrência</a
                        >
                      </li>
                      <li>
                        <a
                          (click)="
                            editarRecursoDesdobramento(recursoDesdobramento, i)
                          "
                          >Editar</a
                        >
                      </li>
                      <li>
                        <a
                          (click)="
                            ativarDesativarRecursoDesdobramento(
                              recursoDesdobramento
                            )
                          "
                          >{{
                            recursoDesdobramento.ativo ? "Encerrar" : "Ativar"
                          }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-6">
                  <span
                    class="ocorrencia-label"
                    *ngIf="recursoDesdobramento.numero != null"
                    >Número: </span
                  >{{ recursoDesdobramento.numero }}
                </div>
                <div class="col-6" *ngIf="i == 0"></div>
                <div class="col-6" *ngIf="i > 0">
                  <span class="ocorrencia-label">{{
                    recursoDesdobramento.partes[0].qualificacaoNome
                  }}</span
                  >:
                  {{ recursoDesdobramento.partes[0].nome }}
                </div>
                <div class="col-6">
                  <span
                    class="ocorrencia-label"
                    *ngIf="recursoDesdobramento.juizo != null"
                    >Juízo: </span
                  >{{ recursoDesdobramento.juizo }}
                </div>
                <div class="col-6" *ngIf="i == 0"></div>
                <div class="col-6" *ngIf="i > 0">
                  <span class="ocorrencia-label">{{
                    recursoDesdobramento.partes[1].qualificacaoNome
                  }}</span
                  >:
                  {{ recursoDesdobramento.partes[1].nome }}
                </div>
                <div class="col-6">
                  <span
                    class="ocorrencia-label"
                    *ngIf="recursoDesdobramento.juizRelator"
                    >Juiz:</span
                  >{{ recursoDesdobramento.juizRelator }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <ocorrencias-detalhes
                recursoDesdobramentoId="{{ recursoDesdobramento.id }}"
                (changeRefresh)="changeRefresh($event)"
              ></ocorrencias-detalhes>
            </div>
            <div
              class="col-12"
              *ngIf="
                recursoDesdobramento.processoJudicialRecursoDesdobramentos !=
                  null &&
                recursoDesdobramento.processoJudicialRecursoDesdobramentos
                  .length > 0
              "
            >
              <div
                class="row"
                *ngFor="
                  let recursoDesdobramentoFilho of recursoDesdobramento.processoJudicialRecursoDesdobramentos;
                  let i = index
                "
              >
                <div class="col-1"></div>
                <div class="col-11">
                  <div class="row box box-historico">
                    <div class="col-9">
                      <h5>
                        <i class="fas fa-landmark"></i>
                        {{ recursoDesdobramentoFilho.instanciaNome }}
                      </h5>
                    </div>
                    <div class="col-2">
                      <span
                        *ngIf="recursoDesdobramentoFilho.ativo"
                        class="historico-ativo"
                        >Ativo</span
                      >
                    </div>
                    <div class="col-1">
                      <div
                        class="btn-adicionar"
                        (click)="
                          recursoDesdobramentoFilho.menuEdivarVisible =
                            !recursoDesdobramentoFilho.menuEdivarVisible
                        "
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </div>
                      <div
                        class="menu-detalhes"
                        (mouseleave)="
                          recursoDesdobramentoFilho.menuEdivarVisible = false
                        "
                        [hidden]="!recursoDesdobramentoFilho.menuEdivarVisible"
                      >
                        <ul>
                          <li>
                            <a
                              (click)="
                                handleAdicionarOcorrencia(
                                  recursoDesdobramentoFilho
                                )
                              "
                              >Adicionar Ocorrência</a
                            >
                          </li>
                          <li>
                            <a
                              (click)="
                                editarRecursoDesdobramento(
                                  recursoDesdobramentoFilho,
                                  i
                                )
                              "
                              >Editar</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-6">
                      <span
                        class="ocorrencia-label"
                        *ngIf="recursoDesdobramentoFilho.numero != null"
                        >Número: </span
                      >{{ recursoDesdobramentoFilho.numero }}
                    </div>
                    <div class="col-6" *ngIf="i == 0"></div>
                    <div class="col-6" *ngIf="i > 0">
                      <span class="ocorrencia-label">{{
                        recursoDesdobramentoFilho.partes[0].qualificacaoNome
                      }}</span
                      >:
                      {{ recursoDesdobramentoFilho.partes[0].nome }}
                    </div>
                    <div class="col-6">
                      <span
                        class="ocorrencia-label"
                        *ngIf="recursoDesdobramentoFilho.juizo != null"
                        >Juízo: </span
                      >{{ recursoDesdobramentoFilho.juizo }}
                    </div>
                    <div class="col-6" *ngIf="i == 0"></div>
                    <div class="col-6" *ngIf="i > 0">
                      <span class="ocorrencia-label">{{
                        recursoDesdobramentoFilho.partes[1].qualificacaoNome
                      }}</span
                      >:
                      {{ recursoDesdobramentoFilho.partes[1].nome }}
                    </div>
                    <div class="col-6">
                      <span
                        class="ocorrencia-label"
                        *ngIf="recursoDesdobramentoFilho.juizRelator"
                        >Juiz:</span
                      >{{ recursoDesdobramentoFilho.juizRelator }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-1"></div>
                    <div class="col-11">
                      <ocorrencias-detalhes
                        recursoDesdobramentoId="{{
                          recursoDesdobramentoFilho.id
                        }}"
                        (changeRefresh)="changeRefresh($event)"
                      >
                      </ocorrencias-detalhes>
                    </div>
                  </div>
                </div>
                <!--nivel 3-->
                <div
                  class="col-12"
                  *ngIf="
                    recursoDesdobramentoFilho.processoJudicialRecursoDesdobramentos !=
                      null &&
                    recursoDesdobramentoFilho
                      .processoJudicialRecursoDesdobramentos.length > 0
                  "
                >
                  <div
                    class="row"
                    *ngFor="
                      let recursoDesdobramentoFilho1 of recursoDesdobramentoFilho.processoJudicialRecursoDesdobramentos;
                      let i = index
                    "
                  >
                    <div class="col-2"></div>
                    <div class="col-10">
                      <div class="row box box-historico">
                        <div class="col-9">
                          <h5>
                            <i class="fas fa-landmark"></i>
                            {{ recursoDesdobramentoFilho1.instanciaNome }}
                          </h5>
                        </div>
                        <div class="col-2">
                          <span
                            *ngIf="recursoDesdobramentoFilho1.ativo"
                            class="historico-ativo"
                            >Ativo</span
                          >
                        </div>
                        <div class="col-1">
                          <div
                            class="btn-adicionar"
                            (click)="
                              recursoDesdobramentoFilho1.menuEdivarVisible =
                                !recursoDesdobramentoFilho1.menuEdivarVisible
                            "
                          >
                            <i class="fas fa-ellipsis-v"></i>
                          </div>
                          <div
                            class="menu-detalhes"
                            (mouseleave)="
                              recursoDesdobramentoFilho1.menuEdivarVisible = false
                            "
                            [hidden]="
                              !recursoDesdobramentoFilho1.menuEdivarVisible
                            "
                          >
                            <ul>
                              <li>
                                <a
                                  (click)="
                                    handleAdicionarOcorrencia(
                                      recursoDesdobramentoFilho1
                                    )
                                  "
                                  >Adicionar Ocorrência</a
                                >
                              </li>
                              <li>
                                <a
                                  (click)="
                                    editarRecursoDesdobramento(
                                      recursoDesdobramentoFilho1,
                                      i
                                    )
                                  "
                                  >Editar</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-6">
                          <span
                            class="ocorrencia-label"
                            *ngIf="recursoDesdobramentoFilho1.numero != null"
                            >Número: </span
                          >{{ recursoDesdobramentoFilho1.numero }}
                        </div>
                        <div class="col-6" *ngIf="i == 0"></div>
                        <div class="col-6" *ngIf="i > 0">
                          <span class="ocorrencia-label">{{
                            recursoDesdobramentoFilho1.partes[0]
                              .qualificacaoNome
                          }}</span
                          >:
                          {{ recursoDesdobramentoFilho1.partes[0].nome }}
                        </div>
                        <div class="col-6">
                          <span
                            class="ocorrencia-label"
                            *ngIf="recursoDesdobramentoFilho1.juizo != null"
                            >Juízo: </span
                          >{{ recursoDesdobramentoFilho1.juizo }}
                        </div>
                        <div class="col-6" *ngIf="i == 0"></div>
                        <div class="col-6" *ngIf="i > 0">
                          <span class="ocorrencia-label">{{
                            recursoDesdobramentoFilho1.partes[1]
                              .qualificacaoNome
                          }}</span
                          >:
                          {{ recursoDesdobramentoFilho1.partes[1].nome }}
                        </div>
                        <div class="col-6">
                          <span
                            class="ocorrencia-label"
                            *ngIf="recursoDesdobramentoFilho1.juizRelator"
                            >Juiz:</span
                          >{{ recursoDesdobramentoFilho1.juizRelator }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-1"></div>
                        <div class="col-11">
                          <ocorrencias-detalhes
                            recursoDesdobramentoId="{{
                              recursoDesdobramentoFilho1.id
                            }}"
                            (changeRefresh)="changeRefresh($event)"
                          ></ocorrencias-detalhes>
                        </div>
                      </div>
                    </div>

                    <!--nivel 4-->
                    <div
                      class="col-12"
                      *ngIf="
                        recursoDesdobramentoFilho1.processoJudicialRecursoDesdobramentos !=
                          null &&
                        recursoDesdobramentoFilho1
                          .processoJudicialRecursoDesdobramentos.length > 0
                      "
                    >
                      <div
                        class="row"
                        *ngFor="
                          let recursoDesdobramentoFilho2 of recursoDesdobramentoFilho1.processoJudicialRecursoDesdobramentos;
                          let i = index
                        "
                      >
                        <div class="col-3"></div>
                        <div class="col-9">
                          <div class="row box box-historico">
                            <div class="col-9">
                              <h5>
                                <i class="fas fa-landmark"></i>
                                {{ recursoDesdobramentoFilho2.instanciaNome }}
                              </h5>
                            </div>
                            <div class="col-2">
                              <span
                                *ngIf="recursoDesdobramentoFilho2.ativo"
                                class="historico-ativo"
                                >Ativo</span
                              >
                            </div>
                            <div class="col-1">
                              <div
                                class="btn-adicionar"
                                (click)="
                                  recursoDesdobramentoFilho2.menuEdivarVisible =
                                    !recursoDesdobramentoFilho2.menuEdivarVisible
                                "
                              >
                                <i class="fas fa-ellipsis-v"></i>
                              </div>
                              <div
                                class="menu-detalhes"
                                (mouseleave)="
                                  recursoDesdobramentoFilho2.menuEdivarVisible = false
                                "
                                [hidden]="
                                  !recursoDesdobramentoFilho2.menuEdivarVisible
                                "
                              >
                                <ul>
                                  <li>
                                    <a
                                      (click)="
                                        handleAdicionarOcorrencia(
                                          recursoDesdobramentoFilho2
                                        )
                                      "
                                      >Adicionar Ocorrência</a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      (click)="
                                        editarRecursoDesdobramento(
                                          recursoDesdobramentoFilho2,
                                          i
                                        )
                                      "
                                      >Editar</a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-6">
                              <span
                                class="ocorrencia-label"
                                *ngIf="
                                  recursoDesdobramentoFilho2.numero != null
                                "
                                >Número: </span
                              >{{ recursoDesdobramentoFilho2.numero }}
                            </div>
                            <div class="col-6" *ngIf="i == 0"></div>
                            <div class="col-6" *ngIf="i > 0">
                              <span class="ocorrencia-label">{{
                                recursoDesdobramentoFilho2.partes[0]
                                  .qualificacaoNome
                              }}</span
                              >:
                              {{ recursoDesdobramentoFilho2.partes[0].nome }}
                            </div>
                            <div class="col-6">
                              <span
                                class="ocorrencia-label"
                                *ngIf="recursoDesdobramentoFilho2.juizo != null"
                                >Juízo: </span
                              >{{ recursoDesdobramentoFilho2.juizo }}
                            </div>
                            <div class="col-6" *ngIf="i == 0"></div>
                            <div class="col-6" *ngIf="i > 0">
                              <span class="ocorrencia-label">{{
                                recursoDesdobramentoFilho2.partes[1]
                                  .qualificacaoNome
                              }}</span
                              >:
                              {{ recursoDesdobramentoFilho2.partes[1].nome }}
                            </div>
                            <div class="col-6">
                              <span
                                class="ocorrencia-label"
                                *ngIf="recursoDesdobramentoFilho2.juizRelator"
                                >Juiz:</span
                              >{{ recursoDesdobramentoFilho2.juizRelator }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-11">
                              <ocorrencias-detalhes
                                recursoDesdobramentoId="{{
                                  recursoDesdobramentoFilho2.id
                                }}"
                                (changeRefresh)="changeRefresh($event)"
                              >
                              </ocorrencias-detalhes>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <!-- <li [ngbNavItem]="5">
            <a ngbNavLink>Acordo</a>
            <ng-template ngbNavContent></ng-template>
        </li>
        <li [ngbNavItem]="6">
            <a ngbNavLink>Valores</a>
            <ng-template ngbNavContent></ng-template>
        </li> -->
    <!-- <li [ngbNavItem]="7">
            <a ngbNavLink>Documentos</a>
            <ng-template ngbNavContent>
                <div class="card mt-2">
                    <div class="card-body">
                        <documentos-resumo [tipo]="1" [referenciaId]="id" [raiz]="raiz"></documentos-resumo>
                    </div>
                </div>
            </ng-template>
        </li> -->
    <li [ngbNavItem]="8">
      <a ngbNavLink>Atendimento</a>
      <ng-template ngbNavContent>
        <div class="card mt-2">
          <div class="card-body">
            <atendimento-resumo
              [tipo]="1"
              [referenciaId]="id"
              [raiz]="raiz"
              [hasFilter]="false"
            ></atendimento-resumo>
          </div>
        </div>
      </ng-template>
    </li>
    <!-- <li [ngbNavItem]="9">
            <a ngbNavLink>Honorário</a>
            <ng-template ngbNavContent>
                <div class="card mt-2">
                    <div class="card-body">
                        <honorarios-resumo [tipo]="1" [referenciaId]="id" [raiz]="raiz"></honorarios-resumo>
                    </div>
                </div>
            </ng-template>
        </li> -->
  </ul>
  <div [ngbNavOutlet]="navProcesso" class="mt-2"></div>
</div>
