export enum TipoRegimeBens {
  ComunhaoParcialBens = 0,
  CommunhaoUniversalBens = 1,
  SeparacaoBens = 2,
  ParticipacaoFinalAquestos = 3
}

export const TipoRegimeBensLabel = new Map<TipoRegimeBens, string>([
  [TipoRegimeBens.ComunhaoParcialBens, "Comunhão parcial de bens"],
  [TipoRegimeBens.CommunhaoUniversalBens, "Comunhão Universal de bens"],
  [TipoRegimeBens.SeparacaoBens, "Separação de bens"],
  [TipoRegimeBens.ParticipacaoFinalAquestos, "Participação final nos aquestos"]
]);
