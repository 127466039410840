import { Injectable } from "@angular/core";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable()
export class ExportProcessListService {
  constructor() {}

  public exportPDF() {
    let docDefinition: TDocumentDefinitions = {
      pageSize: "A4",
      pageMargins: 20,
      defaultStyle: {
        fontSize: 8,
      },
      content: [
        {
          margin: [0, 0, 20, 0],
          columns: [
            [
              {
                text: "Listagem de processos completo",
                fontSize: 13,
                bold: true,
              },
            ],
            [
              {
                text: `CnpjCpf: 43.295.831/0001-40`,
                style: "infoHeader",
              },
              {
                text: `Cliente: INTERLAB FARMACEUTICA LTDASituação: 12-`,
                style: "infoHeader",
              },
              {
                text: `AJUIZADO`,
                style: "infoHeader",
              },
            ],
          ],
        },
        {
          columns: [
            {
              text: "",
              margin: [0, 0, 0, 15],
            },
          ],
        },
        {
          layout: "lightHorizontalLines",
          table: {
            widths: [80, 150, 80, 180],
            body: [
              ["", "", "", ""],
              [
                { text: "Número Pasta:", bold: true },
                { text: "0091.0001" },
                { text: "CNPJ Devedor:", bold: true },
                { text: "00.204.125/0001-33" },
              ],
              [
                { text: "Número Processo:", bold: true },
                { text: "0718677-52.2013.8.02.0001" },
                { text: "Data da distribuição:", bold: true },
                { text: "30/05/2013" },
              ],
              [
                { text: "Requerente:", bold: true },
                { text: "INTERLAB FARMACEUTICA LTDA" },
                { text: "Requerido: ", bold: true },
                {
                  text: "SECRETARIA MUNICIPAL DE SAUDE DE MACEIÓ",
                  fontSize: 8,
                },
              ],
              [
                { text: "Ação:", bold: true },
                { text: "AÇÃO MONITÓRIA" },
                { text: "Advogado: ", bold: true },
                { text: "EDINEIA SANTOS DIAS E ANA LUCIA BRITO" },
              ],
              [
                { text: "Comarca:", bold: true },
                { text: "MACEIO" },
                { text: "Valor da ação:", bold: true },
                { text: "40.339,35", bold: true },
              ],
              [
                { text: "Vara:", bold: true },
                { text: "14A. VARA CIVEL" },
                {},
                {},
              ],
              [
                {
                  text: "Possibilidade de perda de processo:",
                  bold: true,
                  fontSize: 8,
                },
                { text: "POSSIVEL" },
                {},
                {},
              ],
            ],
          },
        },
        {
          columns: [
            {
              text: "",
              margin: [0, 0, 0, 15],
            },
          ],
        },
        {
          layout: "lightHorizontalLines",
          table: {
            widths: [80, 150, 280],
            body: [
              [
                { text: "Data do andamento", bold: true },
                { text: "Tipo do andamento", bold: true },
                { text: "Andamento", bold: true },
              ],
              ["", "", ""],
            ],
          },
        },
        {
          layout: "noBorders",
          table: {
            widths: [80, 150, 280],
            body: [
              ["", "", ""],
              [
                { text: "05/01/2015" },
                { text: "Andamento" },
                {
                  text: `Trata-se de ação monitória. Distribuída em maio de 2013. Em agosto de 2013 o Juízo determinou a citação do Município para pagamento ou
              oposição de embargos monitórios.
              `,
                },
              ],
              [
                { text: "05/01/2015" },
                { text: "Andamento" },
                {
                  text: `Trata-se de ação monitória. Distribuída em maio de 2013. Em agosto de 2013 o Juízo determinou a citação do Município para pagamento ou
              oposição de embargos monitórios.
              `,
                },
              ],
            ],
          },
        },
      ],
      styles: {
        infoHeader: {
          alignment: "right",
          italics: true,
          bold: true,
        },
      },
    };
    pdfMake.createPdf(docDefinition).download();
  }
}
