import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';


// *******************************************************************************

//

@NgModule({
  imports: [RouterModule.forChild([
  ])],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
