import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ComarcaService } from 'src/app/services/comarca.service';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';

@Component({
    selector: 'app-modal-cadastro-comarca',
    templateUrl: './modal-cadastro-comarca.component.html',
    providers: [ComarcaService, DadosCargasService]
})
export class ModalCadastroComarcaComponent implements OnInit {


    modalRef: NgbModalRef;
    public comarca: any;
    public telefones: any[] = [];
    public tiposTelefones = [];
    public settingsTelefones: any;


    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private comarcaService: ComarcaService,
        private dadosCargasService: DadosCargasService,
    ) {
    }

    popularNovaComarca() {

        this.comarca = {
            nome: '',
        };
    }

    ngOnInit(): void {

        this.tiposTelefones = this.modalRef.componentInstance.camposComplementares.tiposTelefones;
        this.popularNovaComarca();
        this.popularSettingsTabelas();
    }

    cadastrar() {

        this.add(this.comarca);
    }

    add(comarca) {

        this.spinner.show();
        this.toastrService.clear();

        this.comarcaService.add(comarca).subscribe(res => {
            this.toastrService.success("Comarca cadastrada.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar a comarca!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    popularSettingsTabelas() {

        var settings = {
            actions: {
                columnTitle: "Ações",
                position: "right"
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
            },
            pager: {
                display: false
            },
            columns: {
                nome: {
                    title: 'Nome',
                    filter: false,
                    width: '85%'
                }
            },
            attr: {
                class: 'table table-striped table-bordered'
            }
        };


    }

    close() {

        this.modalRef.close();
    }

    dismiss() {

        this.modalRef.dismiss();
    }
}
