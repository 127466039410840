<div class="modal-class">
  <!-- Modal template -->
  <div class="modal-header">

      <h5 class="modal-title">
          Tarefa
      </h5>
      <button type="button" class="close" (click)="dismiss()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="col-12 d-flex">
      <label class="form-label">{{tarefa.descricao}}</label>
    </div>
    <div class="col-12 d-flex">
      <label class="form-label">Data:</label>
      <p>{{ tarefa.dataTarefa | date : 'dd/MM/yy'}}</p>
    </div>
    <div class="col-12 d-flex">
      <label class="form-label">Responsável:</label>
      <p>{{ tarefa.responsavelNome}}</p>
    </div>
    <div class="col-12 d-flex">
      <label class="form-label">Prioridade:</label>
      <p>{{tarefa.prioridadeDescricao}}</p>
    </div>
  </div>

</div>
