<div class="modal-header">

    <h5 class="modal-title">
        Parcelas do Acordo Minuta Principal e Honorários
    </h5>
    <button type="button" class="close" (click)="dismiss()">&times;</button>
</div>
<div class="modal-body">
    <ul ngbNav #acordoDetalhes="ngbNav" class="nav-tabs">        
        
        <li ngbNavItem>
            <a ngbNavLink>Fluxo Pagamentos do Acordo</a>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-12 mt-3 text-center" *ngIf="loadingAcordo">
                        <i class="fas fa-spinner fa-pulse"></i> Carregando acordo ...
                    </div>
                    <div class="row" *ngIf="!loadingAcordo" >
                        <div class="col-md-12">
                            <div class="row" *ngFor="let acordo of acordos">
                                <div class="col-md-12 mt-5">
                                   <h2>{{acordo.tipoAcordoJudicialId == 2 ? 'Honorários' : 'Acordo Principal'}}</h2>
                                </div>
                                <div class="col-md-12" *ngIf="!loadingAcordo && acordo && acordo.dividas && acordo.dividas.length > 0">
                                    <div>
                                        <table class="table product-discounts-edit espacamento-tabela-dividas">
                                            <thead>
                                                <tr>
                                                    <th>Tipo Parcela</th>
                                                    <th>Parcela</th>
                                                    <th>Emissão</th>
                                                    <th>Vencimento</th>
                                                    <th>Valor Juros</th>
                                                    <!--th>Valor Multa</th-->
                                                    <th width="50px">Índice correção</th>
                                                    <th>Valor cobrança</th>
                                                    <th>Valor Pago</th>
                                                    <th>Data Pgto.</th>
                                                    <th>Desconto</th>
                                                    <th>Saldo</th>
                                                    <th>Ação</th> <!--removido por enquanto-->
                                                    <!-- <th>Tipo</th> -->
                                                </tr>
                                            </thead>
                                            <tbody style="font-size: 8pt">
                                                <tr *ngFor="let parcela of acordo.dividas">
                                                    <td>{{parcela.tipoParcela}}</td>
                                                    <td>{{formatarParcela(parcela.parcela, acordo.parcelas)}}</td>                                                    
                                                    <td>{{parcela.dataEmissao | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{parcela.dataVencimento | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{acordo.percentualJuros}}</td>
                                                    <!--td>{{acordo.percentualMulta}}</td-->
                                                    <td>{{acordo.percentualIndiceCorrecao ? acordo.percentualIndiceCorrecao : 0 }}</td>
                                                    <td>{{parcela.valorAberto | currency: 'BRL'}}</td>
                                                    <td>{{(parcela.valorPago ? parcela.valorPago : 0) | currency: 'BRL'}}</td>
                                                    <td>{{parcela.dataPagamento | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{(parcela.valorDesconto ? parcela.valorDesconto : 0) | currency: 'BRL'}}</td>
                                                    <td>{{(parcela.valorAberto - (parcela.valorDesconto? parcela.valorDesconto : 0) )- (parcela.valorPago ? parcela.valorPago : 0) | currency: 'BRL'}}</td>
        
                                                    <td>
                                                        <button *ngIf="!parcela.valorPago" type="button" class="btn btn-primary btn-sm" (click)="abrirModalPagamentoAcordo(acordo, parcela)">
                                                            <i class="fas fa-file-invoice-dollar"></i> Lançar Pagamento
                                                        </button>
                                                        <button *ngIf="parcela.valorPago" type="button" class="btn btn-primary btn-sm" (click)="abrirModalPagamentoAcordo(acordo, parcela)">
                                                            <i class="fas fa-edit"></i>
                                                        </button>                                                        
                                                        <button *ngIf="parcela.arquivoId" type="button" class="btn btn-warning btn-sm" (click)="downloadAnexo(parcela)">
                                                            <i class="fas fa-paperclip"></i>
                                                        </button>
                                                        <button *ngIf="parcela.valorPago" type="button" class="btn btn-danger btn-sm" (click)="removerParcela(parcela.id)">
                                                            <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-12 mt-1 mb-2">
                                        <div class="float-right">
                                            Índice: {{ acordo.indiceNome ? acordo.indiceNome : " - " }}
                                        </div>
                                    </div>
                                    <div class="col-12 mt-5 mb-2">                            
                                        <div class="float-right">
                                            <ul>
                                                <li><b>Total do acordo: {{(acordo.valorTotal) | currency: 'BRL'}}</b></li>
                                                <li><b>Total Pago: {{acordo.totalPago | currency: 'BRL'}}</b></li>
                                                <li><b>Total Desconto: {{acordo.totalDesconto| currency: 'BRL'}}</b></li>
                                                <li><b>Total em aberto: {{acordo.totalAberto | currency: 'BRL'}}</b></li>
                                            </ul>                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem *ngIf="possuiAcordoPrincipal()">
            <a ngbNavLink>Ocorrências de Acordo Principal</a>
            <ng-template ngbNavContent>
                <app-historico-acordo-judicial [acordoId]="obterAcordoId(true)"></app-historico-acordo-judicial>
            </ng-template>
        </li>
        <li ngbNavItem *ngIf="possuiAcordoHonorario()">
            <a ngbNavLink>Ocorrências Honorários</a>
            <ng-template ngbNavContent>
                <app-historico-acordo-judicial [acordoId]="obterAcordoId(false)"></app-historico-acordo-judicial>
            </ng-template>
        </li>              
    </ul>
    <div class="row body-card">
        <div class="col-12" [ngbNavOutlet]="acordoDetalhes"></div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">Fechar</button>
</div>
