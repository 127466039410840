import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SituacaoCasoProcessoJudicialService } from "src/app/services/situacao-caso-processo-judicial.service";

@Component({
    selector: 'situacao-caso-processo-judicial-dropdown',
    templateUrl: './situacao-caso-processo-judicial-dropdown.component.html'
})
export class SituacaoCasoProcessoJudicialDropdownComponent implements OnInit {

    @Input() situacaoId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() situacaoIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public situacoes: any = [];
    public loadSituacoes: boolean = false;
    public situacao: any;

    constructor(
        private _situacaoCasoProcessoJudicialService: SituacaoCasoProcessoJudicialService,
    ) { }

    ngOnInit(): void {

        if (this.situacaoId != undefined && this.situacaoId != null && this.situacaoId != '') {
            this.buscarPorId();
        }
        else {
            this.buscarSituacao('a');
        }
    }

    changeSituacao($event) {

        this.situacaoIdChange.emit(this.situacaoId);
        this.change.emit(this.situacoes.filter(x => x.id == this.situacaoId)[0]);
    }

    filtrar(term: any) {

        if (term.target.value != '')
            this.buscarSituacao(term.target.value);
    }

    buscarSituacao(buscar) {

        this.loadSituacoes = true;

        this._situacaoCasoProcessoJudicialService.obterSituacaoCasoProcessoJudicialFiltroNomeAsync(buscar).then(x => {

            this.loadSituacoes = false;
            this.situacoes = x.data;

            if (this.situacao != undefined) {
                this.situacoes.push(this.situacao);
            }
        });
    }

    buscarPorId() {

        this.loadSituacoes = true;
        this._situacaoCasoProcessoJudicialService.getByIdAsync(this.situacaoId)
            .then(res => {
                
                this.loadSituacoes = false;
                let situacoes = [];
                situacoes.push(res.data);
                this.situacao = res.data;
                this.situacoes = situacoes;
            });
    }

    limparSituacao() {

        this.situacaoIdChange.emit(null);
    }
}