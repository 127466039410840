import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ComarcaService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'comarca/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    buscarComarcas(filtro): Observable<any> {
        return this.http.get(environment.api + this.endpoint, filtro)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );

        // return of([
        //     { Id: "45858", Nome: "Comarca", Contato: "J B DE OLIVEIRA SOBRINHO F", Logradouro: "", Complemento: "Residencial",Bairro: "Santa Terezinha",Cidade: "Taubaté",Estado: "SP",Cep: "12422-320",
        //     telefones:[]
        //     }

        //    ]
        // ).pipe(
        //     map(this.extractData)
        // )

    }

    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllTelefonesComarca(idComarca): Observable<any> {
        return this.http.get(environment.api + this.endpoint + `${idComarca}/telefone`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addTelefone(telefone): Observable<any> {
        return this.http.post(environment.api + this.endpoint + "telefone", telefone)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    updateTelefone(telefone): Observable<any> {
        return this.http.put(environment.api + this.endpoint + "telefone", telefone)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    deleteTelefone(idComarca, idTelefone): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + `${idComarca}/telefone/${idTelefone}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }


    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByIdAsync(id) {
        return this.http
            .get(environment.api + this.endpoint + `getById/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

    obterComarcaFiltroNomeAsync(busca) {
        return this.http
            .get(environment.api + this.endpoint + "obter-comarca-filtro/" + busca)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

}
