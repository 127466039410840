import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InstanciasService } from 'src/app/services/instancias.service';

@Component({
    selector: 'instancia-dropdown',
    templateUrl: './instancia-dropdown.component.html'
})
export class InstanciaDropdownComponent implements OnInit {

    @Input() instanciaId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() instanciaIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public instancias: any = [];
    public loadInstancias: boolean = false;
    public instancia: any;

    constructor(
        private _instanciaService: InstanciasService
    ) { }

    ngOnInit(): void {

        if (this.instanciaId != undefined && this.instanciaId != null && this.instanciaId != '') {
            this.buscarPorId();
        } else {
            this.buscarInstancia('a');
        }
    }

    changeInstancia($event) {

        this.instanciaIdChange.emit(this.instanciaId);
        this.change.emit(this.instancias.filter(x => x.id == this.instanciaId)[0]);
    }

    filtrar(term: any) {

        if (term.target.value != '')
            this.buscarInstancia(term.target.value);
    }

    buscarInstancia(buscar) {

        this.loadInstancias = true;

        this._instanciaService.obterInstanciaFiltroNomeAsync(buscar).then(x => {

            this.loadInstancias = false;
            this.instancias = x.data;

            if (this.instancia != undefined) {
                this.instancias.push(this.instancia);
            }
        });
    }

    buscarPorId() {

        this.loadInstancias = true;
        this._instanciaService.getByIdAsync(this.instanciaId)
            .then(res => {
                this.loadInstancias = false;
                let instancias = [];
                instancias.push(res.data);
                this.instancia = res.data;
                this.instancias = instancias;
            })
    }

    limparInstancia() {

        this.instanciaIdChange.emit(null);
    }
}
