<div>
    <!-- Modal template -->
    <div class="modal-header">

        <h5 class="modal-title">
            Acordo
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="loadingAcordo">
            <div class="col-12">
                <div
                    style="min-height: 340px;;display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">

                    <span>Buscando dados do acordo...</span>
                    <span>Aguarde alguns instantes...</span>
                    <div class="spinner-border spinner-border-sm text-info m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loadingAcordo">
          <div *ngFor="let acordo of acordo" class="col-12">
            <div class="row">
                <div class="col-12" *ngIf="acordo && acordo.dividas && acordo.dividas.length > 0">
                <div>
                    <table class="table product-discounts-edit espacamento-tabela-dividas">
                        <thead>
                            <tr>
                                <th>Tipo Parcela</th>
                                <th>Emissão</th>
                                <th>Vencimento</th>
                                <!-- <th>Valor original</th> -->
                                <th>Valor cobrança</th>
                                <!-- <th>Juros</th>
                                <th>Multa</th> -->
                                <!-- <th>Lançamento</th> -->
                                <!-- <th>Tipo</th> -->
                            </tr>
                        </thead>
                        <tbody style="font-size: 8pt">
                            <tr *ngFor="let parcela of acordo.dividas">
                                <td>{{parcela.tipoParcela}}</td>
                                <td>{{parcela.dataEmissao | date: 'dd/MM/yyyy'}}</td>
                                <td>{{parcela.dataVencimento | date: 'dd/MM/yyyy'}}</td>
                                <!-- <td>{{parcela.valorOriginal | currency: 'BRL'}}</td> -->
                                <td>
                                    <input type="text" style="width: 150px;" currencyMask
                                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                                    [(ngModel)]="parcela.valorAberto" (blur)="calcularSimulacao(parcela,acordo)"
                                    *ngIf="acordo.situacaoJudicial !='Aprovado'"/>
                                    <label *ngIf="acordo.situacaoJudicial =='Aprovado'">
                                        {{parcela.valorAberto | currency: 'BRL'}}
                                    </label>
                                </td>
                                <!-- <td>{{(parcela.juros) | currency: 'BRL'}}</td>
                                <td>{{(parcela.multa) | currency: 'BRL'}}</td> -->
                                <!-- <td>{{parcela.lancamento}}</td> -->
                                <!-- <td>{{parcela.tipo}}</td> -->
                        </tbody>
                    </table>
                </div>                
                <div class="col-12 mt-1 mb-2" style="display: initial;">
                    <div class="float-right" style="display: inline-flex;margin-bottom: 10px;">                        
                        <!--select (change)="atualizarSituacaoDivida(acordo)" [(ngModel)]="acordo.situacaoAcordoJudicialDivida" class="custom-select" style="margin: 0 10px;" >
                          <option *ngFor="let item of tipos"   [ngValue]="item.nome">{{item.nome}}</option>
                        </select-->
                        <b>Total a pagar: {{(acordo.valorTotal) | currency: 'BRL'}}</b>
                    </div>
                </div>                
                </div>
                <app-historico-acordo-judicial [acordoId]="acordo.id" style="width: 100%;"></app-historico-acordo-judicial>
          </div>
        </div>

    </div>
    <div class="modal-footer" *ngIf="!loadingAcordo">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-success ng-star-inserted" *ngIf="!inEdit && acordo[0].situacaoJudicial !='Aprovado'" (click)="aprovar()"><i class="fas fa-check-circle"></i> Aprovar Acordo</button>
        <button type="button" class="btn btn-primary" (click)="salvar()" *ngIf="inEdit && acordo[0].situacaoJudicial !='Aprovado'"><i class="fas fa-save"></i> Salvar Simulação</button>
        <button type="button" class="btn btn-danger" (click)="recarregar()" *ngIf="inEdit && acordo[0].situacaoJudicial !='Aprovado'"><i class="fas fa-sync"></i> Refazer Simulação</button>
    </div>
</div>
