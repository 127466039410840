<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">Operacionais / Processos Judiciais e Casos /</span> 
    <span *ngIf="isAddMode">Cadastrar</span><span *ngIf="!isAddMode">Editar</span> Caso</span>
</h4>
<div class="container">
    <form ngNoForm>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Pasta</label>
                <input type="text" maxlength="255" class="form-control" maxlength="255" placeholder="Digite o nome ou número da pasta"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="caso.pasta">
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <label class="form-label d-flex align-items-center">
                    Cliente<span class="required-field ml-1">*</span>
                    <button type="button" class="btn btn-primary btn-xs ml-3" (click)="adicionarCliente()"><i class="fas fa-plus"></i></button>
                </label>
            </div>
        </div>
        <div class="form-row" *ngFor="let cliente of caso.clientes; let i = index">
            <div class="form-group col">                
                <pessoa-dropdown [(pessoaId)]="cliente.pessoaId"></pessoa-dropdown>
            </div>            
            <div class="form-group col-1">
                <button type="button" class="btn btn-danger" (click)="removerCliente(i)"><i class="fas fa-trash"></i></button>
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <label class="form-label d-flex align-items-center">
                    Outros envolvidos 
                    <button type="button" class="btn btn-primary btn-xs ml-3" (click)="adicionarEnvolvido()"><i class="fas fa-plus"></i></button>
                </label>
            </div>
        </div>
        <div class="form-row" *ngFor="let envolvido of caso.envolvidos; let i = index">
            <div class="form-group col">                
                <pessoa-dropdown [(pessoaId)]="envolvido.pessoaId"></pessoa-dropdown>
            </div>
            <div class="form-group col-4">
                <ng-select [items]="qualificacoes" [(ngModel)]="envolvido.qualificacaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Qualificação" [loading]="loadQualificacoes"></ng-select>
            </div>
            <div class="form-group col-1">
                <button type="button" class="btn btn-danger" (click)="removerEnvolvido(i)"><i class="fas fa-trash"></i></button>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Título<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" placeholder="Digite o título do caso" [(ngModel)]="caso.titulo">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Tags</label>
                <angular2-multiselect [data]="tags" [(ngModel)]="caso.tags" [settings]="tagDropdownSettings"
                    [ngModelOptions]="{standalone: true}">
                    <c-item>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-item>
                    <c-badge>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-badge>
                </angular2-multiselect>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Descrição</label>
                <textarea maxlength="1000" rows="5" class="form-control" placeholder="Digite a descrição do caso" [(ngModel)]="caso.descricao"></textarea>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Observações</label>
                <textarea maxlength="1000" rows="5" class="form-control" placeholder="Digite mais detalhes" [(ngModel)]="caso.observacao"></textarea>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Responsável <span class="required-field">*</span></label>                
                <pessoa-dropdown [(pessoaId)]="caso.responsavelId"></pessoa-dropdown>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Acesso</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" [(ngModel)]="caso.tipoAcesso"
                            [ngModelOptions]="{standalone: true}" id="acessoPublico" value="Publico">
                        <label class="form-check-label" for="acessoPublico">Público</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" [(ngModel)]="caso.tipoAcesso"
                            [ngModelOptions]="{standalone: true}" id="acessoPrivado" value="Privado">
                        <label class="form-check-label" for="acessoPrivado">Privado</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" [(ngModel)]="caso.tipoAcesso"
                            [ngModelOptions]="{standalone: true}" id="acessoEquipe" value="Equipe">
                        <label class="form-check-label" for="acessoEquipe">Equipe</label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="caso.tipoAcesso == 'Equipe'">
            <div class="form-row">
                <div class="col">
                    <label class="form-label d-flex align-items-center">
                        Selecione os membros da Equipe<span class="required-field ml-1">*</span>
                        <button type="button" class="btn btn-primary btn-xs ml-3" (click)="adicionarMembroEquipe()"><i class="fas fa-plus"></i></button>
                    </label>
                </div>
            </div>
            <div class="form-row" *ngFor="let membroEquipe of caso.membrosEquipe; let i = index">
                <div class="form-group col">                    
                    <pessoa-dropdown [(pessoaId)]="membroEquipe.pessoaId"></pessoa-dropdown>
                </div>
                <div class="form-group col-1">
                    <button type="button" class="btn btn-danger" (click)="removerMembroEquipe(i)"><i class="fas fa-trash"></i></button>
                </div>
            </div>
        </div>
        <hr>
        <div class="d-flex justify-content-end">
            <button type="button" (click)="cancel()" class="btn btn-light mr-3">Cancelar</button>
            <button type="button" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
        </div>
    </form>
</div>