import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-modal-detalhes-contato-processos-amigaveis',
    templateUrl: './modal-detalhes-contato-processos-amigaveis.component.html'
})
export class ModalDetalhesContatoProcessosAmigaveisComponent implements OnInit {

    ngOnInit(): void {
        this.detalheContato = this.modalRef.componentInstance.detalheContato;
        this.processo = this.modalRef.componentInstance.processo;
        this.ordernarContato();
    }

    modalRef: NgbModalRef;
    isIE10 = false;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService
    ) {

        this.isIE10 = this.appService.isIE10;
    }

    public detalheContato: any;
    public processo: any;
    public historicoContato: any;

    ordernarContato(){
        this.processo.Contatos.sort(function (a, b) {
            if (a.Votos > b.Votos) {
                return  -1;
            }
            if (b.Votos > a.Votos) {
                return  1;
            }
            return 0;
        });
    }

    votarContato(contato) {
        this.processo.Contatos.forEach(c => {
            c.Numero = c.Numero.replace('- último telefone votado.', '');
        });

        this.processo.Contatos = this.processo.Contatos.filter(c => c.Codigo != contato.Codigo);

        contato.Numero = contato.Numero + '- último telefone votado.';
        contato.Votos++;

        this.processo.Contatos.sort(function (a, b) {
            if (a.Votos > b.Votos) {
                return  -1;
            }
            if (b.Votos > a.Votos) {
                return  1;
            }
            return 0;
        });

        this.processo.Contatos.unshift(contato);
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
