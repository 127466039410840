<div class="card p-3 mb-3">
    <h4>Dados do cálculo</h4>

    <table class="table">
        <tbody [formGroup]="calculationDataForm">
            <tr *ngFor="let item of dataTable">
                <th scope="row" style="text-align: left; width: 150px;" [ngClass]="item.background">
                    {{item.label}}
                </th>
                <td
                    [ngClass]="{'d-flex': item.type === 'radio', 'white': item.background === 'white', 'grey': item.background === 'grey'}">
                    <!-- Type text -->
                    <div class="input-group" [ngClass]="item.class" *ngIf="item.type==='text' || item.type==='date'">
                        <input [type]="item.type" class="form-control" [formControlName]="item.controlName" (blur)="saveCalc()">
                    </div>

                    <!-- Type textarea -->
                    <div class="form-group" [ngClass]="item.class" *ngIf="item.type==='textarea'">
                        <textarea class="form-control" rows="3" [formControlName]="item.controlName"></textarea>
                    </div>

                    <!-- Type select -->
                    <div class="form-group" *ngIf="item.type==='select'">
                        <!-- <ng-select 
                            [items]="item.items"
                            bindLabel="nome" 
                            bindValue="id"
                            [formControlName]="item.controlName"
                            [loading]="!item.items.length">
                        </ng-select> -->
                        <select class="form-control" [ngClass]="item.class" [formControlName]="item.controlName" (change)="saveCalc()">
                            <option *ngFor="let indice of item.items" [value]="indice.id">{{indice.nome}}</option>
                        </select>
                    </div>

                    <!-- Type checkBox -->
                    <ng-container *ngIf="item.type==='radio'">
                        <div class="form-check" *ngFor="let radio of item.items" [ngClass]="item.class">
                            <input type="radio" [name]="item.controlName" [id]="item.label + radio.label"
                                [value]="radio.value" [formControlName]="item.controlName" (change)="saveCalc()">
                            <label [for]="item.label + radio.label">
                                {{radio.label}}
                            </label>
                        </div>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="card p-3 mb-5 valores-atualizar" [formGroup]="form">
    <div class="d-flex justify-content-between">
        <h4>Valores a atualizar</h4>

        <div class="d-flex">
            <i class="fas fa-bars cursor-pointer mr-2" mat-raised-button matTooltip="Mostrar todos os campos"
                (click)="showAllField = !showAllField"></i>
        </div>
    </div>

    <table>
        <tbody>
            <tr class="grey" style="height: 4em;">
                <th *ngFor="let item of valoresAtualizar" [class]="item.class">
                    {{item.header}}
                    <div *ngIf="item.hasEngrenagem || item.hasCheck">
                        <input *ngIf="item.hasCheck" class="form-check-input" type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.value"
                            id="flexCheckDefault" (change)="item.check(item.value)">
                        <span *ngIf="item.hasEngrenagem" (click)="item.open()"
                            class="material-symbols-outlined cursor-pointer">settings</span>
                    </div>
                </th>
            </tr>

            <ng-container formArrayName="valoresForm" *ngFor="let form of valoresForm.controls; let i = index">
                <ng-container [formGroupName]="i">
                    <tr>
                        <td colspan="2" style="width: 37%;">
                            <div class="row" tabindex="1">
                                <div class="input-group col-12 pb-2" *ngIf="showAllField">
                                    <input type="text" class="form-control" placeholder="Descrição"
                                        [formControlName]="VALORES_FORM_BINDING.DESC">
                                </div>
                                <div class="input-group col-6">
                                    <input type="date" class="form-control"
                                        [formControlName]="VALORES_FORM_BINDING.DATA" (blur)="saveCalc()">
                                </div>
                                <div class="input-group col-6">
                                    <input tabindex="16" class="form-control" type="text" name="calc_valor_2"
                                        id="calc_valor_2" value="" maxlength="20" placeholder="Valor"
                                        [formControlName]="VALORES_FORM_BINDING.VALOR" currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" (blur)="saveCalc()">
                                </div>
                            </div>
                        </td>
                        <td>
                            <div *ngIf="loading" class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span *ngIf="!loading && form.get(VALORES_FORM_BINDING.VALOR_ATUALIZADO).value">
                                {{ form.get(VALORES_FORM_BINDING.VALOR_ATUALIZADO).value | currency: 'BRL' }}
                            </span>                            
                        </td>
                        <td>
                            <div *ngIf="loading" class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span *ngIf="!loading && form.get(VALORES_FORM_BINDING.JUROSMORATORIOS).value">
                                {{ form.get(VALORES_FORM_BINDING.VALOR_JUROS_MORATORIOS).value | currency: 'BRL' }} ({{form.get(VALORES_FORM_BINDING.PERCENTUAL_JUROS_MORATORIOS).value?.toFixed(2)}}%)
                            </span>
                            <span *ngIf="!loading && !form.get(VALORES_FORM_BINDING.JUROSMORATORIOS).value">
                                --
                            </span>
                            <span *ngIf="!loading" class="material-symbols-outlined cursor-pointer">settings</span>
                        </td>
                        <td>
                            <div *ngIf="loading" class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span *ngIf="!loading && form.get(VALORES_FORM_BINDING.JUROSCOMPENSATORIOS).value">
                                {{ form.get(VALORES_FORM_BINDING.VALOR_JUROS_COMPENSATORIOS).value | currency: 'BRL' }} ({{form.get(VALORES_FORM_BINDING.PERCENTUAL_JUROS_COMPENSATORIOS).value?.toFixed(2)}}%)
                            </span>
                            <span *ngIf="!loading && !form.get(VALORES_FORM_BINDING.JUROSCOMPENSATORIOS).value">
                                --
                            </span>
                            <span *ngIf="!loading" class="material-symbols-outlined cursor-pointer">settings</span>
                        </td>
                        <td>
                            <div *ngIf="loading" class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span *ngIf="!loading && form.get(VALORES_FORM_BINDING.MULTA).value">
                                {{ form.get(VALORES_FORM_BINDING.VALORMULTA).value | currency: 'BRL' }}
                            </span>
                            <span *ngIf="!loading && !form.get(VALORES_FORM_BINDING.MULTA).value">
                                --
                            </span>
                            <span *ngIf="!loading" class="material-symbols-outlined cursor-pointer">settings</span>
                        </td>
                        <td>
                            <div *ngIf="loading" class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span *ngIf="!loading && form.get(VALORES_FORM_BINDING.HONORARIO).value">
                                {{ form.get(VALORES_FORM_BINDING.VALORHONORARIO).value | currency: 'BRL' }}
                            </span>
                            <span *ngIf="!loading && !form.get(VALORES_FORM_BINDING.HONORARIO).value">
                                --
                            </span>
                            <span *ngIf="!loading" class="material-symbols-outlined cursor-pointer">settings</span>
                        </td>
                        <td>
                            <div *ngIf="loading" class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        </td>
                        <td>
                            <div *ngIf="loading" class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span *ngIf="!loading && form.get(VALORES_FORM_BINDING.VALOR_TOTAL).value">
                                {{ form.get(VALORES_FORM_BINDING.VALOR_TOTAL).value | currency: 'BRL' }}
                            </span>
                            <span *ngIf="!loading && !form.get(VALORES_FORM_BINDING.VALOR_TOTAL).value">
                                --
                            </span>                            
                        </td>
                    </tr>
                </ng-container>
            </ng-container>

            <tr class="font-weight-bold">
                <td colspan="2">
                    Total
                </td>
                <td>
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">{{ valorTotalAtualizado() | currency: 'BRL' }}</div>
                </td>
                <td> 
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">{{ valorTotalJurosMoratorios() | currency: 'BRL'}}</div>
                </td>
                <td> 
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">{{ valorTotalJurosCompensatorios() | currency: 'BRL'}}</div>
                </td>
                <td> 
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">{{ valorTotalMultas() | currency: 'BRL'}} </div>
                </td>
                <td>
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">{{ valorTotalHonorarios() | currency: 'BRL'}} </div>
                </td>
                <td>
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">0,00</div>
                </td>
                <td>
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">{{ sumUpdateValues() | currency: 'BRL' }}</div>
                </td>
            </tr>

            <tr class="font-weight-bold">
                <td colspan="8">
                    <span class="mr-4">HONORÁRIOS DE SUCUMBÊNCIA</span>
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault" [ngModelOptions]="{standalone: true}" [(ngModel)]="honorariosSucumbencia" (change)="changeHonorarioAdvocativosSucumbenciais()">
                    <span class="material-symbols-outlined cursor-pointer" (click)="openModalHonorarioAdvocativosSucumbenciais()">settings</span>
                </td>
                <td>
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading && honorariosSucumbencia">
                        {{ valorSucumbencia() | currency: 'BRL' }}
                    </div>
                    <div *ngIf="!loading && !honorariosSucumbencia">
                        -
                    </div>
                </td>
            </tr>

            <tr class="grey font-weight-bold">
                <td colspan="8">
                    <span class="mr-4">MULTA DO ART. 523 NCPC</span>
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault" [ngModelOptions]="{standalone: true}" [(ngModel)]="multaArtNCPC" (change)="changeMultaArtNPC()">
                    <span class="material-symbols-outlined cursor-pointer" (click)="openModalMultaNCPC()">settings</span>
                </td>
                <div *ngIf="loading" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div *ngIf="!loading && multaArtNCPC">
                    {{ valormultaArtNCPC() | currency: 'BRL' }}
                </div>
                <div *ngIf="!loading && !multaArtNCPC">
                    -
                </div>
            </tr>

            <tr class="grey font-weight-bold">
                <td colspan="8">
                    Total
                </td>
                <td>
                    <div *ngIf="loading" class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div *ngIf="!loading">{{ valorTotalGeral() | currency: 'BRL' }}</div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="fixed-save d-flex">
    <button type="button" class="btn btn-md btn-padrao mr-2" mat-raised-button
        matTooltip="Voltar" (click)="voltar()"><span class="fas fa-arrow-left"></span></button>

    <button type="button" class="btn btn-md btn-padrao ml" mat-raised-button
        matTooltip="Adicionar nova linha para calcular" (click)="createValorForm()"><span class="fas fa-plus"></span></button>        
</div>