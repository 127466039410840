<!-- Modal template -->
<div class="modal-header">
    <h5 class="modal-title">
        Calendario - Cobrador
    </h5>
    <button type="button" class="close" (click)="dismiss()">&times;</button>
</div>

<div class="modal-body pt-0">
    <div class="card">
        <div class="card-body">


            <!-- New event modal -- usar para detalhe ? -->
            <ng-template #newEventModal let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h5 class="modal-title">Adicionar tarefa</h5>
                    <button type="button" class="close" (click)="d()">&times;</button>
                </div>
                <form class="modal-body" (submit)="$event.preventDefault() || c()">
                    <div class="form-row col-md-12 mt-1">


                        <div class="form-group col-md-6">
                            <label class="form-label">Atividade</label>
                            <input type="text" class="form-control" placeholder="">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="form-label">Processo</label>
                            <input type="text" class="form-control" placeholder="">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="form-label">Data/hora</label>
                            <div class="input-group">
                                <input (click)="d.toggle()" class="form-control" placeholder="dd/mm/yyyy" name="dp" ngbDatepicker
                                    [(ngModel)]="model" [displayMonths]="displayMonths" [navigation]="navigation"
                                    [disabled]="disabled" [placement]="isRTL ? 'bottom-right' : 'bottom-left'"
                                    #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="input-group-text" [disabled]="disabled" (click)="d.toggle()"
                                        type="button">
                                        <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-row col-md-12 mt-1">
                        <div class="form-group col-md-6">
                            <label class="form-label">Situação</label>
                            <select class="custom-select">
                                <option *ngFor="let item of situacoesList" [value]="item">{{item}}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="form-label">Tags</label>
                            <angular2-multiselect [data]="tagsOptions" [(ngModel)]="tagsSelecionadas"
                                [settings]="dropdownSettings">
                            </angular2-multiselect>
                        </div>
                    </div>
                </form>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="d()">Fechar</button>
                    <button type="button" class="btn btn-primary" (click)="c()"><i class="fas fa-save"></i> Salvar</button>
                </div>
            </ng-template>
            <!-- / New event modal -->

            <div class="form-row col-md-12 mt-1">



                <div class="form-group offset-md-3 col-md-6">
                    <label class="form-label">Usuário</label>
                    <angular2-multiselect [data]="tagsOptions" [(ngModel)]="tagsSelecionadas"
                        [settings]="dropdownSettings">
                    </angular2-multiselect>
                </div>

            </div>
            <!-- Calendar header -->
            <div class="d-flex flex-wrap justify-content-center justify-content-lg-between align-items-center mb-4">
                <h3 class="font-weight-light m-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
                <div class="d-flex justify-content-center w-100 w-lg-auto mt-3 mt-lg-0">
                    <div class="btn-toolbar">
                        <div class="btn-group mr-1">
                            <div class="btn btn-primary btn-sm" (click)="view = 'month'"
                                [class.active]="view === 'month'">Mês</div>
                            <div class="btn btn-primary btn-sm" (click)="view = 'week'"
                                [class.active]="view === 'week'">Semana</div>
                            <div class="btn btn-primary btn-sm" (click)="view = 'day'" [class.active]="view === 'day'">
                                Dia</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center w-100 w-lg-auto mt-3 mt-lg-0">
                    <div class="btn-group">
                        <div class="btn btn-primary btn-sm" mwlCalendarPreviousView [view]="view"
                            [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false"><i
                                class="ion ion-ios-arrow-back scaleX--1-rtl"></i></div>
                        <div class="btn btn-primary btn-sm" mwlCalendarToday [(viewDate)]="viewDate">Hoje</div>
                        <div class="btn btn-primary btn-sm" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                            (viewDateChange)="activeDayIsOpen = false"><i
                                class="ion ion-ios-arrow-forward scaleX--1-rtl"></i></div>
                    </div>
                </div>
            </div>

            <!-- Set minimum width for the calendar (only month and week views) -->
            <div [ngClass]="{'overflow-auto': view !== 'day'}">
                <div [ngSwitch]="view" #calendarContainer [style.minWidth]="view !== 'day' ? '600px' : '0'">
                    <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events"
                        [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                        (eventClicked)="eventClicked($event.event)" [refresh]="refresh"
                        (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events"
                        (eventClicked)="eventClicked($event.event)" [refresh]="refresh"
                        (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events"
                        (eventClicked)="eventClicked($event.event)" [refresh]="refresh"
                        (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-day-view>
                </div>
            </div>

        </div>
    </div>
</div>

<hr class="border-light mt-2 mb-2">



<div class="modal-footer">

</div>
