<ng-container>
    <mat-card>
        <mat-card-content>
            <div class="row m-0 detalhes-processo">
                <div class="col-12 p-0">
                    <div class="pb-3 d-flex justify-content-between">
                        <div class="d-flex flex-column" *ngIf="processo != undefined">
                            <span class="mb-1"><b>{{processo.titulo}}</b></span>
                            <span class="text-secondary mb-1">Processo | {{processo.numero}}</span>
                            <span class="text-secondary mb-1">Pasta | {{processo.pasta}}</span>
                            <span class="text-secondary mb-1" *ngIf="!processo.caso && exibirSituacao">Situação | <span [innerHTML]="obterSituacao()"></span></span>
                            <span class="text-secondary" *ngIf="!processo.caso && exibirStatus">Status   | <span [innerHTML]="obterStatus()"></span></span>
                        </div>
                        
                        <!-- <div class="d-flex flex-column">
                            <span class="mb-1">REMOÇÃO DE INVENTARIANTE</span>
                            <span class="text-secondary">1° Vara da Família e Sucessões</span>
                        </div> -->
                    </div>

                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mt-3">
        <mat-card-content>
            <div class="recurso-desdobramento">
                <div class="d-flex justify-content-between" *ngIf="recursoDesdobramento != undefined">
                    <div class="d-flex">
                        <i class="fa fa-user text-secondary mr-2"></i>
                        <span class="mb-2"><b>{{recursoDesdobramento.instanciaNome}}{{recursoDesdobramento.tipoOcorrenciaNome != undefined ? " - "+recursoDesdobramento.tipoOcorrenciaNome : ""}}</b></span>
                    </div>
                    <div class="d-flex justify-content-end" style="width: 31%;">
                        <!-- <b class="text-success">Fase da instância <i class="fa fa-wifi"></i></b>
                        <i class="fa fa-paperclip cursor-pointer"></i> -->
                        <i class="fas fa-ellipsis-v cursor-pointer"></i>
                        <span class="material-symbols-outlined cursor-pointer" style="margin-top: -7px;"
                            (click)="isShow = !isShow">{{ isShow ? 'expand_less' : 'expand_more'}}</span>
                    </div>
                </div>

                <div class="row m-0 mt-1 ml-4" *ngIf="isShow && processo != undefined && !processo.caso">
                    <div class="col-12 p-0">
                        <div class="m-0 d-flex">
                            <div class="d-flex flex-column" *ngIf="recursoDesdobramento!= undefined">
                                <span class="mb-1"><span class="text-secondary">Número:</span>
                                    {{recursoDesdobramento.numero}}</span>
                                <span class="mb-1"><span class="text-secondary">Foro:</span> {{recursoDesdobramento.foroNome}}
                                </span>
                                <!-- <span class="mb-1"><span class="text-secondary">Vara:</span> {{recursoDesdobramento.varaNome}}</span> -->
                            </div>

                            <!-- <div class="d-flex align-self-center ml-5" *ngIf="recursoDesdobramento.partes.length == 2">
                                <div class="row">
                                    <div class="col-12">
                                        <span class="text-secondary">Parte:</span> {{parte1}}                               
                                    </div>
                                </div> 
                                <div class="row">
                                    <div class="col-12">
                                        <span class="text-secondary">Parte:</span> {{parte2}}                               
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="row mt-3" *ngIf="ocorrencia != undefined">
        <div class="titulo col-12">
            <i class="fas fa-file"></i> Detalhes da Ocorrência
            <hr class="mt-1" />
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6">
            <mat-form-field appearance="fill">
                <mat-label class="label-form-field">Tipo *</mat-label>
                <input matInput disabled value="{{ocorrencia.tipoProcessoJudicialOcorrenciaNome}}" />
            </mat-form-field>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6">
            <mat-form-field appearance="fill">
                <mat-label class="label-form-field">Data publicação: </mat-label>
                <input matInput disabled value="{{ocorrencia.dataOcorrencia | date:'dd/MM/yyyy'}}">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6">
            <mat-form-field appearance="fill">
                <mat-label class="label-form-field">Exibir na timeline *</mat-label>
                <input matInput disabled value="{{ocorrencia.exibirTimeLine ? 'Sim' : 'Não'}}" />
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-label class="label-form-field mb-2">Observação</mat-label>
            <mat-form-field appearance="fill" class="w-100 mt-2">
                <textarea matInput disabled value="{{ocorrencia.descricao}}" cdkTextareaAutosize
                    cdkAutosizeMinRows="7" cdkAutosizeMaxRows="8"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="row mt-3">
        <div class="titulo col-12">
            <i class="fas fa-file"></i> Detalhes da Atividade
            <hr class="mt-1" />
        </div>

       
        <app-card-process class="ml-4 col-11" [item]="item" [isIntoModal]="true"></app-card-process>
        
    </div>
</ng-container>