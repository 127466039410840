<div [formGroup]="form" *ngIf="form">
    <!-- Modal template -->
    <div class="modal-header">
      <h5 class="modal-title">Faturar/Reembolsar despesa</h5>
      <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
      <!-- filter -->
  
      <div class="row">
        <div class="form-group col-8">
          <label class="form-label">Nome Cliente</label>
          <input
            type="text"
            class="form-control"
            formControlName="nomeCliente"
            [disabled]="true"
          />
        </div>
        <div class="form-group col-4">
          <label class="form-label">Despesa para reembolsar?</label>
          <ng-select [items]="tiposDespesa" formControlName="tipoDespesa" bindLabel="status"></ng-select>
      </div>
      </div>
  
      <br />
  
      <div class="row">
          <div class="col-12">
              <div ng2FileDrop class="dropzone"
                  [uploader]="uploader" class="dropzone" (click)="fileInput.click()">                    
                  <label for="files" class="dropzone-container">
                      <div class="file-icon">+</div>
                      <div class="dropzone-title">
                          Carregar documento
                      </div>
                  </label>
                  <input formControlName="document" type="file" accept="application/pdf" #fileInput ng2FileSelect [uploader]="uploader" class="file-input" multiple
                      (onFileSelected)="onDrop($event)" />
              </div>
              <div class="row" *ngIf="uploader.queue.length > 0">
                  <div class="col-12" *ngFor="let item of uploader.queue">
                      {{item?.file?.name}}
                      <button type="button" class="btn btn-danger btn-sm" (click)="popItem(item)">
                          <i class="fas fa-trash"></i>
                      </button>
                      
                  </div>
              </div>
          </div>         
      </div>
  
      <br />
  
      <div class="nav-tabs-top nav-responsive-sm">
        <ul
          [(activeId)]="activeTab"
          ngbNav
          #productTabs="ngbNav"
          class="nav-tabs"
        >
          <li ngbNavItem [ngbNavItem]="1" class="full-width">
            <a ngbNavLink>Arquivos</a>
            <ng-template ngbNavContent>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 200px;
                  justify-content: center;
                  align-items: center;
                  align-self: center;
                  align-content: center;
                "
                class="fa-3x"
                *ngIf="loadNaoFaturadas"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </div>
              <table class="table table-striped table-bordered card-table">
                <thead>
                  <tr>
                    <th class="width-90">Nome Arquivo</th>
                    <th>Ação</th>
                  </tr>
                  
                </thead>
                <tbody>
                  <tr *ngFor="let item of listaArquivo">
                    
                      <td class="width-90">{{ item.nome }}</td>
                      <td class="text-center">
                          <button type="button" class="btn btn-primary btn-sm" (click)="baixarItem(item)">
                              <i class="fas fa-download"></i>
                          </button>
                      </td>
                    
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="productTabs"></div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="dismiss()">
        Fechar
      </button>
      <button type="button" class="btn btn-primary" (click)="salvar()">
        <i class="fas fa-save"></i> Salvar
      </button>
    </div>
  </div>
  