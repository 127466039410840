<div>
    <!-- Modal template -->
    <div class="modal-header">
        
        <h5 class="modal-title">
            Vincular outro processo a este            
        </h5>        
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-12">
                <label class="form-label">Processos<span class="required-field">*</span></label>
                <ng-select [items]="processos" [(ngModel)]="segundoProcesso"
                    bindLabel="titulo" bindValue="id" [ngModelOptions]="{standalone: true}" 
                    [loading]="loadProcessos">
                    <ng-template ng-option-tmp let-item="item">
                        <div class="line"><b>{{item.titulo}}</b></div>
                        <div class="line" *ngIf="item.instanciaNome != null">Instância: {{item.instanciaNome}}</div>
                        <div class="line" *ngIf="item.numero != null">Número: {{item.numero}}</div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group col-12">
                <label class="form-label">Tipo<span class="required-field">*</span></label>
                <ng-select [items]="tipoApensos" [(ngModel)]="tipoApenso"
                    bindLabel="nome" bindValue="id" [ngModelOptions]="{standalone: true}" 
                    [loading]="tipoApensos == null || tipoApensos.length == 0">
                    <ng-template ng-option-tmp let-item="item">
                        <div class="line">Tipo: <b>{{item.nome}}</b></div>                        
                        <div class="line">Replica ocorrências: {{item.replicarOcorrencias ? "Sim" : "Não"}}</div>
                    </ng-template>
                </ng-select>
            </div>
        </div>               
    </div>    
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="salvar()"><i class="fas fa-save"></i> Salvar</button>
    </div>
</div>