export enum Tipo {
    Pessoa = 0,
    Ocorrencia = 1,
    Processo = 2,
    Atividade = 3,
    Juridico = 4,
}

export const TipoLabel = new Map<Tipo, string>([

    [Tipo.Pessoa, "Pessoa"],
    [Tipo.Ocorrencia, "Ocorrencia"],
    [Tipo.Processo, "Processo"],
    [Tipo.Atividade, "Atividade"],
    [Tipo.Juridico, "Juridico"]
]);
