import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dataextenso'})
export class DataExtensoPipe implements PipeTransform {
  transform(dataStr: any): string {

    if(dataStr === undefined || dataStr === null || dataStr === "") return "";

    let data = new Date(dataStr);

    var diaDaSemana = this.diaDaSemana(data.getDay());

    var mesDoAno = this.mesDoAno(data.getMonth());

    return `${diaDaSemana}, ${data.getDate()} de ${mesDoAno} ${data.getFullYear()}`;
  }

  diaDaSemana(day){
    return ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"][day];
  }

  mesDoAno(month){
    return["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"][month];
  }
}
