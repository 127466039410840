import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
    selector: 'app-recursos-e-desdobramentos',
    templateUrl: './recursos-e-desdobramentos.component.html',
    styleUrls: ['./recursos-e-desdobramentos.component.scss']
})
export class RecursosEDesdobramentosComponent implements OnInit {

    @Input() id:any;
    @Input() raiz:string;
    @Input() recursosDesdobramentos:any;
    @Input() nivel:number;
    
    @Output() detalheRecursoDesdobramentoEvent = new EventEmitter();
    @Output() handleAdicionarOcorrenciaEvent = new EventEmitter();
    @Output() monitorarPublicacaoEvent = new EventEmitter();
    @Output() ativarDesativarRecursoDesdobramentoEvent = new EventEmitter();

    constructor() { }

    ngOnInit(): void {

    }

    detalheRecursoDesdobramento(recursoDesdobramento: any){
        this.detalheRecursoDesdobramentoEvent.emit(recursoDesdobramento);
    }

    defineClass(classe: string){
        return 'badge ' + classe;
    }

    handleAdicionarOcorrencia(recursoDesdobramento: any){
        this.handleAdicionarOcorrenciaEvent.emit(recursoDesdobramento);
    }

    handleAnexosRecurososDesdobramentos(recursoDesdobramento: any, aba: any){

    }

    monitorarPublicacao(recursoDesdobramento: any){
        this.monitorarPublicacaoEvent.emit(recursoDesdobramento);
    }

    ativarDesativarRecursoDesdobramento(recursoDesdobramento: any){
        this.ativarDesativarRecursoDesdobramentoEvent.emit(recursoDesdobramento);
    }

    gerarCssColuna(selecionado: boolean){
        let cssSelecionado = selecionado ? 'recurso-selecionado' : '';

        cssSelecionado += ' col-'+(10-this.nivel);

        return cssSelecionado;
    }
}
