import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


// *******************************************************************************
// Layouts

import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutHorizontalSidenavComponent } from './layout-horizontal-sidenav/layout-horizontal-sidenav.component';

// *******************************************************************************
// Components

import { LayoutNavbarComponent } from './layout-navbar/layout-navbar.component';
import { LayoutSidenavComponent } from './layout-sidenav/layout-sidenav.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';


// *******************************************************************************
// Libs

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavModule } from '../../vendor/libs/sidenav/sidenav.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';

// *******************************************************************************
// Services

import { LayoutService } from './layout.service';
import { ModalService } from '../services/shared/modal-service';
import { ModalCalendarioCobrador } from './layout-navbar/modal-calendario-cobrador/modal-calendario-cobrador.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { UsuarioService } from '../services/usuario.service';
import { LayoutDashboardComponent } from './layout-dashboard/layout-dashboard.component';
import { LayoutDashboardFooterComponent } from './layout-dashboard-footer/layout-dashboard-footer.component';
import { LayoutDashboardSidenavComponent } from './layout-dashboard-sidenav/layout-dashboard-sidenav.component';
import { LayoutDashboardMenuComponent } from './layout-dashboard-menu/layout-dashboard-menu.component';
import { NotificacaoPushComponent } from './layout-navbar/notificacao-push/notificacao-push.component';
import { NotificacoesPushService } from '../services/notificacoes-push.service';
import {MatBadgeModule} from '@angular/material/badge';
import { LayoutModalAutenticatorComponent } from './layout-modal-autenticator/layout-modal-autenticator.component';


// *******************************************************************************
//
export function momentAdapterFactory() {
    return adapterFactory(moment);
  };

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        SidenavModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),

        FormsModule,
        AngularMultiSelectModule,
        MatBadgeModule

    ],
    declarations: [
        LayoutHorizontalSidenavComponent,
        LayoutBlankComponent,
        LayoutNavbarComponent,
        LayoutSidenavComponent,
        LayoutFooterComponent,
        LayoutDashboardFooterComponent,
        LayoutDashboardSidenavComponent,
        LayoutDashboardComponent,
        LayoutDashboardMenuComponent,
        NotificacaoPushComponent,
        //Modal - Calendario Cobraodr
        ModalCalendarioCobrador,
        LayoutModalAutenticatorComponent
    ],
    entryComponents: [
        //Modal - Calendario Cobraodr
        ModalCalendarioCobrador,
        LayoutDashboardMenuComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        LayoutService,
        ModalService,
        UsuarioService,
        NotificacoesPushService
    ]
})
export class LayoutModule { }
