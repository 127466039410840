import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-honorarios',
    templateUrl: './modal-honorarios.component.html',
    styleUrls: ['./modal-honorarios.component.scss']
})
export class ModalHonorariosComponent implements OnInit {

    public honorarioForm: FormGroup;
    public modalRef: NgbModalRef;    

    constructor() { }

    ngOnInit(): void {
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    close() {
        this.modalRef.close();
    }
}
