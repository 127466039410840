import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Guid } from "guid-typescript";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProcessosJudiciaisOcorrenciaComentarioMencaoService } from "src/app/services/processos-judiciais-ocorrencia-comentario-mencao.service";
import { ProcessosJudiciaisService } from "src/app/services/processos-judiciais.service";
import Swal from "sweetalert2";
import { ModalDetalheComponent } from "../../dashboard/agenda/modal-detalhe/modal-detalhe.component";

@Component({
    selector: 'lista-atividades-mencionadas-usuario',
    templateUrl: './lista-atividades-mencionadas-usuario.component.html'
})
export class ListaAtividadesMencionadasUsuarioComponent implements OnInit {

    constructor(
        private spinnerService: NgxSpinnerService,
        private toastrService: ToastrService,
        private ngbModal: NgbModal,
        private processosJudiciaisOcorrenciaComentarioMencaoService: ProcessosJudiciaisOcorrenciaComentarioMencaoService,
        private processosJudiciaisService: ProcessosJudiciaisService,
    ) {
    }

    swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    atividadesMencionadasUsuario: [] = [];

    ngOnInit(): void {
        this.obterAtividadesMencionadasUsuario();
    }

    obterAtividadesMencionadasUsuario(): void {
        this.processosJudiciaisOcorrenciaComentarioMencaoService.listarPorUsuario()
            .subscribe(res => {
                this.atualizarAtividadesMencionadasUsuario(res.data);
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção');
                }
                else {
                    this.toastrService.error('Não foi possível carregar os dados!', 'Atenção', { timeOut: 10000 });
                }
            });
    }

    descartarMencao(id: Guid, tipoAtividade: number) {

        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente descartar a menção?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {

            if (!result.value) {
                return;
            }

            this.spinnerService.show();

            this.processosJudiciaisOcorrenciaComentarioMencaoService.descartarMencao(id, tipoAtividade)
                .subscribe(res => {
                    this.toastrService.success("Menção descartarda com sucesso!", 'Sucesso');
                    this.atualizarAtividadesMencionadasUsuario(res.data);
                    this.spinnerService.hide();
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção');
                    }
                    else {
                        this.toastrService.error('Não foi possível carregar os dados!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinnerService.hide();
                });

        });


    }

    atualizarAtividadesMencionadasUsuario(dados): void {
        this.atividadesMencionadasUsuario = dados;
    }

    abrirModalComentario(processoJudicialRecursoDesdobramentoId, atividadeId, tipoAtividade): void {
        this.spinnerService.show();

        this.processosJudiciaisService.obterPorRecursoDesdobramentoId(processoJudicialRecursoDesdobramentoId).subscribe(processo => {
            this.processosJudiciaisService.obterAtividade(atividadeId, tipoAtividade).subscribe(res => {
                const modalRef = this.ngbModal.open(ModalDetalheComponent, {
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false
                });

                modalRef.componentInstance.modalRef = modalRef;
                modalRef.componentInstance.id = atividadeId;
                modalRef.componentInstance.tipoOcorrencia = tipoAtividade;
                modalRef.componentInstance.descricao = res.data.tituloDescricao;
                modalRef.componentInstance.tipoObjeto = res.data.tipoObjeto;
                modalRef.componentInstance.tipoStatusAtividade = res.data.tipoStatusAtividade;
                modalRef.componentInstance.responsavelNome = res.data.responsavelNome;
                modalRef.componentInstance.dataInicioPrazo = res.data.dataInicioPrazo;
                modalRef.componentInstance.estilo = res.data.estilo;
                modalRef.componentInstance.corAlertaData = res.data.corAlertaDataLimite;
                modalRef.componentInstance.numeroProcesso = processo.data.numero;
                modalRef.componentInstance.processo = processo.data;
                modalRef.componentInstance.item = res.data;
                modalRef.componentInstance.exibirAcoes = false;
                modalRef.componentInstance.abaSelecionada = 1;

                this.spinnerService.hide();

                modalRef.result.then(result => {

                }).catch(error => { });
            });
        });
    }
}