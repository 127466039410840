import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-dashboard-menu',
    templateUrl: './layout-dashboard-menu.component.html',
    styleUrls: ['./layout-dashboard-menu.component.scss']
})
export class LayoutDashboardMenuComponent implements OnInit {
    @Output() menuCloseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    

    constructor(
        private authService: AuthService,
        private router: Router) { }

    ngOnInit(): void {
        
    }

    menuClose(validate: boolean) {
        this.menuCloseEvent.emit(validate);
    }

    isAdvogadoOrRevisorOrAssistente() {
        return this.authService.getPerfil() === 'Advogado' || this.authService.getPerfil() === 'Revisor' || this.authService.getPerfil() === 'Assistente';
    }

    isPublicacaoSelected(){
        
        if (this.router.url.indexOf('/publicacoes') > -1) 
        {
            return true;
        }else{
            return false;
        }
    }

}
