import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';

@Component({
    selector: 'app-modal-contato-processos-amigaveis',
    templateUrl: './modal-contato-processos-amigaveis.component.html',
    styleUrls: ['../../../../../vendor/styles/pages/cadastro-ocorrencia-modal.scss'
    ],  
})

export class ModalContatoProcessosAmigaveisComponent implements OnInit {

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processoAmigavel;
        this.novaOcorrencia = { descricao: '', anexo: '', tipoOcorrencia: '' };
        this.ordenarOcorrencias();
        this.novosAnexos = [];
        this.tiposOcorrencias = ['contato realizado', 'pagamento efetuado','outros'];
    }

    modalRef: NgbModalRef;
    isIE10 = false;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService
    ) {

        this.uploader = new FileUploader({
            //url: URL,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => {
                    resolve({
                        name: item._file.name,
                        length: item._file.size,
                        contentType: item._file.type,
                        date: new Date()
                    });
                });
            }
        });

        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);

        this.isIE10 = this.appService.isIE10;
    }

    public novosAnexos: any[] = [];;
    public processo: any;
    public novaOcorrencia: any;
    public files: File[] = [];
    public isHovering: boolean;
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    public hasAnotherDropZoneOver: boolean;
    public response: string;
    public tiposOcorrencias: any;

    dropped(event) {
        var files = this.uploader.queue;
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    //onDrop(event: EventEmitter<File[]>) {
    onDrop(files: FileList) {
        //var filesQueue = this.uploader.queue;
        for (let i = 0; i < files.length; i++) {
            this.files.push(files.item(i));
            this.novosAnexos.push(files.item(i));
        }
        
        this.handleUpload();
    }

    handleUpload() {
     
    }

    excluirNovoAnexo(novoAnexo){
        this.novosAnexos = this.novosAnexos.filter(c => c.name != novoAnexo.name);
    }

    ordenarOcorrencias() {

    }

    cadastrarOcorrencia() {
        var anexos = [{ Nome: 'nota', Id: '1' }, { Nome: 'comprovante', Id: '2' }];
        var dataAgora = new Date().toLocaleDateString();
        var dataJs = dataAgora.split('/')[1] + '-' + dataAgora.split('/')[0] + '-' + dataAgora.split('/')[2];
        var novaOcorrencia = { Data: dataAgora, Usuario: 'SOSA', Descricao: this.novaOcorrencia.descricao, Anexos: anexos, DataJs: dataJs };

        this.novosAnexos = [];
        this.files = [];
        this.novaOcorrencia.descricao = '';
        this.processo.Ocorrencias.unshift(novaOcorrencia);


    }

    abrirModalDetalhesContato(processo, contato) {
        this.modalService.abrirModalProcessoAmigavelDetalheContato(processo, contato);
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
