import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProcessosJudiciaisRecursoDesdobramentoPedidoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'ProcessoJudicialRecursoDesdobramentoPedido/';

    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listarPorRecursoDesdobramento(recursoDesdobramentoId): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}listar-por-recurso-desdobramento/${recursoDesdobramentoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
