import { HomeModule } from './views/home/home.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts

import { LayoutHorizontalSidenavComponent } from './layout/layout-horizontal-sidenav/layout-horizontal-sidenav.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './views/home/home.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { LoginComponent } from './views/login/login.component';
import { EsqueceuSenhaComponent } from './views/login/esqueceu-senha/esqueceu-senha.component';
import { TrocarSenhaComponent } from './views/login/trocar-senha/trocar-senha.component';
import { AcessoLogadoComponent } from './views/acesso-logado/acesso-logado.component';
import { LayoutDashboardComponent } from './layout/layout-dashboard/layout-dashboard.component';

// *******************************************************************************
// Routes

const routes: Routes = [
    {
        path: '', component: LoginComponent, pathMatch: 'full'
    },
    {
      path: 'acesso-logado', component: AcessoLogadoComponent, pathMatch: 'full'
    },
    {
        path: 'login', component: LoginComponent, pathMatch: 'full'
    },
    {
        path: 'esqueceu-senha', component: EsqueceuSenhaComponent, pathMatch: 'full'
    },
    {
        path: 'trocar-senha', component: TrocarSenhaComponent, pathMatch: 'full'
    },

    { path: 'home', component: LayoutHorizontalSidenavComponent, loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule) },
    { path: 'dashboard', component: LayoutDashboardComponent, loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: 'cadastros', component: LayoutHorizontalSidenavComponent, loadChildren: () => import('./views/cadastros/cadastro.module').then(m => m.CadastroModule) },
    { path: 'administracao', component: LayoutHorizontalSidenavComponent, loadChildren: () => import('./views/administracao/administracao.module').then(m => m.AdministracaoModule) },
    { path: 'operacionais', component: LayoutHorizontalSidenavComponent, loadChildren: () => import('./views/operacionais/operacionais.module').then(m => m.OperacionaisModule) },
    { path: 'relatorios', component: LayoutHorizontalSidenavComponent, loadChildren: () => import('./views/relatorios/relatorios.module').then(m => m.RelatoriosModule) },
    { path: 'financeiro', component: LayoutHorizontalSidenavComponent, loadChildren: () => import('./views/financeiro/financeiro.module').then(m => m.FinanceiroModule) },
    { path: 'lista-de-ramais', component: LayoutHorizontalSidenavComponent, loadChildren: () => import('./views/lista-ramais/lista-ramais.module').then(m => m.ListaRamaisModule) },
    { path: 'dashboard-lista-de-ramais', component: LayoutDashboardComponent, loadChildren: () => import('./views/lista-ramais/lista-ramais.module').then(m => m.ListaRamaisModule) },

    // 404 Not Found page
    { path: '**', component: NotFoundComponent }
];

// *******************************************************************************
//

@NgModule({
    imports: [RouterModule.forRoot(routes),HomeModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }
