import { TokensResponse } from './../models/usuario/tokens-response';
import { Injectable } from '@angular/core';
import * as moment from "moment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { tap, mapTo, catchError, shareReplay } from 'rxjs/operators';
// import * as qs from 'qs';
import { environment } from 'src/environments/environment';
import { delayedRetry, ServiceBase } from '../services/service.base';
import { Tokens } from '../models/usuario/tokens';

import { Modulo } from '../enums/modulo.enum';


@Injectable({
    providedIn: 'root'
})
export class AuthService extends ServiceBase {

    private readonly JWT_TOKEN = 'iso.access_token';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    private readonly USER_NAME = "iso.usuario";
    private readonly USER_EMAIL = "iso.usuario_email";
    private readonly USER_ID = "iso.id_usuario";
    private readonly USER_PERFIL = "iso.perfil_usuario";
    private readonly USER_AMBIENTES = "iso.usuario_ambientes";
    private readonly AMBIENTE = "iso.ambiente";
    private readonly USER_ISMASTER = "iso.master_usuario";
    private readonly AUTHORITIES = "AUTHORITIES";

    loggedUser: string;


    constructor(private http: HttpClient) { super(); }

    login(username: string, password: string): Observable<boolean> {
        // let user = {username: username, password: password , grant_type: "password"};
        let user = {login: username, senha: password};
        // let headers = new HttpHeaders();
        // headers = headers.set('Content-Type', "application/x-www-form-urlencoded;charset=UTF-8");

        // return this.http.post<any>(`${environment.api}usuario/login`, qs.stringify(user), {headers: headers})
        return this.http.post<any>(`${environment.api}usuario/login`, user)
            .pipe(
                delayedRetry(1000, 0),
                catchError(error => {
                    return error;
                }),
                shareReplay(),
                tap((tokens: TokensResponse) => {
                    this.doLoginUser(user.login, tokens.data)
                }),
                mapTo(true)
                );
    };

    logout() {
        // return this.http.post<any>(`${environment.api}/oauth/logout`, {
        //     'refreshToken': this.getRefreshToken()
        // }).pipe(
        //     tap(() => this.doLogoutUser()),
        //     mapTo(true),
        //     catchError(error => {
        //         alert(error.error);
        //         return of(false);
        //     }));

        this.doLogoutUser();
    }

    isLoggedIn() {
        return !!this.getJwtToken();
    }

    isMaster() {
        return this.isLoggedIn() && this.getIsMaster() == 'true';
        //return this.isLoggedIn() && this.isPermissionAvailable(Modulo.MASTER);
    }

    isPermissionAvailable(modulo: Modulo) {
        return this.getAuthorities().indexOf(modulo) !== -1;
    }

    refreshToken() {
        return this.http.post<any>(`${environment.api}/oauth/refresh`, {
            'refreshToken': this.getRefreshToken()
        }).pipe(tap((tokens: TokensResponse) => {
            this.storeJwtToken(tokens.data.token);
        }));
    }

    getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    getUserEmail() {
        return localStorage.getItem(this.USER_EMAIL);
    }

    getUserName() {
        return localStorage.getItem(this.USER_NAME);
    }

    getAuthorities() {
        return localStorage.getItem(this.AUTHORITIES);
    }

    getUserId() {
        return localStorage.getItem(this.USER_ID);
    }

    public getPerfil() {
        return localStorage.getItem(this.USER_PERFIL);
    }

    getIsMaster() {
        return localStorage.getItem(this.USER_ISMASTER);
    }

    public getUserAmbientes() {
        return JSON.parse(localStorage.getItem(this.USER_AMBIENTES));
    }

    public setAmbiente(value: any) {
        localStorage.setItem(this.AMBIENTE, value);
    }

    public getAmbiente() {
        return localStorage.getItem(this.AMBIENTE);
    }

    private doLoginUser(username: string, data: any) {
        this.loggedUser = username;
        this.storeTokens(username, data);
    }

    private doLogoutUser() {
        this.loggedUser = null;
        this.removeTokens();
    }

    private getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    private storeJwtToken(jwt: string) {
        localStorage.setItem(this.JWT_TOKEN, jwt);
    }

    private storeTokens(username: string, userData: any) {
        this.storeTokenData(username, userData);
    }

    public storeTokenData(username: string, data: any) {
        localStorage.setItem(this.USER_NAME, username);
        localStorage.setItem(this.JWT_TOKEN, data.token);
        localStorage.setItem(this.USER_ID, data.id);
        localStorage.setItem(this.USER_EMAIL, data.email);
        localStorage.setItem(this.USER_ISMASTER, data.perfil.isMaster);
        localStorage.setItem(this.USER_PERFIL, data.perfil.nome);
        // localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);

        let jwtData = data.token.split('.')[1]
        let decodedJwtJsonData = window.atob(jwtData)
        let decodedJwtData = JSON.parse(decodedJwtJsonData)

        localStorage.setItem(this.AUTHORITIES, decodedJwtData.role);

        let ambientes = [];

        data.usuarioAmbiente.forEach(a => {
            ambientes.push(a.ambiente)
        });

        localStorage.setItem(this.USER_AMBIENTES, JSON.stringify(ambientes));
    }

    private removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
        localStorage.removeItem(this.USER_NAME);
        localStorage.removeItem(this.USER_EMAIL);
        localStorage.removeItem(this.USER_PERFIL);
        localStorage.removeItem(this.USER_ISMASTER);
        localStorage.removeItem(this.USER_ID);
        localStorage.removeItem(this.AUTHORITIES);
    }
}
