import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class NotaDeDebitoService extends ServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  public endpoint = "NotasDeDebito/";
  private dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  obterNotas(tipo, referenciaId): Observable<any> {
    return this.http
      .get(
        environment.api +
          this.endpoint +
          "obter-notas-debito/" +
          tipo +
          "/" +
          referenciaId
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  obterNotasTipos(tipo): Observable<any> {
    return this.http
      .get(
        environment.api +
          this.endpoint +
          "obter-notas-debito-tipos/" +
          tipo
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterNotasPendentes(dataInicio, dataFim, id): Observable<any> {
    let data = {
        dataInicio: dataInicio,
        dataFim: dataFim,
        id: id
    };
    return this.http
      .post(environment.api + this.endpoint + "obter-notas-debito-em-aberto",data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterNotasVencidas(): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + "obter-notas-debito-vencidas")
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterNotasPagasPorPeriodo(dataInicio, dataFim, id): Observable<any> {
    let data = {
        dataInicio: dataInicio,
        dataFim: dataFim,
        id: id
    };

    return this.http
      .post(
        environment.api + this.endpoint + "obter-notas-debito-pagas",
        data
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterExtratoConsolidado(dataInicio, dataFim, id, numeroPasta, numeroRecurso): Observable<any> {
    let data = {
        dataInicio: dataInicio,
        dataFim: dataFim,
        id: id,
        numeroPasta: numeroPasta,
        numeroRecurso: numeroRecurso
      };
    return this.http
      .post(environment.api + this.endpoint + "obter-extrato-consolidado/", data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getById(id): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + id)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  adicionarNotaDebito(body): Observable<any> {
    return this.http
      .post(environment.api + this.endpoint + "adicionar-nota-de-debito", body)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  editarNotaDebito(body): Observable<any> {
    return this.http
      .put(environment.api + this.endpoint + "atualizar-nota-de-debito", body)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  gerarArquivoNotaDebito(notaDebitoId): Observable<any> {
    return this.http
      .post(environment.api + this.endpoint + "gerar-arquivo-nota-de-debito", {notaDebitoId: notaDebitoId})
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  atualizarArquivosDespesas(formData): Observable<any> {
    return this.http
      .post(environment.api + this.endpoint + "atualizar-arquivos-despesas", formData)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  buscarArquivosDespesas(notaDeDebitoId): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + "obter-arquivos-despesas/" + notaDeDebitoId)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  gerarArquivoNotaDebitoProvisoria(body) {
    let url = environment.api + this.endpoint + "gerar-arquivo-nota-de-debito-provisoria";

    return this.http.request(new HttpRequest(
        'POST',
        url,
        body,
        {
            reportProgress: true,
            responseType: 'blob'
        }));
    }
    deletarNotaDebito(id) {
        return this.http.delete(environment.api + this.endpoint + id).pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    exportExcelNotaDebito(dataInicio, dataFim, id){
        let url = environment.api + this.endpoint + "exportar-notas-pagas-excel";
        let data = {
            dataInicio: dataInicio,
            dataFim: dataFim,
            id: id
          };
        return this.http.request(new HttpRequest(
            'POST',
            url,
            data,
            {
                reportProgress: true,
                responseType: 'blob'
            }));
    }

    exportExcelExtrato(dataInicio, dataFim, id){
        let url = environment.api + this.endpoint + "exportar-extrato-excel";
        let data = {
            dataInicio: dataInicio,
            dataFim: dataFim,
            id: id
          };
        return this.http.request(new HttpRequest(
            'POST',
            url,
            data,
            {
                reportProgress: true,
                responseType: 'blob'
            }));
    }
}
