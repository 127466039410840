<div style="display: flex;flex-direction: row;">

  <div *ngIf="inputType!='date'" [ngClass]="symbolRight ? 'input-icon input-icon-right' : 'input-icon'">
    <input type="{{inputType}}"  class="form-control" style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" [(ngModel)]="data"   [disabled]="disabled">
    <i>{{symbol}}</i>
  </div>

  <div *ngIf="inputType=='date'">
    <form class="form-inline" >
      <div class="form-group" style="width: 100%;">
          <div class="input-group" style="width: 100%;">
              <input (click)="d.toggle()" style="width: 100%;" class="form-control" name="dp1" [(ngModel)]="currentDate" ngbDatepicker
                  [displayMonths]="displayMonths" [navigation]="navigation"  (dateSelect) = "onSelectDate($event)"
                  [disabled]="disabled"
                  [placement]="isRTL ? 'bottom-right' : 'bottom-left'" #d="ngbDatepicker" style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;">
              <div class="input-group-append" style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;">
                  <button class="input-group-text" [disabled]="disabled" style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                      (click)="d.toggle()" type="button">
                      <span class="ion ion-md-calendar" style="cursor: pointer;border-top-right-radius: 0px;border-bottom-right-radius: 0px;"></span>
                  </button>
              </div>
          </div>
      </div>
  </form>
  </div>



<button *ngIf="disabled && !saving" type="button" class="btn btn-warning" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" (click)="update()">
<span style="color: white;" class="fa fa-edit"></span>
</button>
<button *ngIf="!disabled && !saving" type="button" class="btn btn-primary" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" (click)="update()">
  <span style="color: white;" class="fa fa-check"></span>
  </button>
  <button *ngIf="saving" type="button" class="btn btn-light" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" (click)="update()">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</div>

