import { delayedRetry, ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, catchError, shareReplay, tap, mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'usuario/';

    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    esqueceuSenha(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'esqueceuSenha', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    trocarSenha(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'trocarSenha', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
