import { Prioridade, PrioridadeLabel } from './../../enums/prioridade.enum';
import { Injectable } from '@angular/core';
import { SelectList } from 'src/app/models/select-list';
import { TipoTag, TipoTagLabel } from 'src/app/enums/tag/tipo-tag.enum';
import { Tag } from 'src/app/models/cadastros/tag/tag';
import { SelectListMultiSelect } from 'src/app/models/select-list-multi-select';
import { TipoAcordo, TipoAcordoLabel } from 'src/app/enums/Acordo/tipo-acordo.enum';
import { AmbienteAcesso, AmbienteAcessoLabel } from 'src/app/enums/pessoa-enums/ambiente-acesso.enum';
import { TipoStatusAtividade, TipoStatusAtividadeLabel } from 'src/app/enums/atividade/tipo-status-atividade.enum';
import { TipoAtividade, TipoAtividadeLabel } from 'src/app/enums/atividade/tipo-atividade.enum';

@Injectable({
    providedIn: 'root'
})
export class SelectListService {

    constructor() { }

    getTipoTags() {
        let listTipoTag: SelectList[] = [];

        listTipoTag.push(new SelectList(TipoTag.Atividade.toString(), TipoTagLabel.get(TipoTag.Atividade)));
        listTipoTag.push(new SelectList(TipoTag.Divida.toString(), TipoTagLabel.get(TipoTag.Divida)));
        listTipoTag.push(new SelectList(TipoTag.Ocorrencia.toString(), TipoTagLabel.get(TipoTag.Ocorrencia)));
        listTipoTag.push(new SelectList(TipoTag.Processo.toString(), TipoTagLabel.get(TipoTag.Processo)));

        return listTipoTag;
    }

    getTipoAcordo() {
      let listTipoAcordo: SelectList[] = [];

      listTipoAcordo.push(new SelectList(TipoAcordo.Duplicata.toString(), TipoAcordoLabel.get(TipoAcordo.Duplicata)));
      listTipoAcordo.push(new SelectList(TipoAcordo.Cheque.toString(), TipoAcordoLabel.get(TipoAcordo.Cheque)));
      listTipoAcordo.push(new SelectList(TipoAcordo.NotaPromissoria.toString(), TipoAcordoLabel.get(TipoAcordo.NotaPromissoria)));
      listTipoAcordo.push(new SelectList(TipoAcordo.DebitoAutomatico.toString(), TipoAcordoLabel.get(TipoAcordo.DebitoAutomatico)));
      listTipoAcordo.push(new SelectList(TipoAcordo.Confissao.toString(), TipoAcordoLabel.get(TipoAcordo.Confissao)));

      return listTipoAcordo;
  }

    getPrioridade() {
        let list: SelectList[] = [];

        list.push(new SelectList(Prioridade.Baixa.toString(), PrioridadeLabel.get(Prioridade.Baixa)));
        list.push(new SelectList(Prioridade.Media.toString(), PrioridadeLabel.get(Prioridade.Media)));
        list.push(new SelectList(Prioridade.Alta.toString(), PrioridadeLabel.get(Prioridade.Alta)));

        return list;
    }


    getTagToMultiSelect(tagL: Tag[]) : SelectListMultiSelect[] {
        return tagL.map(t => { return new SelectListMultiSelect(t.id.toString(), t.nome); });
    }

    getAmbiente() {
        let list: SelectListMultiSelect[] = [];
        
        list.push(new SelectListMultiSelect(AmbienteAcesso.Amigavel.toString(), AmbienteAcessoLabel.get(AmbienteAcesso.Amigavel)));
        list.push(new SelectListMultiSelect(AmbienteAcesso.Juridico.toString(), AmbienteAcessoLabel.get(AmbienteAcesso.Juridico)));

        return list;
    }

    getTipoStatusAtividade(): SelectListMultiSelect[] {
        
        var tiposStatusAtividade: SelectListMultiSelect[] = [];
        
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.NaoIniciada.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.NaoIniciada)));
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.EmExecucao.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.EmExecucao)));
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.EmRevisao.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.EmRevisao)));
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.DevolvidoParaAjuste.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.DevolvidoParaAjuste)));
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.RevisadoELiberado.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.RevisadoELiberado)));
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.LiberadoParaProtocolar.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.LiberadoParaProtocolar)));
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.Concluido.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.Concluido)));
        tiposStatusAtividade.push(new SelectListMultiSelect(TipoStatusAtividade.Encerrado.toString(), TipoStatusAtividadeLabel.get(TipoStatusAtividade.Encerrado)));
        
        return tiposStatusAtividade;
    }

    getTipoAtividade(): SelectListMultiSelect[] {

        var tiposAtividade: SelectListMultiSelect[] = [];
        
        tiposAtividade.push(new SelectListMultiSelect(TipoAtividade.Tarefa.toString(), TipoAtividadeLabel.get(TipoAtividade.Tarefa)));
        //tiposAtividade.push(new SelectListMultiSelect(TipoAtividade.Historico.toString(), TipoAtividadeLabel.get(TipoAtividade.Historico)));
        //tiposAtividade.push(new SelectListMultiSelect(TipoAtividade.Evento.toString(), TipoAtividadeLabel.get(TipoAtividade.Evento)));
        tiposAtividade.push(new SelectListMultiSelect(TipoAtividade.Prazo.toString(), TipoAtividadeLabel.get(TipoAtividade.Prazo)));
        tiposAtividade.push(new SelectListMultiSelect(TipoAtividade.Audiencia.toString(), TipoAtividadeLabel.get(TipoAtividade.Audiencia)));

        return tiposAtividade;
    }
}
