import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-layout-modal-autenticator',
    templateUrl: './layout-modal-autenticator.component.html',
    styleUrls: ['./layout-modal-autenticator.component.scss']
})
export class LayoutModalAutenticatorComponent implements OnInit {

    private modalRef: NgbModalRef;

    constructor() { }

    ngOnInit(): void {
    }

    dismiss() {
        this.modalRef.dismiss();
    }

}
