import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { ContasService } from 'src/app/services/contas.service';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';
import { BancosService } from 'src/app/services/bancos.service';

@Component({
    selector: 'app-modal-cadastro-contas',
    templateUrl: './modal-cadastro-contas.component.html',
    providers: [ContasService]
})

export class ModalCadastroContasComponent implements OnInit {

    modalRef: NgbModalRef;
    public conta: any;
    public listaBancos = [];

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private appService: AppService,
        private contasService: ContasService,
        private dadosCargasService: DadosCargasService,
        private bancosService: BancosService,
    ) {

    }

    popularNovoConta() {
        this.conta = {
            nome: '',
            estado: '',
        };
    }

    ngOnInit(): void {
        this.popularNovoConta();
        this.bancosService.getAll()
        .subscribe(res => {
            this.listaBancos = res.data;
        }, error => {
            this.spinner.hide();
        });
    }

    cadastrar() {
        this.add(this.conta);
    }

    add(conta) {
        this.spinner.show();
        this.toastrService.clear();
        this.contasService.add(conta)
            .subscribe(res => {
                this.toastrService.success("Conta cadastrada.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a conta!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}