
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TipoPossibilidadeProcessoJudicialService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'TipoPossibilidadeProcessoJudicial/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();


    getAllAsync() {
        return this.http.get<any>(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

}
