import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [
        '../../../vendor/styles/pages/authentication.scss'
    ]
})
export class LoginComponent implements OnInit {

    modalRef: NgbModalRef;

    public login = '';
    public senha = '';
    public logando = false;
    public loginNaoValido = false;
    public isMaster = false;
    public perfil = '';

    constructor(private appService: AppService,
        private router: Router,
        private authService: AuthService,
        private loginService: LoginService,
        private spinner: NgxSpinnerService,
        private usuarioService: UsuarioService
    ) {
        this.appService.pageTitle = 'Login';
        this.isMaster = authService.isMaster();
    }

    logar() {
        this.loginNaoValido = false;
        this.logando = true;

        //this.router.navigateByUrl('/home');
        this.authService.login(this.login, this.senha)
            .subscribe(res => {
                // var login = res.data;

                // //window.localStorage.setItem('role', tokenLogin.Role);
                // window.localStorage.setItem('iso.access_token', login.token);
                // window.localStorage.setItem('iso.usuario', login.email);

                this.isMaster = this.authService.isMaster();
                this.perfil = this.authService.getPerfil();
                let ambientes = this.authService.getUserAmbientes();
                this.appService.pageTitle = 'Serviços Empresarias';

                console.log(this.perfil);
                if (ambientes.length > 1) {
                    this.authService.setAmbiente('');
                    this.router.navigateByUrl('/home/ambiente');
                } else {
                    this.authService.setAmbiente(ambientes[0]);
                    if (this.isMaster === true || this.perfil == 'Financeiro') {
                        this.router.navigateByUrl('/home/administrativo');
                    } else {
                        console.log(this.perfil);
                        if (this.perfil == 'Advogado' || this.perfil == 'Revisor') 
                        {
                            this.router.navigateByUrl('/dashboard/area-trabalho');
                        }else{
                            this.router.navigateByUrl('/operacionais/operacao-de-processos');
                        }
                    }
                }
            }, error => {
                this.logando = false;
                this.loginNaoValido = true;

                // this.router.navigateByUrl('/home');
            });
    }

    esqueceuSenha() {
        this.router.navigateByUrl('/esqueceu-senha');
    }

    ngOnInit(): void {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.spinner.hide();
    }
}
