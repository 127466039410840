<div *ngIf="showToolbar" class="mt-3 d-flex justify-content-end align-items-center mb-3">
    <i class="fa fa-eraser cursor-pointer" matTooltip="Limpar filtros da tabela" (click)="onClearFilters()"></i>
    <ng-content select="[toolbar]"></ng-content>
</div>

<ag-grid-angular
	class="ag-theme-alpine {{classGrid}}"
	[rowData]="rowData"
    [defaultColDef]="defaultColDef"
    [columnTypes]="columnTypes"
	[columnDefs]="columnDefs"
    [localeText]="localeText"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [rowSelection]="rowSelection"
    [gridOptions]="gridOptions"
    (cellClicked)="onCellClicked($event)"
    (filterChanged)="onFilterChanged()"
    (columnMoved)="onColumnMoved()"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()">
</ag-grid-angular>