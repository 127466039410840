export enum TipoCampoFormulario {
    Texto = 1,
	Numerico,
	Monetario,
	Checkbox,
	Combobox,
	MultiSelect,
	Data,
}

export const TipoCampoFormularioLabel = new Map<TipoCampoFormulario, string>([

    [TipoCampoFormulario.Texto, "Texto"],
    [TipoCampoFormulario.Numerico, "Numérico"],
    [TipoCampoFormulario.Monetario, "Monetário"],
    [TipoCampoFormulario.Checkbox, "Checkbox"],
    [TipoCampoFormulario.Combobox, "Combobox"],
    [TipoCampoFormulario.MultiSelect, "Multiselect"],
    [TipoCampoFormulario.Data, "Data"],
  ]);

  