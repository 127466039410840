<div>
    <!-- Modal template -->
    <div class="modal-header">
        
        <h5 class="modal-title">
            Motivo de encerramento do atendimento
        </h5>        
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-12">
                <label class="form-label">Motivo<span class="required-field">*</span></label>
                <ng-select [items]="atendimentoEncerramentos" [(ngModel)]="atendimentoEncerramentoId"
                    bindLabel="nome" bindValue="id" [ngModelOptions]="{standalone: true}" 
                    [loading]="load">                    
                </ng-select>
            </div>
        </div> 
        <div class="form-row">
            <div class="form-group col-8">
                <label class="form-label">Data de Encerrameento</label>
                <div class="input-group">
                    <input (click)="dataEncerramentoComponent.toggle()" class="form-control" placeholder="dd/mm/aaaa"
                        name="dataEncerramentoComponent" ngbDatepicker
                        [(ngModel)]="dataEncerramento"
                        placement="bottom-right" #dataEncerramentoComponent="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="dataEncerramentoComponent.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group col-4">
                <label class="form-label">&nbsp;</label>
                <input type="time" class="form-control" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="horaEncerramento">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12">
                <label class="form-label">Descrição</label>
                <textarea class="form-control" rows="2"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="descricao" maxlength="250">
                </textarea>
            </div>
        </div>               
    </div>    
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="salvar()"><i class="fas fa-save"></i> Salvar</button>
    </div>
</div>