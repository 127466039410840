<ng-select 
    [items]="instancias" 
    [loading]="loadInstancias"
    [multiple]="selecionaMultiplos"
    [(ngModel)]="instanciaId" 
    [ngModelOptions]="{standalone: true}"
    bindLabel="nome" 
    bindValue="id" 
    placeholder="Digite a instância" 
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    clearAllText="Limpar seleção"
    (keyup)="filtrar($event)"
    (click)="changeInstancia($event)"
    (clear)="limparInstancia()"
    >
</ng-select>
