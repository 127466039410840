import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PerfilService } from 'src/app/services/perfil.service';

@Component({
    selector: 'app-modal-atualiza-perfil',
    templateUrl: './modal-atualiza-perfil.component.html',
    providers: [PerfilService]
})
export class ModalAtualizaPerfilComponent implements OnInit {

    modulos: any[] = [];
    modulosSelecionados: any[] = [];
    modalRef: NgbModalRef;
    isIE10 = false;
    perfil:any;

    constructor(
        private appService: AppService,
        private perfilService: PerfilService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService
    ) {

        this.isIE10 = this.appService.isIE10;
    }


    ngOnInit(): void {
        this.perfil = this.modalRef.componentInstance.perfil;
        this.modulosSelecionados = this.perfil.modulos;

        this.modulosSelecionados = this.modulosSelecionados.reduce((unique, item) => {
            return unique.includes(item) ? unique : [...unique, item];
          }, []);

        this.modulos = this.modalRef.componentInstance.modulos;
    }


    updateItem(item) {
        var newItem = Object.assign({}, item);
        var existInModulos = this.modulosSelecionados.filter(f => f == newItem.id);
        if (existInModulos.length == 0) {
            this.modulosSelecionados.push(newItem.id);
        }
        else{
            this.modulosSelecionados = this.modulosSelecionados.filter(f => f !== newItem.id);
        }
    }

    atualizar() {
        this.spinner.show();
        this.toastrService.clear();
        this.perfil.modulos = this.modulosSelecionados;
        this.perfilService.update(this.perfil)
            .subscribe(res => {
                this.toastrService.success("Perfil atualizado.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar o perfil!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}

