import { Component, OnInit } from '@angular/core';
import { DefaultEditor } from 'ng2-smart-table';
import { CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';

@Component({
  selector: 'app-custom-input-editor-ng2-money',
  templateUrl: './custom-input-editor-ng2-money.component.html',
  // styleUrls: [
  //   '../../../../../vendor/libs/ng2-smart-table/ng2-smart-table.scss'
  // ],
})


export class CustomInputEditorNg2MoneyComponent extends DefaultEditor {

  constructor() {
    super();
  }
}
