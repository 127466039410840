import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ServiceBase } from './service.base';
import { ProcessoDividaResponse } from '../models/operacionais/operacao/processo-divida-response';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DividaService extends ServiceBase{

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'divida/';

    getAllByProcesso(processoId: Guid) : Observable<ProcessoDividaResponse> {
        return this.http.get(environment.api + this.endpoint + 'processo/' + processoId)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    getProcessoDividaDetalheAllByProcesso(processoId: Guid) : Observable<ProcessoDividaResponse> {
        return this.http.get(environment.api + this.endpoint + 'processo-divida-detalhe/' + processoId)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    getAllByProcessoPagamento(processoId: Guid) : Observable<ProcessoDividaResponse> {
        return this.http.get(environment.api + this.endpoint + 'processo-pagamento/' + processoId)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    getAllByCredor(credorId: Guid) : Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'dividas-credor/' + credorId)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    update(data): Observable<ProcessoDividaResponse> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
      return this.http.delete(environment.api + this.endpoint + `delete/${id}`)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    buscarDidiviasFiltro(filtro): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'buscar-dividas-por-filtro', filtro)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
      }
}
