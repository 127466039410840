<div [formGroup]="form" *ngIf="form">
    <!-- Modal template -->
    <div class="modal-header">
      <h5 class="modal-title">{{isUpdate ? 'Editar' : 'Cadastrar'}} Nota de Débito</h5>
      <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
      <!-- filter -->
  
      <div class="row">
          <div class="form-group col-8">
              <label class="form-label">Cliente</label>
              <input
              type="text"
              class="form-control"
              formControlName="nomeCliente"
              [disabled]="true"
              />
          </div>
        <div class="form-group col-4">
          <label class="form-label">P.O.</label>
          <input
            type="text"
            class="form-control"
            maxlength="50"
            formControlName="ordemDeCompra"
          />
        </div>
      </div>
  
      <div class="row">
        <div class="form-group col-3">
          <label class="form-label">Data Vencimento</label>
          <div class="input-group">
            <input
              (click)="FilterDataVencimento.toggle()"
              class="form-control"
              placeholder="dd/mm/yyyy"
              name="dp"
              ngbDatepicker
              formControlName="dataVencimento"
              [displayMonths]="displayMonths"
              [navigation]="navigation"
              placement="bottom-right"
              #FilterDataVencimento="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                class="input-group-text"
                (click)="FilterDataVencimento.toggle()"
                type="button"
              >
                <span class="ion ion-md-calendar" style="cursor: pointer"></span>
              </button>
            </div>
          </div>
        </div>
  
        <div class="form-group col-3">
          <label class="form-label">Data Pagamento</label>
          <div class="input-group">
            <input
              (click)="FilterDataPagamento.toggle()"
              class="form-control"
              placeholder="dd/mm/yyyy"
              name="dp"
              ngbDatepicker
              formControlName="dataPagamento"
              [displayMonths]="displayMonths"
              [navigation]="navigation"
              placement="bottom-right"
              #FilterDataPagamento="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                class="input-group-text"
                (click)="FilterDataPagamento.toggle()"
                type="button"
              >
                <span class="ion ion-md-calendar" style="cursor: pointer"></span>
              </button>
            </div>
          </div>
      </div>
      <div class="form-group col-3">
          <label class="form-label">Número do Recibo</label>
          <div class="input-group">
              <input
              class="form-control"
              formControlName="numeroRecibo"
            />
          </div>
          
      </div>
      <div class="form-group col-3">
          <label class="form-label">Valor à pagar</label>
          <div class="input-group">
              <input
              currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
              class="form-control"
              formControlName="valorDebito"
            />
          </div>
          
      </div>
      </div>
  
      
      <div class="row">
        <div class="form-group col">
          <label class="form-label">Observação</label>
          <textarea
            class="form-control"
            formControlName="observacao"
            rows="6"
          ></textarea>
        </div>
      </div>
  
      <br />
  
      <div class="nav-tabs-top nav-responsive-sm">
        <ul
          [(activeId)]="activeTab"
          ngbNav
          #productTabs="ngbNav"
          class="nav-tabs"
        >
          <li ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink>Despesas</a>
            <ng-template ngbNavContent>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 200px;
                  justify-content: center;
                  align-items: center;
                  align-self: center;
                  align-content: center;
                "
                class="fa-3x"
                *ngIf="loadNaoFaturadas"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </div>
              <table class="table table-striped table-bordered card-table">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)" />
                    </th>
                    <th>Pasta</th>
                    <th>N° Processo</th>
                    <th>N° Recurso</th>
                    <th>Centro Custo</th>
                    <th>Nome Cliente</th>
                    <th>Data Lançamento</th>
                    <th>Data Limite Pagamento</th>
                    <th>Título</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listaDespesas; let i=index">
                    <ng-container>
                      <td>
                        <input
                          type="checkbox"
                          value="{{item.despesaId}}"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="listaDespesas[i].checked"
                        />
                      </td>
                      <td>{{ item.pasta }}</td>
                      <td>{{ item.numeroProcesso }}</td>
                      <td>{{ item.numeroRecurso }}</td>
                      <td>{{ item.centroCusto }}</td>
                      <td>{{ item.nomeCliente }}</td>
                      <td>{{ item.dataLancamento | date : "dd/MM/yyyy" }}</td>
                      <td>{{ item.dataLimitePagamento | date : "dd/MM/yyyy" }}</td>
                      <td>{{ item.titulo }}</td>
                      <td>{{ item.valor | currency : "BRL" }}</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
          
        </ul>
        <div [ngbNavOutlet]="productTabs"></div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="dismiss()">
        Fechar
      </button>
      <button type="button" class="btn btn-primary" (click)="salvar()">
        <i class="fas fa-save"></i> Salvar
      </button>
  
      <button type="button" class="btn btn-info" (click)="gerarArquivoNotaDebitoProvisoria()">
          Gerar arquivo modelo <i class="fas fa-file-pdf"></i>
          <div style="
              display: flex;
              flex-direction: column;
              height: 20px;
              justify-content: center;
              align-items: center;
              align-self: center;
              align-content: center;" class="fa-1x" *ngIf="loadGerarArquivo">
                  <i class="fas fa-spinner fa-spin"></i>
              </div>
      </button>
    </div>
  </div>
  