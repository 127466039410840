import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { HonorarioService } from 'src/app/services/honorario.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-modal-honorario-convencionados',
    templateUrl: './modal-honorario-convencionados.component.html',
    styleUrls: [
        './modal-honorario-convencionados.component.css',
        '../../../../../../vendor/styles/pages/dropzone.scss'
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class ModalHonorarioConvencionadosComponent implements OnInit {

    private modalRef: NgbModalRef;
    public tipo: any;
    public referenciaId: any;

    public tipoHonorario: string = '2';
    public honorario: any;

    public maskTime = [/\d/, /\d/, ':', /\d/, /\d/]

    public load: boolean = true;

    public valoresInicialFee: any;

    public loadSucessFee: boolean = false;
    public loadTimesheet: boolean = false;

    @Output() refreshTagProcesso;

    @ViewChild('venc') vencimentoElement: ElementRef;

    constructor(
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private honorarioService: HonorarioService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private pessoaService: PessoaService,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {

        if (this.honorario == undefined) {
            this.honorario = {
                tipoReferencia: this.tipo,
                referenciaId: this.referenciaId,
                files: [],
                uploader: new FileUploader({
                    disableMultipart: true,
                }),
                valorHoraCliente: null
            };
            this.calcularInitialFee();
            this.buscarValorHora();
        } else {
            this.tipoHonorario = this.honorario.tipoHonorario.toString();
            
            debugger;
            let dataVencimento = new Date(this.honorario.vencimento);

            let vencimento = {
                year: dataVencimento.getFullYear(),
                month: dataVencimento.getMonth() + 1,
                day: dataVencimento.getDate()
            }

            if (this.tipoHonorario === '3') {
                this.honorario.valorPercentual = this.honorario.percentualHonorario;
            }

            this.honorario.vencimento = vencimento;

            this.honorario.uploader = new FileUploader({
                disableMultipart: true,
            });

            this.honorario.files = [];

            this.load = false;
        }
    }

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });
    
    calcularInitialFee() {
        this.honorarioService.obterValorInitialFee(this.referenciaId).subscribe(res=>{
            this.load = false;

            this.valoresInicialFee = res.data;
            this.preencherInitialFee();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível calcular o Initial Fee!', 'Atenção', { timeOut: 10000 });                
            }                    
            this.spinner.show();

            this.processosJudiciaisRecursoDesdobramentoService.obterChaveClientePrincipal(this.referenciaId).subscribe(res=>{
                this.abrirFormPessoa(res.data);                
            })
        });
    }

    abrirFormPessoa(pessoaId: any) {        
        this.pessoaService.getById(pessoaId)
            .subscribe(res => {
                this.spinner.hide();
                this.modalService.abrirModalCadastraPessoa(res.data, true, false, true);
                this.close();
            })
    }

    changeTipo(){
        if(this.tipoHonorario == "2"){
            this.preencherInitialFee();
        }else{
            this.honorario.valorHonorario = undefined;
        }
    }    

    onDrop(objeto: any, files: FileList) {
        for (let i = 0; i < files.length; i++) {
            objeto.files.push(files[i] as File);
        }

        const fileInQueue: any = objeto.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    salvar() {
        switch (this.tipoHonorario) {
            case '2':
                this.salvarInicialFee();
                break;
            case '3':
                this.salvarSuccessFee();
                break;
            case '4':
                this.salvarTimesheet();
                break;
            default:
                this.toastrService.error('Method not implemented.', 'Error', { timeOut: 10000 });
        }
    }

    salvarTimesheet() {
        if (typeof this.honorario.vencimento == 'undefined') {
            this.toastrService.error('Data é obrigatória!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.valorHoraInformado == 'undefined') {
            this.toastrService.error('Informe o Valor/Hora do Cliente!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.duracao == 'undefined' || this.honorario.duracao == '') {
            this.toastrService.error('Duração é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.valorHonorario == 'undefined') {
            this.toastrService.error('Valor é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.descricao == 'undefined' || this.honorario.descricao == '') {
            this.toastrService.error('Descricao é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        this.honorario.vencimento = new Date(this.honorario.vencimento.year, this.honorario.vencimento.month - 1, this.honorario.vencimento.day);

        let formData: FormData = new FormData();

        if (this.honorario.id != undefined) {
            formData.append('id', this.honorario.id);
        }

        formData.append('vencimento', this.honorario.vencimento.getDate() + "/" + (this.honorario.vencimento.getMonth() + 1) + "/" + this.honorario.vencimento.getFullYear() + " 12:00:00");
        formData.append('valorHonorario', this.honorario.valorHonorario.toString().replace(".", ","));
        formData.append('valorHoraInformado', this.honorario.valorHoraInformado.toString().replace(".", ","));
        formData.append('valorHoraCliente', this.honorario.valorHoraCliente?.toString().replace(".", ","));
        formData.append('duracao', this.honorario.duracao);
        formData.append('descricao', this.honorario.descricao);
        formData.append('referenciaId', this.referenciaId);
        formData.append('tipoReferencia', this.tipo);
        formData.append('tipoHonorario', this.tipoHonorario);

        this.honorario.files.forEach(file => {
            formData.append('files', file);
        });

        this.spinner.show();

        let observable = this.honorario.id == null ? this.honorarioService.addPorTimesheet(formData) : this.honorarioService.atualizarPorTimesheet(formData);

        observable.subscribe(res => {
            this.spinner.hide();
            this.close();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível salvar o Honorário!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        })
    }

    salvarSuccessFee() {
        if (typeof this.honorario.valorRecuperado == 'undefined') {
            this.toastrService.error('Informe o valor recuperado!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.valorPercentual == 'undefined') {
            this.toastrService.error('Informe o valor do percentual!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.valorHonorario == 'undefined') {
            this.toastrService.error('Valor é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.vencimento == 'undefined') {
            this.toastrService.error('Data é obrigatória!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.descricao == 'undefined' || this.honorario.descricao == '') {
            this.toastrService.error('Descrição é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        this.honorario.vencimento = new Date(this.honorario.vencimento.year, this.honorario.vencimento.month - 1, this.honorario.vencimento.day);

        let formData: FormData = new FormData();

        if (this.honorario.id != undefined) {
            formData.append('id', this.honorario.id);
        }

        formData.append('vencimento', this.honorario.vencimento.getDate() + "/" + (this.honorario.vencimento.getMonth() + 1) + "/" + this.honorario.vencimento.getFullYear() + " 12:00:00");
        formData.append('valorHonorario', this.honorario.valorHonorario.toString().replace(".", ","));
        formData.append('valorRecuperado', this.honorario.valorRecuperado.toString().replace(".", ","));
        formData.append('valorPercentual', this.honorario.valorPercentual.toString().replace(".", ","));
        formData.append('descricao', this.honorario.descricao);
        formData.append('referenciaId', this.referenciaId);
        formData.append('tipoReferencia', this.tipo);
        formData.append('tipoHonorario', this.tipoHonorario);
        
        formData.append('valorBaseCalculo', this.honorario.valorBaseCalculo.toString().replace(".", ","));
        
        if(this.honorario.valorInicial !=null)
            formData.append('valorInicial', this.honorario.valorInicial.toString().replace(".", ","));
        
        if(this.honorario.valorFinal != null)
            formData.append('valorFinal', this.honorario.valorFinal.toString().replace(".", ","));

        if(this.honorario.valorBasePercentual != null)
            formData.append('valorBasePercentual', this.honorario.valorBasePercentual.toString().replace(".", ","));

        this.honorario.files.forEach(file => {
            formData.append('files', file);
        });

        this.spinner.show();

        let observable = this.honorario.id == undefined ? this.honorarioService.addPorPercentualComArquivo(formData) : this.honorarioService.atualizarPorPercentualComArquivo(formData);

        observable.subscribe(res => {
            this.refreshTagProcesso.emit();
            this.spinner.hide();
            this.close();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar o Honorário!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        })
    }

    preencherInitialFee() {
        this.honorario.valorHonorario = this.valoresInicialFee.valorCalculado;
        this.honorario.valorCalculado = this.valoresInicialFee.valorCalculado;
        this.honorario.valorBaseCalculo = this.valoresInicialFee.valorBaseCalculo;
        this.honorario.percentualHonorario = this.valoresInicialFee.percentualHonorario;
        this.honorario.valorInicial = this.valoresInicialFee.valorInicial;            
        this.honorario.valorFinal = this.valoresInicialFee.valorFinal;
        this.honorario.quantidadeSalariosMinimos = this.valoresInicialFee.quantidadeSalariosMinimos;
    }

    salvarInicialFee() {
        if (typeof this.honorario.vencimento == 'undefined') {
            this.toastrService.error('Data é obrigatória!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.valorHonorario == 'undefined') {
            this.toastrService.error('Valor é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if (typeof this.honorario.descricao == 'undefined' || this.honorario.descricao == '') {
            this.toastrService.error('Descrição é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if(this.tipo != '3'){
            this.spinner.show();
            this.honorarioService.possuiInitialFeeCadastrado(this.referenciaId)
                .subscribe(res=>{
                    this.spinner.hide();
                    if(res.data){
                        this.swalWithBootstrapButtons.fire({
                            text: "Já existe um honorário do tipo Inicial Fee cadastrado, deseja continuar?",
                            confirmButtonColor: '#FFD950 !important;',
                            showCloseButton: true,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Cancelar'
                        }).then((result) => {
                            if(result.value)
                                this.gravarInicialFee();
                        });
                    }else{
                        this.gravarInicialFee();
                    }
                }, err=>{
                    this.spinner.hide();
                    this.toastrService.error('Não foi consultar se já existe InitialFee para o processo!', 'Atenção', { timeOut: 10000 });
                })
        }else{
            this.gravarInicialFee();
        }

        
        
    }

    gravarInicialFee() {
        this.honorario.vencimento = new Date(this.honorario.vencimento.year, this.honorario.vencimento.month - 1, this.honorario.vencimento.day);

        let formData: FormData = new FormData();

        if (this.honorario.id != undefined) {
            formData.append('id', this.honorario.id);
        }

        formData.append('vencimento', this.honorario.vencimento.getDate() + "/" + (this.honorario.vencimento.getMonth() + 1) + "/" + this.honorario.vencimento.getFullYear() + " 12:00:00");
        formData.append('valorHonorario', this.honorario.valorHonorario.toString().replace(".", ","));
        
        if(this.honorario.valorCalculado != null)
            formData.append('valorCalculado', this.honorario.valorCalculado.toString().replace(".", ","));
        
        formData.append('valorBaseCalculo', this.honorario.valorBaseCalculo.toString().replace(".", ","));
        
        if(this.honorario.percentualHonorario != null)
            formData.append('percentualHonorario', this.honorario.percentualHonorario.toString().replace(".", ","));
        
        if(this.honorario.valorInicial !=null)
            formData.append('valorInicial', this.honorario.valorInicial.toString().replace(".", ","));
        
        if(this.honorario.valorFinal != null)
            formData.append('valorFinal', this.honorario.valorFinal.toString().replace(".", ","));
        
        if(this.honorario.quantidadeSalariosMinimos != null)
            formData.append('quantidadeSalariosMinimos', this.honorario.quantidadeSalariosMinimos);        
        
        formData.append('descricao', this.honorario.descricao);
        formData.append('referenciaId', this.referenciaId);
        formData.append('tipoReferencia', this.tipo);
        formData.append('tipoHonorario', this.tipoHonorario);

        this.honorario.files.forEach(file => {
            formData.append('files', file);
        });

        this.spinner.show();

        let observable = this.honorario.id == undefined ? this.honorarioService.addPorInitialFee(formData) : this.honorarioService.atualizarPorInitialFee(formData);

        observable.subscribe(res => {
            this.spinner.hide();
            this.close();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível salvar o Honorário!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        })
    }

    buscarValorHora() {
        this.honorarioService.obterValorHoraCliente(this.tipo, this.referenciaId)
            .subscribe(res => {
                if (res.success) {
                    this.honorario.valorHoraInformado = res.data;
                    this.honorario.valorHoraCliente = res.data;
                }
            })
    }

    calcularHora() {

        if (this.honorario.valorHoraInformado != undefined &&
            this.honorario.valorHoraInformado > 0 &&
            this.honorario.duracao != undefined &&
            this.honorario.duracao != "") {
            let data = {
                valorHoraInformado: this.honorario.valorHoraInformado,
                duracao: this.honorario.duracao,
                referenciaId:this.referenciaId
            };

            this.loadTimesheet = true;

            this.honorarioService.calcularValorTimesheet(data)
                .subscribe(res => {
                    this.honorario.valorHonorario = res.data;
                    this.loadTimesheet = false;
                }, error => {
                    this.honorario.valorHonorario = "";
                    this.loadTimesheet = false;
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar o Honorário!', 'Atenção', { timeOut: 10000 });
                    }
                });
        }
    }

    onCalculateChange() {
        if (this.honorario.valorRecuperado != undefined && this.honorario.valorPercentual != undefined && this.honorario.valorRecuperado != null && this.honorario.valorPercentual != null)
            this.honorario.valorHonorario = this.honorario.valorRecuperado * (this.honorario.valorPercentual / 100);
    }

    onValorRecuperadoChange($event){
        this.loadSucessFee=true;

        const data = {
            valorRecuperado: this.honorario.valorRecuperado,
            recursoDesdobramentoId: this.referenciaId
        } 

        this.honorarioService.calcularSucessFee(data).subscribe(res=>{            
            this.honorario.valorHonorario = res.data.valorCalculado;
        
            this.honorario.valorBaseCalculo = res.data.valorBaseCalculo;
        
            this.honorario.valorPercentual = res.data.percentualHonorario;
        
            this.honorario.valorBasePercentual = res.data.percentualHonorario;
        
            this.honorario.valorInicial = res.data.valorInicial;
        
            this.honorario.valorFinal = res.data.valorFinal;
        
            this.vencimentoElement.nativeElement.focus();
        
            this.loadSucessFee=false;
        
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Falha ao calcular success fee!', 'Atenção', { timeOut: 10000 });
            }

            this.loadSucessFee=false;
        });
    }
}
