import { TipoAndamentoService } from './../../../../../services/tipo-andamento.service';


import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-modal-atualiza-tipo-andamento',
  templateUrl: './modal-atualiza-tipo-andamento.component.html',
  providers: [TipoAndamentoService]
})
export class ModalAtualizaTipoAndamentoComponent implements OnInit {

  modalRef: NgbModalRef;
  public tipoAndamento: any;
 

  constructor(
    private appService: AppService,
    private tipoAndamentoService: TipoAndamentoService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.tipoAndamento = this.modalRef.componentInstance.tipoAndamento;
  }
  

  atualizar(){
    this.update(this.tipoAndamento);
}

update(tipoAndamento) {
  
    this.spinner.show();
    this.toastrService.clear();

    this.tipoAndamentoService.update(tipoAndamento)
        .subscribe(res => {

    
            this.toastrService.success("Tipo de Andamento atualizado!.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível atualizar o Tipo de Andamento!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
}






close() {
  this.modalRef.close();
}

dismiss() {
  this.modalRef.dismiss();
}




}

