<div [ngClass]="{'cursor-pointer': !isIntoModal, 'through': item.tipoStatusAtividadeNome === 'Encerrado'}">
    <div class="row mb-3 mr-1 bg-white pb-3 pt-3 card-tarefa" [ngClass]="!item.isEdit ? item.estilo + ' card-disable': item.estilo">
        <ng-container>
            <div class="col-12 texto-titulo align-self-center" [ngClass]="item.estilo+'-texto'" (click)="openModalComments(item)">
                <div class="d-flex flex-column">
                    <p [style]="isKanban ?'font-size: 10px' : ''">{{item.tipoObjeto}} <strong> #ID: {{item.codExterno}}</strong></p>
                </div>
            </div>
            <div class="ml-2">
                <app-opcoes-processo
                *ngIf="!isIntoModal && item.isEdit"
                [showReschedule]="true"
                [item]="item"
                [resultConfirmDialog$]="resultConfirmDialog$"
                (buscarTarefaDoUsuario)="buscarTarefaDoUsuario.emit(item)"
                (changeRefresh)="changeRefresh.emit()"
                ></app-opcoes-processo>

                <!-- <div class="photo-envolvidos d-flex justify-content-end">
                    <ng-container *ngFor="let photo of item.envolvidos">
                        <img [src]="photo.img">
                    </ng-container>
                </div> -->
            </div>
    
            <div class="col-4 align-self-top pl-4" (click)="openModalComments(item)">
                <p *ngIf="!isIntoModal && item.responsavelNome && item.tipoOcorrencia != 5" class="d-flex font-weight-bold"><i class="fa fa-user mr-2"></i>
                    <ng-conatiner *ngIf="isKanban">
                        <ng-container *ngIf="item.responsavelNome.length > 11">
                            <span [ngbTooltip]="item.responsavelNome">
                                {{item.responsavelNome | slice:0:12}}...
                            </span>
                        </ng-container>
                        <ng-container *ngIf="item.responsavelNome.length <= 11">
                            {{item.responsavelNome}}
                        </ng-container>
                    </ng-conatiner>

                    <ng-conatiner *ngIf="!isKanban">
                        {{item.classificacaoSubtipoAtividadeNome}}
                    </ng-conatiner>
                </p>
                <p *ngIf="(item.classificacaoSubtipoAtividadeNome != undefined && item.classificacaoSubtipoAtividadeNome != null && item.classificacaoSubtipoAtividadeNome.length > 0 && item.tipoOcorrencia !=5 && !isIntoModal)" class="d-flex font-weight-bold"><i class="fas fa-list mr-2"></i>
                    <ng-conatiner *ngIf="isKanban">
                        <ng-container *ngIf="item.classificacaoSubtipoAtividadeNome.length > 11">
                            <span [ngbTooltip]="item.classificacaoSubtipoAtividadeNome">
                                {{item.classificacaoSubtipoAtividadeNome | slice:0:12}}...
                            </span>
                        </ng-container>
                        <ng-container *ngIf="item.classificacaoSubtipoAtividadeNome.length <= 11">
                            {{item.classificacaoSubtipoAtividadeNome}}
                        </ng-container>
                    </ng-conatiner>

                    <ng-conatiner *ngIf="!isKanban">
                        {{item.classificacaoSubtipoAtividadeNome}}
                    </ng-conatiner>
                </p>
                <p *ngIf="item.tipoSessaoNome != undefined && item.tipoSessaoNome != null && isIntoModal && item.tipoOcorrencia ==5" class="d-flex font-weight-bold"><i class="fas fa-list mr-2"></i>{{item.tipoSessaoNome}}</p>                
                <p *ngIf="(item.tipoSessaoNome != undefined && item.tipoSessaoNome != null &&  item.tipoSessaoNome.length > 0 && item.tipoOcorrencia ==5 && !isIntoModal)" class="d-flex font-weight-bold"><i class="fas fa-list mr-2"></i>
                    <ng-conatiner *ngIf="isKanban">
                        <ng-container *ngIf="item.tipoSessaoNome.length > 11">
                            <span [ngbTooltip]="item.tipoSessaoNome">
                                {{item.tipoSessaoNome | slice:0:12}}...
                            </span>
                        </ng-container>
                        <ng-container *ngIf="item.tipoSessaoNome.length <= 11">
                            {{item.tipoSessaoNome}}
                        </ng-container>
                    </ng-conatiner>

                    <ng-conatiner *ngIf="!isKanban">
                        {{item.tipoSessaoNome}}
                    </ng-conatiner>
                </p>
                <p *ngIf="item.classificacaoSubtipoAtividadeNome != undefined && isIntoModal" class="d-flex font-weight-bold"><i class="fas fa-list mr-2"></i>{{item.classificacaoSubtipoAtividadeNome}}</p>                
                <p *ngIf="item.tipoStatusAtividadeNome" class="d-flex font-weight-bold" ><i class="far fa-clock mr-2"></i>{{item.tipoStatusAtividadeNome}}</p>
                <p class="font-weight-bold" *ngIf="!isIntoModal && !isKanban"><i class="far fa-comment mr-2"></i>{{item.quantidadeComentarios}} Comentário(s)</p>
            </div>
    
            <div class="col-7 align-self-top" (click)="openModalComments(item)">                
                <p class="d-flex font-weight-bold align-items-center" *ngIf="isIntoModal && item.tipoOcorrencia == 1">
                    <i class="fas fa-arrow-alt-circle-down baixa" title="{{item.prioridadeDescricao}}" *ngIf="item.prioridade == 1"></i>
                    <i class="fas fa-minus-circle media" title="{{item.prioridadeDescricao}}" *ngIf="item.prioridade == 2"></i>
                    <i class="fas fa-arrow-alt-circle-up alta" title="{{item.prioridadeDescricao}}" *ngIf="item.prioridade == 3"></i>
                    Prioridade: <span *ngIf="item.prioridade == 1">Baixa</span><span *ngIf="item.prioridade == 2">Média</span><span *ngIf="item.prioridade == 3">Alta</span>
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 4">
                    <i class="far fa-calendar mr-2"></i>
                    <span>Data Limite:</span>{{item.dataLimite | date:'dd/MM/yyyy'}}
                    <span class="farol badge ml-2" *ngIf="item.tipoOcorrencia == 4 && item.corAlertaDataLimite != null" [ngClass]="item.corAlertaDataLimite">&nbsp;</span>
                </p>                
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 5">
                    <i class="far fa-clock mr-2"></i>
                    <span>Data:</span>{{item.dataOcorrencia | date:'dd/MM/yyyy'}}                    
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 5">
                    <i class="far fa-calendar mr-2"></i>
                    <span>Horário:</span>{{item.dataHoraInicial | date:'HH:mm'}} - {{item.dataHoraFinal | date:'HH:mm'}}
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 4">
                    <i class="far fa-calendar mr-2"></i>
                    <span>Data Revisão:</span>{{item.dataRevisao | date:'dd/MM/yyyy'}}
                    <span class="farol badge ml-2" *ngIf="item.tipoOcorrencia == 4 && item.corAlertaDataRevisao != null" [ngClass]="item.corAlertaDataRevisao">&nbsp;</span>
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 1">
                    <i class="far fa-calendar mr-2"></i>
                    <span>Data Limite:</span>{{item.dataTarefa | date:'dd/MM/yyyy'}}     
                    <span class="farol badge ml-2" *ngIf="item.tipoOcorrencia == 1 && item.corAlertaData != null" [ngClass]="item.corAlertaData">&nbsp;</span>             
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 3">
                    <i class="far fa-calendar mr-2"></i>
                    <span>Data Evento:</span>{{item.dataHoraInicial | date:'dd/MM/yyyy'}}                        
                </p>
                <p class="d-flex font-weight-bold mb-2" *ngIf="!isIntoModal">
                    <i class="fas fa-paste mr-2"></i>
                    <span>{{item.caso? 'Caso' : 'Processo'}}:</span>{{item.processoJudicialNumero}}
                    <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true" href="/dashboard/processos-judiciais/detalhes/{{item.processoJudicialId}}" target="_blank"></a>
                </p>
                <p class="d-flex font-weight-bold mb-2" *ngIf="!isIntoModal && item.processoJudicialPasta != undefined && item.processoJudicialPasta != null">
                    <i class="fas fa-folder mr-2"></i>
                    <span>Pasta:</span>{{item.processoJudicialPasta}}                
                </p>
                <p class="d-flex font-weight-bold mb-0" *ngIf="item.atuacao != undefined">
                    <i class="fas fa-paste mr-2"></i>
                    Minha atuação: {{item.atuacao}}                    
                </p>                
            </div>
    
            <div class="col-12 pl-4 mt-3" *ngIf="isIntoModal" (click)="openModalComments(item)">
                <p><i class="far fa-comment"></i> <span class="font-weight-bold">Observações: </span></p>
                <mat-form-field appearance="fill" class="w-100">
                    <textarea matInput disabled value="{{item.tipoOcorrencia == 2 ? item.descricao : item.observacao}}" cdkTextareaAutosize
                        cdkAutosizeMinRows="7" cdkAutosizeMaxRows="8"></textarea>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 d-flex justify-content-end" *ngIf="item.tipoOcorrencia == 5">
                <button class="btn btn-light" (click)="consultarDetalhes(item)">
                    <span class="fas fa-plus-circle" *ngIf="!exibirDetalhes && !exibirCarregando"></span>
                    <span class="fas fa-sync fa-spin" *ngIf="exibirCarregando"></span>
                    <span class="fas fa-minus" *ngIf="exibirDetalhes"></span>
                    &nbsp;&nbsp;Detalhes
                </button>
            </div>
            <div class="col-12 mt-3" *ngIf="exibirDetalhes && item.tipoOcorrencia==5">
                <hr />
                <div class="row">
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">Divisão de Órgão </span>{{detalhes.divisaoDeOrgaoNome != undefined &&  detalhes.divisaoDeOrgaoNome!= null ? detalhes.divisaoDeOrgaoNome : ' - '}}
                    </div>
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">Órgão </span>{{detalhes.orgaoNome != undefined &&  detalhes.orgaoNome!= null ? detalhes.orgaoNome : ' - '}}
                    </div>
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">Foro </span>{{detalhes.divisaoDeOrgaoNome != undefined &&  detalhes.divisaoDeOrgaoNome!= null ? detalhes.divisaoDeOrgaoNome : ' - '}}
                    </div>
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">Comarca </span>{{detalhes.comarcaNome != undefined &&  detalhes.comarcaNome!= null ? detalhes.comarcaNome : ' - '}}
                    </div>
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">UF </span>{{detalhes.ufNome != undefined &&  detalhes.ufNome!= null ? detalhes.ufNome : ' - '}}
                    </div>
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">Área </span>{{detalhes.areaNome != undefined &&  detalhes.areaNome!= null ? detalhes.areaNome : ' - '}}
                    </div>
                    <div class="col-12 mt-2">
                        <span class="font-weight-bold">Endereço </span>{{detalhes.endereco != undefined &&  detalhes.endereco!= null ? detalhes.endereco : ' - '}}
                    </div>
                    <div class="col-12 mt-2">
                        <span class="font-weight-bold">Modo de participação </span>{{detalhes.tipoAudienciaNome != undefined &&  detalhes.tipoAudienciaNome!= null ? detalhes.tipoAudienciaNome : ' - '}}
                    </div>
                    <div class="col-12 mt-2" *ngIf="detalhes.tipoAudienciaLink != undefined &&  detalhes.tipoAudienciaLink!= null">
                        <span class="font-weight-bold">Link para participação </span><a href="{{detalhes.tipoAudienciaLink}}" target="_blank">{{detalhes.tipoAudienciaLink}}</a><i class="fas fa-external-link-square-alt color-info p-0 ml-2"></i>
                    </div>
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">Número da Pauta </span>{{detalhes.numeroPauta != undefined &&  detalhes.numeroPauta!= null ? detalhes.numeroPauta : ' - '}}
                    </div>
                    <div class="col-6 mt-2">
                        <span class="font-weight-bold">Número de Ordem/Preferência </span>{{detalhes.numeroOrdemPreferencia != undefined &&  detalhes.numeroOrdemPreferencia!= null ? detalhes.numeroOrdemPreferencia : ' - '}}
                    </div>
                    <div class="col-12 mt-2" *ngIf="detalhes.envolvidos != null && detalhes.envolvidos != null && detalhes.envolvidos.length > 0">
                        <span class="font-weight-bold">Envolvidos</span>
                        <ul class="mt-2">
                            <li class="mt-2" *ngFor="let envolvido of detalhes.envolvidos">{{envolvido.nome}}{{(envolvido.qualificacaoNome != null ? ' ('+envolvido.qualificacaoNome+')':'')}}</li>
                        </ul>
                    </div>                    
                </div>                
            </div>
        </ng-container>
    </div>
</div>

<!-- <div [ngClass]="{'cursor-pointer': !isIntoModal, 'through': item.tipoStatusAtividadeNome === 'Encerrado'}">
    <div class="row mb-3 mr-1 bg-white pb-3 pt-3 card-tarefa" [ngClass]="!item.isEdit ? item.estilo + ' card-disable': item.estilo">
        <ng-container>
            <div class="col-1 texto-titulo align-self-center" (click)="openModalComments(item)">
                <div class="d-flex flex-column">
                    <p>{{item.tipoObjeto}}</p>
                </div>
            </div>
    
            <div class="col-4 align-self-top pl-4" (click)="openModalComments(item)">
                <p *ngIf="!isIntoModal" class="d-flex font-weight-bold"><i class="fa fa-user mr-2"></i>{{item.responsavelNome}}</p>
                <p *ngIf="(item.classificacaoSubtipoAtividadeNome != undefined && !isIntoModal) || !item.classificacaoSubtipoAtividadeNome" class="d-flex font-weight-bold"><i class="fas fa-list mr-2"></i>{{item.classificacaoSubtipoAtividadeNome}}</p>
                <p *ngIf="item.classificacaoSubtipoAtividadeNome != undefined && isIntoModal" class="d-flex font-weight-bold"><i class="fas fa-list mr-2"></i>{{item.classificacaoSubtipoAtividadeNome}}</p>                
                <p *ngIf="item.tipoStatusAtividadeNome" class="d-flex font-weight-bold" ><i class="far fa-clock mr-2"></i>{{item.tipoStatusAtividadeNome}}</p>
                <p class="font-weight-bold" *ngIf="!isIntoModal && !isKanban"><i class="far fa-comment mr-2"></i>{{item.quantidadeComentarios}} Comentário(s)</p>
            </div>
    
            <div class="col-6 align-self-top" (click)="openModalComments(item)">                
                <p class="d-flex font-weight-bold align-items-center" *ngIf="isIntoModal && item.tipoOcorrencia == 1">
                    <i class="fas fa-arrow-alt-circle-down baixa" title="{{item.prioridadeDescricao}}" *ngIf="item.prioridade == 1"></i>
                    <i class="fas fa-minus-circle media" title="{{item.prioridadeDescricao}}" *ngIf="item.prioridade == 2"></i>
                    <i class="fas fa-arrow-alt-circle-up alta" title="{{item.prioridadeDescricao}}" *ngIf="item.prioridade == 3"></i>
                    Prioridade: <span *ngIf="item.prioridade == 1">Baixa</span><span *ngIf="item.prioridade == 2">Média</span><span *ngIf="item.prioridade == 3">Alta</span>
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 4">
                    <i class="far fa-calendar mr-2"></i>
                    <span *ngIf="!isKanban">Data Limite:</span> <span [ngbTooltip]="isKanban ? 'Data Limite' : ''">{{item.dataLimite | date:'dd/MM/yyyy'}}</span>
                    <span class="farol badge ml-2" *ngIf="item.tipoOcorrencia == 4 && item.corAlertaDataLimite != null" [ngClass]="item.corAlertaDataLimite">&nbsp;</span>
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 4">
                    <i class="far fa-calendar mr-2"></i>
                    <span *ngIf="!isKanban">Data Revisão:</span> <span [ngbTooltip]="isKanban ? 'Data Limite' : ''">{{item.dataRevisao | date:'dd/MM/yyyy'}}</span>
                    <span class="farol badge ml-2" *ngIf="item.tipoOcorrencia == 4 && item.corAlertaDataRevisao != null" [ngClass]="item.corAlertaDataRevisao">&nbsp;</span>
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 1">
                    <i class="far fa-calendar mr-2"></i>
                    <span *ngIf="!isKanban">Data Limite:</span> <span [ngbTooltip]="isKanban ? 'Data Limite' : ''">{{item.dataTarefa | date:'dd/MM/yyyy'}}</span>       
                    <span class="farol badge ml-2" *ngIf="item.tipoOcorrencia == 1 && item.corAlertaData != null" [ngClass]="item.corAlertaData">&nbsp;</span>             
                </p>
                <p class="d-flex font-weight-bold align-items-center" *ngIf="item.tipoOcorrencia == 3">
                    <i class="far fa-calendar mr-2"></i>
                    <span *ngIf="!isKanban">Data Evento:</span> <span [ngbTooltip]="isKanban ? 'Data Evento' : ''">{{item.dataHoraInicial | date:'dd/MM/yyyy'}}</span>                           
                </p>
                <p class="d-flex font-weight-bold mb-2" *ngIf="!isIntoModal">
                    <i class="fas fa-paste mr-2"></i>
                    <span *ngIf="!isKanban">{{item.caso? 'Caso' : 'Processo'}}:</span><span [ngbTooltip]="isKanban ? item.caso? 'Caso' : 'Processo' : ''">{{item.processoJudicialNumero}}</span>
                    <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true" href="/dashboard/processos-judiciais/detalhes/{{item.processoJudicialId}}" target="_blank"></a>
                </p>
                <p class="d-flex font-weight-bold mb-2" *ngIf="!isIntoModal && item.processoJudicialPasta != undefined && item.processoJudicialPasta != null">
                    <i class="fas fa-folder mr-2"></i>
                    <span *ngIf="!isKanban">Pasta:</span><span [ngbTooltip]="isKanban ? 'Pasta' : ''">{{item.processoJudicialPasta}}</span>                    
                </p>
                <p class="d-flex font-weight-bold mb-2" *ngIf="item.atuacao != undefined">
                    <i class="fas fa-paste mr-2"></i>
                    Minha atuação: {{item.atuacao}}                    
                </p>
                <p class="d-flex font-weight-bold mb-0" *ngIf="isIntoModal">
                    <i class="fas fa-user mr-2"></i> Titulo Prazo
                </p>

                <div class="photo-envolvidos d-flex justify-content-end">
                    <ng-container *ngFor="let photo of item.envolvidos">
                        <img height="41px" [src]="photo.img" width="41px">
                    </ng-container>
                </div>
            </div>
    
            <div class="col-1" *ngIf="!isIntoModal && item.isEdit">
                <app-opcoes-processo
                [item]="item"
                [resultConfirmDialog$]="resultConfirmDialog$"
                (buscarTarefaDoUsuario)="buscarTarefaDoUsuario.emit(item)"
                (changeRefresh)="changeRefresh.emit()"
                ></app-opcoes-processo>
            </div>
    
            <div class="col-11 ml-5 pl-4 mt-3" *ngIf="isIntoModal" (click)="openModalComments(item)">
                <p><i class="far fa-comment ml-2 mr-2"></i>Observações: </p>
                <mat-form-field appearance="fill" class="w-100">
                    <textarea matInput disabled value="{{item.tipoOcorrencia == 2 ? item.descricao : item.observacao}}" cdkTextareaAutosize
                        cdkAutosizeMinRows="7" cdkAutosizeMaxRows="8"></textarea>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
</div> -->

<!-- <div *ngIf="isKanban" [ngClass]="{'cursor-pointer': !isIntoModal, 'through': item.tipoStatusAtividadeNome === 'Encerrado'}" (click)="openModalComments(item)">
    <span class="fa-stack fa-3x bookmark" [ngClass]="'bookmark-'+item.estilo">
        <i class="fas fa-bookmark fa-stack-2x"></i>
          <span class="fa fa-stack-1x text-white">
              <span style="font-size:13px; margin-top:-20px; display:block;">
                  {{item.tipoObjeto}}
              </span>
        </span>
    </span>	
    <div class="row mb-3 mr-1 bg-white pb-3 pt-3 card-tarefa" [ngClass]="!item.isEdit ? item.estilo + ' card-disable': item.estilo">
        <ng-container>    
            <div class="col-12" style="line-height: 1.7;">
                <p class="d-flex font-weight-bold align-items-center">
                    <i class="fa fa-user mr-2 font-icon"></i>
                    <span class="font-span">{{item.tituloDescricao}}</span>
                </p>
                <p *ngIf="(item.classificacaoAtividadeNome) || !item.classificacaoSubtipoAtividadeNome" class="d-flex font-weight-bold align-items-center">
                    <i class="fas fa-list mr-2 font-icon"></i>
                    <span class="font-span">{{item.classificacaoAtividadeNome}}</span>
                </p>
                <p class="d-flex font-weight-bold mb-2 align-items-center" *ngIf="!isIntoModal">
                    <i class="fas fa-paste mr-2 font-icon"></i>
                    <span class="font-span">Processo: {{item.processoJudicialNumero}}</span>
                    <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true" href="/dashboard/processos-judiciais/detalhes/{{item.processoJudicialId}}" target="_blank"></a>
                </p>
            </div>
    
        </ng-container>
    </div>
</div> -->