import { Component, OnInit } from "@angular/core";
import {
  FileUploadBackgound,
  UploadInBlackgroundService,
} from "./upload-in-blackground.service";

@Component({
  selector: "app-upload-in-blackground",
  templateUrl: "./upload-in-blackground.component.html",
  styleUrls: ["./upload-in-blackground.component.scss"],
})
export class UploadInBlackgroundComponent implements OnInit {
  public maximize = true;

  public files: Map<number, FileUploadBackgound> = new Map();
  public progressMap = new Map();
  public show = false;

  /** files */
  get qtdFiles() {
    return this.files.size;
  }

  get allFiles(): FileUploadBackgound[] {
    const f: FileUploadBackgound[] = [];
    this.files.forEach((file) => f.push(file));
    return f;
  }

  /** done */
  get allUploadsDone() {
    return this.allFiles.every((file) => !!file.done);
  }

  /** success */
  get qtdSuccess() {
    return this.allFiles.filter((file) => !!file.success)?.length;
  }

  /** error */
  get qtdError() {
    return this.allFiles.filter((file) => !!file.error)?.length;
  }

  get hasSomeError() {
    return this.allFiles.some((file) => !!file.error);
  }

  /** uploading */
  get qtdLoading() {
    return this.allFiles.filter((file) => !file.done)?.length;
  }


  constructor(public uploadInBackgroundService: UploadInBlackgroundService) {}

  ngOnInit(): void {
    this.uploadInBackgroundService.showUploadInBackgroud.subscribe(
      ({ show, files }) => {
        this.files = files;
        this.show = show;
      }
    );

    this.uploadInBackgroundService.uploadProgress$.subscribe(
        (progress) => (this.progressMap = progress)
      );
  }
}
