
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService extends ServiceBase {

  constructor(private http: HttpClient) 
  {
    super();
  }

  public endpoint = 'Calendario/';
  private dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  
    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }  
    
    getDatasByAno(ano): Observable<any> {
        return this.http.get(environment.api + this.endpoint+ ano)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }  

    addFeriadoFixoExcecao(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-excecao-feriado', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    
    deleteFeriadoFixoExcecao(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint+'deletar-excecao-feriado/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }  
        
    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    calcularDiaUtil(qtdDias:number, dtInicial: Date): Observable<any>{
        let data = {
            quantidadeDias: qtdDias,
            dataInicial: dtInicial
        }
        return this.http.post(environment.api + this.endpoint+'calcular-dia-util' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    calcularDiaUtilNegativo(qtdDias:number, dtInicial: Date): Observable<any>{
        let data = {
            quantidadeDias: qtdDias,
            dataInicial: dtInicial
        }
        return this.http.post(environment.api + this.endpoint+'calcular-dia-util-negativo' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
