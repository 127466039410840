import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificacoesPushService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'notificacaoPush/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    };

    buscarNotificacoes() {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    };

    setNotificationAsRead(id) {

        let url = environment.api + this.endpoint + `set-as-read/${id}`;
        console.log(url);

        return this.http.get(url)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    };
}