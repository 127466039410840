import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// *******************************************************************************
// NgBootstrap

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// *******************************************************************************

// App

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotFoundComponent } from "./views/not-found/not-found.component";
import { AppService } from "./app.service";
import { LayoutModule } from "./layout/layout.module";

// *******************************************************************************
// Pages

import { HomeComponent } from "./views/home/home.component";
import { AcessoLogadoComponent } from "./views/acesso-logado/acesso-logado.component";

// *******************************************************************************
//
// *******************************************************************************
// Libs

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ChartsModule } from "ng2-charts";
import { ThemeSettingsModule } from "src/vendor/libs/theme-settings/theme-settings.module";
import { LoginComponent } from "./views/login/login.component";
import { EsqueceuSenhaComponent } from "./views/login/esqueceu-senha/esqueceu-senha.component";
import { LoginService } from "./services/login.service";
import { HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { TrocarSenhaComponent } from "./views/login/trocar-senha/trocar-senha.component";
import { TextMaskModule } from "angular2-text-mask";
import { FilterPipe } from "./utils/pipeFilter";
import { MentionModule } from 'angular-mentions';

// Material
// *******************************************************************************
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

// Calendar
// *******************************************************************************
import { CalendarModule } from "primeng/calendar";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

// *******************************************************************************
// Pt Br

import { CurrencyPipe, registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { UtilsService } from "./utils/utils.service";
import { AuthModule } from "./auth/auth.module";
import { KeyValuePipe } from "./pipes/key-value.pipe";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { UsuarioService } from "./services/usuario.service";

registerLocaleData(localePt);

import * as moment from "moment";

moment.updateLocale("pt-br", moment().locale);

// *******************************************************************************
import { TranslateModule } from "@ngx-translate/core";
import { DashboardModule } from "./views/dashboard/dashboard.module";
import { UploadInBlackgroundComponent } from "./views/shared/upload-in-blackground/upload-in-blackground.component";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DownloadInBackgroundComponent } from "./views/shared/download-in-background/download-in-background.component";
import { MatProgressBarModule } from '@angular/material/progress-bar';
// register FullCalendar plugins
FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
]);

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,

        // Pages
        AcessoLogadoComponent,
        HomeComponent,
        LoginComponent,
        EsqueceuSenhaComponent,
        TrocarSenhaComponent,
        KeyValuePipe,
        UploadInBlackgroundComponent,
        DownloadInBackgroundComponent
    ],

    imports: [
        BrowserModule,
        FullCalendarModule,
        NgbModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: false,
            timeOut: 2500,
            tapToDismiss: true,
            progressBar: true,
            progressAnimation: "decreasing",
            positionClass: "toast-top-right",
        }),
        // App
        AppRoutingModule,
        LayoutModule,
        PerfectScrollbarModule,
        ChartsModule,
        ThemeSettingsModule,
        FormsModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        CalendarModule,
        AuthModule,
        AngularMultiSelectModule,
        DashboardModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatListModule,
        MatTooltipModule,
        MatProgressBarModule,
        MentionModule,
        // AngularMultiSelectModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        Title,
        AppService,
        LoginService,
        UtilsService,
        UsuarioService,
        { provide: LOCALE_ID, useValue: "pt-BR" },
    ],

    bootstrap: [AppComponent],
})
export class AppModule { }
