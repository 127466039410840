import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgendaDashboardComponent } from './agenda/agenda.component';
import { AreaTrabalhoDashboardComponent } from './area-trabalho/area-trabalho.component';
import { AreaTrabalhoRevisorDashboardComponent } from './area-trabalho-revisor/area-trabalho-revisor.component';
import { CarteiraDeProcessosComponent } from './carteira-de-processos/carteira-de-processos.component'
import { ProcessosJudiciaisDetalhesComponent } from '../operacionais/processos-judiciais/processos-judiciais-detalhes/processos-judiciais-detalhes.component';
import { HonorariosPorValorComponent } from '../operacionais/honorarios/honorarios-por-valor/honorarios-por-valor.component';
import { HonorariosPorPercentualComponent } from '../operacionais/honorarios/honorarios-por-percentual/honorarios-por-percentual.component';
import { ProcessosJudiciaisRecursosDesdobramentosComponent } from '../operacionais/processos-judiciais-recursos-desdobramentos/processos-judiciais-recursos-desdobramentos.component';
import { CriarAtendimentoComponent } from '../operacionais/atendimento/criar-atendimento/criar-atendimento.component';
import { AtendimentoRegistrosComponent } from '../operacionais/atendimento/atendimento-registros/atendimento-registros.component';
import { ListarDocumentosComponent } from '../shared/listar-documentos/listar-documentos.component';
import { DocumentosCadastroComponent } from '../operacionais/documentos/documentos-cadastro/documentos-cadastro.component';
import { AtendimentosListaComponent } from '../operacionais/atendimento/atendimentos-lista/atendimentos-lista.component';
import { ProcessosJudiciaisCadastrarComponent } from '../operacionais/processos-judiciais/processos-judiciais-cadastrar/processos-judiciais-cadastrar.component';
import { PublicacoesComponent } from '../operacionais/publicacoes/publicacoes.component';
import { PublicacaoEditarComponent } from '../operacionais/publicacoes/publicacao-editar/publicacao-editar.component';
import { TarefasUsuarioComponent } from '../dashboard/tarefas-usuario/tarefas-usuario.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DocumentsComponent } from './documents/documents.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ImportarComponent } from '../operacionais/publicacoes/importar/importar.component';
import { AtendimentoDocumentoComponent } from '../operacionais/atendimento/atendimento-documento/atendimento-documento.component';
import { CasosCadastrarComponent } from '../operacionais/casos/casos-cadastrar/casos-cadastrar.component';
import { ListaAtualizacoesComponent } from '../operacionais/atualizacao-valores/lista-atualizacoes/lista-atualizacoes.component';
import { CalculationDataComponent } from '../operacionais/atualizacao-valores/calculation-data/calculation-data.component';
import { PrincipalFinaceiroComponent } from './principal-finaceiro/principal-finaceiro.component';
import { HonorariosListarComponent } from '../operacionais/honorarios/honorarios-listar/honorarios-listar.component';
import { DespesasComponent } from '../operacionais/despesas/despesas.component';
import { CadastrarComponent } from '../operacionais/despesas/cadastrar/cadastrar.component';
import { GestaoDespesasComponent } from './gestao-despesas/gestao-despesas.component';
import { GestaoAcordosJudiciaisComponent } from '../operacionais/gestao-acordos-judiciais/gestao-acordos-judiciais.component';

// *******************************************************************************

//

@NgModule({
    imports: [RouterModule.forChild([
        { path: 'area-trabalho', component: AreaTrabalhoDashboardComponent },
        { path: 'area-trabalho-revisor', component: AreaTrabalhoRevisorDashboardComponent },
        { path: 'agenda', component: AgendaDashboardComponent },
        //{ path: 'publicacoes', component: PublicacoesDashboardComponent },
        { path: 'publicacoes', component: PublicacoesComponent },
        { path: 'publicacoes/publicacao-editar/:id', component:  PublicacaoEditarComponent},
        { path: 'publicacoes/importar', component:  ImportarComponent},
        { path: 'carteira-de-processos', component: CarteiraDeProcessosComponent },
        { path: 'processos-judiciais/cadastrar', component: ProcessosJudiciaisCadastrarComponent, data:{caso:false} },
        { path: 'processos-judiciais/cadastrar/:atendimentoId', component: ProcessosJudiciaisCadastrarComponent },
        { path: 'processos-judiciais/editar/:id', component: ProcessosJudiciaisCadastrarComponent },
        { path: 'processos-judiciais/detalhes/:id', component: ProcessosJudiciaisDetalhesComponent },
        { path: 'processos-judiciais/detalhes/:id/:tab', component: ProcessosJudiciaisDetalhesComponent },
        { path: 'honorario-por-valor/:tipoHonorario/:tipo/:referenciaId', component: HonorariosPorValorComponent },        
        { path: 'honorario-por-valor/:tipoHonorario/:tipo/:referenciaId/:processoJudicialId', component: HonorariosPorValorComponent },
        { path: 'honorario-por-percentual/:tipo/:referenciaId', component: HonorariosPorPercentualComponent },
        { path: 'honorarios-listar/:tipoHonorario/:tipo/:referenciaId', component: HonorariosListarComponent },
        { path: 'honorario-por-valor-editar/:id', component: HonorariosPorValorComponent },
        { path: 'despesas/:tipo/:referenciaId', component:  DespesasComponent },
        { path: 'despesas/cadastrar/:tipo/:referenciaId/:processoJudicialId', component: CadastrarComponent },
        { path: 'despesas/cadastrar/:tipo/:referenciaId/:processoJudicialId/:fechar', component: CadastrarComponent },
        { path: 'despesa/editar/:despesaId', component: CadastrarComponent },
        { path: 'processos-judiciais-recursos/:processoId', component: ProcessosJudiciaisRecursosDesdobramentosComponent, data: { recurso: true } },
        { path: 'processos-judiciais-recursos/:editar/:id', component: ProcessosJudiciaisRecursosDesdobramentosComponent, data: { recurso: true } },
        { path: 'processos-judiciais-desdobramento/:processoId', component: ProcessosJudiciaisRecursosDesdobramentosComponent, data: { recurso: false } },
        { path: 'processos-judiciais-desdobramento/:editar/:id', component: ProcessosJudiciaisRecursosDesdobramentosComponent, data: { recurso: false } },
        { path: 'casos/cadastrar', component: ProcessosJudiciaisCadastrarComponent, data:{caso:true} },
        { path: 'casos/cadastrar/:atendimentoId', component: CasosCadastrarComponent },
        { path: 'atendimento/criar-atendimento', component:  CriarAtendimentoComponent},
        { path: 'atendimento/criar-atendimento/:tipo/:referenciaId', component: CriarAtendimentoComponent },
        { path: 'atendimento/atendimentos-lista/:tipo/:referenciaId', component: AtendimentosListaComponent },
        { path: 'atendimento/atendimento-registros/:id', component: AtendimentoRegistrosComponent },
        { path: 'atendimento/atendimento-documento/:id', component: AtendimentoDocumentoComponent },
        { path: 'atendimento/editar-atendimento/:id', component:  CriarAtendimentoComponent},
        { path: 'documentos/listar-documentos', component: ListarDocumentosComponent },
        { path: 'documentos/listar-documentos/:tipo/:referenciaId', component: ListarDocumentosComponent },
        { path: 'documentos/documentos-cadastro', component: DocumentosCadastroComponent },
        { path: 'documentos/documentos-cadastro/:tipo/:referenciaId', component: DocumentosCadastroComponent },
        { path: 'documentos/documentos-cadastro/:id', component: DocumentosCadastroComponent },
        { path: 'atividades-usuario', component: TarefasUsuarioComponent},
        { path: 'contatos', component: ContactsComponent},
        { path: 'atendimentos', component:  AtendimentosListaComponent},
        { path: 'documentos', component:  DocumentsComponent},
        { path: 'documentos/:numeroProcesso', component:  DocumentsComponent},
        { path: 'alertas', component:  AlertsComponent},
        { path: 'atualizacao-valores', component:  CalculationDataComponent},
        { path: 'atualizacao-valores/:id', component:  CalculationDataComponent},
        { path: 'historico-atualizacao-valores', component:  ListaAtualizacoesComponent},
        { path: 'financeiro', component: PrincipalFinaceiroComponent },
        { path: 'gestao-despesas', component: GestaoDespesasComponent },
        { path: 'gestao-acordos-judiciais', component: GestaoAcordosJudiciaisComponent },
    ])],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
