export enum TipoAcordo {
  Duplicata = 1,
  Cheque = 2,
  NotaPromissoria = 3,
  DebitoAutomatico = 4,
  Confissao = 5
}

export const TipoAcordoLabel = new Map<TipoAcordo, string>([
  [TipoAcordo.Duplicata, "Duplicata"],
  [TipoAcordo.Cheque, "Cheque"],
  [TipoAcordo.NotaPromissoria, "Nota Promissória"],
  [TipoAcordo.DebitoAutomatico, "Débito Automatico"],
  [TipoAcordo.Confissao, "Confissão"],
]);
