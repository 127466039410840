import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashService } from 'src/app/services/dash.service';

@Component({
  selector: 'app-chart-documentos-anexados',
  templateUrl: './chart-documentos-anexados.component.html',
  styleUrls: ['./chart-documentos-anexados.component.css']
})
export class ChartDocumentosAnexadosComponent implements OnInit {

  load = false;
  documento: any [] = [];

  constructor(
    private dashService: DashService,
  ) { }


  ngOnInit(): void {
    this.getChartDocumentosAnexados();
  }


  getChartDocumentosAnexados() {
    this.load = true;
    this.dashService.getDashDocumentosAnexados().subscribe(x => {

      this.documento = x.data;
      this.load = false;
    });
  }

}
