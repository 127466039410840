import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ListaCobradorComponent } from '../../components/lista-cobrador/lista-cobrador.component';

@Component({
    selector: 'app-modal-trocar-cobrador',
    templateUrl: './modal-trocar-cobrador.component.html',
    styleUrls: ['./modal-trocar-cobrador.component.css']
})
export class ModalTrocarCobradorComponent implements OnInit {

    processosFiltrados: any[];

    modalRef: NgbModalRef;

    @ViewChild(ListaCobradorComponent)
    private listaCobradorComponent: ListaCobradorComponent;

    constructor() { }

    ngOnInit(): void {
        this.processosFiltrados = this.modalRef.componentInstance.processosFiltrados;
    }

    onConfirm() {
        this.listaCobradorComponent.concluir();
    }

    public dismiss() {
        this.modalRef.dismiss();
    }

    public close() {
        this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page })
    }

    onChangeDataModal(success: Boolean) {
        if (success) {
            this.close();
        }
    }
}
