<div class="modal-header" *ngIf="isModal">
    <h4 class="modal-title">
        Atendimentos
    </h4>
    <button type="button" class="close" (click)="dismiss()">&times;</button>
</div>
<h4
  class="d-flex justify-content-between align-items-center font-weight-bold pt-3"
  *ngIf="hasFilter"
>
  <span>{{ "Atendimentos" }}</span>
  <button
    type="button"
    class="btn btn-md btn-padrao"
    routerLink="/{{ raiz }}/atendimento/criar-atendimento"
  >
    <span class="fas fa-plus-circle"></span>&nbsp;&nbsp;Cadastrar
  </button>
</h4>
<hr *ngIf="hasFilter" />

<!-- <app-listar-documentos [raiz]="'dash'"></app-listar-documentos> -->

<div class="filtro-header mt-2" *ngIf="hasFilter">
  <div
    class="p-3 filtro-header-titulo d-flex justify-content-between"
    (click)="filtroOn = !filtroOn"
  >
    <span>Filtros</span>
    <span class="lnr lnr-chevron-up" *ngIf="filtroOn"></span>
    <span class="lnr lnr-chevron-down" *ngIf="!filtroOn"></span>
  </div>
  <div class="filtro-documents" *ngIf="filtroOn">
    <div class="form-row align-items-center mb-3">
      <div class="col-md-4 col-sm-6">
        <label class="form-label">Situação</label>
        <ng-select
          [items]="situacoes"
          [(ngModel)]="situacao"
          [ngModelOptions]="{ standalone: true }"
          bindLabel="nome"
          bindValue="id"
        ></ng-select>
      </div>
      <div class="col-md-4 col-sm-6">
        <label class="form-label">Pasta</label>
        <input type="text" [(ngModel)]="pasta" class="form-control" />
      </div>
      <div class="col-md-4 col-sm-12">
        <label class="form-label">Cliente</label>
        <pessoa-dropdown
          [(pessoaId)]="clientes"
          [selecionaMultiplos]="true"
        ></pessoa-dropdown>
      </div>
    </div>

    <div class="form-row align-items-center mb-3">
      <div class="col-md-4 col-sm-12">
        <label class="form-label">Responsável</label>
        <pessoa-dropdown
          [(pessoaId)]="responsaveis"
          [selecionaMultiplos]="true"
        ></pessoa-dropdown>
      </div>
      <div class="col-md-4 col-sm-12">
        <label class="form-label">Último Registro</label>
        <input type="text" [(ngModel)]="ultimoRegistro" class="form-control" />
      </div>
      <div class="col-md-4 col-sm-12">
        <label class="form-label">Número do Processo</label>
        <input type="text" [(ngModel)]="numeroProcesso" class="form-control" />
      </div>
    </div>

    <div class="form-row align-items-center mb-3">
      <div class="col-md-4 col-sm-6">
        <label class="form-label">Data de</label>
        <ng-select
          [items]="tiposFiltroData"
          [(ngModel)]="tipoFiltroData"
          [ngModelOptions]="{ standalone: true }"
          bindLabel="nome"
          bindValue="id"
        ></ng-select>
      </div>
      <div class="col-md-2 col-sm-6">
        <label class="form-label">Data Inicial</label>
        <input
          type="date"
          [(ngModel)]="filtroDataInicio"
          class="form-control"
        />
      </div>
      <div class="col-md-2 col-sm-6">
        <label class="form-label">Data Final</label>
        <input type="date" [(ngModel)]="filtroDataFim" class="form-control" />
      </div>
      <div class="col-md-2 col-sm-4">
        <label class="form-label d-none d-md-block">&nbsp;</label>
        <button
          type="button"
          class="btn btn-padrao btn-block"
          (click)="buscarAtendimentos()"
        >
          <span class="fas fa-search"></span>&nbsp;&nbsp;Filtrar
        </button>
      </div>
      <div class="col-md-2 col-sm-4">
        <label class="form-label d-none d-md-block">&nbsp;</label>
        <button
          type="button"
          class="btn bg-light btn-block"
          (click)="limparFiltros()"
        >
          <span class="fas fa-search"></span>&nbsp;&nbsp;Limpar
        </button>
      </div>
    </div>
  </div>
</div>

<hr class="border-light my-0" />

<div class="card modal-content" style="max-height: 90vh; overflow-y: auto;">
  <div [ngClass]="{ 'card-body': hasFilter }">
    <button
      type="button"
      [disabled]="atendimentos?.length == 0"
      class="btn btn-success float-right mb-3"
      (click)="exportarParaExcel()"
    >
      Download em Excel <i class="fas fa-file-excel ml-2"></i>
    </button>

    <div class="table-responsive" *ngIf="atendimentos?.length > 0">
      <table id="tableAtendimentos" class="table table-striped table-bordered">
        <thead>
          <tr>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('Situacao')"
            >
              Situação<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'Situacao'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('Pasta')"
            >
              Pasta<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'Pasta'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('NomeCliente')"
            >
              Cliente<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'NomeCliente'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('nomeResponsavel')"
            >
              Responsável<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'nomeResponsavel'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('UltimoRegistro')"
            >
              Último Registro<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'UltimoRegistro'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('DataInicio')"
            >
              Data de Início<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'DataInicio'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('DataUltimoRegistro')"
            >
              Data Último Registro<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'DataUltimoRegistro'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('DataEncerramento')"
            >
              Data de Encerramento<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'DataEncerramento'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('DataEncerramento')"
            >
              Motivo Encerramento<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'MotivoEncerramento'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th class="text-center text-nowrap align-middle" *ngIf="!isModal">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tabela" *ngFor="let atend of atendimentos">
            <td>
              {{ atend.situacao }}
            </td>
            <td>
              {{ atend.pasta }}
            </td>
            <td>
              {{ atend.nomeCliente }}
            </td>
            <td>
              {{ atend.nomeResponsavel }}
            </td>
            <td>
              <ng-container
                *ngIf="
                  atend.ultimoRegistro && atend.ultimoRegistro.length <= 30
                "
              >
                {{ atend.ultimoRegistro }}
              </ng-container>
              <ng-container
                *ngIf="atend.ultimoRegistro && atend.ultimoRegistro.length > 30"
              >
                {{ atend.ultimoRegistro | slice : 0 : 30 }}...
              </ng-container>
            </td>
            <td>
              {{ atend.dataInicio | date : "dd/MM/yyyy - HH:mm" }}
            </td>
            <td>
              {{ atend.dataUltimoRegistro | date : "dd/MM/yyyy - HH:mm" }}
            </td>
            <td>
                {{ atend.dataEncerramento | date : "dd/MM/yyyy - HH:mm" }}
            </td>
            <td>
                <ng-container *ngIf="atend.motivoEncerramento && atend.motivoEncerramento.length <= 20">
                    {{atend.motivoEncerramento}}
                </ng-container>
                <ng-container *ngIf="atend.motivoEncerramento && atend.motivoEncerramento.length > 20">
                    <span placement="top" [ngbTooltip]="atend.motivoEncerramento">{{atend.motivoEncerramento
                        |
                        slice:0:20}}...</span>
                </ng-container>
            </td>
            <td class="text-center text-nowrap align-middle link" *ngIf="!isModal">
              <button
                type="button"
                class="btn btn-xs icon-btn btn-warning"
                [routerLink]="[
                  raiz + '/atendimento/atendimento-registros',
                  atend.atendimentoId
                ]"
              >
                <span class="far fa-edit"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="card-body py-0">
        <div class="row">
          <div class="col-sm text-sm-left text-center pt-3">
            <span class="text-muted" *ngIf="totalItens"
              >Páginas {{ paginaAtual }} de {{ totalPaginas }}</span
            >
          </div>
          <div
            class="col-sm text-sm-left text-center pt-3"
            *ngIf="atendimentos?.length > 9"
          >
            <label>Itens por página:</label>
            <select
              class="form-select"
              name="itensPorPagina"
              [(ngModel)]="itensPorPagina"
              (change)="alterarPagina(1)"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
              <option [ngValue]="500">500</option>
            </select>
          </div>
          <div class="col-sm pt-6">
            <ngb-pagination
              [maxSize]="15"
              [collectionSize]="totalItens"
              [pageSize]="itensPorPagina"
              [(page)]="paginaAtual"
              [boundaryLinks]="true"
              (pageChange)="alterarPagina(paginaAtual)"
              size="sm"
              class="d-flex justify-content-center justify-content-sm-end m-0"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5" *ngIf="atendimentos?.length < 1">
      <h3>Não possui dados.</h3>
    </div>
  </div>
</div>
