import { SelectList } from './../../../../models/select-list';
import { ProcessosService } from './../../../../services/processos.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PessoaService } from 'src/app/services/pessoa.service';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-modal-filtro-cobradores',
    templateUrl: './modal-filtro-cobradores.component.html',
    styleUrls: ['../../../../../vendor/styles/pages/modal-filtro-processos-orfaos.scss',
        '../../../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    ],
})

export class ModalFiltroCobradoresComponent implements OnInit{

    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    public cobradoresModal = [];
    public cobradoresModalFiltrados = [];
    public todosOsCobradoresModal = false;
    public filtroOn = false;
    public ordemAlfabetica = false;

    public ordernarPor =
        {
            "": "Selecione",
            "nome": "Nome",
            "numProcessos": "Numero de Processos",
            "valorTotal": "valorTotal"
        };
    public ordernarPorFiltroSelecionado: string = '';

    public valueMax:Number = 0;
    public valueMin:Number = 0;
    public valueTeste:string = "";
    public startingRange = [0,1000000]
    public rangeValorConfig = {
        connect: true,
        step: 10,
        range: {
            min: 0,
            max: 0
        },
        direction: 'ltr',
        format: {
            to: function (value) {
              var formatoValor = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' };

              return value.toLocaleString('pt-BR', formatoValor);
            },
            // 'from' the formatted value.
            // Receives a string, should return a number.
            from: function (value) {

              value = value.replace('R$','');
              value = value.trim();
              value = value.replace(',','');
              value = value.replace('.','');
              value = value.trim();
              var number = parseFloat(value);
              return number;
            }
        }
    };

    public settings = {
        selectMode: 'multi',
        hideSubHeader: true,
        actions: {
            columnTitle: "",
            position: "right",
            add: false,
            edit: false,
            delete: false,
        },
        noDataMessage: "Não possui dado",
        pager: {
            display: false
        },
        columns: {},
        attr: {
            class: 'table table-bordered table-responsive table-modal-tabs-scroll scroll-bar-padrao'
        }
    };



    public dropdownSettingsCredor = {
        singleSelection: true,
        text: "Selecione",
        selectAllText: 'Selecione todas',
        unSelectAllText: 'Remova todas',
        enableSearchFilter: true,
        classes: "multi-select-myclass",
    };

    public columns = {
        nome: { title: 'Nome' }, quantidadeProcessos: { title: 'Número de Processos' },
        valorProcessos: {
            title: 'Valor Total',
            valuePrepareFunction: 
            (value) => { return value === 'Valor Total'? value : Intl.NumberFormat('pt-BR',{style:'currency', currency: 'BRL'}).format(value)}
        }
    };

    modalRef: NgbModalRef;
    isIE10 = false;
    loadingIndicator = true;

    filterCobrador = '';
    filterCredor = '';

    credores = [];
    credorSelecionado: any = [];



    ngOnInit(): void {
        this.settings.columns = this.columns;
        this.getCobradores();
        this.getCredores();
    }

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public pessoaService: PessoaService,
        public processoService: ProcessosService,
        private spinner: NgxSpinnerService,
    ) {

        this.isIE10 = this.appService.isIE10;
    }


    public abrirFecharFiltro() {
        this.filtroOn = !this.filtroOn;
    }

    public onUserRowSelect(event) {
        if (event.data) {
            this.cobradoresModalFiltrados.forEach(element => {
                if (element.pessoaId == event.data.pessoaId && element.selecionado) {
                    element.selecionado = element.selecionado == true ? false : true;
                    return true;
                }
                else if (element.pessoaId == event.data.pessoaId) {
                    element.selecionado = true;
                    return true;
                }
            });
        }
        else {
            this.cobradoresModalFiltrados.forEach(element => {
                var selecionado = event.source.filteredAndSorted.find(s => s.pessoaId == element.pessoaId);
                if (selecionado) {
                    if (element.selecionado) {
                        element.selecionado = element.selecionado == true ? false : true;
                        return true;
                    }
                    else {
                        element.selecionado = true;
                        return true;
                    }
                }
            });
        }
    }

    public filtrar() {
        var lista = this.cobradoresModalFiltrados.filter(c => c.selecionado == true);
        this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'filtroCobradores', lista: lista });
    }

    filtroAvancado() {

        var cobradoresFiltradas = this.cobradoresModal;
        var te = this.valueTeste;
        var ae = this.valueMin;

        if (this.filterCobrador != '') {
            cobradoresFiltradas = cobradoresFiltradas.filter(u => u.nome.toLowerCase().includes(this.filterCobrador.toLowerCase()));
        }

        cobradoresFiltradas = cobradoresFiltradas.filter(u => this.convertStringToNumber(this.convertNumberToString(u.valorProcessos)) >= this.valueMin && this.convertStringToNumber(this.convertNumberToString(u.valorProcessos)) <= this.valueMax);

        if (this.ordemAlfabetica) {
            cobradoresFiltradas = cobradoresFiltradas.sort(function compare(a, b) {
                if (a.nome < b.nome) {
                    return -1;
                }
                if (a.nome > b.nome) {
                    return 1;
                }
                return 0;
            });
        }

        if (this.ordernarPorFiltroSelecionado == "nome") {
            cobradoresFiltradas = cobradoresFiltradas.sort(function compare(a, b) {
                if (a.nome < b.nome) {
                    return -1;
                }
                if (a.nome > b.nome) {
                    return 1;
                }
                return 0;
            });
        }
        if (this.ordernarPorFiltroSelecionado == "numProcessos") {
            cobradoresFiltradas = cobradoresFiltradas.sort(function compare(a, b) {
                if (a.quantidadeProcessos < b.quantidadeProcessos) {
                    return 1;
                }
                if (a.quantidadeProcessos > b.quantidadeProcessos) {
                    return -1;
                }
                return 0;
            });

        }
        if (this.ordernarPorFiltroSelecionado == "valorTotal") {
            cobradoresFiltradas = cobradoresFiltradas.sort(function compare(a, b) {
                if (a.valorProcessos < b.valorProcessos) {
                    return 1;
                }
                if (a.valorProcessos > b.valorProcessos) {
                    return -1;
                }
                return 0;
            });
        }

        this.cobradoresModalFiltrados = cobradoresFiltradas;
    }



    public onSelecionaTodosCobradoresChange(event) {
        if (this.todosOsCobradoresModal) {
            this.cobradoresModalFiltrados.forEach(function (item) { item.selecionado = true; });
        }
        else {
            this.cobradoresModalFiltrados.forEach(function (item) { item.selecionado = false });
        }
    }

    public close() {
        this.modalRef.close();
    }

    public dismiss() {
        this.modalRef.dismiss();
    }


    getCredores() {
        //this.spinner.show();
        //this.toastrService.clear();
        this.pessoaService.getByPessoaTipo("Credor")
            .subscribe(res => {
                this.credores = res.data;
                //this.spinner.hide();
            }, error => {
                //this.spinner.hide();
            });
    }

    public getCobradores() {

        var credorId = this.credorSelecionado.length > 0 ? this.credorSelecionado[0].id : Guid.EMPTY;

        this.spinner.show();
        this.toastrService.clear();
        this.processoService.getCobradores(credorId).subscribe(res => {



                this.cobradoresModal = res.data;
                this.cobradoresModalFiltrados = res.data;

                this.getValorMaximoProcessos();

                this.ordemAlfabetica = true;
                this.filtroAvancado();

                this.spinner.hide();
            });
    }

    limparFiltros() {
        this.filterCobrador = '';
        this.ordemAlfabetica = false;
        this.credorSelecionado = [];
        this.ordernarPorFiltroSelecionado = '';
        this.startingRange = [0, 1000000];
        this.getCobradores();
    }

    deselectAll() {
        this.credorSelecionado = [];
    }

    getValorMaximoProcessos()
    {
      this.rangeValorConfig.range.max = Math.max.apply(Math, this.cobradoresModal.map(function (o) { return o.valorProcessos; }));
      this.valueMin = 0;
      this.valueTeste = "";
      this.valueMax =  this.convertStringToNumber(this.convertNumberToString(this.rangeValorConfig.range.max));
    }

    rangeChanged(event)
    {
      this.valueMax = event[1];
      this.valueMin = event[0];
      this.valueTeste = "mudou";
    }

    convertNumberToString(value){
        var formatoValor = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' };

        return value.toLocaleString('pt-BR', formatoValor);
    }

    convertStringToNumber(value){

        value = value.replace('R$','');
        value = value.trim();
        value = value.replace(',','');
        value = value.replace('.','');
        value = value.trim();
        var number = parseFloat(value);
        return number;
    }

}
