import { Component, OnInit , Input} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'copiar-component',
    templateUrl: './copiar.component.html',
    styleUrls: ['./copiar.component.css']
})
export class CopiarComponent implements OnInit {

    @Input() texto:any;
    public iconCopy: boolean = false;

    constructor(
        private toastrService: ToastrService,
        private clipboard: Clipboard) { }

    ngOnInit(): void {
    }

    handleCopiarTexto(){
        //navigator.clipboard.writeText(this.texto);
        this.clipboard.copy(this.texto);
        //this.toast.open('Copiado com sucesso!', null, {duration: 3000});
        this.toastrService.success("Copiado com sucesso");
    }
}
