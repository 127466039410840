import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashService } from 'src/app/services/dash.service';

@Component({
  selector: 'app-chart-borderos-cadastrados',
  templateUrl: './chart-borderos-cadastrados.component.html',
  styleUrls: ['./chart-borderos-cadastrados.component.css']
})
export class ChartBorderosCadastradosComponent implements OnInit {

  load = false;
  borderos: any [] = [];

  constructor(
    private dashService: DashService,
  ) { }


  ngOnInit(): void {
    this.getChartBorderosCadastrados();
  }


  getChartBorderosCadastrados() {
    this.load = true;
    this.dashService.getDashBorderosCadastrados().subscribe(x => {

      this.borderos = x.data;
      this.load = false;

    });
  }

}

