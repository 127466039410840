import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { ModalDetalheComponent } from '../../dashboard/agenda/modal-detalhe/modal-detalhe.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessosJudiciaisOcorrenciaAudienciaService } from 'src/app/services/processos-judiciais-ocorrencia-audiencia.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-card-process',
    templateUrl: './card-process.component.html',
    styleUrls: ['./card-process.component.scss']
})
export class CardProcessComponent implements OnInit {

    @Input() item: any;
    @Input() isIntoModal: boolean = false;
    @Input() resultConfirmDialog$: any;
    @Input() isKanban: boolean = false;

    @Output() buscarTarefaDoUsuario = new EventEmitter();
    @Output() changeRefresh = new EventEmitter();

    public exibirDetalhes = false;
    public exibirCarregando = false;
    public detalhes = {};

    constructor(
        private spinner: NgxSpinnerService,
        private processosJudiciaisService: ProcessosJudiciaisService,
        private processosJudiciaisOcorrenciaAudienciaService: ProcessosJudiciaisOcorrenciaAudienciaService,
        private ngbModal: NgbModal,
        public toastrService: ToastrService,
    ) { }

    ngOnInit(): void {}

    openModalComments(data) {
        if (this.isIntoModal) return;
        this.spinner.show();
        this.processosJudiciaisService.getById(data.processoJudicialId).subscribe(processo => {
            const modalRef = this.ngbModal.open(ModalDetalheComponent, {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

            modalRef.componentInstance.modalRef = modalRef;
            modalRef.componentInstance.id = data.id;
            modalRef.componentInstance.tipoOcorrencia = data.tipoOcorrencia;
            modalRef.componentInstance.descricao = data.title;
            modalRef.componentInstance.tipoObjeto = data.tipoObjeto;
            modalRef.componentInstance.tipoStatusAtividade = data.tipoStatusAtividade;
            modalRef.componentInstance.responsavelNome = data.responsavelNome;
            modalRef.componentInstance.dataInicioPrazo = data.dataInicioPrazo;
            modalRef.componentInstance.estilo = data.estilo;
            modalRef.componentInstance.corAlertaData = data.corAlertaData;
            modalRef.componentInstance.numeroProcesso = processo.data.numero;
            modalRef.componentInstance.item = data;
            modalRef.componentInstance.buscarTarefaDoUsuario = this.buscarTarefaDoUsuario;

            modalRef.result.then(result => { 
                this.buscarTarefaDoUsuario.emit(data);
                this.spinner.hide();
                this.resultConfirmDialog$.next(result);
            }).catch(error => { });
        });
    }

    consultarDetalhes(item){
        
        if(this.exibirDetalhes) {
            this.exibirDetalhes = false;
            return;
        }

        if(item.tipoOcorrencia == 5){
            this.exibirCarregando = true;
            this.processosJudiciaisOcorrenciaAudienciaService.getById(item.id)
                .subscribe(res=>{
                    this.detalhes = res.data;
                    this.exibirDetalhes = true;
                    this.exibirCarregando = false;
                    console.log(res.data);
                },err=>{
                    this.exibirCarregando = false;
                    this.toastrService.error('Erro ao carregar!', 'Atenção', { timeOut: 10000 });
                })
        }
    }
}
