import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';

@Component({
    selector: 'app-historico-acordo-judicial',
    templateUrl: './historico-acordo-judicial.component.html',
    styleUrls: ['./historico-acordo-judicial.component.scss']
})
export class HistoricoAcordoJudicialComponent implements OnInit {

    public historicos: any[];

    @Input() acordoId: string;
    
    public descricao: string;
    public loadComentario: boolean=false;
    public loadComentarioPage: boolean=false;

    constructor(
        public acordoService: AcordoJuridicoService,
        public toastrService: ToastrService,
        ) { }

    ngOnInit(): void {
        this.carregarHistoricos();
    }

    carregarHistoricos() {
        this.loadComentarioPage = true;
        this.acordoService.obterHistoricos(this.acordoId)
            .subscribe(res=>{
                this.historicos = res.data;
                this.loadComentarioPage = false;
            })
    }

    addHistorico(){
        this.loadComentario = true;
        let data = {
            descricao: this.descricao,
            acordoJudicialId: this.acordoId
        }

        this.toastrService.clear();

        this.acordoService.historicoAdd(data).subscribe(res=>{
            this.toastrService.success('Comentário cadastrado com sucesso!');
            this.carregarHistoricos();
            this.loadComentario = false;
            this.descricao = '';
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Erro ao gravar comentário!', 'Atenção', { timeOut: 10000 });
            }            
        });
    }
}
