<ng-select 
    [items]="equipes" 
    [(ngModel)]="equipeId" 
    [ngModelOptions]="{standalone: true}"
    (keyup)="filtrar($event)"
    bindLabel="nome"
    bindValue="id"
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    placeholder="Digite o nome" 
    (click)="changeEquipe($event)"
    [loading]="loadEquipes">
</ng-select>
