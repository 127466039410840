<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Logs</h6>
                    <div id="content">
                        <ul class="timeline" >
                            <div *ngFor='let event of atividadesLogs;let i = index'>
                                <li class="event" data-date="*" style="margin-bottom: 15px; padding: 5px;">
                                    <h3>{{ event.dataCriacaoFormat }} - {{ event.tipoAtividadeNome }}</h3>
                                    <p><strong>Status anterior:</strong> {{ event.tipoStatusAtividadeAnteriorNome}}.</p>
                                    <p><strong>Status atual:</strong> {{ event.tipoStatusAtividadeAtualNome}}.</p>
                                    <p><strong>Usuário criação:</strong> {{ event.usuarioCriacaoNome}}.</p>
                                    <p><strong>Data de criação:</strong> {{ event.dataCriacaoFormat}}.</p>
                                    <p><strong>Usuário atualização:</strong> {{ event.usuarioAtualizacaoNome}}.</p>
                                    <p><strong>Data de atualização:</strong> {{ event.dataAtualizacaoFormat}}.</p>
                                </li>
                            </div>
                                                        
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>