import { Component, Input, OnInit } from '@angular/core';
import { DespesaService } from 'src/app/services/despesa.service';

@Component({
  selector: 'despesa-resumo',
  templateUrl: './despesa-resumo.component.html',
  styleUrls: ['./despesa-resumo.component.css']
})
export class DespesaResumoComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() raiz: string;
    @Input() processoJudicialId: string;
  
    public valorTotalFaturado: any;
    public valorTotalAberto: any;
    public valorTotalReembolsado: any;
    public valorTotal: any;
    public recolhido: boolean = true;
    public load: boolean = true;    
    public hideButtons: boolean = true;
  
    constructor(
      public despesaService: DespesaService
    ) { }
  
    ngOnInit(): void {     
      this.carregarDados();
    }
  
    recolherDados(){
      this.recolhido = !this.recolhido;
    }
  
    carregarDados(){
      this.despesaService.buscarDespesas(this.tipo, this.referenciaId)
          .subscribe(res=>{
              this.valorTotalAberto = res.data.valorTotalAberto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;
  
              this.valorTotalFaturado = res.data.valorTotalFaturado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;

              this.valorTotalReembolsado = res.data.valorTotalReembolsado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;
  
              this.valorTotal = (res.data.valorTotalAberto + res.data.valorTotalFaturado + res.data.valorTotalReembolsado).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;
  
              this.load = false;
  
              this.recolhido = false;
          });
    }

}
