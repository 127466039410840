<h4
  class="d-flex justify-content-between align-items-center font-weight-bold pt-3"
>
  <span>Financeiro</span>
</h4>
<hr />

<div>
  <mat-tab-group #tabgroup [selectedIndex]="0">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="d-flex">
          <div [matBadge]="5" matBadgeOverlap="false">Lançamentos</div>
        </div>
      </ng-template>
      <app-lancamentos></app-lancamentos>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="d-flex">
          <div>Faturas</div>
        </div>
      </ng-template>

      teste
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="d-flex">
          <div>Fluxo de caixa</div>
        </div>
      </ng-template>

      teste
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="d-flex">
          <div>Configurações</div>
        </div>
      </ng-template>

      teste
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #matTabLabel let-title="title">
  <ng-template mat-tab-label>
    <div matBadge="4" matBadgeOverlap="false" class="demo-section">
      <i class="fas fa-bell"></i>{{ title }}
    </div>
  </ng-template>
</ng-template>

<!-- <ng-template #confirmationChangeTabModal>
    <h4 mat-dialog-title>Deseja continuar?</h4>
    <p>Você possui {{qtdSelecteds}} item(s) selecionado(s) na lista anterior que será desmarcado</p>
    <div mat-dialog-actions class="d-flex justify-content-end mt-3">
      <button mat-dialog-close type="button" class="btn btn-md bg-white">CANCELAR</button>
      <button [mat-dialog-close]="true" cdkFocusInitial type="button" class="btn btn-md btn-padrao">CONFIRMAR</button>
    </div>
  </ng-template>
  
  <ng-template #confirmationDeleteModal>
    <h4 mat-dialog-title>Excluir alerta</h4>
    <p>Tem certeza que deseja excluir {{qtdSelecteds}} alertas?</p>
    <div mat-dialog-actions class="d-flex justify-content-end mt-3">
      <button mat-dialog-close type="button" class="btn btn-md bg-white">CANCELAR</button>
      <button [mat-dialog-close]="true" cdkFocusInitial type="button" class="btn btn-md btn-padrao">CONFIRMAR</button>
    </div>
  </ng-template> -->
