<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
  <span>Alertas</span>
</h4>
<hr />

<div>
  <mat-tab-group #tabgroup [selectedIndex]="0" (selectedIndexChange)="selectedIndexChange(confirmationChangeTabModal, tabgroup, $event)">
    <mat-tab *ngFor="let item of tabs">
      <ng-template mat-tab-label>
        <div class="d-flex">
          <i *ngIf="item.title === 'Importantes'" class="fa fa-star mr-2 text-warning"></i>
          <div *ngIf="!!item.alerts.length" [matBadge]="item.alerts.length" matBadgeOverlap="false">{{item.title}}</div>
          <div *ngIf="!item.alerts.length">{{item.title}}</div>
        </div>
      </ng-template>

      <p *ngIf="!item.alerts.length" class="mt-3 mb-3">Parabéns, você verificou todos os seus alertas</p>

      <ng-container *ngIf="!!item.alerts.length">
        <ng-container>
          <mat-card class="mt-3 mb-3">
            <mat-card-content>
              <div class="d-flex justify-content-between text-muted">
                <mat-checkbox [(ngModel)]="item.selectedAll" class="mr-3" (change)="onSelectedAllAlerts()">
                  <span *ngIf="!qtdSelecteds">Mostrando {{item.alerts.length}} de {{item.alerts.length}} alertas</span>
                  <span *ngIf="!!qtdSelecteds">{{qtdSelecteds}} alertas selecionados</span>
                </mat-checkbox>

                <div *ngIf="!qtdSelecteds" class="d-flex align-items-center">
                  <span class="recolher cursor-pointer">Recolher todos</span>
                  <span class="material-symbols-outlined cursor-pointer ml-4"
                    matTooltip="Exibir os mais antigos primeiro">filter_list</span>
                </div>

                <button *ngIf="!!qtdSelecteds" type="button" class="btn btn-md btn-padrao" (click)="openDialogDelete(confirmationDeleteModal)">
                  DESCARTAR ALERTAS
                </button>
              </div>
            </mat-card-content>
          </mat-card>

          <ng-container *ngFor="let alert of item.alerts">
            <mat-card class="mb-3" [ngClass]="{'selected': alert.selected}">
              <mat-card-content>
                <div class="d-flex">
                  <mat-checkbox class="mr-3" [(ngModel)]="alert.selected" (change)="onSelecAlert()"></mat-checkbox>
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex">
                      <div class="d-flex justify-content-between w-100">
                        <div class="d-flex">
                          <i class="fa fa-{{alert.icon}} mr-2 text-muted"></i>
                          <p class="font-weight-bold">{{alert.title}}</p>
                        </div>
                        <p>02/12/2022 - 04:55</p>
                      </div>
                    </div>

                    <div>
                      <p>{{alert.subTitle}}</p>
                      <p class="text-muted">{{alert.processNUmber}}</p>
                      <p>{{alert.info}}</p>
                    </div>

                    <div class="d-flex justify-content-between mt-2">
                      <div>
                        <a class="functions">Teste</a>
                        <a class="functions">Teste</a>
                        <a class="functions">Teste</a>
                      </div>
                      <span class="text-muted font-weight-bold cursor-pointer" (click)="onDiscardAlert(alert.id)">DESCARTAR ALERTA</span>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #matTabLabel let-title="title">
  <ng-template mat-tab-label>
    <div matBadge="4" matBadgeOverlap="false" class="demo-section"><i class="fas fa-bell"></i>{{title}}</div>
  </ng-template>
</ng-template>

<ng-template #confirmationChangeTabModal>
  <h4 mat-dialog-title>Deseja continuar?</h4>
  <p>Você possui {{qtdSelecteds}} item(s) selecionado(s) na lista anterior que será desmarcado</p>
  <div mat-dialog-actions class="d-flex justify-content-end mt-3">
    <button mat-dialog-close type="button" class="btn btn-md bg-white">CANCELAR</button>
    <button [mat-dialog-close]="true" cdkFocusInitial type="button" class="btn btn-md btn-padrao">CONFIRMAR</button>
  </div>
</ng-template>

<ng-template #confirmationDeleteModal>
  <h4 mat-dialog-title>Excluir alerta</h4>
  <p>Tem certeza que deseja excluir {{qtdSelecteds}} alertas?</p>
  <div mat-dialog-actions class="d-flex justify-content-end mt-3">
    <button mat-dialog-close type="button" class="btn btn-md bg-white">CANCELAR</button>
    <button [mat-dialog-close]="true" cdkFocusInitial type="button" class="btn btn-md btn-padrao">CONFIRMAR</button>
  </div>
</ng-template>