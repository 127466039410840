import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { SituacaoProcessoService } from 'src/app/services/situacao-processo.service';

@Component({
  selector: 'app-modal-atualiza-situacao-processo',
  templateUrl: './modal-atualiza-situacao-processo.component.html',
  providers: [SituacaoProcessoService]
})
export class ModalAtualizaSituacaoProcessoComponent implements OnInit {

  modalRef: NgbModalRef;
  public situacaoProcessos: any;
  public tiposProcessos = [];
  public situacaoReal = [];


  constructor(
    private appService: AppService,
    private situacaoProcessoService: SituacaoProcessoService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.situacaoProcessos = this.modalRef.componentInstance.situacaoProcesso;
    this.tiposProcessos = this.modalRef.componentInstance.camposComplementares.tiposProcessos;
    this.situacaoReal = this.modalRef.componentInstance.camposComplementares.situacaoRealProcessos;
  }
  

  atualizar(){
    this.update(this.situacaoProcessos);
}

update(situacaoProcesso) {
  
    this.spinner.show();
    this.toastrService.clear();

    this.situacaoProcessoService.update(situacaoProcesso)
        .subscribe(res => {

            this.toastrService.success("Situacao de Processos atualizada.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível atualizar a Situação dos Processos!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
}




close() {
  this.modalRef.close();
}

dismiss() {
  this.modalRef.dismiss();
}


}

