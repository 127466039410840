<ng-container *ngIf="show">
  <div
    class="download-card"
    [ngClass]="{ maximize: maximize, minimize: !maximize }"
    *ngIf="show"
  >
    <div class="header-card pl-3 pr-3 pt-3">
      <div class="d-flex justify-content-between">
        <h5 *ngIf="!allDownloadsDone">
          <ng-container>
            Downloading {{ qtdLoading }}
            <span *ngIf="qtdLoading === 1">item</span>
            <span *ngIf="qtdLoading > 1">itens</span>
          </ng-container>

          <ng-container *ngIf="hasSomeError">
            / {{ qtdError }} com erro.
          </ng-container>
        </h5>

        <h5 *ngIf="allDownloadsDone">
          <p *ngIf="qtdSuccess > 0" class="m-0">
            {{ qtdSuccess }} download(s) concluído(s)
          </p>
          <p *ngIf="qtdError > 0" class="m-0">
            {{ qtdError }} download(s) com erro
          </p>
        </h5>

        <div>
          <i
            class="fas fa-{{ maximize ? 'minus' : 'plus' }} cursor-pointer"
            (click)="maximize = !maximize"
          ></i>

          <b
            *ngIf="allDownloadsDone"
            class="cursor-pointer ml-2"
            (click)="downloadInBackgroundService.hide()"
            >X</b
          >
          <!-- [matTooltip]="maximize ? 'Minimizar' : 'Maximizar'" -->
        </div>
      </div>
    </div>

    <div class="pl-3 pr-3 pt-2" style="height: 15em; overflow: auto">
      <mat-list-item *ngFor="let f of files | keyvalue">
        <ng-container *ngIf="f.value as file">
          <div
            matListItemTitle
            class="d-flex align-items-center justify-content-between"
            style="padding-top: 1px"
          >
            {{ file.name }}
            <i
              *ngIf="file?.success && !file?.error"
              class="fas fa-check-circle text-success"
              style="font-size: 18px"
            ></i>
            <i
              *ngIf="file?.error && !file?.success"
              class="fas fa-exclamation-circle text-danger"
              style="font-size: 18px"
            ></i>
          </div>
          <div class="d-flex align-items-center" *ngIf="!file?.success && !file?.error">
            <ng-container *ngFor="let p of progressMap | keyvalue">
              <ng-container *ngIf="p.value as progress">
                <mat-progress-bar
                  *ngIf="progress.fileId === file.id"
                  class="mr-3"
                  mode="determinate"
                  [value]="progress.progress"
                ></mat-progress-bar>
                <span *ngIf="progress.fileId === file.id">{{ progress.progress }}%</span>
              </ng-container>
            </ng-container>
          </div>
          <hr />
        </ng-container>
      </mat-list-item>
    </div>
  </div>
</ng-container>
