import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClassificacaoMateriaPedidoService } from 'src/app/services/classificacao-materia-pedido.service';

@Component({
    selector: 'classificacao-materia-pedido-dropdown',
    templateUrl: './classificacao-materia-pedido-dropdown.component.html'
})
export class ClassificacaoMateriaPedidoDropdownComponent implements OnInit {

    @Input() classificacaoMateriaPedidoId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() classificacaoMateriaPedidoIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public classificacoesMateriasPedidos: any = [];
    public load: boolean = false;
    public classificacaoMateriaPedido: any;

    constructor(
        private _classificacaoMateriaPedidoService: ClassificacaoMateriaPedidoService
    ) { }

    ngOnInit(): void {

        // if (this.classificacaoMateriaPedidoId != undefined && this.classificacaoMateriaPedidoId != null && this.classificacaoMateriaPedidoId != '') {
        //     this.buscarPorId();
        // } else {
        //     this.buscarPorNome('a');
        // }
        this.carregarTodos();
    }
    carregarTodos() {
        this._classificacaoMateriaPedidoService.listar().subscribe(res=>{
            this.classificacoesMateriasPedidos = res.data;
        });
    }

    changeClassificacaoMateriaPedido($event) {

        this.classificacaoMateriaPedidoIdChange.emit(this.classificacaoMateriaPedidoId);
        this.change.emit(this.classificacoesMateriasPedidos.filter(x => x.id == this.classificacaoMateriaPedidoId)[0]);
    }

    filtrar(term: any) {

        if (term.target.value != '')
            this.buscarPorNome(term.target.value);
    }

    buscarPorNome(buscar) {

        this.load = true;

        this._classificacaoMateriaPedidoService.obterPorFiltroNomeAsync(buscar).then(x => {

            this.load = false;
            this.classificacoesMateriasPedidos = x.data;

            if (this.classificacaoMateriaPedido != undefined) {
                this.classificacoesMateriasPedidos.push(this.classificacaoMateriaPedido);
            }
        });
    }

    buscarPorId() {

        this.load = true;
        this._classificacaoMateriaPedidoService.getByIdAsync(this.buscarPorId)
            .then(res => {
                this.load = false;
                let classificacoesMateriasPedidos = [];
                classificacoesMateriasPedidos.push(res.data);
                this.classificacaoMateriaPedido = res.data;
                this.classificacoesMateriasPedidos = classificacoesMateriasPedidos;
            })
    }

    limpar() {

        this.classificacaoMateriaPedidoIdChange.emit(null);
    }
}
