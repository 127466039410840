import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { NotaDeDebitoService } from 'src/app/services/notadedebito.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-pagamento-nota-debito',
  templateUrl: './modal-pagamento-nota-debito.component.html',
  styleUrls: ['./modal-pagamento-nota-debito.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
],
})
export class ModalPagamentoNotaDebitoComponent implements OnInit {

    public modalRef: NgbModalRef;
    public processoJudicialRecursoDesdobramentoId;
    public nomeCliente;

    public displayMonths = 1;
    public navigation = "select";
    public arquivoLabel = "Gerar arquivo";
    public classLabel = "fas fa-file-pdf"
    public loadGerarArquivo = false;
    public form: FormGroup;

    public activeTab = 1;

    public listaDespesas = [];
    public loadArquivosDespesas = false;
    public listArquivosDespesas = [];
    public listaArquivoUpload = [];
    public arquivoId: Guid;

    public loadNaoFaturadas = false;

    public isUpdate = false;

    get despesas() {
        return this.form.get("despesas") as FormArray;
    }

    constructor(
        private notaDebitoService: NotaDeDebitoService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private parserFormatter: NgbDateParserFormatter,
        private arquivoService: ArquivoService,
        private utilsService: UtilsService
    ) { }

    async ngOnInit(): Promise<void> {
        this.form = this.formBuilder.group({
            nomeCliente: new FormControl({
                value: this.modalRef.componentInstance.nomeCliente,
                disabled: true,
            }),
            clienteId: null,
            id: new FormControl({value: this.modalRef.componentInstance.debitoId,}),
            observacao: null,
            dataVencimento: null,
            dataCriacao: null,
            dataPagamento: {
                value: null
            },
            valorDebito: { value: null, disabled: true },
            valorCredito: null,
            numeroRecibo: null,
            despesas: this.formBuilder.array([]),
        });
        this.isUpdate = this.modalRef.componentInstance.isEdit;
        this.getDespesas();
        this.arquivoId = Guid.parse(this.modalRef.componentInstance.arquivoId);
        this.verificarDownloadArquivo();
    }

    verificarDownloadArquivo() {
        console.log(this.arquivoId);
        console.log(Guid.createEmpty());
        if (!this.arquivoId.equals(Guid.createEmpty())) {
            this.arquivoLabel = "Download";
            this.classLabel = "fas fa-download"
        }
    }

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    getArquivosDespesas(){
        const notaDeDebitoId = this.modalRef.componentInstance.debitoId; 
        this.listaArquivoUpload = [];
        this.loadArquivosDespesas = true;
        
        this.notaDebitoService.buscarArquivosDespesas(notaDeDebitoId).subscribe((res) => {
            this.listArquivosDespesas = res.data;
            this.loadArquivosDespesas = false;
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível buscar os arquivos!', 'Atenção', { timeOut: 10000 });
            }
        });
    }

    onFilechange(event, arquivo){ 
        if(!arquivo.arquivoCorrompido){
            this.toastrService.error('Só é possível substituir arquivos que estão corrompidos na base!', 'Atenção', { timeOut: 10000 });
            return;
        }
        const file = event.target.files[0];
        if(file){
            const notaDeDebitoId = this.modalRef.componentInstance.debitoId
            const formData = new FormData();
            formData.append('notaDeDebitoId', notaDeDebitoId);
            formData.append('despesaId', arquivo.despesaId);
            formData.append('recursoDesdobramentoId', arquivo.recursoDesdobramentoId);
            formData.append('arquivoId', arquivo.id);
            formData.append('files', file);

            this.swalWithBootstrapButtons.fire({
                title: 'Deseja fazer upload do arquivo?',
                text: `o arquivo ${arquivo.nomeArquivo} será substituído por ${file.name}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não, cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                this.notaDebitoService.atualizarArquivosDespesas(formData).subscribe(                
                    data => {
                        this.toastrService.success('Arquivo substituido com sucesso', 'Sucesso', { timeOut: 10000 });
                        this.getArquivosDespesas()
                    }, error => {this.toastrService.error('Não foi possível excluir a nota!', 'Atenção', { timeOut: 10000 });});
                }
            });
        }
        
    }
    popItem(index){
        this.listaArquivoUpload.splice(index, 1);
    }

    getDespesas() {
        this.notaDebitoService
            .getById(this.modalRef.componentInstance.debitoId)
            .subscribe((res) => {
                this.form.patchValue({
                    ...res.data,
                    dataPagamento: this.parseData(res.data.dataPagamento),
                    dataVencimento: this.parseData(res.data.dataVencimento),
                });
                if(res.data.dataVencimento)
                    this.form.get("dataVencimento").disable()

                this.listaDespesas = res.data.despesas;
            });
    }

    parseData = (date) => {
        if (!date) return null;
        date = date.split('T')[0].split('-');
        return this.parserFormatter.parse(`${date[2]}/${date[1]}/${date[0]}`);
    }

    convertDate(date) {
        return date ? new Date(date.year, date.month - 1, date.day, 23, 59, 59) : null;
    }

    salvar() {
        

        if(!this.validateForm()) return;
        
        const valueForm = this.form.getRawValue();
        const params = {
            ...valueForm,
            dataPagamento: this.convertDate(valueForm.dataPagamento),
            dataVencimento: this.convertDate(valueForm.dataVencimento),
        };

        this.update(params);
    }


    validateForm() {
        const dataVencimento = this.convertDate(this.form.get("dataVencimento").value);
        const dataPagamento = this.convertDate(this.form.get("dataPagamento").value);
        const valorCredito  = parseFloat(this.form.get("valorCredito").value);
        const valorDebito = this.convertDate(this.form.get("valorDebito").value);
        if(!dataVencimento){
            this.toastrService.error("Data de vencimento é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }
        if(!dataPagamento){
            this.toastrService.error("Data de pagamento é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }
        if(!valorCredito){
            this.toastrService.error("Valor de crédito é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }
        if(valorCredito <= 0){
            this.toastrService.error("Valor de crédito deve ser maior que zero", 'Atenção', { timeOut: 10000 });
            return false;
        }
        
        
        return true;
    }


    update(params) {
        this.spinner.show();

        delete params.despesas;
        this.notaDebitoService.editarNotaDebito(params).subscribe((res) => {
            this.spinner.hide();
            this.toastrService.success(
                "Nota de Débito paga com sucesso!",
                "Sucesso"
            );
            this.close();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível gravar!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    gerarArquivoNotaDebito() {
        if(!this.arquivoId.equals(Guid.createEmpty())){
            this.handleDownloadArquivo(this.modalRef.componentInstance.arquivoId);
            return;
        }
        this.loadGerarArquivo = true;
        this.arquivoLabel = "Gerando arquivo";
        this.notaDebitoService
            .gerarArquivoNotaDebito(this.modalRef.componentInstance.debitoId)
            .subscribe(
                (res) => {
                    this.loadGerarArquivo = false;
                    this.handleDownloadArquivo(res.data);
                    this.arquivoId = Guid.parse(res.data);
                    this.verificarDownloadArquivo();
                },
                (error) => {
                    this.toastrService.error('Não foi possível gerar o arquivo!', 'Atenção', { timeOut: 10000 });
                    this.loadGerarArquivo = false;
                    this.arquivoLabel = "Gerar arquivo";
                }
            );
    }

    handleDownloadArquivo(arquivoId:any){
        this.spinner.show();
        try{
            this.arquivoService.downloadArquivo(arquivoId).subscribe(                
                data => {                    
                    if(data.type == 3){
                        this.spinner.hide();                    
                    }
                    this.utilsService.renderDownload(data, arquivoId as string);
                }
            );
        }catch{
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }

}
