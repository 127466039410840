import { OcorrenciaService } from 'src/app/services/ocorrencia.service';


import { Component, OnInit } from '@angular/core';
import { PessoaService } from 'src/app/services/pessoa.service';
import { MidiasSociaisDevedor } from 'src/app/models/contato-devedor/midias-sociais-devedor';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { Ocorrencia } from 'src/app/models/operacionais/ocorrencia/ocorrencia';
import { TipoOcorrencia } from 'src/app/enums/tipo-ocorrencia.enum';

@Component({
    selector: 'app-modal-historico-redirecionamento',
    templateUrl: './modal-historico-redirecionamento.component.html',
    styleUrls: ['./modal-historico-redirecionamento.component.css']
})
export class ModalHistoricoRedirecionamentoComponent implements OnInit {

    modalRef: NgbModalRef;

    processoId:Guid;

    public ocorrencias: Ocorrencia[] = [];

    constructor(public ocorrenciaService:OcorrenciaService) { }

    ngOnInit(): void {
       this.processoId = this.modalRef.componentInstance.processoId;

       this.ocorrenciaService.getAllByProcesso(this.processoId).subscribe(oco => {
          this.ocorrencias = oco.data;
          this.ocorrencias= this.ocorrencias.filter(x => x.tipo == TipoOcorrencia.ProcessoRedirecionamento)
          this.ocorrencias.sort((a: Ocorrencia, b: Ocorrencia) => {
            return +new Date(b.data) - +new Date(a.data);
        });
       })

    }


    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }





}

