<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">
        Operacionais / Processos Judiciais /</span>{{recurso ? 'Recurso' : 'Desdobramento'}}</span>        
</h4>
<hr class="border-light my-0">
<div class="d-flex justify-content-center" *ngIf="!loadProcesso">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="container mb-5" *ngIf="loadProcesso">
    <div class="row">
        <div class="col-8">
            <h1>{{ processo.titulo }}</h1>
            <div *ngFor="let tag of processo.tags" class="mr-1" [ngClass]="defineClass(tag.tagCor)">{{tag.tagNome}}
            </div>
        </div>        
    </div>
    <div class="row mt-4">
        <div class="col">
            Processo | {{ processo.numero}}
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            Cliente | <span *ngFor="let cliente of processo.clientes; let isLast=last">{{ cliente.nome }}{{isLast ? '' :
                ', '}}</span>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            Responsável | {{ processo.responsavelNome}}
        </div>
    </div>
    <div class="row mt-2 check-box">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)] = "recusoDesdobramento.ativo" id="ativo">
            <label class="form-check-label mt-2" for="ativo">
              Ativo
            </label>
          </div>
    </div>
</div>
<div class="container mb-5" *ngIf="loadProcesso">
    <form ngNoForm>
        <div class="form-row" *ngIf="!recurso">
            <div class="form-group col-12">
                <label class="form-label">Desdobramento de:<span class="required-field">*</span></label>
                <ng-select [inputAttrs]="{ 'data-lpignore': 'true' }" [items]="recursosDesdobramentos" [(ngModel)]="recusoDesdobramento.recursoId" [ngModelOptions]="{standalone: true}" bindLabel="display" bindValue="id" placeholder="Desdobramento de" [loading]="loadRecursosDesdobramentos"></ng-select>
            </div>       
        </div>
        <div class="form-row">
            <div class="form-group col-6">
                <label class="form-label">Instância<span class="required-field">*</span></label>
                <ng-select [inputAttrs]="{ 'data-lpignore': 'true' }" [items]="instancias" [(ngModel)]="recusoDesdobramento.instanciaId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Instância" [loading]="loadInstancias"></ng-select>
            </div>       
            <!-- <div class="form-group col-6">
                <label class="form-label">Tipo<span class="required-field">*</span></label>
                <ng-select [inputAttrs]="{ 'data-lpignore': 'true' }" [items]="tiposProcessosJudiciaisRecursosDesdobramentos" [(ngModel)]="recusoDesdobramento.tipoProcessoJudicialRecursoDesdobramentoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Tipo" [loading]="loadtipoRecursoDesdobramento"></ng-select>
            </div>        -->
            <div class="form-group col">
                <label class="form-label">Tipo<span class="required-field">*</span></label>
                <ng-select [items]="acoes" [(ngModel)]="recusoDesdobramento.acaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a ação" [loading]="loadAcoes"></ng-select>
            </div>
        </div>        
        <div class="form-row">            
            <div class="form-group col-8">
                <label class="form-label">Número</label>
                <input type="text" [textMask]="{mask: mascaraNumero, guide: false}" class="form-control"
                    placeholder="Digite o número do processo" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="recusoDesdobramento.numero" data-lpignore="true">
            </div>
            <div class="form-group col-4">
                <label class="form-label">Distribuído em</label>                
                <div class="input-group">
                    <input (click)="distribuidoEm.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" ngbDatepicker [(ngModel)]="recusoDesdobramento.distribuidoEm"
                                    [displayMonths]="displayMonths" [navigation]="navigation" placement="bottom-right"
                                    #distribuidoEm="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="input-group-text" (click)="distribuidoEm.toggle()" type="button">
                                        <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                    </button>
                                </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Divisão de órgão</label>
                <ng-select [items]="dividoesOrgao" [(ngModel)]="recusoDesdobramento.divisaoDeOrgaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Divisão de órgão" [loading]="loadTipoPossibilidadeProcessoJudicial"></ng-select>
            </div>
            <div class="form-group col">
                <label class="form-label">Órgão</label>
                <ng-select [items]="orgaos" [(ngModel)]="recusoDesdobramento.orgaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Órgão" [loading]="orgaos == undefined || !orgaos.length"></ng-select>
            </div>            
        </div>
        <div class="form-row">
            <!-- <div class="form-group col-2">
                <label class="form-label">Juízo</label>
                <ng-select [inputAttrs]="{ 'data-lpignore': 'true' }" [items]="dividoesOrgao" [(ngModel)]="recusoDesdobramento.juizo" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Juízo" [loading]="loadDivisoesOrgaos"></ng-select>
            </div> -->
            <!-- <div class="form-group col-6">
                <label class="form-label">Tipo/Vara</label>
                <ng-select [inputAttrs]="{ 'data-lpignore': 'true' }" [items]="varas" [(ngModel)]="recusoDesdobramento.varaId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Vara" [loading]="loadVaras"></ng-select>
            </div> -->
            <div class="form-group col">
                <label class="form-label">Foro</label>
                <ng-select [items]="foros" [(ngModel)]="recusoDesdobramento.foroId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Foro" [loading]="loadForos"></ng-select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Fase</label>
                <ng-select [items]="fases" (ngModelChange)="changeFase($event)" [(ngModel)]="recusoDesdobramento.faseProcesso" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a fase" [loading]="fases == undefined || !fases.length"></ng-select>
            </div>
            <div class="form-group col">
                <label class="form-label">Situação</label>
                <ng-select [items]="situacoesProcesso" [(ngModel)]="recusoDesdobramento.situacaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a fase" [loading]="fases == undefined || !fases.length"></ng-select>
            </div>
        </div>   
        <!-- <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Ação</label>
                <ng-select [items]="acoes" [(ngModel)]="recusoDesdobramento.acaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a ação" [loading]="loadAcoes"></ng-select>
            </div>
        </div> -->
        <div class="form-row">
            <div class="form-group col-4">
                <label class="form-label">Comarca</label>
                <ng-select [items]="comarcas" [(ngModel)]="recusoDesdobramento.comarcaId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a comarca" [loading]="comarcas == undefined || !comarcas.length"></ng-select>
            </div>
            <div class="form-group col-4">
                <label class="form-label">UF</label>
                <ng-select [items]="ufs" [(ngModel)]="recusoDesdobramento.ufId" [ngModelOptions]="{standalone: true}" bindLabel="displayName" bindValue="id" placeholder="Digite a UF" [loading]="ufs == undefined || !ufs.length"></ng-select>
            </div>
            <div class="form-group col-4">
                <label class="form-label">Área</label>
                <ng-select [items]="areas" [(ngModel)]="recusoDesdobramento.areaId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a Área" [loading]="areas == undefined || !areas.length"></ng-select>
            </div>
        </div>
        <div class="form-row">            
            <div class="form-group col">
                <label class="form-label">Tipo Processo</label>
                <ng-select [items]="ehProcessoEletronico" [(ngModel)]="ehProcessoEletronicoSelecionado" 
                    [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                    (click)="changeProcessoEletronico($event)"></ng-select>                
            </div>
        </div>
        <div class="form-row" *ngIf="recusoDesdobramento.processoEletronico">
            <div class="form-group col">
                <label class="form-label">Tipo processo eletrônico</label>
                <ng-select [items]="tiposProcessosEletronicos" [(ngModel)]="recusoDesdobramento.tipoProcessoEletronicoId" 
                [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" 
                [loading]="tiposProcessosEletronicos == undefined || tiposProcessosEletronicos.length == 0"></ng-select>
            </div>
        </div>
        <div class="form-row">            
            <div class="form-group col">
                <label class="form-label">Link no tribunal</label>
                <input type="text" class="form-control" placeholder="Digite o link no tribunal"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="recusoDesdobramento.linkTribunal">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Juiz / Relator</label>
                <input type="text" class="form-control" placeholder="Nome do juiz ou relator do processo"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="recusoDesdobramento.juizRelator">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Valor da causa</label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                    type="text" class="form-control" placeholder="Digite o valor" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="recusoDesdobramento.valorCausa" maxlength="16">
            </div>
            <div class="form-group col">
                <label class="form-label">Valor histórico</label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                    type="text" class="form-control" placeholder="Digite o valor" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="recusoDesdobramento.valorHistorico" maxlength="16">
            </div>
            <div class="form-group col">
                <label class="form-label">Valor atualizado</label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                    type="text" class="form-control" placeholder="Digite o valor" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="recusoDesdobramento.valorAtualizado" maxlength="16">
            </div>
            <div class="form-group col">
                <label class="form-label">Valor da condenação</label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                    type="text" class="form-control" placeholder="Digite o valor" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="recusoDesdobramento.valorCondenacao" maxlength="16">
            </div>
        </div>  
        <div class="form-row">
            <div class="form-group col-8">
                <label class="form-label">Partes<span class="required-field ml-1">*</span></label>
                <pessoa-dropdown [(pessoaId)]="recusoDesdobramento.partes[0].pessoaId"></pessoa-dropdown>                
                <br />
                <pessoa-dropdown [(pessoaId)]="recusoDesdobramento.partes[1].pessoaId"></pessoa-dropdown>
            </div>
            <div class="form-group col-4">
                <label class="form-label">Qualificação<span class="required-field ml-1">*</span></label>
                <ng-select [items]="qualificacoes" [(ngModel)]="recusoDesdobramento.partes[0].qualificacaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Qualificação" [loading]="loadQualificacoes"></ng-select>
                <br />
                <ng-select [items]="qualificacoes" [(ngModel)]="recusoDesdobramento.partes[1].qualificacaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Qualificação" [loading]="loadQualificacoes"></ng-select>
            </div>
        </div>
        <hr>
        <div class="d-flex justify-content-end">
            <button type="button" (click)="handleCancel()" class="btn btn-light mr-3">Cancelar</button>
            <button type="button" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
        </div>
    </form>
</div>