<div style="min-height: 500px;display: flex;flex-direction: column;justify-content: center;" class="text-center mt-5"
    *ngIf="loading">
    <div class="fa-3x">
        <i class="fas fa-spinner fa-spin"></i>
    </div>
</div>

<div *ngIf="!loading">
    <!-- Modal template -->
    <div class="modal-header">
        <h5 class="modal-title">
            {{title}}
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body" [ngClass]="{disabledDiv: readonly}">
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Tipo pessoa: </label>
                <input *ngIf="this.modalRef.componentInstance.updateData" type="text" class="form-control"
                    [(ngModel)]="pessoa2.tipo" disabled>
                <select *ngIf="!this.modalRef.componentInstance.updateData" class="custom-select"
                    [(ngModel)]="pessoa2.tipo" (change)="onChangeTipoPessoa($event.target.value)">
                    <option *ngFor="let item of tiposPessoas" [value]="item">{{item}}
                    </option>
                </select>
            </div>
            <div class="form-group col">
                <label class="form-label">Nome completo:</label>
                <input type="text" class="form-control" [(ngModel)]="pessoa2.nome" [disabled]="isUpdateContato">
            </div>
            <div class="form-group col">
                <label class="form-label">Nickname:</label>
                <input type="text" class="form-control" [(ngModel)]="pessoa2.nickname">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col ">
                <label class="form-label">E-mail:</label>
                <input type="text" class="form-control" placeholder="" [(ngModel)]="pessoa2.email"
                    [disabled]="isUpdateContato">
            </div>
            <div class="form-group col">
                <label class="form-label">CPF / CNPJ</label>
                <input type="text" class="form-control" placeholder="" [(ngModel)]="pessoa2.cpfCnpj"
                    [textMask]="{mask: getCpfCnpjMask(), guide: false}" [disabled]="isUpdateContato">
            </div>
            <div class="form-group col">
                <label class="form-label">Pasta Jurídico</label>
                <input type="text" class="form-control" placeholder="" [(ngModel)]="pessoa2.codigoCliente">
            </div>
            <div class="form-group col">
                <label class="form-label">Sequêncial do Processo</label>
                <input type="text" class="form-control" placeholder="" [(ngModel)]="pessoa2.numeroProcessoSequencial">
            </div>
        </div>
        <div class="form-row mt-2 mb-2" *ngIf="!isUpdateContato">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="exibirListaDeRamais"
                    [(ngModel)]="pessoa2.exibirListaDeRamais" value="something" checked>
                <label class="form-check-label mt-1" for="exibirListaDeRamais">Exibir na lista de ramais</label>
            </div>
        </div>

        <div class="nav-tabs-top nav-responsive-sm" *ngIf="pessoa2.tipo != null && pessoa2.tipo != ''">

            <ul ngbNav [(activeId)]="active" #productTabs="ngbNav" class="nav-tabs">
                <li ngbNavItem [ngbNavItem]="1" *ngIf="pessoa2.tipo == 'Advogado' && !isUpdateContato">
                    <a ngbNavLink>Informações cadastro</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            OAB
                            <div class="input-group">
                                <input type="text" class="form-control"
                                    [(ngModel)]="getValue(pessoaMap.advogadoInfoCad.advogadoInfoCadPd,pessoaMap.advogadoInfoCad.oab,pessoa2).valor">
                            </div>
                        </div>
                    </ng-template>
                </li>



                <li ngbNavItem ngbNavItem [ngbNavItem]="2" *ngIf="pessoa2.tipo == 'Credor'  && !isUpdateContato">
                    <a ngbNavLink>Informações cadastro</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">

                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Unidade de negócio</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.unidadeNegocio,pessoa2).valor">
                                </div>

                                <div class="form-group col ">
                                    <label class="form-label">Grupo</label>
                                    <input id="typeahead-basic" type="text" class="form-control"
                                        [(ngModel)]="pessoa2.grupo" placeholder="Grupo" [ngbTypeahead]="search" />
                                </div>

                                <div class="form-group col ">
                                    <label class="form-label">Situação</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.situacao,pessoa2).valor">
                                        <option *ngFor="let situacaoCliente of situacoesCliente"
                                            [value]="situacaoCliente.value">
                                            {{situacaoCliente.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Tipo</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.tipo,pessoa2).valor">
                                        <option *ngFor="let tipoCliente of tiposCliente" [value]="tipoCliente.value">
                                            {{tipoCliente.value}}
                                        </option>
                                    </select>
                                </div>



                                <div class="form-group col ">
                                    <label class="form-label">IE / RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.ieRg,pessoa2).valor">
                                </div>
                            </div>

                            <div class="form-row">

                                <div class="form-group col ">
                                    <label class="form-label">Contato</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.contato,pessoa2).valor">
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Site</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.site,pessoa2).valor">
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem *ngIf="pessoa2.tipo == 'Credor' && !isUpdateContato">
                    <a ngbNavLink>Politica de acordo</a>
                    <ng-template ngbNavContent>

                        <div class="card-body">

                            <div class="form-row col-md-12">

                                <div class="form-group col-md-4 ">
                                    <label class="form-label">Percentual de juros minimo</label>
                                    <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.jurosMinimo,pessoa2).valor"
                                        [disabled]="checarAcordo">
                                </div>

                                <div class="form-group col-md-4 ">
                                    <label class="form-label">Percentual de juros maximo</label>
                                    <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.jurosMaximo,pessoa2).valor"
                                        [disabled]="checarAcordo">
                                </div>

                            </div>


                            <div class="form-row col-md-12">


                                <div class="form-group col-md-4 ">
                                    <label class="form-label">Parcelamento minimo</label>
                                    <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.parcelamentoMinimo,pessoa2).valor"
                                        [disabled]="!permitirParcelamento">
                                </div>

                                <div class="form-group col-md-4 ">
                                    <label class="form-label">Parcelamento maximo</label>
                                    <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.parcelamentoMaximo,pessoa2).valor"
                                        [disabled]="!permitirParcelamento">
                                </div>

                            </div>

                            <div class="form-row col-md-12">


                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Permitir Parcelamento ?</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-2 pl-0 pt-1 pb-1"
                                        style="display:flex;flex-direction: row;">
                                        <div
                                            style="display: flex;flex-direction: row;align-content: center;margin-right: 15px;">
                                            <span style="margin-right: 3px;">Sim</span>
                                            <input type="radio" name="radios-parcelamento" class="cursor-pointer-padrao"
                                                [checked]="permitirParcelamento"
                                                (change)="handleChangeParcelamento($event,'Sim')"
                                                [disabled]="checarAcordo">
                                        </div>

                                        <div style="display: flex;flex-direction: row;align-content: center;">
                                            <span style="margin-right: 3px;">Não</span>
                                            <input type="radio" name="radios-parcelamento" class="cursor-pointer-padrao"
                                                [checked]="!permitirParcelamento"
                                                (change)="handleChangeParcelamento($event,'Não')"
                                                [disabled]="checarAcordo">
                                        </div>

                                    </div>
                                </div>


                            </div>

                            <div class="form-row col-md-12">


                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Politica </strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-2 pl-0 pt-1 pb-1"
                                        style="display:flex;flex-direction: row;">
                                        <div
                                            style="display: flex;flex-direction: row;align-content: center;margin-right: 15px;">
                                            <span style="margin-right: 3px;">CDD</span>
                                            <input type="radio" name="radios-politica" class="cursor-pointer-padrao"
                                                [checked]="politica" (change)="handleChangePolitica($event,'CDD')"
                                                [disabled]="checarAcordo">
                                        </div>

                                        <div style="display: flex;flex-direction: row;align-content: center;">
                                            <span style="margin-right: 3px;">Credor</span>
                                            <input type="radio" name="radios-politica" class="cursor-pointer-padrao"
                                                [checked]="!politica" (change)="handleChangePolitica($event,'Credor')"
                                                [disabled]="checarAcordo">
                                        </div>

                                    </div>
                                </div>


                            </div>


                            <div class="form-row col-md-12">


                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Credor deve aprovar sempre ? </strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-2 pl-0 pt-1 pb-1"
                                        style="display:flex;flex-direction: row;">
                                        <div
                                            style="display: flex;flex-direction: row;align-content: center;margin-right: 15px;">
                                            <span style="margin-right: 3px;">Sim</span>
                                            <input type="radio" name="radios-checar-acordo"
                                                class="cursor-pointer-padrao" [checked]="checarAcordo"
                                                (change)="handleChangeChecarParametrosAoGerarAcordo($event,'Sim')">
                                        </div>

                                        <div style="display: flex;flex-direction: row;align-content: center;">
                                            <span style="margin-right: 3px;">Não</span>
                                            <input type="radio" name="radios-checar-acordo"
                                                class="cursor-pointer-padrao" [checked]="!checarAcordo"
                                                (change)="handleChangeChecarParametrosAoGerarAcordo($event,'Não')">
                                        </div>

                                    </div>
                                </div>


                            </div>



                        </div>

                    </ng-template>
                </li>
                <li ngbNavItem *ngIf="pessoa2.tipo == 'Credor' && !isUpdateContato">
                  <a ngbNavLink>Política Acordo Jurídico</a>
                  <ng-template ngbNavContent>

                      <div class="card-body">
                          <div class="form-row col-md-12">


                          <div class="form-group col-md-4 ">
                              <label class="form-label">Qte Parcelamento mínimo</label>
                              <input type="number" class="form-control" placeholder=""
                                  [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPdJ,pessoaMap.credorInfoCad.parcelamentoMinimo,pessoa2).valor"
                                  [disabled]="!permitirParcelamentoJuridico">
                          </div>
                          <div class="form-row col-md-12">

                            <div class="form-group col-md-4 ">
                                <label class="form-label">Limite de Desconto (%) sob valor atualizado</label>
                                <input type="number" class="form-control" placeholder=""
                                    [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPdJ,pessoaMap.credorInfoCad.LimiteDescontoSobValorAtualizado,pessoa2).valor"
                                    [disabled]="checarAcordoJuridico">
                            </div>

                        </div>

                          <div class="form-group col-md-4 ">
                              <label class="form-label">Qte Parcelamento Máximo</label>
                              <input type="number" class="form-control" placeholder=""
                                  [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPdJ,pessoaMap.credorInfoCad.parcelamentoMaximo,pessoa2).valor"
                                  [disabled]="!permitirParcelamentoJuridico">
                          </div>

                          </div>

                          <div class="form-row mb-3">
                            <label class="form-label ">Em caso descumprimento de acordo</label>
                          </div>
                          <div class="form-row col-md-12">
                              <div class="form-group col-md-4 m-2">
                                  <label class="form-label">Perc de Índice de Correção</label>
                                  <input type="number" class="form-control" placeholder=""
                                      [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPdJ,pessoaMap.credorInfoCad.percentualIndiceCorrecao,pessoa2).valor"
                                      [disabled]="checarAcordoJuridico">
                              </div>

                              <div class="form-group col-md-4 ">
                                  <label class="form-label">Perc de Juros</label>
                                  <input type="number" class="form-control" placeholder=""
                                      [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPdJ,pessoaMap.credorInfoCad.percentualJuros,pessoa2).valor"
                                      [disabled]="checarAcordoJuridico">
                              </div>
                              <div class="form-group col-md-4 ">
                                <label class="form-label">Perc de Multa</label>
                                <input type="number" class="form-control" placeholder=""
                                    [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPdJ,pessoaMap.credorInfoCad.percentualMulta,pessoa2).valor"
                                    [disabled]="checarAcordoJuridico">
                            </div>

                          </div>

                          <div class="form-row col-md-12">


                              <div class="col-md-12 display-flex p-2">
                                  <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                      <span><strong>Permitir Parcelamento ?</strong></span>
                                  </div>
                                  <div class="col-md-7 card-body-processos-orfaos pr-2 pl-0 pt-1 pb-1"
                                      style="display:flex;flex-direction: row;">
                                      <div
                                          style="display: flex;flex-direction: row;align-content: center;margin-right: 15px;">
                                          <span style="margin-right: 3px;">Sim</span>
                                          <input type="radio" name="radios-parcelamento" class="cursor-pointer-padrao"
                                              [checked]="permitirParcelamentoJuridico"
                                              (change)="handleChangeParcelamentoJuridico($event,'Sim')"
                                              [disabled]="checarAcordoJuridico">
                                      </div>

                                      <div style="display: flex;flex-direction: row;align-content: center;">
                                          <span style="margin-right: 3px;">Não</span>
                                          <input type="radio" name="radios-parcelamento" class="cursor-pointer-padrao"
                                              [checked]="!permitirParcelamentoJuridico"
                                              (change)="handleChangeParcelamentoJuridico($event,'Não')"
                                              [disabled]="checarAcordoJuridico">
                                      </div>

                                  </div>
                              </div>


                          </div>

                          <div class="form-row col-md-12">


                              <div class="col-md-12 display-flex p-2">
                                  <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                      <span><strong>Politica </strong></span>
                                  </div>
                                  <div class="col-md-7 card-body-processos-orfaos pr-2 pl-0 pt-1 pb-1"
                                      style="display:flex;flex-direction: row;">
                                      <div
                                          style="display: flex;flex-direction: row;align-content: center;margin-right: 15px;">
                                          <span style="margin-right: 3px;">CDD</span>
                                          <input type="radio" name="radios-politica" class="cursor-pointer-padrao"
                                              [checked]="politicaJuridico" (change)="handleChangePoliticaJuridico($event,'CDD')"
                                              [disabled]="checarAcordoJuridico">
                                      </div>

                                      <div style="display: flex;flex-direction: row;align-content: center;">
                                          <span style="margin-right: 3px;">Credor</span>
                                          <input type="radio" name="radios-politica" class="cursor-pointer-padrao"
                                              [checked]="!politicaJuridico" (change)="handleChangePoliticaJuridico($event,'Credor')"
                                              [disabled]="checarAcordoJuridico">
                                      </div>

                                  </div>
                              </div>


                          </div>


                          <div class="form-row col-md-12">


                              <div class="col-md-12 display-flex p-2">
                                  <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                      <span><strong>Credor deve aprovar sempre ? </strong></span>
                                  </div>
                                  <div class="col-md-7 card-body-processos-orfaos pr-2 pl-0 pt-1 pb-1"
                                      style="display:flex;flex-direction: row;">
                                      <div
                                          style="display: flex;flex-direction: row;align-content: center;margin-right: 15px;">
                                          <span style="margin-right: 3px;">Sim</span>
                                          <input type="radio" name="radios-checar-acordo"
                                              class="cursor-pointer-padrao" [checked]="checarAcordoJuridico"
                                              (change)="handleChangeChecarParametrosAoGerarAcordoJuridico($event,'Sim')">
                                      </div>

                                      <div style="display: flex;flex-direction: row;align-content: center;">
                                          <span style="margin-right: 3px;">Não</span>
                                          <input type="radio" name="radios-checar-acordo"
                                              class="cursor-pointer-padrao" [checked]="!checarAcordoJuridico"
                                              (change)="handleChangeChecarParametrosAoGerarAcordoJuridico($event,'Não')">
                                      </div>

                                  </div>
                              </div>


                          </div>



                      </div>

                  </ng-template>
              </li>

                <li ngbNavItem *ngIf="pessoa2.tipo == 'Credor' && !isUpdateContato">
                    <a ngbNavLink>Taxas de acordo</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">

                            <div class="form-row col-md-12">
                                <div class="form-group col-md-4 ">
                                    <label class="form-label">Juros</label>
                                    <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.juros,pessoa2).valor">
                                </div>

                                <div class="form-group col-md-4 ">
                                    <label class="form-label">Multa</label>
                                    <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.multa,pessoa2).valor">
                                </div>

                            </div>

                            <div class="form-row col-md-12">
                                <div class="form-group col-md-4 ">


                                    <div class="form-row col-md-12">
                                        <div class="col-md-7">
                                            <label class="form-label">Taxa administrativa</label>
                                        </div>

                                        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-0 pb-0"
                                            style="display:flex;flex-direction: row;">
                                            <div style="display: flex;flex-direction: row;margin-right: 15px;">
                                                <span style="margin-right: 3px;">Sim</span>
                                                <input type="radio" name="radios-calcular-honorarios"
                                                    class="cursor-pointer-padrao" [checked]="calcularHonorarios"
                                                    (change)="handleChangeCalcularHonorarios($event,'Sim')">
                                            </div>

                                            <div style="display: flex;flex-direction: row;">
                                                <span style="margin-right: 3px;">Não</span>
                                                <input type="radio" name="radios-calcular-honorarios"
                                                    class="cursor-pointer-padrao" [checked]="!calcularHonorarios"
                                                    (change)="handleChangeCalcularHonorarios($event,'Não')">
                                            </div>

                                        </div>
                                    </div>

                                    <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.taxaAdministrativa,pessoa2).valor"
                                        [disabled]="!calcularHonorarios">
                                </div>



                            </div>



                        </div>
                    </ng-template>
                </li>


                <li ngbNavItem *ngIf="pessoa2.tipo == 'Credor' && !isUpdateContato">
                    <a ngbNavLink>Comissões</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <span class="switcher-label">Faixas de valor</span>
                            <label class="switcher switcher-square">
                                <input type="checkbox" class="switcher-input" (change)="switchTipoComissao($event)">
                                <span class="switcher-indicator">
                                    <span class="switcher-yes" value="yes"></span>
                                    <span class="switcher-no" value="no"></span>
                                </span>
                                <span class="switcher-label"> Dias</span>
                            </label>
                        </div>

                        <hr class="m-0">

                        <div class="card-body" *ngIf="tipoComissao== '0'">
                            <label class="form-label">Comissões Privadas</label>
                            <ng2-smart-table [settings]="settingsComissoesPrivadoValores"
                                [source]="comissoesPrivadoValores" (deleteConfirm)="onDeleteConfirmComissao($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>

                        <div class="card-body" *ngIf="tipoComissao== '0'">
                            <label class="form-label">Comissões Públicas</label>
                            <ng2-smart-table [settings]="settingsComissoesPublicoValores"
                                [source]="comissoesPublicoValores" (deleteConfirm)="onDeleteConfirmComissao($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>


                        <div class="card-body" *ngIf="tipoComissao== '1'">
                            <label class="form-label">Comissões Privadas</label>
                            <ng2-smart-table [settings]="settingsComissoesPrivadoDias" [source]="comissoesPrivadoDias"
                                (deleteConfirm)="onDeleteConfirmComissao($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>

                        <div class="card-body" *ngIf="tipoComissao== '1'">
                            <label class="form-label">Comissões Públicas</label>
                            <ng2-smart-table [settings]="settingsComissoesPublicoDias" [source]="comissoesPublicoDias"
                                (deleteConfirm)="onDeleteConfirmComissao($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>

                        <span class="col-md-12"></span>
                        <hr class="m-0">
                        <div class="card-body">
                            Comentários
                            <textarea class="form-control" rows="4"
                                [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.comentario,pessoa2).valor"></textarea>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem *ngIf="pessoa2.tipo == 'Credor' && !isUpdateContato">
                    <a ngbNavLink>Histórico de comissões</a>
                    <ng-template ngbNavContent>

                        <div class="card-body">
                            <span class="switcher-label">Faixas de valor</span>
                            <label class="switcher switcher-square">
                                <input type="checkbox" class="switcher-input" (change)="switchTipoComissao($event)">
                                <span class="switcher-indicator">
                                    <span class="switcher-yes" value="yes"></span>
                                    <span class="switcher-no" value="no"></span>
                                </span>
                                <span class="switcher-label"> Dias</span>
                            </label>
                        </div>

                        <hr class="m-0">

                        <div class="card-body" *ngIf="tipoComissao== '0'">
                            <div class="table-responsive table-modal-tabs-scroll">
                                <table class="table product-discounts-edit">
                                    <thead>
                                        <tr>
                                            <th>Entidade</th>
                                            <th>tipo</th>
                                            <th>Valor Inicial</th>
                                            <th>Valor Final</th>
                                            <th>Percentual</th>
                                            <th>Vigencia Inicial</th>
                                            <th>Vigencia Final</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let comissao of credorComissoesHistoricoValor; let i = index">
                                            <td>
                                                {{comissao.entidade}}
                                            </td>
                                            <td>
                                                {{comissao.tipoComissao}}

                                            </td>
                                            <td>
                                                {{comissao.tipoComissao == "Publica" ? "": comissao.inicial}}

                                            </td>
                                            <td>
                                                {{comissao.tipoComissao == "Publica" ? "": comissao.final}}

                                            </td>
                                            <td>
                                                {{comissao.percentual}}

                                            </td>
                                            <td>
                                                {{comissao.dataVigenciaInicial | date:"dd/MM/yyyy"}}

                                            </td>
                                            <td>
                                                {{comissao.dataVigenciaFinal == "0001-01-01T00:00:00" ?
                                                "Ativa":comissao.dataVigenciaFinal |
                                                date:"dd/MM/yyyy"}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="card-body" *ngIf="tipoComissao== '1'">
                            <div class="table-responsive table-modal-tabs-scroll">
                                <table class="table product-discounts-edit">
                                    <thead>
                                        <tr>
                                            <th>Entidade</th>
                                            <th>tipo</th>
                                            <th>Quantidade de dias Inicial</th>
                                            <th>Quantidade de dias Final</th>
                                            <th>Percentual</th>
                                            <th>Vigencia Inicial</th>
                                            <th>Vigencia Final</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let comissao of credorComissoesHistoricoDias; let i = index">
                                            <td>
                                                {{comissao.entidade}}
                                            </td>
                                            <td>
                                                {{comissao.tipoComissao}}

                                            </td>
                                            <td>
                                                {{comissao.tipoComissao == "Publica" ? "": comissao.inicial}}

                                            </td>
                                            <td>
                                                {{comissao.tipoComissao == "Publica" ? "": comissao.final}}

                                            </td>
                                            <td>
                                                {{comissao.percentual}}

                                            </td>
                                            <td>
                                                {{comissao.dataVigenciaInicial | date:"dd/MM/yyyy"}}

                                            </td>
                                            <td>
                                                {{comissao.dataVigenciaFinal == "0001-01-01T00:00:00" ?
                                                "Ativa":comissao.dataVigenciaFinal |
                                                date:"dd/MM/yyyy"}}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </ng-template>
                </li>





                <li ngbNavItem ngbNavItem [ngbNavItem]="5" *ngIf="pessoa2.tipo == 'Avalista' && !isUpdateContato">
                    <a ngbNavLink>Informações cadastro</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Nacionalidade</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.avalistaInfoCad.avalistaInfoCadPd,pessoaMap.avalistaInfoCad.nacionalidade,pessoa2).valor">
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Naturalidade</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.avalistaInfoCad.avalistaInfoCadPd,pessoaMap.avalistaInfoCad.naturalidade,pessoa2).valor">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6 ">
                                    <label class="form-label">RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.avalistaInfoCad.avalistaInfoCadPd,pessoaMap.avalistaInfoCad.rg,pessoa2).valor">
                                </div>
                                <div class="form-group col-md-2 ">
                                    <label class="form-label">Orgão RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.avalistaInfoCad.avalistaInfoCadPd,pessoaMap.avalistaInfoCad.orgaoRg,pessoa2).valor">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Estado Civil</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.avalistaInfoCad.avalistaInfoCadPd,pessoaMap.avalistaInfoCad.estadoCivil,pessoa2).valor">
                                        <option *ngFor="let estadoCivil of tipoEstadoCivil" [value]="estadoCivil.value">
                                            {{estadoCivil.value}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Regime de bens</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.avalistaInfoCad.avalistaInfoCadPd,pessoaMap.avalistaInfoCad.regimeDeBens,pessoa2).valor">
                                        <option *ngFor="let regimeDeBens of tipoRegimeDeBens"
                                            [value]="regimeDeBens.value">
                                            {{regimeDeBens.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem ngbNavItem [ngbNavItem]="6" *ngIf="pessoa2.tipo == 'Administrador' && !isUpdateContato">
                    <a ngbNavLink>Informações cadastro</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Nacionalidade</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.administradorInfoCad.administradorInfoCadPd,pessoaMap.administradorInfoCad.nacionalidade,pessoa2).valor">
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Naturalidade</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.administradorInfoCad.administradorInfoCadPd,pessoaMap.administradorInfoCad.naturalidade,pessoa2).valor">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6 ">
                                    <label class="form-label">RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.administradorInfoCad.administradorInfoCadPd,pessoaMap.administradorInfoCad.rg,pessoa2).valor">
                                </div>
                                <div class="form-group col-md-2 ">
                                    <label class="form-label">Orgão RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.administradorInfoCad.administradorInfoCadPd,pessoaMap.administradorInfoCad.orgaoRg,pessoa2).valor">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Estado Civil</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.administradorInfoCad.administradorInfoCadPd,pessoaMap.administradorInfoCad.estadoCivil,pessoa2).valor">
                                        <option *ngFor="let estadoCivil of tipoEstadoCivil" [value]="estadoCivil.value">
                                            {{estadoCivil.value}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Regime de bens</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.administradorInfoCad.administradorInfoCadPd,pessoaMap.administradorInfoCad.regimeDeBens,pessoa2).valor">
                                        <option *ngFor="let regimeDeBens of tipoRegimeDeBens"
                                            [value]="regimeDeBens.value">
                                            {{regimeDeBens.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem ngbNavItem [ngbNavItem]="7" *ngIf="pessoa2.tipo == 'Colaborador' && !isUpdateContato">
                    <a ngbNavLink>Informações cadastro</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Nacionalidade</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.colaboradorInfoCad.colaboradorInfoCadPd,pessoaMap.colaboradorInfoCad.nacionalidade,pessoa2).valor">
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Naturalidade</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.colaboradorInfoCad.colaboradorInfoCadPd,pessoaMap.colaboradorInfoCad.naturalidade,pessoa2).valor">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6 ">
                                    <label class="form-label">RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.colaboradorInfoCad.colaboradorInfoCadPd,pessoaMap.colaboradorInfoCad.rg,pessoa2).valor">
                                </div>
                                <div class="form-group col-md-2 ">
                                    <label class="form-label">Orgão RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.colaboradorInfoCad.colaboradorInfoCadPd,pessoaMap.colaboradorInfoCad.orgaoRg,pessoa2).valor">
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem ngbNavItem [ngbNavItem]="4" *ngIf="pessoa2.tipo == 'Cobrador' && !isUpdateContato">
                    <a ngbNavLink>Informações cadastro</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">RG</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.cobradorInfoCad.cobradorInfoCadPd,pessoaMap.cobradorInfoCad.rg,pessoa2).valor">
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Tipo</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.cobradorInfoCad.cobradorInfoCadPd,pessoaMap.cobradorInfoCad.tipo,pessoa2).valor">
                                        <option *ngFor="let tipo of tiposCobrador" [value]="tipo.value">
                                            {{tipo.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem *ngIf="pessoa2.tipo == 'Cobrador' && !isUpdateContato">
                    <a ngbNavLink>Comissoes</a>
                    <ng-template ngbNavContent>

                        <div class="card-body">
                            <label class="form-label">Comissões Cobrador</label>
                            <ng2-smart-table [settings]="settingsComissoesCobradorValores"
                                [source]="comissoesCobradorPessoaNG" (deleteConfirm)="onDeleteConfirm($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>


                    </ng-template>
                </li>
                <li ngbNavItem ngbNavItem [ngbNavItem]="3" *ngIf="pessoa2.tipo == 'Devedor' && !isUpdateContato">
                    <a ngbNavLink>Informações cadastro</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col ">
                                    <label class="form-label">Canal de cobrança</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.devedorInfoCad.devedorInfoCadPd,pessoaMap.devedorInfoCad.canalCobranca,pessoa2).valor">
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Contato</label>
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="getValue(pessoaMap.devedorInfoCad.devedorInfoCadPd,pessoaMap.devedorInfoCad.contato,pessoa2).valor">
                                </div>
                                <div class="form-group col ">
                                    <label class="form-label">Entidade</label>
                                    <select class="custom-select"
                                        [(ngModel)]="getValue(pessoaMap.devedorInfoCad.devedorInfoCadPd,pessoaMap.devedorInfoCad.entidade,pessoa2).valor">
                                        <option *ngFor="let entidade of tiposEntidade" [value]="entidade.value">
                                            {{entidade.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem *ngIf="pessoa2.tipo == 'Advogado' && !isUpdateContato">
                    <a ngbNavLink>Superiores</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">



                            <ng2-smart-table [settings]="settingsSuperioresAdvogado" [source]="superioresPessoaNG"
                                (deleteConfirm)="onDeleteConfirm($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>


                        </div>
                    </ng-template>
                </li>



                <li ngbNavItem *ngIf="!isUpdateContato">
                    <a ngbNavLink>Bancos</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <ng2-smart-table [settings]="settingsBancos" [source]="bancosPessoaNG"
                                (deleteConfirm)="onDeleteConfirm($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem *ngIf="!isUpdateContato">
                    <a ngbNavLink>Endereços</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <ng2-smart-table [settings]="settingsEnderecos" [source]="enderecosPessoaNG"
                                (deleteConfirm)="onDeleteConfirm($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem ngbNavItem [ngbNavItem]="9" *ngIf="pessoa2.tipo != 'Devedor'">
                    <a ngbNavLink>Telefones</a>
                    <ng-template ngbNavContent>
                        <ng2-smart-table [settings]="settingsTelefones" [source]="telefonesPessoaNG"
                            (deleteConfirm)="onDeleteConfirm($event)"
                            class="table-responsive background-color-white table-modal-tabs-scroll">
                        </ng2-smart-table>
                    </ng-template>
                </li>
                <li ngbNavItem ngbNavItem [ngbNavItem]="9" *ngIf="pessoa2.tipo == 'Devedor'">
                    <a ngbNavLink>Telefones</a>
                    <ng-template ngbNavContent>
                        <ng2-smart-table [settings]="settingsTelefonesDevedor" [source]="telefonesDevedorPessoaNG"
                            (deleteConfirm)="onDeleteConfirm($event)"
                            class="table-responsive background-color-white table-modal-tabs-scroll">
                        </ng2-smart-table>
                    </ng-template>
                </li>
                <li ngbNavItem *ngIf="pessoa2.tipo == 'Devedor' && !isUpdateContato">
                    <a ngbNavLink>Endereço Eletrônico</a>
                    <ng-template ngbNavContent>
                        <ng2-smart-table [settings]="settingsEnderecoEletronico" [source]="enderecosEletronicosPessoaNG"
                            (deleteConfirm)="onDeleteConfirm($event)"
                            class="table-responsive background-color-white table-modal-tabs-scroll">
                        </ng2-smart-table>
                    </ng-template>
                </li>
                <li ngbNavItem *ngIf="!isUpdateContato">
                    <a ngbNavLink>Observação</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            Observação
                            <textarea class="form-control" rows="4"
                                [(ngModel)]="getValue(pessoaMap.observacao.observacaoPd,pessoaMap.observacao.observacao,pessoa2).valor"></textarea>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem *ngIf="!isUpdateContato">
                    <a ngbNavLink>Campos adicionais</a>
                    <ng-template ngbNavContent>
                        <ng2-smart-table [settings]="settingsCamposAdicionais" [source]="camposAdicionaisPessoaNG"
                            class="table-responsive background-color-white table-modal-tabs-scroll">
                        </ng2-smart-table>

                    </ng-template>
                </li>

                <li ngbNavItem *ngIf="pessoa2.tipo == 'Cliente' && !isUpdateContato">
                    <a ngbNavLink>Anexos</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            Observação
                            <textarea class="form-control" rows="4"
                                [(ngModel)]="getValue(pessoaMap.observacao.observacaoPd,pessoaMap.observacao.observacao,pessoa2).valor"></textarea>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem *ngIf="!isUpdateContato">
                    <a ngbNavLink>Arquivos</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">

                            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                (fileOver)="fileOverBase($event)" [uploader]="uploader" class="dropzone"
                                (click)="fileInput.click()">
                                <span class=" span-icon fas fa-cloud-download-alt"></span>
                                <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
                                    carregar</span>
                                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none"
                                    multiple (onFileSelected)="onDrop($event)" />
                            </div>

                            <table class="table" *ngIf="uploader.queue.length > 0">
                                <thead>
                                    <tr>
                                        <th width="45%">Nome</th>
                                        <th width="20%">Tipo</th>
                                        <th>Tamanho</th>
                                        <th>Progresso</th>
                                        <th>Status</th>
                                        <th>Acões</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of uploader.queue">
                                        <td><strong>{{ item?.file?.name }}</strong></td>
                                        <td>
                                            <select class="custom-select width-200"
                                                (change)="onTipoDocumentChange($event.target.selectedIndex, item.file)">
                                                <option *ngFor="let tipoDocumento of tipoDocumentos"
                                                    [ngValue]="item?.file?.documentType">{{
                                                    tipoDocumento.nome }}</option>
                                            </select>
                                        </td>
                                        <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 |
                                            number:'.2' }} MB
                                        </td>
                                        <td *ngIf="uploader.options.isHTML5">
                                            <div class="progress" style="margin-bottom: 0;">
                                                <div class="progress-bar" role="progressbar"
                                                    [ngStyle]="{ 'width': item.progress + '%' }"></div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                                            <span *ngIf="item.isCancel"><i
                                                    class="glyphicon glyphicon-ban-circle"></i></span>
                                            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                                        </td>
                                        <td nowrap>
                                            <button type="button" class="btn btn-warning" (click)="item.cancel()"
                                                [disabled]="!item.isUploading">
                                                <span class="glyphicon glyphicon-ban-circle"></span> Cancelar
                                            </button>
                                            <button type="button" class="btn btn-danger" (click)="item.remove()">
                                                <span class="glyphicon glyphicon-trash"></span> Remover
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <br />
                        <ng2-smart-table *ngIf="this.modalRef.componentInstance.updateData"
                            [settings]="settingsArquivos" [source]="source" (deleteConfirm)="onExcluirArquivo($event)"
                            (custom)="onDownloadFile($event)"
                            class="table-responsive background-color-white table-modal-tabs-scroll">
                        </ng2-smart-table>

                    </ng-template>
                </li>

                <li ngbNavItem>
                    <a ngbNavLink>Faturamento Jurídico</a>
                    <ng-template ngbNavContent>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-row mt-2 mb-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox"
                                                id="enviarDadosDepositoSeparado"
                                                [(ngModel)]="pessoa2.enviarDadosDepositoSeparado" value="something"
                                                checked>
                                            <label class="form-check-label mt-1"
                                                for="enviarDadosDepositoSeparado">Enviar dados para depósito separado da
                                                CDD</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 mb-2">
                                            <ul ngbNav #faturamentoTabs="ngbNav" class="nav-tabs">
                                                <li ngbNavItem>
                                                    <a ngbNavLink>Initial Fee</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="col-12">
                                                            <div class="form-row mt-2 mb-2">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        id="faturarNoAmigavelEJuridicoInitialFee"
                                                                        [(ngModel)]="pessoa2.faturarNoAmigavelEJuridicoInitialFee" value="something"
                                                                        checked>
                                                                    <label class="form-check-label mt-1"
                                                                        for="faturarNoAmigavelEJuridicoInitialFee">Agrupar faturamento amigável e jurídico initial fee</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <ul ngbNav #faturamentoInitialFee="ngbNav" class="nav-tabs">
                                                                        <li ngbNavItem>
                                                                            <a ngbNavLink>Comissões Privadas</a>
                                                                            <ng-template ngbNavContent>
                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                        <div class="row">
                                                                                            <div class="form-group col-3 ">
                                                                                                <label class="form-label">Quantidade de salários mínimos</label>
                                                                                                <input type="number" class="form-control" placeholder=""
                                                                                                    [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.initialFeeSalariosPrivado,pessoa2).valor"
                                                                                                    (blur)="calcularSalario()"
                                                                                                    >
                                                                                            </div>
                                                                                            <div class="col-9 salario-display">
                                                                                                {{valorSalario}}
                                                                                            </div>
                                                                                            <div class="col-12">
                                                                                                <hr />
                                                                                            </div>
                                                                                            <div class="col-12">
                                                                                                <ng2-smart-table
                                                                                                    [settings]="settingsHonorariosJDValores"
                                                                                                    [source]="honorariosJDPrivadoValoresInitialFee"
                                                                                                    (deleteConfirm)="onDeleteConfirmHonorariosJD($event)"
                                                                                                    (editConfirm)="onCreateEditHonorarioJD($event)"
                                                                                                    (createConfirm)="onCreateEditHonorarioJD($event)"
                                                                                                    class="table-responsive background-color-white table-modal-tabs-scroll">
                                                                                                </ng2-smart-table>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </li>
                                                                        <li ngbNavItem>
                                                                            <a ngbNavLink>Comissões Públicas</a>
                                                                            <ng-template ngbNavContent>
                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                        <label class="form-label mb-3">Inicial Fee</label>
                                                                                        <div class="row">
                                                                                            <div class="form-group col-3 ">
                                                                                                <label class="form-label">Quantidade de salários mínimos</label>
                                                                                                <input type="number" class="form-control" placeholder=""
                                                                                                    [(ngModel)]="getValue(pessoaMap.credorInfoCad.credorInfoCadPd,pessoaMap.credorInfoCad.initialFeeSalariosPublico,pessoa2).valor"
                                                                                                    (blur)="calcularSalarioPublico()">
                                                                                            </div>
                                                                                            <div class="col-9 salario-display">
                                                                                                {{valorSalarioPublico}}
                                                                                            </div>
                                                                                            <div class="col-12">
                                                                                                <hr />
                                                                                            </div>
                                                                                            <div class="col-12">
                                                                                                <ng2-smart-table
                                                                                                    [settings]="settingsHonorariosJDValores"
                                                                                                    [source]="honorariosJDPublicoValoresInitialFee"
                                                                                                    (deleteConfirm)="onDeleteConfirmHonorariosJD($event)"
                                                                                                    (editConfirm)="onCreateEditHonorarioJD($event)"
                                                                                                    (createConfirm)="onCreateEditHonorarioJD($event)"
                                                                                                    class="table-responsive background-color-white table-modal-tabs-scroll">
                                                                                                </ng2-smart-table>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 border-card" [ngbNavOutlet]="faturamentoInitialFee"></div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </li>
                                                <li ngbNavItem>
                                                    <a ngbNavLink>Success Fee</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="col-12">
                                                            <div class="form-row mt-2 mb-2" *ngIf="pessoa2.tipo == 'Credor'">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        id="faturarNoAmigavelEJuridicoSuccessFee"
                                                                        [(ngModel)]="pessoa2.faturarNoAmigavelEJuridicoSuccessFee" value="something"
                                                                        checked>
                                                                    <label class="form-check-label mt-1"
                                                                        for="faturarNoAmigavelEJuridicoSuccessFee">Agrupar faturamento amigável e jurídico success fee</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <ul ngbNav #faturamentoSuccessFee="ngbNav" class="nav-tabs">
                                                                        <li ngbNavItem>
                                                                            <a ngbNavLink>Comissões Privadas</a>
                                                                            <ng-template ngbNavContent>
                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                        <div class="row">
                                                                                            <div class="col-12">
                                                                                                <ng2-smart-table
                                                                                                    [settings]="settingsHonorariosJDValores"
                                                                                                    [source]="honorariosJDPrivadoValoresSuccessFee"
                                                                                                    (deleteConfirm)="onDeleteConfirmHonorariosJD($event)"
                                                                                                    class="table-responsive background-color-white table-modal-tabs-scroll">
                                                                                                </ng2-smart-table>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </li>
                                                                        <li ngbNavItem>
                                                                            <a ngbNavLink>Comissões Públicas</a>
                                                                            <ng-template ngbNavContent>
                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                        <label class="form-label mb-3">Inicial Fee</label>
                                                                                        <div class="row">
                                                                                            <div class="col-12">
                                                                                                <ng2-smart-table
                                                                                                    [settings]="settingsHonorariosJDValores"
                                                                                                    [source]="honorariosJDPublicoValoresSuccessFee"
                                                                                                    (deleteConfirm)="onDeleteConfirmHonorariosJD($event)"
                                                                                                    class="table-responsive background-color-white table-modal-tabs-scroll">
                                                                                                </ng2-smart-table>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 border-card" [ngbNavOutlet]="faturamentoSuccessFee"></div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </li>
                                                <li ngbNavItem>
                                                    <a ngbNavLink>Time Sheet</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="col-12">
                                                            <div class="form-row mt-2 mb-2">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        id="faturarNoAmigavelEJuridicoTimeSheet"
                                                                        [(ngModel)]="pessoa2.faturarNoAmigavelEJuridicoTimeSheet" value="something"
                                                                        checked>
                                                                    <label class="form-check-label mt-1"
                                                                        for="faturarNoAmigavelEJuridicoTimeSheet">Agrupar faturamento amigável e jurídico timesheet</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-3">
                                                            <label class="form-label">Valor/Hora:</label>
                                                            <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                                class="form-control" [(ngModel)]="pessoa2.valorHora" data-lpignore="true">
                                                        </div>
                                                        <div class="form-group col-3">
                                                            <label class="form-label">Duração Mínima
                                                                <span class="required-field ml-1">*</span></label>
                                                            <input type="text"
                                                                class="form-control" [(ngModel)]="pessoa2.duracaoMinima"
                                                                placeholder="00:00"
                                                                [textMask]="{mask: maskTime, guide: false}" (blur)="calcularHora()">
                                                        </div>
                                                    </ng-template>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 border-card" [ngbNavOutlet]="faturamentoTabs"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="productTabs"></div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button *ngIf="!isDisableSave && ((!isCredor && !isMaster) || isMaster)" type="button" class="btn btn-primary"
            (click)="cadastrar()"><i class="fas fa-save"></i> Salvar</button>
    </div>
</div>
