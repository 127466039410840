import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';

@Component({
    selector: 'app-modal-atualiza-pedidos',
    templateUrl: './modal-atualiza-pedidos.component.html',
    providers: [PedidosService]
})

export class ModalAtualizaPedidosComponent implements OnInit {

    modalRef: NgbModalRef;
    public pedido: any;
    public listaEstados = [];

    constructor(
        private appService: AppService,
        private pedidosService: PedidosService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private dadosCargasService: DadosCargasService
    ) { }

    ngOnInit(): void {
        this.pedido = this.modalRef.componentInstance.pedido;
        this.listaEstados = this.dadosCargasService.mockEstados();
    }

    atualizar() {
        this.update(this.pedido);
    }

    update(pedido) {
        this.spinner.show();
        this.toastrService.clear();

        this.pedidosService.update(pedido)
            .subscribe(res => {
                this.toastrService.success("Pedido atualizado!.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar o Pedido!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}

