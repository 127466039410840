import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss']
})
export class HeaderFilterComponent implements OnInit {

  @Input() data = [];
  @Input() barChartData;
  @Input() barChartLabels;
  @Input() barChartOptions;
  @Input() barChartPlugins;
  @Input() barChartLegend;
  @Input() barChartType;
  @Input() hasChart = true;
  @Input() classe;
  @Input() barWidth = '150px';
  @Input() barHeight = '60px';
  @Input() isAreaTrabalho = false;

  public piscAlert = false;

  constructor() { }

  ngOnInit(): void {
      this.piscAlert = true
    setInterval(() => {
      this.piscAlert = true
    }, 200)
  }

}
