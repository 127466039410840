import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PerfilService } from 'src/app/services/perfil.service';

@Component({
    selector: 'app-modal-cadastro-perfil',
    templateUrl: './modal-cadastro-perfil.component.html',
    providers: [PerfilService]
})
export class ModalCadastroPerfilComponent implements OnInit {

    modulos: any[] = [];
    modalRef: NgbModalRef;
    isIE10 = false;

    constructor(
        private appService: AppService,
        private perfilService: PerfilService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService
    ) {

        this.isIE10 = this.appService.isIE10;
    }

    ngOnInit(): void {
        this.modulos = this.modalRef.componentInstance.modulos;
    }

    public perfil = {
        nome: '',
        modulos: []
    };

    updateItem(item) {
        var newItem = Object.assign({}, item);
        var existInModulos = this.perfil.modulos.filter(f => f == newItem.id);
        if (existInModulos.length == 0) {
            this.perfil.modulos.push(newItem.id);
        }
        else {
            this.perfil.modulos = this.perfil.modulos.filter(f => f != newItem.id);
        }
    }

    cadastrar() {
        this.spinner.show();
        this.toastrService.clear();

        this.perfilService.add(this.perfil)
            .subscribe(res => {
                this.toastrService.success("Perfil cadastrado.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o perfil!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}

