import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-ocorrencia-atvd-detalhe',
  templateUrl: './card-ocorrencia-atvd-detalhe.component.html',
  styleUrls: ['./card-ocorrencia-atvd-detalhe.component.scss']
})
export class CardOcorrenciaAtvdDetalheComponent implements OnInit {

  @Input() atividade;
  @Input() recursoDesdobramento;
  @Input() dataField = 'dataHistorico';
  @Input() isAbaAtividade = false;
  
  @Output() openModalComments = new EventEmitter();
  @Output() editarAtividade = new EventEmitter();
  @Output() excluirAtividade = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onOpenModalComments(recursoDesdobramento, atividade) {
    this.openModalComments.emit({recursoDesdobramento, atividade});
  }

  onExcluirAtividade(recursoDesdobramento, atividade) {
    this.excluirAtividade.emit({recursoDesdobramento, atividade});
  }

  onEditarAtividade(recursoDesdobramento, atividade) {
    this.editarAtividade.emit({recursoDesdobramento, atividade});
  }

}
