import { Component, EventEmitter, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbDateParserFormatter, NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { FileItem, FileLikeObject, FileUploader, FileUploadModule } from 'ng2-file-upload';
import { NgbDateCustomParserFormatter } from '../../../../utils/dateformat';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { DocumentoService } from 'src/app/services/documento.service';
import { TagService } from 'src/app/services/tag.service';
import { SituacaoService } from 'src/app/services/situacao.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { OcorrenciaRequest } from 'src/app/models/operacionais/ocorrencia/ocorrencia-request';
import { SelectListService } from 'src/app/services/shared/select-list.service';
import { SelectList } from 'src/app/models/select-list';
import { TipoTag } from 'src/app/enums/tag/tipo-tag.enum';
import { SelectListMultiSelect } from 'src/app/models/select-list-multi-select';
import { TipoSituacao } from 'src/app/enums/situacao/tipo-situacao.enum';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { OcorrenciaTag } from 'src/app/models/operacionais/ocorrencia/ocorrencia-tag';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { OcorrenciaResponse } from 'src/app/models/operacionais/ocorrencia/ocorrencia-response';
import { Tipo } from 'src/app/enums/documento/tipo.enum';
import { UtilsService } from 'src/app/utils/utils.service';
import { TipoStatusLabel } from 'src/app/enums/tipo-status.enum';
import { LocalDataSource } from 'ng2-smart-table';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/auth/auth.service';
import { Arquivo } from 'src/app/models/shared/arquivo';
import { Modulo } from 'src/app/enums/modulo.enum';

@Component({
    selector: 'app-modal-adicionar-nova-ocorrencia',
    templateUrl: './modal-adicionar-nova-ocorrencia.component.html',
    styleUrls: [
        '../../../../../vendor/styles/pages/dropzone.scss',
        '../../../../../vendor/styles/pages/gestao-processos.scss'
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]

})

export class ModalAdicionarOcorrenciaComponent implements OnInit {

    public isRTL: boolean;

    modalRef: NgbModalRef;
    isIE10 = false;

    public tipoDocumentos: SelectList[] = [];


    public situacoesPaiList: SelectList[] = [];
    public situacoesList: SelectList[] = [];

    public situacaoPaiSelected:SelectList = new SelectList("","");;
    public situacaoSelected:SelectList = new SelectList("","")

    public situacaoProcesso:any;

    public dropdownSettings = {
        singleSelection: false,
        text: "Selecione tags",
        selectAllText: 'Selecione todas',
        unSelectAllText: 'Remova todas',
        enableSearchFilter: true,
        classes: "multi-select-myclass"
    };

    public processo: ProcessoOperacao;
    public ocorrencia: OcorrenciaRequest;

    public novosAnexos: any[] = [];;
    public files: File[] = [];
    public isHovering: boolean;
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    public hasAnotherDropZoneOver: boolean;
    public response: string;
    public tiposOcorrencias: any;

    public tags: SelectListMultiSelect[] = [];
    public tagsSelecionadas: SelectListMultiSelect[] = [];

    title:any;
    isCredor: Boolean;
    isMovido: Boolean;


    source: LocalDataSource;
    public settingsArquivos = {
      actions: {
          add: false,
          edit: false,
          delete:true,
          columnTitle: 'Ações',
          position: 'right',
          custom:[
            {
              name: 'Download file',
              title: '<i class="btn btn-xs icon-btn btn-primary"><span class="fas fa-download"></span></i>'
            },
          ]
      },
      noDataMessage: 'Não possui dado',

      delete: {
          confirmDelete: true,
          deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
      },
      pager: {
          display: false
      },
      columns: {
          nome: {
              title: 'Nome do Arquivo',
              filter: false,
              width: '65%'
          },
          tipoDocumento: {
              title: 'Tipo do Documento',
              filter: false,
              width: '35%',
              valuePrepareFunction: (cell, row) => {
                  return cell.nome;
              }
          }
      },
      attr: {
          class: 'table table-striped table-bordered'
      }
    };
    public loadingArquivos:Boolean = false;


    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public documentoService: DocumentoService,
        public tagService: TagService,
        public situacaoService: SituacaoService,
        public ocorrenciaService: OcorrenciaService,
        public selectListService: SelectListService,
        private spinner: NgxSpinnerService,
        public utilsService: UtilsService,
        private authService: AuthService,
    ) {
        this.isRTL = appService.isRTL;

        this.uploader = new FileUploader({
            url: ocorrenciaService.postFileUrl,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise<void>((resolve, reject) => {
                    resolve();
                });
            }
        });

        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => {
            this.response = res
        });
        this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
            console.log(fileItem);
            const event = fileItem._file;
            const fileInQueue = this.uploader.queue.find(q => q.file.name === event.name);
            const newFile = new CustomFileLike(fileInQueue.file);

            newFile.documentType = this.tipoDocumentos[0].key.toString();
            newFile.file = event;

            fileInQueue.file = newFile;
        };
        this.uploader.onWhenAddingFileFailed = () => {
            console.log("onWhenAddingFileFailed : " + this.uploader);
        };

        this.isIE10 = this.appService.isIE10;
    }

    async ngOnInit(): Promise<void> {
        this.processo = this.modalRef.componentInstance.processo;
        this.ocorrencia = new OcorrenciaRequest();
        this.novosAnexos = [];

        this.isCredor = this.authService.isPermissionAvailable(Modulo.OPERACIONAIS_PROCESSOS_CREDOR);
        // this.situacaoService.getAllPaiByTipo(TipoSituacao.Ocorrencia).subscribe(res => {
        //     this.situacoesPaiList = res.data.map(e => { return new SelectList(e.id.toString(), `${e.idExterno} - ${e.nome}`) });
        // });

        await this.situacaoService.getByIdSync(this.processo.situacaoProcesso.id).then(x => {
            var resp = x["data"];
            this.situacaoProcesso = resp;
        });

        await this.situacaoService.getAllPaiByTipoSync(TipoSituacao.Ocorrencia).then(x => {
            var resp = x["data"];
            this.situacoesPaiList = resp.map(e => { return new SelectList(e.id.toString(), `${e.idExterno} - ${e.nome}`) });
        })

        this.tagService.getAllByTipo(TipoTag.Ocorrencia).subscribe(res => {
            this.tags = this.selectListService.getTagToMultiSelect(res.data);
        });

        this.documentoService.getAllByTipo(Tipo.Ocorrencia).subscribe(res => {
            this.tipoDocumentos = res.data.map(e => { return new SelectList(e.id.toString(), e.nome) });
        });

        if (this.modalRef.componentInstance.updateData) {



            var ocorrenciaSelecionada = this.modalRef.componentInstance.updateData;
            var ocorrenciaId = this.modalRef.componentInstance.updateData.id;

            this.ocorrenciaService.getById(ocorrenciaId).subscribe(res => {
                var result = res.data;

                this.ocorrencia.id = result.id;
                this.ocorrencia.descricao = result.descricao;
                //this.ocorrencia.emails
                this.ocorrencia.isExibirPortal = result.isExibirPortal;
                this.ocorrencia.isNotificarCredor = result.isNotificarCredor;
                this.ocorrencia.ocorrenciaTags = result.ocorrenciaTags;
                this.ocorrencia.processoId = result.processoId;
                this.ocorrencia.situacaoId = result.situacaoId;
                this.ocorrencia.situacaoPaiId = result.situacaoPaiId;
                this.ocorrencia.status = result.status;

                //this.options.readonly = this.isAtividadeReaonly();
                this.title = `Atualizar ocorrência - ${TipoStatusLabel.get(this.ocorrencia.status)} - Processo #${this.processo.idExterno}`;
                this.tagsSelecionadas = ocorrenciaSelecionada.ocorrenciaTags.map(res => { return new SelectListMultiSelect(res.tagId, res.nome); });

                this.getSituacaoSalvaOcorrencia();

                this.getArquivosOcorrencia();
            });
        }

        console.log(this.processo);

        this.isMovido = this.processo.situacaoProcesso != undefined && this.processo.situacaoProcesso.idExterno == 122;        

        if (this.isCredor){

            this.ocorrencia.isExibirPortal = true;
            this.title = `Nova ocorrência - Processo #${this.processo.idExterno}`;
            this.getSituacaoSalvaProcesso();
        }
        else {
            this.title = `Nova ocorrência - Processo #${this.processo.idExterno}`;
            this.getSituacaoSalvaProcesso();
        }


        /*
        this.modalService.resultConfirmDialog$.asObservable().subscribe(res => {
            if (res != undefined) {
                if (res.result === true && res.page === 'emailCredor') {
                    this.ocorrencia.emails = res.lista;
                    this.updateOrSave();
                }
            }
        });
        */
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(fileList) {
        for (var i = 0; i < fileList.length; i++) {
            const event = fileList[i];
            const fileInQueue = this.uploader.queue.find(q => q.file.name === event.name);
            const newFile = new CustomFileLike(fileInQueue.file);

            newFile.documentType = this.tipoDocumentos[0].key.toString();
            newFile.file = event;

            fileInQueue.file = newFile;
        }
    }

    onTipoDocumentChange(index, customFile) {
        customFile.documentType = this.tipoDocumentos[index].key;
    }

    excluirNovoAnexo(novoAnexo) {
        this.novosAnexos = this.novosAnexos.filter(c => c.name != novoAnexo.name);
    }

    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalContatoProcesso(processo, { previousTab: "tabOcorrencias" });
    }


    salvarOcorrencia() {

        if (this.ocorrencia.isNotificarCredor) {

            let IdEmail = this.processo.credorId;

            if (this.isCredor){
            IdEmail = this.processo.cobradorId;

            }

            var resultado = this.modalService.abrirModalEmailCredor(IdEmail);
            resultado.asObservable().subscribe(res => {
                if (res != undefined) {
                    if (res.result === true && res.page === 'emailCredor') {
                        this.ocorrencia.emails = res.lista;
                        this.updateOrSave();
                    }
                }
            });
        } else {
            this.updateOrSave();
        }
    }

    updateOrSave()
    {
      if (this.modalRef.componentInstance.updateData) {
        this.update();
      } else {
          this.add();
      }
    }

    preSave() {
        this.spinner.show();

        this.ocorrencia.processoId = this.processo.id;
        this.ocorrencia.ocorrenciaTags = this.tagsSelecionadas.map(x => {
            return new OcorrenciaTag(Guid.parse(x.id.toString()).toString());
        });
    }



    postSaveError(error) {
        this.spinner.hide();

        if (error && error.errors) {
            this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
        }
        else {
            this.toastrService.error('Não foi possível criar a ocorrência!', 'Atenção', { timeOut: 10000 });
        }
    }

    update() {

      this.preSave();

      var formData = new FormData();
        formData.append("id",this.ocorrencia.id.toString());
        formData.append("descricao",this.ocorrencia.descricao.toString());
        if(this.ocorrencia.isExibirPortal != undefined){
          formData.append("isExibirPortal",this.ocorrencia.isExibirPortal.toString());
        }
        if(this.ocorrencia.isNotificarCredor != undefined){
          formData.append("isNotificarCredor",this.ocorrencia.isNotificarCredor.toString());
        }
        formData.append("processoId",this.processo.id.toString());
        formData.append("situacaoId",Guid.parse(this.situacaoSelected.key.toString()).toString());
        formData.append("situacaoPaiId",Guid.parse(this.situacaoPaiSelected.key.toString()).toString());
        if(this.ocorrencia.status != undefined){
          formData.append("status",this.ocorrencia.status.toString());
        }
        this.ocorrencia.ocorrenciaTags.forEach((tag, index) => {
          formData.append(`ocorrenciaTags[${index}].tagId`,tag.tagId.toString());
        })

        if(this.ocorrencia.emails != undefined){
            this.ocorrencia.emails.forEach(email => {
                formData.append("emails[]",email.toString());
            })
        }

        if (this.uploader != null && this.uploader.queue.length > 0)
        {
          this.uploader.queue.forEach((item,index) => {

            const file = item.file as CustomFileLike;
            formData.append(`ocorrenciaArquivos[${index}].ocorrenciaId`, this.ocorrencia.id.toString());
            formData.append(`ocorrenciaArquivos[${index}].pasta`, 'ocorrencias');
            formData.append(`ocorrenciaArquivos[${index}].tipoDocumentoId`, file.documentType);
            formData.append(`ocorrenciaArquivos[${index}].nome`, file.name);
            formData.append(`ocorrenciaArquivos[${index}].contentType`, file.type);
            if( file.file != undefined ){
              formData.append(`ocorrenciaArquivos[${index}].file`, file.file as File);
            }else{
              formData.append(`ocorrenciaArquivos[${index}].file`, file.rawFile);
            }

          });
        }



        this.ocorrenciaService.update(formData)
            .subscribe((res: OcorrenciaResponse) => {
                this.ocorrencia = null;
                this.spinner.hide();
                this.toastrService.success("Ocorrência atualizada.", "Sucesso");
                this.close(this.processo);
            }, error => {
                this.postSaveError(error);
            });
    }

    add() {

        this.preSave();

        var formData = new FormData();
        //formData.append("id",this.ocorrencia.id.toString());
        formData.append("descricao",this.ocorrencia.descricao.toString());
        if(this.ocorrencia.isExibirPortal != undefined){
          formData.append("isExibirPortal",this.ocorrencia.isExibirPortal.toString());
        }
        if(this.ocorrencia.isNotificarCredor != undefined){
          formData.append("isNotificarCredor",this.ocorrencia.isNotificarCredor.toString());
        }
        formData.append("processoId",this.processo.id.toString());
        formData.append("situacaoId",Guid.parse(this.situacaoSelected.key.toString()).toString());
        formData.append("situacaoPaiId",Guid.parse(this.situacaoPaiSelected.key.toString()).toString());
        if(this.ocorrencia.status != undefined){
          formData.append("status",this.ocorrencia.status.toString());
        }
        this.ocorrencia.ocorrenciaTags.forEach((tag, index) => {
          formData.append(`ocorrenciaTags[${index}].tagId`,tag.tagId.toString());
        })
        if(this.ocorrencia.emails != undefined){
            this.ocorrencia.emails.forEach(email => {
                formData.append("emails[]",email.toString());
            })
        }

        if (this.uploader != null && this.uploader.queue.length > 0)
        {
          this.uploader.queue.forEach((item,index) => {

            const file = item.file as CustomFileLike;

            if (file.type === '' && file.name.includes('.msg')) {
              file.type = 'application/vnd.ms-outlook';
            }

            //formData.append(`ocorrenciaArquivos[${index}].ocorrenciaId`, this.ocorrencia.id.toString());
            formData.append(`ocorrenciaArquivos[${index}].pasta`, 'ocorrencias');
            formData.append(`ocorrenciaArquivos[${index}].tipoDocumentoId`, file.documentType);
            formData.append(`ocorrenciaArquivos[${index}].nome`, file.name);
            formData.append(`ocorrenciaArquivos[${index}].contentType`, file.type);
            if( file.file != undefined ){
              formData.append(`ocorrenciaArquivos[${index}].file`, file.file as File);
            }else{
              formData.append(`ocorrenciaArquivos[${index}].file`, file.rawFile);
            }

          });
        }




        this.ocorrenciaService.add(formData)
            .subscribe((res: OcorrenciaResponse) => {
                this.ocorrencia = null;
                this.spinner.hide();
                this.toastrService.success("Ocorrência adicionada.", "Sucesso");
                this.close(this.processo);
            }, error => {
                this.postSaveError(error);
            });
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    onChangeSituacaoPai(item)
    {
        this.situacaoPaiSelected = this.situacoesPaiList[this.getIndexSituacao(item.key,this.situacoesPaiList)]

        this.situacaoService.getAllByPai(Guid.parse(this.situacaoPaiSelected.key.toString())).subscribe(res => {
            this.situacoesList = res.data.map(e => { return new SelectList(e.id.toString(), `${e.idExterno} - ${e.nome}`) });
            //this.ocorrencia.situacaoPaiId = Guid.parse(this.situacaoPaiSelected.key.toString());
            this.situacaoSelected = this.situacoesList[0];
            //this.ocorrencia.situacaoId = Guid.parse(this.situacoesList[0].key.toString());
        });
    }

    onChangeChildSituacao(item)
    {
        this.situacaoSelected = item;
    }

    getArquivosOcorrencia() {
        this.loadingArquivos = true;
        this.ocorrenciaService.getAllArquivos(this.ocorrencia.id)
            .subscribe(result => {
                this.source = new LocalDataSource(result.data);
                this.loadingArquivos = false;
            }, error => {
                if (error && error.errors) {
                    error.errors.forEach(element => {
                        this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                    });
                } else {
                    this.toastrService.error('Não foi possível obter os arquivos da pessoa!', 'Atenção', { timeOut: 10000 });
                }
                this.loadingArquivos = false;
            });
    }


    onExcluirArquivo($event) {
      if (window.confirm('Confirma a exclusão do arquivo?')) {
          const ocorrenciaId = this.ocorrencia.id;
          const arquivoId = $event.data.id;

          this.ocorrenciaService.deleteArquivo(ocorrenciaId, arquivoId).subscribe(result => {
              if (result.success) {
                  this.toastrService.success(`Arquivo excluído.`, 'Sucesso');
                  $event.confirm.resolve();
              }
          }, error => {
              if (error && error.errors) {
                  error.errors.forEach(element => {
                      this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                  });
              } else {
                  this.toastrService.error('Não foi possível excluir o arquivo!', 'Atenção', { timeOut: 10000 });
              }
              this.spinner.hide();
          });
      } else {
          $event.confirm.reject();
      }
    }

    onDownloadFile(event)
    {
        var fileId = event.data.id;

        var arquivo = new Arquivo()
        arquivo.id = fileId;

        this.ocorrenciaService.downloadArquivo(arquivo).subscribe(
          data => {
              this.utilsService.renderDownload(data, event.data.nome as string);
          }
      );
    }


    getIndexSituacao(situacaoId,listSituacoes:SelectList[])
    {
        var index = 0;
        listSituacoes.forEach((element, i) => {
            if(situacaoId == element.key)
            {
              index = i
            }
        });
        return index;
    }


    getSituacaoSalvaOcorrencia()
    {
      var indexPai = this.getIndexSituacao(this.ocorrencia.situacaoPaiId.toString(),this.situacoesPaiList);

      this.situacaoPaiSelected = this.situacoesPaiList[indexPai];


      this.situacaoService.getAllByPai(Guid.parse(this.situacaoPaiSelected.key.toString())).subscribe(res => {
        this.situacoesList = res.data.map(e => { return new SelectList(e.id.toString(), `${e.idExterno} - ${e.nome}`) });
        var index = this.getIndexSituacao(this.ocorrencia.situacaoId.toString(),this.situacoesList)
        this.situacaoSelected = this.situacoesList[index];
      });

    }

    getSituacaoSalvaProcesso()
    {
      var indexPai = this.getIndexSituacao(this.situacaoProcesso.situacaoPaiId.toString(),this.situacoesPaiList);

      this.situacaoPaiSelected = this.situacoesPaiList[indexPai];


      this.situacaoService.getAllByPai(Guid.parse((this.situacaoProcesso.situacaoPaiId.toString()))).subscribe(res => {
        this.situacoesList = res.data.map(e => { return new SelectList(e.id.toString(), `${e.idExterno} - ${e.nome}`) });
        var index = this.getIndexSituacao(this.situacaoProcesso.id.toString(),this.situacoesList);
        this.situacaoSelected = this.situacoesList[index];
      });
    }


    checkChangeSituacaoAndSave()
    {
        if(this.situacaoProcesso.id != this.situacaoSelected.key){
          this.AlertMudancaDeSituacaoProcesso();
        }else{
          this.salvarOcorrencia();
        }

    }

    AlertMudancaDeSituacaoProcesso() {

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false,

    });

    swalWithBootstrapButtons.fire({
        title: 'Mudança de Situação',
        text:`Você está prestes a alterar a situação do processo para (${this.situacaoPaiSelected.value + ' / ' + this.situacaoSelected.value}),
        deseja prosseguir ?`,
        confirmButtonColor: '#FFD950 !important;',
        showCloseButton: true,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok'

    }).then((result) => {

      if(result.value === true){
          this.salvarOcorrencia();
      }else{

      }

    });

    }




}
