import { Component, OnInit } from '@angular/core';
import {  NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-modal-tarefa-revisor',
    templateUrl: './modal-tarefa-revisor.component.html',
    styleUrls: ['./modal-tarefa-revisor.component.scss'],
})
export class ModalTarefaRevisorComponent implements OnInit {

    private modalRef: NgbModalRef;
    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });
    tarefa : any;

    constructor(
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
      this.tarefa = this.modalRef.componentInstance.tarefa;
    }


    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
