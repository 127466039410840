import { TipoTagLabel } from '../enums/tag/tipo-tag.enum';
import { TagResponse } from '../models/cadastros/tag/tag-response';
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SelectList } from '../models/select-list';
import { TipoTag } from '../enums/tag/tipo-tag.enum';
import { environment } from 'src/environments/environment';
import { Cor, CorLabel } from '../enums/tag/cor.enum';

@Injectable({
    providedIn: 'root'
})
export class TagService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'Tag/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<TagResponse> {
        return this.http.get<TagResponse>(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllAsync() {
        return this.http.get<TagResponse>(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

    getAllByTipo(tipoTag: TipoTag): Observable<TagResponse> {
        return this.http.get<TagResponse>(environment.api + this.endpoint + "tipo/" + tipoTag)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    GetById(idTag): Observable<TagResponse> {
        return this.http.get<TagResponse>(environment.api + this.endpoint + idTag)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getCores() {
        var ListTipoTag: SelectList[] = [];

        ListTipoTag.push(new SelectList(Cor.badgeDefault.toString(), CorLabel.get(Cor.badgeDefault)));
        ListTipoTag.push(new SelectList(Cor.badgeWarning.toString(), CorLabel.get(Cor.badgeWarning)));
        ListTipoTag.push(new SelectList(Cor.badgeDanger.toString(), CorLabel.get(Cor.badgeDanger)));
        ListTipoTag.push(new SelectList(Cor.badgeDark.toString(), CorLabel.get(Cor.badgeDark)));
        ListTipoTag.push(new SelectList(Cor.badgeSuccess.toString(), CorLabel.get(Cor.badgeSuccess)));
        ListTipoTag.push(new SelectList(Cor.badgePrimary.toString(), CorLabel.get(Cor.badgePrimary)));

        return ListTipoTag;
    }
}

