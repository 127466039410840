import { ModalService } from './../../../../services/shared/modal-service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { NgbDateCustomParserFormatter } from '../../../../utils/dateformat';
import { ProcessoOperacao } from './../../../../models/operacionais/operacao/processo-operacao';
import { DocumentoService } from './../../../../services/documento.service';
import { TagService } from './../../../../services/tag.service';
import { SituacaoService } from './../../../../services/situacao.service';
import { SelectListService } from './../../../../services/shared/select-list.service';
import { SelectList } from './../../../../models/select-list';
import { CustomFileLike } from './../../../../models/custom-file-like';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Tipo } from './../../../../enums/documento/tipo.enum';
import { ProcessosService } from 'src/app/services/processos.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
    selector: 'app-modal-adicionar-documento',
    templateUrl: './modal-adicionar-documento.component.html',
    styleUrls: [
        '../../../../../vendor/styles/pages/dropzone.scss',
        '../../../../../vendor/styles/pages/gestao-processos.scss'
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})

export class ModalAdicionarDocumentoComponent implements OnInit {

    public isRTL: boolean;

    modalRef: NgbModalRef;
    isIE10 = false;

    public tipoDocumentos: SelectList[] = [];

    public novosAnexos: any[] = [];;
    public processo: ProcessoOperacao;

    public files: File[] = [];
    public isHovering: boolean;
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    public hasAnotherDropZoneOver: boolean;
    public response: string;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public documentoService: DocumentoService,
        public tagService: TagService,
        public situacaoService: SituacaoService,
        public processoService: ProcessosService,
        public selectListService: SelectListService,
        private spinner: NgxSpinnerService,
        public utilsService: UtilsService
    ) {
        this.isRTL = appService.isRTL;

        this.uploader = new FileUploader({
            url: processoService.postFileUrl,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise<void>((resolve, reject) => {
                    resolve();
                });
            }
        });

        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);

        this.isIE10 = this.appService.isIE10;
    }

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processo;
        this.novosAnexos = [];
        
        this.documentoService.getAllByTipo(Tipo.Processo).subscribe(res => {
            this.tipoDocumentos = res.data.map(e => { return new SelectList(e.id.toString(), e.nome) });
        });
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
    
    onDrop(fileList) {
        for (var i = 0; i < fileList.length; i++) {
            const event = fileList[i];
            const fileInQueue = this.uploader.queue.find(q => q.file.name === event.name);
            const newFile = new CustomFileLike(fileInQueue.file);

            newFile.description = "";
            newFile.documentType = this.tipoDocumentos[0].key.toString();
            newFile.file = event;

            fileInQueue.file = newFile;
        }
    }

    onTipoDocumentChange(index, customFile) {
        customFile.documentType = this.tipoDocumentos[index].key;
    }

    excluirNovoAnexo(novoAnexo) {
        this.novosAnexos = this.novosAnexos.filter(c => c.name != novoAnexo.name);
    }

    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalContatoProcesso(processo, { previousTab: "tabDocumentos" });
    }

    salvarDocumento() {
        if (this.uploader.queue.length > 0) {
            this.spinner.show();

            this.enviarArquivos().subscribe(result => {
            }, error => {
                this.spinner.hide();
                this.toastrService.error(error, 'Atenção');
            },() => { //complete
                this.spinner.hide();
                
                this.toastrService.success("Ocorrência adicionada.", "Sucesso");
                
                setTimeout(() => {
                    this.close(this.processo);
                }, 300);
            });
        }
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    enviarArquivos() : Observable<boolean> {
        const self = this;
        const envioArquivos = new Observable<boolean>((observer) => {
            let canceled = false;

            async function engine() {
                if (self.uploader == null || self.uploader.queue.length == 0) {
                    observer.next(true);
                }
    
                const arquivosUpload = [];
    
                self.uploader.queue.forEach(item => {
                    arquivosUpload.push(self.montarFormDataArquivo(item));
                });
    
                await self.utilsService.asyncForEach(arquivosUpload, async (arquivo, idx) => {
                    await self.processoService.uploadArquivo(arquivo).then(result => {
                        if (result.success) {
                            self.uploader.clearQueue();
    
                            observer.next(true);
                        } else {
                            if (result.data && result.data.errors && result.data.errors.length > 0) {
                                result.data.errors.forEach(element => {
                                    observer.error(element);
                                });
                            } else {
                                observer.error('Houve algum problema ao salvar os documentos');
                            }
                            
                            return;
                        }
    
                        if (arquivosUpload.length == idx + 1) {
                            observer.complete();
                        }
                    }).catch(error => {
                        if (error && error.errors) {
                            error.errors.forEach(element => {
                                observer.error(element);
                            });
                        } else {
                            observer.error('Houve algum problema ao salvar os documentos');
                        }
                        
                        return;
                    });
                });
            }

            engine();
            return () => canceled = true;
        });

        return envioArquivos;
    }

    montarFormDataArquivo(fileItem: FileItem) {
        const item = fileItem.file as FileLikeObject;
        const file = item as CustomFileLike;
        const formData: FormData = new FormData();
        const description = fileItem["description"] ? fileItem["description"] : "";

        formData.append('processoId', this.processo.id.toString());
        formData.append('pasta', 'processos');
        formData.append('tipoDocumentoId', file.documentType);
        formData.append('nome', file.name);
        formData.append('descricao', description);
        formData.append('contentType', file.type);
        formData.append('file', file.file as File);

        return formData;
    }
}
