import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProcessosJudiciaisRecursoDesdobramentoPedidoService } from "src/app/services/processos-judiciais-recurso-desdobramento-pedido.service";

@Component({
    selector: "app-lista-pedidos",
    templateUrl: "./lista-pedidos.component.html",
    styleUrls: ["./lista-pedidos.component.scss"],
})

export class ListaPedidosComponent implements OnInit {
    @Input() recursoDesdobramentoId = null;

    pedidos: Array<any> = [];

    constructor(
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private processosJudiciaisRecursoDesdobramentoPedidoService: ProcessosJudiciaisRecursoDesdobramentoPedidoService,
    ) { }

    async listarPorRecursoDesdobramento() {
        this.spinner.show();
        this.pedidos = (await this.processosJudiciaisRecursoDesdobramentoPedidoService.listarPorRecursoDesdobramento(this.recursoDesdobramentoId).toPromise()).data;
        console.log(this.pedidos);
        this.spinner.hide();
    }

    ngOnInit(): void {
        this.listarPorRecursoDesdobramento();
    }
}