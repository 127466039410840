import { Component, Input, OnInit } from '@angular/core';
import { TipoFaturamentoEnum, TipoFaturamentoLabel } from '../../../../../../enums/tipoFaturamento/tipo-faturamento.enum';
import { PessoaService } from 'src/app/services/pessoa.service';
import { Pessoa } from 'src/app/models/pessoa/pessoa';
import { TipoCredorComissoesEntidade, TipoCredorComissoesEntidadeLabel } from 'src/app/enums/pessoa-enums/credor-comissoes/tipo-credor-comissoes-entidade.enum';
import { CustomInputEditorNg2MoneyComponent } from 'src/app/views/shared/custom-input-editor-ng2-money/custom-input-editor-ng2-money.component';
import { CustomInputEditorNg2PercentageComponent } from 'src/app/views/shared/custom-input-editor-ng2-percentage/custom-input-editor-ng2-percentage.component';
import Swal from 'sweetalert2';
import { elementClosest } from '@fullcalendar/angular';
import { PessoaMapeamentoChaves } from 'src/app/models/pessoa/pessoa-mapeamento-chaves';
import { SalarioService } from 'src/app/services/salario.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-processo-faturamento',
    templateUrl: './processo-faturamento.component.html',
    styleUrls: ['./processo-faturamento.component.scss']
})
export class ProcessoFaturamentoComponent implements OnInit {

    @Input() processo: any;

    public TipoFaturamentoLabel = TipoFaturamentoLabel;

    //public tipoFaturamentoTypes = Object.values(TipoFaturamentoEnum);

    public tiposFaturamento: any = [
        {id:0, nome:'Público'},
        {id:1, nome:'Privado'},
    ];

    public clienteNome: string = "Não definido";

    public pessoa: Pessoa;

    public pessoaMap: PessoaMapeamentoChaves;

    public settingsHonorariosJDValores: any;

    public loadGridInitialFee: boolean = true;

    public valorSalarioCorrente: number = 0;

    constructor(
        private pessoaService: PessoaService,
        private salarioMinimoService: SalarioService,
        public toastrService: ToastrService,
    ) { }

    ngOnInit(): void {
        this.configurarGrid();
        this.obterValorSalario();
    }

    obterValorSalario() {
        this.salarioMinimoService.obterUltimo().subscribe(res => {
            this.valorSalarioCorrente = res.data.valor;            
            //this.tipoFaturamentoObject();
            this.clientePrincipal();
        })
    }

    configurarGrid() {
        this.settingsHonorariosJDValores = {
            actions: {
                columnTitle: "Ações",
                position: "right",
                hideSubHeader: false
            },
            noDataMessage: "Não possui dado",

            add: {
                addButtonContent: '<i class="btn btn-sm btn-padrao"><span class="fas fa-plus"></span></i>',
                createButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
                confirmCreate: true
            },
            edit: {
                editButtonContent: '<i class="btn btn-xs icon-btn btn-warning"><span class="fas fa-edit font-size-12pt"></span></i>',
                saveButtonContent: '<i class="btn btn-xs icon-btn btn-padrao"><span class="fas fa-check"></span></i>',
                cancelButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="fas fa-times"></span></i>',
                confirmSave: true
            },
            delete: {
                deleteButtonContent: '<i class="btn btn-xs icon-btn btn-danger"><span class="far fa-trash-alt"></span></i>',
                confirmDelete: true
            },
            pager: {
                display: false
            },

            attr: {
                class: 'table table-striped table-bordered'
            }
        };

        this.settingsHonorariosJDValores.columns = {
            // idExterno: {
            //     title: 'Código', width: '23%', filter: false, readonly: true
            // },
            inicial: {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = this.formatterValue(value);
                    return newValue;
                },
                title: 'Valor Inicial', width: '23%', filter: false
            },
            final: {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = this.formatterValue(value);
                    return newValue;
                },
                title: 'Valor Final', width: '23%', filter: false
            },
            percentual:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2PercentageComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = value + "%";
                    return newValue;
                },
                title: 'Porcentagem', width: '20%', filter: false
            },
            valor:
            {
                editor: {
                    type: 'custom',
                    component: CustomInputEditorNg2MoneyComponent,
                },
                valuePrepareFunction: (value) => {
                    var newValue = "R$ " + value;
                    return newValue;
                },
                title: 'Valor', width: '20%', filter: false
            }
        };
    }

    onCreateEditHonorarioJD(event){        
        if(event){            
            if(+event.newData['valor'] == 0 && +event.newData['percentual']==0){
                this.toastrService.error('Informe o percentual ou o valor!', 'Atenção', { timeOut: 10000 });
                event.confirm.reject();
                return;
            }

            if(+event.newData['valor'] > 0){
                event.newData['percentual']=0;
            }

            event.confirm.resolve(event.newData);
        }
    }

    onDeleteConfirmHonorariosJD(event) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            text: 'Deseja realmente excluir?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const index = event.source.data.indexOf(event.data);
                event.source.data.splice(index, 1);
                event.confirm.resolve();
            } else {
                event.confirm.reject();
            }
        });
    }

    formatterValue(value) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(value);
    }

    clientePrincipal() {
        const cliente = this.processo.clientes.find((c: { principal: boolean; }) => c.principal);

        if (cliente) {
            this.clienteNome = cliente.nome;
            this.carregarPessoa(cliente.pessoaId);
        }
    }

    carregarPessoa(id: any) {
        this.pessoaService.getById(id).subscribe(res => {
            this.pessoa = res.data;
            this.carregarQuantidadeSalarioMinimoInicialFee();
            this.carregarPessoaDados();
        });
    }

    carregarQuantidadeSalarioMinimoInicialFee() {
        if (this.processo.quantidadeSalarioMinimoInicialFee == undefined || this.processo.quantidadeSalarioMinimoInicialFee == null) {
            this.pessoaService.getPessoaMapeamentoChaves().subscribe(x => {
                this.pessoaMap = x.data;
                this.processo.quantidadeSalarioMinimoInicialFee = this.getValue(this.pessoaMap.credorInfoCad.credorInfoCadPd, this.pessoaMap.credorInfoCad.initialFeeSalariosPrivado).valor
                this.calcularSalario();
            });
        } else {
            this.calcularSalario();
        }
    }

    getValue(chavePessoaDados: String, chavePessoaComplementar: String) {
        var pessoaDados = this.pessoa.pessoaDados.find(x => x.nome == chavePessoaDados);

        if (pessoaDados != null) {
            var pessoaDadosComplementares = pessoaDados.pessoaDadosComplementares.find(x => x.nome == chavePessoaComplementar);

            if (pessoaDadosComplementares != null) {
                return pessoaDadosComplementares;
            } else {
                return null;
            }


        } else {
            return null;
        }
    }

    carregarPessoaDados() {
        this.loadGridInitialFee = true;
        if (this.processo.tipoFaturamento != undefined &&
            this.processo.tipoFaturamento != null &&
            (this.processo.honorariosInitialFee == undefined ||
                this.processo.honorariosInitialFee == null ||
                this.processo.honorariosInitialFee.length == 0)) {
            this.pessoaService.getCredorComissoes(this.pessoa.id).subscribe(x => {
                this.processo.honorariosInitialFee = x.data.filter(element => {
                    return (element.tipoComissao == "Initial Fee" &&
                        element.ambiente == "Juridico" &&
                        element.dataVigenciaFinal == "0001-01-01T00:00:00" && //seleciona sem  data fim
                        element.entidade == TipoCredorComissoesEntidadeLabel.get((this.processo.tipoFaturamento == 0 ? TipoCredorComissoesEntidade.Publica : TipoCredorComissoesEntidade.Privada)));
                });

                this.processo.honorariosSuccessFee = x.data.filter(element => {
                    return (element.tipoComissao == "Success Fee" &&
                        element.ambiente == "Juridico" &&
                        element.dataVigenciaFinal == "0001-01-01T00:00:00" && //seleciona sem datafim
                        element.entidade == TipoCredorComissoesEntidadeLabel.get((this.processo.tipoFaturamento == 0 ? TipoCredorComissoesEntidade.Publica : TipoCredorComissoesEntidade.Privada)));
                });

                //seta initial fee 2 em todos os objetos
                this.processo.honorariosInitialFee.forEach(c => {
                    c.tipoHonorario = 2;
                    c.id = null;
                    c.idExterno = null;
                });
                debugger;
                //seta success fee 3 em todos os objetos
                this.processo.honorariosSuccessFee.forEach(c => {
                    c.tipoHonorario = 3;
                    c.id = null;
                    c.idExterno = null;
                });

                this.loadGridInitialFee = false;
            });
        }
    }

    selecionarFaturamento() {
        this.processo.honorariosInitialFee = null;

        this.carregarPessoaDados();
    }

    // tipoFaturamentoObject() {
    //     this.tipoFaturamentoTypes.forEach(element => {
    //         if (this.TipoFaturamentoLabel[element] != undefined) {
    //             debugger;
    //             this.tiposFaturamento.push({
    //                 id: element,
    //                 nome: this.TipoFaturamentoLabel[element]
    //             });
    //         }
    //     });
    // }

    public valorSalario: string;

    calcularSalario() {
        const quantidade: number = +this.processo.quantidadeSalarioMinimoInicialFee;

        this.valorSalario = this.formatterValue(quantidade * this.valorSalarioCorrente);
    }

}
