import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// *******************************************************************************
// Libs
import { FileUploadModule } from 'ng2-file-upload';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ChartsModule, ChartsModule as Ng2ChartsModule } from 'ng2-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { SharedRoutingModule } from './shared-routing.module';
import { CustomInputEditorNg2Component } from './custom-input-editor-ng2/custom-input-editor-ng2/custom-input-editor-ng2.component';
import { ModalCadastroPessoaComponent } from '../administracao/pessoa/modal-cadastro/modal-cadastro-pessoa.component';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomInputEditorNg2MoneyComponent } from './custom-input-editor-ng2-money/custom-input-editor-ng2-money.component';
import { CustomInputEditorNg2PercentageComponent } from './custom-input-editor-ng2-percentage/custom-input-editor-ng2-percentage.component';
import { LayoutDashboardMenuComponent } from 'src/app/layout/layout-dashboard-menu/layout-dashboard-menu.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PessoaDropdownComponent } from '../operacionais/components/pessoa-dropdown/pessoa-dropdown.component';
import { AgGridComponent } from './ag-grid/ag-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { OpcoesProcessoComponent } from './opcoes-processo/opcoes-processo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { ProcessDetailsComponent } from './process-details/process-details.component';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CardProcessComponent } from './card-process/card-process.component';
import { DocumentosResumoComponent } from './documentos-resumo/documentos-resumo.component';
import { ListarDocumentosComponent } from './listar-documentos/listar-documentos.component';
import { IconOrigemComponent } from './listar-documentos/icon-origem/icon-origem.component';
import { TooltipGridComponent } from './tooltip-grid/tooltip-grid.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { OptionsGridDocsComponent } from './listar-documentos/options-grid-docs/options-grid-docs.component';
import { MatChipsModule } from '@angular/material/chips';
import { EquipeDropdownComponent } from '../operacionais/components/equipe-dropdown/equipe-dropdown.component';
import { HeaderFilterComponent } from './header-filter/header-filter.component';
import { ModalAddDocValoresComponent } from '../operacionais/processos-judiciais/processos-judiciais-detalhes/modal-add-doc-valores/modal-add-doc-valores.component';
import { DocumentsComponent } from '../dashboard/documents/documents.component';
import { InstanciaDropdownComponent } from './instancia-dropdown/instancia-dropdown.component';
import { VaraDropdownComponent } from './vara-dropdown/vara-dropdown.component';
import { AcaoDropdownComponent } from './acao-dropdown/acao-dropdown.component';
import { ComarcaDropdownComponent } from './comarca-dropdown/comarca-dropdown.component';
import { CardOcorrenciaAtvdDetalheComponent } from './card-ocorrencia-atvd-detalhe/card-ocorrencia-atvd-detalhe.component';
import { DataExtensoPipe } from 'src/app/pipes/dataextenso-value.pipe';
import { SaveStateFilters } from './services/save-state-filters';
import { AdvancedSearchFilterComponent } from './advanced-search-filter/advanced-search-filter.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LabelSlideToggleComponent } from './label-slide-toggle/label-slide-toggle.component';
import { FiltroProcessosCasosComponent } from './filtro-processos-casos/filtro-processos-casos.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UfDropdownComponent } from './uf-dropdown/uf-dropdown.component';
import { SituacaoCasoProcessoJudicialDropdownComponent } from './situacao-caso-processo-judicial-dropdown/situacao-caso-processo-judicial-dropdown.component';
import { ModalAddEditPedidoComponent } from '../operacionais/processos-judiciais/processos-judiciais-detalhes/modal-add-edit-pedido/modal-add-edit-pedido.component';
import { TipoMateriaPedidoDropdownComponent } from './tipo-materia-pedido-dropdown/tipo-materia-pedido-dropdown.component';
import { ClassificacaoMateriaPedidoDropdownComponent } from './classificacao-materia-pedido-dropdown/classificacao-materia-pedido-dropdown.component'
import { TipoCalculoDropdownComponent } from './tipo-calculo-dropdown/tipo-calculo-dropdown.component';
import { GlobalTableComponent } from './global-table/global-table.component';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { ListaAtividadesMencionadasUsuarioComponent } from './lista-atividades-mencionadas-usuario/lista-atividades-mencionadas-usuario.component';
import { OpcoesReagendamentoComponent } from './opcoes-processo/opcoes-reagendamento/opcoes-reagendamento.component';
import { CalendarioService } from 'src/app/services/calendario.service';
import { TimeLineAtividadeLogComponent } from './time-line-atividade-log/time-line-atividade-log.component';

// *******************************************************************************
@NgModule({
    imports: [
        CommonModule,
        FileUploadModule,
        FormsModule,
        NgbModule,
        Ng2ChartsModule,
        PerfectScrollbarModule,
        NgxDatatableModule,

        Ng2SmartTableModule,
        SweetAlert2Module,
        SharedRoutingModule,
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: false,
            timeOut: 2500,
            tapToDismiss: true,
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-right',
        }),
        TextMaskModule,
        CurrencyMaskModule,
        CurrencyMaskModule,
        NgSelectModule,
        AgGridModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatTabsModule,
        MatCardModule,
        MatExpansionModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        TextFieldModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatChipsModule,
        ReactiveFormsModule,
        FormsModule,
        MatMenuModule,
        MatIconModule,
        MatSlideToggleModule,
        AngularMultiSelectModule,
        ChartsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
        CustomInputEditorNg2Component,
        ModalCadastroPessoaComponent,
        CustomInputEditorNg2MoneyComponent,
        CustomInputEditorNg2PercentageComponent,
        OpcoesProcessoComponent,
        IconOrigemComponent,
        OptionsGridDocsComponent,
        ModalAddDocValoresComponent,
        DocumentsComponent,
        ModalAddEditPedidoComponent
    ],
    declarations: [
        CustomInputEditorNg2Component,
        ModalCadastroPessoaComponent,
        CustomInputEditorNg2MoneyComponent,
        DocumentsComponent,
        CustomInputEditorNg2PercentageComponent,
        PessoaDropdownComponent,
        AgGridComponent,
        OpcoesProcessoComponent,
        ProcessDetailsComponent,
        CardProcessComponent,
        DocumentosResumoComponent,
        ListarDocumentosComponent,
        IconOrigemComponent,
        TooltipGridComponent,
        OptionsGridDocsComponent,
        EquipeDropdownComponent,
        HeaderFilterComponent,
        InstanciaDropdownComponent,
        VaraDropdownComponent,
        AcaoDropdownComponent,
        ComarcaDropdownComponent,
        CardOcorrenciaAtvdDetalheComponent,
        DataExtensoPipe,
        AdvancedSearchFilterComponent,
        LabelSlideToggleComponent,
        FiltroProcessosCasosComponent,
        UfDropdownComponent,
        SituacaoCasoProcessoJudicialDropdownComponent,
        TipoMateriaPedidoDropdownComponent,
        ClassificacaoMateriaPedidoDropdownComponent,
        TipoCalculoDropdownComponent,
        GlobalTableComponent,
        ListaAtividadesMencionadasUsuarioComponent,
        OpcoesReagendamentoComponent,
        TimeLineAtividadeLogComponent,

    ], providers: [
        MatDatepickerModule,
        CurrencyPipe,
        SaveStateFilters,
        ExcelService,
        CalendarioService
    ],
    exports: [
        PessoaDropdownComponent,
        AgGridComponent,
        OpcoesProcessoComponent,
        MatPaginatorModule,
        MatTabsModule,
        ProcessDetailsComponent,
        CardProcessComponent,
        DocumentosResumoComponent,
        ListarDocumentosComponent,
        MatRadioModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatCardModule,
        MatTooltipModule,
        MatDialogModule,
        MatSnackBarModule,
        MatChipsModule,
        MatDatepickerModule,
        EquipeDropdownComponent,
        HeaderFilterComponent,
        DocumentsComponent,
        InstanciaDropdownComponent,
        VaraDropdownComponent,
        AcaoDropdownComponent,
        ComarcaDropdownComponent,
        DataExtensoPipe,
        CardOcorrenciaAtvdDetalheComponent,
        ReactiveFormsModule,
        FormsModule,
        AdvancedSearchFilterComponent,
        MatMenuModule,
        MatIconModule,
        MatSlideToggleModule,
        LabelSlideToggleComponent,
        FiltroProcessosCasosComponent,
        AngularMultiSelectModule,
        MatFormFieldModule,
        ChartsModule,
        UfDropdownComponent,
        SituacaoCasoProcessoJudicialDropdownComponent,
        TipoMateriaPedidoDropdownComponent,
        ClassificacaoMateriaPedidoDropdownComponent,
        TipoCalculoDropdownComponent,
        GlobalTableComponent,
        CurrencyMaskModule,
        ListaAtividadesMencionadasUsuarioComponent,
        TimeLineAtividadeLogComponent
    ]
})
export class SharedModule { }
