import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { InstanciasService } from 'src/app/services/instancias.service';

@Component({
    selector: 'app-modal-atualiza-instancias',
    templateUrl: './modal-atualiza-instancias.component.html',
    providers: [InstanciasService]
})
export class ModalAtualizaInstanciasComponent implements OnInit {
    modalRef: NgbModalRef;
    public instancia: any;
    constructor(
        private appService: AppService,
        private instanciasService: InstanciasService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService
    ) { }
    ngOnInit(): void {
        this.instancia = this.modalRef.componentInstance.instancia;
    }
    atualizar() {
        this.update(this.instancia);
    }
    update(instancia) {
        this.spinner.show();
        this.toastrService.clear();
        this.instanciasService.update(instancia)
            .subscribe(res => {
                this.toastrService.success("Instância atualizada!.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar a instância!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }
    close() {
        this.modalRef.close();
    }
    dismiss() {
        this.modalRef.dismiss();
    }
}