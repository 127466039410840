import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificacoesPushService } from 'src/app/services/notificacoes-push.service';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ModalOcorrenciaComponent } from 'src/app/views/operacionais/processos-judiciais/processos-judiciais-detalhes/modal-ocorrencia/modal-ocorrencia.component';

@Component({
    selector: 'app-notificacao-push',
    templateUrl: './notificacao-push.component.html',
    styleUrls: ['./notificacao-push.component.css']
})
export class NotificacaoPushComponent implements OnInit {

    notificacoes: [];
    @Output() notificacaoEvent = new EventEmitter<boolean>();
    existeNotificacoes: boolean = true;
    textoQuantidadeNotificacoes: string;

    constructor(
        private notificacoesPushService: NotificacoesPushService,
        private spinner: NgxSpinnerService,
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private ngbModal: NgbModal) {
    }

    ngOnInit(): void {
        this.buscarNotitficacoes();
    }

    buscarNotitficacoes() {
        this.notificacoesPushService.buscarNotificacoes()
            .subscribe(notificacao => {
                this.notificacoes = notificacao.data;

                var tamanho = notificacao.data.length;

                if (!notificacao.data || notificacao.data.length == 0) {
                    this.textoQuantidadeNotificacoes = `Sem Notificações`;
                    this.existeNotificacoes = false;
                } else if (tamanho == 1) {
                    this.textoQuantidadeNotificacoes = `${tamanho} nova notificação`;
                } else {
                    this.textoQuantidadeNotificacoes = `${tamanho} novas notificações`;
                }

                this.notificacaoEvent.emit(this.existeNotificacoes);
            })
    };

    abrirAtividade(atividade: any, id: string) {

        this.notificacoesPushService.setNotificationAsRead(id)
            .toPromise();

        this.spinner.show();

        this.processosJudiciaisOcorrenciasService.getById(atividade.processoJudicialOcorrenciaId)
            .subscribe(rs => {
                let ocorrencia = rs.data;

                this.processosJudiciaisOcorrenciasService.obterOcorrenciaComAtividade(ocorrencia.id, atividade.tipoAtividade, atividade.atividadeId)
                    .subscribe(oc => {

                        if (oc.success) {
                            this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(oc.data.processoJudicialRecursoDesdobramentoId)
                                .subscribe(res => {
                                    if (res.success) {

                                        const modalRef = this.ngbModal.open(ModalOcorrenciaComponent, {
                                            size: 'lg',
                                            backdrop: 'static',
                                            keyboard: false
                                        });

                                        let recursoDesdobramento = res.data;

                                        modalRef.componentInstance.modalRef = modalRef;
                                        modalRef.componentInstance.page = 'processo-judiciais-detalhes';
                                        modalRef.componentInstance.recursoDesdobramento = recursoDesdobramento;
                                        modalRef.componentInstance.editarAtividade(ocorrencia.id, oc.data.detalhes[0]);

                                    }
                                })
                        }
                    })
            })
    };
}