import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AtendimentoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'Atendimento/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }    

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterParaEdicao(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint+'obter-para-edicao/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    buscarDetalhesAtendimento(tipo, referenciaId): Observable<any> {
        return this.http.get(environment.api + this.endpoint+'obter-detalhes-registro/'+tipo+'/'+referenciaId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    obterQuantidadeAtendimentosPorRegistro(tipo, referenciaId): Observable<any> {
        return this.http.get(environment.api + this.endpoint+'obter-quantidade-registro/'+tipo+'/'+referenciaId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterAtivos(): Observable<any> {
        return this.http.get(environment.api + this.endpoint+'obter-ativos/')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterAtendimentosPorFiltro(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'obter-atendimentos-por-filtro/', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    modificarStatus(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'modificar-status', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addRegistro(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-registro/', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
