export enum TipoStatus {
    BorderoNovo = 0,
    BorderoSucesso = 1,
    BorderoErro = 2,

    BorderoLinhaSucesso = 3,
    BorderoLinhaErro = 4,

    AtividadeTarefaAberta = 8,
    AtividadeTarefaFinalizada = 9,

    OcorrenciaCriada = 10,
    OcorrenciaFinalizada = 11,
    OcorrenciaEmAndamento = 6,
    OcorrenciaPendenteAprovacao = 12,
    OcorrenciaPendenteCobrador = 13,

    DividaPagamentoFinalizada = 14,

    DividaPagamentoPendenteCobrador = 13,

    DividaPagamentoTotal = 16,

    OcorrenciaPendenteAprovacaoCredor = 17,
    OcorrenciaRejeitada = 18,
    Notificacao = 19,
    DividaPagamentoRevisada = 20,
    DividaPagamentoPendenteRevisao = 21
}

export const TipoStatusLabel = new Map<TipoStatus, string>([

    [TipoStatus.BorderoErro, "Erro"],
    [TipoStatus.BorderoSucesso, "Sucesso"],

    [TipoStatus.BorderoLinhaErro, "Erro"],
    [TipoStatus.BorderoLinhaSucesso, "Sucesso"],

    [TipoStatus.AtividadeTarefaAberta, "Aberta"],
    [TipoStatus.AtividadeTarefaFinalizada, "Finalizada"],

    [TipoStatus.OcorrenciaCriada, "Criada"],
    [TipoStatus.OcorrenciaFinalizada, "Aprovada"],
    [TipoStatus.OcorrenciaEmAndamento, "Em Andamento"],
    [TipoStatus.OcorrenciaPendenteAprovacao, "Pendente de aprovação"],
    [TipoStatus.OcorrenciaPendenteCobrador, "Pendente cobrador"],

    [TipoStatus.DividaPagamentoFinalizada, "Aprovada"],
    [TipoStatus.DividaPagamentoPendenteCobrador, "Reprovada"],

    [TipoStatus.DividaPagamentoTotal, "Divida Pagamento Total"],
    [TipoStatus.OcorrenciaPendenteAprovacaoCredor, "Pendente de aprovação credor"],
    [TipoStatus.OcorrenciaRejeitada, "Rejeitada"],
    [TipoStatus.DividaPagamentoRevisada, "Revisada"],
    [TipoStatus.DividaPagamentoPendenteRevisao, "Pendente de revisão"],
    [TipoStatus.Notificacao, "Notificação"]
]);

