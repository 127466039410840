import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Guid } from "guid-typescript";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AtualizacaoValoresService } from "src/app/services/atualizacao-valores.service";
import { ProcessosJudiciaisRecursoDesdobramentoParteService } from "src/app/services/processos-judiciais-recurso-desdobramento-parte.service";
import { ProcessosJudiciaisRecursoDesdobramentoPedidoService } from "src/app/services/processos-judiciais-recurso-desdobramento-pedido.service";

@Component({
    selector: "app-modal-add-edit-pedido",
    templateUrl: "./modal-add-edit-pedido.component.html",
    styleUrls: ["./modal-add-edit-pedido.component.scss"],
})
export class ModalAddEditPedidoComponent implements OnInit {

    @Input() recursoDesdobramentoId: Guid = null;

    tipoMateriaPedidoId = null;
    classificacaoMateriaPedidoId = null;
    calculosValores = [];
    calculosValoresSelecionados = [];
    loadCalculosValores: boolean = false;
    pedidoVinculadoCalculo: boolean = true;
    partesEnvolvidas = [];
    parteBeneficiadaPeloPedido: Guid = null;
    loadParteBeneficada: boolean = false;

    public form: FormGroup = this.fb.group({

        detalhes: [''],
        pedidoVinculadoCalculo: ['1'],
        valorCausa: [0],
    });

    constructor(
        private activeModalService: NgbActiveModal,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        private atualizacaoValoresService: AtualizacaoValoresService,
        private processosJudiciaisRecursoDesdobramentoParteService: ProcessosJudiciaisRecursoDesdobramentoParteService,
        private processosJudiciaisRecursoDesdobramentoPedidoService: ProcessosJudiciaisRecursoDesdobramentoPedidoService,
    ) { }

    close() {
        this.activeModalService.close({ form: this.form.getRawValue() });
    }

    obterPartesEnvolvidas() {
        this.loadParteBeneficada = true;

        this.processosJudiciaisRecursoDesdobramentoParteService.listarPorRecursoDesdobramentoId(this.recursoDesdobramentoId)
            .subscribe(res => {
                this.loadParteBeneficada = false;
                this.partesEnvolvidas = res.data;

                this.partesEnvolvidas.forEach(parteEnvolvida => {
                    parteEnvolvida.labelPersonalizado = parteEnvolvida.nome + ' - ' + parteEnvolvida.qualificacaoNome;
                });
            });
    }

    obterCalculosValoresPorRecursoDesdobramentoId() {
        this.loadCalculosValores = true;

        this.atualizacaoValoresService.listarCalculosDeValoresPorRecursoDesdobramentoId(this.recursoDesdobramentoId)
            .subscribe(res => {
                this.loadCalculosValores = false;
                this.calculosValores = res.data;
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível obter atualizações de cálculos para este recurso/desdobramento!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    adicionarPedido() {

        if(!this.tipoMateriaPedidoId){
            this.toastrService.warning("Informe o tipo da matéria do pedido", "Atenção", {timeOut: 7000 });
            return;
        }

        if(!this.classificacaoMateriaPedidoId){
            this.toastrService.warning("Informe a classificação da matéria do pedido", "Atenção", {timeOut: 7000 });
            return;
        }

        const pedidoVinculadoCalculo = Number(this.form.get('pedidoVinculadoCalculo').value) == 1;

        if(pedidoVinculadoCalculo && (this.calculosValoresSelecionados == null || this.calculosValoresSelecionados.length == 0)){
            this.toastrService.warning("Selecione o(s) cálculo(s) vinculado(s) ao pedido", "Atenção", {timeOut: 7000 });
            return;
        }

        this.spinner.show();
        
        const payload = {
            ...this.form.value,
            pedidoVinculadoCalculo: pedidoVinculadoCalculo,
            tipoMateriaPedidoId: this.tipoMateriaPedidoId,
            classificacaoMateriaPedidoId: this.classificacaoMateriaPedidoId,
            processoJudicialCalculoDadosIds: this.calculosValoresSelecionados,
            pessoaBeneficiadaPedidoId: this.parteBeneficiadaPeloPedido,
            processoJudicialRecursoDesdobramentoId: this.recursoDesdobramentoId
        }

        this.processosJudiciaisRecursoDesdobramentoPedidoService.add(payload).subscribe(res => {
            this.toastrService.success("Pedido adicionado com sucesso!.", "Sucesso");
            this.spinner.hide();
            this.close();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível adicionar pedido para este recurso/desdobramento!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    calcularValoresSelecionados() {
        const calculosSelecionados = this.calculosValores.filter(calculo => this.calculosValoresSelecionados.includes(calculo.id));

        const somatorioValorTotalAtualizado = calculosSelecionados.reduce((acumulador, calculo) => {
            return acumulador + calculo.valorTotalAtualizado;
        }, 0);

        this.form.get('valorCausa').setValue(somatorioValorTotalAtualizado);
    }

    aplicarFiltroSelectCalculos(term: string, item: any) {
        term = term.toLowerCase();

        return (item.nome && item.nome.toString().indexOf(term) > -1)
            || (item.valorTotalAtualizado && item.valorTotalAtualizado.toString().indexOf(term) > -1)
            || (item.dataAtualizacaoValores && item.dataAtualizacaoValores.toString().indexOf(term) > -1);
    }

    aplicarFiltroSelectParteBeneficiada(term: string, item: any) {
        term = term.toLowerCase();

        return (item.nome && item.nome.toString().indexOf(term) > -1)
            || (item.qualificacaoNome && item.qualificacaoNome.toString().indexOf(term) > -1);
    }

    ngOnInit(): void {
        this.obterCalculosValoresPorRecursoDesdobramentoId();
        this.obterPartesEnvolvidas();

        this.form.get('pedidoVinculadoCalculo').valueChanges.subscribe((value) => {

            if (value === '1') {
                this.form.get('valorCausa').setValue(0);
                this.pedidoVinculadoCalculo = true;
                return;
            }

            this.pedidoVinculadoCalculo = false;
            this.calculosValoresSelecionados = [];
            this.parteBeneficiadaPeloPedido = null;
        });
    }
}
