<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3" *ngIf="hasFilter">
    <span>{{'Documentos'}}</span>
    <button type="button" class="btn btn-md btn-padrao" routerLink="/dashboard/documentos/documentos-cadastro"><span
        class="fas fa-plus-circle"></span>&nbsp;&nbsp;Cadastrar</button>
</h4>
<hr />

<!-- <app-listar-documentos [raiz]="'dash'"></app-listar-documentos> -->

<div class="filtro-header mt-2" *ngIf="hasFilter">
    <div class="p-3 filtro-header-titulo d-flex justify-content-between" (click)="abrirFecharFiltro()">
        <span>Filtros</span>
        <span class="lnr lnr-chevron-up" *ngIf="filtroOn"></span>
        <span class="lnr lnr-chevron-down" *ngIf="!filtroOn"></span>
    </div>
    <div class="filtro-documents" *ngIf="filtroOn">
        <div class="form-row align-items-center mb-3">
            <div class="col-md-2 col-sm-6">
                <label class="form-label">Origem </label>
                <select class="custom-select" [(ngModel)]="origem">
                    <option value="">Todas</option>
                    <option value="1">Tarefa</option>
                    <option value="2">Evento</option>
                    <option value="3">Prazo</option>
                    <option value="4">Audiencia</option>
                    <option value="5">Outros</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-6">
                <label class="form-label">Descrição</label>
                <input type="text" [(ngModel)]="descricao" class="form-control">
            </div>
            <div class="col-md-2 col-sm-12">
                <label class="form-label">Registro</label>
                <input type="text" [(ngModel)]="registro" class="form-control">
            </div>
            <div class="col-md-3 col-sm-6">
                <label class="form-label">Cliente</label>
                <pessoa-dropdown [(pessoaId)]="clientes" [selecionaMultiplos]="true"></pessoa-dropdown>
            </div>
            <div class="col-md-3 col-sm-6">
                <label class="form-label">Responsável</label>
                <pessoa-dropdown [(pessoaId)]="responsaveis" [selecionaMultiplos]="true"></pessoa-dropdown>
            </div>
        </div>
        <div class="form-row align-items-center mb-3">
            <div class="col-md-2 col-sm-6">
                <label class="form-label">Instância</label>
                <instancia-dropdown [(instanciaId)]="instancias" [selecionaMultiplos]="true"></instancia-dropdown>
            </div>
            <div class="col-md-2 col-sm-6">
                <label class="form-label">Número do processo</label>
                <input type="text" [(ngModel)]="numeroProcesso" class="form-control">
            </div>
            <div class="col-md-2 col-sm-6">
                <label class="form-label">Pasta</label>
                <input type="text" [(ngModel)]="pasta" class="form-control">
            </div>
            <div class="col-md-2 col-sm-6">
                <label class="form-label">Última mov.</label>
                <div class="input-group">
                    <input (click)="dataUltimaMovimentacaoFiltro.toggle()" class="form-control" placeholder="dd/mm/aaaa"
                        name="dataInicialFiltroComponent" ngbDatepicker placement="bottom-right"
                        #dataUltimaMovimentacaoFiltro="ngbDatepicker" [(ngModel)]="dataUltimaMovimentacao">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="dataUltimaMovimentacaoFiltro.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                <button type="button" class="btn btn-padrao btn-block" (click)="obterDocumentos()"><span
                        class="fas fa-search"></span>&nbsp;&nbsp;Filtrar</button>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                <button type="button" class="btn bg-light btn-block" (click)="limparFiltros()"><span
                        class="fas fa-search"></span>&nbsp;&nbsp;Limpar</button>
            </div>
        </div>
    </div>
</div>

<hr class="border-light my-0">

<div class="card">
    <div [ngClass]="{'card-body': hasFilter}">
        <div class="table-responsive" *ngIf="documentos.length > 0">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('TipoDocumentoEvento')">
                            Origem<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='TipoDocumentoEvento'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Descricao')">
                            Descrição<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Descricao'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('TituloReferencia')" *ngIf="hasFilter">
                            Registro<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='TituloReferencia'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Clientes')" *ngIf="hasFilter">
                            Cliente<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Clientes'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Pasta')">
                            Pasta<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Pasta'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('TipoDocumento')">
                            Tipo Documento<i class="ion text-muted ml-2"
                                *ngIf="colunaOrdenacao=='TipoDocumento'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('RecursoDesdobramento')">
                            Recurso/Desdobramento<i class="ion text-muted ml-2"
                                *ngIf="colunaOrdenacao=='RecursoDesdobramento'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('ResponsavelNome')">
                            Responsável<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='ResponsavelNome'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('DataAtualizacao')">
                            Última Mov.<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='DataAtualizacao'"
                                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-center text-nowrap align-middle">
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tabela" *ngFor="let documento of documentos">
                        <td>
                            {{obterOrigemDocumento(documento.tipoDocumentoEvento)}}
                        </td>
                        <td>
                            {{documento.descricao}}
                        </td>
                        <td *ngIf="hasFilter">
                            {{documento.tituloReferencia}}
                        </td>
                        <td *ngIf="hasFilter">
                            {{obterClientes(documento.clientes)}}
                        </td>
                        <td>
                            {{documento.pasta}}
                        </td>
                        <td>
                            {{documento.tipoDocumento}}
                        </td>
                        <td>
                            {{documento.recursoDesdobramento}} 
                            <a *ngIf="documento.tipoReferencia == 1" class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true" href="/dashboard/processos-judiciais/detalhes/{{documento.registroId}}" target="_blank"></a>
                        </td>
                        <td>
                            {{documento.responsavelNome}}
                        </td>
                        <td>
                            {{documento.dataAtualizacao | date: 'dd/MM/yyyy - HH:mm'}}
                        </td>
                        <td class="text-center text-nowrap align-middle link">
                            <button type="button" class="btn btn-xs icon-btn btn-primary"
                                (click)="handleDownloadArquivo(documento.anexos)"
                                [matTooltip]="tooltipDownloadArquivo(documento.anexos)" [matTooltipPosition]="'above'">
                                <span class="fas fa-download"></span>
                            </button>
                            
                            <button type="button" class="btn btn-xs icon-btn btn-warning" *ngIf="permiteEditarDocumento"
                                [routerLink]="['/dashboard/documentos/documentos-cadastro', documento.id]">
                                <span class="far fa-edit"></span>
                            </button>
                            <button type="button" class="btn btn-xs icon-btn btn-danger"
                                (click)="removerArquivo(documento.id)">
                                <span class="far fa-trash-alt"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-body py-0">
                <div class="row">
                    <div class="col-sm text-sm-left text-center pt-3">
                        <span class="text-muted" *ngIf="totalItens">Páginas {{ paginaAtual }} de {{ totalPaginas
                            }}</span>
                    </div>
                    <div class="col-sm text-sm-left text-center pt-3">
                        <label>Itens por página:</label>
                        <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                            (change)="alterarPagina(1)">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                            <option [ngValue]="500">500</option>
                        </select>
                    </div>
                    <div class="col-sm pt-6">
                        <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                            [(page)]="paginaAtual" [boundaryLinks]="true" (pageChange)="alterarPagina(paginaAtual)"
                            size="sm" class="d-flex justify-content-center justify-content-sm-end m-0">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-5" *ngIf="documentos.length == 0">
            <h3>Não possui dados.</h3>
        </div>
    </div>
</div>