<div class="modal-header">
    <h4>Honorários Advocatícios Sucumbenciais</h4>
</div>
<div class="modal-body">
    <form [formGroup]="honorarioSucubenciaForm">
        <div class="row">
            <div class="col-3">Base de cálculo:</div>
            <div class="form-check col-9">
                <input id="type-form-n" type="radio" value="T" formControlName="tipoFormularioCalculo">
                <label for="type-form-n">Utilizar o valor total do cálculo </label>
            </div>
            <div class="col-3"></div>
            <div class="form-check col-9">
                <input id="type-form-s" type="radio" value="V" formControlName="tipoFormularioCalculo">
                <label for="type-form-s">Digitar a base de cálculo </label>
            </div>
        </div>
        <div class="row" *ngIf="honorarioSucubenciaForm.get('tipoFormularioCalculo').value === 'V'">
            <div class="col-3 mt-2">Digite o valor base:</div>
            <div class="form-check col-3">
                <input type="number" class="form-control" formControlName="valorBaseCalculo">
            </div>            
        </div>
        <div class="row">
            <div class="col-3 mt-2">Percentual dos honorários:</div>
            <div class="form-check col-3">
                <input type="number" class="form-control" formControlName="valorPercentual">
            </div>
        </div>
        <hr />
        <div class="d-flex justify-content-end">
            <div class="col-md-2">
                <button type="button" class="btn background-color-white" (click)="dismiss()">Cancelar</button>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-padrao btn-block" (click)="close()">OK</button>
            </div>
        </div>
    </form>
</div>