import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../../services/shared/modal-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PessoaService } from 'src/app/services/pessoa.service';
import { AgGridComponent } from '../../shared/ag-grid/ag-grid.component';
import { ColDef } from 'ag-grid-community';
import { RequisicaoBuscaPaginada } from 'src/app/models/shared/busca-paginada';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent extends AgGridComponent implements OnInit {

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public ngbModal: NgbModal,
        private pessoaService: PessoaService
    ) {
        super()
    }

    public contatos: any = [{}];

    isShow = false;
    letraSelecionada = 'A';
    nome = '';
    tipo = '';

    //Paginação
    itensPorPagina: number = 50;
    totalItens: number = 0;
    paginaAtual: number = 1;

    get totalPaginas() {
        return Math.ceil(this.totalItens / this.itensPorPagina);
    }

    obterListagemSimplificada() {

        this.spinner.show();

        if (this.nome)
            this.letraSelecionada = '';

        let requisicaoBuscaPaginada: RequisicaoBuscaPaginada = {
            paginaAtual: this.paginaAtual,
            itensPorPagina: this.itensPorPagina
        }

        let requisicao = {
            paginacao: requisicaoBuscaPaginada,
            nome: this.nome,
            letraInicial: this.letraSelecionada,
            tipo: this.tipo,
        }

        this.pessoaService.obterListagemSimplificada(requisicao)
            .subscribe(res => {

                this.contatos = res.data.itens;
                this.totalItens = res.data.paginacao.totalItens;
                this.isShow = false;
                this.spinner.hide();
            });
    }

    alterarPagina(paginaSelecionada) {

        this.paginaAtual = paginaSelecionada;
        this.obterListagemSimplificada();
    }

    aoSelecionarLetraInicial(letraSelecionada) {

        this.letraSelecionada = letraSelecionada;
        this.obterListagemSimplificada();
    }

    showModalPessoa(pessoaId: any) {
        
        this.spinner.show();

        this.pessoaService.getById(pessoaId)
            .subscribe(res => {
                this.spinner.hide();
                this.modalService.abrirModalCadastraPessoa(res.data, true, false);
            });
    }

    ngOnInit(): void {
        this.obterListagemSimplificada();
    }
}
