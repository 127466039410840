export enum Prioridade {
    Baixa = 1,
    Media = 2,
    Alta = 3
}

export const PrioridadeLabel = new Map<Prioridade, string>([

    [Prioridade.Baixa, "Baixa"],
    [Prioridade.Media, "Média"],
    [Prioridade.Alta, "Alta"]

]);