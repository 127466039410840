import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { ServiceBase } from '../service.base';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(public authService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.authService.obterTokenUsuario() && request.url.indexOf("usuario/login") === -1) {
            request = this.addToken(request, this.authService.obterTokenUsuario());
        }

        return next.handle(request).pipe(catchError(error => {

            if (error instanceof HttpErrorResponse && error.status === 401) {
                this.handle401Error(request, next);
            } else {
                return throwError(error);
            }
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        this.router.navigate(['/']);
    }
}
