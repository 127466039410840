import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ServiceBase } from './service.base';
import { ProcessoDividaResponse } from '../models/operacionais/operacao/processo-divida-response';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FaturamentoService extends ServiceBase{

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'faturamento/';


    getPagamentos(cpfCnpj,dataInicial,dataFinal) : Observable<any> {

      dataInicial = dataInicial == undefined ? new Date('1970-01-01Z00:00:00:000').toDateString():new Date(dataInicial.year,dataInicial.month,dataInicial.day).toDateString();
      dataFinal = dataFinal == undefined ? new Date().toDateString():new Date(dataFinal.year,dataFinal.month,dataFinal.day).toDateString();

      return this.http.get(environment.api + this.endpoint + `pagamentosByCpfCnpjNaoFaturados?cpfCnpj=${cpfCnpj}
      &dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
  }


    search(pageIndex: number, pageSize: number, filters: any[], orderBy: string) : Observable<any> {

        return this.http.post(environment.api + this.endpoint + 'search/', { pageIndex: pageIndex,pageSize: pageSize, filters: filters, orderBy: orderBy  })
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    save(faturamento: any) : Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'save/',faturamento)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }



    getAll() : Observable<any> {

      return this.http.get(environment.api + this.endpoint)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    getAllPendentesEmissaoByMonth(date) : Observable<any> {

      return this.http.get(environment.api + this.endpoint + `pendentesEmissao?date=${date}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    getAllNaoBaixadasByMonth(date) : Observable<any> {

      return this.http.get(environment.api + this.endpoint + `naoBaixadas?date=${date}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    getAllbyCredorAndDate(credorId,date:Date) : Observable<any> {

      var dateStr = date.toDateString();

      return this.http.get(environment.api + this.endpoint + `getAllByCredorAndDate?credorId=${credorId}&date=${dateStr}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    getAllCredoresComPagamantosNaoFaturados() : Observable<any> {

      return this.http.get(environment.api + this.endpoint + 'credoresComPagamentosNaoFaturados')
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    getPagamentoEComissaoByFaturamento(faturamentoId) : Observable<any> {

    return this.http.get(environment.api + this.endpoint + `totalPagamentoTotalComissaoByFaturamento/${faturamentoId}`)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    add(faturamento: any) : Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'add/',faturamento)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    update(faturamento: any) : Observable<any> {
      return this.http.post(environment.api + this.endpoint + 'update/',faturamento)
      .pipe(
          map(this.extractData),
          catchError(this.serviceError)
      );
    }

    export(faturamentoId: Guid) : Observable<Blob> {
        return this.http.get(environment.api + this.endpoint + 'export/'+ faturamentoId, {responseType: "blob"})
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + 'delete/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
