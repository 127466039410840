export enum TipoSituacao {
    Divida = 2,
    Ocorrencia = 3,
    Atividade = 4
}

export const TipoSituacaoLabel = new Map<TipoSituacao, string>([

    [TipoSituacao.Divida, "Divida"],
    [TipoSituacao.Ocorrencia, "Ocorrencia"],
    [TipoSituacao.Atividade, "Atividade"],
]);
