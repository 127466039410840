<div class="modal-class">
    <div class="modal-header">

        <h5 class="modal-title">
            Honorário Convencionados
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div style="display: flex;flex-direction: column;justify-content: center;"
            class="text-center mt-5" *ngIf="load">
            <div class="fa-3x">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
        </div>
        <form *ngIf="!load" ngNoForm>
            <div class="row">
                <div class="col-12">
                    <label class="font-weight-bold">Tipo de honorário: </label>
                    <div class="form-check form-check-inline form-group mt-1">
                        <input mdbRadio class="form-check-input" type="radio" [(ngModel)]="tipoHonorario" [disabled]="honorario.id != undefined ? true : false"
                            id="inicial-fee" value="2" (change)="changeTipo()" />
                        <label class="form-check-label" for="inicial-fee">Inicial Fee</label>
                    </div>
                    <div class="form-check form-check-inline form-group">
                        <input mdbRadio class="form-check-input" type="radio" [(ngModel)]="tipoHonorario" [disabled]="honorario.id != undefined ? true : false"
                            id="sucess-fee" value="3" (change)="changeTipo()" />
                        <label class="form-check-label" for="sucess-fee">Success Fee</label>
                    </div>
                    <div class="form-check form-check-inline form-group">
                        <input mdbRadio class="form-check-input" type="radio" [(ngModel)]="tipoHonorario" [disabled]="honorario.id != undefined ? true : false" 
                            id="timesheet" value="4" (change)="changeTipo()" />
                        <label class="form-check-label" for="timesheet">Timesheet</label>
                    </div>
                </div>
            </div>
            <!--2 - Inicial Fee-->
            <div class="col-12 mt-2" *ngIf="tipoHonorario == '2'">
                <div class="row">
                    <div class="form-row" *ngIf="honorario.quantidadeSalariosMinimos == null && honorario.valorInicial != null && honorario.valorFinal != null">
                        <div class="col-12 mb-3"><b>Valores considerados no cálculo</b></div>
                        <div class="form-group col-6">
                            <label class="form-label">Valor Inicial</label>
                            <span>{{honorario.valorInicial | currency: 'BRL'}}</span>
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label">Valor Final</label>
                            <span>{{honorario.valorFinal | currency: 'BRL'}}</span>
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label">Percentual:</label>
                            <span>{{honorario.percentualHonorario}}%</span>
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label">Valor Calculado:</label>
                            <span>{{honorario.valorCalculado | currency: 'BRL'}}</span>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="honorario.quantidadeSalariosMinimos != null">
                        <div class="col-12 mb-3"><b>Valores considerados no cálculo</b></div>
                        <div class="form-group col-6">
                            <label class="form-label">Quantidade de Salários</label>
                            <span>{{honorario.quantidadeSalariosMinimos}}</span>
                        </div>                        
                        <div class="form-group col-6">
                            <label class="form-label">Valor Calculado:</label>
                            <span>{{honorario.valorCalculado | currency: 'BRL'}}</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label class="form-label">Data do evento<span class="required-field ml-1">*</span></label>
                            <div class="input-group">
                                <input (click)="Vencimento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" ngbDatepicker [(ngModel)]="honorario.vencimento"
                                    [displayMonths]="displayMonths" [navigation]="navigation" placement="bottom-right"
                                    #Vencimento="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="input-group-text" (click)="Vencimento.toggle()" type="button">
                                        <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label">Valor Honorário<span
                                    class="required-field ml-1">*</span></label>
                            <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                class="form-control" [(ngModel)]="honorario.valorHonorario" data-lpignore="true">
                        </div>
                        <div class="form-group col-12">
                            <label class="form-label">Descrição<span class="required-field ml-1">*</span></label>
                            <input type="text" maxlength="1000" class="form-control" [(ngModel)]="honorario.descricao">
                        </div>
                        <div class="col-12">
                            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" [uploader]="uploader"
                                class="dropzone" (click)="fileInput.click()">
                                <span class="span-text">Carregar documento</span>
                                <input type="file" #fileInput ng2FileSelect [uploader]="honorario.uploader"
                                    style="display: none" multiple (onFileSelected)="onDrop(honorario, $event)" />
                            </div>
                            <div class="row" *ngIf="honorario.uploader.queue.length > 0">
                                <div class="col-12" *ngFor="let item of honorario.uploader.queue">
                                    {{item?.file?.name}}
                                </div>
                            </div>
                        </div>                                                
                        <br />
                    </div>
                </div>
            </div>
            <!--3 - Success Fee-->            
            <div class="col-12 mt-2" *ngIf="tipoHonorario == '3'">
                <div class="row">
                    <div class="form-group col-6">
                        <label class="form-label">Valor Recuperado<span
                                class="required-field ml-1">*</span></label>
                        <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            class="form-control" [(ngModel)]="honorario.valorRecuperado" data-lpignore="true" (blur)="onValorRecuperadoChange($event)">
                    </div>
                    <div class="form-group col-6">
                        <label class="form-label">% dos honorários<span
                                class="required-field ml-1">*</span></label>
                        <input currencyMask [options]="{ prefix:'', suffix: ' %', thousands: '.', decimal: ',' }"
                            class="form-control" [(ngModel)]="honorario.valorPercentual" data-lpignore="true" (blur)="onCalculateChange()" [disabled]="loadSucessFee">
                            <span class="spinner-border spinner-border-sm input-spnner" role="status" aria-hidden="true" *ngIf="loadSucessFee"></span>
                    </div>
                    <div class="form-group col-6">
                        <label class="form-label">Valor Honorário<span
                                class="required-field ml-1">*</span></label>
                        <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            class="form-control" [(ngModel)]="honorario.valorHonorario" data-lpignore="true" [disabled]="loadSucessFee" (blur)="onCalculateChange()">
                            <span class="spinner-border spinner-border-sm input-spnner" role="status" aria-hidden="true" *ngIf="loadSucessFee"></span>
                    </div>
                    <div class="form-group col-6">
                        <label class="form-label">Data do evento<span class="required-field ml-1">*</span></label>
                        <div class="input-group">
                            <input (click)="Vencimento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" ngbDatepicker [(ngModel)]="honorario.vencimento"
                                [displayMonths]="displayMonths" [navigation]="navigation" placement="bottom-right"
                                #venc
                                #Vencimento="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="input-group-text" (click)="Vencimento.toggle()" type="button">
                                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label class="form-label">Descrição<span class="required-field ml-1">*</span></label>
                        <input type="text" maxlength="1000" class="form-control" [(ngModel)]="honorario.descricao">
                    </div>
                    <div class="col-12">
                        <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" [uploader]="uploader"
                            class="dropzone" (click)="fileInput.click()">
                            <span class="span-text">Carregar documento</span>
                            <input type="file" #fileInput ng2FileSelect [uploader]="honorario.uploader"
                                style="display: none" multiple (onFileSelected)="onDrop(honorario, $event)" />
                        </div>
                        <div class="row" *ngIf="honorario.uploader.queue.length > 0">
                            <div class="col-12" *ngFor="let item of honorario.uploader.queue">
                                {{item?.file?.name}}
                            </div>
                        </div>
                    </div>                                                
                    <br />
                </div>
            </div>
            <div class="col-12 mt-2" *ngIf="tipoHonorario == '4'">
                <div class="row">
                    <div class="form-group col-4">
                        <label class="form-label">Data do evento<span class="required-field ml-1">*</span></label>
                        <div class="input-group">
                            <input (click)="Vencimento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" ngbDatepicker [(ngModel)]="honorario.vencimento"
                                [displayMonths]="displayMonths" [navigation]="navigation" placement="bottom-right"
                                #Vencimento="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="input-group-text" (click)="Vencimento.toggle()" type="button">
                                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label class="form-label">Valor/Hora Cliente
                            <span class="required-field ml-1">*</span></label>
                        <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            class="form-control" [(ngModel)]="honorario.valorHoraInformado" data-lpignore="true" (blur)="calcularHora()">
                        <input type="hidden" [(ngModel)]="honorario.valorHoraCliente">
                    </div>
                    <div class="form-group col-4">
                        <label class="form-label">Duração
                            <span class="required-field ml-1">*</span></label>
                        <input type="text"
                            class="form-control" [(ngModel)]="honorario.duracao"  
                            placeholder="00:00"
                            [textMask]="{mask: maskTime, guide: false}" (blur)="calcularHora()">                            
                    </div>
                    <div class="form-group col-4">
                        <label class="form-label">Valor Honorário<span
                                class="required-field ml-1">*</span></label>
                        <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            class="form-control" [(ngModel)]="honorario.valorHonorario" data-lpignore="true" disabled>
                        <span class="spinner-border spinner-border-sm input-spnner" role="status" aria-hidden="true" *ngIf="loadTimesheet"></span>
                    </div>
                    <div class="form-group col-12">
                        <label class="form-label">Descrição<span class="required-field ml-1">*</span></label>
                        <input type="text" maxlength="1000" class="form-control" [(ngModel)]="honorario.descricao">
                    </div>
                    <div class="col-12">
                        <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" [uploader]="uploader"
                            class="dropzone" (click)="fileInput.click()">
                            <span class="span-text">Carregar documento</span>
                            <input type="file" #fileInput ng2FileSelect [uploader]="honorario.uploader"
                                style="display: none" multiple (onFileSelected)="onDrop(honorario, $event)" />
                        </div>
                        <div class="row" *ngIf="honorario.uploader.queue.length > 0">
                            <div class="col-12" *ngFor="let item of honorario.uploader.queue">
                                {{item?.file?.name}}
                            </div>
                        </div>
                    </div>                                                
                    <br />
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="salvar()"><i class="fas fa-save"></i> Salvar</button>
    </div>
</div>