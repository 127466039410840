import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessosJudiciaisOcorrenciaAudienciaService } from 'src/app/services/processos-judiciais-ocorrencia-audiencia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CustomFileLike } from 'src/app/models/custom-file-like';

@Component({
    selector: 'audiencia-detalhes',
    templateUrl: './audiencia-detalhes.component.html',
    styleUrls: [
        './audiencia-detalhes.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ]
})
export class AudienciaDetalhesComponent implements OnInit {

    @Input() audienciaId: any;
    @Input() audienciaDetalhes: any;

    @Output() changeQuantidade: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeQuantidadeOcultando: EventEmitter<number> = new EventEmitter<number>();
    
    public loadAudiencia: boolean = true;
    
    public audiencia: any;

    public comentarioDescricao: string;
    
    public comentarios: any = [];

    public uploader: FileUploader;

    public loadAtach: boolean = false;

    private file: File;

    private files: Array<File> = [];


    constructor(
        private processosJudiciaisOcorrenciaAudienciaService: ProcessosJudiciaisOcorrenciaAudienciaService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,        
    ) { 
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            }
        });
    }

    ngOnInit(): void {
        this.buscarAudiencia();
    }

    buscarAudiencia() {
        this.processosJudiciaisOcorrenciaAudienciaService.getById(this.audienciaId)
            .subscribe(res => {

                if (res.success) {
                    
                    this.audiencia = res.data;

                    this.loadAudiencia = false;

                    this.audiencia.quantidadeComentarios = res.data.quantidadeComentarios;

                    this.comentarios = res.data.comentarios;

                    this.changeQuantidade.emit(this.audiencia.quantidadeComentarios);
                }
            })
    }

    handleInserirComentarioForm() {

        this.spinner.show();
        this.toastrService.clear();

        let formData: FormData = new FormData();
        formData.append('audienciaId', this.audienciaId);
        formData.append('comentario', this.comentarioDescricao != undefined ? this.comentarioDescricao : "");
        this.files.forEach(file => {
            formData.append('files', file);
        });        

        this.processosJudiciaisOcorrenciaAudienciaService.addComentario(formData)
            .subscribe(res => {
                if (res.success) {
                    this.toastrService.success("Comentário cadastrado.", "Sucesso");

                    this.buscarAudiencia();

                    this.comentarioDescricao = "";

                    this.spinner.hide();

                    this.uploader.clearQueue();

                    this.files = [];
                } else {
                    this.toastrService.error("Falha ao adicionar comentário.", "Sucesso");

                    this.spinner.hide();
                }
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o comentário!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            this.files.push(files[i] as File);
        }

        const fileInQueue = this.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item:FileItem){
        console.log(item);
        this.files = this.files.filter(c=>c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(c=>c.file.name != item.file.name);
    }

    handleOcultar() {
        this.changeQuantidadeOcultando.emit(this.audiencia.quantidadeComentarios);
    }    
}
