export enum Cor {
  badgeDanger = 1,
  badgeWarning = 2,
  badgeDefault = 3,
  badgeDark = 4,
  badgeSuccess = 5,
  badgePrimary = 6
}

export const CorLabel = new Map<Cor, string>([

  [Cor.badgeDefault, "badge-default"],
  [Cor.badgeDanger, "badge-danger"],
  [Cor.badgeWarning, "badge-warning"],
  [Cor.badgeDark, "badge-dark"],
  [Cor.badgeSuccess, "badge-success"],
  [Cor.badgePrimary, "badge-primary"]

]);

export const badgeToBg = {
    'badge-danger': '#FFB0B0',
    'badge-warning': '#FFFF7D',
    'badge-dark': '#9F9F9F',
    'badge-success': '#C1FFD4',
    'badge-primary': '#C1E5FF',
}






