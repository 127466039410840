
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicacaoService extends ServiceBase {

  constructor(private http: HttpClient) 
  {
    super();
  }

  public endpoint = 'publicacao/';
  private dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  
    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    descartarPublicacao(id): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'descartar-publicacao/' +id, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    concluirPublicacao(id): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'concluir-publicacao/' +id, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    descartarPublicacoes(ids): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'descartar-publicacoes', ids)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    desfazerDescartarPublicacao(id): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'desfazer-descartar-publicacao/' +id, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllByDias(quantidadeDias:number): Observable<any> {
        return this.http.get(environment.api + this.endpoint+'obter-por-dias/'+quantidadeDias)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    filterPagination(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'obter-paginado', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getDetails(internas): Observable<any> {
        return this.http.get(environment.api + this.endpoint+`obter-detalhes/${internas}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
        
    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    gerarOcorrencia(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'gerar-ocorrencia' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    vincularOcorrencia(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'vincular-ocorrencia' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    adicionarPrazo(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-prazo' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    editarPrazo(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint+'editar-prazo' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    adicionarAudiencia(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-audiencia' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    editarAudiencia(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint+'editar-audiencia' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    adicionarTarefa(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-tarefa' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    adicionarEvento(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-evento' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    adicionarHistoricoManual(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-historico-manual' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    importacao(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'importacao' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    sincronizarPublicacoes(): Observable<any> {
        return this.http.get(environment.api + this.endpoint+'sincronizar-publicacoes')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
