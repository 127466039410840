import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Guid } from "guid-typescript";

@Injectable()
export class ProcessosJudiciaisAtividadesLogsService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'ProcessoJudicialAtividadeLog/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    obterLogsDaAtividade(atividadeId): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-logs-da-atividade/${atividadeId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    
    
}
