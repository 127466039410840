import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter, NgbModalRef, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PessoaService } from 'src/app/services/pessoa.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { TipoOcorrenciaJudicialService } from 'src/app/services/tipo-ocorrencia-judicial.service';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-modal-ocorrencia',
    templateUrl: './modal-ocorrencia.component.html',
    styleUrls: ['./modal-ocorrencia.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class ModalOcorrenciaComponent implements OnInit {

    private modalRef: NgbModalRef;
    public recursoDesdobramento: any;
    public ocorrencia: any = {
        tarefas: [],
        historicosManuais: [],
        eventos: [],
        prazos: [],
        audiencias: [],
        formularios: [],
    };

    public prazoId: any = null;

    private prazosDelete: any = [];
    private tarefasDelete: any = [];
    private eventosDelete: any = [];
    private historicosManuaisDelete: any = [];
    private audienciasDelete: any = [];
    private formulariosDelete: any = [];
    public loadTipoOcorrencia: boolean = false;
    public loadTipoTarefas: boolean = false;
    public editarAtividadeOcorrencia: boolean = false;
    notificarCliente: boolean = false;
    contatos: any = [];

    get selecionouTodosContatos() {
        return this.contatos.every(contato => contato.selecionado);
    }


    public tipoOcorrencia: any = [];
    public tipoTimeLine: any = [
        {
            value: true,
            display: "Sim"
        }, {
            value: false,
            display: "Não"
        }
    ]

    public historicoManual: any = {
        isEdit: false
    };

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    constructor(
        private datePipe: DatePipe,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
        private tipoOcorrenciaJudicialService: TipoOcorrenciaJudicialService
    ) { }

    ngOnInit(): void {

        this.buscarTipoOcorrenciaJudicial();
    }

    buscarTipoOcorrenciaJudicial() {
        this.loadTipoOcorrencia = true;

        this.tipoOcorrenciaJudicialService.getAllAsync()
            .then(res => {
                if (res.success) {
                    this.tipoOcorrencia = res.data;
                    this.loadTipoOcorrencia = false;
                }
            })
    }

    handleAdicionarTarefa() {

        this.ocorrencia.tarefas.push(
            {
                isEdit: false,
                index: this.ocorrencia.tarefas.length,
                processoJudicialOcorrenciaPrazoId: this.prazoId
            });
    }

    handleAdicionarHistoricoManual() {
        this.ocorrencia.historicosManuais.push(
            {
                isEdit: false,
                index: this.ocorrencia.historicosManuais.length,
            });
    }

    handleAdicionarEvento() {

        this.ocorrencia.eventos.push(
            {
                isEdit: false,
                alerta: 0,
                tipoAlerta: 1,
                index: this.ocorrencia.eventos.length,
                adicionarEmails: false,
                emails: [],
                processoJudicialOcorrenciaPrazoId: this.prazoId,
                envolvidos: []
            });
    }

    handleAdicionarPrazo() {

        this.ocorrencia.prazos.push(
            {
                isEdit: false,
                alerta: 0,
                tipoAlerta: 1,
                index: this.ocorrencia.prazos.length,
                envolvidos: []
            });
    }

    handleAdicionarFormulario(){
        this.ocorrencia.formularios.push({
            isEdit: false,
            alerta: 0,
            tipoAlerta: 1,
            index: this.ocorrencia.formularios.length,
            envolvidos: [],
        })
    }    

    adicionarEnvolvidoPrazo(prazo: any) {
        prazo.envolvidos.push({});
    }

    removerEnvolvidoPrazo(prazo: any, envolvidoIndex) {
        prazo.envolvidos.forEach((element, index) => {
            if (index == envolvidoIndex) {
                prazo.envolvidos.splice(index, 1);
            }
        });
    }

    handleAdicionarAudiencia() {
        this.ocorrencia.audiencias.push(
            {
                isEdit: false,
                alerta: 0,
                tipoAlerta: 1,
                index: this.ocorrencia.audiencias.length
            });
    }

    salvar() {

        this.ocorrencia.processoJudicialRecursoDesdobramentoId = this.recursoDesdobramento.id;

        if (this.ocorrencia.descricao == undefined || this.ocorrencia.descricao == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a descrição!',
                '',
                'error'
            );

            return;
        }

        if (this.ocorrencia.tipoProcessoJudicialOcorrenciaId == undefined || this.ocorrencia.tipoProcessoJudicialOcorrenciaId == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o tipo!',
                '',
                'error'
            );

            return;
        }

        if (this.ocorrencia.dataOcorrenciaComponent == undefined || this.ocorrencia.dataOcorrenciaComponent == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a data!',
                '',
                'error'
            );

            return;
        } else {
            this.ocorrencia.dataOcorrencia = new Date(this.ocorrencia.dataOcorrenciaComponent.year, this.ocorrencia.dataOcorrenciaComponent.month - 1, this.ocorrencia.dataOcorrenciaComponent.day);
        }

        if (this.ocorrencia.exibirTimeLine == undefined || this.ocorrencia.exibirTimeLine == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado se é para exibir no Time Line!',
                '',
                'error'
            );

            return;
        }

        if (this.ocorrencia.exibirParaCliente == undefined || this.ocorrencia.exibirParaCliente == null) {
            this.swalWithBootstrapButtons.fire(
                'Por favor, informe se a ocorrência deve ser exibida para o cliente!',
                '',
                'error'
            );

            return;
        }
        if (!this.validarPrazos()) return;
        if (!this.validarTarefas()) return;
        if (!this.validarEventos()) return;
        if (!this.validarHistoricoManual()) return;
        if (!this.validarAudiencia()) return;
        if (!this.validarFormularios()) return;
        if(!this.validarNotificacaoCliente()) return;

        this.spinner.show();
        this.toastrService.clear();

        if (!this.ocorrencia.isEdit) {
            this.processosJudiciaisOcorrenciasService.add(this.ocorrencia)
                .subscribe(res => {
                    this.spinner.hide();
                    this.close();
                }, error => {

                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar a ocorrência!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        } else {
            this.processosJudiciaisOcorrenciasService.update(this.ocorrencia)
                .subscribe(res => {
                    this.spinner.hide();
                    this.close();
                }, error => {

                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar a ocorrência!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        }
    }

    validarAudiencia(): boolean {
        if (this.audienciasDelete.length > 0) {
            this.audienciasDelete.forEach(c => this.ocorrencia.audiencias.push(c));
        }

        var retorno = true;

        if (this.ocorrencia.audiencias != undefined && this.ocorrencia.audiencias.length > 0) {
            this.ocorrencia.audiencias.forEach(audiencia => {

                if (!retorno) return false;

                if (audiencia.tipoSessaoId == undefined || audiencia.tipoSessaoId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o tipo de sessão da audiência!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (audiencia.responsavelId == undefined || audiencia.responsavelId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o responsável da audiência!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (audiencia.dataHoraInicialComponent == undefined || audiencia.dataHoraInicialComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a data da audiência!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (audiencia.dataHoraInicialTimeComponent == undefined || audiencia.dataHoraInicialTimeComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a hora de início da audiência!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (audiencia.dataHoraInicialTimeComponent == undefined || audiencia.dataHoraInicialTimeComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a hora de fim da audiência!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (audiencia.observacao == undefined || audiencia.observacao == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a observação!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                var horainicial = audiencia.dataHoraInicialTimeComponent.split(":");

                audiencia.dataHoraInicial = new Date(audiencia.dataHoraInicialComponent.year,
                    audiencia.dataHoraInicialComponent.month - 1,
                    audiencia.dataHoraInicialComponent.day,
                    horainicial[0],
                    horainicial[1]);

                var horafinal = audiencia.dataHoraFinalTimeComponent.split(":");

                audiencia.dataHoraFinal = new Date(audiencia.dataHoraInicialComponent.year,
                    audiencia.dataHoraInicialComponent.month - 1,
                    audiencia.dataHoraInicialComponent.day,
                    horafinal[0],
                    horafinal[1]);
            });
        }

        return retorno;
    }

    validarHistoricoManual(): boolean {
        if (this.historicosManuaisDelete.length > 0) {
            this.historicosManuaisDelete.forEach(c => this.ocorrencia.historicosManuais.push(c));
        }
        var retorno = true;
        if (this.ocorrencia.historicosManuais != undefined && this.ocorrencia.historicosManuais.length > 0) {
            this.ocorrencia.historicosManuais.forEach(historicoManual => {
                if (!retorno) return false;

                if (historicoManual.descricao == undefined || historicoManual.descricao == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a descrição do histórico!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (historicoManual.responsavelId == undefined || historicoManual.responsavelId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o responsável do histórico!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (historicoManual.dataHistoricoComponent == undefined || historicoManual.dataHistoricoComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a data do histórico!',
                        '',
                        'error'
                    );

                    retorno = false;
                } else {
                    historicoManual.dataHistorico = new Date(historicoManual.dataHistoricoComponent.year, historicoManual.dataHistoricoComponent.month - 1, historicoManual.dataHistoricoComponent.day);
                }

                if (!retorno) return false;
            });
        }

        return retorno;
    }

    validarTarefas(): boolean {
        var retorno = true;
        if (this.tarefasDelete.length > 0) {
            this.tarefasDelete.forEach(c => this.ocorrencia.tarefas.push(c));
        }

        if (this.ocorrencia.tarefas != undefined && this.ocorrencia.tarefas.length > 0) {
            this.ocorrencia.tarefas.forEach(tarefa => {
                if (!retorno) return false;

                if (tarefa.descricao == undefined || tarefa.descricao == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a descrição da tarefa!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (tarefa.responsavelId == undefined || tarefa.responsavelId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o responsável da tarefa!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (tarefa.revisorId == undefined || tarefa.revisorId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o revisor da tarefa!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (tarefa.prioridade == undefined || tarefa.prioridade == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a prioridade da Tarefa!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (tarefa.dataTarefaComponent != undefined || tarefa.dataTarefaComponent != null) {
                    tarefa.dataTarefa = new Date(tarefa.dataTarefaComponent.year, tarefa.dataTarefaComponent.month - 1, tarefa.dataTarefaComponent.day);
                }
            });
        }

        return retorno;
    }

    validarEventos(): boolean {
        if (this.eventosDelete.length > 0) {
            this.eventosDelete.forEach(c => this.ocorrencia.eventos.push(c));
        }
        var retorno = true;

        if (this.ocorrencia.eventos != undefined && this.ocorrencia.eventos.length > 0) {
            this.ocorrencia.eventos.forEach(evento => {
                if (evento.titulo == undefined || evento.titulo == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o título do evento!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (evento.dataHoraInicialComponent == undefined || evento.dataHoraInicialComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a data de início do evento!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (evento.dataHoraFinalComponent == undefined || evento.dataHoraFinalComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a data de fim do evento!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (!evento.diaInteiro) {
                    if (evento.dataHoraInicialTimeComponent == undefined || evento.dataHoraInicialTimeComponent == null) {
                        this.swalWithBootstrapButtons.fire(
                            'Não foi informado a hora de início do evento!',
                            '',
                            'error'
                        );

                        retorno = false;
                    }

                    if (!retorno) return false;

                    if (evento.dataHoraFinalTimeComponent == undefined || evento.dataHoraFinalTimeComponent == null) {
                        this.swalWithBootstrapButtons.fire(
                            'Não foi informado a hora de fim do evento!',
                            '',
                            'error'
                        );

                        retorno = false;
                    }

                    if (!retorno) return false;

                    var horainicial = evento.dataHoraInicialTimeComponent.split(":");

                    evento.dataHoraInicial = new Date(evento.dataHoraInicialComponent.year,
                        evento.dataHoraInicialComponent.month + 1,
                        evento.dataHoraInicialComponent.day,
                        horainicial[0],
                        horainicial[1]);

                    var horainicial = evento.dataHoraFinalTimeComponent.split(":");

                    evento.dataHoraFinal = new Date(evento.dataHoraFinalComponent.year,
                        evento.dataHoraFinalComponent.month + 1,
                        evento.dataHoraFinalComponent.day,
                        horainicial[0],
                        horainicial[1]);

                } else {
                    evento.dataHoraInicial = new Date(evento.dataHoraInicialComponent.year,
                        evento.dataHoraInicialComponent.month + 1,
                        evento.dataHoraInicialComponent.day,
                        0,
                        0);

                    evento.dataHoraFinal = new Date(evento.dataHoraFinalComponent.year,
                        evento.dataHoraFinalComponent.month + 1,
                        evento.dataHoraFinalComponent.day,
                        23,
                        59);
                }

                if (evento.responsavelId == undefined || evento.responsavelId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o responsável do evento!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (evento.envolvidos.length > 0) {
                    evento.envolvidos.forEach(element => {
                        if (element.envolvidoId == undefined && retorno) {
                            this.swalWithBootstrapButtons.fire(
                                'Envolvido não selecionado!',
                                '',
                                'error'
                            );

                            retorno = false;
                        }
                    });
                }

                if (!retorno) return false;

                if (evento.observacao == undefined || evento.observacao == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a observação!',
                        '',
                        'error'
                    );

                    retorno = false;
                }
            });
        }

        return retorno;
    }

    validarPrazos(): boolean {

        if (this.prazosDelete.length > 0) {
            this.prazosDelete.forEach(c => this.ocorrencia.prazos.push(c));
        }
        var retorno = true;

        if (this.ocorrencia.prazos != undefined && this.ocorrencia.prazos.length > 0) {
            this.ocorrencia.prazos.forEach(prazo => {
                if (!retorno) retorno;

                if (!retorno) return false;

                if (prazo.dataLimiteComponent == undefined || prazo.dataLimiteComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a data limite do prazo!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                prazo.dataLimite = new Date(prazo.dataLimiteComponent.year,
                    prazo.dataLimiteComponent.month - 1,
                    prazo.dataLimiteComponent.day);



                prazo.dataRevisao = new Date(prazo.dataRevisaoComponent.year,
                    prazo.dataRevisaoComponent.month - 1,
                    prazo.dataRevisaoComponent.day);

                if (prazo.responsavelId == undefined || prazo.responsavelId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o responsável do prazo!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (prazo.revisorId == undefined || prazo.revisorId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado o revisor do prazo!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (prazo.alerta != undefined && prazo.alerta != null && prazo.alerta < 0) {
                    this.swalWithBootstrapButtons.fire(
                        'Alerta informado no prazo é inválido!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (prazo.dataLimiteComponent != undefined || prazo.dataLimiteComponent != null) {
                    prazo.dataLimite = new Date(prazo.dataLimiteComponent.year, prazo.dataLimiteComponent.month - 1, prazo.dataLimiteComponent.day);
                }

                if (prazo.envolvidos.length > 0) {
                    prazo.envolvidos.forEach(element => {
                        if (element.envolvidoId == undefined && retorno) {
                            this.swalWithBootstrapButtons.fire(
                                'Envolvido não selecionado!',
                                '',
                                'error'
                            );

                            retorno = false;
                        }
                    });
                }

                if (!retorno) return false;

                if (prazo.observacao == undefined || prazo.observacao == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Não foi informado a observação!',
                        '',
                        'error'
                    );

                    retorno = false;
                }
            });
        }

        return retorno;
    }

    validarFormularios(): boolean {

        if (this.formulariosDelete.length > 0) {
            this.formulariosDelete.forEach(c => this.ocorrencia.formularios.push(c));
        }
        var retorno = true;

        if (this.ocorrencia.formularios != undefined && this.ocorrencia.formularios.length > 0) {
            this.ocorrencia.formularios.forEach(formulario => {
                if (!retorno) retorno;

                if (!retorno) return false;

                if(!formulario.formularioDinamicoId){
                    this.swalWithBootstrapButtons.fire(
                        'Selecione o formulário',
                        '',
                        'error'
                    );
                }

                if (formulario.dataLimiteComponent == undefined || formulario.dataLimiteComponent == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Informe a data limite do formulário!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                formulario.dataLimite = new Date(formulario.dataLimiteComponent.year,
                    formulario.dataLimiteComponent.month - 1,
                    formulario.dataLimiteComponent.day);

                formulario.dataRevisao = new Date(formulario.dataRevisaoComponent.year,
                    formulario.dataRevisaoComponent.month - 1,
                    formulario.dataRevisaoComponent.day);

                if (formulario.responsavelId == undefined || formulario.responsavelId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Informe o responsável do formulário!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (formulario.revisorId == undefined || formulario.revisorId == null) {
                    this.swalWithBootstrapButtons.fire(
                        'Informe o revisor do formulário!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (formulario.alerta != undefined && formulario.alerta != null && formulario.alerta < 0) {
                    this.swalWithBootstrapButtons.fire(
                        'Alerta informado no formulário é inválido!',
                        '',
                        'error'
                    );

                    retorno = false;
                }

                if (!retorno) return false;

                if (formulario.dataLimiteComponent != undefined || formulario.dataLimiteComponent != null) {
                    formulario.dataLimite = new Date(formulario.dataLimiteComponent.year, formulario.dataLimiteComponent.month - 1, formulario.dataLimiteComponent.day);
                }
            });
        }

        return retorno;
    }

    editar(id: any) {
        this.editarAtividade(id, null);
    }

    editarAtividade(id: any, atividade: any) {
        this.spinner.show();

        this.toastrService.clear();

        this.processosJudiciaisOcorrenciasService.getById(id)
            .subscribe(res => {
                if (res.success) {
                    this.ocorrencia = res.data;
                    this.ocorrencia.isEdit = true;

                    if (this.ocorrencia.dataOcorrencia != undefined && this.ocorrencia.dataOcorrencia != null) {
                        let data = new Date(this.ocorrencia.dataOcorrencia);

                        this.ocorrencia.dataOcorrenciaComponent =
                        {
                            year: data.getFullYear(),
                            month: data.getMonth() + 1,
                            day: data.getDate()
                        };
                    }
                    this.carregarAtividades(atividade);

                    this.spinner.hide();
                } else {
                    this.toastrService.error('Não foi possível localizar a ocorrência!', 'Atenção', { timeOut: 10000 });
                    this.close();
                }
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
                this.close();
                this.spinner.hide();
            })
    }

    carregarEditarAtividadesPrazos(atividade: any, ocorrencia: any) {
        this.spinner.show();

        this.toastrService.clear();

        this.prazoId = atividade.id;

        this.ocorrencia = ocorrencia;

        this.ocorrencia.isEdit = true;

        this.editarAtividadeOcorrencia = true;

        console.log(atividade);

        if (this.ocorrencia.dataOcorrencia != undefined && this.ocorrencia.dataOcorrencia != null) {
            let data = new Date(this.ocorrencia.dataOcorrencia);

            this.ocorrencia.dataOcorrenciaComponent =
            {
                year: data.getFullYear(),
                month: data.getMonth() + 1,
                day: data.getDate()
            };
        }

        this.ocorrencia.audiencias = [];

        this.ocorrencia.historicosManuais = [];

        this.ocorrencia.prazos = [];

        this.ocorrencia.eventos = ocorrencia.eventos.filter(c => c.processoJudicialOcorrenciaPrazoId == this.prazoId);

        this.ocorrencia.tarefas = ocorrencia.tarefas.filter(c => c.processoJudicialOcorrenciaPrazoId == this.prazoId);

        this.carregarAtividades(null);

        this.spinner.hide();
    }

    carregarAtividades(atividade: any) {

        if (atividade == null) {
            this.carregaTarefas();
            this.carregaHistoricosManuais();
            this.carregaEventos();
            this.carregaPrazos();
            this.carregarAudiencias();
            this.carregarFormularios();
        } else {
            this.editarAtividadeOcorrencia = true;

            if (atividade.tipoOcorrencia == 1) {
                this.carregaTarefas();
                this.ocorrencia.tarefas = this.ocorrencia.tarefas.filter(c => c.id == atividade.id);
                this.ocorrencia.eventos = [];
                this.ocorrencia.prazos = [];
                this.ocorrencia.historicosManuais = [];
                this.ocorrencia.audiencias = [];
                this.ocorrencia.formularios = [];
            }

            if (atividade.tipoOcorrencia == 2) {
                this.carregaHistoricosManuais();
                this.ocorrencia.historicosManuais = this.ocorrencia.historicosManuais.filter(c => c.id == atividade.id);
                this.ocorrencia.eventos = [];
                this.ocorrencia.prazos = [];
                this.ocorrencia.tarefas = [];
                this.ocorrencia.audiencias = [];
                this.ocorrencia.formularios = [];
            }


            if (atividade.tipoOcorrencia == 3) {
                this.carregaEventos();
                this.ocorrencia.eventos = this.ocorrencia.eventos.filter(c => c.id == atividade.id);
                this.ocorrencia.tarefas = [];
                this.ocorrencia.prazos = [];
                this.ocorrencia.historicosManuais = [];
                this.ocorrencia.audiencias = [];
                this.ocorrencia.formularios = [];
            }

            if (atividade.tipoOcorrencia == 4) {
                this.carregaPrazos();
                this.ocorrencia.prazos = this.ocorrencia.prazos.filter(c => c.id == atividade.id);
                this.ocorrencia.tarefas = [];
                this.ocorrencia.eventos = [];
                this.ocorrencia.historicosManuais = [];
                this.ocorrencia.audiencias = [];
                this.ocorrencia.formularios = [];
            }

            if (atividade.tipoOcorrencia == 5) {
                this.carregarAudiencias();
                this.ocorrencia.audiencias = this.ocorrencia.audiencias.filter(c => c.id == atividade.id);
                this.ocorrencia.tarefas = [];
                this.ocorrencia.eventos = [];
                this.ocorrencia.historicosManuais = [];
                this.ocorrencia.prazos = [];
                this.ocorrencia.formularios = [];
            }

            if(atividade.tipoOcorrencia == 6) {
                this.carregarFormularios();
                this.ocorrencia.formularios = this.ocorrencia.formularios.filter(c => c.id == atividade.id);
                this.ocorrencia.tarefas = [];
                this.ocorrencia.eventos = [];
                this.ocorrencia.historicosManuais = [];
                this.ocorrencia.prazos = [];
            }
        }
    }

    carregarAudiencias() {
        if (this.ocorrencia.audiencias != undefined && this.ocorrencia.audiencias.length > 0) {

            this.ocorrencia.audiencias.forEach(audiencia => {
                if (audiencia.dataHoraInicial != undefined && audiencia.dataHoraInicial != null) {
                    let data = new Date(audiencia.dataHoraInicial);

                    audiencia.dataHoraInicialComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };

                    audiencia.dataHoraInicialTimeComponent = data.toLocaleTimeString();
                }

                if (audiencia.dataHoraFinal != undefined && audiencia.dataHoraFinal != null) {

                    let data = new Date(audiencia.dataHoraFinal);

                    audiencia.dataHoraFinalTimeComponent = data.toLocaleTimeString();
                }
            });
        }
    }

    carregaPrazos() {

        if (this.ocorrencia.prazos != undefined && this.ocorrencia.prazos.length > 0) {

            this.ocorrencia.prazos.forEach(prazo => {

                if (prazo.dataLimite != undefined && prazo.dataLimite != null) {
                    let data = new Date(prazo.dataLimite);

                    prazo.dataLimiteComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };
                }

                if (prazo.dataRevisao != undefined && prazo.dataRevisao != null) {
                    let data = new Date(prazo.dataRevisao);

                    prazo.dataRevisaoComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };
                }

            });
        }
    }

    carregaEventos() {

        if (this.ocorrencia.eventos != undefined && this.ocorrencia.eventos.length > 0) {

            this.ocorrencia.eventos.forEach(evento => {

                if (evento.dataHoraInicial != undefined && evento.dataHoraInicial != null) {
                    let data = new Date(evento.dataHoraInicial);

                    evento.dataHoraInicialComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };

                    evento.dataHoraInicialTimeComponent = data.toLocaleTimeString();
                }

                if (evento.dataHoraFinal != undefined && evento.dataHoraFinal != null) {
                    let data = new Date(evento.dataHoraFinal);

                    evento.dataHoraFinalComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };

                    evento.dataHoraFinalTimeComponent = data.toLocaleTimeString();
                }

                evento.adicionarEmails = false;

            });
        }
    }

    carregaHistoricosManuais() {
        if (this.ocorrencia.historicosManuais != undefined && this.ocorrencia.historicosManuais.length > 0) {
            this.ocorrencia.historicosManuais.forEach(historico => {
                if (historico.dataHistorico != undefined && historico.dataHistorico != null) {
                    let data = new Date(historico.dataHistorico);

                    historico.dataHistoricoComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };
                }
            });
        }
    }

    carregaTarefas() {

        if (this.ocorrencia.tarefas != undefined && this.ocorrencia.tarefas.length > 0) {

            this.ocorrencia.tarefas.forEach(tarefa => {
                if (tarefa.dataTarefa != undefined && tarefa.dataTarefa != null) {
                    let data = new Date(tarefa.dataTarefa);

                    tarefa.dataTarefaComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };
                }
            });
        }
    }

    carregarFormularios() {
        if (this.ocorrencia.formularios != undefined && this.ocorrencia.formularios.length > 0) {

            this.ocorrencia.formularios.forEach(formulario => {

                if (formulario.dataLimite != undefined && formulario.dataLimite != null) {
                    const data = new Date(formulario.dataLimite);

                    formulario.dataLimiteComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };
                }

                if (formulario.dataRevisao != undefined && formulario.dataRevisao != null) {
                    const data = new Date(formulario.dataRevisao);

                    formulario.dataRevisaoComponent =
                    {
                        year: data.getFullYear(),
                        month: data.getMonth() + 1,
                        day: data.getDate()
                    };
                }

            });
        }
    }

    handleExcluirPrazo(prazo: any) {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir o prazo?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (!result.dismiss) {
                if (!prazo.isEdit) {
                    this.ocorrencia.prazos = this.ocorrencia.prazos.filter(c => c.index != prazo.index);
                } else {
                    prazo.isDelete = true;
                    this.ocorrencia.prazos = this.ocorrencia.prazos.filter(c => c.id != prazo.id);
                    this.prazosDelete.push(prazo);
                }
            }
        });
    }

    handleExcluirTarefa(tarefa: any) {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir a tarefa?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (!result.dismiss) {
                if (!tarefa.isEdit) {
                    this.ocorrencia.tarefas = this.ocorrencia.tarefas.filter(c => c.index != tarefa.index);
                } else {
                    tarefa.isDelete = true;
                    this.ocorrencia.tarefas = this.ocorrencia.tarefas.filter(c => c.id != tarefa.id);
                    this.tarefasDelete.push(tarefa);
                }
            }
        });
    }

    handleExcluirEvento(evento: any) {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir o evento?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (!result.dismiss) {
                if (!evento.isEdit) {
                    this.ocorrencia.eventos = this.ocorrencia.eventos.filter(c => c.index != evento.index);
                } else {
                    evento.isDelete = true;
                    this.ocorrencia.eventos = this.ocorrencia.eventos.filter(c => c.id != evento.id);
                    this.eventosDelete.push(evento);
                }
            }
        });
    }

    handleExcluirHistoricoManual(historicoManual: any) {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir o histórico?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (!result.dismiss) {
                if (!historicoManual.isEdit) {
                    this.ocorrencia.historicosManuais = this.ocorrencia.historicosManuais.filter(c => c.index != historicoManual.index);
                } else {
                    historicoManual.isDelete = true;
                    this.ocorrencia.historicosManuais = this.ocorrencia.historicosManuais.filter(c => c.id != historicoManual.id);
                    this.historicosManuaisDelete.push(historicoManual);
                }
            }
        });
    }

    handleExcluirAudiencia(audiencia: any) {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir o histórico?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (!result.dismiss) {
                if (!audiencia.isEdit) {
                    this.ocorrencia.audiencias = this.ocorrencia.audiencias.filter(c => c.index != audiencia.index);
                } else {
                    audiencia.isDelete = true;
                    this.ocorrencia.audiencias = this.ocorrencia.audiencias.filter(c => c.id != audiencia.id);
                    this.audienciasDelete.push(audiencia);
                }
            }
        });
    }

    handleExcluirFormulario(formulario: any) {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir o formulário?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (!result.dismiss) {
                if (!formulario.isEdit) {
                    this.ocorrencia.formularios = this.ocorrencia.formularios.filter(c => c.index != formulario.index);
                } else {
                    formulario.isDelete = true;
                    this.ocorrencia.formularios = this.ocorrencia.formularios.filter(c => c.id != formulario.id);
                    this.formulariosDelete.push(formulario);
                }
            }
        });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    toggleNotificarCliente(notificarCliente: MatSlideToggleChange): void {

        if (!notificarCliente.checked) {
            this.contatos = [];
            return;
        }

        this.obterContatosParaNotificacao();
    }

    obterContatosParaNotificacao(): void {
        this.spinner.show();

        this.processosJudiciaisOcorrenciasService.ObterContatosClientePrincipalPorOcorrenciaId(this.recursoDesdobramento.id)
            .subscribe(res => {

                this.contatos.push({
                    selecionado: true,
                    nome: res.data.nome,
                    email: res.data.email,
                });

                const emailsValidos = res.data.emails.filter(email => email[0].chave === 'telefone-email' && email[0].valor?.length > 0);

                if (emailsValidos?.length > 0) {
                    emailsValidos.forEach(email => {
                        this.contatos.push({
                            selecionado: true,
                            nome: res.data.nome,
                            email: email[0].valor,
                        });
                    });
                }
                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    error.errors.forEach(element => {
                        this.toastrService.error(element, 'Atenção', { timeOut: 10000 });
                    });
                } else {
                    this.toastrService.error('Não foi possível obter contatos do cliente principal!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    selecionarTodosContatos(selecionado: boolean): void {
        this.contatos.forEach(contato => {
            contato.selecionado = selecionado;
        });
    }

    validarNotificacaoCliente(): boolean {
        const emailsParaNotificar = this.contatos.filter(contato => contato.selecionado === true).map(contato => contato.email);

        if (this.notificarCliente && emailsParaNotificar?.length === 0) {
            this.swalWithBootstrapButtons.fire(
                'Por favor, selecione um contato para notificar o cliente!',
                '',
                'warning'
            );
            return false;
        }

        this.ocorrencia.emailsParaNotificar = emailsParaNotificar;

        return true;
    }
}
