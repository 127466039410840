import { ModalModelosConfissaoComponent } from './../../views/operacionais/components/modal-modelos-confissao/modal-modelos-confissao.component';
import { ModalListaProcessosPessoaComponent } from './../../views/operacionais/operacao-de-processos/modal-lista-processos/modal-lista-processos-pessoa/modal-lista-processos-pessoa.component';
import { ModalHistoricoRedirecionamentoComponent } from './../../views/operacionais/operacao-de-processos/modal-historico-redirecionamento/modal-historico-redirecionamento.component';
import { ModalNotificacoesComponent } from './../../views/operacionais/components/modal-notificacoes/modal-notificacoes.component';
import { Atividade } from './../../models/operacionais/atividade/atividade';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { ResultadoModal } from './resultado-modal';
import { ModalCadastroUsuarioComponent } from 'src/app/views/administracao/usuario/modal-cadastro/modal-cadastro-usuario.component';
import { ModalAtualizaUsuarioComponent } from 'src/app/views/administracao/usuario/modal-atualiza/modal-atualiza-usuario.component';
import { ModalCadastroPessoaComponent } from 'src/app/views/administracao/pessoa/modal-cadastro/modal-cadastro-pessoa.component';
import { ModalContatoProcessosAmigaveisComponent } from 'src/app/views/operacionais/processos-amigaveis/modal-contato-processos-amigaveis/modal-contato-processos-amigaveis.component';
import { ModalDetalhesContatoProcessosAmigaveisComponent } from 'src/app/views/operacionais/processos-amigaveis/modal-detalhes-contato-processos-amigaveis/modal-detalhes-contato-processos-amigaveis.component';
import { ModalFiltroProcessosOrfaosComponent } from 'src/app/views/operacionais/processos-orfaos/modal-filtro-processos-orfaos/modal-filtro-processos-orfaos.component';
import { ModalFiltroCobradoresComponent } from 'src/app/views/operacionais/processos-orfaos/modal-filtro-cobradores/modal-filtro-cobradores.component';
import { ModalFiltroProcessosComponent } from 'src/app/views/operacionais/gestao-processos/modal-filtro-processos/modal-filtro-processos.component';
import { ModalTagProcessoComponent } from 'src/app/views/operacionais/gestao-processos/modal-criar-tag-processo/modal-tag-processo.component';
import { ModalContatoProcessoCobradorComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-contato-processo-cobrador/modal-contato-processo-cobrador.component';
import { ModalAdicionarOcorrenciaComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-adicionar-nova-ocorrencia/modal-adicionar-nova-ocorrencia.component';
import { ModalCalendarioCobrador } from 'src/app/layout/layout-navbar/modal-calendario-cobrador/modal-calendario-cobrador.component';
import { ModalCadastroComarcaComponent } from 'src/app/views/cadastros/comarcas/modal-cadastro/modal-cadastro-comarca/modal-cadastro-comarca.component';
import { ModalAtualizaComarcaComponent } from 'src/app/views/cadastros/comarcas/modal-atualiza/modal-atualiza-comarca/modal-atualiza-comarca.component';
import { ModalInfoComarcaComponent } from 'src/app/views/cadastros/comarcas/modal-info/modal-info-comarca/modal-info-comarca.component';
import { ModalAtualizaVarasComponent } from 'src/app/views/cadastros/varas/modal-atualiza/modal-atualiza-varas/modal-atualiza-varas.component';
import { ModalCadastroVarasComponent } from 'src/app/views/cadastros/varas/modal-cadastro/modal-cadastro-varas/modal-cadastro-varas.component';
import { ModalCadastroTipoAndamentoComponent } from 'src/app/views/cadastros/tipo-de-andamento/modal-cadastro/modal-cadastro-tipo-andamento/modal-cadastro-tipo-andamento.component';
import { ModalAtualizaTipoAndamentoComponent } from 'src/app/views/cadastros/tipo-de-andamento/modal-atualiza/modal-atualiza-tipo-andamento/modal-atualiza-tipo-andamento.component';
import { ModalInfoTipoAndamentoComponent } from 'src/app/views/cadastros/tipo-de-andamento/modal-info/modal-info-tipo-andamento/modal-info-tipo-andamento.component';
import { ModalHistoricoImportacaoComponent } from 'src/app/views/operacionais/importacao-bordero/modal-historico-importacao/modal-historico-importacao.component';
import { ModalHistoricoImportacaoLinhaComponent } from 'src/app/views/operacionais/importacao-bordero/modal-historico-importacao-linha/modal-historico-importacao-linha.component';
import { Bordero } from 'src/app/models/operacionais/importacao-bordero/bordero';
import { ModalCadastroSituacaoProcessoComponent } from 'src/app/views/cadastros/situacoes-dos-processos/modal-cadastro/modal-cadastro-situacao-processo/modal-cadastro-situacao-processo.component';
import { ModalAtualizaSituacaoProcessoComponent } from 'src/app/views/cadastros/situacoes-dos-processos/modal-atualiza/modal-atualiza-situacao-processo/modal-atualiza-situacao-processo.component';
import { ModalInfoSituacaoProcessoComponent } from 'src/app/views/cadastros/situacoes-dos-processos/modal-info/modal-info-situacao-processo/modal-info-situacao-processo.component';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { ModalCadastroDocumentosComponent } from 'src/app/views/cadastros/documentos/modal-cadastro/modal-cadastro-documentos/modal-cadastro-documentos.component';
import { ModalAtualizaDocumentosComponent } from 'src/app/views/cadastros/documentos/modal-atualiza/modal-atualiza-documentos/modal-atualiza-documentos.component';
import { ModalCadastroTagsComponent } from 'src/app/views/cadastros/tags/modal-cadastro/modal-cadastro-tags/modal-cadastro-tags.component';
import { ModalAdicionarAtividadeComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-adicionar-atividade/modal-adicionar-atividade.component';
import { ModalAdicionarDocumentoComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-adicionar-documento/modal-adicionar-documento.component';

import { ModalCadastroPerfilComponent } from 'src/app/views/administracao/perfil/modal-cadastro/modal-cadastro-perfil.component';
import { ModalAtualizaPerfilComponent } from 'src/app/views/administracao/perfil/modal-atualiza/modal-atualiza-perfil.component';

import { Ocorrencia } from 'src/app/models/operacionais/ocorrencia/ocorrencia';
import { ModalOcorrenciaDetalheComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-ocorrencia-detalhe/modal-ocorrencia-detalhe.component';
import { ModalTrocarCobradorComponent } from 'src/app/views/operacionais/gestao-processos/modal-trocar-cobrador/modal-trocar-cobrador.component';

import { ModalAdicionarDividaPagamentosComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-adicionar-divida-pagamentos/modal-adicionar-divida-pagamentos.component';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { ModalAprovarDividaPagamentoComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-aprovar-divida-pagamento/modal-aprovar-divida-pagamento.component';
import { ModalAdicionarDividaDescontoComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-adicionar-divida-desconto/modal-adicionar-divida-desconto.component';
import { ModalAdicionarDividaTaxaAdministrativaComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-adicionar-divida-taxa-administrativa/modal-adicionar-divida-taxa-administativa.component';
import { options } from 'numeral';
import { ModalAdicionarFaturamentoComponent } from 'src/app/views/operacionais/faturamentos/modal-adicionar-faturamento/modal-adicionar-faturamento.component';
import { Guid } from 'guid-typescript';
import { ModalAprovarDividaDescontoComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-aprovar-divida-desconto/modal-aprovar-divida-desconto.component';
import { ModalEmailCredorComponent } from '../../views/operacionais/operacao-de-processos/modal-email-credor/modal-email-credor.component';
import { ModalPddComponent } from 'src/app/views/operacionais/components/modal-pdd/modal-pdd.component';
import { ModalTermoParcelamentoComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-termo-parcelamento/modal-termo-parcelamento.component';
import { ModalDemonstrativoDebitoComponent } from 'src/app/views/operacionais/operacao-de-processos/modal-demonstrativos-debito/modal-demonstrativo-debito.component';
import { ModalDetalhamentoPagamentoFaturamentoExtratoComponent } from 'src/app/views/operacionais/faturamento-extrato/modal-detalhamento-pagamento/modal-detalhamento-pagamento-faturamento-extrato.component';
import { ModalDetalhamentoNfComponent } from 'src/app/views/operacionais/faturamento-extrato/modal-detalhamento-nf/modal-detalhamento-nf.component';
import { ModalDetalhamentoExtratoCredorPorPeriodoComponent } from './../../views/operacionais/faturamento-extrato/modal-detalhamento-extrato-credor-por-periodo/modal-detalhamento-extrato-credor-por-periodo.component';
import { ModalRepasseComponent } from 'src/app/views/operacionais/components/modal-repasse/modal-repasse.component';
import { ModalAtualizaForosComponent } from 'src/app/views/cadastros/foros/modal-atualiza/modal-atualiza-foros/modal-atualiza-foros.component';
import { ModalCadastroForosComponent } from 'src/app/views/cadastros/foros/modal-cadastro/modal-cadastro-foros/modal-cadastro-foros.component';
import { ModalAtualizaOrgaosComponent } from 'src/app/views/cadastros/orgaos/modal-atualiza/modal-atualiza-orgaos/modal-atualiza-orgaos.component';
import { ModalCadastroOrgaosComponent } from 'src/app/views/cadastros/orgaos/modal-cadastro/modal-cadastro-orgaos/modal-cadastro-orgaos.component';
import { ModalAtualizaPedidosComponent } from 'src/app/views/cadastros/pedidos/modal-atualiza/modal-atualiza-pedidos/modal-atualiza-pedidos.component';
import { ModalCadastroPedidosComponent } from 'src/app/views/cadastros/pedidos/modal-cadastro/modal-cadastro-pedidos/modal-cadastro-pedidos.component';
import { ModalAtualizaTipoTarefaComponent } from 'src/app/views/cadastros/tipo-de-tarefa/modal-atualiza/modal-atualiza-tipo-de-tarefa/modal-atualiza-tipo-tarefa.component';
import { ModalCadastroTipoTarefaComponent } from 'src/app/views/cadastros/tipo-de-tarefa/modal-cadastro/modal-cadastro-tipo-de-tarefa/modal-cadastro-tipo-tarefa.component';
import { ModalAtualizaMunicipiosComponent } from 'src/app/views/cadastros/municipios/modal-atualiza/modal-atualiza-municipios/modal-atualiza-municipios.component';
import { ModalCadastroMunicipiosComponent } from 'src/app/views/cadastros/municipios/modal-cadastro/modal-cadastro-municipios/modal-cadastro-municipios.component';
import { ModalCadastroBancosComponent } from 'src/app/views/financeiro/bancos/modal-cadastro/modal-cadastro-bancos/modal-cadastro-bancos.component';
import { ModalAtualizaBancosComponent } from 'src/app/views/financeiro/bancos/modal-atualiza/modal-atualiza-bancos/modal-atualiza-bancos.component';
import { ModalCadastroCentroCustosComponent } from 'src/app/views/financeiro/centro-custos/modal-cadastro/modal-cadastro-centro-custos/modal-cadastro-centro-custos.component';
import { ModalAtualizaCentroCustosComponent } from 'src/app/views/financeiro/centro-custos/modal-atualiza/modal-atualiza-centro-custos/modal-atualiza-centro-custos.component';
import { ModalCadastroTipoDocumentosComponent } from 'src/app/views/financeiro/tipo-documentos/modal-cadastro/modal-cadastro-tipo-documentos/modal-cadastro-tipo-documentos.component';
import { ModalAtualizaTipoDocumentosComponent } from 'src/app/views/financeiro/tipo-documentos/modal-atualiza/modal-atualiza-tipo-documentos/modal-atualiza-tipo-documentos.component';
import { ModalCadastroContasComponent } from 'src/app/views/financeiro/contas/modal-cadastro/modal-cadastro-contas/modal-cadastro-contas.component';
import { ModalAtualizaContasComponent } from 'src/app/views/financeiro/contas/modal-atualiza/modal-atualiza-contas/modal-atualiza-contas.component';
import { ModalAtualizaInstanciasComponent } from 'src/app/views/cadastros/instancias/modal-atualiza/modal-atualiza-instancias/modal-atualiza-instancias.component';
import { ModalCadastroInstanciasComponent } from 'src/app/views/cadastros/instancias/modal-cadastro/modal-cadastro-instancias/modal-cadastro-instancias.component';
import { ModalTarefaComponent } from 'src/app/views/dashboard/area-trabalho/modal-tarefa/modal-tarefa.component';
import { ModalTarefaRevisorComponent } from 'src/app/views/dashboard/area-trabalho-revisor/modal-tarefa-revisor/modal-tarefa-revisor.component';
import { LayoutModalAutenticatorComponent } from 'src/app/layout/layout-modal-autenticator/layout-modal-autenticator.component';

@Injectable()
export class ModalService {
    resultConfirmDialog$ = new Subject<ResultadoModal>();
    resultConfirmDialogIndividual$ = new Subject<ResultadoModal>();

    constructor(
        private ngbModal: NgbModal
    ) { }

    public abrirModalAdicionarDividaTaxaAdministrativa(processo: ProcessoOperacao, valorTaxaAdministrativa: number) {
        const modalRef = this.ngbModal.open(ModalAdicionarDividaTaxaAdministrativaComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AdicionarDividaTaxaAdministrativa';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.valorComprovante = valorTaxaAdministrativa;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAdicionarDividaPagamento(processo: ProcessoOperacao, valorComprovante: number, dividas: ProcessoDivida[]) {
        const modalRef = this.ngbModal.open(ModalAdicionarDividaPagamentosComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AdicionarDividaPagamento';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.dividas = dividas;
        modalRef.componentInstance.valorComprovante = valorComprovante;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }


    public abrirModalAdicionarDividaDesconto(processo: ProcessoOperacao, valorComprovante: number, dividas: ProcessoDivida[]) {
        const modalRef = this.ngbModal.open(ModalAdicionarDividaDescontoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AdicionarDividaDesconto';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.dividas = dividas;
        modalRef.componentInstance.valorComprovante = valorComprovante;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraUsuario(pessoasCadastradas?: any, perfis?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroUsuarioComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'usuarios';
        modalRef.componentInstance.pessoasCadastradas = pessoasCadastradas;
        modalRef.componentInstance.perfis = perfis;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaUsuario(usuario?: any, pessoasCadastradas?: any, perfis?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaUsuarioComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
        debugger;
        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'usuarios';
        modalRef.componentInstance.usuario = usuario;
        modalRef.componentInstance.pessoasCadastradas = pessoasCadastradas;
        modalRef.componentInstance.perfis = perfis;


        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraPessoa(pessoa?: any,updateData?:boolean, disableSave?:boolean, updateFaturamentoJuridico?:boolean) {
        const modalRef = this.ngbModal.open(ModalCadastroPessoaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'pessoa';
        //modalRef.componentInstance.camposComplementares = camposComplementares;
        modalRef.componentInstance.pessoa = pessoa;
        modalRef.componentInstance.updateData = updateData;
        modalRef.componentInstance.updateFaturamentoJuridico = updateFaturamentoJuridico != undefined?updateFaturamentoJuridico : false;

        if(disableSave != undefined && disableSave != null && disableSave){
            modalRef.componentInstance.isDisableSave = true;
        }

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraPessoaContato(pessoa?: any,updateData?:boolean) {
        const modalRef = this.ngbModal.open(ModalCadastroPessoaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'pessoa';
        //modalRef.componentInstance.camposComplementares = camposComplementares;
        modalRef.componentInstance.pessoa = pessoa;
        modalRef.componentInstance.updateData = updateData;
        modalRef.componentInstance.updateDataContato = true;

        this.resultConfirmDialogIndividual$ = new Subject<ResultadoModal>();
        modalRef.result.then(result => {
            this.resultConfirmDialogIndividual$.next(result);
        }).catch(error => { });

        return this.resultConfirmDialogIndividual$;
    }


    public abrirModalProcessoAmigavelContato(processoAmigavel?: any) {
        const modalRef = this.ngbModal.open(ModalContatoProcessosAmigaveisComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'processo amigável';
        modalRef.componentInstance.processoAmigavel = processoAmigavel;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });

    }

    public abrirModalProcessoAmigavelDetalheContato(processo?: any, detalheContato?: any) {
        const modalRef = this.ngbModal.open(ModalDetalhesContatoProcessosAmigaveisComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'detalhe contato processo amigavel';
        modalRef.componentInstance.detalheContato = detalheContato;
        modalRef.componentInstance.processo = processo;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });

    }

    public abrirModalFiltrarProcessosOrfaos(processosOrfaos?: any) {
        const modalRef = this.ngbModal.open(ModalFiltroProcessosOrfaosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'processosOrfaos';
        modalRef.componentInstance.processosOrfaos = processosOrfaos;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });

    }

    public abrirModalFiltrarProcessos(processos?: any) {
        const modalRef = this.ngbModal.open(ModalFiltroProcessosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'processos';
        modalRef.componentInstance.processos = processos;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });

    }

    public abrirModalFiltrarCobradores(cobradores?: any) {
        const modalRef = this.ngbModal.open(ModalFiltroCobradoresComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'cobradores';
        modalRef.componentInstance.cobradores = cobradores;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });

    }

    public abrirModalTagProcessos(processo?: any) {
        const modalRef = this.ngbModal.open(ModalTagProcessoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tags';
        modalRef.componentInstance.processo = processo;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });

    }

    public abrirModalContatoProcesso(processo: ProcessoOperacao, options?: {readonly?: Boolean, previousTab?: String}) {
        const modalRef = this.ngbModal.open(ModalContatoProcessoCobradorComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        options = this.SetDefaultOptions(options);

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'ProcessoContatoCobrador';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.options = options;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAdicionarFaturamento(faturamento?: any,credorPreselecionado?:any) {
        const modalRef = this.ngbModal.open(ModalAdicionarFaturamentoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.faturamento = faturamento;
        modalRef.componentInstance.page = 'AdicionarNovoFaturamento';
        modalRef.componentInstance.credorPreselecionado = credorPreselecionado;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    /*
    public abrirModalAdicionarOcorrencia(processo: ProcessoOperacao) {
        const modalRef = this.ngbModal.open(ModalAdicionarOcorrenciaComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AdicionarNovaOcorrencia';
        modalRef.componentInstance.processo = processo;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
*/
    public abrirModalAdicionarOcorrencia(processo: ProcessoOperacao, ocorrencia: Ocorrencia, options?: {readonly?: Boolean, previousTab?: String}) {
        const modalRef = this.ngbModal.open(ModalAdicionarOcorrenciaComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        options = this.SetDefaultOptions(options);

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AdicionarNovaOcorrencia';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.updateData = ocorrencia
        modalRef.componentInstance.options = options;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalDetalheOcorrencia(processo: ProcessoOperacao, ocorrencia: Ocorrencia, options?: {readonly?: Boolean, previousTab?: String}) {
        const modalRef = this.ngbModal.open(ModalOcorrenciaDetalheComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'DetalheOcorrencia';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.updateData = ocorrencia;
        modalRef.componentInstance.options = options;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAdicionarAtividade(processo: ProcessoOperacao, atividade: Atividade, options?: {readonly?: Boolean, previousTab?: String}) {
        const modalRef = this.ngbModal.open(ModalAdicionarAtividadeComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        options = this.SetDefaultOptions(options);

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AdicionarNovaAtividade';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.updateData = atividade
        modalRef.componentInstance.options = options;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirCalendarioCobrador() {
        const modalRef = this.ngbModal.open(ModalCalendarioCobrador
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'ProcessoContatoCobrador';

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }


    public abrirModalCadastraComarca(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroComarcaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'comarcas';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalAtualizaComarca(comarca?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaComarcaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'comarcas';
        modalRef.componentInstance.comarca = comarca;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalInfoComarca(comarca?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalInfoComarcaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'comarcas';
        modalRef.componentInstance.comarca = comarca;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraVara(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroVarasComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'varas';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalAtualizaVara(vara?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaVarasComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'varas';
        modalRef.componentInstance.vara = vara;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }


    public abrirModalCadastraTipoAndamento(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroTipoAndamentoComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tipos de andamentos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalAtualizaTipoAndamento(tipoAndamento?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaTipoAndamentoComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tipos de andamentos';
        modalRef.componentInstance.tipoAndamento = tipoAndamento;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalInfoTipoAndamento(tipoAndamento?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalInfoTipoAndamentoComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tipos de andamentos';
        modalRef.componentInstance.tipoAndamento = tipoAndamento;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraSituacaoProcesso(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroSituacaoProcessoComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'situacoes dos processos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalAtualizaSituacaoProcesso(situacaoProcesso?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaSituacaoProcessoComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'situacoes dos processos';
        modalRef.componentInstance.situacaoProcesso = situacaoProcesso;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalInfoSituacaoProcesso(situacaoProcesso?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalInfoSituacaoProcessoComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'situacoes dos processos';
        modalRef.componentInstance.situacaoProcesso = situacaoProcesso;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalHistoricoImportacao() {
        const modalRef = this.ngbModal.open(ModalHistoricoImportacaoComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'ImportacaoBordero';


        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });

    }

    public abrirModalHistoricoImportacaoLinha(bordero: Bordero) {
        const modalRef = this.ngbModal.open(ModalHistoricoImportacaoLinhaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'ImportacaoBorderoLinha';
        modalRef.componentInstance.bordero = bordero;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }



    public abrirModalCadastraDocumento(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroDocumentosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'documentos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalAtualizaDocumento(documento?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaDocumentosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'documentos';
        modalRef.componentInstance.documento = documento;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalProcessoAdicionarDocumento(processo: ProcessoOperacao) {
        const modalRef = this.ngbModal.open(ModalAdicionarDocumentoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AdicionarNovaDocumento';
        modalRef.componentInstance.processo = processo;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }
    public abrirModalTarefa(tarefa: any) {
      const modalRef = this.ngbModal.open(ModalTarefaComponent
          , {
             // size: 'lg',
              backdrop: 'static',
              keyboard: false
          });

      modalRef.componentInstance.modalRef = modalRef;
      modalRef.componentInstance.page = 'Tarefa';
      modalRef.componentInstance.tarefa = tarefa;

      modalRef.result.then(result => {
          this.resultConfirmDialog$.next(result);
      }).catch(error => { });
  }

  public abrirModalTarefaRevisor(tarefa: any) {
    const modalRef = this.ngbModal.open(ModalTarefaRevisorComponent
        , {
           // size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'Tarefa';
    modalRef.componentInstance.tarefa = tarefa;

    modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
    }).catch(error => { });
}



    public abrirModalCadastraPerfil(modulos) {
        const modalRef = this.ngbModal.open(ModalCadastroPerfilComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'perfil';
        modalRef.componentInstance.modulos = modulos;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaPerfil(perfil?: any, modulos?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaPerfilComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'perfil';
        modalRef.componentInstance.perfil = perfil;
        modalRef.componentInstance.modelSelected = perfil.modulos;
        modalRef.componentInstance.modulos = modulos;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAprovarDividaDesconto(processo: ProcessoOperacao, ocorrencia: Ocorrencia) {
        const modalRef = this.ngbModal.open(ModalAprovarDividaDescontoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AprovarDividaDesconto';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.ocorrencia = ocorrencia;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAprovarDividaPagamento(processo: ProcessoOperacao, ocorrencia: Ocorrencia) {
        const modalRef = this.ngbModal.open(ModalAprovarDividaPagamentoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AprovarDividaPagamento';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.ocorrencia = ocorrencia;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalTrocarCobrador(processosFiltrados) {
        const modalRef = this.ngbModal.open(ModalTrocarCobradorComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'trocarCobrador';
        modalRef.componentInstance.processosFiltrados = processosFiltrados;
        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalEmailCredor(credorId,description:Boolean = false) {
        const modalRef = this.ngbModal.open(ModalEmailCredorComponent, {
            // size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
        debugger;
        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'emailCredor';
        modalRef.componentInstance.credorId = credorId;
        modalRef.componentInstance.description = description;

        this.resultConfirmDialogIndividual$ = new Subject<ResultadoModal>();
        modalRef.result.then(result => {
            this.resultConfirmDialogIndividual$.next(result);
        }).catch(error => { });

        return this.resultConfirmDialogIndividual$;
    }


    public abrirModalNotificacoes(processoId) {
      const modalRef = this.ngbModal.open(ModalNotificacoesComponent, {
          // size: 'lg',
          backdrop: 'static',
          keyboard: false
      });

      modalRef.componentInstance.modalRef = modalRef;
      modalRef.componentInstance.page = 'notificacoes';
      modalRef.componentInstance.processoId = processoId;

      modalRef.result.then(result => {
          this.resultConfirmDialog$.next(result);
      }).catch(error => { });
  }

  public abrirModalPdd(processoId) {
    const modalRef = this.ngbModal.open(ModalPddComponent, {
        // size: 'lg',
        backdrop: 'static',
        keyboard: false
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'pdd';
    modalRef.componentInstance.processoId = processoId;

    modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
    }).catch(error => { });
}

  public abrirModalModelosConfissao(processoId) {
    const modalRef = this.ngbModal.open(ModalModelosConfissaoComponent, {
        // size: 'lg',
        backdrop: 'static',
        keyboard: false
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'modelos-confissao';
    modalRef.componentInstance.processoId = processoId;

    modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
    }).catch(error => { });
  }

public abrirModalModelo(processoId) {
    const modalRef = this.ngbModal.open(ModalRepasseComponent, {
        // size: 'lg',
        backdrop: 'static',
        keyboard: false
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'modelo';
    modalRef.componentInstance.processoId = processoId;

    modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
    }).catch(error => { });
}


    public abrirModalHistoricoRedirecionamento(processoId) {
      const modalRef = this.ngbModal.open(ModalHistoricoRedirecionamentoComponent, {
          // size: 'lg',
          backdrop: 'static',
          keyboard: false
      });

      modalRef.componentInstance.modalRef = modalRef;
      modalRef.componentInstance.page = 'historicoRedirecionamento';
      modalRef.componentInstance.processoId = processoId;

      modalRef.result.then(result => {
          this.resultConfirmDialog$.next(result);
      }).catch(error => { });
  }

  public abrirModalListaProcessosPessoa(pessoaId,readonly) {
    const modalRef = this.ngbModal.open(ModalListaProcessosPessoaComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.pessoaId = pessoaId;
    modalRef.componentInstance.readonly = readonly;

    modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
    }).catch(error => { });
  }

  public abrirModalTermoParcelamento(processoId: Guid) {
    const modalRef = this.ngbModal.open(ModalTermoParcelamentoComponent
        , {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'TermoParcelamento';
    modalRef.componentInstance.processoId = processoId;

    modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
    }).catch(error => { });
}

public abrirModalDemonstrativoDebito(processoId: Guid) {
    const modalRef = this.ngbModal.open(ModalDemonstrativoDebitoComponent
        , {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'DemonstrativoDebito';
    modalRef.componentInstance.processoId = processoId;

    modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
    }).catch(error => { });
}



    public abrirModalDetalhamentoPagamentoFaturamentoExtrato(date:Date,credorId,tipo) {
      const modalRef = this.ngbModal.open(ModalDetalhamentoPagamentoFaturamentoExtratoComponent, {
          size: 'lg',
          backdrop: 'static',
          keyboard: false
      });

      modalRef.componentInstance.modalRef = modalRef;
      modalRef.componentInstance.page = 'detalhamentoPagamento';
      modalRef.componentInstance.tipo = tipo;
      modalRef.componentInstance.credorId = credorId;
      modalRef.componentInstance.date = date;

      modalRef.result.then(result => {
        this.resultConfirmDialog$.next(result);
      }).catch(error => { });

    }

    public abrirModalDetalhamentoPagamentoFaturamentoExtratoNF(date:Date,credorId) {
    const modalRef = this.ngbModal.open(ModalDetalhamentoNfComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'detalhamentoPagamento';
    modalRef.componentInstance.credorId = credorId;
    modalRef.componentInstance.date = date;

    modalRef.result.then(result => {
      this.resultConfirmDialog$.next(result);
    }).catch(error => { });

    }

    public abrirModalDetalhamentoExtratoCredorPorPeriodo(credorId) {
    const modalRef = this.ngbModal.open(ModalDetalhamentoExtratoCredorPorPeriodoComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.page = 'detalhamentoExtratoCredorPorPeriodo';
    modalRef.componentInstance.credorId = credorId;

    modalRef.result.then(result => {
      this.resultConfirmDialog$.next(result);
    }).catch(error => { });

    }

    public abrirModalCadastraForo(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroForosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'foros';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaForo(foro?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaForosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'foros';
        modalRef.componentInstance.foro = foro;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraOrgao(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroOrgaosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'orgaos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaOrgao(orgao?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaOrgaosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'orgaos';
        modalRef.componentInstance.orgao = orgao;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraPedido(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroPedidosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'pedidos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaPedido(pedido?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaPedidosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'pedidos';
        modalRef.componentInstance.pedido = pedido;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraTipoTarefa(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroTipoTarefaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tipo-de-tarefa';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaTipoTarefa(tipoTarefa?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaTipoTarefaComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tipo-de-tarefa';
        modalRef.componentInstance.tipoTarefa = tipoTarefa;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraMunicipio(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroMunicipiosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'municipios';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaMunicipio(municipio?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaMunicipiosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'municipios';
        modalRef.componentInstance.municipio = municipio;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraBanco(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroBancosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'bancos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaBanco(banco?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaBancosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'bancos';
        modalRef.componentInstance.banco = banco;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraCentroCusto(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroCentroCustosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'centro-de-custos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaCentroCusto(centroCusto?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaCentroCustosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'centro-de-custos';
        modalRef.componentInstance.centroCusto = centroCusto;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraTipoDocumento(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroTipoDocumentosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tipos-de-documentos';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaTipoDocumento(tipoDocumento?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaTipoDocumentosComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'tipos-de-documentos';
        modalRef.componentInstance.tipoDocumento = tipoDocumento;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraConta(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroContasComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'contas';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaConta(conta?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaContasComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'contas';
        modalRef.componentInstance.conta = conta;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalCadastraInstancia(camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalCadastroInstanciasComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'instancias';
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirModalAtualizaInstancia(instancia?: any, camposComplementares?: any) {
        const modalRef = this.ngbModal.open(ModalAtualizaInstanciasComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'instancias';
        modalRef.componentInstance.instancia = instancia;
        modalRef.componentInstance.camposComplementares = camposComplementares;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    public abrirAutenticador(){
        const modalRef = this.ngbModal.open(LayoutModalAutenticatorComponent, {
            size: 'md',
            backdrop: 'static',
            keyboard: false
        });
        
        modalRef.componentInstance.modalRef = modalRef;
    }

    private SetDefaultOptions(options) {
        if (!options) {
            options = {
                readonly: false
            }
        }

        if (options.readonly == null) {
            options.readonly = false;
        }

        return options;
    }
}
