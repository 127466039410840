<div class='demo-app'>
  <div class="row">
    <div class="col-12">
      <h1 class="title1">Agenda</h1>
    </div>
    <div class="col-12">
      <app-header-filter
        [data]="dataHeaderFilter"
        [barChartData]="barChartData"
        [barChartLabels]="barChartLabels"
        [barChartOptions]="barChartOptions"
        [barChartPlugins]="barChartPlugins"
        [barChartLegend]="barChartLegend"
        [barChartType]="barChartType"
        barWidth="190px"
        barHeight="70px"
        [isAreaTrabalho]="true">
      </app-header-filter>
    </div>
    <div class="col-12 mt-3">
      <app-header-filter
        [data]="dataHeaderFilter2"
        [hasChart]="false"
        classe="justify-content-around"
        [isAreaTrabalho]="true">

      </app-header-filter>

    </div>
  </div>
  <div class="row mt-3">
    <div class="col-10 input-group box-busca">
      <button class="btn btn-outline-secondary btn-flag" type="button" (click)="exibirFiltro = !exibirFiltro" style="height: 2.86em">
          <i class="fas fa-caret-down"></i>
      </button>
      <input type="text" class="form-control" placeholder="Digite o filtro" style="border-color: #8897AA"
          (input)="onSearchChange($event.target.value)">
      <div ngbDropdown>
          <button class="btn btn-outline-secondary dropdown-toggle btn-tipo-vinculo" type="button"
              id="menu-filtro" ngbDropdownToggle>
              Tipo de vínculo na atividade
              <i class="fas fa-caret-down"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="menu-filtro">
              <div class="dropdown-item cursor-pointer" (click)="filtrarPorTipoVinculo('executor', 'Responsavel')">
                <i [ngClass]="gerenciarSelecaoFiltroTipoVinculo('executor')"></i>
                  Executor
              </div>
              <div class="dropdown-item cursor-pointer" (click)="filtrarPorTipoVinculo('revisor', 'Revisor')">
                <i [ngClass]="gerenciarSelecaoFiltroTipoVinculo('revisor')"></i>
                  Revisor
              </div>
              <div class="dropdown-item cursor-pointer" (click)="filtrarPorTipoVinculo('assistente', 'Assistente')">
                <i [ngClass]="gerenciarSelecaoFiltroTipoVinculo('assistente')"></i>
                  Assistente
              </div>
          </div>
      </div>
      <div ngbDropdown>
          <button class="btn btn-outline-secondary dropdown-toggle btn-tipo" type="button"
              id="menu-filtro-tipo" ngbDropdownToggle>
              Tipo
              <i class="fas fa-caret-down"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="menu-filtro-tipo">
              <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(1, 'Tarefa')">
                  <i [ngClass]="gerenciarSelecaoFiltroTipo(1)"></i>
                  Tarefa
              </a>
              <!-- <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(2)">
                  <i [ngClass]="gerenciarSelecaoFiltroTipo(2)"></i>
                  Histórico Manual
              </a> -->
              <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(3, 'Evento')">
                  <i [ngClass]="gerenciarSelecaoFiltroTipo(3)"></i>
                  Evento
              </a>
              <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(4, 'Prazo')">
                  <i [ngClass]="gerenciarSelecaoFiltroTipo(4)"></i>
                  Prazo
              </a>
              <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPorTipo(5, 'Audiência')">
                  <i [ngClass]="gerenciarSelecaoFiltroTipo(5)"></i>
                  Audiência
              </a>
          </div>
      </div>
    </div>
    <div class="col-6 col-sm-6 col-md-2 d-flex">
      <button class="ga-button ga-padding"
          title="Visualizar Gridview"
          tooltip-placement="bottom" tooltip-append-to-body="true" (click)="goToGridView()">
          <i class="fa fa-table" aria-hidden="true"></i>
      </button>
      <button class="ga-button ga-padding" title="Limpar Filtros" tooltip-placement="bottom"
          tooltip-append-to-body="true" (click)="limparFiltros()">
          <span class="material-symbols-outlined">filter_alt_off</span>
      </button>
    </div>
    <div class="card box-fload" *ngIf="exibirFiltro">
      <div class="card-body d-flex flex-column">
          <div class="row mt-5">
              <div class="col-6">
                  <label class="form-label">Data inicial</label>
                  <div class="input-group">
                      <input (click)="dataInicialFiltro.toggle()" class="form-control"
                          placeholder="dd/mm/yyyy" name="dataInicialFiltroComponent" ngbDatepicker
                          [(ngModel)]="dataInicialFiltroComponent" placement="bottom-right"
                          #dataInicialFiltro="ngbDatepicker">
                      <div class="input-group-append">
                          <button class="input-group-text" (click)="dataInicialFiltro.toggle()" type="button">
                              <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                          </button>
                      </div>
                  </div>
              </div>
              <div class="col-6">
                  <label class="form-label">Data final</label>
                  <div class="input-group">
                      <input (click)="dataFinalFiltro.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                          name="dataFinalFiltroComponent" ngbDatepicker [(ngModel)]="dataFinalFiltroComponent"
                          placement="bottom-right" #dataFinalFiltro="ngbDatepicker">
                      <div class="input-group-append">
                          <button class="input-group-text" (click)="dataFinalFiltro.toggle()" type="button">
                              <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-3 mb-3">
              <div class="row">
                  <div class="col-12 d-flex flex-wrap">
                      <div class="tag-filter" *ngFor="let option of options" (click)="changeDateFilterOption(option)" [ngClass]="{'selectedTagFilter': option.id === selectedTagFilter}">{{option.label}}</div>
                  </div>
              </div>
          </div>
          <div class="mt-auto">
              <button type="button" class="btn btn-primary float-right"
                  (click)="aplicarFiltros()">Aplicar</button>
              <button type="button" class="btn btn-outline-primary float-right mr-2"
                  (click)="limparFiltros()">Limpar</button>
              <button type="button" class="btn btn-outline-primary float-right mr-2"
                  (click)="exibirFiltro = false">Cancelar</button>
          </div>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let filtro of filtersAppliedHeader; let index = i">
  <label *ngIf="filtro" class="ml-0 mt-3 label-filtro">Status: {{filtro.label}} <span class="filtro-remove" (click)="removeFilterApplied(filtro.id, filtro.typeFilter)"><i class="fas fa-window-close cursor-pointer"></i></span></label>
  </ng-container>
  <div class='demo-app-main' *ngIf="calendarVisible">
    <full-calendar [options]='calendarOptions' [events]="eventos"></full-calendar>
  </div>
</div>
