import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { TokenInterceptorService } from '../services/security/token-interceptor.service';

@NgModule({
    declarations: [],
    providers: [
        AuthGuard,
        AuthService,
        // RandomGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule
        // ReactiveFormsModule,
        // MatButtonModule,
        // MatFormFieldModule,
        // MatInputModule
    ]
})
export class AuthModule { }