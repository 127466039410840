import { Component, Input, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "app-icon-origem",
  templateUrl: "./icon-origem.component.html",
  styleUrls: ["./icon-origem.component.scss"],
})
export class IconOrigemComponent implements OnInit, ICellRendererAngularComp {
  tipoDocumentoEvento: string;
  anexos: string;
  documento: string;
  handleDownloadArquivo: Function;

  constructor() {}

  ngOnInit(): void { }

  agInit(params): void {
    this.tipoDocumentoEvento = params.tipoDocumentoEvento;
    this.anexos = params.anexos;
    this.documento = params.documento;
    this.handleDownloadArquivo = params.handleDownloadArquivo;
  }

  refresh(params) {
    return false;
  }

  handleDownload(anexo) {
    this.handleDownloadArquivo(anexo);
  }
}
