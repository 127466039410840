<div class="tools" *ngIf="item && !isGrid && !bulkAction">
  <div class="options d-flex float-right">
    <app-opcoes-reagendamento [item]="item" (dateUpdate)="this.buscarTarefaDoUsuario.emit($event)" *ngIf="item.tipoOcorrencia == 1 || item.tipoOcorrencia == 4"></app-opcoes-reagendamento>

    <div ngbDropdown class="">
      <span
        ngbDropdownToggle
        type="button"
        id="acoes"
        data-toggle="dropdown"
        class="fas fa-ellipsis-v"
        aria-expanded="false"
      ></span>
      <div ngbDropdownMenu aria-labelledby="acoes">
        <a
          ngbDropdownItem
          class="cursor-pointer"
          href="/dashboard/processos-judiciais/detalhes/{{
            item.processoJudicialId
          }}"
          target="_blank"
          ><i class="fas fa-eye"></i> Ver processo</a
        >
        <a
          ngbDropdownItem
          class="cursor-pointer"
          *ngIf="item.tipoOcorrencia == 2"
          (click)="item.editarDetalhes = !item.editarDetalhes"
          ><i class="fas fa-info-circle"></i> Exibir detalhes do histórico</a
        >
        <a
          ngbDropdownItem
          class="cursor-pointer"
          (click)="item.editarDetalhes = !item.editarDetalhes"
          *ngIf="item.tipoOcorrencia != 2"
          ><i class="fa fa-tag"></i> Detalhes da atividade</a
        >
        <a ngbDropdownItem class="cursor-pointer" (click)="onAbrirAtividade()"
          ><i class="fa fa-pencil-alt"></i> Editar atividade</a
        >
        <a
          ngbDropdownItem
          class="cursor-pointer"
          *ngIf="item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 1"
          (click)="onIniciarAtividade()"
          ><i class="fas fa-play"></i> Iniciar atividade</a
        >
        <a
          ngbDropdownItem
          class="cursor-pointer"
          *ngIf="
            item.tipoOcorrencia != 2 &&
            (item.tipoStatusAtividade == 2 || item.tipoStatusAtividade == 4)
          "
          (click)="onEnviarParaRevisao()"
          ><i class="fab fa-algolia"></i> Enviar para revisão</a
        >
        <a
          ngbDropdownItem
          class="cursor-pointer"
          *ngIf="
            item.tipoOcorrencia != 2 &&
            (item.tipoStatusAtividade == 5 || item.tipoStatusAtividade == 6)
          "
          (click)="concluirAtividade()"
          ><i class="fas fa-check-circle"></i> Concluir atividade</a
        >

        <a
          ngbDropdownItem
          class="cursor-pointer"
          *ngIf="item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 3"
          (click)="onDevolverAtividade()"
        >
          <i class="fas fa-undo"></i> Devolver atividade</a
        >
        <a
          ngbDropdownItem
          class="cursor-pointer"
          *ngIf="item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 3"
          (click)="onRevisadoAtividade()"
        >
          <i class="fas fa-check-circle"></i> Revisado e liberado</a
        >
        <a
          ngbDropdownItem
          class="cursor-pointer"
          *ngIf="item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 7"
          (click)="onEncerrarRevisao()"
          ><i class="fab fa-algolia"></i> Encerrar atividade</a
        >

        <a ngbDropdownItem class="cursor-pointer" (click)="onExcluirAtiviade()"
          ><i class="fa fa-trash"></i> Excluir atividade</a
        >
      </div>
    </div>
  </div>
</div>

<div *ngIf="item && isGrid && !bulkAction">
  <div class="d-flex" [ngClass]="{ 'justify-content-between': !viewOnly }">
    <a
      class="cursor-pointer text-dark"
      [ngClass]="{ 'mr-2': viewOnly }"
      matTooltip="Ver processo"
      href="/dashboard/processos-judiciais/detalhes/{{
        item.processoJudicialId
      }}"
      target="_blank"
      ><i class="fas fa-eye"></i
    ></a>
    <a
      class="cursor-pointer"
      (click)="openModalDetalhesAtividade()"
      matTooltip="Detalhes da atividade"
      *ngIf="item.tipoOcorrencia != 2"
    >
      <i class="fa fa-tag" [ngClass]="{ 'text-muted': disabled() }"></i>
    </a>
    <ng-container *ngIf="!viewOnly">
      <a
        class="cursor-pointer"
        *ngIf="item.tipoOcorrencia == 2"
        matTooltip="Exibir detalhes do histórico"
        (click)="disabled() ? '' : (item.editarDetalhes = !item.editarDetalhes)"
      >
        <i
          class="fas fa-info-circle"
          [ngClass]="{ 'text-muted': disabled() }"
        ></i>
      </a>

      <a class="cursor-pointer" (click)="onAbrirAtividade()">
        <i
          class="fa fa-pencil-alt"
          [ngClass]="{ 'text-muted': disabled() }"
          matTooltip="Editar atividade"
        ></i>
      </a>

      <a
        class="cursor-pointer"
        *ngIf="
          item.tipoOcorrencia != 2 &&
          item.tipoOcorrencia != 5 &&
          item.tipoStatusAtividade == 1
        "
        matTooltip="Iniciar atividade"
        (click)="onIniciarAtividade()"
      >
        <i class="fas fa-play" [ngClass]="{ 'text-muted': disabled() }"></i>
      </a>

      <a
        class="cursor-pointer"
        matTooltip="Enviar para revisão"
        *ngIf="
          item.tipoOcorrencia != 2 &&
          item.tipoOcorrencia != 5 &&
          (item.tipoStatusAtividade == 2 || item.tipoStatusAtividade == 4)
        "
        (click)="onEnviarParaRevisao()"
      >
        <i class="fab fa-algolia" [ngClass]="{ 'text-muted': disabled() }"></i>
      </a>

      <a
        class="cursor-pointer"
        matTooltip="Liberar para protocolar atividade"
        *ngIf="
          item.tipoOcorrencia != 2 &&
          item.tipoOcorrencia != 5 &&
          item.tipoStatusAtividade == 5
        "
        (click)="onLiberarProtocolarAtividade()"
      >
        <i
          class="fas fa-paperclip"
          [ngClass]="{ 'text-muted': disabled() }"
        ></i>
      </a>

      <a
        class="cursor-pointer"
        matTooltip="Concluir atividade"
        *ngIf="
          item.tipoOcorrencia != 2 &&
          item.tipoOcorrencia != 5 &&
          (item.tipoStatusAtividade == 5 || item.tipoStatusAtividade == 6)
        "
        (click)="concluirAtividade()"
      >
        <i
          class="fas fa-check-circle"
          [ngClass]="{ 'text-muted': disabled() }"
        ></i>
      </a>

      <a
        class="cursor-pointer"
        matTooltip="Devolver atividade"
        *ngIf="
          item.tipoOcorrencia != 2 &&
          item.tipoOcorrencia != 5 &&
          item.tipoStatusAtividade == 3
        "
        (click)="onDevolverAtividade()"
      >
        <i class="fas fa-undo" [ngClass]="{ 'text-muted': disabled() }"></i>
      </a>
      <a
        class="cursor-pointer"
        matTooltip="Revisado e liberado"
        *ngIf="
          item.tipoOcorrencia != 2 &&
          item.tipoOcorrencia != 5 &&
          item.tipoStatusAtividade == 3
        "
        (click)="onRevisadoAtividade()"
      >
        <i
          class="fas fa-check-circle"
          [ngClass]="{ 'text-muted': disabled() }"
        ></i>
      </a>

      <a
        class="cursor-pointer"
        matTooltip="Encerrar atividade"
        *ngIf="
          item.tipoOcorrencia != 2 &&
          item.tipoOcorrencia != 5 &&
          item.tipoStatusAtividade == 7
        "
        (click)="onEncerrarRevisao()"
      >
        <i class="fab fa-algolia" [ngClass]="{ 'text-muted': disabled() }"></i>
      </a>

      <a
        class="cursor-pointer"
        (click)="onExcluirAtiviade()"
        matTooltip="Excluir atividade"
      >
        <i class="fa fa-trash" [ngClass]="{ 'text-muted': disabled() }"></i>
      </a>
    </ng-container>
  </div>
</div>

<div *ngIf="!!items.length && bulkAction">
  <div class="d-flex position-absolute">
    <a
      class="cursor-pointer mr-2"
      *ngIf="showIniciarAtividade()"
      matTooltip="Iniciar atividades"
      (click)="onIniciarAtividade()"
    >
      <i class="fas fa-play" [ngClass]="{ 'text-muted': disabled() }"></i>
    </a>

    <a
      class="cursor-pointer mr-2"
      matTooltip="Enviar para revisão"
      *ngIf="showEnviarParaAnalise()"
      (click)="onEnviarParaRevisao()"
    >
      <i class="fab fa-algolia" [ngClass]="{ 'text-muted': disabled() }"></i>
    </a>

    <a
      class="cursor-pointer mr-2"
      matTooltip="Liberar para protocolar atividades"
      *ngIf="showLiberarProtocolarAtividade()"
      (click)="onLiberarProtocolarAtividade()"
    >
      <i class="fas fa-paperclip" [ngClass]="{ 'text-muted': disabled() }"></i>
    </a>

    <a
      class="cursor-pointer mr-2"
      matTooltip="Concluir atividades"
      *ngIf="showConcluirAtividade()"
      (click)="concluirAtividade()"
    >
      <i
        class="fas fa-check-circle"
        [ngClass]="{ 'text-muted': disabled() }"
      ></i>
    </a>

    <!--Fluxo revisor-->
    <a
      class="cursor-pointer mr-2"
      matTooltip="Devolver atividades"
      *ngIf="showDevolverAtividade()"
      (click)="onDevolverAtividade()"
    >
      <i class="fas fa-undo" [ngClass]="{ 'text-muted': disabled() }"></i>
    </a>
    <a
      class="cursor-pointer mr-2"
      matTooltip="Revisado e liberado"
      *ngIf="showRevisadoAtividade()"
      (click)="onRevisadoAtividade()"
    >
      <i
        class="fas fa-check-circle"
        [ngClass]="{ 'text-muted': disabled() }"
      ></i>
    </a>

    <a
      class="cursor-pointer mr-2"
      matTooltip="Encerrar atividades"
      *ngIf="showEncerrarRevisao()"
      (click)="onEncerrarRevisao()"
    >
      <i class="fab fa-algolia" [ngClass]="{ 'text-muted': disabled() }"></i>
    </a>
  </div>
</div>
