import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaveStateFilters {

  private _filterCarteiraProcesso = null;
  // {
  //   numeroPasta: '',
  //   numeroProcesso: '',
  //   clientes: [],
  //   responsaveis: [],
  //   ufSelecionada: '',
  //   dataDe: '',
  //   dataInicial: null,
  //   dataFinal: null,
  //   situacaoSelecionada: '',
  //   tagsSelecionadas: '',
  //   tipoFiltroValor: 0,
  //   valorInicial: 0,
  //   valorFinal: 0,
  //   tipo: '',
  //   instancias: [],
  //   varas: [],
  //   acoes: [],
  //   comarcas: [],
  // }

  constructor() {}

  public setFilterCarteiraProcesso(value) {
    this._filterCarteiraProcesso = value;
  }

  public get getFilterCarteiraProcesso() {
    return this._filterCarteiraProcesso;
  }
}
