<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos / Adicionar honorário - percentual no êxito</span>        
    </span>
</h4>
<hr />
<div class="container mb-5">
    <form ngNoForm>
        <div class="row">
            <div class="col-8">
                <label class="form-label">% de honorário<span class="required-field ml-1">*</span></label>
                <input type="number" class="form-control col-2" [(ngModel)]="honorarioPorPercentual.valorPercentual">
            </div>
        </div>
        <div class="row">
            <div class="col-8 alertar-encerramento">
                <input class="form-check-input" type="checkbox" id="check_encerramento" [(ngModel)]="honorarioPorPercentual.alertarEncerramento">
                <label class="form-check-label" for="check_encerramento">
                    Alertar ao encerrar o processo
                </label>
            </div>
        </div>
        <br />
        <br />
        <br />
        <div class="row">
            <div class="form-group col-8">
                <label class="form-label">Descrição<span class="required-field ml-1">*</span></label>
                <textarea maxlength="1000" rows="5" class="form-control" placeholder="Digite a descrição do caso" [(ngModel)]="honorarioPorPercentual.descricao"></textarea>
            </div>
        </div>
    </form>
    <div class="row" *ngIf="honorariosDetalhe != undefined">
        <div class="form-group col-4">
            <label class="form-label">Processo, caso ou atendimento<span class="required-field ml-1">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="honorariosDetalhe.titulo">
        </div>
        <div class="form-group col-4">
            <label class="form-label">Cliente(s)<span class="required-field ml-1">*</span></label>                
            <ng-select class="form-select" [ngModel]="clienteSelected" [items]="this.honorariosDetalhe.nomeCliente" >                    
            </ng-select>
        </div>
        <div class="form-group col-8">
            <label class="form-label">Responsável<span class="required-field ml-1">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="honorariosDetalhe.nomeResponsavel">
        </div>
    </div>
    <hr />
    <div class="d-flex justify-content-end">
        <button type="button" (click)="cancel()" class="btn btn-light mr-3">Cancelar</button>
        <button type="button" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
    </div>    
</div>