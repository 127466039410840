import { Guid } from 'guid-typescript';

export class ProcessoDivida {
    id: Guid;
    situacao: String;
    numeroDocumento: String;
    numeroTitulo: String;
    parcela: String;
    dataVencimento: Date;
    dataRemessa: Date;
    dataEmissao: Date;
    dataReferencia: Date;
    dividaPaiId: Guid;
    valorOriginal: number;
    valorAberto: number;
    valorCustasProtesto: number;
    valorSaldoAberto: number;
    valorOriginalAberto: number;
    selecionado: Boolean;
    valorPagamento: number;
    statusAcordo:String;
    acordoId:Guid;
    juros:number;
    multa:number;
    pagamentos: any;
    valorTotal: number
    valorSaldoPendente: number;
    valorPago: number;
    tipoParcela:String;
    valorDesconto: number;
    dataCriacao:Date;
    valorAtualizacaoCustas: number;
    cobradorId:Guid;
    cobrador:String;
    cobradorIdExterno:String;
    acordoSaldo: ProcessoDivida[]
}
