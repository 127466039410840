import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/shared/modal-service';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-modal-filtro-processos',
    templateUrl: './modal-filtro-processos.component.html',
    styleUrls: ['../../../../../vendor/styles/pages/modal-filtro-processos-orfaos.scss',
        '../../../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    ],
})

export class ModalFiltroProcessosComponent implements OnInit {
    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    public processosModal = [];
    public processosModalFiltrados = [];
    public todosOsProcessosModal = false;

    public filtroSemContato = ['Selecione...', 'mais que 5 dias', 'mais que 10 dias', 'mais que 15 dias', 'mais que 30 dias'];
    public filtroSemAtuacao = ['Selecione...', 'mais que 5 dias', 'mais que 10 dias', 'mais que 15 dias', 'mais que 30 dias'];

    public rangeValue2 = [2050, 3000];
    public rangeConfig = {
        connect: true,
        step: 10,
        range: {
            min: 10,
            max: 10000
        },
        direction: 'ltr'
    };

    public settings = {
        selectMode: 'multi',
        actions: {
            columnTitle: "",
            position: "right",
            add: false,
            edit: false,
            delete: false,
        },
        noDataMessage: "Não possui dado",
        pager: {
            display: false
        },
        columns: {},
        attr: {
            class: 'table table-bordered table-responsive table-modal-tabs-scroll scroll-bar-padrao'
        }
    };

    public columns = {
        Codigo: { title: 'Código' },
        Cobrador: { title: 'Cobrador' },
        Valor: { title: 'Valor' },
        AnoDaDivida: { title: 'Ano da dívida' },
        Cliente: { title: 'Cliente' },
        Devedor: { title: 'Devedor' },
        Regiao: { title: 'Região' }
    };

    modalRef: NgbModalRef;
    isIE10 = false;
    loadingIndicator = true;

    ngOnInit(): void {
        this.modalRef.componentInstance.processos.forEach(element => {
            var novoProcesso = {
                Codigo: element.Codigo,
                Cobrador: element.Cobrador,
                Nome: element.Nome,
                Valor: element.Valor,
                AnoDaDivida: element.AnoDaDivida,
                Cliente: element.Cliente,
                Devedor: element.Devedor,
                Regiao: element.Regiao
            };

            this.processosModal.push(novoProcesso);
            this.processosModalFiltrados.push(novoProcesso);
        });

        this.settings.columns = this.columns;

        //this.processosModalFiltrados = this.modalRef.componentInstance.processosModal;
    }

    constructor(
        private appService: AppService,
        public modalService: ModalService
    ) {

        this.isIE10 = this.appService.isIE10;
    }

    public onUserRowSelect(event) {
        if (event.data) {
            this.processosModalFiltrados.forEach(element => {
                if (element.Codigo == event.data.Codigo && element.Selecionado) {
                    element.Selecionado = element.Selecionado == true ? false : true;
                    return true;
                }
                else if (element.Codigo == event.data.Codigo) {
                    element.Selecionado = true;
                    return true;
                }
            });
        }
        else {
            this.processosModalFiltrados.forEach(element => {
                var selecionado = event.source.filteredAndSorted.find(s => s.Codigo == element.Codigo);
                if (selecionado) {
                    if (element.Selecionado) {
                        element.Selecionado = element.Selecionado == true ? false : true;
                        return true;
                    }
                    else {
                        element.Selecionado = true;
                        return true;
                    }
                }
            });

        }
    }

    public filtrar() {
        var lista = this.processosModalFiltrados.filter(c => c.Selecionado == true);
        this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'filtroprocessosModal', lista: lista });
    }

    public onSelecionaTodosprocessosModalChange(event) {
        if (this.todosOsProcessosModal) {
            this.processosModalFiltrados.forEach(function (item) { item.Selecionado = true; });
        }
        else {
            this.processosModalFiltrados.forEach(function (item) { item.Selecionado = false });
        }
    }

    public close() {
        this.modalRef.close();
    }

    public dismiss() {
        this.modalRef.dismiss();
    }
}
