<h4 class="d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">Gestão /</span> Despesas</span>
</h4>
<hr class="border-light" />

<div>
    <ul [(activeId)]="activeTab" ngbNav #productTabs="ngbNav" class="nav-tabs">
        <li ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink (click)="getDespesasEmAberto()">Despesas a Recolher

                <div style="margin-left: 10px" *ngIf="loadDespesasEmAberto"
                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </a>
            <ng-template ngbNavContent>
                <div class="filtro-header mt-2">

                    <div class="p-3 filtro-header-titulo d-flex justify-content-between"
                        (click)="filtroDespesasRecolherOn = !filtroDespesasRecolherOn">
                        <span>Filtros</span>
                        <span class="lnr lnr-chevron-up" *ngIf="filtroDespesasRecolherOn"></span>
                        <span class="lnr lnr-chevron-down" *ngIf="!filtroDespesasRecolherOn"></span>
                    </div>

                    <div class="filtro-despesas-recolher" *ngIf="filtroDespesasRecolherOn">
                        <div class="row mt-4 mb-4">
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Filtrar por</label>
                                <ng-select [(ngModel)]="tipoFiltroValor" [clearable]="false" [searchable]="false">
                                    <ng-option [value]="1">Data Vencimento</ng-option>
                                    <ng-option [value]="2">Data Limite Pagamento</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Início</label>
                                <div class="input-group">
                                    <input (click)="dataInicioFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataInicioFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataInicioFiltro="ngbDatepicker"
                                        [(ngModel)]="dataInicio" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataInicioFiltro.toggle()"
                                            type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Fim</label>
                                <div class="input-group">
                                    <input (click)="dataFimFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataFimFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataFimFiltro="ngbDatepicker" [(ngModel)]="dataFim" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataFimFiltro.toggle()" type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-1 col-sm-2 c">
                                <label class="form-label d-none d-md-block">&nbsp;</label>
                                <button type="button" class="btn btn-padrao btn-block" (click)="ordenarEPaginar()">
                                    <span class="fas fa-search"></span>&nbsp;&nbsp;Filtrar
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="extrato">
                    <p>
                        <b>
                            TOTAL DE DESPESAS:
                            {{ valorTotalDespesa | currency : "BRL" }}
                        </b>
                    </p>
                </div>
                <div class="card">
                    <!-- Table -->
                    <hr class="border-light m-0">
                    <div class="table-responsive"
                        *ngIf="!loadDespesasEmAberto && listDespesasEmAbertoFiltrada && listDespesasEmAbertoFiltrada.length > 0">
                        <table class="table table-striped table-bordered card-table">
                            <thead>
                                <tr>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('numeroProcesso')">
                                        Processo<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='numeroProcesso'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer" (click)="setSortDespesasPendente('pasta')">
                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByDespesasPendente =='pasta'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('nomeCliente')">
                                        Cliente<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='nomeCliente'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('dataLancamento')">
                                        Data Vencimento<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='dataLancamento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('dataLimitePagamento')">
                                        Data Limite Pagamento<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='dataLimitePagamento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('nomeUsuario')">
                                        Criado Por<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='nomeUsuario'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer" (click)="setSortDespesasPendente('valor')">
                                        Valor<i class="ion text-muted ml-2" *ngIf="sortByDespesasPendente =='valor'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr class="tabela" *ngFor="let desp of listDespesasEmAbertoFiltrada">
                                    <td>
                                        {{ desp.numeroProcesso }}
                                        <a class="fas fa-external-link-square-alt color-info p-0 ml-2"
                                            aria-hidden="true" href="/operacionais/processos-judiciais/detalhes/{{
                    desp.processoJudicialId
                  }}" target="_blank"></a>
                                    </td>
                                    <td>{{ desp.pasta }}</td>
                                    <td>{{ desp.nomeCliente }}</td>
                                    <td>{{ desp.dataLancamento | date: "dd/MM/yyyy" }}</td>
                                    <td>{{ desp.dataLimitePagamento | date: "dd/MM/yyyy" }}</td>
                                    <td>{{ desp.nomeUsuario }}</td>
                                    <td>{{ desp.valor | currency : "BRL" }}</td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-primary btn-sm"
                                            (click)="openCadastrarTipoDespesa(desp)">
                                            Processar Despesa
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="card-body py-0" *ngIf="totalItens > 0">
                        <div class="row">
                            <div class="col-sm text-sm-left text-center pt-3">
                                <span class="text-muted">Páginas {{ paginaAtual }} de {{
                                    totalPaginas }}</span>
                            </div>
                            <div class="col-sm text-sm-left text-center pt-3">
                                <label>Itens por página:</label>
                                <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                                    (change)="definirPaginacao(1)">
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                            </div>
                            <div class="col-sm pt-3">
                                <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                                    [(page)]="paginaAtual" [boundaryLinks]="true"
                                    (pageChange)="definirPaginacao(paginaAtual)" size="sm"
                                    class="d-flex justify-content-center justify-content-sm-end m-0">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-5"
                        *ngIf="!loadDespesasEmAberto && (!listDespesasEmAbertoFiltrada || listDespesasEmAbertoFiltrada.length == 0)">
                        <h3>Não possui dados.</h3>
                    </div>
                    <div class="text-center mt-5" *ngIf="loadFatuamento">
                        <div class="align-items-center">
                            <h3>Carregando dados...</h3>
                            <div class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true">
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </li>

        <li ngbNavItem [ngbNavItem]="2">
            <a ngbNavLink (click)="getDespesasProcessadas()">Despesas Processadas

                <div style="margin-left: 10px" *ngIf="loadDespesasProcessadas"
                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </a>
            <ng-template ngbNavContent>
                <div class="filtro-header mt-2">

                    <div class="p-3 filtro-header-titulo d-flex justify-content-between"
                        (click)="filtroDespesasProcessadasOn = !filtroDespesasProcessadasOn">
                        <span>Filtros</span>
                        <span class="lnr lnr-chevron-up" *ngIf="filtroDespesasProcessadasOn"></span>
                        <span class="lnr lnr-chevron-down" *ngIf="!filtroDespesasProcessadasOn"></span>
                    </div>

                    <div class="filtro-despesas-processadas" *ngIf="filtroDespesasProcessadasOn">

                        <div class="row">
                            <div class="col-md-8 col-sm-6">
                                <label class="form-label">Cliente *</label>
                                <pessoa-dropdown [(pessoaId)]="clientes" [selecionaMultiplos]="false"></pessoa-dropdown>
                            </div>

                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Pasta</label>
                                <div class="input-group">
                                    <input class="form-control" placeholder="Número da Pasta" name="filtroPasta"
                                        [(ngModel)]="filtroPasta" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" type="button">
                                            <span class="fas fa-suitcase"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Processo</label>
                                <div class="input-group">
                                    <input class="form-control" placeholder="Número do Processo" name="filtroProcesso"
                                        [(ngModel)]="filtroProcesso" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" type="button">
                                            <span class="fas fa-user-tie"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 mb-4">
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Filtrar por</label>
                                <ng-select [(ngModel)]="tipoFiltroValor" [clearable]="false" [searchable]="false">
                                    <ng-option [value]="1">Data Vencimento</ng-option>
                                    <ng-option [value]="2">Data Limite Pagamento</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Início *</label>
                                <div class="input-group">
                                    <input (click)="dataInicioFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataInicioFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataInicioFiltro="ngbDatepicker"
                                        [(ngModel)]="dataInicio" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataInicioFiltro.toggle()"
                                            type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Fim *</label>
                                <div class="input-group">
                                    <input (click)="dataFimFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataFimFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataFimFiltro="ngbDatepicker" [(ngModel)]="dataFim" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataFimFiltro.toggle()" type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Valor</label>
                                <div class="input-group">
                                    <input class="form-control" placeholder="R$ 0,00" name="filtroValor"
                                        [(ngModel)]="filtroValor" currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" type="button">
                                            <span class="fas fa-search-dollar"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-2 col-sm-6 c">
                                <label class="form-label d-none d-md-block">&nbsp;</label>
                                <button type="button" class="btn btn-padrao btn-block" (click)="ordenarEPaginar()">
                                    <span class="fas fa-search"></span>&nbsp;&nbsp;Filtrar
                                </button>
                            </div>
                            <div class="col-md-2 col-sm-6">
                                <label class="form-label d-none d-md-block">&nbsp;</label>
                                <button type="button" class="btn btn-info btn-block"
                                    (click)="exportDespesaProcessada()">
                                    <span class="fas fa-file-excel"></span>&nbsp;&nbsp;Exportar
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="extrato">
                    <p>
                        <b>
                            TOTAL DE DESPESAS:
                            {{ valorTotalDespesaProcessadas | currency : "BRL" }}
                        </b>
                    </p>
                </div>
                <div class="card">
                    <!-- Table -->
                    <hr class="border-light m-0">
                    <div class="table-responsive"
                        *ngIf="!loadDespesasProcessadas && listDespesasProcessadasFiltrada && listDespesasProcessadasFiltrada.length > 0">
                        <table class="table table-striped table-bordered card-table">
                            <thead>
                                <tr>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('numeroProcesso')">
                                        Processo<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='numeroProcesso'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer" (click)="setSortDespesasPendente('pasta')">
                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByDespesasPendente =='pasta'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('nomeCliente')">
                                        Cliente<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='nomeCliente'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('dataLancamento')">
                                        Data Vencimento<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='dataLancamento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('dataLimitePagamento')">
                                        Data Limite Pagamento<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='dataLimitePagamento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortDespesasPendente('nomeUsuario')">
                                        Criado Por<i class="ion text-muted ml-2"
                                            *ngIf="sortByDespesasPendente =='nomeUsuario'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer" (click)="setSortDespesasPendente('valor')">
                                        Valor<i class="ion text-muted ml-2" *ngIf="sortByDespesasPendente =='valor'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr class="tabela" *ngFor="let desp of listDespesasProcessadasFiltrada">
                                    <td>
                                        {{ desp.numeroProcesso }}
                                        <a class="fas fa-external-link-square-alt color-info p-0 ml-2"
                                            aria-hidden="true" href="/operacionais/processos-judiciais/detalhes/{{
                    desp.processoJudicialId
                  }}" target="_blank"></a>
                                    </td>
                                    <td>{{ desp.pasta }}</td>
                                    <td>{{ desp.nomeCliente }}</td>
                                    <td>{{ desp.dataLancamento | date: "dd/MM/yyyy" }}</td>
                                    <td>{{ desp.dataLimitePagamento | date: "dd/MM/yyyy" }}</td>
                                    <td>{{ desp.nomeUsuario }}</td>
                                    <td>{{ desp.valor | currency : "BRL" }}</td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-primary btn-sm"
                                            (click)="openCadastrarTipoDespesa(desp, true)">
                                            Editar Despesa
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="card-body py-0" *ngIf="totalItens > 0">
                        <div class="row">
                            <div class="col-sm text-sm-left text-center pt-3">
                                <span class="text-muted">Páginas {{ paginaAtual }} de {{
                                    totalPaginas }}</span>
                            </div>
                            <div class="col-sm text-sm-left text-center pt-3">
                                <label>Itens por página:</label>
                                <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                                    (change)="definirPaginacao(1)">
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                            </div>
                            <div class="col-sm pt-3">
                                <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                                    [(page)]="paginaAtual" [boundaryLinks]="true"
                                    (pageChange)="definirPaginacao(paginaAtual)" size="sm"
                                    class="d-flex justify-content-center justify-content-sm-end m-0">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-5"
                        *ngIf="!loadDespesasProcessadas && (!listDespesasProcessadasFiltrada || listDespesasProcessadasFiltrada.length == 0)">
                        <h3>Não possui dados.</h3>
                    </div>
                    <div class="text-center mt-5" *ngIf="loadDespesasProcessadas">
                        <div class="align-items-center">
                            <h3>Carregando dados...</h3>
                            <div class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true">
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </li>

        <li ngbNavItem [ngbNavItem]="3">
            <a ngbNavLink (click)="getNotasDebitoPendentes()">Emissão de Notas de Débito
                <div style="margin-left: 10px" *ngIf="loadNotasDebitoPendente"
                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </a>
            <ng-template ngbNavContent>
                <div class="card">
                    <!-- Table -->
                    <hr class="border-light m-0">
                    <div class="table-responsive"
                        *ngIf="!loadNotasDebitoPendente && listNotasDebitoPendenteFiltrada && listNotasDebitoPendenteFiltrada.length > 0">
                        <table class="table table-striped table-bordered card-table">
                            <thead>
                                <tr>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortNotaDebitoPendente('nomeCliente')">
                                        Cliente<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='nomeCliente'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr class="tabela" *ngFor="let debito of listNotasDebitoPendenteFiltrada">
                                    <td>{{ debito.nomeCliente }}</td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-primary btn-sm"
                                            (click)="openCadastrarNotaPendente(debito, true)">
                                            Cadastrar Nota de Débito
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="card-body py-0" *ngIf="totalItens > 0">
                        <div class="row">
                            <div class="col-sm text-sm-left text-center pt-3">
                                <span class="text-muted">Páginas {{ paginaAtual }} de {{
                                    totalPaginas }}</span>
                            </div>
                            <div class="col-sm text-sm-left text-center pt-3">
                                <label>Itens por página:</label>
                                <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                                    (change)="definirPaginacao(1)">
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                            </div>
                            <div class="col-sm pt-3">
                                <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                                    [(page)]="paginaAtual" [boundaryLinks]="true"
                                    (pageChange)="definirPaginacao(paginaAtual)" size="sm"
                                    class="d-flex justify-content-center justify-content-sm-end m-0">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-5"
                        *ngIf="!loadNotasDebitoPendente && (!listNotasDebitoPendenteFiltrada || listNotasDebitoPendenteFiltrada.length == 0)">
                        <h3>Não possui dados.</h3>
                    </div>
                    <div class="text-center mt-5" *ngIf="loadFatuamento">
                        <div class="align-items-center">
                            <h3>Carregando dados...</h3>
                            <div class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>

        <li ngbNavItem [ngbNavItem]="4">
            <a ngbNavLink (click)="getNotasDebitoPagar()">Controle de Notas de
                Débito
                <div style="margin-left: 10px" *ngIf="loadNotasDebitoVencidas"
                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </a>
            <ng-template ngbNavContent>
                <div class="filtro-header mt-2">
                    <div class="p-3 filtro-header-titulo d-flex justify-content-between"
                        (click)="filtroControleNotasDebitoOn = !filtroControleNotasDebitoOn">
                        <span>Filtros</span>
                        <span class="lnr lnr-chevron-up" *ngIf="filtroControleNotasDebitoOn"></span>
                        <span class="lnr lnr-chevron-down" *ngIf="!filtroControleNotasDebitoOn"></span>
                    </div>
                    <div class="filtro-controle-notas-debito" *ngIf="filtroControleNotasDebitoOn">
                        <div class="row mt-4 mb-4">
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Início *</label>
                                <div class="input-group">
                                    <input (click)="dataInicioFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataInicioFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataInicioFiltro="ngbDatepicker"
                                        [(ngModel)]="dataInicio" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataInicioFiltro.toggle()"
                                            type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Fim *</label>
                                <div class="input-group">
                                    <input (click)="dataFimFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataFimFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataFimFiltro="ngbDatepicker" [(ngModel)]="dataFim" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataFimFiltro.toggle()" type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Status</label>
                                <ng-select [items]="dropStatus" bindLabel="name" bindValue="id" [multiple]="true"
                                    placeholder="Selecione um Status" (change)="onMaterialGroupChange($event)">
                                </ng-select>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <label class="form-label">Cliente</label>
                                <pessoa-dropdown [(pessoaId)]="clientes" [selecionaMultiplos]="false"></pessoa-dropdown>
                            </div>

                            <div class="col-md-1 col-sm-2">
                                <label class="form-label d-none d-md-block">&nbsp;</label>
                                <button type="button" class="btn btn-padrao btn-block" (click)="ordenarEPaginar()">
                                    <span class="fas fa-search"></span>&nbsp;&nbsp;Filtrar
                                </button>
                            </div>
                            <div class="col-md-1 col-sm-2">
                                <label class="form-label d-none d-md-block">&nbsp;</label>
                                <button type="button" class="btn btn-info btn-block" (click)="exportExcelNota()">
                                    <span class="fas fa-file-excel"></span>&nbsp;&nbsp;Exportar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <!-- Table -->
                    <hr class="border-light m-0">
                    <div class="table-responsive"
                        *ngIf="!loadNotasDebitoVencidas && listNotasDebitoVencidasFiltrada &&  listNotasDebitoVencidasFiltrada.length > 0">
                        <table class="table table-striped table-bordered card-table">
                            <thead>
                                <tr>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortNotaDebitoAberta('idExterno')">
                                        Código<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoAberta =='idExterno'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoAberta ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortNotaDebitoAberta('nomeCliente')">
                                        Cliente<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoAberta =='nomeCliente'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoAberta ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortNotaDebitoAberta('vencimento')">
                                        Data Vencimento<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoAberta =='vencimento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoAberta ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer" (click)="setSortNotaDebitoAberta('debito')">
                                        Débito<i class="ion text-muted ml-2" *ngIf="sortByNotaDebitoAberta =='debito'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoAberta ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer" (click)="setSortNotaDebitoAberta('credito')">
                                        Crédito<i class="ion text-muted ml-2" *ngIf="sortByNotaDebitoAberta =='credito'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoAberta ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr class="tabela" [ngClass]="getCssClass(debito)"
                                    *ngFor="let debito of listNotasDebitoVencidasFiltrada">
                                    <td>{{ debito.idExterno }}</td>
                                    <td>{{ debito.nomeCliente }}</td>
                                    <td>{{ debito.vencimento | date : "dd/MM/yyyy" }}</td>
                                    <td>{{ debito.debito | currency : "BRL" }}</td>
                                    <td>{{ debito.credito | currency : "BRL" }}</td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-primary btn-sm"
                                            (click)="openPagamentoNotaPendente(debito)">
                                            Processar
                                        </button>
                                        <button type="button" class="btn btn-primary btn-sm" *ngIf="debito.pago"
                                            (click)="openPagamentoNotaPendente(debito, true)">
                                            Editar
                                        </button>
                                        <button type="button" class="btn btn-info btn-sm"
                                            *ngIf="checkForArquivo(debito)"
                                            (click)="handleDownloadArquivo(debito.arquivoId)">
                                            <i class="fas fa-download"></i>
                                        </button>
                                        <button type="button" class="btn btn-danger btn-sm"
                                            (click)="deletarNota(debito.id)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-body py-0" *ngIf="totalItens > 0">
                        <div class="row">
                            <div class="col-sm text-sm-left text-center pt-3">
                                <span class="text-muted">Páginas {{ paginaAtual }} de {{
                                    totalPaginas }}</span>
                            </div>
                            <div class="col-sm text-sm-left text-center pt-3">
                                <label>Itens por página:</label>
                                <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                                    (change)="definirPaginacao(1)">
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                            </div>
                            <div class="col-sm pt-3">
                                <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                                    [(page)]="paginaAtual" [boundaryLinks]="true"
                                    (pageChange)="definirPaginacao(paginaAtual)" size="sm"
                                    class="d-flex justify-content-center justify-content-sm-end m-0">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-5"
                        *ngIf="!loadNotasDebitoVencidas && (!listNotasDebitoVencidasFiltrada || listNotasDebitoVencidasFiltrada.length == 0)">
                        <h3>Não possui dados.</h3>
                    </div>
                    <div class="text-center mt-5" *ngIf="loadFatuamento">
                        <div class="align-items-center">
                            <h3>Carregando dados...</h3>
                            <div class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true">
                            </div>
                        </div>
                    </div>
                    <div class="row right"
                        *ngIf="!loadNotasDebitoVencidas && listNotasDebitoVencidasFiltrada &&  listNotasDebitoVencidasFiltrada.length > 0">
                        <div class='my-legend'>
                            <div class='legend-title'>Status:</div>
                            <div class='legend-scale'>
                                <ul class='legend-labels'>
                                    <li><span class="vencido"></span>Vencido</li>
                                    <li><span class="vencimento-proximo"></span>A vencer</li>
                                    <li><span class="sem-vencimento"></span>Sem vencimento</li>
                                    <li><span class="pago"></span>Pago</li>
                                </ul>
                            </div>
                            <div class="legend-source">A(s) linha(s) colorida refere-se a <strong>data de
                                    vencimento</strong></div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>

        <li ngbNavItem [ngbNavItem]="5">
            <a ngbNavLink>Extrato Consolidado
                <div style="margin-left: 10px" *ngIf="loadExtratoConsolidado"
                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </a>
            <ng-template ngbNavContent>              
                <div class="filtro-header mt-2">

                    <div class="p-3 filtro-header-titulo d-flex justify-content-between"
                        (click)="filtroExtratoConsolidadoOn = !filtroExtratoConsolidadoOn">
                        <span>Filtros</span>
                        <span class="lnr lnr-chevron-up" *ngIf="filtroExtratoConsolidadoOn"></span>
                        <span class="lnr lnr-chevron-down" *ngIf="!filtroExtratoConsolidadoOn"></span>
                    </div>

                    <div class="filtro-extrato-consolidado" *ngIf="filtroExtratoConsolidadoOn">
                        <div class="row">
                            <div class="col-md-8 col-sm-6">
                                <label class="form-label">Cliente *</label>
                                <pessoa-dropdown [(pessoaId)]="clientes" [selecionaMultiplos]="false"></pessoa-dropdown>
                            </div>
                        </div>
                        <div class="row mt-4 mb-4">

                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Início</label>
                                <div class="input-group">
                                    <input (click)="dataInicioFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataInicioFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataInicioFiltro="ngbDatepicker"
                                        [(ngModel)]="dataInicio" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataInicioFiltro.toggle()"
                                            type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Data Fim</label>
                                <div class="input-group">
                                    <input (click)="dataFimFiltro.toggle()" class="form-control"
                                        placeholder="dd/mm/yyyy" name="dataFimFiltroComponent" ngbDatepicker
                                        placement="bottom-right" #dataFimFiltro="ngbDatepicker" [(ngModel)]="dataFim" />
                                    <div class="input-group-append">
                                        <button class="input-group-text" (click)="dataFimFiltro.toggle()" type="button">
                                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Número da Pasta</label>
                                <input type="text" [(ngModel)]="numeroPasta" class="form-control">
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <label class="form-label">Recurso Desdobramento</label>
                                <input type="text" [(ngModel)]="numeroRecurso" class="form-control">
                            </div>

                            <div class="col-md-1 col-sm-2">
                                <label class="form-label d-none d-md-block">&nbsp;</label>
                                <button type="button" class="btn btn-padrao btn-block"
                                    (click)="getExtratoConsolidado()">
                                    <span class="fas fa-search"></span>&nbsp;&nbsp;Filtrar
                                </button>
                            </div>
                            <div class="col-md-1 col-sm-2">
                                <label class="form-label d-none d-md-block">&nbsp;</label>
                                <button type="button" class="btn btn-info btn-block" (click)="exportExcelExtrato()">
                                    <span class="fas fa-file-excel"></span>&nbsp;&nbsp;Exportar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="extrato"
                    *ngIf="!loadExtratoConsolidado && extratoConsolidado && listExtratoConsolidadoFiltrada &&  listExtratoConsolidadoFiltrada.length > 0">
                    <p>
                        <b>
                            TOTAL DE REEMBOLSO:
                            {{ extratoConsolidado.creditos | currency : "BRL" }}
                        </b>
                    </p>
                    <p>
                        <b>
                            TOTAL DE ADIANTAMENTO:
                            {{ extratoConsolidado.debitos | currency : "BRL" }}
                        </b>
                    </p>
                    <p>
                        <b>
                            SALDO:
                            {{ extratoConsolidado.creditos + extratoConsolidado.debitos | currency : "BRL" }}
                        </b>
                    </p>
                </div>
                <div class="card">
                    <!-- Table -->
                    <hr class="border-light m-0">
                    <div class="table-responsive"
                        *ngIf="!loadExtratoConsolidado && listExtratoConsolidadoFiltrada &&  listExtratoConsolidadoFiltrada.length > 0">
                        <table class="table table-striped table-bordered card-table">
                            <thead>
                                <tr>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('nomeCliente')">
                                        Cliente<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='nomeCliente'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('recursoDesdobramento')">
                                        Recurso Desdobramento<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='recursoDesdobramento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('numeroPasta')">
                                        Pasta <i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='numeroPasta'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('vencimento')">
                                        Vencimento<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='vencimento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('dataLimitePagamento')">
                                        Data Limite Pagamento<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='dataLimitePagamento'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('tipoDespesa')">
                                        Faturamento<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='tipoDespesa'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('tipoDespesa')">
                                        Tipo Despesa<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='tipoDespesa'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('debito')">
                                        Valor Adiantado <i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='debito'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer"
                                        (click)="setSortExtratoConsolidado('credito')">
                                        Valor Reembolsado<i class="ion text-muted ml-2"
                                            *ngIf="sortByNotaDebitoPendente =='credito'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                    <th class="text-nowrap cursor-pointer" (click)="setSortExtratoConsolidado('saldo')">
                                        Saldo<i class="ion text-muted ml-2" *ngIf="sortByNotaDebitoPendente =='saldo'"
                                            [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                    </th>
                                </tr>

                            </thead>
                            <tbody>

                                <tr class="tabela" *ngFor="let extrato of listExtratoConsolidadoFiltrada">
                                    <td>{{extrato.nomeCliente}}</td>
                                    <td>{{ extrato.recursoDesdobramento }}</td>
                                    <td>{{ extrato.numeroPasta }}</td>
                                    <td>{{ extrato.vencimento | date : "dd/MM/yyyy" }}</td>
                                    <td>{{ extrato.dataLimitePagamento | date : "dd/MM/yyyy" }}</td>
                                    <td>{{ extrato.dataFaturamento | date : "dd/MM/yyyy" }}</td>
                                    <td>{{ extrato.tipoDespesa }}</td>
                                    <td>{{ extrato.debito | currency : "BRL" }}</td>
                                    <td>{{ extrato.credito | currency : "BRL" }}</td>
                                    <td>{{ extrato.saldo | currency : "BRL" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-body py-0" *ngIf="totalItens > 0">
                        <div class="row">
                            <div class="col-sm text-sm-left text-center pt-3">
                                <span class="text-muted">Páginas {{ paginaAtual }} de {{
                                    totalPaginas }}</span>
                            </div>
                            <div class="col-sm text-sm-left text-center pt-3">
                                <label>Itens por página:</label>
                                <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                                    (change)="definirPaginacao(1)">
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select>
                            </div>
                            <div class="col-sm pt-3">
                                <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                                    [(page)]="paginaAtual" [boundaryLinks]="true"
                                    (pageChange)="definirPaginacao(paginaAtual)" size="sm"
                                    class="d-flex justify-content-center justify-content-sm-end m-0">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-5"
                        *ngIf="!loadExtratoConsolidado && (!listExtratoConsolidadoFiltrada || listExtratoConsolidadoFiltrada.length == 0)">
                        <h3>Não possui dados.</h3>
                    </div>
                    <div class="text-center mt-5" *ngIf="loadFatuamento">
                        <div class="align-items-center">
                            <h3>Carregando dados...</h3>
                            <div class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>

    </ul>
    <div [ngbNavOutlet]="productTabs"></div>

    <br />
    <br />
</div>