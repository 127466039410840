
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { OrgaosService } from 'src/app/services/orgaos.service';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';

@Component({
    selector: 'app-modal-cadastro-orgaos',
    templateUrl: './modal-cadastro-orgaos.component.html',
    providers: [OrgaosService]
})

export class ModalCadastroOrgaosComponent implements OnInit {

    modalRef: NgbModalRef;
    public orgao: any;
    public listaEstados = [];

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private appService: AppService,
        private orgaosService: OrgaosService,
        private dadosCargasService: DadosCargasService,
    ) {

    }

    popularNovoOrgao() {
        this.orgao = {
            nome: '',
            email: '',
            telefone1: '',
            telefone2: '',
            logradouro: '',
            complemento: '',
            bairro:'',
            cidade:'',
            estado:'',
            cep:'',
        };
    }

    ngOnInit(): void {
        this.popularNovoOrgao();
        this.listaEstados = this.dadosCargasService.mockEstados();
    }

    cadastrar() {
        this.add(this.orgao);
    }

    add(orgao) {
        this.spinner.show();
        this.toastrService.clear();
        this.orgaosService.add(orgao)
            .subscribe(res => {
                this.toastrService.success("Órgão cadastrado.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o Órgão!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}

