import { ProcessoArquivoResponse } from './../models/operacionais/operacao/processo-arquivo-response';
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProcessoTagRequest } from '../models/operacionais/operacao/processo-tag-request';
import { Guid } from 'guid-typescript';
import { ProcessoArquivo } from '../models/operacionais/operacao/processo-arquivo';
import { ProcessoOperacaoResponse } from '../models/operacionais/operacao/processo-operacao-response';

@Injectable({
    providedIn: 'root'
})
export class ProcessosService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'processo/';
    public postFileUrl = environment.api + this.endpoint + 'arquivo';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint + "getAll")
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getProcessoOriginaisProcessoAcordos(processoId): Observable<any> {
      return this.http.get(environment.api + this.endpoint + `processosOriginaisProcessosAcordo/${processoId}`)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }



    getProcessosOrfaos(): Observable<any> {
        return this.http.get(environment.api + this.endpoint + "orfaos")
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    updateCodigoExternoDevedor(codigoExternoDevedor,processoId): Observable<any> {
      return this.http.put(environment.api + this.endpoint + `update/codigoExternoDevedor/${processoId}?codigoExternoDevedor=${codigoExternoDevedor}`, null)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    deleteRange(ids): Observable<any> {
        
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
          body: ids
        };
      
        return this.http.delete<any>(environment.api + this.endpoint, httpOptions)
          .pipe(
            map(this.extractData),
            catchError(this.serviceError)
          );
      }

    getCobradores(credor): Observable<any> {
        return this.http.get(environment.api + this.endpoint + `resumo/cobrador?credorId=${credor}&limite=9999999`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    associarProcessoAoCobrador(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + "cobradores", data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    associarProcessoTag(processoTagRequest: ProcessoTagRequest) {
        return this.http.post(environment.api + this.endpoint + "tag", processoTagRequest)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    async uploadArquivo(data) {
        return await this.http.post(this.postFileUrl, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

    getAllProcessoArquivo(processoId: Guid): Observable<ProcessoArquivoResponse> {
        return this.http.get(environment.api + this.endpoint + `arquivo/${processoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    downloadArquivo(processoArquivo: ProcessoArquivo) {
        let url = environment.api + this.endpoint + "arquivo/download/" + processoArquivo.id;

        return this.http.request(new HttpRequest(
            'GET',
            url,
            null,
            {
                reportProgress: true,
                responseType: 'blob'
            }));
    }

    deleteArquivo(processoId, arquivoId): Observable<any> {
      return this.http
          .delete(
              environment.api +
              this.endpoint +
              `arquivo/${processoId}/${arquivoId}`
          )
          .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getById(processoId): Observable<ProcessoOperacaoResponse> {
        return this.http.get(environment.api + this.endpoint + `${processoId}`).pipe(
            map(this.extractData),
            catchError(this.serviceError)
        )
    }


    getByIdSimplificado(processoId): Observable<any> {
      return this.http.get(environment.api + this.endpoint + `getByIdSimplificado/${processoId}`).pipe(
          map(this.extractData),
          catchError(this.serviceError)
      )
  }

    updatePercentuaisProcesso(id,data): Observable<any> {
      return this.http.put(environment.api + this.endpoint +
        `update/processo/percentuais/${id}`, data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }


    updateTaxaAdministrativa(id,taxaAdministrativa): Observable<any> {
      return this.http.put(environment.api + this.endpoint +
        `update/percentualTaxaAdministrativa/${id}?taxaAdministrativa=${taxaAdministrativa}`, null)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    updatePercentualMulta(id,percentualMulta): Observable<any> {
      return this.http.put(environment.api + this.endpoint +
        `update/percentualMulta/${id}?percentualMulta=${percentualMulta}`, null)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    updatePercentualJuros(id,percentualJuros): Observable<any> {
      return this.http.put(environment.api + this.endpoint +
        `update/percentualJuros/${id}?percentualJuros=${percentualJuros}`, null)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }



    async getCalculosJurosEMulta(data) {
      return this.http.post(environment.api + this.endpoint + "jurosemulta/calculo", data).toPromise();
          // .pipe(
          //     map(this.extractData),
          //     catchError(this.serviceError)
          // );
    }

    addAvalista(data): Observable<any> {
      return this.http.post(environment.api + this.endpoint + 'processoAvalista/add', data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    addAdministrador(data): Observable<any> {
      return this.http.post(environment.api + this.endpoint + 'processoAdministrador/add', data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getAvalistas(processoId): Observable<any> {
      return this.http.get(environment.api + this.endpoint + `processoAvalista/getByProcesso/${processoId}`)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getAdministradores(processoId): Observable<any> {
      return this.http.get(environment.api + this.endpoint + `processoAdministrador/getByProcesso/${processoId}`)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    deleteAvalista(id): Observable<any> {
      return this.http
          .delete(
              environment.api +
              this.endpoint +
              `processoAvalista/remove/${id}`
          )
          .pipe(map(this.extractData), catchError(this.serviceError));
    }

    deleteAdministrador(id): Observable<any> {
      return this.http
          .delete(
              environment.api +
              this.endpoint +
              `processoAdministrador/remove/${id}`
          )
          .pipe(map(this.extractData), catchError(this.serviceError));
    }



}
