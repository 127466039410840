import { AtividadeResponse } from './../../../../models/operacionais/atividade/atividade-response';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Tipo } from 'src/app/enums/documento/tipo.enum';
import { TipoSituacao } from 'src/app/enums/situacao/tipo-situacao.enum';
import { TipoTag } from 'src/app/enums/tag/tipo-tag.enum';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { AtividadeRequest } from 'src/app/models/operacionais/atividade/atividade-request';
import { OcorrenciaTag } from 'src/app/models/operacionais/ocorrencia/ocorrencia-tag';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { SelectList } from 'src/app/models/select-list';
import { SelectListMultiSelect } from 'src/app/models/select-list-multi-select';
import { AtividadeService } from 'src/app/services/atividade.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { SelectListService } from 'src/app/services/shared/select-list.service';
import { SituacaoService } from 'src/app/services/situacao.service';
import { TagService } from 'src/app/services/tag.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { AtividadeTarefaRequest } from 'src/app/models/operacionais/atividade/atividade-tarefa-request';
import { TipoStatus, TipoStatusLabel } from 'src/app/enums/tipo-status.enum';
import { Prioridade } from 'src/app/enums/prioridade.enum';
import { Moment } from 'moment';
import * as moment from "moment";
import { IDatePickerConfig } from 'ng2-date-picker';
import { OcorrenciaAtualizarStatus, OcorrenciaStatusRequest } from 'src/app/models/operacionais/ocorrencia/ocorrencia-status-request';
import { OcorrenciaResponse } from 'src/app/models/operacionais/ocorrencia/ocorrencia-response';
import { AuthService } from 'src/app/auth/auth.service';


const now = new Date();

@Component({
    selector: 'app-modal-adicionar-atividade',
    templateUrl: './modal-adicionar-atividade.component.html',
    styleUrls: [
        '../../../../../vendor/styles/pages/dropzone.scss',
        './modal-adicionar-atividade.component.css'
    ]
})
export class ModalAdicionarAtividadeComponent implements OnInit {

    modalRef: NgbModalRef;
    isIE10 = false;
    public isRTL: boolean;

    public dataAtividadePlanejada: Moment;
    public tipoDocumentos: SelectList[] = [];
    public situacoesPaiList: SelectList[] = [];
    public situacoesList: SelectList[] = [];

    public dropdownSettings = {
        singleSelection: false,
        text: "Selecione tags",
        selectAllText: 'Selecione todas',
        unSelectAllText: 'Remova todas',
        enableSearchFilter: true,
        classes: "multi-select-myclass"
    };

    public novosAnexos: any[] = [];;
    public files: File[] = [];
    public isHovering: boolean;
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    public hasAnotherDropZoneOver: boolean;
    public response: string;
    public tiposDocumentos: any;

    public processo: ProcessoOperacao;
    public atividade: AtividadeRequest;

    public tags: SelectListMultiSelect[] = [];
    public tagsSelecionadas: SelectListMultiSelect[] = [];

    inputNovaTarefa: String;
    @ViewChild('inputNovaTarefaEl') inputNovaTarefaElement: ElementRef;


    prioridadeSelectList: SelectList[] = [];

    public displayMonths = 2;
    public navigation = 'select';
    public disabled = false;

    title: any;

    public options: { readonly?: Boolean, previousTab?: String };

    datePickerConfig: IDatePickerConfig = {
        format: "DD/MM/YYYY HH:mm",
        firstDayOfWeek: "mo",
        locale: moment.locale(),
        hours24Format: "HH",
        showTwentyFourHours: true
    };

    ocorrenciaId: Guid;
    isMaster: Boolean;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public documentoService: DocumentoService,
        private selectListService: SelectListService,
        public atividadeService: AtividadeService,
        public ocorrenciaService: OcorrenciaService,
        public situacaoService: SituacaoService,
        public tagService: TagService,
        public utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        authService: AuthService
    ) {
        this.isRTL = appService.isRTL;

        this.uploader = new FileUploader({
            url: ocorrenciaService.postFileUrl,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise<void>((resolve, reject) => {
                    resolve();
                });
            }
        });

        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);

        this.isIE10 = this.appService.isIE10;
        this.isMaster = authService.isMaster();
    }

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processo;
        this.novosAnexos = [];
        this.atividade = new AtividadeRequest();

        this.situacaoService.getAllPaiByTipo(TipoSituacao.Atividade).subscribe(res => {
            this.situacoesPaiList = res.data.map(e => { return new SelectList(e.id.toString(), `${e.idExterno} - ${e.nome}`) });
        });

        this.tagService.getAllByTipo(TipoTag.Atividade).subscribe(res => {
            this.tags = this.selectListService.getTagToMultiSelect(res.data);
        });

        this.documentoService.getAllByTipo(Tipo.Atividade).subscribe(res => {
            this.tipoDocumentos = res.data.map(e => { return new SelectList(e.id.toString(), e.nome) });
        });

        this.prioridadeSelectList = this.selectListService.getPrioridade();

        if (this.modalRef.componentInstance.updateData) {
            this.ocorrenciaId = this.modalRef.componentInstance.updateData.ocorrenciaId;

            this.atividadeService.getById(this.ocorrenciaId).subscribe(res => {
                this.atividade = res.data;
                this.options.readonly = this.isAtividadeReaonly();
                this.title = `Atualizar atividade - ${TipoStatusLabel.get(this.atividade.status)} - Processo #${this.processo.idExterno}`;
                this.tagsSelecionadas = this.atividade.ocorrenciaTags.map(res => { return new SelectListMultiSelect(res.tagId, res.nome); });

                this.atividade.planejado = new Date(this.atividade.planejado);

                this.dataAtividadePlanejada = moment(this.atividade.planejado);

                this.onChangeSituacao(this.atividade.situacaoId);
            });
        } else {
            this.title = `Nova atividade - Processo #${this.processo.idExterno}`;
        }
    }

    enviarAprovacao() {
        this.atividade.status = TipoStatus.OcorrenciaPendenteAprovacao;

        this.salvar();
    }

    salvar() {
        if (this.validate()) {
            if (this.modalRef.componentInstance.updateData) {
                this.update();
            } else {
                this.add();
            }
        }
    }

    validate() {
        if (!this.atividade.nome) {
            this.toastrService.error("Campo de Nome é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }

        if (!this.atividade.prioridade) {
            this.toastrService.error("Campo de Propriedade é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }

        if (!this.atividade.situacaoPaiId) {
            this.toastrService.error("Campo de Situação é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }

        if (!this.atividade.situacaoId) {
            this.toastrService.error("Campo de Situação Sub é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }

        if (!this.dataAtividadePlanejada) {
            this.toastrService.error("Campo de Data é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }

        if (!moment(this.dataAtividadePlanejada).isValid()) {
            this.toastrService.error("Campo de Data/Hora está inválido, preencha no formato dd/mm/yyyy 00:00", 'Atenção', { timeOut: 10000 });
            return false;
        }

        return true;
    }

    update() {
        this.preSave();

        this.atividadeService.update(this.atividade)
            .subscribe((res: AtividadeResponse) => {
                this.postSave(res);
            }, error => {
                this.spinner.hide();

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível criar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
            });
    }

    add() {
        this.preSave();

        this.atividadeService.add(this.atividade)
            .subscribe((res: AtividadeResponse) => {
                this.postSave(res);
            }, error => {
                this.spinner.hide();

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível criar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
            });
    }

    preSave() {
        this.spinner.show();
        this.toastrService.clear();

        if (this.dataAtividadePlanejada) {
            this.atividade.planejado = this.dataAtividadePlanejada.toDate();
        }

        this.atividade.processoId = this.processo.id;
        this.atividade.ocorrenciaTags = this.tagsSelecionadas.map(x => {
            return new OcorrenciaTag(Guid.parse(x.id.toString()).toString());
        });
    }

    postSave(res: AtividadeResponse) {
        this.atividade.ocorrenciaId = res.data.ocorrenciaId;

        if (this.uploader != null && this.uploader.queue.length > 0) {
            this.enviarArquivos().subscribe(result => {
            }, error => {
                this.spinner.hide();
                this.toastrService.error(error, 'Atenção');
            }, () => { //complete
                this.spinner.hide();

                this.toastrService.success("Ocorrência adicionada.", "Sucesso");

                setTimeout(() => {
                    this.close(this.processo);
                }, 300);
            });
        } else {
            this.spinner.hide();

            this.toastrService.success("Ocorrência adicionada.", "Sucesso");

            setTimeout(() => {
                this.close(this.processo);
            }, 300);
        }
    }

    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalContatoProcesso(processo, { previousTab: "tabOcorrencias" });
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    adicionarTarefa() {
        if (this.inputNovaTarefa) {
            const ordem = this.atividade.atividadeTarefas.length + 1;

            this.atividade.atividadeTarefas.push(new AtividadeTarefaRequest(this.inputNovaTarefa, ordem));
            this.inputNovaTarefa = "";
            this.inputNovaTarefaElement.nativeElement.focus();
        }
    }

    removerTarefa(tarefa) {
        this.atividade.atividadeTarefas = this.atividade.atividadeTarefas.filter(item => item.ordem !== tarefa.ordem);

        this.atividade.atividadeTarefas.forEach((item, idx) => {
            item.ordem = idx + 1;
        });
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(fileList) {
        for (var i = 0; i < fileList.length; i++) {
            const event = fileList[i];
            const fileInQueue = this.uploader.queue.find(q => q.file.name === event.name);
            const newFile = new CustomFileLike(fileInQueue.file);

            newFile.documentType = this.tipoDocumentos[0].key.toString();
            newFile.file = event;

            fileInQueue.file = newFile;
        }
    }

    onTipoDocumentChange(index, customFile) {
        customFile.documentType = this.tipoDocumentos[index].key;
    }

    excluirNovoAnexo(novoAnexo) {
        this.novosAnexos = this.novosAnexos.filter(c => c.name != novoAnexo.name);
    }

    onChangeSituacao(situacaoSelected: Guid) {
        this.situacaoService.getAllByPai(this.atividade.situacaoPaiId).subscribe(res => {
            this.situacoesList = res.data.map(e => { return new SelectList(e.id.toString(), `${e.idExterno} - ${e.nome}`) });

            this.atividade.situacaoId = situacaoSelected;
        });
    }

    enviarArquivos(): Observable<boolean> {
        const self = this;
        const envioArquivos = new Observable<boolean>((observer) => {
            let canceled = false;

            async function engine() {
                const arquivosUpload = [];

                self.uploader.queue.forEach(item => {
                    arquivosUpload.push(self.montarFormDataArquivo(item.file));
                });

                await self.utilsService.asyncForEach(arquivosUpload, async (arquivo, idx) => {
                    await self.ocorrenciaService.uploadArquivo(arquivo).then(result => {
                        if (result.success) {
                            self.uploader.clearQueue();

                            observer.next(true);
                        } else {
                            if (result.data && result.data.errors && result.data.errors.length > 0) {
                                result.data.errors.forEach(element => {
                                    observer.error(element);
                                });
                            } else {
                                observer.error('Houve algum problema ao salvar os documentos');
                            }

                            return;
                        }

                        if (arquivosUpload.length == idx + 1) {
                            observer.complete();
                        }
                    }).catch(error => {
                        if (error && error.errors) {
                            error.errors.forEach(element => {
                                observer.error(element);
                            });
                        } else {
                            observer.error('Houve algum problema ao salvar os documentos');
                        }

                        return;
                    });
                });
            }

            engine();
            return () => canceled = true;
        });

        return envioArquivos;
    }

    montarFormDataArquivo(item: FileLikeObject) {
        const file = item as CustomFileLike;
        const formData: FormData = new FormData();

        formData.append('ocorrenciaId', this.atividade.ocorrenciaId.toString());
        formData.append('pasta', 'ocorrencias');
        formData.append('tipoDocumentoId', file.documentType);
        formData.append('nome', file.name);
        formData.append('contentType', file.type);
        formData.append('file', file.file as File);

        return formData;
    }

    isTarefaFinalizada(tarefa: AtividadeTarefaRequest) {
        return tarefa.status == TipoStatus.AtividadeTarefaFinalizada;
    }

    isAllTarefaFinalizada() {
        return this.atividade.status != TipoStatus.OcorrenciaFinalizada && this.atividade.status != TipoStatus.OcorrenciaPendenteAprovacao && this.atividade.atividadeTarefas.length > 0 && this.atividade.atividadeTarefas.every(atividade => this.isTarefaFinalizada(atividade));
    }

    isAtividadeReaonly() {
        return this.atividade.status == TipoStatus.OcorrenciaFinalizada || this.options.readonly;
    }

    changeTarefaStatus(tarefa: AtividadeTarefaRequest) {
        tarefa.status = tarefa.status == TipoStatus.AtividadeTarefaAberta ? TipoStatus.AtividadeTarefaFinalizada : TipoStatus.AtividadeTarefaAberta;
    }

    aprovarOcorrencia() {
        this.updateStatus(TipoStatus.OcorrenciaFinalizada);
    }

    reprovarOcorrencia() {
        this.updateStatus(TipoStatus.OcorrenciaPendenteCobrador);
    }

    isShowAprovar() {
        return this.isMaster && (this.atividade.status == TipoStatus.OcorrenciaPendenteAprovacao || this.atividade.status == TipoStatus.OcorrenciaPendenteCobrador);
    }

    isShowReprovar() {
        return this.isMaster && this.atividade.status == TipoStatus.OcorrenciaPendenteAprovacao;
    }

    private updateStatus(status: TipoStatus) {

        let ocorrenciaAtualizarStatus: OcorrenciaAtualizarStatus = {
            id: this.ocorrenciaId,
            processoId: this.processo.id
        }
        
        let ocorrenciaStatusRequest = new OcorrenciaStatusRequest(status, [ocorrenciaAtualizarStatus]);

        this.ocorrenciaService.updateStatus(ocorrenciaStatusRequest)
            .subscribe((res: OcorrenciaResponse) => {
                this.spinner.hide();

                this.toastrService.success("Ocorrência atualizada.", "Sucesso");

                setTimeout(() => {
                    this.close(this.processo);
                }, 300);
            }, error => {
                this.spinner.hide();

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
            });
    }
}
