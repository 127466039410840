import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { AtendimentoService } from "src/app/services/atendimento.service";
import { TagService } from "src/app/services/tag.service";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProcessoCasoAtendimentoDetalhesService } from "src/app/services/processo-caso-atendimento-detalhes.service";
import { PessoaDropdownComponent } from "../../components/pessoa-dropdown/pessoa-dropdown.component";

@Component({
  selector: "criar-atendimento",
  templateUrl: "./criar-atendimento.component.html",
  styleUrls: ["./criar-atendimento.component.css"],
})
export class CriarAtendimentoComponent implements OnInit {
  @ViewChild("clienteDropdown") clienteDropdown!: PessoaDropdownComponent;
  @ViewChild("responsavelDropdown")
  responsavelDropdown!: PessoaDropdownComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public tagService: TagService,
    public atendimentoService: AtendimentoService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private processoCasoAtendimentoDetalhesService: ProcessoCasoAtendimentoDetalhesService
  ) {}

  public referenciaId: any;

  public tipo: any;

  public id: string;

  public isEdit: boolean;

  public atendimento: any = {};

  public clientes: any = [{}];
  public tags: any = [];

  public tagsdata: any = [];
  public atendimentoDetalhes: any;

  public loadTags: boolean = false;

  public atendimentosCasosEProcessos = [];
  public loadAtendimentosCasosEProcessos = true;
  public atendimentosCasosEProcessosSelecionado: string = null;

  public tagDropdownSettings = {
    singleSelection: false,
    primaryKey: "tagId",
    text: "",
    noDataLabel: "",
    enableSearchFilter: true,
    disabled: false,
    selectAllText: "Selecione Todas",
    unSelectAllText: "Deselecionar Todas",
    searchPlaceholderText: "Procurar",
    classes: "multiselect-tags",
    position: "bottom",
    autoPosition: false,
  };

  private swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-padrao btn-lg mr-3",
      cancelButton: "btn btn-default btn-lg",
    },
    buttonsStyling: false,
  });

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params["id"];

    this.isEdit = this.id != undefined;

    this.referenciaId = this.activatedRoute.snapshot.params["referenciaId"];
    this.tipo = this.activatedRoute.snapshot.params["tipo"];

    if (!this.isEdit) {
      this.buscarTags();
      this.buscarAtendimentoDetalhes();
      this.carregarAtendimentosCasosEProcessos();
    } else {
      this.spinner.show();
      this.atendimentoService.obterParaEdicao(this.id).subscribe((res) => {
        console.log(res.data);
        this.referenciaId = res.data.registroId;
        this.tipo = res.data.tipoReferencia;
        this.buscarTags(res.data.tags);
        this.buscarAtendimentoDetalhes();
        this.atendimento = res.data;

        if (res.data.clientes != null && res.data.clientes != undefined) {
          this.clientes = [];
          res.data.clientes.forEach((c) => {
            this.clientes.push({ pessoaId: c });
          });
        }

        this.spinner.hide();
      });
    }
  }

  buscarAtendimentoDetalhes() {
    if (this.tipo != undefined && this.referenciaId != undefined) {
      this.atendimentoService
        .buscarDetalhesAtendimento(this.tipo, this.referenciaId)
        .subscribe((res) => {
          if (res.success) {
            this.atendimentoDetalhes = res.data;
            this.atendimento.responsavelId = res.data.responsavelId;
          }
        });
    }
  }

  buscarTags(tags: any = null) {
    this.loadTags = true;
    this.tagService.getAllAsync().then((x) => {
      this.loadTags = false;
      x.data.forEach((element, index) => {
        this.tagsdata.push({
          tagId: element.id,
          tagNome: element.nome,
          tagCor: element.cor,
        });
      });
      if (tags != null && tags != undefined) {
        this.tags = [];
        tags.forEach((c) => {
          var objectTag = x.data.find((x) => x.id == c);
          this.tags.push({
            tagId: objectTag.id,
            tagNome: objectTag.nome,
            tagCor: objectTag.cor,
          });
        });
      }
    });
  }

  adicionarCliente() {
    this.clientes.push({});
  }

  cancel() {
    this.location.back();
  }

  onSubmit() {
    this.atendimento.clientes = [];

    this.atendimento.tags = [];

    if (this.tipo != undefined && this.tipo != null)
      this.atendimento.tipoReferencia = this.tipo;

    if (this.referenciaId) {
      this.atendimento.registroId = this.referenciaId;
    } else if (this.atendimentosCasosEProcessosSelecionado) {
      const atendimentosCasosEProcessos = this.atendimentosCasosEProcessos.find(
        (c) => c.registroUnicoId == this.atendimentosCasosEProcessosSelecionado
      );
      this.atendimento.registroId = atendimentosCasosEProcessos.id;
      this.atendimento.tipoReferencia = atendimentosCasosEProcessos.tipoId;
    }

    let valido: boolean = true;

    this.clientes.forEach((cliente) => {
      if (valido && cliente.pessoaId == undefined) {
        this.swalWithBootstrapButtons.fire(
          "Não foi selecionado o cliente!",
          "",
          "error"
        );
        valido = false;
        return;
      }

      this.atendimento.clientes.push(cliente.pessoaId);
    });

    if (!valido) {
      return;
    }

    this.tags.forEach((tag) => {
      this.atendimento.tags.push(tag.tagId);
    });

    if (
      this.atendimento.assunto == undefined ||
      this.atendimento.assunto == ""
    ) {
      valido = false;

      this.swalWithBootstrapButtons.fire(
        "Não foi informado o assunto!",
        "",
        "error"
      );

      return;
    }

    if (
      this.atendimento.responsavelId == undefined ||
      this.atendimento.responsavelId == ""
    ) {
      valido = false;

      this.swalWithBootstrapButtons.fire(
        "Não foi informado o responsável!",
        "",
        "error"
      );

      return;
    }

    if (
      this.atendimento.primeiroRegistro == undefined ||
      this.atendimento.primeiroRegistro == ""
    ) {
      valido = false;

      this.swalWithBootstrapButtons.fire(
        "Não foi informado o primeiro registro!",
        "",
        "error"
      );

      return;
    }

    if (valido) {
      this.spinner.show();
      this.toastrService.clear();

      let request = !this.isEdit
        ? this.atendimentoService.add(this.atendimento)
        : this.atendimentoService.update(this.atendimento);

      request.subscribe(
        (res) => {
          if (res.success) {
            this.spinner.hide();
            this.location.back();
            this.toastrService.success("Atendimento cadastrado.", "Sucesso");
          } else {
            this.spinner.hide();
            this.toastrService.success(
              "Erro ao cadastrar Atendimento.",
              "Sucesso"
            );
          }
        },
        (error) => {
          if (error && error.errors) {
            this.toastrService.error(error.errors[0], "Atenção", {
              timeOut: 10000,
            });
          } else {
            this.toastrService.error(
              "Não foi possível cadastrar o Atendimento!",
              "Atenção",
              { timeOut: 10000 }
            );
          }
          this.spinner.hide();
        }
      );
    }
  }

  buscarPessoaPeloAtendimentoSelecionado() {
    const atendimentosCasosEProcessos = this.atendimentosCasosEProcessos.find(
      (c) => c.registroUnicoId == this.atendimentosCasosEProcessosSelecionado
    );

    if (!this.clientes || this.clientes.length == 0) this.adicionarCliente();

    if (!atendimentosCasosEProcessos) return;

    this.responsavelDropdown.selecionarPessoa(
      atendimentosCasosEProcessos.responsavelId
    );
    atendimentosCasosEProcessos.clientesPessoaId.forEach((pessoaId) =>
      this.clienteDropdown.selecionarPessoa(pessoaId)
    );
  }

  carregarAtendimentosCasosEProcessos() {
    this.loadAtendimentosCasosEProcessos = true;
    this.processoCasoAtendimentoDetalhesService.getAll().subscribe((res) => {
      if (res.success) {
        this.loadAtendimentosCasosEProcessos = false;
        this.atendimentosCasosEProcessos = res.data;
      }
    });
  }

  aplicarFiltroAtendimentoCasoProcesso(term: string, item: any) {
    term = term.toLowerCase();

    return (
      item.titulo.toLowerCase().indexOf(term) > -1 ||
      (item.hasOwnProperty("pasta") &&
        item.pasta &&
        item.pasta.toString().indexOf(term) > -1) ||
      (item.hasOwnProperty("numeroProcesso") &&
        item.numeroProcesso &&
        item.numeroProcesso.toString().indexOf(term) > -1) ||
      (item.hasOwnProperty("recursoDesdobramento") &&
        item.recursoDesdobramento &&
        item.recursoDesdobramento.toString().indexOf(term) > -1)
    );
  }

  removerCliente(clienteIndex) {
    this.clientes.forEach((element, index) => {
      if (index == clienteIndex) {
        this.clientes.splice(index, 1);
      }
    });
  }
}
