import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ServiceBase } from "./service.base";

@Injectable({
  providedIn: "root",
})
export class DashService extends ServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  public endpointDash = "dash/";
  public endpointDashboard = "dashboard/";

  getDashBorderosNaoEnviados(): Observable<any> {
    return this.http
      .get(environment.api + this.endpointDash + "borderosNaoEnviados")
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getDashDocumentosAnexados(): Observable<any> {
    return this.http
      .get(environment.api + this.endpointDash + "documentosAnexados")
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getDashUltimoAcessoDeUsuarioNoSistema(): Observable<any> {
    return this.http
      .get(
        environment.api + this.endpointDash + "ultimoAcessoDeUsuarioNoSistema"
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getDashBorderosCadastrados() {
    return this.http
      .get(environment.api + this.endpointDash + "borderosCadastrados")
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getTotalPublicacoesPorDia() {
    return this.http
      .get(environment.api + this.endpointDashboard + "total-publicacoes-por-dia")
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  //?dataInicial=2022-02-13&dataFinal=2023-04-13
  getTotalPublicacoesPorEstado(params) {
    return this.http
      .get(environment.api + this.endpointDashboard + "total-por-estado", {params})
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getTotalPublicacoesPorResp(params) {
    return this.http
      .get(environment.api + this.endpointDashboard + "total-por-responsavel", {params})
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterGraficoAtendimentosPorCliente(params) {
    return this.http
      .get(environment.api + this.endpointDashboard + "grafico-atendimentos-por-cliente", {params})
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterGraficoAtendimentosNovosXEncerrados(params) {
    return this.http
      .get(environment.api + this.endpointDashboard + "grafico-atendimentos-novos-x-encerrados", {params})
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterGraficoAtendimentosEncerradosPorSituacaoEncerramento(params) {
    return this.http
      .get(environment.api + this.endpointDashboard + "grafico-atendimentos-encerrados-por-situacao-encerramento", {params})
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterGraficoAtendimentosPorResponsavel(params) {
    return this.http
      .get(environment.api + this.endpointDashboard + "grafico-atendimentos-por-responsavel", {params})
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
