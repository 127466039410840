import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  TipoCampoFormulario,
  TipoCampoFormularioLabel,
} from "../enums/formulario-dinamico/tipo-campo-formulario-dinamico.enum";
import { SelectList } from "../models/select-list";
import { ServiceBase } from "./service.base";

@Injectable({
  providedIn: "root",
})
export class FormularioDinamicoService extends ServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  public endpoint = "FormularioDinamico/";
  private dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  obterSelectListTipoCampoFormulario(): SelectList[] {
    var selectList: SelectList[] = [];

    selectList.push(
      new SelectList(
        TipoCampoFormulario.Texto.toString(),
        TipoCampoFormularioLabel.get(TipoCampoFormulario.Texto)
      )
    );
    selectList.push(
      new SelectList(
        TipoCampoFormulario.Numerico.toString(),
        TipoCampoFormularioLabel.get(TipoCampoFormulario.Numerico)
      )
    );
    selectList.push(
      new SelectList(
        TipoCampoFormulario.Monetario.toString(),
        TipoCampoFormularioLabel.get(TipoCampoFormulario.Monetario)
      )
    );
    selectList.push(
      new SelectList(
        TipoCampoFormulario.Combobox.toString(),
        TipoCampoFormularioLabel.get(TipoCampoFormulario.Combobox)
      )
    );
    selectList.push(
      new SelectList(
        TipoCampoFormulario.MultiSelect.toString(),
        TipoCampoFormularioLabel.get(TipoCampoFormulario.MultiSelect)
      )
    );
    selectList.push(
      new SelectList(
        TipoCampoFormulario.Data.toString(),
        TipoCampoFormularioLabel.get(TipoCampoFormulario.Data)
      )
    );

    return selectList;
  }

  add(data): Observable<any> {
    return this.http
      .post(environment.api + this.endpoint, data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  adicionarCampo(data): Observable<any> {
    return this.http
      .post(environment.api + this.endpoint + 'adicionar-campo', data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  atualizarFormulario(data): Observable<any> {
    return this.http
      .put(`${environment.api}${this.endpoint}atualizar-formulario`, data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  atualizarCampo(data): Observable<any> {
    return this.http
      .put(`${environment.api}${this.endpoint}atualizar-campo`, data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getAll(): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getById(id: any): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + id)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterFormulariosProcesso(): Observable<any> {
    return this.http
      .get(`${environment.api}${this.endpoint}obter-formularios-processo`)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterValoresProcessoJudicialFormularioDinamico(
    processoJudicialId: Guid
  ): Observable<any> {
    return this.http
      .get(
        `${environment.api}${this.endpoint}obter-valores-processo-judicial-formulario-dinamico/${processoJudicialId}`
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  removerFormulario(formularioId): Observable<any> {
    return this.http
      .delete(`${environment.api}${this.endpoint}${formularioId}`)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  removerCampo(campoId): Observable<any> {
    return this.http
      .delete(`${environment.api}${this.endpoint}remover-campo/${campoId}`)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
