import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-label-slide-toggle",
  templateUrl: "./label-slide-toggle.component.html",
  styleUrls: ["./label-slide-toggle.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LabelSlideToggleComponent implements OnInit {
  @Input() tooltip: string;
  @Input() label: string;
  @Input() classLabel: string;
  @Input() model: boolean;

  @Output() change = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  changeTest(event) {
    console.log(event)
  }
}
