<div class="row">
    <div class="col-12">
        <h6>Evento ({{index}})</h6>
    </div>
    <div class="col-12">
        <label class="form-label">Título do evento<span class="required-field">*</span></label>
        <input class="form-control" placeholder="Digite o título do evento"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="evento.titulo">
    </div>
    <div class="col-12">
        <div class="form-check-inline">
            <input class="form-check-input" type="checkbox" value="" id="diaInteiro"
                [(ngModel)]="evento.diaInteiro">
            <label class="form-check-label" for="diaInteiro">
                Dia inteiro
            </label>
        </div>
    </div>
    <div class="col-8" *ngIf="!evento.diaInteiro">
        <label class="form-label">De<span class="required-field ml-1">*</span></label>
        <div class="input-group">
            <input (click)="dataHoraInicialEvento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataHoraInicialEventoComponent" ngbDatepicker
                [(ngModel)]="evento.dataHoraInicialComponent"
                placement="bottom-right" #dataHoraInicialEvento="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="dataHoraInicialEvento.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="!evento.diaInteiro">
        <label class="form-label">&nbsp;</label>
        <input type="time" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="evento.dataHoraInicialTimeComponent">
    </div>

    <div class="col-8" *ngIf="!evento.diaInteiro">
        <label class="form-label">Até<span class="required-field ml-1">*</span></label>
        <div class="input-group">
            <input (click)="dataHoraFinalEvento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataHoraFinalEventoComponent" ngbDatepicker [(ngModel)]="evento.dataHoraFinalComponent"
                placement="bottom-right"
                #dataHoraFinalEvento="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="dataHoraFinalEvento.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="!evento.diaInteiro">
        <label class="form-label">&nbsp;</label>
        <input type="time" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="evento.dataHoraFinalTimeComponent">
    </div>

    <div class="col-6" *ngIf="evento.diaInteiro">
        <label class="form-label">De<span class="required-field ml-1">*</span></label>
        <div class="input-group">
            <input (click)="dataHoraInicialEvento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataHoraInicialEventoComponent" ngbDatepicker
                [(ngModel)]="evento.dataHoraInicialComponent"
                placement="bottom-right" #dataHoraInicialEvento="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="dataHoraInicialEvento.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="evento.diaInteiro">
        <label class="form-label">Até<span class="required-field ml-1">*</span></label>
        <div class="input-group">
            <input (click)="dataHoraFinalEvento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataHoraFinalEventoComponent" ngbDatepicker [(ngModel)]="evento.dataHoraFinalComponent"
                placement="bottom-right"
                #dataHoraFinalEvento="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="dataHoraFinalEvento.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-2">
        <label class="form-label">Alerta</label>
        <input type="number" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="evento.alerta">
    </div>
    <div class="col-3">
        <label class="form-label">&nbsp;</label>
        <ng-select [items]="tipoAlerta" [(ngModel)]="evento.tipoAlerta" [ngModelOptions]="{standalone: true}"
            bindLabel="nome" bindValue="id" [loading]="loadTipoAlerta"></ng-select>
    </div>
    <div class="col-7">
        <label class="form-label">Responsável <span class="required-field">*</span></label>
        <pessoa-dropdown [(pessoaId)]="evento.responsavelId"></pessoa-dropdown>        
    </div>
    <div class="col-12">
        <label class="form-label">Observações<span class="required-field ml-1">*</span></label>
        <textarea class="form-control" placeholder="Digite a descrição" rows="2"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="evento.observacao" maxlength="1000"></textarea>
    </div>
    <div class="col-12">
        <div class="form-row">
            <div class="col">
                <label class="form-label d-flex align-items-center">
                    Outros envolvidos 
                    <button type="button" class="btn btn-primary btn-xs ml-3" (click)="adicionarEnvolvidoEvento(evento)"><i class="fas fa-plus"></i></button>
                </label>
            </div>
        </div>
        <div class="form-row" *ngFor="let envolvido of evento.envolvidos; let i = index">
            <div class="form-group col">                
                <pessoa-dropdown [(pessoaId)]="envolvido.envolvidoId"></pessoa-dropdown>
            </div>                
            <div class="form-group col-1">
                <button type="button" class="btn btn-danger" (click)="removerEnvolvidoEvento(evento, i)"><i class="fas fa-trash"></i></button>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="emails container" *ngIf="evento.adicionarEmails">
                <div class="row">
                    <div class="col-12"><b>NOTIFICAR CLIENTES</b></div>
                </div>
                <div class="row">
                    <div class="col-12" *ngFor="let mail of evento.emailsObject; let i=index">
                        <input type="email" class="form-control" [(ngModel)]="mail.endereco">
                        <div *ngIf="evento.emailsObject.length > 1" class="email-remove"
                            (click)="handleRemoverEmailLista(evento, i)"><i class="far fa-trash-alt"></i></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 adicionar" (click)="handleAdicionarNovoEmail(evento)"><span>ADINIONAR
                            MAIS UM E-MAIL</span></div>
                </div>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-5 cancelar" (click)="handleCancelarAdicionarEmails(evento)">
                        <span>CANCELAR</span></div>
                    <div class="col-4 salvar" (click)="handleSalvarEmails(evento)"><span>SALVAR</span></div>
                </div>
            </div>
        </div>
        <label class="form-label">Notificar cliente(s)</label><br />
        <button class="btn btn-light" (click)="handleAdicionarEmailsEvento(evento)">CONFIGURAR E-MAILS</button>
    </div>
</div>