export enum CALCULATION_DATA_FORM_BINDING {
    ID = 'id',
    APROVADO = 'aprovado',
    NOME = 'nome',
    DESC = 'descricao',
    DATA_ATUALIZACAO = 'dataAtualizacaoValores',
    INDICE_ID = 'indiceId',
    PRO_DATA = 'proRota',
    INDICE_NEGATIVOS = 'indiceNegativos',    
    RECURSO_DESDOBRAMENTO_ID = 'processoJudicialRecursoDesdobramentoId',
    JUROSMORATORIOS = 'jurosMoratorios',
    JUROSMORATORIOSREGRAS = 'jurosMoratoriosRegras',    
    JUROSCOMPENSATORIOS = 'jurosCompensatorios',
    JUROSCOMPENSATORIOSREGRAS = 'jurosCompensatoriosRegras',    
    VALORTOTALATUALIZADO = 'valorTotalAtualizado',    
    VALORTOTALJUROSMORATORIOS = 'valorTotalJurosMoratorios',    
    VALORTOTALJUROSCOMPENSATORIOS = 'valorTotalJurosCompensatorios', 
    MULTA = 'multa',
    MULTAREGRAS = 'multaRegras',
    VALORTOTALMULTAS = 'valorTotalMultas', 
    HONORARIO = 'honorario',
    HONORARIOREGRAS = 'honorarioRegras',
    VALORTOTALHONORARIOS = 'valorTotalHonorarios',
    HONORARIOSSUCUMBENCIA = 'honorariosSucumbencia',
    SUCUMBENCIAREGRAS = 'sucumbenciaRegras',
    VALORSUCUMBENCIA = 'valorSucumbencia',
    VALORTOTALGERAL = 'valorTotalGeral',
    MULTAARTNCPC = 'multaArtNCPC',
    MULTAARTNCPCREGRAS = 'multaArtNCPCRegras',
    VALORMULTAARTNCPC = 'valorMultaArtNCPC'    
}

export enum VALORES_FORM_BINDING {
    ID = 'id',
    DESC = 'descricao',
    DATA = 'data',
    VALOR = 'valor',
    VALOR_ATUALIZADO = 'valorAtualizado',
    JUROSMORATORIOS = 'jurosMoratorios',
    VALOR_JUROS_MORATORIOS = 'valorJurosMoratorios',
    PERCENTUAL_JUROS_MORATORIOS= 'percentualJurosMoratorios',
    JUROSCOMPENSATORIOS = 'jurosCompensatorios',
    VALOR_JUROS_COMPENSATORIOS = 'valorJurosCompensatorios',
    PERCENTUAL_JUROS_COMPENSATORIOS = 'percentualJurosCompensatorios',
    MULTA = 'multa',
    VALORMULTA = 'valorMulta',
    HONORARIO = 'honorario',
    VALORHONORARIO = 'valorHonorario',    
    VALOR_TOTAL = 'total',    
}

export enum HEADER_JUROS_AVANCADO{
    TIPOJUROS = 'tipoJuros',
    TIPOFORMULARIOCALCULO = 'tipoFormularioCalculo',
    PERCENTUAL = 'percentual',
    TIPOCALCULO = 'tipoCalculo',
    JUROSPROPORCIONAL = 'jurosProporcional',
    JUROSDATAVENCIMENTO = 'jurosDataVencimento',
    AVANCADO = 'avancado'
}

export enum MULTA_REGRAS{
    ID = 'id',
    VALOR = 'valor',
    TIPOCALCULOMULTA='tipoCalculoMulta',
    CALCULARMULTASOBREJUROS = 'calcularMultaSobreJuros'
}

export enum HONORARIO_REGRAS{
    ID = 'id',
    VALOR = 'valor',
    TIPOCALCULOHONORARIO='tipoCalculoHonorario',
}

export enum HONORARIOS_ADVOCATICIOS_SUCUMBENCIAIS{
    ID = 'id',
    TIPOFORMULARIOCALCULO = 'tipoFormularioCalculo',
    VALORPERCENTUAL = 'valorPercentual',
    VALORBASECALCULO = 'valorBaseCalculo'
}

export enum MULTA_ART_NCPC{
    ID= 'id',
    VALORATUALIZADO= 'valorAtualizado',
    JUROSMORATORIOS = 'jurosMoratorios',
    JUROSCOMPENSATORIOS = 'jurosCompensatorios',
    MULTA = 'multa',
    HONORARIOS = 'honorarios',
    HONORARIOSSUCUMBENCIA = 'honorariosSucumbencia',
    CUSTASATUALIZADAS = 'custasAtualizadas'
}