export enum TipoEstadoCivil {
  Solteiro = 0,
  Casado = 1,
  Divorciado = 2,
  Viuvo = 3
}

export const TipoEstadoCivilLabel = new Map<TipoEstadoCivil, string>([
  [TipoEstadoCivil.Solteiro, "Solteiro"],
  [TipoEstadoCivil.Casado, "Casado"],
  [TipoEstadoCivil.Divorciado, "Divorciado"],
  [TipoEstadoCivil.Viuvo, "Viuvo"],
]);
