<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3" *ngIf="tipo != null">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos / Honorários /</span> <span>{{obterNome()}}</span>      
    </span>
</h4>
<hr />
<div class="container mb-5"  *ngIf="tipo != null">
    <form ngNoForm>
        <div class="form-row" *ngFor="let honorario of honorariosObject; let i = index">
            <div class="form-group col-3">
                <label class="form-label">Vencimento<span class="required-field ml-1">*</span></label>
                <div class="input-group">
                    <input (click)="Vencimento.toggle()" class="form-control" placeholder="dd/mm/yyyy" name="dp"
                        ngbDatepicker [(ngModel)]="honorario.vencimento" [displayMonths]="displayMonths"
                        [navigation]="navigation" placement="bottom-right" #Vencimento="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="Vencimento.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>                
            </div>
            <div class="form-group col-2">
                <label class="form-label">Valor/Valor com desc<span class="required-field ml-1">*</span></label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"  class="form-control" [(ngModel)]="honorario.valorHonorario" data-lpignore="true">
            </div>
            <div class="form-group" [ngClass]="tipo == 4 ? 'col-2' : 'col-4'" >
                <label class="form-label">Descrição<span class="required-field ml-1">*</span></label>
                <input type="text" maxlength="1000" class="form-control" [(ngModel)]="honorario.descricao">
            </div>                               
            <div class="col-4" *ngIf="tipo == 4">
                <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                    [uploader]="uploader" class="dropzone" (click)="fileInput.click()">                    
                    <span class="span-text">Carregar documento</span>
                    <input type="file" #fileInput ng2FileSelect [uploader]="honorario.uploader" style="display: none" multiple
                        (onFileSelected)="onDrop(honorario, $event)" />
                </div>
                <div class="row" *ngIf="honorario.uploader.queue.length > 0">
                    <div class="col-12" *ngFor="let item of honorario.uploader.queue">
                        {{item?.file?.name}}
                    </div>
                </div>
            </div>         
            <div class="form-group col-1">
                <button type="button" class="btn btn-danger btn-remover-honorario" (click)="removerHonorario(i)"><i class="fas fa-trash"></i></button>
            </div>
            <div class="form-check col-2 align-middle repetir-mensal" *ngIf="tipo != 4">                
                <input class="form-check-input" type="checkbox" id="check_{{i}}" [(ngModel)]="honorario.repetirMensalmente">
                <label class="form-check-label" for="check_{{i}}">
                    Repetir mensalmente
                </label>
            </div>
            <div class="form-group col-1" *ngIf="honorario.repetirMensalmente">
                <input class="form-control" type="number" maxlength="2" [(ngModel)]="honorario.quantidadeMesesRepetir" >
            </div>
            <div class="col-8 linha" *ngIf="honorario.repetirMensalmente">
                mês
            </div>
            <br />
        </div>
    </form>        
        <hr />
        <div class="row" *ngIf="isAdd">
            <div class="col-2">
                <button class="btn btn-secondary" (click)="adicionarHonorario()">ADICIONAR</button>
            </div>
            <div class="form-group col-1">
                <input class="form-control" type="number" [(ngModel)]="quantidadeLinhasAdd" >                
            </div>
            <div class="col-9 linha">
                linha(s)
            </div>
        </div>
        <hr />
        <div class="row" *ngIf="honorariosDetalhe != undefined">
            <div class="form-group col-4">
                <label class="form-label">Processo, caso ou atendimento<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="honorariosDetalhe.titulo" disabled>
            </div>
            <div class="form-group col-4">
                <label class="form-label">Cliente(s)<span class="required-field ml-1">*</span></label>                
                <ng-select class="form-select" [ngModel]="clienteSelected" [items]="this.honorariosDetalhe.nomeCliente" disabled>                    
                </ng-select>
            </div>
            <div class="form-group col-4">
                <label class="form-label">Responsável<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="honorariosDetalhe.nomeResponsavel" disabled>
            </div>
        </div>
        <hr />
        <div class="d-flex justify-content-end">
            <button type="button" (click)="cancel()" class="btn btn-light mr-3">Cancelar</button>
            <button type="button" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
        </div>    
</div>