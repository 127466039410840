import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
//import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
//import { ComarcaService } from 'src/app/services/comarca.service';

@Component({
    selector: 'app-modal-info-comarca',
    templateUrl: './modal-info-comarca.component.html'
})
export class ModalInfoComarcaComponent implements OnInit {

    modalRef: NgbModalRef;
    public comarca: any;
    //public telefones: [];

    constructor(
        // private comarcaService: ComarcaService,
        // private spinner: NgxSpinnerService,
        public toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        this.comarca = this.modalRef.componentInstance.comarca;
        //this.buscarTelefones();
    }


    // buscarTelefones() {
    //     this.spinner.show();
    //     this.toastrService.clear();
    //     this.comarcaService.getAllTelefonesComarca(this.comarca.id)
    //         .subscribe(res => {

    //             setTimeout(() => {
    //                 this.telefones = res.data;
    //                 //this.update();
    //                 this.spinner.hide();

    //             }, 1500);
    //         }, error => {
    //             this.spinner.hide();
    //         });
    // }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

}
