<div class="row">
    <div class="col-12">
        <h6>Histórico Manual ({{index}})</h6>
    </div>
    <div class="col-12">
        <label class="form-label">Descrição<span class="required-field">*</span></label>
        <textarea class="form-control" placeholder="Digite a descrição" rows="2"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="historicoManual.descricao"></textarea>
    </div>
    <div class="col-8">
        <label class="form-label">Responsável <span class="required-field">*</span></label>        
        <pessoa-dropdown [(pessoaId)]="historicoManual.responsavelId"></pessoa-dropdown> 
    </div>
    <div class="col-4">
        <label class="form-label">Data<span class="required-field ml-1">*</span></label>
        <div class="input-group">
            <input (click)="datahistorico.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataHistoricoComponent" ngbDatepicker [(ngModel)]="historicoManual.dataHistoricoComponent"
                placement="bottom-right"
                #datahistorico="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="datahistorico.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>
</div>