import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PessoaService } from 'src/app/services/pessoa.service';

@Component({
    selector: 'app-modal-codigo',
    templateUrl: './modal-codigo.component.html',
    styleUrls: ['./modal-codigo.component.scss']
})
export class ModalCodigoComponent implements OnInit {

    private modalRef: NgbModalRef; 
    public pessoaId: any;
    public pessoa: any ={
        codigoCliente:''
    };
    
    constructor(
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private pessoaService: PessoaService
    ) { }

    ngOnInit(): void {
        this.pessoaService.getById(this.pessoaId)
            .subscribe(res=>{
                this.pessoa=res.data;
            })
    }

    salvar(){
        this.spinner.show();

        let dado = {
            pessoaId: this.pessoa.id,
            codigoCliente: this.pessoa.codigoCliente
        }

        this.pessoaService.updatePessoaCodigo(dado)
            .subscribe(res=>{
                this.spinner.hide();
                this.close();
            }, error=>{
                this.spinner.hide();
                this.toastrService.error("Erro ao salvar", "Erro");
            })
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

}
