<div class="d-flex justify-content-center" *ngIf="loadPrazo">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!loadPrazo && !!comentarios.length">
    <ng-container *ngFor="let comentario of comentarios; let i = index">

        <div class="row border-comentario m-0 mb-3">
            <div [ngClass]="comentario.importante ? 'important-blue' : 'important-gray'"
                (click)="definirImportante(comentario)" style="margin-left: 31em !important;">
                <i class="fas fa-bookmark"></i>
            </div>

            <div class="col-12 d-flex">
                <div class="photo p-1 mr-2 align-self-center">
                    <img src="https://thumbs.dreamstime.com/z/businessman-avatar-line-icon-vector-illustration-design-79327237.jpg"
                        width="30px" height="30px" />
                </div>
                <p>
                    {{comentario.pessoaComentarioNome}} em {{comentario.dataComentario | date: 'dd/MM/yyyy'}} às
                    {{comentario.dataComentario | date: 'HH:mm'}} <br>
                </p>
            </div>

            <div class="col-12 mt-3">
                <p class="m-0">
                    <i class="fas fa-comment"></i> Comentário:
                </p>
            </div>
            <div class="col-9 mt-2 pl-4">
                <span class="comentario-html" [innerHTML]="comentario.comentario | safeHtml"></span>
            </div>

            <div class="col-12 mt-3"
                *ngIf="comentario.arquivos != undefined && comentario.arquivos != null && comentario.arquivos.length > 0">
                <p class="m-0">
                    <i class="fas fa-paperclip"></i> Documentos:
                </p>
            </div>
            <div class="mt-2 pl-4"
                *ngIf="comentario.arquivos != undefined && comentario.arquivos != null && comentario.arquivos.length > 0">
                <div class="anexo d-flex align-items-center flex-column">
                    <div *ngFor="let arquivo of comentario.arquivos" class="mb-2">
                        <span class="font-weight-bold">
                            <ng-container *ngIf="arquivo.nomeArquivo && arquivo.nomeArquivo.length <= 20">
                                <div class="btn btn-primary" [ngbTooltip]="arquivo.nomeArquivo"
                                    (click)="handleDownloadArquivo(arquivo)">
                                    Download {{arquivo.nomeArquivo | uppercase}}<i
                                        class="btn btn-xs icon-btn btn-primary"><span
                                            class="fas fa-download"></span></i>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="arquivo.nomeArquivo && arquivo.nomeArquivo.length > 20">

                                <div class="btn btn-primary" [ngbTooltip]="arquivo.nomeArquivo"
                                    (click)="handleDownloadArquivo(arquivo)">
                                    {{arquivo.nomeArquivo}}<i class="btn btn-xs icon-btn btn-primary"><span
                                            class="fas fa-download"></span></i>
                                </div>
                            </ng-container>
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="row m-0 mb-3">
    <div class="col-12">
        <mat-paginator #paginator [length]="comentariosOriginal?.length" [pageSize]="4">
        </mat-paginator>
    </div>
</div>

<div class="card-body" *ngIf="loadAtach">
    <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
        [uploader]="uploader" class="dropzone" (click)="fileInput.click()">
        <span class=" span-icon fas fa-cloud-download-alt"></span>
        <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
            carregar</span>
        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" multiple />
    </div>

    <table class="table" *ngIf="uploader.queue.length > 0">
        <thead>
            <tr>
                <th width="45%">Nome</th>
                <th>Tamanho</th>
                <th>Status</th>
                <th>Acões</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of uploader.queue">
                <td><strong>{{ item?.file?.name }}</strong></td>
                <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 |
                    number:'.2' }} MB
                </td>
                <td class="text-center">
                    <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                    <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                    <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                </td>
                <td nowrap>
                    <button type="button" class="btn btn-danger" (click)="handleRemoveFile(item)">
                        <span class="glyphicon glyphicon-trash"></span> Remover
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="row border-comentario m-1 pt-4 pb-4 align-items-center">
    <div class="col-10">
        <label class="form-label mb-2">Adicionar comentário</label>
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
        <i class="fas fa-paperclip atachment" style="margin-top: -19px;" (click)="loadAtach = !loadAtach"></i>

        <ngx-editor [editor]="editor" [(ngModel)]="comentarioDescricao"
            placeholder="Digite seu comentário, utilize @ para mencionar uma pessoa"
            (ngModelChange)="editorChange($event)" [mention]="pessoasParaMencao" [mentionConfig]="mentionConfig">
        </ngx-editor>
        <!-- <small class="float-right mt-1">Máximo 100 caracteres</small> -->

        <!-- <input type="text" class="form-control" placeholder="Digite um comentário" [(ngModel)]="comentarioDescricao"
            maxlength="1000"> -->
    </div>
    <div class="col-2">
        <label class="form-label">&nbsp;</label>
        <div class="btn btn-primary form-control" (click)="handleInserirComentarioForm()">
            Comentar
        </div>
    </div>
</div>

<!-- <ng-container *ngFor="let comentario of comentarios">
    <div class="row panel-commentarios">
        <div class="col-12">
            <i class="fas fa-user"></i> {{comentario.pessoaComentarioNome}} | {{comentario.dataComentario | date: 'dd/MM/yyyy'}} às {{comentario.dataComentario | date: 'HH:mm'}}
            <br /><br />
            <p>
                <i class="fas fa-comment"></i> Comentário: {{comentario.comentario}}
            </p>
        </div>      
        <div class="col-12" *ngIf="comentario.arquivos != undefined && comentario.arquivos != null && comentario.arquivos.length > 0">
            <i class="fas fa-paperclip"></i> Anexos:
            <div class="anexo d-flex">
                <div class="btn btn-primary" *ngFor="let arquivo of comentario.arquivos" [ngbTooltip]="arquivo.nomeArquivo" (click)="handleDownloadArquivo(arquivo)">
                    Download <i class="btn btn-xs icon-btn btn-primary" ><span class="fas fa-download"></span></i>
                </div>
            </div>            
        </div>                  
    </div>
    <hr />          
</ng-container> -->