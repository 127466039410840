export enum TipoOcorrencia {
    Ocorrencia,
    Atividade,
    Acordo,
    QuitacaoDivida,
    DescontoDivida,
    Notificacao,
    ProcessoRedirecionamento
}

export const TipoOcorrenciaLabel = new Map<TipoOcorrencia, string>([

    [TipoOcorrencia.Ocorrencia, "Ocorrência"],
    [TipoOcorrencia.Atividade, "Atividade"],

    [TipoOcorrencia.Acordo, "Acordo"],
    [TipoOcorrencia.QuitacaoDivida, "Quitação de Dívida"],
    [TipoOcorrencia.DescontoDivida, "Desconto de Dívida"],
    [TipoOcorrencia.DescontoDivida, "Notificação"],
    [TipoOcorrencia.ProcessoRedirecionamento, "Redirecionamento de Processo"]

]);
