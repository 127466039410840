import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TipoOcorrencia } from "../enums/tipo-ocorrencia.enum";

@Injectable()
export class ProcessosJudiciaisService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'processoJudicial/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    buscarProcessosJudiciais(filtro): Observable<any> {
        return this.http.get(environment.api + this.endpoint, filtro)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllFilter(filtro): Observable<any> {
        return this.http.get(environment.api + this.endpoint +  'filtro-publicacao/' + filtro)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getCarteiraDeProcessos(requisicao): Observable<any> {
        return this.http.post(environment.api + this.endpoint + "carteira-de-processos", requisicao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterProcessosApenso(processoJudicialId: string) {
        return this.http.get(environment.api + this.endpoint + 'obter-processos-apensos/' + processoJudicialId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    transformarEmProcesso(id): Observable<any> {
        return this.http.put(environment.api + this.endpoint+"transformar-em-processo/"+id, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    setCodigoExternoDevedor(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint+"definir-codigo-externo-devedor/", data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    removerCliente(id, clienteId): Observable<any> {
        return this.http.delete(environment.api + this.endpoint+'remover-cliente/' + id+'/'+clienteId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    removerEnvolvido(id, clienteId): Observable<any> {
        return this.http.delete(environment.api + this.endpoint+'remover-envolvido/' + id+'/'+clienteId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    removerTag(id: any) {
        return this.http.delete(environment.api + this.endpoint+'remover-tag/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    podeEncerrar(processoId) {
        return this.http.get(`${environment.api}${this.endpoint}verificar-se-pode-encerar/${processoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    encerrarProcesso(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint+'encerrar-processo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    migrarProcessoParaAmigavel(data): Observable<any> {
        
        return this.http.put(environment.api + this.endpoint+'mover-para-amigavel/'+data, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    reativarProcesso(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint+'reativar-processo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterPorRecursoDesdobramentoId(processoId) {
        return this.http.get(`${environment.api}${this.endpoint}obter-por-recurso-desdobramento/${processoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterAtividade(tarefaId, tipOcorrencia) {
        return this.http.get(`${environment.api}${this.endpoint}obter-atividade/${tarefaId}/${tipOcorrencia}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByIdAsync(id) {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

    getTarefasDoProcesso(processoId) {
        return this.http.get(`${environment.api}${this.endpoint}obter-atividades-processo/${processoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    getTarefasDoUsuario(tipo: string) {
        return this.http.get(`${environment.api}${this.endpoint}obter-atividades-usuario/${tipo}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterIdProcessoPorNumero(numero: string) {
        return this.http.get(`${environment.api}${this.endpoint}obter-processo-por-numero/${numero}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getTarefasDoUsuarioFiltro(filter: any) {
        return this.http.post(`${environment.api}${this.endpoint}obter-atividades-usuario-filtro/`, filter)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    inserirTag(requisicao: any) {
        return this.http.post(environment.api + this.endpoint+'inserir-tag/', requisicao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterProcessosJudiciaisPorFiltro(requisicao: any) {
        return this.http.post(`${environment.api}${this.endpoint}obter-processos-judiciais-por-filtro/`, requisicao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterAtividadesPorFiltro(requisicao: any) {
        return this.http.post(`${environment.api}${this.endpoint}obter-atividades-por-filtro/`, requisicao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    alterarDadosEmMassa(requisicao): Observable<any> {
        return this.http.put(`${environment.api}${this.endpoint}alterar-dados-em-massa/`, requisicao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterResumoAtividades() {
        return this.http.get(`${environment.api}${this.endpoint}obter-resumo-atividades`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterTaxasAcordo(id){
        return this.http.get(`${environment.api}${this.endpoint}obter-taxas-acordo/${id}`)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    obterChaveClientePrincipal(id){
        return this.http.get(`${environment.api}${this.endpoint}obter-cliente-principal-id/${id}`)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }
}
