import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColDef } from "ag-grid-community";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { badgeToBg } from "src/app/enums/tag/cor.enum";
import { ProcessosJudiciaisService } from "src/app/services/processos-judiciais.service";
import { ResultadoModal } from "src/app/services/shared/resultado-modal";
import { AgGridComponent } from "../../shared/ag-grid/ag-grid.component";
import { OpcoesProcessoComponent } from "../../shared/opcoes-processo/opcoes-processo.component";
import { ModalDetalheComponent } from "../agenda/modal-detalhe/modal-detalhe.component";
import { TipoProcessoEletronicoService } from "src/app/services/tipo-processo-eletronico.service";

@Component({
  selector: "app-tarefas-usuario",
  templateUrl: "./tarefas-usuario.component.html",
  styleUrls: ["./tarefas-usuario.component.scss"],
})
export class TarefasUsuarioComponent extends AgGridComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() buscarTarefaDoUsuarioEmitter = new EventEmitter();

  constructor(
    private _toastrService: ToastrService,
    public ngbModal: NgbModal,
    public _processosJudiciaisService: ProcessosJudiciaisService,
    public _tipoProcessoEletronicoService: TipoProcessoEletronicoService,
    public _spinner: NgxSpinnerService,
    private router: Router
  ) {
    super();
  }

  public filtroOn = false;
  // public tipoAtividade: string = 'responsavel';
  // displayedColumns: string[] = ['numeroProcesso', 'descricaoAtividade', 'tipoAtividade', 'classificacaoAtividade', 'dataLimite', 'dataRevisao', 'estado', 'tipoAcao', 'tipoProcesso', 'tituloProcesso', 'situacaoProcesso'];
  // tarefasUsuario: any = [];
  // tarefasUsuarioDataSource: MatTableDataSource<any> = new MatTableDataSource();

  public resultConfirmDialog$ = new Subject<ResultadoModal>();

  //Filtros
  // public filtroNumeroProcesso: string = '';
  // public filtroDescricaoAtividade: string = '';
  // public filtroTipoAtividade: string = '';
  // public filtroClassificacaoAtividade: string = '';
  // public filtroDataLimite: string = '';
  // public filtroDataRevisao: string = '';
  // public filtroVara: string = '';
  // public filtroEstado: string = '';
  // public filtroTipoAcao: string = '';
  // public filtroTipoProcesso: string = '';
  // public filtroTituloProcesso: string = '';
  // public filtroSituacaoProcesso: string = '';

  public filter: any = {
    asc: false,
    campoSort: "dataCriacao",
    quantidade: 500,
    pagina: 0,
  };

  cellStyle(params) {
    const { tipoStatusAtividadeNome, isEdit } = params.data;
    return {
      cursor: "pointer",
      "text-decoration":
        tipoStatusAtividadeNome === "Encerrado" ? "line-through" : "none",
      background:
        tipoStatusAtividadeNome === "Encerrado" || !isEdit ? "#ccc" : "",
    };
  }

  columnDefs: ColDef[] = [
    {
      headerName: " ",
      field: "acoes",
      filter: false,
      sortable: false,
      checkboxSelection: true,
      width: 50,
      cellStyle: (params) => {
        const { tipoStatusAtividadeNome, isEdit } = params.data;
        return {
          background:
            tipoStatusAtividadeNome === "Encerrado" || !isEdit ? "#ccc" : "",
        };
      },
      cellRendererSelector: (params) => {
        // const viewOnly = !(
        //   params.data.tipoObjeto === "PRAZO" ||
        //   params.data.tipoObjeto === "TAREFA"
        // );
        return {
          component: OpcoesProcessoComponent,
          params: {
            item: params.data,
            resultConfirmDialog$: this.resultConfirmDialog$,
            isGrid: true,
            viewOnly: true,
            buscarTarefaDoUsuario: () => this.buscarTarefaDoUsuario(),
          },
        };
      },
    },
    {
      headerName: "Pasta",
      field: "processoJudicialPasta",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Data Revisão",
      field: "dataRevisao",
      type: "dateTimeColumn",
      filter: "agDateColumnFilter",
      filterParams: this.filterDateParams,
      cellStyle: (params) => {
        const { tipoStatusAtividadeNome, isEdit } = params.data;
        return {
          background:
            tipoStatusAtividadeNome === "Encerrado" || !isEdit
              ? "#ccc"
              : badgeToBg[params.data.corAlertaDataRevisao],
          "text-decoration":
            tipoStatusAtividadeNome === "Encerrado" ? "line-through" : "none",
        };
      },
    },
    {
      headerName: "Data Limite",
      field: "dataLimite",
      type: "dateTimeColumn",
      filter: "agDateColumnFilter",
      filterParams: this.filterDateParams,
      cellStyle: (params) => {
        const { tipoStatusAtividadeNome, isEdit } = params.data;
        return {
          background:
            tipoStatusAtividadeNome === "Encerrado" || !isEdit
              ? "#ccc"
              : badgeToBg[params.data.corAlertaDataLimite],
          "text-decoration":
            tipoStatusAtividadeNome === "Encerrado" ? "line-through" : "none",
        };
      },
    },
    {
      headerName: "Tipo da Atividade",
      field: "tipoObjeto",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
        headerName: "#ID",
        field: "codExterno",
        filterParams: this.filterParams,
        cellStyle: (params) => this.cellStyle(params),
      },
    {
      headerName: "Descrição da Atividade",
      field: "descricao",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Número do Processo",
      field: "processoJudicialNumero",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Titulo do Processo",
      filterParams: this.filterParams,
      field: "processoJudicialTitulo",
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Data Lançamento Atividade",
      field: "dataCriacao",
      type: "dateTimeColumn",
      filter: "agDateColumnFilter",
      filterParams: this.filterDateParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Tipo de Ação",
      filterParams: this.filterParams,
      field: "processoJudicialAcao",
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Situacao do Processo",
      filterParams: this.filterParams,
      field: "processoJudicialSituacao",
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Instância",
      field: "processoJudicialInstancia",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Tipo Proc (Eletrônico-Sistema ou Físico)",
      filterParams: this.filterParams,
      field: "processoJudicialTipo",
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Estado",
      filterParams: this.filterParams,
      field: "processoJudicialUF",
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Classificação",
      field: "classificacaoAtividadeNome",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Sub-tipo de Classificação",
      field: "classificacaoSubtipoAtividadeNome",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Status da Atividade",
      field: "tipoStatusAtividadeNome",
      filterParams: this.filterParams,
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Minha Atribuição",
      filterParams: this.filterParams,
      field: "atuacao",
      cellStyle: (params) => this.cellStyle(params),
    },
    {
      headerName: "Gatilho de Ação",
      filterParams: this.filterParams,
      field: "isEdit",
      cellStyle: (params) => this.cellStyle(params),
      cellRenderer: (param) => {
        const minhaAcao: boolean = param.data.isEdit;
        return minhaAcao ? "Minha ação" : "Ação de outros";
      },
    },
  ];

  //Paginação
  itensPorPagina: number = 50;
  totalItens: number = 50;
  paginaAtual: number = 1;

  selectedRows = [];

  get totalPaginas() {
    return Math.ceil(this.totalItens / this.itensPorPagina);
  }

  onCheckedsRows(event) {
    this.selectedRows = event;
  }

  onCellClicked(event) {
    if (event.colDef.field !== "acoes") {
      this._processosJudiciaisService
        .getById(event.data.processoJudicialId)
        .subscribe((processo) => {
          this.openModal(event.data);
        });
    }
  }

  iniciarSelecionadas() {
    console.log("iniciar");
    console.log(this.gridApi.getSelectedRows());
  }

  openModal(data) {
    this._spinner.show();

    this._processosJudiciaisService
      .getById(data.processoJudicialId)
      .subscribe((processo) => {
        const modalRef = this.ngbModal.open(ModalDetalheComponent, {
          size: "lg",
          backdrop: "static",
          keyboard: false,
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.id = data.id;
        modalRef.componentInstance.tipoOcorrencia = data.tipoOcorrencia;
        modalRef.componentInstance.descricao = data.titulo;
        modalRef.componentInstance.tipoObjeto = data.tipoObjeto;
        modalRef.componentInstance.responsavelNome = data.responsavelNome;
        modalRef.componentInstance.dataInicioPrazo = data.dataInicioPrazo;
        modalRef.componentInstance.estilo = data.estilo;
        modalRef.componentInstance.corAlertaData = data.corAlertaData;
        modalRef.componentInstance.numeroProcesso = processo.data.numero;
        modalRef.componentInstance.processo = processo.data;
        modalRef.componentInstance.item = data;
        modalRef.componentInstance.buscarTarefaDoUsuario =
          this.buscarTarefaDoUsuarioEmitter;

        this._spinner.hide();
      });
  }

  loadData: boolean = false;
  buscarTarefaDoUsuario(): void {
    this._spinner.show();
    this._toastrService.clear();
    this._processosJudiciaisService
      .getTarefasDoUsuarioFiltro(this.filter)
      .subscribe(
        (res) => {
          //console.log(res.data);
          //this.tarefasUsuario = res.data;
            this._tipoProcessoEletronicoService.getAll().subscribe( (tipo) => {
               res.data.filter((item) => {
                  let isProcessoEletronico = item.processoJudicialTipo == 'Eletrônico';
                  if(isProcessoEletronico){
                    //item.processoJudicialEletronicoId = "588477f4-481f-4057-a64d-d96447f19fcd";
                    if(item.processoJudicialEletronicoId){
                    let objetoEletronico = tipo.data.find(x => x.id == item.processoJudicialEletronicoId);
                    item.processoJudicialTipo = objetoEletronico && objetoEletronico.nome  ? this.colocarPrimeiraLetraMaiucula(objetoEletronico.nome) : item.processoJudicialTipo;
                    }
                  }


               })

            }
          )
          const encerrados = res.data.filter(
            (item) =>
              item.tipoStatusAtividadeNome === "Encerrado" && !item.isEdit
          );

          const outrosUsuarios = res.data.filter(
            (item) =>
              item.tipoStatusAtividadeNome !== "Encerrado" && !item.isEdit
          );

          const naoencerrados = res.data.filter(
            (item) =>
              item.tipoStatusAtividadeNome !== "Encerrado" && item.isEdit
          );

          this.rowData = naoencerrados.concat(
            outrosUsuarios.concat(encerrados)
          );

          // this.tarefasUsuarioDataSource = new MatTableDataSource<any>(this.tarefasUsuario);
          // this.tarefasUsuarioDataSource.paginator = this.paginator;
          // this.tarefasUsuarioDataSource.sort = this.sort;
          this._spinner.hide();
          this.loadData = true;
        },
        (error) => {
          if (error && error.errors) {
            this._toastrService.error(error.errors[0], "Atenção", {
              timeOut: 10000,
            });
          } else {
            this._toastrService.error(
              "Não foi possível carregar os dados!",
              "Atenção",
              { timeOut: 10000 }
            );
          }
          this._spinner.hide();
        }
      );
  }
  colocarPrimeiraLetraMaiucula(str: string){
    const palavras = str.split(" ");

    return palavras.map((palavra) => {
      return palavra[0].toUpperCase() + palavra.substring(1).toLowerCase();
    }).join(" ");
  }

  // filtrarDados(): void {

  //     let dados = this.tarefasUsuario;
  //     console.log('Antes: ', dados);

  //     dados = dados.filter(item => item.id == this.filtroNumeroProcesso);

  //     console.log('Depois: ', dados);
  //     this.tarefasUsuarioDataSource = new MatTableDataSource<any>(dados);

  // }

  abrirFecharFiltro(): void {
    this.filtroOn = !this.filtroOn;
  }

  // limparFiltro(): void {

  //     this.filtroNumeroProcesso = '';
  //     this.filtroDescricaoAtividade = '';
  //     this.filtroTipoAtividade = '';
  //     this.filtroClassificacaoAtividade = '';
  //     this.filtroDataLimite = '';
  //     this.filtroDataRevisao = '';
  //     this.filtroVara = '';
  //     this.filtroEstado = '';
  //     this.filtroTipoAcao = '';
  //     this.filtroTipoProcesso = '';
  //     this.filtroTituloProcesso = '';
  //     this.filtroSituacaoProcesso = '';

  //     this.tarefasUsuarioDataSource = new MatTableDataSource<any>(this.tarefasUsuario);
  // }

  ngOnInit(): void {
    // this.tarefasUsuarioDataSource.paginator = this.paginator;
    // this.tarefasUsuarioDataSource.sort = this.sort;
    this.buscarTarefaDoUsuario();
  }
}
