<div class="card-atendimento" (mouseover)="hideButtons = false" (mouseleave)="hideButtons = true">
    <div class="row">
        <div class="col-11">
            <h5 class="card-title">
                <i class="far fa-copy"></i> Documentos
            </h5>
        </div>
        <!-- <div class="col-1">
            <div (click)="recolherDados()" class="btn-recolher" *ngIf="!recolhido">
                <i class="fas fa-angle-up"></i>
            </div>
            <div (click)="recolherDados()" class="btn-recolher" *ngIf="recolhido">
                <i class="fas fa-angle-down"></i>
            </div>            
        </div> -->        
        <div class="col-1 d-flex justify-content-between align-items-center font-weight-bold pt-3">
            <div class="btn-adicionar" routerLink="{{raiz}}/documentos/documentos-cadastro/{{tipo}}/{{referenciaId}}">
                <i class="fas fa-plus"></i>
            </div>         
            <div class="btn-detalhes" routerLink="{{raiz}}/documentos/listar-documentos/{{tipo}}/{{referenciaId}}">
                <i class="fas fa-folder-open"></i>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="loadQuantidade">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- <div class="row" *ngIf="!loadQuantidade && !recolhido">
        <div class="col-12" *ngIf="quantidade == 0">Sem registros.</div>
        <div class="col-12" *ngIf="quantidade == 1"><a routerLink="{{raiz}}/documentos/listar-documentos/{{tipo}}/{{referenciaId}}">1 Documento encontrado.</a></div>
        <div class="col-12" *ngIf="quantidade > 1"><a routerLink="{{raiz}}/documentos/listar-documentos/{{tipo}}/{{referenciaId}}">{{quantidade}} Documentos encontrados.</a></div>
    </div> -->
    <app-listar-documentos [tipo]="tipo" [referenciaId]="referenciaId" [raiz]="raiz"></app-listar-documentos>
</div>