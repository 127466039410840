<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span>Atividades do Usuário</span>
</h4>

<hr class="border-light my-0">

<!-- TODO: filtros ocultos (hidden) por solicitação em 29/01. Definir se vai voltar a exibir em algum momento, se não for, remover do código. -->
<div class="filtro-header mt-2" hidden>
    <div class="p-3 filtro-header-titulo d-flex justify-content-between" (click)="abrirFecharFiltro()">
        <span>Filtros</span>
        <span class="lnr lnr-chevron-up" *ngIf="filtroOn"></span>
        <span class="lnr lnr-chevron-down" *ngIf="!filtroOn"></span>
    </div>

    <div class="filtro-tarefas-usuario" *ngIf="filtroOn">
        <div class="form-row align-items-center mb-3">
            <div class="col-6 col-md-3">
                <label class="form-label">Número Processo</label>
                <input type="text" [(ngModel)]="filtroNumeroProcesso" class="form-control">
            </div>

            <div class="col-6 col-md-3">
                <label class="form-label">Descrição da Atividade</label>
                <input type="text" [(ngModel)]="filtroDescricaoAtividade" class="form-control">
            </div>

            <div class="col-6 col-md-3">
                <label class="form-label">Tipo da Atividade</label>
                <input type="text" [(ngModel)]="filtroTipoAtividade" class="form-control">
            </div>

            <div class="col-6 col-md-3">
                <label class="form-label">Classificação da Atividade</label>
                <input type="text" [(ngModel)]="filtroClassificacaoAtividade" class="form-control">
            </div>
        </div>

        <div class="form-row align-items-center mb-3">
            <div class="col-6 col-md-3">
                <label class="form-label">Data Limite</label>
                <input type="text" [(ngModel)]="filtroDataLimite" class="form-control">
            </div>

            <div class="col-6 col-md-3">
                <label class="form-label">Data Revisão</label>
                <input type="text" [(ngModel)]="filtroDataRevisao" class="form-control">
            </div>

            <!-- <div class="col-6 col-md-3">
                <label class="form-label">Vara</label>
                <input type="text" [(ngModel)]="filtroVara" class="form-control">
            </div> -->

            <div class="col-6 col-md-3">
                <label class="form-label">Estado</label>
                <input type="text" [(ngModel)]="filtroEstado" class="form-control">
            </div>
        </div>

        <div class="form-row align-items-center mb-3">
            <div class="col-6 col-md-3">
                <label class="form-label">Tipo de Ação</label>
                <input type="text" [(ngModel)]="filtroTipoAcao" class="form-control">
            </div>

            <div class="col-6 col-md-3">
                <label class="form-label">Tipo de Processo</label>
                <input type="text" [(ngModel)]="filtroTipoProcesso" class="form-control">
            </div>

            <div class="col-6 col-md-3">
                <label class="form-label">Título de Processo</label>
                <input type="text" [(ngModel)]="filtroTituloProcesso" class="form-control">
            </div>

            <div class="col-6 col-md-3">
                <label class="form-label">Situação do Proceesso</label>
                <input type="text" [(ngModel)]="filtroSituacaoProcesso" class="form-control">
            </div>
        </div>

        <div class="form-row align-items-center mb-3">
            <div class="col-md-2">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                <button type="button" class="btn btn-padrao btn-block" (click)="filtrarDados()"><span
                        class="fas fa-search"></span>&nbsp;&nbsp;Filtrar</button>
            </div>

            <div class="col-md-2">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                <button type="button" class="btn btn-padrao btn-block" (click)="limparFiltro()"><span
                        class="oi oi-loop"></span>&nbsp;&nbsp;Limpar</button>
            </div>
        </div>
    </div>
</div>
<div class="" *ngIf="loadData">
    <app-opcoes-processo [items]="selectedRows" [bulkAction]="true" [showReschedule]="true"></app-opcoes-processo>
    <app-ag-grid classGrid="h-grid" [rowData]="rowData" [pagination]="true" [columnDefs]="columnDefs"
        [showToolbar]="true" [rowSelection]="'multiple'" keyLocalStorageFilter="filter-atividades-usuario"
        (cellClicked)="onCellClicked($event)" (selectionChanged)="onCheckedsRows($event)">

    </app-ag-grid>
</div>