import { NgbDate, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { Component, Input, OnInit } from "@angular/core";
import { NgbDateCustomParserFormatter } from "src/app/utils/dateformat";
import { FormularioDinamicoService } from "src/app/services/formulario-dinamico.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { TipoCampoFormulario } from "src/app/enums/formulario-dinamico/tipo-campo-formulario-dinamico.enum";
import { Guid } from "guid-typescript";
import { DatePipe } from "@angular/common";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

@Component({
  selector: "app-processos-judiciais-formulario-dinamico",
  templateUrl: "./processos-judiciais-formulario-dinamico.component.html",
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class ProcessosJudiciaisFormularioDinamicoComponent implements OnInit {
  constructor(
    private _formularioDinamicoService: FormularioDinamicoService,
    private _toastr: ToastrService,
    private _spinner: NgxSpinnerService,
    private _datePipe: DatePipe
  ) {
  }

  @Input() processoJudicialId?: Guid = null;
  public formularios: any = [];

  ngOnInit(): void {
    this.carregarFormularios();
  }

  carregarFormularios() {
    this._spinner.show();
    this._formularioDinamicoService.obterFormulariosProcesso().subscribe(
      (res) => {
        if (!res.data) return;

        this.formularios = res.data.map((item) => {
          return { ...item, accordionExpandido: false };
        });

        this.carregarItensSelect();
        this.carregarValoresFormularios();
        this._spinner.hide();
      },
      (error) => {
        if (error && error.errors) {
          this._toastr.error(error.errors[0], "Atenção", {
            timeOut: 10000,
          });
        } else {
          this._toastr.error(
            "Não foi possível carregar formulários dinâmicos",
            "Atenção",
            { timeOut: 10000 }
          );
        }
        this._spinner.hide();
      }
    );
  }

  carregarItensSelect() {
    const tiposSelect = [
      TipoCampoFormulario.Combobox,
      TipoCampoFormulario.MultiSelect,
    ];

    this.formularios.forEach((form) => {
      form.camposFormulario.forEach((campo) => {
        if (tiposSelect.includes(campo.tipo)) {
          campo.itensSelect = campo.opcoesCampoSelecao
            .split(",")
            .map((opcao, index) => {
              return {
                id: (index + 1).toString(),
                nome: opcao.trim(),
              };
            });
        }
      });
    });
  }

  carregarValoresFormularios() {
    if (!this.formularios || !this.processoJudicialId) return;

    this._spinner.show();
    this._formularioDinamicoService
      .obterValoresProcessoJudicialFormularioDinamico(this.processoJudicialId)
      .subscribe(
        (res) => {
          const processoJudicialFormularioDinamicoValores = res.data;

          if (res.data) {
            this.formularios.forEach((form) => {
              form.camposFormulario.forEach((campo) => {
                const valorCampoFormularioDinamico = processoJudicialFormularioDinamicoValores.find((valor) => valor.formularioDinamicoCampoId == campo.id);
                if (valorCampoFormularioDinamico &&valorCampoFormularioDinamico?.valor) {
                  if (campo.tipo == TipoCampoFormulario.Data) {
                    const [year, month, day] = valorCampoFormularioDinamico.valor?.split('-');
                    campo.valor = new NgbDate(parseInt(year), parseInt(month), parseInt(day.split(' ')[0].trim()));
                  } 
                  else if(campo.tipo == TipoCampoFormulario.MultiSelect){
                    campo.valor = valorCampoFormularioDinamico.valor?.split(',');
                  }
                  else {
                    campo.valor = valorCampoFormularioDinamico.valor;
                  }
                }
              });
            });
          }
          this._spinner.hide();
        },
        (error) => {
          if (error && error.errors) {
            this._toastr.error(error.errors[0], "Atenção", {
              timeOut: 10000,
            });
          } else {
            this._toastr.error(
              "Não foi possível carregar valores dos formulários dinâmicos",
              "Atenção",
              { timeOut: 10000 }
            );
          }
          this._spinner.hide();
        }
      );
  }
}
