import { Component, OnInit } from "@angular/core";
import {
  FileDownloadBackgound,
  FileDownloadBackgoundService,
} from "./download-in-background.service";

@Component({
  selector: "app-download-in-background",
  templateUrl: "./download-in-background.component.html",
  styleUrls: ["./download-in-background.component.scss"],
})
export class DownloadInBackgroundComponent implements OnInit {
  public maximize = true;

  public files: Map<number, FileDownloadBackgound> = new Map();
  public progressMap = new Map();

  public show = false;

  /** files */
  get qtdFiles() {
    return this.files.size;
  }

  get allFiles(): FileDownloadBackgound[] {
    const f: FileDownloadBackgound[] = [];
    this.files.forEach((file) => f.push(file));
    return f;
  }

  /** done */
  get allDownloadsDone() {
    return this.allFiles.every((file) => !!file.done);
  }

  /** success */
  get qtdSuccess() {
    return this.allFiles.filter((file) => !!file.success)?.length;
  }

  /** error */
  get qtdError() {
    return this.allFiles.filter((file) => !!file.error)?.length;
  }

  get hasSomeError() {
    return this.allFiles.some((file) => !!file.error);
  }

  /** downloading */
  get qtdLoading() {
    return this.allFiles.filter((file) => !file.done)?.length;
  }

  constructor(
    public downloadInBackgroundService: FileDownloadBackgoundService
  ) {}

  ngOnInit(): void {
    this.downloadInBackgroundService.showDownloadInBackgroud.subscribe(
      ({ show, files }) => {
        this.files = files;
        this.show = show;
      }
    );

    this.downloadInBackgroundService.downloadProgress$.subscribe(
      (progress) => (this.progressMap = progress)
    );
  }
}
