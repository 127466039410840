<div class="align-middle text-center" [ngSwitch]="tipoDocumentoEvento" *ngIf="tipoDocumentoEvento">
    <div *ngSwitchCase="1">
        <span class="d-inline-block" tabindex="0" ngbTooltip="Tarefa">
            <i class="far fa-check-square"></i>
        </span>
    </div>
    <div *ngSwitchCase="2">
        <span class="d-inline-block" tabindex="0" ngbTooltip="Evento">
            <i class="fas fa-flag"></i>
        </span>
    </div>
    <div *ngSwitchCase="3">
        <span class="d-inline-block" tabindex="0" ngbTooltip="Evento">
            <i class="fas fa-calendar"></i>
        </span>
    </div>
    <div *ngSwitchCase="4">
        <span class="d-inline-block" tabindex="0" ngbTooltip="Audiência">
            <i class="far fa-check-square"></i>
        </span>
    </div>
    <div *ngSwitchDefault>
        <span class="d-inline-block" tabindex="0" ngbTooltip="Outros">
            <i class="fas fa-file"></i>
        </span>
    </div>
</div>

<div *ngIf="anexos">    
    <div *ngFor="let anexo of documento.anexos">
        <button class="btn btn-primary" [ngbTooltip]="anexo.arquivoNome" (click)="handleDownload(anexo)">
            Download <i class="fas fa-download"></i>
        </button>
    </div>
</div>