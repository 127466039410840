import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PessoaService } from 'src/app/services/pessoa.service';

@Component({
    selector: 'pessoa-dropdown',
    templateUrl: './pessoa-dropdown.component.html',
    styleUrls: ['./pessoa-dropdown.component.css']
})
export class PessoaDropdownComponent implements OnInit {

    @Input() pessoaId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() pessoaIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    private searchText$ = new Subject<string>();
    subscription: Subscription;

    public pessoas: any = [];
    public loadPessoas: boolean = false;
    public pessoa: any;

    constructor(
        private pessoaService: PessoaService
    ) { }

    ngOnInit(): void {
        this.subscription = this.searchText$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((value: string) => {   
                this.buscarPessoa(value);                    
            }
        );

        if (this.pessoaId != undefined && this.pessoaId != null && this.pessoaId != '') {
            this.buscarPorId();
        } else {
            this.buscarPessoa('a');
        }        
    }

    selecionarPessoa(pessoaId: any): void {

        this.pessoaId = pessoaId;

        if(this.pessoaId)
            this.buscarPorId();

        this.pessoaIdChange.emit(this.pessoaId);
    }

    changePessoa($event) {

        this.pessoaIdChange.emit(this.pessoaId);
        this.change.emit(this.pessoas.filter(x => x.id == this.pessoaId)[0]);
    }

    limparPessoa() {

        this.pessoaIdChange.emit(null);
    }

    filtrar(term: any) {

        if (term.keyCode == 38 || term.keyCode == 40) return;

        if (term.keyCode == 13) {
            this.changePessoa(null);
        }

        if (term.target.value != '') {            
            this.searchText$.next(term.target.value);            
            //this.buscarPessoa(term.target.value);
        }        
    }

    buscarPessoa(buscar) {
        
        this.loadPessoas = true;

        this.pessoaService.obterPessoaFiltroNomeAsync(buscar).then(x => {

            this.loadPessoas = false;
            this.pessoas = x.data;

            if (this.pessoa != undefined)
                this.pessoas.push(this.pessoa);

        });
    }

    buscarPorId() {

        this.loadPessoas = true;

        this.pessoaService.getByIdAsync(this.pessoaId)
            .then(res => {
                this.loadPessoas = false;
                let pessoas = [];
                pessoas.push(res.data);
                this.pessoa = res.data;
                this.pessoas = pessoas;
            })
    }
}
