import { Component, OnInit, NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-layout-dashboard',
    templateUrl: './layout-dashboard.component.html',
    styleUrls: ['./layout-dashboard.component.scss']
})
export class LayoutDashboardComponent implements OnInit {

    open = true;

    constructor(private router: Router) {
        if (this.router.url.indexOf('/atividades-usuario') > -1) {
            this.open = false;
        } else {
            router.events.subscribe((val) => {
                if (val instanceof NavigationStart) {
                    this.open = JSON.parse(sessionStorage.getItem('openMenu')) === false ? false : true;
                }
                if (val instanceof NavigationEnd) {
                    sessionStorage.setItem('openMenu', JSON.stringify(true));
                }
            });
        }
    }

    ngOnInit(): void {
    }

}
