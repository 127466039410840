import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';

@Component({
    selector: 'app-process-details',
    templateUrl: './process-details.component.html',
    styleUrls: ['./process-details.component.scss']
})
export class ProcessDetailsComponent implements OnInit {

    @Input() processoId: any;
    @Input() processo: any;
    @Input() item: any;
    
    public ocorrencia: any;
    public recursoDesdobramento: any;
    public envolvidos: string;
    public recursosDesdobramentos:any = [{}]
    public parte1: string;
    public parte2: string;
    public carregandoProcesso: boolean = true;
    public carregandoRecurso: boolean = true;
    public carregancoOcorrencia: boolean = true;

    isShow = true;    

    constructor(
        private spinner: NgxSpinnerService,
        private processosJudiciaisService: ProcessosJudiciaisService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
    ) {}

    ngOnInit(): void 
    {        
        this.buscarProcesso();
        this.buscarRecursosDesdobramentos();
        this.buscarOcorrencia();
    }

    carregando(){
        if(!this.carregancoOcorrencia && !this.carregandoProcesso && !this.carregandoRecurso){            
            this.spinner.hide();
        }
    }

    buscarOcorrencia() {
        this.processosJudiciaisOcorrenciasService.getById(this.item.processoJudicialOcorrenciaId)
            .subscribe(res=>{
                 this.ocorrencia = res.data;
                 
                 this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(this.ocorrencia.processoJudicialRecursoDesdobramentoId)
                    .subscribe(res=>{                        
                        this.recursoDesdobramento = res.data;
                        
                        if(res.data.partes.length == 2){
                            this.parte1 = res.data.partes[0].nome +"(" + res.data.partes[0].qualificacaoNome+")";
                            this.parte2 = res.data.partes[1].nome +"(" + res.data.partes[1].qualificacaoNome+")";
                        }
                        this.carregancoOcorrencia = false;
                        this.carregando();
                    })
            });
    }
    buscarProcesso() {
        if(this.processo == undefined){
            this.processosJudiciaisService.getById(this.processoId).subscribe(processo => {
                this.processo = processo.data;              
                this.carregandoProcesso = false;
                this.carregando();
            });
        }else{
            this.carregandoProcesso = false;
            this.carregando();
        }
    }    

    exibirSituacao:boolean = false;
    
    obterSituacao(){
        let situacao:string = "";
        
        this.recursosDesdobramentos.forEach((recurso, index) => {
            if(!this.exibirSituacao && recurso.instanciaNome != null){
                this.exibirSituacao = true;
            }

            situacao += recurso.instanciaNome +" (";            
            situacao += (recurso.ativo ? "Ativo" : "Encerrado") + ") ";

            if(index < this.recursosDesdobramentos.length-1){
                situacao += "| ";
            }
        });

        return situacao;
    }

    defineClass(classe: string) {
        return 'badge ' + classe;
    }

    exibirStatus: boolean = false;

    obterStatus(){
        let status:string = "";
        
        this.recursosDesdobramentos.forEach((recurso, index) => {
            if(!this.exibirStatus && recurso.instanciaNome != null){
                this.exibirStatus = true;
            }
            
            status += recurso.instanciaNome +" ";
            
            if(recurso.situacao != null){
                status += `<div class='${this.defineClass(recurso.situacaoCor)}'>`;
                status += recurso.situacao + "</div> ";
            }
            
            if(index < this.recursosDesdobramentos.length-1){
                status += "| ";
            }
        });

        return status;
    }

    buscarRecursosDesdobramentos() {        
        this.processosJudiciaisRecursoDesdobramentoService.obterPorProcesso(this.processoId)
            .subscribe(res => {
                if (res.success) {
                    this.recursosDesdobramentos = res.data;                    
                    this.recursosDesdobramentos.forEach(recurso=>{
                        recurso.loadHistoricoOcorrencia = true;      
                        recurso.selecionado = false;
                        
                        if(recurso.processoJudicialRecursoDesdobramentos != undefined && recurso.processoJudicialRecursoDesdobramentos.length > 0){
                            recurso.processoJudicialRecursoDesdobramentos.forEach(des=>{
                                des.selecionado = false;
                            });
                        }
                        this.carregandoRecurso = false;
                        this.carregando();
                    });                    
                }
            })
    }    
}
