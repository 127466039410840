import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalHeaderJurosMoratoriosComponent } from './components/modal-header-juros-moratorios/modal-header-juros-moratorios.component';
import { dataTable } from './tables';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AtualizacaoValoresService } from 'src/app/services/atualizacao-valores.service';
import { IndiceService } from 'src/app/services/indice.service';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CALCULATION_DATA_FORM_BINDING, HEADER_JUROS_AVANCADO, HONORARIOS_ADVOCATICIOS_SUCUMBENCIAIS, HONORARIO_REGRAS, MULTA_ART_NCPC, MULTA_REGRAS, VALORES_FORM_BINDING } from './calculation.enum';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { fil } from 'date-fns/locale';
import { ModalMultaComponent } from './components/modal-multa/modal-multa.component';
import { ModalHonorariosComponent } from './components/modal-honorarios/modal-honorarios.component';
import { ModalHonorariosSucubenciaComponent } from './components/modal-honorarios-sucubencia/modal-honorarios-sucubencia.component';
import { ModalMultaNcpcComponent } from './components/modal-multa-ncpc/modal-multa-ncpc.component';

@Component({
    selector: "app-calculation-data",
    templateUrl: "./calculation-data.component.html",
    styleUrls: ["./calculation-data.component.scss"],
})
export class CalculationDataComponent implements OnInit, OnDestroy {
    readonly CALCULATION_DATA_FORM_BINDING = CALCULATION_DATA_FORM_BINDING;
    readonly VALORES_FORM_BINDING = VALORES_FORM_BINDING;
    readonly HEADER_JUROS_AVANCADO = HEADER_JUROS_AVANCADO;
    readonly MULTA_REGRAS = MULTA_REGRAS;
    readonly HONORARIO_REGRAS = HONORARIO_REGRAS;

    public dataTable = dataTable;
    public valoresAtualizar = [];
    public indices = [];

    public showAllField = true;

    public id = null;

    public loading: boolean = true;

    resultConfirmDialog$ = new Subject<ResultadoModal>();

    /** Form Header Juros Moratorios */
    public headerJurosMoratoriosForm = this.fb.group({
        [HEADER_JUROS_AVANCADO.TIPOJUROS]: "",
        [HEADER_JUROS_AVANCADO.TIPOFORMULARIOCALCULO]: ["S"],
        [HEADER_JUROS_AVANCADO.PERCENTUAL]: [0.0],
        [HEADER_JUROS_AVANCADO.TIPOCALCULO]: [1],
        [HEADER_JUROS_AVANCADO.JUROSPROPORCIONAL]: [false],
        [HEADER_JUROS_AVANCADO.JUROSDATAVENCIMENTO]: [false],
        [HEADER_JUROS_AVANCADO.AVANCADO]: this.fb.array([]),
    });

    public headerJurosCompensatoriosForm = this.fb.group({
        [HEADER_JUROS_AVANCADO.TIPOJUROS]: "",
        [HEADER_JUROS_AVANCADO.TIPOFORMULARIOCALCULO]: ["S"],
        [HEADER_JUROS_AVANCADO.PERCENTUAL]: [0.0],
        [HEADER_JUROS_AVANCADO.TIPOCALCULO]: [1],
        [HEADER_JUROS_AVANCADO.JUROSPROPORCIONAL]: [false],
        [HEADER_JUROS_AVANCADO.JUROSDATAVENCIMENTO]: [false],
        [HEADER_JUROS_AVANCADO.AVANCADO]: this.fb.array([]),
    });

    public multaForm = this.fb.group({
        [MULTA_REGRAS.ID]: null,
        [MULTA_REGRAS.VALOR]: 0.0,
        [MULTA_REGRAS.TIPOCALCULOMULTA]: 1,
        [MULTA_REGRAS.CALCULARMULTASOBREJUROS]: false,
    });

    public honorarioForm = this.fb.group({
        [HONORARIO_REGRAS.ID]: null,
        [HONORARIO_REGRAS.VALOR]: 0.0,
        [HONORARIO_REGRAS.TIPOCALCULOHONORARIO]: "1"
    })

    public honorarioSucubenciaForm = this.fb.group({
        [HONORARIOS_ADVOCATICIOS_SUCUMBENCIAIS.ID]: null,
        [HONORARIOS_ADVOCATICIOS_SUCUMBENCIAIS.TIPOFORMULARIOCALCULO]: "T",
        [HONORARIOS_ADVOCATICIOS_SUCUMBENCIAIS.VALORBASECALCULO]: 0,
        [HONORARIOS_ADVOCATICIOS_SUCUMBENCIAIS.VALORPERCENTUAL]: 0
    })

    public multaArtNCPForm = this.fb.group({
        [MULTA_ART_NCPC.ID]: null,
        [MULTA_ART_NCPC.VALORATUALIZADO]: false,
        [MULTA_ART_NCPC.JUROSMORATORIOS]: false,
        [MULTA_ART_NCPC.JUROSCOMPENSATORIOS]: false,
        [MULTA_ART_NCPC.MULTA]: false,
        [MULTA_ART_NCPC.HONORARIOS]: false,
        [MULTA_ART_NCPC.HONORARIOSSUCUMBENCIA]: false,
        [MULTA_ART_NCPC.CUSTASATUALIZADAS]: false,
    });

    /** Formulários */
    public form = this.fb.group({

        /** Formulário dados do cálculo */
        calculationDataForm: this.fb.group({
            [CALCULATION_DATA_FORM_BINDING.ID]: '',
            [CALCULATION_DATA_FORM_BINDING.APROVADO]: true,
            [CALCULATION_DATA_FORM_BINDING.NOME]: 'Novo cálculo de atualização',
            [CALCULATION_DATA_FORM_BINDING.DESC]: '',
            [CALCULATION_DATA_FORM_BINDING.DATA_ATUALIZACAO]: null,
            [CALCULATION_DATA_FORM_BINDING.INDICE_ID]: 'b47a327b-d442-470e-a384-96e04f62f9d7',
            [CALCULATION_DATA_FORM_BINDING.PRO_DATA]: false,
            [CALCULATION_DATA_FORM_BINDING.INDICE_NEGATIVOS]: true,
            [CALCULATION_DATA_FORM_BINDING.RECURSO_DESDOBRAMENTO_ID]: '',
            [CALCULATION_DATA_FORM_BINDING.JUROSMORATORIOS]: false,
            [CALCULATION_DATA_FORM_BINDING.JUROSCOMPENSATORIOS]: false,
            [CALCULATION_DATA_FORM_BINDING.JUROSMORATORIOSREGRAS]: {},
            [CALCULATION_DATA_FORM_BINDING.JUROSCOMPENSATORIOSREGRAS]: {},
            [CALCULATION_DATA_FORM_BINDING.VALORTOTALATUALIZADO]: 0.0,
            [CALCULATION_DATA_FORM_BINDING.VALORTOTALJUROSMORATORIOS]: 0.0,
            [CALCULATION_DATA_FORM_BINDING.VALORTOTALJUROSCOMPENSATORIOS]: 0.0,
            [CALCULATION_DATA_FORM_BINDING.MULTA]: false,
            [CALCULATION_DATA_FORM_BINDING.MULTAREGRAS]: {},
            [CALCULATION_DATA_FORM_BINDING.VALORTOTALMULTAS]: 0.0,
            [CALCULATION_DATA_FORM_BINDING.HONORARIO]: false,
            [CALCULATION_DATA_FORM_BINDING.HONORARIOREGRAS]: {},
            [CALCULATION_DATA_FORM_BINDING.VALORTOTALHONORARIOS]: 0.0,
            [CALCULATION_DATA_FORM_BINDING.HONORARIOSSUCUMBENCIA]: false,
            [CALCULATION_DATA_FORM_BINDING.SUCUMBENCIAREGRAS]: {},
            [CALCULATION_DATA_FORM_BINDING.VALORSUCUMBENCIA]: null,
            [CALCULATION_DATA_FORM_BINDING.VALORTOTALGERAL]: 0.0,
            [CALCULATION_DATA_FORM_BINDING.MULTAARTNCPCREGRAS]: {},
            [CALCULATION_DATA_FORM_BINDING.MULTAARTNCPC]: false,
            [CALCULATION_DATA_FORM_BINDING.VALORMULTAARTNCPC]: null,
        }),

        /** Formulário de valores */
        valoresForm: this.fb.array([]),
    });

    get calculationDataForm() {
        return this.form.controls.calculationDataForm as FormGroup;
    }

    get valoresForm() {
        return this.form.controls.valoresForm as FormArray;
    }

    public subscriptions = new Subscription();

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-padrao btn-lg mr-3",
            cancelButton: "btn btn-default btn-lg",
        },
        buttonsStyling: false,
    });

    constructor(
        public modalService: NgbModal,
        private fb: FormBuilder,
        private service: AtualizacaoValoresService,
        private indiceService: IndiceService,
        private activatedRoute: ActivatedRoute,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private location: Location,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params["id"];

        this.handleValoresAtualizar();
        this.getIndices();
        this.getValue();
    }

    getValue() {
        if (!this.id) {
            this.createValorForm();
            this.loading = false;

            return;
        };
        this.spinner.show();
        this.subscriptions.add(
            this.service.getById(this.id).subscribe(
                value => {
                    this.setForms(value.data);
                    this.spinner.hide()
                })
        )
    }

    setForms(data: any) {
        this.valoresForm.clear();
        Object.values(CALCULATION_DATA_FORM_BINDING).forEach((field: string) => {            
            this.calculationDataForm
                .get(field)
                .setValue(
                    field === CALCULATION_DATA_FORM_BINDING.DATA_ATUALIZACAO &&
                        data[field] != null
                        ? data[field].split("T")[0]
                        : data[field]
                );

            this.calculationDataForm.get(field).setValue((field === CALCULATION_DATA_FORM_BINDING.DATA_ATUALIZACAO && data[field] != null) ? data[field].split('T')[0] : data[field]);            

            this.valoresAtualizar.find(f => f.id != undefined && f.id == "jurosMoratorios").value = data.jurosMoratorios;

            if (data.jurosMoratoriosRegras != null) {
                let jurosMoratorios = data.jurosMoratoriosRegras;
                Object.values(HEADER_JUROS_AVANCADO).forEach((field: string) => {
                    if (field !== HEADER_JUROS_AVANCADO.AVANCADO)
                        this.headerJurosMoratoriosForm
                            .get(field)
                            .setValue(jurosMoratorios[field]);
                });
            }

            this.valoresAtualizar.find(
                (f) => f.id != undefined && f.id == "jurosCompensatorios"
            ).value = data.jurosCompensatorios;

            if (data.jurosCompensatoriosRegras != null) {
                let jurosCompensatorios = data.jurosCompensatoriosRegras;
                Object.values(HEADER_JUROS_AVANCADO).forEach((field: string) => {
                    if (field !== HEADER_JUROS_AVANCADO.AVANCADO)
                        this.headerJurosCompensatoriosForm
                            .get(field)
                            .setValue(jurosCompensatorios[field]);
                });
            }

            this.valoresAtualizar.find(
                (f) => f.id != undefined && f.id == "multa"
            ).value = data.multa;

            if (data.multaRegras != null) {
                let multaRegras = data.multaRegras;
                Object.values(MULTA_REGRAS).forEach((field: string) => {
                    this.multaForm.get(field).setValue(multaRegras[field]);
                });
            }

            this.valoresAtualizar.find(
                (f) => f.id != undefined && f.id == "honorarios"
            ).value = data.honorario;

            if (data.honorarioRegras != null) {
                let honorarioRegras = data.honorarioRegras;
                Object.values(HONORARIO_REGRAS).forEach((field: string) => {
                    this.honorarioForm.get(field).setValue(honorarioRegras[field]);
                });
            }

            if (field === CALCULATION_DATA_FORM_BINDING.HONORARIOSSUCUMBENCIA) {
                this.honorariosSucumbencia = data.honorariosSucumbencia;
                this.calculationDataForm.get(field).setValue(data.honorariosSucubencia);
            }
                        

            if (data.sucumbenciaRegras != null) {
                let sucumbenciaRegras = data.sucumbenciaRegras;
                Object.values(HONORARIOS_ADVOCATICIOS_SUCUMBENCIAIS).forEach((field: string) => {
                    this.honorarioSucubenciaForm.get(field).setValue(sucumbenciaRegras[field]);
                });
            }

            if (field === CALCULATION_DATA_FORM_BINDING.MULTAARTNCPC) {                
                this.multaArtNCPC = data.multaArtNCPC;
                this.calculationDataForm.get(field).setValue(data.multaArtNCPC);
            }

            if (data.multaArtNCPCRegras != null) {                
                let multaArtNCPCRegras = data.multaArtNCPCRegras;
                Object.values(MULTA_ART_NCPC).forEach((field: string) => {
                    this.multaArtNCPForm.get(field).setValue(multaArtNCPCRegras[field]);
                });
            }            
        });

        data.valores.forEach((valor) => {
            this.createValorForm(valor);
        });

        this.loading = false;
    }

    deleteAllValuesFormArray() {
        this.valoresForm.controls.forEach((_, i) => this.valoresForm.removeAt(i));
    }

    createValorForm(valor?: any) {
        const obj = {};
        Object.values(VALORES_FORM_BINDING).forEach((field: string) => {
            if (!valor) {
                obj[field] = null;
                return;
            }

            obj[field] =
                field === VALORES_FORM_BINDING.DATA && valor[field] != null
                    ? valor[field].split("T")[0]
                    : valor[field];
        });
        this.valoresForm.push(this.fb.group(obj, { updateOn: "blur" }));
    }

    getIndices() {
        this.spinner.show();
        this.subscriptions.add(
            this.indiceService.getAll().subscribe((indices) => {
                this.indices = indices.data;
                this.dataTable = this.dataTable.map((data) =>
                    data.controlName === CALCULATION_DATA_FORM_BINDING.INDICE_ID
                        ? { ...data, items: this.indices }
                        : data
                );
                this.spinner.hide();
            })
        );
    }

    sumUpdateValues() {
        const valores = this.valoresForm.getRawValue();
        return valores.reduce(
            (acc, curr) => acc + parseFloat(curr[VALORES_FORM_BINDING.VALOR_TOTAL]),
            0
        );
    }

    // erro ao inserir novo valor, talvez por não ter ID
    // campo valor atualizado não está sendo calculado

    saveCalc() {
        const params = {
            valores: this.valoresForm.getRawValue(),
            ...this.calculationDataForm.getRawValue(),
        };
        console.log(params);
        params.dataAtualizacaoValores =
            params.dataAtualizacaoValores != null
                ? params.dataAtualizacaoValores + " 12:00:00"
                : null;

        let valido = true;

        if (params.valores) {
            params.valores.forEach((vl) => {
                //valido = vl.descricao != null && vl.valor != null && vl.data;
                if (vl.data != null) vl.data = vl.data + " 12:00:00";
            });
        }
        this.loading = true;
        if (valido) {
            if (!!this.id) {
                this.service.update(params).subscribe(
                    (res) => {
                        this.setForms(res.data);
                    },
                    (error) => {
                        if (error && error.errors) {
                            this.toastrService.error(error.errors[0], "Atenção", {
                                timeOut: 10000,
                            });
                        } else {
                            this.toastrService.error(
                                "Não foi possível calcular!",
                                "Atenção",
                                { timeOut: 10000 }
                            );
                        }
                    }
                );
            } else {
                params.jurosCompensatoriosRegras = null;
                params.jurosMoratoriosRegras = null;
                params.multaRegras = null;

                this.service.add(params).subscribe(
                    (res) => {
                        this.id = res.data.id;
                        this.location.replaceState(this.router.url + "/" + this.id);
                        this.setForms(res.data);
                    },
                    (error) => {
                        if (error && error.errors) {
                            this.toastrService.error(error.errors[0], "Atenção", {
                                timeOut: 10000,
                            });
                        } else {
                            this.toastrService.error(
                                "Não foi possível calcular!",
                                "Atenção",
                                { timeOut: 10000 }
                            );
                        }
                    }
                );
            }

            //this.getValue();
        }
    }

    handleValoresAtualizar() {
        this.valoresAtualizar = [
            { header: "Data", class: "w-15" },
            { header: "Valor", class: "w-15" },
            { header: "Valor Atualizado", class: "w-15" },
            {
                header: "Juros Moratórios",
                hasEngrenagem: true,
                value: false,
                id: "jurosMoratorios",
                hasCheck: true,
                check: (value) => {
                    this.setDataJurosMoratoriosForm(value, 1);
                },
                open: () => {
                    this.openModalJurosMoratoriosCompensatorios("Juros Moratórios", 1);
                },
            },
            {
                header: "Juros Compensatórios",
                hasEngrenagem: true,
                id: "jurosCompensatorios",
                hasCheck: true,
                value: false,
                check: (value) => {
                    this.setDataJurosMoratoriosForm(value, 2);
                },
                open: () => {
                    this.openModalJurosMoratoriosCompensatorios(
                        "Juros Compensatórios",
                        2
                    );
                },
            },
            {
                header: "Multa",
                hasEngrenagem: true,
                hasCheck: true,
                id: "multa",
                value: false,
                check: (value) => {
                    this.setDataMultaForm(value);
                },
                open: () => {
                    this.openModalMulta();
                },
            },
            {
                header: "Honorários",
                hasEngrenagem: true,
                hasCheck: true,
                id: "honorarios",
                value: false,
                check: (value) => {
                    this.setDataHonorarioForm(value);
                },
                open: () => {
                    this.openModalHonorarios();
                },
            },
            { header: "Custas", hasEngrenagem: true, id: "custas" },
            { header: "Total", id: "total" },
        ];
    }

    openModalJurosMoratoriosCompensatorios(title, tipo) {
        const modalRef = this.modalService.open(
            ModalHeaderJurosMoratoriosComponent
        );

        if (tipo == 1) {
            modalRef.componentInstance.headerJurosMoratoriosForm =
                this.headerJurosMoratoriosForm;
        } else {
            modalRef.componentInstance.headerJurosMoratoriosForm =
                this.headerJurosCompensatoriosForm;
        }

        modalRef.componentInstance.title = title;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result
            .then((result) => {
                if (tipo == 1) {
                    this.valoresAtualizar.find(
                        (f) => f.id != undefined && f.id == "jurosMoratorios"
                    ).value = true;
                    this.calculationDataForm
                        .get(CALCULATION_DATA_FORM_BINDING.JUROSMORATORIOSREGRAS)
                        ?.setValue(this.headerJurosMoratoriosForm.getRawValue());
                } else {
                    this.valoresAtualizar.find(
                        (f) => f.id != undefined && f.id == "jurosCompensatorios"
                    ).value = true;
                    this.calculationDataForm
                        .get(CALCULATION_DATA_FORM_BINDING.JUROSCOMPENSATORIOSREGRAS)
                        ?.setValue(this.headerJurosCompensatoriosForm.getRawValue());
                }
                this.setDataJurosMoratoriosForm(true, tipo);
            })
            .catch((error) => { });
    }

    openModalMulta() {
        const modalRef = this.modalService.open(ModalMultaComponent);
        modalRef.componentInstance.multaForm = this.multaForm;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result.then((result) => {
            this.calculationDataForm
                .get(CALCULATION_DATA_FORM_BINDING.MULTAREGRAS)
                ?.setValue(this.multaForm.getRawValue());
            this.setDataMultaForm(true);
        });
    }

    openModalHonorarios() {
        const modalRef = this.modalService.open(ModalHonorariosComponent);
        modalRef.componentInstance.honorarioForm = this.honorarioForm;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result.then(result => {
            this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.HONORARIOREGRAS)?.setValue(this.honorarioForm.getRawValue());
            this.setDataHonorarioForm(true);
        });
    }

    openModalHonorarioAdvocativosSucumbenciais() {
        const modalRef = this.modalService.open(ModalHonorariosSucubenciaComponent);
        modalRef.componentInstance.honorarioSucubenciaForm = this.honorarioSucubenciaForm;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result.then(result => {
            this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.SUCUMBENCIAREGRAS)?.setValue(this.honorarioSucubenciaForm.getRawValue());

            this.honorariosSucumbencia = true;

            this.changeHonorarioAdvocativosSucumbenciais();
        });
    }

    openModalMultaNCPC() {
        const modalRef = this.modalService.open(ModalMultaNcpcComponent, 
        { 
            size: 'sm',            
            backdrop: 'static',
            keyboard: false
        });
        
        modalRef.componentInstance.multaArtNCP = this.multaArtNCPForm;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result.then(result => {
            this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.MULTAARTNCPCREGRAS)?.setValue(this.multaArtNCPForm.getRawValue());

            this.multaArtNCPC = true;

            this.changeMultaArtNPC();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setDataJurosMoratoriosForm(value, tipo) {

        if (tipo == 1) {
            this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.JUROSMORATORIOS).setValue(value);

            this.valoresForm.controls.forEach((e) => {
                var juros = e.get(VALORES_FORM_BINDING.JUROSMORATORIOS);
                juros.setValue(value);
            });
        } else {
            this.calculationDataForm
                .get(CALCULATION_DATA_FORM_BINDING.JUROSCOMPENSATORIOS)
                .setValue(value);

            this.valoresForm.controls.forEach((e) => {
                var juros = e.get(VALORES_FORM_BINDING.JUROSCOMPENSATORIOS);
                juros.setValue(value);
            });
        }

        this.saveCalc();
    }

    setDataMultaForm(value) {
        this.calculationDataForm
            .get(CALCULATION_DATA_FORM_BINDING.MULTA)
            .setValue(value);

        this.valoresForm.controls.forEach((e) => {
            var juros = e.get(VALORES_FORM_BINDING.MULTA);
            juros.setValue(value);
        });

        this.saveCalc();
    }

    setDataHonorarioForm(value) {
        this.calculationDataForm
            .get(CALCULATION_DATA_FORM_BINDING.HONORARIO)
            .setValue(value);

        this.valoresForm.controls.forEach((e) => {
            var juros = e.get(VALORES_FORM_BINDING.HONORARIO);
            juros.setValue(value);
        });

        this.saveCalc();
    }

    valorTotalAtualizado() {
        return this.calculationDataForm.get(
            CALCULATION_DATA_FORM_BINDING.VALORTOTALATUALIZADO
        ).value;
    }

    valorTotalJurosMoratorios() {
        return this.calculationDataForm.get(
            CALCULATION_DATA_FORM_BINDING.VALORTOTALJUROSMORATORIOS
        ).value;
    }

    valorTotalJurosCompensatorios() {
        return this.calculationDataForm.get(
            CALCULATION_DATA_FORM_BINDING.VALORTOTALJUROSCOMPENSATORIOS
        ).value;
    }

    valorTotalMultas() {
        return this.calculationDataForm.get(
            CALCULATION_DATA_FORM_BINDING.VALORTOTALMULTAS
        ).value;
    }

    valorTotalHonorarios() {
        return this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.VALORTOTALHONORARIOS).value;
    }

    honorariosSucumbencia: boolean;

    changeHonorarioAdvocativosSucumbenciais() {

        let honorario = this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.HONORARIOSSUCUMBENCIA);

        honorario.setValue(this.honorariosSucumbencia);

        this.saveCalc();
    }

    multaArtNCPC: boolean;

    changeMultaArtNPC() {

        let multa = this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.MULTAARTNCPC);

        multa.setValue(this.multaArtNCPC);

        this.saveCalc();
    }

    valorSucumbencia() {
        let valor = this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.VALORSUCUMBENCIA).value;

        if (valor == null) return 0;

        return valor;
    }

    valormultaArtNCPC(){
        let valor = this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.VALORMULTAARTNCPC).value;

        if (valor == null) return 0;

        return valor;
    }

    valorTotalGeral() {
        return this.calculationDataForm.get(CALCULATION_DATA_FORM_BINDING.VALORTOTALGERAL).value;
    }

    voltar(){
        this.location.back();
    }
}
