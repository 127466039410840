import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ServiceBase } from "./service.base"
import { environment } from 'src/environments/environment';
import { catchError, map } from "rxjs/operators";
import { Guid } from "guid-typescript";

@Injectable({
    providedIn: 'root'
})

export class CompetenciaService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'competencia/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    obterPorId(id: Guid): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listar(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    inserir(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizar(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    remover(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByIdAsync(id) {
        return this.http
            .get(environment.api + this.endpoint + `getById/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

    obterPorFiltroNomeAsync(busca) {
        return this.http
            .get(environment.api + this.endpoint + "obter-por-filtro-nome/" + busca)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }
}