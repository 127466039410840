import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FaturamentoExtratoService } from 'src/app/services/faturamento-extrato.service';

@Component({
  selector: 'app-modal-detalhamento-extrato-credor-por-periodo',
  templateUrl: './modal-detalhamento-extrato-credor-por-periodo.component.html',
  styleUrls: ['./modal-detalhamento-extrato-credor-por-periodo.component.css']
})
export class ModalDetalhamentoExtratoCredorPorPeriodoComponent implements OnInit {

  modalRef: NgbModalRef;

  load:boolean = false;
  extratos:any = [];
  credorId:any;

  startDate:Date = new Date();
  endDate:Date = new Date();

  constructor(
    private faturamentoExtratoService: FaturamentoExtratoService,
  ) { }

  ngOnInit(): void {
    this.endDate = new Date();
    this.startDate = this.getDateMonthsBefore(new Date(),12);
    this.credorId = this.modalRef.componentInstance.credorId;
    this.getExtratosByCredor(this.credorId,this.startDate,this.endDate);
  }


  getExtratosByCredor(credorId,startDate:Date,endDate:Date)
  {

    this.load = true;
      this.faturamentoExtratoService.getAllFaturamentoExtratoByCredorAndDateGroupByMonth(credorId,startDate,endDate).subscribe(x => {
        this.extratos = x.data;

        x.data.forEach(element => {

          var dt:Date = new Date(element.periodo);

          this.faturamentoExtratoService.getTaxaAdministrativaByCredorAndDate(dt,this.credorId).subscribe(y => {
            element.taxaAdministrativa = y.data.taxaAdministrativa
          });
          this.faturamentoExtratoService.getRetidoByCredorAndDate(dt,this.credorId).subscribe(y => {
            element.retido = y.data.retido
          });

        });

        this.load = false;
      })
  }


  close() {
    this.modalRef.close();
  }

  dismiss() {
      this.modalRef.dismiss();
  }


  getDateMonthsBefore(date,nofMonths) {
    var thisMonth = date.getMonth();
    // set the month index of the date by subtracting nofMonths from the current month index
    date.setMonth(thisMonth - nofMonths);
    // When trying to add or subtract months from a Javascript Date() Object which is any end date of a month,
    // JS automatically advances your Date object to next month's first date if the resulting date does not exist in its month.
    // For example when you add 1 month to October 31, 2008 , it gives Dec 1, 2008 since November 31, 2008 does not exist.
    // if the result of subtraction is negative and add 6 to the index and check if JS has auto advanced the date,
    // then set the date again to last day of previous month
    // Else check if the result of subtraction is non negative, subtract nofMonths to the index and check the same.
    if((thisMonth - nofMonths < 0) && (date.getMonth() != (thisMonth + nofMonths))) {
        date.setDate(0);
    } else if ((thisMonth - nofMonths >= 0) && (date.getMonth() != thisMonth - nofMonths)) {
        date.setDate(0);
    }
    return date;
}
}
