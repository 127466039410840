import { AuthService } from "src/app/auth/auth.service";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Guid } from "guid-typescript";
import { FileLikeObject, FileUploader } from "ng2-file-upload";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { AppService } from "src/app/app.service";
import { Tipo } from "src/app/enums/documento/tipo.enum";
import { TipoStatus } from "src/app/enums/tipo-status.enum";
import { CustomFileLike } from "src/app/models/custom-file-like";
import { Ocorrencia } from "src/app/models/operacionais/ocorrencia/ocorrencia";
import { OcorrenciaResponse } from "src/app/models/operacionais/ocorrencia/ocorrencia-response";
import { OcorrenciaAtualizarStatus, OcorrenciaStatusRequest } from "src/app/models/operacionais/ocorrencia/ocorrencia-status-request";
import { ProcessoDivida } from "src/app/models/operacionais/operacao/processo-divida";
import { ProcessoOperacao } from "src/app/models/operacionais/operacao/processo-operacao";
import { SelectList } from "src/app/models/select-list";
import { DividaPagamentosService } from "src/app/services/divida-pagamentos.service";
import { DividaService } from "src/app/services/divida.service";
import { DocumentoService } from "src/app/services/documento.service";
import { OcorrenciaService } from "src/app/services/ocorrencia.service";
import { ModalService } from "src/app/services/shared/modal-service";
import { UtilsService } from "src/app/utils/utils.service";

@Component({
  selector: "app-modal-aprovar-divida-pagamento",
  templateUrl: "./modal-aprovar-divida-pagamento.component.html",
  styleUrls: ["./modal-aprovar-divida-pagamento.component.css"],
})
export class ModalAprovarDividaPagamentoComponent
  implements OnInit, AfterContentChecked
{
  enableButtons: Boolean = true;

  modalRef: NgbModalRef;
  isIE10 = false;
  public isRTL: boolean;

  public ocorrencia: Ocorrencia;
  public processo: ProcessoOperacao;
  public dataPagamento: Date;

  public valorTotalPagamento: number = 0;
  public valorTotalPendente: number = 0;
  public valorTotalResidual: number = 0;
  public valorTotalComprovante: number = 0;

  public options: { readonly?: Boolean; previousTab?: String };

  tipoPagamento: String;

  constructor(
    private appService: AppService,
    public modalService: ModalService,
    public dividaService: DividaService,
    public dividaPagamentoService: DividaPagamentosService,
    public ocorrenciaService: OcorrenciaService,
    public toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    public utilsService: UtilsService,
    public documentoService: DocumentoService,
    private changeDetector: ChangeDetectorRef,
    public authService: AuthService
  ) {
    this.isRTL = appService.isRTL;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.processo = this.modalRef.componentInstance.processo;
    this.ocorrencia = this.modalRef.componentInstance.ocorrencia;

    this.divida.ocorrenciaId = this.ocorrencia.id;
    this.divida.processoId = this.processo.id;
    this.divida.dividas = [];

    this.dividaPagamentoService
      .getAllByOcorrencia(this.ocorrencia.id)
      .subscribe((res) => {
        this.divida.dividas = res.data;

        this.divida.dividas.forEach((divida) => {
          divida.dataRemessa = this.processo.dataRemessa;
          this.getTipoPagamento(divida);
          this.calculoValorPendente(divida);
        });

        this.valorTotalPendente = this.calculoValorTotalPendente();
        this.valorTotalPagamento = this.calculoValorTotalPagamento();
        this.valorTotalComprovante = this.calculoValorComprovante();
      });

    //building
    /*
        this.modalService.resultConfirmDialog$.asObservable().subscribe(res => {
          if (res != undefined) {
              if (res.result === true && res.page === 'emailCredor' && this.divida.dividas) {
                  this.divida.dividas.forEach(element => {
                    element.Emails = res.lista;
                  });
                  this.UpdateStatus(TipoStatus.DividaPagamentoRevisada);
                  //this.salvarAcordoCore();
              }
          }
        });
        */
  }

  public divida: any = {
    ocorrenciaId: "",
    processoId: "",
    dividas: [],
  };

  calculoValorTotalPagamento() {
    var valorTotal = 0;

    this.divida.dividas.forEach((pagamento) => {
      //divida.divida.pagamentos.forEach((pagamento) => {
        this.dataPagamento = pagamento.dataPagamento;
          valorTotal += pagamento.valorPagamento;
      //});
    });

    return valorTotal;
  }

  calculoValorComprovante() {
    return this.divida.dividas[0].valorComprovante;
  }

  calculoValorTotalPendente() {
    var valorTotal = 0;

    this.divida.dividas.forEach((divida) => {
      valorTotal += divida.divida.valorPendente;
    });

    return valorTotal;
  }

  calculoValorPendente(divida: any) {
    var valorTotalPagamento = 0;

    divida.divida.pagamentos.forEach((pagamento) => {
      valorTotalPagamento += pagamento.valorPagamento;
    });

    var valorDivida =
      divida.divida.valorAberto +
      divida.divida.juros +
      divida.divida.multa +
      divida.divida.valorCustasProtesto;

    divida.divida.valorPendente = valorDivida - valorTotalPagamento;

    return valorDivida - valorTotalPagamento;
  }

  calculoValorDivida(divida: any) {
    var valorDivida =
      divida.divida.valorAberto +
      divida.divida.juros +
      divida.divida.multa +
      divida.divida.valorCustasProtesto +
      divida.divida.valorAtualizacaoCustas;

    return valorDivida;
  }

  isShowRevisar() {
    return this.ocorrencia.status == TipoStatus.DividaPagamentoPendenteRevisao;
  }
  isShowReprovarRevisao() {
    return this.ocorrencia.status == TipoStatus.DividaPagamentoPendenteRevisao;
  }

  isShowAprovar() {
    return (
      this.ocorrencia.status == TipoStatus.DividaPagamentoRevisada ||
      this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao
    );
  }

  isShowReprovar() {
    //return this.ocorrencia.status == TipoStatus.DividaPagamentoRevisada || this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao;
    return !this.isShowReprovarRevisao();
  }

  checkMaster() {
    return this.authService.isMaster();
  }

  dismiss() {
    this.modalRef.dismiss();
  }

  close(processo: ProcessoOperacao) {
    this.modalRef.close();
    this.modalService.abrirModalContatoProcesso(processo);
  }

  revisarOcorrencia() {
    this.update(TipoStatus.DividaPagamentoRevisada);
  }

  revisaoReprovadaOcorrencia() {
    this.update(TipoStatus.OcorrenciaPendenteCobrador);
  }

  aprovarOcorrencia() {
    this.update(TipoStatus.OcorrenciaFinalizada);
  }

  reprovarOcorrencia() {
    this.update(TipoStatus.OcorrenciaPendenteCobrador);
  }

  private update(status: TipoStatus) {
    this.enableButtons = false;
    this.spinner.show();

    this.UpdateStatus(status);

    //Removido - [Item 14] Trello Amigavel
    // if (status == TipoStatus.DividaPagamentoRevisada) {
    //   this.enableButtons = true;
    //   this.spinner.hide();
    //   //chamar modal emails
    //   var resultado = this.modalService.abrirModalEmailCredor(
    //     this.processo.credorId
    //   );
    //   resultado.asObservable().subscribe((res) => {
    //     if (res != undefined) {
    //       if (
    //         res.result === true &&
    //         res.page === "emailCredor" &&
    //         this.divida.dividas
    //       ) {
    //         this.divida.dividas.forEach((element) => {
    //           element.Emails = res.lista;
    //         });
    //         this.UpdateStatus(TipoStatus.DividaPagamentoRevisada);
    //         //this.salvarAcordoCore();
    //       }
    //     }
    //   });
    // } else {
    //   this.UpdateStatus(status);
    // }
  }

  //building
  DividaPagamentoRevisada() {
    this.enableButtons = false;
    this.spinner.show();

    this.dividaPagamentoService.update(this.divida.dividas).subscribe(
      (del) => {
        this.toastrService.success("Ocorrência atualizada.", "Sucesso");
        this.divida.dividas = null;

        this.spinner.hide();
        this.enableButtons = true;

        this.close(this.processo);
      },
      (error) => {
        this.enableButtons = true;
        this.spinner.hide();
        this.toastrService.error(
          "Não foi possível atualizar a ocorrência.",
          "Error"
        );
      }
    );
  }

  DividaPagamentoPendenteCobrador() {
    this.dividaPagamentoService
      .delete(this.ocorrencia.id, this.processo.id)
      .subscribe(
        (del) => {
          this.enableButtons = true;
          this.toastrService.success("Ocorrência atualizada.", "Sucesso");
          this.spinner.hide();
          this.close(this.processo);
        },
        (error) => {
          this.enableButtons = true;
          this.spinner.hide();
          this.toastrService.error(
            "Não foi possível atualizar a ocorrência.",
            "Error"
          );
        }
      );
  }

  UpdateStatus(status: TipoStatus) {
    this.toastrService.clear();

    this.enableButtons = false;

    let ocorrenciaAtualizarStatus: OcorrenciaAtualizarStatus = {
        id: this.ocorrencia.id,
        processoId: this.processo.id
    }
    
    let ocorrenciaStatusRequest = new OcorrenciaStatusRequest(status, [ocorrenciaAtualizarStatus]);

    this.ocorrenciaService.updateStatus(ocorrenciaStatusRequest).subscribe(
      (res: OcorrenciaResponse) => {
        if (status == TipoStatus.DividaPagamentoPendenteCobrador) {
          this.DividaPagamentoPendenteCobrador();
        } else if (status == TipoStatus.DividaPagamentoRevisada) {
          this.DividaPagamentoRevisada();
        } else {
          this.toastrService.success("Ocorrência atualizada.", "Sucesso");
          this.spinner.hide();
          this.close(this.processo);
        }
      },
      (error) => {
        this.enableButtons = true;
        this.spinner.hide();

        if (error && error.errors) {
          this.toastrService.error(error.errors[0], "Atenção", {
            timeOut: 10000,
          });
        } else {
          this.toastrService.error(
            "Não foi possível atualizar a ocorrência!",
            "Atenção",
            { timeOut: 10000 }
          );
        }
      }
    );
  }

  getTipoPagamento(pagamento) {
    var retido: Boolean = pagamento.retido;
    var isento: Boolean = pagamento.isento;

    if (isento && !retido) {
      this.tipoPagamento = "DIRETO";
    } else if (!isento && retido) {
      this.tipoPagamento = "RETIDO";
    } else {
      this.tipoPagamento = "NORMAL";
    }
  }
}
