import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class DadosCargasService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = '/carga';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    buscarDadosComplementaresPessoa(): Observable<any> {
        var bancos = this.mockBancos();
        var tiposTelefones = this.mockTelefones();
        var tiposPessoas = this.getipoPessoas();

        var estados = this.mockEstados();

        var data = {
            data: {
                bancos: bancos,
                tiposTelefones: tiposTelefones,
                tiposPessoas: tiposPessoas,
                estados: estados
            }
        };

        return of(
            data
        ).pipe(
            map(this.extractData)
        )
    }

    buscarDadosSituacaoProcesso(): Observable<any> {
        var tiposProcessos = this.getSituacaoProcessoTipo();
        var situacaoRealProcessos = this.getSituacaoProcessoSituacaoReal();

        var data = {
            data: {
                tiposProcessos: tiposProcessos,
                situacaoRealProcessos: situacaoRealProcessos,
            }
        };

        return of(
            data
        ).pipe(
            map(this.extractData)
        )
    }



    mockBancos() {

        return [
            {
              "value": "ABN AMRO S.A",
              "title": "ABN AMRO S.A"
            },
            {
              "value": "ACESSO SOLUÇÕES DE PAGAMENTO S.A.",
              "title": "ACESSO SOLUÇÕES DE PAGAMENTO S.A."
            },
            {
              "value": "BANCO A. J. RENNER S.A",
              "title": "BANCO A. J. RENNER S.A"
            },
            {
              "value": "BANCO ABC ROMA S.A",
              "title": "BANCO ABC ROMA S.A"
            },
            {
              "value": "BANCO AGF BRASIL S. A.",
              "title": "BANCO AGF BRASIL S. A."
            },
            {
              "value": "BANCO AGRIMISA S.A.",
              "title": "BANCO AGRIMISA S.A."
            },
            {
              "value": "BANCO AGROINVEST S.A",
              "title": "BANCO AGROINVEST S.A"
            },
            {
              "value": "BANCO ALFA S/A",
              "title": "BANCO ALFA S/A"
            },
            {
              "value": "BANCO AMERICA DO SUL S.A",
              "title": "BANCO AMERICA DO SUL S.A"
            },
            {
              "value": "BANCO APLICAP S.A.",
              "title": "BANCO APLICAP S.A."
            },
            {
              "value": "BANCO ARAUCARIA S.A",
              "title": "BANCO ARAUCARIA S.A"
            },
            {
              "value": "BANCO ARBI S.A",
              "title": "BANCO ARBI S.A"
            },
            {
              "value": "BANCO ATLANTIS S.A",
              "title": "BANCO ATLANTIS S.A"
            },
            {
              "value": "BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A",
              "title": "BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A"
            },
            {
              "value": "BANCO B.M.C. S.A",
              "title": "BANCO B.M.C. S.A"
            },
            {
              "value": "BANCO B.M.G. S.A",
              "title": "BANCO B.M.G. S.A"
            },
            {
              "value": "BANCO B.N.L DO BRASIL S.A",
              "title": "BANCO B.N.L DO BRASIL S.A"
            },
            {
              "value": "BANCO BANCRED S.A",
              "title": "BANCO BANCRED S.A"
            },
            {
              "value": "BANCO BANDEIRANTES S.A",
              "title": "BANCO BANDEIRANTES S.A"
            },
            {
              "value": "BANCO BANQUE NATIONALE DE PARIS BRASIL S",
              "title": "BANCO BANQUE NATIONALE DE PARIS BRASIL S"
            },
            {
              "value": "BANCO BBM S.A",
              "title": "BANCO BBM S.A"
            },
            {
              "value": "BANCO BBM-COM.C.IMOB.CFI S.A.",
              "title": "BANCO BBM-COM.C.IMOB.CFI S.A."
            },
            {
              "value": "BANCO BOAVISTA S.A",
              "title": "BANCO BOAVISTA S.A"
            },
            {
              "value": "BANCO BOREAL S.A",
              "title": "BANCO BOREAL S.A"
            },
            {
              "value": "BANCO BOZANO SIMONSEN S.A",
              "title": "BANCO BOZANO SIMONSEN S.A"
            },
            {
              "value": "BANCO BRASCAN S.A",
              "title": "BANCO BRASCAN S.A"
            },
            {
              "value": "BANCO BRASILEIRO COMERCIAL",
              "title": "BANCO BRASILEIRO COMERCIAL"
            },
            {
              "value": "BANCO BRASILEIRO IRAQUIANO S.A.",
              "title": "BANCO BRASILEIRO IRAQUIANO S.A."
            },
            {
              "value": "BANCO C.C.F. BRASIL S.A",
              "title": "BANCO C.C.F. BRASIL S.A"
            },
            {
              "value": "BANCO C6 S.A.",
              "title": "BANCO C6 S.A."
            },
            {
              "value": "BANCO CACIQUE",
              "title": "BANCO CACIQUE"
            },
            {
              "value": "BANCO CAMBIAL S.A",
              "title": "BANCO CAMBIAL S.A"
            },
            {
              "value": "BANCO CEDULA S.A",
              "title": "BANCO CEDULA S.A"
            },
            {
              "value": "BANCO CENTRAL DO BRASIL",
              "title": "BANCO CENTRAL DO BRASIL"
            },
            {
              "value": "BANCO CIDADE S.A",
              "title": "BANCO CIDADE S.A"
            },
            {
              "value": "BANCO CINDAM S.A",
              "title": "BANCO CINDAM S.A"
            },
            {
              "value": "BANCO CLASSICO S.A",
              "title": "BANCO CLASSICO S.A"
            },
            {
              "value": "BANCO COMERCIAL BANCESA S.A.",
              "title": "BANCO COMERCIAL BANCESA S.A."
            },
            {
              "value": "BANCO COMERCIAL PARAGUAYO S.A",
              "title": "BANCO COMERCIAL PARAGUAYO S.A"
            },
            {
              "value": "BANCO COMERCIAL URUGUAI S.A.",
              "title": "BANCO COMERCIAL URUGUAI S.A."
            },
            {
              "value": "BANCO CREDIBANCO S.A",
              "title": "BANCO CREDIBANCO S.A"
            },
            {
              "value": "BANCO CREDIBEL S.A",
              "title": "BANCO CREDIBEL S.A"
            },
            {
              "value": "BANCO CREDIPLAN S.A",
              "title": "BANCO CREDIPLAN S.A"
            },
            {
              "value": "BANCO CREFISUL",
              "title": "BANCO CREFISUL"
            },
            {
              "value": "BANCO CRITERIUM S.A",
              "title": "BANCO CRITERIUM S.A"
            },
            {
              "value": "BANCO CRUZEIRO S.A",
              "title": "BANCO CRUZEIRO S.A"
            },
            {
              "value": "BANCO DA AMAZONIA S.A",
              "title": "BANCO DA AMAZONIA S.A"
            },
            {
              "value": "BANCO DAYCOVAL S.A",
              "title": "BANCO DAYCOVAL S.A"
            },
            {
              "value": "BANCO DE BOSTON S.A",
              "title": "BANCO DE BOSTON S.A"
            },
            {
              "value": "BANCO DE CREDITO DE SAO PAULO S.A",
              "title": "BANCO DE CREDITO DE SAO PAULO S.A"
            },
            {
              "value": "BANCO DE CREDITO METROPOLITANO S.A",
              "title": "BANCO DE CREDITO METROPOLITANO S.A"
            },
            {
              "value": "BANCO DE CREDITO NACIONAL S.A",
              "title": "BANCO DE CREDITO NACIONAL S.A"
            },
            {
              "value": "BANCO DE CREDITO REAL DE MINAS GERAIS S.",
              "title": "BANCO DE CREDITO REAL DE MINAS GERAIS S."
            },
            {
              "value": "BANCO DE CREDITO REAL DE MINAS GERAIS SA",
              "title": "BANCO DE CREDITO REAL DE MINAS GERAIS SA"
            },
            {
              "value": "BANCO DE LA NACION ARGENTINA S.A",
              "title": "BANCO DE LA NACION ARGENTINA S.A"
            },
            {
              "value": "BANCO DESTAK S.A",
              "title": "BANCO DESTAK S.A"
            },
            {
              "value": "BANCO DIBENS S.A",
              "title": "BANCO DIBENS S.A"
            },
            {
              "value": "BANCO DIGIO S.A.",
              "title": "BANCO DIGIO S.A."
            },
            {
              "value": "BANCO DIMENSAO S.A",
              "title": "BANCO DIMENSAO S.A"
            },
            {
              "value": "BANCO DO BRASIL",
              "title": "BANCO DO BRASIL"
            },
            {
              "value": "BANCO DO ESADO DO AMAZONAS S.A",
              "title": "BANCO DO ESADO DO AMAZONAS S.A"
            },
            {
              "value": "BANCO DO ESTADO DA BAHIA S.A",
              "title": "BANCO DO ESTADO DA BAHIA S.A"
            },
            {
              "value": "BANCO DO ESTADO DA PARAIBA S.A",
              "title": "BANCO DO ESTADO DA PARAIBA S.A"
            },
            {
              "value": "BANCO DO ESTADO DE ALAGOAS S.A",
              "title": "BANCO DO ESTADO DE ALAGOAS S.A"
            },
            {
              "value": "BANCO DO ESTADO DE GOIAS S.A",
              "title": "BANCO DO ESTADO DE GOIAS S.A"
            },
            {
              "value": "BANCO DO ESTADO DE MINAS GERAIS S.A",
              "title": "BANCO DO ESTADO DE MINAS GERAIS S.A"
            },
            {
              "value": "BANCO DO ESTADO DE PERNAMBUCO",
              "title": "BANCO DO ESTADO DE PERNAMBUCO"
            },
            {
              "value": "BANCO DO ESTADO DE RONDONIA S.A",
              "title": "BANCO DO ESTADO DE RONDONIA S.A"
            },
            {
              "value": "BANCO DO ESTADO DE RORAIMA S.A",
              "title": "BANCO DO ESTADO DE RORAIMA S.A"
            },
            {
              "value": "BANCO DO ESTADO DE SANTA CATARINA S.A",
              "title": "BANCO DO ESTADO DE SANTA CATARINA S.A"
            },
            {
              "value": "BANCO DO ESTADO DE SERGIPE S.A",
              "title": "BANCO DO ESTADO DE SERGIPE S.A"
            },
            {
              "value": "BANCO DO ESTADO DO ACRE S.A",
              "title": "BANCO DO ESTADO DO ACRE S.A"
            },
            {
              "value": "BANCO DO ESTADO DO AMAPA S.A",
              "title": "BANCO DO ESTADO DO AMAPA S.A"
            },
            {
              "value": "BANCO DO ESTADO DO CEARA S.A",
              "title": "BANCO DO ESTADO DO CEARA S.A"
            },
            {
              "value": "BANCO DO ESTADO DO ESPIRITO SANTO S.A",
              "title": "BANCO DO ESTADO DO ESPIRITO SANTO S.A"
            },
            {
              "value": "BANCO DO ESTADO DO MARANHAO S.A",
              "title": "BANCO DO ESTADO DO MARANHAO S.A"
            },
            {
              "value": "BANCO DO ESTADO DO MATO GROSSO S.A.",
              "title": "BANCO DO ESTADO DO MATO GROSSO S.A."
            },
            {
              "value": "BANCO DO ESTADO DO PARA S.A",
              "title": "BANCO DO ESTADO DO PARA S.A"
            },
            {
              "value": "BANCO DO ESTADO DO PARANA S.A",
              "title": "BANCO DO ESTADO DO PARANA S.A"
            },
            {
              "value": "BANCO DO ESTADO DO PIAUI S.A",
              "title": "BANCO DO ESTADO DO PIAUI S.A"
            },
            {
              "value": "BANCO DO ESTADO DO RIO DE JANEIRO S.A",
              "title": "BANCO DO ESTADO DO RIO DE JANEIRO S.A"
            },
            {
              "value": "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
              "title": "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A"
            },
            {
              "value": "BANCO DO NORDESTE DO BRASIL S.A",
              "title": "BANCO DO NORDESTE DO BRASIL S.A"
            },
            {
              "value": "BANCO DO PROGRESSO S.A",
              "title": "BANCO DO PROGRESSO S.A"
            },
            {
              "value": "BANCO DRACMA S.A",
              "title": "BANCO DRACMA S.A"
            },
            {
              "value": "BANCO EMBLEMA S.A",
              "title": "BANCO EMBLEMA S.A"
            },
            {
              "value": "BANCO EMPRESARIAL S.A",
              "title": "BANCO EMPRESARIAL S.A"
            },
            {
              "value": "BANCO EUROINVEST S.A",
              "title": "BANCO EUROINVEST S.A"
            },
            {
              "value": "BANCO EXCEL ECONOMICO S/A",
              "title": "BANCO EXCEL ECONOMICO S/A"
            },
            {
              "value": "BANCO EXTERIOR DE ESPANA S.A",
              "title": "BANCO EXTERIOR DE ESPANA S.A"
            },
            {
              "value": "BANCO FATOR S.A",
              "title": "BANCO FATOR S.A"
            },
            {
              "value": "BANCO FENICIA S.A",
              "title": "BANCO FENICIA S.A"
            },
            {
              "value": "BANCO FIBRA S.A",
              "title": "BANCO FIBRA S.A"
            },
            {
              "value": "BANCO FICSA S.A",
              "title": "BANCO FICSA S.A"
            },
            {
              "value": "BANCO FINABANCO S.A",
              "title": "BANCO FINABANCO S.A"
            },
            {
              "value": "BANCO FINANCIAL PORTUGUES",
              "title": "BANCO FINANCIAL PORTUGUES"
            },
            {
              "value": "BANCO FINANCIAL PORTUGUES S.A",
              "title": "BANCO FINANCIAL PORTUGUES S.A"
            },
            {
              "value": "BANCO FININVEST S.A",
              "title": "BANCO FININVEST S.A"
            },
            {
              "value": "BANCO FITAL S.A",
              "title": "BANCO FITAL S.A"
            },
            {
              "value": "BANCO FONTE S.A",
              "title": "BANCO FONTE S.A"
            },
            {
              "value": "BANCO FORTALEZA S.A",
              "title": "BANCO FORTALEZA S.A"
            },
            {
              "value": "BANCO FRANCES E BRASILEIRO S.A",
              "title": "BANCO FRANCES E BRASILEIRO S.A"
            },
            {
              "value": "BANCO FRANCES E BRASILEIRO SA",
              "title": "BANCO FRANCES E BRASILEIRO SA"
            },
            {
              "value": "BANCO FRICRISA AXELRUD S.A",
              "title": "BANCO FRICRISA AXELRUD S.A"
            },
            {
              "value": "BANCO GARANTIA S.A",
              "title": "BANCO GARANTIA S.A"
            },
            {
              "value": "BANCO GENERAL MOTORS S.A",
              "title": "BANCO GENERAL MOTORS S.A"
            },
            {
              "value": "BANCO GERAL DO COMERCIO S.A",
              "title": "BANCO GERAL DO COMERCIO S.A"
            },
            {
              "value": "BANCO GERDAU S.A",
              "title": "BANCO GERDAU S.A"
            },
            {
              "value": "BANCO GNPP S.A.",
              "title": "BANCO GNPP S.A."
            },
            {
              "value": "BANCO GRAPHUS S.A",
              "title": "BANCO GRAPHUS S.A"
            },
            {
              "value": "BANCO GUANABARA S.A",
              "title": "BANCO GUANABARA S.A"
            },
            {
              "value": "BANCO GULVINVEST S.A",
              "title": "BANCO GULVINVEST S.A"
            },
            {
              "value": "BANCO HNF S.A.",
              "title": "BANCO HNF S.A."
            },
            {
              "value": "BANCO HSBC",
              "title": "BANCO HSBC"
            },
            {
              "value": "BANCO ICATU S.A",
              "title": "BANCO ICATU S.A"
            },
            {
              "value": "BANCO INDUSCRED S.A",
              "title": "BANCO INDUSCRED S.A"
            },
            {
              "value": "BANCO INDUSTRIAL DO BRASIL S.A",
              "title": "BANCO INDUSTRIAL DO BRASIL S.A"
            },
            {
              "value": "BANCO INDUSTRIAL E COMERCIAL",
              "title": "BANCO INDUSTRIAL E COMERCIAL"
            },
            {
              "value": "BANCO INDUSVAL S.A",
              "title": "BANCO INDUSVAL S.A"
            },
            {
              "value": "BANCO INTER",
              "title": "BANCO INTER"
            },
            {
              "value": "BANCO INTER-ATLANTICO S.A",
              "title": "BANCO INTER-ATLANTICO S.A"
            },
            {
              "value": "BANCO INTERCAP S.A",
              "title": "BANCO INTERCAP S.A"
            },
            {
              "value": "BANCO INTERIOR DE SAO PAULO S.A",
              "title": "BANCO INTERIOR DE SAO PAULO S.A"
            },
            {
              "value": "BANCO INTERPACIFICO S.A",
              "title": "BANCO INTERPACIFICO S.A"
            },
            {
              "value": "BANCO INTERPART S.A",
              "title": "BANCO INTERPART S.A"
            },
            {
              "value": "BANCO INTERUNION S.A",
              "title": "BANCO INTERUNION S.A"
            },
            {
              "value": "BANCO INVESTCORP S.A.",
              "title": "BANCO INVESTCORP S.A."
            },
            {
              "value": "BANCO INVESTCRED S.A",
              "title": "BANCO INVESTCRED S.A"
            },
            {
              "value": "BANCO INVESTOR S.A.",
              "title": "BANCO INVESTOR S.A."
            },
            {
              "value": "BANCO IOCHPE S.A",
              "title": "BANCO IOCHPE S.A"
            },
            {
              "value": "BANCO ITABANCO S.A.",
              "title": "BANCO ITABANCO S.A."
            },
            {
              "value": "BANCO ITAMARATI S.A",
              "title": "BANCO ITAMARATI S.A"
            },
            {
              "value": "BANCO J. P. MORGAN S.A",
              "title": "BANCO J. P. MORGAN S.A"
            },
            {
              "value": "BANCO KEB DO BRASIL S.A.",
              "title": "BANCO KEB DO BRASIL S.A."
            },
            {
              "value": "BANCO LA PROVINCIA DE BUENOS AIRES",
              "title": "BANCO LA PROVINCIA DE BUENOS AIRES"
            },
            {
              "value": "BANCO LA REP. ORIENTAL DEL URUGUAY",
              "title": "BANCO LA REP. ORIENTAL DEL URUGUAY"
            },
            {
              "value": "BANCO LAVRA S.A.",
              "title": "BANCO LAVRA S.A."
            },
            {
              "value": "BANCO LIBERAL S.A",
              "title": "BANCO LIBERAL S.A"
            },
            {
              "value": "BANCO LUSO BRASILEIRO S.A",
              "title": "BANCO LUSO BRASILEIRO S.A"
            },
            {
              "value": "BANCO MAPPIN S.A",
              "title": "BANCO MAPPIN S.A"
            },
            {
              "value": "BANCO MARKA S.A",
              "title": "BANCO MARKA S.A"
            },
            {
              "value": "BANCO MARTINELLI S.A",
              "title": "BANCO MARTINELLI S.A"
            },
            {
              "value": "BANCO MATRIX S.A",
              "title": "BANCO MATRIX S.A"
            },
            {
              "value": "BANCO MAXINVEST S.A",
              "title": "BANCO MAXINVEST S.A"
            },
            {
              "value": "BANCO MERCANTIL DE DESCONTOS S/A",
              "title": "BANCO MERCANTIL DE DESCONTOS S/A"
            },
            {
              "value": "BANCO MERCANTIL DE SAO PAULO S.A",
              "title": "BANCO MERCANTIL DE SAO PAULO S.A"
            },
            {
              "value": "BANCO MERCANTIL DO BRASIL S.A",
              "title": "BANCO MERCANTIL DO BRASIL S.A"
            },
            {
              "value": "BANCO MERIDIONAL DO BRASIL",
              "title": "BANCO MERIDIONAL DO BRASIL"
            },
            {
              "value": "BANCO MERRILL LYNCH S.A",
              "title": "BANCO MERRILL LYNCH S.A"
            },
            {
              "value": "BANCO MITSUBISHI BRASILEIRO S.A",
              "title": "BANCO MITSUBISHI BRASILEIRO S.A"
            },
            {
              "value": "BANCO MULTIPLIC S.A",
              "title": "BANCO MULTIPLIC S.A"
            },
            {
              "value": "BANCO MÁXIMA S.A",
              "title": "BANCO MÁXIMA S.A"
            },
            {
              "value": "BANCO NAC DESENV. ECO. SOCIAL S.A",
              "title": "BANCO NAC DESENV. ECO. SOCIAL S.A"
            },
            {
              "value": "BANCO NACIONAL DA BAHIA S.A",
              "title": "BANCO NACIONAL DA BAHIA S.A"
            },
            {
              "value": "BANCO NACIONAL DO NORTE S.A",
              "title": "BANCO NACIONAL DO NORTE S.A"
            },
            {
              "value": "BANCO NACIONAL S.A",
              "title": "BANCO NACIONAL S.A"
            },
            {
              "value": "BANCO NACOES S.A.",
              "title": "BANCO NACOES S.A."
            },
            {
              "value": "BANCO NEON",
              "title": "BANCO NEON"
            },
            {
              "value": "BANCO NORCHEM S.A",
              "title": "BANCO NORCHEM S.A"
            },
            {
              "value": "BANCO NOROESTE S.A",
              "title": "BANCO NOROESTE S.A"
            },
            {
              "value": "BANCO OMEGA S.A",
              "title": "BANCO OMEGA S.A"
            },
            {
              "value": "BANCO OPEN S.A",
              "title": "BANCO OPEN S.A"
            },
            {
              "value": "BANCO OPERADOR S.A",
              "title": "BANCO OPERADOR S.A"
            },
            {
              "value": "BANCO ORIGINAL",
              "title": "BANCO ORIGINAL"
            },
            {
              "value": "BANCO PACTUAL S.A",
              "title": "BANCO PACTUAL S.A"
            },
            {
              "value": "BANCO PANAMERICANO S.A",
              "title": "BANCO PANAMERICANO S.A"
            },
            {
              "value": "BANCO PARANA BANCO S.A",
              "title": "BANCO PARANA BANCO S.A"
            },
            {
              "value": "BANCO PATENTE S.A",
              "title": "BANCO PATENTE S.A"
            },
            {
              "value": "BANCO PAULISTA S.A",
              "title": "BANCO PAULISTA S.A"
            },
            {
              "value": "BANCO PEBB S.A",
              "title": "BANCO PEBB S.A"
            },
            {
              "value": "BANCO PECUNIA S.A",
              "title": "BANCO PECUNIA S.A"
            },
            {
              "value": "BANCO PERFORMANCE S.A",
              "title": "BANCO PERFORMANCE S.A"
            },
            {
              "value": "BANCO PLANIBANC S.A",
              "title": "BANCO PLANIBANC S.A"
            },
            {
              "value": "BANCO PONTUAL S.A",
              "title": "BANCO PONTUAL S.A"
            },
            {
              "value": "BANCO PORTO REAL S.A",
              "title": "BANCO PORTO REAL S.A"
            },
            {
              "value": "BANCO PORTO SEGURO S.A",
              "title": "BANCO PORTO SEGURO S.A"
            },
            {
              "value": "BANCO PORTUGUES DO ATLANTICO-BRASIL S.A",
              "title": "BANCO PORTUGUES DO ATLANTICO-BRASIL S.A"
            },
            {
              "value": "BANCO PREMIER S.A.",
              "title": "BANCO PREMIER S.A."
            },
            {
              "value": "BANCO PRIMUS S.A",
              "title": "BANCO PRIMUS S.A"
            },
            {
              "value": "BANCO PROSPER S.A",
              "title": "BANCO PROSPER S.A"
            },
            {
              "value": "BANCO REAL S.A",
              "title": "BANCO REAL S.A"
            },
            {
              "value": "BANCO REDIMENTO S.A",
              "title": "BANCO REDIMENTO S.A"
            },
            {
              "value": "BANCO REGIONAL MALCON S.A",
              "title": "BANCO REGIONAL MALCON S.A"
            },
            {
              "value": "BANCO REPUBLIC NATIONAL OF NEW YORK (BRA",
              "title": "BANCO REPUBLIC NATIONAL OF NEW YORK (BRA"
            },
            {
              "value": "BANCO RURAL S.A",
              "title": "BANCO RURAL S.A"
            },
            {
              "value": "BANCO S.R.L S.A",
              "title": "BANCO S.R.L S.A"
            },
            {
              "value": "BANCO SAFRA",
              "title": "BANCO SAFRA"
            },
            {
              "value": "BANCO SANTANDER DE NEGOCIOS S.A",
              "title": "BANCO SANTANDER DE NEGOCIOS S.A"
            },
            {
              "value": "BANCO SANTANDER S.A.",
              "title": "BANCO SANTANDER S.A."
            },
            {
              "value": "BANCO SANTOS NEVES S.A",
              "title": "BANCO SANTOS NEVES S.A"
            },
            {
              "value": "BANCO SANTOS S.A",
              "title": "BANCO SANTOS S.A"
            },
            {
              "value": "BANCO SAO JORGE S.A.",
              "title": "BANCO SAO JORGE S.A."
            },
            {
              "value": "BANCO SCHAHIN CURY S.A",
              "title": "BANCO SCHAHIN CURY S.A"
            },
            {
              "value": "BANCO SEGMENTO S.A",
              "title": "BANCO SEGMENTO S.A"
            },
            {
              "value": "BANCO SISTEMA S.A",
              "title": "BANCO SISTEMA S.A"
            },
            {
              "value": "BANCO SOFISA S.A",
              "title": "BANCO SOFISA S.A"
            },
            {
              "value": "BANCO SOGERAL S.A",
              "title": "BANCO SOGERAL S.A"
            },
            {
              "value": "BANCO SUDAMERIS BRASIL S.A",
              "title": "BANCO SUDAMERIS BRASIL S.A"
            },
            {
              "value": "BANCO SUL AMERICA S.A",
              "title": "BANCO SUL AMERICA S.A"
            },
            {
              "value": "BANCO SUMITOMO BRASILEIRO S.A",
              "title": "BANCO SUMITOMO BRASILEIRO S.A"
            },
            {
              "value": "BANCO TECNICORP S.A",
              "title": "BANCO TECNICORP S.A"
            },
            {
              "value": "BANCO TENDENCIA S.A",
              "title": "BANCO TENDENCIA S.A"
            },
            {
              "value": "BANCO TOKIO S.A",
              "title": "BANCO TOKIO S.A"
            },
            {
              "value": "BANCO TRIANGULO S.A",
              "title": "BANCO TRIANGULO S.A"
            },
            {
              "value": "BANCO UNION S.A.C.A",
              "title": "BANCO UNION S.A.C.A"
            },
            {
              "value": "BANCO UNITED S.A",
              "title": "BANCO UNITED S.A"
            },
            {
              "value": "BANCO UNIVERSAL S.A",
              "title": "BANCO UNIVERSAL S.A"
            },
            {
              "value": "BANCO V.R. S.A",
              "title": "BANCO V.R. S.A"
            },
            {
              "value": "BANCO VARIG S.A",
              "title": "BANCO VARIG S.A"
            },
            {
              "value": "BANCO VEGA S.A",
              "title": "BANCO VEGA S.A"
            },
            {
              "value": "BANCO VETOR S.A.",
              "title": "BANCO VETOR S.A."
            },
            {
              "value": "BANCO VOTORANTIM S.A.",
              "title": "BANCO VOTORANTIM S.A."
            },
            {
              "value": "BANCOOB",
              "title": "BANCOOB"
            },
            {
              "value": "BANCORP BANCO COML. E. DE INVESTMENTO",
              "title": "BANCORP BANCO COML. E. DE INVESTMENTO"
            },
            {
              "value": "BANESTO BANCO URUGAUAY S.A",
              "title": "BANESTO BANCO URUGAUAY S.A"
            },
            {
              "value": "BBA - CREDITANSTALT S.A",
              "title": "BBA - CREDITANSTALT S.A"
            },
            {
              "value": "BCN BARCLAYS",
              "title": "BCN BARCLAYS"
            },
            {
              "value": "BCR - BANCO DE CREDITO REAL S.A",
              "title": "BCR - BANCO DE CREDITO REAL S.A"
            },
            {
              "value": "BEAL - BANCO EUROPEU PARA AMERICA LATINA",
              "title": "BEAL - BANCO EUROPEU PARA AMERICA LATINA"
            },
            {
              "value": "BFC BANCO S.A.",
              "title": "BFC BANCO S.A."
            },
            {
              "value": "BGN",
              "title": "BGN"
            },
            {
              "value": "BIG S.A. - BANCO IRMAOS GUIMARAES",
              "title": "BIG S.A. - BANCO IRMAOS GUIMARAES"
            },
            {
              "value": "BRADESCO",
              "title": "BRADESCO"
            },
            {
              "value": "BRB - BANCO DE BRASÍLIA",
              "title": "BRB - BANCO DE BRASÍLIA"
            },
            {
              "value": "BRMSANTIL SA",
              "title": "BRMSANTIL SA"
            },
            {
              "value": "BRP",
              "title": "BRP"
            },
            {
              "value": "BS2",
              "title": "BS2"
            },
            {
              "value": "CAIXA ECONOMICA DO ESTADO DE SAO PAULO",
              "title": "CAIXA ECONOMICA DO ESTADO DE SAO PAULO"
            },
            {
              "value": "CAIXA ECONOMICA DO ESTADO DO R.G.SUL",
              "title": "CAIXA ECONOMICA DO ESTADO DO R.G.SUL"
            },
            {
              "value": "CAIXA ECONÔMICA FEDERAL",
              "title": "CAIXA ECONÔMICA FEDERAL"
            },
            {
              "value": "CENTRO HISPANO BANCO",
              "title": "CENTRO HISPANO BANCO"
            },
            {
              "value": "CHASE MANHATTAN BANK S.A",
              "title": "CHASE MANHATTAN BANK S.A"
            },
            {
              "value": "CITIBANK",
              "title": "CITIBANK"
            },
            {
              "value": "CITIBANK N.A",
              "title": "CITIBANK N.A"
            },
            {
              "value": "CONTINENTAL BANCO S.A",
              "title": "CONTINENTAL BANCO S.A"
            },
            {
              "value": "COOPERATIVA CENTRAL DE CREDITO - AILOS",
              "title": "COOPERATIVA CENTRAL DE CREDITO - AILOS"
            },
            {
              "value": "DEUTSCH SUDAMERIKANICHE BANK AG",
              "title": "DEUTSCH SUDAMERIKANICHE BANK AG"
            },
            {
              "value": "DEUTSCHE BANK S.A - BANCO ALEMAO",
              "title": "DEUTSCHE BANK S.A - BANCO ALEMAO"
            },
            {
              "value": "DRESDNER BANK LATEINAMERIKA-BRASIL S/A",
              "title": "DRESDNER BANK LATEINAMERIKA-BRASIL S/A"
            },
            {
              "value": "EQUATORIAL",
              "title": "EQUATORIAL"
            },
            {
              "value": "INTERNATIONALE NEDERLANDEN BANK N.V.",
              "title": "INTERNATIONALE NEDERLANDEN BANK N.V."
            },
            {
              "value": "ITAÚ",
              "title": "ITAÚ"
            },
            {
              "value": "LLOYDS BANK PLC",
              "title": "LLOYDS BANK PLC"
            },
            {
              "value": "MARADA",
              "title": "MARADA"
            },
            {
              "value": "MERCADOPAGO.COM REPRESENTAÇÕES LTDA.",
              "title": "MERCADOPAGO.COM REPRESENTAÇÕES LTDA."
            },
            {
              "value": "MILBANCO S.A.",
              "title": "MILBANCO S.A."
            },
            {
              "value": "MODAL S.A.",
              "title": "MODAL S.A."
            },
            {
              "value": "MULTI BANCO S.A",
              "title": "MULTI BANCO S.A"
            },
            {
              "value": "NU PAGAMENTOS S.A",
              "title": "NU PAGAMENTOS S.A"
            },
            {
              "value": "PAGSEGURO INTERNET S.A.",
              "title": "PAGSEGURO INTERNET S.A."
            },
            {
              "value": "PICPAY SERVIÇOS S.A.",
              "title": "PICPAY SERVIÇOS S.A."
            },
            {
              "value": "PLURAL S.A. BANCO MULTIPLO",
              "title": "PLURAL S.A. BANCO MULTIPLO"
            },
            {
              "value": "PONTUAL",
              "title": "PONTUAL"
            },
            {
              "value": "RABOBANK DO BRASIL",
              "title": "RABOBANK DO BRASIL"
            },
            {
              "value": "SICREDI",
              "title": "SICREDI"
            },
            {
              "value": "STONE PAGAMENTOS S.A.",
              "title": "STONE PAGAMENTOS S.A."
            },
            {
              "value": "THE FIRST NATIONAL BANK OF BOSTON",
              "title": "THE FIRST NATIONAL BANK OF BOSTON"
            },
            {
              "value": "THECA",
              "title": "THECA"
            },
            {
              "value": "UNIBANCO - UNIAO DOS BANCOS BRASILEIROS",
              "title": "UNIBANCO - UNIAO DOS BANCOS BRASILEIROS"
            },
            {
              "value": "XP INVESTIMENTOS",
              "title": "XP INVESTIMENTOS"
            },
            {
              "value": "ÓRAMA DTVM S.A.",
              "title": "ÓRAMA DTVM S.A."
            }
          ];
    }

    mockCamposAdicionais() {

      return [
          { value: "OAB", title: "OAB" },
          { value: "Novo email", title: "Novo email" },
          { value: "Nome fantasia", title: "Nome fantasia" }
      ];
  }


    mockTelefones() {
       return [
            { value: "Residencial", title: "Residencial" },
            { value: "Comercial", title: "Comercial" },
            { value: "Celular", title: "Celular" }
        ];
    }

    mockEstados(){

        return [
            { value: 'AC', title: 'AC' },
            { value: 'AL', title: 'AL' },
            { value: 'AP', title: 'AP' },
            { value: 'AM', title: 'AM' },
            { value: 'BA', title: 'BA' },
            { value: 'CE', title: 'CE' },
            { value: 'DF', title: 'DF' },
            { value: 'ES', title: 'ES' },
            { value: 'GO', title: 'GO' },
            { value: 'MA', title: 'MA' },
            { value: 'MT', title: 'MT' },
            { value: 'MS', title: 'MS' },
            { value: 'MG', title: 'MG' },
            { value: 'PA', title: 'PA' },
            { value: 'PB', title: 'PB' },
            { value: 'PR', title: 'PR' },
            { value: 'PE', title: 'PE' },
            { value: 'PI', title: 'PI' },
            { value: 'RJ', title: 'RJ' },
            { value: 'RN', title: 'RN' },
            { value: 'RS', title: 'RS' },
            { value: 'RO', title: 'RO' },
            { value: 'RR', title: 'RR' },
            { value: 'SC', title: 'SC' },
            { value: 'SP', title: 'SP' },
            { value: 'SE', title: 'SE' },
            { value: 'TO', title: 'TO' },
        ];

    }

    getipoPessoas() {

        return [
            'Advogado',
            'Credor',
            'Devedor',
            'Cobrador',
            'Avalista',
            'Administrador',
            'Colaborador'
        ];

    }


    getSituacaoProcessoTipo(){
        return[
            { value: 'Amigavel', title: 'Amigavel' },
            { value: 'Judicial', title: 'Judicial' },
            { value: 'Ambos', title: 'Ambos' }
        ]
    }
    getSituacaoProcessoSituacaoReal(){
        return[
            { value: 'Novo', title: 'Novo' },
            { value: 'Andamento', title: 'Andamento' },
            { value: 'Baixado', title: 'Baixado' },
            { value: 'Finalizado', title: 'Finalizado' },
            { value: 'Cancelado', title: 'Cancelado' },
            { value: 'Devolvido', title: 'Devolvido' }
        ]
    }



}
