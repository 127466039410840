import { Situacao } from 'src/app/models/cadastros/situacao/situacao.model';
import { Guid } from 'guid-typescript';
import { Tag } from '../../cadastros/tag/tag';
import { Ocorrencia } from '../ocorrencia/ocorrencia';

export class ProcessoOperacao {
    id: Guid;
    idExterno: String;
    codigoExternoDevedor: String;
    credor: String;
    credorId: Guid;
    credorDocumento:String;
    cobradorId: Guid;
    cobrador: String;
    cobradorDocumento:String;
    devedor: String;
    devedorId:Guid;
    devedorDocumento:String;
    valor: number;
    valorEmAberto: number;
    anoDivida: Number;
    quantidadeDivida: Number;
    regiao: String;
    quantidadeAnexo: Number;
    situacaoProcesso: any;
    entrada: Date;
    ultimaOcorrencia:Ocorrencia;
    dataRemessa: Date;
    tags: Tag[];
    isFinalizado: Boolean; //campo retornado da API verificando se o processo está finalizdo, de acordo com a situação

    percentualJuros: number;
    percentualMulta: number;
    percentualTaxaAdministrativa: number;
    percentualAtualizacaoCusta: number;

    Ocorrencias: any; // temporário, apenas para parar de dar erro quando foi colocado um objeto para model aqui. Antes estava mock
    Telefones: any;  // temporário, apenas para parar de dar erro quando foi colocado um objeto para model aqui. Antes estava mock
    Emails: any; // temporário, apenas para parar de dar erro quando foi colocado um objeto para model aqui. Antes estava mock
    Valores: any; // temporário, apenas para parar de dar erro quando foi colocado um objeto para model aqui. Antes estava mock
    Parcelas: any; // temporário, apenas para parar de dar erro quando foi colocado um objeto para model aqui. Antes estava mock
    ParcelaPaga: any; // temporário, apenas para parar de dar erro quando foi colocado um objeto para model aqui. Antes estava mock
    Dividas: any; // temporário, apenas para parar de dar erro quando foi colocado um objeto para model aqui. Antes estava mock


    selecionado: Boolean; // Campo lógico apenas para tratamento de tela

    constructor(id?, idExterno?, codigoExternoDevedor?, credor?, cobrador?, devedor?, valor?: number, valorEmAberto?: number, anoDivida?, quantidadeDivida?,
       regiao?, quantidadeAnexo?, situacaoProcesso?:any, entrada?: Date, ultimaOcorrencia?:Ocorrencia, tags?,devedorId?,
       devedorDocumento?:String,cobradorDocumento?:String,credorDocumento?:String) {
        this.id = id;
        this.idExterno = idExterno;
        this.codigoExternoDevedor = codigoExternoDevedor;
        this.credor = credor;
        this.cobrador = cobrador;
        this.devedor = devedor;
        this.valor = valor;
        this.valorEmAberto = valorEmAberto;
        this.anoDivida = anoDivida;
        this.quantidadeDivida = quantidadeDivida;
        this.regiao = regiao;
        this.quantidadeAnexo = quantidadeAnexo;
        this.situacaoProcesso = situacaoProcesso;
        this.entrada = entrada;
        this.ultimaOcorrencia = ultimaOcorrencia;
        this.tags = tags;
        this.devedorId = devedorId;
        this.devedorDocumento = devedorDocumento;
        this.cobradorDocumento = cobradorDocumento;
        this.credorDocumento = credorDocumento;
    }
}
