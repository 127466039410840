<div *ngIf="!loadPublicacao">
    <h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
        <span><span class="text-muted font-weight-light">Operacionais / Publicações /</span> Publicação</span>
    </h4>
    <div class="d-flex justify-content-end">

        <div ngbDropdown class="d-inline-block" placement="bottom-right"
            *ngIf="publicacao.tipoSituacaoPublicacao != 3 && !publicacao.concluido">
            <button type="button" class="btn btn-primary" id="dropdownCadastrar" ngbDropdownToggle>
                {{verificarTipoPublicacao(publicacao) ? "Tratamentos":"Adicionar Atividade"}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownCadastrar" *ngIf="publicacao.processoJudicialId != null">
                <a class="cursor" [disabled]="botoes.edit && botoes.adicionarPrazo" ngbDropdownItem
                    (click)="adicionarPrazo()">Adicionar Prazo</a>
                <a class="cursor" [disabled]="botoes.edit && botoes.adicionarAudiencia" ngbDropdownItem
                    (click)="adicionarAudiencia()">Adicionar Audiência/Julgamento</a>
                <a class="cursor" [disabled]="botoes.edit && botoes.adicionarTarefa" ngbDropdownItem
                    (click)="adicionarTarefa()">Adicionar Tarefa</a>
                <a class="cursor" [disabled]="botoes.edit && botoes.adicionarEvento" ngbDropdownItem
                    (click)="adicionarEvento()">Adicionar Evento</a>
                <a class="cursor" [disabled]="botoes.edit && botoes.adicionarHistoricoManual" ngbDropdownItem
                    (click)="adicionarHistoricoManual()">Adicionar Histórico Manual</a>
            </div>
        </div>
        &nbsp;
        <button type="button" class="btn btn-descartar"
            *ngIf="publicacao.processoJudicialId == null && publicacao.tipoSituacaoPublicacao == 1"
            (click)="descartarPublicacao()">
            Descartar
        </button>
        &nbsp;
        <button type="button" class="btn btn-concluir"
            *ngIf="publicacao.tipoSituacaoPublicacao != 3 && publicacao.processoJudicialId != null && !publicacao.concluido"
            (click)="concluirPublicacao()">
            Concluir
        </button>
    </div>
    <div>
        <span class="situacao badge badge-primary" *ngIf="publicacao.concluido">Concluído</span>
        <span class="situacao badge"
            ngClass="{{publicacao.tipoSituacaoPublicacaoCor}}">{{publicacao.tipoSituacaoPublicacaoNome}}</span>
    </div>
    <div class="row corpo">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <div class="card-publicacao">
                        <p class="publicacao-diario" [ngClass]="getClassFromPub(publicacao)">
                            {{publicacao.diario}}
                            <span *ngIf="publicacao.audienciaReferenciaId != null || 
                            publicacao.tarefaReferenciaId != null || 
                            publicacao.prazoReferenciaId != null"
                                class="fas fa-external-link-square-alt color-info p-0 ml-2 cursor-pointer"
                                aria-hidden="true" (click)="openModalComments(publicacao.listaAtividades)"
                                ngbTooltip="Clique para acessar detalhes da atividade"></span>
                        </p>
                        <div>
                            <span class="titulo">Divulgado em:</span> <b>{{publicacao.dataDivulgacao |
                                date:'dd/MM/yyyy'}}</b> - <span class="titulo">Publicado em:</span>
                            <b>{{publicacao.dataPublicacao | date:'dd/MM/yyyy'}}</b>
                        </div>
                        <div>
                            <span class="titulo">Processo:</span> <b><copiar-component
                                    texto="{{ publicacao.numeroProcesso }}"></copiar-component></b>
                        </div>
                        <div>
                            <span class="titulo">Termo encontrado:</span> <b><copiar-component
                                    texto="{{ publicacao.grupoPesquisado }}"></copiar-component></b>
                        </div>
                        <div>
                            <span class="titulo">Diário:</span> <b><copiar-component
                                    texto="{{ publicacao.diario }}"></copiar-component></b>
                        </div>
                        <div>
                            <span class="titulo">Origem:</span> <b>{{ publicacao.origem }} <span *ngIf="publicacao.audienciaReferenciaId != null || 
                                publicacao.tarefaReferenciaId != null || 
                                publicacao.prazoReferenciaId != null"
                                    class="fas fa-external-link-square-alt color-info p-0 ml-2 cursor-pointer"
                                    aria-hidden="true" (click)="editarOcorrencia(publicacao)"
                                    ngbTooltip="Clique para inserir novas atividades"></span></b>
                        </div>
                        <div *ngIf="publicacao.comentarios">
                            <div class="btn btn-primary" *ngFor="let arquivo of publicacao.comentarios"
                                [ngbTooltip]="arquivo.nomeArquivo" (click)="handleDownloadArquivo(arquivo)">
                                Download {{arquivo.nomeArquivo | uppercase}}<i
                                    class="btn btn-xs icon-btn btn-primary"><span class="fas fa-download"></span></i>
                            </div>
                        </div>
                        <hr />
                        <span [innerHtml]="publicacao.descricaoFormatada"></span>
                    </div>
                </div>
                <div class="col-6" *ngIf="botoes.edit">
                    <div class="card prazo" *ngIf="botoes.adicionarPrazo && publicacao.processoJudicialId != null">
                        <prazo-cadastro [prazo]="prazo" [index]="1" [publicacao]="publicacao"></prazo-cadastro>
                        <br />
                        <div class="d-flex justify-content-end col-12">
                            <button type="button" (click)="desabilitarBotoes()" class="btn btn-default">
                                Cancelar
                            </button>
                            &nbsp;
                            <button type="button" (click)="gravarPrazo()" class="btn btn-primary">
                                Salvar
                            </button>
                        </div>
                    </div>
                    <div class="card audiencia"
                        *ngIf="botoes.adicionarAudiencia && publicacao.processoJudicialId != null">
                        <audiencia-cadastro [audiencia]="audiencia" [index]="1"></audiencia-cadastro>
                        <br />
                        <div class="d-flex justify-content-end col-12">
                            <button type="button" (click)="desabilitarBotoes()" class="btn btn-default">
                                Cancelar
                            </button>
                            &nbsp;
                            <button type="button" (click)="gravarAudiencia()" class="btn btn-primary">
                                Salvar
                            </button>
                        </div>
                    </div>
                    <div class="card tarefa" *ngIf="botoes.adicionarTarefa && publicacao.processoJudicialId != null">
                        <tarefa-cadastro [tarefa]="tarefa" [index]="1" [publicacao]="publicacao"
                            [recursosDesdobramentoId]="recursoDesdobramentoSelecionado"></tarefa-cadastro>
                        <br />
                        <div class="d-flex justify-content-end col-12">
                            <button type="button" (click)="desabilitarBotoes()" class="btn btn-default">
                                Cancelar
                            </button>
                            &nbsp;
                            <button type="button" (click)="gravarTarefa()" class="btn btn-primary">
                                Salvar
                            </button>
                        </div>
                    </div>
                    <div class="card evento" *ngIf="botoes.adicionarEvento && publicacao.processoJudicialId != null">
                        <evento-cadastro [evento]="evento" [index]="1"></evento-cadastro>
                        <br />
                        <div class="d-flex justify-content-end col-12">
                            <button type="button" (click)="desabilitarBotoes()" class="btn btn-default">
                                Cancelar
                            </button>
                            &nbsp;
                            <button type="button" (click)="gravarEvento()" class="btn btn-primary">
                                Salvar
                            </button>
                        </div>
                    </div>
                    <div class="card historico"
                        *ngIf="botoes.adicionarHistoricoManual && publicacao.processoJudicialId != null">
                        <historico-cadastro [historicoManual]="historicoManual" [index]="1"></historico-cadastro>
                        <br />
                        <div class="d-flex justify-content-end col-12">
                            <button type="button" (click)="desabilitarBotoes()" class="btn btn-default">
                                Cancelar
                            </button>
                            &nbsp;
                            <button type="button" (click)="gravarHistoricoManual()" class="btn btn-primary">
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-6" *ngIf="!botoes.edit">
                    <div class="card processo" *ngIf="publicacao.processoJudicialId != null">
                        <div>
                            <b>PROCESSO</b>
                        </div>
                        <div>
                            <a class="link-processo"
                                href="/operacionais/processos-judiciais/detalhes/{{publicacao.processoJudicialId}}"
                                target="_blank">{{publicacao.processoJudicialTitulo}} -
                                {{publicacao.processoJudicialInstancia}}</a>
                        </div>
                        <div class="detalhes-processo">
                            Responsável: {{publicacao.processoJudicialResponsael}}
                            <hr />
                            {{publicacao.numeroProcesso}}
                        </div>
                    </div>
                    <div class="card processo"
                        *ngIf="publicacao.processoJudicialId == null && publicacao.tipoSituacaoPublicacao == 1">
                        <div>
                            <b>PROCESSO
                                <a *ngIf="processoSelecionado"
                                    class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                    href="/dashboard/processos-judiciais/detalhes/{{processoSelecionado}}"
                                    target="_blank" ngbTooltip="Clique para acessar detalhes do processo"></a>
                            </b>
                        </div>
                        <div>
                            Vincule um processo para dar início ao tratamento da Publicação
                        </div>
                        <div class="form-group">
                            <ng-select [items]="processos" [(ngModel)]="processoSelecionado"
                                (change)="carregarRecursosDesdobramento()" bindLabel="titulo" bindValue="id"
                                [ngModelOptions]="{standalone: true}" [searchFn]="filtrarProcessoDropDown"
                                [loading]="loadProcessos" (keyup)="filtrarProcesso($event)">
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="line"><b>{{item.titulo}}</b></div>
                                    <div class="line" *ngIf="item.instanciaNome != null">Instância:
                                        {{item.instanciaNome}}</div>
                                    <div class="line" *ngIf="item.numero != null">Número: {{item.numero}}</div>
                                    <div class="line" *ngIf="item.clientes != null && item.clientes.length == 1">
                                        Cliente: {{item.clientes[0].nome}}</div>
                                    <div class="line" *ngIf="item.clientes != null && item.clientes.length > 1">
                                        Clientes: <span
                                            *ngFor="let cliente of item.clientes; let i=index">{{cliente.nome}}{{((i<item.clientes.length-1)?"
                                                | ":"")}}</span></div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div *ngIf=" loadMensagemProcessoLocalizado">
                                                Foi localizado um processo com mesmo número para ser vinculado
                                    </div>
                                    <hr *ngIf="exibirDetalhesRecursoDesdobramento" />
                                    <div *ngIf="exibirDetalhesRecursoDesdobramento">
                                        <b>Dados para gerar a ocorrência</b>
                                    </div>
                                    <div *ngIf="exibirDetalhesRecursoDesdobramento">
                                        Recurso e/ou Desdobramento
                                    </div>
                                    <div *ngIf="exibirDetalhesRecursoDesdobramento">
                                        <ng-select [items]="recursosDesdobramentos"
                                            [(ngModel)]="recursoDesdobramentoSelecionado" bindLabel="display"
                                            bindValue="id" [ngModelOptions]="{standalone: true}"
                                            [loading]="loadRecursoDesdobramento">
                                            <ng-template ng-option-tmp let-item="item">
                                                <div class="line"><b>{{item.display}}</b></div>
                                                <div class="line" *ngIf="item.instanciaNome != null">Instância:
                                                    {{item.instanciaNome}}</div>
                                                <div class="line" *ngIf="item.numero != null">Número: {{item.numero}}
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div *ngIf="exibirDetalhesRecursoDesdobramento">
                                        Tipo da ocorrência
                                    </div>
                                    <div *ngIf="exibirDetalhesRecursoDesdobramento">
                                        <ng-select [items]="tipoOcorrencia"
                                            [(ngModel)]="tipoProcessoJudicialOcorrenciaSelecionado"
                                            [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                            [loading]="loadTipoOcorrencia"></ng-select>
                                    </div>
                                    <div *ngIf="apensos != null && apensos.length > 0">
                                        Replicar publicação nos seguintes processos vinculados:
                                    </div>
                                    <div *ngIf="apensos != null && apensos.length > 0" class="row">
                                        <div class="col-12" *ngFor="let apenso of apensos">
                                            <input type="checkbox" name="radios-privado" class="cursor-pointer-padrao"
                                                [(ngModel)]="apenso.selecionado">
                                            <label class="form-label">{{apenso.titulo}}</label>
                                            <p>{{apenso.display}}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="exibirDetalhesRecursoDesdobramento">
                                        <button type="button" class="btn btn-primary" (click)="criarOcorrencia()">
                                            Gerar Ocorrência
                                        </button>
                                    </div>
                        </div>

                        <div class="card descarte" *ngIf="publicacao.tipoSituacaoPublicacao == 3">
                            <div>
                                <b>PUBLICAÇÃO DESCARTADA</b>
                            </div>
                            <div>
                                Por {{publicacao.pessoaEfetuaouDescarte}} em {{publicacao.dataTratamento |
                                date:'dd/MM/yyyy'}} às {{publicacao.dataTratamento | date:'HH:mm:ss'}}
                            </div>
                            <div>
                                <button type="button" (click)="desfazerDescarte()" class="btn btn-light">
                                    Desfazer
                                </button>
                            </div>
                        </div>
                        <br *ngIf="publicacao.possuiTratamento" />
                        <div *ngIf="publicacao.possuiTratamento" class="titulo-tratamento">
                            <b>TRATAMENTOS</b> POR {{publicacao.pessoaTratamento}} EM {{publicacao.dataTratamento |
                            date: 'dd/MM/yyyy HH:mm'}}
                        </div>
                        <div
                            *ngIf="publicacao.possuiTratamento && publicacao.processoJudicialId != null && publicacao.prazos != null">
                            <div class="card prazo prazo-card" *ngFor="let prazo of publicacao.prazos"
                                (click)="editarPrazo(prazo)">
                                <div class="prazo-texto">PRAZO <i class="far fa-edit"></i></div>
                                <div class="col-2">
                                    <input class="form-check-input" type="checkbox" disabled="true"
                                        [(ngModel)]="prazo.concluido">
                                </div>
                                <div class="col-10 mt-1">
                                    <p class="prazo-titulo">
                                        {{prazo.titulo}}
                                    </p>
                                    <div>
                                        <span class="titulo">Data limite:</span> {{prazo.dataLimite | dataextenso}}
                                        <span class="farol badge" [ngClass]="prazo.corAlertaDataLimite"
                                            *ngIf="prazo.corAlertaDataLimite != null">&nbsp;</span>
                                    </div>
                                    <div>
                                        <span class="titulo">Data revisão:</span> {{prazo.dataRevisao | dataextenso}}
                                        <span class="farol badge" [ngClass]="prazo.corAlertaDataRevisao"
                                            *ngIf="prazo.corAlertaDataRevisao != null">&nbsp;</span>
                                    </div>
                                    <div>
                                        <span class="titulo">Responsável:</span> {{prazo.responsavelNome}}
                                    </div>
                                    <div>
                                        <span class="titulo">Observações:</span> {{prazo.observacao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="publicacao.possuiTratamento && publicacao.processoJudicialId != null && publicacao.audiencias != null">
                            <div class="card audiencia audiencia-card" *ngFor="let audiencia of publicacao.audiencias"
                                (click)="editarAudiencia(audiencia)">
                                <div class="audiencia-texto">AUDIÊNCIA/JULGAMENTO <i class="far fa-edit"></i></div>
                                <div class="col-2">
                                    <input class="form-check-input" type="checkbox" disabled="true"
                                        [(ngModel)]="audiencia.concluido">
                                </div>
                                <div class="col-10 mt-1">
                                    <p class="audiencia-titulo">
                                        {{audiencia.tipoSessaoNome}}
                                    </p>
                                    <div>
                                        <span class="titulo">Data e hora:</span> {{audiencia.dataHoraInicial |
                                        dataextenso}} - {{audiencia.dataHoraInicial | date:'HH:mm' }} às
                                        {{audiencia.dataHoraFinal | date:'HH:mm'}}
                                    </div>
                                    <div>
                                        <span class="titulo">Responsável:</span> {{audiencia.responsavelNome}}
                                    </div>
                                    <div>
                                        <span class="titulo">Observações:</span> {{audiencia.observacao}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="publicacao.possuiTratamento && publicacao.processoJudicialId != null && publicacao.tarefas != null">
                            <div class="card tarefa tarefa-card" *ngFor="let tarefa of publicacao.tarefas"
                                (click)="editarTarefa(tarefa)">
                                <div class="tarefa-texto">TAREFA <i class="far fa-edit"></i></div>
                                <div class="col-2">
                                    <input class="form-check-input" type="checkbox" disabled="true"
                                        [(ngModel)]="tarefa.concluido">
                                </div>
                                <div class="col-10 mt-1">
                                    <p class="tarefa-titulo">
                                        {{tarefa.titulo}}
                                    </p>
                                    <div *ngIf="tarefa.dataTarefa != null">
                                        <span class="titulo">Data:</span> {{tarefa.dataTarefa | dataextenso}} <span
                                            class="farol badge" [ngClass]="tarefa.corAlertaData"
                                            *ngIf="tarefa.corAlertaData != null">&nbsp;</span>
                                    </div>
                                    <div>
                                        <span class="titulo">Prioridade:</span> {{tarefa.prioridadeDescricao}}
                                    </div>
                                    <div>
                                        <span class="titulo">Responsável:</span> {{tarefa.responsavelNome}}
                                    </div>
                                    <div>
                                        <span class="titulo">Descricao:</span> {{tarefa.descricao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="publicacao.possuiTratamento && publicacao.processoJudicialId != null && publicacao.eventos != null">
                            <div class="card evento evento-card" *ngFor="let evento of publicacao.eventos"
                                (click)="editarEvento(evento)">
                                <div class="evento-texto">EVENTO <i class="far fa-edit"></i></div>
                                <div class="col-2">
                                    <input class="form-check-input" type="checkbox" disabled="true"
                                        [(ngModel)]="evento.concluido">
                                </div>
                                <div class="col-10 mt-1">
                                    <p class="evento-titulo">
                                        {{evento.titulo}}
                                    </p>
                                    <div *ngIf="evento.diaInteiro">
                                        <span class="titulo">Data:</span> {{evento.dataHoraInicial | dataextenso}}
                                    </div>
                                    <div *ngIf="!evento.diaInteiro">
                                        <span class="titulo">Data:</span> {{evento.dataHoraInicial | dataextenso}}
                                    </div>
                                    <div *ngIf="!evento.diaInteiro">
                                        <span class="titulo">Hora:</span> {{evento.dataHoraInicial | date:'HH:mm'}} até
                                        {{evento.dataHoraFinal | date:'HH:mm'}}
                                    </div>
                                    <div>
                                        <span class="titulo">Responsável:</span> {{evento.responsavelNome}}
                                    </div>
                                    <div>
                                        <span class="titulo">Observação:</span> {{evento.observacao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="publicacao.possuiTratamento && publicacao.processoJudicialId != null && publicacao.historicosManuais != null">
                            <div class="card historico historico-card"
                                *ngFor="let historico of publicacao.historicosManuais"
                                (click)="editarHistorico(historico)">
                                <div class="historico-texto">HISTÓRICO <i class="far fa-edit"></i></div>
                                <div class="col-2">
                                    <input class="form-check-input" type="checkbox" disabled="true"
                                        [(ngModel)]="historico.concluido">
                                </div>
                                <div class="col-10 mt-1">
                                    <div>
                                        <span class="titulo">Data:</span> {{historico.dataHistorico | dataextenso}}
                                    </div>
                                    <div>
                                        <span class="titulo">Descrição:</span> {{historico.descricao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>