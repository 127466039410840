import { FaturamentosComponent } from './../faturamentos.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDateParserFormatter, NgbModalRef, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
const now = new Date();
import * as moment from 'moment';
import { DividaService } from 'src/app/services/divida.service';
import { FaturamentoService } from 'src/app/services/faturamento.service';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PessoaService } from 'src/app/services/pessoa.service';
import { DividaPagamentosService } from 'src/app/services/divida-pagamentos.service';
import { AgrupamentoPagamentosPorProcessoComponent } from '../../components/agrupamento-pagamentos-por-processo/agrupamento-pagamentos-por-processo.component';
import { FormControl } from '@angular/forms';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { ModalDetalhePagamentosComponent } from '../modal-detalhe-pagamentos/modal-detalhe-pagamentos.component';


@Component({
    selector: 'app-modal-adicionar-faturamento',
    templateUrl: './modal-adicionar-faturamento.component.html',
    styleUrls: ['./modal-adicionar-faturamento.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class ModalAdicionarFaturamentoComponent implements OnInit {





    modalRef: NgbModalRef;

    public title: any;

    faturamento: any;

    credores: any[];
    credoresSelecionados: any = [];

    dividas: any[];

    public displayMonths = 1;
    public navigation = 'select';
    public disabled = false;


    pagamentos: any = [];
    pagamentosNaoFaturados: any = [];
    pagamentosFaturados: any = [];


    naoFaturado: any = [];
    aprovado: any = [];
    recusado: any = [];


    loadNaoFaturados: boolean = false;
    loadFaturados: boolean = false;
    loadCredores: boolean = false;


    // Options
    perPage = 20;

    //Paginate
    currentPage = 1;
    totalItems = 0;
    checkAllPagamentos = false;
    public activeTab = 1;
    fitersPagamentos = [
        { id: 1, label: "Todos" },
        { id: 2, label: "Isento" }
    ]
    filterPagamentoSelected = 1;
    filterDataInicial: any;
    filterDataFinal: any;
    pessoas: any = [];
    pessoasSelecionados: any = [];


    dataEmissao: any;
    dataPagamento: any;
    dataVencimento: any;

    valorComissao: any = 0;
    valorNf: any = 0;
    numeroNf: any;
    numeroPo: any;
    observacao: any = "";

    updateData: boolean = false;

    credorPreselecionado: any;

    public dropdownSettings = {
        singleSelection: false,
        text: "Selecione",
        selectAllText: 'Selecione todas',
        unSelectAllText: 'Remova todas',
        enableSearchFilter: true,
        classes: "multi-select-myclass",
        disabled: false
    };



    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        private dividaServise: DividaService,
        private faturamentoService: FaturamentoService,
        private pessoaService: PessoaService,
        private utilsService: UtilsService,
        private dividaPagamentoService: DividaPagamentosService
    ) {

    }

    async ngOnInit(): Promise<void> {


        this.pessoas = [];
        this.pessoasSelecionados = [];

        if (this.modalRef.componentInstance.faturamento != null) {

            this.updateData = true;
            this.faturamento = this.modalRef.componentInstance.faturamento;

            this.initFields();

            await this.getCredores();
            this.setDisabledDropDown(true);

            this.getPagamentosFaturados(this.faturamento.id);

            this.faturamento.credorFaturamento.forEach(element => {
                this.pessoasSelecionados.push(
                    {
                        id: element.credorId, nome: element.nomeCredor,
                        cpfCnpj: element.cpfCnpjCredor, bu: element.bu
                    }
                )
            });

            this.getPagamentosNaoFaturados(this.pessoasSelecionados);

            this.activeTab = 2;
        }
        else {
            this.updateData = false;
            this.credorPreselecionado = this.modalRef.componentInstance.credorPreselecionado;
            this.faturamento = {};

            if (this.credorPreselecionado != null) {
                this.addCredorPreselecionado(this.credorPreselecionado)
            } else {
                await this.getCredores();
            }


        }

        this.title = this.updateData ? "Atualizar Faturamento" : "Cadastrar Faturamento";
    }

    async getCredores() {
        this.loadCredores = true;
        await this.pessoaService.getByPessoaTipoListaFaturamentoAsync("Credor").then(x => {
            this.pessoas = x.data;
            this.loadCredores = false;

            this.pessoas.forEach(element => {
                var resultCredorFaturamento = this.faturamento.credorFaturamento.find(x => x.id == element.id);
                if (resultCredorFaturamento != null) {
                    this.pessoasSelecionados.push(element);
                }
            });
        });
    }

    AtualizaValorNF() {

        this.valorComissao = 0;
        var aprovados = this.getAprovadosTab();

        var valueComissao = 0;
        aprovados.forEach(element => {
            valueComissao += element.valorComissao;
        });
        this.valorComissao = valueComissao;
    }

    dividaSelecionada() {
        this.AtualizaValorNF();
    }


    async getPagamentosFaturados(faturamentoId) {


        this.spinner.show();
        this.loadFaturados = true;



        this.dividaPagamentoService.getPagamentosFaturados(faturamentoId).then(x => {

            this.pagamentosFaturados = x.data;

            this.attArraysInit();

            this.spinner.hide();
            this.loadFaturados = false;
        });

    }

    async getPagamentosNaoFaturados(credoresSelecionados) {
        var credoresSelecionadosId = credoresSelecionados.map(({ id }) => id);

        //this.spinner.show();
        this.loadNaoFaturados = true;
        this.activeTab = 1;
        this.dividaPagamentoService.
            getPagamentosNaoFaturados(credoresSelecionadosId, this.filterDataInicial,
                this.filterDataFinal, this.filterPagamentoSelected).then(x => {                    
                    if (this.credorPreselecionado != undefined) {
                        if (this.credorPreselecionado.ambiente == undefined || this.credorPreselecionado.ambiente == '') {
                            this.pagamentosNaoFaturados = x.data;
                        } else if (this.credorPreselecionado.ambiente == '1') {
                            this.pagamentosNaoFaturados = x.data.filter(x => x.ambienteNome == 'Amigável');
                        } else if (this.credorPreselecionado.ambiente == '2') {
                            this.pagamentosNaoFaturados = x.data.filter(x => x.ambienteNome == 'Jurídico');
                        }

                    }else{
                        this.pagamentosNaoFaturados = x.data;
                    }
                    this.attArraysInit();
                    console.log(this.pagamentosNaoFaturados);
                    //this.spinner.hide();
                    this.loadNaoFaturados = false;
                });

    }

    Calcular(tipo: string) {

        let total = 0;

        if (tipo == 'pagamento') {
            total = this.dividas.reduce((accumulator, current) => accumulator + parseFloat(current.valorPagamento), 0);
        }
        else if (tipo == 'comissao') {
            total = this.dividas.reduce((accumulator, current) => accumulator + parseFloat(current.valorComissao), 0);
        }

        return total.toFixed(2);


    }

    salvar() {
        if (this.validateInfo()) {
            if (this.preSave()) {
                if (this.updateData) {
                    this.update();
                } else {
                    this.add();
                }

            }

        }
    }

    export(idFaturamento: Guid) {
        this.faturamentoService.export(idFaturamento).subscribe(res => {
            var datePipe = new DatePipe("en-US");
            FileSaver.saveAs(res, "faturamento-" + datePipe.transform(new Date(), 'dd-MM-yyyy') + '.xlsx');
        });
    }

    validateInfo() {
        this.toastrService.clear();

        if (this.pessoasSelecionados.length == 0) {
            this.toastrService.error("Selecione pelo menos 1 credor", "Atenção");
            return false;
        }

        //
        //validations here
        //


        return true;
    }

    preSave() {
        this.faturamento.dataVencimento = this.dataVencimento != undefined ? this.returnDate(this.dataVencimento) : this.dataVencimento;
        this.faturamento.dataEmissaoFaturamento = this.dataEmissao != undefined ? this.returnDate(this.dataEmissao) : this.dataEmissao;
        this.faturamento.dataPagamento = this.dataPagamento != undefined ? this.returnDate(this.dataPagamento) : this.dataPagamento;
        this.faturamento.observacao = this.observacao;
        this.faturamento.valorNotaFiscal = this.valorNf;
        this.faturamento.numeroPo = this.numeroPo;
        this.faturamento.numeroNotaFiscal = this.numeroNf;

        var pagamentos = this.getAprovadosTab();
        pagamentos.push(...this.getRecusadosTab());


        this.faturamento.pagamentos = pagamentos;


        var credores = [];
        this.pessoasSelecionados.forEach(element => {
            credores.push(element.id);
        });
        this.faturamento.credores = credores;

        return true;
    }

    add() {
        this.spinner.show()

        this.faturamentoService.add(this.faturamento).subscribe(res => {

            this.spinner.hide();
            this.toastrService.success("Fatura cadastrada com sucesso", "Sucesso");
            this.close();

        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção');
            }
            else {
                this.toastrService.error('Não foi possível carregar os dados!', 'Atenção', { timeOut: 10000 });
            }

            this.spinner.hide();
        });
    }

    update() {

        this.spinner.show()

        this.faturamentoService.update(this.faturamento).subscribe(res => {

            this.spinner.hide();
            this.toastrService.success("Fatura atualizada com sucesso.", "Sucesso");
            this.close();

        });
    }


    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }



    limparFiltros() {
        this.filterDataFinal = undefined;
        this.filterDataInicial = undefined;
        this.pagamentos = [];

    }


    atualizaPagamentos(array) {

        array.forEach(element => {
            if (element.tab == "naoFaturado") {
                var result = this.pagamentosNaoFaturados.find(x => x.id == element.id);
                if (result == null) {
                    this.pagamentosNaoFaturados.push(...element);
                    this.pagamentosFaturados = this.removeById(this.pagamentosFaturados, element.id);
                }

            }

            if (element.tab == "aprovado") {
                var result = this.pagamentosFaturados.find(x => x.id == element.id);
                if (result == null) {
                    this.pagamentosFaturados.push(...element);
                    this.pagamentosNaoFaturados = this.removeById(this.pagamentosNaoFaturados, element.id);
                }

            }

            if (element.tab == "recusado") {
                var result = this.pagamentosFaturados.find(x => x.id == element.id);
                if (result == null) {
                    this.pagamentosFaturados.push(...element);
                    this.pagamentosNaoFaturados = this.removeById(this.pagamentosNaoFaturados, element.id);
                }
            }

            this.valorNf = this.pagamentosFaturados.reduce((ac, ob) => {
                return ac + ob.valorPagamento
            }, 0);
        });





        // this.pagamentosFaturados.forEach(element => {
        //   var result = array.find(x => x.id == element.id);
        //   if(result != null){
        //     element.tab = result.tab;
        //     element.selecionado = result.selecionado;
        //   }
        // });

        // this.pagamentosNaoFaturados.forEach(element => {
        //   var result = array.find(x => x.id == element.id);
        //   if(result != null){
        //     element.tab = result.tab;
        //     element.selecionado = result.selecionado;
        //   }
        // });

        this.attArraysiAfter();
    }





    attArraysInit() {
        var naofaturado = this.pagamentosNaoFaturados.filter(x => x.faturamentoId == null);
        naofaturado.forEach(element => {

            element.tab = "naoFaturado";
            element.enable = true;
        });
        this.naoFaturado = naofaturado;

        var aprovado = this.pagamentosFaturados.filter(x => (x.faturamentoId != null) && (!x.recusado));
        aprovado.forEach(element => {
            element.tab = "aprovado";
            element.selecionado = true;
            element.enable = true;
        });
        this.aprovado = aprovado;


        var recusado = this.pagamentosFaturados.filter(x => x.recusado);
        recusado.forEach(element => {
            element.tab = "recusado";
            element.enable = true;
        });
        this.recusado = recusado;

        this.AtualizaValorNF();
    }

    attArraysiAfter() {
        var naofaturado = this.pagamentosNaoFaturados.filter(x => x.tab == "naoFaturado");
        this.naoFaturado = naofaturado;

        var aprovado = this.pagamentosFaturados.filter(x => x.tab == "aprovado");
        this.aprovado = aprovado;

        var recusado = this.pagamentosFaturados.filter(x => x.tab == "recusado");
        this.recusado = recusado;

        this.AtualizaValorNF();
    }


    getAprovadosTab() {
        return this.pagamentosFaturados.filter(x => x.tab == "aprovado");
    }
    getNaoFaturadosTab() {
        return this.pagamentosNaoFaturados.filter(x => x.tab == "naoFaturado");
    }
    getRecusadosTab() {
        return this.pagamentosFaturados.filter(x => x.tab == "recusado");
    }

    initFields() {
        this.dataEmissao = this.faturamento.dataEmissaoFaturamento != null ? this.initDate(new Date(this.faturamento.dataEmissaoFaturamento)) : null;
        this.dataPagamento = this.faturamento.dataPagamento != null ? this.initDate(new Date(this.faturamento.dataPagamento)) : null;
        this.dataVencimento = this.faturamento.dataVencimento != null ? this.initDate(new Date(this.faturamento.dataVencimento)) : null;
        this.valorNf = this.faturamento.valorNotaFiscal;
        this.numeroNf = this.faturamento.numeroNotaFiscal;
        this.numeroPo = this.faturamento.numeroPo;
        this.observacao = this.faturamento.observacao;
    }


    returnCss(css) {
        return css;
    }


    returnDate(date) {
        if (date["year"]) {
            return new Date(date["year"], date["month"] - 1, date["day"]);
        } else {
            return date;
        }
    }


    initDate(date: Date) {

        var obj = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
        return obj;



    }


    removeById(array, id) {
        array = array.filter(x => x.id != id);
        return array;
    }


    checkAddOrRemoveCredor(event, item) {
        var status = event.currentTarget.checked;
        item.selecionado = status;

        if (status) {
            this.credoresSelecionados.push(item);
        } else {
            this.credoresSelecionados = this.credoresSelecionados.filter(x => x.id != item.id);
        }

    }

    arrayAgrupadoPorPorcessoAmigavel(array) {
        let agrup = this.utilsService.groupBy(array.filter(x => x.ambiente == 1), "processoId");
        return agrup
    }

    arrayAgrupadoPorPorcessoJuridico(array) {
        let agrup = this.utilsService.groupBy(array.filter(x => x.ambiente == 2), "processoRecursoDesdobramentoId");
        return agrup
    }

    arrayAgrupadoPorProcessoLength(array) {
        var agrupamento = [];

        this.arrayAgrupadoPorPorcessoAmigavel(array).forEach(c => agrupamento.push(c));
        this.arrayAgrupadoPorPorcessoJuridico(array).forEach(c => agrupamento.push(c));

        return agrupamento.length;
    }


    getBuCredor(credor) {

        //var pd = credor.pessoaDados;
        return "";
    }


    setDisabledDropDown(value: boolean) {
        this.dropdownSettings = {
            singleSelection: false,
            text: "Selecione",
            selectAllText: 'Selecione todas',
            unSelectAllText: 'Remova todas',
            enableSearchFilter: true,
            classes: "multi-select-myclass",
            disabled: value
        };
    }


    addCredorPreselecionado(credorPreselecionado) {

        this.pessoas.push(credorPreselecionado);
        this.pessoasSelecionados.push(credorPreselecionado);
        this.setDisabledDropDown(true);

        this.getPagamentosNaoFaturados(this.pessoasSelecionados);

    }

}

