import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ProcessosJudiciaisOcorrenciaEventoService } from 'src/app/services/processos-judiciais-ocorrencia-evento.service';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
    selector: 'eventos-detalhes',
    templateUrl: './eventos-detalhes.component.html',
    styleUrls: [
        './eventos-detalhes.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ]
})
export class EventosDetalhesComponent implements OnInit {

    @Input() eventoId: any;
    @Input() eventoDetalhes: any;

    @Output() changeQuantidade: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeQuantidadeOcultando: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeConcluirEvento: EventEmitter<boolean> = new EventEmitter<boolean>();

    public evento: any;

    public loadEvento: boolean = true;

    public recarregarAoFechar: boolean = true;

    public comentarioDescricao: string;

    public comentarios: any = [];

    public uploader: FileUploader;

    public loadAtach: boolean = false;

    private file: File;

    private files: Array<File> = [];

    constructor(
        public processosJudiciaisOcorrenciaEventoService: ProcessosJudiciaisOcorrenciaEventoService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public arquivoService: ArquivoService,
        public utilsService: UtilsService,
    ) { 
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            }
        });
    }

    ngOnInit(): void {
        this.buscarEvento();
    }

    buscarEvento() {
        this.processosJudiciaisOcorrenciaEventoService.getById(this.eventoId)
            .subscribe(res => {

                if (res.success) {
                    this.evento = res.data;

                    this.loadEvento = false;

                    this.evento.quantidadeComentarios = res.data.quantidadeComentarios;

                    this.comentarios = res.data.comentarios;

                    this.changeQuantidade.emit(this.evento.quantidadeComentarios);
                }
            })
    }

    handleInserirComentarioForm() {

        this.spinner.show();
        this.toastrService.clear();

        let formData: FormData = new FormData();
        formData.append('eventoId', this.eventoId);
        formData.append('comentario', this.comentarioDescricao != undefined ? this.comentarioDescricao : "");
        
        this.files.forEach(file => {                        
            formData.append('files', file);
        })

        this.processosJudiciaisOcorrenciaEventoService.addComentario(formData)
            .subscribe(res => {
                if (res.success) {
                    this.toastrService.success("Comentário cadastrado.", "Sucesso");

                    this.buscarEvento();

                    this.comentarioDescricao = "";

                    this.spinner.hide();
                    
                    this.uploader.clearQueue();

                    this.files = [];
                } else {
                    this.toastrService.error("Falha ao adicionar comentário.", "Sucesso");

                    this.spinner.hide();
                }
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o comentário!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            this.files.push(files[i] as File);
        }

        const fileInQueue = this.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item:FileItem){
        console.log(item);
        this.files = this.files.filter(c=>c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(c=>c.file.name != item.file.name);
    }

    handleDownloadArquivo(arquivo:any){
        this.spinner.show();
        try{
            this.arquivoService.downloadArquivo(arquivo.arquivoId).subscribe(                
                data => {                    
                    if(data.type == 3){
                        this.spinner.hide();                    
                    }
                    this.utilsService.renderDownload(data, arquivo.nomeArquivo as string);
                }
            );
        }catch (err){
            console.log(err);
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }

    handleOcultar() {
        this.changeQuantidadeOcultando.emit(this.evento.quantidadeComentarios);
    }
}
