import { CurrencyPipe, DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { AtualizacaoValoresService } from "src/app/services/atualizacao-valores.service";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class PdfCalculoService {
  constructor(
    private service: AtualizacaoValoresService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private spinner: NgxSpinnerService
  ) {}

  async openPdf(id) {
    this.spinner.show();
    const { data } = await this.service.getById(id).toPromise();
    const valores = data.valores;

    const transformDate = (date) => {
      return this.datePipe.transform(new Date(date), "dd/MM/yyyy");
    };

    const transformCurrency = (currency) => {
      return this.currencyPipe.transform(currency, "BRL");
    };

    const list = [];
    valores.forEach((valor) => {
      const {
        data: date,
        valorAtualizado,
        valorJurosMoratorios,
        valorJurosCompensatorios,
        total,
      } = valor;
      list.push([
        { text: transformDate(date) },
        {
          text: `${transformCurrency(valorAtualizado)} ${
            valorJurosMoratorios > 0 ? ": " + valorJurosMoratorios : ""
          } ${
            valorJurosCompensatorios > 0 ? "x " + valorJurosCompensatorios : ""
          }`,
        },
        {
          text: transformCurrency(total),
          alignment: "right",
        },
      ]);
    });

    this.spinner.hide();

    const heigths = [10];
    valores.forEach(() => heigths.push(40));

    let docDefinition: TDocumentDefinitions = {
      pageSize: "A4",
      pageMargins: 20,
      defaultStyle: {
        fontSize: 8,
        color: "black",
      },
      content: [
        {
          color: "#555",
          table: {
            heights: [20],
            widths: ["*"],
            body: [
              [
                {
                  text: "Atualização Aluguel",
                  fillColor: "#d4d4d4",
                  fontSize: 12,
                  alignment: "center",
                },
              ],
            ],
          },
          layout,
        },
        {
          columns: [
            {
              text: "",
              margin: [0, 0, 0, 5],
            },
          ],
        },
        {
          color: "#555",
          table: {
            heights: [20],
            widths: ["*"],
            body: [
              [
                {
                  text: "Correção Monetária",
                  fillColor: "#d4d4d4",
                  fontSize: 12,
                  alignment: "center",
                },
              ],
              [
                {
                  text: `Valores atualizados até ${transformDate(
                    data?.dataAtualizacaoValores
                  )} \n\n Indexador utilizado: ${data?.indice?.nome}`,
                  fontSize: 12,
                },
              ],
            ],
          },
          layout,
        },
        {
          layout: "noBorders",
          table: {
            heights: heigths,
            widths: [80, 300, 150],
            body: [["", "", ""], ...list],
          },
        },
        {
          color: "#555",
          table: {
            heights: [20],
            widths: ["*"],
            body: [
              [
                {
                  text: "Resumo",
                  fillColor: "#d4d4d4",
                  fontSize: 12,
                  alignment: "center",
                },
              ],
              [
                {
                  layout: "noBorders",
                  table: {
                    heights: [5, 20, 20, 20, 20, 20, 20, 20],
                    widths: [250, 90, 90, 90],
                    body: [
                      ["", "", "", ""],
                      [
                        { text: "" },
                        { text: "Valores", bold: true },
                        { text: `Custas`, bold: true },
                        { text: `Total`, bold: true },
                      ],
                      [
                        { text: "Valores Atualizados" },
                        { text: transformCurrency(data.valorTotalAtualizado) },
                        { text: "0,00" },
                        { text: transformCurrency(data.valorTotalAtualizado) },
                      ],
                      [
                        { text: "TOTAL", bold: true },
                        {
                          text: transformCurrency(data.valorTotalAtualizado),
                          bold: true,
                        },
                        { text: "0,00", bold: true },
                        {
                          text: transformCurrency(data.valorTotalAtualizado),
                          bold: true,
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
          },
          layout,
        },
      ],
    };
    pdfMake.createPdf(docDefinition).open();
  }
}

const layout = {
  hLineColor: function (i, node) {
    return "grey";
  },
  vLineColor: function (i, node) {
    return "grey";
  },
};
