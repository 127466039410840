import { Component, OnInit } from '@angular/core';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { AtendimentoRegistroService } from 'src/app/services/atendimento-registro.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ModalEncerramentoComponent } from './modal-encerramento/modal-encerramento.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentoArquivoService } from 'src/app/services/documento-arquivo.service';

@Component({
    selector: 'app-atendimento-registros',
    templateUrl: './atendimento-registros.component.html',
    styleUrls: ['./atendimento-registros.component.css']
})
export class AtendimentoRegistrosComponent implements OnInit {

    public id: string;

    public loadAtendimento: boolean = true;

    public atendimento: any;

    public registrosOriginais: any=[];

    public textoRegistro: string="";

    public filtroImportante: boolean = false;

    public exibirMenu: boolean = false;

    public raiz: string;

    public quantidadeDocumentos: number = 0;

    constructor(
        public atendimentoService: AtendimentoService,
        public atendimentoRegistroService: AtendimentoRegistroService,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private router: Router,
        private ngbModal: NgbModal,
        private documentoArquivoService: DocumentoArquivoService
    ) { }

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];

        if (this.router.url.indexOf('/dashboard/') > -1)
        {
            this.raiz = '/dashboard'
        }else{
            this.raiz = '/operacionais'
        }

        this.buscarAtendimento();
        this.buscarQuantidadeDocumentos();
    }

    buscarQuantidadeDocumentos() {
        this.documentoArquivoService.obterQuantidadeDocumentosPorRegistro('3', this.id)
            .subscribe(res => {
                if (res.success) {
                    this.quantidadeDocumentos = res.data;
                }
            })
    }

    buscarAtendimento() {

        this.loadAtendimento = true;

        this.atendimentoService.getById(this.id)
            .subscribe(res=>{
                if(res.success){
                    this.atendimento = res.data;

                    this.registrosOriginais = res.data.registros;

                    console.log(this.atendimento);

                    this.loadAtendimento = false;
                }
            })
    }
    filtro() {
        if(this.filtroImportante){
            this.atendimento.registros = this.atendimento.registros.filter(c=>c.importante);
        }else{
            this.atendimento.registros = this.registrosOriginais;
        }
    }

    filtrarImportante(){
        this.filtroImportante = !this.filtroImportante;

        this.filtro();
    }

    defineClass(classe: string) {
        return 'badge ' + classe;
    }

    definirImportante(registro){
        registro.importante = !registro.importante;

        this.atendimentoRegistroService.update(registro)
            .subscribe(res=>{
                if(!res.success){
                    registro.importante = !registro.importante;
                }
            },err=>{
                registro.importante = !registro.importante;
            });
    }

    modificarStatus(){
        const modalRef = this.ngbModal.open(ModalEncerramentoComponent, {
            size: 'sm',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'atendimento-registros';
        modalRef.componentInstance.atendimentoId =this.id;

        modalRef.result.then(result => {
            this.buscarAtendimento();
            this.spinner.hide();
        }).catch(error => {

        });
    }

    onSubmit(){
        this.spinner.show();

        let registro = {
            AtendimentoId:this.id,
            Texto: this.textoRegistro
        }

        this.atendimentoService.addRegistro(registro)
            .subscribe(res=>{
                if(res.success){
                    this.textoRegistro = "";

                    this.spinner.hide();

                    this.buscarAtendimento();

                    this.toastrService.success("Registro adicionado.", "Sucesso");
                }else{
                    this.toastrService.error("Falha ao adicionar registro.", "Erro");
                }
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o comentário!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    voltar(){
        this.location.back();
    }

    criarCaso(){
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente criar o caso e vincular o atendimento a ele?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.value != undefined && result.value == true)
            {
                this.router.navigate([this.raiz+'/casos/cadastrar/'+this.id]);
            }
        });
    }

    criarProcesso(){
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente criar o processo e vincular o atendimento a ele?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.value != undefined && result.value == true)
            {
                this.router.navigate([this.raiz+'/processos-judiciais/cadastrar/'+this.id]);
            }
        });
    }
}
