<div class="row prazo">
    <div class="col-12 mb-2">
        <h6 id="prazo_{{index}}">Prazo ({{index}})</h6>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Classificação atividade<span class="required-field">*</span></label>
        <ng-select [items]="classificacoesAtividades" [(ngModel)]="prazo.classificacaoAtividadeId" [ngModelOptions]="{standalone: true}" (change)="classificacaoPrioridadeChange()"
            bindLabel="classificacao" bindValue="id" [loading]="classificacoesAtividades == undefined || classificacoesAtividades.length==0"></ng-select>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Sub-tipo de classificação atividade<span class="required-field">*</span></label>
        <ng-select [items]="subtiposAtividade" [(ngModel)]="prazo.classificacaoSubtipoAtividadeId" [ngModelOptions]="{standalone: true}"
            bindLabel="subtipoClassificacao" bindValue="id"></ng-select>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Prioridade<span class="required-field">*</span></label>
        <ng-select [items]="tipoPrioridade" [(ngModel)]="prazo.tipoPrioridade" [ngModelOptions]="{standalone: true}"
            bindLabel="nome" bindValue="id" [loading]="tipoPrioridade == undefined || tipoPrioridade.length==0"></ng-select>
    </div>    
    <div class="col-6 mb-2" *ngIf="prazo.dataInicioPrazo != undefined">
        <label class="form-label">Data da publicação</label>
        <p>{{prazo.dataPublicacao | date: 'dd/MM/yyyy'}}</p>
    </div>
    <div class="col-6 mb-2" *ngIf="prazo.dataInicioPrazo != undefined">
        <label class="form-label">Data início do prazo</label>
        <p>{{prazo.dataInicioPrazo | date: 'dd/MM/yyyy'}}</p>
    </div>
    <div class="col-2 mb-2" *ngIf="publicacao != undefined">
        <label class="form-label">Prazo</label>
        <input type="number" min="0" (input)="atualizaDataLimite()" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="prazo.prazoQuantidade">
    </div>
    <div class="col-6 mb-2" *ngIf="publicacao != undefined">
        <label class="form-label">&nbsp;</label>
        <ng-select [items]="tipoPrazo" [(ngModel)]="prazo.tipoPrazo" (change)="atualizaDataLimite()" [ngModelOptions]="{standalone: true}"
            bindLabel="nome" bindValue="id" [loading]="loadTipoAlerta"></ng-select>
    </div>
    <div class="col-4 mb-2">
        <label class="form-label">Data limite<span class="required-field ml-1">*</span></label>
        <div class="input-group">
            <input (click)="dataLimite.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataLimiteComponent" ngbDatepicker [(ngModel)]="prazo.dataLimiteComponent"
                placement="bottom-right"
                #dataLimite="ngbDatepicker" (ngModelChange)="changeDate($event)">
            <div class="input-group-append">
                <button class="input-group-text" (click)="dataLimite.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>    
    <div class="col-2 mb-2">
        <label class="form-label">Alerta</label>
        <input type="number" min="0" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="prazo.alerta">
    </div>
    <div class="col-6 mb-2">
        <label class="form-label">&nbsp;</label>
        <ng-select [items]="tipoAlerta" [(ngModel)]="prazo.tipoAlerta" [ngModelOptions]="{standalone: true}"
            bindLabel="nome" bindValue="id" [loading]="loadTipoAlerta"></ng-select>
    </div>
    <div class="col-4 mb-2">
        <label class="form-label">Data revisao<span class="required-field ml-1">*</span></label>
        <div class="input-group">            
            <input (click)="dataRevisao.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataRevisaoComponent" ngbDatepicker [(ngModel)]="prazo.dataRevisaoComponent"
                placement="bottom-right" [disabled]="calcularData"
                #dataRevisao="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="dataRevisao.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;" *ngIf="!calcularData"></span>
                    <span class="spinner-border spinner-border-sm" role="status" *ngIf="calcularData" aria-hidden="true"></span>
                    <span class="sr-only" *ngIf="calcularData">Loading...</span>
                </button>
            </div>            
        </div>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Equipe <i class="fas fa-info-circle" ngbTooltip="Por favor, selecione uma equipe para que os membros padrão sejam carregados automaticamente nos campos abaixo"></i></label>
        <equipe-dropdown [(equipeId)]="equipeId" (change)="onChangeEquipe($event)"></equipe-dropdown>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Executor <span class="required-field">*</span></label>        
        <pessoa-dropdown #executorDropdown [(pessoaId)]="prazo.responsavelId"></pessoa-dropdown> 
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Revisor <span class="required-field">*</span></label>        
        <pessoa-dropdown #revisorDropdown [(pessoaId)]="prazo.revisorId"></pessoa-dropdown> 
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Assistente <span class="required-field">*</span></label>        
        <pessoa-dropdown #assistenteDropdown [(pessoaId)]="prazo.assistenteId"></pessoa-dropdown> 
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Observações<span class="required-field ml-1">*</span></label>
        <textarea class="form-control" placeholder="Digite a descrição" rows="2"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="prazo.observacao"></textarea>
    </div>
    <div class="col-12 mb-2">
        <div class="form-row">
            <div class="col">
                <label class="form-label d-flex align-items-center">
                    Outros envolvidos 
                    <button type="button" class="btn btn-primary btn-xs ml-3" (click)="adicionarEnvolvidoPrazo(prazo)"><i class="fas fa-plus"></i></button>
                </label>
            </div>
        </div>
        <div class="form-row" *ngFor="let envolvido of prazo.envolvidos; let i = index">
            <div class="form-group col">                
                <pessoa-dropdown [(pessoaId)]="envolvido.envolvidoId"></pessoa-dropdown> 
            </div>                
            <div class="form-group col-1">
                <button type="button" class="btn btn-danger" (click)="removerEnvolvidoPrazo(prazo, i)"><i class="fas fa-trash"></i></button>
            </div>
        </div>
    </div>
</div>