import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AtualizacaoValoresService } from "src/app/services/atualizacao-valores.service";
import { CALCULATION_DATA_FORM_BINDING } from "../calculation-data/calculation.enum";
import { PdfCalculoService } from "../pdf-calculo.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-lista-atualizacoes",
  templateUrl: "./lista-atualizacoes.component.html",
  styleUrls: ["./lista-atualizacoes.component.scss"],
})
export class ListaAtualizacoesComponent implements OnInit {
  @Input() recursoDesdobramentoId = null;

  readonly CALCULATION_DATA_FORM_BINDING = CALCULATION_DATA_FORM_BINDING;

  atualizacoes: Array<any> = [];

  constructor(
    private service: AtualizacaoValoresService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private pdfCalculoService: PdfCalculoService
  ) {}

  public raiz: string = "";

  ngOnInit(): void {
    if (this.router.url.indexOf("/dashboard/") > -1) {
      this.raiz = "/dashboard";
    } else {
      this.raiz = "/operacionais";
    }

    this.getAll();
  }

  openPdf(id) {
    this.pdfCalculoService.openPdf(id);
  }

  aprovar(id){
    this.spinner.show();
    this.service.aprovarSimulacaoValores(id)
        .subscribe(res=>{
            this.spinner.hide();
        }, err=>{
            this.spinner.hide();
            this.toastrService.error('Falha ao aprovar!', 'Atenção', { timeOut: 10000 });
        })
  }

  async getAll() {
    this.spinner.show();
    this.atualizacoes = (await this.service.getAll().toPromise()).data;
    
    if(!!this.recursoDesdobramentoId) {
        this.atualizacoes = this.atualizacoes.filter(item => item.processoJudicialRecursoDesdobramentoId === this.recursoDesdobramentoId)
    }
    this.spinner.hide();
  }
}
