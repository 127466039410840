import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PerfilService } from 'src/app/services/perfil.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { SelectListMultiSelect } from 'src/app/models/select-list-multi-select';
import { SelectListService } from 'src/app/services/shared/select-list.service';

@Component({
    selector: 'app-modal-cadastro-usuario',
    templateUrl: './modal-cadastro-usuario.component.html',
    providers: [UsuarioService, PerfilService]
})
export class ModalCadastroUsuarioComponent implements OnInit {

    public loadPessoas: boolean = false;
    public pessoasSelecionadas: any = [];

    public ambientesAcessoList: SelectListMultiSelect[] = [];
    public ambientesSelecionados: SelectListMultiSelect[] = [];
    public settingsAmbientesAcesso = {
        text: "Selecione",
        selectAllText: "Selecione Todos",
        unSelectAllText: "Deselecionar Todos",
        searchPlaceholderText: "Procurar",
        classes: "myclass custom-class",
    };

    searchPerfil = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => term.length < 1 ? []
                : this.perfisFiltro.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1))
        )

    modalRef: NgbModalRef;
    isIE10 = false;


    perfis: any[] = [];
    perfisFiltro: any[] = [];

    constructor(
        private appService: AppService,
        private UsuarioService: UsuarioService,
        private PerfilService: PerfilService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public pessoaService: PessoaService,
        private selectListService: SelectListService
    ) {

        this.isIE10 = this.appService.isIE10;
    }

    public usuario = {
        nome: '',
        login: '',
        email: '',
        senha: '',
        confirmarSenha: '',
        pessoas: '',
        pessoaId: '',
        perfilId: '',
        perfil: '',
        usuarioPessoa: [],
        usuarioAmbiente: []
    };

    cadastrar() {

        if (this.ambientesSelecionados == null || this.ambientesSelecionados.length == 0) {
            this.toastrService.error('Selecione pelo menos um Ambiente de Acesso', 'Atenção', { timeOut: 5000 });
            return;
        }

        let pessoasSelecionadas = this.pessoasSelecionadas.filter(pessoa => pessoa.pessoaId != undefined);

        if (pessoasSelecionadas == null || pessoasSelecionadas.length == 0) {
            this.toastrService.error('Selecione uma pessoa', 'Atenção', { timeOut: 5000 });
            return;
        }

        if (!this.usuario.perfilId) {
            this.toastrService.error('Selecione um perfil', 'Atenção', { timeOut: 5000 });
            return;
        }

        this.usuario.usuarioPessoa = this.montaUsuarioPessoa(pessoasSelecionadas);
        this.usuario.usuarioAmbiente = this.montaUsuarioAmbiente(this.ambientesSelecionados);
        this.add(this.usuario);
    }

    add(usuario) {

        this.spinner.show();
        this.toastrService.clear();

        let userData = Object.assign({}, usuario);
        delete userData.perfil;

        this.UsuarioService.add(userData)
            .subscribe(res => {
                this.toastrService.success("Usuário cadastrado.", "Sucesso");
                this.spinner.hide();
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o usuário!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();

            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    montaUsuarioPessoa(pessoasSelecionadas) {

        var usuarioPessoa = [];
        pessoasSelecionadas.forEach(element => {

            var obj = {
                pessoaId: element.pessoaId,
                usuarioId: undefined,
            }
            usuarioPessoa.push(obj);
        });

        return usuarioPessoa;
    }

    montaUsuarioAmbiente(ambientesSelecionados) {
        var usuarioAmbiente = [];
        ambientesSelecionados.forEach(element => {
            var obj = {
                ambiente: element.id,
                usuarioId: undefined,
                id: null,
            }
            usuarioAmbiente.push(obj);
        });
        return usuarioAmbiente;
    }

    adicionarPessoa(): void {

        this.pessoasSelecionadas.push({});
    }

    removerPessoa(membroEquipeIndex): void {

        this.pessoasSelecionadas.forEach((element, index) => {

            if (index == membroEquipeIndex) {
                this.pessoasSelecionadas.splice(index, 1);
            }
        });
    }

    ngOnInit(): void {

        this.perfis = this.modalRef.componentInstance.perfis;
        this.perfis.forEach(perfil => {
            this.perfisFiltro.push(perfil.nome);
        });

        this.ambientesAcessoList = this.selectListService.getAmbiente();
        this.adicionarPessoa();
    }
}
