import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { CentroCustosService } from 'src/app/services/centro-custos.service';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';

@Component({
    selector: 'app-modal-atualiza-centro-custos',
    templateUrl: './modal-atualiza-centro-custos.component.html',
    providers: [CentroCustosService]
})

export class ModalAtualizaCentroCustosComponent implements OnInit {

    modalRef: NgbModalRef;
    public centroCusto: any;

    constructor(
        private appService: AppService,
        private centroCustosService: CentroCustosService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private dadosCargasService: DadosCargasService
    ) { }

    ngOnInit(): void {
        this.centroCusto = this.modalRef.componentInstance.centroCusto;
    }

    atualizar() {
        this.update(this.centroCusto);
    }

    update(centroCusto) {
        this.spinner.show();
        this.toastrService.clear();

        this.centroCustosService.update(centroCusto)
            .subscribe(res => {
                this.toastrService.success("Centro de Custo atualizado!.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar o centro de custo!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}