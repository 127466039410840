import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentoArquivoService } from 'src/app/services/documento-arquivo.service';
import { ProcessoCasoAtendimentoDetalhesService } from 'src/app/services/processo-caso-atendimento-detalhes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { AgGridComponent } from '../ag-grid/ag-grid.component';
import { ColDef } from 'ag-grid-community';
import { IconOrigemComponent } from './icon-origem/icon-origem.component';
import { TooltipGridComponent } from '../tooltip-grid/tooltip-grid.component';
import { OpcoesProcessoComponent } from '../opcoes-processo/opcoes-processo.component';
import { OptionsGridDocsComponent } from './options-grid-docs/options-grid-docs.component';

@Component({
    selector: 'app-listar-documentos',
    templateUrl: './listar-documentos.component.html',
    styleUrls: ['./listar-documentos.component.css']
})
export class ListarDocumentosComponent extends AgGridComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() raiz: string;

    public loadDocumentos: boolean = true;
    public loadFiltroAplicado: boolean = false;
    public sortBy: string = 'dataAtualizacao';
    public sortDesc: boolean = true;
    public perPage: number = 8;
    public currentPage: number = 1;
    public totalItems: number = 0;
    public textoFiltro: any;

    public documentos: any = [];

    columnDefs: ColDef[] = [];

    constructor(
        private documentoArquivoService: DocumentoArquivoService,
        private processoCasoAtendimentoDetalhesService: ProcessoCasoAtendimentoDetalhesService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public arquivoService: ArquivoService,
        public utilsService: UtilsService,
    ) {
        super();
    }
    getRowHeight() {
        return 800;
    }

    ngOnInit(): void {
        this.columnDefs = [
            {
                headerName: 'Origem',
                field: 'tipoDocumentoEvento',
                tooltipField: 'tipoDocumentoEvento',
                width: this.raiz === 'dash' ? 150 : 103,
                cellRenderer: (param) => {
                    const { tipoDocumentoEvento } = param.data;
                    if (tipoDocumentoEvento === 1) return 'Tarefa';
                    if (tipoDocumentoEvento === 2) return 'Evento';
                    if (tipoDocumentoEvento === 3) return 'Evento';
                    if (tipoDocumentoEvento === 4) return 'Audiência';
                    return 'Outros';
                }
            },
            {
                headerName: 'Documento',
                field: 'id',
                filterParams: this.filterParams,
                width: this.raiz === 'dash' ? 300 : 200,
                cellRendererSelector: params => {
                    return {
                        component: IconOrigemComponent,
                        params: {
                            anexos: params.data.anexos,
                            documento: params.data,
                            handleDownloadArquivo: this.handleDownloadArquivo.bind(this)
                        }
                    };
                }
            },
            {
                headerName: 'Descrição',
                field: 'descricao',
                filterParams: this.filterParams
            },
            {
                headerName: 'Registro/Cliente',
                field: 'tituloReferencia',
                filterParams: this.filterParams,
                width: this.raiz === 'dash' ? 250 : 200,
                cellRenderer: (param) => {
                    const { clientes, tituloReferencia } = param.data;
                    return `${tituloReferencia} <br/> ${clientes.map(c => c).toString().replace(',', '<br/>')} <br/>`;
                },
                autoHeight: true
            },
            {
                headerName: 'Responsável',
                field: 'responsavelNome',
                filterParams: this.filterParams
            },
            {
                headerName: 'Última Mov.',
                field: 'dataAtualizacao',
                type: "dateTimeColumn",
                filter: 'agDateColumnFilter',
                filterParams: this.filterDateParams,
                width: this.raiz === 'dash' ? 220 : 200,
            },
            {
                headerName: 'Ações',
                field: 'acoes',
                filter: false,
                sortable: false,
                width: 80,
                cellRendererSelector: params => {
                    return {
                        component: OptionsGridDocsComponent,
                        params: {
                            documento: params.data,

                        }
                    };
                }
            },
        ];
        this.defaultColDef = {
            ...this.defaultColDef,
            tooltipComponent: TooltipGridComponent,
        }
        if (this.referenciaId == undefined)
            this.referenciaId = this.activatedRoute.snapshot.params['referenciaId'];

        if (this.tipo == undefined)
            this.tipo = this.activatedRoute.snapshot.params['tipo'];

        this.carregarDocumentos();
    }
    carregarDocumentos() {
        this.loadDocumentos = true;

        if (this.referenciaId == undefined && this.tipo == undefined) {

            this.documentoArquivoService.getAll()
                .subscribe(res => {
                    if (res.success) {
                        this.loadDocumentos = false;

                        this.documentos = res.data;
                    }
                });
        } else {
            this.loadFiltroAplicado = false;
            this.processoCasoAtendimentoDetalhesService.getAllByRegistro(this.tipo, this.referenciaId)
                .subscribe(res => {
                    if (res.success) {
                        this.loadFiltroAplicado = true;
                        this.textoFiltro = `${res.data.tipo}: ${res.data.titulo}`;
                    }
                })

            this.documentoArquivoService.getAllByRegistro(this.tipo, this.referenciaId)
                .subscribe(res => {
                    if (res.success) {
                        this.loadDocumentos = false;

                        this.documentos = res.data;
                    }
                });
        }
    }

    removerFiltro() {
        this.textoFiltro = undefined;
        this.loadFiltroAplicado = false;
        this.referenciaId = undefined;
        this.tipo = undefined;
        this.carregarDocumentos();
    }

    setSort(key) {
        if (this.sortBy !== key) {
            this.sortBy = key;
            this.sortDesc = false;
        } else {
            this.sortDesc = !this.sortDesc;
        }

        this.currentPage = 1;
        this.filter();
    }

    adicionar() {
        if (this.tipo && this.referenciaId) {
            this.router.navigate(['/operacionais/documentos/documentos-cadastro/', this.tipo, this.referenciaId]);
        } else {
            this.router.navigate(['/operacionais/documentos/documentos-cadastro']);
        }
    }

    filter() {
    }

    handleDownloadArquivo(arquivo: any) {
        this.spinner.show();
        this.toastrService.clear();
        try {
            this.arquivoService.downloadArquivo(arquivo.arquivoId).subscribe(
                data => {
                    if (data.type == 3) {
                        this.spinner.hide();
                    }
                    this.utilsService.renderDownload(data, arquivo.arquivoNome as string);
                }
            );
        } catch {
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }
}
