<div ngbDropdown class="">
  <span
    ngbDropdownToggle
    type="button"
    id="reschedule"
    data-toggle="dropdown"
    class="fas fa-calendar-alt mr-2"
    aria-expanded="false"
  ></span>
  <input
    matInput
    (dateChange)="saveDate(RESCHEDULE_TYPE.SOMEDAY, $event)"
    [matDatepicker]="picker"
    class="inpt-calendar"
  />
  <div ngbDropdownMenu aria-labelledby="reschedule">
    <a
      ngbDropdownItem
      class="cursor-pointer"
      (click)="saveDate(RESCHEDULE_TYPE.TODAY, $event)"
      >Reagendar para hoje</a
    >
    <a
      ngbDropdownItem
      class="cursor-pointer"
      (click)="saveDate(RESCHEDULE_TYPE.TOMORROW, $event)"
      >Reagendar para amanhã</a
    >
    <ng-container>
      <mat-datepicker #picker></mat-datepicker>
      <a ngbDropdownItem class="cursor-pointer" (click)="picker.open()">
        Reagendar para algum dia</a
      >
    </ng-container>
    <a
      ngbDropdownItem
      class="cursor-pointer"
      (click)="saveDate(RESCHEDULE_TYPE.NEXT_MONDAY, $event)"
      >Reagendar para a próxima segunda</a
    >
  </div>
</div>
