import { BorderoLinhaResponse } from './../../../../models/operacionais/importacao-bordero/bordero-linha-response';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BorderoService } from 'src/app/services/bordero.service';
import { Bordero } from 'src/app/models/operacionais/importacao-bordero/bordero';
import { BorderoLinha } from 'src/app/models/operacionais/importacao-bordero/bordero-linha';
import { TipoStatus, TipoStatusLabel } from 'src/app/enums/tipo-status.enum';
import { SelectList } from 'src/app/models/select-list';

@Component({
    selector: 'app-modal-historico-importacao-linha',
    templateUrl: './modal-historico-importacao-linha.component.html',
    styleUrls: [
        '../../../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    ],
})

export class ModalHistoricoImportacaoLinhaComponent implements OnInit {
    // Filters
    filterMensagem = '';
    filterAba = '';
    filterStatus:TipoStatus = null;

    filterStatusList: SelectList[] = [];
    
    // Options
    sortDesc = true;
    perPage = 8;

    //Paginate
    currentPage = 1;
    totalItems = 0;

    bordero: Bordero;
    borderos: BorderoLinha[] = [];
    borderosFiltrados: BorderoLinha[] = [];

    modalRef: NgbModalRef;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        public modalService: ModalService,
        public borderoService: BorderoService
    ) {
        this.filterStatusList.push(new SelectList(null, "Todos"));
        this.filterStatusList.push(new SelectList(TipoStatus.BorderoLinhaSucesso.toString(), TipoStatusLabel.get(TipoStatus.BorderoLinhaSucesso)));
        this.filterStatusList.push(new SelectList(TipoStatus.BorderoLinhaErro.toString(), TipoStatusLabel.get(TipoStatus.BorderoLinhaErro)));
    }

    ngOnInit() {
        this.bordero = this.modalRef.componentInstance.bordero;

        this.buscarborderos();
    }

    get totalPages() {
        return Math.ceil(this.totalItems / this.perPage);
    }

    paginate(data) {
        const perPage = parseInt(String(this.perPage), 10);
        const offset = (this.currentPage - 1) * perPage;

        return data.slice(offset, offset + perPage);
    }

    update() {
        this.borderosFiltrados = this.filter();

        this.totalItems = this.borderosFiltrados.length;

        this.borderosFiltrados = this.paginate(this.borderosFiltrados);
    }

    filter() {
        var borderosFiltrados = this.borderos;

        if(this.filterMensagem != ''){
            borderosFiltrados = borderosFiltrados.filter(u => u.mensagem.toLowerCase().includes(this.filterMensagem.toLowerCase()));
        }
        if(this.filterAba != ''){
            borderosFiltrados = borderosFiltrados.filter(u => u.nomeAba.toLowerCase().includes(this.filterAba.toLowerCase()));
        }
        if(this.filterStatus != null){
            borderosFiltrados = borderosFiltrados.filter(u => u.status == this.filterStatus);
        }

        return borderosFiltrados;
    }

    filtrar(){
        const data = this.filter();
        this.totalItems = data.length;

        this.borderosFiltrados = this.paginate(data);
    }

    buscarborderos() {
        this.spinner.show();

        this.borderoService.getAllLinha(this.bordero)
            .subscribe((res: BorderoLinhaResponse) => {
                this.borderos = res.data;
                this.borderosFiltrados = res.data;

                this.spinner.hide();

                this.filtrar();
            }, error => {

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção');
                }
                else {
                    this.toastrService.error('Não foi possível carregar os dados!', 'Atenção', { timeOut: 10000 });
                }

                this.borderos = [];
                this.borderosFiltrados = [];

                this.filtrar();

                this.spinner.hide();
            });
    }

    public close() {
        this.modalRef.close();
    }

    public dismiss() {
        this.modalRef.dismiss();
    }
}
