<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3" *ngIf="raiz == undefined">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Documentos</span>
    </span>
</h4>
<hr *ngIf="raiz == undefined" />
<div class="mb-5" [ngClass]="{'container': raiz !== 'modal' && raiz !== 'dash'}">
    <div class="d-flex justify-content-center" *ngIf="loadDocumentos && raiz == undefined">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div *ngIf="!loadDocumentos">        
        <div class="mt-2 mb-2 d-flex justify-content-end" *ngIf="raiz == undefined">
            <button type="button" class="btn btn-primary" (click)="adicionar()">
                <span class="fas fa-plus-circle"></span>&nbsp;&nbsp;Cadastrar
            </button>
        </div>
        <div class="mt-2 mb-2" *ngIf="loadFiltroAplicado && (raiz !== 'modal' || raiz !== 'dash')">
            <div class="row">
                <div class="col-12"><label class="label-filtro">{{textoFiltro}} <span class="filtro-remove" (click)="removerFiltro()"><i class="fas fa-window-close"></i></span></label></div>
            </div>
        </div>  
        <app-ag-grid
            classGrid="h-grid"
            [rowData]="documentos"
            [columnDefs]="columnDefs"
            [tooltipShowDelay]="0"
            [tooltipHideDelay]="2000"
            [showToolbar]="true"
            [getRowHeight]="getRowHeight()"
        ></app-ag-grid>     
    </div>
</div>