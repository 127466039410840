<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span>{{'Contatos'}}</span>
</h4>
<hr />
<div class="card">
    <div class="card-header pb-0">
        <div class="d-flex align-items-center p-2" style="background: #ebebeb;">
            <div>
                <span class="material-symbols-outlined cursor-pointer mt-1" (click)="isShow = !isShow">{{ isShow ?
                    'expand_less' : 'expand_more'}}</span>
            </div>
            <div class="d-flex">
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === ''}" (click)="aoSelecionarLetraInicial('')">Todos</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'A'}" (click)="aoSelecionarLetraInicial('A')">A</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'B'}" (click)="aoSelecionarLetraInicial('B')">B</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'C'}" (click)="aoSelecionarLetraInicial('C')">C</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'D'}" (click)="aoSelecionarLetraInicial('D')">D</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'E'}" (click)="aoSelecionarLetraInicial('E')">E</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'F'}" (click)="aoSelecionarLetraInicial('F')">F</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'G'}" (click)="aoSelecionarLetraInicial('G')">G</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'H'}" (click)="aoSelecionarLetraInicial('H')">H</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'I'}" (click)="aoSelecionarLetraInicial('I')">I</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'J'}" (click)="aoSelecionarLetraInicial('J')">J</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'K'}" (click)="aoSelecionarLetraInicial('K')">K</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'L'}" (click)="aoSelecionarLetraInicial('L')">L</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'M'}" (click)="aoSelecionarLetraInicial('M')">M</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'N'}" (click)="aoSelecionarLetraInicial('N')">N</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'O'}" (click)="aoSelecionarLetraInicial('O')">O</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'P'}" (click)="aoSelecionarLetraInicial('P')">P</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'Q'}" (click)="aoSelecionarLetraInicial('Q')">Q</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'R'}" (click)="aoSelecionarLetraInicial('R')">R</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'S'}" (click)="aoSelecionarLetraInicial('S')">S</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'T'}" (click)="aoSelecionarLetraInicial('T')">T</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'U'}" (click)="aoSelecionarLetraInicial('U')">U</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'W'}" (click)="aoSelecionarLetraInicial('W')">W</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'X'}" (click)="aoSelecionarLetraInicial('X')">X</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'Y'}" (click)="aoSelecionarLetraInicial('Y')">Y</div>
                <div class="col-filter" [ngClass]="{'select': letraSelecionada === 'Z'}" (click)="aoSelecionarLetraInicial('Z')">Z</div>
            </div>

            <div *ngIf="isShow" class="p-5 filter-options">
                <div class="w-100">
                    <input type="text" class="form-control" [(ngModel)]="nome" placeholder="Encontre um contato">
                </div>
                <br />


                <label class="mb-2">Mostrar:</label> <br />
                <div class="d-flex justify-content-between">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="tipo">
                        <mat-radio-button class="mr-4" value="">Todos</mat-radio-button>
                        <mat-radio-button class="mr-4" value="Credor">Credor</mat-radio-button>
                        <mat-radio-button class="mr-4" value="Advogado">Advogado</mat-radio-button>
                        <mat-radio-button class="mr-4" value="Revisor">Revisor</mat-radio-button>
                        <mat-radio-button class="mr-4" value="Colaborador">Colaborador</mat-radio-button>
                    </mat-radio-group>

                    <div class="d-flex">
                        <button type="button" class="btn btn-md btn-padrao mr-4" (click)='obterListagemSimplificada()'>
                            Aplicar
                        </button>
                        <button type="button" class="btn btn-md btn-padrao back" (click)="isShow = false">
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive" *ngIf="contatos.length > 0">
            <table class="table table-striped table-bordered card-table">
                <thead>
                    <tr>
                        <th class="text-nowrap text-center align-middle">
                            Nome
                        </th>
                        <th class="text-nowrap text-center align-middle">
                            Departamento
                        </th>
                        <th class="text-nowrap text-center align-middle">
                            E-mail
                        </th>
                        <th class="text-center text-nowrap align-middle">
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tabela" *ngFor="let contato of contatos">
                        <td>
                            {{contato.nome}}
                        </td>
                        <td>
                            {{contato.tipo}}
                        </td>
                        <td>
                            {{contato.email}}
                        </td>
                        <td class="text-center text-nowrap align-middle">
                            <i class="fas fa-eye cursor-pointer"
                                (click)="showModalPessoa(contato.id)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Paginação -->
            <div class="card-body py-0">
                <div class="row">
                    <div class="col-sm text-sm-left text-center pt-3">
                        <span class="text-muted" *ngIf="totalItens">Páginas {{ paginaAtual }} de {{ totalPaginas
                            }}</span>
                    </div>
                    <div class="col-sm text-sm-left text-center pt-3">
                        <label>Itens por página:</label>
                        <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                            (change)="alterarPagina(1)">
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                            <option [ngValue]="500">500</option>
                        </select>
                    </div>
                    <div class="col-sm pt-6">
                        <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                            [(page)]="paginaAtual" [boundaryLinks]="true" (pageChange)="alterarPagina(paginaAtual)"
                            size="sm" class="d-flex justify-content-center justify-content-sm-end m-0">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
            <!-- / Paginação -->
        </div>
    </div>
</div>