import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashService } from 'src/app/services/dash.service';

@Component({
  selector: 'app-chart-ultimos-acessos-clientes',
  templateUrl: './chart-ultimos-acessos-clientes.component.html',
  styleUrls: ['./chart-ultimos-acessos-clientes.component.css']
})
export class ChartUltimosAcessosClientesComponent implements OnInit {

  load = false;
  usuario: any [] = [];

  constructor(
    private dashService: DashService,
  ) { }


  ngOnInit(): void {
    this.getChartUltimoAcessoDeUsuarioNoSistema();
  }


  getChartUltimoAcessoDeUsuarioNoSistema() {
    this.load = true;
    this.dashService.getDashUltimoAcessoDeUsuarioNoSistema().subscribe(x => {

      this.usuario = x.data;
      this.load = false;
    });
  }

}
