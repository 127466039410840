<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3" *ngIf="tipo != null">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos / </span> <span>Despesas</span>      
    </span>
    <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button type="button" class="btn btn-primary" id="dropdownCadastrar" (click)="voltar()" >
            <i class="fas fa-chevron-circle-left"></i> &nbsp;&nbsp;&nbsp;Voltar
        </button>        
    </div>
</h4>
<app-lista-despesa [tipo]="tipo" [referenciaId]="referenciaId"></app-lista-despesa>
<br />
<!-- <hr *ngIf="notasDeDebitos != undefined && notasDeDebitos.length" /> -->

<app-lista-nota-de-debito [tipo]="tipo" [referenciaId]="referenciaId"></app-lista-nota-de-debito>