import { Injectable } from "@angular/core";

@Injectable()
export class HonorariosService {
    public obterNome(tipoHonorario){
        switch(tipoHonorario){
            case '1': return "Sucumbência";
            default: return 'Não existe tipo'
        }
    }
}