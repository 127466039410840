<div class="row">
  <div class="col-11">
    <div [ngClass]="{ 'card pb-3 pr-3': filtroOn }">
      <div class="row mt-3 mr-0 ml-0">
        <div class="col-{{ col }} p-0 input-group box-busca">
          <button
            class="btn btn-outline-secondary btn-flag bg-light"
            type="button"
            (click)="filtroOn = !filtroOn"
            style="height: 2.86em"
          >
            <i class="fas fa-caret-down"></i>
          </button>
          <input
            type="text"
            class="form-control"
            [placeholder]="serachPlaceholder"
            style="border-color: #8897aa"
          />
          <ng-content select="[drops]"></ng-content>
          <ng-content select="[otherThings]"></ng-content>
        </div>
      </div>
      <div class="d-flex flex-column" *ngIf="filtroOn">
        <div class="row m-0 mt-3 mb-3" [formGroup]="formFilter">
          <div class="col-{{ form.col }}" *ngFor="let form of config">
            <label class="form-label">{{ form.label }}</label>
            <div class="input-group">
              <input
                *ngIf="form.isCurrency"
                [placeholder]="form.placeholder"
                currencyMask
                [formControlName]="form.controlName"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                class="form-control"
              />

              <input
                *ngIf="form.isText"
                [placeholder]="form.placeholder"
                [formControlName]="form.controlName"
                class="form-control"
              />

              <ng-select
                *ngIf="form.isSelect"
                [items]="form.itemsSelect"
                [formControlName]="form.controlName"
                [placeholder]="form.placeholder"
                bindLabel="nome"
                bindValue="id"
              >
              </ng-select>
            </div>
          </div>
        </div>

        <div class="mt-auto">
          <button
            type="button"
            class="btn btn-primary float-right"
            (click)="applyFormFilter()"
          >
            Aplicar
          </button>
          <button
            type="button"
            class="btn btn-outline-primary float-right mr-2"
            (click)="filtroOn = false"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-1 mt-4">
    <div class="d-flex justify-content-end ml-3" *ngIf="hasPrintAndExport">
      <i
        class="fas fa-print cursor-pointer mr-2"
        title="Imprimir"
        tooltip-placement="bottom"
        tooltip-append-to-body="true"
      ></i>

      <i
        class="fas fa-file-excel cursor-pointer mr-2"
        title="Exportar Lançamentos"
        tooltip-placement="bottom"
        tooltip-append-to-body="true"
      ></i>

      <span
        style="margin-top: -4px; font-size: 21px"
        *ngIf="hasUpdate"
        class="material-symbols-outlined cursor-pointer"
        [title]="'Atualizar' + title"
        tooltip-placement="bottom"
        tooltip-append-to-body="true"
        >cached
      </span>
    </div>
  </div>
</div>

<!-- SELECTED FILTERS -->
<div [ngClass]="{ marginFilterOn: filtroOn }">
  <ng-container *ngFor="let filter of filtersApplied; let index = i">
    <label *ngIf="filter" class="mt-3 label-filtro"
      >{{ filter.label }}: {{ filter.value | uppercase }}
      <span class="filtro-remove" (click)="removeFilterApplied(filter.id)"
        ><i class="fas fa-window-close cursor-pointer"></i></span
    ></label>
  </ng-container>
</div>
<!--  -->
