<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos /</span><span *ngIf="!isEdit"> Adicionar Atendimento</span><span *ngIf="isEdit"> Editar Atendimento</span>        
    </span>
</h4>
<hr />
<div class="container mb-5">
    <form ngNoForm>
        <div class="form-row">
            <div class="form-group col" *ngIf="!isEdit">
                <label class="form-label">Processo, caso, atendimento ou recurso/desdobramento</label>
                <ng-select 
                    [items]="atendimentosCasosEProcessos" 
                    [(ngModel)]="atendimentosCasosEProcessosSelecionado" 
                    [ngModelOptions]="{standalone: true}" 
                    [loading]="loadAtendimentosCasosEProcessos" 
                    [searchFn]="aplicarFiltroAtendimentoCasoProcesso"
                    [virtualScroll]="true"
                    bindLabel="titulo" 
                    bindValue="registroUnicoId"
                    (change)="buscarPessoaPeloAtendimentoSelecionado()" >
                    <ng-template ng-option-tmp let-item="item">
                        <div class="line"><b>{{item.titulo}}</b></div>
                        <div class="line">Tipo: {{item.tipo}}</div>
                        <div class="line">Responsável: {{item.responsavelNome}}</div>
                        <div class="line" *ngIf="item.tipoId != 3">Pasta: {{item.pasta}}</div>
                        <div class="line" *ngIf="item.tipoId == 4">Número processo: {{item.numeroProcesso}}</div>
                        <div class="line" *ngIf="item.tipoId == 4">Número do incidente: {{item.recursoDesdobramento}}</div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <label class="form-label d-flex align-items-center">
                    Cliente<span class="required-field ml-1">*</span>
                    <button type="button" class="btn btn-primary btn-xs ml-3" (click)="adicionarCliente()"><i class="fas fa-plus"></i></button>
                </label>
            </div>
        </div>
        <div class="form-row" *ngFor="let cliente of clientes; let i = index">
            <div class="form-group col">                
                <pessoa-dropdown #clienteDropdown [(pessoaId)]="cliente.pessoaId"></pessoa-dropdown>
            </div>            
            <div class="form-group col-1">
                <button type="button" class="btn btn-danger" (click)="removerCliente(i)"><i class="fas fa-trash"></i></button>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Assunto<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" maxlength="250" placeholder="Digite o assunto"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="atendimento.assunto">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Tags</label>
                <angular2-multiselect [data]="tagsdata" [(ngModel)]="tags" [settings]="tagDropdownSettings"
                    [ngModelOptions]="{standalone: true}">
                    <c-item>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-item>
                    <c-badge>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-badge>
                </angular2-multiselect>
            </div>
        </div>
        <div class="form-row" *ngIf="atendimentoDetalhes != undefined">
            <div class="form-group col">
                <label class="form-label">Processo, caso ou atendimento</label>
                <input type="text" class="form-control" [(ngModel)]="atendimentoDetalhes.titulo" disabled>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Responsável pelo Atendimento<span class="required-field ml-1">*</span></label>
                <pessoa-dropdown #responsavelDropdown [(pessoaId)]="atendimento.responsavelId"></pessoa-dropdown>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">1º Registro do atendimento<span class="required-field ml-1">*</span></label>
                <textarea class="form-control" rows="2"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="atendimento.primeiroRegistro" maxlength="1000"></textarea>
            </div>
        </div>
        <hr />
        <div class="d-flex justify-content-end">
            <button type="button" (click)="cancel()" class="btn btn-light mr-3">Cancelar</button>
            <button type="button" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
        </div>
    </form>
</div>