import { Component, OnInit, ViewChild } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { ForosService } from 'src/app/services/foros.service';
import { VarasService } from 'src/app/services/varas.service';
import { AcaoService } from 'src/app/services/acao.service';
import { TagService } from 'src/app/services/tag.service';
import { InstanciasService } from 'src/app/services/instancias.service';
import { QualificacaoService } from 'src/app/services/qualificacao.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { NgbDateParserFormatter, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { TipoPossibilidadeProcessoJudicialService } from 'src/app/services/tipo-possibilidade-processo-judicial.service';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { TipoProcessoEletronicoService } from 'src/app/services/tipo-processo-eletronico.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { DivisaoDeOrgaoService } from 'src/app/services/divisao-de-orgao.service';
import { AreaService } from 'src/app/services/area.service';
import { OrgaosService } from 'src/app/services/orgaos.service';
import { ComarcaService } from 'src/app/services/comarca.service';
import { UfService } from 'src/app/services/uf.service';
import { SituacaoCasoProcessoJudicialService } from 'src/app/services/situacao-caso-processo-judicial.service';
import { ModalCodigoComponent } from './modal-codigo/modal-codigo.component';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { CompetenciaService } from 'src/app/services/competencia.service';
import { ProcessosJudiciaisFormularioDinamicoComponent } from './processos-judiciais-formulario-dinamico/processos-judiciais-formulario-dinamico.component';
import { TipoCampoFormulario } from 'src/app/enums/formulario-dinamico/tipo-campo-formulario-dinamico.enum';

@Component({
    selector: 'app-processos-judiciais-cadastrar',
    templateUrl: './processos-judiciais-cadastrar.component.html',
    styleUrls: ['./processos-judiciais-cadastrar.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class ProcessosJudiciaisCadastrarComponent implements OnInit {
    @ViewChild('formulariosDinamicos') formulariosDinamicos!: ProcessosJudiciaisFormularioDinamicoComponent;

    public id: string;
    public atendimentoId: string;
    public isAddMode: boolean = false;

    public processo: any;
    public mascaraInteiro: Array<string | RegExp>;
    public mascaraData: Array<string | RegExp>;
    public mascaraDataAutoCorrected: Array<string | RegExp>;
    public mascaraNumero: Array<string | RegExp>;

    public loadVaras: boolean = false;
    public loadForos: boolean = false;
    public loadAcoes: boolean = false;
    public loadInstancias: boolean = false;
    public loadCompetencias: boolean = false;
    public loadTags: boolean = false;
    public loadQualificacoes: boolean = false;
    public loadTipoPossibilidadeProcessoJudicial: boolean = false;
    public dataCadastro: any;
    public distribuidoEm: any;
    public dataEntrada: any;
    public varas: any = [];
    public foros: any = [];
    public acoes: any = [];
    public instancias: any = [];
    public competencias: any = [];
    public tags: any = [];
    public qualificacoes: any = [];
    public divisoes: any =[];
    public tiposPossibilidadesProcessosJudiciais: any = [];
    public tiposProcessosEletronicos: any = [];
    public orgaos: any = [];
    public comarcas: any = [];
    public ufs: any = [];
    public areas: any = [];
    public fases: any = [];
    public situacoesProcesso: any = [];

    public equipeId: number;

    public tagDropdownSettings = {
        singleSelection: false,
        primaryKey: "tagId",
        text: "",
        noDataLabel: "",
        enableSearchFilter: true,
        disabled: false,
        selectAllText: "Selecione Todas",
        unSelectAllText: "Deselecionar Todas",
        searchPlaceholderText: "Procurar",
        classes: 'multiselect-tags'
    };

    public raiz: string;

    public pessoas: any = [];

    public ehProcessoEletronico: any =[
        {
            id: 1, nome: 'Físico',
        },
        {
            id: 2, nome: 'Eletrônico',
        }
    ];
    
    public ehProcessoEletronicoSelecionado: number = 1;

    public resultConfirmDialog$ = new Subject<ResultadoModal>();

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private datePipe: DatePipe,
        public processosJudiciaisService: ProcessosJudiciaisService,
        public forosService: ForosService,
        public varasService: VarasService,
        public acaoService: AcaoService,
        public instanciasService: InstanciasService,
        public competenciasService: CompetenciaService,
        public qualificacaoService: QualificacaoService,
        public tagService: TagService,
        public tipoPossibilidadeProcessoJudicialService: TipoPossibilidadeProcessoJudicialService,
        public atendimentoService: AtendimentoService,
        public tipoProcessoEletronicoService: TipoProcessoEletronicoService,
        public pessoaService: PessoaService,
        public divisaoDeOrgaoService: DivisaoDeOrgaoService,
        public areaService: AreaService,
        public orgaoServie: OrgaosService,
        public comarcaService: ComarcaService,
        public ufService: UfService,
        private situacaoCasoProcessoJudicialService: SituacaoCasoProcessoJudicialService,
        private ngbModal: NgbModal,
        private activatedRoute: ActivatedRoute,
    ) {
        this.mascaraInteiro = createNumberMask({
            prefix: '',
            thousandsSeparatorSymbol: '',
        });
        this.mascaraData = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        this.mascaraDataAutoCorrected = createAutoCorrectedDatePipe('dd/mm/yyyy', { minYear: 1900, maxYear: 2100 });
        this.mascaraNumero = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
    }
    
    ngOnInit(): void {
        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard/carteira-de-processos'
        } else {
            this.raiz = 'operacionais/processos-judiciais'
        }

        this.id = this.route.snapshot.params['id'];

        this.atendimentoId = this.route.snapshot.params['atendimentoId'];

        this.isAddMode = !this.id;        

        this.processo = {
            tipoAcesso: 'Publico',
            clientes: [{principal:true}],
            envolvidos: [{}],
            membrosEquipe: [],
            processoEletronico: false,
            caso:false,
            loading: true,
            outrosNumeros: [{}]
        };        

        if (!this.isAddMode) {
                        
            this.processosJudiciaisService.getById(this.id).subscribe(processo => {
                this.processo = processo.data;

                if (this.processo.distribuidoEm != null && this.processo.distribuidoEm.length) {
                    this.distribuidoEm = this.datePipe.transform(this.processo.distribuidoEm, 'dd/MM/yyyy');
                }
                
                if (this.processo.dataCadastro != null && this.processo.dataCadastro.length) {
                    this.dataCadastro = this.datePipe.transform(this.processo.dataCadastro, 'dd/MM/yyyy');
                }

                if (this.processo.dataEntrada != null && this.processo.dataEntrada.length) {
                    this.dataEntrada = this.datePipe.transform(this.processo.dataEntrada, 'dd/MM/yyyy');
                }

                this.processo.loading = false;

                // Não sei o pq isso apareceu aqui (Thiago)
                // delete this.processo.dataAtualizacao;
                // delete this.processo.acaoNome;
                // delete this.processo.foroNome;
                // delete this.processo.varaNome;
                // delete this.processo.instanciaNome;
                // delete this.processo.responsavelNome;

                this.ehProcessoEletronicoSelecionado = !this.processo.processoEletronico ? 1 : 2;

                if (!this.processo.clientes.length) {
                    this.processo.clientes = [{principal:true}];
                }
                if (!this.processo.envolvidos.length) {
                    this.processo.envolvidos = [{}];
                }
            });
        }else{
            if(this.atendimentoId != undefined && this.atendimentoId !=null){
                this.processo.atendimentoId = this.atendimentoId;
            }

            this.activatedRoute.data.subscribe(res=>{
                this.processo.caso = res.caso;
            });

            this.dataCadastro = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
            this.processo.loading = false;
        }

        this.buscarAcoes();
        this.buscarForos();
        this.buscarInstancias();
        this.buscarCompetencias();
        this.buscarVaras();
        this.buscarTags();
        this.buscarQualificacoes();
        this.buscarTipoPossibilidadeProcessoJudicial();
        this.buscarTipoProcessoEletronico();
        this.buscarDivisaoDeOrgao();
        this.buscarOrgao();
        this.buscarComarcas();
        this.buscarUfs();
        this.buscarAreas();
        this.buscarTipoFaseSituacaoProcesso();

        if (this.atendimentoId != undefined) {
            this.buscarAtendimento();
        }
    }

    buscarTipoFaseSituacaoProcesso(){
        this.situacaoCasoProcessoJudicialService.getAllFase()
            .subscribe(res=>{
                this.fases = res.data;

                if (!this.isAddMode) {
                    this.situacaoCasoProcessoJudicialService.getAll()
                    .subscribe(res=>{
                        this.situacoesProcesso = res.data;
                        this.changeFase();
                    })
                }
            })
    }

    changeFase(){
        if(this.processo.faseProcesso != undefined && this.processo.faseProcesso != null)
        {
            this.situacaoCasoProcessoJudicialService.obterTipoProcessoPorFase(this.processo.faseProcesso)
                .subscribe(res=>{
                    this.situacoesProcesso = res.data;
                })
        }
    }

    buscarAreas(){
        this.areaService.getAll()
            .subscribe(res=>{
                this.areas = res.data;
            })
    }

    buscarUfs(){
        this.ufService.getAll()
            .subscribe(res=>{
                this.ufs = res.data;
            })
    }

    buscarComarcas(){
        this.comarcaService.getAll()
            .subscribe(res=>{                
                this.comarcas = res.data;                
            });
    }

    buscarOrgao(){
        this.orgaoServie.getAll()
            .subscribe(res=>{                
                this.orgaos = res.data;                
            });
    }

    buscarDivisaoDeOrgao(){
        this.divisaoDeOrgaoService.getAll()
            .subscribe(res=>{                
                this.divisoes = res.data;                
            });
    }

    buscarTipoPossibilidadeProcessoJudicial() {
        this.loadTipoPossibilidadeProcessoJudicial = true;
        this.tipoPossibilidadeProcessoJudicialService.getAllAsync().then(x => {
            this.loadTipoPossibilidadeProcessoJudicial = false;
            this.tiposPossibilidadesProcessosJudiciais = x.data;
        });
    }

    buscarVaras() {
        this.loadVaras = true;
        this.varasService.getAllAsync().then(x => {
            this.loadVaras = false;
            this.varas = x.data;
        });
    }

    buscarForos() {
        this.loadForos = true;
        this.forosService.getAllAsync().then(x => {
            this.loadForos = false;
            this.foros = x.data;
        });
    }

    buscarAcoes() {
        this.loadAcoes = true;
        this.acaoService.getAllAsync().then(x => {
            this.loadAcoes = false;
            this.acoes = x.data;
        });
    }

    buscarInstancias() {
        this.loadInstancias = true;
        this.instanciasService.getAllAsync().then(x => {
            this.loadInstancias = false;
            this.instancias = x.data;
        });
    }
    buscarCompetencias() {
        this.loadCompetencias = true;
        this.competenciasService.listar().subscribe(x => {
            this.loadCompetencias = false;
            this.competencias = x.data;
        },error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível listar competências', 'Atenção', { timeOut: 10000 });
            }
        });
    }

    buscarTipoProcessoEletronico(){
        this.tipoProcessoEletronicoService.getAll()
            .subscribe(res=>{
                this.tiposProcessosEletronicos = res.data;
            })
    }

    buscarTags() {
        this.loadTags = true;
        this.tagService.getAllAsync().then(x => {
            this.loadTags = false;
            x.data.forEach((element, index) => {
                this.tags.push({
                    tagId: element.id,
                    tagNome: element.nome,
                    tagCor: element.cor,
                });
            });
        });
    }

    buscarQualificacoes() {
        this.loadQualificacoes = true;
        this.qualificacaoService.getAllAsync().then(x => {
            this.loadQualificacoes = false;
            this.qualificacoes = x.data;
        });
    }

    adicionarCliente() {
        this.processo.clientes.push({});
    }

    adicionarNumero() {
        this.processo.outrosNumeros.push({});
    }

    adicionarEnvolvido() {
        this.processo.envolvidos.push({});
    }

    removerMembroEquipe(membroEquipeIndex) {
        this.processo.membrosEquipe.forEach((element, index) => {
            if (index == membroEquipeIndex) {
                this.processo.membrosEquipe.splice(index, 1);
            }
        });
    }

    removerCliente(clienteIndex, cliente) {
        this.processo.clientes.forEach((element, index) => {
            if (index == clienteIndex) {
                if(this.isAddMode){
                    this.processo.clientes.splice(index, 1);
                }else{
                    this.swalWithBootstrapButtons.fire({
                        title: 'Deseja realmente remover o cliente do processo?',                        
                        confirmButtonColor: '#FFD950 !important;',
                        showCloseButton: true,
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Ok',
                        cancelButtonText: 'Cancelar'
                    }).then(
                        result=>{                                    
                            if(result.value != undefined){
                                this.spinner.show();
                                
                                this.processosJudiciaisService.removerCliente(this.id, cliente.id)
                                    .subscribe(res=>{
                                        this.processo.clientes.splice(index, 1);
                                        this.spinner.hide();
                                    }, error => {
                                        if (error && error.errors) {
                                            this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                                        }
                                        else {
                                            this.toastrService.error('Não foi possível remover o cliente!', 'Atenção', { timeOut: 10000 });
                                        }
                                        this.spinner.hide();
                                    });
                            }
                        },err=>{
                            console.log('err'+err);
                        }
                    )
                }
            }
        });
    }

    removerNumero(numeroIndex: number) {
        if (numeroIndex >= 0 && numeroIndex < this.processo.outrosNumeros.length) {
            this.processo.outrosNumeros.splice(numeroIndex, 1);
        }
    }

    removerEnvolvido(envolvidoIndex, envolvido) {       
        this.processo.envolvidos.forEach((element, index) => {
            if (index == envolvidoIndex) {
                if(this.isAddMode){
                    this.processo.envolvidos.splice(index, 1);
                }else{
                    this.swalWithBootstrapButtons.fire({
                        title: 'Deseja realmente remover o envolvido do processo?',                        
                        confirmButtonColor: '#FFD950 !important;',
                        showCloseButton: true,
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Ok',
                        cancelButtonText: 'Cancelar'
                    }).then(
                        result=>{                                    
                            if(result.value != undefined){
                                this.spinner.show();
                                
                                this.processosJudiciaisService.removerEnvolvido(this.id, envolvido.id)
                                    .subscribe(res=>{
                                        this.processo.envolvidos.splice(index, 1);
                                        this.spinner.hide();
                                    }, error => {
                                        if (error && error.errors) {
                                            this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                                        }
                                        else {
                                            this.toastrService.error('Não foi possível remover o envolvido!', 'Atenção', { timeOut: 10000 });
                                        }
                                        this.spinner.hide();
                                    });
                            }
                        },err=>{
                            console.log('err'+err);
                        }
                    )
                }
            }
        });

        this.preencherTitulo();
    }

    onSubmit() {
        if(!this.validarPreenchimentoFormulariosDinamicos()) return;

        this.processo.distribuidoEm = null;

        if (typeof (this.dataCadastro) != "undefined" && this.dataCadastro != null && this.dataCadastro.length) {
            let date = this.dataCadastro.split("/");
            this.processo.dataCadastro = new Date(date[2], date[1] - 1, date[0]);
        }

        if (typeof (this.distribuidoEm) != "undefined" && this.distribuidoEm != null && this.distribuidoEm.length) {
            let date = this.distribuidoEm.split("/");
            this.processo.distribuidoEm = new Date(date[2], date[1] - 1, date[0]);
        }

        if (typeof (this.dataEntrada) != "undefined" && this.dataEntrada != null && this.dataEntrada.length) {
            let date = this.dataEntrada.split("/");
            this.processo.dataEntrada = new Date(date[2], date[1] - 1, date[0]);
        }

        this.processo.envolvidos.forEach((element, index) => {
            if (typeof (element.pessoaId) == "undefined" || !element.pessoaId.length) {
                this.processo.envolvidos.splice(index, 1);
            }
        });

        this.processo.membrosEquipe.forEach((element, index) => {
            if (typeof (element.pessoaId) == "undefined" || !element.pessoaId.length) {
                this.processo.membrosEquipe.splice(index, 1);
            }
        });

        this.processo.outrosNumeros = this.processo.outrosNumeros.filter(element => element.numero && element.numero.length);

        if (this.processo.tipoAcesso != "Equipe") {
            this.processo.membrosEquipe = [];
        }
        
        if(this.processo.honorariosInitialFee && this.processo.honorariosInitialFee.length){
            this.processo.honorariosInitialFee.forEach(element => {
                if(element.inicial == ""){
                    element.inicial = 0;
                }

                if(element.final == ""){
                    element.final = 0;
                }

                if(element.percentual == ""){
                    element.percentual = 0;
                }

                if(element.valor == ""){
                    element.valor = 0;
                }
            });
        }

        if(this.processo.honorariosSuccessFee && this.processo.honorariosSuccessFee.length){
            this.processo.honorariosSuccessFee.forEach(element => {
                if(element.inicial == ""){
                    element.inicial = 0;
                }

                if(element.final == ""){
                    element.final = 0;
                }

                if(element.percentual == ""){
                    element.percentual = 0;
                }

                if(element.valor == ""){
                    element.valor = 0;
                }
            });
        }


        if (this.isAddMode) {
            this.spinner.show();
            this.toastrService.clear();            
            this.processosJudiciaisService.add(this.processo)
                .subscribe(res => {
                    // this.router.navigate([this.raiz]);
                    this.location.back();
                    this.toastrService.success("Histórico Manual cadastrado.", "Sucesso");
                    this.spinner.hide();
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar o Histórico Manual!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        } else {
            this.spinner.show();
            this.toastrService.clear();

            this.processosJudiciaisService.update(this.processo)
                .subscribe(res => {
                    // this.router.navigate([this.raiz]);
                    this.location.back();
                    this.toastrService.success("Processo Judicial editado.", "Sucesso");
                    this.spinner.hide();
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível editar o Processo Judicial!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        }
    }

    validarPreenchimentoFormulariosDinamicos(): Boolean {
        
        if(!this.formulariosDinamicos || !this.formulariosDinamicos.formularios) return true;

        const formularioDinamico = [...this.formulariosDinamicos.formularios];

        let validado = true;

        for (const form of formularioDinamico) {
            for(const campo of form.camposFormulario) {
                if(campo.obrigatorio && !campo.valor) {
                    this.toastrService.error(`O campo ${campo.nomeCampo} do formulário dinâmico ${form.nomeFormulario} é obrigatório.`, 'Atenção', { timeOut: 10000 });
                    validado = false;
                    break;
                }

                if(campo.tipo == TipoCampoFormulario.Data && typeof(campo.valor) != "undefined" && campo.valor)
                    campo.valor = `${campo.valor?.year}-${campo.valor?.month}-${campo.valor?.day}`;

                if(campo.tipo == TipoCampoFormulario.MultiSelect && typeof(campo.valor) != "undefined" && campo.valor)
                    campo.valor = campo.valor?.join(',');
            }

            if(!validado)
                break;
        }

        this.processo.formulariosDinamicos = formularioDinamico;
        return validado;
    }

    cancel() {
        this.location.back();
    }    

    buscarAtendimento(){
        this.atendimentoService.getById(this.atendimentoId)
            .subscribe(res=>{
                this.processo.clientes = [];
                res.data.clientesId.forEach(c=>{                    
                    this.processo.clientes.push({pessoaId:c});

                    this.pessoas.push({pessoaId:c});
                });

                this.processo.titulo = res.data.titulo;

                this.processo.responsavelId = res.data.responsavelId;
            })
    }

    changeProcessoEletronico($event){
        
        if(this.ehProcessoEletronicoSelecionado == 1){
            this.processo.tipoProcessoEletronicoId = null;
            this.processo.processoEletronico = false;
        }else{
            this.processo.processoEletronico = true;
        }
    }

    onCheckboxClienteChange(index:number){        
        let i=0;
        this.processo.clientes.forEach(element => {
            if(i !== index){                
                element.principal = false;
            }
            i++;
        });

        this.preencherTitulo();
        this.preencherPasta();
    }

    onPessoaChange(pessoa:any){      

        if(this.pessoas != undefined && pessoa !== undefined && this.pessoas.filter(x=>x.id == pessoa.id).length == 0){
            this.pessoas.push(pessoa);
        }

        this.preencherTitulo();
        this.preencherPasta();
    }

    private clienteSelecionado: any;

    preencherPasta(){
        
        //Desativado temporariamente
        // let clientePrincipal = this.processo.clientes.filter(x=>x.principal); 

        // if(clientePrincipal != undefined && this.clienteSelecionado != clientePrincipal[0].pessoaId){
        //     this.clienteSelecionado = clientePrincipal[0].pessoaId;

        //     this.pessoaService.obterCodigoProcesso(this.clienteSelecionado)
        //         .subscribe(res=>{
        //             if(res.data == null){
        //                 this.modalPreencherCodigoPessoa(this.clienteSelecionado);
        //             }else{
        //                 this.processo.pasta = res.data;                    
        //             }                
        //         });
        // }
    }

    modalPreencherCodigoPessoa(pessoaId: any) {
        const modalRef = this.ngbModal.open(ModalCodigoComponent, {
            size: 'sm',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'processo-judiciais-cadastrar';
        modalRef.componentInstance.pessoaId = pessoaId;


        modalRef.result.then(result => {            
            this.resultConfirmDialog$.next(result);
            this.clienteSelecionado = undefined;
            this.preencherPasta();
        }).catch(error => { });
    }

    preencherTitulo(){

        this.processo.titulo = "";
        let clientePrincipal = this.processo.clientes.find(x=>x.principal);
        let quantidadeEnvolvidos = this.processo.envolvidos.length;

        if(clientePrincipal){
            let cliente = this.pessoas.find(x=> x.id == clientePrincipal.pessoaId);

             if(cliente){
                 this.processo.titulo = cliente.nickname + ' X ';
             }
        }

        if(quantidadeEnvolvidos == 0)
            return;

        let primeiroEnvolvido = this.pessoas.find(pessoa=> pessoa.id == this.processo.envolvidos[0].pessoaId);

        if(!primeiroEnvolvido)
            return;

        if (quantidadeEnvolvidos == 1){
            this.processo.titulo += (primeiroEnvolvido.nickname);
            return;
        }

        this.processo.titulo += (primeiroEnvolvido.nickname + " | Outros");        
    }

    onChangeEquipe(equipe) {

        if(!equipe)
            return;
        
        this.processo.membrosEquipe = [];

        equipe.equipePessoas.forEach(pessoa => {

            let pessoaId = pessoa.pessoaId;

            //let membroJaAdicionado = this.processo.membrosEquipe.find(membro => membro.pessoaId == pessoa.pessoaId);
            
            //if(!membroJaAdicionado)
            this.processo.membrosEquipe.push({pessoaId});
        });
    }

    numeroChanged(numero:any ){
        if(numero != undefined && numero != ""){
            this.processosJudiciaisService.obterIdProcessoPorNumero(numero).subscribe(res=>{
                debugger;
                if(res.data){
                    this.swalWithBootstrapButtons.fire({
                        title: 'Foi localizado um processo com o mesmo número, deseja ver?',                        
                        confirmButtonColor: '#FFD950 !important;',
                        showCloseButton: true,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Ver o processo',
                        cancelButtonText: 'Cancelar'
                    }).then(
                        result=>{                                    
                            if(result.value != undefined){
                                window.open(location.origin+"/"+this.raiz+"/detalhes/"+res.data, "_blank");
                            }else{
                                console.log('cancelar');
                            }
                        },err=>{
                            console.log('err'+err);
                        }
                    )
                }
            });
        }
    }
}
