<div style="min-height: 500px;display: flex;flex-direction: column;justify-content: center;" class="text-center mt-5"
    *ngIf="loadingProcesso">
    <div class="fa-3x">
        <i class="fas fa-spinner fa-spin"></i>
    </div>
</div>
<div id="modal" *ngIf="!loadingProcesso">
    <!-- Modal template -->
    <div class="modal-header">
        <h5 class="modal-title">
            Processo #{{ processo.idExterno }}
        </h5>

        <div class="pr-3">
            <button *ngIf="idExternoProcessoAcordo != null" type="button" class="btn btn-success"
                [disabled]="options.readonly && !this.isCredor"
                (click)="abrirModalProcesso(idExternoProcessoAcordo.id)"><i class="fa fa-arrow-circle-left pr-2"></i>
                Abrir Processo proveniente de Acordo #{{idExternoProcessoAcordo.idExterno}}</button>

            <button *ngIf="idExternoProcessoOriginal != null" type="button" class="btn btn-primary"
                [disabled]="options.readonly && !this.isCredor"
                (click)="abrirModalProcesso(idExternoProcessoOriginal.id)">Abrir Processo Original
                #{{idExternoProcessoOriginal.idExterno}} <i class="fa fa-arrow-circle-right pl-2"></i></button>
        </div>

        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>

    <div class="modal-body pt-0">

        <div class="form-row col-md-12 mt-2 pr-1 pl-2">
            <div class="form-group col ">
                <label class="form-label">Credor</label>
                <p>
                    {{processo.credor}} <span class="fas fa-info-circle cursor-pointer-padrao" *ngIf="!options.readonly"
                        (click)="detalhePessoa(processo.credorId)" placement="top" ngbTooltip="Detalhes"></span>
                </p>
            </div>

            <div class="form-group col ">
                <label class="form-label">Devedor</label>
                <p>
                    {{processo.devedor}} <span class="fas fa-info-circle cursor-pointer-padrao"
                        *ngIf="!options.readonly" (click)="detalhePessoa(processo.devedorId)" placement="top"
                        ngbTooltip="Detalhes"></span>
                </p>
            </div>

            <div class="form-group col ">
                <label class="form-label">Valor original NF</label>
                <input type="text" class="form-control" placeholder="" value="{{ processo.valor | currency: 'BRL'}}"
                    disabled>
            </div>

            <div class="form-group col ">
                <label class="form-label">Saldo</label>
                <input type="text" class="form-control" placeholder=""
                    value="{{ processo.valorEmAberto | currency: 'BRL'}}" disabled>
            </div>
        </div>

        <div class="nav-tabs-top nav-responsive-sm">
            <ul ngbNav #productTabs="ngbNav" class="nav-tabs">


                <li ngbNavItem="tabCapaDoProcesso">
                    <a ngbNavLink (click)="selecionarAba('capa-do-processo')">Capa do Processo</a>
                    <ng-template ngbNavContent>

                        <app-capa-processo [processoId]='processo.id'></app-capa-processo>

                        <br>
                    </ng-template>
                </li>
                <!--
            <li ngbNavItem="tabParcelamento">
              <a ngbNavLink  (click)="selecionarAba('parcelamento')">Parcelamento</a>
              <ng-template ngbNavContent>

                <app-parcelamento [processoId]='processo.id'></app-parcelamento>

            <br>
              </ng-template>
          </li>
  -->

                <li ngbNavItem="tabInformacoesAdicionais">
                    <a ngbNavLink (click)="selecionarAba('informacao-adicional')">Informações Adicionais</a>
                    <ng-template ngbNavContent>
                        <!-- <div class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">



                    </div> -->

                        <div class="col-md-12 display-flex p-2">
                            <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Código do Processo</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{processo.idExterno}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Código Externo Devedor</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{processo ? (processo.codigoExternoDevedor ?processo.codigoExternoDevedor
                                            :""):""}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Data de Entrada</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{processo.entrada | date:"dd/MM/yyyy HH:mm"}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Total de Dividas</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{qtdDividasOriginais}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Total de Juros</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{sumarioJuros | currency: 'BRL'}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Total de Custas</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{sumarioCustas | currency: 'BRL'}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Atualização de Custas</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{sumarioAtualizacaoCustas | currency: 'BRL'}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Comissao do Credor</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{0| currency: 'BRL'}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Juros por Mês</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>0%</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Valor da Multa</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{sumarioMulta | currency: 'BRL'}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Total a pagar</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{0| currency: 'BRL'}}</span>
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Situacao</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{ processo.situacaoProcesso ? processo.situacaoProcesso.nome : ''}} {{
                                            processo.situacaoProcesso != null && processo.situacaoProcesso.situacaoPai
                                            != null ? '/' + processo.situacaoProcesso.situacaoPai.nome : ''}}</span>
                                    </div>
                                </div>


                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Estado</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{processo.regiao}}</span>
                                    </div>
                                </div>


                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Cobrador</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{processo.cobrador}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Moeda</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>Real</span>
                                    </div>
                                </div>


                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Total de Descontos</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{sumarioDesconto | currency: 'BRL'}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Valor do Processo</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{processo.valor| currency: 'BRL'}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Comissao do Cobrador</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{0| currency: 'BRL'}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Percentual por Multa</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>0%</span>
                                    </div>
                                </div>

                                <div class="col-md-12 display-flex p-2">
                                    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span><strong>Despesas Administrativas</strong></span>
                                    </div>
                                    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                        <span>{{sumarioTaxaAdministrativa| currency: 'BRL'}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <br>








                    </ng-template>
                </li>

                <li ngbNavItem="tabOcorrencias">
                    <a ngbNavLink (click)="selecionarAba('ocorrencia')">Ocorrências</a>
                    <ng-template ngbNavContent>
                        <div class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                            <div style="height: 300px !important;"
                                class="table-responsive tamanho-ocorrencias-modal scroll-bar-padrao">
                                <table class="table">
                                    <tr>
                                        <td><i class="fas fa-eye"></i> Exibir Portal Credor</td>
                                        <td><i class="fas fa-circle text-light"></i> Ocorrência</td>
                                        <td><i class="fas fa-circle text-primary"></i> Atividade</td>
                                        <td><i class="fas fa-circle text-success"></i> Acordo</td>
                                        <td><i class="fas fa-circle text-info"></i> Quitação de Divida</td>
                                        <td><i class="fas fa-circle"></i> Notificação</td>
                                        <td></td>
                                    </tr>
                                </table>
                                <table class="table product-discounts-edit">
                                    <thead>
                                        <tr>
                                            <th style="width:5%"></th>
                                            <th style="width:7%">Data</th>
                                            <th style="width:15%">Status</th>
                                            <th style="width:12%">Tipo / Sub Tipo</th>
                                            <th style="width:25%">Descrição</th>
                                            <th style="width:15%">Usuário</th>
                                            <th style="width:15%">Tags</th>
                                            <th style="width:5%">Anexos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" *ngIf="!ocorrencias || ocorrencias.length == 0">
                                            <td colspan="7">
                                                <label class="form-label">Não há dados disponíveis</label>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let ocorrencia of ocorrencias; let i = index">
                                            <td class="text-center pl-0 pr-0">

                                                <span class="fas fa-eye" placement="top"
                                                    *ngIf="ocorrencia.isExibirPortal"
                                                    ngbTooltip="Exibir Portal Credor"></span>

                                                <span class="fas fa-circle" placement="top"
                                                    [ngClass]="getClassByTipo(ocorrencia)"
                                                    ngbTooltip="{{getTipoLabel(ocorrencia.tipo)}}"></span>
                                            </td>

                                            <td>{{ocorrencia.data | date:"dd/MM/yyyy HH:mm"}}</td>
                                            <td>{{getStatusLabel(ocorrencia.status)}}</td>
                                            <td>{{ocorrencia.situacaoPai}} / {{ocorrencia.situacao}}</td>
                                            <td>
                                                <a href="javascript:;" (click)="detalheOcorrencia(ocorrencia)">
                                                    {{ocorrencia.descricao}}
                                                </a>
                                            </td>
                                            <td>{{ocorrencia.criadoPor}}</td>
                                            <td>
                                                <a *ngFor="let tag of ocorrencia.ocorrenciaTags"
                                                    href="javascript:void(0)" class="badge {{tag.cor}} mr-1">
                                                    {{tag.nome}}
                                                </a>
                                            </td>
                                            <td class="font-size-15pt ocorrencia-arquivo">
                                                <ng-template #popContent>
                                                    <div *ngFor="let arquivo of ocorrencia.ocorrenciaArquivos"
                                                        class="mb-3">
                                                        <a href="javascript:;" class="item-download" placement="top"
                                                            ngbTooltip="Clique para fazer o download {{arquivo.nome}}"
                                                            (click)="downloadOcorrenciaArquivo(arquivo)">
                                                            <span class="fas fa-download"></span> {{arquivo.nome}}
                                                            <small>({{arquivo.tipoDocumento.nome}})</small></a>
                                                    </div>
                                                </ng-template>
                                                <ng-template #popTitle>Arquivos</ng-template>

                                                <span class="fas fa-paperclip cursor-pointer-padrao ngb-popover-dark"
                                                    placement="top" *ngIf="ocorrencia.ocorrenciaArquivos.length > 0"
                                                    [ngbPopover]="popContent" [popoverTitle]="popTitle"
                                                    [openDelay]="300" [closeDelay]="3000"
                                                    triggers="click:mouseleave"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem>
                    <a ngbNavLink (click)="selecionarAba('contato')">Contatos</a>
                    <ng-template ngbNavContent>
                        <div class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                            <div class="ml-0 pl-0 mr-0 pr-0 col-md-12 display-flex tamanho-maximo-contato-modal">
                                <div class="col-md-6 border-padrao overflow-contato scroll-bar-padrao">
                                    <div class="table-responsive">
                                        <table class="table product-discounts-edit">
                                            <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Numero</th>
                                                    <th>Ramal</th>
                                                    <th>Contato</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-center" *ngIf="contatosDevedorTelefones.length == 0">
                                                    <td colspan="7">
                                                        <label class="form-label">Não há dados disponíveis</label>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let telefone of contatosDevedorTelefones; let i = index">
                                                    <td>{{telefone.tipo}}</td>
                                                    <td>{{telefone.numero}}</td>
                                                    <td>{{telefone.ramal}}</td>
                                                    <td>{{telefone.contato}}</td>
                                                    <td> <span
                                                            class="fa fa-phone cursor-pointer-padrao mr-1 font-size-15pt"
                                                            placement="top" ngbTooltip="Discar"></span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-md-6 border-padrao overflow-contato scroll-bar-padrao">
                                    <div class="table-responsive">
                                        <table class="table product-discounts-edit">
                                            <thead>
                                                <tr>
                                                    <th>Uf</th>
                                                    <th>Cidade</th>
                                                    <th>Cep</th>
                                                    <th>Logradouro</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-center" *ngIf="contatosDevedorEnderecos.length == 0">
                                                    <td colspan="7">
                                                        <label class="form-label">Não há dados disponíveis</label>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let endereco of contatosDevedorEnderecos; let i = index">
                                                    <td>{{endereco.uf}}</td>
                                                    <td>{{endereco.cidade}}</td>
                                                    <td>{{endereco.cep}}</td>
                                                    <td>{{endereco.logradouro}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                                <div class="ml-0 pl-0 mr-0 pr-0 col-md-12 display-flex tamanho-maximo-contato-modal">
                                    <div class="col-md-12 border-padrao overflow-contato scroll-bar-padrao">
                                        <div class="table-responsive">
                                            <table class="table product-discounts-edit">
                                                <thead>
                                                    <tr>
                                                        <th>Email</th>
                                                        <th>Facebook</th>
                                                        <th>Instagram</th>
                                                        <th>Linkedin</th>
                                                        <th>Telegram</th>
                                                        <th>Twitter</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="text-center" *ngIf="contatosDevedorEmails.length == 0">
                                                        <td colspan="7">
                                                            <label class="form-label">Não há dados disponíveis</label>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        *ngFor="let midiaSocial of contatosDevedorEmails; let i = index">
                                                        <td>{{midiaSocial.email}}</td>
                                                        <td>{{midiaSocial.facebook}}</td>
                                                        <td>{{midiaSocial.instagram}}</td>
                                                        <td>{{midiaSocial.linkedin}}</td>
                                                        <td>{{midiaSocial.telegram}}</td>
                                                        <td>{{midiaSocial.twitter}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem="tabSimulacaoPagamento" *ngIf="!options.readonly">
                    <a ngbNavLink (click)="selecionarAba('simulacao-pagamento')">Simulação e Pagamento</a>
                    <ng-template ngbNavContent>

                        <br>

                        <ul ngbNav #productTabs2="ngbNav" class="nav-tabs">


                            <li ngbNavItem="tabSimulacaoPagamentoCalculos">
                                <a ngbNavLink>Calculos</a>
                                <ng-template ngbNavContent>



                                    <div class="col-md-12 display-flex p-2" style="height:300px;">

                                        <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1"
                                                    style="display: flex;flex-direction: column;">
                                                    <span><strong>Percentual de Juros do processo</strong></span>
                                                    <div class="pt-2">
                                                        <span><strong>Juros do processo = </strong></span>
                                                        <span>{{calculo.juros | currency: 'BRL'}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <app-input-save
                                                        (newTextEvent)="attCalculo($event,camposSimulacao.percentualJuros=$event)"
                                                        [inputType]="'number'" [symbol]="'%'" [symbolRight]="true"
                                                        [data]="camposSimulacao.percentualJuros"
                                                        [func]=""></app-input-save>
                                                </div>
                                            </div>

                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1"
                                                    style="display: flex;flex-direction: column;">
                                                    <span><strong>Percentual de Multa</strong></span>
                                                    <div class="pt-2">
                                                        <span><strong>Multa = </strong></span>
                                                        <span>{{calculo.multa | currency: 'BRL'}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <app-input-save
                                                        (newTextEvent)="attCalculo($event,camposSimulacao.percentualMulta=$event)"
                                                        [inputType]="'number'" [symbol]="'%'" [symbolRight]="true"
                                                        [data]="camposSimulacao.percentualMulta"
                                                        [func]=""></app-input-save>
                                                </div>
                                            </div>

                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1"
                                                    style="display: flex;flex-direction: column;">
                                                    <span><strong>Percentual de taxa administrativa</strong></span>
                                                    <div class="pt-2">
                                                        <span><strong>Taxa administrativa = </strong></span>
                                                        <span>{{calculo.taxaAdministrativa | currency: 'BRL'}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <app-input-save
                                                        (newTextEvent)="attCalculo($event,camposSimulacao.percentualTaxaAdministrativa= $event)"
                                                        [inputType]="'number'" [symbol]="'%'" [symbolRight]="true"
                                                        [data]="camposSimulacao.percentualTaxaAdministrativa"
                                                        [func]=""></app-input-save>
                                                </div>
                                            </div>
                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1"
                                                    style="display: flex;flex-direction: column;">
                                                    <div class="pt-2">
                                                        <span><strong>Valor de Custas </strong></span>
                                                        <br>
                                                        <span>{{calculo.custas | currency: 'BRL'}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <div class="pt-2">
                                                        <span><strong>Valor Atualização de Custas </strong></span>
                                                        <br>
                                                        <span>{{calculo.atualizacaoCusta | currency: 'BRL'}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <br>

                                            <button (click)='modalAtualizarPercentuaisDoProcesso()' type="button"
                                                class="btn btn-success" [disabled]="options.readonly || isMovido"><i
                                                    class="fas fa-plus-circle"></i> Atualizar Percentuais no
                                                Processo</button>

                                            <!-- <div class="col-md-12 display-flex p-2">
                                  <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1" style="display: flex;flex-direction: column;">
                                    <span><strong>Valor do protesto</strong></span>
                                  </div>
                                  <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                    <app-input-save (newTextEvent)="attCalculo($event,camposSimulacao.jurosProtesto= $event)" [inputType]="'number'" [symbol]="'R$'" [data]="camposSimulacao.jurosProtesto" [func]=""></app-input-save>
                                  </div>
                                </div> -->

                                        </div>

                                        <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

                                            <div class="col-md-12 display-flex p-2">

                                                <div class="col-md-4 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span><strong>Data</strong></span>
                                                </div>
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <app-input-save
                                                        (newTextEvent)="attCalculo($event,camposSimulacao.dataCalculos = $event)"
                                                        [inputType]="'date'" [symbol]="''"
                                                        [data]="camposSimulacao.dataCalculos"
                                                        [func]=""></app-input-save>
                                                </div>
                                            </div>


                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span><strong>Tipo de calculo</strong></span>
                                                </div>
                                                <div class="col-md-7 card-body-processos-orfaos pr-2 pl-0 pt-1 pb-1"
                                                    style="display:flex;flex-direction: row;">
                                                    <div
                                                        style="display: flex;flex-direction: row;align-content: center;margin-right: 15px;">
                                                        <span style="margin-right: 3px;">Simples</span>
                                                        <input type="radio" name="radios-cobradores"
                                                            class="cursor-pointer-padrao"
                                                            [checked]="camposSimulacao.simples"
                                                            (change)="handleChange($event,'simples')">
                                                    </div>

                                                    <div
                                                        style="display: flex;flex-direction: row;align-content: center;">
                                                        <span style="margin-right: 3px;">Composto</span>
                                                        <input type="radio" name="radios-cobradores"
                                                            class="cursor-pointer-padrao"
                                                            [checked]="camposSimulacao.composto"
                                                            (change)="handleChange($event,'composto')">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span><strong>Valor original Do Processo</strong></span>
                                                </div>
                                                <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span>{{calculo.valorOriginal | currency: 'BRL'}}</span>
                                                </div>
                                            </div>



                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span><strong>Valor cobrança</strong></span>
                                                </div>
                                                <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span>{{(calculo.totalEmAberto + calculo.juros + calculo.multa +
                                                        calculo.custas + calculo.atualizacaoCusta) | currency:
                                                        'BRL'}}</span>
                                                </div>
                                            </div>

                                            <div class="col-md-12 display-flex p-2">
                                                <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span><strong>Total do Processo</strong></span>
                                                </div>
                                                <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
                                                    <span>{{(calculo.totalEmAberto + calculo.juros + calculo.multa +
                                                        calculo.custas + calculo.taxaAdministrativa +
                                                        calculo.atualizacaoCusta) | currency: 'BRL'}}</span>
                                                </div>
                                            </div>


                                        </div>


                                    </div>

                                </ng-template>
                            </li>

                            <li ngbNavItem="tabSimulacaoPagamentoAcordo">
                                <a ngbNavLink>Acordo</a>
                                <ng-template ngbNavContent>

                                    <div *ngIf="loadingAcordos"
                                        style="min-height: 340px;;display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">
                                        <span>Verificando a existencia de acordos...</span>
                                        <span>Aguarde alguns instantes...</span>
                                        <div class="spinner-border spinner-border-sm text-info m-1" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!loadingAcordos"
                                        style="min-height: 340px;;display: flex;flex-direction: column;justify-content: center;">


                                        <!--tabela de acordos feitos -->

                                        <div *ngIf="acordosL.length > 0 && parcelas.length == 0"
                                            class="table-responsive table-modal-tabs-scroll"
                                            style="display: flex;height: 280px;">
                                            <table class="table product-discounts-edit">
                                                <thead>
                                                    <tr>
                                                        <th class="text-nowrap text-center cursor-pointer">processo/id
                                                        </th>
                                                        <th class="text-nowrap text-center cursor-pointer">Situacao do
                                                            acordo</th>
                                                        <th class="text-nowrap text-center cursor-pointer">Parcelas</th>
                                                        <th class="text-nowrap text-center cursor-pointer">Valor da
                                                            Parcela</th>
                                                        <th class="text-nowrap text-center cursor-pointer">Valor Total
                                                        </th>
                                                        <th class="text-nowrap text-center cursor-pointer">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let acordo of acordosL">
                                                        <td class="text-center text-nowrap align-middle">
                                                            <strong>#{{processo.idExterno.toString() +'/'+
                                                                acordo.idExterno.toString()}}</strong>
                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            <span
                                                                class="badge {{checkColorBadgeSituacaoAcordo(acordo)}}">
                                                                {{acordo.ocorrencia.situacao}}
                                                            </span>
                                                        </td>

                                                        <td class="text-center text-nowrap align-middle">
                                                            {{acordo.parcelas}}

                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            {{
                                                            (acordo.valorTotal/acordo.parcelas) | currency: 'BRL'
                                                            }}

                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            {{acordo.valorTotal | currency: 'BRL'}}

                                                        </td>
                                                        <td class="text-center text-nowrap align-middle">

                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                (click)="detalheOcorrencia(acordo.ocorrencia)">Expandir</button>

                                                            <button *ngIf="checkDescumprimentoButton(acordo)"
                                                                type="button"
                                                                (click)=" modalGerarDescumprimento(acordo.ocorrenciaId)"
                                                                class="btn btn-danger btn-sm"
                                                                [disabled]="options.readonly">
                                                                Descumpriu</button>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- menu gerar parcela -->
                                        <div *ngIf="acordosL.length == 0 && parcelas.length == 0"
                                            style="height: 160px;"></div>

                                        <div *ngIf="parcelas.length == 0 && checkButtonCriarParcelas()" class="col"
                                            style="align-self: center;text-align: center;">

                                            <div ngbDropdown class="d-inline-block" ngbDropdown #myDrop="ngbDropdown"
                                                style="width: 320px;">
                                                <!-- <span > Não existe nenhum acordo vigente, então você pode criar um.</span> -->

                                                <button style="margin-top: 10px;" class="btn btn-outline-primary"
                                                    id="dropdownConfig" ngbDropdownAnchor (focus)="myDrop.open()">Gerar
                                                    Novo Acordo</button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownConfig"
                                                    style="width: 340px">

                                                    <div class="card-body pr-0 pl-0 pt-1 pb-1 p-3"
                                                        style="display:flex;flex-direction: column;justify-content: space-between;">

                                                        <div>
                                                            <div class="form-group" style="width: 100%;">
                                                                <label class="form-label">Data inicial</label>
                                                                <br>
                                                                <p-calendar
                                                                    [(ngModel)]="camposSimulacao.dataInicialParcelas"
                                                                    dateFormat="dd/mm/yy" slotChar="dd/mm/yyyy"
                                                                    [locale]="pt-br" [showIcon]="true"
                                                                    inputId="icon"></p-calendar>
                                                            </div>

                                                            <div
                                                                style="display: flex;flex-direction: row;justify-content: space-between;">

                                                                <div *ngIf="camposSimulacao.diaFixoOption == 'Sim'"
                                                                    class="form-group" style="width: 100%;">
                                                                    <label class="form-label">N. parcelas</label>
                                                                    <input type="number" max="100" min="0"
                                                                        class="form-control" placeholder=""
                                                                        [(ngModel)]="camposSimulacao.numParcelas">
                                                                </div>
                                                                <div *ngIf="camposSimulacao.diaFixoOption == 'Não'"
                                                                    class="form-group" style="width: 48%;">
                                                                    <label class="form-label">N. parcelas</label>
                                                                    <input type="number" max="100" min="0"
                                                                        class="form-control" placeholder=""
                                                                        [(ngModel)]="camposSimulacao.numParcelas">
                                                                </div>

                                                                <div *ngIf="camposSimulacao.diaFixoOption == 'Não'"
                                                                    class="form-group" style="width: 48%;">
                                                                    <label class="form-label">Intervalo de dias</label>
                                                                    <input type="number" max="300" min="0"
                                                                        class="form-control" placeholder=""
                                                                        [(ngModel)]="camposSimulacao.intervaloDias">
                                                                </div>

                                                                <!--  <div *ngIf="camposSimulacao.diaFixoOption == 'Sim'" class="form-group" style="width: 48%;">
                                              <label class="form-label">Dia</label>
                                              <input type="number" max="31" min="1" class="form-control" placeholder="" [(ngModel)]="camposSimulacao.diaFixoValor" disabled>
                                               </div>-->

                                                            </div>

                                                            <div
                                                                style="display: flex;flex-direction: row;justify-content: space-between;">

                                                                <div class="form-group" style="width: 48%;">
                                                                    <label class="form-label">Dia fixo</label>
                                                                    <select class="custom-select"
                                                                        [(ngModel)]="camposSimulacao.diaFixoOption">
                                                                        <option selected>Sim</option>
                                                                        <option>Não</option>
                                                                    </select>
                                                                </div>



                                                                <div class="form-group" style="width: 48%;">
                                                                    <label class="form-label">Tipo</label>

                                                                    <select class="custom-select"
                                                                        [(ngModel)]="camposSimulacao.tipo">
                                                                        <option *ngFor="let tipo of ListTipoAcordo"
                                                                            [ngValue]="tipo.key">
                                                                            {{tipo.value}}
                                                                        </option>
                                                                    </select>
                                                                </div>

                                                            </div>

                                                            <div
                                                                style="display: flex;flex-direction: row;justify-content: space-between;">


                                                                <div class="form-group" style="width: 48%;">
                                                                    <label class="form-label"
                                                                        style="color: white;">-</label>
                                                                    <select class="custom-select"
                                                                        [(ngModel)]="camposSimulacao.acrescimoOuDesconto"
                                                                        disabled>
                                                                        <option selected>Desconto</option>
                                                                        <option>Acréscimo</option>
                                                                    </select>
                                                                </div>

                                                                <div class="form-group"
                                                                    style="width: 48%;height: 30px;display: flex;flex-direction: row;justify-content: flex-end;">


                                                                    <div class="form-group" style="width: 42%;">
                                                                        <label class="form-label"
                                                                            style="color: white;">-</label>
                                                                        <input type="number" max="300" min="0"
                                                                            class="form-control" placeholder=""
                                                                            [(ngModel)]="camposSimulacao.porcentagemOuValor"
                                                                            disabled>
                                                                    </div>


                                                                    <div class="form-group" style="width: 54%;">
                                                                        <label class="form-label"
                                                                            style="color: white;">-</label>
                                                                        <select class="custom-select"
                                                                            [(ngModel)]="camposSimulacao.porcentagemOuValorOption"
                                                                            disabled>
                                                                            <option selected>%</option>
                                                                            <option>R$</option>
                                                                        </select>
                                                                    </div>

                                                                </div>


                                                            </div>


                                                            <div
                                                                style="display: flex;flex-direction: row;justify-content: space-between;">

                                                                <div class="form-group" style="width: 48%;">
                                                                    <label class="form-label">Perc Juros
                                                                        Processo</label>
                                                                    <input type="number" max="300" min="0"
                                                                        class="form-control" placeholder=""
                                                                        [(ngModel)]="camposSimulacao.percentualJuros">
                                                                </div>


                                                                <div class="form-group" style="width: 48%;">
                                                                    <label class="form-label">Perc
                                                                        administrativa</label>
                                                                    <input type="number" max="300" min="0"
                                                                        class="form-control" placeholder=""
                                                                        [(ngModel)]="camposSimulacao.percentualTaxaAdministrativa">
                                                                </div>



                                                            </div>

                                                        </div>

                                                        <div style="display: flex;width:100%;flex-direction: column;">

                                                            <div class="p-1" style="width: 100%;">
                                                                <button type="button" class="btn btn-success"
                                                                    style="width: 100%;"
                                                                    (click)="mockParcela();$event.stopPropagation(); myDrop.close();">Calcular</button>
                                                            </div>

                                                            <!-- <div class="p-1">
                                          <button type="button" class="btn btn-success" style="width: 100%;" (click)="$event.stopPropagation(); myDrop.close();"><i class="fas fa-save"></i> Salvar Parcelas</button>
                                        </div> -->

                                                        </div>


                                                    </div>


                                                </div>
                                            </div>


                                        </div>

                                        <div *ngIf="parcelas.length > 0"
                                            style="display: flex;flex-direction: column;width: 100%;margin-top: 15px;">

                                            <div class="col-md-12  scroll-bar-padrao" style="overflow:auto ;">
                                                <table class="table product-discounts-edit espacamento-tabela-dividas">
                                                    <thead>
                                                        <tr>
                                                            <th>N. Documento</th>
                                                            <th>Tipo Parcela</th>
                                                            <th>Emissão</th>
                                                            <th>Vencimento</th>
                                                            <th>Valor cobrança</th>
                                                            <th>Juros</th>
                                                            <th>Multa</th>
                                                            <th>Custas</th>
                                                            <th>Atualização Custas</th>
                                                            <th>Total</th>
                                                            <th>Lançamento</th>
                                                            <th>Tipo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style="font-size: 8pt">
                                                        <tr *ngFor="let parcela of parcelas; let i = index">
                                                            <td>{{parcela.numDoc}}</td>
                                                            <td>{{parcela.tipoParcela}}</td>
                                                            <td>{{parcela.emissao}}</td>
                                                            <td>{{parcela.vencimento}}</td>
                                                            <td>{{parcela.valorAberto2 | currency: 'BRL'}}</td>
                                                            <td>{{parcela.juros | currency: 'BRL'}}</td>
                                                            <td>{{parcela.multa | currency: 'BRL'}}</td>
                                                            <td>{{parcela.custas | currency: 'BRL'}}</td>
                                                            <td>{{parcela.atualizacaoCusta | currency: 'BRL'}}</td>
                                                            <td>
                                                                <div *ngIf="parcela.tipoParcela == 'parcela normal'">
                                                                    <input type="text" style="width: 150px;"
                                                                        currencyMask
                                                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                                        class="form-control"
                                                                        [(ngModel)]="parcela.valorTotal2"
                                                                        (blur)="CalcularValorTotalAcordo(parcela)" />
                                                                </div>
                                                                <div *ngIf="parcela.tipoParcela != 'parcela normal'">
                                                                    {{parcela.valorTotal2 | currency: 'BRL'}}
                                                                </div>
                                                            </td>
                                                            <td>{{parcela.lancamento}}</td>
                                                            <td>{{parcela.tipo}}</td>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr style="background-color: rgb(224, 224, 224);">
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><b>Total</b></td>
                                                            <td style="text-align: right;">{{valorTotalAcordo |
                                                                currency: 'BRL' }}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>



                                            <table class="table product-discounts-edit espacamento-tabela-dividas">
                                                <thead>
                                                    <tr>
                                                        <th>Valor cobrança</th>
                                                        <th>Juros</th>
                                                        <th>Multa</th>
                                                        <th>Custas</th>
                                                        <th>Atualização de custas</th>
                                                        <th>Total cobrança</th>
                                                        <th>Taxa administrativa</th>
                                                        <th>Total a pagar</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 8pt">
                                                    <tr>
                                                        <td>{{acordo.totalEmAberto | currency: 'BRL'}}</td>
                                                        <td>{{acordo.juros | currency: 'BRL'}}</td>
                                                        <td>{{acordo.multa | currency: 'BRL'}}</td>
                                                        <td>{{acordo.custas | currency: 'BRL'}}</td>
                                                        <td>{{acordo.atualizacaoCusta | currency: 'BRL'}}</td>
                                                        <td>{{(acordo.totalEmAberto + acordo.juros + acordo.multa +
                                                            acordo.custas + acordo.atualizacaoCusta) | currency: 'BRL'}}
                                                        </td>
                                                        <td>{{acordo.taxaAdministrativa | currency: 'BRL'}}</td>
                                                        <td>{{(acordo.totalEmAberto + acordo.juros + acordo.multa +
                                                            acordo.custas + acordo.atualizacaoCusta +
                                                            acordo.taxaAdministrativa) | currency: 'BRL'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="row">
                                                <div class="col-12">Justificativa:</div>
                                                <div class="col-12">
                                                    <textarea class="form-control" rows="4"
                                                        [(ngModel)]="justificativa">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div style="display: flex;flex-direction: row;">
                                                <div class="p-1">
                                                    <button type="button" class="btn btn-danger"
                                                        (click)="parcelas = []"><i class="fas fa-trash"></i>
                                                        Limpar</button>
                                                </div>

                                                <div class="p-1">
                                                    <button type="button" class="btn btn-success"
                                                        (click)="modalSalvarParcelas()"><i class="fas fa-save"></i>
                                                        Salvar parcelas</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </ng-template>
                            </li>

                        </ul>
                        <div [ngbNavOutlet]="productTabs2"></div>


                    </ng-template>
                </li>


                <li ngbNavItem="tabDividas">
                    <a ngbNavLink (click)="selecionarAba('divida')">Dívidas</a>
                    <ng-template ngbNavContent>
                        <br>
                        <ul [(activeId)]="tabDividasSelected" ngbNav #dividaTabs2="ngbNav" class="nav-tabs">
                            <li ngbNavItem="tabDividaGeral" [ngbNavItem]="0">
                                <a ngbNavLink (click)="selecionarTipoDividas(0)">Geral</a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                                        <div class="scroll-bar-padrao">
                                            <table id="table-dividas"
                                                class="table product-discounts-edit espacamento-tabela-dividas">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Acordo</th>
                                                        <th>Situação</th>
                                                        <th>Documento</th>
                                                        <th>Título</th>
                                                        <th>Parcela</th>
                                                        <th>Remessa</th>
                                                        <th>Vencimento</th>
                                                        <th>Referencia</th>
                                                        <th>Valor original Cobrança</th>
                                                        <th>Valor da Cobrança</th>
                                                        <th>Desconto</th>
                                                        <th>Juros</th>
                                                        <th>Multa</th>
                                                        <th>Custas</th>
                                                        <th>Atualização Custas</th>
                                                        <th>Total Corrigido</th>
                                                        <th>Valor Pago</th>
                                                        <th>Saldo</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 8pt">
                                                    <tr class="text-center" *ngIf="dividaL.length == 0">
                                                        <td colspan="18">
                                                            <label class="form-label">Não há dados disponíveis</label>
                                                        </td>
                                                    </tr>
                                                    <ng-container *ngFor="let divida of dividas; let i = index">
                                                    <tr [ngClass]="corSituacoPagamento(divida)">
                                                        <td>
                                                            <button class="btn btn-light btn-custom" *ngIf="divida.idBorderoCliente" (click)="divida.expandido = !divida.expandido">
                                                                <i class="fa fa-plus" *ngIf="!divida.expandido" ></i>
                                                                <i class="fa fa-minus" *ngIf="divida.expandido" ></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="divida.acordoId != null"
                                                                class="badge badge-success">
                                                                Acordo Vigente
                                                            </span>
                                                            <span *ngIf="divida.acordoId == null"
                                                                class="badge badge-default">
                                                                Divida Original
                                                            </span>
                                                        </td>
                                                        <td>{{divida.situacao.nome}}</td>
                                                        <td>{{divida.numeroDocumento}}</td>
                                                        <td>{{divida.numeroTitulo}}</td>
                                                        <td>{{divida.parcela}}</td>
                                                        <td>{{divida.dataCriacao | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataVencimento | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataReferencia | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.valorOriginalAberto != null ?
                                                            removeWhiteSpace((divida.valorOriginalAberto |
                                                            currency:'BRL')) : "" }}</td>
                                                        <td>{{divida.valorAberto !=
                                                            null?removeWhiteSpace((divida.valorAberto |
                                                            currency:'BRL')): "" }}</td>
                                                        <td>{{divida.valorDesconto !=
                                                            null?removeWhiteSpace((divida.valorDesconto |
                                                            currency:'BRL')): ""}}</td>
                                                        <td>{{divida.juros != null?removeWhiteSpace((divida.juros |
                                                            currency:'BRL')): "" }}</td>
                                                        <td>{{divida.multa != null?removeWhiteSpace((divida.multa |
                                                            currency:'BRL')): ""}}</td>
                                                        <td>{{divida.valorCustasProtesto !=
                                                            null?removeWhiteSpace((divida.valorCustasProtesto|
                                                            currency:'BRL')): "" }}</td>
                                                        <td>{{divida.valorAtualizacaoCustas !=
                                                            null?removeWhiteSpace((divida.valorAtualizacaoCustas |
                                                            currency:'BRL')): ""}}</td>
                                                        <td>{{divida.valorTotal !=
                                                            null?removeWhiteSpace((divida.valorTotal | currency:'BRL')):
                                                            ""}}</td>
                                                        <td>{{divida.valorPago !=
                                                            null?removeWhiteSpace((divida.valorPago | currency:'BRL')):
                                                            ""}}</td>
                                                        <td>{{divida.valorSaldoPendente != null ?
                                                            removeWhiteSpace((divida.valorSaldoPendente |
                                                            currency:'BRL')):"" }}</td>

                                                    </tr>
                                                    <tr *ngIf="divida.idBorderoCliente && divida.expandido">
                                                        <td colspan="19">
                                                            <ul>
                                                                <li class="mt-2"><b>Borderô do cliente:</b> {{ divida.idBorderoCliente }}</li>
                                                                <li class="mt-2"><b>Informações Adicionais 1:</b> {{ divida.informacaoAdicional1 ? divida.informacaoAdicional1 : ' - ' }}</li>
                                                                <li class="mt-2"><b>Informações Adicionais 2:</b> {{ divida.informacaoAdicional2 ? divida.informacaoAdicional2 : ' - ' }}</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    </ng-container>
                                                    <tr style="background-color: rgb(216, 216, 216);">
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorOriginalAberto',0) |
                                                            currency: 'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorAberto') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('juros',0) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('multa',0) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorCustasProtesto',0) |
                                                            currency: 'BRL'}}</td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorTotal') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPago',0) | currency:
                                                            'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPendente',0) | currency:
                                                            'BRL'}}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <br>

                                    <button (click)='dividasToExcel()' type="button" class="btn btn-success btn-sm"><i
                                            class="fas fa-plus-circle"></i> Export to Excel</button>

                                    <br>

                                </ng-template>
                            </li>
                            <li ngbNavItem="tabDividaPagamento" [ngbNavItem]="1">
                                
                                <a ngbNavLink (click)="selecionarTipoDividas(1)">Pagamentos</a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <div class="p-2">
                                                    <label [innerHTML]="mensagemTipoPagamento"></label>
                                                </div>
                                                <div class="custom-btns" style="margin-bottom: 10px;">
                                                    <div class="btn-group btn-group-toggle" ngbRadioGroup
                                                        name="radioBasic" [(ngModel)]="tipoPagamento"
                                                        (change)="changeTipoPagamento()">
                                                        <label ngbButtonLabel class="btn-primary">
                                                            <input ngbButton type="radio" [value]="1"> Normal
                                                        </label>
                                                        <label ngbButtonLabel class="btn-primary">
                                                            <input ngbButton type="radio" [value]="2"> Direto
                                                        </label>
                                                        <label ngbButtonLabel class="btn-primary">
                                                            <input ngbButton type="radio" [value]="3"> Retido
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1"></div>

                                            <div class="col-7">
                                                <div class="p-2">
                                                    <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                                                        <span><b style="color: red;">*</b> <strong>Valor do Comprovante
                                                                de Pagamento</strong></span>
                                                    </div>
                                                    <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                                                        <input [readonly]="tipoPagamento == 0" currencyMask
                                                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                            class="form-control" style="border-color: red"
                                                            placeholder="" [(ngModel)]="valorPagamentoDivida"
                                                            (blur)="informarValorComprovante()">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="scroll-bar-padrao">
                                            <table class="table product-discounts-edit espacamento-tabela-dividas">
                                                <thead>
                                                    <tr>
                                                        <th *ngIf="!options.readonly && isMaster"></th>
                                                        <th *ngIf="!options.readonly"></th>
                                                        <th>Situação</th>
                                                        <th>Documento</th>
                                                        <th>Título</th>
                                                        <th>Parcela</th>
                                                        <th>Remessa</th>
                                                        <th>Vencimento</th>
                                                        <th>Referencia</th>
                                                        <th>Valor original NF</th>
                                                        <th>Valor da Cobrança</th>
                                                        <th>Desconto</th>
                                                        <th>Juros</th>
                                                        <th>Multa</th>
                                                        <th>Custas</th>
                                                        <th>Atualização Custas</th>
                                                        <th>Total Corrigido</th>
                                                        <th>Valor Pago</th>
                                                        <th>Saldo</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 8pt">
                                                    <tr class="text-center" *ngIf="dividas.length == 0">
                                                        <td colspan="18">
                                                            <label class="form-label">Não há dados disponíveis</label>
                                                        </td>
                                                    </tr>

                                                    <tr *ngFor="let divida of dividas; let i = index"
                                                        [ngClass]="corSituacoPagamento(divida)">

                                                        <div *ngIf="!options.readonly && isMaster" class="col">
                                                            <div ngbDropdown placement="right" class="d-inline-block">
                                                                <button class="btn btn-link" id="dropdownBasic1"
                                                                    ngbDropdownToggle>
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                                    <button ngbDropdownItem
                                                                        (click)="modalExcluirTitulo(divida)">Excluir
                                                                        Titulo</button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <td *ngIf="!options.readonly"><input
                                                                [disabled]="tipoPagamento == 0" type="checkbox"
                                                                [(ngModel)]="divida.selecionado"
                                                                (change)="calcularValorPagamento($event, divida);"></td>
                                                        <td>{{divida.situacao.nome}}</td>
                                                        <td>{{divida.numeroDocumento}}</td>
                                                        <td>{{divida.numeroTitulo}}</td>
                                                        <td>{{divida.parcela}}</td>
                                                        <td>{{divida.dataRemessa | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataVencimento | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataReferencia | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.valorOriginalAberto | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorAberto | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorDesconto | currency: 'BRL'}}</td>
                                                        <td>{{divida.juros | currency: 'BRL'}}</td>
                                                        <td>{{divida.multa | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorCustasProtesto | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorAtualizacaoCustas | currency: 'BRL' }}</td>
                                                        <td>{{divida.valorTotal | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorPago | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorSaldoPendente | currency: 'BRL'}}</td>
                                                    </tr>
                                                    <tr style="background-color: rgb(216, 216, 216);">
                                                        <div *ngIf="!options.readonly && isMaster" class="col"></div>
                                                        <td *ngIf="!options.readonly"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorOriginalAberto',1) |
                                                            currency: 'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorAberto') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('juros',1) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('multa',1) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorCustasProtesto',1) |
                                                            currency: 'BRL'}}</td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorTotal') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPago',1) | currency:
                                                            'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPendente',1) | currency:
                                                            'BRL'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="modal-footer p-1" *ngIf="!options.readonly">
                                        <button [disabled]="tipoPagamento == 0"
                                            (click)='selectORDeselectAllDividas(dividas,!selecteAllDividasPagamento)'
                                            type="button" class="btn btn-light"> {{selecteAllDividasPagamento ? 'Limpar
                                            Todos':'Selecionar Todos'}}</button>

                                        <button [disabled]="tipoPagamento == 0" *ngIf="possuiDividas(1)"
                                            (click)='abrirModalAdicionarDivida(processo)' type="button"
                                            class="btn btn-danger"><i class="fas fa-plus-circle"></i> Pagamento
                                            {{totalDividaQuitacao | currency: 'BRL' }}</button>
                                        <br>

                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem="tabDividaDesconto" [ngbNavItem]="2">
                                <a ngbNavLink (click)="selecionarTipoDividas(2)">Descontos</a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                                        <div class="col-md-12 p-2" *ngIf="!options.readonly">
                                            <div class="col-md-12 pr-0 pl-0 pt-1 pb-1">
                                                <span><b style="color: red;">*</b> <strong>Valor do
                                                        Desconto</strong></span>
                                            </div>
                                            <div class="col-md-12 pr-0 pl-0 pt-1 pb-1">
                                                <input currencyMask
                                                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                    class="form-control" style="border-color: red" placeholder=""
                                                    [(ngModel)]="valorDescontoDivida" (blur)="informarValorDesconto()">
                                            </div>
                                        </div>
                                        <div class="scroll-bar-padrao">
                                            <table class="table product-discounts-edit espacamento-tabela-dividas">
                                                <thead>
                                                    <tr>
                                                        <th *ngIf="!options.readonly"></th>
                                                        <th>Situação</th>
                                                        <th>Documento</th>
                                                        <th>Título</th>
                                                        <th>Parcela</th>
                                                        <th>Remessa</th>
                                                        <th>Vencimento</th>
                                                        <th>Referencia</th>
                                                        <th>Valor original NF</th>
                                                        <th>Valor da Cobrança</th>
                                                        <th>Desconto</th>
                                                        <th>Juros</th>
                                                        <th>Multa</th>
                                                        <th>Custas</th>
                                                        <th>Atualização Custas</th>
                                                        <th>Total Corrigido</th>
                                                        <th>Valor Pago</th>
                                                        <th>Saldo</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 8pt">
                                                    <tr class="text-center" *ngIf="dividas.length == 0">
                                                        <td colspan="10">
                                                            <label class="form-label">Não há dados disponíveis</label>
                                                        </td>
                                                    </tr>

                                                    <tr *ngFor="let divida of dividas; let i = index"
                                                        [ngClass]="corSituacoPagamento(divida)">
                                                        <td *ngIf="!options.readonly"><input
                                                                *ngIf="(divida.tipoParcela == null || divida.tipoParcela.trim().length == 0)  && (divida.situacao.nome == 'Novo' || divida.situacao.nome == 'Desconto Aprovado')"
                                                                type="checkbox" [(ngModel)]="divida.selecionado"
                                                                (change)="calcularValorPagamento($event, divida);"></td>
                                                        <td>{{divida.situacao.nome}}</td>
                                                        <td>{{divida.numeroDocumento}}</td>
                                                        <td>{{divida.numeroTitulo}}</td>
                                                        <td>{{divida.parcela}}</td>
                                                        <td>{{divida.dataRemessa | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataVencimento | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataReferencia | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.valorOriginalAberto | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorAberto | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorDesconto | currency: 'BRL'}}</td>
                                                        <td>{{divida.juros | currency: 'BRL'}}</td>
                                                        <td>{{divida.multa | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorCustasProtesto | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorAtualizacaoCustas | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorTotal | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorPago | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorSaldoPendente | currency: 'BRL'}}</td>
                                                    </tr>
                                                    <tr style="background-color: rgb(216, 216, 216);">
                                                        <td *ngIf="!options.readonly"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorOriginalAberto',2) |
                                                            currency: 'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorAberto') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('juros',2) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('multa',2) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorCustasProtesto',2) |
                                                            currency: 'BRL'}}</td>
                                                        <td class="font-weight-bold"></td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorTotal') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPago',2) | currency:
                                                            'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPendente',2) | currency:
                                                            'BRL'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="modal-footer p-1" *ngIf="!options.readonly">
                                        <button (click)='abrirModalAdicionarDividaDesconto(processo)' type="button"
                                            class="btn btn-danger"><i class="fas fa-plus-circle"></i> Desconto</button>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem="tabDividaTaxaAdministrativa" *ngIf="!isCredor && isMaster" [ngbNavItem]="3">
                                <a ngbNavLink (click)="selecionarTipoDividas(3)">Taxa administrativa</a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                                        <div class="col-md-12 p-2" *ngIf="!options.readonly">
                                            <div class="col-md-12 pr-0 pl-0 pt-1 pb-1">
                                                <span><b style="color: red;">*</b> <strong>Valor da Taxa
                                                        administrativa</strong></span>
                                            </div>
                                            <div class="col-md-12 pr-0 pl-0 pt-1 pb-1">
                                                <input currencyMask
                                                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                    class="form-control" style="border-color: red" placeholder=""
                                                    [(ngModel)]="valorTaxaAdministrativaDivida"
                                                    (blur)="informarValorTaxaAdministrativa()">
                                            </div>
                                        </div>
                                        <div class="scroll-bar-padrao">
                                            <table class="table product-discounts-edit espacamento-tabela-dividas">
                                                <thead>
                                                    <tr>
                                                        <th *ngIf="!options.readonly && isMaster"></th>
                                                        <th>Cobrador</th>
                                                        <th>Cobrador Id</th>
                                                        <th>Observação</th>
                                                        <th>Pagamento</th>
                                                        <th>Referencia</th>
                                                        <th>Valor Pago</th>

                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 8pt">
                                                    <tr class="text-center" *ngIf="dividas.length == 0">
                                                        <td colspan="15">
                                                            <label class="form-label">Não há dados disponíveis</label>
                                                        </td>
                                                    </tr>

                                                    <tr *ngFor="let divida of dividas; let i = index"
                                                        [ngClass]="corSituacoTaxaAdministrativa(divida)">

                                                        <div *ngIf="!options.readonly && isMaster" class="col">
                                                            <div ngbDropdown placement="right" class="d-inline-block">
                                                                <button class="btn btn-link" id="dropdownBasic1"
                                                                    ngbDropdownToggle>
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                                    <button ngbDropdownItem
                                                                        (click)="modalExcluirTaxaAdministrativa(divida)">Excluir
                                                                        Taxa</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <td>{{divida.pagamentos[0].cobrador}}</td>
                                                        <td>{{divida.pagamentos[0].cobradorIdExterno}}</td>
                                                        <td>{{divida.pagamentos[0].ocorrenciaDescricao}}</td>
                                                        <td>{{divida.pagamentos[0].dataPagamento | date:"dd/MM/yy"}}
                                                        </td>
                                                        <td>{{divida.dataReferencia | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.pagamentos[0].valorPagamento | currency: 'BRL'}}
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="modal-footer p-1" *ngIf="!options.readonly">
                                        <button (click)='abrirModalAdicionarDividaTaxaAdministrativa(processo)'
                                            type="button" class="btn btn-danger"><i class="fas fa-plus-circle"></i> Taxa
                                            administrativa</button>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem="tabDividaGeral" [ngbNavItem]="4">
                                <a ngbNavLink (click)="selecionarTipoDividas(4)">Acordos</a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                                        <div class="scroll-bar-padrao">
                                            <table id="table-dividas"
                                                class="table product-discounts-edit espacamento-tabela-dividas">
                                                <thead>
                                                    <tr>
                                                        <th>acordo</th>
                                                        <th>Situação</th>
                                                        <th>Documento</th>
                                                        <th>Título</th>
                                                        <th>Parcela</th>
                                                        <th>Remessa</th>
                                                        <th>Vencimento</th>
                                                        <th>Referencia</th>
                                                        <th>Valor original Cobrança</th>
                                                        <th>Valor da Cobrança</th>
                                                        <th>Desconto</th>
                                                        <th>Juros</th>
                                                        <th>Multa</th>
                                                        <th>Custas</th>
                                                        <th>Atualização Custas</th>
                                                        <th>Total Corrigido</th>
                                                        <th>Valor Pago</th>
                                                        <th>Saldo</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 8pt">
                                                    <tr class="text-center" *ngIf="dividaL.length == 0">
                                                        <td colspan="18">
                                                            <label class="form-label">Não há dados disponíveis</label>
                                                        </td>
                                                    </tr>

                                                    <tr *ngFor="let divida of dividas; let i = index"
                                                        [ngClass]="corSituacoPagamento(divida)">
                                                        <!-- <td>
                                                            <span *ngIf="divida.acordoId != null"
                                                                class="badge badge-success">
                                                                Acordo Vigente
                                                            </span>
                                                            <span *ngIf="divida.acordoId == null"
                                                                class="badge badge-default">
                                                                Divida Original
                                                            </span>
                                                        </td> -->
                                                        <td>{{divida.situacaoAcordo}}</td>
                                                        <td>{{divida.situacao.nome}}</td>
                                                        <td>{{divida.numeroDocumento}}</td>
                                                        <td>{{divida.numeroTitulo}}</td>
                                                        <td>{{divida.parcela}}</td>
                                                        <td>{{divida.dataCriacao | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataVencimento | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.dataReferencia | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.valorOriginalAberto != null ?
                                                            removeWhiteSpace((divida.valorOriginalAberto |
                                                            currency:'BRL')) : "" }}</td>
                                                        <td>{{divida.valorAberto !=
                                                            null?removeWhiteSpace((divida.valorAberto |
                                                            currency:'BRL')): "" }}</td>
                                                        <td>{{divida.valorDesconto !=
                                                            null?removeWhiteSpace((divida.valorDesconto |
                                                            currency:'BRL')): ""}}</td>
                                                        <td>{{divida.juros != null?removeWhiteSpace((divida.juros |
                                                            currency:'BRL')): "" }}</td>
                                                        <td>{{divida.multa != null?removeWhiteSpace((divida.multa |
                                                            currency:'BRL')): ""}}</td>
                                                        <td>{{divida.valorCustasProtesto !=
                                                            null?removeWhiteSpace((divida.valorCustasProtesto|
                                                            currency:'BRL')): "" }}</td>
                                                        <td>{{divida.valorAtualizacaoCustas !=
                                                            null?removeWhiteSpace((divida.valorAtualizacaoCustas |
                                                            currency:'BRL')): ""}}</td>
                                                        <td>{{divida.valorTotal !=
                                                            null?removeWhiteSpace((divida.valorTotal | currency:'BRL')):
                                                            ""}}</td>
                                                        <td>{{divida.valorPago !=
                                                            null?removeWhiteSpace((divida.valorPago | currency:'BRL')):
                                                            ""}}</td>
                                                        <td>{{divida.valorSaldoPendente != null ?
                                                            removeWhiteSpace((divida.valorSaldoPendente |
                                                            currency:'BRL')):"" }}</td>

                                                    </tr>
                                                    <tr style="background-color: rgb(216, 216, 216);">
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorOriginalAberto',0) |
                                                            currency: 'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorAberto') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('juros',0) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('multa',0) | currency: 'BRL'}}
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorCustasProtesto',0) |
                                                            currency: 'BRL'}}</td>
                                                        <td></td>
                                                        <td class="font-weight-bold">
                                                            <!--{{calcularValorTotalDivida('valorTotal') | currency: 'BRL'}}-->
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPago',0) | currency:
                                                            'BRL'}}</td>
                                                        <td class="font-weight-bold">
                                                            {{calcularValorTotalDividas('valorPendente',0) | currency:
                                                            'BRL'}}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem="tabDividaGeral" [ngbNavItem]="5">
                                <a ngbNavLink (click)="selecionarExtrato()">Extrato (Beta)</a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                                        <div class="d-flex justify-content-center" *ngIf="loadExtrato">
                                            <div class="spinner-border text-primary" role="status">
                                              <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <table id="table-dividas-extrato"
                                            class="table product-discounts-edit espacamento-tabela-dividas"
                                            *ngIf="!loadExtrato">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Documento</th>
                                                    <th>Título</th>
                                                    <th>Tipo</th>
                                                    <th>Prefixo</th>
                                                    <th>Data Emissão</th>
                                                    <th>Parcela</th>
                                                    <th>Situação</th>
                                                    <th>Valor Original da Cobrança</th>
                                                    <th>Custas</th>
                                                    <th>Valor Pago</th>
                                                    <th>Valor Total</th>
                                                    <th>Saldo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-center" *ngIf="dividasExtrato.length == 0">
                                                    <td colspan="18">
                                                        <label class="form-label">Não há dados disponíveis</label>
                                                    </td>
                                                </tr>
                                                <ng-container *ngFor="let divida of dividasExtrato; let i = index">
                                                    <tr [ngClass]="corSituacaoExtrato(divida)">
                                                        <td>
                                                            <button class="btn btn-light btn-custom" *ngIf="divida.acordoSaldo != null" (click)="expandirDividaExtrato(divida)">
                                                                <i class="fa fa-plus" *ngIf="!divida.expandido" ></i>
                                                                <i class="fa fa-minus" *ngIf="divida.expandido" ></i>
                                                            </button>
                                                        </td>
                                                        <td>{{divida.numeroDocumento}}</td>
                                                        <td>{{divida.numeroTitulo}}</td>
                                                        <td>{{divida.tp}}</td>
                                                        <td>{{divida.prefixo}}</td>
                                                        <td>{{divida.dataEmissao | date:"dd/MM/yy"}}</td>
                                                        <td>{{divida.parcela}}</td>
                                                        <td>{{divida.statusCobranca}}</td>
                                                        <td>{{divida.valorOriginal | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorCustasProtesto | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorPago | currency: 'BRL'}}</td>
                                                        <td>{{(divida.valorOriginal + divida.valorCustasProtesto) | currency: 'BRL'}}</td>
                                                        <td>{{divida.valorSaldoPendente | currency: 'BRL'}}</td>
                                                    </tr>
                                                    <ng-container *ngIf="divida.acordoSaldo != null && divida.expandido">
                                                        <tr>
                                                            <td></td>
                                                            <td colspan="6" class="text-center titulo-acordos">Acordos</td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <th>Documento</th>
                                                            <th>Data Pagamento</th>
                                                            <th>Valor Pago</th>
                                                            <th>Valor Original da Cobrança</th>
                                                            <th>Saldo</th>
                                                            <th colspan="1"></th>
                                                        </tr>
                                                        <tr *ngFor="let acordo of divida.acordoSaldo">
                                                            <td></td>
                                                            <td>{{acordo.numeroDocumento}}</td>
                                                            <td>{{obterDataPagamento(acordo) | date:"dd/MM/yyyy"}}</td>
                                                            <td>{{acordo.valorPago | currency: 'BRL'}}</td>
                                                            <td>{{divida.valorOriginal | currency: 'BRL'}}</td>
                                                            <td>{{divida.valorSaldoPendente | currency: 'BRL'}}</td>
                                                            <th colspan="1"></th>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td colspan="6" class="text-center titulo-acordos-footer"></td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="dividaTabs2"></div>
                    </ng-template>
                </li>

                <li ngbNavItem="tabDocumentos">
                    <a ngbNavLink (click)="selecionarAba('documento')">Documentos</a>
                    <ng-template ngbNavContent>
                        <div class="card-body tamanho-maximo-modal-ocorrencia-contato-cobrador scroll-bar-padrao p-0">
                            <div style="height: 300px !important;"
                                class="table-responsive tamanho-ocorrencias-modal scroll-bar-padrao">

                                <table class="table product-discounts-edit">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style="width:7%">Data</th>
                                            <th>Nome</th>
                                            <th>Tipo</th>
                                            <th>Descrição</th>
                                            <th>Usuário</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center"
                                            *ngIf="!processoArquivos || processoArquivos.length == 0">
                                            <td colspan="6">
                                                <label class="form-label">Não há dados disponíveis</label>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let processoArquivo of processoArquivos; let i = index"
                                            ngbTooltip="{{getTooltipProcessoArquivo(processoArquivo)}}"
                                            [ngClass]="{'table-warning': isArquivoOcorrencia(processoArquivo)}">

                                            <div class="col">
                                                <div ngbDropdown placement="right" class="d-inline-block">
                                                    <button class="btn btn-link" id="dropdownBasic1" ngbDropdownToggle>
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                        <button *ngIf="!options.readonly && isMaster" ngbDropdownItem
                                                            (click)="excluirArquivo(processoArquivo)">Excluir
                                                            Arquivo</button>
                                                        <button ngbDropdownItem
                                                            (click)="downloadProcessoArquivo(processoArquivo)">Baixar
                                                            Arquivo</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <td>{{processoArquivo.dataCriacao | date:"dd/MM/yyyy HH:mm"}}</td>
                                            <td>{{processoArquivo.nome}}</td>
                                            <td>{{processoArquivo.tipoDocumento.nome}}</td>
                                            <td>{{processoArquivo.descricao}}</td>
                                            <td>{{processoArquivo.criadoPor}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="productTabs"></div>
        </div>
    </div>

    <div class="modal-footer p-1">
        <button (click)='abrirModalAdicionarOcorrencia(processo)' type="button" class="btn btn-primary"
            [disabled]="(options.readonly && !this.isCredor) || this.isMovido"><i class="fas fa-plus-circle"></i>
            Ocorrência</button>
        <button (click)='abrirModalAdicionarAtividade(processo)' type="button" class="btn btn-primary"
            [disabled]="(options.readonly || this.isCredor) || this.isMovido"><i class="fas fa-plus-circle"></i>
            Atividade</button>
        <button (click)='abrirModalAdicionarDocumento(processo)' type="button" class="btn btn-success"
            [disabled]="(options.readonly && !this.isCredor) || this.isMovido"><i class="fas fa-plus-circle"></i>
            Documento</button>
        <button (click)='close()' type="button" class="btn btn-default">Fechar</button>
    </div>

</div>