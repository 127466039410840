<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos / </span><span>Atendimento</span>
    </span>
</h4>
<hr />
<div class="container-fluid mb-5 h-100">
    <div class="d-flex justify-content-center" *ngIf="loadAtendimento">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row h-100" *ngIf="!loadAtendimento">
        <div class="col-9">
            <div class="row cabecalho">
                <div class="col-9">Início: {{atendimento.dataInicio | date: 'dd/MM/yyyy'}}
                    <span class="atendimento-label">{{atendimento.encerrado ? 'Encerrado' : 'Em Atendimento'}}</span>
                </div>
                <div class="col-1">
                   <div class="voltar" (click)="voltar()">Voltar</div>
                </div>
                <div class="col-1">
                    <div [ngClass]="filtroImportante ? 'important-blue-titulo' : 'important-gray-titulo'" (click)="filtrarImportante()">
                        <i class="fas fa-bookmark" placement="bottom" ngbTooltip="Filtrar registros importantes"></i>
                    </div>
                </div>
                <div class="col-1 cursor" *ngIf="!atendimento.encerrado">
                    <div (click)="exibirMenu=!exibirMenu">
                        <i class="fas fa-ellipsis-v"></i>
                    </div>
                    <ul class="dropdown-tmp-list" *ngIf="exibirMenu" (click)="exibirMenu=!exibirMenu">
                        <li class="dropdown-tmp-item mt-3" routerLink="/{{raiz}}/atendimento/editar-atendimento/{{id}}">
                            <i class="fas fa-edit"></i>
                            <span class="text-link">Editar atendimento</span>
                        </li>
                        <li class="dropdown-tmp-item" (click)="modificarStatus()" *ngIf="!atendimento.encerrado">
                            <i class="fas fa-check"></i>
                            <span class="text-link">Encerrar atendimento</span>
                        </li>
                        <!-- <li class="dropdown-tmp-item" (click)="modificarStatus()" *ngIf="atendimento.encerrado">
                            <i class="fas fa-redo-alt"></i>
                            <span class="text-link">Reabrir atendimento</span>
                        </li> -->
                        <li class="dropdown-tmp-item" *ngIf="atendimento.tipoReferencia == null" (click)="criarCaso()">
                            <i class="fas fa-sync"></i>
                            <span class="text-link">Transformar em caso</span>
                        </li>
                        <li class="dropdown-tmp-item" *ngIf="atendimento.tipoReferencia == null" (click)="criarProcesso()">
                            <i class="fas fa-sync"></i>
                            <span class="text-link">Transformar em processo</span>
                        </li>
                    </ul>
                </div>
                <div class="col-12 mt-2" *ngIf="atendimento.encerrado">Encerramento: {{atendimento.dataEncerramento | date: 'dd/MM/yyyy HH:mm'}}</div>
                <div class="col-12" *ngIf="atendimento.encerrado">Motivo encerramento: {{atendimento.atendimentoEncerramentoNome}}</div>
                <div class="col-12" *ngIf="atendimento.encerrado">Justificativa encerramento: {{atendimento.descricaoEncerramento}}</div>
                <div class="col-12">Título: {{atendimento.titulo}}</div>
                <div class="col-12" *ngIf="atendimento.tituloRegistro != undefined || atendimento.tituloRegistro != null">{{atendimento.tituloRegistro}}</div>
                <div class="col-12">Responsável: {{atendimento.nomeResponsavel}}</div>
                <div class="col-12" *ngIf="atendimento.tags.length > 0">
                    <div *ngFor="let tag of atendimento.tags" class="mr-1" [ngClass]="defineClass(tag.tagCor)">{{tag.tagNome}}
                    </div>
                </div>
            </div>
            <div class="row detalhes">
                <div class="col-12">
                    <div class="row" *ngFor="let registro of atendimento.registros">
                        <div class="col-9 card registro-texto">
                            <div [ngClass]="registro.importante ? 'important-blue' : 'important-gray'" (click)="definirImportante(registro)">
                                <i class="fas fa-bookmark"></i>
                            </div>
                            {{registro.texto}}
                        </div>
                        <div class="col-3">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="registro-responsavel">{{registro.nomeResponsavel?.charAt(0)}}</div>
                                </div>
                                <div class="col-12 d-flex justify-content-center">{{registro.dataCriacao | date: 'dd/MM/yyyy'}}</div>
                                <div class="col-12 d-flex justify-content-center">{{registro.dataCriacao | date: 'HH:mm'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row iteracao">
                <div class="form-group col-9">
                    <textarea rows="3" class="form-control" [(ngModel)]="textoRegistro"></textarea>
                </div>
                <div class="col-3 salvar" *ngIf="!atendimento.encerrado">
                    <button type="button" class="btn btn-primary" (click)="onSubmit()">Salvar</button>
                </div>
            </div>
        </div>
        <div class="col-3 direita">
            <div class="row mb-1" *ngFor="let cliente of atendimento.clientes">
                <div class="col-12 cliente">
                    <div class="cliente-icone"><i class="fas fa-user"></i></div><span>{{cliente}}</span>
                </div>
            </div>
            <div class="row opcoes separacao">
                <div class="col-10 opcoes-atendimento mt-3">
                   Honorários R$ {{atendimento.valorHonorarios.toFixed(2)}}
                </div>
                <div class="col-2 mt-3 cursor" placement="left" ngbTooltip="Clique para adicionar" routerLink="{{raiz}}/honorario-por-valor/3/{{atendimento.id}}">
                    <i class="fas fa-plus"></i>
                </div>
            </div>
            <div class="row opcoes separacao">
                <div class="col-10 opcoes-atendimento mt-3">
                   Timesheets 0:00
                </div>
                <div class="col-2 mt-3 cursor" placement="left" ngbTooltip="Clique para adicionar">
                    <i class="fas fa-plus"></i>
                </div>
            </div>
            <div class="row opcoes separacao">
                <div class="col-10 opcoes-atendimento mt-3">
                   Documentos ({{quantidadeDocumentos}})
                </div>
                <div class="col-2 mt-3 cursor" placement="left" ngbTooltip="Clique para adicionar" routerLink="{{raiz}}/atendimento/atendimento-documento/{{atendimento.id}}">
                    <i class="fas fa-plus"></i>
                </div>
            </div>
        </div>
    </div>
</div>
