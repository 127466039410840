import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-esqueceu-senha',
    templateUrl: './esqueceu-senha.component.html',
    styleUrls: [
        '../../../../vendor/styles/pages/authentication.scss'
    ]
})
export class EsqueceuSenhaComponent {

    public email = '';
    public requisicaoSendoFeita = false;

    constructor(private appService: AppService,
        private router: Router,
        private loginService: LoginService,
        public toastrService: ToastrService
        ) {
        this.appService.pageTitle = 'esqueceu senha';
    }
    
    recuperarSenha(){
        this.requisicaoSendoFeita = true;
        var dataEsqueceuSenha = {email: this.email};
        
        this.loginService.esqueceuSenha(dataEsqueceuSenha)
            .subscribe(res => {
                this.requisicaoSendoFeita = false;
                this.toastrService.success(res.data, 'Sucesso', {closeButton: false, timeOut:10000});
                this.email = '';
        }, error =>{
            this.requisicaoSendoFeita = false;
            this.toastrService.error(error.errors[0], 'Erro', {closeButton: false, timeOut:10000});
        })
    }

    voltar(){
        this.router.navigateByUrl('/login');
    }
}
