import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicacaoService } from 'src/app/services/publicacao.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { debug, timeStamp } from 'console';
import Swal from 'sweetalert2';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { TipoOcorrenciaJudicialService } from 'src/app/services/tipo-ocorrencia-judicial.service';
import { ToastrService } from 'ngx-toastr';
import { ProcessosJudiciaisApensosService } from 'src/app/services/processos-judiciais-apensos.service';
import { fil } from 'date-fns/locale';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDetalheComponent } from 'src/app/views/dashboard/agenda/modal-detalhe/modal-detalhe.component';
import { Guid } from 'guid-typescript';
import { ModalOcorrenciaComponent } from '../../processos-judiciais/processos-judiciais-detalhes/modal-ocorrencia/modal-ocorrencia.component';

@Component({
    selector: 'app-publicacao-editar',
    templateUrl: './publicacao-editar.component.html',
    styleUrls: ['./publicacao-editar.component.css']
})
export class PublicacaoEditarComponent implements OnInit {

    public loadPublicacao: boolean = true;
    public id: string;
    public publicacao: any;

    public processos: any;
    public loadProcessos: boolean = true;
    public processoSelecionado: any;

    public loadRecursoDesdobramento: boolean = false;
    public recursosDesdobramentos: any;
    public exibirDetalhesRecursoDesdobramento: boolean = false;
    public recursoDesdobramentoSelecionado: any;
    public tipoProcessoJudicialOcorrenciaSelecionado: any;
    public loadTipoOcorrencia: boolean = false;
    public loadMensagemProcessoLocalizado: boolean = false;

    public filtroPublicacao: string;

    public botoes: any = {
        edit: false,
        adicionarPrazo: false,
        adicionarAudiencia: false,
        adicionarTarefa: false,
        adicionarEvento: false,
        adicionarHistoricoManual: false
    }

    public prazo: any = {
        isEdit: false,
        alerta: 0,
        tipoAlerta: 1,
        envolvidos: []
    };

    public audiencia: any = {
        isEdit: false,
        alerta: 0,
        tipoAlerta: 1,
    }

    public tarefa: any = {
        isEdit: false
    }

    public evento: any = {
        isEdit: false,
        alerta: 0,
        tipoAlerta: 1,
        adicionarEmails: false,
        emails: [],
        envolvidos: []
    }

    public historicoManual: any = {
        isEdit: false
    }

    public tipoOcorrencia: any = [];

    public apensos: any = [];

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    constructor(
        private activatedRoute: ActivatedRoute,
        private ngbModal: NgbModal,
        public publicacaoService: PublicacaoService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private location: Location,
        private processosJudiciaiServices: ProcessosJudiciaisService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private tipoOcorrenciaJudicialService: TipoOcorrenciaJudicialService,
        private processosJudiciaisApensosService: ProcessosJudiciaisApensosService
    ) { }


    ngOnInit(): void {

        this.id = this.activatedRoute.snapshot.params['id'];
        this.buscarPublicacao();
        this.buscarTipoOcorrenciaJudicial();
    }

    buscarTipoOcorrenciaJudicial() {
        this.loadTipoOcorrencia = true;

        this.tipoOcorrenciaJudicialService.getAllAsync()
            .then(res => {
                if (res.success) {
                    this.tipoOcorrencia = res.data;
                    this.loadTipoOcorrencia = false;
                }
            })
    }

    buscarPublicacao() {
        this.loadPublicacao = true;
        this.spinner.show();
        this.publicacaoService.getById(this.id)
            .subscribe(res => {
                this.publicacao = res.data;
                this.loadPublicacao = false;
                this.publicacao.edit = false;
                this.filtroPublicacao = this.publicacao.numeroProcesso;
                this.buscarProcessos();
                this.spinner.hide();
                console.log(res.data);
            })
    }

    buscarProcessos() {
        this.loadProcessos = true;
        this.processosJudiciaiServices.getAllFilter(this.filtroPublicacao)
            .subscribe(res => {
                this.processos = res.data;
                if (this.processos.length == 1) {
                    
                    this.processoSelecionado = this.processos[0].id;
                    this.carregarRecursosDesdobramento();
                    this.loadMensagemProcessoLocalizado = true;

                } else {

                    var filtro = this.processos.filter(c => c.numero == this.publicacao.numeroProcesso);

                    if (filtro != undefined && filtro.length > 0) {
                        this.processoSelecionado = filtro[0].id;
                        this.carregarRecursosDesdobramento();
                        this.loadMensagemProcessoLocalizado = true;
                    }
                }

                this.loadProcessos = false;
            })
    }

    getClassFromPub(publicacao){
        if(publicacao.audienciaReferenciaId != null){
            return 'audiencia';
        }
        if(publicacao.prazoReferenciaId != null){
            return 'prazo';
        }
        if(publicacao.tarefaReferenciaId != null){
            return 'tarefa';
        }
        return "";
    }

    openModalComments(listaAtividades: Array<any>) {
        const  atividade = listaAtividades.find(function(obj){
            if('tipoOcorrencia' in obj){
                return obj;
            }
        });
        console.log(atividade);
        this.spinner.show();

        this.processosJudiciaiServices.obterPorRecursoDesdobramentoId(atividade.processoJudicialRecursoDesdobramentoId).subscribe(processo => {
            this.processosJudiciaiServices.obterAtividade(atividade.id, atividade.tipoOcorrencia).subscribe(res => {
                const modalRef = this.ngbModal.open(ModalDetalheComponent, {
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false
                });

                modalRef.componentInstance.modalRef = modalRef;
                modalRef.componentInstance.id = atividade.id;
                modalRef.componentInstance.tipoOcorrencia = atividade.tipoOcorrencia;
                modalRef.componentInstance.descricao = atividade.title;
                modalRef.componentInstance.tipoObjeto = atividade.tipoObjeto;
                modalRef.componentInstance.tipoStatusAtividade = res.data.tipoStatusAtividade;
                modalRef.componentInstance.responsavelNome = atividade.responsavelNome;
                modalRef.componentInstance.dataInicioPrazo = atividade.dataInicioPrazo;
                modalRef.componentInstance.estilo = atividade.estilo;
                modalRef.componentInstance.corAlertaData = atividade.corAlertaData;
                modalRef.componentInstance.numeroProcesso = processo.data.numero;
                modalRef.componentInstance.processo = processo.data;
                modalRef.componentInstance.item = res.data;
                modalRef.componentInstance.exibirAcoes = false;

                this.spinner.hide();
                modalRef.result.then(result => {

                }).catch(error => { });
            });
        });
    }


    editarOcorrencia(publicacao: any) {
        const  atividade = publicacao.listaAtividades.find(function(obj){
            if('tipoOcorrencia' in obj){
                return obj;
            }
        });
        this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(atividade.processoJudicialRecursoDesdobramentoId)
            .subscribe(res => {
                if(res.success){
                    this.spinner.show();

                    const modalRef = this.ngbModal.open(ModalOcorrenciaComponent, {
                        size: 'lg',
                        backdrop: 'static',
                        keyboard: false
                    });

                    let recursoDesdobramento = res.data;

                    modalRef.componentInstance.modalRef = modalRef;
                    modalRef.componentInstance.page = 'processo-judiciais-detalhes';
                    modalRef.componentInstance.recursoDesdobramento = recursoDesdobramento;
                    modalRef.componentInstance.editar(atividade.processoJudicialOcorrenciaId);
                    

                    modalRef.result.then(result => {
                       
                    }).catch(error => { });
                }
            })

    }

    filtrarProcesso(term){
        if(term.target.value != ''){
            this.exibirDetalhesRecursoDesdobramento = false;
            this.filtroPublicacao = term.target.value;
            this.buscarProcessos();            
        }
    }

    buscarRecursoDesdobramento(processoId: string) {

        this.loadRecursoDesdobramento = true;
        
        this.processosJudiciaisRecursoDesdobramentoService.obterPorProcessoComRecurso(processoId)
            .subscribe(res => {
                
                if(res.data.length == 1){
                    this.recursoDesdobramentoSelecionado = res.data[res.data.length - 1].id;                             
                }else{
                    var filtro = res.data.filter(c => c.numero == this.publicacao.numeroProcesso);

                    if(filtro != undefined && filtro.length == 1){
                        this.recursoDesdobramentoSelecionado = filtro[0].id;
                    }
                }
                console.log(res.data);
                this.recursosDesdobramentos = res.data;      
                this.loadRecursoDesdobramento = false;
            })
    }

    carregarRecursosDesdobramento() {
        this.exibirDetalhesRecursoDesdobramento = true;
        this.buscarRecursoDesdobramento(this.processoSelecionado);
        this.buscarApensos();
    }

    buscarApensos() {
        this.processosJudiciaisApensosService.obterApessosReplicarOcorrenciaPorProcessoJudicialId(this.processoSelecionado)
            .subscribe(res => {
                this.apensos = res.data;
                
                if(this.apensos != undefined && this.apensos.length>0){
                    this.apensos.forEach(c => {
                        c.selecionado = true;
                    })
                }
            })
    }

    close() {
        window.close();
    }

    descartarPublicacao() {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente descartar a publicação?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.publicacaoService.descartarPublicacao(this.id)
                    .subscribe(res => {

                        this.close();

                    }, error => {

                        this.spinner.hide();

                        this.swalWithBootstrapButtons.fire(
                            'Não foi possível descartar a publicação!',
                            '',
                            'error'
                        );
                    });
            }
        });
    }

    concluirPublicacao() {
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja concluir a publicação?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.publicacaoService.concluirPublicacao(this.id)
                    .subscribe(res => {

                        this.close();

                    }, error => {

                        this.spinner.hide();

                        this.swalWithBootstrapButtons.fire(
                            'Não foi possível concluir a publicação!',
                            '',
                            'error'
                        );
                    });
            }
        });
    }

    desfazerDescarte() {
        this.spinner.show();

        this.publicacaoService.desfazerDescartarPublicacao(this.id)
            .subscribe(res => {

                this.close();

            }, error => {

                this.spinner.hide();

                this.swalWithBootstrapButtons.fire(
                    'Não foi possível descartar a publicação!',
                    '',
                    'error'
                );
            });
    }

    criarOcorrencia() {
        this.spinner.show();
        this.toastrService.clear();

        let publicacaoVincluloProcessoVM = {
            publicacaoId: this.id,
            processoJudicialId: this.processoSelecionado,
            processoJudicialRecursoDesdobramentoId: this.recursoDesdobramentoSelecionado,
            tipoOcorrencia: this.tipoProcessoJudicialOcorrenciaSelecionado,
            processoJudicialOcorrenciaId: Guid.createEmpty().toString(),
            processoJudiciaisReplicacao: []
        }
        debugger;

        if (this.apensos) {
            this.apensos.forEach(c => {
                if (c.selecionado) {
                    publicacaoVincluloProcessoVM.processoJudiciaisReplicacao.push(c.processoJudicialId);
                }
            });
        }

        this.publicacaoService.gerarOcorrencia(publicacaoVincluloProcessoVM)
            .subscribe(res => {
                this.buscarPublicacao();
                this.spinner.hide();
                this.exibirDetalhesRecursoDesdobramento = false;
            }, error => {

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    vincularOcorrencia(publicacao) {
        this.spinner.show();
        this.toastrService.clear();
        console.log(publicacao)
        const  atividade = publicacao.listaAtividades.find(function(obj){
            if('tipoOcorrencia' in obj){
                return obj;
            }
        });

        let publicacaoVincluloProcessoVM = {
            publicacaoId: publicacao.id,
            processoJudicialId: atividade.processoJudicialId,
            processoJudicialRecursoDesdobramentoId: atividade.processoJudicialRecursoDesdobramentoId,
            processoJudicialOcorrenciaId: atividade.processoJudicialOcorrenciaId,
            tipoOcorrencia: Guid.createEmpty().toString(),
            processoJudiciaisReplicacao: []
        }
        debugger;

        this.publicacaoService.vincularOcorrencia(publicacaoVincluloProcessoVM)
            .subscribe(res => {
                this.buscarPublicacao();
                this.spinner.hide();
                this.exibirDetalhesRecursoDesdobramento = false;
            }, error => {

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    validarPrazos(): boolean {

        if (!this.prazo.dataLimiteComponent) {
            
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a data limite do prazo!',
                '',
                'error'
            );

            return false;
        }

        this.prazo.dataLimite = new Date(this.prazo.dataLimiteComponent.year,
            this.prazo.dataLimiteComponent.month + 1,
            this.prazo.dataLimiteComponent.day);

        if (this.prazo.alerta && this.prazo.alerta < 0) {
            
            this.swalWithBootstrapButtons.fire(
                'Alerta informado no prazo é inválido!',
                '',
                'error'
            );

            return false;
        }

        if (!this.prazo.observacao) {
            
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a observação!',
                '',
                'error'
            );

            return false;
        }

        if (this.prazo.dataLimiteComponent) {

            this.prazo.dataLimite = new Date(this.prazo.dataLimiteComponent.year, this.prazo.dataLimiteComponent.month - 1, this.prazo.dataLimiteComponent.day);
        }

        if (this.prazo.dataRevisaoComponent) {

            this.prazo.dataRevisao = new Date(this.prazo.dataRevisaoComponent.year, this.prazo.dataRevisaoComponent.month - 1, this.prazo.dataRevisaoComponent.day);
        }

        if (this.prazo.envolvidos.length > 0) {
            this.prazo.envolvidos.forEach(element => {
                if (!element.envolvidoId) {
                    this.swalWithBootstrapButtons.fire(
                        'Envolvido não selecionado!',
                        '',
                        'error'
                    );

                    return false;
                }
            });
        }

        if (!this.prazo.responsavelId) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado o executor do prazo!',
                '',
                'error'
            );

            return false;
        }

        if (!this.prazo.revisorId) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado o revisor do prazo!',
                '',
                'error'
            );

            return  false;
        }

        if (!this.prazo.assistenteId) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado o assistente do prazo!',
                '',
                'error'
            );

            return false;
        }

        return true;
    }

    validarAudiencia(): boolean {
        let retorno = true;

        if (this.audiencia.tipoSessaoId == undefined || this.audiencia.tipoSessaoId == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o tipo de sessão da audiência!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.audiencia.responsavelId == undefined || this.audiencia.responsavelId == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o responsável da audiência!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.audiencia.dataHoraInicialComponent == undefined || this.audiencia.dataHoraInicialComponent == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a data da audiência!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.audiencia.dataHoraInicialTimeComponent == undefined || this.audiencia.dataHoraInicialTimeComponent == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a hora de início da audiência!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.audiencia.dataHoraInicialTimeComponent == undefined || this.audiencia.dataHoraInicialTimeComponent == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a hora de fim da audiência!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.audiencia.observacao == undefined || this.audiencia.observacao == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a observação!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        var horainicial = this.audiencia.dataHoraInicialTimeComponent.split(":");

        this.audiencia.dataHoraInicial = new Date(this.audiencia.dataHoraInicialComponent.year,
            this.audiencia.dataHoraInicialComponent.month - 1,
            this.audiencia.dataHoraInicialComponent.day,
            horainicial[0],
            horainicial[1]);

        var horafinal = this.audiencia.dataHoraFinalTimeComponent.split(":");

        this.audiencia.dataHoraFinal = new Date(this.audiencia.dataHoraInicialComponent.year,
            this.audiencia.dataHoraInicialComponent.month - 1,
            this.audiencia.dataHoraInicialComponent.day,
            horafinal[0],
            horafinal[1]);

        return retorno;
    }

    validarTarefa(): boolean {

        if (!this.tarefa.descricao) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado a descrição da tarefa!',
                '',
                'error'
            );

            return false;
        }

        if (!this.tarefa.responsavelId) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado o responsável da tarefa!',
                '',
                'error'
            );

            return false;
        }

        if (!this.tarefa.prioridade) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado a prioridade da Tarefa!',
                '',
                'error'
            );

            return false;
        }

        if (this.tarefa.dataTarefaComponent == null) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informada data!',
                '',
                'error'
            );

            return false;
        }

        if (this.tarefa.dataTarefaComponent) {
            this.tarefa.dataTarefa = new Date(this.tarefa.dataTarefaComponent.year, this.tarefa.dataTarefaComponent.month - 1, this.tarefa.dataTarefaComponent.day);
        }

        if (!this.tarefa.responsavelId) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado o executor da Tarefa!',
                '',
                'error'
            );

            return false;
        }

        if (!this.tarefa.revisorId) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado o revisor da Tarefa!',
                '',
                'error'
            );

            return  false;
        }

        if (!this.tarefa.assistenteId) {

            this.swalWithBootstrapButtons.fire(
                'Não foi informado o assistente da Tarefa!',
                '',
                'error'
            );

            return false;
        }

        return true;
    }

    validarEventos(): boolean {

        var retorno = true;

        if (this.evento.titulo == undefined || this.evento.titulo == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o título do evento!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.evento.dataHoraInicialComponent == undefined || this.evento.dataHoraInicialComponent == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a data de início do evento!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.evento.dataHoraFinalComponent == undefined || this.evento.dataHoraFinalComponent == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a data de fim do evento!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (!this.evento.diaInteiro) {
            if (this.evento.dataHoraInicialTimeComponent == undefined || this.evento.dataHoraInicialTimeComponent == null) {
                this.swalWithBootstrapButtons.fire(
                    'Não foi informado a hora de início do evento!',
                    '',
                    'error'
                );

                retorno = false;
            }

            if (!retorno) return false;

            if (this.evento.dataHoraFinalTimeComponent == undefined || this.evento.dataHoraFinalTimeComponent == null) {
                this.swalWithBootstrapButtons.fire(
                    'Não foi informado a hora de fim do evento!',
                    '',
                    'error'
                );

                retorno = false;
            }

            if (!retorno) return false;

            if (this.evento.observacao == undefined ||
                this.evento.observacao == null ||
                this.evento.observacao == '') {
                this.swalWithBootstrapButtons.fire(
                    'Não foi informado a observação!',
                    '',
                    'error'
                );

                retorno = false;
            }

            if (!retorno) return false;

            var horainicial = this.evento.dataHoraInicialTimeComponent.split(":");

            this.evento.dataHoraInicial = new Date(this.evento.dataHoraInicialComponent.year,
                this.evento.dataHoraInicialComponent.month + 1,
                this.evento.dataHoraInicialComponent.day,
                horainicial[0],
                horainicial[1]);

            var horainicial = this.evento.dataHoraFinalTimeComponent.split(":");

            this.evento.dataHoraFinal = new Date(this.evento.dataHoraFinalComponent.year,
                this.evento.dataHoraFinalComponent.month + 1,
                this.evento.dataHoraFinalComponent.day,
                horainicial[0],
                horainicial[1]);

        } else {
            this.evento.dataHoraInicial = new Date(this.evento.dataHoraInicialComponent.year,
                this.evento.dataHoraInicialComponent.month + 1,
                this.evento.dataHoraInicialComponent.day,
                0,
                0);

            this.evento.dataHoraFinal = new Date(this.evento.dataHoraFinalComponent.year,
                this.evento.dataHoraFinalComponent.month + 1,
                this.evento.dataHoraFinalComponent.day,
                23,
                59);
        }

        if (this.evento.responsavelId == undefined || this.evento.responsavelId == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o responsável do evento!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.evento.envolvidos.length > 0) {
            this.evento.envolvidos.forEach(element => {
                if (element.envolvidoId == undefined && retorno) {
                    this.swalWithBootstrapButtons.fire(
                        'Envolvido não selecionado!',
                        '',
                        'error'
                    );

                    retorno = false;
                }
            });
        }


        return retorno;
    }

    validarHistoricoManual(): boolean {

        var retorno = true;

        if (!retorno) return false;

        if (this.historicoManual.descricao == undefined || this.historicoManual.descricao == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a descrição do histórico!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.historicoManual.responsavelId == undefined || this.historicoManual.responsavelId == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado o responsável do histórico!',
                '',
                'error'
            );

            retorno = false;
        }

        if (!retorno) return false;

        if (this.historicoManual.dataHistoricoComponent == undefined || this.historicoManual.dataHistoricoComponent == null) {
            this.swalWithBootstrapButtons.fire(
                'Não foi informado a data do histórico!',
                '',
                'error'
            );

            retorno = false;
        } else {
            this.historicoManual.dataHistorico = new Date(this.historicoManual.dataHistoricoComponent.year, this.historicoManual.dataHistoricoComponent.month - 1, this.historicoManual.dataHistoricoComponent.day);
        }

        if (!retorno) return false;


        return retorno;
    }


    verificarTipoPublicacao(publicacao){
        return publicacao.audienciaReferenciaId != null && 
            publicacao.prazoReferenciaId != null && 
            publicacao.tarefaReferenciaId != null
        
    }

    gravarPrazo() {

        let retorno = this.validarPrazos();

        if (!retorno) return;

        let publicacaoPrazo = {
            publicacao: this.publicacao,
            prazo: this.prazo
        };

        this.toastrService.clear();
        this.spinner.show();

        if (!this.prazo.isEdit) {
            this.publicacaoService.adicionarPrazo(publicacaoPrazo)
                .subscribe(res => {
                    this.spinner.hide();
                    this.desabilitarBotoes();
                    this.buscarPublicacao();
                }, error => {

                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar o prazo!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });

        } else {
            this.publicacaoService.editarPrazo(publicacaoPrazo)
                .subscribe(res => {
                    this.spinner.hide();
                    this.desabilitarBotoes();
                    this.buscarPublicacao();
                }, error => {

                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar o prazo!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        }
    }

    gravarAudiencia() {
        let retorno = this.validarAudiencia();

        if (!retorno) return;

        let publicacaoVMAudiencia: any = {
            publicacao: this.publicacao,
            audiencia: this.audiencia
        }

        this.toastrService.clear();
        this.spinner.show();

        if (!this.audiencia.isEdit) {
            this.publicacaoService.adicionarAudiencia(publicacaoVMAudiencia).subscribe(res => {
                this.spinner.hide();
                this.desabilitarBotoes();
                this.buscarPublicacao();
            }, error => {

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a audiência!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
        } else {
            this.publicacaoService.editarAudiencia(publicacaoVMAudiencia).subscribe(res => {
                this.spinner.hide();
                this.desabilitarBotoes();
                this.buscarPublicacao();
            }, error => {

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar a audiência!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
        }
    }

    gravarTarefa() {
        let retorno = this.validarTarefa();

        if (!retorno) return;

        let publicacaoVMTarefa: any = {
            publicacao: this.publicacao,
            tarefa: this.tarefa
        }

        this.toastrService.clear();
        this.spinner.show();
        this.publicacaoService.adicionarTarefa(publicacaoVMTarefa).subscribe(res => {
            this.spinner.hide();
            this.desabilitarBotoes();
            this.buscarPublicacao();
        }, error => {

            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar a tarefa!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    gravarEvento() {
        let retorno = this.validarEventos();

        if (!retorno) return;

        let publicacaoVMEvento: any = {
            publicacao: this.publicacao,
            evento: this.evento
        }

        this.toastrService.clear();
        this.spinner.show();
        this.publicacaoService.adicionarEvento(publicacaoVMEvento).subscribe(res => {
            this.spinner.hide();
            this.desabilitarBotoes();
            this.buscarPublicacao();
        }, error => {

            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar o evento!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    gravarHistoricoManual() {
        let retorno = this.validarHistoricoManual();

        if (!retorno) return;

        let publicacaoVMHistoricoManual: any = {
            publicacao: this.publicacao,
            historico: this.historicoManual
        }

        this.toastrService.clear();
        this.spinner.show();
        this.publicacaoService.adicionarHistoricoManual(publicacaoVMHistoricoManual).subscribe(res => {
            this.spinner.hide();
            this.desabilitarBotoes();
            this.buscarPublicacao();
        }, error => {

            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar o histórico!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    desabilitarBotoes() {
        Object.keys(this.botoes).forEach(c => this.botoes[c] = false);
    }

    adicionarPrazo() {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarPrazo = true;

        this.prazo = {
            isEdit: false,
            alerta: 0,
            tipoAlerta: 1,
            envolvidos: []
        };
    }

    editarPrazo(prazo: any) {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarPrazo = true;

        this.prazo = prazo;

        if (this.prazo.dataLimite != undefined) {
            this.prazo.dataLimite = new Date(this.prazo.dataLimite);

            this.prazo.dataLimiteComponent = {
                year: this.prazo.dataLimite.getFullYear(),
                month: this.prazo.dataLimite.getMonth() + 1,
                day: this.prazo.dataLimite.getDate()
            }
        }

        if (this.prazo.dataRevisao != undefined) {
            this.prazo.dataRevisao = new Date(this.prazo.dataRevisao);

            this.prazo.dataRevisaoComponent = {
                year: this.prazo.dataRevisao.getFullYear(),
                month: this.prazo.dataRevisao.getMonth() + 1,
                day: this.prazo.dataRevisao.getDate()
            }
        }

        this.prazo.isEdit = true;
    }

    adicionarAudiencia() {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarAudiencia = true;

        this.audiencia = {
            isEdit: false,
            alerta: 0,
            tipoAlerta: 1,
        }
    }

    editarAudiencia(audiencia: any) {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarAudiencia = true;

        if (audiencia.dataHoraInicial != undefined && audiencia.dataHoraInicial != null) {
            let data = new Date(audiencia.dataHoraInicial);

            audiencia.dataHoraInicialComponent =
            {
                year: data.getFullYear(),
                month: data.getMonth() + 1,
                day: data.getDate()
            };

            audiencia.dataHoraInicialTimeComponent = data.toLocaleTimeString();
        }

        if (audiencia.dataHoraFinal != undefined && audiencia.dataHoraFinal != null) {

            let data = new Date(audiencia.dataHoraFinal);

            audiencia.dataHoraFinalTimeComponent = data.toLocaleTimeString();
        }

        this.audiencia = audiencia;

        this.audiencia.isEdit = true;
    }

    adicionarTarefa() {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarTarefa = true;

        this.tarefa = {
            isEdit: false
        }
    }

    editarTarefa(tarefa: any) {
        this.desabilitarBotoes();

        this.botoes.edit = true;

        this.botoes.adicionarTarefa = true;

        tarefa.isEdit = true;

        if (tarefa.dataTarefa != undefined && tarefa.dataTarefa != null) {
            let data = new Date(tarefa.dataTarefa);

            tarefa.dataTarefaComponent =
            {
                year: data.getFullYear(),
                month: data.getMonth() + 1,
                day: data.getDate()
            };
        }

        this.tarefa = tarefa;
    }

    adicionarEvento() {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarEvento = true;

        this.tarefa = {
            isEdit: false,
            alerta: 0,
            tipoAlerta: 1,
            adicionarEmails: false,
            emails: [],
            envolvidos: []
        }
    }

    editarEvento(evento: any) {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarEvento = true;
        evento.isEdit = true;
        if (evento.dataHoraInicial != undefined && evento.dataHoraInicial != null) {
            let data = new Date(evento.dataHoraInicial);

            evento.dataHoraInicialComponent =
            {
                year: data.getFullYear(),
                month: data.getMonth() + 1,
                day: data.getDate()
            };

            evento.dataHoraInicialTimeComponent = data.toLocaleTimeString();
        }

        if (evento.dataHoraFinal != undefined && evento.dataHoraFinal != null) {
            let data = new Date(evento.dataHoraFinal);

            evento.dataHoraFinalComponent =
            {
                year: data.getFullYear(),
                month: data.getMonth() + 1,
                day: data.getDate()
            };

            evento.dataHoraFinalTimeComponent = data.toLocaleTimeString();
        }

        this.evento = evento;
    }

    adicionarHistoricoManual() {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarHistoricoManual = true;

        this.historicoManual = {
            isEdit: false,
        }
    }

    editarHistorico(historico: any) {
        this.desabilitarBotoes();
        this.botoes.edit = true;
        this.botoes.adicionarHistoricoManual = true;

        historico.isEdit = true;

        let data = new Date(historico.dataHistorico);

        historico.dataHistoricoComponent =
        {
            year: data.getFullYear(),
            month: data.getMonth() + 1,
            day: data.getDate()
        };

        this.historicoManual = historico;
    }

    filtrarProcessoDropDown(term: string, item: any) {
        term = term.toLocaleLowerCase();

        return item.instanciaNome?.toLocaleLowerCase().indexOf(term) > -1 ||
            item.numero?.toLocaleLowerCase().indexOf(term) > -1 ||
            item.titulo?.toLocaleLowerCase().indexOf(term) > -1 ||
            (item.clientes.length > 0 && item.clientes.filter(obj => { return obj.nome?.toLocaleLowerCase().indexOf(term) > -1 }).length > 0);
    }
}
