import { Component, OnInit } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AtendimentoEncerramentoService } from "src/app/services/atendimento-encerramento.service";
import { AtendimentoService } from "src/app/services/atendimento.service";
import { NgbDateCustomParserFormatter } from "src/app/utils/dateformat";
import { UtilsService } from "src/app/utils/utils.service";

@Component({
  selector: "app-modal-encerramento",
  templateUrl: "./modal-encerramento.component.html",
  styleUrls: ["./modal-encerramento.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class ModalEncerramentoComponent implements OnInit {
  private modalRef: NgbModalRef;
  public atendimentoEncerramentos: any = [];
  public atendimentoEncerramentoId: any;
  public load: boolean = true;
  public atendimentoId: string;
  public descricao: string;
  public dataEncerramento;
  public horaEncerramento;

  constructor(
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public atendimentoService: AtendimentoService,
    public atendimentoEncerramentoService: AtendimentoEncerramentoService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.buscarTodos();
    this.carregarDadosIniciaisEncerramento();
  }

  buscarTodos() {
    this.atendimentoEncerramentoService.getAll().subscribe((res) => {
      this.atendimentoEncerramentos = res.data;
      this.spinner.hide();
      this.load = false;
    });
  }

  salvar() {
    this.spinner.show();

    const data = {
      atendimentoId: this.atendimentoId,
      atendimentoEncerramentoId: this.atendimentoEncerramentoId,
      descricao: this.descricao == undefined ? "" : this.descricao,
      dataEncerramento: this.utilsService.formatarDataNgbDatepickerParaRequisicao(this.dataEncerramento),
      horaEncerramento: this.horaEncerramento,
    };

    this.atendimentoService.modificarStatus(data).subscribe(
      (res) => {
        this.modalRef.close();
      },
      (error) => {
        this.toastrService.error("Não foi possível editar!", "Atenção", {
          timeOut: 10000,
        });
      }
    );
  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }

  carregarDadosIniciaisEncerramento() {
    const dataAtual = new Date();

    this.dataEncerramento = {
      year: dataAtual.getFullYear(),
      month: dataAtual.getMonth() + 1,
      day: dataAtual.getDate(),
    };

    const horaAtual = dataAtual.toTimeString().slice(0, 5); // Pegar os primeiros 5 caracteres (hh:mm)
    this.horaEncerramento = horaAtual;
  }
}
