<div class="form-row">
    <div class="form-group col-8">
        <label class="form-label">Cliente</label>
        <div class="input-group">
            <input class="form-control" [(ngModel)]="clienteNome" [ngModelOptions]="{standalone: true}"
                [disabled]="true">
        </div>
    </div>
</div>
<div class="form-row">
    <div class="form-group col-4">
        <label class="form-label">Tipo de Faturamento</label>
        <ng-select [items]="tiposFaturamento" [(ngModel)]="processo.tipoFaturamento"
            [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
            [loading]="processo == undefined || processo == null" (change)="selecionarFaturamento()"></ng-select>
    </div>
</div>
<div class="form-row" *ngIf="this.valorSalarioCorrente != undefined && processo.tipoFaturamento != undefined && processo.tipoFaturamento != null">
    <div class="col-12">
        <ul ngbNav #faturamentoTabs="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink>Initial Fee</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="form-group col-3">
                            <label class="form-label">Quantidade de salários mínimos</label>
                            <input type="number" class="form-control" placeholder=""
                                [(ngModel)]="processo.quantidadeSalarioMinimoInicialFee" (blur)="calcularSalario()">
                        </div>
                        <div class="col-9 salario-display">
                            {{valorSalario}}
                        </div>
                        <div class="form-group col-12">
                            <label class="form-label">Faixas Initial Fee {{processo.tipoFaturamento == 0 ? 'Público':
                                'Privado'}}</label>
                            <div style="display: flex;flex-direction: column;justify-content: center;"
                                class="text-center mt-5" *ngIf="processo.honorariosInitialFee == undefined">
                                <div class="fa-3x">
                                    <i class="fas fa-spinner fa-spin"></i>
                                </div>
                            </div>
                            <ng2-smart-table *ngIf="processo.honorariosInitialFee != undefined"
                                [settings]="settingsHonorariosJDValores" [source]="processo.honorariosInitialFee"
                                (editConfirm)="onCreateEditHonorarioJD($event)"
                                (createConfirm)="onCreateEditHonorarioJD($event)"
                                (deleteConfirm)="onDeleteConfirmHonorariosJD($event)"
                                class="table-responsive background-color-white table-modal-tabs-scroll">
                            </ng2-smart-table>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Success Fee</a>
                <ng-template ngbNavContent>
                    <div class="form-group col-12 mt-2">
                        <label class="form-label">Faixas Success Fee {{processo.tipoFaturamento == 0 ? 'Público':
                            'Privado'}}</label>
                        <div style="display: flex;flex-direction: column;justify-content: center;"
                            class="text-center mt-5" *ngIf="processo.honorariosSuccessFee == undefined">
                            <div class="fa-3x">
                                <i class="fas fa-spinner fa-spin"></i>
                            </div>
                        </div>
                        <ng2-smart-table *ngIf="processo.honorariosSuccessFee != undefined"
                            [settings]="settingsHonorariosJDValores" [source]="processo.honorariosSuccessFee"
                            (editConfirm)="onCreateEditHonorarioJD($event)"
                            (createConfirm)="onCreateEditHonorarioJD($event)"
                            (deleteConfirm)="onDeleteConfirmHonorariosJD($event)"
                            class="table-responsive background-color-white table-modal-tabs-scroll">
                        </ng2-smart-table>
                    </div>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="col-12 border-card" [ngbNavOutlet]="faturamentoTabs">

    </div>
</div>