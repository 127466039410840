<div [formGroup]="form" *ngIf="form">
    <!-- Modal template -->
    <div class="modal-header">
        <h5 class="modal-title">{{isUpdate ? 'Editar' : 'Cadastrar'}} Nota de Débito</h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <!-- filter -->

        <div class="row">
            <div class="form-group col-10">
                <input type="text" class="form-control" formControlName="nomeCliente" [disabled]="true" />
            </div>
            <div class="form-group col-2">
                <button type="button" class="btn btn-primary" (click)="gerarArquivoNotaDebito()">
                    {{arquivoLabel}} <i class="{{classLabel}}"></i>
                    <div style="
                        display: flex;
                        flex-direction: column;
                        height: 20px;
                        justify-content: center;
                        align-items: center;
                        align-self: center;
                        align-content: center;" class="fa-1x" *ngIf="loadGerarArquivo">
                            <i class="fas fa-spinner fa-spin"></i>
                        </div>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-3">
                <label class="form-label">Data Vencimento</label>
                <div class="input-group">
                    <input (click)="FilterDataVencimento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" ngbDatepicker formControlName="dataVencimento" [displayMonths]="displayMonths"
                        [navigation]="navigation" placement="bottom-right" #FilterDataVencimento="ngbDatepicker" [disabled]="isUpdate"/>
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="FilterDataVencimento.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="form-group col-3">
                <label class="form-label">Data Pagamento</label>
                <div class="input-group">
                    <input (click)="FilterDataPagamento.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" ngbDatepicker formControlName="dataPagamento" [displayMonths]="displayMonths"
                        [navigation]="navigation" placement="bottom-right" #FilterDataPagamento="ngbDatepicker" />
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="FilterDataPagamento.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group col-2">
                <label class="form-label">Número Recibo</label>
                <div class="input-group">
                    <input class="form-control"
                        formControlName="numeroRecibo" [disabled]="isUpdate"/>
                </div>

            </div>
            <div class="form-group col-2">
                <label class="form-label">Valor Nota</label>
                <div class="input-group">
                    <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                        formControlName="valorDebito" [disabled]="isUpdate"/>
                </div>

            </div>
            <div class="form-group col-2">
                <label class="form-label">Valor Pago</label>
                <div class="input-group">
                    <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                        formControlName="valorCredito" />
                </div>

            </div>
        </div>

        <div class="row">
            <div class="form-group col">
                <label class="form-label">Observação</label>
                <textarea class="form-control" formControlName="observacao" rows="6" [disabled]="isUpdate"></textarea>
            </div>
        </div>

        <div class="row" *ngIf="listaArquivoUpload.length > 0">
            <div class="col-12" *ngFor="let item of listaArquivoUpload; let idx = index">
                {{item?.name}}
                <button type="button" class="btn btn-danger btn-sm" (click)="popItem(idx)">
                    <i class="fas fa-trash"></i>
                </button>
                
            </div>
        </div>

        <br />

        <div class="nav-tabs-top nav-responsive-sm">
            <ul [(activeId)]="activeTab" ngbNav #productTabs="ngbNav" class="nav-tabs">
                <li ngbNavItem [ngbNavItem]="1">
                    <a ngbNavLink>Despesas</a>
                    <ng-template ngbNavContent>
                        <div style="
                display: flex;
                flex-direction: column;
                height: 200px;
                justify-content: center;
                align-items: center;
                align-self: center;
                align-content: center;
              " class="fa-3x" *ngIf="loadNaoFaturadas">
                            <i class="fas fa-spinner fa-spin"></i>
                        </div>
                        <table class="table table-striped table-bordered card-table" disabled>
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Data Lançamento</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listaDespesas;">
                                    <ng-container>
                                        <td>{{ item.descricao }}</td>
                                        <td>{{ item.data | date : "dd/MM/yyyy" }}</td>
                                        <td>{{ item.valor | currency : "BRL" }}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </li>
                <li ngbNavItem [ngbNavItem]="2">
                    <a ngbNavLink (click)="getArquivosDespesas()">Arquivos Despesas
                        <div style="margin-left: 10px" *ngIf="loadArquivosDespesas"
                            class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <!-- Table -->
                            <hr class="border-light m-0">
                            <div class="table-responsive height-table" 
                                *ngIf="!loadArquivosDespesas && listArquivosDespesas && listArquivosDespesas.length > 0">
                                <table class="table table-striped table-bordered card-table ">
                                    <thead>
                                        <tr>
                                            <th class="text-nowrap cursor-pointer"
                                                (click)="setSortNotaDebitoPendente('nomeArquivo')">
                                                Arquivo<i class="ion text-muted ml-2"
                                                    *ngIf="sortByNotaDebitoPendente =='nomeArquivo'"
                                                    [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                            </th>
                                            <th class="text-nowrap cursor-pointer"
                                                (click)="setSortNotaDebitoPendente('inseridoPor')">
                                                Inserido Por<i class="ion text-muted ml-2"
                                                    *ngIf="sortByNotaDebitoPendente =='inseridoPor'"
                                                    [ngClass]="'ion-md-arrow-' + (sortDescNotaDebitoPendente ? 'down' : 'up')"></i>
                                            </th>
        
                                            <th class="text-center text-nowrap align-middle">Ações</th>
                                        </tr>
        
                                    </thead>
                                    <tbody>
                                        <tr class="tabela" [ngClass]="{corrompido: arquivo.arquivoCorrompido}" *ngFor="let arquivo of listArquivosDespesas">
                                            <td>{{ arquivo.nomeArquivo }}</td>
                                            <td>{{ arquivo.inseridoPor }}</td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="handleDownloadArquivo(arquivo.id)">
                                                    <i class="fas fa-download"></i>
                                                </button>
                                                <input style="display: none" type="file" accept="application/pdf" (change)="onFilechange($event, arquivo)" #hiddenfileinput>

                                                <button (click)="hiddenfileinput.click()"  class="btn btn-info btn-sm"><i class="fas fa-upload"></i></button> 
                                               
                                            </td>
                                        </tr>
        
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center mt-5"
                                *ngIf="!loadArquivosDespesas && (!listArquivosDespesas || listArquivosDespesas.length == 0)">
                                <h3>Não possui dados.</h3>
                            </div>
                            <div class="row right" *ngIf="!loadArquivosDespesas && listArquivosDespesas &&  listArquivosDespesas.length > 0">
                                <div class='my-legend'>
                                    <div class='legend-title'>Status:</div>
                                    <div class='legend-scale'>
                                        <ul class='legend-labels'>
                                            <li><span class="corrompido"></span>Corrompido</li>
                                            
                                        </ul>
                                    </div>
                                    <div class="legend-source">A linha colorida refere-se ao <strong>arquivo</strong></div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="productTabs"></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">
            Fechar
        </button>
        <button type="button" class="btn btn-primary" (click)="salvar()">
            <i class="fas fa-save"></i> Pagar
        </button>
    </div>
</div>