<div class="modal-header">
    <h4>Multa do Art. 523 NCPC</h4>
</div>
<div class="modal-body">
    <form [formGroup]="multaArtNCP">
        <div class="row">
            <div class="form-check col-12">
                <input id="valorAtualizado" type="checkbox" formControlName="valorAtualizado">
                <label for="valorAtualizado">Valor atualizado</label>
            </div>
            <div class="form-check col-12">
                <input id="jurosMoratorios" type="checkbox" formControlName="jurosMoratorios">
                <label for="jurosMoratorios">Juros moratórios</label>
            </div>            
            <div class="form-check col-12">
                <input id="jurosCompensatorios" type="checkbox" formControlName="jurosCompensatorios">
                <label for="jurosCompensatorios">Juros compensatório</label>
            </div>
            <div class="form-check col-12">
                <input id="multa" type="checkbox" formControlName="multa">
                <label for="multa">Multa</label>
            </div>
            <div class="form-check col-12">
                <input id="honorarios" type="checkbox" formControlName="honorarios">
                <label for="honorarios">Honorários</label>
            </div>
            <div class="form-check col-12">
                <input id="honorariosSucumbencia" type="checkbox" formControlName="honorariosSucumbencia">
                <label for="honorariosSucumbencia">Honorários sucumbenciais</label>
            </div>
            <div class="form-check col-12">
                <input id="custasAtualizadas" type="checkbox" formControlName="custasAtualizadas">
                <label for="custasAtualizadas">Custas atualizadas</label>
            </div>
        </div>
    </form>
    <hr />
    <div class="row">
        <div class="col-12 d-flex justify-content-end">
            <div class="mr-2">
                <button type="button" class="btn background-color-white" (click)="dismiss()">Cancelar</button>
            </div>
            <div>
                <button type="button" class="btn btn-padrao btn-block" (click)="close()">Salvar</button>
            </div>
        </div>
    </div>    
</div>