<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos /</span>
        <span *ngIf="isAddMode">Cadastrar</span><span *ngIf="!isAddMode">Editar</span> {{processo.caso ?
        'Caso':'Processo'}}</span>
</h4>
<div class="container-fluid mb-5" *ngIf="!processo.loading">
    <form ngNoForm>
        <div class="row">
            <div class="col-12">
                <ul ngbNav #processoCadastro="ngbNav" class="nav-tabs">
                    <li ngbNavItem>
                        <a ngbNavLink>Dados do Processo</a>
                        <ng-template ngbNavContent>
                            <div class="form-row">
                                <div class="col">
                                    <label class="form-label d-flex align-items-center">
                                        Cliente<span class="required-field ml-1">*</span>
                                        <button type="button" class="btn btn-primary btn-xs ml-3"
                                            (click)="adicionarCliente()"><i class="fas fa-plus"></i></button>
                                    </label>
                                </div>
                            </div>
                            <div class="form-row mt-2" *ngFor="let cliente of processo.clientes; let i = index">
                                <div class="form-group col-12">
                                    <input type="checkbox" [(ngModel)]="cliente.principal"
                                        (change)="onCheckboxClienteChange(i)" id="checkboxCliente_{{i}}" />
                                    <label class="form-check-label mt-2" for="checkboxCliente_{{i}}">
                                        Principal
                                    </label>
                                </div>
                                <div class="form-group col-7">
                                    <pessoa-dropdown [(pessoaId)]="cliente.pessoaId"
                                        (change)="onPessoaChange($event)"></pessoa-dropdown>
                                </div>
                                <div class="form-group col-4">
                                    <ng-select [items]="qualificacoes" [(ngModel)]="cliente.qualificacaoId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Qualificação" [loading]="loadQualificacoes"></ng-select>
                                </div>
                                <div class="form-group col-1">
                                    <button type="button" class="btn btn-danger" (click)="removerCliente(i, cliente)"><i
                                            class="fas fa-trash"></i></button>
                                </div>
                            </div>
                            <div class="form-row mt-2">
                                <div class="col">
                                    <label class="form-label d-flex align-items-center">
                                        Outros envolvidos
                                        <button type="button" class="btn btn-primary btn-xs ml-3"
                                            (click)="adicionarEnvolvido()"><i class="fas fa-plus"></i></button>
                                    </label>
                                </div>
                            </div>
                            <div class="form-row" *ngFor="let envolvido of processo.envolvidos; let i = index">
                                <div class="form-group col-7">
                                    <pessoa-dropdown [(pessoaId)]="envolvido.pessoaId"
                                        (change)="onPessoaChange($event)"></pessoa-dropdown>
                                </div>
                                <div class="form-group col-4">
                                    <ng-select [items]="qualificacoes" [(ngModel)]="envolvido.qualificacaoId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Qualificação" [loading]="loadQualificacoes"></ng-select>
                                </div>
                                <div class="form-group col-1">
                                    <button type="button" class="btn btn-danger" (click)="removerEnvolvido(i, envolvido)"><i
                                            class="fas fa-trash"></i></button>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Pasta</label>
                                    <input type="text" class="form-control" maxlength="255"
                                        placeholder="Digite o nome ou número da pasta"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.pasta">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Título <span class="required-field">*</span></label>
                                    <input type="text" class="form-control" maxlength="255"
                                        placeholder="Digite o título do processo" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="processo.titulo">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Tags</label>
                                    <angular2-multiselect [data]="tags" [(ngModel)]="processo.tags"
                                        [settings]="tagDropdownSettings" [ngModelOptions]="{standalone: true}">
                                        <c-item>
                                            <ng-template let-item="item">
                                                <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                                            </ng-template>
                                        </c-item>
                                        <c-badge>
                                            <ng-template let-item="item">
                                                <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                                            </ng-template>
                                        </c-badge>
                                    </angular2-multiselect>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col-sm-12 col-lg-4">
                                    <label class="form-label">Competência</label>
                                    <ng-select [items]="competencias" [(ngModel)]="processo.competenciaId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Competência" [loading]="loadCompetencias"></ng-select>
                                </div>
                                <div class="form-group col-sm-12 col-lg-4">
                                    <label class="form-label">Instância principal</label>
                                    <ng-select [items]="instancias" [(ngModel)]="processo.instanciaId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Instância" [loading]="loadInstancias"></ng-select>
                                </div>
                                <div class="form-group col-sm-12 col-lg-4">
                                    <label class="form-label">Número Padrão CNJ</label>
                                    <input type="text" [textMask]="{mask: mascaraNumero, guide: false}"
                                        class="form-control" placeholder="Digite o número do processo"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.numero"
                                        (blur)="numeroChanged($event.target.value)">
                                </div>
                            </div>
                            <div class="form-row mt-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="form-label cursor-pointer" for="btnAdicionarNumero">Outros números</label>
                                    <button type="button" id="btnAdicionarNumero" class="btn btn-primary btn-xs ml-3"
                                        (click)="adicionarNumero()"><i class="fas fa-plus"></i></button>
                                </div>
                            </div>
                            <div class="form-row mt-2">
                                <div class="form-group col-sm-12 col-lg-4 d-flex justify-content-between align-items-center" *ngFor="let outroNumero of processo.outrosNumeros; let i = index">
                                    <input type="text" [(ngModel)]="outroNumero.numero" [textMask]="{mask: mascaraNumero, guide: false}" class="form-control" placeholder="Adicione outro número no processo">
                                    <button type="button" class="btn btn-danger" (click)="removerNumero(i)"><i class="fas fa-trash"></i></button>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Divisão de órgão</label>
                                    <ng-select [items]="divisoes" [(ngModel)]="processo.divisaoDeOrgaoId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        [loading]="loadTipoPossibilidadeProcessoJudicial"></ng-select>
                                </div>
                                <div class="form-group col">
                                    <label class="form-label">Órgão</label>
                                    <ng-select [items]="orgaos" [(ngModel)]="processo.orgaoId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Órgão"
                                        [loading]="orgaos == undefined || !orgaos.length"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <!-- <div class="form-group col-2">
                                    <label class="form-label">Juízo</label>
                                    <input type="text" [textMask]="{mask: mascaraInteiro, guide: false}" class="form-control"
                                        placeholder="Nº" [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.juizo" maxlength="10">
                                </div> -->
                                <!-- <div class="form-group col-6">
                                    <label class="form-label">Tipo/Vara</label>
                                    <ng-select [items]="varas" [(ngModel)]="processo.varaId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Vara" [loading]="loadVaras"></ng-select>
                                </div> -->
                                <div class="form-group col">
                                    <label class="form-label">Foro</label>
                                    <ng-select [items]="foros" [(ngModel)]="processo.foroId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Foro" [loading]="loadForos"></ng-select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Fase</label>
                                    <ng-select [items]="fases" (click)="changeFase()"
                                        [(ngModel)]="processo.faseProcesso" [ngModelOptions]="{standalone: true}"
                                        bindLabel="nome" bindValue="id" placeholder="Digite a fase"
                                        [loading]="fases == undefined || !fases.length"></ng-select>
                                </div>
                                <div class="form-group col">
                                    <label class="form-label">Sub-fase</label>
                                    <ng-select [items]="situacoesProcesso" [(ngModel)]="processo.situacaoId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Digite a fase"
                                        [loading]="fases == undefined || !fases.length"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Ação</label>
                                    <ng-select [items]="acoes" [(ngModel)]="processo.acaoId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Digite a ação" [loading]="loadAcoes"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col-4">
                                    <label class="form-label">Comarca</label>
                                    <ng-select [items]="comarcas" [(ngModel)]="processo.comarcaId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Digite a comarca"
                                        [loading]="comarcas == undefined || !comarcas.length"></ng-select>
                                </div>
                                <div class="form-group col-4">
                                    <label class="form-label">UF</label>
                                    <ng-select [items]="ufs" [(ngModel)]="processo.ufId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="displayName" bindValue="id"
                                        placeholder="Digite a UF"
                                        [loading]="ufs == undefined || !ufs.length"></ng-select>
                                </div>
                                <div class="form-group col-4">
                                    <label class="form-label">Área</label>
                                    <ng-select [items]="areas" [(ngModel)]="processo.areaId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        placeholder="Digite a Área"
                                        [loading]="areas == undefined || !areas.length"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Tipo Processo</label>
                                    <ng-select [items]="ehProcessoEletronico"
                                        [(ngModel)]="ehProcessoEletronicoSelecionado"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        (click)="changeProcessoEletronico($event)"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="processo.processoEletronico && !processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Tipo processo eletrônico</label>
                                    <ng-select [items]="tiposProcessosEletronicos"
                                        [(ngModel)]="processo.tipoProcessoEletronicoId"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        [loading]="tiposProcessosEletronicos == undefined || tiposProcessosEletronicos.length == 0"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="processo.processoEletronico && !processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Link no tribunal</label>
                                    <input type="text" class="form-control" placeholder="Digite o link no tribunal"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.linkTribunal">
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Objeto</label>
                                    <textarea class="form-control" placeholder="Digite a descrição do processo" rows="2"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.objeto"></textarea>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Data Cadastro</label>
                                    <div class="input-group">
                                        <input
                                            [textMask]="{mask: mascaraData, keepCharPositions: true, pipe: mascaraDataAutoCorrected, guide: false}"
                                            class="form-control" placeholder="Digite a data" [(ngModel)]="dataCadastro"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="form-group col" *ngIf="!processo.caso">
                                    <label class="form-label">Data Distribuição</label>
                                    <div class="input-group">
                                        <input
                                            [textMask]="{mask: mascaraData, keepCharPositions: true, pipe: mascaraDataAutoCorrected, guide: false}"
                                            class="form-control" placeholder="Digite a data" [(ngModel)]="distribuidoEm"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="form-group col" *ngIf="!processo.caso">
                                    <label class="form-label">Data Entrada</label>
                                    <div class="input-group">
                                        <input
                                            [textMask]="{mask: mascaraData, keepCharPositions: true, pipe: mascaraDataAutoCorrected, guide: false}"
                                            class="form-control" placeholder="Digite a data" [(ngModel)]="dataEntrada"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Valor da causa</label>
                                    <input currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        type="text" class="form-control" placeholder="Digite o valor"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.valorCausa"
                                        maxlength="16">
                                </div>
                                <div class="form-group col">
                                    <label class="form-label">Valor histórico</label>
                                    <input currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        type="text" class="form-control" placeholder="Digite o valor"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.valorHistorico"
                                        maxlength="16">
                                </div>
                                <div class="form-group col">
                                    <label class="form-label">Valor atualizado</label>
                                    <input currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        type="text" class="form-control" placeholder="Digite o valor"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.valorAtualizado"
                                        maxlength="16">
                                </div>
                                <div class="form-group col">
                                    <label class="form-label">Valor da condenação</label>
                                    <input currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        type="text" class="form-control" placeholder="Digite o valor"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.valorCondenacao"
                                        maxlength="16">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Observações</label>
                                    <textarea class="form-control" placeholder="Digite mais detalhes" rows="2"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="processo.observacao"
                                        maxlength="1000"></textarea>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Possibilidade de perda de processo</label>
                                    <ng-select [items]="tiposPossibilidadesProcessosJudiciais"
                                        [(ngModel)]="processo.possibilidadePerda" [ngModelOptions]="{standalone: true}"
                                        bindLabel="nome" bindValue="id"
                                        [loading]="loadTipoPossibilidadeProcessoJudicial"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Justificativa da Possibilidade de perda de
                                        processo</label>
                                    <textarea class="form-control" placeholder="Digite mais detalhes" rows="2"
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="processo.justificativaPossibilidadePerda"
                                        maxlength="500"></textarea>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Possibilidade de não recebimento do crédito</label>
                                    <ng-select [items]="tiposPossibilidadesProcessosJudiciais"
                                        [(ngModel)]="processo.possibilidadeNaoRecebimentoCredito"
                                        [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                        [loading]="loadTipoPossibilidadeProcessoJudicial"></ng-select>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.caso">
                                <div class="form-group col">
                                    <label class="form-label">Justificativa da Possibilidade de não recebimento do
                                        crédito</label>
                                    <textarea class="form-control" placeholder="Digite mais detalhes" rows="2"
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="processo.justificativaNaoRecebimentoCredito"
                                        maxlength="500"></textarea>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="!processo.loading">
                                <div class="form-group col">
                                    <label class="form-label">Responsável<span class="required-field">*</span></label>
                                    <pessoa-dropdown [(pessoaId)]="processo.responsavelId"></pessoa-dropdown>
                                </div>
                            </div>
                            <!-- <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Revisor <span class="required-field">*</span></label>                
                                    <pessoa-dropdown [(pessoaId)]="processo.revisorId"></pessoa-dropdown>
                                </div>
                            </div> -->
                            <!-- <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Administrativo <span class="required-field">*</span></label>                
                                    <pessoa-dropdown [(pessoaId)]="processo.administrativoId"></pessoa-dropdown>
                                </div>
                            </div>         -->
                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="form-label">Acesso</label>
                                    <div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                [(ngModel)]="processo.tipoAcesso" [ngModelOptions]="{standalone: true}"
                                                id="acessoPublico" value="Publico">
                                            <label class="form-check-label" for="acessoPublico">Público</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                [(ngModel)]="processo.tipoAcesso" [ngModelOptions]="{standalone: true}"
                                                id="acessoPrivado" value="Privado">
                                            <label class="form-check-label" for="acessoPrivado">Privado</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                [(ngModel)]="processo.tipoAcesso" [ngModelOptions]="{standalone: true}"
                                                id="acessoEquipe" value="Equipe">
                                            <label class="form-check-label" for="acessoEquipe">Equipe</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="processo.tipoAcesso == 'Equipe'">
                                <div class="form-row">
                                    <div class="form-group col">
                                        <label class="form-label">Selecione a equipe</label>
                                        <equipe-dropdown [(equipeId)]="equipeId"
                                            (change)="onChangeEquipe($event)"></equipe-dropdown>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <label class="form-label d-flex align-items-center">
                                            Membros da Equipe
                                        </label>
                                    </div>
                                </div>
                                <div class="form-row"
                                    *ngFor="let membroEquipe of processo.membrosEquipe; let i = index">
                                    <div class="form-group col">
                                        <pessoa-dropdown [(pessoaId)]="membroEquipe.pessoaId"></pessoa-dropdown>
                                    </div>
                                    <div class="form-group col-1">
                                        <button type="button" class="btn btn-danger" (click)="removerMembroEquipe(i)"><i
                                                class="fas fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>

                            <app-processos-judiciais-formulario-dinamico #formulariosDinamicos [(processoJudicialId)]="id"></app-processos-judiciais-formulario-dinamico>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Dados do Faturamento</a>
                        <ng-template ngbNavContent>
                            <app-processo-faturamento [processo]="processo">                                
                            </app-processo-faturamento>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row body-card">
            <div class="col-12 mt-2 border-card" [ngbNavOutlet]="processoCadastro"></div>
            <hr />
            <div class="col-12 mt-2 mb-2">
                <div class="d-flex justify-content-end">
                    <button type="button" (click)="cancel()" class="btn btn-light mr-3">Cancelar</button>
                    <button type="button" *ngIf="isAddMode" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
                    <button type="button" *ngIf="!isAddMode" [disabled]="!processo.usuarioPodeEditarProcesso"
                        (click)="onSubmit()" class="btn btn-primary">Salvar</button>
                </div>
            </div>
        </div>
    </form>
</div>