import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-atendimento-documento',
    templateUrl: './atendimento-documento.component.html',
    styleUrls: ['./atendimento-documento.component.scss']
})
export class AtendimentoDocumentoComponent implements OnInit {

    public id: string;

    public raiz: string;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
    ) { }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];

        if (this.router.url.indexOf('/dashboard/') > -1) 
        {
            this.raiz = '/dashboard'
        }else{
            this.raiz = '/operacionais'
        }
    }

    voltar(){
        this.location.back();
    }
}
