<div class="modal-header">
    <h4>{{title}}</h4>
</div>
<div class="modal-body">
    <form [formGroup]="headerJurosMoratoriosForm">

        <div class="d-flex">
            <div class="form-check p-0">
                <input  id="type-form-n" type="radio" value="S" formControlName="tipoFormularioCalculo">
                <label for="type-form-n">Formulário simplificado </label>
            </div>
            <div class="form-check">
                <input id="type-form-s" type="radio" value="A" formControlName="tipoFormularioCalculo">
                <label for="type-form-s"> Formulário avançado </label>
            </div>
        </div>
        <hr/>

        <div class="row">
            <ng-container *ngIf="headerJurosMoratoriosForm.get('tipoFormularioCalculo').value === 'S'">
                <div class="col-6">
                    <div class="form-group">
                        <label class="font-weight-bold">Percentual</label>
                        <input type="number" class="form-control" formControlName="percentual">
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label class="font-weight-bold">Tipo de cálculo:</label>
                        <select class="form-control" formControlName="tipoCalculo">
                            <option value="1">Simples Mensal</option>                            
                            <option value="2">Capitalizado Mensalmente</option>                            
                            <option value="3">Capitalizado Semestralmente</option>
                            <option value="4">Capitalizado Anualmente</option>
                        </select>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="headerJurosMoratoriosForm.get('tipoFormularioCalculo').value === 'A'">
                <ng-container formArrayName="avancado" *ngFor="let form of formAvancado.controls; let i = index">
                    <ng-container [formGroupName]="i">
                        <div class="col-3">
                            <div class="form-group">
                                <label class="font-weight-bold" *ngIf="i === 0">Data Inicial</label>
                                <input type="date" class="form-control" formControlName="dtInicial">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label class="font-weight-bold" *ngIf="i === 0">Data Final</label>
                                <input type="date" class="form-control" formControlName="dtFinal">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label class="font-weight-bold" *ngIf="i === 0">Percentual</label>
                                <input type="number" class="form-control" formControlName="percentual">
                            </div>
                        </div>
        
                        <div class="col-3">
                            <div class="form-group">
                                <label class="font-weight-bold" *ngIf="i === 0">Tipo de cálculo:</label>
                                <select class="form-control" formControlName="tipoCalculo">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div class="col-6">
                <div class="form-group">
                    <label class="font-weight-bold">Juros proporcional quando necessário (pró-rata)?</label>
                    <div class="d-flex">
                        <div class="form-check p-0">
                            <input id="juros-prop-n" type="radio" [value]="false" formControlName="jurosProporcional">
                            <label for="juros-prop-n">Não</label>
                        </div>
                        <div class="form-check">
                            <input id="juros-prop-s" type="radio" [value]="true" formControlName="jurosProporcional"> 
                            <label for="juros-prop-s">Sim</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label class="font-weight-bold">Juros a partir de:</label>
                        <div class="form-check p-0">
                            <input id="juros-partir-de-vencimento"type="radio" [value]="false" formControlName="jurosDataVencimento">
                            <label for="juros-partir-de-vencimento">Data do vencimento</label>
                        </div>
                        <div class="form-check p-0">
                            <input id="juros-partir-de-citacao" type="radio" [value]="true" formControlName="jurosDataVencimento">
                            <label for="juros-partir-de-citacao">Data da citação / outra</label>
                        </div>
                </div>
            </div>                
        </div>
        <hr />
        <div class="d-flex justify-content-end">
            <div class="col-md-2">
                <button type="button" class="btn background-color-white" (click)="dismiss()">Cancelar</button>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-padrao btn-block" (click)="close()">OK</button>
            </div>
        </div>
    </form>
</div>