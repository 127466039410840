import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PerfilService } from 'src/app/services/perfil.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { AmbienteAcessoLabel } from 'src/app/enums/pessoa-enums/ambiente-acesso.enum';
import { SelectListMultiSelect } from 'src/app/models/select-list-multi-select';
import { SelectListService } from 'src/app/services/shared/select-list.service';
import { debug } from 'console';

@Component({
    selector: 'app-modal-atualiza-usuario',
    templateUrl: './modal-atualiza-usuario.component.html',
    providers: [UsuarioService, PerfilService]
})
export class ModalAtualizaUsuarioComponent implements OnInit {

    public pessoasSelecionadas: any = [];
    public ambientesAcessoList: SelectListMultiSelect[] = [];
    public ambientesSelecionados: SelectListMultiSelect[] = [];
    public settingsAmbientesAcesso = {
        text: "Selecione",
        selectAllText: "Selecione Todos",
        unSelectAllText: "Deselecionar Todos",
        searchPlaceholderText: "Procurar",
        classes: "myclass custom-class",
    };

    modalRef: NgbModalRef;
    isIE10 = false;

    perfis: any[] = [];
    constructor(
        private appService: AppService,
        private UsuarioService: UsuarioService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public pessoaService: PessoaService,
        private selectListService: SelectListService
    ) {

        this.isIE10 = this.appService.isIE10;
    }

    public usuario: any;

    atualizar() {

        if (this.ambientesSelecionados == null || this.ambientesSelecionados.length == 0) {
            this.toastrService.error('Selecione pelo menos um Ambiente de Acesso', 'Atenção', { timeOut: 5000 });
            return;
        }

        let pessoasSelecionadas = this.pessoasSelecionadas.filter(pessoa => pessoa.pessoaId != undefined);

        if (pessoasSelecionadas == undefined || pessoasSelecionadas.length == 0) {
            this.toastrService.error('Selecione uma pessoa', 'Atenção', { timeOut: 5000 });
            return;
        }

        if (!this.usuario.perfilId) {
            this.toastrService.error('Selecione um perfil', 'Atenção', { timeOut: 5000 });
            return;
        }


        var usuarioPessoa = this.montarUsuarioPessoa(pessoasSelecionadas);
        var usuarioAmbiente = this.montaUsuarioAmbiente(this.ambientesSelecionados);
        var usuarioUpdate = {
            id: this.usuario.id,
            nome: this.usuario.nome,
            login: this.usuario.login,
            email: this.usuario.email,
            senha: this.usuario.senha,
            confirmarSenha: this.usuario.confirmarSenha,
            pessoaId: this.usuario.pessoaId,
            perfilId: this.usuario.perfilId,
            usuarioPessoa: usuarioPessoa,
            usuarioAmbiente: usuarioAmbiente,
        };

        this.update(usuarioUpdate);
    }

    update(usuarioUpdate) {
        this.spinner.show();
        this.toastrService.clear();

        this.UsuarioService.update(usuarioUpdate)
            .subscribe(res => {
                this.toastrService.success("Usuário atualizado.", "Sucesso");
                this.spinner.hide();
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar o usuário!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    carregarPessoasSelecionadas(): void {        
        var pessoasSalvas = this.usuario.usuarioPessoa;

        if (pessoasSalvas.length == 0) {
            this.pessoasSelecionadas.push({});
            return;
        }

        pessoasSalvas.forEach(p => {
            this.pessoasSelecionadas.push({ id: p.id, pessoaId: p.pessoaId });
        });


    }

    montarUsuarioPessoa(pessoasSelecionadas) {

        debugger;
        var pessoasSalvas = this.usuario.usuarioPessoa;

        var usuarioPessoa = [];

        pessoasSelecionadas.forEach(selecionada => {
            debugger;
            var pessoaSalva = pessoasSalvas.find(x => x.pessoaId == selecionada.pessoaId);

            var obj = {
                pessoaId: selecionada.pessoaId,
                usuarioId: this.usuario.id,
            }

            if (pessoaSalva != undefined)
                obj['id'] = pessoaSalva.id;

            usuarioPessoa.push(obj);
        });

        return usuarioPessoa;
    }

    selectAmbientes(ambientes) {
        ambientes.forEach(p => {
            var o = new SelectListMultiSelect(p.ambiente.toString(), AmbienteAcessoLabel.get(p.ambiente));
            this.ambientesSelecionados.push(o);
        });
    }

    montaUsuarioAmbiente(ambientesSelecionados) {
        var usuarioAmbiente = [];
        ambientesSelecionados.forEach(element => {
            var result = this.usuario.usuarioAmbiente.find(x => x.ambiente == element.id);
            var obj = {
                ambiente: element.id,
                usuarioId: this.usuario.id,
                id: null,
            }
            if (result != null) {
                obj.id = result.id;
            }
            usuarioAmbiente.push(obj);
        });
        return usuarioAmbiente;
    }

    adicionarPessoa(): void {

        this.pessoasSelecionadas.push({});
    }

    removerPessoa(membroEquipeIndex): void {

        this.pessoasSelecionadas.forEach((element, index) => {

            if (index == membroEquipeIndex) {
                this.pessoasSelecionadas.splice(index, 1);
            }
        });
    }

    ngOnInit(): void {

        this.spinner.show();

        this.perfis = this.modalRef.componentInstance.perfis;

        this.usuario = this.modalRef.componentInstance.usuario;

        this.UsuarioService.getById(this.usuario.id).subscribe(res=>{
            this.usuario = res.data;
            this.carregarPessoasSelecionadas();

            this.ambientesAcessoList = this.selectListService.getAmbiente();
            this.selectAmbientes(this.usuario.usuarioAmbiente);
            this.spinner.hide();
        }, error => {

            this.spinner.hide();
            this.toastrService.error(error.errors, 'Erro');
        });      
    }

    criarAccessKey(usuario){
        this.spinner.show();
        console.log(usuario);
        let dados = {
            id: usuario.id
        }

        this.UsuarioService.registerAuthKey(dados).subscribe(res=>{
            usuario.authKey = res.data.authKey;
            usuario.secretkey = res.data.secretkey;
            this.spinner.hide();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível atualizar o usuário!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }
}
