import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoMateriaPedidoService } from 'src/app/services/tipo-materia-pedido.service';

@Component({
    selector: 'tipo-materia-pedido-dropdown',
    templateUrl: './tipo-materia-pedido-dropdown.component.html'
})
export class TipoMateriaPedidoDropdownComponent implements OnInit {

    @Input() tipoMateriaPedidoId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() tipoMateriaPedidoIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public tiposMateriasPedidos: any = [];
    public load: boolean = false;
    public tipoMateriaPedido: any;

    constructor(
        private _tipoMateriaPedidoService: TipoMateriaPedidoService
    ) { }

    ngOnInit(): void {

        // if (this.tipoMateriaPedidoId != undefined && this.tipoMateriaPedidoId != null && this.tipoMateriaPedidoId != '') {
        //     this.buscarPorId();
        // } else {
        //     this.buscarPorNome('a');
        // }
        this.carregarMaterias();
    }
    carregarMaterias() {
        this._tipoMateriaPedidoService.listar().subscribe(res=>{
            this.tiposMateriasPedidos = res.data;
        })
    }

    changeTipoMateriaPedido($event) {

        this.tipoMateriaPedidoIdChange.emit(this.tipoMateriaPedidoId);
        this.change.emit(this.tiposMateriasPedidos.filter(x => x.id == this.tipoMateriaPedidoId)[0]);
    }

    filtrar(term: any) {

        if (term.target.value != '')
            this.buscarPorNome(term.target.value);
    }

    buscarPorNome(buscar) {

        this.load = true;

        this._tipoMateriaPedidoService.obterPorFiltroNomeAsync(buscar).then(x => {

            this.load = false;
            this.tiposMateriasPedidos = x.data;

            if (this.tipoMateriaPedido != undefined) {
                this.tiposMateriasPedidos.push(this.tipoMateriaPedido);
            }
        });
    }

    buscarPorId() {

        this.load = true;
        this._tipoMateriaPedidoService.getByIdAsync(this.buscarPorId)
            .then(res => {
                this.load = false;
                let tiposMateriasPedidos = [];
                tiposMateriasPedidos.push(res.data);
                this.tipoMateriaPedido = res.data;
                this.tiposMateriasPedidos = tiposMateriasPedidos;
            })
    }

    limpar() {

        this.tipoMateriaPedidoIdChange.emit(null);
    }
}
