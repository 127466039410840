import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-home-ambiente',
    templateUrl: './home-ambiente.component.html'
})
export class HomeAmbienteComponent implements OnInit {

    public isMaster = false;
    public perfil = '';

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
        this.isMaster = authService.isMaster();
    }

    ngOnInit(): void {
        let ambiente = this.authService.getAmbiente();
        this.perfil = this.authService.getPerfil();

        if (ambiente) {
            if (this.isMaster !== true) {
                if (this.perfil == 'Financeiro') {
                    this.router.navigateByUrl('/home/administrativo');
                }
                // } else if (this.perfil == 'Revisor') {
                //     this.router.navigateByUrl('/dashboard/area-trabalho-revisor');
                // } else {
                //     this.router.navigateByUrl('/operacionais/operacao-de-processos');
                // }
                this.router.navigateByUrl('/dashboard/area-trabalho');
            } else {
                this.router.navigateByUrl('/home/administrativo');
            }
        }
    }

    selecionaAmbiente(ambiente): void {
        this.authService.setAmbiente(ambiente);

        if (ambiente == 1) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl('/home/administrativo');
            });
        } else {

            if (this.isMaster !== true) {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    if (this.perfil == 'Financeiro') {
                        this.router.navigateByUrl('/home/administrativo');
                    } else {
                        this.router.navigate(['/dashboard/area-trabalho']);
                    }
                });
            } else {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this.router.navigate(['/dashboard/area-trabalho'])
                );
            }
        }
    }
}
