import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DespesaService } from 'src/app/services/despesa.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { FileUploader } from 'ng2-file-upload';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { Location, DatePipe } from '@angular/common';
import { CentroCustosService } from 'src/app/services/centro-custos.service';
import { MatTooltipModule } from '@angular/material/tooltip';
@Component({
    selector: 'app-cadastrar',
    templateUrl: './cadastrar.component.html',
    styleUrls: [
        './cadastrar.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class CadastrarComponent implements OnInit {

    constructor(
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private location: Location,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        private despesaService: DespesaService,
        private router: Router,
        private centroCustosService: CentroCustosService,
    ) { }

    public despesasAdd: any;
    public despesasDetalhe: any = [];
    public tipo: string = "4";
    public referenciaId: string;
    public atendimentoId: string;
    public processoJudicialId: string;
    public quantidadeLinhasAdd: number = 1;
    public clienteSelected: any;
    public raiz: string;
    public centrocustos: any;
    public fechar: any;
    public editar: boolean = false;

    ngOnInit(): void {
        console.log(this.router.url.indexOf('/editar/'))
        
        console.log(this.router.url.indexOf('/editar/'))
        if (this.router.url.indexOf('/editar/') > -1){
            console.log("editar")
            this.editar = true;
            this.editarDespesa();
        } 
        else{
            console.log("Cadastrar")
            this.cadastrar()
        }
    }

    editarDespesa(){
        const despesaId = this.route.snapshot.params['despesaId'];
        const parseData = (date) => {
            if (!date) return null;
            date = date.split('T')[0].split('-');
            return this.ngbDateParserFormatter.parse(`${date[2]}/${date[1]}/${date[0]}`);
        }
        

        this.centroCustosService.getAll().subscribe(res=>{
            this.centrocustos = res.data;

            this.despesaService.buscarDespesa(despesaId).subscribe(res => {
                this.referenciaId = res.data.referenciaId,
                this.despesasAdd = [{
                    quantidadeMesesRepetir: 1,
                    repetirMensalmente: false,
                    tipoReferencia: this.tipo,
                    referenciaId: res.data.referenciaId,
                    atendimentoId: null,
                    files: [],
                    uploader: new FileUploader({
                        disableMultipart: true,
                    }),
                    data: parseData(res.data.data),
                    dataLimitePagamento: parseData(res.data.dataLimitePagamento),
                    valor: res.data.valor,
                    descricao: res.data.descricao,
                    centroDeCustoId: this.centrocustos.find(f => f.id == res.data.centroCustoId).id ,
                    tipoDespesa: "0",
                    listaArquivo: res.data.arquivos
                }];
    
                this.despesaService.buscarDetalhesDespesas("4", res.data.referenciaId)
                .subscribe(res => {
                    this.despesasDetalhe = res.data;
    
                    this.clienteSelected = this.despesasDetalhe.nomeCliente[0];
                })
            })
        })

        

    }

    cadastrar(){
        this.tipo = this.route.snapshot.params['tipo'];
        this.referenciaId = this.route.snapshot.params['referenciaId'];
        this.atendimentoId = this.route.snapshot.params['atendimentoId'];
        this.processoJudicialId = this.route.snapshot.params['processoJudicialId'];

        if(this.route.snapshot.params['fechar']){
            this.fechar = this.route.snapshot.params['fechar'];
        }else{
            this.fechar = false;
        }

        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
        } else {
            this.raiz = '/operacionais'
        }

        if (this.atendimentoId == undefined) this.atendimentoId = null;

        this.despesasAdd = [{
            quantidadeMesesRepetir: 1,
            repetirMensalmente: false,
            tipoReferencia: this.tipo,
            referenciaId: this.referenciaId,
            atendimentoId: this.atendimentoId,
            files: [],
            uploader: new FileUploader({
                disableMultipart: true,
            })
        }];

        this.despesaService.buscarDetalhesDespesas(this.tipo, this.referenciaId)
            .subscribe(res => {
                this.despesasDetalhe = res.data;

                this.clienteSelected = this.despesasDetalhe.nomeCliente[0];
            })

        this.centroCustosService.getAll().subscribe(res=>{
            this.centrocustos = res.data;
        })
    }
    onDrop(despesa: any, files: FileList) {
        for (let i = 0; i < files.length; i++) {
            despesa.files.push(files[i] as File);
        }

        const fileInQueue: any = despesa.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    onSubmit() {
        debugger;
        this.spinner.show();
        let errorDespesas = false;
        this.toastrService.clear();

        this.despesasAdd.forEach(c => {


            if (typeof c.data == 'undefined') {
                this.toastrService.error('Data de vencimento é obrigatória!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                errorDespesas = true;

                return;
            }

            if (typeof c.dataLimitePagamento == 'undefined') {
                this.toastrService.error('Data Limite Pagamento é obrigatória!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                errorDespesas = true;

                return;
            }

            if (typeof c.valor == 'undefined') {
                this.toastrService.error('Valor é obrigatório!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                errorDespesas = true;

                return;
            }

            if (typeof c.descricao == 'undefined') {
                this.toastrService.error('Descrição é obrigatório!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                errorDespesas = true;

                return;
            }

            if((c.files.length == 0 && !this.editar) || (c.files.length == 0 && this.editar && c.listaArquivo.length == 0)){
                this.toastrService.error('Documento é obrigatório!', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                errorDespesas = true;

                return;
            }
            c.files.forEach(file => {
                if (!this.editar && file.name.toLowerCase().substr(file.name.toLowerCase().lastIndexOf('.') + 1) != "pdf"){
                    this.toastrService.error('Apenas arquivos PDF são permitidos', 'Atenção', { timeOut: 10000 });
                    this.spinner.hide();
                    errorDespesas = true;
                    return;
                }
            });
        });
        if (errorDespesas) return;
        let i = 0;
        this.despesasAdd.forEach(async despesa => {
            
            let formData: FormData = new FormData();

            formData.append('data', (despesa.data.day) + "/" + (despesa.data.month) + "/" + despesa.data.year + " 12:00:00");
            formData.append('dataLimitePagamento', (despesa.dataLimitePagamento.day) + "/" + (despesa.dataLimitePagamento.month) + "/" + despesa.dataLimitePagamento.year + " 12:00:00");
            formData.append('valor', despesa.valor.toString().replace(".", ","));
            formData.append('descricao', despesa.descricao);
            formData.append('repetirMensalmente', despesa.repetirMensalmente);
            formData.append('quantidadeMesesRepetir', despesa.quantidadeMesesRepetir);
            formData.append('referenciaId', this.referenciaId);
            formData.append('tipoReferencia', this.tipo);
            formData.append('centroDeCustoId', despesa.centroDeCustoId);
            formData.append('tipoDespesa', '0')

            despesa.files.forEach(file => {
                formData.append('files', file);
            });
            console.log(formData)
            if(this.editar){
                await this.despesaService.editarDespesa(this.route.snapshot.params['despesaId'], formData).toPromise();
            }else{
                await this.despesaService.addPorValorComArquivo(formData).toPromise();
            }

            i++;

            if (i == this.despesasAdd.length) {
                this.spinner.hide();
                this.reload();
            }
        });
    }

    adicionarHonorario() {
        for (let i = 0; i < this.quantidadeLinhasAdd; i++) {
            this.despesasAdd.push({
                quantidadeMesesRepetir: 1,
                repetirMensalmente: false,
                tipoReferencia: this.tipo,
                referenciaId: this.referenciaId,
                files: [],
                uploader: new FileUploader({
                    disableMultipart: true,
                })
            });
        }
    }

    popItem(item) {
            item.remove();
            this.despesasAdd.forEach((element, index) => {
                element.files.forEach((file, indexFile) => {
                    if (file.name == item.file.name) {
                        element.files.splice(indexFile, 1);
                    }
                });
            });
        }

    removerHonorario(honorarioIndex) {
        this.despesasAdd.forEach((element, index) => {
            if (index == honorarioIndex) {
                this.despesasAdd.splice(index, 1);
            }
        });
    }

    cancel(){
        if(this.fechar)
            window.close();
        else
            this.location.back();
    }

    removerItem(item){
        this.despesaService.removerArquivo(item).subscribe(res=>{
            this.toastrService.success('Arquivo removido com sucesso!', 'Sucesso', { timeOut: 10000 });
            window.location.reload();
        })
    }

    reload() {
        if(this.fechar)
            window.close();
        else{
            //window.location.reload();
            this.location.back();
        }
    }
}
