<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">
            Operacionais / Documentos /</span>
        <span *ngIf="isAddMode">Cadastrar</span><span *ngIf="!isAddMode">Editar</span> Documento</span>
</h4>
<div class="container mb-5 card">
    <form>        
        <div class="form-row">
            <div class="card-body">
                <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                    [uploader]="uploader" class="dropzone" (click)="fileInput.click()">
                    <span class=" span-icon fas fa-cloud-download-alt"></span>
                    <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
                        carregar</span>
                    <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" multiple/>
                </div>

                <table class="table" *ngIf="uploader.queue.length > 0">
                    <thead>
                        <tr>
                            <th width="45%">Nome</th>
                            <th>Tamanho</th>
                            <th>Status</th>
                            <th>Acões</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploader.queue">
                            <td><strong>{{ item?.file?.name }}</strong></td>
                            <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 |
                                number:'.2' }} MB
                            </td>
                            <td class="text-center">
                                <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                                <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                                <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                            </td>
                            <td nowrap>
                                <button type="button" class="btn btn-danger" (click)="handleRemoveFile(item)">
                                    <span class="glyphicon glyphicon-trash"></span> Remover
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-row mb-2">
            <div class="col-12 d-flex flex-wrap">
                <div *ngFor="let anexo of documento.anexos">
                    <label class="anexo-cadastrado">
                        {{anexo.arquivoNome}} <span class="anexo-cadastrado-link" (click)="removerAnexo(anexo)"><i
                            class="fas fa-window-close"></i></span>
                    </label>
                </div>
            </div>
        </div>
        <div style="margin:15px">
            <div class="form-row">
                <div class="form-group col">
                    <label class="form-label">Descrição <span class="required-field">*</span></label>
                    <textarea class="form-control" placeholder="Digite a descrição" rows="3"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="documento.descricao"></textarea>
                </div>
            </div>        
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="form-label">Tipo de documento</label>
                    <ng-select [items]="libDocumentos" [(ngModel)]="documento.tipoDocumentoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" [loading]="loadDocumentos"></ng-select>
                </div>
                <div class="form-group col-6">
                    <label class="form-label">Responsável <span class="required-field">*</span></label>                
                    <pessoa-dropdown #pessoaDropdown [(pessoaId)]="documento.responsavelId"></pessoa-dropdown>
                </div>
                <div class="form-group col-6">
                    <label class="form-label">Processo, caso, atendimento ou recurso/desdobramento <span class="required-field">*</span></label>
                    <ng-select [disabled]="!isAddMode" [items]="atendimentosCasosEProcessos" (change)="buscarCliente()" [(ngModel)]="atendimentosCasosEProcessosSelecionado" [ngModelOptions]="{standalone: true}" bindLabel="titulo" bindValue="registroUnicoId" [loading]="loadAtendimentosCasosEProcessos" [searchFn]="aplicarFiltroAtendimentoCasoProcesso">
                        <ng-template ng-option-tmp let-item="item">
                            <div class="line"><b>{{item.titulo}}</b></div>
                            <div class="line">Tipo: {{item.tipo}}</div>
                            <div class="line">Responsável: {{item.responsavelNome}}</div>
                            <div class="line" *ngIf="item.tipoId != 3">Pasta: {{item.pasta}}</div>
                            <div class="line" *ngIf="item.tipoId == 4">Número processo: {{item.numeroProcesso}}</div>
                            <div class="line" *ngIf="item.tipoId == 4">Número do incidente: {{item.recursoDesdobramento}}</div>
                        </ng-template>
                    </ng-select>
                </div>            
            </div>        
            <div class="form-row">
                <div class="col-12">Cliente(s) do processo, caso, atendimento ou recurso/desdobramento:</div>            
                <div class="col-12 clientes">
                    <ol *ngFor="let cliente of clientes">
                        <li>{{cliente}}</li>
                    </ol>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" (click)="cancel()" class="btn btn-light mr-3">Cancelar</button>
                <button type="button" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
            </div>
        </div>
    </form>
</div>