<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos /Atendimentos/</span><span> Documentos</span>
    </span>
    <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button type="button" class="btn btn-primary" (click)="voltar()">
            <span></span>&nbsp;&nbsp;Voltar
        </button>        
    </div>
</h4>
<hr />
<documentos-resumo [tipo]="3" [referenciaId]="id" [raiz]="raiz" class="mt-2"></documentos-resumo>