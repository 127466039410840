<div class="row mt-3" *ngIf="atividadesMencionadasUsuario.length > 0">
    <hr />
    <div class="table-responsive">
        <h5><strong>ATIVIDADES MENCIONADAS</strong></h5>
        <table id="tableAtividadesMencionadasUsuario" class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="text-nowrap text-center">
                        Pasta
                    </th>
                    <th class="text-nowrap text-center">
                        Mencionado por
                    </th>
                    <th class="text-nowrap text-center">
                        Data/hora menção
                    </th>
                    <th class="text-nowrap text-center">
                        Comentário
                    </th>
                    <th class="text-nowrap text-center">
                        Ações
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="tabela" *ngFor="let mencao of atividadesMencionadasUsuario;">
                    <td class="text-center">
                        {{ mencao.pasta ? mencao.pasta : ' NDA ' }}
                        <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                            href="/dashboard/processos-judiciais/detalhes/{{mencao.processoJudicialId}}"
                            target="_blank"></a>
                    </td>
                    <td class="text-center">
                        {{ mencao.mencionadoPor }}
                    </td>
                    <td class="text-center">
                        {{ mencao.dataHoraInclusao ? (mencao.dataHoraInclusao | date: 'dd/MM/yyyy - HH:mm') : '-' }}
                    </td>
                    <td class="text-center">
                        <ng-container *ngIf="mencao.comentarioFormatado && mencao.comentarioFormatado.length > 50">
                            <span placement="top" [ngbTooltip]="mencao.comentarioFormatado">{{mencao.comentarioFormatado
                                | slice:0:50}}...</span>
                        </ng-container>
                    </td>
                    <td class="text-center text-nowrap align-middle link">
                        <button type="button" class="btn btn-xs icon-btn btn-primary"
                            (click)="abrirModalComentario(mencao.processoJudicialRecursoDesdobramentoId, mencao.atividadeId, mencao.tipoAtividadeComentarioMencao)"
                            ngbTooltip="Abrir/editar">
                            <i class="far fa-edit"></i>
                        </button>
                        <button type="button" class="btn btn-xs icon-btn btn-primary"
                            (click)="descartarMencao(mencao.processoJudicialOcorrenciaComentarioMencao, mencao.tipoAtividadeComentarioMencao)"
                            ngbTooltip="Descartar">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>