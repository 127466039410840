<div class="layout-wrapper layout-1 layout-without-sidenav" [ngClass]="{'layout-loading': !initialized}">
  <div class="layout-inner">
    <app-layout-navbar [sidenavToggle]="false"></app-layout-navbar>

    <div class="layout-container">
      <div class="layout-content">
        <app-layout-sidenav orientation="horizontal"></app-layout-sidenav>

        <div class="container-fluid router-transitions flex-grow-1">
          <router-outlet></router-outlet>
        </div>

        <app-layout-footer></app-layout-footer>
      </div>
    </div>
  </div>
</div>
