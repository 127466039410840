import { Component, Input, HostBinding, OnInit, EventEmitter, Output } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import { Router } from '@angular/router';
import { ServiceBase } from 'src/app/services/service.base';
import { ModalService } from 'src/app/services/shared/modal-service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-layout-navbar',
    templateUrl: './layout-navbar.component.html',
    styles: [':host { display: block; }']
})
export class LayoutNavbarComponent {
    isExpanded = false;
    public existeNotificacoes: boolean = false;
    usuarioLogado = '';
    isRTL: boolean;
    isMaster: boolean = false;
    showMenuTrocarAmbienteAmigavel: boolean = false;
    showMenuTrocarAmbienteJuridico: boolean = false;
    dashboard: boolean = true;
    isJuridico = false;
    versao: string;
    @Input() sidenavToggle = true;
    @Input() isOpen = true;

    @HostBinding('class.layout-navbar') hostClassMain = true;

    @Output() closeMenu = new EventEmitter();

    public perfil = '';

    constructor(private appService: AppService,
        private layoutService: LayoutService,
        private modalService: ModalService,
        private router: Router,
        private authService: AuthService) {
        this.isRTL = appService.isRTL;
        this.usuarioLogado = localStorage.getItem('iso.usuario');
        this.isMaster = authService.isMaster();
        this.versao = environment.appVersion
    }

    ngOnInit() {

        this.perfil = this.authService.getPerfil();

        if (this.authService.getUserAmbientes().length > 1 && this.authService.getAmbiente().length > 0) {
            this.showMenuTrocarAmbienteAmigavel = (this.authService.getAmbiente() == '2') ? true : false;
            this.showMenuTrocarAmbienteJuridico = (this.authService.getAmbiente() == '1') ? true : false;            
        }

        this.isJuridico = this.authService.getAmbiente() == '2';

        this.dashboard = this.router.url.indexOf('/dashboard/') > -1;
    }

    goToCalculator() {
        //this.router.navigate(['dashboard/atualizacao-valores'])
        if(this.dashboard)
            this.router.navigate(['dashboard/historico-atualizacao-valores']);
        else
            this.router.navigate(['operacionais/historico-atualizacao-valores']);
    }

    goToKanban() {
        this.router.navigate(['operacionais/kanban'])
    }

    currentBg() {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav() {
        this.layoutService.toggleCollapsed();
    }

    trocarSenha() {
        this.router.navigate(['/trocar-senha']);
    }

    abrirCalendario() {
        if(this.isJuridico) {
            this.router.navigate(['dashboard/agenda'])
        } else {
            this.modalService.abrirCalendarioCobrador();
        }
    }

    logout() {

        window.localStorage.removeItem('role');
        window.localStorage.removeItem('iso.access_token');
        window.localStorage.removeItem('iso.usuario');

        //this.router.navigate(['/login']);
        window.location.href = '/login';
    }

    //Chama a tela de ramais de acordo com o perfil
    abrirListaDeRamais() {
        if (this.authService.getPerfil() === 'Advogado' || this.authService.getPerfil() === 'Revisor') {
            this.router.navigate(['/dashboard-lista-de-ramais']);
        } else {
            this.router.navigate(['/lista-de-ramais']);
        }
    }

    trocarVisualizacao() {
        let raiz = ""
        if (this.router.url.indexOf('/dashboard/') > -1) {
            raiz = '/home/administrativo'
        } else {
            raiz = '/dashboard/area-trabalho'
        }

        return raiz;

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([raiz])
        );
    }

    migrarAmbiente(ambiente) {        
        if (this.authService.getUserAmbientes().length > 1) {
            if (this.authService.getUserAmbientes().indexOf(ambiente) >= 0) {
                if (this.authService.getAmbiente().length > 0) {
                    if (this.authService.getAmbiente() != ambiente) {
                        this.authService.setAmbiente(ambiente);
                        if (this.isMaster !== true) {

                            if(this.perfil == 'Financeiro'){
                                window.location.href="/home/administrativo";                                
                            }else{
                                if(ambiente == 2){
                                    window.location.href="/dashboard/area-trabalho";
                                }else{
                                    window.location.href="/home/administrativo";
                                }
                            }                         
                        } else {
                            // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                            //     ambiente == 2 ? this.router.navigate(['/dashboard/area-trabalho']) :
                            //         this.router.navigate(['/home/administrativo'])

                            // );
                            //fazendo com redirect pois tem um erro que não carrega o css quando 
                            //faz com rota
                            debugger;
                            if(ambiente == 2){
                                window.location.href="/dashboard/area-trabalho";
                            }else{
                                window.location.href="/home/administrativo";
                            }
                        }
                    }
                }
            }
        }
    }

    notificar(event) {
        this.existeNotificacoes = event;
    }

    openAutenticatior(){
        this.modalService.abrirAutenticador();
    }
}
