import { PessoaMapeamentoChavesResponse } from "./../models/pessoa/pessoa-mapeamento-chaves-response";
import { PessoaMapeamentoChaves } from "./../models/pessoa/pessoa-mapeamento-chaves";
import { element } from "protractor";
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, catchError, debounceTime } from "rxjs/operators";
import { SelectList } from "../models/select-list";
import {
    SituacaoCredor,
    SituacaoCredorLabel,
} from "../enums/pessoa-enums/situacao-credor.enum";
import {
    TipoCredor,
    TipoCredorLabel,
} from "../enums/pessoa-enums/tipo-credor.enum";
import {
    CalcularHonorarioCredor,
    CalcularHonorarioCredorLabel,
} from "../enums/pessoa-enums/calcular-honorario-credor.enum";
import {
    TipoEstadoCivil,
    TipoEstadoCivilLabel,
} from "../enums/pessoa-enums/tipo-estado-civil.enum";
import {
    TipoRegimeBens,
    TipoRegimeBensLabel,
} from "../enums/pessoa-enums/tipo-regime-bens.enum";
import {
    TipoCobrador,
    TipoCobradorLabel,
} from "../enums/pessoa-enums/tipo-cobrador.enum";
import {
    TipoEntidade,
    TipoEntidadeLabel,
} from "../enums/pessoa-enums/tipo-entidade.enum";
import { environment } from "src/environments/environment";
import { createPipe } from "@angular/compiler/src/core";
import { ContatoDevedorResponse } from "../models/contato-devedor/contato-devedor-response";
import { Guid } from "guid-typescript";
import { Pessoa, PessoaDados } from "../models/pessoa/pessoa";
import { Arquivo } from "../models/shared/arquivo";

@Injectable()
export class PessoaService extends ServiceBase {
    public endpoint = "Pessoa/";
    public camposPessoasDados: any;
    public postFileUrl = environment.api + this.endpoint + "arquivo";
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    private chavesPessoa: PessoaMapeamentoChaves = null;

    constructor(private http: HttpClient) {
        super();
        this.getPessoaMapeamentoChaves().subscribe(
            (x) => {
                this.chavesPessoa = x.data;
            },
            (error) => {
                this.chavesPessoa = null;
            }
        );
    }

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    async checkIfExists(id) {
        return this.http
            .get(environment.api + this.endpoint + `getById/${id}`).toPromise();

    }

    getAll(): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + "getAll")
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getAllAsync() {
        return this.http
            .get(environment.api + this.endpoint + "getAll")
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

    obterPessoaFiltroNomeAsync(busca) {
        return this.http
            .get(environment.api + this.endpoint + "obter-pessoa-filtro/" + busca)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

    obterCodigoProcesso(id: any) {
        return this.http
            .get(environment.api + this.endpoint + `obter-codigo-processo/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getByIdAsync(id) {
        return this.http
            .get(environment.api + this.endpoint + `getById/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

    getById(id): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + `getById/${id}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getAllSimplificado(): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + "getAllPessoasSimplificado")
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getAllListagemSimplificada(): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + "getAllPessoasListagemSimplificada")
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    obterListagemSimplificada(requisicao): Observable<any> {
        return this.http
            .post(environment.api + this.endpoint + "obterPessoasListagemSimplificada", requisicao)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getByPessoaTipo(tipo): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + `getByPessoaTipo?tipo=${tipo}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getByPessoaTipoAsync(tipo) {
        return this.http
            .get(environment.api + this.endpoint + `getByPessoaTipo?tipo=${tipo}`)
            .pipe(map(this.extractData), catchError(this.serviceError)).toPromise();
    }

    getByPessoaTipoListaFaturamentoAsync(tipo) {
        return this.http
            .get(environment.api + this.endpoint + `getByPessoaTipoListaFaturamento?tipo=${tipo}`)
            .pipe(map(this.extractData), catchError(this.serviceError)).toPromise();
    }

    getAllPessoaParaCadastroUsuarioAsync() {
        return this.http
            .get(environment.api + this.endpoint + `getAllPessoaParaCadastroUsuario`)
            .pipe(map(this.extractData), catchError(this.serviceError)).toPromise();
    }

    getByPessoaTipoListagemSimplificadaAsync(tipo) {
        return this.http
            .get(environment.api + this.endpoint + `getByPessoaTipoListagemSimplificada?tipo=${tipo}`)
            .pipe(map(this.extractData), catchError(this.serviceError)).toPromise();
    }

    getAllDevedoresListagemSimplificadaAsync() {
        return this.http
            .get(environment.api + this.endpoint + `getAllDevedorListagemSimplificada`)
            .pipe(map(this.extractData), catchError(this.serviceError)).toPromise();
    }

    getAllGroups(): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + `grupos`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    add(data): Observable<any> {
        return this.http
            .post(environment.api + this.endpoint + "add", data)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    update(data): Observable<any> {
        return this.http
            .put(environment.api + this.endpoint + "update", data)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updatePessoaCodigo(data): Observable<any> {
        return this.http
            .put(environment.api + this.endpoint + "update-pessoa-codigo", data)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    alterarStatus(pessoaId): Observable<void> {
        return this.http
            .put(environment.api + this.endpoint + `alterar-status/${pessoaId}`, null)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    // metodos de adaptar model

    getSituacaoCredor() {
        var List: SelectList[] = [];
        List.push(
            new SelectList(
                SituacaoCredor.Ativo.toString(),
                SituacaoCredorLabel.get(SituacaoCredor.Ativo)
            )
        );
        List.push(
            new SelectList(
                SituacaoCredor.Bloqueado.toString(),
                SituacaoCredorLabel.get(SituacaoCredor.Bloqueado)
            )
        );
        List.push(
            new SelectList(
                SituacaoCredor.Cancelado.toString(),
                SituacaoCredorLabel.get(SituacaoCredor.Cancelado)
            )
        );
        return List;
    }

    getTipoCredor() {
        var List: SelectList[] = [];
        List.push(
            new SelectList(
                TipoCredor.Fisica.toString(),
                TipoCredorLabel.get(TipoCredor.Fisica)
            )
        );
        List.push(
            new SelectList(
                TipoCredor.Juridica.toString(),
                TipoCredorLabel.get(TipoCredor.Juridica)
            )
        );
        return List;
    }

    getCalcularHonorarioCredor() {
        var List: SelectList[] = [];
        List.push(
            new SelectList(
                CalcularHonorarioCredor.Sim.toString(),
                CalcularHonorarioCredorLabel.get(CalcularHonorarioCredor.Sim)
            )
        );
        List.push(
            new SelectList(
                CalcularHonorarioCredor.Nao.toString(),
                CalcularHonorarioCredorLabel.get(CalcularHonorarioCredor.Nao)
            )
        );
        return List;
    }

    getTipoEstadoCivil() {
        var List: SelectList[] = [];
        List.push(
            new SelectList(
                TipoEstadoCivil.Solteiro.toString(),
                TipoEstadoCivilLabel.get(TipoEstadoCivil.Solteiro)
            )
        );
        List.push(
            new SelectList(
                TipoEstadoCivil.Casado.toString(),
                TipoEstadoCivilLabel.get(TipoEstadoCivil.Casado)
            )
        );
        List.push(
            new SelectList(
                TipoEstadoCivil.Divorciado.toString(),
                TipoEstadoCivilLabel.get(TipoEstadoCivil.Divorciado)
            )
        );
        List.push(
            new SelectList(
                TipoEstadoCivil.Viuvo.toString(),
                TipoEstadoCivilLabel.get(TipoEstadoCivil.Viuvo)
            )
        );
        return List;
    }

    getTipoRegimeBens() {
        var List: SelectList[] = [];
        List.push(
            new SelectList(
                TipoRegimeBens.ComunhaoParcialBens.toString(),
                TipoRegimeBensLabel.get(TipoRegimeBens.ComunhaoParcialBens)
            )
        );
        List.push(
            new SelectList(
                TipoRegimeBens.CommunhaoUniversalBens.toString(),
                TipoRegimeBensLabel.get(TipoRegimeBens.CommunhaoUniversalBens)
            )
        );
        List.push(
            new SelectList(
                TipoRegimeBens.SeparacaoBens.toString(),
                TipoRegimeBensLabel.get(TipoRegimeBens.SeparacaoBens)
            )
        );
        List.push(
            new SelectList(
                TipoRegimeBens.ParticipacaoFinalAquestos.toString(),
                TipoRegimeBensLabel.get(TipoRegimeBens.ParticipacaoFinalAquestos)
            )
        );
        return List;
    }

    getTipoCobrador() {
        var List: SelectList[] = [];
        List.push(
            new SelectList(
                TipoCobrador.Cobrador.toString(),
                TipoCobradorLabel.get(TipoCobrador.Cobrador)
            )
        );
        List.push(
            new SelectList(
                TipoCobrador.Prospector.toString(),
                TipoCobradorLabel.get(TipoCobrador.Prospector)
            )
        );
        List.push(
            new SelectList(
                TipoCobrador.Ambos.toString(),
                TipoCobradorLabel.get(TipoCobrador.Ambos)
            )
        );
        return List;
    }

    getTipoEntidade() {
        var List: SelectList[] = [];
        List.push(
            new SelectList(
                TipoEntidade.Privado.toString(),
                TipoEntidadeLabel.get(TipoEntidade.Privado)
            )
        );
        List.push(
            new SelectList(
                TipoEntidade.Publico.toString(),
                TipoEntidadeLabel.get(TipoEntidade.Publico)
            )
        );
        return List;
    }




    getAllArquivos(id): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + `${id}/arquivo`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    uploadArquivo(data): Observable<any> {
        return this.http
            .post(environment.api + this.endpoint + "arquivo", data)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    deleteArquivo(pessoaId, arquivoId): Observable<any> {
        return this.http
            .delete(
                environment.api +
                this.endpoint +
                `${pessoaId}/pessoaArquivo/${arquivoId}`
            )
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getTiposDocumento(): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + "arquivo/tiposdocumento")
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getContatosPessoa(id): Observable<ContatoDevedorResponse> {
        return this.http
            .get(environment.api + this.endpoint + `${id}/contato-pessoa`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getPessoaMapeamentoChaves(): Observable<PessoaMapeamentoChavesResponse> {
        return this.http
            .get(environment.api + this.endpoint + `mapeamento-chaves`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateCredorComissoes(pessoaId, data): Observable<any> {
        return this.http
            .put(
                environment.api + this.endpoint + `update/credor-comissoes/${pessoaId}`,
                data
            )
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getCredorComissoes(pessoaId): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + `credor-comissoes/${pessoaId}`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    deleteCredorComissoes(pessoaId, id): Observable<any> {
        return this.http
            .delete(
                environment.api +
                this.endpoint +
                `delete/credor-comissoes/${pessoaId}/${id}`
            )
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    downloadArquivo(id) {
        let url = environment.api + this.endpoint + "arquivo/download/" + id;

        return this.http.request(new HttpRequest(
            'GET',
            url,
            null,
            {
                reportProgress: true,
                responseType: 'blob'
            }));
    }

    listarPessoasParaMencao(): Observable<any> {
        return this.http
            .get(environment.api + this.endpoint + `listar-para-mencao`)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }
}
