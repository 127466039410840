import { Component, Input, OnInit } from '@angular/core';
import { isThisSecond } from 'date-fns';
import * as internal from 'events';
import { HonorarioService } from 'src/app/services/honorario.service';

@Component({
  selector: 'honorarios-resumo',
  templateUrl: './honorarios.component.html',
  styleUrls: ['./honorarios.component.css']
})
export class HonorariosComponent implements OnInit {

  @Input() tipo: string;
  @Input() referenciaId: string;
  @Input() raiz: string;
  @Input() processoJudicialId: string;
  @Input() tipoHonorario: string;
  @Input() refresh: string = "";

  public valorTotalFaturado: any;
  public valorTotalAFaturar: any;
  public valorTotal: any;
  public recolhido: boolean = true;
  public load: boolean = true;
  public inserirHonorario: boolean = false;
  public hideButtons: boolean = true;

  constructor(
    public honorarioService: HonorarioService
  ) { }

  ngOnInit(): void {
    this.carregarDados();
  }

  ngOnChanges(){    
    this.carregarDados();    
  }
  
  recolherDados(){
    this.recolhido = !this.recolhido;
  }

  carregarDados(){
    this.load = true;
    this.honorarioService.buscaroHonorario(this.tipoHonorario, this.tipo, this.referenciaId)
        .subscribe(res=>{
            this.valorTotalAFaturar = res.data.valorTotalAFaturar.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;

            this.valorTotalFaturado = res.data.valorTotalFaturado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;

            this.valorTotal = (res.data.valorTotalAFaturar + res.data.valorTotalFaturado).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;

            this.load = false;

            this.recolhido = false;
        });
  }

}
