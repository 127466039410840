import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'historico-detalhes',
    templateUrl: './historico-detalhes.component.html',
    styleUrls: ['./historico-detalhes.component.css']
})
export class HistoricoDetalhesComponent implements OnInit {

    @Input() descricao: any;

    constructor() { }

    ngOnInit(): void {   
        console.log(this.descricao)     ;
    }

}
