import { Component, OnInit, Input } from '@angular/core';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
    selector: 'comentarios-detalhes',
    templateUrl: './comentarios-detalhes.component.html',
    styleUrls: ['./comentarios-detalhes.component.css']
})
export class ComentariosDetalhesComponent implements OnInit {

    @Input() comentarios:any=[];

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public arquivoService: ArquivoService,
        public utilsService: UtilsService,
    ) { }

    ngOnInit(): void {        
    }

    handleDownloadArquivo(arquivo:any){
        
        this.spinner.show();
        try{
            this.arquivoService.downloadArquivo(arquivo.arquivoId).subscribe(                
                data => {                    
                    if(data.type == 3){
                        this.spinner.hide();                    
                    }
                    this.utilsService.renderDownload(data, arquivo.nomeArquivo as string);
                }
            );
        }catch{
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }
}
