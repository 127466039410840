import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-multa',
    templateUrl: './modal-multa.component.html',
    styleUrls: ['./modal-multa.component.scss']
})
export class ModalMultaComponent implements OnInit {

    public multaForm: FormGroup;
    public modalRef: NgbModalRef;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    close() {
        this.modalRef.close();
    }

}
