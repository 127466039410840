import { AtividadeRequestResponse } from './../models/operacionais/atividade/atividade-request-response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from 'src/app/services/service.base';
import { Injectable } from '@angular/core';
import { AtividadeListaResponse } from '../models/operacionais/atividade/atividade-lista-response';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { map, catchError } from 'rxjs/operators';
import { AtividadeResponse } from '../models/operacionais/atividade/atividade-response';
import { AtividadePrioridadeCobradorResponse } from '../models/operacionais/atividade/atividade-prioridade-cobrador-response';

@Injectable({
    providedIn: 'root'
})
export class AtividadeService extends ServiceBase {

    public endpoint = 'atividade/';

    constructor(private http: HttpClient) {
        super();
    }

    getAllByProcesso(guid: Guid): Observable<AtividadeListaResponse> {
        return this.http.get(environment.api + this.endpoint + 'processo/' + guid)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllCobrador(): Observable<AtividadePrioridadeCobradorResponse> {
        return this.http.get(environment.api + this.endpoint + 'cobrador')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllAprovador(): Observable<AtividadePrioridadeCobradorResponse> {
        return this.http.get(environment.api + this.endpoint + 'aprovador')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data): Observable<AtividadeResponse> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<AtividadeResponse> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(guid: Guid): Observable<AtividadeRequestResponse> {
        return this.http.get(environment.api + this.endpoint + guid)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
