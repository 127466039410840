export enum TipoTag {
    Processo = 1,
    Divida = 2,
    Ocorrencia = 3,
    Atividade = 4
}

export const TipoTagLabel = new Map<TipoTag, string>([

    [TipoTag.Processo, "Processo"],
    [TipoTag.Divida, "Divida"],
    [TipoTag.Ocorrencia, "Ocorrencia"],
    [TipoTag.Atividade, "Atividade"]

]);