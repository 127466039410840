import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { RequisicaoBuscaPaginada } from 'src/app/models/shared/busca-paginada';
import { SortDirection } from '@swimlane/ngx-datatable';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { ExportProcessListService } from './export-process-list.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-carteira-de-processos',
    templateUrl: './carteira-de-processos.component.html',
    styleUrls: ['./carteira-de-processos.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ],
})
export class CarteiraDeProcessosComponent implements OnInit {

    constructor(
        public toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        private processoJudicialService: ProcessosJudiciaisService,
        private exportProcessListService: ExportProcessListService,
        private router: Router,
    ) { }

    public processos: any = [];
    req: any;

    //Paginação
    itensPorPagina: number = 50;
    totalItens: number = 0;
    paginaAtual: number = 1;
    colunaOrdenacao: string = 'DataAtualizacao';
    ordernarDesc: boolean = true;

    public raiz: string = '';

    get totalPaginas() {
        return Math.ceil(this.totalItens / this.itensPorPagina);
    }

    ngOnInit(): void {
        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
        } else {
            this.raiz = '/operacionais'
        }

        this.obterProcessosCasos();
    }

    obterProcessosCasos(req = null): void {
        
        this.spinner.show();
    
        let paginacao: RequisicaoBuscaPaginada = {
          itensPorPagina: this.itensPorPagina,
          paginaAtual: this.paginaAtual,
          colunaOrdenacao: this.colunaOrdenacao,
          direcaoOrdenacao: this.ordernarDesc
            ? SortDirection.desc
            : SortDirection.asc,
        };
    
        this.req = req ?? this.req;
    
        let requisicao: any = {
          paginacao,
          ...this.req
        };
    
        this.processoJudicialService
          .obterProcessosJudiciaisPorFiltro(requisicao)
          .subscribe(
            (res) => {
              this.processos = res.data.itens;
              this.totalItens = res.data.paginacao.totalItens;
              this.spinner.hide();
            },
            (error) => {
              if (error && error.errors) {
                this.toastrService.error(error.errors[0], "Atenção", {
                  timeOut: 10000,
                });
              } else {
                this.toastrService.error(
                  "Não foi possível obter os processos/casos!",
                  "Atenção",
                  { timeOut: 10000 }
                );
              }
              this.spinner.hide();
            }
          );
      }

    exportPdf() {
        this.exportProcessListService.exportPDF();
    }

    // defineClass(classe: string) {
    //     return 'badge ' + classe;
    // }

    alterarPagina(paginaSelecionada) {
        this.paginaAtual = paginaSelecionada;
        this.obterProcessosCasos();
      }
    
      ordenar(colunaOrdenacao: string) {
        this.colunaOrdenacao = colunaOrdenacao;
        this.ordernarDesc = !this.ordernarDesc;
        this.obterProcessosCasos();
      }
}
