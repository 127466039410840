import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentoArquivoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'DocumentoArquivo/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllByRegistro(tipo: string, registroId: string): Observable<any> {
        return this.http.get(environment.api + this.endpoint + `obter-documentos-tipo/${tipo}/${registroId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterQuantidadeDocumentosPorRegistro(tipo: string, registroId: string): Observable<any> {
        return this.http.get(environment.api + this.endpoint + `obter-quantidade-registro/${tipo}/${registroId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    uploadAnexo(id, data): Observable<any> {
        return this.http
          .request(
            new HttpRequest(
              "POST",
              environment.api + this.endpoint + "upload-documento-anexo/" + id,
              data,
              {
                reportProgress: true,
              }
            )
          )
          .pipe(map(this.extractData), catchError(this.serviceError));
        // return this.http
        //   .post(
        //     environment.api + this.endpoint + "upload-documento-anexo/" + id,
        //     data
        //   )
        //   .pipe(map(this.extractData), catchError(this.serviceError));
      }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterDocumentosPorFiltro(requisicao): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'obter-documentos-por-filtro/', requisicao)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
