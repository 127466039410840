<sidenav [orientation]="orientation" [ngClass]="getClasses()">

    <!-- Links -->
    <div class="sidenav-inner" [ngClass]="{ 'py-1': orientation == 'horizontal' }">

      <!-- Home -->
      <sidenav-router-link icon="lnr d-block lnr-home" route="/home/administrativo" [active]="isActive('/home')">Home</sidenav-router-link>

      <ng-container *ngIf="showMenu">
        <sidenav-menu *ngFor="let modulo of modulos" icon="lnr d-block {{modulo.icone}}" text="{{modulo.nome}}" [active]="isMenuActive(modulo.url)"
            [open]="isMenuOpen(modulo.url)">

            <sidenav-router-link *ngFor="let subModulo of modulo.filhos" [route]="subModulo.url" [active]="isActive(subModulo.url)">{{subModulo.nome}}</sidenav-router-link>
        </sidenav-menu>
       </ng-container>
    </div>
  </sidenav>
