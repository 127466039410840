<div style="min-height: 500px;display: flex;flex-direction: column;justify-content: center;" class="text-center mt-5" *ngIf="loadingProcesso">
  <div class="fa-3x">
      <i class="fas fa-spinner fa-spin"></i>
  </div>
</div>

<div *ngIf="!loadingProcesso">

  <div *ngIf="!isCredor">
    <br>
    <br>
    <div class="col">
      <label class="form-label ml-3">Lista de Avalistas</label>
      <!-- <div *ngIf="loadCobradores">
        <div class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
      </div> -->
      <div class="form-group col">
        <angular2-multiselect
          (onDeSelect)="onItemDeSelectAvalista($event)"
         (onSelect)="onItemSelectAvalista($event)"  [data]="avalistas" [(ngModel)]="avalistasSelecionados"
          [settings]="dropdownSettings">


          <c-item>
              <ng-template let-item="item">
                  <label>{{item.nome + " - " + item.cpfCnpj}}</label>
              </ng-template>
          </c-item>
          <c-badge>
              <ng-template let-item="item">
                  <label style="margin: 0px;">{{item.nome + " - " + item.cpfCnpj}}</label>
              </ng-template>
          </c-badge>
      </angular2-multiselect>
      </div>
    </div>

  </div>


  <div class="col-md-12 display-flex p-2">
    <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

    <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Data de abertura</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{processo.entrada | date:"dd/MM/yyyy"}}</span>
        </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>Data da baixa</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>{{''}}</span>
      </div>
  </div>

  <div class="col-md-12 display-flex p-2">
    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span style="color: transparent;"><strong>.</strong></span>
    </div>
    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span style="color: transparent;">.</span>
    </div>
  </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>Devedor</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>{{devedor ? devedor.nome:''}}</span>
      </div>
  </div>


  <div class="col-md-12 display-flex p-2">
    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span><strong>Endereco</strong></span>
    </div>
    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span>{{enderecoDevedor}}</span>
    </div>
  </div>


  <div class="col-md-12 display-flex p-2">
    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span><strong>Bairro</strong></span>
    </div>
    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span>{{bairroDevedor}}</span>
    </div>
  </div>

  <div class="col-md-12 display-flex p-2">
    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span><strong>UF</strong></span>
    </div>
    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span>{{ufDevedor}}</span>
    </div>
  </div>

  <div class="col-md-12 display-flex p-2">
    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span><strong>Credor</strong></span>
    </div>
    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span>{{credor ? credor.nome : ''}}</span>
    </div>
  </div>

  <div class="col-md-12 display-flex p-2">
    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span><strong>Atualizado em</strong></span>
    </div>
    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span>{{''}}</span>
    </div>
  </div>

  <div class="col-md-12 display-flex p-2">
    <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span><strong>Cobrador</strong></span>
    </div>
    <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
      <span>{{ cobrador ? cobrador.nome:''}}</span>
    </div>
  </div>


  </div>

    <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span style="color: transparent;"><strong>.</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span style="color: transparent;">.</span>
      </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>Numero do processo</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>{{processo.idExterno.toString()}}</span>
      </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>Codigo Externo Devedor</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

        <span *ngIf="!isMaster">{{processo.codigoExternoDevedor != null ? processo.codigoExternoDevedor:''}}</span>
        <div *ngIf="isMaster" class="input-group">
          <input type="text" class="form-control" placeholder="Codigo Externo Devedor" [(ngModel)]="processo.codigoExternoDevedor">
          <div class="input-group-append">
              <button  type="button" class="btn btn-primary" (click)="setCodigoExternoDevedor()">Salvar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>CNPJ/CPF</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>{{devedor ? devedor.cpfCnpj:''}}</span>
      </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span style="color: transparent;"><strong>.</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span style="color: transparent;">.</span>
      </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>Cidade</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>{{cidadeDevedor}}</span>
      </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>Cep</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>{{cepDevedor}}</span>
      </div>
    </div>

    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>CNPJ/CPF</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>{{credor ? credor.cpfCnpj:''}}</span>
      </div>
    </div>


    <div class="col-md-12 display-flex p-2">
      <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span><strong>Situação</strong></span>
      </div>
      <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
        <span>
          {{ processo.situacaoProcesso ? processo.situacaoProcesso.nome : ''}} {{ processo.situacaoProcesso  != null && processo.situacaoProcesso.situacaoPai != null ? '/' + processo.situacaoProcesso.situacaoPai.nome : ''}}
        </span>
      </div>
    </div>




    </div>


  </div>


  <br>
  <br>
  <div style="text-align: center;">
    <h4>Valores</h4>
  </div>

  <div class="col-md-12 display-flex p-2">



    <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">

      <div class="col-md-12 display-flex p-2">
          <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
            <span><strong>Valor do Processo</strong></span>
          </div>
          <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
            <span>{{processo.valor | currency: 'BRL'}}</span>
          </div>
      </div>

      <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Percentual taxa de correção</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{taxaJuros}}%</span>
        </div>
      </div>

      <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Valor da taxa de correção</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{totalJuros | currency: 'BRL'}}</span>
        </div>
      </div>


      <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Valor do protesto</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{totalProtesto | currency: 'BRL'}}</span>
        </div>
      </div>

      <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Juros do protesto</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{totalAtualizacaoCusta | currency: 'BRL'}}</span>
        </div>
      </div>

      <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Credor</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{totalCredor | currency: 'BRL'}}</span>
        </div>
      </div>


    </div>

      <div class="col-md-6 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">



      <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Taxa Administrativa</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{totalTaxaAdm | currency: 'BRL'}}</span>
        </div>
      </div>

      <div class="col-md-12 display-flex p-2">
        <div class="col-md-5 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span><strong>Valor total do processo</strong></span>
        </div>
        <div class="col-md-7 card-body-processos-orfaos pr-0 pl-0 pt-1 pb-1">
          <span>{{(totalCredor + totalTaxaAdm) | currency: 'BRL' }}</span>
        </div>
      </div>









      </div>


  </div>


  <br>
  <br>
  <div style="text-align: center;">
    <h4>Bancos do credor</h4>
  </div>


  <div class="table-responsive table-modal-tabs-scroll">
    <table class="table product-discounts-edit">
        <thead>
            <tr>
                <th>Banco</th>
                <th>Agencia</th>
                <th>Conta Corrente</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let banco of bancosCredor; let i = index">
                <td>
                    {{banco.nome}}
                </td>
                <td>
                    {{banco.agencia}}

                </td>
                <td>
                    {{banco.contaCorrente}}

                </td>

            </tr>
        </tbody>
    </table>
</div>

<br>
<br>
<div style="text-align: center;">
  <h4>Contatos do credor</h4>
</div>

<div class="table-responsive table-modal-tabs-scroll">
  <table class="table product-discounts-edit">
      <thead>
          <tr>
              <th>Numero</th>
              <th>Contato</th>
              <th>Email</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let contato of contatosCredor; let i = index">
              <td>
                  {{contato.numero}}
              </td>
              <td>
                  {{contato.contato}}

              </td>
              <td>
                  {{contato.email}}
              </td>

          </tr>
      </tbody>
  </table>
</div>



<!-- <div *ngIf="isMaster">
  <br>
  <br>
  <div style="text-align: center;">
    <h4>Avalistas</h4>
  </div>

  <div class="table-responsive table-modal-tabs-scroll">
    <table class="table product-discounts-edit">
        <thead>
            <tr>
                <th>Id</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let avalista of avalistas; let i = index">
                <td>
                    {{avalista.idExterno}}
                </td>
                <td>
                    {{avalistas.nome}}

                </td>
                <td>
                    {{avalista.email}}
                </td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm">Remover</button>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</div> -->




</div>


