import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { TipoTarefaService } from 'src/app/services/tipo-tarefa.service';
import { NivelComplexidadeService } from 'src/app/services/nivel-complexidade.service';


@Component({
    selector: 'app-modal-cadastro-tipo-de-tarefa',
    templateUrl: './modal-cadastro-tipo-tarefa.component.html',
    providers: [TipoTarefaService]
})
export class ModalCadastroTipoTarefaComponent implements OnInit {

    modalRef: NgbModalRef;
    public tipoTarefa: any;
    public loadNiveisComplexidade: boolean = false;
    public loadTiposTarefa: boolean = false;
    public niveisComplexidade: any = [];
    public tiposTarefa: any = [];

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private appService: AppService,
        private tipoTarefaService: TipoTarefaService,
        public nivelComplexidadeService: NivelComplexidadeService,
    ) {

    }

    popularNovoTipoTarefa() {
        this.tipoTarefa = {
            nome: '',
            exigeAprovacao: false,
        };
    }

    ngOnInit(): void {
        this.popularNovoTipoTarefa();
        this.buscarNiveisComplexidade();
        this.buscarTiposTarefa();
    }

    buscarNiveisComplexidade() {
        this.loadNiveisComplexidade = true;
        this.nivelComplexidadeService.getAllAsync().then(x => {
            this.loadNiveisComplexidade = false;
            this.niveisComplexidade = x.data;
        });
    }

    buscarTiposTarefa() {
        this.loadTiposTarefa = true;
        this.tipoTarefaService.getAllAsync().then(x => {

            let tiposTarefa = [];
            let tipos = x.data;
            x.data.forEach((element, index) => {
                let tipoTarefaNome = '';
                if (typeof (element.tipoTarefaPaiId) != "undefined" && element.tipoTarefaPaiId != null && element.tipoTarefaPaiId.length) {
                    tipoTarefaNome = this.buscarTipoTarefaPaiNome(tipos, element.tipoTarefaPaiId) + ' - ';
                }

                tipoTarefaNome += element.nome;

                tiposTarefa.push({
                    id: element.id,
                    nome: tipoTarefaNome,
                });
            });

            tiposTarefa.sort(function (a, b) {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });

            this.tiposTarefa = tiposTarefa;
            this.loadTiposTarefa = false;
        });
    }

    buscarTipoTarefaPaiNome(tipos, id) {
        let nome = '';
        tipos.forEach((element, index) => {
            if (element.id == id) {
                if (typeof (element.tipoTarefaPaiId) != "undefined" && element.tipoTarefaPaiId != null && element.tipoTarefaPaiId.length) {
                    nome += this.buscarTipoTarefaPaiNome(tipos, element.tipoTarefaPaiId) + ' - ';
                }
                nome += element.nome;
            }
        });
        return nome;
    }

    cadastrar() {
        this.add(this.tipoTarefa);
    }

    add(tipoTarefa) {
        this.spinner.show();
        this.toastrService.clear();

        this.tipoTarefaService.add(tipoTarefa)
            .subscribe(res => {
                this.toastrService.success("Tipo de Tarefa cadastrado.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o tipo de tarefa!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }


    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}