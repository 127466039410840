import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HonorarioStatusRequest } from "../models/operacionais/honorario/honorario-status-request";

@Injectable()
export class HonorarioService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'Honorario/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    remover(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    buscaroHonorario(tipoHonorario, tipo, referenciaId): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'obter-faturamento/' + tipoHonorario + '/' + tipo + '/' + referenciaId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    buscarDetalhesHonorario(tipo, referenciaId): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'obter-detalhes-honorario/' + tipo + '/' + referenciaId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    buscarTodos(tipoHonorario, tipo, referenciaId): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'obter-todos/' + tipoHonorario + '/' + tipo + '/' + referenciaId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addPorValor(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'adicionar-por-valor', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    cadastrarFaturamento(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'cadastrar-faturamento', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addPorValorComArquivo(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'adicionar-por-valor-com-arquivo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addPorPercentualComArquivo(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'adicionar-por-percentual-com-arquivo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizarPorPercentualComArquivo(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'atualizar-por-percentual-com-arquivo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addPorPercentual(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'adicionar-por-percentual', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addPorTimesheet(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'adicionar-por-timesheet-com-arquivo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizarPorTimesheet(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'atualizar-por-timesheet-com-arquivo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addPorInitialFee(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'adicionar-por-initialfee-com-arquivo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizarPorInitialFee(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'atualizar-por-initialfee-com-arquivo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    possuiInitialFeeCadastrado(recursoDesdobramentoId){
        return this.http.get(environment.api + this.endpoint + 'possui-initialfee-cadastrado/'+ recursoDesdobramentoId)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    calcularValorTimesheet(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'calcular-valor-timesheet', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterValorHoraCliente(tipo, referenciaId): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'obter-valor-hora-cliente/' + tipo + '/' + referenciaId)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    calcularSucessFee(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'calcular-successfee-processo', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterPorId(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'obter-por-id/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizarPorValorComArquivo(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'atualizar-por-valor', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterValorInitialFee(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'calcular-initialfee-processo/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterHonorariosConvenionados(filtro): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'obter-honorarios-convenionados', filtro)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizarHonorariosConvenionados(honorarioStatusRequest: HonorarioStatusRequest): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'atualizar-status-honorarios-convenionados', honorarioStatusRequest)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    atualizarHonorariosConvenionadosCollection(honorariosStatusRequest: Array<HonorarioStatusRequest>): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'atualizar-status-honorarios-convenionados-collection', honorariosStatusRequest)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
