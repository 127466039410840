<h4
  *ngIf="!recursoDesdobramentoId"
  class="d-flex justify-content-between align-items-center font-weight-bold pt-3"
>
  <span>{{ "Atualizações de Valores" }}</span>
  <button
    type="button"
    class="btn btn-md btn-padrao"
    routerLink="{{ raiz }}/atualizacao-valores"
  >
    <span class="fas fa-plus-circle"></span>&nbsp;&nbsp;Novo Cálculo
  </button>
</h4>
<hr *ngIf="!recursoDesdobramentoId" />

<hr class="border-light my-0" />

<div class="card">
  <div>
    <div class="table-responsive" *ngIf="atualizacoes.length > 0">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('nome')"
            >
              Nome<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'nome'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('indice')"
            >
              Índice<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'indice'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('data')"
            >
              Data de Atualização<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'data'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center cursor-pointer"
              (click)="ordenar('data')"
            >
              Valor Atualizado Total<i
                class="ion text-muted ml-2"
                *ngIf="colunaOrdenacao == 'valorAtualizadoTotal'"
                [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"
              ></i>
            </th>
            <th
              class="text-nowrap text-center"
            >
              Aprovado?<i
                class="ion text-muted ml-2"                
              ></i>
            </th>
            <th class="text-center text-nowrap align-middle">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tabela" *ngFor="let item of atualizacoes">
            <td>{{ item[CALCULATION_DATA_FORM_BINDING.NOME] }}</td>
            <td>{{ item.indice?.nome }}</td>
            <td>
                {{
                  item[CALCULATION_DATA_FORM_BINDING.DATA_ATUALIZACAO]
                    | date : "dd/MM/yyyy"
                }}
            </td>
            <td>
                {{
                  item[CALCULATION_DATA_FORM_BINDING.VALORTOTALATUALIZADO]
                    | currency: 'BRL'
                }}
              </td>
              <td>
                {{
                  item[CALCULATION_DATA_FORM_BINDING.APROVADO] ? 'Sim' : '-'
                }}
              </td>
            <td class="text-left text-nowrap align-middle link">
              <a
                type="button"
                href="{{ raiz }}/atualizacao-valores/{{ item.id }}"
                class="btn btn-xs icon-btn btn-warning"
              >
                <span class="far fa-edit"></span>
              </a>

              <button
                type="button"
                class="btn btn-xs icon-btn btn-primary"
                (click)="openPdf(item[CALCULATION_DATA_FORM_BINDING.ID])"
              >
                <span class="fas fa-eye"></span>
              </button>
              <button type="button" class="btn btn-xs icon-btn btn-success" *ngIf="!item.aprovado"
                (click)="aprovar(item[CALCULATION_DATA_FORM_BINDING.ID])">
                <span class="fas fa-check-circle"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center mt-5" *ngIf="atualizacoes.length == 0">
      <h3>Não possui dados.</h3>
    </div>
  </div>
</div>
