import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SelectList } from '../models/select-list';
import { Cor, CorLabel } from '../enums/tag/cor.enum';


@Injectable({
    providedIn: 'root'
})
export class SituacaoCasoProcessoJudicialService extends ServiceBase {    

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'situacaoCasoProcessoJudicial/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }
    
    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllFase(): Observable<any> {
        return this.http.get(environment.api + this.endpoint+ 'obter-fases')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllTipos(): Observable<any> {
        return this.http.get(environment.api + this.endpoint+ 'obter-tipos')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllGatilhos(): Observable<any> {
        return this.http.get(environment.api + this.endpoint+ 'obter-gatilhos')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByTipo(tipo): Observable<any> {
        return this.http.get(environment.api + this.endpoint +'obter-por-tipo/'+tipo )
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterTipoProcessoPorFase(tipoFase: any) {
        return this.http.get(environment.api + this.endpoint +'obter-tipos-processo-por-fase/'+tipoFase )
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getByIdAsync(id) {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            )
            .toPromise();
    }

    obterSituacaoCasoProcessoJudicialFiltroNomeAsync(busca) {
        return this.http
            .get(environment.api + this.endpoint + "obter-situacao-caso-processo-judicial-filtro/" + busca)
            .pipe(map(this.extractData), catchError(this.serviceError))
            .toPromise();
    }

    getCores() {
        let listTipoTag: SelectList[] = [];

        listTipoTag.push(new SelectList(Cor.badgeDefault.toString(), CorLabel.get(Cor.badgeDefault)));
        listTipoTag.push(new SelectList(Cor.badgeWarning.toString(), CorLabel.get(Cor.badgeWarning)));
        listTipoTag.push(new SelectList(Cor.badgeDanger.toString(), CorLabel.get(Cor.badgeDanger)));
        listTipoTag.push(new SelectList(Cor.badgeDark.toString(), CorLabel.get(Cor.badgeDark)));
        listTipoTag.push(new SelectList(Cor.badgeSuccess.toString(), CorLabel.get(Cor.badgeSuccess)));
        listTipoTag.push(new SelectList(Cor.badgePrimary.toString(), CorLabel.get(Cor.badgePrimary)));

        return listTipoTag;
    }
}
