<div class="row mt-3">
    <div class="col-12">
        <textarea class="form-control" rows="4" [(ngModel)]="descricao"></textarea>
    </div>
    <div class="col-12 mt-1">
        <div class="float-right">
            <button class="btn btn-primary" (click)="addHistorico()" [disabled]="loadComentario">
                <i class="fas fa-plus-circle" *ngIf="!loadComentario"></i>
                <i class="fas fa-spinner fa-pulse" *ngIf="loadComentario"></i>
                Adicionar comentário</button>
        </div>
    </div>
    <div class="col-12 text-center" *ngIf="loadComentarioPage">
        <i class="fas fa-spinner fa-pulse"></i> Carregando comentários ...
    </div>
    <div class="col-12" *ngIf="!loadComentarioPage">        
        <div class="media pb-1 mb-3 mt-2" *ngFor="let historico of historicos">            
            <div class="media-body ml-3">
                <a href="javascript:void(0)"><strong>{{historico.criadoPor}}</strong></a>
                <p class="my-1">{{historico.descricao}}</p>
                <div class="clearfix">

                    <span class="float-left text-muted small">
                        {{historico.dataCriacao | date:"dd/MM/yyyy HH:mm"}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>