import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SortDirection } from '@swimlane/ngx-datatable';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RequisicaoBuscaPaginada } from 'src/app/models/shared/busca-paginada';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-atendimentos-lista',
    templateUrl: './atendimentos-lista.component.html',
    styleUrls: ['./atendimentos-lista.component.css']
})

export class AtendimentosListaComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() raiz: string;
    @Input() hasFilter = true;
    @Input() isModal = false;
    public atendimentos: any;
    modalRef: NgbModalRef;
    fromDash = false;
    
    //Filtros como input
    @Input() situacao = 'todos';
    @Input() clientes: Guid []= [];
    @Input() responsaveis: Guid []= [];
    @Input() situacoesEncerramento: Guid[];
    //Filtros
    pasta = '';
    
    ultimoRegistro = '';
    numeroProcesso = '';
    tipoFiltroData = '1';
    filtroDataInicio = null;
    filtroDataFim = null;

    public filtroOn: boolean = false;
    totalItens: number = 0;
    paginaAtual: number = 1;
    itensPorPagina = 50
    colunaOrdenacao: string = 'DataInicio';
    ordernarDesc: boolean = true;

    get totalPaginas() {
        return Math.ceil(this.totalItens / this.itensPorPagina);
    }

    situacoes = [
        {
            nome: 'Ativos',
            id: 'ativos'
        },
        {
            nome: 'Encerrados',
            id: 'encerrados'
        },
        {
            nome: 'Todos',
            id: 'todos'
        },
    ];

    tiposFiltroData = [
        {
            nome: 'Início do Atendimento',
            id: '1'
        },
        {
            nome: 'Encerramento do Atendimento',
            id: '2'
        },
        {
            nome: 'Último Andamento',
            id: '3'
        },
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        public atendimentoService: AtendimentoService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {

        this.fromDash = window.location.href.includes('dashboard/');
        if (this.referenciaId == undefined)
            this.referenciaId = this.activatedRoute.snapshot.params['referenciaId'];

        if (this.tipo == undefined)
            this.tipo = this.activatedRoute.snapshot.params['tipo'];

        this.buscarAtendimentos();

        if (this.raiz == undefined) {
            if (this.router.url.indexOf('/dashboard/') > -1) {
                this.raiz = '/dashboard'
            } else {
                this.raiz = '/operacionais'
            }
        }

        console.log(this.raiz);
    }

    buscarAtendimentos() {

        this.spinner.show();

        this.atendimentoService.obterAtendimentosPorFiltro(this.obterFiltrosRequisicao(true))
            .subscribe(res => {
                if (res.success) {
                    
                    this.atendimentos = res.data.itens;
                    this.totalItens = res.data.paginacao.totalItens;
                    this.spinner.hide();
                }
            })
    }

    //TODO: Remover
    carregarMais() {
        this.spinner.show();
        this.buscarAtendimentos();
    }

    registro(atendimento: any) {

        //Adicionar caso e atividade futuramente
        if (atendimento.tipo == 1) {
            this.router.navigateByUrl('/operacionais/processos-judiciais/detalhes/' + atendimento.registroId);
        }
    }

    limparFiltros(): void {

        this.situacao = 'todos';
        this.pasta = '';
        this.clientes = [];
        this.responsaveis = [];
        this.ultimoRegistro = '';
        this.numeroProcesso = '';
        this.tipoFiltroData = '1';
        this.filtroDataInicio = null;
        this.filtroDataFim = null;
        this.situacoesEncerramento = [];
        
        this.paginaAtual = 1;
        this.buscarAtendimentos();
    }

    alterarPagina(paginaSelecionada) {

        this.paginaAtual = paginaSelecionada;
        this.buscarAtendimentos();
    }

    ordenar(colunaOrdenacao: string) {

        this.colunaOrdenacao = colunaOrdenacao;
        this.ordernarDesc = !this.ordernarDesc;
        this.buscarAtendimentos();
    }

    exportarParaExcel() {
        this.spinner.show();

        this.atendimentoService.obterAtendimentosPorFiltro(this.obterFiltrosRequisicao(false)).subscribe(res => {

            this.atendimentos = res.data.itens;
            this.totalItens = res.data.paginacao.totalItens;

            setTimeout(() => {
                this.spinner.hide();

                const tabela = document.getElementById("tableAtendimentos");
                const tabelaClone = tabela.cloneNode(true) as HTMLElement;

                const linhas = tabelaClone.getElementsByTagName("tr");
                for (let i = 0; i < linhas.length; i++) {
                    linhas[i].deleteCell(-1); // Remove a última coluna
                }

                const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tabelaClone, { dateNF: 'mm/dd/yyyy;@', cellDates: false, raw: true });
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Atendimentos');

                XLSX.writeFile(wb, 'Atendimentos.xlsx');
            }, 5000);

        }, error => {
            if (error && error.errors) {
                this.toastr.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastr.error('Não foi possível exportar dados para excel!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    obterFiltrosRequisicao(paginar: Boolean = true) {
        const requisicaoBuscaPaginada: RequisicaoBuscaPaginada = {
            itensPorPagina: this.itensPorPagina,
            paginaAtual: paginar ? this.paginaAtual : 0,
            colunaOrdenacao: this.colunaOrdenacao,
            direcaoOrdenacao: this.ordernarDesc ? SortDirection.desc : SortDirection.asc,
        };

        let requisicao = {
            paginacao: requisicaoBuscaPaginada,
            situacao: this.situacao,
            pasta: this.pasta,
            clientes: this.clientes,
            responsaveis: this.responsaveis,
            ultimoRegistro: this.ultimoRegistro,
            numeroProcesso: this.numeroProcesso,
            tipoFiltroData: this.tipoFiltroData,
            filtroDataInicio: this.filtroDataInicio,
            filtroDataFim: this.filtroDataFim,
            registroId: this.referenciaId,
            situacoesEncerramento: this.situacoesEncerramento
        };
        return requisicao;
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
