import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-options-grid-docs',
  templateUrl: './options-grid-docs.component.html',
  styleUrls: ['./options-grid-docs.component.scss']
})
export class OptionsGridDocsComponent implements ICellRendererAngularComp {
  documento;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params): void {
    this.documento = params.documento;
  }

  refresh(params) {
    return false;
  }

}
