import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { MunicipiosService } from 'src/app/services/municipios.service';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';

@Component({
    selector: 'app-modal-atualiza-municipios',
    templateUrl: './modal-atualiza-municipios.component.html',
    providers: [MunicipiosService]
})

export class ModalAtualizaMunicipiosComponent implements OnInit {

    modalRef: NgbModalRef;
    public municipio: any;
    public listaEstados = [];

    constructor(
        private appService: AppService,
        private municipiosService: MunicipiosService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private dadosCargasService: DadosCargasService
    ) { }

    ngOnInit(): void {
        this.municipio = this.modalRef.componentInstance.municipio;
        this.listaEstados = this.dadosCargasService.mockEstados();
    }

    atualizar() {
        this.update(this.municipio);
    }

    update(municipio) {
        this.spinner.show();
        this.toastrService.clear();

        this.municipiosService.update(municipio)
            .subscribe(res => {
                this.toastrService.success("Município atualizado!.", "Sucesso");
                this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar o município!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}