import { Component } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-acesso-logado',
    templateUrl: './acesso-logado.component.html'
})
export class AcessoLogadoComponent {
    token: string;
    id: any;
    usuarioId: any;
    nome: string;
    email: string;
    tokenValido: boolean = false;
    page: string;
    action: string;
    processoId: any;

    constructor(private route: ActivatedRoute,
        private appService: AppService,
        private router: Router,
        private authService: AuthService,
        private usuarioService: UsuarioService) {
        this.appService.pageTitle = 'Acordo - Aprovação';
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.token = params['data'];
            this.id = params['id'];
            this.page = params['page'];
            this.action = params['action'];
            this.processoId = params["processoId"];

            this.usuarioService.loginComRefreshToken(this.token)
                .subscribe(result => {
                    this.tokenValido = result.success;
                    this.usuarioId = result.data.id;
                    this.nome = result.data.nome;
                    this.email = result.data.email;

                    this.authService.storeTokenData(this.email, result.data);

                    if (this.tokenValido && this.page) {
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                "processoId": this.processoId,
                                "id": this.id,
                                "action": this.action
                            }
                        };

                        // this.router.navigate(['/operacionais/processos-credor'], navigationExtras);
                        this.router.navigate([this.page], navigationExtras);
                    }
                });
        });
    }
}
