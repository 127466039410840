import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { TipoDespesaOptions } from 'src/app/enums/tipo-despesa/tipo-despesa.enum';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { DespesaService } from 'src/app/services/despesa.service';
import { NotaDeDebitoService } from 'src/app/services/notadedebito.service';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { ModalCadastrarComprovanteComponent } from './components/modal-cadastrar-comprovante/modal-cadastrar-comprovante.component';
import { ModalCadastrarNotaDebitoComponent } from './components/modal-cadastrar-nota-debito/modal-cadastrar-nota-debito.component';
import { ModalPagamentoNotaDebitoComponent } from './components/modal-pagamento-nota-debito/modal-pagamento-nota-debito.component';

@Component({
  selector: 'app-gestao-despesas',
  templateUrl: './gestao-despesas.component.html',
  styleUrls: ['./gestao-despesas.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
],
})
export class GestaoDespesasComponent implements OnInit {

    public activeTab = 1;

    public loadDespesasEmAberto = true;
    public loadNotasDebitoPendente = false;
    public loadNotasDebitoVencidas = false;
    public loadNotasDebitoPagas = false;
    public loadExtratoConsolidado = false;
    public loadDespesasProcessadas = false;

    /** Filtros */

    public listDespesasEmAberto = [];
    public listDespesasEmAbertoFiltrada = [];
    public listNotasDebitoPendente = [];
    public listNotasDebitoPendenteFiltrada = [];
    public listNotasDebitoVencidas = [];
    public listNotasDebitoVencidasFiltrada = [];
    public listNotasDebitoPagas = [];
    public listNotasDebitoPagasFiltrada = [];
    public listExtratoConsolidado = [];
    public listExtratoConsolidadoFiltrada = [];
    public extratoConsolidado;
    public listDespesasProcessadas = [];
    public listDespesasProcessadasFiltrada = [];

    public headerGridDespAberto = [
        { title: "N° Processo", field: "numeroProcesso", ord: "NumeroProcesso" },
        { title: "Pasta", field: "pasta", ord: "Pasta" },
        { title: "Nome Cliente", field: "nomeCliente", ord: "NomeCliente" },
        {
            title: "Data Vencimento",
            field: "dataLancamento",
            ord: "DataLancamento",
        },
        { title: "Valor", field: "valor", ord: "Valor" },
        { title: "Ações", field: "action" },
    ];

    public headerGridNotasDebito = [
        { title: "Cód.", field: "idExterno", ord: "idExterno" },
        { title: "Nome Cliente", field: "nomeCliente", ord: "NomeCliente" },
        { title: "Data Vencimento", field: "vencimento", ord: "Vencimento" },
        { title: "Débito", field: "debito", ord: "Debito" },
        { title: "Crédito", field: "credito", ord: "Credito" },
        { title: "Ações", field: "action" },
    ];
    public headerGridNotasDebitoPagas = [
        { title: "Cód.", field: "idExterno", ord: "idExterno" },
        { title: "Nome Cliente", field: "nomeCliente", ord: "NomeCliente" },
        { title: "Data Vencimento", field: "vencimento", ord: "Vencimento" },
        { title: "Débito", field: "debito", ord: "Debito" },
        { title: "Crédito", field: "credito", ord: "Credito" },
    ];

    public headerGridExtratoConsolidado = [
        { title: "Nome Cliente", field: "nomeCliente", ord: "NomeCliente" },
        { title: "Recurso", field: "recursoDesdobramento", ord: "RecursoDesdobramento" },
        { title: "Pasta", field: "numeroPasta", ord: "NumeroPasta" },
        { title: "Data Vencimento", field: "vencimento", ord: "Vencimento" },
        { title: "Data Pagamento", field: "dataFaturamento", ord: "DataFaturamento" },
        { title: "Tipo", field: "tipoDespesa", ord: "TipoDespesa" },
        { title: "Débito", field: "debito", ord: "Debito" },
        { title: "Crédito", field: "credito", ord: "Credito" },
    ];

    public headerGridDespProcessadas = [
        { title: "N° Processo", field: "numeroProcesso", ord: "NumeroProcesso" },
        { title: "Pasta", field: "pasta", ord: "Pasta" },
        { title: "Nome Cliente", field: "nomeCliente", ord: "NomeCliente" },
        {
            title: "Data Vencimento",
            field: "dataLancamento",
            ord: "DataLancamento",
        },
        { title: "Valor", field: "valor", ord: "Valor" },
        { title: "Ações", field: "action" },
    ];
    resultConfirmDialog$ = new Subject<ResultadoModal>();

    /** Filtro notas pagas  */
    public tipoFiltroValor: Number = 1;
    public dataInicio;
    public dataFim;
    public clickedFilter = false;
    public clientes;
    public clickedFilterExtrato = false;
    public tiposDespesa = TipoDespesaOptions;
    public numeroRecurso;
    public numeroPasta;
    public valorTotalDespesa;
    public valorTotalDespesaProcessadas
    public dropStatus = []
    public selectedStatus = [];
    public filtroProcesso;
    public filtroValor;
    public filtroPasta;

    constructor(
        private despesasService: DespesaService,
        private notasDebitoService: NotaDeDebitoService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private arquivoService: ArquivoService,
        private utilsService: UtilsService,
        private ngbModal: NgbModal
    ) { }

    filters: any[] = [];
    sortDescByDespesasPendente: boolean = true;
    sortByDespesasPendente: string = 'dataLancamento';
    sortDescNotaDebitoPendente: boolean = true;
    sortByNotaDebitoPendente: string = 'nomeCliente';
    sortDescNotaDebitoAberta: boolean = true;
    sortByNotaDebitoAberta: string = 'idExterno';
    sortDescNotaDebitoPaga: boolean = true;
    sortByNotaDebitoPaga: string = 'dataPagamento';
    sortDescExtratoConsolidado: boolean = false;
    sortByExtratoConsolidado: string = 'razao'

    /*paginacao*/
    totalItens = 0;
    itensPorPagina = 50
    paginaAtual = 1;

    get totalPaginas() {
        return Math.ceil(this.totalItens / this.itensPorPagina);
    }

    ngOnInit(): void {
        this.getDespesasEmAberto();
        this.dropStatus = [{id: 1, name: "A vencer"}, {id: 2, name: "Vencido"}, {id: 3, name: "Pago"}]
    }

    getCssClass(nota){
        if(nota.pago)
            return 'pago'
        if(nota.vencido)
            return 'vencido'
        if(nota.vencimentoProximo)
            return 'vencimento-proximo'
        if(nota.semVencimento)
            return 'sem-vencimento'
    }

    checkForArquivo(nota): boolean {
        let arquivoId = Guid.parse(nota.arquivoId);
        let isEmpty = arquivoId.isEmpty();
        return !isEmpty;
    }
    
    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    getExtratoConsolidado() {
        this.loadExtratoConsolidado = true;
        this.listExtratoConsolidado = [];
        this.notasDebitoService
            .obterExtratoConsolidado(
                this.dateToString(this.dataInicio),
                this.dateToString(this.dataFim, true),
                this.clientes, this.numeroPasta, this.numeroRecurso)
            .subscribe((res) => {
                // this.listExtratoConsolidado = res.data.extrato;
                this.loadExtratoConsolidado = false;
                if(res.data == null){
                    this.extratoConsolidado = {
                        credito: 0,
                        debito: 0,
                        extrato: []
                    }
                    this.listExtratoConsolidado = [];
                    this.listExtratoConsolidadoFiltrada = [];
                    return;
                }
                this.extratoConsolidado = res.data;
                this.clickedFilterExtrato = true;
                // this.listExtratoConsolidado.forEach((item) => {
                //     item.tipoDespesa = this.tiposDespesa.find(tipo => tipo._id == item.tipoDespesa)?.status;
                // });
                this.listExtratoConsolidado = res.data?.extrato.map(extrato => ({
                    ...extrato,
                    tipoDespesa: this.tiposDespesa.find(tipo => tipo._id == extrato.tipoDespesa)?.status,
                    saldo: extrato.credito + extrato.debito
                }))
                this.listExtratoConsolidadoFiltrada = this.listExtratoConsolidado;
                this.ordernarAbaAtiva();
                this.paginarAbaAtiva();
            });
    }

    onMaterialGroupChange(event) {
        this.selectedStatus = event;
      }

    getDespesasEmAberto() {
        this.valorTotalDespesa = 0;
        this.listDespesasEmAberto = [];
        this.loadDespesasEmAberto = true;
        this.despesasService.obterDespesasNaoFaturadas(this.dateToString(this.dataInicio),
        this.dateToString(this.dataFim, true)).subscribe((res) => {
            this.listDespesasEmAberto = res.data;
            this.listDespesasEmAbertoFiltrada = this.listDespesasEmAberto;
            this.loadDespesasEmAberto = false;
            this.valorTotalDespesa = res.data ? res.data.reduce((acc, cur) => acc + cur.valor, 0) : 0;
            this.ordernarAbaAtiva();
            this.paginarAbaAtiva();
        });
    }

    getDespesasProcessadas() {
        this.valorTotalDespesa = 0;
        this.listDespesasProcessadas = [];
        this.loadDespesasProcessadas = true;
        this.despesasService.obterDespesasFaturadas(this.dateToString(this.dataInicio),
        this.dateToString(this.dataFim, true), this.clientes).subscribe((res) => {
            this.listDespesasProcessadas = res.data;
            this.listDespesasProcessadasFiltrada = this.listDespesasProcessadas;
            this.loadDespesasProcessadas = false;
            this.valorTotalDespesaProcessadas = res.data ? res.data.reduce((acc, cur) => acc + cur.valor, 0) : 0;
            this.ordernarAbaAtiva();
            this.paginarAbaAtiva();
        });
    }

    getNotasDebitoPendentes() {
        this.listNotasDebitoPendente = [];
        this.loadNotasDebitoPendente = true;
        this.despesasService.obterDespesasPendenteNotaDebito().subscribe((res) => {
            this.listNotasDebitoPendente = res.data;
            this.listNotasDebitoPendenteFiltrada = this.listNotasDebitoPendente;
            this.loadNotasDebitoPendente = false;
            this.ordernarAbaAtiva();
            this.paginarAbaAtiva();
        });
    }

    getNotasDebitoPagar(filtroClicked = false) {
        this.listNotasDebitoVencidas = [];
        this.loadNotasDebitoVencidas = true;
        this.selectedStatus =[];

        if(!filtroClicked){
            this.dataInicio = null;
            this.dataFim = null;
            this.clientes = null;
            
        }
        this.notasDebitoService.obterNotasPendentes(this.dateToString(this.dataInicio),
        this.dateToString(this.dataFim, true),
        this.clientes).subscribe((res) => {
            this.listNotasDebitoVencidas = res.data;
            this.listNotasDebitoVencidasFiltrada = this.listNotasDebitoVencidas;
            this.loadNotasDebitoVencidas = false;
            this.ordenarEPaginar();
        });
    }

    

    openCadastrarTipoDespesa(despesa, edit = false) {
        const modalRef = this.ngbModal.open(ModalCadastrarComprovanteComponent, {
            size: "lg",
            backdrop: "static",
            keyboard: false,
        });
        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.despesa = despesa;
        modalRef.componentInstance.edit = edit;

        modalRef.result
            .then((result) => {
                this.resultConfirmDialog$.next(result);
                this.getDespesasEmAberto();
                this.ordernarAbaAtiva();
                this.paginarAbaAtiva();
            })
            .catch((error) => { });
    }

    openCadastrarNotaPendente(despesa) {
        const modalRef = this.ngbModal.open(ModalCadastrarNotaDebitoComponent, {
            size: "lg",
            backdrop: "static",
            keyboard: false,
        });
        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.recursoDesdobramentoId = despesa.processoJudicialRecursoDesdobramentoId;
        modalRef.componentInstance.clienteId = despesa.clienteId;
        modalRef.componentInstance.nomeCliente = despesa.nomeCliente;

        modalRef.result
            .then((result) => {
                this.resultConfirmDialog$.next(result);
                this.getNotasDebitoPendentes();
                this.ordernarAbaAtiva();
                this.paginarAbaAtiva();
            })
            .catch((error) => { });
    }

    openPagamentoNotaPendente(debito, isEdit = false) {
        const modalRef = this.ngbModal.open(ModalPagamentoNotaDebitoComponent, {
            size: "lg",
            backdrop: "static",
            keyboard: false,
        });
        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.debitoId = debito.id;
        modalRef.componentInstance.nomeCliente = debito.nomeCliente;
        modalRef.componentInstance.arquivoId = debito.arquivoId;
        modalRef.componentInstance.isEdit = isEdit;

        modalRef.result
            .then((result) => {
                this.resultConfirmDialog$.next(result);
                this.getNotasDebitoPagar();
                this.ordenarEPaginar();
            })
            .catch((error) => { });
    }

    ordernarAbaAtiva(abaParaOrdenar: number = null) {

        if (!abaParaOrdenar)
            abaParaOrdenar = this.activeTab;

            console.log(abaParaOrdenar)
            console.log(this.listExtratoConsolidado)
            console.log(this.listExtratoConsolidadoFiltrada)

        switch (abaParaOrdenar) {
            case 1:
                this.sort(this.listDespesasEmAbertoFiltrada, this.sortByDespesasPendente, this.sortDescByDespesasPendente);
                break;
            case 2:
                this.sort(this.listDespesasProcessadasFiltrada, this.sortByDespesasPendente, this.sortDescByDespesasPendente);
                break;

            case 3:
                this.sort(this.listNotasDebitoPendenteFiltrada, this.sortByNotaDebitoPendente, this.sortDescNotaDebitoPendente);
                break;

            case 4:
                this.sort(this.listNotasDebitoVencidasFiltrada, this.sortByNotaDebitoAberta, this.sortDescNotaDebitoAberta);
                break;
            
            case 5:
                this.sort(this.listExtratoConsolidadoFiltrada, this.sortByExtratoConsolidado, this.sortDescExtratoConsolidado);
                break;
            
        }
    }

    paginarAbaAtiva(abaParaPaginar: number = null) {

        if (!abaParaPaginar)
            abaParaPaginar = this.activeTab;

        switch (abaParaPaginar) {
            case 1:
                this.totalItens = this.listDespesasEmAbertoFiltrada.length;
                this.listDespesasEmAbertoFiltrada = this.paginate(this.listDespesasEmAbertoFiltrada);
                break;
            case 2:
                this.totalItens = this.listDespesasProcessadasFiltrada.length;
                this.listDespesasProcessadasFiltrada = this.paginate(this.listDespesasProcessadasFiltrada);
                break;
            case 3:
                this.totalItens = this.listNotasDebitoPendenteFiltrada.length;
                this.listNotasDebitoPendenteFiltrada = this.paginate(this.listNotasDebitoPendenteFiltrada);
                break;
            case 4:
                this.totalItens = this.listNotasDebitoVencidasFiltrada.length;
                this.listNotasDebitoVencidasFiltrada = this.paginate(this.listNotasDebitoVencidasFiltrada);
                break;
            
            case 5:
                this.totalItens = this.listExtratoConsolidadoFiltrada.length;
                this.listExtratoConsolidadoFiltrada = this.paginate(this.listExtratoConsolidadoFiltrada);
                break;
            

        }
    }
    sort(data: any, sortBy: string, sortDesc: boolean) {

        data.sort((a: any, b: any) => {

            a = typeof (a[sortBy]) === 'string' ? a[sortBy].trimLeft().toUpperCase() : a[sortBy];
            b = typeof (b[sortBy]) === 'string' ? b[sortBy].trimLeft().toUpperCase() : b[sortBy];

            if (a === null) { return 1; }
            if (b === null) { return -1; }

            if (a < b) { return sortDesc ? 1 : -1; }
            if (a > b) { return sortDesc ? -1 : 1; }

            return 0;
        });
    }

    paginate(data: any) {

        const perPage = parseInt(String(this.itensPorPagina), 10);
        const offset = (this.paginaAtual - 1) * perPage;

        return data.slice(offset, offset + perPage);
    }

    setSortExtratoConsolidado(key) {

        if (this.sortByExtratoConsolidado !== key) {
            this.sortByExtratoConsolidado = key;
            this.sortDescExtratoConsolidado = false;
        } else {
            this.sortDescExtratoConsolidado = !this.sortDescExtratoConsolidado;
        }

        this.ordenarEPaginar();
    }

    setSortDespesasPendente(key) {

        if (this.sortByDespesasPendente !== key) {
            this.sortByDespesasPendente = key;
            this.sortDescByDespesasPendente = false;
        } else {
            this.sortDescByDespesasPendente = !this.sortDescByDespesasPendente;
        }

        this.ordenarEPaginar();
    }

    setSortNotaDebitoPendente(key) {

        if (this.sortByNotaDebitoPendente !== key) {
            this.sortByNotaDebitoPendente = key;
            this.sortDescNotaDebitoPendente = false;
        } else {
            this.sortDescNotaDebitoPendente = !this.sortDescNotaDebitoPendente;
        }

        this.ordenarEPaginar();
    }

    setSortNotaDebitoAberta(key) {

        if (this.sortByNotaDebitoAberta !== key) {
            this.sortByNotaDebitoAberta = key;
            this.sortDescNotaDebitoAberta = false;
        } else {
            this.sortDescNotaDebitoAberta = !this.sortDescNotaDebitoAberta;
        }

        this.ordenarEPaginar();
    }

    setSortNotaDebitoPaga(key) {

        if (this.sortByNotaDebitoPaga !== key) {
            this.sortByNotaDebitoPaga = key;
            this.sortDescNotaDebitoPaga = false;
        } else {
            this.sortDescNotaDebitoPaga = !this.sortDescNotaDebitoPaga;
        }

        this.ordenarEPaginar();
    }

    definirPaginacao(paginaSelecionada: number) {

        this.paginaAtual = paginaSelecionada;
        this.ordenarEPaginar();
    }

    handleDownloadArquivo(arquivoId:any){
        this.spinner.show();
        try{
            this.arquivoService.downloadArquivo(arquivoId).subscribe(                
                data => {                    
                    if(data.type == 3){
                        this.spinner.hide();                    
                    }
                    this.utilsService.renderDownload(data, arquivoId as string);
                }
            );
        }catch{
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }

    deletarNota(notaId){

        this.swalWithBootstrapButtons.fire({
            title: 'Deseja realmente excluir a Nota de Débito?',
            text: 'As despesas contidas na nota também serão excluídas. Essa ação não poderá ser revertida!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
            this.notasDebitoService.deletarNotaDebito(notaId).subscribe(                
                data => {
                    this.toastrService.success('Nota excluída com sucesso!', 'Sucesso', { timeOut: 10000 });
                    this.getNotasDebitoPagar();
                    this.ordenarEPaginar();
                }, error => {this.toastrService.error('Não foi possível excluir a nota!', 'Atenção', { timeOut: 10000 });});
            }
        });

        
    }

    exportExcelNota(){

        this.notasDebitoService.exportExcelNotaDebito(this.dateToString(this.dataInicio), this.dateToString(this.dataFim, true), this.clientes).subscribe(                
            data => {
                this.utilsService.renderDownload(data, `notas-de-debito-pagas-${new Date().getTime()}.xlsx`);
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível gravar!', 'Atenção', { timeOut: 10000 });
                }});
    }

    dateToString(date, fim = false){
        if(date){
            if(fim){
                return `${date.year}-${date.month}-${date.day} 23:59:59`;
            }
            return `${date.year}-${date.month}-${date.day}`;
        }
        return null;
    }
    exportExcelExtrato(){
        this.notasDebitoService.exportExcelExtrato(this.dateToString(this.dataInicio), this.dateToString(this.dataFim, true), this.clientes).subscribe(                
            data => {
                this.utilsService.renderDownload(data, `Extrato-Consolidado-${new Date().getTime()}.xlsx`);
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível gravar!', 'Atenção', { timeOut: 10000 });
                }});
    }
    exportDespesaProcessada(){
        this.despesasService.exportDespesaProcessada(this.dateToString(this.dataInicio), this.dateToString(this.dataFim, true), this.clientes).subscribe(
            data => {
                console.log(data);
                this.utilsService.renderDownload(data, `Extrato-Despesas-${new Date().getTime()}.xlsx`);
        }, error => {
            console.log(error)
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível gravar!', 'Atenção', { timeOut: 10000 });
            }});
    }

    ordenarEPaginar(){

        let despesasEmAberto = this.listDespesasEmAberto;
        let notasDebitoPendente = this.listNotasDebitoPendente;
        let notasDebitoVencidas = this.listNotasDebitoVencidas;
        let extratoConsolidado = this.listExtratoConsolidado;
        let despesasProcessadas = this.listDespesasProcessadas;

        if (this.dataInicio && this.dataFim) {
            const filtroDataInicio = `${this.dataInicio.year}-${this.dataInicio.month}-${parseInt(this.dataInicio.day)} 00:00:00`;
            const filtroDataFim = `${this.dataFim.year}-${this.dataFim.month}-${parseInt(this.dataFim.day)} 23:59:59`;

            const filtrarPorData = (dataArray, dataProp) => dataArray.filter(x => 
                new Date(x[dataProp]) >= new Date(filtroDataInicio) &&
                new Date(x[dataProp]) <= new Date(filtroDataFim)
            );

            const despesaDataProp = this.tipoFiltroValor == 1 ? 'dataLancamento' : 'dataLimitePagamento';

            despesasEmAberto = filtrarPorData(despesasEmAberto, despesaDataProp);
            despesasProcessadas = filtrarPorData(despesasProcessadas, despesaDataProp);
            notasDebitoVencidas = filtrarPorData(notasDebitoVencidas, 'vencimento');
        }

        const calcularValorTotal = (dataArray) => dataArray.reduce((acc, cur) => acc + cur.valor, 0);
        this.valorTotalDespesaProcessadas = calcularValorTotal(despesasProcessadas);
        this.valorTotalDespesa = calcularValorTotal(despesasEmAberto);

        if(this.selectedStatus.length > 0){
            let status = this.selectedStatus.map(x => x.id);
            let statusArray = [];
            if(status.includes(1)){
                notasDebitoVencidas.filter(x => x.vencimentoProximo).forEach(x => statusArray.push(x));
            }
            if(status.includes(2)){
                notasDebitoVencidas.filter(x => x.vencido && ! x.pago).forEach(x => statusArray.push(x));
            }
            if(status.includes(3)){
                notasDebitoVencidas.filter(x => x.pago).forEach(x => statusArray.push(x));
            }

            notasDebitoVencidas = statusArray;
        }

        if(this.clientes){
            console.log(this.clientes)

            notasDebitoVencidas = notasDebitoVencidas.filter(x => x.clienteId == this.clientes);
            despesasProcessadas = despesasProcessadas.filter(x => x.clienteId == this.clientes);
        }

        if(this.filtroPasta && this.filtroPasta != ''){
            despesasProcessadas = despesasProcessadas.filter(x => x.pasta && x.pasta.toLowerCase().includes(this.filtroPasta.toLowerCase()));
        }

        if(this.filtroProcesso && this.filtroProcesso != ''){
            despesasProcessadas = despesasProcessadas.filter(x => x.numeroProcesso && x.numeroProcesso.toLowerCase().includes(this.filtroProcesso.toLowerCase()));
        }

        if(this.filtroValor && this.filtroValor != ''){
            despesasProcessadas = despesasProcessadas.filter(x => x.valor == parseFloat(this.filtroValor));
        }

        this.listDespesasEmAbertoFiltrada = despesasEmAberto;
        this.listNotasDebitoPendenteFiltrada = notasDebitoPendente;
        this.listNotasDebitoVencidasFiltrada = notasDebitoVencidas;
        this.listExtratoConsolidadoFiltrada = extratoConsolidado;
        this.listDespesasProcessadasFiltrada = despesasProcessadas;

        this.ordernarAbaAtiva();
        this.paginarAbaAtiva();
    }

}
