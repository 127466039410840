<!-- Filtro -->
<app-filtro-processos-casos (buscar)="obterProcessosCasos($event)"></app-filtro-processos-casos>
<!-- / Filtro -->

<!-- Export pdf -->
<button class="export" title="Exportar para PDF" tooltip-placement="bottom" tooltip-append-to-body="true"
    (click)="exportPdf()">
    <i class="fa fa-download" aria-hidden="true"></i>
</button>
<hr class="border-light my-0">

<div class="card" *ngIf="processos != undefined && processos.length != 0">

    <hr class="border-light m-0">

    <!-- Table -->
    <div class="table-responsive">
        <table class="table table-striped table-bordered card-table">
            <thead>
                <tr>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Titulo')">
                        Título<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Titulo'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('CodigoInternoAdvNet')">
                        ID Processo/Caso <i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='CodigoInternoAdvNet'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Pasta')">
                        Número Pasta<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Pasta'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Numero')">
                        Número do Processo/Caso<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Numero'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('ClientePrincipalNome')">
                        Cliente Principal<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='ClientePrincipalNome'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center">
                        Envolvidos
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Tipo')">
                        Tipo<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Tipo'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('AcaoNome')">
                        Ação<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='AcaoNome'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('ComarcaNome')">
                        Comarca<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='ComarcaNome'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('UfSigla')">
                        UF<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='UfSigla'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Situacao')">
                        Sub-fase<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Situacao'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('ValorCausa')">
                        Valor Causa <i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='ValorCausa'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('Base')">
                        Base<i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='Base'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('ResponsavelNome')">
                        Responsável <i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='ResponsavelNome'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                    <th class="text-nowrap text-center cursor-pointer" (click)="ordenar('DataAtualizacao')">
                        Última Mov. <i class="ion text-muted ml-2" *ngIf="colunaOrdenacao=='DataAtualizacao'"
                            [ngClass]="'ion-md-arrow-' + (ordernarDesc ? 'down' : 'up')"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="tabela" *ngFor="let processo of processos">
                    <td class="align-middle">
                        <div>
                            <i class="fas fa-exclamation-triangle" *ngIf="processo.processoAmigavelId != null"></i>
                            {{processo.titulo }}
                        </div>
                        <!-- <div *ngFor="let tag of processo.tags" class="mt-2 mr-1" [ngClass]="defineClass(tag.tagCor)">
                            {{tag.tagNome}}</div> -->
                    </td>
                    <td class="text-center">
                        {{processo.codigoInternoAdvNet}}
                    </td>
                    <td class="text-center">
                        {{processo.pasta}}
                    </td>
                    <td>
                        {{processo.numeroProcesso}}
                        <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                            href="{{raiz}}/processos-judiciais/detalhes/{{processo.processoJudicialId}}" target="_blank"></a>
                    </td>
                    <td>{{processo.clientePrincipalNome}}</td>
                    <td>
                        <div *ngFor="let envolvido of processo.envolvidos; let last = last">
                            {{ envolvido.nome }}<span *ngIf="!last"> | </span>
                        </div>
                    </td>
                    <td>
                        {{processo.tipo}}
                    </td>
                    <td>
                        {{processo.acaoNome}}
                    </td>
                    <td>
                        {{processo.comarcaNome}}
                    </td>
                    <td class="text-center">
                        {{processo.ufSigla}}
                    </td>
                    <td>
                        {{processo.situacaoNome}}
                    </td>
                    <td class="text-rigth">
                        {{processo.valorCausa | currency: 'BRL'}}
                    </td>
                    <td>
                        {{processo.base}}
                    </td>
                    <td>
                        {{processo.responsavelNome}}
                    </td>
                    <td>
                        {{processo.dataAtualizacao | date: 'dd/MM/yyyy'}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- / Table -->
    <!-- Paginação -->
    <div class="card-body py-0">
        <div class="row">
            <div class="col-sm text-sm-left text-center pt-3">
                <span class="text-muted" *ngIf="totalItens">Páginas {{ paginaAtual }} de {{ totalPaginas
                    }}</span>
            </div>
            <div class="col-sm text-sm-left text-center pt-3">
                <label>Itens por página:</label>
                <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                    (change)="alterarPagina(1)">
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="500">500</option>
                </select>
            </div>
            <div class="col-sm pt-6">
                <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                    [(page)]="paginaAtual" [boundaryLinks]="true" (pageChange)="alterarPagina(paginaAtual)" size="sm"
                    class="d-flex justify-content-center justify-content-sm-end m-0">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <!-- / Paginação -->
</div>
<div class="text-center mt-5" *ngIf="processos == undefined || processos.length == 0">
    <h3>Não possui dados.</h3>
</div>