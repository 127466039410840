import { Component, Input, OnInit } from '@angular/core';
import { DocumentoArquivoService } from 'src/app/services/documento-arquivo.service';

@Component({
    selector: 'documentos-resumo',
    templateUrl: './documentos-resumo.component.html',
    styleUrls: ['./documentos-resumo.component.css']
})
export class DocumentosResumoComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() raiz: string;

    public loadQuantidade: boolean = true;
    public quantidade: number = 0;
    public hideButtons: boolean = true;
    public recolhido: boolean = false;
    
    constructor(
        private documentoArquivoService:DocumentoArquivoService
    ) { }

    ngOnInit(): void {
        this.loadQuantidade = true;
        this.documentoArquivoService.obterQuantidadeDocumentosPorRegistro(this.tipo, this.referenciaId)
            .subscribe(res => {
                if (res.success) {
                    this.loadQuantidade = false;

                    this.quantidade = res.data;

                    console.log(res.data);
                }
            })
    }

    recolherDados() {
        this.recolhido = !this.recolhido;
    }

}
