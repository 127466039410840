import { TipoAndamentoService } from './../../../../../services/tipo-andamento.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { SelectList } from 'src/app/models/select-list';


@Component({
  selector: 'app-modal-cadastro-documentos',
  templateUrl: './modal-cadastro-documentos.component.html',
  providers: [DocumentoService]
})
export class ModalCadastroDocumentosComponent implements OnInit {


  modalRef: NgbModalRef;
  public documento: any;

  ListTipo: SelectList[] = [];



  constructor(
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private appService: AppService,
    private documentoService: DocumentoService,
  )
  {

  }

  popularNovoDocumento() {
    this.documento = {
        nome: '',
        descricao: '',
        tipo:this.ListTipo[0].value,
    };
}



  ngOnInit(): void {
    this.ListTipo = this.documentoService.getTipo();
    this.popularNovoDocumento();
  }

  cadastrar() {
    //if (this.validarCadastroPessoa(this.pessoa)) {
        this.add(this.documento);
    //}
  }

  add(documento) {
    this.spinner.show();
    this.toastrService.clear();

    // var pessoaModelCadastro = this.montarModelPessoaCadastro(pessoa);

    this.documentoService.add(documento)
        .subscribe(res => {
            this.toastrService.success("Novo tipo de documento cadastrado", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar o novo tipo de documento', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
  }


  close() {
    this.modalRef.close();
  }

  dismiss() {
      this.modalRef.dismiss();
  }


}


