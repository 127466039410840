import { TipoAndamentoService } from './../../../../../services/tipo-andamento.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { SelectList } from 'src/app/models/select-list';


@Component({
  selector: 'app-modal-atualiza-documentos',
  templateUrl: './modal-atualiza-documentos.component.html',
  providers: [TipoAndamentoService]
})
export class ModalAtualizaDocumentosComponent implements OnInit {

  modalRef: NgbModalRef;
  public documento: any;

  ListTipo: SelectList[] = [];

  constructor(
    private appService: AppService,
    private documentoService: DocumentoService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.ListTipo = this.documentoService.getTipo();
    this.documento = this.modalRef.componentInstance.documento;
  }


  atualizar(){
    this.update(this.documento);
}

update(documento) {

    this.spinner.show();
    this.toastrService.clear();

    this.documentoService.update(documento)
        .subscribe(res => {


            this.toastrService.success("Tipo de documento atualizado!.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível atualizar o Tipo de documento!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
}




close() {
  this.modalRef.close();
}

dismiss() {
  this.modalRef.dismiss();
}




}


