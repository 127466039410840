import { Component, Input, OnInit } from '@angular/core';
import { PessoaService } from 'src/app/services/pessoa.service';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';

@Component({
    selector: 'historico-cadastro',
    templateUrl: './historico-cadastro.component.html',
    styleUrls: ['./historico-cadastro.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class HistoricoCadastroComponent implements OnInit {

    @Input() historicoManual:any;
    @Input() index:any;

    
    constructor(
        private datePipe: DatePipe,
        
    ) { }

    ngOnInit(): void {
        
    }
}
