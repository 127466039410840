<div class="modal-header">
    <h4>Adicionar Pedido</h4>
    <button type="button" class="close" (click)="close()">&times;</button>
</div>
<div class="modal-body modal-personalizado">
    <form [formGroup]="form">
        <div class="row mb-3">
            <div class="col-12">
                <label class="form-label" for="tipo-materia-pedido">Tipo da Matéria do Pedido <span class="required-field">*</span></label>
                <tipo-materia-pedido-dropdown id="tipo-materia-pedido"
                    [(tipoMateriaPedidoId)]="tipoMateriaPedidoId"></tipo-materia-pedido-dropdown>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12">
                <label class="form-label" for="classificacao-materia-pedido">Classificação da Matéria do Pedido <span class="required-field">*</span></label>
                <classificacao-materia-pedido-dropdown id="classificacao-materia-pedido"
                    [(classificacaoMateriaPedidoId)]="classificacaoMateriaPedidoId"></classificacao-materia-pedido-dropdown>
            </div>
        </div>

        <div class="row mb-3">
            <div class="form-group col-12">
                <label class="font-weight-bold" for="detalhes">Detalhes</label>
                <textarea id="detalhes" placeholder="Digite os detalhes" class="form-control" rows="3"
                    formControlName="detalhes"></textarea>
            </div>
        </div>

        <div class="row align-items-center mb-3">
            <div class="col-12">
                <label class="font-weight-bold">Selecione o tipo do pedido: </label>
                <div class="form-check form-check-inline form-group">
                    <input mdbRadio class="form-check-input" type="radio" formControlName="pedidoVinculadoCalculo"
                        id="pedido-vinculado-calculo" value="1" />
                    <label class="form-check-label" for="pedido-vinculado-calculo">Pedido vinculado a cálculo</label>
                </div>
                <div class="form-check form-check-inline">
                    <input mdbRadio class="form-check-input" type="radio" formControlName="pedidoVinculadoCalculo"
                        id="pedido-nao-vinculado-calculo" value="0" />
                    <label class="form-check-label" for="pedido-nao-vinculado-calculo">Pedido não vinculado a
                        cálculo</label>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="form-group col-12">
                <label class="form-label">Selecione cálculos de valores vinculadas ao pedido
                    <span class="required-field" *ngIf="pedidoVinculadoCalculo">*</span></label>
                <ng-select [items]="calculosValores" (change)="calcularValoresSelecionados()"
                    [(ngModel)]="calculosValoresSelecionados" [ngModelOptions]="{standalone: true}" bindLabel="nome"
                    bindValue="id" [loading]="loadCalculosValores" [searchFn]="aplicarFiltroSelectCalculos"
                    [multiple]="true" [disabled]="!pedidoVinculadoCalculo">
                    <ng-template ng-option-tmp let-item="item">
                        <div class="line"><b>{{item.nome}}</b></div>
                        <div class="line">Valor atualizado: {{item.valorTotalAtualizado | currency: 'BRL'}}</div>
                        <div class="line">Data atualização: {{item.dataAtualizacaoValores | date:"dd/MM/yyyy"}}</div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label class="font-weight-bold" for="valor-causa">Valor da Causa</label>
                    <input class="form-control" formControlName="valorCausa" id="valor-causa" currencyMask
                        [options]="{prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left'}"
                        [readonly]="pedidoVinculadoCalculo" />
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group col-12">
                <label class="form-label">A quem beneficia esse pedido
                    <span class="required-field"></span></label>
                <ng-select [items]="partesEnvolvidas" [(ngModel)]="parteBeneficiadaPeloPedido"
                    [ngModelOptions]="{standalone: true}" bindLabel="labelPersonalizado" bindValue="pessoaId"
                    [loading]="loadCalculosValores" [searchFn]="aplicarFiltroSelectParteBeneficiada">
                </ng-select>
            </div>
        </div>
    </form>

    <div class="d-flex justify-content-end">
        <div class="col-md-3">
            <button type="button" class="btn background-color-white" (click)="close()">
                Cancelar
            </button>
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-padrao btn-block" (click)="adicionarPedido()">
                Salvar
            </button>
        </div>
    </div>
</div>