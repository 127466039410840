<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3" *ngIf="tipo != null">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos / Honorarios / </span> <span>{{obterNome()}}</span>      
    </span>
    <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button type="button" class="btn btn-primary" id="dropdownCadastrar" (click)="voltar()" >
            <i class="fas fa-chevron-circle-left"></i> &nbsp;&nbsp;&nbsp;Voltar
        </button>        
    </div>
</h4>
<div class="card">    
    <div class="card-body">
        <div class="table-responsive">                        
            <table class="table table-striped table-bordered card-table">
                <thead>
                    <tr>                        
                        <th class="text-nowrap text-center align-middle">
                            Descrição
                        </th>
                        <th class="text-nowrap text-center align-middle">
                            Valor
                        </th>
                        <th class="text-nowrap text-center align-middle">
                            Faturado?
                        </th>                        
                        <th class="text-center text-nowrap align-middle">
                            Anexos
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tabela" *ngFor="let honorario of honorarios">                        
                        <td>
                            {{honorario.descricao}}
                        </td>
                        <td>
                            {{honorario.valorHonorario}}
                        </td>
                        <td>
                            {{honorario.faturado ? 'Sim':'Não'}}
                        </td>                                                
                        <td class="text-center text-nowrap align-middle">
                            <div class="anexo d-flex align-items-center">
                                <ng-container *ngFor="let arquivo of honorario.arquivos">
                                    <span class="font-weight-bold">
                                        {{arquivo.nomeArquivo | uppercase}}
                                    </span>
                                    <div class="btn btn-primary" [ngbTooltip]="arquivo.nomeArquivo"
                                        (click)="handleDownloadArquivo(arquivo)">
                                        Download <i class="btn btn-xs icon-btn btn-primary"><span
                                                class="fas fa-download"></span></i>
                                    </div>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Paginação -->
            <!-- <div class="card-body py-0">
                <div class="row">
                    <div class="col-sm text-sm-left text-center pt-3">
                        <span class="text-muted" *ngIf="totalItens">Páginas {{ paginaAtual }} de {{ totalPaginas
                            }}</span>
                    </div>
                    <div class="col-sm text-sm-left text-center pt-3">
                        <label>Itens por página:</label>
                        <select class="form-select" name="itensPorPagina" [(ngModel)]="itensPorPagina"
                            (change)="alterarPagina(1)">
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                            <option [ngValue]="500">500</option>
                        </select>
                    </div>
                    <div class="col-sm pt-6">
                        <ngb-pagination [maxSize]="15" [collectionSize]="totalItens" [pageSize]="itensPorPagina"
                            [(page)]="paginaAtual" [boundaryLinks]="true" (pageChange)="alterarPagina(paginaAtual)"
                            size="sm" class="d-flex justify-content-center justify-content-sm-end m-0">
                        </ngb-pagination>
                    </div>
                </div>
            </div> -->
            <!-- / Paginação -->
        </div>
    </div>
</div>