import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Guid } from "guid-typescript";

@Injectable()
export class DespesaService extends ServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  public endpoint = "Despesa/";
  private dataSource = new BehaviorSubject<any>([]);
  currenteData = this.dataSource.asObservable();

  changeData(data: any[]) {
    this.dataSource.next(data);
  }

  buscarDespesas(tipo, referenciaId): Observable<any> {
    return this.http
      .get(
        environment.api +
          this.endpoint +
          "obter-faturamento/" +
          tipo +
          "/" +
          referenciaId
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  buscarDetalhesDespesas(tipo, referenciaId): Observable<any> {
    return this.http
      .get(
        environment.api +
          this.endpoint +
          "obter-detalhes-despesa/" +
          tipo +
          "/" +
          referenciaId
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  buscarTodos(tipo, referenciaId): Observable<any> {
    return this.http
      .get(
        environment.api +
          this.endpoint +
          "obter-todos/" +
          tipo +
          "/" +
          referenciaId
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  buscarTodosTipos(tipo): Observable<any> {
    return this.http
      .get(
        environment.api +
          this.endpoint +
          "obter-todos-tipos/" +
          tipo
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  buscarDespesa(despesaId): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + "obter-despesa/" + despesaId)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  addPorValor(data): Observable<any> {
    return this.http
      .post(environment.api + this.endpoint + "adicionar-por-valor", data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  cadastrarNotaDeDebito(data): Observable<any> {
    return this.http
      .post(environment.api + this.endpoint + "cadastrar-nota-de-debito", data)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  addPorValorComArquivo(data): Observable<any> {
    return this.http
      .post(
        environment.api +
          this.endpoint +
          "adicionar-por-valor-com-arquivo-custas-recurso-desdobramento",
        data
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterDespesasNaoFaturadas(dataInicio, dataFim): Observable<any> {
    let data = {
        dataInicio: dataInicio,
        dataFim: dataFim,
        tipoDespesa: "0"
    }
    return this.http
      .post(environment.api + this.endpoint + `obter-despesas-nao-faturada`, data )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterDespesasFaturadas(dataInicio, dataFim, id): Observable<any> {
    let data = {
        dataInicio: dataInicio,
        dataFim: dataFim,
        tipoDespesa: "2",
        clienteId: id ? id : Guid.createEmpty().toString()
    }
    return this.http
      .post(environment.api + this.endpoint + `obter-despesas-faturada`, data )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  exportDespesaProcessada(dataInicio, dataFim, id): Observable<any> {
    let url = environment.api + this.endpoint + "obter-despesas-faturada-excel";
    let data = {
        dataInicio: dataInicio,
        dataFim: dataFim,
        tipoDespesa: "2",
        clienteId: id ? id : Guid.createEmpty().toString()
    }
    return this.http.request(new HttpRequest(
        'POST',
        url,
        data,
        {
            reportProgress: true,
            responseType: 'blob'
        }));
  }

  obterDespesasNaoFaturadasPorRecursoDesdobramento(referenciaId, tipoDespesa, faturado ): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + `obter-despesas-nao-faturada-por-recurso-desdobramento/${referenciaId}/${tipoDespesa}/${faturado}` )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  obterDespesasNaoFaturadasPorCliente(clienteId): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + `obter-despesas-nao-faturada-por-cliente/${clienteId}` )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  obterDespesasPendenteNotaDebito(): Observable<any> {
    return this.http
      .get(environment.api + this.endpoint + `obter-despesas-pendente-nota-debito` )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  buscarDespesasProcesso(request) : Observable<any> {
    return this.http
      .post(environment.api + this.endpoint + "obter-despesas-processo", request)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  buscarArquivosDespesa(despesaId) : Observable<any> {
    return this.http
    .get(environment.api + this.endpoint + "obter-arquivos-despesa/" + despesaId)
    .pipe(map(this.extractData), catchError(this.serviceError));
  }
  buscarArquivosDespesas(despesaIds) : Observable<any> {
    return this.http
    .post(environment.api + this.endpoint + "obter-arquivos-despesas", despesaIds)
    .pipe(map(this.extractData), catchError(this.serviceError));
  }
  faturarDespesa(data) : Observable<any> {
    return this.http
    .post(environment.api + this.endpoint + "faturar-despesa", data)
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  removerArquivo(item){
    return this.http
    .post(environment.api + this.endpoint + "remover-arquivo", item)
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  editarDespesa(id, data) : Observable<any> {
    return this.http
    .put(environment.api + this.endpoint + "editar-despesa/" + id, data)
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  deletarDespesa(id) : Observable<any> {
    return this.http
    .delete(environment.api + this.endpoint + "deletar-despesa/" + id)
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  downloadArquivosDespesas(despesaId) {
    let url = `${environment.api}${this.endpoint}download-arquivos-despesa/${despesaId}`;

    return this.http.request(new HttpRequest(
        'GET',
        url,
        null,
        {
            reportProgress: true,
            responseType: 'blob'
        }));
}
}
