<!-- Filtro -->
<div class="filtro-header mt-2">

    <div class="p-3 filtro-header-titulo d-flex justify-content-between" (click)="filtroOn = !filtroOn">
        <span>Filtros</span>
        <span class="lnr lnr-chevron-up" *ngIf="filtroOn"></span>
        <span class="lnr lnr-chevron-down" *ngIf="!filtroOn"></span>
    </div>

    <div class="filtro-carteira-de-processos" *ngIf="filtroOn">
        <div class="form-row align-items-center mb-3">
            <div class="col-md-3 col-sm-6">
                <app-label-slide-toggle [model]="contemCliente" label="Cliente" (change)="contemCliente = $event"></app-label-slide-toggle>

                <pessoa-dropdown [(pessoaId)]="clientes" [selecionaMultiplos]="true"></pessoa-dropdown>
            </div>
            <div class="col-md-3 col-sm-6">
                <app-label-slide-toggle [model]="contemResponsavel" label="Responsável" (change)="contemResponsavel = $event"></app-label-slide-toggle>
                <pessoa-dropdown [(pessoaId)]="responsaveis" [selecionaMultiplos]="true"></pessoa-dropdown>
            </div>
            <div class="col-md-3 col-sm-6">
                <app-label-slide-toggle [model]="contemOutrosEnvolvidos" label="Outros Envolvidos" (change)="contemOutrosEnvolvidos = $event"></app-label-slide-toggle>
                <pessoa-dropdown [(pessoaId)]="outrosEnvolvidos" [selecionaMultiplos]="true"></pessoa-dropdown>
            </div>
            <div class="col-md-3 col-sm-6">
                <label class="form-label">Tags</label>
                <angular2-multiselect [data]="tags" [(ngModel)]="tagsSelecionadas" [settings]="tagDropdownSettings" [ngModelOptions]="{standalone: true}">
                    <c-item>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-item>
                    <c-badge>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-badge>
                </angular2-multiselect>
            </div>
        </div>

        <div class="form-row align-items-center mb-3">
            <div class="col-md-2 col-sm-4">
                <label class="form-label">Data de:</label>
                <select class="form-control form-select" [(ngModel)]="tipoFiltroData">
                    <option value="1" selected>Data de entrada</option>
                    <option value="2">Data de criação</option>
                    <option value="3">Data de atualização</option>
                    <option value="4">Data de distribuição</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label">Data inicial</label>
                <div class="input-group">
                    <input (click)="dataInicialFiltro.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                        name="dataInicialFiltroComponent" ngbDatepicker placement="bottom-right"
                        #dataInicialFiltro="ngbDatepicker" [(ngModel)]="dataInicial">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="dataInicialFiltro.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label">Data final</label>
                <div class="input-group">
                    <input (click)="dataFinalFiltro.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                        name="dataFinalFiltroComponent" ngbDatepicker placement="bottom-right"
                        #dataFinalFiltro="ngbDatepicker" [(ngModel)]="dataFinal">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="dataFinalFiltro.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label">Número da Pasta</label>
                <input type="text" [(ngModel)]="numeroPasta" class="form-control">
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label">
                    Número Processo <i class="fas fa-info-circle" ngbTooltip="Filtre múltiplos processos pelo número separando por espaço"></i>
                </label>
                <input class="form-control" [(ngModel)]="stringMultiplosNumerosProcessos" (input)="convertStringToArrayNumerosProcessos()"/>
            </div>
            <div class="col-md-2 col-sm-4">
                <app-label-slide-toggle classLabel="form-select" [model]="contemUf" label="Estado" (change)="contemUf = $event"></app-label-slide-toggle>
                <uf-dropdown [(ufId)]="ufs" [selecionaMultiplos]="true"></uf-dropdown>
            </div>            
        </div>

        <div class="form-row align-items-center mb-3">
            <div class="col-md-2 col-sm-4">
                <label class="form-label">Filtrar por</label>
                <ng-select [(ngModel)]="tipoFiltroValor" [clearable]="false" [searchable]="false">
                    <ng-option [value]="1">Valor da causa</ng-option>
                    <ng-option [value]="2">Valor atualizado</ng-option>
                    <ng-option [value]="3">Valor da condenação</ng-option>
                </ng-select>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label">R$ inicial
                </label>
                <div class="d-flex flex-row">
                    <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                        type="text" class="form-control" placeholder="Digite o valor final"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="valorInicial" maxlength="16">
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label">R$ final
                </label>
                <div class="d-flex flex-row">
                    <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                        type="text" class="form-control" placeholder="Digite o valor inicial"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="valorFinal" maxlength="16">
                </div>
            </div>
            <div class="col-md-2 col-sm-6">
                <label class="form-label form-select">Tipo</label>
                <select class="form-control form-select" [(ngModel)]="tipo">
                    <option value="" selected>Todos</option>
                    <option value="P">Processo</option>
                    <option value="C">Caso</option>
                    <option value="R">Recurso/Desdobramento</option>
                </select>
            </div>
            <div class="col-md-2 col-sm-6">
                <label class="form-label form-select">Base</label>
                <select class="form-control form-select" [(ngModel)]="base">
                    <option value="" selected>Todos</option>
                    <option value="A">Ativo</option>
                    <option value="I">Inativo</option>                    
                </select>
            </div>
            <div class="col-md-2 col-sm-6">
                <app-label-slide-toggle classLabel="form-select" [model]="contemSituacao" label="Situação" (change)="contemSituacao = $event"></app-label-slide-toggle>
                <situacao-caso-processo-judicial-dropdown [(situacaoId)]="situacoes" [selecionaMultiplos]="true"></situacao-caso-processo-judicial-dropdown>
            </div>
        </div>

        <div class="form-row align-items-center mb-3">
            <div class="col-md-2 col-sm-6">
                <app-label-slide-toggle [model]="contemInstancia" label="Instância" (change)="contemInstancia = $event"></app-label-slide-toggle>
                <instancia-dropdown [(instanciaId)]="instancias" [selecionaMultiplos]="true"></instancia-dropdown>
            </div>
            <div class="col-md-2 col-sm-6">
                <app-label-slide-toggle [model]="contemAcao" label="Ação" (change)="contemAcao = $event"></app-label-slide-toggle>
                <acao-dropdown [(acaoId)]="acoes" [selecionaMultiplos]="true"></acao-dropdown>
            </div>
            <div class="col-md-2 col-sm-6">
                <app-label-slide-toggle [model]="contemComarca" label="Comarca" (change)="contemComarca = $event"></app-label-slide-toggle>
                <comarca-dropdown [(comarcaId)]="comarcas" [selecionaMultiplos]="true"></comarca-dropdown>
            </div>
            <!-- <div class="col-md-3 col-sm-6">
                <app-label-slide-toggle [model]="contemVara" label="Vara" (change)="contemVara = $event"></app-label-slide-toggle>
                <vara-dropdown [(varaId)]="varas" [selecionaMultiplos]="true"></vara-dropdown>
            </div> -->
            <div class="col-md-2 col-sm-6">
                <label class="form-label mb-2">
                    ID do Processo/Caso <i class="fas fa-info-circle" ngbTooltip="Filtre múltiplos processos/casos pelo id separando por espaço"></i>
                </label>
                <input class="form-control" [(ngModel)]="stringMultiplosIdsAdvNet" (input)="convertStringToArrayIdsProcessosCasos()"/>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                    <button type="button" class="btn btn-padrao btn-block" (click)="filtrar()"><span
                        class="fas fa-search"></span>&nbsp;&nbsp;Filtrar</button>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                    <button type="button" class="btn bg-light btn-block" (click)="limparFiltros()"><span
                        class="fas fa-search"></span>&nbsp;&nbsp;Limpar</button>
            </div>
        </div>
    </div>
</div>
<!-- / Filtro -->