import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { FileItem, FileUploader } from "ng2-file-upload";
import { CustomFileLike } from "src/app/models/custom-file-like";
import { DocumentoArquivoService } from "src/app/services/documento-arquivo.service";
import { ProcessoCasoAtendimentoDetalhesService } from "src/app/services/processo-caso-atendimento-detalhes.service";
import { PessoaService } from "src/app/services/pessoa.service";
import { DocumentoService } from "src/app/services/documento.service";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { Tipo } from "src/app/enums/documento/tipo.enum";
import { UsuarioService } from "src/app/services/usuario.service";
import { PessoaDropdownComponent } from "../../components/pessoa-dropdown/pessoa-dropdown.component";
import { UploadInBlackgroundService } from "src/app/views/shared/upload-in-blackground/upload-in-blackground.service";

@Component({
    selector: "app-documentos-cadastro",
    templateUrl: "./documentos-cadastro.component.html",
    styleUrls: [
        "./documentos-cadastro.component.css",
        "../../../../../vendor/styles/pages/dropzone.scss",
    ],
})
export class DocumentosCadastroComponent implements OnInit {
    @ViewChild("pessoaDropdown") pessoaDropdown!: PessoaDropdownComponent;

    public teste: string;
    public id: string;
    public tipo: string;
    public referenciaId: string;
    public isAddMode: boolean = false;
    public documento: any = {};
    public anexosDelete: any = [];
    public atendimentosCasosEProcessos = [];
    public loadAtendimentosCasosEProcessos = true;
    private recursoDesdobramentoIdQueryParam: string;

    public loadDocumentos: boolean = true;
    public atendimentosCasosEProcessosSelecionado: string = "";
    public clientes: any = [];
    public libDocumentos: any = [];

    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;
    private files: Array<File> = [];

    public showUploadInBackground = false;

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-padrao btn-lg mr-3",
            cancelButton: "btn btn-default btn-lg",
        },
        buttonsStyling: false,
    });

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private documentoArquivoService: DocumentoArquivoService,
        private processoCasoAtendimentoDetalhesService: ProcessoCasoAtendimentoDetalhesService,
        private documentoService: DocumentoService,
        private usuarioService: UsuarioService,
        private uploadInBlackgroundService: UploadInBlackgroundService
    ) {
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            },
        });
    }

    carregarDocumento() {
        this.documentoArquivoService.getById(this.id).subscribe((res) => {
            if (res.success) {
                this.documento = res.data;
                this.atendimentosCasosEProcessosSelecionado = `${res.data.tipoReferencia}-${res.data.registroId}`;

                this.clientes = res.data.clientes;
            }
        });
    }

    ngOnInit(): void {
        this.uploader.onAfterAddingFile = (file) => {
          const { pasta, tipoDocumento, recursoDesdobramento } = this.documento;
          const extension = file.file.type.split("/")[1];
          const identificador = Math.floor(Math.random() * 100);
        //   const newName = `${pasta ? pasta + "-" : ""}${
        //     tipoDocumento ? tipoDocumento + "-" : ""
        //   }${
        //     recursoDesdobramento ? recursoDesdobramento + "-" : ""
        //   }${identificador}.${extension}`;
        //   file.file.name = newName;

          this.files.push(new File([file._file], file.file.name, {type: file.file.type}));

          const fileInQueue: any = this.uploader.queue.find(
            (q) => q.file.name === file.file.name
          );
          const newFile = new CustomFileLike(fileInQueue.file);
          fileInQueue.file = newFile;
        };

        this.carregarResponsavelUsuarioLogado();

        this.id = this.route.snapshot.params["id"];
        this.isAddMode = !this.id;

        this.tipo = this.route.snapshot.params["tipo"];
        this.referenciaId = this.route.snapshot.params["referenciaId"];

        this.recursoDesdobramentoIdQueryParam =
            this.route.snapshot.queryParamMap.get("recursoDesdobramentoId");

        this.carregarLibDocumentos();

        this.carregarAtendimentosCasosEProcessos();

        if (!this.isAddMode) {
            this.carregarDocumento();
        }
    }

    carregarResponsavelUsuarioLogado(): void {
        this.usuarioService
            .ObterPessoasRelacionadasAoUsuarioPorUsuarioId()
            .subscribe(
                (res) => {
                    const usuarioLogado = res.data;

                    if (usuarioLogado && usuarioLogado[0].pessoaId)
                        this.pessoaDropdown.selecionarPessoa(usuarioLogado[0].pessoaId);
                },
                (error) => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], "Atenção", {
                            timeOut: 10000,
                        });
                    } else {
                        this.toastrService.error(
                            "Não foi possível obter responsável pelo usuário logado!",
                            "Atenção",
                            { timeOut: 10000 }
                        );
                    }
                    this.spinner.hide();
                }
            );
    }

    carregarLibDocumentos() {
        this.loadDocumentos = true;
        this.documentoService.getAllByTipo(Tipo.Juridico).subscribe((res) => {
            if (res.success) {
                this.loadDocumentos = false;
                this.libDocumentos = res.data;
                this.libDocumentos.splice(0, 0, {
                    id: null,
                    nome: "--",
                });
            }
        });
    }

    buscarCliente() {
        let atendimentosCasosEProcessos = this.atendimentosCasosEProcessos.filter(
            (c) => c.registroUnicoId == this.atendimentosCasosEProcessosSelecionado
        );

        if (
            atendimentosCasosEProcessos != undefined &&
            atendimentosCasosEProcessos.length > 0
        ) {
            this.clientes = atendimentosCasosEProcessos[0].clientes;
        } else {
            this.clientes = [];
        }
    }

    carregarAtendimentosCasosEProcessos() {
        this.loadAtendimentosCasosEProcessos = true;
        this.processoCasoAtendimentoDetalhesService.getAll().subscribe((res) => {
            if (res.success) {
                this.loadAtendimentosCasosEProcessos = false;
                this.atendimentosCasosEProcessos = res.data;

                if (this.tipo && this.referenciaId) {
                    this.atendimentosCasosEProcessosSelecionado = `${this.tipo}-${this.referenciaId}`;
                    this.buscarCliente();
                }

                if (
                    this.recursoDesdobramentoIdQueryParam &&
                    this.atendimentosCasosEProcessos.length > 0
                ) {
                    const recursoDesdobramento = this.atendimentosCasosEProcessos.find(
                        (item) => item.id == this.recursoDesdobramentoIdQueryParam
                    );

                    if (recursoDesdobramento) {
                        this.atendimentosCasosEProcessosSelecionado =
                            recursoDesdobramento.registroUnicoId;
                        this.buscarCliente();
                    }
                }
            }
        });
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            debugger;
            this.files.push(files[i] as File);
        }

        const fileInQueue: any = this.uploader.queue.find(
            (q) => q.file.name === files[0].name
        );
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item: FileItem) {
        console.log(item);
        this.files = this.files.filter((c) => c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(
            (c) => c.file.name != item.file.name
        );
    }

    removerAnexo(anexo: any) {
        this.swalWithBootstrapButtons
            .fire({
                text: `Deseja excluir o anexo ${anexo.arquivoNome}?`,
                confirmButtonColor: "#FFD950 !important;",
                showCloseButton: true,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Cancelar",
            })
            .then((result) => {
                if (result.value) {
                    anexo.isDelete = true;
                    this.anexosDelete.push(anexo);
                    this.documento.anexos = this.documento.anexos.filter(
                        (c) => c.id != anexo.id
                    );
                }
            });
    }

    cancel() {
        this.location.back();
    }

    onSubmit() {
        if (
            this.documento.descricao == undefined ||
            this.documento.descricao == ""
        ) {
            this.swalWithBootstrapButtons.fire(
                "Não foi informada a descrição!",
                "",
                "error"
            );

            return;
        }

        if (
            this.atendimentosCasosEProcessosSelecionado == undefined ||
            this.atendimentosCasosEProcessosSelecionado == null
        ) {
            this.swalWithBootstrapButtons.fire(
                "Não foi informado o processo, caso ou atendimento!",
                "",
                "error"
            );

            return;
        }

        if (this.documento.responsavelId == undefined) {
            this.swalWithBootstrapButtons.fire(
                "Não foi informada o responsável!",
                "",
                "error"
            );

            return;
        }

        if (this.isAddMode && (this.files == undefined || this.files.length == 0)) {
            this.swalWithBootstrapButtons.fire(
                "Não foi anexado documento!",
                "",
                "error"
            );

            return;
        }

        if (!this.isAddMode) {
            if (this.documento.anexos.length == 0 && this.files.length == 0) {
                this.swalWithBootstrapButtons.fire(
                    "Documento sem anexos!",
                    "",
                    "error"
                );

                return;
            }
        }

        // this.spinner.show();
        this.toastrService.clear();

        let service: Observable<any> = null;
        let acao: string = "";
        if (this.isAddMode) {
            let atendimentosCasosEProcessos = this.atendimentosCasosEProcessos.filter(
                (c) => c.registroUnicoId == this.atendimentosCasosEProcessosSelecionado
            );

            if (
                atendimentosCasosEProcessos != undefined &&
                atendimentosCasosEProcessos.length > 0
            ) {
                this.documento.registroId = atendimentosCasosEProcessos[0].id;
                this.documento.tipoReferencia = atendimentosCasosEProcessos[0].tipoId;
            }

            service = this.documentoArquivoService.add(this.documento);
            acao = "Documento cadastrado com sucesso!";
        } else {
            service = this.documentoArquivoService.update(this.documento);
            acao = "Edição do documento realizada com sucesso!";
        }

        service.subscribe(
            (res) => {
                if (res.success) {
                    this.toastrService.success(acao, "Sucesso");
                    this.documento = res.data;
                    this.uploadDocumento();
                }
            },
            (error) => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], "Atenção", {
                        timeOut: 10000,
                    });
                } else {
                    this.toastrService.error("Erro ao gravar documento!", "Atenção", {
                        timeOut: 10000,
                    });
                }
                this.spinner.hide();
            }
        );
    }

    uploadDocumento() {
        if (this.files != undefined && this.files.length > 0) {
            let formData: FormData = new FormData();
            this.files.forEach((file) => {
                formData.append("files", file);
                // formData.append(
                //   "files",
                //   file,
                //   `${pasta}-${tipoDocumento}-${recursoDesdobramento}`
                // );
            });
            this.uploadInBlackgroundService.show({
                urlFrom: `dashboard/documentos/documentos-cadastro/${this.id}`,
                service: this.documentoArquivoService,
                uploadFunction: "uploadAnexo",
                params: formData,
                files: this.files,
                id: this.documento.id,
            });
            this.spinner.hide();
            this.location.back();
        } else {
            this.spinner.hide();
            this.location.back();
        }

        // if (this.files != undefined && this.files.length > 0) {
        //     let formData: FormData = new FormData();
        //     this.files.forEach(file => {
        //         formData.append('files', file);
        //     });

        //     this.documentoArquivoService.uploadAnexo(this.documento.id, formData)
        //         .subscribe(res => {
        //             if (res.success) {
        //                 this.location.back();
        //                 this.spinner.hide();
        //                 this.toastrService.success("Upload realizado com sucesso!", "Sucesso");
        //             }
        //         }, error => {
        //             if (error && error.errors) {
        //                 this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
        //             }
        //             else {
        //                 this.toastrService.error('Não foi possível fazer o upload do documento!', 'Atenção', { timeOut: 10000 });
        //             }
        //             this.spinner.hide();
        //         });
        // } else {
        //     this.spinner.hide();
        //     this.location.back();
        // }
    }

    aplicarFiltroAtendimentoCasoProcesso(term: string, item: any) {
        term = term.toLowerCase();

        return (
            item.titulo.toLowerCase().indexOf(term) > -1 ||
            (item.hasOwnProperty("pasta") &&
                item.pasta &&
                item.pasta.toString().indexOf(term) > -1) ||
            (item.hasOwnProperty("numeroProcesso") &&
                item.numeroProcesso &&
                item.numeroProcesso.toString().indexOf(term) > -1) ||
            (item.hasOwnProperty("recursoDesdobramento") &&
                item.recursoDesdobramento &&
                item.recursoDesdobramento.toString().indexOf(term) > -1)
        );
    }
}
