<div class="modal-header">
    <h4 *ngIf="!documento">Adicionar</h4>
    <h4 *ngIf="documento">Editar (Documento - {{documento?.numero}})</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <ng-container formArrayName="docs" *ngFor="let form of formArrayDoc.controls; let i = index">
            <ng-container [formGroupName]="i">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">N° Documento</label>
                            <input placeholder="000000000" type="text" class="form-control" formControlName="numero">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Vencimento</label>
                            <input type="date" class="form-control" formControlName="dtVencimento">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Remessa</label>
                            <input type="date" class="form-control" formControlName="dtRemessa">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Valor Original</label>
                            <input placeholder="R$0,00" 
                                   currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" 
                                   type="text" 
                                   class="form-control" 
                                   formControlName="valor"
                                   (ngModelChange)="calcularTotal(form.value, i)">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Juros</label>
                            <input placeholder="R$0,00" 
                                   currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" 
                                   type="text" 
                                   class="form-control" 
                                   formControlName="juros"
                                   (ngModelChange)="calcularTotal(form.value, i)">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Valor Protesto</label>
                            <input placeholder="R$0,00" 
                                   currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" 
                                   type="text" 
                                   class="form-control" 
                                   formControlName="protesto"
                                   (ngModelChange)="calcularTotal(form.value, i)">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Juros do Protesto</label>
                            <input placeholder="R$0,00" 
                                   currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" 
                                   type="text" 
                                   class="form-control" 
                                   formControlName="jurosProtesto"
                                   (ngModelChange)="calcularTotal(form.value, i)">
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Total</label>
                            <input placeholder="R$0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" type="text" class="form-control" formControlName="total">
                        </div>
                    </div>                    
                    <div class="col-9">
                        <div class="form-group">
                            <label class="font-weight-bold">Índice</label>
                            <ng-select 
                                [items]="indices" 
                                formControlName="indiceId"
                                bindLabel="nome" 
                                bindValue="id" 
                                [loading]="indices == undefined">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Valor Atualizado Índice</label>
                            <input placeholder="R$0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" type="text" class="form-control" formControlName="valorAtualizadoCalculado">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Júros Moratorios</label>
                            <input placeholder="R$0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" type="text" class="form-control" formControlName="valorJurosMoratoriosCalculado">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Júros Compensatorios</label>
                            <input placeholder="R$0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" type="text" class="form-control" formControlName="valorJurosCompensatoriosCalculado">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Multa Índice</label>
                            <input placeholder="R$0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" type="text" class="form-control" formControlName="valorMultaCalculado">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label class="font-weight-bold">Valor Honorário</label>
                            <input placeholder="R$0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" type="text" class="form-control" formControlName="valorHonorarioCalculado">
                        </div>
                    </div>
                    <div class="col-12" *ngIf="!documento">                        
                        <button type="button" class="float-right btn btn-danger btn-remover-honorario" (click)="removeItem(i)"><i class="fas fa-trash"></i></button>
                    </div>
                </div>
                <hr *ngIf="formArrayDoc.length > 1 && !documento"/>
            </ng-container>
        </ng-container>
    </form>
    <ng-container *ngIf="!documento">
        <hr/>
        <div class="row">
            <div class="col-2">
                <button class="btn btn-secondary" (click)="createItem()">ADICIONAR</button>
            </div>
            <div class="form-group col-2">
                <input class="form-control" type="number" [(ngModel)]="quantidadeLinhasAdd" >                
            </div>
            <div class="col-8 linha mt-2">
                linha(s)
            </div>
        </div>
    </ng-container>

        <div class="d-flex justify-content-end">
            <div class="col-md-2">
                <button type="button" class="btn background-color-white" (click)="close()">Cancelar</button>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-padrao btn-block" (click)="salvar()">Salvar</button>
            </div>
        </div>
</div>