import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Tipo } from 'src/app/enums/documento/tipo.enum';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { SelectList } from 'src/app/models/select-list';
import { AtividadeService } from 'src/app/services/atividade.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { SituacaoService } from 'src/app/services/situacao.service';
import { TagService } from 'src/app/services/tag.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { AtividadeTarefaRequest } from 'src/app/models/operacionais/atividade/atividade-tarefa-request';
import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { DividaPagamentosService } from 'src/app/services/divida-pagamentos.service';
import { DividaPagamentoResponse } from 'src/app/models/operacionais/divida/divida-pagamento-response';
import { NgbDateCustomParserFormatter } from '../../../../utils/dateformat';
import { Moment } from 'moment';
import * as moment from "moment";
import { formatDate } from '@angular/common';
const now = new Date();


@Component({
  selector: 'app-modal-adicionar-divida-desconto',
  templateUrl: './modal-adicionar-divida-desconto.component.html',
  styleUrls: ['./modal-adicionar-divida-desconto.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
]
})
export class ModalAdicionarDividaDescontoComponent implements OnInit {

    modalRef: NgbModalRef;
    isIE10 = false;
    public isRTL: boolean;

    public processo: ProcessoOperacao;
    public dividas: any[] = [];

    valorComprovante: number = 0;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public ocorrenciaService: OcorrenciaService,
        public dividaPagamentoSevice: DividaPagamentosService,
        private spinner: NgxSpinnerService
    ) {

        this.isRTL = appService.isRTL;

        this.isIE10 = this.appService.isIE10;
    }

    public pagamento: any = {
        observacao: '',
        dataPagamento: '',
        idProcesso: '',
        valorComprovante: 0,
        ocorrencia:{
            ocorrenciaId: '',
            descricao: '',
            processoId: ''
        },
        dividas: []
    };

    valorTotalPagamento: number = 0;
    valorPendente: number = 0;

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processo;
        this.valorComprovante = this.modalRef.componentInstance.valorComprovante;
        this.pagamento.valorComprovante = this.valorComprovante;

        this.pagamento.dividas = [];
        this.valorPendente = 0;
        this.valorTotalPagamento = 0;
        this.dividas = this.modalRef.componentInstance.dividas;

        this.dividas.forEach(divida => {
            divida.valorPendente = divida.valorTotal - divida.valorDesconto;
            divida.valorTotal = divida.valorPendente;
            divida.dividaId = divida.id;
            divida.id = "";
            divida.valorComprovante = this.pagamento.valorComprovante;
            this.valorPendente += divida.valorPendente;

        });

        this.pagamento.dividas = this.dividas;

    }

    salvar() {
        this.toastrService.clear();

        if(!this.pagamento.ocorrencia.descricao)
        {
            this.toastrService.error("Por Favor, informe a observação!", 'Atenção', { timeOut: 10000 });
        }
        else{
            this.add();
        }
    }

    add() {
        this.spinner.show();
        this.preSave();
        this.dividaPagamentoSevice.addDesconto(this.pagamento)
        .subscribe(res => {
            //this.toastrService.success('Desconto aplicado com sucesso', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
        }, error => {
            this.spinner.hide();

            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível aplicar o desconto!', 'Atenção', { timeOut: 10000 });
            }
        },() => { //complete
            this.toastrService.success("Desconto aplicado com sucesso", "Sucesso");
            
            this.spinner.hide();
            
            setTimeout(() => {
                this.close(this.processo);
            }, 300);
        });
        
    }


    preSave() {
        this.spinner.show();
        this.toastrService.clear();

        this.pagamento.idProcesso = this.processo.id;
        this.pagamento.ocorrencia.processoId = this.processo.id;
        this.pagamento.dataPagamento = formatDate(new Date(), 'yyyy/MM/dd', 'en');
        this.pagamento.dividas.forEach(divida => {
            divida.dataPagamento = this.pagamento.dataPagamento;
        });

        this.spinner.hide();
    }

    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalContatoProcesso(processo);
    }

    dismiss() {
        this.modalRef.dismiss();
    }


    calcularTotalValorQuitacao(){

        var valor = 0;
        this.dividas.forEach(divida =>{
            valor += divida.valorQuitacao;
        });

        return valor;
    }

    calcularTotalValorPendente(){

        var valor = 0;
        this.dividas.forEach(divida =>{
            valor += divida.valorPendente;
        });

        return valor;
    }
}
