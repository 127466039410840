import { TipoStatus } from './../../../enums/tipo-status.enum';
import { OcorrenciaArquivo } from './ocorrencia-arquivo';
import { Guid } from "guid-typescript";
import { OcorrenciaTag } from "./ocorrencia-tag";
import { TipoOcorrencia } from 'src/app/enums/tipo-ocorrencia.enum';

export class Ocorrencia {
    id: Guid;
    processoId: Guid;
    criadoPor: String;
    data: Date;
    descricao: String;
    isExibirPortal: boolean;
    isNotificarCredor: boolean;
    situacao: String;
    situacaoId: Guid;
    situacaoPai: String;
    situacaoPaiId: Guid;
    status: TipoStatus;
    ocorrenciaTags: OcorrenciaTag[];
    ocorrenciaArquivos: OcorrenciaArquivo[];
    tipo: TipoOcorrencia;
    isSistemaSituacao:boolean;
}
