import { OcorrenciaResponse } from './../models/operacionais/ocorrencia/ocorrencia-response';
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Guid } from "guid-typescript";
import { OcorrenciaListaResponse } from '../models/operacionais/ocorrencia/ocorrencia-lista-response';
import { Arquivo } from '../models/shared/arquivo';
import { OcorrenciaStatusRequest } from '../models/operacionais/ocorrencia/ocorrencia-status-request';
import { OcorrenciaHistoricoResponse } from '../models/operacionais/ocorrencia/ocorrencia-historico-response';
import { OcorrenciaHistorico } from '../models/operacionais/ocorrencia/ocorrencia-historico';
import { BuscaPaginadaAtividadePrioridadeCobradorResponse } from '../models/operacionais/atividade/atividade-prioridade-cobrador-response';
import { OcorrenciaRevisaoResponse } from '../models/operacionais/ocorrencia/ocorrencia-revisao-response';
import { RequisicaoBuscaPaginada } from '../models/shared/busca-paginada';
import { UtilsService } from '../utils/utils.service';


@Injectable({
    providedIn: 'root'
})
export class OcorrenciaService extends ServiceBase {

    constructor(private http: HttpClient,
                private _utilsService: UtilsService) {
        super();
    }

    public endpoint = 'ocorrencia/';
    public postFileUrl = environment.api + this.endpoint + 'arquivo';

    getAllArquivos(id): Observable<any> {
      return this.http
          .get(environment.api + this.endpoint + `${id}/arquivo`)
          .pipe(map(this.extractData), catchError(this.serviceError));
    }

    deleteArquivo(ocorrenciaId, arquivoId): Observable<any> {
      return this.http
          .delete(
              environment.api +
              this.endpoint +
              `${ocorrenciaId}/ocorrenciaArquivo/${arquivoId}`
          )
          .pipe(map(this.extractData), catchError(this.serviceError));
    }

    add(data): Observable<OcorrenciaResponse> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<OcorrenciaResponse> {
        return this.http.put(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    enviarEmail(data): Observable<OcorrenciaResponse> {
        return this.http.post(environment.api + this.endpoint + "enviar-email", data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    addHistorico(data): Observable<OcorrenciaHistorico> {
        return this.http.post(environment.api + this.endpoint + "historico", data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    updateStatus(data: OcorrenciaStatusRequest): Observable<OcorrenciaResponse> {
        return this.http.put(environment.api + this.endpoint + "update-status", data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(guid: Guid): Observable<OcorrenciaResponse> {
        return this.http.get(environment.api + this.endpoint + guid)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAllByProcesso(guid: Guid): Observable<OcorrenciaListaResponse> {
        return this.http.get(environment.api + this.endpoint + 'processo/' + guid)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    async uploadArquivo(data) {
        return this.http.post(this.postFileUrl, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }

    downloadArquivo(arquivo: Arquivo) {
        let url = environment.api + this.endpoint + "arquivo/download/" + arquivo.id;

        return this.http.request(new HttpRequest(
            'GET',
            url,
            null,
            {
                reportProgress: true,
                responseType: 'blob'
            }));
    }

    getAllHistorico(guid: Guid) : Observable<OcorrenciaHistoricoResponse> {
        return this.http.get(environment.api + this.endpoint + 'historico/' + guid)
        .pipe(
            map(this.extractData),
            catchError(this.serviceError)
        );
    }

    getAllAprovador(requisicaoBuscaPaginada: RequisicaoBuscaPaginada): Observable<BuscaPaginadaAtividadePrioridadeCobradorResponse> {
        return this.http.get(environment.api + this.endpoint + 'aprovador?' + this._utilsService.getParametroRequisicaoBuscaPaginada(requisicaoBuscaPaginada))
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getOcorrenciasRevisao(requisicaoBuscaPaginada: RequisicaoBuscaPaginada): Observable<OcorrenciaRevisaoResponse> {
        
      return this.http.get(environment.api + this.endpoint + 'revisao?' + this._utilsService.getParametroRequisicaoBuscaPaginada(requisicaoBuscaPaginada))
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
}
