<div>
    <!-- Modal template -->
    <div class="modal-header">
        
        <h5 class="modal-title">
            Adicionar tags ao processo
        </h5>        
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            
            <div class="col-12">
                <label class="form-label">Tags</label>
                <angular2-multiselect [data]="tags" [(ngModel)]="tagsSelecionadas" [settings]="tagDropdownSettings" [ngModelOptions]="{standalone: true}">
                    <c-item>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-item>
                    <c-badge>
                        <ng-template let-item="item">
                            <div class="badge {{ item.tagCor }}">{{item.tagNome}}</div>
                        </ng-template>
                    </c-badge>
                </angular2-multiselect>
            </div>

        </div>               
    </div>    
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="salvar()"><i class="fas fa-save"></i> Salvar</button>
    </div>
</div>