import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImportacaoBorderoService } from 'src/app/services/importacao-bordero.service';
import { Bordero } from 'src/app/models/operacionais/importacao-bordero/bordero';
import { TipoStatus, TipoStatusLabel } from 'src/app/enums/tipo-status.enum';
import { BorderoService } from 'src/app/services/bordero.service';
import { BorderoLinhaResponse } from 'src/app/models/operacionais/importacao-bordero/bordero-linha-response';
import { SelectList } from 'src/app/models/select-list';
import { HttpEventType } from '@angular/common/http';
import { BorderoListaResponse } from 'src/app/models/operacionais/importacao-bordero/bordero-lista-response';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
    selector: 'app-modal-historico-importacao',
    templateUrl: './modal-historico-importacao.component.html',
    styleUrls: ['../../../../../vendor/styles/pages/modal-historico-importacao.scss',
        '../../../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    ],
})

export class ModalHistoricoImportacaoComponent {
    // Filters
    filterNomeDoArquivo = '';
    filterStatus: TipoStatus = null;

    filterStatusList: SelectList[] = [];

    // Options
    perPage = 8;

    //Paginate
    currentPage = 1;
    totalItems = 0;

    borderos: Bordero[] = [];
    borderosFiltrados: Bordero[] = [];

    modalRef: NgbModalRef;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        public modalService: ModalService,
        public borderoService: BorderoService,
        public utilsService: UtilsService
    ) {

        this.loadData();

        this.filterStatusList.push(new SelectList(null, "Todos"));
        this.filterStatusList.push(new SelectList(TipoStatus.BorderoSucesso.toString(), TipoStatusLabel.get(TipoStatus.BorderoSucesso)));
        this.filterStatusList.push(new SelectList(TipoStatus.BorderoErro.toString(), TipoStatusLabel.get(TipoStatus.BorderoErro)));
    }

    loadData() {
        this.buscarborderos();
    }

    get totalPages() {
        return Math.ceil(this.totalItems / this.perPage);
    }

    paginate(data) {
        const perPage = parseInt(String(this.perPage), 10);
        const offset = (this.currentPage - 1) * perPage;

        return data.slice(offset, offset + perPage);
    }

    update() {
        this.borderosFiltrados = this.filter();

        this.totalItems = this.borderosFiltrados.length;

        this.borderosFiltrados = this.paginate(this.borderosFiltrados);
    }

    filter() {
        var borderosFiltrados = this.borderos;

        if (this.filterNomeDoArquivo != '') {
            borderosFiltrados = borderosFiltrados.filter(u => u.nomeDoArquivo.toLowerCase().includes(this.filterNomeDoArquivo.toLowerCase()));
        }
        // if(this.filterDataImportacao != ''){
        //     borderosFiltrados = borderosFiltrados.filter(u => u.dataImportacao.includes(this.filterDataImportacao));
        // }
        if (this.filterStatus != null) {
            borderosFiltrados = borderosFiltrados.filter(u => u.status == this.filterStatus);
        }

        return borderosFiltrados;
    }

    filtrar() {
        const data = this.filter();
        this.totalItems = data.length;

        this.borderosFiltrados = this.paginate(data);
    }

    buscarborderos() {
        this.spinner.show();

        this.borderoService.getAll()
            .subscribe((res: BorderoListaResponse) => {

                this.borderos = res.data;
                this.borderosFiltrados = res.data;

                this.spinner.hide();

                this.filtrar();
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção');
                }
                else {
                    this.toastrService.error('Não foi possível carregar os dados!', 'Atenção', { timeOut: 10000 });
                }

                this.borderos = [];
                this.borderosFiltrados = [];

                this.filtrar();

                this.spinner.hide();
            });
    }

    download(bordero: Bordero) {
        this.borderoService.download(bordero).subscribe(
            data => {
                this.utilsService.renderDownload(data, bordero.nomeDoArquivo as string);
            }
        );
    }

    public close() {
        this.modalRef.close();
    }

    public dismiss() {
        this.modalRef.dismiss();
    }

    public abrirModalBorderoLinha(bordero: Bordero) {
        this.modalService.abrirModalHistoricoImportacaoLinha(bordero);
    }
}
