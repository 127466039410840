<div class="modal-header">
    <h4>Multa</h4>
</div>
<div class="modal-body">
    <form [formGroup]="multaForm">
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label class="font-weight-bold">Valor:</label>
                    <input type="number" class="form-control" formControlName="valor">
                </div>
            </div>
            <div class="col-8">
                <div class="form-group">
                    <label class="font-weight-bold">Modo de cálculo:</label>
                    <select class="form-control" formControlName="tipoCalculoMulta">
                        <option value="1">Aplicar o percentual digitado ao lado</option>
                        <option value="2">Multa diária</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    &nbsp;
                    <input class="form-check-input" type="checkbox" formControlName="calcularMultaSobreJuros" id="calcularMultaSobreJuros">
                    <label class="mt-1 form-check-label" for="calcularMultaSobreJuros">
                        Desejo calcular a multa sobre o juros
                    </label>
                </div>
            </div>
        </div>
        <hr />
        <div class="d-flex justify-content-end">
            <div class="col-md-2">
                <button type="button" class="btn background-color-white" (click)="dismiss()">Cancelar</button>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-padrao btn-block" (click)="close()">OK</button>
            </div>
        </div>
    </form>
</div>