import { ProcessosService } from './../../../../services/processos.service';
import { NotificacaoService } from './../../../../services/notificacao.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';
import { UtilsService } from 'src/app/utils/utils.service';
import { PddService } from 'src/app/services/pdd.service';

@Component({
  selector: 'app-modal-pdd',
  templateUrl: './modal-pdd.component.html',
  styleUrls: ['./modal-pdd.component.css']
})
export class ModalPddComponent implements OnInit {

  modalRef: NgbModalRef;
  processoId:any;
  idExterno:any = "";
  cobradorId:any;


  public loadingEsgotado:Boolean = false;
  public loadingEmpresaFechou:Boolean = false;
  public loadingTitulosPrescritos:Boolean = false;
  public loadingNaoLocalizado:Boolean = false;

  public loadingModeloRepasse:Boolean = false;

  constructor(
    public pddService: PddService,    
    public toastrService: ToastrService,
    public processoService:ProcessosService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.processoId = this.modalRef.componentInstance.processoId;

    this.processoService.getById(this.processoId).subscribe(x => {
      this.idExterno = x.data.idExterno.toString();
      this.cobradorId = x.data.cobradorId;
    })

  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }





  Esgotado() {

    this.loadingEsgotado = true;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Esgotado',
      text:'Essa ação vai gerar uma ocorrencia com esse documento em anexo,deseja continuar ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){
      this.pddService.PddEsgotado(this.processoId).subscribe( res =>{
        console.log(res);
        this.toastrService.success("Ocorrencia gerada", "Success")
        this.loadingEsgotado = false;
      },error=>{
        this.loadingEsgotado = false;
        this.toastrService.error("Erro ao gerar ocorrencia com documento anexado!", "Error")
      });
    }else{
      this.loadingEsgotado = false;
    }

  });
  }


  NaoLocalizado() {

    this.loadingNaoLocalizado = true;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Não Localizado',
      text:'Essa ação vai gerar uma ocorrencia com esse documento em anexo,deseja continuar ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){
      this.pddService.PddNaoLocalizado(this.processoId).subscribe( res =>{
        console.log(res);
        this.toastrService.success("Ocorrencia gerada", "Success")
        this.loadingNaoLocalizado = false;
      },error=>{
        this.loadingNaoLocalizado = false;
        this.toastrService.error("Erro ao gerar ocorrencia com documento anexado!", "Error")
      });
    }else{
      this.loadingNaoLocalizado = false;
    }

  });
  }

  EmpresaFechou() {

    this.loadingEmpresaFechou = true;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Empresa Fechou',
      text:'Essa ação vai gerar uma ocorrencia com esse documento em anexo,deseja continuar ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){
      this.pddService.PddEmpresaFechou(this.processoId).subscribe( res =>{
        console.log(res);
        this.toastrService.success("Ocorrencia gerada", "Success")
        this.loadingEmpresaFechou = false;
      },error=>{
        this.loadingEmpresaFechou = false;
        this.toastrService.error("Erro ao gerar ocorrencia com documento anexado!", "Error")
      });
    }else{
      this.loadingEmpresaFechou = false;
    }

  });
  }


  TitulosPrescritos() {

    this.loadingTitulosPrescritos = true;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-padrao btn-lg mr-3',
          cancelButton: 'btn btn-default btn-lg'
      },
      buttonsStyling: false,

  });

  swalWithBootstrapButtons.fire({
      title: 'Titulos Prescritos',
      text:'Essa ação vai gerar uma ocorrencia com esse documento em anexo,deseja continuar ?',
      confirmButtonColor: '#FFD950 !important;',
      showCloseButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

  }).then((result) => {

    if(result.value === true){
      this.pddService.PddTitulosPrescritos(this.processoId).subscribe( res =>{
        console.log(res);
        this.toastrService.success("Ocorrencia gerada", "Success")
        this.loadingTitulosPrescritos = false;
      },error=>{
        this.loadingTitulosPrescritos = false;
        this.toastrService.error("Erro ao gerar ocorrencia com documento anexado!", "Error")
      });
    }else{
      this.loadingTitulosPrescritos = false;
    }

  });
  }


  downloadTemplateEsgotado() {
    this.pddService.downloadTemplateEsgotado(this.processoId).subscribe(
        data => {
            this.utilsService.renderDownload(data, "Esgotado.docx" as string);
        }
    );
  }

  downloadTemplateNaoLocalizado() {
    this.pddService.downloadTemplateNaoLocalizado(this.processoId).subscribe(
        data => {
            this.utilsService.renderDownload(data, "Não localizado.docx" as string);
        }
    );
  }

  downloadTemplateTitulosPrecritos() {
    this.pddService.downloadTemplateTitulosPrescritos(this.processoId).subscribe(
        data => {
            this.utilsService.renderDownload(data, "Titulos prescritos.docx" as string);
        }
    );
  }

  downloadTemplateEmpresaFechou() {
    this.pddService.downloadTemplateEmpresaFechou(this.processoId).subscribe(
        data => {
            this.utilsService.renderDownload(data, "Empresa fechou.docx" as string);
        }
    );
  }
  

}

