<ng-select 
    [items]="comarcas" 
    [loading]="loadComarcas"
    [multiple]="selecionaMultiplos"
    [(ngModel)]="comarcaId" 
    [ngModelOptions]="{standalone: true}"
    bindLabel="nome" 
    bindValue="id" 
    placeholder="Digite a comarca" 
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    clearAllText="Limpar seleção"
    (keyup)="filtrar($event)"
    (click)="changeComarca($event)"
    (clear)="limparComarca()"
    >
</ng-select>
