<div class="card-honorario">
    <div class="row">
        <div class="col-11">
            <h5 class="card-title">
                <i class="fas fa-file-invoice-dollar"></i> Despesas
            </h5>
        </div>                        
        <div class="col-1 d-flex justify-content-between align-items-center font-weight-bold pt-3">
            <div class="btn-adicionar" (click)="inserirHonorario = !inserirHonorario" *ngIf="tipo != '4'">
                <i class="fas fa-plus"></i>
            </div>            
            <div class="btn-adicionar" *ngIf="tipo == '4'" 
            routerLink="{{raiz}}/despesas/cadastrar/{{tipo}}/{{referenciaId}}/{{processoJudicialId}}">
                <i class="fas fa-plus"></i>                
            </div>
            <div class="btn-detalhes" *ngIf="tipo == '4'" routerLink="{{raiz}}/despesas/{{tipo}}/{{referenciaId}}">
                <i class="fas fa-folder-open"></i>
            </div>
        </div>   
    </div>    
    <div class="d-flex justify-content-center" *ngIf="load">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row" *ngIf="!recolhido">
        <div class="col-9">Faturado:</div>
        <div class="col-3 text-right">{{valorTotalFaturado}}</div>
    </div>
    <div class="row" *ngIf="!recolhido">
        <div class="col-9">Reembolsado:</div>
        <div class="col-3 text-right">{{valorTotalReembolsado}}</div>
    </div>
    <div class="row" *ngIf="!recolhido">
        <div class="col-9">Em aberto:</div>
        <div class="col-3 text-right">{{valorTotalAberto}}</div>
    </div>
    <hr *ngIf="!recolhido"/>
    <div class="row" *ngIf="!recolhido">
        <div class="col-9">Total de lançamentos:</div>
        <div class="col-3 text-right">{{valorTotal}}</div>
    </div>
</div>