<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">Operacionais /</span> Publicações</span>
    <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="activeTab == 1">
        <button type="button" class="btn btn-primary" [routerLink]="['importar']">
            <span class="fas fa-plus-circle"></span>&nbsp;&nbsp;Importar
        </button>
    </div>
</h4>

<div>
    <ul [(activeId)]="activeTab" ngbNav #productTabs="ngbNav" class="nav-tabs">
        <li ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink (click)="getPublicacoes()">Publicacões e Intimações

                <div style="margin-left: 10px" *ngIf="loadPublicacoesExternas"
                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </a>
            <ng-template ngbNavContent>

                <app-header-filter [data]="dataHeaderFilter" [barChartData]="barChartData"
                    [barChartLabels]="barChartLabels" [barChartOptions]="barChartOptions"
                    [barChartPlugins]="barChartPlugins" [barChartLegend]="barChartLegend" [barChartType]="barChartType">

                </app-header-filter>
                <!-- Filtro -->
                <div class="mt-2 mb-2">
                    <div class="form-row align-items-center mb-2">
                        <div class="col-md-6">
                            <label class="form-label">Filtro</label>
                            <input type="text" [(ngModel)]="publicacaoVMFilter.textoBusca" class="form-control">
                        </div>
                        <div class="col-6">
                            <label class="form-label">Tipo do Filtro</label>
                            <div class="mt-2">
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="numeroProcesso" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="numeroProcesso">
                                    <label class="form-check-label" for="numeroProcesso">Processo </label>
                                </div>
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="diario" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="diario">
                                    <label class="form-check-label" for="diario">Diário </label>
                                </div>
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="nomePesquisado" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="nomePesquisado">
                                    <label class="form-check-label" for="nomePesquisado">Nome Pesquisado </label>
                                </div>
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="nomePesquisado" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="descricao">
                                    <label class="form-check-label" for="nomePesquisado">Detalhes </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label class="form-label d-none d-md-block">&nbsp;</label>
                            <button type="button" class="btn btn-padrao btn-block" (click)="buscar()"><span
                                    class="fas fa-search"></span>&nbsp;&nbsp;Filtrar</button>
                        </div>
                        <div class="col-md-2">
                            <label class="form-label d-none d-md-block">&nbsp;</label>
                            <button type="button" class="btn background-color-white btn-block"
                                (click)="limparFiltros()"><span class="fas fa-search"></span>&nbsp;&nbsp;Limpar</button>
                        </div>
                    </div>
                </div>
                <!-- / Filtro -->
                <div class="row" *ngIf="publicacoes != undefined && possuiSelecionadas()">
                    <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-descartar"
                                (click)="descartarPublicacoesSelecionadas()">
                                Descartar selecionadas
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="mt-2 mb-2">
                        <div class="row" *ngIf="textoFiltro">
                            <div class="col-12"><label class="label-filtro">Status: {{textoFiltro}} <span
                                        class="filtro-remove" (click)="limparFiltros()"><i
                                            class="fas fa-window-close cursor-pointer"></i></span></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12  table-custom">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input type="checkbox"
                                            (click)="selecionado = !selecionado; marcarTodasPublicacoes(selecionado)">
                                    </th>
                                    <th></th>
                                    <th class="link" scope="col" (click)="setSort('dataDivulgacao')">DIVULGADO EM <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='dataDivulgacao'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('dataDivulgacao')">CADASTRADO EM <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='dataDivulgacao'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('numeroProcesso')">PROCESSO <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='numeroProcesso'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('diario')">TEOR + DETALHAMENTO <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='diario'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <!-- <th class="link" scope="col" (click)="setSort('nomePesquisado')">NOME PESQUISADO <i
                                        class="ion text-muted ml-2" *ngIf="sortBy=='nomePesquisado'"
                                        [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th> -->
                                    <th class="link" scope="col" (click)="setSort('status')">STATUS <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='status'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="text-nowrap text-center">AÇÕES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [ngStyle]="{'background-color': publicacao.possivelDuplicidade ? '#ffdede' : ''}" class="cursor-tabela" *ngFor="let publicacao of publicacoes; let i=index"
                                    title="{{publicacao.descricao}}">
                                    <td>
                                        <input type="checkbox" [(ngModel)]="publicacao.checked">
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">{{i + 1}}</td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        <p>{{publicacao.dataDivulgacao | date: 'dd/MM/yyyy' }}</p>
                                        <p>Publicado em: {{publicacao.dataPublicacao | date: 'dd/MM/yyyy' }}</p>
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        <p>{{publicacao.dataCriacao | date: 'dd/MM/yyyy' }}</p>
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        {{publicacao.numeroProcesso}}
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        Teor: {{publicacao.teor}}
                                        <br />
                                        <br />
                                        Detalhamento: {{publicacao.detalhamento}}
                                    </td>
                                    <!-- <td (click)="abrirPublicacao(publicacao.id)">
                                    {{publicacao.nomePesquisado}}
                                </td> -->
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        <p>
                                            <span class="situacao badge badge-primary"
                                                *ngIf="publicacao.concluido">Concluído</span>
                                            <span class="badge"
                                                ngClass="{{publicacao.tipoSituacaoPublicacao == 1 ? publicacao.tipoSituacaoPublicacaoCor : 'badge-success'}}">{{publicacao.tipoSituacaoPublicacao
                                                == 1 ? publicacao.tipoSituacaoPublicacaoNome : 'Tratado'}}</span>
                                            <span *ngIf="publicacao.tipoSituacaoPublicacao == 3"
                                                class="badge badge-danger">Descartado</span>
                                            <span class="badge badge-secondary"
                                                *ngIf="publicacao.possivelDuplicidade">Possível Duplicidade</span>
                                        </p>
                                    </td>
                                    <td class="text-center text-nowrap align-middle link">
                                        <button type="button" class="btn btn-xs icon-btn btn-danger ml-2"
                                            (click)="descartarPublicacaoPorId(publicacao.id)"
                                            ngbTooltip="Descartar publicação">
                                            <span class="fas fa-trash"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 carregar-mais" (click)="carregarMais()">
                        <i class="fas fa-arrow-circle-down"></i>Carregar mais
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="2">
            <a ngbNavLink (click)="getPublicacoes()"> Devolutivas Internas
                <div style="margin-left: 10px" *ngIf="loadPublicacoesInternas"
                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </a>

            <ng-template ngbNavContent>
                <app-header-filter [data]="dataHeaderFilter" [barChartData]="barChartData"
                    [barChartLabels]="barChartLabels" [barChartOptions]="barChartOptions"
                    [barChartPlugins]="barChartPlugins" [barChartLegend]="barChartLegend" [barChartType]="barChartType">

                </app-header-filter>
                <!-- Filtro -->
                <div class="mt-2 mb-2">
                    <div class="form-row align-items-center mb-2">
                        <div class="col-md-6">
                            <label class="form-label">Filtro</label>
                            <input type="text" [(ngModel)]="publicacaoVMFilter.textoBusca" class="form-control">
                        </div>
                        <div class="col-6">
                            <label class="form-label">Tipo do Filtro</label>
                            <div class="mt-2">
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="numeroProcesso" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="numeroProcesso">
                                    <label class="form-check-label" for="numeroProcesso">Processo </label>
                                </div>
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="diario" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="diario">
                                    <label class="form-check-label" for="diario">Diário </label>
                                </div>
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="nomePesquisado" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="nomePesquisado">
                                    <label class="form-check-label" for="nomePesquisado">Nome Pesquisado </label>
                                </div>
                                <div class=" form-check form-check-inline">
                                    <input class="form-check-input" id="nomePesquisado" type="radio"
                                        [(ngModel)]="publicacaoVMFilter.campoBusca" value="descricao">
                                    <label class="form-check-label" for="nomePesquisado">Detalhes </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label class="form-label d-none d-md-block">&nbsp;</label>
                            <button type="button" class="btn btn-padrao btn-block" (click)="buscar()"><span
                                    class="fas fa-search"></span>&nbsp;&nbsp;Filtrar</button>
                        </div>
                        <div class="col-md-2">
                            <label class="form-label d-none d-md-block">&nbsp;</label>
                            <button type="button" class="btn background-color-white btn-block"
                                (click)="limparFiltros()"><span class="fas fa-search"></span>&nbsp;&nbsp;Limpar</button>
                        </div>
                    </div>
                </div>
                <!-- / Filtro -->
                <div class="row" *ngIf="publicacoes != undefined && possuiSelecionadas()">
                    <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-descartar"
                                (click)="descartarPublicacoesSelecionadas()">
                                Descartar selecionadas
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="mt-2 mb-2">
                        <div class="row" *ngIf="textoFiltro">
                            <div class="col-12"><label class="label-filtro">Status: {{textoFiltro}} <span
                                        class="filtro-remove" (click)="limparFiltros()"><i
                                            class="fas fa-window-close cursor-pointer"></i></span></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12  table-custom">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input type="checkbox"
                                            (click)="selecionado = !selecionado; marcarTodasPublicacoes(selecionado)">
                                    </th>
                                    <th></th>
                                    <th class="link" scope="col" (click)="setSort('dataDivulgacao')">DIVULGADO EM <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='dataDivulgacao'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('dataDivulgacao')">CADASTRADO EM <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='dataDivulgacao'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('numeroProcesso')">PROCESSO <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='numeroProcesso'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('diario')">DIÁRIO <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='diario'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('nomePesquisado')">NOME PESQUISADO <i
                                        class="ion text-muted ml-2" *ngIf="sortBy=='nomePesquisado'"
                                        [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="link" scope="col" (click)="setSort('status')">STATUS <i
                                            class="ion text-muted ml-2" *ngIf="sortBy=='status'"
                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i></th>
                                    <th class="text-nowrap text-center">AÇÕES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [ngStyle]="{'background-color': publicacao.possivelDuplicidade ? '#ffdede' : ''}" class="cursor-tabela" *ngFor="let publicacao of publicacoes; let i=index"
                                    title="{{publicacao.descricao}}">
                                    <td>
                                        <input type="checkbox" [(ngModel)]="publicacao.checked">
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">{{i + 1}}</td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        <p>{{publicacao.dataDivulgacao | date: 'dd/MM/yyyy' }}</p>
                                        <p>Publicado em: {{publicacao.dataPublicacao | date: 'dd/MM/yyyy' }}</p>
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        <p>{{publicacao.dataCriacao | date: 'dd/MM/yyyy' }}</p>
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        {{publicacao.numeroProcesso}}
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        {{publicacao.diario}}
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        {{publicacao.nomePesquisado}}
                                    </td>
                                    <td (click)="abrirPublicacao(publicacao.id)">
                                        <p>
                                            <span class="situacao badge badge-primary"
                                                *ngIf="publicacao.concluido">Concluído</span>
                                            <span class="badge"
                                                ngClass="{{publicacao.tipoSituacaoPublicacao == 1 ? publicacao.tipoSituacaoPublicacaoCor : 'badge-success'}}">{{publicacao.tipoSituacaoPublicacao
                                                == 1 ? publicacao.tipoSituacaoPublicacaoNome : 'Tratado'}}</span>
                                            <span *ngIf="publicacao.tipoSituacaoPublicacao == 3"
                                                class="badge badge-danger">Descartado</span>
                                            <span class="badge badge-secondary"
                                                *ngIf="publicacao.possivelDuplicidade">Possível Duplicidade</span>
                                        </p>
                                    </td>
                                    <td class="text-center text-nowrap align-middle link">
                                        <button type="button" class="btn btn-xs icon-btn btn-danger ml-2"
                                            (click)="descartarPublicacaoPorId(publicacao.id)"
                                            ngbTooltip="Descartar publicação">
                                            <span class="fas fa-trash"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 carregar-mais" (click)="carregarMais()">
                        <i class="fas fa-arrow-circle-down"></i>Carregar mais
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
</div>
<div [ngbNavOutlet]="productTabs"></div>