<ng-select 
    [items]="situacoes" 
    [loading]="loadSituacoes"
    [multiple]="selecionaMultiplos"
    [(ngModel)]="situacaoId" 
    [ngModelOptions]="{standalone: true}"
    bindLabel="nome"
    bindValue="id"
    placeholder="Digite a Situação" 
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    clearAllText="Limpar seleção"
    (keyup)="filtrar($event)"
    (click)="changeSituacao($event)"
    (clear)="limparSituacao()"
    >
</ng-select>
