import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ModalOcorrenciaComponent } from 'src/app/views/operacionais/processos-judiciais/processos-judiciais-detalhes/modal-ocorrencia/modal-ocorrencia.component';
import { Observable, Subject } from 'rxjs';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { ProcessosJudiciaisOcorrenciaEventoService } from 'src/app/services/processos-judiciais-ocorrencia-evento.service';
import { ProcessosJudiciaisOcorrenciaTarefaService } from 'src/app/services/processos-judiciais-ocorrencia-tarefa.service';
import { ProcessosJudiciaisOcorrenciaPrazoService } from 'src/app/services/processos-judiciais-ocorrencia-prazo.service';
import { ProcessosJudiciaisOcorrenciaAudienciaService } from 'src/app/services/processos-judiciais-ocorrencia-audiencia.service';
import { ProcessosJudiciaisHistoricoManualService } from 'src/app/services/processos-judiciais-historico-manual.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-area-trabalho-revisor-dashboard',
    templateUrl: './area-trabalho-revisor.component.html',
    styleUrls: ['./area-trabalho-revisor.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class AreaTrabalhoRevisorDashboardComponent implements OnInit {


    @Output() changeRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
    public resultConfirmDialog$ = new Subject<ResultadoModal>();

    constructor(
        private processosJudiciaisOcorrenciasService: ProcessosJudiciaisOcorrenciasService,
        private processosJudiciaisService: ProcessosJudiciaisService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private modalService: ModalService,
        private ngbModal: NgbModal,
        private processosJudiciaisOcorrenciaTarefaService: ProcessosJudiciaisOcorrenciaTarefaService,
        private processosJudiciaisOcorrenciaEventoService: ProcessosJudiciaisOcorrenciaEventoService,
        private processosJudiciaisOcorrenciaPrazoService: ProcessosJudiciaisOcorrenciaPrazoService,
        private processosJudiciaisOcorrenciaAudienciaService: ProcessosJudiciaisOcorrenciaAudienciaService,
        private processosJudiciaisHistoricoManualService: ProcessosJudiciaisHistoricoManualService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private router: Router,
    ) { }

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    public tarefas: any;    
    public tarefasExibir: any;
    public tarefasOriginal: any;
    public loadAtividadesDoProcesso: boolean = false;
    public quantidadeAtividadesNaoConcluida: number = 0;
    public quantidadeAtividadesConcluida: number = 0;
    public quantidadeAtividadesEmAtraso: number = 0;
    public quantidadeTarefas: number = 0;
    public quantidadePrazo: number = 0;
    public quantidadeEvento: number = 0;
    public quantidadeAudiencia: number = 0;
    public quantidadeHistorico: number = 0;
    public barChartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: 'rgb(255, 99, 132)'
                }
            }
        }
    };
    public barChartLabels: Label[] = ['Tarefa', 'Evento', 'Prazo', 'Audiência', 'Historico Manuais'];
    public barChartType: ChartType = 'horizontalBar';
    public barChartLegend = false;
    public barChartPlugins = [];
    public barChartData: ChartDataSets[] = [
        {
            data: [this.quantidadeTarefas, this.quantidadeEvento, this.quantidadePrazo, this.quantidadeAudiencia
                , this.quantidadeHistorico],
        }
    ];
    ngOnInit(): void {
        this.buscarTarefaDoUsuario();
    }

    goToGridView() {
        sessionStorage.setItem('openMenu', JSON.stringify(false));
        this.router.navigate(['/dashboard/atividades-usuario']);
    }

    buscarTarefaDoUsuario() {
        this.spinner.show();
        this.toastrService.clear();
        this.loadAtividadesDoProcesso = true;
        this.processosJudiciaisService.getTarefasDoUsuario('revisor')
            //this.processosJudiciaisOcorrenciaTarefaService.getTarefasDoUsuario()
            .subscribe(res => {
                this.tarefas = res.data;
                
                this.tarefas.forEach(c => {
                    if (c.tipoOcorrencia == 1) {
                        c.dataOcorrencia = c.dataTarefa;
                        c.tituloDescricao = c.descricao;
                        c.exibiPrincipal = !c.emRevisao;

                    } else if (c.tipoOcorrencia == 3 || c.tipoOcorrencia == 5) {
                        c.dataOcorrencia = c.dataHoraInicial;
                        c.tituloDescricao = c.titulo;
                        
                        if(c.tipoOcorrencia == 3){
                            c.exibiPrincipal = !c.emRevisao;
                        }else{
                            c.exibiPrincipal = true;
                        }

                    } else if (c.tipoOcorrencia == 2) {
                        c.dataOcorrencia = c.dataHistorico;
                        c.tituloDescricao = c.descricao;
                        c.exibiPrincipal = true;
                    } else {
                        c.dataOcorrencia = c.dataLimite;
                        c.tituloDescricao = c.titulo;

                        if (c.tipoOcorrencia == 4) {
                            c.exibiPrincipal = !c.emRevisao;
                            c.quantidadeTarefasEventosVinculados = c.quantidadeTarefasEventosVinculados;
                        }else{
                            c.exibiPrincipal = true;
                        }
                    }
                    
                    c.editarDetalhes = false;
                    
                    if (c.responsavelNome && c.responsavelNome.length > 17)
                        c.responsavelNome = c.responsavelNome.substring(0, 14) + '...';
                    
                });

                this.tarefasOriginal = this.tarefas;                                
                this.tarefasExibir = this.tarefas;

                this.quantidadeAtividadesNaoConcluida = this.tarefas.filter(x => !x.concluido && !x.emAtraso).length;
                this.quantidadeAtividadesConcluida = this.tarefas.filter(x => x.concluido).length;
                this.quantidadeAtividadesEmAtraso = this.tarefas.filter(x => !x.concluido && x.emAtraso).length;

                this.quantidadeTarefas = this.tarefas.filter(x => x.tipoOcorrencia == 1).length;
                //console.log('this.quantidadeTarefas',this.quantidadeTarefas)
                this.quantidadeHistorico = this.tarefas.filter(x => x.tipoOcorrencia == 2).length;
                this.quantidadeEvento = this.tarefas.filter(x => x.tipoOcorrencia == 3).length;
                this.quantidadePrazo = this.tarefas.filter(x => x.tipoOcorrencia == 4).length;
                this.quantidadeAudiencia = this.tarefas.filter(x => x.tipoOcorrencia == 5).length;


                this.barChartData = [
                    {
                        data: [this.quantidadeTarefas, this.quantidadeEvento, this.quantidadePrazo, this.quantidadeAudiencia
                            , this.quantidadeHistorico],
                    }
                ];

                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível carregar os dados!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
        this.loadAtividadesDoProcesso = false;
    }

    filtrarTarefa() {
        this.tarefasExibir = this.tarefasOriginal.filter(c => c.tipoOcorrencia == 1);
    }

    filtrarHistorico() {
        this.tarefasExibir = this.tarefasOriginal.filter(c => c.tipoOcorrencia == 2);
    }

    filtrarEvento() {
        this.tarefasExibir = this.tarefasOriginal.filter(c => c.tipoOcorrencia == 3);
    }

    filtrarPrazo() {
        this.tarefasExibir = this.tarefasOriginal.filter(c => c.tipoOcorrencia == 4);
    }

    filtrarAudiencia() {
        this.tarefasExibir = this.tarefasOriginal.filter(c => c.tipoOcorrencia == 5);
    }

    detalhesExibirOcultar(atividade: any) {
        console.log(atividade);
        atividade.editarDetalhes = !atividade.editarDetalhes;
    }

    clickModalTarefa(tarefa: any) {
        this.modalService.abrirModalTarefaRevisor(tarefa);
    }

    onChangeQuantidade(atividade: any, ocultar: boolean, quantidadeComentarios: number) {
        console.log(quantidadeComentarios);
        atividade.editarDetalhes = !ocultar;
        atividade.quantidadeComentarios = quantidadeComentarios;
    }

    abrirAtividade(atividade: any) {
        this.spinner.show();
        console.log(atividade);
        this.processosJudiciaisOcorrenciasService.getById(atividade.processoJudicialOcorrenciaId)
            .subscribe(rs => {
                let ocorrencia = rs.data;
                this.processosJudiciaisOcorrenciasService.obterOcorrenciaComAtividade(ocorrencia.id, atividade.tipoOcorrencia, atividade.id)
                    .subscribe(oc => {
                        
                        if (oc.success) {
                            this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(oc.data.processoJudicialRecursoDesdobramentoId)
                                .subscribe(res => {
                                    if(res.success){
                                        
                                        const modalRef = this.ngbModal.open(ModalOcorrenciaComponent, {
                                            size: 'lg',
                                            backdrop: 'static',
                                            keyboard: false
                                        });

                                        let recursoDesdobramento = res.data;

                                        modalRef.componentInstance.modalRef = modalRef;
                                        modalRef.componentInstance.page = 'processo-judiciais-detalhes';
                                        modalRef.componentInstance.recursoDesdobramento = recursoDesdobramento;
                                        modalRef.componentInstance.editarAtividade(ocorrencia.id, oc.data.detalhes[0]);
                                        
                                        modalRef.result.then(result => {
                                            this.resultConfirmDialog$.next(result);
                                            this.buscarTarefaDoUsuario();
                                            this.changeRefresh.emit(true);
                                        }).catch(error => { });
                                    }
                                })                            
                        }
                    })

            })
    }

    concluirRevisao(atividade:any){
        let request: Observable<any>;

        this.swalWithBootstrapButtons.fire({
            text: 'Deseja concluir essa atividade?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.value != undefined && result.value == true){
                this.spinner.show();
                switch (atividade.tipoOcorrencia) {
                    case 1:
                        let dataTarefa = {
                            tarefaId: atividade.id,
                            concluido: true
                        }
                        request = this.processosJudiciaisOcorrenciaTarefaService.concluirOuRemoverConcluirDaTarefa(dataTarefa);
                        break;                    
                    case 3:
                        let dataEvento = {
                            eventoId: atividade.id,
                            concluido: true
                        }
                        request = this.processosJudiciaisOcorrenciaEventoService.concluirOuRemoverConcluirDaTarefa(dataEvento);
                        break;
                    case 4:
                        let dataPrazo = {
                            prazoId: atividade.id,
                            concluido: true,
                            justificativa: 'Prazo concluído pelo revisor via painel'
                        }
                        request = this.processosJudiciaisOcorrenciaPrazoService.concluirOuRemoverConcluirDoPrazo(dataPrazo);
                        break;                                    
                }                        

                request.subscribe(res=>{
                    this.buscarTarefaDoUsuario();    
                    this.swalWithBootstrapButtons.fire(
                        'Concluído com sucesso!',
                        '',
                        'success'
                    );            
                },error=>{                
                    this.spinner.hide();
                    this.swalWithBootstrapButtons.fire(
                        'Não foi possível concluir!',
                        '',
                        'error'
                    );
                })
            }
        });
    }

    devolverAtividade(item: any){
        this.spinner.show();
        
        if (item.tipoOcorrencia == 1) {
            this.processosJudiciaisOcorrenciaTarefaService.devolverParaAjuste(item.id)
            .subscribe(res=>{
                this.buscarTarefaDoUsuario();
            },error=>{                
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível devolver!',
                    '',
                    'error'
                );
            });
        }else{
           this.processosJudiciaisOcorrenciaPrazoService.devolverParaAjuste(item.id)
           .subscribe(res=>{
                this.buscarTarefaDoUsuario();
            },error=>{                
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível devolver!',
                    '',
                    'error'
                );
            });
        }
    }

    revisadoAtividade(item){
        this.spinner.show();
        
        if (item.tipoOcorrencia == 1) {
            this.processosJudiciaisOcorrenciaTarefaService.revisadoELiberado(item.id)
            .subscribe(res=>{
                this.buscarTarefaDoUsuario();
            },error=>{                
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível mudar o status!',
                    '',
                    'error'
                );
            });
        }else{
           this.processosJudiciaisOcorrenciaPrazoService.revisadoELiberado(item.id)
           .subscribe(res=>{
                this.buscarTarefaDoUsuario();
            },error=>{                
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível mudar o status!',
                    '',
                    'error'
                );
            });
        }
    }

    encerrarRevisao(item: any){
        this.spinner.show();
        
        if (item.tipoOcorrencia == 1) {
            this.processosJudiciaisOcorrenciaTarefaService.encerrarAtividade(item.id)
            .subscribe(res=>{
                this.buscarTarefaDoUsuario();
            },error=>{                
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível mudar o status!',
                    '',
                    'error'
                );
            });
        }else{
           this.processosJudiciaisOcorrenciaPrazoService.encerrarAtividade(item.id)
           .subscribe(res=>{
                this.buscarTarefaDoUsuario();
            },error=>{                
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível mudar o status!',
                    '',
                    'error'
                );
            });
        }
    }

    excluirAtiviade(atividade: any) {
        let request: Observable<any>;

        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir essa atividade?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.value != undefined && result.value == true){
                this.spinner.show();
                switch (atividade.tipoOcorrencia) {
                    case 1:
                        request = this.processosJudiciaisOcorrenciaTarefaService.delete(atividade.id);
                        break;
                    case 2:
                        request = this.processosJudiciaisHistoricoManualService.delete(atividade.id);
                        break;
                    case 3:
                        request = this.processosJudiciaisOcorrenciaEventoService.delete(atividade.id);
                        break;
                    case 4:
                        request = this.processosJudiciaisOcorrenciaPrazoService.delete(atividade.id);
                        break;
                    case 5:
                        request = this.processosJudiciaisOcorrenciaAudienciaService.delete(atividade.id);
                        break;                
                }                        

                request.subscribe(res=>{
                    this.buscarTarefaDoUsuario();    
                    this.swalWithBootstrapButtons.fire(
                        'Removido com sucesso!',
                        '',
                        'success'
                    );            
                },error=>{                
                    this.spinner.hide();
                    this.swalWithBootstrapButtons.fire(
                        'Não foi possível remover!',
                        '',
                        'error'
                    );
                })
            }
        });
    }

    // tarefasEmRevisao(){
    //     this.tarefasExibir = this.tarefasRevisao;
    // }

    // filtrarTarefaRevisao(){
    //     this.tarefasExibir = this.tarefasRevisao.filter(c => c.tipoOcorrencia == 1);
    // }    
    
    // filtrarEventoRevisao(){
    //     this.tarefasExibir = this.tarefasRevisao.filter(c => c.tipoOcorrencia == 3);
    // }

    // filtrarPrazoRevisao(){
    //     this.tarefasExibir = this.tarefasRevisao.filter(c => c.tipoOcorrencia == 4);
    // }
}
