import { AtividadeTarefaRequest } from './atividade-tarefa-request';
import { Guid } from "guid-typescript";
import { Prioridade } from "src/app/enums/prioridade.enum";
import { OcorrenciaTag } from "../ocorrencia/ocorrencia-tag";
import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { OcorrenciaArquivo } from '../ocorrencia/ocorrencia-arquivo';

export class AtividadeRequest {
    prioridade: Prioridade;
    planejado: Date;
    finalizado: Date;
    nome: String;
    processoId: Guid;
    ocorrenciaId: Guid; // PK
    situacaoId: Guid;
    situacaoPaiId: Guid;
    ocorrenciaTags: OcorrenciaTag[];
    atividadeTarefas: AtividadeTarefaRequest[];
    status: TipoStatus;
    ocorrenciaArquivos: OcorrenciaArquivo[];

    constructor() {
        this.atividadeTarefas = [];
    }
}
