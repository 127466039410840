import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfigFormFilter, FiltersApplied } from "./filter";
import { CurrencyPipe } from "@angular/common";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-advanced-search-filter",
  templateUrl: "./advanced-search-filter.component.html",
  styleUrls: ["./advanced-search-filter.component.scss"],
})
export class AdvancedSearchFilterComponent implements OnInit {
  @Input() hasPrintAndExport: boolean = true;
  @Input() hasUpdate: boolean = false;

  @Input() title: string;
  @Input() serachPlaceholder: string;

  @Input() col: number = 11;
  @Input() config: ConfigFormFilter[] = [];
  @Input() formFilter: FormGroup;
  @Input() context: any;

  @Output() apply = new EventEmitter();

  public filtroOn: boolean = false;
  public filtersApplied: FiltersApplied[] = [];

  constructor(public currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {}

  applyFormFilter() {
    const formValue = this.context.formFilter.value;
    Object.values(this.context.FORM_FILTER).forEach((field: string, i) => {
      if (!!formValue[field]) {
        const itemsSelect = this.config[i]?.itemsSelect;
        const value = !!itemsSelect ? itemsSelect.find(item=>item.id === formValue[field])?.nome : formValue[field];

        this.selectFormFilter(field, this.config[i].label, value);
      }
      this.filtroOn = false;
    });
  }

  selectFormFilter(id: number | string, label: string, value?: any) {
    const alreadyExist = this.filtersApplied.findIndex((f) => f.id === id);
    const isCurrency = !!label.includes("Valor");

    const data = {
      id,
      label,
      value: isCurrency ? this.currencyPipe.transform(value, "BRL") : value,
    };

    if (alreadyExist > -1) this.filtersApplied[alreadyExist] = data;
    else this.filtersApplied.push(data);
  }

  selectSomeFilter(id: number | string, label: string, array: Array<any>) {
    const alreadyExist = this.filtersApplied.find((f) => f.id === id);
    if (alreadyExist) return;

    const { name: valueFound } = array.find((c) => c.objectId === id);

    this.filtersApplied.push({ id, label, value: valueFound });
  }

  removeFilterApplied(id: string | number) {
    this.filtersApplied = this.filtersApplied.filter((f) => f.id !== id);
  }
}
