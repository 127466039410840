import { Guid } from "guid-typescript";

export class OcorrenciaTag {
    tagId: string;
    nome: string;
    cor: string;

    constructor(tagId: string) {
        this.tagId = tagId;
    }
}
