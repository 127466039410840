<div class="modal-class" [ngClass]="prazoId != null ? 'prazo' : ''">
    <!-- Modal template -->
    <div class="modal-header">

        <h5 class="modal-title">
            Ocorrência
            <br /><br />
            <i class="fas fa-landmark"></i>
            {{recursoDesdobramento.instanciaNome}}{{recursoDesdobramento.tipoOcorrenciaNome != undefined ? " - "+recursoDesdobramento.tipoOcorrenciaNome : ""}}
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col">
                <label class="form-label">Descrição<span class="required-field">*</span></label>
                <textarea class="form-control" placeholder="Digite a descrição" rows="2"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="ocorrencia.descricao"
                    [disabled]="editarAtividadeOcorrencia"></textarea>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6 col-sm-12">
                <label class="form-label">Tipo<span class="required-field">*</span></label>
                <ng-select [items]="tipoOcorrencia" [(ngModel)]="ocorrencia.tipoProcessoJudicialOcorrenciaId"
                    [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" [loading]="loadTipoOcorrencia"
                    [disabled]="editarAtividadeOcorrencia"></ng-select>
            </div>
            <div class="col-md-2 col-sm-12">
                <label class="form-label">Data<span class="required-field">*</span></label>
                <div class="input-group">
                    <input (click)="dataOcorrenciaComponent.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                        name="dataOcorrenciaComponent" ngbDatepicker [(ngModel)]="ocorrencia.dataOcorrenciaComponent"
                        placement="bottom-right" #dataOcorrenciaComponent="ngbDatepicker"
                        [disabled]="editarAtividadeOcorrencia">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="dataOcorrenciaComponent.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-2 col-sm-6">
                <label class="form-label">Exibir no Timeline<span class="required-field">*</span></label>
                <ng-select [items]="tipoTimeLine" [(ngModel)]="ocorrencia.exibirTimeLine"
                    [ngModelOptions]="{standalone: true}" bindLabel="display" bindValue="value"
                    [disabled]="editarAtividadeOcorrencia"></ng-select>
            </div>
            <div class="form-group col-md-2 col-sm-6">
                <label class="form-label">Exibir para o cliente<span class="required-field">*</span></label>
                <ng-select [items]="tipoTimeLine" [(ngModel)]="ocorrencia.exibirParaCliente"
                    [ngModelOptions]="{standalone: true}" bindLabel="display" bindValue="value"
                    [disabled]="editarAtividadeOcorrencia"></ng-select>
            </div>
        </div>
        <hr />
        <div>
            <label class="form-label w-100 d-flex align-items-center">
                <span>Notificar cliente</span>
                <mat-slide-toggle class="ml-2" [(ngModel)]="notificarCliente" (change)="toggleNotificarCliente($event)">
                </mat-slide-toggle>
            </label>
        </div>
        <div class="table-responsive" *ngIf="contatos.length > 0">
            <table class="table table-striped table-bordered card-table">
                <thead>
                    <tr>
                        <th class="text-nowrap">
                            <input type="checkbox" class="cursor-pointer-padrao" [checked]="selecionouTodosContatos" (change)="selecionarTodosContatos($event.target.checked)">
                        </th>
                        <th class="text-nowrap cursor-pointer">
                            Nome
                        </th>                        
                        <th class="text-center text-nowrap align-middle">E-mail</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let contato of contatos">
                        <td>
                            <input type="checkbox" class="cursor-pointer-padrao" [(ngModel)]="contato.selecionado">
                        </td>
                        <td class="align-middle">{{ contato.nome }}</td>
                        <td class="align-middle">{{ contato.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr />
        <div class="row caixa prazo" *ngFor="let prazo of ocorrencia.prazos; let i=index">
            <div class="col-12">
                <prazo-cadastro [prazo]="prazo" [index]="i+1"
                    [recursosDesdobramentoId]="recursoDesdobramento.id"></prazo-cadastro>
            </div>
            <div class="col-12" *ngIf="!editarAtividadeOcorrencia">
                <br />
                <div class="row">
                    <div class="col-12 text-right">
                        <div class="btn btn-primary" (click)="handleAdicionarPrazo()">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="btn btn-danger" (click)="handleExcluirPrazo(prazo)">
                            <i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row caixa tarefa" *ngFor="let tarefaManual of ocorrencia.tarefas; let i=index">
            <div class="col-12">
                <tarefa-cadastro [tarefa]="tarefaManual" [index]="i+1"
                    [recursosDesdobramentoId]="recursoDesdobramento.id"></tarefa-cadastro>
            </div>
            <div class="col-12" *ngIf="!editarAtividadeOcorrencia">
                <br />
                <div class="row">
                    <div class="col-12 text-right">
                        <div class="btn btn-primary" (click)="handleAdicionarTarefa()">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="btn btn-danger" (click)="handleExcluirTarefa(tarefaManual)">
                            <i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row caixa evento" *ngFor="let evento of ocorrencia.eventos; let i=index">
            <div class="col-12">
                <evento-cadastro [evento]="evento" [index]="i+1"></evento-cadastro>
            </div>
            <div class="col-12">
                <br />
                <div class="row" *ngIf="!editarAtividadeOcorrencia">
                    <div class="col-12 text-right">
                        <div class="btn btn-primary" (click)="handleAdicionarEvento()">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="btn btn-danger" (click)="handleExcluirEvento(evento)">
                            <i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row caixa historico" *ngFor="let historicoManual of ocorrencia.historicosManuais; let i=index">
            <div class="col-12">
                <historico-cadastro [historicoManual]="historicoManual" [index]="i+1"></historico-cadastro>
            </div>
            <div class="col-12" *ngIf="!editarAtividadeOcorrencia">
                <br />
                <div class="row">
                    <div class="col-12 text-right">
                        <div class="btn btn-primary" (click)="handleAdicionarHistoricoManual()">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="btn btn-danger" (click)="handleExcluirHistoricoManual(historicoManual)">
                            <i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row caixa audiencia" *ngFor="let audiencia of ocorrencia.audiencias; let i=index">
            <div class="col-12">
                <audiencia-cadastro [audiencia]="audiencia" [index]="i+1"></audiencia-cadastro>
            </div>
            <div class="col-12" *ngIf="!editarAtividadeOcorrencia">
                <br />
                <div class="row">
                    <div class="col-12 text-right">
                        <div class="btn btn-primary" (click)="handleAdicionarAudiencia()">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="btn btn-danger" (click)="handleExcluirAudiencia(audiencia)">
                            <i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row caixa formulario" *ngFor="let formulario of ocorrencia.formularios; let i=index">
            <div class="col-12">
                <formulario-cadastro [formulario]="formulario" [index]="i+1"
                    [recursosDesdobramentoId]="recursoDesdobramento.id"></formulario-cadastro>
            </div>
            <div class="col-12" *ngIf="!editarAtividadeOcorrencia">
                <br />
                <div class="row">
                    <div class="col-12 text-right">
                        <div class="btn btn-primary" (click)="handleAdicionarFormulario()">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="btn btn-danger" (click)="handleExcluirFormulario(formulario)">
                            <i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br /><br />
        <div class="btn-group" *ngIf="!editarAtividadeOcorrencia">
            <div ngbDropdown>
                <button type="button" class="btn btn-primary dropdown-toggle" id="dropDownMenuOcorrencias"
                    ngbDropdownToggle>
                    Adicionar Atividade
                </button>
                <div ngbDropdownMenu aria-labelledby="dropDownMenuOcorrencias">
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarPrazo()">Prazo</a>
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarTarefa()">Tarefa</a>
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarEvento()">Evento</a>
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarHistoricoManual()">Histórico Manual</a>
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarAudiencia()">Audiência/Julgamento</a>
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarFormulario()">Formulário</a>
                    <!-- <a class="sub-menu" ngbDropdownItem>Sessão de Julgamento</a> -->
                </div>
            </div>
        </div>
        <div class="btn-group" *ngIf="prazoId != null">
            <div ngbDropdown>
                <button type="button" class="btn btn-primary dropdown-toggle" id="dropDownMenuOcorrencias"
                    ngbDropdownToggle>
                    Tipo de Ocorrência
                </button>
                <div ngbDropdownMenu aria-labelledby="dropDownMenuOcorrencias">
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarTarefa()">Tarefa</a>
                    <a class="sub-menu" ngbDropdownItem (click)="handleAdicionarEvento()">Evento</a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="salvar()"><i class="fas fa-save"></i> Salvar</button>
    </div>
</div>