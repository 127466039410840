import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { HomeAdministrativoComponent } from './home-administrativo/home-administrativo.component';
import { HomeAmbienteComponent } from './home-ambiente/home-ambiente.component';


// *******************************************************************************

//

@NgModule({
  imports: [RouterModule.forChild([
    { path: 'administrativo', component: HomeAdministrativoComponent },
    { path: 'ambiente', component: HomeAmbienteComponent },
  ])],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
