<ng-select 
    [items]="pessoas" 
    [(ngModel)]="pessoaId" 
    [ngModelOptions]="{standalone: true}"
    (keyup)="filtrar($event)"
    bindLabel="nome" 
    bindValue="id" 
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    placeholder="Digite o nome ou CPF/CNPJ" 
    (click)="changePessoa($event)"
    [loading]="loadPessoas"
    [multiple]="selecionaMultiplos"
    (clear)="limparPessoa()">
    <ng-template ng-option-tmp let-item="item">
        <div class="line"><b>{{item.nome}}</b></div>
        <div class="line mb-1"><b>CPF/CNPJ</b>: {{item.cpfCnpj}}</div>                
    </ng-template>
</ng-select>
