export enum ParametroChaveType {
    SITUACAO_ACORDO_PAI,
        SITUACAO_ACORDO_FILHO_APROVADO,
        SITUACAO_ACORDO_FILHO_NEGADO,
        SITUACAO_ACORDO_FILHO_ANALISE,
        SITUACAO_ACORDO_FILHO_DESCUMPRIMENTO_ANALISE,
        SITUACAO_ACORDO_FILHO_DESCUMPRIMENTO_APROVADO,
        SITUACAO_ACORDO_FILHO_DESCUMPRIMENTO_NEGADO,
        SITUACAO_ACORDO_DIVIDA,

        SITUACAO_DIVIDA_PAGAMENTO_NOVO,
        SITUACAO_DIVIDA_PAGAMENTO_FINALIZADO,
        SITUACAO_DIVIDA_PAGAMENTO_PENDENTE_APROVACAO,
        SITUACAO_DIVIDA_PAGAMENTO_PENDENTE,


        SITUACAO_DIVIDA_DESCONTO_FINALIZADO,
        SITUACAO_DIVIDA_DESCONTO_PENDENTE_APROVACAO,
        SITUACAO_DIVIDA_DESCONTO_PENDENTE,

        SITUACAO_DIVIDA_TAXA_ADMINISTRATIVA_NOVO,
        SITUACAO_DIVIDA_TAXA_ADMINISTRATIVA_FINALIZADO,
        SITUACAO_DIVIDA_TAXA_ADMINISTRATIVA_PENDENTE_APROVACAO,
        SITUACAO_DIVIDA_TAXA_ADMINISTRATIVA_PENDENTE,

        SITUACAO_DIVIDA_OCORRENCIA_PAGAMENTO,
        SITUACAO_DIVIDA_OCORRENCIA_PAGAMENTO_PAI,

        SITUACAO_DIVIDA_OCORRENCIA_DESCONTO,
        SITUACAO_DIVIDA_OCORRENCIA_DESCONTO_PAI,

        SITUACAO_DIVIDA_OCORRENCIA_TAXA_ADMINISTRATIVA,
        SITUACAO_DIVIDA_OCORRENCIA_TAXA_ADMINISTRATIVA_PAI,

        GERAL_URL_SITE,

        FILE_UPLOAD_MAX_FILE_SIZE_BYTE
}
