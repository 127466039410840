import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { TipoAlertaService } from 'src/app/services/tipo-alerta.service';

@Component({
    selector: 'evento-cadastro',
    templateUrl: './evento-cadastro.component.html',
    styleUrls: ['./evento-cadastro.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class EventoCadastroComponent implements OnInit {

    @Input() evento:any;
    @Input() index:any;
    
    
    public tipoAlerta: any = [];    
    public loadTipoAlerta: boolean = false;

    constructor(
        private datePipe: DatePipe,
        private tipoAlertaService: TipoAlertaService,        
    ) { }

    ngOnInit(): void {
        this.buscarTipoAlertas();
    }        

    buscarTipoAlertas() {
        this.loadTipoAlerta = true;
        this.tipoAlertaService.getAllAsync().then(x => {
            this.loadTipoAlerta = false;
            this.tipoAlerta = x.data;            
        });
    }

    adicionarEnvolvidoEvento(evento: any){
        evento.envolvidos.push({});
    }

    removerEnvolvidoEvento(evento: any, envolvidoIndex) {
            evento.envolvidos.forEach((element,index)=>{
            if(index == envolvidoIndex) {
                evento.envolvidos.splice(index,1);
            }
        });
    }

    handleAdicionarEmailsEvento(evento: any) {
        evento.adicionarEmails = true;
        evento.emailsObject = [];
        if (evento.emails.length == 0) {
            evento.emailsObject.push({ endereco: '' });
        } else {
            evento.emails.forEach(element => {
                evento.emailsObject.push({ endereco: element });
            });
        }
    }

    handleCancelarAdicionarEmails(evento: any) {
        evento.adicionarEmails = false;
    }

    handleSalvarEmails(evento: any) {
        evento.emails = [];

        evento.emailsObject.forEach(element => {
            evento.emails.push(element.endereco);
        });

        evento.adicionarEmails = false;
    }

    handleAdicionarNovoEmail(evento: any) {
        evento.emailsObject.push({ endereco: '' });
    }

    handleRemoverEmailLista(evento: any, i: number) {

        let lista = [];

        for (let x = 0; x < evento.emailsObject.length; x++) {
            if (x != i) {
                lista.push(evento.emailsObject[x]);
            }
        }

        evento.emailsObject = lista;
    }
}
