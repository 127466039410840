<div class="mt-3" *ngIf="honorarios != undefined && honorarios.length > 0" >        
    <div class="mt-2 col-12">
        <hr />                        
        Detalhes:
    </div> 
    <div class="table-responsive" *ngIf="honorarios != undefined && honorarios.length > 0">
        <table class="table table-striped table-bordered card-table">
            <thead>
                <th class="text-nowrap">
                    Código
                </th>
                <th class="text-nowrap">
                    Faturado
                </th>
                <th class="text-nowrap">
                    Data Vencimento
                </th>
                <th class="text-nowrap text-right">
                    Valor Honorário
                </th>
                <th class="text-nowrap text-right" *ngIf="tipoHonorario == 3">
                    Valor Recuperado
                </th>
                <th class="text-nowrap" *ngIf="tipoHonorario == 4">
                    Duração
                </th>
                <th class="text-nowrap text-right" *ngIf="tipoHonorario == 4">
                    Valor Hora
                </th>
                <th class="text-nowrap text-right" *ngIf="tipoHonorario == 3">
                    Valor Recuperado %
                </th>
                <th class="text-nowrap">
                    Ações
                </th>
            </thead>
            <tbody>
                <ng-container *ngFor="let honorario of honorarios">
                    <tr>
                        <td class="text-center">
                            {{honorario.idExterno}}
                        </td>
                        <td class="text-center">
                            {{honorario.faturado ? 'Sim':'Não'}}
                        </td>
                        <td class="text-center">
                            {{honorario.vencimento | date:"dd/MM/yy"}}
                        </td>
                        <td class="text-right">
                            {{honorario.valorHonorario | currency : "BRL"}}
                        </td>
                        <td class="text-right" *ngIf="tipoHonorario == 3">
                            {{honorario.valorRecuperado | currency : "BRL"}}
                        </td>
                        <td class="text-center" *ngIf="tipoHonorario == 4">
                            {{honorario.duracao}}
                        </td>
                        <td class="text-right" *ngIf="tipoHonorario == 4">
                            {{honorario.valorHoraInformado | currency : "BRL" }}
                        </td>
                        <td class="text-right" *ngIf="tipoHonorario == 3">
                            {{honorario.percentualHonorario | number }}%
                        </td>
                        <td class="text-center">
                            <a class="btn btn-xs icon-btn btn-primary font-white" (click)="exibirDetalhes(honorario, true)">
                                <span class="fas fa-eye"></span>
                            </a>
                            <a class="btn btn-xs icon-btn btn-secondary font-white"  (click)="exibirDownloads(honorario, true)"
                                *ngIf="honorario.arquivos != undefined && honorario.arquivos.length">
                                <span class="fas fa-paperclip"></span>
                            </a>
                            <button class="btn btn-xs icon-btn btn-warning" type="button" (click)="editar(honorario)">
                                <span class="far fa-edit"></span>
                            </button>
                            <button class="btn btn-xs icon-btn btn-danger" type="button" (click)="remover(honorario)">
                                <span class="far fa-trash-alt"></span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="honorario.exibirDetalhes">
                        <td [attr.colspan]="colspan">
                            <div class="row">
                                <div class="col-12">
                                    Descrição:
                                    <hr />
                                </div>
                                <div class="col-12">
                                    {{honorario.descricao}}
                                </div>
                                <div class="col-12 text-right mt-1">
                                    <a class="btn btn-primary font-white" (click)="exibirDetalhes(honorario, false)">
                                        Fechar
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="honorario.downloadAnexo">
                        <td [attr.colspan]="colspan">
                            <div class="row">
                                <div class="col-12">
                                    Anexo(s):
                                    <hr />
                                </div>
                                <div class="col-12">                                    
                                    <span *ngFor="let arquivo of honorario.arquivos">                        
                                        <div class="btn btn-primary" [ngbTooltip]="arquivo.nomeArquivo"
                                            (click)="handleDownloadArquivo(arquivo)">
                                            {{arquivo.arquivoNome | uppercase}}
                                            <i class="btn btn-xs icon-btn btn-primary"><span
                                                    class="fas fa-download"></span></i>
                                        </div>
                                    </span>
                                </div>
                                <div class="col-12 text-right mt-1">
                                    <a class="btn btn-primary font-white" (click)="exibirDownloads(honorario, false)">
                                        Fechar
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>    
</div>