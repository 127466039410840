export enum TipoCobrador {
  Cobrador = 0,
  Prospector = 1,
  Ambos = 2,
  Viuvo = 3
}

export const TipoCobradorLabel = new Map<TipoCobrador, string>([
  [TipoCobrador.Cobrador, "Cobrador"],
  [TipoCobrador.Prospector, "Prospector"],
  [TipoCobrador.Ambos, "Ambos"]
]);
