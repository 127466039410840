import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { Guid } from "guid-typescript";

export class OcorrenciaStatusRequest {
    status: TipoStatus;
    ocorrencias: OcorrenciaAtualizarStatus[];

    constructor(status: TipoStatus, ocorrencias: OcorrenciaAtualizarStatus[]) {
        this.status = status;
        this.ocorrencias = ocorrencias;
    }
}

export class OcorrenciaAtualizarStatus {
    id: Guid;
    processoId: Guid;

    constructor(ocorrenciaId: Guid, processoId: Guid){
        this.id = ocorrenciaId;
        this.processoId = processoId;
    }
}
