<div class="card-honorario" (mouseover)="hideButtons = false" (mouseleave)="hideButtons = true" *ngIf="tipo != undefined">
    <div class="row">
        <div class="col-11">
            <h5 class="card-title" *ngIf="tipo != 4">
                <i class="fas fa-file-invoice-dollar"></i> Honorários
            </h5>
        </div>
        <div class="col-1 d-flex justify-content-between align-items-center font-weight-bold pt-3">
            <div class="btn-adicionar" (click)="inserirHonorario = !inserirHonorario" *ngIf="tipo != '4'">
                <i class="fas fa-plus"></i>
            </div>
            <div class="menu-adicionar" [hidden]="!inserirHonorario" *ngIf="tipo != '4'">
                <ul>
                    <li><a routerLink="{{raiz}}/honorario-por-valor/{{tipoHonorario}}/{{tipo}}/{{referenciaId}}">Honorário</a></li>
                    <li><a routerLink="{{raiz}}/honorario-por-percentual/{{tipo}}/{{referenciaId}}">Honorário com % êxito</a></li>
                </ul>
            </div>
            <div class="btn-adicionar" *ngIf="tipoHonorario =='1'" 
            routerLink="{{raiz}}/honorario-por-valor/{{tipoHonorario}}/{{tipo}}/{{referenciaId}}/{{processoJudicialId}}">
                <i class="fas fa-plus"></i>                
            </div>
            <div class="btn-detalhes" *ngIf="tipoHonorario == '1'" routerLink="{{raiz}}/honorarios-listar/{{tipoHonorario}}/{{tipo}}/{{referenciaId}}">
                <i class="fas fa-folder-open"></i>
            </div>
        </div>                
    </div>
    <div class="d-flex justify-content-center" *ngIf="load">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row" *ngIf="!load">
        <div class="col-9">Faturado:</div>
        <div class="col-3 text-right">{{valorTotalFaturado}}</div>
    </div>
    <div class="row" *ngIf="!load">
        <div class="col-9">A faturar:</div>
        <div class="col-3 text-right">{{valorTotalAFaturar}}</div>
    </div>
    <hr *ngIf="!load"/>
    <div class="row" *ngIf="!load">
        <div class="col-9">Total de lançamentos:</div>
        <div class="col-3 text-right">{{valorTotal}}</div>
    </div>
</div>