import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ServiceBase } from "./service.base";

@Injectable()
export class ProcessosJudiciaisOcorrenciaComentarioMencaoService extends ServiceBase {
    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'ProcessoJudicialOcorrenciaComentarioMencao/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    listarPorUsuario(): Observable<any> {
        return this.http.get(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    descartarMencao(id: Guid, tipoAtividade: number): Observable<any> {
        return this.http.put(environment.api + this.endpoint + `descartar-mencao/${id}/${tipoAtividade}`, null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}