import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { DespesaService } from 'src/app/services/despesa.service';
import { NotaDeDebitoService } from 'src/app/services/notadedebito.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-modal-cadastrar-comprovante',
  templateUrl: './modal-cadastrar-comprovante.component.html',
  styleUrls: ['./modal-cadastrar-comprovante.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
],
})
export class ModalCadastrarComprovanteComponent implements OnInit {

    public modalRef: NgbModalRef;
    public processoJudicialRecursoDesdobramentoId;
    public nomeCliente;

    public displayMonths = 1;
    public navigation = "select";

    public form: FormGroup;
    public activeTab = 1;

    public listaArquivo = [];
    public tiposDespesa = [{_id: "1", status: 'Sim'}, {_id: "2", status: 'Não'}]
    public uploader = new FileUploader({
        disableMultipart: true,
    });
    public loadArquivos = false;

    public isUpdate = false;

    get files() {
        return this.form.get("files") as FormArray;
    }

    constructor(
        private notaDebitoService: NotaDeDebitoService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private despesaService: DespesaService,
        private formBuilder: FormBuilder,
        private arquivoService: ArquivoService,
        private utilsService: UtilsService,
        private parserFormatter: NgbDateParserFormatter
    ) { }

    async ngOnInit(): Promise<void> {
        this.isUpdate = this.modalRef.componentInstance.edit;

        this.form = this.formBuilder.group({
            despesaId: this.modalRef.componentInstance.despesa.despesaId,
            clienteId: this.modalRef.componentInstance.despesa.clienteId,
            nomeCliente: new FormControl({
                value: this.modalRef.componentInstance.despesa.nomeCliente,
                disabled: true,
            }),
            document:[null,null],
            tipoDespesa: this.isUpdate ? this.tiposDespesa.filter(filter => filter._id == this.modalRef.componentInstance.despesa.tipoDespesa) : null,
            files: this.formBuilder.array([]),
        });

        this.obterArquivosDespesas();
    }

    

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            this.files.push(this.formBuilder.group( { file: files[i] as File }));
        }
        const fileInQueue: any = this.uploader.queue.find(q =>  q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    baixarItem(arquivo) {
        this.spinner.show();
        try {
            this.arquivoService.downloadArquivo(arquivo.id).subscribe(
                data => {
                    if (data.type == 3) {
                        this.spinner.hide();
                    }
                    this.utilsService.renderDownload(data, arquivo.nome as string);
                }
            );
        } catch {
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }


    obterArquivosDespesas() {
        this.despesaService.buscarArquivosDespesa(
                this.modalRef.componentInstance.despesa
                    .despesaId
            ).subscribe((res) => {
                this.listaArquivo = res.data;
                this.loadArquivos = false;
            });
    }

    popItem(item) {
        const findIndex = this.files.value.findIndex(
            (d) => d.file.name === item.file.name
        );
        if (findIndex > -1) {
            this.files.removeAt(findIndex);
            item.remove();
        }

    }

   

    salvar() {
       
        const valueForm = this.form.getRawValue();
        
        const filesFromForm = this.form.get("files").value;
        const tipoDespesa = this.form.get("tipoDespesa").value;
        let errorDespesas = false;

        if(tipoDespesa == null){
            this.toastrService.error('É necessário selecionar um tipo de faturamento!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        if(filesFromForm.length == 0 && !this.isUpdate){
            this.toastrService.error('Documento é obrigatório!', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
            return;
        }

        filesFromForm.forEach(file => {
            if (file.file.name.toLowerCase().substr(file.file.name.toLowerCase().lastIndexOf('.') + 1) != "pdf"){
                this.toastrService.error('Apenas arquivos PDF são permitidos', 'Atenção', { timeOut: 10000 });
                this.spinner.hide();
                errorDespesas = true;
                return;
            }
        });
        if(errorDespesas) return;

        let formData: FormData = new FormData();
        formData.append('despesaId', valueForm.despesaId);
        formData.append('tipoDespesa', tipoDespesa._id);
        formData.append('despesaFaturada', this.isUpdate ? "true" : "false")
        filesFromForm.forEach((file) => {
            formData.append('files', file.file);
        });

        
        this.addFluxoCaixa(formData)

        
    }


    addFluxoCaixa(formData){
        this.spinner.show();
        this.despesaService.faturarDespesa(formData).subscribe((res) => {
            this.spinner.hide();
            this.toastrService.success(
                "Despesa Faturada com sucesso!",
                "Sucesso"
            );
            this.close();
        },error=>{
            if (error && error.errors) {
              this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível gravar!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
          });
          return;
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

}
