import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DespesaService } from 'src/app/services/despesa.service';
import { NotaDeDebitoService } from 'src/app/services/notadedebito.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-modal-cadastrar-nota-debito',
  templateUrl: './modal-cadastrar-nota-debito.component.html',
  styleUrls: ['./modal-cadastrar-nota-debito.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
],
})
export class ModalCadastrarNotaDebitoComponent implements OnInit {

    public modalRef: NgbModalRef;
    public nomeCliente;

    public displayMonths = 1;
    public navigation = "select";

    public form: FormGroup;

    public activeTab = 1;

    public listaDespesas = [];

    public loadNaoFaturadas = false;

    public isUpdate = false;

    get despesas() {
        return this.form.get("despesas") as FormArray;
    }

    constructor(
        private notaDebitoService: NotaDeDebitoService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private despesaService: DespesaService,
        private formBuilder: FormBuilder,
        private parserFormatter: NgbDateParserFormatter,
        private utilsService: UtilsService
    ) { }

    async ngOnInit(): Promise<void> {
        this.form = this.formBuilder.group({
            clienteId: this.modalRef.componentInstance.clienteId,
            nomeCliente: new FormControl({
                value: this.modalRef.componentInstance.nomeCliente,
                disabled: true,
            }),
            ordemDeCompra: null,
            observacao: null,
            dataVencimento: null,
            dataCriacao: null,
            dataPagamento: {
                value: null,
                disabled: !this.isUpdate,
            },
            valorDebito: { value: null, disabled: true },
            valorCredito: null,
            numeroRecibo: null,
            pago: false,
            despesas: this.formBuilder.array([]),
        });

        this.obterDespesasNaoFaturadasPorCliente();
    }


    obterDespesasNaoFaturadasPorCliente() {
        this.despesaService
            .obterDespesasNaoFaturadasPorCliente(
                this.modalRef.componentInstance.clienteId
            )
            .subscribe((res) => {
                this.listaDespesas = res.data.map(despesa => ({
                    ...despesa,
                    checked: false
                }))
                console.log(this.listaDespesas)
                this.loadNaoFaturadas = false;
            });
    }

    isAllCheckBoxChecked() {
        const valor = this.listaDespesas.reduce((sum, item) => {
            if (item.checked) {
                return sum + item.valor
            }
            return sum;
        }, 0)
        this.form.controls['valorDebito'].setValue(valor)
        return this.listaDespesas.every(p => p.checked);
    }

    checkAllCheckBox(ev: any) {
        this.listaDespesas.forEach(x => x.checked = ev.target.checked)
    }

    convertDate(date) {
        return date ? new Date(date.year, date.month - 1, date.day, 23, 59, 59) : null;
    }

    salvar() {
        

        if(!this.validateForm()) return;
        
        const valueForm = this.form.getRawValue();
        const params = {
            ...valueForm,
            dataPagamento: this.convertDate(valueForm.dataPagamento),
            dataVencimento: this.convertDate(valueForm.dataVencimento),
            valorDebito: valueForm.valorDebito == null ? 0 : valueForm.valorDebito,
            valorCredito: valueForm.valorCredito == null ? 0 : valueForm.valorCredito,
            despesas: this.listaDespesas.filter(p => p.checked).map(p => ({id : p.despesaId}))
        };

        this.add(params);
    }


    validateForm() {
        const dataVencimento = this.convertDate(this.form.get("dataVencimento").value);
        const despesasSelecionadas = this.listaDespesas.filter(p => p.checked).map(p => p.despesaId)
        const ordemDeCompra = this.convertDate(this.form.get("dataVencimento").value);

        if(!ordemDeCompra){
            this.toastrService.error("Ordem de compra é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }
        if(!dataVencimento){
            this.toastrService.error("Data de vencimento é obrigatório", 'Atenção', { timeOut: 10000 });
            return false;
        }
        if (dataVencimento && dataVencimento.getTime() <= new Date().getTime()) {
            this.toastrService.error("Data de vencimento menor que data atual", 'Atenção', { timeOut: 10000 });
            return false;
        }
        if (despesasSelecionadas.length == 0) {
            this.toastrService.error(
                "Selecione pelo menos uma despesa!",
                "Atenção"
            );
            return false;
        }
        return true;
    }

    add(params) {
        debugger;
        this.spinner.show();


        this.notaDebitoService.adicionarNotaDebito(params).subscribe((res) => {
            this.spinner.hide();
            this.toastrService.success(
                "Nota de Débito cadastrada com sucesso.",
                "Sucesso"
            );
            this.close();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível gravar!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
    }

    gerarArquivoNotaDebitoProvisoria(){
        this.spinner.show();
        const despesasSelecionadas = this.listaDespesas.filter(p => p.checked).map(p => p.despesaId)

        if (despesasSelecionadas.length == 0) {
            this.toastrService.error(
                "Selecione pelo menos uma despesa!",
                "Atenção"
            );
            this.spinner.hide();
            return;
        }
        const valueForm = this.form.getRawValue();
        console.log(valueForm);
        const params = {
            ...valueForm,
            dataPagamento: this.convertDate(valueForm.dataPagamento),
            dataVencimento: this.convertDate(valueForm.dataVencimento),
            valorDebito: valueForm.valorDebito == null ? 0 : valueForm.valorDebito,
            valorCredito: valueForm.valorCredito == null ? 0 : valueForm.valorCredito,
            despesas: this.listaDespesas.filter(p => p.checked).map(p => ({id : p.despesaId}))
        };

        this.notaDebitoService.gerarArquivoNotaDebitoProvisoria(params).subscribe((res) => {
            this.utilsService.renderDownload(res, `nota-de-debito-provisoria-${new Date().getTime()}.pdf`,);
            this.spinner.hide();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível gravar!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });

    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

}
