import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter,
    Output,
    OnDestroy,
    ViewEncapsulation,
    Pipe,
    PipeTransform,
} from "@angular/core";
import { ArquivoService } from "src/app/services/arquivo.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { UtilsService } from "src/app/utils/utils.service";
import { MatPaginator } from "@angular/material/paginator";
import { merge, of } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import { FileUploader, FileItem } from "ng2-file-upload";
import { CustomFileLike } from "src/app/models/custom-file-like";
import { ProcessosJudiciaisOcorrenciaPrazoService } from "src/app/services/processos-judiciais-ocorrencia-prazo.service";
import { ProcessosJudiciaisOcorrenciaEventoService } from "src/app/services/processos-judiciais-ocorrencia-evento.service";
import { ProcessosJudiciaisOcorrenciaTarefaService } from "src/app/services/processos-judiciais-ocorrencia-tarefa.service";
import { ProcessosJudiciaisOcorrenciaAudienciaService } from "src/app/services/processos-judiciais-ocorrencia-audiencia.service";
import { Editor, Toolbar, toHTML } from "ngx-editor";
import { DomSanitizer } from "@angular/platform-browser";
import { UploadInBlackgroundService } from "src/app/views/shared/upload-in-blackground/upload-in-blackground.service";
import { FileDownloadBackgoundService } from "src/app/views/shared/download-in-background/download-in-background.service";
import { PessoaService } from "src/app/services/pessoa.service";
import { Guid } from "guid-typescript";

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Component({
    selector: "comentarios-detalhes",
    templateUrl: "./comentarios-detalhes.component.html",
    styleUrls: [
        "./comentarios-detalhes.component.css",
        "../../../../../vendor/styles/pages/dropzone.scss",
    ],
    encapsulation: ViewEncapsulation.None,
})
export class ComentariosDetalhesComponent implements OnInit, OnDestroy {
    @Input() id;
    @Input() idString;
    @Input() tipoOcorrencia;
    @Input() buscarTarefaDoUsuario: any;
    @Input() item: any;
    @Input() processo: any;

    comentariosOriginal;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @Output() changeQuantidade: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeQuantidadeOcultando: EventEmitter<number> =
        new EventEmitter<number>();
    @Output() changeConcluirPrazo: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    editor: Editor;
    toolbar: Toolbar = [
        ["bold", "italic"],
        ["underline", "strike"],
        ["code", "blockquote"],
        ["ordered_list", "bullet_list"],
        [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
        ["text_color", "background_color"],
        ["align_left", "align_center", "align_right", "align_justify"],
    ];

    pessoasParaMencao: any[] = [];
    mentionConfig = {
        triggerChar: '@',
        labelKey: 'nome',
        maxItems: 10,
    };

    public prazo: any;

    public loadPrazo: boolean = true;

    public recarregarAoFechar: boolean = true;

    public comentarioDescricao: any;

    public comentarios: any = [];

    public uploader: FileUploader;

    public loadAtach: boolean = false;

    private file: File;

    private files: Array<File> = [];

    service;

    qtdCaracteresComentario = 0;

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public arquivoService: ArquivoService,
        public utilsService: UtilsService,
        public prazoService: ProcessosJudiciaisOcorrenciaPrazoService,
        public eventoService: ProcessosJudiciaisOcorrenciaEventoService,
        public tarefaService: ProcessosJudiciaisOcorrenciaTarefaService,
        public audienciaService: ProcessosJudiciaisOcorrenciaAudienciaService,
        private uploadInBlackgroundService: UploadInBlackgroundService,
        private downloadBackgoundService: FileDownloadBackgoundService,
        private pessoaService: PessoaService
    ) {
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            },
        });
    }

    ngOnDestroy(): void {
        this.editor.destroy();
    }

    ngOnInit(): void {
        this.uploader.onAfterAddingFile = (file) => {
            const { pasta, tipo, numero } = this.processo;
            const extension = file.file.type.split("/")[1];
            const identificador = Math.floor(Math.random() * 100);
            // const newName = `${pasta ? pasta + "-" : ""}${tipo ? tipo + "-" : ""
            //     }${numero ? numero + "-" : ""
            //     }${identificador}.${extension}`;
            //file.file.name = newName;

            this.files.push(
                new File([file._file], file.file.name, { type: file.file.type })
            );

            const fileInQueue: any = this.uploader.queue.find(
                (q) => q.file.name === file.file.name
            );
            const newFile = new CustomFileLike(fileInQueue.file);
            fileInQueue.file = newFile;
        };

        this.editor = new Editor();

        let key = "";
        switch (this.tipoOcorrencia) {
            case 1: {
                this.service = this.tarefaService;
                key = "tarefaId";
                break;
            }
            case 3: {
                this.service = this.eventoService;
                key = "eventoId";
                break;
            }
            case 4: {
                this.service = this.prazoService;
                key = "prazoId";
                break;
            }
            case 5: {
                this.service = this.audienciaService;
                key = "audienciaId";
                break;
            }
        }

        if (this.idString == undefined) {
            this.idString = key;
        }
        this.buscarTarefa();
        this.listarPessoasParaMencao();
    }

    linkListToPaginator() {
        merge(this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    const data = this.comentariosOriginal;
                    return of(data);
                })
            )
            .subscribe((res: any) => {
                const from = this.paginator.pageIndex * 4;
                const to = from + 4;
                this.comentarios = res.slice(from, to);
            });
    }

    buscarTarefa() {
        this.service.getById(this.id).subscribe((res) => {
            if (res.success) {
                this.prazo = res.data;

                this.loadPrazo = false;

                this.prazo.quantidadeComentarios = res.data.quantidadeComentarios;

                this.comentarios = res.data.comentarios;
                this.comentariosOriginal = this.comentarios;
                this.linkListToPaginator();

                this.changeQuantidade.emit(this.prazo.quantidadeComentarios);
            }
        });
    }

    editorChange(event) {
        let arraySum = [];
        event?.content.forEach((content) => {
            arraySum.push(
                content.content?.reduce((acc, o) => acc + parseInt(o.text.length), 0) ??
                0
            );
            this.qtdCaracteresComentario = arraySum.reduce((acc, o) => acc + o, 0);
        });
    }

    callAfterUpload() {
        this.buscarTarefa();

        this.comentarioDescricao = "";

        this.spinner.hide();

        this.uploader.clearQueue();

        this.files = [];

        if (
            this.item != undefined &&
            this.item != null &&
            this.buscarTarefaDoUsuario != undefined &&
            this.buscarTarefaDoUsuario != null
        ) {
            this.buscarTarefaDoUsuario.emit(this.item);
        }
    }

    handleInserirComentarioForm() {

        const comentario = this.comentarioDescricao;
        const pessoasMencionadas = this.obterPessoasMencionadas();

        let formData: FormData = new FormData();
        formData.append(this.idString, this.id);
        formData.append("comentario", comentario != undefined ? toHTML(comentario) : "");
        pessoasMencionadas.forEach(pessoaMencionada => {
            formData.append("pessoasMencionadas", pessoaMencionada.toString());
        });
        this.files.forEach((file) => {
            formData.append("files", file);
        });

        // if (this.files.length > 0) {
        //     this.uploadInBlackgroundService.show({
        //         urlFrom: "modal",
        //         service: this.service,
        //         uploadFunction: "addComentario",
        //         params: formData,
        //         files: this.files,
        //         context: this,
        //         callAfterUpload: "callAfterUpload",
        //         msgSuccess: "Comentário cadastrado.",
        //         msgError: "Não foi possível cadastrar o comentário!",
        //     });
        // } else {
        //     this.service.addComentario(formData)
        //         .subscribe(res => {
        //             if (res.success) {
        //                 this.toastrService.success("Comentário cadastrado.", "Sucesso");

        //                 this.buscarTarefa();

        //                 this.comentarioDescricao = "";

        //                 this.spinner.hide();

        //                 this.uploader.clearQueue();

        //                 this.files = [];

        //                 if (this.item != undefined &&
        //                     this.item != null &&
        //                     this.buscarTarefaDoUsuario != undefined &&
        //                     this.buscarTarefaDoUsuario != null) {
        //                     this.buscarTarefaDoUsuario.emit(this.item);
        //                 }
        //             } else {
        //                 this.toastrService.error("Falha ao adicionar comentário.", "Sucesso");

        //                 this.spinner.hide();
        //             }
        //         }, error => {
        //             if (error && error.errors) {
        //                 this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
        //             }
        //             else {
        //                 this.toastrService.error('Não foi possível cadastrar o comentário!', 'Atenção', { timeOut: 10000 });
        //             }
        //             this.spinner.hide();
        //         });
        // }
        // if(this.qtdCaracteresComentario > 100) {
        //     this.toastrService.error('Máximo de 100 caracteres é permitido para o comentário', 'Atenção', { timeOut: 10000 });
        //     return;
        // }
        this.spinner.show();
        this.service.addComentario(formData)
            .subscribe(res => {
                if (res.success) {
                    this.toastrService.success("Comentário cadastrado.", "Sucesso");

                    this.buscarTarefa();

                    this.spinner.hide();

                    this.uploader.clearQueue();

                    this.editor.setContent('');

                    this.files = [];

                    if (this.item != undefined &&
                        this.item != null &&
                        this.buscarTarefaDoUsuario != undefined &&
                        this.buscarTarefaDoUsuario != null) {
                        this.buscarTarefaDoUsuario.emit(this.item);
                    }
                } else {
                    this.toastrService.error("Falha ao adicionar comentário.", "Sucesso");

                    this.spinner.hide();
                }
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o comentário!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            this.files.push(files[i] as File);
        }

        const fileInQueue: any = this.uploader.queue.find(
            (q) => q.file.name === files[0].name
        );
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item: FileItem) {
        console.log(item);
        this.files = this.files.filter((c) => c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(
            (c) => c.file.name != item.file.name
        );
    }

    handleOcultar() {
        this.changeQuantidadeOcultando.emit(this.prazo.quantidadeComentarios);
    }

    handleDownloadArquivo(arquivo: any) {
        const params = {
            arquivoId: arquivo.arquivoId,
            file: { name: arquivo.nomeArquivo, id: arquivo.id },
        };
        this.downloadBackgoundService.show(params);
    }

    definirImportante(comentario) {
        this.spinner.show();

        this.service.alterarStatusImportanteComentario(comentario.id).subscribe(
            (res) => {
                if (res.success) {
                    comentario.importante = !comentario.importante;
                    this.toastrService.success(
                        "Status do comentário alterado com sucesso.",
                        "Sucesso"
                    );
                } else {
                    this.toastrService.error(
                        "Não foi possível alterar status do comentário.",
                        "Ops"
                    );
                }

                this.spinner.hide();
            },
            (error) => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], "Ops", { timeOut: 10000 });
                } else {
                    this.toastrService.error(
                        "Não foi possível alterar o status do comentário!",
                        "Ops",
                        { timeOut: 10000 }
                    );
                }

                this.spinner.hide();
            }
        );
    }

    listarPessoasParaMencao(): void {
        this.pessoaService.listarPessoasParaMencao().subscribe(res => {
            this.pessoasParaMencao = res.data;
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção');
            }
            else {
                this.toastrService.error('Não foi possível carregar pessoas para menção!', 'Atenção', { timeOut: 10000 });
            }
        });
    }

    obterPessoasMencionadas(): Guid[] {
        const mencoesEncontradas: Guid[] = [];
        var comentario = toHTML(this.comentarioDescricao)

        for (const pessoa of this.pessoasParaMencao) {
            const regex = new RegExp(`@${pessoa.nome}`, 'g');
            if (comentario.match(regex)) {
                mencoesEncontradas.push(pessoa.idPessoa);
            }
        }

        const distinctMencoesEncontradas = mencoesEncontradas.filter(
            (value, index, self) => {
                return self.indexOf(value) === index;
            }
        );

        return distinctMencoesEncontradas;
    }
}
