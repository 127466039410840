<div class="css-hd7q8 {{classe}}">
    <div class="css-70qvj9" *ngFor="let item of data">
        <div class="css-thpkte" (click)="item.onFilter()">
            <div class="css-t0iktd" [ngClass]="{'qtd': isAreaTrabalho}">{{item.value}}</div>
            <div class="css-9q7euj" [ngClass]="{'desc': isAreaTrabalho}">{{item.label}}</div>
            <div *ngIf="item.showAlert && item.value > 0" class="fa-blink"></div>
        </div>
        <div class="css-4unb3"></div>
    </div>
    <div class="css-70qvj9" *ngIf="hasChart">
        <div class="css-thpkte">
            <div [ngStyle]="{'display': 'block','height': barHeight, 'width': barWidth}">
                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                    [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" height="70"
                    width="150">
                </canvas>
            </div>
        </div>
    </div>
</div>