<div class="d-flex justify-content-center" *ngIf="load">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="card" *ngIf="!load && despesas != undefined && despesas.length > 0">    
    <div class="card-header mb-2">
        <h3>Despesas</h3>
    </div>
    <div class="card-body">
        <div class="table-responsive">                        
            <table class="table table-striped table-bordered card-table">
                <thead>
                    <tr>
                        
                        <th class="text-nowrap text-center align-middle cursor-header" (click)="setSortDespesasPendente('descricao')">
                            Descrição<i class="ion text-muted ml-2"
                                *ngIf="sortByDespesasPendente =='descricao'"
                                [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center align-middle cursor-header" (click)="setSortDespesasPendente('centroCustoNome')">
                            Centro de custo<i class="ion text-muted ml-2"
                                *ngIf="sortByDespesasPendente =='centroCustoNome'"
                                [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center align-middle cursor-header"(click)="setSortDespesasPendente('valor')">
                            Valor<i class="ion text-muted ml-2"
                                *ngIf="sortByDespesasPendente =='valor'"
                                [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center align-middle cursor-header" (click)="setSortDespesasPendente('data')">
                            Lançamento<i class="ion text-muted ml-2"
                                *ngIf="sortByDespesasPendente =='data'"
                                [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                        </th>
                        <th class="text-nowrap text-center align-middle cursor-header"(click)="setSortDespesasPendente('tipoDespesa')">
                            Tipo Despeas<i class="ion text-muted ml-2"
                                *ngIf="sortByDespesasPendente =='tipoDespesa'"
                                [ngClass]="'ion-md-arrow-' + (sortDescByDespesasPendente ? 'down' : 'up')"></i>
                        </th>
                        
                        <th class="text-center text-nowrap align-middle" *ngIf="!resumo">
                            Anexos
                        </th>
                        <th class="text-center text-nowrap align-middle">
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tabela" *ngFor="let despesa of despesas">
                        
                        <td>
                            {{despesa.descricao}}
                        </td>
                        <td>
                            {{despesa.centroCustoNome}}
                        </td>
                        <td>
                            {{despesa.valor | currency: 'R$'}}
                        </td>
                        <td>
                            {{despesa.data | date : "dd/MM/yyyy"}}
                        </td>
                        <td>
                            {{despesa.tipoDespesa}}
                        </td>
                        <td  *ngIf="!resumo" class="text-center text-nowrap align-middle">
                            <div class="anexo d-flex align-items-center">
                                <ng-container *ngFor="let arquivo of despesa.arquivos">                                    
                                    <div class="btn btn-primary" [ngbTooltip]="arquivo.arquivoNome"
                                        (click)="handleDownloadArquivo(arquivo)">
                                        {{arquivo.arquivoNome}} <i class="btn btn-xs icon-btn btn-primary"><span
                                                class="fas fa-download"></span></i>
                                    </div>
                                </ng-container>
                            </div>
                        </td>
                        <td class="text-center text-nowrap align-middle">
                            <button type="button" class="btn btn-primary btn-sm"
                                (click)="editarDespesa(despesa)">
                                Editar
                            </button>
                            
                            <button type="button" class="btn btn-danger btn-sm" 
                                (click)="deletarDespesa(despesa)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>            
        </div>
    </div>
</div>