import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-header-juros-moratorios',
  templateUrl: './modal-header-juros-moratorios.component.html',
  styleUrls: ['./modal-header-juros-moratorios.component.scss']
})
export class ModalHeaderJurosMoratoriosComponent implements OnInit {

  public headerJurosMoratoriosForm: FormGroup;
  public title: string;
  modalRef: NgbModalRef;
  
  get formAvancado() {
    return this.headerJurosMoratoriosForm.controls['avancado'] as FormArray;
  }

  constructor(private activeModalService: NgbActiveModal, private fb: FormBuilder) { }

  ngOnInit(): void {
    if(!this.formAvancado.controls.length) {
        this.createItem();
        this.createItem();
        this.createItem();
        this.createItem();
    }
  }

  createItem() {
    this.formAvancado.push(this.fb.group({
        dataInicial: [],
        dataFinal: [],
        percentual: [],
        tipoCalculo: [],
    }));
  }

  dismiss(){
    this.modalRef.dismiss();
  }

  close() {
    this.modalRef.close();
  }

}
