import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { TagService } from 'src/app/services/tag.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-modal-adicionar-tag',
    templateUrl: './modal-adicionar-tag.component.html',
    styleUrls: ['./modal-adicionar-tag.component.scss']
})

export class ModalAdicionarTagComponent implements OnInit {

    // Parâmetros
    modalRef: NgbModalRef;
    processoJudicialId: Guid;
    tagsDoProcesso?: any[] = [];

    public tagDropdownSettings = {
        singleSelection: false,
        primaryKey: "tagId",
        text: "",
        noDataLabel: "",
        enableSearchFilter: true,
        filterSelectAllText: "Selecionar todas filtradas",
        filterUnSelectAllText: "Desmarcar todas filtradas",
        disabled: false,
        selectAllText: "Selecionar todas",
        unSelectAllText: "Desmarcar todas",
        searchPlaceholderText: "Procurar",
        classes: 'multiselect-tags',
        badgeShowLimit: 4
    };

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    public loadTags: boolean = false;
    public tags: any[] = [];
    tagsSelecionadas: any = [];

    constructor(
        public _tagService: TagService,
        public _spinner: NgxSpinnerService,
        private _processosJudiciaisService: ProcessosJudiciaisService
    ) { }

    ngOnInit(): void {

        this.buscarTags();
    }

    buscarTags(): void {

        this.loadTags = true;

        this._tagService.getAllAsync().then(res => {

            this.loadTags = false;

            const tagIdsDoProcesso = this.tagsDoProcesso.map(tag => tag.tagId);

            res.data.forEach((tag, index) => {

                if (!tagIdsDoProcesso || !tagIdsDoProcesso.includes(tag.id)) {

                    this.tags.push({
                        tagId: tag.id,
                        tagNome: tag.nome,
                        tagCor: tag.cor,
                    });
                }
            });
        });
    }

    close(): void {
        this.modalRef.close();
    }

    dismiss(): void {
        this.modalRef.dismiss();
    }

    salvar(): void {

        if (this.tagsSelecionadas != null && this.tagsSelecionadas.length == 0) {
            this.swalWithBootstrapButtons.fire(
                'Selecione tags para adicionar ao processo!',
                '',
                'warning'
            );

            return;
        }

        this._spinner.show();

        this.tagsSelecionadas.forEach(tag => {
            tag.processoJudicialId = this.processoJudicialId
        });

        this._processosJudiciaisService.inserirTag(this.tagsSelecionadas)
            .subscribe(res => {

                this._spinner.hide();
                this.close();
            });
    }
}
