import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'prioridade-icone',
    templateUrl: './prioridade-icone.component.html',
    styleUrls: ['./prioridade-icone.component.css']
})
export class PrioridadeIconeComponent implements OnInit {

    @Input() prioridade: any;
    @Input() prioridadeDescricao: any;
    
    constructor() { }

    ngOnInit(): void {
    }

}
