import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principal-finaceiro',
  templateUrl: './principal-finaceiro.component.html',
  styleUrls: ['./principal-finaceiro.component.scss']
})
export class PrincipalFinaceiroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
