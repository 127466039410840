import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Guid } from "guid-typescript";

@Injectable()
export class UsuarioService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'usuario/';

    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    getAll(): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'getAll')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getById(id: Guid): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'getById/'+id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    GetPessoasIdsRelacionadasAoUsuarioById(): Observable<any> {
      return this.http.get(environment.api + this.endpoint + 'GetPessoasIdsRelacionadasAoUsuarioById')
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    ObterPessoasRelacionadasAoUsuarioPorUsuarioId(): Observable<any> {
        return this.http.get(environment.api + this.endpoint + 'ObterPessoasRelacionadasAoUsuarioPorUsuarioId')
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
      }


    add(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'add', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    registerAuthKey(data): Observable<any> {
        return this.http.post(environment.api + this.endpoint + 'register-auth-key', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    update(data): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'update', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getPermissoes()  {
        return this.http.post(environment.api + this.endpoint + 'getPermissoes',null).toPromise();
    }


    delete(id): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + 'delete/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    ativarUsuario(usuario): Observable<any> {
        return this.http.put(environment.api + this.endpoint + 'ativar', usuario)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    loginComRefreshToken(token): Observable<any> {
      return this.http.post(environment.api + this.endpoint + 'login/refreshtoken', { token : token })
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
}
