export enum SituacaoCredor {
  Ativo = 0,
  Bloqueado = 1,
  Cancelado = 2
}

export const SituacaoCredorLabel = new Map<SituacaoCredor, string>([
  [SituacaoCredor.Ativo, "Ativo"],
  [SituacaoCredor.Bloqueado, "Bloqueado"],
  [SituacaoCredor.Cancelado, "Cancelado"],
]);
