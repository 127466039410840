import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ProcessosJudiciaisOcorrenciaTarefaService } from 'src/app/services/processos-judiciais-ocorrencia-tarefa.service';
import { ProcessosJudiciaisOcorrenciaPrazoService } from 'src/app/services/processos-judiciais-ocorrencia-prazo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ProcessosJudiciaisOcorrenciaAudienciaService } from 'src/app/services/processos-judiciais-ocorrencia-audiencia.service';

@Component({
    selector: 'app-modal-concluir-atividade',
    templateUrl: './modal-concluir-atividade.component.html',
    styleUrls: [
        './modal-concluir-atividade.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ]
})
export class ModalConcluirAtividadeComponent implements OnInit {

    modalRef: NgbModalRef;

    public descricao: any;

    public uploader: FileUploader;
    private files: Array<File> = [];
    public hasBaseDropZoneOver: boolean;
    public item: any;
    public gerarPublicacao: boolean = false;

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    constructor(
        private processosJudiciaisOcorrenciaTarefaService: ProcessosJudiciaisOcorrenciaTarefaService,
        private processosJudiciaisOcorrenciaPrazoService: ProcessosJudiciaisOcorrenciaPrazoService,
        private processosJudiciaisOcorrenciaAudienciaService: ProcessosJudiciaisOcorrenciaAudienciaService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
    ) {
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            }
        });
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            this.files.push(files[i] as File);
        }

        const fileInQueue: any = this.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item: FileItem) {
        console.log(item);
        this.files = this.files.filter(c => c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(c => c.file.name != item.file.name);
    }

    ngOnInit(): void {
    }

    concluirItem(item: any) {
        this.item = item;
    }

    salvar() {
        if (this.item.tipoOcorrencia != 1 && this.item.tipoOcorrencia != 4 && this.item.tipoOcorrencia != 5) {
            this.swalWithBootstrapButtons.fire(
                'Não foi possível concluir!',
                '',
                'error'
            );

            return;
        }

        if (this.descricao == undefined || this.descricao == "") {
            this.swalWithBootstrapButtons.fire(
                'Informe uma descrição!',
                '',
                'error'
            );

            return;
        }

        this.spinner.show();

        let formData: FormData = new FormData();
        formData.append('comentario', this.descricao != undefined ? this.descricao : "");
        this.files.forEach(file => {
            formData.append('files', file);
        });
        formData.append('gerarPublicacao', this.gerarPublicacao ? 'true' : 'false');

        if (this.item.tipoOcorrencia == 1) {
            formData.append('tarefaId', this.item.id);
            this.processosJudiciaisOcorrenciaTarefaService.concluirTarefa(formData)
                .subscribe(res => {
                    this.spinner.hide();
                    this.close();
                }, error => {
                    this.spinner.hide();
                    this.swalWithBootstrapButtons.fire(
                        'Não foi possível concluir!',
                        '',
                        'error'
                    );
                });
        } else if (this.item.tipoOcorrencia == 4) {
            formData.append('prazoId', this.item.id);
            this.processosJudiciaisOcorrenciaPrazoService.concluirPrazo(formData)
            .subscribe(res => {
                this.spinner.hide();
                this.close();
            }, error => {
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível concluir!',
                    '',
                    'error'
                );
            });
        }else if (this.item.tipoOcorrencia == 5) {
            formData.append('audienciaId', this.item.id);
            this.processosJudiciaisOcorrenciaAudienciaService.concluirAudiencia(formData)
            .subscribe(res => {
                this.spinner.hide();
                this.close();
            }, error => {
                this.spinner.hide();
                this.swalWithBootstrapButtons.fire(
                    'Não foi possível concluir!',
                    '',
                    'error'
                );
            });
        }else{
            this.swalWithBootstrapButtons.fire(
                'Não foi possível concluir!',
                '',
                'error'
            );
        }
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
