import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-dashboard-footer',
  templateUrl: './layout-dashboard-footer.component.html',
  styles: [':host { display: block; }']
})
export class LayoutDashboardFooterComponent {


  constructor() {}

}
