<div class="row audiencia">
    <div class="col-12">
        <h6>Audiência/Julgamento ({{index}})</h6>
    </div>
    <div class="col-12">
        <label class="form-label">Tipo de Sessão</label>
        <ng-select [items]="tipoDeSessao" [(ngModel)]="audiencia.tipoSessaoId" [ngModelOptions]="{standalone: true}"
            bindLabel="nome" bindValue="id" [loading]="loadTipoDeSessao"></ng-select>
    </div>
    <div class="col-6 mb-2">
        <label class="form-label">Data<span class="required-field ml-1">*</span></label>
        <div class="input-group">
            <input (click)="dataHoraInicialAudiencia.toggle()" class="form-control" placeholder="dd/mm/yyyy"
                name="dataHoraInicialAudienciaComponent" ngbDatepicker [(ngModel)]="audiencia.dataHoraInicialComponent"
                placement="bottom-right" #dataHoraInicialAudiencia="ngbDatepicker">
            <div class="input-group-append">
                <button class="input-group-text" (click)="dataHoraInicialAudiencia.toggle()" type="button">
                    <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-3 mb-2">
        <label class="form-label">Início</label>
        <input type="time" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="audiencia.dataHoraInicialTimeComponent">
    </div>
    <div class="col-3 mb-2">
        <label class="form-label">Fim</label>
        <input type="time" class="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="audiencia.dataHoraFinalTimeComponent">
    </div>
    <div class="col-2 mb-2">
        <label class="form-label">Alerta</label>
        <input type="number" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="audiencia.alerta">
    </div>
    <div class="col-10 mb-2">
        <label class="form-label">&nbsp;</label>
        <ng-select [items]="tipoAlerta" [(ngModel)]="audiencia.tipoAlerta" [ngModelOptions]="{standalone: true}"
            bindLabel="nome" bindValue="id" [loading]="loadTipoAlerta"></ng-select>
    </div>
    <!-- <div class="col-7 mb-2">
        <label class="form-label">Fórum</label>
        <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="audiencia.forum">
    </div>
    <div class="col-3 mb-2">
        <label class="form-label">Sala do fórum</label>
        <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="audiencia.sala">
    </div> -->
    <div class="form-group col-6">
        <label class="form-label">Divisão de órgão</label>
        <ng-select [items]="divisoes" [(ngModel)]="audiencia.divisaoDeOrgaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Divisão de Órgão"  [loading]="divisoes == undefined || !divisoes.length"></ng-select>
    </div>
    <div class="form-group col-6">
        <label class="form-label">Órgão</label>
        <ng-select [items]="orgaos" [(ngModel)]="audiencia.orgaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Órgão" [loading]="orgaos == undefined || !orgaos.length"></ng-select>
    </div> 
    <div class="form-group col-12">
        <label class="form-label">Foro</label>
        <ng-select [items]="foros" [(ngModel)]="audiencia.foroId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Foro" [loading]="foros == undefined || !foros.length"></ng-select>
    </div>
    <div class="form-group col-4">
        <label class="form-label">Comarca</label>
        <ng-select [items]="comarcas" [(ngModel)]="audiencia.comarcaId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a comarca" [loading]="comarcas == undefined || !comarcas.length"></ng-select>
    </div>
    <div class="form-group col-4">
        <label class="form-label">UF</label>
        <ng-select [items]="ufs" [(ngModel)]="audiencia.ufId" [ngModelOptions]="{standalone: true}" bindLabel="displayName" bindValue="id" placeholder="Digite a UF" [loading]="ufs == undefined || !ufs.length"></ng-select>
    </div>
    <div class="form-group col-4">
        <label class="form-label">Área</label>
        <ng-select [items]="areas" [(ngModel)]="audiencia.areaId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite a Área" [loading]="areas == undefined || !areas.length"></ng-select>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Endereço</label>
        <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="audiencia.endereco">
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Modo de participação</label>
        <ng-select [items]="tipoAudiencias" [(ngModel)]="audiencia.tipoAudiencia" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Digite o modo de participação" [loading]="tipoAudiencias == undefined || !tipoAudiencias.length"></ng-select>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Link para participação</label>
        <input type="text" class="form-control" maxlength="200" [ngModelOptions]="{standalone: true}" [(ngModel)]="audiencia.tipoAudienciaLink">
    </div>
    <div class="col-12 mb-2">
        <div class="form-row mt-2">
            <div class="col">
                <label class="form-label d-flex align-items-center">
                    Envolvidos 
                    <button type="button" class="btn btn-primary btn-xs ml-3" (click)="adicionarEnvolvido()"><i class="fas fa-plus"></i></button>
                </label>
            </div>
        </div>        
        <div class="form-row" *ngFor="let envolvido of audiencia.envolvidos; let i = index">
            <div class="form-group col-7">                
                <pessoa-dropdown [(pessoaId)]="envolvido.envolvidoId"></pessoa-dropdown>
            </div>
            <div class="form-group col-4">
                <ng-select [items]="qualificacoes" [(ngModel)]="envolvido.qualificacaoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Qualificação" [loading]="qualificacoes != undefined && !qualificacoes.length"></ng-select>
            </div>
            <div class="form-group col-1">
                <button type="button" class="btn btn-danger" (click)="removerEnvolvido(i)"><i class="fas fa-trash"></i></button>
            </div>
        </div>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Número da Pauta</label>
        <input type="text" class="form-control" maxlength="200" [ngModelOptions]="{standalone: true}" [(ngModel)]="audiencia.numeroPauta">
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Número de Ordem/Preferência</label>
        <input type="text" class="form-control" maxlength="200" [ngModelOptions]="{standalone: true}" [(ngModel)]="audiencia.numeroOrdemPreferencia">
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Observações<span class="required-field ml-1">*</span></label>
        <textarea class="form-control" placeholder="Digite a descrição" rows="2" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="audiencia.observacao" maxlength="1000"></textarea>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Executor <span class="required-field">*</span></label>        
        <pessoa-dropdown #executorDropdown [(pessoaId)]="audiencia.responsavelId"></pessoa-dropdown>
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Revisor <span class="required-field">*</span></label>        
        <pessoa-dropdown #revisorDropdown [(pessoaId)]="audiencia.revisorId"></pessoa-dropdown> 
    </div>
    <div class="col-12 mb-2">
        <label class="form-label">Assistente <span class="required-field">*</span></label>        
        <pessoa-dropdown #assistenteDropdown [(pessoaId)]="audiencia.assistenteId"></pessoa-dropdown> 
    </div>
</div>