<nav class="au-app-nav " id="sidebar">
    <div class="row au-app-nav-bar  display-sm-none">
        <div class="col-12">
            <ul class="nav navbar-nav d-flex flex">
                <li><a type="button"><i class="fa fa-plus icon-header" role="button" title="Adicionar"></i></a></li>
                <li><a type="button"><i class="fa fa-cloud-download-alt icon-header" role="button"
                            title="Adicionar"></i></a></li>
                <li><a type="button" (click)="menuClose(false)"><i class="fa fa-times icon-header" role="button"
                            title="Adicionar"></i></a></li>
            </ul>
        </div>
    </div>
    <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/area-trabalho">
        <i class="fa fa-list-ul"></i> Área de trabalho
    </a>

    <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/agenda">
        <i class="fa fa-calendar-check"></i> Agenda
    </a>

    <ng-container>
        <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/contatos">
            <i class="fas fa-user"></i> Contatos
        </a>

        <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/gestao-despesas">
            <i class="fas fa-dollar-sign"></i> Gestão de Despesas
        </a>

        <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/gestao-acordos-judiciais">
            <i class="fas fa-handshake"></i> Acordos Judiciais
        </a>

        <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/atendimentos">
            <i class="fas fa-comments"></i> Atendimento
        </a>
    </ng-container>

    <a class="au-app-nav__item" [ngClass]="isPublicacaoSelected() ? 'au-app-nav__item_active' : ''" routerLink="/dashboard/publicacoes">
        <i class="fa fa-file-alt"></i> Publicações
    </a>

    <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active"
        routerLink="/dashboard/carteira-de-processos">
        <i class="fas fa-briefcase"></i> Casos e Processos
    </a>

    <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active"
        routerLink="/dashboard/financeiro">
        <i class="fas fa-dollar-sign"></i> Financeiro
    </a>

    <ng-container>
        <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/documentos">
            <i class="fas fa-paperclip"></i> Documentos
        </a>

        <a class="au-app-nav__item" routerLinkActive="au-app-nav__item_active" routerLink="/dashboard/alertas">
            <div matBadge="4" matBadgeColor="warn" matBadgeOverlap="false" class="demo-section"><i class="fas fa-bell"></i>Alertas</div>
        </a>
    </ng-container>
</nav>