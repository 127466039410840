import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { ProcessoOperacaoSimplificado } from 'src/app/models/operacionais/operacao/processo-operacao-simplificado';
import { GestaoProcessoService } from 'src/app/services/gestao-processos.service';
import { ProcessosService } from 'src/app/services/processos.service';

@Component({
  selector: 'app-modal-lista-processos-pessoa',
  templateUrl: './modal-lista-processos-pessoa.component.html',
  styleUrls: ['./modal-lista-processos-pessoa.component.css']
})
export class ModalListaProcessosPessoaComponent implements OnInit {


  public loadingProcesso:Boolean = false;
  public processos: ProcessoOperacaoSimplificado[] = [];
  modalRef: NgbModalRef;

  public pessoaId:Guid;
  options = { readonly: true };

  constructor(
    private processoService: ProcessosService,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private gestaoProcessoService: GestaoProcessoService
  ) {

   }


  ngOnInit(): void {
    this.pessoaId = this.modalRef.componentInstance.pessoaId;
    this.options.readonly = this.modalRef.componentInstance.readonly;
    this.buscarProcessos(this.pessoaId);
  }



  public buscarProcessos(pessoaId) {
    this.loadingProcesso = true;
    this.gestaoProcessoService.buscarProcessosByPessoa(pessoaId)
        .subscribe((res: ProcessoOperacaoSimplificado[]) => {
            this.processos = res;
            this.loadingProcesso = false;
        }, error => {
            this.loadingProcesso = false;
        });
}

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }

}
