import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessosJudiciaisOcorrenciaTarefaService } from 'src/app/services/processos-judiciais-ocorrencia-tarefa.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
    selector: 'tarefas-detalhes',
    templateUrl: './tarefas-detalhes.component.html',
    styleUrls: [
        './tarefas-detalhes.component.css',
        '../../../../../vendor/styles/pages/dropzone.scss'
    ]
})
export class TarefasDetalhesComponent implements OnInit {

    @Input() tarefaId: any;
    @Input() tarefaDetalhes: any;

    @Output() changeQuantidade: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeQuantidadeOcultando: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeConcluirTarefa: EventEmitter<boolean> = new EventEmitter<boolean>();

    public tarefa: any;

    public loadTarefa: boolean = true;

    public recarregarAoFechar: boolean = true;

    public comentarioDescricao: string;

    public comentarios: any = [];

    public hasBaseDropZoneOver: boolean;

    public uploader: FileUploader;

    public loadAtach: boolean = false;

    private file: File;

    private files: Array<File> = [];

    constructor(
        public processosJudiciaisOcorrenciaTarefaService: ProcessosJudiciaisOcorrenciaTarefaService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public arquivoService: ArquivoService,
        public utilsService: UtilsService,
    ) {

        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            }
        });

    }

    ngOnInit(): void {
        this.buscarTarefa();
    }

    buscarTarefa() {
        this.processosJudiciaisOcorrenciaTarefaService.getById(this.tarefaId)
            .subscribe(res => {

                if (res.success) {
                    this.tarefa = res.data;

                    this.loadTarefa = false;

                    this.tarefa.quantidadeComentarios = res.data.quantidadeComentarios;

                    this.comentarios = res.data.comentarios;

                    this.changeQuantidade.emit(this.tarefa.quantidadeComentarios);
                }
            })
    }

    adicionarFiles(item: FileLikeObject, comment: any) {
        //comment.files.push(item as File);
    }

    handleInserirComentarioForm() {

        this.spinner.show();
        this.toastrService.clear();

        let formData: FormData = new FormData();
        formData.append('tarefaId', this.tarefaId);
        formData.append('comentario', this.comentarioDescricao != undefined ? this.comentarioDescricao : "");
        this.files.forEach(file => {
            formData.append('files', file);
        });


        this.processosJudiciaisOcorrenciaTarefaService.addComentario(formData)
            .subscribe(res => {
                if (res.success) {
                    this.toastrService.success("Comentário cadastrado.", "Sucesso");

                    this.buscarTarefa();

                    this.comentarioDescricao = "";

                    this.spinner.hide();

                    this.uploader.clearQueue();

                    this.files = [];
                } else {
                    this.toastrService.error("Falha ao adicionar comentário.", "Sucesso");

                    this.spinner.hide();
                }
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível cadastrar o comentário!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    handleOcultar() {
        this.changeQuantidadeOcultando.emit(this.tarefa.quantidadeComentarios);
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {            
            this.files.push(files[i] as File);
        }

        const fileInQueue = this.uploader.queue.find(q => q.file.name === files[0].name);
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item:FileItem){
        console.log(item);
        this.files = this.files.filter(c=>c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(c=>c.file.name != item.file.name);
    }

    handleDownloadArquivo(arquivo:any){
        this.spinner.show();
        try{
            this.arquivoService.downloadArquivo(arquivo.arquivoId).subscribe(                
                data => {                    
                    if(data.type == 3){
                        this.spinner.hide();                    
                    }
                    this.utilsService.renderDownload(data, arquivo.nomeArquivo as string);
                }
            );
        }catch{
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }
}
