<ng-select 
    [items]="classificacoesMateriasPedidos" 
    [loading]="load"
    [multiple]="selecionaMultiplos"
    [(ngModel)]="classificacaoMateriaPedidoId" 
    [ngModelOptions]="{standalone: true}"
    bindLabel="nome" 
    bindValue="id" 
    placeholder="Digite a classificação da matéria do pedido" 
    notFoundText="Não há dados para esse filtro"
    loadingText="Buscando"
    clearAllText="Limpar seleção"
    (keyup)="filtrar($event)"
    (click)="changeClassificacaoMateriaPedido($event)"
    (clear)="limpar()"
    >
</ng-select>
