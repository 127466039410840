import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { TipoPrioridadeService } from 'src/app/services/tipo-prioridade.service';
import { TipoTarefaService } from 'src/app/services/tipo-tarefa.service';
import { CalendarioService } from 'src/app/services/calendario.service';
import { ClassificacaoAtividadeService } from 'src/app/services/classificacao-atividade.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { PessoaDropdownComponent } from '../pessoa-dropdown/pessoa-dropdown.component';

@Component({
    selector: 'tarefa-cadastro',
    templateUrl: './tarefa-cadastro.component.html',
    styleUrls: ['./tarefa-cadastro.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class TarefaCadastroComponent implements OnInit {

    @Input() tarefa:any;
    @Input() index:any;
    @Input() publicacao:any;
    @Input() recursosDesdobramentoId:string;

    @ViewChild('executorDropdown') executorDropdown!: PessoaDropdownComponent;
    @ViewChild('revisorDropdown') revisorDropdown!: PessoaDropdownComponent;
    @ViewChild('assistenteDropdown') assistenteDropdown!: PessoaDropdownComponent;

    public loadTipoPrioridade: boolean = false;    
    public loadTipoTarefas: boolean = false;    
    public tipoPrioridade: any = [];
    public tipoTarefas: any = [];   
    public classificacoesAtividades: any =[]; 
    public subtiposAtividade: any =[];
    public equipeId: number;

    public tipoPrazo: any = [
        {
            id: 1,
            nome: "Dias Úteis"
        },
        {
            id: 2,
            nome: "Dias Corridos"
        }
    ]

    constructor(
        private tipoPrioridadeService: TipoPrioridadeService,
        private tipoTarefaService: TipoTarefaService,
        private caledarioService: CalendarioService,
        public classificacaoAtividadeService: ClassificacaoAtividadeService,
        public processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService 
    ) { }

    ngOnInit(): void {        
        this.buscarTipoPrioridade();
        this.buscarTipoTarefas();
        this.buscarClassificacoesAtividades();

        if(this.publicacao != undefined){
            this.tarefa.tipoPrazo = 1;

            let dataPublicacao = new Date(this.publicacao.dataPublicacao);

            let dataDivulgacao = new Date(this.publicacao.dataDivulgacao);

            let dataInicioPrazo;

            if(dataPublicacao<dataDivulgacao){
                dataInicioPrazo = dataPublicacao;
            }else{
                dataInicioPrazo = dataDivulgacao;
            }
            
            this.tarefa.dataPublicacao = this.publicacao.dataPublicacao;
            
            this.tarefa.dataInicioPrazo = new Date(dataInicioPrazo.getFullYear(), dataInicioPrazo.getMonth(), dataInicioPrazo.getDate(), 0, 0, 0);
            
        }

        this.obterResponsavelProcesso();
    }

    obterResponsavelProcesso() {
        if(this.tarefa.responsavelId == undefined && this.recursosDesdobramentoId != null){
            this.processosJudiciaisRecursoDesdobramentoService.obterResponsavelProcesso(this.recursosDesdobramentoId)
                .subscribe(res=>{
                    this.tarefa.responsavelId = res.data;
            })
        }
    }

    buscarTipoPrioridade() {
        this.loadTipoPrioridade = true;
        this.tipoPrioridadeService.getAllAsync().then(x => {
            this.loadTipoPrioridade = false;
            this.tipoPrioridade = x.data;
        });
    }

    buscarTipoTarefas() {
        this.loadTipoTarefas = true;
        this.tipoTarefaService.getAllAsync().then(x => {
            this.loadTipoTarefas = false;
            this.tipoTarefas = x.data;
        });
    }

    buscarClassificacoesAtividades() {
        this.classificacaoAtividadeService.getAllTipo(1)
            .subscribe(res=>{
                this.classificacoesAtividades = res.data;

                this.classificacaoPrioridadeChange();
            });
    }

    atualizaDataTarefa(){
        if(this.tarefa.tipoPrazo == 2){
            let data = new Date(this.tarefa.dataInicioPrazo);

            data.setDate(data.getDate() + this.tarefa.prazoQuantidade);
            this.formataData(data);
        }else{
            this.caledarioService.calcularDiaUtil(this.tarefa.prazoQuantidade, this.tarefa.dataInicioPrazo)
                .subscribe(res=>{
                    this.formataData(new Date(res.data));
                });
        }
    }

    formataData(data:Date){
        this.tarefa.dataTarefaComponent ={
            year: data.getFullYear(),
            month: data.getMonth() +1,
            day: data.getDate()
        }
    } 

    classificacaoPrioridadeChange(){
        
        if(this.tarefa.classificacaoAtividadeId != undefined && this.tarefa.classificacaoAtividadeId != null){
            let classificacao = this.classificacoesAtividades.find(c=>c.id == this.tarefa.classificacaoAtividadeId);
            
            if(classificacao != undefined){
                this.tarefa.prioridade = classificacao.tipoPrioridade;

                this.subtiposAtividade = classificacao.subtiposAtividade;
            }
        }
    }

    onChangeEquipe(equipe) {

        if (!equipe)
            return;

        this.executorDropdown.selecionarPessoa(equipe.executorPadraoId);
        this.revisorDropdown.selecionarPessoa(equipe.revisorPadraoId)
        this.assistenteDropdown.selecionarPessoa(equipe.assistentePadraoId)
    }
}
