<div class="row ocorrencia-detalhes align-items-center cursor-pointer" style="margin-bottom: 5px;" [ngClass]="atividade.concluido ? 'concluido' : atividade.estilo">
    <div class="col-11" (click)="onOpenModalComments(recursoDesdobramento, atividade)">
        <div class="row" >
            <div class="col-4 d-flex align-items-center" [ngClass]="'texto-'+atividade.estilo" >
                <!-- <i class="fas fa-plus-square btn-diversos" *ngIf="expandirAtividade(atividade) && !atividade.editarDetalhes" (click)="detalhesExibirOcultar(atividade)"></i>
                <i class="fas fa-minus btn-diversos" *ngIf="expandirAtividade(atividade) && atividade.editarDetalhes" (click)="detalhesExibirOcultar(atividade)"></i>                                 -->
                {{atividade.tipoObjeto}} #IDD: {{atividade.codExterno}}
            </div>
            <div class="col-4 p-0 d-flex align-items-center" [ngClass]="atividade.concluido ? 'concluido-risco' : 'texto-normal'" >
                <i class="fas fa-calendar-minus" *ngIf="atividade[dataField] == null"></i>
                <i class="fas fa-calendar-check" *ngIf="atividade[dataField] != null"></i>
                &nbsp;{{atividade[dataField] | dataextenso}}&nbsp;
                <prioridades-icone prioridade="{{ atividade.prioridade }}" prioridadeDescricao="{{ atividade.prioridadeDescricao }}" class="d-flex"></prioridades-icone>
            </div>                                        
            <div class="col-4" >
                <i class="fas fa-user"></i> <span [ngClass]="atividade.concluido ? 'concluido-risco' : 'texto-normal'">
                    <ng-container *ngIf="atividade.responsavelNome && atividade.responsavelNome.length > 30 && !isAbaAtividade">
                        <span [title]="atividade.responsavelNome" tooltip-placement="bottom" tooltip-append-to-body="true">
                            {{atividade.responsavelNome | slice:0:30}}...
                        </span>
                    </ng-container>
                    <ng-container *ngIf="(atividade.responsavelNome && atividade.responsavelNome.length <= 30) || isAbaAtividade">
                        {{atividade.responsavelNome}}
                    </ng-container>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 titulo-descricao" [ngClass]="atividade.concluido ? 'concluido-risco' : 'texto-normal'" >
                <ng-container *ngIf="atividade.tituloDescricao && atividade.tituloDescricao.length >= 150">
                    <span [title]="atividade.tituloDescricao" tooltip-placement="bottom" tooltip-append-to-body="true">
                        {{atividade.tituloDescricao | slice:0:150}} ...
                    </span>
                </ng-container>
                <ng-container *ngIf="atividade.tituloDescricao && atividade.tituloDescricao.length < 150">
                    {{atividade.tituloDescricao}}
                </ng-container>
            </div>
        </div>
    </div>                                                                      
                            
    <div class="col-1 text-right d-flex justify-content-between" *ngIf="!isAbaAtividade">                            
        <div *ngIf="!atividade.replicada && !atividade.concluido" (click)="onEditarAtividade(recursoDesdobramento, atividade)"><i class="fas fa-edit"></i></div>                           
       <div *ngIf="!atividade.replicada && !atividade.concluido" (click)="onExcluirAtividade(recursoDesdobramento, atividade)"><i class="fas fa-trash-alt"></i></div>
    </div>
    <div class="col-12" *ngIf="atividade.replicada">
        Replicado do processo: {{atividade.numeroProcessoOcorrenciasReplicadas}}
    </div>
</div>