import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Tipo } from 'src/app/enums/documento/tipo.enum';
import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { Ocorrencia } from 'src/app/models/operacionais/ocorrencia/ocorrencia';
import { OcorrenciaResponse } from 'src/app/models/operacionais/ocorrencia/ocorrencia-response';
import { OcorrenciaAtualizarStatus, OcorrenciaStatusRequest } from 'src/app/models/operacionais/ocorrencia/ocorrencia-status-request';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { SelectList } from 'src/app/models/select-list';
import { DividaPagamentosService } from 'src/app/services/divida-pagamentos.service';
import { DividaService } from 'src/app/services/divida.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
    selector: 'app-modal-aprovar-divida-desconto',
    templateUrl: './modal-aprovar-divida-desconto.component.html',
    styleUrls: ['./modal-aprovar-divida-desconto.component.css']
})
export class ModalAprovarDividaDescontoComponent implements OnInit,AfterContentChecked {
    modalRef: NgbModalRef;
    isIE10 = false;
    public isRTL: boolean;

    public ocorrencia: Ocorrencia;
    public processo: ProcessoOperacao;
    public dataPagamento: Date;

    public valorTotalPagamento: number = 0;
    public valorTotalPendente: number = 0;
    public valorTotalResidual: number = 0;
    public valorTotalComprovante: number = 0;

    public options: { readonly?: Boolean, previousTab?: String };

    constructor(
        private appService: AppService,
        public modalService: ModalService,
        public dividaService: DividaService,
        public dividaPagamentoService: DividaPagamentosService,
        public ocorrenciaService: OcorrenciaService,
        public toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        public utilsService: UtilsService,
        public documentoService: DocumentoService,
        private changeDetector: ChangeDetectorRef
    ) {

        this.isRTL = appService.isRTL;

    }

    ngAfterContentChecked() : void {
        this.changeDetector.detectChanges();
    }

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processo;
        this.ocorrencia = this.modalRef.componentInstance.ocorrencia;

        this.divida.ocorrenciaId = this.ocorrencia.id;
        this.divida.processoId = this.processo.id;
        this.divida.dividas = [];

        this.dividaPagamentoService.getAllByOcorrencia(this.ocorrencia.id).subscribe(res => {

            this.divida.dividas = res.data;

            this.divida.dividas.forEach(divida => {
                divida.dataRemessa = this.processo.dataRemessa;

                var pagamento = divida.divida.pagamentos.find(x => x.ocorrenciaId == this.ocorrencia.id);

                divida.divida.valorDesconto = (pagamento.valorPagamento * -1);

                this.calculoValorPendente(divida);
            });

           this.valorTotalPendente = this.calculoValorTotalPendente();
           this.valorTotalPagamento = this.calculoValorTotalPagamento();
           this.valorTotalComprovante = this.calculoValorComprovante();
        });


    }

    public divida: any = {
        ocorrenciaId: '',
        processoId: '',
        dividas: []
    };

    calculoValorTotalPagamento()
    {
        var valorTotal = 0;

        this.divida.dividas.forEach(divida => {
            divida.divida.pagamentos.forEach(pagamento => {
                valorTotal += pagamento.valorPagamento;
            });
        });

        return valorTotal;
    }

    calculoValorComprovante(){
       return this.divida.dividas[0].valorComprovante;
    }

    calculoValorTotalPendente()
    {
        var valorTotal = 0;

        this.divida.dividas.forEach(divida => {
            valorTotal += divida.divida.valorPendente;

        });

        return valorTotal;
    }



    calculoValorPendente(divida: any){

        var valorTotalPagamento = 0;

        divida.divida.pagamentos.forEach(pagamento => {
            valorTotalPagamento += pagamento.valorPagamento;
        });

        var valorDivida = divida.divida.valorAberto + divida.divida.juros + divida.divida.multa + divida.divida.valorCustasProtesto;

        divida.divida.valorPendente = valorDivida - valorTotalPagamento;

        return valorDivida - valorTotalPagamento;
    }


    calculoValorDivida(divida: any){

        var valorDivida = divida.divida.valorAberto + divida.divida.juros + divida.divida.multa + divida.divida.valorCustasProtesto;

        return valorDivida;
    }

    isShowAprovar() {
        return this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao || this.ocorrencia.status == TipoStatus.OcorrenciaPendenteCobrador;
    }

    isShowReprovar() {
        return this.ocorrencia.status == TipoStatus.OcorrenciaPendenteAprovacao || this.ocorrencia.status == TipoStatus.DividaPagamentoFinalizada;
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalContatoProcesso(processo);
    }

    aprovarOcorrencia() {
        this.updateStatus(TipoStatus.DividaPagamentoFinalizada);
    }

    reprovarOcorrencia() {
        this.updateStatus(TipoStatus.DividaPagamentoPendenteCobrador);
    }

    private updateStatus(status: TipoStatus) {

        let ocorrenciaAtualizarStatus: OcorrenciaAtualizarStatus = {
            id: this.ocorrencia.id,
            processoId: this.processo.id
        }
        
        let ocorrenciaStatusRequest = new OcorrenciaStatusRequest(status, [ocorrenciaAtualizarStatus]);

        this.ocorrenciaService.updateStatus(ocorrenciaStatusRequest)
            .subscribe((res: OcorrenciaResponse) => {
                this.spinner.hide();


                if(status == TipoStatus.DividaPagamentoPendenteCobrador){
                    this.dividaPagamentoService.deleteDesconto(this.ocorrencia.id,this.processo.id).subscribe(del =>{

                        this.toastrService.success("Ocorrência atualizada.", "Sucesso");
                    }, error =>{

                        this.toastrService.success("Não foi possível atualizar a ocorrência.", "Sucesso");
                    });
                }
                else if(status == TipoStatus.DividaPagamentoFinalizada){

                    this.dividaPagamentoService.updateDesconto(this.divida.dividas).subscribe(del =>{

                        this.toastrService.success("Ocorrência atualizada.", "Sucesso");
                    }, error =>{

                        this.toastrService.success("Não foi possível atualizar a ocorrência.", "Sucesso");
                    });
                }




                setTimeout(() => {
                    this.close(this.processo);
                }, 300);
            }, error => {
                this.spinner.hide();

                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível atualizar a ocorrência!', 'Atenção', { timeOut: 10000 });
                }
            });
    }


}
