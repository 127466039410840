

import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';
import { SituacaoProcessoService } from 'src/app/services/situacao-processo.service';

@Component({
  selector: 'app-modal-cadastro-situacao-processo',
  templateUrl: './modal-cadastro-situacao-processo.component.html',
  providers: [SituacaoProcessoService]
})
export class ModalCadastroSituacaoProcessoComponent implements OnInit {


  modalRef: NgbModalRef;
  public situacaoProcesso: any;
  public tiposProcessos = [];
  public situacaoReal = [];


  constructor(
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private appService: AppService,
    private situacaoProcessoService: SituacaoProcessoService,
  )
  {

  }

  popularNovaSituacaoProcesso() {
    this.situacaoProcesso = {
        nome: '',
        descricao: '',
        tipo: '',
        situacaoReal: '',
        relatorioGerencia:false,
    };
}



  ngOnInit(): void {
    this.popularNovaSituacaoProcesso();
    this.tiposProcessos = this.modalRef.componentInstance.camposComplementares.tiposProcessos;
    this.situacaoReal = this.modalRef.componentInstance.camposComplementares.situacaoRealProcessos;
  }

  cadastrar() {
    //if (this.validarCadastroPessoa(this.pessoa)) {
        this.add(this.situacaoProcesso);
    //}
  }

  add(situacaoProcesso) {
    this.spinner.show();
    this.toastrService.clear();

    // var pessoaModelCadastro = this.montarModelPessoaCadastro(pessoa);

    this.situacaoProcessoService.add(situacaoProcesso)
        .subscribe(res => {
            this.toastrService.success("Situacao de Processo cadastrada.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar a Situacao de Processo!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
  }


  

  close() {
    this.modalRef.close();
  }

  dismiss() {
      this.modalRef.dismiss();
  }


}
