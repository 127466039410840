<div *ngIf="!isAmigavel">
    <h4 class="font-weight-bold pt-3 mb-0">
        Estatísticas
        <div class="text-muted text-tiny mt-1">
            <small class="font-weight-normal">{{ dataAtual }}</small>
        </div>
    </h4>

    <mat-tab-group #tabgroup [selectedIndex]="0">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="d-flex">Publicações</div>
            </ng-template>
            <div class="filtro-header mt-2">
                <div class="p-3 filtro-header-titulo d-flex justify-content-between" (click)="filtroOn = !filtroOn">
                    <span>Filtros</span>
                    <span class="lnr lnr-chevron-up" *ngIf="filtroOn"></span>
                    <span class="lnr lnr-chevron-down" *ngIf="!filtroOn"></span>
                </div>
                <div class="filtro-publicacoes p-3" *ngIf="filtroOn">
                    <div class="form-row align-items-center mb-3">

                        <div class="col-md-2 col-sm-6">
                            <label class="form-label">Data Inicial</label>
                            <div class="input-group">
                                <input (click)="dataInicialFiltro.toggle()" class="form-control"
                                    placeholder="dd/mm/aaaa" name="dataInicialFiltroComponent" ngbDatepicker
                                    placement="bottom-right" #dataInicialFiltro="ngbDatepicker"
                                    [(ngModel)]="dataInicial">
                                <div class="input-group-append">
                                    <button class="input-group-text" (click)="dataInicialFiltro.toggle()" type="button">
                                        <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6">
                            <label class="form-label">Data Final</label>
                            <div class="input-group">
                                <input (click)="dataFinalFiltro.toggle()" class="form-control" placeholder="dd/mm/aaaa"
                                    name="dataInicialFiltroComponent" ngbDatepicker placement="bottom-right"
                                    #dataFinalFiltro="ngbDatepicker" [(ngModel)]="dataFinal">
                                <div class="input-group-append">
                                    <button class="input-group-text" (click)="dataFinalFiltro.toggle()" type="button">
                                        <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <label class="form-label d-none d-md-block">&nbsp;</label>
                            <button type="button" class="btn btn-padrao btn-block" (click)="getAll()"><span
                                    class="fas fa-search"></span>&nbsp;&nbsp;Filtrar</button>
                        </div>
                        <!-- <div class="col-md-2 col-sm-4">
                    <label class="form-label d-none d-md-block">&nbsp;</label>
                    <button type="button" class="btn bg-light btn-block" (click)="dataInicial = null; dataFinal = null"><span
                            class="fas fa-search"></span>&nbsp;&nbsp;Limpar</button>
                </div> -->
                    </div>
                </div>
            </div>
            <div class="row m-2 mt-4">
                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!dayChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de publicações por dia</strong>
                        </h5>
                        <canvas baseChart [datasets]="dayChartData" [labels]="dayChartLabels"
                            [options]="dayChartOptions" [legend]="true" [colors]="barChartColors" chartType="bar">
                        </canvas>
                    </div>
                </div>

                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de publicações por estado</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="blueColors" chartType="doughnut">
                        </canvas>
                    </div>
                </div>

                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!respChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de publicações por responsável</strong>
                        </h5>
                        <canvas baseChart [datasets]="respChartData" [labels]="respChartLabels"
                            [options]="respChartOptions" [legend]="true" [colors]="barChartColors" chartType="line">
                        </canvas>
                    </div>
                </div>
                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de processos por estado</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="blueColors" chartType="pie">
                        </canvas>
                    </div>
                </div>

                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de testes</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="barChartColors" chartType="radar">
                        </canvas>
                    </div>
                </div>

                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!dayChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de processos por responsável</strong>
                        </h5>
                        <canvas baseChart [datasets]="dayChartData" [labels]="dayChartLabels"
                            [options]="dayChartOptions" [legend]="true" [colors]="barChartColors"
                            chartType="horizontalBar">
                        </canvas>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div class="d-flex">
                    <div>Atividades</div>
                </div>
            </ng-template>

            <div class="row m-2 mt-4">
                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!dayChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de atividades</strong>
                        </h5>
                        <canvas baseChart [datasets]="dayChartData" [labels]="dayChartLabels"
                            [options]="dayChartOptions" [legend]="true" [colors]="barChartColors"
                            chartType="horizontalBar">
                        </canvas>
                    </div>
                </div>
                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Título gráfico</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="blueColors" chartType="line">
                        </canvas>
                    </div>
                </div>
                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Título gráfico</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="blueColors" chartType="doughnut">
                        </canvas>
                    </div>
                </div>
                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!dayChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de tarefas</strong>
                        </h5>
                        <canvas baseChart [datasets]="dayChartData" [labels]="dayChartLabels"
                            [options]="dayChartOptions" [legend]="true" [colors]="barChartColors" chartType="polarArea">
                        </canvas>
                    </div>
                </div>

                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!respChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Total de publicações por responsável</strong>
                        </h5>
                        <canvas baseChart [datasets]="respChartData" [labels]="respChartLabels"
                            [options]="respChartOptions" [legend]="true" [colors]="barChartColors" chartType="line">
                        </canvas>
                    </div>
                </div>

                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Título gráfico</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="barChartColors" chartType="bar">
                        </canvas>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div class="d-flex">
                    <div>Acompanhamento por Ministro</div>
                </div>
            </ng-template>

            <div class="row m-2 mt-4">
                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!dayChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Titulo gráfico bar</strong>
                        </h5>
                        <canvas baseChart [datasets]="dayChartData" [labels]="dayChartLabels"
                            [options]="dayChartOptions" [legend]="true" [colors]="barChartColors" chartType="bar">
                        </canvas>
                    </div>
                </div>

                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Titulo gráfico doughnut</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="blueColors" chartType="doughnut">
                        </canvas>
                    </div>
                </div>

                <div class="col-4">
                    <div class="d-block card-chart" *ngIf="!!respChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Titulo gráfico line</strong>
                        </h5>
                        <canvas baseChart [datasets]="respChartData" [labels]="respChartLabels"
                            [options]="respChartOptions" [legend]="true" [colors]="barChartColors" chartType="line">
                        </canvas>
                    </div>
                </div>
                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Titulo gráfico pie</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="blueColors" chartType="pie">
                        </canvas>
                    </div>
                </div>

                <div class="col-4 mt-4">
                    <div class="d-block card-chart" *ngIf="!!stateChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Titulo gráfico radar</strong>
                        </h5>
                        <canvas baseChart [datasets]="stateChartData" [labels]="stateChartLabels"
                            [options]="stateChartOptions" [legend]="true" [colors]="barChartColors" chartType="radar">
                        </canvas>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div class="d-flex">
                    <div>Atendimentos</div>
                </div>
            </ng-template>

            <div class="row m-2 mt-4">
                <div class="col-4 mt-2">
                    <div class="d-block same-margin border-with-radius card-chart">
                        <h5 class="m-0 ml-3 mb-2 text-to-left">
                            Total de Atendimentos: <strong>{{totalAtendimentos}}</strong>
                        </h5>
                    </div>

                    <div class="d-block same-margin border-with-radius card-chart">
                        <h5 class="m-0 ml-3 mb-2 text-to-left">
                            Ativos: <strong>{{totalAtendimentosNaoEncerrados}}</strong>
                        </h5>
                    </div>

                    <div class="d-block border-with-radius card-chart">
                        <h5 class="m-0 ml-3 mb-2 text-to-left">
                            Encerrados: <strong>{{totalAtendimentosEncerrados}}</strong>
                        </h5>
                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="d-block card-chart" *ngIf="!!atendimentoPorClienteChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Atendimentos por Cliente</strong>
                        </h5>
                        <canvas baseChart [datasets]="atendimentoPorClienteChartData" [labels]="atendimentoPorClienteChartLabels"
                            [options]="atendimentoPorClienteChartOptions" [legend]="true" [colors]="barChartColors" chartType="horizontalBar">
                        </canvas>
                    </div>
                </div>
        
                <div class="col-4 mt-2">
                    <div class="d-block card-chart" *ngIf="!!atendimentoNovoXEncerradoChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Atendimentos Novos X Encerrados</strong>
                        </h5>
                        <canvas baseChart [datasets]="atendimentoNovoXEncerradoChartData" [labels]="atendimentoNovoXEncerradoChartLabels"
                            [options]="atendimentoNovoXEncerradoChartOptions" [legend]="true" [colors]="blueColors" chartType="pie">
                        </canvas>
                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="d-block card-chart">
                        <h5 class="m-0 ml-3 mb-2 align-items-center">
                            <strong>Filtros</strong>
                        </h5>
                        <div class="col-12 mb-3">
                            <label class="form-label">Cliente</label>
                            <pessoa-dropdown [(pessoaId)]="clientes" [selecionaMultiplos]="false"></pessoa-dropdown>
                        </div>

                        <div class="col-12 mb-3">
                            <label class="form-label">Status</label>
                            <ng-select [items]="atendimentoStatus" bindLabel="status" bindValue="id" placeholder="Selecione um Status" [(ngModel)]="selectedIndex"></ng-select>
                        </div>

                        <div class="col-12 mb-3">
                            <label class="form-label">Responsável</label>
                            <pessoa-dropdown [(pessoaId)]="responsaveis" [selecionaMultiplos]="false"></pessoa-dropdown>
                        </div>

                        <div class="col-12 mb-2">
                            <button type="button" class="btn btn-padrao btn-block" (click)="filtrar()"><span
                                class="fas fa-search"></span>Filtrar</button>
                        </div>
                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="d-block card-chart" *ngIf="!!atendimentoEncerradoPorSituacaoEncerramentoChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Atendimento Encerrados por Situação de Encerramento</strong>
                        </h5>
                        <canvas baseChart [datasets]="atendimentoEncerradoPorSituacaoEncerramentoChartData" [labels]="atendimentoEncerradoPorSituacaoEncerramentoChartLabels"
                            [options]="atendimentoEncerradoPorSituacaoEncerramentoChartOptions" [legend]="true" chartType="doughnut">
                        </canvas>
                    </div>
                </div>
                        
                <div class="col-4 mt-2">
                    <div class="d-block card-chart" *ngIf="!!atendimentoPorResponsavelChartData.length">
                        <h5 class="m-0 ml-3 mb-2">
                            <strong>Atendimento por Responsavel</strong>
                        </h5>
                        <canvas baseChart [datasets]="atendimentoPorResponsavelChartData" [labels]="atendimentoPorResponsavelChartLabels"
                            [options]="atendimentoPorResponsavelChartOptions" [legend]="true" chartType="line">
                        </canvas>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div *ngIf="isAmigavel">
    <div class="row">
        <div class="col-sm-6 col-xl-6">
            <app-chart-ultimos-acessos-clientes></app-chart-ultimos-acessos-clientes>
        </div>

        <div class="col-sm-6 col-xl-6">
            <app-chart-clientes-nao-enviam-borderos></app-chart-clientes-nao-enviam-borderos>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6 col-xl-6">
            <app-chart-borderos-cadastrados></app-chart-borderos-cadastrados>
        </div>

        <div class="col-sm-6 col-xl-6">
            <app-chart-documentos-anexados></app-chart-documentos-anexados>
        </div>
    </div>
</div>