import { Component, ViewEncapsulation, AfterViewChecked, ViewChild, ElementRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput } from '@fullcalendar/angular';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { ModalDetalheComponent } from './modal-detalhe/modal-detalhe.component';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoStatusAtividade } from 'src/app/enums/atividade/tipo-status-atividade.enum';

@Component({
    selector: 'app-agenda-dashboard',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgendaDashboardComponent implements OnInit {

    eventGuid: number = 0;
    calendarVisible = false;
    eventos: EventInput[] = [];
    public filter: any = {
        asc: false,
        campoSort: 'dataCriacao',
        quantidade: 200,
        pagina: 0
    }

    public options = [
      {
          label: 'Hoje',
          id: 'H'
      },
      {
          label: 'Esta semana',
          id: 'ES'
      },
      {
          label: 'Este mês',
          id: 'EM'
      },
      {
          label: 'Últimos 7 dias',
          id: '7'
      },
      {
          label: 'Últimas 30 dias',
          id: '30'
      },
      {
          label: 'Próximos 3 dias',
          id: '3'
      },
      {
          label: 'Próxima semana',
          id: 'PS'
      },
      {
          label: 'Próximo mês',
          id: 'PM'
      },
  ];
    public atividadesOriginal = [];
    public exibirFiltro: boolean = false;
    public dataInicialFiltroComponent: any;
    public tarefas: any;
    public tipoAtividade: string;
    public dataFinalFiltroComponent: any;
    public tarefasOriginal = [];
    public tarefasExibir: any;
    public tiposDeVinculoSelecionados: string[] = [];
    public filtroRevisaoAtivo: boolean = false;
    public loadAtividadesDoProcesso: boolean = false;
    public quantidadeAtividadesNaoConcluida: number = 0;
    public quantidadeAtividadesConcluida: number = 0;
    public quantidadeAtividadesEmAtraso: number = 0;
    public quantidadeTarefas: number = 0;
    public quantidadePrazo: number = 0;
    public quantidadeEvento: number = 0;
    public quantidadeAudiencia: number = 0;
    public quantidadeHistorico: number = 0;
    public tiposSelecionados: number[] = [];
    selectedTagFilter;
    public barChartData: ChartDataSets[] = [
      {
          data: [this.quantidadeTarefas, this.quantidadeEvento, this.quantidadePrazo, this.quantidadeAudiencia],
      }
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            labels: {
                color: 'rgb(255, 99, 132)'
            }
        }
    }
};
public barChartLabels: Label[] = ['Tarefa', 'Evento', 'Prazo', 'Audiência'];
public barChartType: ChartType = 'horizontalBar';
public barChartLegend = false;
public barChartPlugins = [];

    calendarOptions: CalendarOptions

    dataHeaderFilter = [];
    filtersAppliedHeader = [];

    dataHeaderFilter2 = [];

    constructor(private ngbModal: NgbModal,
        private spinner: NgxSpinnerService,
        private processosJudiciaisService: ProcessosJudiciaisService,
        public toastrService: ToastrService,
        private router: Router) {
    }
    ngOnInit(): void {
        this.limparFiltros();
        this.buscarTarefaDoUsuario();

        this.drawCalendar();
    }
    adicionarRemoverFiltroTipoVinculo(tipoDeVinculo: string,forcedRemove : boolean = false): boolean {

      const index = this.tiposDeVinculoSelecionados.find(x => x === tipoDeVinculo);

      if (index) {

          this.tiposDeVinculoSelecionados = this.tiposDeVinculoSelecionados.filter(x => x !== tipoDeVinculo);
          this.removeFilterApplied(tipoDeVinculo, 'vinculo');
          this.quantidades();
          return false;
      }
      if(!forcedRemove)
      this.tiposDeVinculoSelecionados.push(tipoDeVinculo);

      this.quantidades();
      return true;
  }
  adicionarRemoverFiltroTipo(tipo: number, forcedRemove : boolean = false): boolean {

    const index = this.tiposSelecionados.find(x => x === tipo);
    console.log("this", this.tiposSelecionados)
    if (index) {

        this.tiposSelecionados = this.tiposSelecionados.filter(x => x !== tipo);
        this.removeFilterApplied(tipo, 'tipo');
        this.quantidades();
        return false;
    }
    if(!forcedRemove)
    this.tiposSelecionados.push(tipo);

    this.quantidades();
    return true;
}
  removeFilterApplied(id, tipo) {
    this.filtersAppliedHeader = this.filtersAppliedHeader.filter(f => f.id !== id)

    if(tipo === 'tipo') {
        this.adicionarRemoverFiltroTipo(id,true);
    }
    if(tipo === 'vinculo') {
        this.adicionarRemoverFiltroTipoVinculo(id,true);
    }
    localStorage.setItem('area-trabalho-filters-applied-header', JSON.stringify(this.filtersAppliedHeader));
    this.applyFilterHeader('fromDelete');
}


    filtrarPorTipoVinculo(tipoDeVinculo: string, label: string): void {

      if(!this.adicionarRemoverFiltroTipoVinculo(tipoDeVinculo)) return;

      if (this.tiposDeVinculoSelecionados.length == 0) {
          // this.buscarTarefaDoUsuario();
          return;
      }

      this.spinner.show();

      this.applyFilterHeader(tipoDeVinculo, label, 'vinculo');

      this.quantidades();
      this.MapearDadosAgenda(this.tarefasExibir);

      this.spinner.hide();
  }


    formatarData(data) {

      const date = new Date(data);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }
    onSearchChange(texto: string) {

      this.spinner.show();

      if (texto) {

          this.tarefasExibir = this.tarefasOriginal.filter(x =>
              (x.tituloDescricao && x.tituloDescricao.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
              || (x.tipoObjeto && x.tipoObjeto.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
              || (x.classificacaoAtividadeNome && x.classificacaoAtividadeNome.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
              || (x.classificacaoSubtipoAtividadeNome && x.classificacaoSubtipoAtividadeNome.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
              || (x.tipoStatusAtividadeNome && x.tipoStatusAtividadeNome.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
              || (x.dataTarefa && this.formatarData(x.dataTarefa).includes(texto))
              || (x.dataLimite && this.formatarData(x.dataLimite).includes(texto))
              || (x.dataRevisao && this.formatarData(x.dataRevisao).includes(texto))
              || (x.processoJudicialNumero && x.processoJudicialNumero.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
              || (x.processoJudicialPasta && x.processoJudicialPasta.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
              || (x.atuacao && x.atuacao.toLowerCase().indexOf(texto.toLocaleLowerCase()) !== -1)
          );
      } else {

          this.tarefasExibir = this.tarefasOriginal;
      }

      this.quantidades();
      this.MapearDadosAgenda(this.tarefasExibir);
      this.spinner.hide();
  }


    currentEvents: EventApi[] = [];

    handleDateSelect(selectInfo: DateSelectArg) {
        //console.log(selectInfo);
    }

    limparBusca() {
      this.tarefas = undefined;
      this.filter = {
        asc: false,
        campoSort: 'dataCriacao',
        quantidade: 1000,
        pagina: 0
    };
  }
    aplicarFiltros() {
      this.exibirFiltro = false;
      this.limparBusca();

      if (this.dataInicialFiltroComponent != null) {
          this.filter.dataInicio = new Date(this.dataInicialFiltroComponent.year, this.dataInicialFiltroComponent.month - 1, this.dataInicialFiltroComponent.day);
      }

      if (this.dataFinalFiltroComponent != null) {
          this.filter.dataFim = new Date(this.dataFinalFiltroComponent.year, this.dataFinalFiltroComponent.month - 1, this.dataFinalFiltroComponent.day);
      }

      this.buscarTarefaDoUsuario();
    }
    filtrarAtividadesPorStatus(field, label = null) {
      let tarefasFiltradas = this.tarefasOriginal;
      const dataAtual = new Date().toISOString();

      switch (field) {
          case 'atividadesEmDia':
              tarefasFiltradas = this.filtrarAtividadesEmDia();
              break;
          case 'emAtrasoDataRevisao':
              tarefasFiltradas = this.filtrarAtrasoDataRevisao();
              break;
          case 'emAtrasoDataLimite':
              tarefasFiltradas = this.filtrarAtrasoDataLimite();
              break;
      }

      if (!label) return tarefasFiltradas.length;

      this.applyFilterHeader(field, label, 'date');
  }
  filtrarAtividadesEmDia(){
    return this.tarefasOriginal.filter(item => (item.corAlertaDataLimite != 'badge-danger' || item.hasOwnProperty('dataRevisao') && item.corAlertaDataRevisao != 'badge-danger'));
}

filtrarAtrasoDataRevisao(){
    return this.tarefasOriginal.filter(item => item.hasOwnProperty('dataRevisao') && item.corAlertaDataRevisao == 'badge-danger' && item.tipoStatusAtividade < '3');
}

filtrarAtrasoDataLimite(){
    return this.tarefasOriginal.filter(item => item.corAlertaDataLimite == 'badge-danger' && item.tipoStatusAtividade < '6');
}
  applyFilterHeader(id?, label?, typeFilter?) {
    const filterStorage = JSON.parse(localStorage.getItem('area-trabalho-filters-applied-header'));
    this.filtersAppliedHeader = id !== 'fromDelete' && filterStorage ? filterStorage : this.filtersAppliedHeader;

    const alreadyExist =  this.filtersAppliedHeader.some(item => item.id === id && item.typeFilter === typeFilter);
    if(id && label && !alreadyExist) {
        this.filtersAppliedHeader.push({id, label, typeFilter});

        localStorage.setItem('area-trabalho-filters-applied-header', JSON.stringify(this.filtersAppliedHeader));
    }

    if(!this.filtersAppliedHeader.length) {
        this.tarefasExibir =  this.tarefasOriginal;
    }else {
        this.executeFilters();
    }
    this.MapearDadosAgenda(this.tarefasExibir);

}
executeFilters() {
  /** filtro por situação */
  const ids = this.filtersAppliedHeader.filter(item => item.typeFilter === 'status').map(item => item.id );
  if(ids.length) {
      this.tarefasExibir = this.tarefasOriginal.filter(original => !!ids.includes(original.tipoStatusAtividade));
  } else {
      this.tarefasExibir = this.tarefasOriginal;
  }

  /** filtro por data */
  const situacoes = this.filtersAppliedHeader.filter(item => item.typeFilter === 'date').map(item => item.id );
  const dataAtual = new Date().toISOString();

  if(situacoes.length) {
      let arr = [];
      situacoes.forEach(field => {
          switch (field) {
              case 'atividadesEmDia':
                  arr = arr.concat(this.tarefasExibir.filter(item => (dataAtual < item.dataLimite || item.hasOwnProperty('dataRevisao') && dataAtual < item.dataRevisao)));
                  break;
              case 'emAtrasoDataRevisao':
                  arr = arr.concat(this.tarefasExibir.filter(item => item.hasOwnProperty('dataRevisao') && dataAtual >= item.dataRevisao && item.tipoStatusAtividade < '3'));
                  break;
              case 'emAtrasoDataLimite':
                  arr = arr.concat(this.tarefasExibir.filter(item => dataAtual >= item.dataLimite && item.tipoStatusAtividade < '6'));
                  break;
          }
      });
      this.tarefasExibir = arr;
  }

  /** filtro por tipo de vinculo na atividade */
  const vinculos = this.filtersAppliedHeader.filter(item => item.typeFilter === 'vinculo');
  if(vinculos.length) {
      let arr = [];

      vinculos.forEach(field => {
          arr = arr.concat(this.tarefasOriginal.filter(c => this.tiposDeVinculoSelecionados.includes(field.id) && c[`is${field.label}`]));
      });
      this.tarefasExibir = arr;
  }

  /** filtro por tipo */
  const tipos = this.filtersAppliedHeader.filter(item => item.typeFilter === 'tipo').map(item => item.id );;
  if(!!tipos.length) {
      this.tarefasExibir = this.tarefasOriginal.filter(original => !!tipos.includes(original.tipoOcorrencia));
  }
}


  filtrarPorSituacao(tipoStatusAtividade, label = null) {
    /** quando não tem label, retorna apenas a quantidade de items do tipoStatusAtividade*/
    if (!label) return this.tarefasOriginal.filter(item => item.tipoStatusAtividade == tipoStatusAtividade).length;
    /** */

    this.applyFilterHeader(tipoStatusAtividade, label, 'status');
}


    dataHeaders() {
      this.dataHeaderFilter2 = [
          {
              onFilter: () => this.filtrarAtividadesPorStatus('atividadesEmDia', 'Atividades em dia'),
              value: this.filtrarAtividadesPorStatus('atividadesEmDia', null),
              label: 'Atividades em dia'
          },
          {
              onFilter: () => this.filtrarAtividadesPorStatus('emAtrasoDataRevisao', 'Data revisão atrasado'),
              value: this.filtrarAtividadesPorStatus('emAtrasoDataRevisao', null),
              label: 'Data revisão atrasado',
              showAlert: true
          },
          {
              onFilter: () => this.filtrarAtividadesPorStatus('emAtrasoDataLimite', 'Data limite atrasado'),
              value: this.filtrarAtividadesPorStatus('emAtrasoDataLimite', null),
              label: 'Data limite atrasado',
              showAlert: true
          },
      ];

      this.dataHeaderFilter = [
          {
              onFilter: () => this.filtrarPorSituacao(1, 'Não iniciada'),
              value: this.filtrarPorSituacao(1),
              label: 'Não iniciada'
          },
          {
              onFilter: () => this.filtrarPorSituacao(2, 'Em execução'),
              value: this.filtrarPorSituacao(2),
              label: 'Em execução'
          },
          {
              onFilter: () => this.filtrarPorSituacao(3, 'Em revisão'),
              value: this.filtrarPorSituacao(3),
              label: 'Em revisão'
          },
          {
              onFilter: () => this.filtrarPorSituacao(4, 'Devolvida para ajustes'),
              value: this.filtrarPorSituacao(4),
              label: 'Devolvida para ajustes'
          },
          {
              onFilter: () => this.filtrarPorSituacao(5, 'Revisado e liberado'),
              value: this.filtrarPorSituacao(5),
              label: 'Revisado e liberado'
          },
          {
              onFilter: () => this.filtrarPorSituacao(7, 'Atividades concluídas'),
              value: this.filtrarPorSituacao(7),
              label: 'Atividades concluídas'
          },
      ]
  }

    handleEventClick(clickInfo: EventClickArg) {

        let numeroProcesso: string;
        console.log("processoJudicialId =>" + clickInfo.event._def.extendedProps.processoJudicialId)
        this.processosJudiciaisService.getById(clickInfo.event._def.extendedProps.processoJudicialId).subscribe(processo => {
            numeroProcesso = processo.data.numero;

            this.handleModal(clickInfo, numeroProcesso);
        });
    }

    handleModal(clickInfo, numeroProcesso) {

        var data = this.tarefasOriginal.filter(x => x.id == clickInfo.event.id)[0];

        const modalRef = this.ngbModal.open(ModalDetalheComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.id = data.id;
        modalRef.componentInstance.tipoOcorrencia = clickInfo.event._def.extendedProps.tipoOcorrencia;
        modalRef.componentInstance.descricao = clickInfo.event.title;
        modalRef.componentInstance.tipoObjeto = clickInfo.event._def.extendedProps.tipoObjeto;
        modalRef.componentInstance.tipoStatusAtividade = clickInfo.event._def.extendedProps.tipoStatusAtividade;
        modalRef.componentInstance.responsavelNome = clickInfo.event._def.extendedProps.responsavelNome;
        modalRef.componentInstance.dataInicioPrazo = clickInfo.event._def.extendedProps.dataInicioPrazo;
        modalRef.componentInstance.estilo = clickInfo.event._def.extendedProps.estilo;
        modalRef.componentInstance.corAlertaData = clickInfo.event._def.extendedProps.corAlertaData;
        modalRef.componentInstance.numeroProcesso = numeroProcesso;
        modalRef.componentInstance.item = data;

        modalRef.result.then(result => {

            this.spinner.hide();

        }).catch(error => { });
    }

    handleEvents(events: EventApi[]) {

        this.currentEvents = events;
    }

    drawCalendar() {

        this.calendarOptions = {

            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            themeSystem: 'bootstrap5',
            locale: ptBrLocale,
            initialView: 'dayGridMonth',
            initialEvents: this.eventos,
            weekends: true,
            weekNumbers: true,
            weekNumberFormat: { week: 'numeric' },
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            select: this.handleDateSelect.bind(this),
            eventClick: this.handleEventClick.bind(this),
            eventsSet: this.handleEvents.bind(this),
            displayEventTime: false
        };
    }
    limparFiltros() {
      this.selectedTagFilter = null;
      this.exibirFiltro = false;
      this.dataFinalFiltroComponent = null;
      this.dataInicialFiltroComponent = null;
      this.filter.status = null;
      this.limparBusca();
      this.filtersAppliedHeader = [];
      this.tiposSelecionados = [];
      this.tiposDeVinculoSelecionados = [];
      localStorage.removeItem('area-trabalho-filters-applied-header');
      this.buscarTarefaDoUsuario();
  }

    goToGridView() {
      sessionStorage.setItem('openMenu', JSON.stringify(false));
      //this.router.navigate(['/dashboard/atividades-usuario']);
      window.location.href='/dashboard/atividades-usuario';
    }
    gerenciarSelecaoFiltroTipo(tipo: number): string {

      return this.tiposSelecionados.find(x => x === tipo) ? 'fas fa-check-square' : 'far fa-square'
    }

    gerenciarSelecaoFiltroTipoVinculo(tipoDeVinculo: string): string {


      return this.tiposDeVinculoSelecionados.find(x => x === tipoDeVinculo) ? 'fas fa-check-square' : 'far fa-square'
    }
    filtrarPorTipo(tipo: number, label: string): void {
      if(!this.adicionarRemoverFiltroTipo(tipo)) return;

      if (this.tiposSelecionados.length == 0) {
          // this.buscarTarefaDoUsuario();
          return;
      }

      this.spinner.show();

      this.applyFilterHeader(tipo, label, 'tipo');

      this.quantidades();
      this.MapearDadosAgenda(this.tarefasExibir);

      this.spinner.hide();
  }


    buscarTarefaDoUsuario() {

      this.filtroRevisaoAtivo = false;
      this.exibirFiltro = false;

      this.spinner.show();
      this.toastrService.clear();
      this.loadAtividadesDoProcesso = true;
      this.processosJudiciaisService.getTarefasDoUsuarioFiltro(this.filter)
          .subscribe(res => {
             res.data = res.data.filter(tarefa => tarefa.tipoStatusAtividade !== TipoStatusAtividade.Encerrado);
              if (this.tarefas == undefined) {
                  this.tarefas = res.data;
                  this.tarefasOriginal = res.data;
              } else {
                  let inseridos = 0;
                  res.data.forEach(item=>{
                      let filter = this.tarefas.filter(c=>c.id == item.id);

                      if(filter.length == 0){
                          inseridos++;
                          this.tarefas.push(item);
                          this.tarefasOriginal.push(item);
                      }
                  });

                  //if(inseridos == 0){
                  //    this.toastrService.error("Não foram localizados mais dados!");
                  //}
                  // this.tarefas = this.tarefas.concat(res.data);
                  // this.tarefasOriginal = this.tarefas.concat(res.data);
              }


              this.spinner.hide();


              this.dataHeaders();

              this.filter.pagina += 1;
              this.tarefas.forEach(c => {

                  c.editarDetalhes = false;

                  if (c.responsavelNome && c.responsavelNome.length > 17)
                      c.responsavelNome = c.responsavelNome.substring(0, 14) + '...';

              });

              this.tarefasOriginal = this.tarefas;
              this.filtrarAtividades();
              this.tarefasExibir = this.tarefas;
              this.quantidades();
              this.spinner.hide();
              this.applyFilterHeader();

          }, error => {
              if (error && error.errors) {
                  this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
              }
              else {
                  this.toastrService.error('Não foi possível carregar os dados!', 'Atenção', { timeOut: 10000 });
              }
              this.spinner.hide();
          });

      this.loadAtividadesDoProcesso = false;
  }
  MapearDadosAgenda(dadosProcessos: any){
    let data: EventInput[] = dadosProcessos.map(x => {
      if (x.tipoOcorrencia == 1) {
          x.dataOcorrencia = x.dataTarefa;
      } else if (x.tipoOcorrencia == 3 || x.tipoOcorrencia == 5) {
          x.dataOcorrencia = x.dataHoraInicial;
      } else if (x.tipoOcorrencia == 2) {
          x.dataOcorrencia = x.dataHistorico;
      } else {
          x.dataOcorrencia = x.dataLimite;

          if (x.tipoOcorrencia == 4) {
              x.quantidadeTarefasEventosVinculados = x.quantidadeTarefasEventosVinculados;
          }
      }

      x.tituloDescricao = x.hasOwnProperty('classificacaoSubtipoAtividadeNome') && x.classificacaoSubtipoAtividadeNome ? x.classificacaoSubtipoAtividadeNome : x.titulo;

      const today = new Date(x.dataOcorrencia);
      const y = today.getFullYear();
      const m = today.getMonth();
      const d = today.getDate();
      const h = today.getHours();
      const min = today.getMinutes();

      return {
          id: x.id,
          title: `${x.tituloDescricao}`,
          start: new Date(y, m, d, h, min),
          processoId: x.processoJudicialId,
          tipoOcorrencia: x.tipoOcorrencia,
          tipoObjeto: x.tipoObjeto,
          responsavelNome: x.responsavelNome,
          dataInicioPrazo: x.dataInicioPrazo,
          estilo: x.estilo,
          corAlertaData: x.corAlertaData,
          className: [this.obterClasseEvento(x.tipoOcorrencia), 'evento-all'],
          processoJudicialId: x.processoJudicialId
      }
  });

  this.calendarOptions.events = data;
  this.eventos = data;
  this.calendarVisible = true;
  }
  filtrarAtividades(field: string = null, isGet = false) {
    if (!field) return;
    const filter = this.tarefasOriginal.filter(t => t[field]);
    if (isGet) return filter.length;
    this.spinner.show();
    this.tarefasExibir = filter;
    this.MapearDadosAgenda(this.tarefasExibir);
    this.spinner.hide();
}

filtrarNaoIniciada() {
  this.spinner.show();
  this.tarefasExibir = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 1);
  this.quantidades();
  this.MapearDadosAgenda(this.tarefasExibir);
  this.spinner.hide();
}

filtrarEmExecucao() {
  this.spinner.show();
  this.tarefasExibir = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 2);
  this.quantidades();
  this.MapearDadosAgenda(this.tarefasExibir);
  this.spinner.hide();
}

filtrarEmRevisao() {
  this.spinner.show();
  this.tarefasExibir = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 3);
  this.quantidades();
  this.MapearDadosAgenda(this.tarefasExibir);
  this.spinner.hide();
}

filtrarDevolvidaParaAjustes() {
  this.spinner.show();
  this.tarefasExibir = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 4);
  this.quantidades();
  this.MapearDadosAgenda(this.tarefasExibir);
  this.spinner.hide();
}

filtrarRevisadoLiberado() {
  this.spinner.show();
  this.tarefasExibir = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 5);
  this.quantidades();
  this.MapearDadosAgenda(this.tarefasExibir);
  this.spinner.hide();
}

filtrarConcluido() {
  this.spinner.show();
  this.tarefasExibir = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 6 || x.tipoStatusAtividade == 7);
  this.quantidades();
  this.MapearDadosAgenda(this.tarefasExibir);
  this.spinner.hide();

}

filtrarEncerrado() {
  this.spinner.show();
  this.tarefasExibir = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 8);
  this.quantidades();
  this.MapearDadosAgenda(this.tarefasExibir);
  this.spinner.hide();
}

quantidades() {
    this.quantidadeAtividadesNaoConcluida = this.tarefasOriginal.filter(x => !x.concluido && !x.emAtraso).length;
    this.quantidadeAtividadesConcluida = this.tarefasOriginal.filter(x => x.concluido).length;
    this.quantidadeAtividadesEmAtraso = this.tarefasOriginal.filter(x => !x.concluido && x.emAtraso).length;
    this.quantidadeTarefas = this.tarefasOriginal.filter(x => x.tipoOcorrencia == 1).length;
    this.quantidadeHistorico = this.tarefasOriginal.filter(x => x.tipoOcorrencia == 2).length;
    this.quantidadeEvento = this.tarefasOriginal.filter(x => x.tipoOcorrencia == 3).length;
    this.quantidadePrazo = this.tarefasOriginal.filter(x => x.tipoOcorrencia == 4).length;
    this.quantidadeAudiencia = this.tarefasOriginal.filter(x => x.tipoOcorrencia == 5).length;

    this.dataHeaderFilter[0].value = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 1).length;
    this.dataHeaderFilter[1].value = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 2).length;
    this.dataHeaderFilter[2].value = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 3).length;
    this.dataHeaderFilter[3].value = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 4).length;
    this.dataHeaderFilter[4].value = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 5).length;
    this.dataHeaderFilter[5].value = this.tarefasOriginal.filter(x => x.tipoStatusAtividade == 7).length;

    this.dataHeaderFilter2[0].value = this.filtrarAtividadesPorStatus('atividadesEmDia');
    this.dataHeaderFilter2[1].value = this.filtrarAtividadesPorStatus('emAtrasoDataRevisao');
    this.dataHeaderFilter2[2].value = this.filtrarAtividadesPorStatus('emAtrasoDataLimite');

    this.barChartData = [
        {
            data: [this.quantidadeTarefas, this.quantidadeEvento, this.quantidadePrazo, this.quantidadeAudiencia
                , this.quantidadeHistorico],
        }
    ];

}



    obterClasseEvento(tipoOcorrencia: any) {
        switch (tipoOcorrencia) {
            case 1:
                return 'evento-tarefa';
            case 2:
                return 'evento-historico';
            case 3:
                return 'evento-evento';
            case 4:
                return 'evento-prazo';
            case 5:
                return 'evento-audiencia';
            default:
                return '';
        }
    }
    changeDateFilterOption(param) {
      if (this.selectedTagFilter === param.id) {
          this.selectedTagFilter = null;
          this.dataInicialFiltroComponent = null;
          this.dataFinalFiltroComponent = null;
          return;
      }
      this.selectedTagFilter = param.id;
      const currentDate = new Date();
      switch (param.id) {
          case 'H':
              this.setDateComponent(currentDate, 'dataInicialFiltroComponent');
              this.setDateComponent(currentDate, 'dataFinalFiltroComponent');
              break;

          case '7':
              this.setDateComponent(currentDate, 'dataFinalFiltroComponent');
              this.setDateComponent(this.sumDaysOnDate(-7), 'dataInicialFiltroComponent');
              break;

          case '3':
              this.setDateComponent(currentDate, 'dataInicialFiltroComponent');
              this.setDateComponent(this.sumDaysOnDate(3), 'dataFinalFiltroComponent');
              break;

          case '30':
              this.setDateComponent(currentDate, 'dataFinalFiltroComponent');
              this.setDateComponent(this.sumDaysOnDate(-30), 'dataInicialFiltroComponent');
              break;

          case 'PM':
              this.setDateComponent(this.nextMonth(), 'dataInicialFiltroComponent');
              this.setDateComponent(this.nextMonth(false, 2), 'dataFinalFiltroComponent');
              break;

          case 'EM':
              this.setDateComponent(this.nextMonth(true, 0), 'dataInicialFiltroComponent');
              this.setDateComponent(this.nextMonth(false, 1), 'dataFinalFiltroComponent');
              break;

          case 'PS':
              this.setDateComponent(this.addWeeks(new Date(), 1), 'dataInicialFiltroComponent');
              this.setDateComponent(this.addWeeks(new Date(), 1, false), 'dataFinalFiltroComponent');
              break;

          case 'ES':
              this.setDateComponent(this.thisWeek(), 'dataInicialFiltroComponent');
              this.setDateComponent(this.thisWeek(false), 'dataFinalFiltroComponent');
              break;


      }
  }

  thisWeek(beginningWeek: boolean = true) {
      const date = new Date();
      date.setDate((date.getDate() + (beginningWeek ? 0 : 7)) - date.getDay());
      return date;
  }

  addWeeks(date: Date, weeks: number, beginningWeek: boolean = true) {
      date.setDate(((date.getDate() + (beginningWeek ? 7 : 14)) - date.getDay()) * weeks || 1);
      return date;
  }

  nextMonth(beginningMonth = true, qtdMonths: number = 1) {
      const date = new Date();
      date.setMonth(date.getMonth() + qtdMonths);
      date.setDate(beginningMonth ? 1 : 0);
      return date;
  }

  sumDaysOnDate(qtdDays: number, date: Date = new Date()) {
      date.setDate(date.getDate() + qtdDays);
      return date;
  }

  setDateComponent(date: Date, field: string) {
      const formatDate = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
      };
      this[field] = formatDate;
  }


}
