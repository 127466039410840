import { HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { ArquivoService } from "src/app/services/arquivo.service";
import { UtilsService } from "src/app/utils/utils.service";

export interface FileDownloadBackgound {
  id: number;
  name: string;
  success: boolean;
  error: boolean;
  done: boolean;
}

@Injectable({
  providedIn: "root",
})
export class FileDownloadBackgoundService {
  public showDownloadInBackgroud: BehaviorSubject<{
    show: boolean;
    files: Map<number, FileDownloadBackgound>;
  }> = new BehaviorSubject({ show: false, files: new Map() });

  private files: Map<number, FileDownloadBackgound> = new Map();

  public downloadProgress$ = new BehaviorSubject(new Map());
  private downloadProgressMap = new Map();

  constructor(
    private toastrService: ToastrService,
    private utilsService: UtilsService,
    private arquivoService: ArquivoService
  ) {}

  show({ arquivoId, file }) {
      
      this.files.set(file.id, {
      id: file.id,
      name: file.name,
      success: false,
      error: false,
      done: false,
    });

    this.showDownloadInBackgroud.next({ show: true, files: this.files });
    this.downloadProgressMap.set(file.id, {fileId: file.id, progress: 0});
    
    this.arquivoService.downloadArquivo(arquivoId).subscribe(
      (res) => {
        if (res.type === HttpEventType.DownloadProgress) {
          const progress = Math.round((100 * res.loaded) / res.total);

          this.downloadProgressMap.set(file.id, {fileId: file.id, progress});

          this.downloadProgress$.next(this.downloadProgressMap);

          if (progress === 100) {
            this.downloadErrorComplete(file, true);
          }
        }

        this.utilsService.renderDownload(res, file.name as string);
      },
      (error) => {
        if (error && error.errors)
          this.toastrService.error(error.errors[0], "Atenção", {
            timeOut: 10000,
          });
        else
          this.toastrService.error(
            "Não foi possível fazer o download do documento!",
            "Atenção",
            { timeOut: 10000 }
          );

        this.downloadErrorComplete(file, false);
      }
    );
  }

  private downloadErrorComplete(file, success: boolean) {
    this.files.forEach((f, i) => {
      if (f.id === file.id) {
        this.files.set(i, {
          ...f,
          error: !success,
          success,
          done: true,
        });
      }
    });

    this.showDownloadInBackgroud.next({ show: true, files: this.files });
  }

  hide() {
    this.files = new Map();
    this.showDownloadInBackgroud.next({ show: false, files: this.files });
  }
}
