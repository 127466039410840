import { ModalService } from 'src/app/services/shared/modal-service';
import { DividaPagamentosService } from '../../../../services/divida-pagamentos.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';

@Component({
  selector: 'app-modal-detalhamento-pagamento',
  templateUrl: './modal-detalhamento-pagamento-faturamento-extrato.component.html',
  styleUrls: ['./modal-detalhamento-pagamento-faturamento-extrato.component.css']
})
export class ModalDetalhamentoPagamentoFaturamentoExtratoComponent implements OnInit {

  modalRef: NgbModalRef;
  load:boolean = false;

  pagamentos:any = [];

  credorId:any;
  faturamentoId:any;
  tipo = 0;
  date:Date;
  title:String = ""

  constructor(
    private dividaPagamentoService: DividaPagamentosService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.tipo = this.modalRef.componentInstance.tipo;
    this.credorId = this.modalRef.componentInstance.credorId;
    this.date = this.modalRef.componentInstance.date;

    if(this.tipo == 0)
    {
        this.getPagamentosRetidoByCredor();
        this.title = "Pagamentos Retidos"
    }


    if(this.tipo == 1)
    {
       this.getPagamentosTaxaAdministrativaByCredor();
       this.title = "Taxas Administrativas"
    }

  }


  close() {
    this.modalRef.close();
  }

  dismiss() {
      this.modalRef.dismiss();
  }


  getPagamentosRetidoByCredor()
  {
    this.load = true;
      this.dividaPagamentoService.getAllRetidoByCredorAndDate(this.credorId,this.date).subscribe(x =>
        {
            this.pagamentos = x.data;
            this.load = false;
        });
  }

  getPagamentosTaxaAdministrativaByCredor()
  {
    this.load = true;
    this.dividaPagamentoService.getAllTaxaAdministrativaByCredorAndDate(this.credorId,this.date).subscribe(x =>
      {
          this.pagamentos = x.data;
          this.load = false;
      });
  }



  abrirModalProcesso(id)
  {
    var processo = new ProcessoOperacao();
    processo.id = id;

    this.modalService.abrirModalContatoProcesso(processo);
  }

  abrirModalPessoa(id)
  {
    var pessoa = {id:id}
    this.modalService.abrirModalCadastraPessoa(pessoa,true);
  }





}
