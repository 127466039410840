import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertsComponent implements OnInit {

  public tabs = [
    {
      title: 'Importantes',
      alerts: [
        {
          icon: 'star',
          title: 'Andamento Recebido',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 87,
          selected: false,
        },
        {
          icon: 'file',
          title: 'Publicação Recebido',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 44,
          selected: false,
        }
      ]
    },
    {
      title: 'Prazos',
      alerts: []
    },
    {
      title: 'Tarefas',
      alerts: []
    },
    {
      title: 'Evento',
      alerts: []
    },
    {
      title: 'Audiência',
      alerts: []
    },
    {
      title: 'Todos',
      alerts: []
    },
    {
      title: 'Publicações',
      selectedAll: false,
      alerts: [
        {
          icon: 'star',
          title: 'Andamento Publicações',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 97,
          selected: false,
        },
        {
          icon: 'file',
          title: 'Publicação Recebido',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 75,
          selected: false,
        }
      ]
    },
    {
      title: 'Andamentos',
      selectedAll: false,
      alerts: [
        {
          icon: 'star',
          title: 'Andamentos',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 10,
          selected: false,
        },
        {
          icon: 'file',
          title: 'Andamentos Recebidos',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 23,
          selected: false,
        }
      ]
    },
    {
      title: 'Processos',
      selectedAll: false,
      alerts: [
        {
          icon: 'star',
          title: 'Processos',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 2,
          selected: false,
        },
        {
          icon: 'file',
          title: 'Processos Recebidos',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 7,
          selected: false,
        },
        {
          icon: 'file',
          title: 'Processos Recebidos',
          subTitle: 'DISTRITO FEDERAL X Edineia Santos Dias e OUTRO - 1° Grau - Ação Recisória - Honorários Advocaticios',
          processNUmber: '0978909-78.2022.8.0000',
          info: '6 andamento(s) encontrado(s) - 4 importante(s)',
          id: 54,
          selected: false,
        }
      ]
    }
  ]

  public index: number = 0;
  public selectedIndex: number;
  public alreadyCalled: boolean = false;

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  selectedIndexChange(content, tabgroup: MatTabGroup, index: number) {
    if (!!this.qtdSelecteds && !this.alreadyCalled) {
      this.alreadyCalled = true;
      this.selectedIndex = tabgroup.selectedIndex;
      tabgroup.selectedIndex = this.index;
      return this.openDialogChangeTab(content, tabgroup);
    }
    this.alreadyCalled = false;
    return this.index = index;
  }

  get qtdSelecteds() {
    return this.tabs[this.index].alerts.filter(alert => alert.selected).length;
  }

  onSelectedAllAlerts() {
    this.tabs[this.index].alerts = this.tabs[this.index].alerts.map(alert => ({ ...alert, selected: this.tabs[this.index].selectedAll }));
  }

  onSelecAlert() {
    this.tabs[this.index].selectedAll = this.qtdSelecteds === this.tabs[this.index].alerts.length;
  }

  async openDialogChangeTab(content, tabgroup: MatTabGroup) {
    const ref = await this.dialog.open(content, { width: '700px' }).afterClosed().toPromise();
    this.alreadyCalled = false;
    if (!ref) return;
    this.tabs[this.index].alerts = this.tabs[this.index].alerts.map(alert => ({ ...alert, selected: false }));
    this.tabs[this.index].selectedAll = false;
    tabgroup.selectedIndex = this.selectedIndex;
  }

  async openDialogDelete(content) {
    const ref = await this.dialog.open(content, { width: '700px' }).afterClosed().toPromise();
    if (!ref) return;
    this.openSnack(`${this.qtdSelecteds} Alertas excluídos com sucesso!`);
    this.tabs[this.index].alerts = this.tabs[this.index].alerts.filter(alert => !alert.selected);
  }

  openSnack(msg: string) {
    this.snackBar.open(msg, null, { verticalPosition: 'top', panelClass: 'alert', duration: 3000 });
  }

  onDiscardAlert(id: number) {
    this.openSnack(`Alerta excluído com sucesso!`);
    this.tabs[this.index].alerts = this.tabs[this.index].alerts.filter(alert => alert.id !== id);
  }

}
