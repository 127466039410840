import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { DatePipe } from '@angular/common';
import { TipoAlertaService } from 'src/app/services/tipo-alerta.service';
import { CalendarioService } from 'src/app/services/calendario.service';
import { ClassificacaoAtividadeService } from 'src/app/services/classificacao-atividade.service';
import { TipoPrioridadeService } from 'src/app/services/tipo-prioridade.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { PessoaDropdownComponent } from '../pessoa-dropdown/pessoa-dropdown.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'prazo-cadastro',
    templateUrl: './prazo-cadastro.component.html',
    styleUrls: ['./prazo-cadastro.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class PrazoCadastroComponent implements OnInit {

    @Input() prazo: any;
    @Input() index: any;
    @Input() publicacao: any;
    @Input() recursosDesdobramentoId: string;

    @ViewChild('executorDropdown') executorDropdown!: PessoaDropdownComponent;
    @ViewChild('revisorDropdown') revisorDropdown!: PessoaDropdownComponent;
    @ViewChild('assistenteDropdown') assistenteDropdown!: PessoaDropdownComponent;

    public tipoAlerta: any = [];
    public tipoPrioridade: any = [];
    public tipoPrazo: any = [
        {
            id: 1,
            nome: "Dias Úteis"
        },
        {
            id: 2,
            nome: "Dias Corridos"
        }
    ]

    public classificacoesAtividades: any = [];
    public subtiposAtividade: any = [];
    public equipeId: number;

    public loadTipoAlerta: boolean = false;

    constructor(
        private datePipe: DatePipe,
        private tipoPrioridadeService: TipoPrioridadeService,
        private tipoAlertaService: TipoAlertaService,
        private caledarioService: CalendarioService,
        public classificacaoAtividadeService: ClassificacaoAtividadeService,
        public processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        public toastrService: ToastrService,
    ) { }

    ngOnInit(): void {

        this.buscarTipoAlertas();
        this.buscarTipoPrioridade();
        this.buscarClassificacoesAtividades();

        if (this.publicacao != undefined) {
            this.prazo.tipoPrazo = 1;

            let dataPublicacao = new Date(this.publicacao.dataPublicacao);

            let dataDivulgacao = new Date(this.publicacao.dataDivulgacao);

            let dataInicioPrazo;

            if (dataPublicacao < dataDivulgacao) {
                dataInicioPrazo = dataPublicacao;
            } else {
                dataInicioPrazo = dataDivulgacao;
            }

            this.prazo.dataPublicacao = this.publicacao.dataPublicacao;

            this.prazo.dataInicioPrazo = new Date(dataInicioPrazo.getFullYear(), dataInicioPrazo.getMonth(), dataInicioPrazo.getDate(), 0, 0, 0);
        }

        if (this.prazo.dataRevisao == undefined) {
            this.definirDataRevisao();
        }

        this.obterResponsavelProcesso();
    }

    obterResponsavelProcesso() {
        if (this.prazo.responsavelId == undefined && this.recursosDesdobramentoId != null) {
            this.processosJudiciaisRecursoDesdobramentoService.obterResponsavelProcesso(this.recursosDesdobramentoId)
                .subscribe(res => {
                    this.prazo.responsavelId = res.data;
                })
        }
    }

    buscarClassificacoesAtividades() {
        this.classificacaoAtividadeService.getAllTipo(2)
            .subscribe(res => {
                this.classificacoesAtividades = res.data;

                this.classificacaoPrioridadeChange();
            });
    }

    buscarTipoPrioridade() {
        this.tipoPrioridadeService.getAllAsync().then(x => {
            this.tipoPrioridade = x.data;
        });
    }

    formataData(data: Date) {
        this.prazo.dataLimiteComponent = {
            year: data.getFullYear(),
            month: data.getMonth() + 1,
            day: data.getDate()
        }

        this.definirDataRevisao();
    }

    formataDataRevisao(data: Date) {
        this.prazo.dataRevisaoComponent = {
            year: data.getFullYear(),
            month: data.getMonth() + 1,
            day: data.getDate()
        }

        this.calcularData = false;
    }

    atualizaDataLimite() {
        if (this.prazo.tipoPrazo == 2) {
            let data = new Date(this.prazo.dataInicioPrazo);

            data.setDate(data.getDate() + this.prazo.prazoQuantidade);
            this.formataData(data);
        } else {
            this.caledarioService.calcularDiaUtil(this.prazo.prazoQuantidade, this.prazo.dataInicioPrazo)
                .subscribe(res => {
                    this.formataData(new Date(res.data));
                });
        }
    }

    buscarTipoAlertas() {
        this.loadTipoAlerta = true;
        this.tipoAlertaService.getAllAsync().then(x => {
            this.loadTipoAlerta = false;
            this.tipoAlerta = x.data;
        });
    }

    adicionarEnvolvidoPrazo(prazo: any) {
        prazo.envolvidos.push({});
    }

    removerEnvolvidoPrazo(prazo: any, envolvidoIndex) {
        prazo.envolvidos.forEach((element, index) => {
            if (index == envolvidoIndex) {
                prazo.envolvidos.splice(index, 1);
            }
        });
    }

    public calcularData: boolean = false;

    definirDataRevisao() {

        if (this.prazo.dataLimiteComponent != undefined) {
            let dataLimite = new Date(this.prazo.dataLimiteComponent.year,
                this.prazo.dataLimiteComponent.month - 1,
                this.prazo.dataLimiteComponent.day);

            //dataLimite.setDate(dataLimite.getDate() - 3);

            this.calcularData = true;

            this.prazo.dataRevisaoComponent = null;

            this.toastrService.clear();

            this.caledarioService.calcularDiaUtilNegativo(-3, dataLimite)
                .subscribe(res => {                    
                    this.formataDataRevisao(new Date(res.data));
                },err=>{
                    this.calcularData = false;
                    this.toastrService.error('Não foi possível calcular data!', 'Atenção', { timeOut: 10000 });
                })
        }
    }

    classificacaoPrioridadeChange() {
        
        if (this.prazo.classificacaoAtividadeId != undefined && this.prazo.classificacaoAtividadeId != null) {
            let classificacao = this.classificacoesAtividades.find(c => c.id == this.prazo.classificacaoAtividadeId);

            if (classificacao != undefined) {
                this.prazo.tipoPrioridade = classificacao.tipoPrioridade;

                this.subtiposAtividade = classificacao.subtiposAtividade;
            }
        }
    }

    changeDate($event) {
        this.definirDataRevisao();
    }

    onChangeEquipe(equipe) {

        if (!equipe)
            return;

        this.executorDropdown.selecionarPessoa(equipe.executorPadraoId);
        this.revisorDropdown.selecionarPessoa(equipe.revisorPadraoId)
        this.assistenteDropdown.selecionarPessoa(equipe.assistentePadraoId)
    }
}
