<div class="row">
    <div class="col-12 col-sm-12 col-md-7 margin-top top-100">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-5">
                <h1 class="title1">Área de trabalho</h1>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <!-- <button class="btn btn-primary btn-add" type="button">Adicionar Tarefa</button>-->
            </div>
            <div class="col-6 col-sm-6 col-md-4 d-flex">
                <button class="ga-button ga-padding" title="Visualizar Gridview" tooltip-placement="bottom"
                    tooltip-append-to-body="true" (click)="goToGridView()">
                    <i class="fa fa-table" aria-hidden="true"></i>
                </button>
                <button class="ga-button ga-padding" title="Ordenar..." tooltip-placement="bottom"
                    tooltip-append-to-body="true">
                    <i class="fa fa-sort-amount-down-alt" aria-hidden="true"></i>
                </button>
                <button class="ga-button ga-padding" title="Imprimir" tooltip-placement="bottom"
                    tooltip-append-to-body="true">
                    <i class="fa fa-print" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div ngbDropdown class="">
                    <div class="dropdown-header margin" style="padding-left: 0;">
                        <button ngbDropdownToggle
                            class="ga-button ga-margin ga--has-icon-right ng-binding dropdown-toggle" type="button"
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            Lista de atividades
                        </button>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                        <a ngbDropdownItem class="cursor-pointer" (click)="buscarTarefaDoUsuario()">Todos</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPrazo()">Prazo</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarTarefa()">Tarefa</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarEvento()">Evento</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarHistorico()">Histórico Manual</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarAudiencia()">Audiência</a>
                    </div> 
                </div>                                
            </div>
            <!-- <div class="col-6">
                <div ngbDropdown class="">
                    <div class="dropdown-header margin" style="padding-left: 0;">
                        <button ngbDropdownToggle
                            class="ga-button ga-margin ga--has-icon-right ng-binding dropdown-toggle" type="button"
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            Lista de atividades em revisão
                        </button>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                        <a ngbDropdownItem class="cursor-pointer" (click)="tarefasEmRevisao()">Todos</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarPrazoRevisao()">Prazo</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarTarefaRevisao()">Tarefa</a>
                        <a ngbDropdownItem class="cursor-pointer" (click)="filtrarEventoRevisao()">Evento</a>                            
                    </div>                    
                </div>
            </div> -->
        </div>
        <div class="row border-row" *ngIf="tarefasExibir == undefined || tarefasExibir.length == 0">
            Nenhum dado foi localizado!
        </div>
        <div class="row border-row" [ngClass]="item.estilo" *ngFor="let item of tarefasExibir;let i=index">
            <div class="col-12">
                <div class="row">
                    <div class="col-1 text-center">
                        <label class="faux-check">
                            <input type="checkbox" name="faux-check" class="checkbox" />
                            <i class="fa fa-lg fa-check" [ngClass]="'texto-'+item.estilo"></i>
                        </label>
                    </div>
                    <div class="col-7">
                        <div role="presentation">
                            <div class="au-word-break">
                                <div class="texto-titulo d-flex cursor-pointer" (click)="abrirAtividade(item)"
                                    [ngClass]="'texto-'+item.estilo">
                                    {{item.tipoObjeto}}&nbsp; <prioridade-icone *ngIf="item.tipoOcorrencia == 1"
                                        prioridade="{{ item.prioridade }}"
                                        prioridadeDescricao="{{ item.prioridadeDescricao }}"></prioridade-icone>
                                        <div class="status" *ngIf="item.tipoStatusAtividadeNome != undefined">
                                            {{item.tipoStatusAtividadeNome}}
                                        </div>
                                </div>
                                <p class="paragrafo cursor-pointer" (click)="abrirAtividade(item)">
                                    <a color="default" type="button" class="css-gyl37v-LinkButton">{{
                                        item.tituloDescricao}}</a>
                                    <span> - {{ item.dataOcorrencia | date : 'dd/MM/yyyy' }} </span>
                                </p>
                                <p *ngIf="item.tipoOcorrencia == 4">
                                    Data Limite: {{item.dataLimite | date:'dd/MM/yyyy'}} <span class="farol badge"
                                        [ngClass]="item.corAlertaDataLimite"
                                        *ngIf="item.tipoOcorrencia == 4 && item.corAlertaDataLimite != null">&nbsp;</span>
                                </p>
                                <p *ngIf="item.tipoOcorrencia == 4 && item.dataRevisao != null">
                                    Data Limite: {{item.dataRevisao | date:'dd/MM/yyyy'}} <span class="farol badge"
                                        [ngClass]="item.corAlertaDataRevisao"
                                        *ngIf="item.tipoOcorrencia == 4 && item.corAlertaDataRevisao != null">&nbsp;</span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tools">
                            <div class="options d-flex float-right">
                                <div ngbDropdown class="">
                                    <button ngbDropdownToggle class="btn btn-secondary " type="button" id="acoes"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Opções <span class="fas fa-ellipsis-v"></span>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="acoes">
                                        <a ngbDropdownItem class="cursor-pointer"
                                            href="/dashboard/processos-judiciais/detalhes/{{item.processoJudicialId}}"
                                            target="_blank"><i class="fas fa-eye"></i> Ver processo</a>
                                        <a ngbDropdownItem class="cursor-pointer" *ngIf="item.tipoOcorrencia == 2"
                                            (click)="item.editarDetalhes = !item.editarDetalhes"><i
                                                class="fas fa-info-circle"></i> Exibir detalhes do histórico</a>
                                        <a ngbDropdownItem class="cursor-pointer"
                                            (click)="item.editarDetalhes = !item.editarDetalhes"
                                            *ngIf="item.tipoOcorrencia != 2"><i class="fa fa-tag"></i> Detalhes da
                                            atividade</a>
                                        <a ngbDropdownItem class="cursor-pointer" (click)="abrirAtividade(item)"><i
                                                class="fa fa-pencil-alt"></i> Editar atividade</a>
                                        <a ngbDropdownItem class="cursor-pointer"
                                            *ngIf="item.tipoOcorrencia != 2 && item.tipoOcorrencia !=5 && item.tipoStatusAtividade == 3"
                                            (click)="devolverAtividade(item)">
                                            <i class="fas fa-undo"></i> Devolver atividade</a>                                        
                                            <a ngbDropdownItem class="cursor-pointer"
                                            *ngIf="item.tipoOcorrencia != 2 && item.tipoOcorrencia !=5 && item.tipoStatusAtividade == 3"
                                            (click)="revisadoAtividade(item)">
                                            <i class="fas fa-check-circle"></i> Revisado e liberado</a>                                        
                                        <a ngbDropdownItem class="cursor-pointer"
                                            *ngIf="item.tipoOcorrencia != 2 && item.tipoOcorrencia !=5 && item.tipoStatusAtividade == 6"
                                            (click)="encerrarRevisao(item)"><i class="fab fa-algolia"></i> Encerrar atividade</a>
                                        <a ngbDropdownItem class="cursor-pointer" 
                                            (click)="excluirAtiviade(item)"><i class="fa fa-trash"></i> Excluir
                                            atividade</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" style="margin: 0.5em 0 0.7em 0;">
                <div class="row">
                    <div class="col-1">

                    </div>
                    <div class="col-11">
                        <a class="collapsible-item-scope alt au-word-break ng-scope" (click)="clickModalTarefa(item)">
                            {{item.responsavelNome}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-1">

                    </div>
                    <div class="col-11">
                        <div class="collapsible-item-filler d-flex">
                            <div class="ng-scope ng-isolate-scope">
                                <div role="presentation">
                                    <div class="au-word-break">
                                        <div class="css-n0bex0 e12ztx4k0">
                                            <i class="fa fa-comments color-info -info" style="cursor: pointer;"
                                                *ngIf="item.tipoOcorrencia != 2"
                                                (click)="item.editarDetalhes = !item.editarDetalhes">
                                                {{item.quantidadeComentarios}}</i>
                                            <div class="row">
                                                <div class="col-12"
                                                    *ngIf="item.editarDetalhes && item.tipoOcorrencia == 1">
                                                    <tarefas-detalhes tarefaId="{{item.id}}" tarefaDetalhes="{{item}}"
                                                        (changeQuantidade)="onChangeQuantidade(item, false, $event)"
                                                        (changeQuantidadeOcultando)="onChangeQuantidade(item, true, $event)"
                                                        (changeConcluirTarefa)="onChangeConcluirTarefa(item, $event)">
                                                    </tarefas-detalhes>
                                                </div>
                                                <div class="col-12"
                                                    *ngIf="item.editarDetalhes && item.tipoOcorrencia == 2">
                                                    <historico-detalhes descricao="{{item.descricao}}">
                                                    </historico-detalhes>
                                                </div>
                                                <div class="col-12"
                                                    *ngIf="item.editarDetalhes && item.tipoOcorrencia == 3">
                                                    <eventos-detalhes eventoId="{{item.id}}" eventoDetalhes="{{item}}"
                                                        (changeQuantidade)="onChangeQuantidade(item, false, $event)"
                                                        (changeConcluirEvento)="onChangeConcluirEvento(item, $event)"
                                                        (changeQuantidadeOcultando)="onChangeQuantidade(item, true, $event)">
                                                    </eventos-detalhes>
                                                </div>
                                                <div class="col-12"
                                                    *ngIf="item.editarDetalhes && item.tipoOcorrencia == 4">
                                                    <prazo-detalhes prazoId="{{item.id}}" prazoDetalhes="{{item}}"
                                                        (changeQuantidade)="onChangeQuantidade(item, false, $event)"
                                                        (changeConcluirPrazo)="onChangeConcluirPrazo(item, $event)"
                                                        (changeQuantidadeOcultando)="onChangeQuantidade(item, true, $event)">
                                                    </prazo-detalhes>
                                                </div>
                                                <div class="col-12"
                                                    *ngIf="item.editarDetalhes && item.tipoOcorrencia == 5">
                                                    <audiencia-detalhes audienciaId="{{item.id}}"
                                                        audienciaDetalhes="{{item}}"
                                                        (changeQuantidade)="onChangeQuantidade(item, false, $event)">
                                                    </audiencia-detalhes>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-5 border-minha-atividade margin-top">
        <div class="col-12">
            <div class="row">
                <div class="col-7">
                    <div class="col-12">
                        <h3 color="gray" margin="md" class="minha-atividade">Minhas atividades</h3>
                    </div>
                    <div class="col-12 css-132o8hc">

                        <a type="button" class="e1cr19n20 css-1eh57tx-PlainButtonStyled enilzg62">
                            <span class="css-d26r8t-LinkSpan enilzg61">Ocultar</span>
                            <i class="fa fa-angle-up css-1j7d5jt-Icon enilzg64" content="Ocultar"></i>
                        </a>
                        <div class="css-ut5fli e1ovr2wm1"></div>
                        <p class="css-j5nnmn-TextElement efb112z0">Atualizado às 13:13</p>

                    </div>

                </div>
                <div class="col-5">
                    <div class="css-cqpqhr ep427ga0">
                        <div class="css-fob8hg-WrapButton e12bersl1">
                            <div style="display: inline-block;">
                                <div class="css-u1uzqr-Wrap e12bersl0">
                                    <div role="presentation">
                                        <div role="button" class="css-hin92e eb2yzm0">
                                            Esta semana<i class="fa fa-caret-down" style="margin-left: 5px;"></i></div>
                                    </div>
                                    <ul class="css-185bb1f-List e12bersl2">
                                        <li class="css-ixd7ue-Item e12bersl3">Hoje</li>
                                        <li class="css-ixd7ue-Item e12bersl3">Esta semana</li>
                                        <li class="css-ixd7ue-Item e12bersl3">Este mês</li>
                                        <li class="css-ixd7ue-Item e12bersl3">Últimos 7 dias</li>
                                        <li class="css-ixd7ue-Item e12bersl3">Últimos 30 dias</li>
                                        <li class="css-ixd7ue-Item e12bersl3">Próximos 3 dias</li>
                                        <li class="css-ixd7ue-Item e12bersl3">Próxima semana</li>
                                        <li class="css-ixd7ue-Item e12bersl3">Próximo mês</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="css-1isemmb e1hbtxhq1">
                            <div role="button" aria-label="refresh" class="css-iac34f e1jyucgb0" tabindex="0">
                                <i class="ion ion-ios-refresh css-1ma5pps-IconComponent e11c20nv0"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-4">
                    <div role="button" class="css-122l6va e1mhp44i0">
                        <p class="css-1h62zbb e1mhp44i1">{{quantidadeAtividadesConcluida}}</p>
                        <label class="css-13hfmhj e1mhp44i3">Concluídas</label>
                    </div>
                </div>
                <div class="col-4">
                    <div role="button" class="css-122l6va e1mhp44i0">
                        <p class="css-1h62zbb e1mhp44i1">{{quantidadeAtividadesEmAtraso}}</p>
                        <label class="css-13hfmhj e1mhp44i3">Atrasadas</label>
                    </div>
                </div>
                <div class="col-4">
                    <div role="button" class="css-122l6va e1mhp44i0">
                        <p class="css-1h62zbb e1mhp44i1">{{quantidadeAtividadesNaoConcluida}}</p>
                        <label class="css-13hfmhj e1mhp44i3">A concluir</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div style="display: block;">
                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                    [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" height="200"
                    width="323">
                </canvas>
            </div>
        </div>

    </div>

</div>