import { TipoStatus } from './../../../enums/tipo-status.enum';
import { Guid } from "guid-typescript";
import { OcorrenciaTag } from "./ocorrencia-tag";

export class OcorrenciaRequest {
    id: Guid;
    descricao: String;
    isExibirPortal: boolean;
    isNotificarCredor: boolean;
    processoId: Guid;
    situacaoId: Guid;
    situacaoPaiId: Guid;
    status: TipoStatus;
    ocorrenciaTags: OcorrenciaTag[];

    emails: String[];
}
