import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UfService } from "../../../services/uf.service";

@Component({
    selector: 'uf-dropdown',
    templateUrl: './uf-dropdown.component.html'
})
export class UfDropdownComponent implements OnInit {

    @Input() ufId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() ufIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public ufs: any = [];
    public loadUfs: boolean = false;
    public uf: any;

    constructor(
        private _ufService: UfService
    ) { }

    ngOnInit(): void {

        if (this.ufId != undefined && this.ufId != null && this.ufId != '') {
            this.buscarPorId();
        }
        else {
            this.buscarUf('a');
        }
    }

    changeUf($event) {

        this.ufIdChange.emit(this.ufId);
        this.change.emit(this.ufs.filter(x => x.id == this.ufId)[0]);
    }

    filtrar(term: any) {

        if (term.target.value != '')
            this.buscarUf(term.target.value);
    }

    buscarUf(buscar) {

        this.loadUfs = true;

        this._ufService.obterUfFiltroSiglaAsync(buscar).then(x => {

            this.loadUfs = false;
            this.ufs = x.data;

            if (this.uf != undefined) {
                this.ufs.push(this.uf);
            }
        });
    }

    buscarPorId() {

        this.loadUfs = true;
        this._ufService.getByIdAsync(this.ufId)
            .then(res => {

                this.loadUfs = false;
                let ufs = [];
                ufs.push(res.data);
                this.uf = res.data;
                this.ufs = ufs;
            })
    }

    limparUf() {

        this.ufIdChange.emit(null);
    }
}
