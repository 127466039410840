export enum TipoAtividade {
    Tarefa = 1,
    //Historico = 2,
    Evento = 3,
    Prazo = 4,
    Audiencia = 5
}

export const TipoAtividadeLabel = new Map<TipoAtividade, string>([

    [TipoAtividade.Tarefa, "Tarefa"],
    //[TipoAtividade.Historico, "Histórico"],
    //[TipoAtividade.Evento, "Evento"],
    [TipoAtividade.Prazo, "Prazo"],
    [TipoAtividade.Audiencia, "Audiência"],
]);
