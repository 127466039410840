import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { DatePipe } from '@angular/common';
import { TipoAlertaService } from 'src/app/services/tipo-alerta.service';
import { TipoDeSessaoService } from 'src/app/services/tipo-de-sessao.service';
import { DivisaoDeOrgaoService } from 'src/app/services/divisao-de-orgao.service';
import { OrgaosService } from 'src/app/services/orgaos.service';
import { ForosService } from 'src/app/services/foros.service';
import { AreaService } from 'src/app/services/area.service';
import { ComarcaService } from 'src/app/services/comarca.service';
import { UfService } from 'src/app/services/uf.service';
import { PessoaDropdownComponent } from '../pessoa-dropdown/pessoa-dropdown.component';
import { QualificacaoService } from 'src/app/services/qualificacao.service';

@Component({
    selector: 'audiencia-cadastro',
    templateUrl: './audiencia-cadastro.component.html',
    styleUrls: ['./audiencia-cadastro.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class AudienciaCadastroComponent implements OnInit {

    @Input() audiencia:any;
    @Input() index:any;
    
    public tipoAlerta: any = [];
    public tipoDeSessao: any = [];
    public loadTipoAlerta: boolean = false;
    public loadTipoDeSessao: boolean = false;
    public divisoes: any =[];
    public orgaos: any = [];
    public foros: any = [];
    public comarcas: any = [];
    public ufs: any = [];
    public areas: any = [];
    public qualificacoes: any = [];
    public tipoAudiencias: any =[
        { id: 1, nome: 'Virtual'},
        { id: 2, nome: 'Presencial sem videoconferência'},
        { id: 3, nome: 'Videoconferência'},
        { id: 4, nome: 'Híbrida'},
    ]

    @ViewChild('executorDropdown') executorDropdown!: PessoaDropdownComponent;
    @ViewChild('revisorDropdown') revisorDropdown!: PessoaDropdownComponent;
    @ViewChild('assistenteDropdown') assistenteDropdown!: PessoaDropdownComponent;

    constructor(
        private datePipe: DatePipe,        
        private tipoAlertaService: TipoAlertaService,  
        private tipoSessaoService: TipoDeSessaoService,
        public divisaoDeOrgaoService: DivisaoDeOrgaoService,
        public orgaoServie: OrgaosService,
        public forosService: ForosService,
        public areaService: AreaService,        
        public comarcaService: ComarcaService,
        public ufService: UfService,
        public qualificacaoService: QualificacaoService,
    ) { }

    ngOnInit(): void {        
        this.buscarTipoAlertas();
        this.buscarTipoSessao();
        this.buscarOrgao();
        this.buscarDivisaoDeOrgao();
        this.buscarForos();
        this.buscarComarcas();
        this.buscarUfs();
        this.buscarAreas();
        this.buscarQualificacoes();
    }

    buscarQualificacoes() {        
        this.qualificacaoService.getAllAsync().then(x => {            
            this.qualificacoes = x.data;
        });
    }

    buscarAreas(){
        this.areaService.getAll()
            .subscribe(res=>{
                this.areas = res.data;
            })
    }

    buscarUfs(){
        this.ufService.getAll()
            .subscribe(res=>{
                this.ufs = res.data;
            })
    }

    buscarComarcas(){
        this.comarcaService.getAll()
            .subscribe(res=>{                
                this.comarcas = res.data;                
            });
    }

    buscarForos() {        
        this.forosService.getAllAsync().then(x => {            
            this.foros = x.data;
        });
    }

    buscarTipoSessao() {
        this.loadTipoDeSessao = true;
        this.tipoSessaoService.getAll().subscribe(res=>{
            this.loadTipoDeSessao = false;
            this.tipoDeSessao = res.data;
        });
    }

    buscarTipoAlertas() {
        this.loadTipoDeSessao = true;
        this.tipoAlertaService.getAllAsync().then(x => {
            this.loadTipoAlerta = false;
            this.tipoAlerta = x.data;
        });
    }

    buscarOrgao(){
        this.orgaoServie.getAll()
            .subscribe(res=>{                
                this.orgaos = res.data;                
            });
    }

    buscarDivisaoDeOrgao(){
        this.divisaoDeOrgaoService.getAll()
            .subscribe(res=>{                
                this.divisoes = res.data;                
            });
    }

    removerEnvolvido(envolvidoIndex) {
        this.audiencia.envolvidos.forEach((element, index) => {
            if (index == envolvidoIndex) {
                this.audiencia.envolvidos.splice(index, 1);
            }
        });        
    }

    adicionarEnvolvido() {
        
        if(this.audiencia.envolvidos == undefined || this.audiencia.envolvidos == null ){
            this.audiencia.envolvidos = [];
        }

        this.audiencia.envolvidos.push({});
    }
}
