<div class="card">
    <div>
      <div class="table-responsive" *ngIf="pedidos.length > 0">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th class="text-nowrap text-center cursor-pointer">
                Código<i class="ion text-muted ml-2"></i>
              </th>
              <th class="text-nowrap text-center cursor-pointer">
                Detalhes<i class="ion text-muted ml-2"></i>
              </th>
              <th class="text-nowrap text-center cursor-pointer">
                Tipo da Matéria do Pedido<i class="ion text-muted ml-2"></i>
              </th>
              <th class="text-nowrap text-center cursor-pointer">
                Classificação da Matéria do Pedido<i class="ion text-muted ml-2"></i>
              </th>
              <th class="text-nowrap text-center cursor-pointer">
                Vinculado a cálculo<i class="ion text-muted ml-2"></i>
              </th>
              <th class="text-nowrap text-center cursor-pointer">
                Valor Causa<i class="ion text-muted ml-2"></i>
              </th>
              <th class="text-nowrap text-center cursor-pointer">
                Data de Inclusão<i class="ion text-muted ml-2"></i>
              </th>
              <th class="text-nowrap text-center cursor-pointer">
                Beneficiário do pedido<i class="ion text-muted ml-2"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="tabela" *ngFor="let item of pedidos">
              <td>{{item.idExterno}}</td>
              <td>{{item.detalhes}}</td>
              <td>{{item.nomeTipoMateriaPedido}}</td>
              <td>{{item.nomeClassificacaoMateriaPedido}}</td>
              <td>{{item.pedidoVinculadoCalculo ? "Sim" : "Não"}}</td>
              <td>
                  {{item.valorCausa | currency: 'BRL'}}
            </td>
            <td>
                {{item.dataCriacao | date : "dd/MM/yyyy"}}
            </td>
            <td>
                {{item.pessoaBeneficiadaPedidoNome}}
            </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center mt-5" *ngIf="pedidos.length == 0">
        <h3>Não possui dados.</h3>
      </div>
    </div>
  </div>