<div class="">
  <div class="">
    <div class="">
      <div class="">
        <app-layout-navbar [sidenavToggle]="false" [isOpen]="open" (closeMenu)="open = !open"></app-layout-navbar>
        

        <div class="container-fluid router-transitions flex-grow-1">
          <div class="row">
            <div class="col-2 p-0 display-none" [ngClass]="{'d-none': !open}">
              <app-dashboard-menu (menuCloseEvent)="open = $event" ></app-dashboard-menu>
            </div>
            <div class="container-router" [ngClass]="{'col-10': open, 'col-12': !open}">
                <br />
                <router-outlet></router-outlet>
            </div>
          </div>
        </div>

        <app-layout-dashboard-footer></app-layout-dashboard-footer>
      </div>
    </div>
  </div>
</div>
