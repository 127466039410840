export class Pessoa {

  id:undefined;
  nome:String = "";
  nickname:String = "";
  email:String = "";
  cpfCnpj:String = "";
  tipo:String = "";
  grupo:String = "";
  pessoaDados:PessoaDados[] = [];


  GetPessoaDados(pessoa:Pessoa,chave:String)
  {
      var pessoaDados = pessoa.pessoaDados.filter(function filter(element, index, array) {
        return (element.nome == chave);
      })

      if(pessoaDados != null)
      {
        return pessoaDados;
      }
      return null;
  }

  GetPessoaDadosComplementar(pessoaDados:PessoaDados,chave:String)
  {
      var pessoaDadosComplemetar = pessoaDados.pessoaDadosComplementares.find(x => x.nome == chave);

      if(pessoaDadosComplemetar != null)
      {
          return pessoaDadosComplemetar;
      }
      return null;
  }

}

export class PessoaDados
{
  id:undefined;
  pessoaId:undefined;
  nome:String = "";
  nickname:String = "";
  valor:String = "";
  pessoaDadosComplementares:PessoaDadosComplementares[] = [];
}

export class PessoaDadosComplementares
{
  id:undefined;
  pessoaDadosId:undefined;
  nome:String = "";
  nickname:String = "";
  valor:String = "";
}
