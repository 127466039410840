import { Component, OnInit } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-tooltip-grid',
  templateUrl: './tooltip-grid.component.html',
  styleUrls: ['./tooltip-grid.component.scss']
})
export class TooltipGridComponent implements ITooltipAngularComp {
    private params!: { color: string } & ITooltipParams;
    public data!: any;
    public color!: string;
  
    agInit(params: { color: string } & ITooltipParams): void {
      this.params = params;
  
      this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
      this.color = this.params.color || 'white';
    }
  }
