import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { ModalHonorarioConvencionadosComponent } from '../modal-honorario-convencionados/modal-honorario-convencionados.component';

@Component({
    selector: 'app-honorarios-processo',
    templateUrl: './honorarios-processo.component.html',
    styleUrls: ['./honorarios-processo.component.css']
})
export class HonorariosProcessoComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() raiz: string;
    @Output() refreshChange = new EventEmitter<boolean>();


    @Output() refreshTagProcesso = new EventEmitter();

    public refresh: string = "";

    public resultConfirmDialog$ = new Subject<ResultadoModal>();

    constructor(
        private ngbModal: NgbModal,
    ) { }

    ngOnInit(): void {
    }

    abrirModalConvenionados() {        
        const modalRef = this.ngbModal.open(ModalHonorarioConvencionadosComponent, { size: 'md' });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.tipo = this.tipo;
        modalRef.componentInstance.referenciaId = this.referenciaId;        
        modalRef.componentInstance.refreshTagProcesso = this.refreshTagProcesso;

        modalRef.result.then(result => {
            this.refresh = new Date().toString();
        });
    }
}
