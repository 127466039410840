import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IndiceService } from 'src/app/services/indice.service';
import { ProcessosJudiciaisValorService } from 'src/app/services/processos-judiciais-valor.service';

@Component({
    selector: 'app-modal-add-doc-valores',
    templateUrl: './modal-add-doc-valores.component.html',
    styleUrls: ['./modal-add-doc-valores.component.scss']
})
export class ModalAddDocValoresComponent implements OnInit {

    @Input() documento = null;
    @Input() recursoDesdobramento = null;

    private indices: any;

    public form: FormGroup = this.fb.group({ docs: this.fb.array([]) });

    get formArrayDoc() {
        return this.form.controls['docs'] as FormArray;
    }

    public quantidadeLinhasAdd = 1;

    constructor(
        private activeModalService: NgbActiveModal,
        private fb: FormBuilder,
        private processosJudiciaisValorService: ProcessosJudiciaisValorService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private indiceService: IndiceService,) { }

    ngOnInit(): void {
        this.createItem();
        this.getIndices();
    }

    getIndices() {
        this.spinner.show();
        this.indiceService.getAll().subscribe((indices) => {
            this.indices = indices.data;            
            this.spinner.hide();
        })
    }

    createItem() {
        for (let i = 0; i < this.quantidadeLinhasAdd; i++) {
            
            let dataVencimento='';

            if(this.documento != undefined && this.documento.dtVencimento != undefined){
                const dtVencimento = new Date(this.documento.dtVencimento);
                
                dataVencimento = dtVencimento.getFullYear()+'-'+ dtVencimento.getMonth() + 1+'-'+dtVencimento.getDate();
            }

            let dataRemessa='';

            if(this.documento != undefined && this.documento.dtRemessa != undefined){
                const dtRemessa = new Date(this.documento.dtRemessa);
                
                dataRemessa = dtRemessa.getFullYear()+'-'+ dtRemessa.getMonth() + 1+'-'+dtRemessa.getDate();
            }

            this.formArrayDoc.push(this.fb.group({
                numero: [{ value: this.documento?.numero, disabled: !!this.documento }],
                dtVencimento: [dataVencimento],
                dtRemessa: [dataRemessa],
                valor: [this.documento?.valor],
                protesto: [this.documento?.protesto],
                juros: [this.documento?.juros],
                jurosProtesto: [this.documento?.jurosProtesto],
                total: [this.documento?.total],
                indiceId: [this.documento?.indiceId],
                valorAtualizadoCalculado: [this.documento?.valorAtualizadoCalculado],
                valorJurosMoratoriosCalculado: [this.documento?.valorJurosMoratoriosCalculado],
                valorJurosCompensatoriosCalculado: [this.documento?.valorJurosCompensatoriosCalculado],
                valorMultaCalculado: [this.documento?.valorMultaCalculado],
                valorHonorarioCalculado: [this.documento?.valorHonorarioCalculado],
            }));
        }
    }

    removeItem(i) {
        this.formArrayDoc.removeAt(i)
    }

    close() {
        //this.activeModalService.close({ form: this.formArrayDoc.getRawValue(), isEdit: !!this.documento });
        this.activeModalService.close();
    }

    salvar() {
        let valores = [];
        this.spinner.show();
        for (let control of this.formArrayDoc.controls) {
            let ctl = control as FormGroup;

            let valor = {
                numero: '',
                dtRemessa: '',
                dtVencimento: '',
                valor: 0.00,
                protesto: 0.00,
                juros: 0.00,
                jurosProtesto: 0.00,
                total: 0.00,
                processoJudicialRecursoDesdobramentoId: this.recursoDesdobramento.id,
                indiceId: null,
                valorAtualizadoCalculado: 0.00,
                valorJurosMoratoriosCalculado: 0.00,
                valorJurosCompensatoriosCalculado: 0.00,
                valorMultaCalculado: 0.00,
                valorHonorarioCalculado: 0.00,
            };

            debugger;
            valor.numero = ctl.controls["numero"].value;
            valor.dtVencimento = ctl.controls["dtVencimento"].value+" 12:00:00";
            valor.dtRemessa = ctl.controls["dtRemessa"].value+" 12:00:00";
            valor.valor = ctl.controls["valor"].value == null ? 0 : ctl.controls["valor"].value;
            valor.protesto = ctl.controls["protesto"].value == null ? 0 : ctl.controls["protesto"].value;
            valor.juros = ctl.controls["juros"].value == null ? 0 : ctl.controls["juros"].value;
            valor.jurosProtesto = ctl.controls["jurosProtesto"].value == null ? 0 : ctl.controls["jurosProtesto"].value;
            valor.total = ctl.controls["total"].value == null ? 0 : ctl.controls["total"].value;
            valor.indiceId = ctl.controls["indiceId"].value;
            valor.valorAtualizadoCalculado = ctl.controls["valorAtualizadoCalculado"].value == null ? 0 : ctl.controls["valorAtualizadoCalculado"].value;
            valor.valorJurosMoratoriosCalculado = ctl.controls["valorJurosMoratoriosCalculado"].value == null ? 0 : ctl.controls["valorJurosMoratoriosCalculado"].value;
            valor.valorJurosCompensatoriosCalculado = ctl.controls["valorJurosCompensatoriosCalculado"].value == null ? 0 : ctl.controls["valorJurosCompensatoriosCalculado"].value;
            valor.valorMultaCalculado = ctl.controls["valorMultaCalculado"].value == null ? 0 : ctl.controls["valorMultaCalculado"].value;
            valor.valorHonorarioCalculado = ctl.controls["valorHonorarioCalculado"].value == null ? 0 : ctl.controls["valorHonorarioCalculado"].value;

            valores.push(valor);
        }

        if (valores.length > 0) {
            this.processosJudiciaisValorService.adicionarVarios(valores)
                .subscribe(res => {
                    this.activeModalService.close();
                }, error => {
                    this.spinner.hide();
                    this.toastrService.error('Falha ao salvar!', 'Atenção', { timeOut: 10000 });
                });
        }
    }

    public calcularTotal(item, index): void {

         const totalCalculado = item.valor + item.juros + item.protesto + item.jurosProtesto;

         const control = this.form.get('docs')['controls'].at(index)
         control.patchValue({ total: totalCalculado });
    }
}
