<div class="authentication-wrapper authentication-2 px-4">
    <div class="authentication-inner py-5">

        <!-- Form -->
        <form class="card">
            <div class="p-4 p-sm-5">

                <!-- Logo -->
                <div class="d-flex justify-content-center align-items-center pb-2 mb-4">
                    <div class="ui-w-60">
                        <div class="w-100 position-relative" style="padding-bottom: 54%">
                            <img src="assets/img/logo-cdd-sem-fundo.png" style="background-color:#fff">
                        </div>
                    </div>
                </div>
                <!-- / Logo -->

                <h5 class="text-center text-muted font-weight-normal mb-4">Troque sua senha</h5>
                <hr class="mt-0 mb-4">

                <div class="form-group">
                    <label> Senha atual</label>
                    <input [(ngModel)]="senhaAtual" name="senhaAtual" type="password" class="form-control">
                </div>

                <div class="form-group">
                    <label> Nova senha</label>
                    <input [(ngModel)]="novaSenha" name="novaSenha" type="password" class="form-control">
                </div>

                <div class="form-group">
                    <label> Confirmação da nova senha </label>
                    <input [(ngModel)]="confirmacaoNovaSenha" name="confirmacaoNovaSenha" type="password"
                        class="form-control">
                </div>

                <div class="form-group">
                    <button type="button" class="btn btn-block btn-padrao" (click)="trocarSenha()"
                        *ngIf="!requisicaoSendoFeita">enviar</button>
                    <button class="btn btn-block btn-padrao" type="button" disabled *ngIf="requisicaoSendoFeita">
                        <span class="spinner-border" role="status" aria-hidden="true"></span>
                        Enviando...
                    </button>
                    <button type="button" class="btn btn-block btn-default" (click)="voltar()">voltar</button>
                </div>

            </div>
        </form>
        <!-- / Form -->

    </div>
</div>