import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prioridades-icone',
  templateUrl: './prioridades-icone.component.html',
  styleUrls: ['./prioridades-icone.component.css']
})
export class PrioridadesIconeComponent implements OnInit {

  @Input() prioridade: any;
  @Input() prioridadeDescricao: any;

  constructor() { }

  ngOnInit(): void {
    
  }

}
