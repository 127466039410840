import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Tipo } from 'src/app/enums/documento/tipo.enum';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { SelectList } from 'src/app/models/select-list';
import { AtividadeService } from 'src/app/services/atividade.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { ModalService } from 'src/app/services/shared/modal-service';
import { SituacaoService } from 'src/app/services/situacao.service';
import { TagService } from 'src/app/services/tag.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { AtividadeTarefaRequest } from 'src/app/models/operacionais/atividade/atividade-tarefa-request';
import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { DividaPagamentosService } from 'src/app/services/divida-pagamentos.service';
import { DividaPagamentoResponse } from 'src/app/models/operacionais/divida/divida-pagamento-response';
import { NgbDateCustomParserFormatter } from '../../../../utils/dateformat';
import { Moment } from 'moment';
import * as moment from "moment";
import { formatDate } from '@angular/common';
const now = new Date();


@Component({
  selector: 'app-modal-adicionar-divida-taxa-administativa',
  templateUrl: './modal-adicionar-divida-taxa-administativa.component.html',
  styleUrls: ['./modal-adicionar-divida-taxa-administativa.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
]
})
export class ModalAdicionarDividaTaxaAdministrativaComponent implements OnInit {

    modalRef: NgbModalRef;
    isIE10 = false;
    public isRTL: boolean;

    public processo: ProcessoOperacao;
    public dividas: any[] = [];

    valorComprovante: number = 0;

    public displayMonths = 1;
    public navigation = 'select';
    public disabled = false;

    public dataPagamento: NgbDateStruct = {
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate()
    };

    public dataReferencia: NgbDateStruct = {
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate()
    };

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public ocorrenciaService: OcorrenciaService,
        public dividaPagamentoSevice: DividaPagamentosService,
        private spinner: NgxSpinnerService
    ) {

        this.isRTL = appService.isRTL;

        this.isIE10 = this.appService.isIE10;
    }

    public pagamento: any = {
        observacao: '',
        dataPagamento: '',
        idProcesso: '',
        valorComprovante: 0,
        ocorrencia:{
            ocorrenciaId: '',
            descricao: '',
            processoId: ''
        },
        dividas: []
    };

    valorTotalPagamento: number = 0;
    valorPendente: number = 0;

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processo;
        this.valorComprovante = this.modalRef.componentInstance.valorComprovante;
        this.pagamento.valorComprovante = this.valorComprovante;

        this.pagamento.dividas = [];
        this.valorPendente = 0;
        this.valorTotalPagamento = 0;
        this.dividas = [];
        this.dividas.push({
            situacao: 'Novo',
            documento : '',
            titulo: '',
            parcela:1,
            dataRemessa: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
            dataPagamento: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
            dataReferencia: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
            valorOriginalAberto: 0,
            valorAberto: this.pagamento.valorComprovante,
            juros: 0,
            multa: 0,
            valorCustasProtesto: 0,
            valorTotal: this.pagamento.valorComprovante,
            valorPagamento:  this.pagamento.valorComprovante,
            valorPendente: 0
        })

        this.pagamento.dividas = this.dividas;

    }

    salvar() {
        this.toastrService.clear();

        if(!this.pagamento.ocorrencia.descricao)
        {
            this.toastrService.error("Por Favor, informe a observação!", 'Atenção', { timeOut: 10000 });
        }
        else{
            this.add();
        }
    }

    add() {
        this.preSave();
        this.spinner.show();
        this.dividaPagamentoSevice.addTaxaAdministrativa(this.pagamento)
        .subscribe(res => {
            //this.toastrService.success('Taxa administrativa salva com sucesso', 'Atenção', { timeOut: 10000 });
            this.spinner.hide();
        }, error => {
            this.spinner.hide();

            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível salvar a taxa administrativa!', 'Atenção', { timeOut: 10000 });
            }
        },() => { //complete
            this.spinner.hide();

            this.toastrService.success("Taxa administrativa salva com sucesso", "Sucesso");

            setTimeout(() => {
                this.close(this.processo);
            }, 300);
        });

    }


    preSave() {
        this.spinner.show();
        this.toastrService.clear();

        this.pagamento.idProcesso = this.processo.id;
        this.pagamento.ocorrencia.processoId = this.processo.id;

        //var dataVencimento = this.dataVencimento.year +"-"+ this.dataVencimento.month+"-"+ this.dataVencimento.day;
        //var dataReferencia = this.dataReferencia.year +"-"+ this.dataReferencia.month+"-"+ this.dataReferencia.day;

        var datePagamento = new Date(this.dataPagamento.year,this.dataPagamento.month-1,this.dataPagamento.day);
        var dateRef = new Date(this.dataReferencia.year,this.dataReferencia.month-1,this.dataReferencia.day);

        this.pagamento.dataPagamento = datePagamento;

        this.pagamento.dividas.forEach(divida => {
            divida.dataReferencia = dateRef;
            divida.dataVencimento = dateRef;
            divida.dataPagamento = datePagamento;
        });

        this.spinner.hide();
    }

    close(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalContatoProcesso(processo);
    }

    dismiss() {
        this.modalRef.dismiss();
    }


    calcularTotalValorQuitacao(){

        var valor = 0;
        this.dividas.forEach(divida =>{
            valor += divida.valorQuitacao;
        });

        return valor;
    }

    calcularTotalValorPendente(){

        var valor = 0;
        this.dividas.forEach(divida =>{
            valor += divida.valorPendente;
        });

        return valor;
    }
}
