import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProcessosJudiciaisRecursoDesdobramentoService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'ProcessoJudicialRecursoDesdobramento/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();

    changeData(data: any[]) {
        this.dataSource.next(data);
    }

    buscarProcessosJudiciais(filtro): Observable<any> {
        return this.http.get(environment.api + this.endpoint , filtro)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data): Observable<any> {
      return this.http.post(environment.api + this.endpoint, data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }
    
    getAll(): Observable<any> {
      return this.http.get(environment.api + this.endpoint)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    update(data): Observable<any> {
      return this.http.put(environment.api + this.endpoint , data)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    ativarDesativar(recursoDesdobramentoId): Observable<any> {
        
        return this.http.put(environment.api + this.endpoint+'ativar-desativar/'+recursoDesdobramentoId , null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    ativarDesativarPorProcesso(id): Observable<any> {
        
        return this.http.put(environment.api + this.endpoint+'ativar-desativar-por-processo/'+id , null)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    ativarDesativarMonitoramento(data): Observable<any> {
        
        return this.http.put(environment.api + this.endpoint+'ativar-desativar-monitoramento-publicacao/' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    calcularValores(data): Observable<any> {
        
        return this.http.post(environment.api + this.endpoint+'calcular-valores/' , data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
    
    delete(id): Observable<any> {
      return this.http.delete(environment.api + this.endpoint + id)
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
    }

    getById(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }    

    obterResponsavelProcesso(id): Observable<any> {
        return this.http.get(environment.api + this.endpoint+'obter-responsavel-processo/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }    

    obterPorProcesso(processoId): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-por-processo/${processoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterPorProcessoComRecurso(processoId): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-por-processo-com-recurso/${processoId}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterPorIdComDetalhes(id): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-com-detalhes/${id}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    
    uploadAnexo(id, data): Observable<any> {
        return this.http.post(environment.api + this.endpoint+ 'upload-arquivo/'+id, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }


    obterArquivos(id): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-arquivos/${id}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterValorCausa(id): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-valor-causa/${id}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterChaveClientePrincipal(id): Observable<any> {
        return this.http.get( `${environment.api}${this.endpoint}obter-cliente-principal-id/${id}`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
