
import { ServiceBase } from "./service.base";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import { range } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProcessosJudiciaisValorService extends ServiceBase {

    constructor(private http: HttpClient) {
        super();
    }

    public endpoint = 'ProcessoJudicialValor/';
    private dataSource = new BehaviorSubject<any>([]);
    currenteData = this.dataSource.asObservable();


    getAllAsync() {
        return this.http.get<any>(environment.api + this.endpoint)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            ).toPromise();
    }  

    obterPorRecursoDesdobramentoId(id: any) {
        return this.http.get<any>(environment.api + this.endpoint+ 'obter-por-recurso-desdobramento/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    add(data: any): Observable<any> {
        return this.http.post(environment.api + this.endpoint, data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    adicionarVarios(data: any): Observable<any> {
        return this.http.post(environment.api + this.endpoint+'adicionar-varios/', data)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    delete(id: any): Observable<any> {
        return this.http.delete(environment.api + this.endpoint + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    obterValorAbertoPorRecursoDesdobramento(id: any) {
        return this.http.get<any>(environment.api + this.endpoint+ 'obter-valor-aberto-recurso-desdobramento/' + id)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
