<div *ngFor="let form of formularios" class="card mt-2">
  <div class="card-body">
    <h5
      class="card-title d-flex cursor-pointer align-items-center"
      (click)="form.accordionExpandido = !form.accordionExpandido"
    >
      {{ form.nomeFormulario }}
      <span class="material-symbols-outlined">{{
        form.accordionExpandido ? "expand_less" : "expand_more"
      }}</span>
    </h5>

    <ng-container *ngIf="form.accordionExpandido">
      <div *ngFor="let campo of form.camposFormulario; let i = index">
        <div *ngIf="campo.tipo == 1" class="form-group col-md-6 col-sm-12 mb-2">
          <label class="form-label"
            >{{ campo.nomeCampo }}
            <span
              class="required-field"
              style="color: #d9534f"
              *ngIf="campo.obrigatorio"
              >*</span
            ></label
          >
          <input type="text" class="form-control" [(ngModel)]="campo.valor" />
        </div>

        <div *ngIf="campo.tipo == 2" class="form-group col-md-6 col-sm-12 mb-2">
          <label class="form-label"
            >{{ campo.nomeCampo }}
            <span
              class="required-field"
              style="color: #d9534f"
              *ngIf="campo.obrigatorio"
              >*</span
            ></label
          >
          <input
            :id="campo.nomeCampo + i"
            
            currencyMask
            [options]="{
              prefix: '',
              thousands: '',
              precision: 0,
              allowNegative: false
            }"
            class="form-control"
            [(ngModel)]="campo.valor"
            maxlength="9"
          />
        </div>

        <div *ngIf="campo.tipo == 3" class="form-group col-md-6 col-sm-12 mb-2">
          <label class="form-label"
            >{{ campo.nomeCampo }}
            <span
              :id="campo.nomeCampo + i"
              class="required-field"
              style="color: #d9534f"
              *ngIf="campo.obrigatorio"
              >*</span
            ></label
          >
          <input
            :id="campo.nomeCampo + i"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ',',
              align: 'left'
            }"
            type="text"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="campo.valor"
            maxlength="16"
          />
        </div>

        <div *ngIf="campo.tipo == 4" class="form-group form-check ml-3 col-12">
          <input
            class="form-check-input"
            type="checkbox"
            :id="campo.nomeCampo + i"
            [(ngModel)]="campo.nomeCampo"
          />
          <label class="form-check-label mt-1" :for="campo.nomeCampo + i">
            {{ campo.nomeCampo }}
            <span
              class="required-field"
              style="color: #d9534f"
              *ngIf="campo.obrigatorio"
              >*</span
            >
          </label>
        </div>

        <div
          *ngIf="campo.tipo == 5 || campo.tipo == 6"
          class="form-group col-md-6 col-sm-12 mb-2"
        >
          <label class="form-label"
            >{{ campo.nomeCampo }}
            <span
              class="required-field"
              style="color: #d9534f"
              *ngIf="campo.obrigatorio"
              >*</span
            ></label
          >
          <ng-select
            :id="campo.nomeCampo + i"
            [items]="campo.itensSelect"
            [(ngModel)]="campo.valor"
            [ngModelOptions]="{ standalone: true }"
            bindLabel="nome"
            bindValue="id"
            [multiple]="campo.tipo == 6"
          ></ng-select>
        </div>

        <div *ngIf="campo.tipo == 7" class="form-group col-md-6 col-sm-12 mb-2">
          <label class="form-label"
            >{{ campo.nomeCampo }}
            <span
              class="required-field"
              style="color: #d9534f"
              *ngIf="campo.obrigatorio"
              >*</span
            ></label
          >
          <div class="input-group">
            <input
              :id="campo.nomeCampo + i"
              :name="campo.nomeCampo+'Component'+i"
              (click)="campoData.toggle()"
              class="form-control"
              placeholder="dd/mm/aaaa"
              ngbDatepicker
              placement="bottom-right"
              #campoData="ngbDatepicker"
              [(ngModel)]="campo.valor"
            />
            <div class="input-group-append">
              <button
                class="input-group-text"
                (click)="campoData.toggle()"
                type="button"
              >
                <span
                  class="ion ion-md-calendar"
                  style="cursor: pointer"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
