import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-input-save',
  templateUrl: './input-save.component.html',
  styleUrls: ['./input-save.component.css']
})
export class InputSaveComponent implements OnInit {


  @Input() data: any;
  @Input() inputType: string;
  @Input() func: Function;
  @Input() symbol: any;
  @Input() symbolRight: boolean;

  @Output() newTextEvent = new EventEmitter<any>();



  currentDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  constructor(
    public toastrService: ToastrService,
  ) {

   }

  disabled = true;
  saving = false;


  ngOnInit(): void {
    if (this.inputType === 'date') {
      const day = new Date(this.data).getDate();
      const month = new Date(this.data).getMonth() + 1;
      const year = new Date(this.data).getFullYear();

      this.currentDate = {
        day,
        year,
        month,
      };
    }
  }

  update() {

    if (!this.saving) {
      this.disabled = !this.disabled;

      if(this.disabled) {
        this.data = this.inputType === 'number'
        ? Number(this.data)
        : new Date(this.currentDate.year, this.currentDate.month - 1, this.currentDate.day);
        // this.returnNewData();
        this.newTextEvent.emit(this.data);
        this.saving = true;
        setTimeout( () => {
          // this.toastrService.clear();
          // this.toastrService.success("Update Realizado", "Sucesso");
          this.saving = false;
         }, 1000 );
      }
    }


  }

  // returnNewData() {
  //   var newValue = this.inputType == "number" ? Number(value) : value;
  //   this.newTextEvent.emit(this.data);
  // }

  onSelectDate(evt: any) {
    this.data = new Date(evt.year, evt.month - 1, evt.day);
  }

}
