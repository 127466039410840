import { Component, OnInit } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { ForosService } from 'src/app/services/foros.service';
import { VarasService } from 'src/app/services/varas.service';
import { AcaoService } from 'src/app/services/acao.service';
import { TagService } from 'src/app/services/tag.service';
import { InstanciasService } from 'src/app/services/instancias.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { QualificacaoService } from 'src/app/services/qualificacao.service';
import { CasoService } from 'src/app/services/caso.service';
import { AtendimentoService } from 'src/app/services/atendimento.service';


@Component({
    selector: 'app-casos-cadastrar',
    templateUrl: './casos-cadastrar.component.html',
    styleUrls: ['./casos-cadastrar.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class CasosCadastrarComponent implements OnInit {

    public id: string;
    public atendimentoId: string;
    public isAddMode: boolean = false;

    public caso: any;

    public mascaraInteiro: Array<string | RegExp>;
    public mascaraData: Array<string | RegExp>;
    public mascaraDataAutoCorrected: Array<string | RegExp>;
    public mascaraNumero: Array<string | RegExp>;

    public loadQualificacoes: boolean = false;
    public loadTags: boolean = false;

    public qualificacoes: any = [];
    public tags: any = [];
    public tagDropdownSettings = {
        singleSelection: false,
        primaryKey: "tagId",
        text: "",
        noDataLabel: "",
        enableSearchFilter: true,
        disabled: false,
        selectAllText: "Selecione Todas",
        unSelectAllText: "Deselecionar Todas",
        searchPlaceholderText: "Procurar",
        classes: 'multiselect-tags'
    };

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private datePipe: DatePipe,
        public processosJudiciaisService: ProcessosJudiciaisService,
        public forosService: ForosService,
        public varasService: VarasService,
        public acaoService: AcaoService,
        public instanciasService: InstanciasService,
        public tagService: TagService,
        public qualificacaoService: QualificacaoService,
        public casoService: CasoService,
        public atendimentoService: AtendimentoService
    ) {

        this.mascaraInteiro = createNumberMask({
            prefix: '',
            thousandsSeparatorSymbol: '',
        });
        this.mascaraData = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        this.mascaraDataAutoCorrected = createAutoCorrectedDatePipe('dd/mm/yyyy', { minYear: 1900, maxYear: 2100 });
        this.mascaraNumero = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.params['id'];

        this.atendimentoId = this.route.snapshot.params['atendimentoId'];

        this.isAddMode = !this.id;

        this.caso = {
            tipoAcesso: 'Publico',
            clientes: [{}],
            envolvidos: [{}],
            membrosEquipe: [{}]
        };

        if (!this.isAddMode) {
            this.casoService.getById(this.id).subscribe(caso => {
                this.caso = caso.data;

                if (!this.caso.clientes.length) {
                    this.caso.clientes = [{}];
                }

                if (!this.caso.envolvidos.length) {
                    this.caso.envolvidos = [{}];
                }

                if (!this.caso.membrosEquipe.length) {
                    this.caso.membrosEquipe = [{}];
                }
            });
        }

        this.buscarQualificacao();
        this.buscarTags();

        if(this.atendimentoId != undefined){
            this.buscarAtendimento();
        }
    }

    buscarAtendimento(){
        this.atendimentoService.getById(this.atendimentoId)
            .subscribe(res=>{
                this.caso.clientes = [];
                res.data.clientesId.forEach(c=>{                    
                    this.caso.clientes.push({pessoaId:c});
                });

                this.caso.titulo = res.data.titulo;

                this.caso.responsavelId = res.data.responsavelId;
            })
    }

    onSubmit(): void {

        this.caso.envolvidos.forEach((element, index) => {
            if (typeof (element.pessoaId) == "undefined" || !element.pessoaId.length) {
                this.caso.envolvidos.splice(index, 1);
            }
        });

        this.caso.clientes.forEach((element, index) => {
            if (typeof (element.pessoaId) == "undefined" || !element.pessoaId.length) {
                this.caso.clientes.splice(index, 1);
            }
        });

        if (this.isAddMode) {
            if(this.atendimentoId != undefined && this.atendimentoId !=null){
                this.caso.atendimentoId = this.atendimentoId;
            }

            this.spinner.show();
            this.toastrService.clear();
            this.casoService.add(this.caso)
                .subscribe(res => {
                    this.router.navigate(['operacionais/processos-judiciais']);
                    this.toastrService.success("Caso cadastrado.", "Sucesso");
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível cadastrar o Caso!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        } else {
            this.spinner.show();
            this.toastrService.clear();
            this.casoService.update(this.caso)
                .subscribe(res => {
                    this.router.navigate(['operacionais/processos-judiciais']);
                    this.toastrService.success("Caso editado.", "Sucesso");
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível editar o Caso!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        }
    }

    cancel() {
        this.location.back();
    }

    adicionarCliente() {
        this.caso.clientes.push({});
    }

    removerCliente(clienteIndex) {
        this.caso.clientes.forEach((element, index) => {
            if (index == clienteIndex) {
                this.caso.clientes.splice(index, 1);
            }
        });
    }

    adicionarEnvolvido() {
        this.caso.envolvidos.push({});
    }

    removerEnvolvido(envolvidoIndex) {
        this.caso.envolvidos.forEach((element, index) => {
            if (index == envolvidoIndex) {
                this.caso.envolvidos.splice(index, 1);
            }
        });
    }

    adicionarMembroEquipe() {
        this.caso.membrosEquipe.push({});
    }

    removerMembroEquipe(membroEquipeIndex) {
        this.caso.membrosEquipe.forEach((element, index) => {
            if (index == membroEquipeIndex) {
                this.caso.membrosEquipe.splice(index, 1);
            }
        });
    }
    
    buscarQualificacao() {
        this.loadQualificacoes = true;
        this.qualificacaoService.getAllAsync().then(x => {
            this.loadQualificacoes = false;
            this.qualificacoes = x.data;
        });
    }

    buscarTags() {
        this.loadTags = true;
        this.tagService.getAllAsync().then(x => {
            this.loadTags = false;
            x.data.forEach((element, index) => {
                this.tags.push({
                    tagId: element.id,
                    tagNome: element.nome,
                    tagCor: element.cor,
                });
            });
        });
    }
}
