import { Component, OnInit } from '@angular/core';
import { PessoaService } from 'src/app/services/pessoa.service';
import { MidiasSociaisDevedor } from 'src/app/models/contato-devedor/midias-sociais-devedor';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-email-credor',
    templateUrl: './modal-email-credor.component.html',
    styleUrls: ['./modal-email-credor.component.css']
})
export class ModalEmailCredorComponent implements OnInit {

    modalRef: NgbModalRef;

    public btnSucess = true;
    public email = true;
    public buttonName: string;

    contatosCredorEmails: MidiasSociaisDevedor[] = [];

    descricao: string;
    description = false;

    constructor(public pessoaService: PessoaService) { }

    ngOnInit(): void {

       this.description = this.modalRef.componentInstance.description;

       this.pessoaService.getContatosPessoa(this.modalRef.componentInstance.credorId)
            .subscribe(res => {
                let midia = new MidiasSociaisDevedor();
                midia.email = res.data.email;
                midia.selecionado = true;
                this.contatosCredorEmails.push(midia);

                res.data.emails.forEach(element => {
                 let midia = new MidiasSociaisDevedor();
                 midia.email = this.checkChaves(element.find(x => x.chave == 'telefone-email'));
                 midia.selecionado = true;

                 if ( midia.email != undefined && midia.email.trim().length > 0 ) {
                   this.contatosCredorEmails.push(midia);
                  }
                });
            }, error => {
                // this.spinner.hide();
            });
            this.mudarButton();
    }

    confirmarEmailNotificacao() {
        const emails = this.contatosCredorEmails.filter(x => x.selecionado).map(x => x.email);
        this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, lista: emails, description: this.descricao });
    }

    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    mudarButton(): void {
      if (this.email) {
        this.buttonName = 'Desmarcar E-mails';
        this.btnSucess = !this.email;
        this.contatosCredorEmails.forEach(element => {
          element.selecionado = true;
        });
      } else {
        this.buttonName = 'Selecionar E-mails';
        this.btnSucess = !this.email;
        this.contatosCredorEmails.forEach(element => {
          element.selecionado = false;
        });
      }
      this.email = !this.email;
    }

    private checkChaves(expression) {
        if (expression != null) {
            return expression.valor;
        } else {
            return '';
        }
    }
}
