import { ColumnApi, GridApi } from "ag-grid-community";
import * as moment from "moment";

export interface agParams {
    api: GridApi;
    columnApi: ColumnApi;
}

export const agColumnTypes = {
    dateTimeColumn: {
        filter: "agDateColumnFilter",
        cellRenderer: (data) => {
            if(!data.value) return '-';
            return moment
                .utc(data.value || null)
                .local()
                .format("DD/MM/YYYY - HH:mm");
        },
    },
    dateColumn: {
        filter: "agDateColumnFilter",
        cellRenderer: (data) => {
            if(!data?.value?.includes('-')) return data.value;
            if(!data.value) return '-';
            return moment
                .utc(data.value || null)
                .local()
                .format("DD/MM/YYYY");
        },
    },
}

