import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Guid } from "guid-typescript";
import * as moment from "moment";
import { Color, Label } from "ng2-charts";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { DashService } from "src/app/services/dash.service";
import { NgbDateCustomParserFormatter } from "src/app/utils/dateformat";
import { AtendimentosListaComponent } from "../../operacionais/atendimento/atendimentos-lista/atendimentos-lista.component";
import { element } from "protractor";
import {BrowserModule} from '@angular/platform-browser';
import {FormControl, FormGroup, ReactiveFormsModule, FormsModule} from '@angular/forms';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
@Component({
  selector: "app-home-administrativo",
  templateUrl: "./home-administrativo.component.html",
  styleUrls: [
    "./home-administrativo.component.scss",
    "../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss",
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class HomeAdministrativoComponent implements OnInit {
  public isAmigavel: boolean = true;
  public filtroOn = false;
  public dataAtual: any;
  public isMaster = false;

  public currentDate = new Date();
  public dataInicial = { day: 13, month: 2, year: 2022 };
  public dataFinal = { day: 13, month: 4, year: 2023 };

  public dayChartOptions: ChartOptions = {
    responsive: true,
  };
  public dayChartLabels: Label[] = [];
  public dayChartData: ChartDataSets[] = [];

  public stateChartOptions: ChartOptions = {
    responsive: true,
    showLines: false,
  };
  public stateChartLabels: Label[] = [];
  public stateChartData: ChartDataSets[] = [];

  public respChartOptions: ChartOptions = {
    responsive: true,
  };
  public respChartLabels: Label[] = [];
  public respChartData: ChartDataSets[] = [];

  public atendimentoPorClienteChartOptions: ChartOptions = {};
  public atendimentoPorClienteChartLabels: Label[] = [];
  public atendimentoPorClienteChartData: ChartDataSets[] = [];

  public atendimentoNovoXEncerradoChartOptions: ChartOptions = {
    responsive: true,
  };
  public atendimentoNovoXEncerradoChartLabels: Label[] = [];
  public atendimentoNovoXEncerradoChartData: ChartDataSets[] = [];

  public atendimentoEncerradoPorSituacaoEncerramentoChartOptions: ChartOptions =
    {
      responsive: true,
    };
  public atendimentoEncerradoPorSituacaoEncerramentoChartLabels: Label[] = [];
  public atendimentoEncerradoPorSituacaoEncerramentoChartData: ChartDataSets[] =
    [];

  public atendimentoPorResponsavelChartOptions: ChartOptions = {
    responsive: true,
  };
  public atendimentoPorResponsavelChartLabels: Label[] = [];
  public atendimentoPorResponsavelChartData: ChartDataSets[] = [];

  public clientes;
  public atendimentoStatus = [{id: "1", status: 'Ativo'}, {id: "2", status: 'Encerrado'}]
  public responsaveis;
  public selectedIndex: number;

  public totalAtendimentos: number;
  public totalAtendimentosEncerrados: number;
  public totalAtendimentosNaoEncerrados: number;

  public barChartColors: Color[] = [
    { borderColor: "#2c5b86", backgroundColor: "#2c5b8673" },
  ];

  public blueColors: Color[] = [
    {
      borderColor: "#2c5b865c",
      backgroundColor: [
        "#2c5b865c",
        "#075d725c",
        "#0e67ba5c",
        "#00ceff5c",
        "#3387d55c",
        "#61a7e75c",
        "#0d385f5c",
        "#1c6ab05c",
        "##2c8ee75c",
        "#09467e5c",
        "#5da1df5c",
        "#375e825c",
        "#3c61835c",
        "#0737635c",
        "#0763b65c",
        "#0086ff5c",
        "#2c9bff5c",
        "#5fb0f95c",
        "#001cff5c",
        "#1585ec5c",
        "#0028315c",
        "#2c5b865c",
        "#2c5b865c",
        "#2c5b865c",
        "#2c5b865c",
        "#3f51b55c",
      ],
    },
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private dashService: DashService,
    private currencyPipe: CurrencyPipe,
    private spinnerService: NgxSpinnerService,
    private ngbModal: NgbModal
  ) {
    this.isMaster = authService.isMaster();
  }

  ngOnInit(): void {
    this.isAmigavel = this.authService.getAmbiente() == "1" ? true : false;

    if (!this.isAmigavel) {
      this.retornarDataAtual();
      this.getAll();
    }

    if (!this.isMaster) {
      if (this.authService.getPerfil() == "Financeiro") {
        this.router.navigateByUrl("/operacionais/faturamentos");
      } else {
        this.router.navigateByUrl("/operacionais/operacao-de-processos");
      }
    } else this.router.navigateByUrl("/home/administrativo");
  }

  // generateBlueColors(data) {
  //   const letters = "BCDEF".split("");
  //   const colors = data.map(() => {
  //     var color = "#";
  //     for (var i = 0; i < 6; i++) {
  //       color += letters[Math.floor(Math.random() * letters.length)];
  //     }
  //     return color
  //   });
  //   // const colors = data.map(() => {
  //   //   const h = 240;
  //   //   const s = Math.floor(Math.random() * 100);
  //   //   const l = Math.floor(Math.random() * 100);
  //   //   return `hsl(${h}, ${s}%, ${l}%)`;
  //   // });
  //   this.blueColors.push({ backgroundColor: colors });
  // }

  tolltips(data, exibeValorTotal = true) {
    return {
      tooltips: {
        callbacks: {
          label: ({ index }, { datasets }) => {
            const label = datasets[0].label;
            const value = datasets[0].data[index];

            let textoFormatado = `${label}: ${value}`;
            if (exibeValorTotal && data[index].valorTotalProcessos)
              textoFormatado += `/ Vl. total: ${this.currencyPipe.transform(
                data[index].valorTotalProcessos,
                "BRL"
              )}`;

            return textoFormatado;
          },
        },
      },
    };
  }

  formattDate(date) {
    return moment
      .utc(date || null)
      .local()
      .format("DD/MM/YYYY");
  }

  createCharts(
    type: string,
    field: string,
    data,
    isDate = false,
    label = "Qtd de processos"
  ) {
    this[`${type}ChartOptions`] = {
      ...this[`${type}ChartOptions`],
      ...this.tolltips(data),
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 2,
            },
          },
        ],
      },
    };

    this[`${type}ChartData`][0] = { data: [], label: "" };
    this[`${type}ChartLabels`] = [];

    data.forEach((item) => {
      this[`${type}ChartLabels`].push(
        isDate ? this.formattDate(item[field]) : item[field]
      );
      this[`${type}ChartData`][0].data.push(item.quantidadeProcessos);
      this[`${type}ChartData`][0].label = label;
    });
  }

  criarGraficoAtendimentos(
    tipo: string,
    campo: string,
    campoChave: string | null,
    data,
    isDate = false,
    label = 'Quantidade'
  ) {
    this[`${tipo}ChartOptions`] = {
      responsive: true,
      maintainAspectRatio: true, // Mantém a proporção do gráfico
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              fontSize: 10 // Ajusta o tamanho da fonte dos rótulos do eixo X
            },
            stacked: true,
          },
        ],
        yAxes: [
          {
            ticks: {
              autoSkip: false,
              fontSize: 10 // Ajusta o tamanho da fonte dos rótulos do eixo Y
            },
            stacked: true,
            scaleLabel: {
                display: true,
                fontSize: 12 // Ajusta o tamanho da fonte do título da barra horizontal
            }
          },
        ],
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      onClick: (event, array) => this.onChartClick(event, array, tipo),
      //onClick: this.abrirModalAtendimentos.bind(this)  // Adicione a função de clique
    };

    const dados = [];
    const guids = [];
    const labels = [];
    data.forEach((item) => {
        const valor = item.quantidade;
        dados.push(valor);
        if (campoChave) {
          guids.push(item[campoChave]);
        }
        if (isDate) {
          labels.push(this.formattDate(item[campo]));
        } else {
          labels.push(item[campo]);
        }
        this[`${tipo}ChartLabels`] = labels
      });
    
      const dataset = {
        data: dados,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        label: label
      };
    
      if (campoChave) {
        dataset['meta'] = guids;
      }
    
      this[`${tipo}ChartData`] = [dataset];
  }

  criarGraficoAtendimentoRadar(
    tipo: string,
    campo: string,
    campoChave: string | null,
    data,
    isDate = false,
  ) {
    this[`${tipo}ChartOptions`] = {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        labels: {
            fontSize: 8,
            usePointStyle: true,
        }
      },
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
            label: (tooltipItem, data) => {
                const label = data.datasets[0].tooltipLabels[tooltipItem.index];
                const value = data.datasets[0].data[tooltipItem.index];
                return `${label}: ${value}`;
            },
        }
      },
      onClick: (event, array) => this.onChartClick(event, array, tipo),
      //onClick: this.abrirModalAtendimentos.bind(this)  // Adicione a função de clique
    };

    const dados = [];
    const guids = [];
    const tooltipLabels = [];
    const labels = [];
    data.forEach((item) => {
        const valor = item.quantidade;
        dados.push(valor);
        if (campoChave) {
          guids.push(item[campoChave]);
        }
        if (isDate) {
          labels.push(this.formattDate(item[campo]));
        } else {
          labels.push(item[campo].replace(/(.{12})..+/, "$1..."));
        }
        this[`${tipo}ChartLabels`] = labels;
        tooltipLabels.push(item[campo]);
      });
      const dataset = {
        data: dados,
        backgroundColor: [
            "#2c5b865c",
            "#0e67ba5c",
            "#3387d55c",
            "#0d385f5c",
            "##2c8ee75c",
            "#5da1df5c",
            "#3c61835c",
            "#0763b65c",
            "#2c9bff5c",
          ],
        label: 'Quantidade',
        tooltipLabels: tooltipLabels
      };
    
      if (campoChave) {
        dataset['meta'] = guids;
      }
    
      this[`${tipo}ChartData`] = [dataset];
      this[`${tipo}ChartType`] = 'doughnut';

  }

  criarGraficoAtendimentosComDadosEmpilhados(
    tipo: string,
    campo: string,
    campoChave: string,
    data,
    isDate = false
  ) {
    this[`${tipo}ChartOptions`] = {
      responsive: true,
      maintainAspectRatio: true, // Mantém a proporção do gráfico
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 2,
              fontSize: 10 // Ajusta o tamanho da fonte dos rótulos do eixo X
            },
            stacked: true,
          },
        ],
        yAxes: [
          {
            ticks: {
              autoSkip: false,
              fontSize: 10 // Ajusta o tamanho da fonte dos rótulos do eixo Y
            },
            stacked: true,
            scaleLabel: {
                display: true,
                fontSize: 12 // Ajusta o tamanho da fonte do título da barra horizontal
              }
          },
        ],
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      //onClick: this.abrirModalAtendimentos.bind(this)  // Adicione a função de clique
      onClick: (event, array) => this.onChartClick(event, array, tipo),
    };

    // Inicializa arrays para dados
  const nomes = [];
  const ativos = [];
  const encerrados = [];

  // Processa os dados para separar as quantidades de ativos e encerrados
  data.forEach((item) => {
    const index = nomes.indexOf(item[campo]);
    if (index === -1) {
      nomes.push(isDate ? this.formattDate(item[campo]) : item[campo]);
      ativos.push({ quantidade: item.situacao === "Ativo" ? item.quantidade : 0, guid: item[campoChave] });
      encerrados.push({ quantidade: item.situacao === "Encerrado" ? item.quantidade : 0, guid: item[campoChave] });
    } else {
      if (item.situacao === "Ativo") {
        ativos[index].quantidade += item.quantidade;
      } else if (item.situacao === "Encerrado") {
        encerrados[index].quantidade += item.quantidade;
      }
    }
  });

  // Define os dados e labels para o gráfico
  this[`${tipo}ChartLabels`] = nomes;
  this[`${tipo}ChartData`] = [
    {
      data: ativos.map(item => item.quantidade),
      label: "Ativos",
      backgroundColor: "rgba(75, 192, 192, 0.6)",
      meta: ativos.map(item => item.guid) // Adiciona GUIDs aos dados
    },
    {
      data: encerrados.map(item => item.quantidade),
      label: "Encerrados",
      backgroundColor: "rgba(255, 99, 132, 0.6)",
      meta: encerrados.map(item => item.guid) // Adiciona GUIDs aos dados
    },
  ];

  // Configurar o tipo de gráfico
  this[`${tipo}ChartType`] = "horizontalBar";
  }

  criarGraficoLineAtendimentosComDadosEmpilhados(
    tipo: string,
    campo: string,
    campoChave: string,
    data,
    isDate = false
  ) {
    this[`${tipo}ChartOptions`] = {
      responsive: true,
      maintainAspectRatio: true, // Mantém a proporção do gráfico
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              fontSize: 8 // Ajusta o tamanho da fonte dos rótulos do eixo X
            },
            stacked: true,
          },
        ],
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      //onClick: this.abrirModalAtendimentos.bind(this)  // Adicione a função de clique
      onClick: (event, array) => this.onChartClick(event, array, tipo),
    };

    // Inicializa arrays para dados
  const nomes = [];
  const ativos = [];
  const encerrados = [];

  // Processa os dados para separar as quantidades de ativos e encerrados
  data.forEach((item) => {
    const index = nomes.indexOf(item[campo]);
    if (index === -1) {
      nomes.push(isDate ? this.formattDate(item[campo]) : item[campo]);
      ativos.push({ quantidade: item.situacao === "Ativo" ? item.quantidade : 0, guid: item[campoChave] });
      encerrados.push({ quantidade: item.situacao === "Encerrado" ? item.quantidade : 0, guid: item[campoChave] });
    } else {
      if (item.situacao === "Ativo") {
        ativos[index].quantidade += item.quantidade;
      } else if (item.situacao === "Encerrado") {
        encerrados[index].quantidade += item.quantidade;
      }
    }
  });

  // Define os dados e labels para o gráfico
  this[`${tipo}ChartLabels`] = nomes;
  this[`${tipo}ChartData`] = [
    {
      data: ativos.map(item => item.quantidade),
      label: "Ativos",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
      pointBackgroundColor: "rgba(75, 192, 192, 1)",
      pointBorderColor: "fff",
      meta: ativos.map(item => item.guid) // Adiciona GUIDs aos dados
    },
    {
      data: encerrados.map(item => item.quantidade),
      label: "Encerrados",
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      pointBackgroundColor: "rgba(255, 99, 132, 1)",
      pointBorderColor: "fff",
      meta: encerrados.map(item => item.guid) // Adiciona GUIDs aos dados
    },
  ];

  // Configurar o tipo de gráfico
  this[`${tipo}ChartType`] = "line";
  }

  getDate(date) {
    return `${date.year}-${date.month.toString().padStart(2, "0")}-${date.day
      .toString()
      .padStart(2, "0")}`;
  }

  getAll() {
    const params = {
      dataInicial: this.getDate(this.dataInicial),
      dataFinal: this.getDate(this.dataFinal),
    };
    const paramsAtendimento = {
        clientId: "",
        StatusAtendimento: 0,
        responsavelId: "",
    }
    this.spinnerService.show();
    forkJoin({
      byDay: this.dashService.getTotalPublicacoesPorDia(),
      byState: this.dashService.getTotalPublicacoesPorEstado(params),
      byResp: this.dashService.getTotalPublicacoesPorResp(params),
      byAtendimentoPorCliente:
        this.dashService.obterGraficoAtendimentosPorCliente(paramsAtendimento),
      byAtendimentoNovoXEncerrado:
        this.dashService.obterGraficoAtendimentosNovosXEncerrados(paramsAtendimento),
      byAtendimentoEncerradoPorSituacaoEncerramento:
        this.dashService.obterGraficoAtendimentosEncerradosPorSituacaoEncerramento(paramsAtendimento),
      byAtendimentoPorResponsavel:
        this.dashService.obterGraficoAtendimentosPorResponsavel(paramsAtendimento),
    }).subscribe(
      ({
        byDay,
        byState,
        byResp,
        byAtendimentoPorCliente,
        byAtendimentoNovoXEncerrado,
        byAtendimentoEncerradoPorSituacaoEncerramento,
        byAtendimentoPorResponsavel,
      }) => {
        this.createCharts("day", "data", byDay.data, true);
        this.createCharts("state", "siglaEstado", byState.data);
        this.createCharts("resp", "nomeResponsavel", byResp.data);
        this.criarGraficoAtendimentosComDadosEmpilhados(
          "atendimentoPorCliente",
          "nomeCliente",
          "idCliente",
          byAtendimentoPorCliente.data
        );
        this.criarGraficoAtendimentos(
          "atendimentoNovoXEncerrado",
          "situacao",
          null,
          byAtendimentoNovoXEncerrado.data
        );
        this.criarGraficoAtendimentoRadar(
          "atendimentoEncerradoPorSituacaoEncerramento",
          "situacaoEncerramento",
          "idSituacaoEncerramento",
          byAtendimentoEncerradoPorSituacaoEncerramento.data
        );
        this.criarGraficoLineAtendimentosComDadosEmpilhados(
          "atendimentoPorResponsavel",
          "nomeResponsavel",
          "idResponsavel",
          byAtendimentoPorResponsavel.data
        );
        this.calcularTotal(byAtendimentoNovoXEncerrado.data);
        this.spinnerService.hide();
      }
    );
  }

  filtrar(){
    this.spinnerService.show();
    const params = {
        clientId: this.clientes ? this.clientes : "",
        StatusAtendimento: this.selectedIndex ? this.selectedIndex : 0,
        responsavelId: this.responsaveis ? this.responsaveis : "",
    }

    forkJoin({
        byAtendimentoPorCliente:
          this.dashService.obterGraficoAtendimentosPorCliente(params),
        byAtendimentoNovoXEncerrado:
          this.dashService.obterGraficoAtendimentosNovosXEncerrados(params),
        byAtendimentoEncerradoPorSituacaoEncerramento:
          this.dashService.obterGraficoAtendimentosEncerradosPorSituacaoEncerramento(params),
        byAtendimentoPorResponsavel:
          this.dashService.obterGraficoAtendimentosPorResponsavel(params),
      }).subscribe(
        ({
          byAtendimentoPorCliente,
          byAtendimentoNovoXEncerrado,
          byAtendimentoEncerradoPorSituacaoEncerramento,
          byAtendimentoPorResponsavel,
        }) => {
          this.criarGraficoAtendimentosComDadosEmpilhados(
            "atendimentoPorCliente",
            "nomeCliente",
            "idCliente",
            byAtendimentoPorCliente.data
          );
          this.criarGraficoAtendimentos(
            "atendimentoNovoXEncerrado",
            "situacao",
            null,
            byAtendimentoNovoXEncerrado.data
          );
          this.criarGraficoAtendimentoRadar(
            "atendimentoEncerradoPorSituacaoEncerramento",
            "situacaoEncerramento",
            "idSituacaoEncerramento",
            byAtendimentoEncerradoPorSituacaoEncerramento.data
          );
          this.criarGraficoLineAtendimentosComDadosEmpilhados(
            "atendimentoPorResponsavel",
            "nomeResponsavel",
            "idResponsavel",
            byAtendimentoPorResponsavel.data
          );
          this.calcularTotal(byAtendimentoNovoXEncerrado.data);
          this.spinnerService.hide();
        }
      );
  }
  calcularTotal(data) {
    this.totalAtendimentos = data.reduce(
        (acc, item) => acc + item.quantidade,
        0
      );
      this.totalAtendimentosEncerrados = data.reduce(
        (acc, item) => {
          if (item.situacao === "Encerrado") {
            return acc + item.quantidade;
          } else {
            return acc;
          }
        },
        0
      );
      this.totalAtendimentosNaoEncerrados = data.reduce(
        (acc, item) => {
          if (item.situacao === "Ativo") {
            return acc + item.quantidade;
          } else {
            return acc;
          }
        },
        0
      );
  }
  retornarDataAtual(): void {
    const today = new Date();
    this.dataAtual = new Intl.DateTimeFormat("pt-BR", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(today);

    return this.dataAtual;
  }

  onChartClick(event, array, tipo: string) {
    if (array.length > 0) {
        const chartElement = array[0];
        const datasetIndex = chartElement._datasetIndex;
        const index = chartElement._index;
    
        const datasetLabel = this[`${tipo}ChartData`][datasetIndex].label;
        const value = this[`${tipo}ChartData`][datasetIndex].data[index];
        const label = this[`${tipo}ChartLabels`][index];
        const guid = this[`${tipo}ChartData`][datasetIndex].meta ? this[`${tipo}ChartData`][datasetIndex].meta[index] : null; // Obtém o GUID se existir
    
      // Aqui você pode fazer qualquer outra coisa com os dados obtidos
      const modalRef = this.ngbModal.open(AtendimentosListaComponent, {
        size: "lg",
        backdrop: "static",
      });

      // Filtros
      modalRef.componentInstance.modalRef = modalRef;
      modalRef.componentInstance.hasFilter = false; // Passe dados para o componente modal, se necessário
      modalRef.componentInstance.isModal = true;

      switch (tipo) {
        case 'atendimentoPorCliente':
            modalRef.componentInstance.clientes = [guid];
            break;
        case 'atendimentoNovoXEncerrado':
            modalRef.componentInstance.situacao = label === "Encerrado" ? "encerrados" : "ativos";
            break;
        case 'atendimentoEncerradoPorSituacaoEncerramento':
            modalRef.componentInstance.situacoesEncerramento = [guid];
            break;
        case 'atendimentoPorResponsavel':
            modalRef.componentInstance.responsaveis = [guid];
            break;
        default:
            break;
      }
    }
  }
}
