import { OcorrenciaArquivo } from 'src/app/models/operacionais/ocorrencia/ocorrencia-arquivo';
import { OcorrenciaTag } from 'src/app/models/operacionais/ocorrencia/ocorrencia-tag';
import { TipoStatus } from 'src/app/enums/tipo-status.enum';
import { Prioridade } from 'src/app/enums/prioridade.enum';
import { Guid } from 'guid-typescript';

export class Atividade {
    ocorrenciaId: Guid;
    nome: String;
    prioridade: Prioridade;
    status: TipoStatus;
    planejado: Date;
    finalizado: Date;
    criadoPor: String;
    situacaoPai: String;
    situacao: String;
    ocorrenciaTags: OcorrenciaTag[];
    ocorrenciaArquivos: OcorrenciaArquivo[];
}
