import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { PublicacaoService } from 'src/app/services/publicacao.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-publicacoes',
    templateUrl: './publicacoes.component.html',
    styleUrls: ['./publicacoes.component.css']
})
export class PublicacoesComponent implements OnInit {
    apllyFilterPublicType: boolean;
    textoFiltro: string;
    sortDesc = true;

    constructor(
        public publicacaoService: PublicacaoService,
        private spinner: NgxSpinnerService,
        private _router: Router,
    ) { }

    public selecionado: boolean = false;
    public sortAsc: boolean = false;
    public sortBy: string = 'dataCriacao';
    public activeTab = 1;
    public publicacaoVMFilter: any = {
        asc: false,
        campoSort: 'dataCriacao',
        quantidade: 0,
        pagina: 500,
        campoBusca: 'numeroProcesso',
        filtroTela: false,
        publicacaoInternas: false

    }

    public swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    public barChartOptions: ChartOptions = {

        legend: {
            display: false
        },
        responsive: true,
        scales: {

            yAxes: [{
                ticks: {
                    display: false
                },
                gridLines: {
                    display: false
                }
            }],
            xAxes: [{
                gridLines: {
                    display: false
                }
            }]
        }

    };
    public barChartLabels: Label[] = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public barChartPlugins = [];

    public publicacoes: any;

    public publicacoesOriginal: any;

    public detalhes = {
        recebidasHoje: 0,
        tratadasHoje: 0,
        descartadasHoje: 0,
        naoTratadas: 0
    }

    public barChartData: ChartDataSets[] = [
        { data: [0, 0, 0, 0, 0, 0, 0] }
    ];

    public dataHeaderFilter = [];

    public loadPublicacoesExternas = false;
    public loadPublicacoesInternas = false;
    
    ngOnInit(): void {

        this.zerarFiltros();
        this.obterFiltroTratamento('nao_tratado');
    }


    verificarPublicacoesInternas() {
        return this.activeTab == 1 ? false : true;
    }

    getPublicacoes() {
        console.log(this.activeTab);
        this.zerarFiltros();
        this.obterFiltroTratamento('nao_tratado');
    }

    zerarFiltros() {
        this.publicacaoVMFilter = {
            asc: false,
            campoSort: 'dataCriacao',
            quantidade: 0,
            pagina: 500,
            campoBusca: 'numeroProcesso',
            filtroTela: false,
            publicacaoInternas: this.activeTab == 1 ? false : true
            
        }
        this.publicacoes = undefined;
        this.publicacoesOriginal = undefined;
    }

    setSort(key: string) {
        // this.publicacoes = undefined;
        // this.publicacoesOriginal = undefined;
        // if (this.publicacaoVMFilter.campoSort == field) {
        //     this.publicacaoVMFilter.quantidade = 0;
        //     this.publicacaoVMFilter.pagina = 500;
        //     this.publicacaoVMFilter.asc = !this.publicacaoVMFilter.asc;
        // } else {
        //     this.zerarFiltros();
        //     this.publicacaoVMFilter.campoSort = field;
        // }

        // this.carregarPublicacoes();

        if (this.sortBy !== key) {
            this.sortBy = key;
            this.sortDesc = false;
        } else {
            this.sortDesc = !this.sortDesc;
        }


        this.sort(this.publicacoes);
    }

    sort(data) {
        data.sort((a: any, b: any) => {
            a = typeof (a[this.sortBy]) === 'string' ? a[this.sortBy].toUpperCase() : a[this.sortBy];
            b = typeof (b[this.sortBy]) === 'string' ? b[this.sortBy].toUpperCase() : b[this.sortBy];

            if (a < b) { return this.sortDesc ? 1 : -1; }
            if (a > b) { return this.sortDesc ? -1 : 1; }
            return 0;
        });
    }

    carregarDetalhes() {
        this.publicacaoService.getDetails(this.activeTab == 1 ? false : true).subscribe(res => {
            this.detalhes = res.data;
            this.dataHeaderFilter = [
                {
                    onFilter: () => this.obterFiltroTratamento("recebidas_hoje"),
                    value: this.detalhes.recebidasHoje,
                    label: 'Recebidas hoje'
                },
                {
                    onFilter: () => this.obterFiltroTratamento("tratadas_hoje"),
                    value: this.detalhes.tratadasHoje,
                    label: 'Tratadas hoje'
                },
                {
                    onFilter: () => this.obterFiltroTratamento("descartados_hoje"),
                    value: this.detalhes.descartadasHoje,
                    label: 'Descartadas hoje'
                },
                {
                    onFilter: () => this.obterFiltroTratamento("nao_tratado"),
                    value: this.detalhes.naoTratadas,
                    label: 'Não tratadas'
                },
            ]

            let bar: ChartDataSets[] = [{
                data: []
            }
            ];

            for (let i = 0; i < 7; i++) {
                bar[0].data.push(res.data.quantidadeDiasDaSemana[i].quantidadeTotal);
            }

            this.barChartData = bar;
        })
    }

    buscar() {
        this.publicacaoVMFilter.quantidade = 0;
        this.publicacaoVMFilter.pagina = 500;
        this.publicacaoVMFilter.publicacaoInternas = this.activeTab == 1 ? false : true;
        this.publicacoes = undefined;
        this.publicacoesOriginal = undefined;
        this.carregarPublicacoes();
    }

    limparFiltros() {

        this.textoFiltro = null;
        this.zerarFiltros();
        this.carregarPublicacoes();
    }

    obterFiltroTratamento(filtro_tratamento: string) {
        this.zerarFiltros();
        if (this.activeTab == 1) this.loadPublicacoesExternas = true;
        if (this.activeTab == 2) this.loadPublicacoesInternas = true;
        this.publicacaoVMFilter.campoBusca = filtro_tratamento;
        this.publicacaoVMFilter.filtroTela = true;
        this.publicacaoVMFilter.quantidade = 0;
        this.publicacaoVMFilter.pagina = 500;
        this.publicacaoVMFilter.publicacaoInternas= this.activeTab == 1 ? false : true;
        this.publicacoes = undefined;
        this.publicacoesOriginal = undefined;

        this.definirTextoFiltro(filtro_tratamento);
        this.publicacaoVMFilter.textoFiltro = this.definirFiltroTexto(this.textoFiltro);
        this.carregarPublicacoes();
    }

    carregarPublicacoes() {

        this.spinner.show();

        this.publicacaoService.filterPagination(this.publicacaoVMFilter)
            .subscribe(res => {
                
                if (this.publicacoes == undefined) {
                    this.publicacoes = res.data;
                    this.publicacoesOriginal = res.data;
                } else {
                    this.publicacoes = this.publicacoes.concat(res.data);
                    this.publicacoesOriginal = this.publicacoes.concat(res.data);
                }

                this.ajustarDescricao();

                this.publicacaoVMFilter.quantidade += this.publicacaoVMFilter.pagina;

                this.publicacoes.forEach(element => {
                    element.checked = false;
                });

                this.carregarDetalhes();
                this.loadPublicacoesExternas = false;
                this.loadPublicacoesInternas = false;
                this.spinner.hide();
            })
    }

    ajustarDescricao() {
        this.publicacoes.forEach(element => {
            if(element.detalhamento && element.detalhamento.length > 100){
                element.detalhamentoResumo = element.detalhamento.substring(0,100) + "...";
            }else{
                element.detalhamentoResumo = element.detalhamento;
            }
        });
    }

    carregarMais() {
        this.carregarPublicacoes();
    }

    marcarTodasPublicacoes(selecionado: boolean) {

        this.publicacoes.forEach(element => {
            element.checked = selecionado;
        });
    }

    possuiSelecionadas(): boolean {
        return this.publicacoes.filter(c => c.checked).length > 0;
    }

    descartarPublicacoesSelecionadas() {
        const publicacoesSelecionadas = this.publicacoes.filter(c => c.checked);
        if(!publicacoesSelecionadas.length)
            this.swalWithBootstrapButtons.fire('Selecione publicações para descartar!','Atenção','warning');
        
        const ids = publicacoesSelecionadas.filter(p => p.tipoSituacaoPublicacao == 1).map(p => p.id)  ?? [];
        this.descartarPublicacacoes(ids);
    }

    descartarPublicacaoPorId(idPublicacao) {
        this.descartarPublicacacoes([idPublicacao]);
    }

    descartarPublicacacoes(ids){
        this.swalWithBootstrapButtons.fire({
            text: `Deseja descartar ${(ids.length > 1) ? ' as publicações selecionadas?' : ' a publicação' }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                this.publicacaoService.descartarPublicacoes(ids)
                .subscribe(res => {
                    this.zerarFiltros();
    
                    this.publicacoes = undefined;
                    this.publicacoesOriginal = undefined;
                    this.spinner.hide();

                    this.carregarPublicacoes();
                }, error => {
    
                    this.spinner.hide();
    
                    this.swalWithBootstrapButtons.fire('Não foi possível descartar a publicação!','','error'
                    );
                });
            }
        });
    }

    abrirPublicacao(idPublicacao): void {

        const urlBase = this._router.url;

        const url = this._router.serializeUrl(
            this._router.createUrlTree([`${urlBase}/publicacao-editar/${idPublicacao}`])
        );

        window.open(url, '_blank');
    }

    definirTextoFiltro(filtro): void {

        switch (filtro) {
            case "recebidas_hoje":
                this.textoFiltro = 'Recebidas hoje';
                break;
            case "tratadas_hoje":
                this.textoFiltro = 'Tratadas hoje';
                break;
            case "descartados_hoje":
                this.textoFiltro = 'Descartadas hoje';
                break;
            case "nao_tratado":
                this.textoFiltro = 'Não tratadas';
                break;
            default:
              this.textoFiltro = null;
              break
        }
    }
    definirFiltroTexto(filtro): string {

      switch (filtro) {
          case "Recebidas hoje":
              return 'recebidas_hoje';
          case "Tratadas hoje":
              return 'tratadas_hoje';
          case "Descartadas hoje":
              return 'descartados_hoje';
          case "Não tratadas":
            return 'nao_tratado'
          default:
            return null;
      }
  }
}
