<div class="table-responsive" *ngIf="acordosParcelas && acordosParcelas.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSort('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortBy =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSort('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortBy =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortBy =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortBy =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortBy =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('situacaoParcela')">
                                                        Situação da Parcela<i class="ion text-muted ml-2" *ngIf="sortBy =='situacaoParcela'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortBy =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('dataVencimento')">
                                                        Vencimento<i class="ion text-muted ml-2" *ngIf="sortBy =='dataVencimento'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('numeroParcela')">
                                                        Parcela<i class="ion text-muted ml-2" *ngIf="sortBy =='numeroParcela'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>                                                    
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('valorAberto')">
                                                        Valor da Parcela<i class="ion text-muted ml-2" *ngIf="sortBy =='valorAberto'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSort('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortBy =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSort('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortBy =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDesc ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelas">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoParcela}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.dataVencimento | date: 'dd/MM/yy'}}</td>
                                                    <td class="text-center">{{formatarParcela(acordo)}}</td>                                            
                                                    <td class="text-center">{{acordo.valorAberto | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>