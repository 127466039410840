

import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { SituacaoProcessoService } from 'src/app/services/situacao-processo.service';

@Component({
  selector: 'app-modal-info-situacao-processo',
  templateUrl: './modal-info-situacao-processo.component.html',
})
export class ModalInfoSituacaoProcessoComponent implements OnInit {

  modalRef: NgbModalRef;
  public situacaoProcessos: any;

  constructor(
    private appService: AppService,
    private situacaoProcessoService: SituacaoProcessoService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.situacaoProcessos = this.modalRef.componentInstance.situacaoProcesso;
  }



  close() {
    this.modalRef.close();
  }
  
  dismiss() {
    this.modalRef.dismiss();
  }

}
