import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { VarasService } from 'src/app/services/varas.service';


@Component({
  selector: 'app-modal-atualiza-varas',
  templateUrl: './modal-atualiza-varas.component.html',
  providers: [VarasService]
})
export class ModalAtualizaVarasComponent implements OnInit {

  modalRef: NgbModalRef;
  public vara: any;
 

  constructor(
    private appService: AppService,
    private varasService: VarasService,
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.vara = this.modalRef.componentInstance.vara;
  }
  

  atualizar(){
    this.update(this.vara);
}

update(vara) {
  
    this.spinner.show();
    this.toastrService.clear();

    this.varasService.update(vara)
        .subscribe(res => {

    
            this.toastrService.success("Vara atualizada!.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'atualizado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível atualizar a vara!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
}






close() {
  this.modalRef.close();
}

dismiss() {
  this.modalRef.dismiss();
}




}

