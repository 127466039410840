import { Component, Input, OnInit } from '@angular/core';
import { PessoaService } from 'src/app/services/pessoa.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { Pessoa } from 'src/app/models/pessoa/pessoa';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { Parametro } from 'src/app/models/shared/parametro';
import { Guid } from 'guid-typescript';
import { PessoaMapeamentoChaves } from 'src/app/models/pessoa/pessoa-mapeamento-chaves';
import { Acordo } from 'src/app/models/operacionais/acordo/acordo';
import { ModalService } from 'src/app/services/shared/modal-service';
import { ProcessosService } from 'src/app/services/processos.service';
import { DividaService } from 'src/app/services/divida.service';
import { ParametroService } from 'src/app/services/parametro.service';
import { AcordoService } from 'src/app/services/acordo.service';
import { ProcessoDividaResponse } from 'src/app/models/operacionais/operacao/processo-divida-response';
import { AcordoListaResponse } from 'src/app/models/operacionais/acordo/acordo-lista-response';
import { ParametroChaveType } from 'src/app/enums/parametro/parametro-chave-type.enum';

@Component({
    selector: 'app-modal-termo-parcelamento',
    templateUrl: './modal-termo-parcelamento.component.html',
    styleUrls: ['./modal-termo-parcelamento.component.css']
})
export class ModalTermoParcelamentoComponent implements OnInit {

    modalRef: NgbModalRef;


  pmc: PessoaMapeamentoChaves;
  public loadingProcesso: Boolean = true;
  processoId: Guid;
  processo: ProcessoOperacao;
  parametroL: Parametro[];
  acordosL: Acordo[] = [];
  dividaL: ProcessoDivida[] = [];
  dividasOriginais: ProcessoDivida[] = [];
  dividasAcordo: ProcessoDivida[] = [];
  credor: Pessoa;
  devedor: Pessoa;

  taxaJuros = 0;
  taxaAdministrativa = 0;

  totalJuros = 0;
  totalMulta = 0;
  totalProtesto = 0;
  totalDevido = 0;
  totalAtualizacaoCusta = 0;
  // soma dos campos de cima
  totalCredor = 0;
  totalTaxaAdm = 0;


  enderecoDevedor: String = '';
  bairroDevedor: String = '';
  cidadeDevedor: String = '';
  ufDevedor: String = '';
  cepDevedor: String = '';
  dataExtenso: string = '';
    constructor(
        public modalService: ModalService,
        public pessoaService: PessoaService,
        public processoService: ProcessosService,
        public dividaService: DividaService,
        private parametroService: ParametroService,
        public acordoService: AcordoService
    ) { }

    ngOnInit(): void {

        var data = new Date();
        var day = data.getDate();
        var month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][data.getMonth()];
        var year = data.getFullYear();

        this.dataExtenso = 'São Paulo, ' + day + ' de ' + month + ' de ' + year;

        this.processoId = this.modalRef.componentInstance.processoId;

        this.pessoaService.getPessoaMapeamentoChaves().subscribe(x => {
            this.pmc = x.data;
          });

        this.parametroService.getAll().subscribe(res => {
            this.parametroL = res.data;
          });



        this.processoService.getById(this.processoId).subscribe(x => {
            this.loadingProcesso = false;
            this.processo = x.data;

            this.taxaJuros = this.processo.percentualJuros;
            this.taxaAdministrativa = this.processo.percentualTaxaAdministrativa;

            this.dividaService.getAllByProcesso(this.processo.id).subscribe(async (res: ProcessoDividaResponse) => {
              this.dividaL = res.data;

              this.dividaL = this.dividaL.filter(x => x.tipoParcela != 'taxa administrativa');

              this.getDividasOriginais();

              this.acordoService.getAllByProcesso(this.processo.id).subscribe((res: AcordoListaResponse) => {
                  this.acordosL = res.data;

                  // acordos aprovados ficam em primeiro
                  this.acordosL.forEach((element, index) => {
                    if (element.ocorrencia.situacaoId == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_APROVADO].toString())[0].valor)) {
                      this.moveElementArray(this.acordosL, index, 0);
                    }
                    this.getDividasAcordo();
                  });
              });

              this.loadingProcesso = false;

              }, erro => {
                var teste = '';
            });

            this.pessoaService.getById(this.processo.credorId).subscribe(cre => {
                  this.credor = cre.data;
              });

            this.pessoaService.getById(this.processo.devedorId).subscribe(dev => {
                    this.devedor = dev.data;
                    this.getEnderecoDevedor(this.devedor);
              });
          });
    }



  getDividasOriginais() {
      this.totalJuros = 0;
      this.totalMulta = 0;
      this.totalCredor = 0;
      this.totalProtesto = 0;
      this.totalDevido = 0;
      this.totalTaxaAdm = 0;
      this.totalAtualizacaoCusta = 0;

      this.dividasOriginais = this.dividaL.filter(function filterDividaAcordo(element, index, array) {
          if (element.valorPago == null) {
              element.valorPago = 0;
          }
          return (element.acordoId == null);
        //return (element.acordoId == null && element.dividaPaiId == null);
      });

      this.dividasOriginais.forEach(x => {
        if (x.valorAtualizacaoCustas == null) {
          x.valorAtualizacaoCustas = 0;
      }
        this.totalJuros += x.juros;
        this.totalMulta += x.multa;
        this.totalDevido += x.valorSaldoPendente;
        this.totalProtesto += x.valorCustasProtesto;
        this.totalAtualizacaoCusta += x.valorAtualizacaoCustas;
        this.totalCredor = this.totalJuros + this.totalMulta + this.totalProtesto + this.totalAtualizacaoCusta;
        this.totalTaxaAdm += this.getTaxaAdministrativaDivida(x);
      });
  }

  getDividasAcordo() {
      if (this.acordosL.length > 0) {
          const acordoVigenteId = this.acordosL[0].ocorrenciaId;

          this.dividasAcordo = this.dividaL.filter(function filterDividaAcordo(element, index, array) {
            return (element.acordoId == acordoVigenteId);
          });

          this.dividasAcordo.forEach(x => {
            this.totalDevido += x.valorSaldoPendente;
          });

      }
  }



  moveElementArray(input, from, to) {
    let numberOfDeletedElm = 1;

    const elm = input.splice(from, numberOfDeletedElm)[0];

    numberOfDeletedElm = 0;

    input.splice(to, numberOfDeletedElm, elm);
  }


  getTaxaAdministrativaDivida(divida: ProcessoDivida) {
      if (new Date() > new Date(divida.dataVencimento)) {
          var taxa = ((divida.valorAberto + divida.juros+ divida.multa + divida.valorCustasProtesto + (divida.valorAtualizacaoCustas != null ? divida.valorAtualizacaoCustas : 0)) * (this.taxaAdministrativa /100));
          return taxa;
      }
      return 0;
  }

  getEnderecoDevedor(devedor: Pessoa) {
      const dev = new Pessoa();

      const pdc = dev.GetPessoaDados(devedor, this.pmc.endereco.enderecoPd);

      if (dev != null) {
        this.enderecoDevedor = dev.GetPessoaDadosComplementar(pdc[0], this.pmc.endereco.logradouro).valor;
        this.bairroDevedor = '';
        this.cidadeDevedor = dev.GetPessoaDadosComplementar(pdc[0], this.pmc.endereco.cidade).valor;
        this.ufDevedor = dev.GetPessoaDadosComplementar(pdc[0], this.pmc.endereco.uf).valor;
        this.cepDevedor = dev.GetPessoaDadosComplementar(pdc[0], this.pmc.endereco.cep).valor;
      }

  }


    close() {
        this.modalRef.close();
    }

    dismiss() {
        this.modalRef.dismiss();
    }

}
