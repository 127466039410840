import { FaturamentoService } from 'src/app/services/faturamento.service';
import { HomeComponent } from './home.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { DashService } from './../../services/dash.service';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// Libs

import { FileUploadModule } from 'ng2-file-upload';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ChartsModule as Ng2ChartsModule } from 'ng2-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';



import { ModalService } from 'src/app/services/shared/modal-service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { TextMaskModule } from 'angular2-text-mask';
import { DadosCargasService } from 'src/app/services/dados-cargas.service';

import { ModuloService } from 'src/app/services/modulo.service';
import { TreeNodeComponent } from 'src/app/utils/tree-node';
// import { CustomInputEditorNg2Component } from '../shared/custom-input-editor-ng2/custom-input-editor-ng2/custom-input-editor-ng2.component';
import { SharedModule } from '../shared/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { HomeAdministrativoComponent } from './home-administrativo/home-administrativo.component';
import { HomeAmbienteComponent } from './home-ambiente/home-ambiente.component';
import { HomeRoutingModule } from './home-routing.module';
import { ChartDocumentosAnexadosComponent } from './components/chart-documentos-anexados/chart-documentos-anexados.component';
import { ChartUltimosAcessosClientesComponent } from './components/chart-ultimos-acessos-clientes/chart-ultimos-acessos-clientes.component';
import { ChartClientesNaoEnviamBorderosComponent } from './components/chart-clientes-nao-enviam-borderos/chart-clientes-nao-enviam-borderos.component';
import { ChartBorderosCadastradosComponent } from './components/chart-borderos-cadastrados/chart-borderos-cadastrados.component';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

// *******************************************************************************
//

@NgModule({
    imports: [
        HomeRoutingModule,
        CommonModule,
        FileUploadModule,
        FormsModule,
        NgbModule,
        Ng2ChartsModule,
        PerfectScrollbarModule,
        NgxDatatableModule,
        NgSelectModule,
        Ng2SmartTableModule,
        SweetAlert2Module,
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: false,
            timeOut: 2500,
            tapToDismiss: true,
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-right',
        }),
        SharedModule


    ],
    declarations: [
    HomeAdministrativoComponent,
    HomeAmbienteComponent,
    ChartDocumentosAnexadosComponent,
    ChartUltimosAcessosClientesComponent,
    ChartClientesNaoEnviamBorderosComponent,
    ChartBorderosCadastradosComponent

  ], providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        PessoaService,
        ModuloService,
        ModalService,
        DadosCargasService,
        DashService,
        UtilsService,
        FaturamentoService
    ],
    exports: [

    ]
})
export class HomeModule { }
