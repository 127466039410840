<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span>
        <span class="text-muted font-weight-light">
            Operacionais / Processos Judiciais e Casos / </span> <span>{{editar ? "Editar" : "Cadastrar"}} Despesas</span>      
    </span>
</h4>
<hr />
<div class="container mb-5">
    <form ngNoForm>
        <div class="form-row" *ngFor="let despesa of despesasAdd; let i = index">
            <hr />
            <div class="form-group col-3">
                <label class="form-label">Data Vencimento<span class="required-field ml-1">* <i class="fas fa-exclamation-circle" mat-raised-button matTooltip="Considerar data de vencimento igual Data de revisão da atividade" matTooltipPosition="after"></i></span> </label>
                <div class="input-group">
                    <input (click)="Data.toggle()" class="form-control" placeholder="dd/mm/aaaa" name="dp"
                        ngbDatepicker [(ngModel)]="despesa.data" [displayMonths]="displayMonths"
                        [navigation]="navigation" placement="bottom-right" #Data="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="Data.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>                
            </div>
            <div class="form-group col-3">
                <label class="form-label">Data Limite Pagamento<span class="required-field ml-1">*</span></label>
                <div class="input-group">
                    <input (click)="DataLimitePagamento.toggle()" class="form-control" placeholder="dd/mm/aaaa" name="dp"
                        ngbDatepicker [(ngModel)]="despesa.dataLimitePagamento" [displayMonths]="displayMonths"
                        [navigation]="navigation" placement="bottom-right" #DataLimitePagamento="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="input-group-text" (click)="DataLimitePagamento.toggle()" type="button">
                            <span class="ion ion-md-calendar" style="cursor: pointer;"></span>
                        </button>
                    </div>
                </div>                
            </div>
            
            <div class="form-group col-3">
                <label class="form-label">Valor/Valor com desc<span class="required-field ml-1">*</span></label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"  class="form-control" [(ngModel)]="despesa.valor" data-lpignore="true">
            </div>
            <div class="form-group col-3" >
                <label class="form-label">Centro de custo<span class="required-field ml-1">*</span></label>
                <ng-select [items]="centrocustos" [(ngModel)]="despesa.centroDeCustoId" [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id" placeholder="Centro de custo" [loading]="centrocustos == undefined"></ng-select>
            </div>              
            <div class="form-group col-12" >
                <label class="form-label">Descrição da Despesa<span class="required-field ml-1">*</span></label>
                <input type="text" maxlength="1000" class="form-control" [(ngModel)]="despesa.descricao">
            </div>           
            <div class="col-11" *ngIf="tipo == 4">
                <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                    [uploader]="uploader" class="dropzone" (click)="fileInput.click()">                    
                    <span class="span-text">Carregar documento</span>
                    <input type="file" accept="application/pdf" #fileInput ng2FileSelect [uploader]="despesa.uploader" style="display: none" multiple
                        (onFileSelected)="onDrop(despesa, $event)" />
                </div>
                <div class="row" *ngIf="despesa.uploader.queue.length > 0">
                    <div class="col-12" *ngFor="let item of despesa.uploader.queue">
                        {{item?.file?.name}}
                        <button type="button" class="btn btn-danger btn-sm" (click)="popItem(item)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>  
            <div class="col-12" *ngIf="editar">
                <table class="table table-striped table-bordered card-table">
                    <thead>
                      <tr>
                        <th class="width-90">Nome Arquivo</th>
                        <th>Ação</th>
                      </tr>
                      
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of despesa.listaArquivo">
                        
                          <td class="width-95">{{ item.arquivoNome }}</td>
                          <td class="text-center">
                              <button type="button" class="btn btn-danger btn-sm" (click)="removerItem(item)">
                                  <i class="fas fa-trash"></i>
                              </button>
                          </td>
                        
                      </tr>
                    </tbody>
                  </table>
            </div>       
            <div class="form-group col-1" *ngIf="!editar">
                <button type="button" class="btn btn-danger btn-remover-honorario" (click)="removerHonorario(i)"><i class="fas fa-trash"></i></button>
            </div>
            <div class="form-check col-2 align-middle repetir-mensal" *ngIf="tipo != 4">                
                <input class="form-check-input" type="checkbox" id="check_{{i}}" [(ngModel)]="despesa.repetirMensalmente">
                <label class="form-check-label" for="check_{{i}}">
                    Repetir mensalmente
                </label>
            </div>
            <div class="form-group col-1" *ngIf="despesa.repetirMensalmente">
                <input class="form-control" type="number" maxlength="2" [(ngModel)]="despesa.quantidadeMesesRepetir" >
            </div>
            <div class="col-8 linha" *ngIf="despesa.repetirMensalmente">
                mês
            </div>
            <br />            
        </div>
    </form>                
        <div class="row" *ngIf="!editar">
            <div class="col-2" >
                <button class="btn btn-secondary" (click)="adicionarHonorario()">ADICIONAR</button>
            </div>
            <div class="form-group col-1">
                <input class="form-control" type="number" [(ngModel)]="quantidadeLinhasAdd" >                
            </div>
            <div class="col-9 linha">
                linha(s)
            </div>
        </div>
        <hr />
        <div class="row" *ngIf="despesasDetalhe != undefined">
            <div class="form-group col-4">
                <label class="form-label">Processo, caso ou atendimento<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="despesasDetalhe.titulo">
            </div>
            <div class="form-group col-4">
                <label class="form-label">Cliente(s)<span class="required-field ml-1">*</span></label>                
                <ng-select class="form-select" [ngModel]="clienteSelected" [items]="this.despesasDetalhe.nomeCliente" >                    
                </ng-select>
            </div>
            <div class="form-group col-4">
                <label class="form-label">Responsável<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="despesasDetalhe.nomeResponsavel">
            </div>
        </div>
        <div class="row" *ngIf="despesasDetalhe != undefined">
            <div class="form-group col-3">
                <label class="form-label">Código Processo<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="despesasDetalhe.idExternoProcesso" disabled>
            </div>
            <div class="form-group col-3">
                <label class="form-label">Número processo<span class="required-field ml-1">*</span></label>                
                <input type="text" class="form-control" [(ngModel)]="despesasDetalhe.numeroProcesso" disabled>
            </div>
            <div class="form-group col-3">
                <label class="form-label">Número Desdobramento<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="despesasDetalhe.numeroDesdobramento" disabled>
            </div>
            <div class="form-group col-3">
                <label class="form-label">Pasta<span class="required-field ml-1">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="despesasDetalhe.pasta" disabled>
            </div>
        </div>
        <hr />
        <div class="d-flex justify-content-end">
            <button type="button" (click)="reload()" class="btn btn-light mr-3">Cancelar</button>
            <button type="button" (click)="onSubmit()" class="btn btn-primary">Salvar</button>
        </div>    
</div>