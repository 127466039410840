import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { Location } from "@angular/common";
import { HttpEventType } from "@angular/common/http";

export interface FileUploadBackgound {
  id: number;
  name: string;
  size: number;
  success: boolean;
  error: boolean;
  done: boolean;
}

@Injectable({
  providedIn: "root",
})
export class UploadInBlackgroundService {
  public showUploadInBackgroud: BehaviorSubject<{
    show: boolean;
    files: Map<number, FileUploadBackgound>;
  }> = new BehaviorSubject({ show: false, files: new Map() });

  public files: Map<number, FileUploadBackgound> = new Map();

  public uploadProgress$ = new BehaviorSubject(new Map());
  private uploadProgressMap = new Map();

  constructor(
    private toastrService: ToastrService,
    private location: Location
  ) {}

  show({
    urlFrom,
    service,
    uploadFunction,
    params,
    files,
    context = null,
    callAfterUpload = null,
    id = null,
    msgSuccess = null,
    msgError = null,
  }) {
    files.forEach((f, i) => {
      files[i].id = this.files.size + 1;

      this.files.set(this.files.size + 1, {
        id: this.files.size + 1,
        name: f.name,
        size: f.size,
        success: false,
        error: false,
        done: false,
      });
      this.uploadProgressMap.set(this.files.size + 1, {
        fileId: this.files.size + 1,
        progress: 0,
      });
    });

    this.showUploadInBackgroud.next({ show: true, files: this.files });

    const call = !!id
      ? service[uploadFunction](id, params)
      : service[uploadFunction](params);

    call.subscribe(
      (res) => {
        if (res.type === HttpEventType.UploadProgress) {
          const progress = Math.round((100 * res.loaded) / res.total);

          files.forEach((f, i) => {
            this.uploadProgressMap.set(f.id, {
              fileId: f.id,
              progress,
            })
          });

          this.uploadProgress$.next(this.uploadProgressMap);

          if (progress === 100) {
            this.uploadErrorComplete(files, true);
            if (context && context[callAfterUpload]) {
              context[callAfterUpload]();
            }

            this.toastrService.success(
              msgSuccess ?? "Upload realizado com sucesso!",
              "Sucesso"
            );

            if (
              urlFrom !== "modal" &&
              window.location.pathname.includes(urlFrom)
            ) {
              this.location.back();
            }
          }
        }
      },
      (error) => {
        if (error && error.errors)
          this.toastrService.error(error.errors[0], "Atenção", {
            timeOut: 10000,
          });
        else
          this.toastrService.error(
            msgError ?? "Não foi possível fazer o upload do documento!",
            "Atenção",
            { timeOut: 10000 }
          );

        this.uploadErrorComplete(files, false);
      }
    );
  }

  uploadErrorComplete(files, success: boolean) {
    this.files.forEach((f, i) => {
      files.forEach((f2) => {
        if (f.id === f2.id) {
          this.files.set(i, {
            ...f,
            error: !success,
            success: success,
            done: true,
          });
        }
      });
    });

    this.showUploadInBackgroud.next({ show: true, files: this.files });
  }

  hide() {
    this.files = new Map();
    this.showUploadInBackgroud.next({ show: false, files: this.files });
  }
}
