import { Component, OnInit, Input } from '@angular/core';
import { ProcessosJudiciaisAtividadesLogsService } from 'src/app/services/processos-judiciais-atividades-logs.service';


@Component({
  selector: 'app-time-line-atividade-log',
  templateUrl: './time-line-atividade-log.component.html',
  styleUrls: ['./time-line-atividade-log.component.scss']
})
export class TimeLineAtividadeLogComponent implements OnInit {

    public atividadesLogs: any = [];
    @Input() codigoAtividade: string;

    public timeLine = [{ year: '2015', detail: 'Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.' },
        { year: '2016', detail: 'Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.'},
        { year: '2017', detail: 'Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.'},
        { year: '2018', detail: 'Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.' },
        { year: '2019', detail: 'Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.'}
        
        ]

  constructor(private _atividadeLogService: ProcessosJudiciaisAtividadesLogsService ) { }

  ngOnInit(): void {
    this.obterLogsDaAtividade();
  }

  obterLogsDaAtividade():void {
    console.log(this.codigoAtividade);
    this._atividadeLogService.obterLogsDaAtividade(this.codigoAtividade).subscribe(res => {
        if (res.success) {

            console.log(res.data);

            this.atividadesLogs = res.data;

            console.log(this.atividadesLogs);

            /*this.spinner.hide();

            this.despesas = res.data.result.itens[0].despesas;
            this.totalItens = res.data.result.paginacao.totalItens;
            this.despesas.forEach(honorario => {
                honorario.tipoDespesa = this.tiposDespesa.find(tipo => tipo._id == honorario.tipoDespesa)?.status;
            });  */
        }
    });
  }

}
