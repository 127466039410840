import { TipoAndamentoService } from './../../../../../services/tipo-andamento.service';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-modal-cadastro-tipo-andamento',
  templateUrl: './modal-cadastro-tipo-andamento.component.html',
  providers: [TipoAndamentoService]
})
export class ModalCadastroTipoAndamentoComponent implements OnInit {


  modalRef: NgbModalRef;
  public tipoAndamento: any;



  constructor(
    private spinner: NgxSpinnerService,
    public toastrService: ToastrService,
    private appService: AppService,
    private tipoAndamentoService: TipoAndamentoService,
  )
  {

  }

  popularNovaComarca() {
    this.tipoAndamento = {
        nome: '',
        descricao: '',
    };
}



  ngOnInit(): void {
    this.popularNovaComarca();
  }

  cadastrar() {
    //if (this.validarCadastroPessoa(this.pessoa)) {
        this.add(this.tipoAndamento);
    //}
  }

  add(tipoAndamento) {
    this.spinner.show();
    this.toastrService.clear();

    // var pessoaModelCadastro = this.montarModelPessoaCadastro(pessoa);

    this.tipoAndamentoService.add(tipoAndamento)
        .subscribe(res => {
            this.toastrService.success("Tipo de Andamento cadastrado.", "Sucesso");
            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'cadastrado' });
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível cadastrar o Tipo de Andamento!', 'Atenção', { timeOut: 10000 });
            }
            this.spinner.hide();
        });
  }


  close() {
    this.modalRef.close();
  }

  dismiss() {
      this.modalRef.dismiss();
  }


}

