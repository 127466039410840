import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SortDirection } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RequisicaoBuscaPaginada } from 'src/app/models/shared/busca-paginada';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { UtilsService } from 'src/app/utils/utils.service';

import Swal from 'sweetalert2';
import { DespesaService } from 'src/app/services/despesa.service';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { TipoDespesaOptions } from 'src/app/enums/tipo-despesa/tipo-despesa.enum';

@Component({
    selector: 'app-despesas-dashboard',
    templateUrl: './despesas-dashboard.component.html',
    styleUrls: ['./despesas-dashboard.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class DespesasDashboardComponent implements OnInit {

    swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    @Input() hasFilter = true;

    constructor(private spinner: NgxSpinnerService,
        private _arquivoService: ArquivoService,
        private _utilsService: UtilsService,
        private _toastrService: ToastrService,
        private router: Router,
        private _despesaService: DespesaService,
        private _ocorrenciaServide: ProcessosJudiciaisOcorrenciasService
    ) {   }

    public despesas: any = [];

  
    @Input() numeroProcesso: string;
    @Input() codigoAtividade: string;
    @Input() itensPorPagina: number = 50;
    @Output() saiuTela: EventEmitter<boolean> = new EventEmitter<boolean>();
    outScreen: boolean = true;
    totalItens: number = 0;
    paginaAtual: number = 1;
    colunaOrdenacao: string = 'DataInsercao';
    ordernarDesc: boolean = true;
    desdobramentoId: string = '';
    public tiposDespesa = TipoDespesaOptions;
   
    get totalPaginas() {
        return Math.ceil(this.totalItens / this.itensPorPagina);
    }

    ngOnInit(): void {
        this.obterDespesas();
        this.obterDesdobramentoId();
    }

    refreshData(){
        this.obterDespesas();
        this.obterDesdobramentoId();
    }

    obterDespesas(): void {

        this.spinner.show();

        let requisicaoBuscaPaginada: RequisicaoBuscaPaginada = {
            itensPorPagina: this.itensPorPagina,
            paginaAtual: this.paginaAtual,
            colunaOrdenacao: this.colunaOrdenacao,
            direcaoOrdenacao: this.ordernarDesc ? SortDirection.desc : SortDirection.asc,
        }

        let requisicao = {
            paginacao: requisicaoBuscaPaginada,            
            codigoAtividade: this.codigoAtividade,
            numeroProcesso: this.numeroProcesso?.trim(),
        };
        this._despesaService.buscarDespesasProcesso(requisicao).subscribe(res => {
            if (res.success) {

                this.spinner.hide();

                this.despesas = res.data.result.itens[0].despesas;
                this.totalItens = res.data.result.paginacao.totalItens;
                this.despesas.forEach(honorario => {
                    honorario.tipoDespesa = this.tiposDespesa.find(tipo => tipo._id == honorario.tipoDespesa)?.status;
                });  
            }
        });
    }

    obterDesdobramentoId(): void{
        this._ocorrenciaServide.obterCodigoDesdobramentoIdPorAtividateId(this.numeroProcesso, this.codigoAtividade).subscribe(res => {
            if (res.success) {
                this.desdobramentoId = res.data.result;
            }
        });
    }


    handleDownloadArquivo(despesaId: any) {
        this.spinner.show();
        try {
            this._despesaService.downloadArquivosDespesas(despesaId).subscribe(
                data => {
                    if (data.type == 3) {
                        this.spinner.hide();
                    }
                    this._utilsService.renderDownload(data, despesaId as string);
                }
            );
        } catch {
            this.spinner.hide();
            this._toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
        this.spinner.hide();
    }

    editarDespesa(despesa: any) {
        console.log(despesa)
        if(despesa && despesa.tipoDespesa == "Em Aberto"){
            this.router.navigate([]).then(result => {  window.open(`/dashboard/despesa/editar/${despesa.despesaId}`, '_blank') });
        }else{
            this._toastrService.error('Não é possível editar uma despesa Faturada/Reembolsada!', 'Atenção');
        }
    }
    

    deletarDespesa(despesa: any) {

        if(despesa.tipoDespesa != "Em Aberto") {
            this._toastrService.error('Não é possível excluir uma despesa Faturada/Reembolsada!', 'Atenção');
            return;
        }   

        this.swalWithBootstrapButtons.fire({
            title: 'Deseja realmente excluir a despesa?',
            text: 'Essa ação não poderá ser revertida!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                this._despesaService.deletarDespesa(despesa.despesaId).subscribe(
                    () => {
                        this.spinner.hide();
                        this._toastrService.success('Despesa excluída com sucesso!', 'Sucesso');
                        this.obterDespesas();
                        this.obterDesdobramentoId();
                    },
                    () => {
                        this.spinner.hide();
                        this._toastrService.error('Não foi possível excluir a despesa!', 'Atenção');
                    }
                );
            }
        });
    }
    
    alterarPagina(paginaSelecionada) {

        this.paginaAtual = paginaSelecionada;
        this.obterDespesas();
    }

    ordenar(colunaOrdenacao: string) {

        this.colunaOrdenacao = colunaOrdenacao;
        this.ordernarDesc = !this.ordernarDesc;
        this.obterDespesas();
    }

    
}
