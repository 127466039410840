import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-trocar-senha',
    templateUrl: './trocar-senha.component.html',
    styleUrls: [
        '../../../../vendor/styles/pages/authentication.scss'
    ]
})
export class TrocarSenhaComponent {

    public senhaAtual = '';
    public novaSenha = '';
    public confirmacaoNovaSenha = '';

    public requisicaoSendoFeita = false;

    constructor(private appService: AppService,
        private router: Router,
        private loginService: LoginService,
        public toastrService: ToastrService
        ) {
        this.appService.pageTitle = 'trocar senha';
    }
    
    trocarSenha(){
        this.requisicaoSendoFeita = true;
        var emailUsuario = localStorage.getItem('iso.usuario');

        var dataTrocarSenha = {
            SenhaAtual: this.senhaAtual,
            NovaSenha: this.novaSenha,
            ConfirmarNovaSenha: this.confirmacaoNovaSenha,
            EmailUsuario: emailUsuario
        };
        
        this.loginService.trocarSenha(dataTrocarSenha)
            .subscribe(res => {
                this.requisicaoSendoFeita = false;
                this.toastrService.success(res.data, 'Sucesso', {closeButton: false, timeOut:3000});

                setTimeout(() => this.router.navigateByUrl('/home'), 1000);
                
        }, error =>{
            this.requisicaoSendoFeita = false;
            this.toastrService.error(error.errors[0], 'Erro', {closeButton: false, timeOut:10000});
        })
    }

    voltar(){
        this.router.navigateByUrl('/home');
    }
}
