import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AcaoService } from 'src/app/services/acao.service';

@Component({
    selector: 'acao-dropdown',
    templateUrl: './acao-dropdown.component.html'
})
export class AcaoDropdownComponent implements OnInit {

    @Input() acaoId!: any | any;
    @Input() selecionaMultiplos!: Boolean | false;
    @Output() acaoIdChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public acoes: any = [];
    public loadAcoes: boolean = false;
    public acao: any;

    constructor(
        private _acaoService: AcaoService
    ) { }

    ngOnInit(): void {

        if (this.acaoId != undefined && this.acaoId != null && this.acaoId != '') {
            this.buscarPorId();
        } else {
            this.buscarAcao('a');
        }
    }

    changeAcao($event) {

        this.acaoIdChange.emit(this.acaoId);
        this.change.emit(this.acoes.filter(x => x.id == this.acaoId)[0]);
    }

    filtrar(term: any) {

        if (term.target.value != '')
            this.buscarAcao(term.target.value);
    }

    buscarAcao(buscar) {

        this.loadAcoes = true;

        this._acaoService.obterAcaoFiltroNomeAsync(buscar).then(x => {

            this.loadAcoes = false;
            this.acoes = x.data;

            if (this.acao != undefined) {
                this.acoes.push(this.acao);
            }
        });
    }

    buscarPorId() {

        this.loadAcoes = true;
        this._acaoService.getByIdAsync(this.acaoId)
            .then(res => {
                this.loadAcoes = false;
                let acoes = [];
                acoes.push(res.data);
                this.acao = res.data;
                this.acoes = acoes;
            })
    }

}
