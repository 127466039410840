import { TagResponse } from './../../../../models/cadastros/tag/tag-response';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TagService } from 'src/app/services/tag.service';
import { Tag } from 'src/app/models/cadastros/tag/tag';
import { TipoTag } from 'src/app/enums/tag/tipo-tag.enum';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { ProcessosService } from 'src/app/services/processos.service';
import { ProcessoTagRequest } from 'src/app/models/operacionais/operacao/processo-tag-request';
import { ErroResponse } from 'src/app/models/erro-response';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-modal-tag-processo',
    templateUrl: './modal-tag-processo.component.html',
    styleUrls: ['../../../../../vendor/styles/pages/modal-filtro-processos-orfaos.scss',
        '../../../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    ],
})

export class ModalTagProcessoComponent implements OnInit {
    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    tags: Tag[] = [];
    tagsProcesso: Tag[] = [];

    modalRef: NgbModalRef;
    isIE10 = false;
    processo: ProcessoOperacao;

    ngOnInit(): void {
        this.processo = this.modalRef.componentInstance.processo;

        this.tagsProcesso = Object.assign([], this.processo.tags);

        this.tagService.getAllByTipo(TipoTag.Processo).subscribe((res:TagResponse) => {
            this.tags = res.data;

            this.tagsProcesso.forEach(tag => {
                this.tags = this.tags.filter(f => f.id != tag.id);
            });
        });
    }

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public tagService: TagService,
        private spinner: NgxSpinnerService,
        public processosService: ProcessosService
    ) {
        this.isIE10 = this.appService.isIE10;
    }

    public removerTag(tag: Tag) {
        this.tagsProcesso = this.tagsProcesso.filter(f => f != tag);
        this.tags.push(tag);
    }

    public addTag(tag: Tag) {
        this.tags = this.tags.filter(f => f != tag);
        this.tagsProcesso.push(tag);
    }

    public ok() {
        this.spinner.show();

        let processoTagRequest = new ProcessoTagRequest();
        processoTagRequest.processoId = this.processo.id;
        processoTagRequest.tags = this.tagsProcesso.map((tag: Tag) => tag.id);

        this.processosService.associarProcessoTag(processoTagRequest).subscribe((res) => {
            this.processo.tags = this.tagsProcesso.map((tag: Tag) => tag);

            this.modalRef.close({ result: true, page: this.modalRef.componentInstance.page, acao: 'tags', lista: [this.processo] });

            this.spinner.hide();
        }, (err: ErroResponse) => {
            if (err && err.errors) {
                this.toastrService.error(err.errors[0], 'Atenção');
            }
            else {
                this.toastrService.error('Não foi possível atualizar os dados!', 'Atenção', { timeOut: 10000 });
            }

            this.spinner.hide();
        });
    }

    public close() {
        this.modalRef.close();
    }

    public dismiss() {
        this.modalRef.dismiss();
    }
}
