<nav class="navbar navbar-expand-lg align-items-lg-center" [ngClass]="currentBg()">

    <!-- Brand demo (see demo.css) -->
    <a routerLink="/" class="navbar-brand app-brand demo d-lg-none py-0 mr-4">
        <span class="app-brand-logo demo bg-primary">
            <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity=".25" offset="0"></stop>
                        <stop stop-opacity=".1" offset=".3"></stop>
                        <stop stop-opacity="0" offset=".9"></stop>
                    </linearGradient>
                    <linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlink:href="#a"></linearGradient>
                    <linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlink:href="#a">
                    </linearGradient>
                </defs>
                <path style="fill: #fff;" transform="translate(-.1)"
                    d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z">
                </path>
                <path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z"
                    fill="url(#a)"></path>
                <path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z"
                    fill="url(#e)"></path>
                <path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z"
                    fill="url(#d)"></path>
            </svg>
        </span>
        <span class="app-brand-text demo font-weight-normal ml-2">Appwork</span>
    </a>

    <!-- Sidenav toggle (see demo.css) -->
    <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto" *ngIf="sidenavToggle">
        <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" (click)="toggleSidenav()">
            <i class="ion ion-md-menu text-large align-middle"></i>
        </a>
    </div>

    <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
        <!-- Divider -->
        <hr class="d-lg-none w-100 my-2">

        <i class="fa fa-bars mr-2 cursor-pointer" (click)="closeMenu.emit()"></i>

        <div class="navbar-nav align-items-lg-center">
            <!-- logo -->
            <span class="app-brand-logo demo bg-primary">
                <img src="assets/img/logo-cdd-sem-fundo.png" style="background-color:#fff">
            </span>
            <label class="nav-item navbar-text navbar-search-box p-0 active">
                CDD - Serviços Empresariais
            </label>
        </div>

        <div class="navbar-nav align-items-lg-center ml-auto">
            <div class="mr-2">
                Ambiente: <b>{{isJuridico ? 'Jurídico' : 'Amigável'}}</b>
                <div style="font-size: 0.8em;">Versão: {{versao}}</div>
            </div>
            
            <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|
            </div>

            <span (click)="openAutenticatior()" class="d-inline-flex flex-lg-row-reverse align-items-center align-middle cursor-pointer-padrao mr-3">
                <span class="fa fa-user-shield"></span>
            </span>

            <span (click)="abrirListaDeRamais()"
                class="d-inline-flex flex-lg-row-reverse align-items-center align-middle cursor-pointer-padrao mr-2">
                <span class="d-block fas fa-phone"></span>
            </span>            
            <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'"
                class="demo-navbar-notifications nav-item mr-lg-3">
                <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
                    <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
                    <span *ngIf="existeNotificacoes" class="badge badge-primary badge-dot indicator"></span>
                    <span class="d-lg-none align-middle">&nbsp; Notificações</span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                <app-notificacao-push (notificacaoEvent)="notificar($event)"></app-notificacao-push>
            </div>
            </div>

            <span (click)="abrirCalendario()" *ngIf="isJuridico"
                class="d-inline-flex flex-lg-row-reverse align-items-center align-middle cursor-pointer-padrao">
                <span class="d-block far fa-calendar-alt"></span>
            </span>

            <span (click)="goToCalculator()" *ngIf="isJuridico"
                class="ml-3 mr-1 d-inline-flex flex-lg-row-reverse align-items-center align-middle cursor-pointer-padrao">
                <span class="d-block fa fa-calculator"></span>
            </span>

            <!-- <span (click)="goToKanban()"
                class="ml-2 mr-1 d-inline-flex flex-lg-row-reverse align-items-center align-middle cursor-pointer-padrao">
                <span class="material-symbols-outlined" style="font-size:18px">view_kanban</span>
            </span> -->
            <!-- Divider -->
            <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|
            </div>

            <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-user nav-item">
                <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
                    <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                        <span class="px-1 mr-lg-2 ml-2">{{usuarioLogado}}</span>
                        <span class="d-block fas fa-user"></span>
                    </span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                    <a href="javascript:void(0)" class="dropdown-item" (click)="trocarSenha()"><i
                            class="ion ion-ios-person"></i> &nbsp; Perfil</a>
                    <a *ngIf="isMaster && dashboard" href="{{trocarVisualizacao()}}" class="dropdown-item"><i class="ion ion-md-settings"></i> &nbsp;
                        Configurações</a>
                    <a *ngIf="isMaster && !dashboard" href="{{trocarVisualizacao()}}" class="dropdown-item"><i class="fas fa-columns"></i> &nbsp;
                        Dashboard</a>
                    <div *ngIf="showMenuTrocarAmbienteJuridico || showMenuTrocarAmbienteAmigavel"
                        class="dropdown-divider"></div>
                    <a *ngIf="showMenuTrocarAmbienteJuridico" (click)="migrarAmbiente(2)" class="dropdown-item"
                        href="javascript:void(0)"><i class="ion ion-md-repeat"></i> &nbsp;
                        Migrar para Jurídico</a>
                    <a *ngIf="showMenuTrocarAmbienteAmigavel" (click)="migrarAmbiente(1)" class="dropdown-item"
                        href="javascript:void(0)"><i class="ion ion-md-repeat"></i> &nbsp;
                        Migrar para Amigável</a>
                    <div class="dropdown-divider"></div>
                    <a (click)="logout()" class="dropdown-item" href="javascript:void(0)"><i
                            class="ion ion-ios-log-out text-danger"></i> &nbsp;
                        Sair</a>
                </div>
            </div>
        </div>
    </div>
</nav>