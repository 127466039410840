import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SortDirection } from '@swimlane/ngx-datatable';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RequisicaoBuscaPaginada } from 'src/app/models/shared/busca-paginada';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { DocumentoArquivoService } from 'src/app/services/documento-arquivo.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { FileDownloadBackgoundService } from '../../shared/download-in-background/download-in-background.service';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class DocumentsComponent implements OnInit {

    swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    @Input() hasFilter = true;
    @Input() permiteEditarDocumento = true;

    constructor(private spinner: NgxSpinnerService,
        private _documentoArquivoService: DocumentoArquivoService,
        private _arquivoService: ArquivoService,
        private _utilsService: UtilsService,
        private _toastrService: ToastrService,
        private route: ActivatedRoute,
        private downloadInBackgroundService: FileDownloadBackgoundService,
    ) {

        if (this.route.snapshot.params['numeroProcesso'])
            this.numeroProcesso = this.route.snapshot.params['numeroProcesso'];

        if (this.route.snapshot.params['referenciaId'])
            this.referenciaId = this.route.snapshot.params['referenciaId'];
    }

    public documentos: any = [];
    public filtroOn: boolean = false;

    //Filtros
    @Input() referenciaId: Guid;
    origem: string = '';
    descricao: string = '';
    registro: string = '';
    clientes: Guid[] = [];
    responsaveis: Guid[] = [];
    instancias: Guid[] = [];
    @Input() numeroProcesso: string = '';
    pasta: string = '';
    dataUltimaMovimentacao: Date = null;

    //Paginação
    @Input() itensPorPagina: number = 50;
    totalItens: number = 0;
    paginaAtual: number = 1;
    colunaOrdenacao: string = 'DataAtualizacao';
    ordernarDesc: boolean = true;
   
    get totalPaginas() {
        return Math.ceil(this.totalItens / this.itensPorPagina);
    }

    ngOnInit(): void {

        this.obterDocumentos();
    }

    obterDocumentos(): void {

        this.spinner.show();

        let requisicaoBuscaPaginada: RequisicaoBuscaPaginada = {
            itensPorPagina: this.itensPorPagina,
            paginaAtual: this.paginaAtual,
            colunaOrdenacao: this.colunaOrdenacao,
            direcaoOrdenacao: this.ordernarDesc ? SortDirection.desc : SortDirection.asc,
        }

        let requisicao = {
            paginacao: requisicaoBuscaPaginada,
            referenciaId: this.referenciaId,
            origem: Number(this.origem),
            descricao: this.descricao?.trim(),
            registro: this.registro?.trim(),
            clientes: this.clientes,
            responsaveis: this.responsaveis,
            instancias: this.instancias,
            numeroProcesso: this.numeroProcesso?.trim(),
            pasta: this.pasta?.trim(),
            dataUltimaMovimentacao: this._utilsService.formatarDataNgbDatepickerParaRequisicao(this.dataUltimaMovimentacao),
        };

        this._documentoArquivoService.obterDocumentosPorFiltro(requisicao).subscribe(res => {

            if (res.success) {

                this.spinner.hide();

                this.documentos = res.data.itens;
                this.totalItens = res.data.paginacao.totalItens;
            }
        });
    }

    tooltipDownloadArquivo(docs) {
        if(docs?.length > 0) {
            return `${docs.map(doc => `${doc.arquivoNome}`).join('\n')}`;
        }
    }

    obterClientes(documentoClientes): string {
        
        if(!documentoClientes) return "";
        
        return `${documentoClientes.map(c => c).toString().replace(',', '\n')} \n`;
    }

    obterOrigemDocumento(tipoDocumentoEvento): string {

        switch (tipoDocumentoEvento) {
            case 1:
                return 'Tarefa';
            case 2:
                return 'Evento';
            case 3:
                return 'Prazo';
            case 4:
                return 'Audiencia';
            case 5:
                return 'Outros';
            case 6:
                return 'Acordo';
            default:
                return 'Outros';
        }
    }

    handleDownloadArquivo(anexos: any): void {
        anexos.forEach(anexo => {
            const params = {
                arquivoId: anexo.arquivoId,
                file: {name: anexo.arquivoNome, id: anexo.id},
            } 
            this.downloadInBackgroundService.show(params);
        });
    }

    abrirFecharFiltro(): void {
        this.filtroOn = !this.filtroOn;
    }

    limparFiltros(): void {

        this.origem = '';
        this.descricao = '';
        this.registro = '';
        this.clientes = [];
        this.responsaveis = [];
        this.instancias = [];
        this.numeroProcesso = ''
        this.pasta = '';
        this.dataUltimaMovimentacao = null;

        this.paginaAtual = 1;
        this.obterDocumentos();
    }

    alterarPagina(paginaSelecionada) {

        this.paginaAtual = paginaSelecionada;
        this.obterDocumentos();
    }

    ordenar(colunaOrdenacao: string) {

        this.colunaOrdenacao = colunaOrdenacao;
        this.ordernarDesc = !this.ordernarDesc;
        this.obterDocumentos();
    }

    removerArquivo(id: Guid): void {

        this.swalWithBootstrapButtons.fire({
            text: 'Deseja realmente remover o documento?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {

            if (!result.value) {
                return;
            }

            this.spinner.show();

            this._documentoArquivoService.delete(id).subscribe(res => {

                this._toastrService.success('Documento removido!', 'Sucesso', { timeOut: 5000 });
                this.spinner.hide();
                this.obterDocumentos();
            },
                error => {
                    if (error && error.errors) {
                        this._toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this._toastrService.error('Não foi possível remover o documento!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
        });
    }
}
