<div class="col-12" *ngIf="load">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="col-12" *ngIf="!recursoDesdobramento.loadHistoricoOcorrencia">
    <ng-container *ngFor="let recursoDesdobramento of recursoDesdobramento.historicosProcessoOcorrencias; let i = index">
        <div class="d-flex" [ngClass]="{'opacity': load}">
            <div class="col-11"></div>
            <div class="d-flex justify-content-end col-1">
                <div class="btn-adicionar"
                    (click)="recursoDesdobramento.menuEdivarVisible = !recursoDesdobramento.menuEdivarVisible">
                    <i class="fas fa-ellipsis-v"></i>
                </div>
                <div class="menu-detalhes" (mouseover)="recursoDesdobramento.menuEdivarVisible = true"
                    (mouseleave)="recursoDesdobramento.menuEdivarVisible = false"
                    [hidden]="!recursoDesdobramento.menuEdivarVisible">
                    <ul>
                        <li><a (click)="editarOcorrencia(recursoDesdobramento)">Editar</a></li>
                        <li><a (click)="excluirOcorrencia(recursoDesdobramento)">Excluir</a></li>
                    </ul>
                </div>
            </div>   
        </div>  
        <div class="d-flex" [ngClass]="{'opacity': load}">
            <div class="pr-3">
                {{recursoDesdobramento.dataOcorrencia | date: 'dd/MM/yyyy'}}    
            </div> 
            <div class="d-flex justify-content-between col-12">
                <div class="tipo-ocorrencia">
                    <div class="d-flex">
                        <i class="far fa-copy mr-2"></i> 
                        <b (click)="toogleTipoOcorrencia(i)">
                            {{recursoDesdobramento.tipoOcorrenciaNome}} 
                            <span *ngIf="recursoDesdobramento.qtdLines > 2.8">
                                <span>
                                {{ !recursoDesdobramento.showTipoOcorrencia ? '[+]' : '[-]'}}
                                </span>
                            </span>
                        </b>     
                        <i *ngIf="recursoDesdobramento.exibirParaCliente" class="fa fa-eye ml-2" ngbTooltip="Visível para o cliente"></i>                               
                    </div>
                    <div class="mt-2" style="font-size: small;">
                        <i class="fas fa-user-edit"></i> Cadastrado por {{recursoDesdobramento.pessoaCadastro}} em {{recursoDesdobramento.dataCriacao | date: 'dd/MM/yyyy'}}
                    </div>
                    <p class="tipo-descricao" id="tipo-descricao {{i}}" style="line-height: 22px;" [ngClass]="{'collapse-desc': !recursoDesdobramento.showTipoOcorrencia && recursoDesdobramento.qtdLines > 2.8}">
                        {{recursoDesdobramento.descricao}}
                    </p>
                </div>  
            </div>                                                    
        </div>
        <div class="col-12 ml-4">
            <div class="row" *ngFor="let atividade of recursoDesdobramento.detalhes">
                <div class="col-2"></div>
                <div class="col-10">
                    <app-card-ocorrencia-atvd-detalhe
                        [atividade]="atividade" 
                        [recursoDesdobramento]="recursoDesdobramento" 
                        (openModalComments)="openModalComments($event)"
                        (editarAtividade)="editarAtividade($event)"
                        (excluirAtividade)="excluirAtividade($event)"
                        ></app-card-ocorrencia-atvd-detalhe>
                </div>  
                <div class="col-2"></div>
                <div class="col-10" *ngIf="atividade.editarDetalhes && atividade.tipoOcorrencia == 2">
                    <tarefas-detalhes tarefaId="{{atividade.id}}" tarefaDetalhes="{{atividade}}" 
                        (changeQuantidade)="onChangeQuantidade(atividade, false, $event)"
                        (changeQuantidadeOcultando)="onChangeQuantidade(atividade, true, $event)"
                        (changeConcluirTarefa)="onChangeConcluirTarefa(atividade, $event)"></tarefas-detalhes>
                </div>
                <div class="col-10" *ngIf="atividade.editarDetalhes && atividade.tipoOcorrencia == 3">
                    <eventos-detalhes eventoId="{{atividade.id}}" eventoDetalhes="{{atividade}}"                                     
                        (changeConcluirEvento)="onChangeConcluirEvento(atividade, $event)"
                        (changeQuantidadeOcultando)="onChangeQuantidade(atividade, true, $event)"></eventos-detalhes>
                </div>
                <div class="col-10" *ngIf="atividade.editarDetalhes && atividade.tipoOcorrencia == 4">
                    <prazo-detalhes prazoId="{{atividade.id}}" prazoDetalhes="{{atividade}}"                                     
                        (changeConcluirPrazo)="onChangeConcluirPrazo(atividade, $event)"
                        (changeQuantidadeOcultando)="onChangeQuantidade(atividade, true, $event)"></prazo-detalhes>
                </div>                                      
            </div>
        </div>
    </ng-container>
</div>