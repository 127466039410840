import { Component, Input, AfterViewInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styles: [':host { display: block; }']
})
export class LayoutSidenavComponent implements AfterViewInit{
  @Input() orientation = 'horizontal';

  @HostBinding('class.layout-sidenav') hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') hostClassFlex = false;

  constructor(private router: Router,
    private appService: AppService,
    private layoutService: LayoutService,
    private usuarioService: UsuarioService,
    private authService: AuthService
    ) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;
  }

  ngOnInit() {
    this.showMenu = (this.authService.getAmbiente().length > 0) ? true : false;
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();

    this.usuarioService.getPermissoes().then(res =>{
        let user_ambiente = this.authService.getAmbiente();
        this.modulos = res["data"];
        this.modulos = this.modulos.filter(m => {
            return m.moduloAmbiente.filter(ma => {
                return ma.ambiente == user_ambiente;
            }).length > 0;
        });
        this.modulos.forEach(m => {
            m.filhos = m.filhos.filter(mf => {
                return mf.moduloAmbiente.filter(ma => {
                    return ma.ambiente == user_ambiente;
                }).length > 0;
            });
        });
    });
  }

  modulos: any[] = [];
  showMenu: boolean;

  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  }

  isActive(url) {
    return this.router.isActive(url, true);
  }

  isMenuActive(url) {
    return this.router.isActive(url, false);
  }

  isMenuOpen(url) {
    return this.router.isActive(url, false) && this.orientation !== 'horizontal';
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
