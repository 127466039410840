import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-despesas',
  templateUrl: './despesas.component.html',
  styleUrls: ['./despesas.component.scss']
})
export class DespesasComponent implements OnInit {
    
    public tipo: string;
    public referenciaId: string;
    public atendimentoId: string;
    public raiz:string;
    public processoJudicialId: string;
    
    constructor(                
        private router: Router,
        private route: ActivatedRoute,        
        public toastrService: ToastrService,                        
        private location: Location) { }

    ngOnInit(): void {        
        this.tipo = this.route.snapshot.params['tipo'];
        this.referenciaId = this.route.snapshot.params['referenciaId'];
        this.atendimentoId = this.route.snapshot.params['atendimentoId'];
        this.processoJudicialId = this.route.snapshot.params['processoJudicialId'];

        if (this.router.url.indexOf('/dashboard/') > -1) 
        {
            this.raiz = '/dashboard'
        }else{
            this.raiz = '/operacionais'
        }                
    }
    
    voltar(){
        this.location.back();
    }

    
}
