<div class="modal-class">
    <div>
        <div class="row m-0">
            <div class="col-8 p-3">
                <mat-tab-group>
                    <mat-tab label="Geral">
                        <ng-container
                            *ngTemplateOutlet="title; context: {title: item.caso ? 'Detalhes do Caso' : 'Detalhes do Processo'}"></ng-container>
                        <app-process-details [processo]="processo" [processoId]="item.processoJudicialId" [item]="item"></app-process-details>
                    </mat-tab>

                    <mat-tab label="Comentários">
                        <ng-container *ngTemplateOutlet="title; context: {title: 'Comentários'}"></ng-container>
                        <div class="row">
                            <div class="col-12" *ngIf="(tipoOcorrencia == 1 || tipoOcorrencia == 4) && item.tipoStatusAtividade != 1">
                                <comentarios-detalhes [processo]="processo" [id]="item.id" [item]="item" [buscarTarefaDoUsuario]="buscarTarefaDoUsuario" [tipoOcorrencia]="tipoOcorrencia"></comentarios-detalhes>
                            </div>
                            <div class="col-12" *ngIf="(tipoOcorrencia == 1 || tipoOcorrencia == 4) && item.tipoStatusAtividade == 1">
                                Atividade não iniciada
                            </div>
                            <div class="col-12" *ngIf="tipoOcorrencia != 1 && tipoOcorrencia != 2 && tipoOcorrencia != 4">
                                <comentarios-detalhes [processo]="processo" [id]="item.id" [item]="item" [buscarTarefaDoUsuario]="buscarTarefaDoUsuario" [tipoOcorrencia]="tipoOcorrencia"></comentarios-detalhes>
                            </div>
                            <div class="col-12" *ngIf="tipoOcorrencia == 2">
                                <historico-detalhes descricao="{{descricao}}">
                                </historico-detalhes>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Despesas">
                        <ng-container *ngTemplateOutlet="title; context: {title: 'Despesas'}"></ng-container>
                        <app-despesas-dashboard [itensPorPagina]="10" [codigoAtividade]="item.id"  [numeroProcesso]="item.processoJudicialId" (saiuTela)="sairModal()"></app-despesas-dashboard>
                    </mat-tab>

                    <mat-tab label="Documentos">
                        <ng-container *ngTemplateOutlet="title; context: {title: 'Documentos'}"></ng-container>
                        <app-documents [hasFilter]="false" [permiteEditarDocumento]="false" [itensPorPagina]="10" [referenciaId]="item.processoJudicialId"></app-documents>
                    </mat-tab>

                    

                    <mat-tab label="Log da Atividade">
                        <ng-container *ngTemplateOutlet="title; context: {title: 'Log da Atividade'}"></ng-container>
                        <app-time-line-atividade-log [codigoAtividade]="item.id"></app-time-line-atividade-log>
                        
                        
                    </mat-tab>
                </mat-tab-group>
            </div>

            <div class="col-4 p-1 atividades">
                <button type="button" class="close" (click)="dismiss()">&times;</button>

                <div class="p-5">
                    <ng-container>
                        <h5>
                            <b><i class="fa fa-user"></i> Usuários da Atividade</b>
                        </h5>

                        <div class="d-flex align-items-center">
                            <div class="photo p-1 mr-2 align-self-center">
                                <img src="https://thumbs.dreamstime.com/z/businessman-avatar-line-icon-vector-illustration-design-79327237.jpg"
                                    width="30px" height="30px" />
                            </div>
                            <span>
                                {{item.responsavelNome}}<br>
                                <b>Executor</b>
                            </span>
                        </div>

                        <div class="d-flex mt-2 align-items-center" *ngIf="item.revisorNome != undefined">
                            <div class="photo p-1 mr-2 align-self-center">
                                <img src="https://thumbs.dreamstime.com/z/businessman-avatar-line-icon-vector-illustration-design-79327237.jpg"
                                    width="30px" height="30px" />
                            </div>
                            <span>
                                {{item.revisorNome}}<br>
                                <b>Revisor</b>
                            </span>
                        </div>

                        <div class="d-flex mt-2 align-items-center" *ngIf="item.assistenteNome != undefined">
                            <div class="photo p-1 mr-2 align-self-center">
                                <img src="https://thumbs.dreamstime.com/z/businessman-avatar-line-icon-vector-illustration-design-79327237.jpg"
                                    width="30px" height="30px" />
                            </div>
                            <span>
                                {{item.assistenteNome}}<br>
                                <b>Assistente</b>
                            </span>
                        </div>

                        <!-- <div class="d-flex align-items-center">
                            <div class="photo p-1 mr-2 align-self-center">
                                <img src="https://thumbs.dreamstime.com/b/homem-de-neg%C3%B3cios-como-um-empres%C3%A1rio-bem-sucedido-114487226.jpg"
                                    width="30px" height="30px" />
                            </div>
                            <span>
                                Teste Teste<br>
                                <b>Teste</b>
                            </span>
                        </div> -->
                    </ng-container>

                    <hr />

                    <!-- <ng-container>
                        <h5>
                            <b>Tempo na Atividade</b>
                        </h5>

                        <h5><span class="text-muted">00:00</span><i class="fas fa-play ml-2 cursor-pointer"></i></h5>
                    </ng-container>

                    <hr /> -->

                    <ng-container>
                        <h5>
                            <b><i class="fas fa-info-circle"></i> Informações de datas</b>
                            <div ngbDropdown class="options d-flex float-right" *ngIf="item.tipoOcorrencia == 1 || item.tipoOcorrencia == 4">
                                <span
                                  ngbDropdownToggle
                                  type="button"
                                  id="reschedule"
                                  data-toggle="dropdown"
                                  class="fas fa-calendar-alt mr-2"
                                  aria-expanded="false"
                                ></span>
                                <input
                                  matInput
                                  (dateChange)="saveDate(RESCHEDULE_TYPE.SOMEDAY, $event)"
                                  [matDatepicker]="picker"
                                  class="inpt-calendar"
                                />
                                <div ngbDropdownMenu aria-labelledby="reschedule">
                                  <a
                                    ngbDropdownItem
                                    class="cursor-pointer"
                                    (click)="saveDate(RESCHEDULE_TYPE.TODAY, $event)"
                                    >Reagendar para hoje</a
                                  >
                                  <a
                                    ngbDropdownItem
                                    class="cursor-pointer"
                                    (click)="saveDate(RESCHEDULE_TYPE.TOMORROW, $event)"
                                    >Reagendar para amanhã</a
                                  >
                                  <ng-container>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <a ngbDropdownItem class="cursor-pointer" (click)="picker.open()">
                                      Reagendar para algum dia</a
                                    >
                                  </ng-container>
                                  <a
                                    ngbDropdownItem
                                    class="cursor-pointer"
                                    (click)="saveDate(RESCHEDULE_TYPE.NEXT_MONDAY, $event)"
                                    >Reagendar para a próxima segunda</a
                                  >
                                </div>
                              </div>
                        </h5>

                        <div *ngIf="item.dataLimite != undefined" class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="far fa-calendar mr-1"></i>
                            <span class="mr-1">Data Limite:</span>
                            <a>{{item.dataLimite | date:'dd/MM/yyyy'}}</a>
                        </div>

                        <div *ngIf="item.dataRevisao != undefined" class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="far fa-calendar mr-1"></i>
                            <span class="mr-1">Data revisão:</span>
                            <a>{{item.dataRevisao | date:'dd/MM/yyyy'}}</a>
                        </div>

                        <div *ngIf="item.dataPublicacao != undefined" class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="far fa-calendar mr-1"></i>
                            <span class="mr-1">Data publicação:</span>
                            <a>{{item.dataPublicacao | date:'dd/MM/yyyy'}}</a>
                        </div>

                        <div *ngIf="item.dataInicioPrazo != undefined" class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="far fa-calendar mr-1"></i>
                            <span class="mr-1">Data início prazo:</span>
                            <a>{{item.dataInicioPrazo | date:'dd/MM/yyyy'}}</a>
                        </div>

                        <div *ngIf="item.dataHoraInicial != undefined && !item.diaInteiro" class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="far fa-calendar mr-1"></i>
                            <span class="mr-1">Data hora inicial:</span>
                            <a>{{item.dataHoraInicial | date:'dd/MM/yyyy HH:mm'}}</a>
                        </div>

                        <div *ngIf="item.dataHoraFinal != undefined && !item.diaInteiro" class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="far fa-calendar mr-1"></i>
                            <span class="mr-1">Data hora final:</span>
                            <a>{{item.dataHoraFinal | date:'dd/MM/yyyy HH:mm'}}</a>
                        </div>

                        <div *ngIf="item.dataHoraInicial != undefined && item.diaInteiro" class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="far fa-calendar mr-1"></i>
                            <span class="mr-1">Dia inteiro:</span>
                            <a>{{item.dataHoraInicial | date:'dd/MM/yyyy'}}</a>
                        </div>

                        <!-- <div class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="fa fa-flag"></i>
                            <span class="text-center w-100">New</span>
                        </div>

                        <div class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="fa fa-check mr-1"></i>
                            <span class="mr-1">Prioridade:</span>
                            <a>Urgent</a>
                        </div>

                        <div class="info-datas d-flex align-items-center p-2 mb-2">
                            <i class="fa fa-eye"></i>
                            <span class="text-center w-100">Visible</span>
                        </div> -->

                    </ng-container>

                    <hr />

                    <ng-container *ngIf="processo != undefined && processo.tags != undefined && processo.tags.length">
                        <h5>
                            <i class="fas fa-tag"></i> <b>Tags</b>
                        </h5>
                        <div *ngFor="let tag of processo.tags" class="mr-2 mt-2 tag-topo" [ngClass]="defineClass(tag.tagCor)">
                            {{tag.tagNome}} &nbsp;
                       </div>
                    </ng-container>

                    <hr />

                    <ng-container *ngIf="exibirAcoes">
                        <h5>
                            <b><i class="fas fa-exclamation-circle"></i> Ações</b>
                        </h5>

                        <!-- <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2">
                            <i class="fas fa-play mr-2"></i>
                            <span>Iniciar atividade:</span>
                        </div> -->
                        <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2">
                            <i class="fas fa-eye"></i>
                            <a href="/dashboard/processos-judiciais/detalhes/{{item.processoJudicialId}}" target="_blank">
                                Ver processo <i class="fas fa-external-link-square-alt"></i>
                            </a>
                        </div>
                        <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2" *ngIf="item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 1" [ngClass]="!item.isEdit ? 'noClick' : ''">
                            <a (click)="item.isEdit ? onIniciarAtividade(item) : ''" [ngClass]="!item.isEdit ? 'noClick' : ''"><i class="fas fa-play"></i> 
                                Iniciar atividade
                            </a>
                        </div>
                        <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2" *ngIf="item.tipoOcorrencia != 2 && (item.tipoStatusAtividade == 2 || item.tipoStatusAtividade == 4)" [ngClass]="!item.isEdit ? 'noClick' : ''">
                            <a (click)="item.isEdit ? onEnviarParaRevisao(item) : ''" [ngClass]="!item.isEdit ? 'noClick' : ''"><i class="fab fa-algolia"></i> 
                                <span>Enviar para revisão</span>
                            </a>
                        </div>
                        <!-- <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2" *ngIf="item.tipoOcorrencia != 2 && item.tipoOcorrencia !=5 && item.tipoStatusAtividade == 5" [ngClass]="!item.isEdit ? 'noClick' : ''">
                            <a class="cursor-pointer" (click)="item.isEdit ? onLiberarProtocolarAtividade(item) : ''" [ngClass]="!item.isEdit ? 'noClick' : ''">
                                <i class="fas fa-paperclip"></i>
                                Liberar para protocolar atividade
                            </a>
                        </div> -->
                        <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2" *ngIf="(item.isRevisor && item.tipoStatusAtividade != 7 && item.tipoStatusAtividade != 8) || (item.tipoOcorrencia != 2 && (item.tipoStatusAtividade == 5 || item.tipoStatusAtividade == 6))"  [ngClass]="!item.isEdit ? 'noClick' : ''">
                            <a class="cursor-pointer" (click)="item.isEdit || item.isRevisor ? concluirAtividade(item) : ''" [ngClass]="!item.isEdit ? 'noClick' : ''">
                                <i class="fas fa-check-circle"></i>
                                Concluir atividade
                            </a>
                        </div>
                    <!--Fluxo revisor-->
                    <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2" *ngIf="item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 3" [ngClass]="!item.isEdit ? 'noClick' : ''">
                        <a class="cursor-pointer" (click)="item.isEdit ? onDevolverAtividade(item) : ''" [ngClass]="!item.isEdit ? 'noClick' : ''">
                            <i class="fas fa-undo"></i> Devolver atividade
                        </a>
                    </div>
                    <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2" *ngIf="item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 3"  [ngClass]="!item.isEdit ? 'noClick' : ''">
                        <a class="cursor-pointer" (click)="item.isEdit ? onRevisadoAtividade(item) : ''" [ngClass]="!item.isEdit ? 'noClick' : ''">
                            <i class="fas fa-check-circle"></i> Revisado e liberado
                        </a>
                    </div>
                    <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2"*ngIf="(item.tipoOcorrencia != 2 && item.tipoStatusAtividade == 7)"  [ngClass]="!item.isEdit ? 'noClick' : ''">
                        <a class="cursor-pointer" (click)="item.isEdit ? onEncerrarRevisao(item) : ''">
                            <i class="fab fa-algolia"></i> Encerrar atividade
                        </a>
                    </div>
                    <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2"  [ngClass]="!item.isEdit ? 'noClick' : ''">
                        <a class="cursor-pointer" (click)="item.isEdit ? onExcluirAtiviade(item) : ''" [ngClass]="!item.isEdit ? 'noClick' : ''">
                            <i class="fa fa-trash"></i> Excluir atividade</a>
                    </div>
                        <!-- <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2">
                            <i class="fas fa-pen mr-2"></i>
                            <span>Editar atividade:</span>
                        </div>

                        <div class="info-datas cursor-pointer d-flex align-items-center p-2 mb-2">
                            <h5 class="m-0 mr-2">&times;</h5>
                            <span>Excluir atividade:</span>
                        </div> -->

                    </ng-container>



                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #title let-title="title">
    <div class="row mt-3">
        <div class="col-12" style="font-size: 16px">
            <i class="fas fa-file"></i> {{title}}
            <hr class="mt-1" />
        </div>
    </div>
</ng-template>