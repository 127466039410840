import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TipoDespesaOptions } from 'src/app/enums/tipo-despesa/tipo-despesa.enum';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { DespesaService } from 'src/app/services/despesa.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-lista-despesa',
    templateUrl: './lista-despesa.component.html',
    styleUrls: ['./lista-despesa.component.scss']
})
export class ListaDespesaComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() resumo: boolean = false;

    public despesas: any;
    public load:boolean = true;
    public tiposDespesa = TipoDespesaOptions;
    sortDescByDespesasPendente: boolean = true;
    sortByDespesasPendente: string = 'data';
    constructor(
        private despesaService: DespesaService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public arquivoService: ArquivoService,
        public utilsService: UtilsService,
        private router: Router,
    ) { }

    ngOnInit(): void {
      if(this.referenciaId)
        this.buscarDespesa();
      else
      this.buscarDespesaTipos();
    }

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    buscarDespesa() {
        this.load = true;
        this.despesaService.buscarTodos(this.tipo, this.referenciaId)
            .subscribe(res => {
                this.despesas = res.data;

                this.despesas.forEach(honorario => {
                    honorario.valorHonorario = honorario.valorHonorario?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                    honorario.tipoDespesa = this.tiposDespesa.find(tipo => tipo._id == honorario.tipoDespesa)?.status;
                    honorario.faturar = false;
                });
                console.log(this.despesas);
                this.load = false;
            })
    }
    buscarDespesaTipos() {
      this.load = true;
      this.despesaService.buscarTodosTipos(this.tipo)
          .subscribe(res => {
              this.despesas = res.data;

              this.despesas.forEach(honorario => {
                  honorario.valorHonorario = honorario.valorHonorario?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                  honorario.tipoDespesa = this.tiposDespesa.find(tipo => tipo._id == honorario.tipoDespesa)?.status;
                  honorario.faturar = false;
              });
              console.log(this.despesas);
              this.load = false;
          })
  }

    editarDespesa(despesa: any) {
        console.log(despesa);
        if(despesa && despesa.tipoDespesa == "Em Aberto"){
            this.router.navigate([`/dashboard/despesa/editar/${despesa.id}`]);
        }else{
            this.toastrService.error('Não é possível editar uma despesa Faturada/Reembolsada!', 'Atenção');
        }
    }

    deletarDespesa(despesa: any) {

        if(despesa.tipoDespesa != "Em Aberto") {
            this.toastrService.error('Não é possível excluir uma despesa Faturada/Reembolsada!', 'Atenção');
            return;
        }

        this.swalWithBootstrapButtons.fire({
            title: 'Deseja realmente excluir a despesa?',
            text: 'Essa ação não poderá ser revertida!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                this.despesaService.deletarDespesa(despesa.id).subscribe(
                    () => {
                        this.spinner.hide();
                        this.toastrService.success('Despesa excluída com sucesso!', 'Sucesso');
                        this.buscarDespesa();
                    },
                    () => {
                        this.spinner.hide();
                        this.toastrService.error('Não foi possível excluir a despesa!', 'Atenção');
                    }
                );
            }
        });
    }

    handleDownloadArquivo(arquivo: any) {
        this.spinner.show();
        try {
            this.arquivoService.downloadArquivo(arquivo.arquivoId).subscribe(
                data => {
                    if (data.type == 3) {
                        this.spinner.hide();
                    }
                    this.utilsService.renderDownload(data, arquivo.nomeArquivo as string);
                }
            );
        } catch {
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download do arquivo!', 'Atenção', { timeOut: 10000 });
        }
    }

    setSortDespesasPendente(key) {

        if (this.sortByDespesasPendente !== key) {
            this.sortByDespesasPendente = key;
            this.sortDescByDespesasPendente = false;
        } else {
            this.sortDescByDespesasPendente = !this.sortDescByDespesasPendente;
        }

        this.ordernar();
    }

    sort(data: any, sortBy: string, sortDesc: boolean) {

        data.sort((a: any, b: any) => {

            a = typeof (a[sortBy]) === 'string' ? a[sortBy].trimLeft().toUpperCase() : a[sortBy];
            b = typeof (b[sortBy]) === 'string' ? b[sortBy].trimLeft().toUpperCase() : b[sortBy];

            if (a === null) { return 1; }
            if (b === null) { return -1; }

            if (a < b) { return sortDesc ? 1 : -1; }
            if (a > b) { return sortDesc ? -1 : 1; }

            return 0;
        });
    }

    ordernar() {

        this.sort(this.despesas, this.sortByDespesasPendente, this.sortDescByDespesasPendente);

    }
}
